import { IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO } from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyAulaService = () => {
	const resourceURL = '/aca-aula';

	return {
		...baseFind<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
		...baseFindList<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
		...baseFindById<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
		...baseCreate<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),
		...baseUpdate<IAcaAulaCleanDTO, IAcaAulaDTO, IAcaAulaFullDTO>(resourceURL),

		// createFull: (body): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.post(`${resourceURL}/full`, body),
		// patch: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		// patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),

	};
};
