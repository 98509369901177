import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useUnidadeContext } from '../../../context/UnidadeContext';

interface Props {
    current: number;
}

function StepsCreate({ current }: Props): ReactElement {

    const { revisao } = useUnidadeContext();

    return (
        <div className="menu-user">
            <h3>Criação de Franquia</h3>
            <Steps direction="vertical" current={revisao ? 8 : current}>
                <Steps.Step title="Dados Básicos" />
                <Steps.Step title="Localização" />
                <Steps.Step title="Contrato" />
                <Steps.Step title="Responsáveis" />
                <Steps.Step title="Documentos" />
                <Steps.Step title="Classificação" />
                <Steps.Step title="Dados Bancários" />
                <Steps.Step title="Revisão" />
            </Steps>
        </div>
    )
}

export default StepsCreate
