import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Input, InputNumber } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import fondo from '../../assets/img/matriculas/fondo.svg';
import BoxCurso from '../../components/BoxCurso';
import Modal from '../../components/Modal';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import { useCursoFranquiaContext } from '../../context/CursoFranquiaContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCursoUnidadeService } from '../../services/curso-unidade.service';
import { useLogService } from '../../services/log.service';
import { useNegocioService } from '../../services/negocio.service';
import { IHoraUnidadeCleanDTO, IHoraUnidadeDTO, IHoraUnidadeFullDTO, ILogCleanDTO, INegocioCleanDTO, INegocioFullDTO, INegocioUnidadeDTO } from './../../models/happy-code-api.model';
import './style.css';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { currencyFormatter, currencyParser } from '../../util/format';
import { useHoraUnidadeService } from '../../services/hora-unidade.service';
import { useNotificationService } from '../../services/notification.service';
moment().locale('pt-br');

export const CursoFranquia: React.FunctionComponent = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<PageableResponse<IHoraUnidadeFullDTO>>();
    const [descricao, setDescricao] = useState<string>();
    const cursoUnidadeService = useCursoUnidadeService();
    const negocio = useNegocioService();
    const horaUnidadeService = useHoraUnidadeService();
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const history = useHistory();
    const [cursoPredicate] = useState<Predicate>(new Predicate(6));
    const [negocioPredicate] = useState<Predicate>(new Predicate(6));
    const { turma, setTurma } = useTurmaContext();
    const {oferta, setOferta} = useCursoFranquiaContext();
    const notification = useNotificationService();


    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso tela cursos franquia",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const findCurso = () => {
        negocioPredicate.addOption('ativo', true)
        negocioPredicate.addOption('unidade.id', unidade.id)
        return horaUnidadeService.findFull(negocioPredicate)
            .then((response) => {
                setOferta(response.data.content[0]);
            });
    }

    // useEffect(() => {
    //     if (!unidade.id) return;

    //     setIsGlobalLoading(true);
    //     findCurso().finally(() => setIsGlobalLoading(false));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [unidade.id]);


    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        setIsGlobalLoading(true);
        negocioPredicate.setPage(page);
        findCurso().finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (descricao !== undefined) {
                setIsGlobalLoading(true);
                negocioPredicate.addOption('negocio.descricao', descricao, Operators.CONTAINS);
                findCurso().finally(() => setIsGlobalLoading(false));
            }
        }, 1500);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao]);

    const handleSubmit = (negocio: INegocioCleanDTO) => {
        if (negocio) {
            turma.curriculoNegocioId = negocio?.id
            setTurma(turma);
            history.push("/curso-franquia/turma");
        }
    }

    const save = () => {
        setIsGlobalLoading(true)
        horaUnidadeService.patchCustom(oferta.id, oferta).then(() =>
            notification({ description: `Oferta alterada com sucesso!`, type: 'success', message: 'Sucesso!' }))
            .catch(() => notification({ description: `Não foi possível alterar a oferta!`, type: 'warning', message: 'Aviso!' }));
            setIsGlobalLoading(false)
            setTimeout(() => {
                history.push('/curso-franquia/step-3');
            }, 1500);
    }

    

    return (
        <div className="container-fondo" style={{ paddingTop: 0 }}>
            <NewMenu selecionado={12} />
            <div className="franquia" id="aluno-detalhe-row">
                <div className="user-row" style={{ width: 900, zIndex: 2 }}>
                    <div className="card-form">
                        <h3 id="taxas-h3">1. Oferta para uma hora e meia</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                value={oferta?.valorOfertaUmaHoraMeia}
                                onChange={(e) => setOferta({ ...oferta, valorOfertaUmaHoraMeia: e })}
                            />
                        </div>
                        <h3 id="taxas-h3">2. Oferta para três horas</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={oferta?.valorOfertaTresHora}
                                value={oferta?.valorOfertaTresHora}
                                onChange={(e) => setOferta({ ...oferta, valorOfertaTresHora: e })}
                            />
                        </div>
                        <h3 id="taxas-h3">3. Oferta para quatro horas e meia</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={oferta?.valorOfertaQuatroHoraMeia}
                                value={oferta?.valorOfertaQuatroHoraMeia}
                                onChange={(e) => setOferta({ ...oferta, valorOfertaQuatroHoraMeia: e })}
                            />
                        </div>
                        <h3 id="taxas-h3">4. Oferta para seis horas</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={oferta?.valorOfertaSeisHora}
                                value={oferta?.valorOfertaSeisHora}
                                onChange={(e) => setOferta({ ...oferta, valorOfertaSeisHora: e })}
                            />
                        </div>
                        <h3 id="taxas-h3">5. Oferta valor adicional</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={oferta?.valorAdicionalOferta}
                                value={oferta?.valorAdicionalOferta}
                                onChange={(e) => setOferta({ ...oferta, valorAdicionalOferta: e })}
                            />
                        </div>

                        <h3 id="taxas-h3">6. Oferta para Curta</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={oferta?.valorOfertaCurta}
                                value={oferta?.valorOfertaCurta}
                                onChange={(e) => setOferta({ ...oferta, valorOfertaCurta: e })}
                            />
                        </div>
                        <h3 id="taxas-h3">7. Oferta para Colônia</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={oferta?.valorOfertaColonia}
                                value={oferta?.valorOfertaColonia}
                                onChange={(e) => setOferta({ ...oferta, valorOfertaColonia: e })}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Link to={"/franquia"}>
                                <Button style={{ marginLeft: '10px' }} onClick={() => setIsOpen(true)} className="button-primary">Voltar</Button>
                            </Link>
                            <Button style={{ marginLeft: '10px' }} className="button-primary" onClick={save}>Salvar</Button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a adição desta Nova Matricula?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/alunos'
            />
            <img src={fondo} alt="" className="img-fondo" />
        </div>
    );
}