import { DateRangeOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, DatePicker, Input, Space, TableColumnType, TableColumnsType } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { IRelFinanceiroDetalhado } from '../../../../models/relatorio.model';
import { formatDataRelatorio, formatMoney, formattedDate } from '../../../../util/format';
import useRelatorioHook from './use-relatorio.hook';

type DataIndex = keyof IRelFinanceiroDetalhado;

const TableColumns = () => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const [filteredRange, setFilteredRange] = useState<[moment.Moment, moment.Moment] | null>(null);

	const {
		selectStatusNfs,
		selectStatusParcelaCobranca,
		selectTipoPagamento,
		selectStatus,
		selectNegocios,
		selectStatusCobranca,
		selectStatusMatricula,
		selectTipoCurriculo,
		findStatusMatricula,
		findTipoCurriculo,
		findTipoPagamento,
		findNegocio,
		findStatusCobranca,
	} = useRelatorioHook();

	useEffect(() => {
		findNegocio();
		findTipoCurriculo();
		findStatusCobranca();
		findStatusMatricula();
		findTipoPagamento();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDateChange = (dates: [moment.Moment, moment.Moment] | null, setSelectedKeys: (keys: string[]) => void) => {
		setFilteredRange(dates);
		if (dates) {
			setSelectedKeys([dates[0].toISOString(), dates[1].toISOString()]);
		} else {
			setSelectedKeys([]);
		}
	};

	const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: string) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
		setFilteredRange(null);
	};

	const getColumnDateProps = (dataIndex: DataIndex): TableColumnType<IRelFinanceiroDetalhado> => ({
		filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: any) => (
			<div style={{ padding: 8 }}>
				<DatePicker.RangePicker
					className="mr-10"
					onChange={(dates) => handleDateChange(dates, setSelectedKeys)}
					value={filteredRange}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => confirm()}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90, height: "auto" }}
					>
						Search
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" className="w-90">
						Resetar
					</Button>
				</Space>
			</div>
		),

		filterIcon: (filtered: boolean) => (
			<DateRangeOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),

		onFilter: (_value, record: IRelFinanceiroDetalhado) => {
			if (!filteredRange) return true;

			const dateValue = moment(record[dataIndex]);
			if (!dateValue.isValid() || !record[dataIndex]) {
				return false;
			}

			const [start, end] = filteredRange;
			return dateValue.isBetween(start, end, 'days', '[]');
		},


		render: (value: string) => formattedDate(value),
	});

	const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<IRelFinanceiroDetalhado> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90, height: 'auto' }}
					>
						Buscar
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" className="w-90">Resetar</Button>
				</Space>
			</div>
		),

		filterIcon: (filtered: boolean) => (<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />),

		onFilter: (value, record) => record[dataIndex]?.toString()?.toLowerCase()?.includes((value as string)?.toLowerCase()),

		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) :
				(text),
	});

	const columns: TableColumnsType<IRelFinanceiroDetalhado> = [
		{
			title: 'UNIDADE',
			dataIndex: 'C01_UNIDADE',
			align: "center",
			...getColumnSearchProps('C01_UNIDADE'),
			sorter: (a, b) => a.C01_UNIDADE?.localeCompare(b.C01_UNIDADE),
			width: 100,
		},
		{
			title: 'Aluno',
			dataIndex: 'C02_ALUNO',
			align: "center",
			...getColumnSearchProps('C02_ALUNO'),
			sorter: (a, b) => a.C02_ALUNO?.localeCompare(b.C02_ALUNO),
			width: 150,
		},
		{
			title: 'Status Aluno',
			dataIndex: 'C03_STATUS_ALUNO',
			align: "center",
			filters: selectStatus,
			onFilter: (value: string, record) => record?.C03_STATUS_ALUNO?.includes(value),
			sorter: (a, b) => a.C03_STATUS_ALUNO?.localeCompare(b.C03_STATUS_ALUNO),
			width: 120,
		},
		{
			title: 'Responsável Financeiro',
			dataIndex: 'C04_RESPONSAVEL_FINANCEIRO',
			align: "center",
			...getColumnSearchProps('C04_RESPONSAVEL_FINANCEIRO'),
			sorter: (a, b) => a.C04_RESPONSAVEL_FINANCEIRO?.localeCompare(b.C04_RESPONSAVEL_FINANCEIRO),
			width: 180,
		},
		{
			title: 'Negócio',
			dataIndex: 'C05_NEGOCIO',
			align: "center",
			filters: selectNegocios,
			onFilter: (value: string, record) => record?.C05_NEGOCIO?.includes(value),
			sorter: (a, b) => a.C05_NEGOCIO?.localeCompare(b.C05_NEGOCIO),
			width: 150,
		},
		{
			title: 'Currículo',
			dataIndex: 'C06_CURRICULO',
			align: "center",
			...getColumnSearchProps('C06_CURRICULO'),
			sorter: (a, b) => a.C06_CURRICULO?.localeCompare(b.C06_CURRICULO),
			width: 150,
		},
		{
			title: 'Tipo Currículo',
			dataIndex: 'C07_TIPO_CURRICULO',
			align: "center",
			filters: selectTipoCurriculo,
			onFilter: (value: string, record) => record?.C07_TIPO_CURRICULO?.includes(value),
			sorter: (a, b) => a.C07_TIPO_CURRICULO?.localeCompare(b.C07_TIPO_CURRICULO),
			width: 150,
		},
		{
			title: 'Turma',
			dataIndex: 'C08_TURMA',
			align: "center",
			...getColumnSearchProps('C08_TURMA'),
			sorter: (a, b) => a.C08_TURMA?.localeCompare(b.C08_TURMA),
			width: 150,
		},
		{
			title: 'ID Matricula',
			dataIndex: 'C09_ID_MATRICULA',
			align: "center",
			...getColumnSearchProps('C09_ID_MATRICULA'),
			sorter: (a, b) => a.C09_ID_MATRICULA - b.C09_ID_MATRICULA,
			width: 150,
		},
		{
			title: 'Status Matricula',
			dataIndex: 'C10_STATUS_MATRICULA',
			align: "center",
			filters: selectStatusMatricula,
			onFilter: (value: string, record) => record.C10_STATUS_MATRICULA?.includes(value),
			sorter: (a, b) => a.C10_STATUS_MATRICULA?.localeCompare(b.C10_STATUS_MATRICULA),
			width: 150,
		},
		{
			title: 'ID Cobrança',
			dataIndex: 'C11_ID_COBRANCA',
			align: "center",
			...getColumnSearchProps('C11_ID_COBRANCA'),
			sorter: (a, b) => a.C11_ID_COBRANCA - b.C11_ID_COBRANCA,
			width: 150,
		},
		{
			title: 'ID Financeiro',
			dataIndex: 'C12_ID_FINANCEIRO',
			align: "center",
			...getColumnSearchProps('C12_ID_FINANCEIRO'),
			sorter: (a, b) => a.C12_ID_FINANCEIRO - b.C12_ID_FINANCEIRO,
			width: 150,
		},
		{
			title: 'Status Cobrança',
			dataIndex: 'C13_STATUS_COBRANCA',
			align: "center",
			filters: selectStatusParcelaCobranca,
			onFilter: (value: string, record) => record?.C13_STATUS_COBRANCA?.includes(value),
			sorter: (a, b) => a.C13_STATUS_COBRANCA?.localeCompare(b.C13_STATUS_COBRANCA),
			width: 150,
		},
		{
			title: 'Status Parcela',
			dataIndex: 'C14_STATUS_PARCELA',
			align: "center",
			filters: selectStatusCobranca,
			onFilter: (value: string, record) => record.C14_STATUS_PARCELA?.includes(value),
			sorter: (a, b) => a.C14_STATUS_PARCELA?.localeCompare(b.C14_STATUS_PARCELA),
			width: 150,
		},
		{
			title: 'Tipo Pagamento',
			dataIndex: 'C15_TIPO_PAGAMENTO',
			align: "center",
			filters: selectTipoPagamento,
			onFilter: (value: string, record) => record.C15_TIPO_PAGAMENTO?.includes(value),
			sorter: (a, b) => a.C15_TIPO_PAGAMENTO?.localeCompare(b.C15_TIPO_PAGAMENTO),
			width: 150,
		},
		{
			title: 'Parcelamento Contrato',
			dataIndex: 'C16_PARCELAMENTO_CONTRATO',
			align: "center",
			...getColumnSearchProps('C16_PARCELAMENTO_CONTRATO'),
			sorter: (a, b) => a.C16_PARCELAMENTO_CONTRATO - b.C16_PARCELAMENTO_CONTRATO,
			width: 150,
		},
		{
			title: 'Parcela Atual',
			dataIndex: 'C17_PARCELA_ATUAL',
			align: "center",
			...getColumnSearchProps('C17_PARCELA_ATUAL'),
			sorter: (a, b) => a.C17_PARCELA_ATUAL - b.C17_PARCELA_ATUAL,
			width: 150,
		},
		{
			title: 'VL Total Oferta',
			dataIndex: 'C18_VL_TOTAL_OFERTA',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C18_VL_TOTAL_OFERTA);
			},
			sorter: (a, b) => a.C18_VL_TOTAL_OFERTA - b.C18_VL_TOTAL_OFERTA,
			width: 150,
		},
		{
			title: 'VL Total Com Desconto',
			dataIndex: 'C19_VL_TOTAL_COM_DESCONTO',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C19_VL_TOTAL_COM_DESCONTO);
			},
			sorter: (a, b) => a.C19_VL_TOTAL_COM_DESCONTO - b.C19_VL_TOTAL_COM_DESCONTO,
			width: 150,
		},
		{
			title: 'VL Oferta Hub',
			dataIndex: 'C20_VL_OFERTA_HUB',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C20_VL_OFERTA_HUB);
			},
			sorter: (a, b) => a.C20_VL_OFERTA_HUB - b.C20_VL_OFERTA_HUB,
			width: 150,
		},
		{
			title: 'VL Oferta Templo',
			dataIndex: 'C21_VL_OFERTA_TEMPLO',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C21_VL_OFERTA_TEMPLO);
			},
			sorter: (a, b) => a.C21_VL_OFERTA_TEMPLO - b.C21_VL_OFERTA_TEMPLO,
			width: 150,
		},
		{
			title: 'VL Mensalidade Com Desconto',
			dataIndex: 'C22_VL_MENSALIDADE_COM_DESCONTO',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C22_VL_MENSALIDADE_COM_DESCONTO);
			},
			sorter: (a, b) => a.C22_VL_MENSALIDADE_COM_DESCONTO - b.C22_VL_MENSALIDADE_COM_DESCONTO,
			width: 150,
		},
		{
			title: 'VL Parcela',
			dataIndex: 'C23_VL_PARCELA',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C23_VL_PARCELA);
			},
			sorter: (a, b) => a.C23_VL_PARCELA - b.C23_VL_PARCELA,
			width: 150,
		},
		{
			title: 'VL Encargos',
			dataIndex: 'C24_VL_ENCARGOS',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C24_VL_ENCARGOS);
			},
			sorter: (a, b) => a.C24_VL_ENCARGOS - b.C24_VL_ENCARGOS,
			width: 150,
		},
		{
			title: 'VL Cobrança',
			dataIndex: 'C25_VL_COBRANCA',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C25_VL_COBRANCA);
			},
			sorter: (a, b) => a.C25_VL_COBRANCA - b.C25_VL_COBRANCA,
			width: 150,
		},
		{
			title: 'VL Pago',
			dataIndex: 'C26_VL_PAGO',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C26_VL_PAGO);
			},
			sorter: (a, b) => a.C26_VL_PAGO - b.C26_VL_PAGO,
			width: 150,
		},
		{
			title: 'VL Parcela Hub',
			dataIndex: 'C27_VL_PARCELA_HUB',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C27_VL_PARCELA_HUB);
			},
			sorter: (a, b) => a.C27_VL_PARCELA_HUB - b.C27_VL_PARCELA_HUB,
			width: 150,
		},
		{
			title: 'VL Parcela Licença Templo',
			dataIndex: 'C28_VL_PARCELA_LICENCA_TEMPLO',
			align: "right",
			render(_value, record, _index) {
				return formatMoney(record.C28_VL_PARCELA_LICENCA_TEMPLO);
			},
			sorter: (a, b) => a.C28_VL_PARCELA_LICENCA_TEMPLO - b.C28_VL_PARCELA_LICENCA_TEMPLO,
			width: 150,
		},
		{
			title: 'DT Início Contrato',
			dataIndex: 'C29_DT_INICIO_CONTRATO',
			align: "center",
			...getColumnDateProps('C29_DT_INICIO_CONTRATO'),
			sorter: (a, b) => moment(a.C29_DT_INICIO_CONTRATO).unix() - moment(b.C29_DT_INICIO_CONTRATO).unix(),
			render(_value, record, _index) {
				return formatDataRelatorio(record.C29_DT_INICIO_CONTRATO);
			},
			width: 150,
		},
		{
			title: 'DT Vencimento Parcela',
			dataIndex: 'C30_DT_VENCIMENTO_PARCELA',
			align: "center",
			...getColumnDateProps('C30_DT_VENCIMENTO_PARCELA'),
			sorter: (a, b) => moment(a.C30_DT_VENCIMENTO_PARCELA).unix() - moment(b.C30_DT_VENCIMENTO_PARCELA).unix(),
			render(_value, record, _index) {
				return formatDataRelatorio(record.C30_DT_VENCIMENTO_PARCELA);
			},
			width: 150,
		},
		{
			title: 'DT Pagamento',
			dataIndex: 'C31_DT_PAGAMENTO',
			align: "center",
			...getColumnDateProps('C31_DT_PAGAMENTO'),
			sorter: (a, b) => moment(a.C31_DT_PAGAMENTO).unix() - moment(b.C31_DT_PAGAMENTO).unix(),
			render(_value, record, _index) {
				return formatDataRelatorio(record.C31_DT_PAGAMENTO);
			},
			width: 150,
		},
		{
			title: 'DT Previsão Recebimento',
			dataIndex: 'C32_DT_PREVISAO_RECEBIMENTO',
			align: "center",
			...getColumnDateProps('C32_DT_PREVISAO_RECEBIMENTO'),
			sorter: (a, b) => moment(a.C32_DT_PREVISAO_RECEBIMENTO).unix() - moment(b.C32_DT_PREVISAO_RECEBIMENTO).unix(),
			render(_value, record, _index) {
				return formatDataRelatorio(record.C32_DT_PREVISAO_RECEBIMENTO);
			},
			width: 150,
		},
		{
			title: 'DT Cancelamento',
			dataIndex: 'C33_DT_CANCELAMENTO',
			align: "center",
			...getColumnDateProps('C33_DT_CANCELAMENTO'),
			sorter: (a, b) => moment(a.C33_DT_CANCELAMENTO).unix() - moment(b.C33_DT_CANCELAMENTO).unix(),
			render(_value, record, _index) {
				return formatDataRelatorio(record.C33_DT_CANCELAMENTO);
			},
			width: 150,
		},
		{
			title: 'Emitiu Nota',
			dataIndex: 'C34_EMITIU_NOTA',
			align: "center",
			filters: selectStatusNfs,
			onFilter: (value: string, record) => record?.C34_EMITIU_NOTA?.includes(value),
			sorter: (a, b) => a.C34_EMITIU_NOTA?.localeCompare(b.C34_EMITIU_NOTA),
			width: 150,
		}
	];

	return columns;
};

export default TableColumns;
