import * as Unicons from '@iconscout/react-unicons';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import MenuDetalheUser from '../../components/MenuDetalheUser';
import { Tags } from '../../components/Tags';
import { useUsuarioContext } from '../../context/UsuarioContext';

import { ContatoEnum } from '../../models/pessoa.model';
import './style.css';
import { TipoAcessoAcademy } from '../../enum/tipoAcessoAcademy';

const UsuarioCreateStep7: React.FunctionComponent = () => {
	const { usuario, setRevisao } = useUsuarioContext();
	const history = useHistory();

	const goTo = (url) => history.push(url);

	useEffect(() => {
		if (!usuario) {
			history.push(`/usuarios`);
		}
		setRevisao(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
			</div>
			{/* <Menu selecionado="3" /> */}
			<MenuDetalheUser />
			<div className="franquia" style={{ backgroundColor: '#F5F5F9', top: 70 }}>
				<div className="franquia-row">
					<h5>Revisão de Dados</h5>
					<div className="row-revisao">
						{/* <ul className="menu-revisao-detalhe">
							<li className="selected" style={{ width: '34%' }}>
								Dados Básicos
							</li>
							<li style={{ width: '33%' }}>Franquias</li>
							<li style={{ width: '33%' }}>Controle</li>
						</ul> */}
						<div className="box-revisao">
							<div className="dados">
								<Button className="button-line" onClick={() => goTo('/usuarios/create/step-1')} >
									Editar
								</Button>
								<h4>Dados Básicos</h4>
								<div className="nome">
									<h5>Nome Completo</h5>
									<span style={{ fontSize: 18 }}>{usuario.pessoa.nome}</span>
								</div>
								<div className="dados-franquia">
									<div className="cpf">
										<h6>E-mail Pessoal</h6>
										<span>{usuario.pessoa.email}</span>
									</div>
									<div className="cpf">
										<h6>E-mail Professional</h6>
										<span>
											{
												usuario.pessoa.contatos.find(
													(c) => c.tipoContatoId === ContatoEnum.EPF
												)?.descricao
											}
										</span>
									</div>
								</div>
							</div>

							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<Button className="button-line" onClick={() => goTo('/usuarios/create/step-2')} >
									Editar
								</Button>
								<h4 className="mb10">Dados de Acesso</h4>
								<div className="dados-franquia mt10">
									<div style={{ display: "flex" }} className="cpf">
										<div id="boxes-tags" className="col mr20">
											<h6>Perfil de Acesso</h6>
											<Tags tipo={usuario.perfil.codigo} />
										</div>
										<div id="boxes-tags" className="col mr20">
											<h6>Função</h6>
											<Tags tipo={usuario.funcao.descricao} />
										</div>
									</div>
								</div>
							</div>

							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<Button className="button-line" onClick={() => goTo('/usuarios/create/step-9')} >
									Editar
								</Button>
								<h4 className="mb10">Dados de Acesso do Academy</h4>
								<div className="dados-franquia mt10">
									<div style={{ display: "flex" }} className="cpf">
										<div id="boxes-tags" className="col mr20 ">
											<h6>Perfil de Acesso</h6>
											<div className="row">
												{usuario.acessoB2c ?
													<div className="mr20">
														<Tags tipo={TipoAcessoAcademy.CODIGO_B2C} />
													</div>
													: null}
												{usuario?.acessoB2b ?
													<div className="mr20">
														<Tags tipo={TipoAcessoAcademy.CODIGO_B2B} />
													</div>
													: null}
												{usuario.acessoComercial ?
													<div className="mr20">
														<Tags tipo={TipoAcessoAcademy.CODIGO_COMERCIAL} />
													</div>
													: null}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados" style={{ minHeight: 50 }}>
								<Button
									onClick={() => goTo('/usuarios/create/step-3')}
									className="button-line"
								>
									Editar
								</Button>
								<div id="user-franquias-revisao">
									{usuario.usuarioPaises.length > 0 ?
										<>
											<h4>Paises</h4>
											{usuario.usuarioPaises.map((usuarioPais) =>
												<div id="curso-usuarios" key={usuarioPais.id}>
													<h5>{usuarioPais.paisNome}</h5>
												</div>)}
										</>
										: usuario.usuarioClusters.length > 0 ?
											<>
												<h4>Clusters</h4>
												{usuario.usuarioClusters.map((usuarioCluster) =>
													<div id="curso-usuarios" key={usuarioCluster.id}>
														<h5>{usuarioCluster.clusterDescricao}</h5>
													</div>)}
											</>
											: usuario.usuarioUnidades.length > 0 &&
											<>
												<h4>Franquias</h4>
												{usuario.usuarioUnidades.map((usuarioUnidade) =>
													<div id="curso-usuarios" key={usuarioUnidade.id}>
														<h5>{usuarioUnidade.unidadeDescricao}</h5>
													</div>)}
											</>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="botoes" style={{ paddingBottom: 30 }}></div>
				</div>
			</div>
			<div className="botao-voltar-revisao">
				<Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />} onClick={() => history.goBack()}>
					Voltar
				</Button>
			</div>
		</div>
	);
}

export default UsuarioCreateStep7;