import { Button } from 'antd';
import { useCallback, useState } from 'react';
import ModalCreate from '../../../../components/ModalCreate/index';
import { useEscolaContext } from '../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { useGrupoService } from '../../../../services/grupo.service';
import './menu-revisao-escola-grupo.component.style.scss';

interface Props {
  qtdDocumentoPendente: number;
}

export const MenuRevisaoEscolaGrupoComponent: React.FunctionComponent<Props> = ({ qtdDocumentoPendente }: Props) => {
  const { grupoParaCadastrar, getCategoriaQuantidadeAlunosById, limparGrupoParaCadastrar, setFlRevisao } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);

  const grupoService = useGrupoService();

  const saveGrupo = () => {

    setIsGlobalLoading(true);

    if (!grupoParaCadastrar.unidade?.id) {
      grupoParaCadastrar.unidade = undefined;
    }

    if (grupoParaCadastrar.id) {
      // (grupoParaCadastrar.endereco as any).dataCriacao = new Date();
      return grupoService.patchFull(grupoParaCadastrar).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setIsOpen(true);
          limparGrupoParaCadastrar();
        }
      }).finally(() => {
        setIsGlobalLoading(false);
      });
    }

    grupoService.createFull(grupoParaCadastrar).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        setIsOpen(true);
        limparGrupoParaCadastrar();
      }
    }).finally(() => {
      setIsGlobalLoading(false);
    });

  }

  const onClickLink2 = useCallback(() => setFlRevisao(false), []);

  return (
    <div id="revisao-grupo">
      <div className="menu-box-revisao-float" style={{ marginTop: 30 }}>
        <div className="bloco-1">
          <h4>Nome do Grupo</h4>
          <h1>{grupoParaCadastrar?.nomeFantasia}</h1>
          <h6>CNPJ</h6>
          <p>{grupoParaCadastrar?.cnpj}</p>
          <h6>Quant. aluno</h6>
          <p>{getCategoriaQuantidadeAlunosById(grupoParaCadastrar?.categoriaQuantidadeAlunos?.id)?.descricao}</p>
          {/* <h6>Razão Social</h6> */}
          {/* <h1>{grupoParaCadastrar?.}</h1> */}
          <Button id="salvar" style={{ width: 250, cursor: 'pointer', }} className="button-primary" onClick={() => saveGrupo()}>
            Salvar Grupo
          </Button>
        </div>
      </div>
      <ModalCreate
        //onClose={() => setIsOpen(false)} 
        title={`Grupo salvo com sucesso!`}
        textButton1='Voltar a página inicial'
        textButton2='Criar outro grupo'
        link1={'/escolas/grupos'}
        link2={'/escolas/grupos/create/step-1'}
        replaceLink1={true}
        replaceLink2={true}
        open={isOpen}
        styleButton1="button-line"
        styleButton2="button-primary"
        onClickLink2={onClickLink2}
      />
    </div>
  );
}