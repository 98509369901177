import * as Unicons from '@iconscout/react-unicons';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ICursoUnidadeDTO } from "../../models/happy-code-api.model";
import { formatMoney } from '../../util/format';

interface TItemLista {
    cursoUnidade: ICursoUnidadeDTO;
}

const ItemListOferta = memo(({ cursoUnidade }: TItemLista) => {
    const [ativo, setAtivo] = useState<boolean>();
    const history = useHistory();

    useEffect(() => {
        setAtivo(cursoUnidade.ativo);
    }, [cursoUnidade.ativo])

    const goTo = () => {
        history.push(`/cursos/oferta/step-3/${cursoUnidade.id}`)
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <div className="nome">
                    <p>{cursoUnidade.unidade.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}>
                <span>{formatMoney(cursoUnidade.valorSplitMatricula)}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}>
                <span>{formatMoney(cursoUnidade.valorSplitRematricula)}</span>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}></div>
            <div className="registro-opcao" style={{ width: "10%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
            </div>
        </div>
    )
})

ItemListOferta.displayName = 'ItemListOferta';


export default ItemListOferta;
