import { useState, useEffect } from 'react';
import { RelatorioIndicadores } from '../../models/relatorio.model';
import { TipoStatusCobranca } from '../../enum/tipoStatusCobranca';

interface Props {
	displayedData: RelatorioIndicadores[];
}

const IndicadoresRelatorio = ({ displayedData }: Props) => {
	const [valorTotalSedeReal, setValorTotalSedeReal] = useState<string>();
	const [valorTotalFranquiaReal, setValorTotalFranquiaReal] =
		useState<string>();
	const [valorTotalVendido, setValorTotalVendido] = useState<string>();
	const [valorTotalCobranca, setValorTotalCobranca] = useState<string>();
	const [totalMatricula, setTotalMatricula] = useState<number>();
	const [totalPago, setTotalPago] = useState<number>();

	useEffect(() => {
		let auxValorTotalSede = 0;
		let auxValorTotalFranquia = 0;
		let auxValorTotalVendido = 0;
		let auxValorTotalCobranca = 0;
		let auxTotalPago = 0;

		displayedData.forEach((dataObj) => {
			const valorSede = dataObj.valorSede;
			if (valorSede) {
				auxValorTotalSede += valorSede;
			}
			const valorFranquia = dataObj.valorLiquido;
			if (valorFranquia) {
				auxValorTotalFranquia += valorFranquia;
			}
			const valorVendido = dataObj.valorTotalComDesconto;
			if (valorVendido) {
				auxValorTotalVendido += valorVendido;
			}
			const valorCobranca = dataObj.valorMensalidadeComDesconto;
			if (valorCobranca) {
				auxValorTotalCobranca += valorCobranca;
			}
			const statusCobranca = dataObj.statusCobranca;
			if (statusCobranca == TipoStatusCobranca.DESCRICAO_PAGO) {
				auxTotalPago += 1;
			}
		});
		setValorTotalSedeReal(
			auxValorTotalSede.toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
		setValorTotalFranquiaReal(
			auxValorTotalFranquia.toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
		setValorTotalVendido(
			auxValorTotalVendido.toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
		setValorTotalCobranca(
			auxValorTotalCobranca.toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
		setTotalMatricula(displayedData.length);
		setTotalPago(auxTotalPago);
	}, [displayedData]);

	return (
		<div className="box-header-contador-relatorio">
			<div className="box-informe">
				<h1>Sede</h1>
				<p>{valorTotalSedeReal}</p>
			</div>
			<div className="box-informe">
				<h1>Franquia</h1>
				<p>{valorTotalFranquiaReal}</p>
			</div>
			<div className="box-informe">
				<h1>Cobranças</h1>
				<p>{valorTotalCobranca}</p>
			</div>
			<div className="box-informe">
				<h1>Vendido</h1>
				<p>{valorTotalVendido}</p>
			</div>
			<div className="box-informe">
				<h1>Total contratos</h1>
				<p>{totalMatricula}</p>
			</div>
			<div className="box-informe">
				<h1>Total pagos</h1>
				<p>{totalPago}</p>
			</div>
		</div>
	);
};

export default IndicadoresRelatorio;
