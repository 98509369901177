/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Operators } from '../../../enum/operators.enum';
import { PerfilEnum } from '../../../enum/perfil.enum';
import { ICampanhaLeadEscolaCleanDTO, ICidadeDTO, ICursoDTO, IEstadoDTO, ILeadEscolaEstagioDTO, ILeadEscolaEstagioFullDTO, ILeadEscolaOrigemDTO, IPaisDTO, IStatusLeadEscolaDTO, IUnidadeDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useCategoriaQuantidadeAlunosService } from '../../../services/categoria-quantidade-alunos.service';
import { useCursoService } from '../../../services/curso.service';
import { useGrupoService } from '../../../services/grupo.service';
import { useLeadEscolaCampanhaService } from '../../../services/lead-escola-campanha.service';
import { useLeadEscolaEstagioService } from '../../../services/lead-escola-estagio.service';
import { useLeadEscolaOrigemService } from '../../../services/lead-escola-origem.service';
import { useLeadEscolaStatusService } from '../../../services/lead-escola-status.service';
import { useLeadEscolaService } from '../../../services/lead-escola.service';
import { useLeadTipoService } from '../../../services/lead-tipo.service';
import { usePaisService } from '../../../services/pais.service';
import { useUnidadeService } from '../../../services/unidade.service';


const CrmEscolaContext = createContext<any>(null);

export interface PropsCrmEscolaContext {
    leadsStatus?: IStatusLeadEscolaDTO[];
    campanhas?: ICampanhaLeadEscolaCleanDTO[];
    leadStatus?: IStatusLeadEscolaDTO[];
    leadEstagios?: ILeadEscolaEstagioDTO[];
    paises?: IPaisDTO[];
    estados?: IEstadoDTO[];
    cidades?: ICidadeDTO[];
    leadOrigens?: ILeadEscolaOrigemDTO[];
    leadTipos?: any[];
    unidades?: IUnidadeDTO[];
    cursos?: ICursoDTO[];
    boards?: ILeadEscolaEstagioFullDTO[];
    leadParaCadastrar: any;
    setLeadParaCadastrar: any;
    onFecharModal: any;
    onAbrirModal: any;
    flModalOpen: boolean;
    refresh: any;
    refreshLeads: any;
    state: any;
    setCrmEscolaContext: any;
    setFlModalOpen: any;
    indexCadastro: number;
    setIndexCadastro: any;
    onCancelarCadastro: any;
    onVoltarStep: any;
    unidadeUsuarioLogado: any;
    limparLeadsParaCadastrar: any;
    fetchAll: any;
    onChangeUnidade: any;
    isSede: boolean;
    setFilterSearch: (e) => PropsCrmEscolaContext;
    filterSearch: number;
}

export const CrmEscolaContextProvider: React.FC = ({ children }: any) => {
    const [state, setState] = useState({
        campanhas: [],
        leadStatus: [],
        leadEstagios: [],
        paises: [],
        estados: [],
        cidades: [],
        leadOrigens: [],
        leadTipos: [],
        unidades: [],
        cursos: [],
        boards: [],
        boardsFiltrados: [],
        categoriasQuantidadeAlunos: [],
        boardsTemp: [],
        flRefresh: false,
        qtLeadsPorPaginas: 10,
    });

    const leadCampanhaService = useLeadEscolaCampanhaService();
    const leadStatusEscolaService = useLeadEscolaStatusService();
    const leadEscolaEstagioService = useLeadEscolaEstagioService();
    const paisService = usePaisService();
    const leadTipoService = useLeadTipoService();
    const leadOrigemEscolaService = useLeadEscolaOrigemService();
    const categoriaQuantidadeAlunosService = useCategoriaQuantidadeAlunosService();
    const unidadeService = useUnidadeService();
    const grupoService = useGrupoService();
    const cursoService = useCursoService();
    const leadEscolaService = useLeadEscolaService();
    const { unidade } = useGlobalContext();

    const { setIsGlobalLoading, usuario } = useGlobalContext();

    const [leadParaCadastrar, setLeadParaCadastrar] = useState<any>();
    const [unidadeSelecionada, setUnidadeSelecionada] = useState<any>(null);

    const [flModalOpen, setFlModalOpen] = useState<boolean>(false);
    const [indexCadastro, setIndexCadastro] = useState<number>(1);
    const [filterSearch, setFilterSearch] = useState<number>(1);

    const refreshLeads = async (dsBusca = '', estagiosTemp: ILeadEscolaEstagioDTO[] = null, flExibirLoading = true) => {

        flExibirLoading && setIsGlobalLoading(true);
        let estagios = estagiosTemp || state?.boards;

        if (!estagios || estagios?.length <= 0) return;
        if (!usuario?.perfil) return;
        if (usuario.perfil === PerfilEnum.FRANQUIA && !unidade?.id) return;
        else if (usuario.perfil === PerfilEnum.SEDE && unidadeSelecionada !== null && !unidadeSelecionada?.id) return;

        estagios = await Promise.all(estagios.map(async (estagio: ILeadEscolaEstagioFullDTO) => {
            const predicate = new Predicate();

            predicate.sort = 'asc';
            predicate.size = state.qtLeadsPorPaginas;
            predicate.addOption('estagioCodigo', estagio?.codigo, Operators.EQUAL);

            if (dsBusca?.length >= 3) {

                let search;
                if (filterSearch === 1) {
                    search = 'nome';
                }
                if (filterSearch === 2) {
                    search = 'nomeContato';
                }
                predicate.addOption(search, dsBusca, Operators.CONTAINS);
            }
            
            predicate.addSort('dataCriacao');

            let leadResponse;
            if (usuario.perfil === PerfilEnum.FRANQUIA) {
                predicate.addOption('unidadeId', unidade.id, Operators.EQUAL);
                leadResponse = await leadEscolaService.find(predicate);
            } else {
                if (unidadeSelecionada == null) {
                    leadResponse = await leadEscolaService.findDaSede(predicate);
                } else {
                    predicate.addOption('unidadeId', unidadeSelecionada.id);
                    leadResponse = await leadEscolaService.find(predicate);
                }
            }

            if (leadResponse.status >= 200 && leadResponse.status <= 299) {
                estagio.leads = [...leadResponse.data.content] as any;
            }
            return estagio;
        }));


        setState((state) => ({ ...state, boards: [...estagios], boardsTemp: [...estagios], flRefresh: true }));
        flExibirLoading && setIsGlobalLoading(false);
    }

    const refresh = async () => {
        setIsGlobalLoading(true);

        const predicate = new Predicate();
        predicate.addSort('ordem');

        const estagios: ILeadEscolaEstagioDTO[] = await leadEscolaEstagioService.findList(predicate).then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadEstagios: response.data }));
                return response.data;
            }
            return null;
        });

        if (!estagios) {
            setIsGlobalLoading(false);
            return;
        };
        await refreshLeads(null, estagios);
        setIsGlobalLoading(false);
    }

    useEffect(() => {
        if (state?.qtLeadsPorPaginas && (unidadeSelecionada || unidadeSelecionada == null || unidade)) {
            refresh();
        }
    }, [state?.qtLeadsPorPaginas, usuario, filterSearch]);


    const fetchAll = () => {
        if (!usuario?.perfil) return;

        const predicate = new Predicate();
        if (usuario?.perfil === PerfilEnum.FRANQUIA) {
            predicate.addOption('unidade.id', unidade.id);
        } else {

            unidadeService.findList().then((response) => {
                if (response.status === 200) {
                    setState((state) => ({ ...state, unidades: response.data }));
                }
            });
        }

        grupoService.findList(predicate).then((response) => {
            if (response.status === 200)
                setState((state) => ({ ...state, grupos: response.data.filter((grupo) => grupo.ativo) }));

        });

        leadCampanhaService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, campanhas: response.data }));
            }
        });

        categoriaQuantidadeAlunosService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, categoriasQuantidadeAlunos: response.data }));
            }
        });

        leadStatusEscolaService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadStatus: response.data }));
            }
        });

        // leadEscolaEstagioService.findList().then((response) => {
        //   if (response.status === 200) {
        //     setState((state) => ({ ...state, leadEstagios: response.data }));
        //   }
        // });

        paisService.findCleanList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, paises: response.data }));
            }
        })

        leadTipoService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadTipos: response?.data }));
            }
        });

        leadOrigemEscolaService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadOrigens: response?.data }));
            }
        });

        unidadeService.findCleanList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, unidades: response?.data }));
            }
        });

        cursoService.findCleanList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, cursos: response?.data }));
            }
        });
    }

    useEffect(() => {
        setIsGlobalLoading(true);
        fetchAll();
        refresh();
    }, [unidadeSelecionada, unidade, usuario, filterSearch]);

    const onFecharModal = () => {
        setFlModalOpen(false);
        setLeadParaCadastrar(null);
        setIndexCadastro(1);
    }

    const onAbrirModal = () => {
        setFlModalOpen(false);
    }

    const onCancelarCadastro = () => {
        onFecharModal();
    }

    const onVoltarStep = () => {
        setIndexCadastro(indexCadastro - 1);
    }

    const limparLeadsParaCadastrar = () => {
        setLeadParaCadastrar(null);
        setIndexCadastro(1);
        setFlModalOpen(false);
    }

    const onChangeUnidade = (idUnidade) => {
        if (idUnidade === -1)
            setUnidadeSelecionada(null);
        else {
            const unidadeLogado = state?.unidades?.find((item) => item.id === idUnidade)
            setUnidadeSelecionada(unidadeLogado);
        }
    }

    return <CrmEscolaContext.Provider value={{
        state,
        setCrmEscolaContext: setState,
        refresh,
        refreshLeads,
        leadParaCadastrar,
        setLeadParaCadastrar,
        onFecharModal,
        flModalOpen,
        onAbrirModal,
        setFlModalOpen,
        indexCadastro,
        setIndexCadastro,
        onCancelarCadastro,
        onVoltarStep,
        unidadeUsuarioLogado: unidadeSelecionada,
        limparLeadsParaCadastrar,
        fetchAll,
        onChangeUnidade,
        isSede: usuario?.perfil === 'SEDE',
        setFilterSearch,
        filterSearch,
    }}>
        {children}
    </CrmEscolaContext.Provider>
}

export const useCrmEscolaContext = (): PropsCrmEscolaContext => {
    const context = useContext(CrmEscolaContext);
    if (!context)
        throw new Error(
            'useEscolaContext must be used within a EscolaContextProvider'
        );
    return context;
};

