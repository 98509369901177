import {
	IGeneroCleanDTO,
	IGeneroDTO,
	IGeneroFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useGeneroService = () => {
	const resourceURL = 'genero';

	return {
		...baseFindList<IGeneroCleanDTO, IGeneroDTO, IGeneroFullDTO>(resourceURL),
	};
};
