
import defaultUser from '../../assets/img/header/defaultUser.png';
import { IArquivoCleanDTO } from './../../models/happy-code-api.model';

interface Props {
    arquivo?: IArquivoCleanDTO;
    base64?: string;
    size?: number;
}

export const Avatar = (props: Props) => {
    const { arquivo, base64, size } = props;    

    const getUrl = () => {
        if (base64) {
            return `data:image/png;base64,${base64}`;
        }
        return `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${arquivo.url}?name=${arquivo.codigo}`;
    }

    return (
        <div>
            {(arquivo && arquivo.url) || base64 ? (
                <img style={{ borderRadius: '50%', height: size ?? 40, width: size ?? 40 }} src={getUrl()} alt={"avatar"} className="avatar" />
            ) : (
                <img src={defaultUser} alt={"avatar"} className="avatar" />
            )}
        </div>
    )
}