import ModalAntdInativar from '../../../components/ModalAntdInativar';
import { useAlunoContext } from '../../../context/AlunoContext';

const useResponsavelDeletion = (setIsOnePersonTurn) => {
    const { aluno, setAluno } = useAlunoContext();

    const deleteResponsavel = (index: number) => {
        ModalAntdInativar({
            title: `Deseja realmente excluir o Responsável?`,
            content: `O responsável ${aluno?.pessoa?.pessoaResponsaveis[index]?.responsavel?.nome ?? ""}`,
            onOk: () => {
                if (!aluno?.pessoa?.pessoaResponsaveis[index].id) {
                    setIsOnePersonTurn(false);
                    setAluno({
                        ...aluno,
                        pessoa: {
                            ...aluno.pessoa,
                            pessoaResponsaveis: aluno?.pessoa?.pessoaResponsaveis.filter((_, idx) => idx !== index)
                        }
                    });
                }
            }
        });
    };

    return { deleteResponsavel };
};

export default useResponsavelDeletion;
