import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { IUnidadeCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useUnidadeService } from '../../../services/unidade.service';
import { ExportToExcel } from '../../../util/exportToExcel';

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelInadimplencia() {
	const [dados, setData] = useState<[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [dataInicio, setDataInicio] = useState<moment.Moment>();
	const [dataFim, setDataFim] = useState<moment.Moment>();
	const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>();
	const [unidadeId, setUnidadeId] = useState();

	const service = useRelatorioService();
	const unidadeService = useUnidadeService();

	useEffect(() => {
		const predicate = new Predicate();

		unidadeId && predicate.addOption('unidadeId', unidadeId);
		dataInicio && predicate.addOption('dataInicio', dataInicio?.toISOString());
		dataFim && predicate.addOption('dataFim', dataFim?.toISOString());

		service
			.inadimplenciaMensal(predicate)
			.then(({ data }) => setData(data))
			.catch((error) => console.log(error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidadeId, dataInicio, dataFim]);

	const fetchUnidades = () => {
		return unidadeService
			.findCleanList()
			.then((response) => setUnidades(response.data));
	};

	useEffect(() => {
		fetchUnidades();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidadeId, dataInicio, dataFim]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	const getPeriodo = (): any => {
		if (dataInicio && dataFim) {
			return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')];
		}
		return null;
	};

	const selectUnidade = unidades?.map((unidade) => (
		<Option key={unidade.id} value={unidade.id}>
			{unidade.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório inadimplência mensal
				</h1>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de inadimplencia</span>
						<br />
						<RangePicker
							value={getPeriodo()}
							onChange={(values) => {
								setDataInicio(values && values[0]);
								setDataFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel apiData={dados} fileName={'financeiros-pago'} />
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Unidade</span>
						<br />
						<Select
							showSearch
							style={filtroStyle}
							defaultValue={unidadeId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setUnidadeId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectUnidade}
						</Select>
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}
export default RelInadimplencia;
