import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ITaxaCobrancaBaixaDTO,
	ITaxaCobrancaCleanDTO,
	ITaxaCobrancaCustomDTO,
	ITaxaCobrancaDTO,
	ITaxaCobrancaFullDTO,
	ITaxaCobrancaKpiDTO,
	ITaxaCobrancaPagarMeDTO,
} from '../models/happy-code-api.model';
import { Predicate } from '../models/predicate.model';
import { formatHttpParams } from './../models/predicate.model';
import { PageableResponse } from './../models/response.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useTaxaCobrancaService = () => {
	const resourceURL = 'taxa-cobranca';

	const getKpi = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<ITaxaCobrancaKpiDTO>> => {
		return http.get(`${resourceURL}/kpi`, formatHttpParams(predicate));
	};

	const findCustom = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<PageableResponse<ITaxaCobrancaCustomDTO>>> => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	};

	const patch = (toUpdate: any): Promise<AxiosResponse<ITaxaCobrancaDTO>> =>
		http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate);

	const renegociacao = (toTaxas: any) =>
		http.post(`${resourceURL}/renegociacao`, toTaxas);

	const pagarmeTaxa = (
		taxa: ITaxaCobrancaPagarMeDTO,
		token: string): Promise<AxiosResponse<ITaxaCobrancaPagarMeDTO>> => {
		return http.post(`${resourceURL}/checkout/atualizar-qrcode`, taxa, {
			headers: { Authorization: `Bearer ${token}` },
		});
	}

	const findStatus = (
		cobrancaId: number,
		token: string
	): Promise<AxiosResponse<string>> => {
		return http.get(`${resourceURL}/checkout/status?cobrancaId=${cobrancaId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
	};

	const findCheckout = (
		codigoPedido: string,
		multa: boolean,
		token: string
	): Promise<AxiosResponse<ITaxaCobrancaPagarMeDTO>> => {
		return http.get(
			`${resourceURL}/checkout?codigoPedido=${codigoPedido}&multa=${multa}`,
			{ headers: { Authorization: `Bearer ${token}` } });

	};

	const gerarTaxa = (idUnidade: number, valor:number): Promise<AxiosResponse> => {
		return http.post(`${resourceURL}/taxa-franquia/${idUnidade}/${valor}`)
	}

	const validar = (predicate: Predicate = new Predicate()): Promise<AxiosResponse<ITaxaCobrancaCleanDTO>> => {
		return http.get(`${resourceURL}/validar`, formatHttpParams(predicate))
	}

	const baixaManual = (body: any, id: number) => {
		const formData = new FormData();
		  formData.append('file', body)
			return http.post(`${resourceURL}/baixa-manual?id=${id}`, formData);
	  }
	
	  const baixaManualObservacao = (observacao: ITaxaCobrancaBaixaDTO, id: number) => {    
			return http.patch(`${resourceURL}/baixa-manual/${id}`, observacao);
	  }

	return {
		...baseFindList<ITaxaCobrancaCleanDTO, ITaxaCobrancaDTO, ITaxaCobrancaFullDTO>(resourceURL),
		...baseFind<ITaxaCobrancaCleanDTO, ITaxaCobrancaDTO, ITaxaCobrancaFullDTO>(
			resourceURL
		),
		getKpi,
		findCustom,
		patch,
		renegociacao,
		pagarmeTaxa,
		findStatus,
		findCheckout,
		gerarTaxa,
		validar,
		baixaManual,
		baixaManualObservacao
	};
};
