import * as Unicons from '@iconscout/react-unicons';
import { Button, Form } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import card from '../../../assets/img/franquias/card.svg';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { GrupoPessoaTipoEnum } from '../../../enum/grupo-pessoa-tipo.enum';
import { IGrupoPessoaTipoDTO } from '../../../models/happy-code-api.model';
import EscolaGruposFormActionComponent from '../components/escola-grupos-form-action-component/escola-grupos-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-grupos-form-fechar-component/escola-grupos-form-fechar.component';
import MenuLateralCreateComponent from '../components/menu-lateral-create-component/menu-lateral-create.component';
import '../styles/escola-grupo-create-step.styles.scss';
import { useGlobalContext } from '../../../context/GlobalContext';
import { PerfilEnum } from '../../../enum/perfil.enum';
import { useEffect } from 'react';


export const GrupoCreateStep3Screen: React.FunctionComponent = () => {
  const { usuario } = useGlobalContext();
  const { unidade, revisao } = useUnidadeContext();
  const { grupoParaCadastrar, grupoPessoaTipos, flRevisao } = useEscolaContext();
  const history = useHistory();
  const [form] = Form.useForm();

  const divFocal = (key, titulo, idPessoa, nome, link) => {

    function disabledFocal() {
      if (usuario.perfil === PerfilEnum.FRANQUIA && link === `/escolas/grupos/create/responsavel/${GrupoPessoaTipoEnum.RESPONSAVEL_NEGOCIACAO}`) {
        return true;
      }
    }

    return (
      <div key={key} className="box-cards" style={{ flex: '50%' }}>
        <div className="card" style={disabledFocal() ? { background: '#ccc' } : null}>
          {idPessoa ?
            (<>
              <div className="check">
                <Unicons.UilCheck size="20" color="#FFFFFF" />
              </div>
              <h4>{titulo}</h4>
              <div className="dados-box">
                <div className="circle-avatar"></div>
                <div className="dados-responsavel">
                  <h4>{nome}</h4>
                </div>
              </div>
              <Link to={link}>
                <Button disabled={disabledFocal()} className="button-line" style={{ width: 310 }}>Alterar</Button>
              </Link>
            </>
            ) :
            (<>
              <h4>{titulo}</h4>
              <img src={card} alt={"Card"} className="" />
              {!disabledFocal() ?
                <>
                  <p>Ops, você ainda não adicionou nenhum usuário aqui</p>
                  <Link to={link}>
                    <div id="criar-responsavel" className="button-primary" style={{ width: 310 }}>
                      <Unicons.UilPlus size="20" color="#FFFFFF" />
                      <span>Adicionar responsável</span>
                    </div>
                  </Link>
                </>
                : <p>Cadastramento do responsável pela Negociação deve ser feito pela Sede, campo não obrigatório</p>}
            </>
            )
          }
        </div>
      </div >
    );

  }

  const handleStep3 = () => {
    // if (!grupoParaCadastrar?.responsavelNegociacao ||
    //   !grupoParaCadastrar?.responsavelLegal ||
    //   !grupoParaCadastrar?.focalFinanceiro ||
    //   !grupoParaCadastrar?.focalOperacao) {
    //   return;
    // }
    history.push('/escolas/grupos/create/step-4');
  }

  const getObjeto = (grupoPessoaTipo: IGrupoPessoaTipoDTO) => {
    if (!grupoParaCadastrar) {
      return {
        data: null,
        link: grupoPessoaTipo.codigo,
      }
    }

    const { pessoas } = grupoParaCadastrar;
    let data = null;

    if (pessoas?.length) {
      data = pessoas.find((item) => item.tipo.codigo === grupoPessoaTipo.codigo)

      if (!data) {
        if (grupoPessoaTipo.codigo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO) {
          data = {
            pessoa: grupoParaCadastrar.focalFinanceiro
          };
        } else if (grupoPessoaTipo.codigo === GrupoPessoaTipoEnum.FOCAL_OPERACIONAL) {
          data = {
            pessoa: grupoParaCadastrar.focalOperacional
          };
        }
      }
    }

    return {
      data,
      link: grupoPessoaTipo.codigo,
    };
  }

  return (
    <div className="container">
      <Header />
      <Progress />
      <MenuLateralCreateComponent current={2} />
      <Form
        form={form}
        name="control-hooks"
        onFinish={handleStep3}
      >
        <div className="franquia" style={{ marginTop: 80 }}>
          <div className="franquia-row">
            <h5>Pessoas</h5>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {divFocal(
                1,
                "Focal Financeiro",
                grupoParaCadastrar?.focalFinanceiro?.id,
                grupoParaCadastrar?.focalFinanceiro?.nome,
                "/escolas/grupos/create/responsavel/" + GrupoPessoaTipoEnum.FOCAL_FINANCEIRO
              )
              }
              {divFocal(
                2,
                "Focal Operacional",
                grupoParaCadastrar?.focalOperacional?.id,
                grupoParaCadastrar?.focalOperacional?.nome,
                "/escolas/grupos/create/responsavel/" + GrupoPessoaTipoEnum.FOCAL_OPERACIONAL
              )
              }
              {grupoPessoaTipos?.filter(
                (tipo) =>
                  tipo.codigo !== GrupoPessoaTipoEnum.FOCAL_FINANCEIRO
                  && tipo.codigo !== GrupoPessoaTipoEnum.FOCAL_OPERACIONAL
              ).map(
                (item, index) => {
                  const grupo = getObjeto(item);

                  return divFocal(
                    index + 2,
                    item.descricao,
                    grupo?.data?.pessoa?.id,
                    grupo?.data?.pessoa?.nome,
                    "/escolas/grupos/create/responsavel/" + grupo?.link
                  )
                }
              )
              }
            </div>

            <EscolaGruposFormActionComponent revisao={flRevisao} rotaAnterior={'/escolas/grupos/create/step-2'} />
          </div>
        </div>
      </Form>
      <EscolaGruposFormFecharComponent />
    </div>
  )
}