import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCampanhaContext } from '../../context/CampanhaContext';

import StepsCreate from './steps-create';
import './style.css';

const CampanhasCreateStep1: React.FunctionComponent = () => {

  const { campanha, setCampanha, revisao } = useCampanhaContext();

  const { TextArea } = Input;
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const onFinish = () => {
    history.push("/campanhas/create/step-2");
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={16} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">
        <div className="user-row">
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3 id="taxas-h3">Insira o nome e descrição da campanha</h3>
            {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
            <Form.Item name="nome" id="nome" rules={[{ required: true, message: 'Nome da Campanha!' }]}>
              <div className="input-box">
                <p>Nome da Campanha</p>
                <Input value={campanha.codigo} id="nome" onChange={e => setCampanha({ ...campanha, codigo: e.target.value })} />
              </div>
            </Form.Item>
            <Form.Item name="descricao" id="descricao" rules={[{ required: true, message: 'Descreva o funcionamento da campanha!' }]}>
              <div className="input-box">
                <p>Descreva o funcionamento da campanha</p>
                <TextArea rows={4} value={campanha.descricao} id="descricao" onChange={e => setCampanha({ ...campanha, descricao: e.target.value })} />
              </div>
            </Form.Item>
            <div className="botoes">
              {revisao ?
                <Link to="/campanhas/create/step-6">
                  <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
                </Link>
                : <>
                  <Link to="/campanhas">
                    <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                  </Link>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" id="proximo" className="button-primary" style={{ width: 345 }}>Próximo</Button>
                  </Form.Item>
                </>
              }
            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa campanha?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/campanhas'
      />
    </div>
  )

}

export default CampanhasCreateStep1;