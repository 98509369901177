import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from '@material-ui/lab';
import { Button, Col, Input, Row } from 'antd';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImgProgramacao from '../../assets/img/box-curso/programacao.svg';
import { Avatar } from '../../components/Avatar';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';

import { ITurmaDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useAlunoService } from '../../services/aluno.service';
import { useMatriculaService } from '../../services/matricula.service';
import { useNotificationService } from '../../services/notification.service';
import { useTurmaService } from '../../services/turma.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import './style.css';

export const AlunosTransferenciaTurma: React.FunctionComponent = () => {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const turmaService = useTurmaService();
	const { detalheMatricula, setMatriculas } = useAlunoContext();
	const history = useHistory();
	const [turmaSelected, setTurmaSelected] = useState<ITurmaDTO>();
	const [listTurma, setListTurma] = useState<PageableResponse<ITurmaFullDTO>>();
	const [turmaPredicate] = useState<Predicate>(new Predicate());
	const [descricao, setDescricao] = useState<string>("");
	const matriculaService = useMatriculaService();
	const notification = useNotificationService();
	const alunoService = useAlunoService();

	const isSelected = (id) => {
		return turmaSelected && turmaSelected.id === id;
	}

	const statusTurma = (turma: ITurmaFullDTO) => {
		if (!turma.ativo) {
			return (
				<div className="status-tranferencia-turma">
					<div className="dot-detalhe-turma-vermelho"></div>
					<p className='detalhe-turma-p-vermelho'>Inativo</p>
				</div>
			)
		}
		if (isBeforeDate(turma.dataInicio)) {
			return (
				<div className="status-tranferencia-turma">
					<div className="dot-detalhe-turma-standby"></div>
					<p className='detalhe-turma-p-standby'>Não Iniciado</p>
				</div>
			)
		}
		if (isDateBetween(turma.dataInicio, turma.dataFim)) {
			return (
				<div className="status-tranferencia-turma">
					<div className="dot-detalhe-turma-verde"></div>
					<p className='detalhe-turma-p-verde'>Em andamento</p>
				</div>
			)
		}
		if (isAfterDate(turma.dataFim)) {
			return (
				<div className="status-tranferencia-turma">
					<div className="dot-detalhe-turma-verde"></div>
					<p className='detalhe-turma-p-verde'>Concluído</p>
				</div>
			)
		}
		return null;
	}

	const fetchTurmas = () => {
		turmaPredicate.addOption('descricao', descricao, Operators.CONTAINS);
		turmaPredicate.addOption('unidade.id', unidade.id);
		turmaPredicate.addOption('ativo', true);
		turmaPredicate.addOption('id', detalheMatricula.turma.id, Operators.NOT_EQUAL);

		if (detalheMatricula?.turmaCurriculoNegocioId) {
			detalheMatricula?.turmaCurso?.id && turmaPredicate.addMultiOption('curso.id', detalheMatricula?.turmaCurso?.id, Operators.NOT_EQUAL);

			if (detalheMatricula?.turmaCurriculoNegocioTransferencia) {
				turmaPredicate.addMultiOption('curriculo.negocio.transferencia', true);
			} else {
				turmaPredicate.addMultiOption('curriculo.negocio.transferencia', false);
				turmaPredicate.addMultiOption('curriculo.negocio.id', detalheMatricula?.turmaCurriculoNegocioId, Operators.EQUAL);
			}
		}

		setIsGlobalLoading(true);
		return turmaService.findFull(turmaPredicate)
			.then((response) => setListTurma(response.data))
			.finally(() => setIsGlobalLoading(false));
	}

	useEffect(() => {
		if (!unidade.id) return;
		fetchTurmas();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id]);

	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		turmaPredicate.setPage(page);
		fetchTurmas();
	}

	useEffect(() => {

		const typingTimeout = setTimeout(() => {
			setIsGlobalLoading(true);
			fetchTurmas().finally(() => setIsGlobalLoading(false));
		}, 1500);

		return () => clearTimeout(typingTimeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descricao]);

	const handleSubmit = async () => {
		setIsGlobalLoading(true);
		const turmaAtual = detalheMatricula.turma;

		try {
			detalheMatricula.turma = turmaSelected;

			await matriculaService.trocarTurma(detalheMatricula);

			const response = await matriculaService.findListMatriculasAluno(detalheMatricula?.aluno?.id);
			setMatriculas(response.data);

			notification({
				description: 'Aluno transferido!',
				type: 'success',
				message: 'Sucesso!',
			});

			history.push(`/alunos/detalhe-matricula/${detalheMatricula.id}`);
		} catch (error) {
			detalheMatricula.turma = turmaAtual;
			console.error(error);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	const goTo = () => {
		history.push(`/alunos/detalhe-matricula/${detalheMatricula?.id}`);
	}

	const divDadosTurma = (turma) => {

		return (
			<div onClick={() => setTurmaSelected(turma)} className={isSelected(turma?.id) ? "turma-selected" : "turma"}>
				<Unicons.UilEllipsisH size="20" className="mais" color="#FFFFFF" />
				<h3>{turma.descricao}</h3>
				<h6>ID #{turma?.id}</h6>
				{statusTurma(turma)}
				<div className="dados">
					<div className="col">
						<h4>Instrutor</h4>
						<p>{turma?.unidadePessoa?.pessoaNome}</p>
					</div>
					<div className="col">
						<h4>Alunos</h4>
						<p>{turma?.matriculas?.length ?? 0}</p>
					</div>
				</div>
				<h4>
					{moment(turma.dataInicio).format('DD/MM/YYYY')}
					- {moment(turma.dataFim).format('DD/MM/YYYY')}
					({moment(turma.dataInicio).to(turma.dataFim, true)})
				</h4>
				<div className="boxes">{
					turma.horarios.map(
						horario => (
							<div className="box" key={horario.id}>
								<h6>{horario.diaSemana}</h6>
								<p>{horario.horaInicio.slice(0, -3)} - {horario.horaFim.slice(0, -3)}</p>
							</div>
						)
					)
				}</div>
			</div>
		)

	}

	const colsTurmas = () => {

		if (listTurma?.content.length > 0)

			return listTurma?.content.map(
				(turma, index) => (<Col key={index} style={{ marginBottom: 20 }} span={12}>{divDadosTurma(turma)}</Col>)
			);

		else

			return (<Col style={{ marginBottom: 20 }} span={12}>Turma não encontrada</Col>);

	}

	const divListaTurmas = () => {

		return (
			<div className="matriculas" style={{ width: '100%' }}>
				<Row className='turmas'>{colsTurmas()}</Row>
			</div>
		)

	}

	const divSelecaoTurma = () => {

		return (
			<div className="bloco-1" style={{ zIndex: 2 }}>
				<h5>Selecione uma turma</h5>
				<div className="filtros" style={{ width: '100%' }}>
					<div className="input-text" style={{ width: '100%' }}>

						<Input
							onChange={(e) => setDescricao(e.target.value)}
							placeholder="Buscar pelo nome"
							prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />

					</div>
				</div>
				{divListaTurmas()}
				<div className="botoes">

					<Button
						onClick={() => handleSubmit()}
						className="button-primary"
						style={{ width: 345 }}
						disabled={!(turmaSelected?.id)}>

						Transferir
					</Button>
				</div>
				<div className="paginacao">
					<Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={listTurma ? listTurma.pageable.pageNumber + 1 : 0} count={listTurma?.content ? listTurma.totalPages : 0} />
				</div>
			</div>
		)
	}

	return (
		<div className="container-fondo">
			<NewMenu selecionado={3} />
			<div className='menu-box-revisao-float'>
				<div className="row-revisao" style={{ width: 350 }}>
					<div className="box-revisao">
						<h3>Transferência de turma</h3>
						<div className="dados">

							<Avatar arquivo={detalheMatricula.aluno.pessoa?.foto} size={115} />
							<h2 id="title">{detalheMatricula.aluno.pessoa?.nome || 'Nome do Aluno'}</h2>
							<h6 style={{ marginBottom: 0 }}>Observações</h6>
							<span>{detalheMatricula.aluno.observacao || 'N/A'}</span>
							<br /><br />
							<div className="box-aluno" id="turma-detalhe">
								<div className="avatar-turma">
									<img src={ImgProgramacao} alt="" height={115} />
									<Tags tipo={detalheMatricula?.turmaCurso?.descricao} />
									<p><h4>{detalheMatricula.turma.descricao}</h4></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="franquia" style={{ backgroundColor: '#F5F5F9', left: 0 }}>
				<div style={{ width: '31%', minWidth: 650 }} className="space1"></div>
				<div className="franquia-row">
					{divSelecaoTurma()}
				</div>
			</div>
			<div className="botao-voltar-revisao" style={{ top: '14%' }}>
				<Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />} onClick={goTo}>Voltar</Button>
			</div>
		</div>
	);

};
