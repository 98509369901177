import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ICursoUnidadeCleanDTO,
	ICursoUnidadeDTO,
	ICursoUnidadeFullDTO
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useCursoUnidadeService = () => {
	const resourceURL = 'curso-unidade';

	const patchCustom = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<ICursoUnidadeDTO>> =>
		http.patch<ICursoUnidadeDTO>(resourceURL + `/custom/${id}`, dto);

	const findByUnidadeAndCurso = (idUnidade: number, idCurso: number): Promise<AxiosResponse<ICursoUnidadeDTO>> => {
		return http.get<ICursoUnidadeDTO>(resourceURL + `/findby-unidade-curso?idUnidade=${idUnidade}&idCurso=${idCurso}`)
	}

	return {
		...baseFind<ICursoUnidadeCleanDTO, ICursoUnidadeDTO, ICursoUnidadeFullDTO>(resourceURL),
		...baseFindList<ICursoUnidadeCleanDTO, ICursoUnidadeDTO, ICursoUnidadeFullDTO>(resourceURL),
		...baseFindById<ICursoUnidadeCleanDTO, ICursoUnidadeDTO, ICursoUnidadeFullDTO>(resourceURL),
		createFull: (body): Promise<IAxiosResponseCustom<ICursoUnidadeCleanDTO>> => http.post(`${resourceURL}/full`, body),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<ICursoUnidadeCleanDTO>> => http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<ICursoUnidadeCleanDTO>> => http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		patchCustom,
		findByUnidadeAndCurso,
	};
};
