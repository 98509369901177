import './course-card.styles.scss';
import { IMatriculaCustomDTO } from '../../../../../../models/happy-code-api.model';
import useUpgradeIsAvailable from '../../../../hooks/use-upgrade-is-available.hook';
import useCourseCard from './use-course-card.hook';

interface CourseCardProps {
	course: IMatriculaCustomDTO;
}

export default function CourseCard({ course }: CourseCardProps) {
	const code =
		course?.turmaCurso?.descricao || course?.turmaCurriculoNegocioDescricao;
	const classroom = course.turma?.descricao;
	const idFinanceiro = course?.financeiro?.id;
	const idFinanceiroOrigem = course?.financeiro?.financeiroOrigem;

	const { courseCardBorderLeftColor, navigateToDetalheMatriculaPage } =
		useCourseCard();

	const { upgradeButtonVisible } = useUpgradeIsAvailable({
		idFinanceiro,
	});

	const getCourseCardBorderLeftColor =
		courseCardBorderLeftColor[code] || 'var(--primary-color)';

	return (
		<div
			onClick={() => navigateToDetalheMatriculaPage(course)}
			className={'course-card-wrapper'}
			style={{ borderLeft: `8px solid ${getCourseCardBorderLeftColor}` }}
		>
			<div className={'course-card-wrapper__course-infos'}>
				<div className={'course-infos__financeiro-code'}>
					<p>#{idFinanceiro}</p>
					<p>{code}</p>
				</div>
				<div className={'course-infos__financeiro-code'}>
					{idFinanceiroOrigem && (
						<small className={'course-infos__classroom-text'}>
							Contrato Anterior: #{idFinanceiroOrigem}{' '}
						</small>
					)}
					<small className={'course-infos__classroom-text'}>{classroom}</small>
				</div>
			</div>

			{upgradeButtonVisible && (
				<div className={'course-card-wrapper__upgrade-indicator'}>
					Upgrade disponível
				</div>
			)}
		</div>
	);
}
