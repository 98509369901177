export enum TipoDocumentoEnum {
	COF = 1, // 1	COF	COF
	CTT = 2, // 2	CTT	Contrato
	CNDRFE = 3,
	CNP = 4,
	CDFCTCEF = 5,
	CPT = 6,
	ACR = 7,
	DIR = 8,
	LVB = 9,
	DIA = 10,
	DIRP = 11,
	CPR = 12,
	DUI = 13,
	RCC = 14,
	CRE = 15,
	CDC = 16,
	CRC = 17,
	LCA = 18,
	IRA = 19,
	CPJ = 20,
	ACS = 21,
	SPC = 22,
	ADC = 23

	// 3	CNDRFE	Certidão Negativa de Débito da Receita Federal e Estadual
	// 4	CNP	Certidão Negativa da Prefeitura
	// 5	CDFCTCEF	"Certidão dos Distribuidores Fiscal, Civel, Trabalhista e
	// Criminal nas esferas Estadual e Federal"
	// 6	CPT	Certidão de Protesto
	// 7	ACR	Antecedentes criminais
	// 8	DIR	Declaração de Imposto de Renda dos últimos 2 anos
	// 9	LVB	Laudo de vistoria dos bombeiros
	// 10	DIA	Documento de identificação do aluno
	// 11	DIRP	Documento de identificação do responsável principal
	// 12	CPR	Comprovante de residência
	// 13	DUI	Direito de uso de imagem
}
