import { useMemo, useState } from 'react';
import { ITaxaCobrancaPagarMeDTO } from '../../../models/happy-code-api.model';

const useCheckoutSedeState = () => {
	const tiposPagamento = useMemo(
		() => ({			
			PIX: {
				idPagamento: 'PIX',
				dsPagamento: 'PIX',
			},
		}),
		[]
	);

	const [cobranca, setCobranca] = useState<ITaxaCobrancaPagarMeDTO>();

	return {
		tiposPagamento,
		cobranca,
		setCobranca,		
	};
};

export default useCheckoutSedeState;
