import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Operators } from '../../../enum/operators.enum';
import { IPessoaCustomDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { usePessoaService } from '../../../services/pessoa.service';

const useCpfSearch = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const pessoaService = usePessoaService();
    const [pessoaResponsavelList, setPessoaResponsavelList] = useState<IPessoaCustomDTO[]>([]);
    const [cpf, setCPF] = useState<string>('');

    const findResponsavel = async () => {
        if (!cpf) {
            setPessoaResponsavelList([]);
            return;
        }

        setIsGlobalLoading(true);
        try {
            const predicate = new Predicate();
            predicate.addOption('ativo', true);
            predicate.addOption('cpf', cpf, Operators.CONTAINS);
            const response = await pessoaService.findCustom(predicate);
            setPessoaResponsavelList(response.data.content);
        } finally {
            setIsGlobalLoading(false);
        }
    };

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            findResponsavel();
        }, 1500);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpf]);

    return { pessoaResponsavelList, cpf, setCPF };
};

export default useCpfSearch;
