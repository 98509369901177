import { useEffect } from 'react';
import { ILogCleanDTO } from '../models/happy-code-api.model';
import { useLogService } from '../services/log.service';

interface UseRegisterLogProps {
	descricaoEvento: string;
	descricaoTipo: string;
}

export default function useRegisterLog({
	descricaoEvento,
	descricaoTipo,
}: UseRegisterLogProps) {
	const { registraLog } = useLogService();

	const log: ILogCleanDTO = {
		descricaoEvento,
		descricaoTipo,
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	};

	useEffect(() => {
		const controller = new AbortController();

		registraLog(log);

		return () => controller.abort();
	}, []);
}
