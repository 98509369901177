import * as Unicons from '@iconscout/react-unicons';

import './style.css';

interface TInputs {
  open: boolean;
  onClose: () => void;
}

const ModalHistorico: React.FunctionComponent<TInputs> = ({ open, onClose }: TInputs) => {

  if (!open) return null

  return (
    <div className="modalContainer">
      <div className="modalBox">
        <button onClick={onClose} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
        <h3>Histórico de documentos</h3>
        {/* <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor tristique nec lorem ut duis.</h6> */}
        <div className="tabela">
          <div className="titles">
            <div className="item" style={{ width: "15%" }}>
              <span>DATA</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "85%" }}>
              <span>NOME DE ARQUIVO</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
          </div>
          <div className="registros-arquivos" style={{ marginTop: "-15px" }}>
            <div className="data">
              <span>17/01/2021</span>
            </div>
            <div className="arquivo">
              <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
              <p>nomedoarquivo.pdf</p>
            </div>
            <div className="botao">
              <button>
                <Unicons.UilDownloadAlt size="15" color="#FFFFFF" className="icon-alt" />
                Baixar Arquivo
              </button>
            </div>
          </div>
          <div className="registros-arquivos">
            <div className="data">
              <span>17/01/2021</span>
            </div>
            <div className="arquivo">
              <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
              <p>nomedoarquivo.pdf</p>
            </div>
            <div className="botao">
              <button>
                <Unicons.UilDownloadAlt size="15" color="#FFFFFF" className="icon-alt" />
                Baixar Arquivo
              </button>
            </div>
          </div>
          <div className="registros-arquivos">
            <div className="data">
              <span>17/01/2021</span>
            </div>
            <div className="arquivo">
              <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
              <p>nomedoarquivo.pdf</p>
            </div>
            <div className="botao">
              <button>
                <Unicons.UilDownloadAlt size="15" color="#FFFFFF" className="icon-alt" />
                Baixar Arquivo
              </button>
            </div>
          </div>
          <div className="registros-arquivos">
            <div className="data">
              <span>17/01/2021</span>
            </div>
            <div className="arquivo">
              <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
              <p>nomedoarquivo.pdf</p>
            </div>
            <div className="botao">
              <button>
                <Unicons.UilDownloadAlt size="15" color="#FFFFFF" className="icon-alt" />
                Baixar Arquivo
              </button>
            </div>
          </div>
          <div className="registros-arquivos">
            <div className="data">
              <span>17/01/2021</span>
            </div>
            <div className="arquivo">
              <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
              <p>nomedoarquivo.pdf</p>
            </div>
            <div className="botao">
              <button>
                <Unicons.UilDownloadAlt size="15" color="#FFFFFF" className="icon-alt" />
                Baixar Arquivo
              </button>
            </div>
          </div>
        </div>
        <div className="carregar">
          <button className="btn-cancel">Carregar mais</button>
        </div>
      </div>
    </div>
  );
}

export default ModalHistorico;