import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ILeadEscolaEstagioFullDTO, ILeadEstagioFullDTO } from '../../../../../models/happy-code-api.model';
import { useCrmEscolaContext } from '../../../context/crm-escola.context';
import useTipoRetornoState from '../../../selectors/tipo-retorno.state';

const useFiltroState = () => {
	const [strFiltro, setStrFiltro] = useState('');
	const [filtrosGerais, setFiltrosGerais] = useState('');
	const { refreshLeads,
		state,
		boards, setCrmEscolaContext, } = useCrmEscolaContext();
	const [filtrosRetorno, setFiltrosRetorno] = useState({
		sem: {
			value: true,
		},
		atrasado: {
			value: true,
		},
		hoje: {
			value: true,
		},
		futuro: {
			value: true,
		},
		descartado: {
			value: false,
		},
	});

	const { getTipoRetorno } = useTipoRetornoState();

	const totalResultadosFiltrados = useMemo(() => {
		if (!boards) {
			return 0;
		}

		const lengths = boards?.map((board) => board?.leads?.length);
		return lengths.reduce((somaParcial, a) => somaParcial + a, 0);
	}, [boards]);

	const totalResultados = useMemo(() => {
		if (!state?.boardsTemp) {
			return 0;
		}

		const lengths = state?.boardsTemp.map((board) => board?.leads?.length);
		return lengths.reduce((somaParcial, a) => somaParcial + a, 0);
	}, [state]);

	const todosOsFiltros = (
		filtrosRetornoParam,
		strFiltroParam,
		filtrosGerais
	) => {
		setCrmEscolaContext((crmContext) => {
			const boards = JSON.parse(
				JSON.stringify(state?.boardsTemp)
			) as ILeadEscolaEstagioFullDTO[];
			let novosBoards = [...boards];
			novosBoards = novosBoards.map((board) => {
				if (board?.leads) {
					let leads = [];

					Object.keys(filtrosRetornoParam || filtrosRetorno)
						.filter((k, v) => {
							return (filtrosRetornoParam || filtrosRetorno)[k].value;
						})
						.map((k, v) => {
							const leadsFiltrados = board.leads.filter((lead) => {
								const type = getTipoRetorno(lead);
								return k === type;
							});
							leads = leads.concat(leadsFiltrados);
						});
					board.leads = leads;
				}
				return board;
			});

			novosBoards = novosBoards.map((board) => {
				if (board?.leads) {
					let leads = board.leads;

					if (filtrosGerais?.status?.id) {
						leads = leads.filter(
							(lead) => lead.status.id === filtrosGerais?.status?.id
						);
					}

					if (filtrosGerais?.campanha?.id) {
						leads = leads.filter(
							(lead) => lead?.campanha?.id === filtrosGerais?.campanha?.id
						);
					}

					// if (filtrosGerais?.tipo?.id) {
					// 	leads = leads.filter(
					// 		(lead) => lead.tipo.id === filtrosGerais?.tipo?.id
					// 	);
					// }

					if (filtrosGerais?.origem?.id) {
						leads = leads.filter(
							(lead) => lead?.origem?.id === filtrosGerais?.origem?.id
						);
					}

					if (filtrosGerais?.unidade?.id) {
						leads = leads.filter(
							(lead) => lead.unidade.id === filtrosGerais?.unidade?.id
						);
					}

					if (
						//filtrosGerais?.filtroPor &&
						filtrosGerais?.dataInicio &&
						filtrosGerais?.dataFim
					) {
						const { dataInicio, dataFim } = filtrosGerais;
						const dataInicioM = moment(dataInicio).startOf('day');
						const dataFimM = moment(dataFim).endOf('day');

						if (filtrosGerais?.filtroPor === 'data-retorno') {
							leads = leads.filter((lead) => {
								return (
									lead.dataProximoRetorno &&
									moment(lead.dataProximoRetorno)
										.isBetween(dataInicioM, dataFimM)
								);
							});
						}

						if (filtrosGerais?.filtroPor === 'data-criacao') {
							leads = leads.filter((lead) => {
								return (
									lead.dataCriacao &&
									moment(lead.dataCriacao)
										.isBetween(dataInicioM, dataFimM)
								);
							});
						}

						if (!filtrosGerais?.filtroPor) {
							leads = leads.filter((lead) => {
								return (
									lead.dataCriacao &&
									moment(lead.dataCriacao)
										.isBetween(dataInicioM, dataFimM)
								);
							});
						}
					}

					if (filtrosGerais?.avaliacaoLead) {
						leads = leads.filter((lead) => {
							return (
								lead.avaliacaoLead === parseInt(filtrosGerais.avaliacaoLead)
							);
						});
					}

					board.leads = leads;
				}
				return board;
			});

			return { ...crmContext, boards: novosBoards };
		});
	};

	useEffect(() => {
		if (state?.flRefresh) {
			todosOsFiltros(filtrosRetorno, strFiltro, filtrosGerais);
			setCrmEscolaContext((crmContext) => ({ ...crmContext, flRefresh: false }));
		}
	}, [state]);

	const onChangeFiltro = (e) => {
		const valorFiltro = e.target.value ?? '';
		setStrFiltro(valorFiltro);
		if (valorFiltro?.length >= 3 || valorFiltro?.length === 0) {
			refreshLeads(valorFiltro);
		}

		todosOsFiltros(filtrosRetorno, valorFiltro, filtrosGerais);
	};

	const onChangeFiltroTipoRetorno = (tipo: string, value: boolean) => {
		filtrosRetorno[tipo].value = value;
		setFiltrosRetorno(filtrosRetorno);
		todosOsFiltros(filtrosRetorno, strFiltro, filtrosGerais);
	};

	const onChangeFiltroGeral = (filtrosGerais) => {
		setFiltrosGerais(filtrosGerais);
		todosOsFiltros(filtrosRetorno, strFiltro, filtrosGerais);
	};

	return {
		strFiltro,
		setStrFiltro,
		onChangeFiltro,
		totalResultados,
		totalResultadosFiltrados,
		onChangeFiltroTipoRetorno,
		onChangeFiltroGeral,
	};
};

export default useFiltroState;
