import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ICursoFullDTO,
	INegocioCleanDTO,
	INegocioDTO,
	INegocioFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useNegocioService = () => {
	const resourceURL = 'negocio';

	const replicarOferta = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<INegocioFullDTO>> =>
		http.patch<INegocioFullDTO>(resourceURL + `/replicar-oferta/${id}`, dto);

	return {
		...baseFindList<INegocioCleanDTO, INegocioDTO, INegocioFullDTO>(
			resourceURL
		),
		...baseFind<INegocioCleanDTO, INegocioDTO, INegocioFullDTO>(resourceURL),
		...baseUpdate<INegocioCleanDTO, INegocioDTO, INegocioFullDTO>(resourceURL),
		...baseCreate<INegocioCleanDTO, INegocioDTO, INegocioFullDTO>(resourceURL),
		...baseFind<INegocioCleanDTO, INegocioDTO, INegocioFullDTO>(resourceURL),
		...baseFindById<INegocioCleanDTO, INegocioDTO, INegocioFullDTO>(resourceURL),
		replicarOferta,
	};
};
