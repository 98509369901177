/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Unicons from '@iconscout/react-unicons';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { IArquivoCleanDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { token } from '../../util/store';
import './style.css';

interface Props {
    title: string;
    subtitle: string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFileList: (arquivos: UploadFile<IArquivoCleanDTO>[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRemoveFile: (arquivo: any) => void;
    defaultFileList: any;
    statusDocumento: any;
    maxCount?: number;
    notRemove?: boolean;
}

export const BoxDocumentos: React.FunctionComponent<Props> = ({ title, subtitle, defaultFileList, statusDocumento, maxCount, setFileList, onRemoveFile, notRemove }: Props) => {
    const notification = useNotificationService();

    const onChange = ({ file, fileList }: UploadChangeParam) => {
        const { status } = file;
        if (status === 'done') {
            notification({ description: `${file.name} file uploaded successfully.`, type: 'success', message: 'Sucesso!' });
            setFileList(fileList);
        } else if (status === 'error') {
            notification({ description: `${file.name} file upload failed.`, type: 'error', message: 'Falha!' });
        } else if (status === 'removed' && notRemove) {
            notification({ description: `${file.name} no authorization to remove file.`, type: 'error', message: 'Error!' });
        } else if (status === 'removed') {
            onRemoveFile(file);
        }
    }

    return (
        <div className="box-documentos">
            <div className="franja"></div>
            <div className="documentos">
                <div className="descricao">
                    <div>
                        <h6>{title}</h6>
                        <span>{subtitle}</span>
                    </div>
                    {statusDocumento}
                </div>
                <div className="upload-component">
                    <Upload key={'upload-box-test'} defaultFileList={defaultFileList} maxCount={maxCount ?? null} headers={{ "Authorization": `Bearer ${token()}` }} action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`} onChange={onChange}>
                        <button className="button-upload" id="upload">
                            <Unicons.UilCloudUpload size="20" color="var(--primary-color)" className="icon-upload" />
                            Fazer Upload
                        </button>
                    </Upload>
                </div>
            </div>
        </div>
    )
}