import { useEffect, useState } from "react";
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { Predicate } from "../../../../../models/predicate.model";
import { useLeadEscolaStatusService } from "../../../../../services/lead-escola-status.service";
import { useLeadEscolaService } from "../../../../../services/lead-escola.service";
import { useNotificationService } from '../../../../../services/notification.service';
import { useCrmEscolaContext } from "../../../context/crm-escola.context";

const useNovoLeadStep6ComponentState = () => {

  const { leadParaCadastrar,
    setLeadParaCadastrar,
    onFecharModal,
    setIndexCadastro,
    indexCadastro,
    unidadeUsuarioLogado,
    limparLeadsParaCadastrar,
    refresh, } = useCrmEscolaContext();
  const [leadStatus, setLeadStatus] = useState<any>();
  const [isModalConfirmacao, setIsModalConfirmacao] = useState<boolean>(false);
  const leadEscolaStatusService = useLeadEscolaStatusService();
  const leadEscolaService = useLeadEscolaService();
  const [bodyFinal, setBodyFinal] = useState();
  const { setIsGlobalLoading } = useGlobalContext();
  const notification = useNotificationService();

  const fetchStatus = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    return leadEscolaStatusService.findList().then((response) => {
      setLeadStatus(response.data);
    })
  }

  useEffect(() => {
    fetchStatus();
  }, [])



  const onFinish = (form) => {

    setIsModalConfirmacao(true);
    const body = {
      ...leadParaCadastrar, ...form,
      novo: !leadParaCadastrar?.id,
      unidade: unidadeUsuarioLogado,
      status: form?.status?.id ? form?.status : null,
      campanha: leadParaCadastrar?.campanha?.id ? leadParaCadastrar?.campanha : null,
    };

    setLeadParaCadastrar({ ...body });
    setBodyFinal({ ...body });

  }

  const onCloseModalConfirmacao = () => {
    setIsModalConfirmacao(false);
  }

  const onCadastrarLead = () => {
    if (isModalConfirmacao && bodyFinal) {
      setIsGlobalLoading(true);
      leadEscolaService.create(bodyFinal).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setBodyFinal(null);
          setIsModalConfirmacao(false);
          limparLeadsParaCadastrar();
          refresh();
          notification({ description: 'Lead cadastrado!', type: 'success', message: 'Sucesso!' });

        }
      }).catch((e) => {
        notification({ description: 'Houve um problema! ' + e?.message, type: 'error', message: 'Falha no cadastro do lead!' });
      }).finally(() => {
        setIsGlobalLoading(false);
      });
    }

  }

  return {
    leadParaCadastrar,
    onFinish,
    onFecharModal,
    leadStatus,
    isModalConfirmacao,
    onCloseModalConfirmacao,
    onCadastrarLead,
    setIsModalConfirmacao,

  }
}

export default useNovoLeadStep6ComponentState;
