import { ISituacaoCursoCleanDTO, ISituacaoCursoDTO, ISituacaoCursoFullDTO } from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useSituacaoCursoService = () => {
	const resourceURL = '/situacao-curso';

	return {
		...baseFind<ISituacaoCursoCleanDTO, ISituacaoCursoDTO, ISituacaoCursoFullDTO>(resourceURL),
		...baseFindList<ISituacaoCursoCleanDTO, ISituacaoCursoDTO, ISituacaoCursoFullDTO>(resourceURL),
		...baseFindById<ISituacaoCursoCleanDTO, ISituacaoCursoDTO, ISituacaoCursoFullDTO>(resourceURL),
		...baseCreate<ISituacaoCursoCleanDTO, ISituacaoCursoDTO, ISituacaoCursoFullDTO>(resourceURL),
		...baseUpdate<ISituacaoCursoCleanDTO, ISituacaoCursoDTO, ISituacaoCursoFullDTO>(resourceURL),

		// createFull: (body): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.post(`${resourceURL}/full`, body),
		// patch: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		// patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),

	};
};
