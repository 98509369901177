import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useHoraSedeContext } from '../../context/HoraSedeContext';
import { useHoraSedeService } from '../../services/hora-sede.service';
import ModalCreate from '../ModalCreate/index';
import './style.css';

export const MenuDetalheHoraSede: React.FunctionComponent = () => {
  const { horaSede, removeHoraSede, setRevisao } = useHoraSedeContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);
  const horaSedeService = useHoraSedeService();

  const save = () => {
    setIsGlobalLoading(true)
    if (horaSede.id) {
      horaSedeService.patchFull(horaSede)
        .then(() => {
          removeHoraSede();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    } else {
      horaSedeService.createCustom(horaSede)
        .then(() => {
          removeHoraSede();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    }
  }

  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1">
        <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
        <h1 id="title">{"Configuração de split"}</h1>
        {horaSede?.id ?
          <>
            <Link to={`/hora-oferta/${horaSede.id}`}>
              <Button id="salvar" style={{ width: 265, marginBottom: 5 }} className="button-primary">Alterar Oferta por Hub</Button>
            </Link>
            <Button id="salvar" style={{ width: 265 }} className="button-primary" onClick={() => save()}>Salvar</Button>
          </>
          : <Button id="salvar" style={{ width: 265 }} className="button-primary" onClick={() => save()}>Salvar</Button>}
      </div>
      <ModalCreate
        open={isOpen}
        //onClose={() => setIsOpen(false)}
        title="Configuração salva com sucesso!"
        textButton1="Criar outro configuração"
        textButton2="Voltar a página inicial"
        styleButton1="button-line"
        styleButton2="button-primary"
        link1="/hora/create/step-1"
        link2="/hora"
      />
    </div>
  );
}