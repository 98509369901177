

const useValidationAntdHook = () => {
  const validateNumber = (rule:any, value:string, callback:(value?: string) => void) => {
		if (value === undefined || value === '') {
			callback();
			return;
		}
		if (/^\d+$/.test(value)) {
			callback();
		} else {
			callback("Por favor, informe apenas números");
		}
	};

	const validateLength = (rule, value:string, callback: (value?: string) => void, length:number) => {
		if (value === undefined || value === '') {
			callback();
			return;
		}
		if (value.length <= length) {
			callback();
		} else {
			callback( `Por favor, informe no máximo de ${length} digitos`);
		}
	}

  return {
    validateNumber,
    validateLength
  }
}

export default useValidationAntdHook