import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { ILogCleanDTO, IProdutoEscolaCleanDTO, IProdutoEscolaDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';

import { useLogService } from '../../services/log.service';
import { useProdutoEscolaService } from '../../services/produto-escola.service';
import FiltrosProdutoEscola from './filtros-curriculo';
import ItemListProdutoEscola from './item-lista';
import './style.css';


const ProdutoEscola: React.FunctionComponent = () => {
  const [data, setData] = useState<PageableResponse<IProdutoEscolaDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const produtoEscolaService = useProdutoEscolaService();
  const { setIsGlobalLoading } = useGlobalContext();

  const history = useHistory();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso Produto Escola",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    logService.registraLog(log)
  }, [])

  const findProdutoEscola = () => {
    setIsGlobalLoading(true);
    produtoEscolaService.find(predicate)
      .then(({ data }) => setData(data))
      .finally(() => setIsGlobalLoading(false));
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findProdutoEscola();
  }

  const goToAdd = () => {
    history.push("/produto-escola/create/step-1");
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findProdutoEscola();
  }

  const handleprodutoEscola = (produtoEscola: IProdutoEscolaCleanDTO, state: boolean) => {
    produtoEscola.ativo = state;
    setIsGlobalLoading(true);
    produtoEscolaService.patch(produtoEscola)
      .then(() => findProdutoEscola())
      .finally(() => setIsGlobalLoading(false));
  }

  return (
    <div className="container">
      <NewMenu selecionado={122} />
      <div className="dashboard">
        <div className="box-title">
          <h3 className="title">Produtos Escolas ({data?.totalElements})</h3>
          <Button className="button-primary" id="criar-campanha" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Produto</Button>
        </div>
        <FiltrosProdutoEscola findProdutoEscola={findProdutoEscola} predicate={predicate} />
        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '15%' }}>
              <span>Nome</span>
              <div className="arrows" onClick={() => ordenar('descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>ID</span>
              <div className="arrows" onClick={() => ordenar('id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>Faixa Etaria</span>
              <div className="arrows" onClick={() => ordenar('faixaEtariaIni')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>ANO ESCOLAR</span>
              <div className="arrows" onClick={() => ordenar('anoEscolar')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>Negócio</span>
              <div className="arrows" onClick={() => ordenar('negocio.descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>             
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>Código</span>
              <div className="arrows" onClick={() => ordenar('codigo')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
              </div>
            <div className="item" style={{ width: '5%' }}></div>
          </div>
          <div className="tabela-body">
            {data && data.content.map((produtoEscola, index) => (
              <ItemListProdutoEscola handleprodutoEscola={(produtoEscola, state) => handleprodutoEscola(produtoEscola, state)} produtoEscola={produtoEscola} key={index} />
            ))}
          </div>
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProdutoEscola;