import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import '../../../../global.css';
import { IConectaDTO, ILogCleanDTO, IWebinarDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useAcademyConectaService } from '../../../../services/academy-conceta';
import { useLogService } from '../../../../services/log.service';
import FiltrosConecta from './filtros-conecta';
import AcademyConectaItemList from './item-lista';
import './style.css';

export const AcademyConecta: React.FunctionComponent = () => {
    const [data, setData] = useState<PageableResponse<IConectaDTO>>();
    const [predicate] = useState<Predicate>(new Predicate());
    const conectaService = useAcademyConectaService();
    const { setIsGlobalLoading } = useGlobalContext();
    const history = useHistory();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso gestao conecta academy",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findConecta = () => {
        setIsGlobalLoading(true);
        conectaService.find(predicate)
            .then(({ data }) => {
                setData(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        findConecta();
    }

    const ordenar = (campo) => {
        predicate.addSort(campo);
        findConecta();
    }

    const goToAdd = () => {
        history.push(`/academy/conectas/create`);
    }

    return (
        <div className="container">
            <NewMenu selecionado={33} />
            <div className="dashboard">
                <div className="box-title">
                    <h3 className="title">Conectas ({data?.totalElements})</h3>
                    <Button className="button-primary" id="criar-curso" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Conecta</Button>
                </div>
                <FiltrosConecta findConecta={findConecta} predicate={predicate} />
                <div className="tabela">
                    <div className="titles-users">

                        <div className="item" style={{ width: '30%' }}>
                            <span>CONECTA</span>
                            <div className="arrows" onClick={() => ordenar('nome')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>

                        <div className="item" style={{ width: '20%' }}>
                            <span>ID</span>
                            <div className="arrows" onClick={() => ordenar('id')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>

                        <div className="item" style={{ width: '50%' }}></div>
                    </div>

                    <div className="tabela-body">
                        {data && data.content.map((conecta, index) => (
                            <AcademyConectaItemList conecta={conecta} key={index} />
                        ))}
                    </div>

                    <div className="paginacao">
                        <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                    </div>
                </div>
            </div>
        </div>
    );
}
