import http from "../helpers/http-common";
import { IMetaCleanDTO, IMetaDTO, IMetaFullDTO } from "../models/happy-code-api.model";
import { IAxiosResponseCustom } from "../models/http.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

interface Props {
	id?: number;
}

export const useMetaService = () => {
	const resourceURL = '/meta';

	const adicionarMetaUnidade = (body): Promise<IAxiosResponseCustom> =>
		http.post(`${resourceURL}/adicionar-meta-unidade`, body);

	const criarMetaUnidades = (body): Promise<IAxiosResponseCustom> =>
		http.post(`${resourceURL}/criar-meta-unidades`, body);

	return {
		...baseFind<IMetaCleanDTO, IMetaDTO, IMetaFullDTO>(resourceURL),
		...baseFindList<IMetaCleanDTO, IMetaDTO, IMetaFullDTO>(resourceURL),
		...baseFindById<IMetaCleanDTO, IMetaDTO, IMetaFullDTO>(resourceURL),
		...baseCreate<IMetaCleanDTO, IMetaDTO, IMetaFullDTO>(resourceURL),
		...baseUpdate<IMetaCleanDTO, IMetaDTO, IMetaFullDTO>(resourceURL),
		delete: (body: Props) => http.delete(`${resourceURL}/${body.id}`),
		adicionarMetaUnidade,
		criarMetaUnidades
	};
};
