const formatTime = (time: number) => time.toString().padStart(2, '0');

export const renderMinutesToHour = (minutes: number): string => {
	const hours = Math.floor(minutes / 60);
	const minutesRemaining = minutes % 60;

	const formattedHour = formatTime(hours);
	const formattedMinutes = formatTime(minutesRemaining);

	return `${formattedHour}:${formattedMinutes}`;
};
