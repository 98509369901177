import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import { INegocioUnidadeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import FiltrosCursoUnidade from './filtros-curso-unidade';
import ItemListNegocio from './item-lista';

export const HoraUnidade: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const [data, setData] = useState<PageableResponse<INegocioUnidadeDTO>>();
    const [predicate] = useState<Predicate>(new Predicate());
    const negocioUnidade = useNegocioUnidadeService();
    const { idNegocio } = useParams<any>();

    const findMetaTipo = () => {
        setIsGlobalLoading(true);
        predicate.addOption('negocio.id', idNegocio)
        return negocioUnidade.find(predicate)
            .then(({ data }) => {
                setData(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    const ordenar = (campo) => {
        predicate.addSort(campo);
        findMetaTipo();
    }
    
    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        findMetaTipo();
    }

    return (
        <div className="container">
            <NewMenu selecionado={12} />
            <div className="dashboard">
                <div className="box-title">
                    <h3 className="title">{data?.content?.[0]?.negocio.descricao} ({data?.totalElements})</h3>
                </div>
                <FiltrosCursoUnidade findMetaTipo={findMetaTipo} predicate={predicate} />
                <div className="tabela">
                    <div className="titles-users">
                        <div className="item" style={{ width: '30%' }}>
                            <span>NOME</span>
                            <div className="arrows" onClick={() => ordenar('unidade.descricao')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '30%' }}>
                            <span>SPLIT MATRÍCULA</span>
                            <div className="arrows" onClick={() => ordenar('valorSplit')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '30%' }}>
                            <span>SPLIT REMATRÍCULA</span>
                            <div className="arrows" onClick={() => ordenar('valorSplit')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '40%' }}></div>
                    </div>
                    <div className="tabela-body">
                        {data && data.content.map((negocioUnidade, index) => (
                            <ItemListNegocio negocioUnidade={negocioUnidade} key={index} />
                        ))}
                    </div>
                    <div className="paginacao">
                        <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                    </div>
                </div>
            </div>
        </div>
    );
}
