import { useCallback } from 'react';
import { ICobrancaDTO } from '../../../../../models/happy-code-api.model';
import { TipoStatusCobranca } from '../../../../../enum/tipoStatusCobranca';

interface UseCalcularSaldoContratoAtualProps {
	cobrancasByIdFinanceiro: ICobrancaDTO[];
}

export default function useCalcularSaldoContratoAtual({ cobrancasByIdFinanceiro }: UseCalcularSaldoContratoAtualProps) {
	const calcularSaldoContratoAtual = useCallback(() => {
		if (!cobrancasByIdFinanceiro.length) return;

		const filterUnpaidCharges = cobrancasByIdFinanceiro.filter((cobranca) => cobranca.statusCobranca.codigo !== TipoStatusCobranca.CODIGO_PAGO);

		return filterUnpaidCharges.reduce((acc, cobranca) => acc + cobranca.valorCobranca, 0);
	}, [cobrancasByIdFinanceiro]);

	return {
		calcularSaldoContratoAtual,
	};
}
