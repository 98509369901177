import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Progress, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { IClusterCleanDTO, ICursoEscolaFranquiaDTO, IEscolaCleanDTO, IGrupoCleanDTO, IPaisCleanDTO, IUnidadeCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useClusterService } from '../../services/cluster.service';
import { useCursoEscolaFranquiaService } from '../../services/curso-escola-franquia.service';
import { useEscolaService } from '../../services/escola.service';
import { useGrupoService } from '../../services/grupo.service';
import { usePaisService } from '../../services/pais.service';
import { useUnidadeService } from '../../services/unidade.service';
import StepsCreate from './steps-create';
import './style.css';

export const CursosCreateStep3: React.FunctionComponent = () => {

	const { curso, setCurso, revisao } = useCursoContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const [paises, setPaises] = useState<IPaisCleanDTO[]>([]);
	const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>([]);
	const [clusters, setClusters] = useState<IClusterCleanDTO[]>([]);
	const [escolas, setEscolas] = useState<IEscolaCleanDTO[]>([]);
	const [grupos, setGrupos] = useState<IGrupoCleanDTO[]>([]);
	const [cursoEscolaFranquia, setCursoEscolaFranquia] = useState<ICursoEscolaFranquiaDTO>();
	const [grupoEscolaFranquia, setGrupoEscolaFranquia] = useState<IGrupoCleanDTO>();

	const [isOpen, setIsOpen] = useState(false);

	const [value, setValue] = useState(0);
	const [valueEscola, setValueEscola] = useState(0);
	const [predicate] = useState<Predicate>(new Predicate());
	const [liberaEscola, setLiberaEscola] = useState(0);

	const paisService = usePaisService();
	const unidadeService = useUnidadeService();
	const clusterService = useClusterService();
	const escolaService = useEscolaService();
	const grupoService = useGrupoService();
	const cursoEscolaFranquiaService = useCursoEscolaFranquiaService();

	const fetchInicialSchollData = (id: number) => {
		setIsGlobalLoading(true);
		predicate.addOption('grupo.id', id)
		escolaService.findCleanList(predicate)
			.then(({ data }) => setEscolas(data))
			.finally(() => setIsGlobalLoading(false));
	}

	const findCursoEscolaFranquia = () => {
		setIsGlobalLoading(true);
		if (curso.tipoCurso.id === 11) {
			cursoEscolaFranquiaService.findCustom(curso.id)
				.then(response => {
					setCursoEscolaFranquia(response.data),
						setValueEscola(1),
						setLiberaEscola(1),
						setCurso({ ...curso, cursoEscolaFranquiaCustomDTO: response.data })
				})
				.catch((e) => console.log(e))
				.finally(() => setIsGlobalLoading(false));
		}
	}

	const findGrupoEscola = () => {
		setIsGlobalLoading(true);
		grupoService.findCustom(cursoEscolaFranquia?.escola.id)
			.then((response) => setGrupoEscolaFranquia(response.data))
			.finally(() => setIsGlobalLoading(false));
	}

	const fetchInicialGroupData = () => {
		setIsGlobalLoading(true);
		grupoService.findCleanList()
			.then(({ data }) => setGrupos(data))
			.finally(() => setIsGlobalLoading(false));
	}

	const fetchInitialCountryData = useCallback(async () => {
		setIsGlobalLoading(true);
		paisService.findCleanList()
			.then(({ data }) => setPaises(data))
			.finally(() => setIsGlobalLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchInitialClusterData = useCallback(async () => {
		setIsGlobalLoading(true);
		clusterService.findCleanList()
			.then(({ data }) => setClusters(data))
			.finally(() => setIsGlobalLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchInitialUnidadeData = useCallback(async () => {
		setIsGlobalLoading(true);
		unidadeService.findCleanList()
			.then(({ data }) => setUnidades(data))
			.finally(() => setIsGlobalLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		switch (curso.nivelAcesso) {
			case 'PAIS':
				fetchInitialCountryData().finally(() => setValue(1));
				break;
			case 'CLUSTER':
				fetchInitialClusterData().finally(() => setValue(2));
				break;
			case 'FRANQUIA':
				fetchInitialUnidadeData().finally(() => setValue(3));
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (valueEscola === 1) {
			fetchInicialGroupData();
		}
		findCursoEscolaFranquia();
		if (cursoEscolaFranquia) {
			findGrupoEscola();
		}
		setIsGlobalLoading(false);
	}, [valueEscola]);

	function onChangePais(e: CheckboxChangeEvent, pais: IPaisCleanDTO) {
		e.preventDefault();
		e.stopPropagation();
		const { cursoPaises } = curso;
		if (e.target.checked) {
			cursoPaises.push({ id: null, ativo: true, paisId: pais.id, paisNome: pais.nome, paisSigla: pais.sigla });
			setCurso({ ...curso, cursoPaises })
		} else {
			setCurso({ ...curso, cursoPaises: cursoPaises.filter(tp => tp.paisId !== pais.id) })
		}
	}

	function onChangeCluster(e: CheckboxChangeEvent, cluster: IClusterCleanDTO) {
		e.preventDefault();
		e.stopPropagation();
		const { cursoClusters } = curso;
		if (e.target.checked) {
			cursoClusters.push({ id: null, ativo: true, clusterId: cluster.id, clusterCodigo: cluster.codigo, clusterDescricao: cluster.descricao });
			setCurso({ ...curso, cursoClusters: cursoClusters })
		} else {
			setCurso({ ...curso, cursoClusters: cursoClusters.filter(tp => tp.clusterId !== cluster.id) })
		}
	}

	const onUnidadesChange = (op) => {
		setCurso({ ...curso, cursoUnidades: op.map(o => ({ id: null, ativo: true, unidadeId: o.key, unidadeDescricao: o.value })) })
	}

	const onGrupoChange = (e) => {
		fetchInicialSchollData(e);
		setLiberaEscola(1);
	}

	const onSchollChange = (e) => {
		setCurso({ ...curso, cursoEscolaFranquiaCustomDTO: ({ id: curso.cursoEscolaFranquiaCustomDTO?.id, escolaId: e, cursoId: null, unidadeId: null }) })
	}

	const onChangeRadio = e => {
		const valueTmp = e.target.value;
		setValue(valueTmp);
		switch (valueTmp) {
			case 1:
				clusters.length === 0 && fetchInitialCountryData();
				setCurso({ ...curso, cursoClusters: [], cursoUnidades: [], nivelAcesso: 'PAIS' });
				break;
			case 2:
				clusters.length === 0 && fetchInitialClusterData();
				setCurso({ ...curso, cursoPaises: [], cursoUnidades: [], nivelAcesso: 'CLUSTER' });
				break;
			case 3:
				unidades.length === 0 && fetchInitialUnidadeData();
				setCurso({ ...curso, cursoClusters: [], cursoPaises: [], nivelAcesso: 'FRANQUIA' });
				break;
		}
	};

	const onChangeEscolaRadio = e => {
		setValueEscola(e.target.value);
		if (valueEscola === 1) {
			setCurso({ ...curso, cursoEscolaFranquiaCustomDTO: ({ id: curso.cursoEscolaFranquiaCustomDTO?.id, escolaId: null, cursoId: null, unidadeId: null }) })
		}
	}

	const optionsUnidade = unidades.map(u => <Select.Option key={u.id} value={u.descricao}>{u.descricao}</Select.Option>)
	const optionsGrupo = grupos.map(e => <Select.Option key={e.id} value={e.id}>{e.nomeFantasia}</Select.Option>)
	const optionsEscola = escolas.map(e => <Select.Option key={e.id} value={e.id}>{e.nomeFantasia}</Select.Option>)

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress className="progress-user" percent={60} strokeLinecap="square" showInfo={false} />
			</div>
			<StepsCreate current={2} />
			<div className="franquia">
				<div className="user-row">
					<h3 id="taxas-h3">Onde esse curso será criado?</h3>
					<Radio.Group onChange={onChangeRadio} value={value}>
						<div className="boxes">
							<div className={value === 1 ? "box-check-selected" : "box-check"}>
								<Radio id="radio-1" value={1} />
								<div className="box-text">
									<h6>Selecionar por</h6>
									<p>País</p>
								</div>
							</div>
							<div className={value === 2 ? "box-check-selected" : "box-check"}>
								<Radio id="radio-2" value={2} />
								<div className="box-text">
									<h6>Selecionar por</h6>
									<p>Cluster</p>
								</div>
							</div>
							<div className={value === 3 ? "box-check-selected" : "box-check"}>
								<Radio id="radio-3" value={3} />
								<div className="box-text">
									<h6>Selecionar por</h6>
									<p>Franquias</p>
								</div>
							</div>
						</div>
					</Radio.Group>
					{value === 1 && <><h4>Selecione o(s) países desejados</h4>
						{paises?.map((pais) => {
							return (
								<div
									className="box-check"
									style={{ marginBottom: 7 }}
									key={pais.id}
								>
									<Checkbox id={pais.sigla}
										onChange={(e) => onChangePais(e, pais)}
										checked={curso.cursoPaises.some(p => p.paisId === pais.id)}
									/>
									<p>{pais.nome}</p>
								</div>
							);
						})}</>}
					{value === 2 && <><h4>Selecione o(s) Cluster desejados</h4>
						{clusters?.map((cluster) => {
							return (
								<div
									className="box-check"
									style={{ marginBottom: 7 }}
									key={cluster.id}
								>
									<Checkbox id={"cluster-" + cluster.id}
										onChange={(e) => onChangeCluster(e, cluster)}
										checked={curso.cursoClusters.some(p => p.clusterId === cluster.id)}
									/>
									<p>{cluster.codigo}</p>
								</div>
							);
						})}</>}
					{value === 3 && <><h4>Selecione a(s) Franquia desejada</h4>
						<div className="box-select">
							<h5>Franquias</h5>
							<Select
								mode="multiple"
								style={{ width: '100%' }}
								placeholder="Selecione a(s) Franquia(s) desejada(s)"
								defaultValue={curso.cursoUnidades.map(c => (c.unidadeDescricao))}
								onChange={(value, op) => onUnidadesChange(op)}
							>
								{optionsUnidade}
							</Select>
						</div></>}
					{curso.tipoCurso.id === 11 ?
						<>
							<h4>Este é um curso de escola?</h4>
							<div >
								<Radio.Group onChange={onChangeEscolaRadio} value={valueEscola} style={{ display: 'flex', flexDirection: 'row' }}>
									<div className={"box-check"} style={{ marginRight: '10px' }}>
										<Radio checked={valueEscola === 1 ? true : false} id="radio-4" value={1} />
										<div >
											<p>Sim</p>
										</div>
									</div>
									<div className={"box-check"}>
										<Radio checked={valueEscola === 1 ? true : false} id="radio-5" value={2} />
										<div >
											<p>Não</p>
										</div>
									</div>
								</Radio.Group>
							</div>
						</> : null}
					{valueEscola === 1 && <><h4>Selecione o grupo desejado</h4>
						<div className="box-select">
							<h5>Grupos</h5>
							<Select
								style={{ width: '100%' }}
								placeholder="Selecione o Grupo desejada"
								defaultValue={cursoEscolaFranquia?.grupo.nomeFantasia}
								onChange={(op) => onGrupoChange(op)}
							>
								{optionsGrupo}
							</Select>
						</div></>}

					{liberaEscola === 1 && valueEscola === 1 && <><h4>Selecione a Escola desejada</h4>
						<div className="box-select">
							<h5>Escolas</h5>
							<Select
								style={{ width: '100%' }}
								placeholder="Selecione a Escolas desejada"
								defaultValue={cursoEscolaFranquia?.escola.descricao}
								onChange={(op) => onSchollChange(op)}
							>
								{optionsEscola}
							</Select>
						</div></>}
					<div className="botoes">
						{revisao ?
							<Link to="/cursos/create/step-6">
								<Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
							</Link>
							: <>
								<Link to="/cursos/create/step-2">
									<Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
								</Link>
								<Link to="/cursos/create/step-4">
									<Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
								</Link>
							</>
						}
					</div>
				</div>
			</div>
			<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title='Você deseja cancelar a criação deste curso?'
				subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
				textButton1='Não'
				textButton2='Sim, Cancelar'
				styleButton1='button-line'
				styleButton2='button-danger'
				link2='/cursos'
			/>
		</div>

	);
}