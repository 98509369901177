import * as Unicons from '@iconscout/react-unicons';
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
// import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import '../style.css';
import StepsCreate from './steps-create';
import useValidationAntdHook from '../useValidationAntdHook';

const FranquiaCreateBanco: React.FunctionComponent = () => {
	const { unidade, setUnidade, revisao } = useUnidadeContext();
	// const { setIsGlobalLoading } = useGlobalContext();
	const history = useHistory();

	const { validateNumber, validateLength } = useValidationAntdHook();

	const [form] = Form.useForm();

	useEffect(() => {
		const { banco, agencia, digitoAgencia, conta, digitoConta } = unidade;
		form.setFieldsValue({
			banco,
			agencia,
			digitoAgencia,
			conta,
			digitoConta,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBancoFinish = (bancoCrud) => {
		const contextState = { ...unidade, ...bancoCrud };
		setUnidade(contextState);
		history.push('/franquias/create/step-10');
	};

	return (
		<div className="container">
			<Header />
			<Progress />
			<StepsCreate current={6} />
			<div className="franquia" style={{ top: 70 }}>
				<Form form={form} name="control-hooks" onFinish={handleBancoFinish}>
					<div className="franquia-row">
						<h5>Dados bancários</h5>
						<div className="box-cidade" style={{ width: '80%' }}>
							<div className="input-franquia">
								<span>Número do banco</span>
								<Form.Item
									name="banco"
									rules={[
										{ required: true, message: 'Informe o número da conta ou 0' },
										{ validator: validateNumber },
										{ validator: (rule, value, callback) => validateLength(rule, value, callback, 3) },
									]}
								>
									<Input id="banco" className="input" maxLength={3} />
								</Form.Item>
							</div>
						</div>
						<div className="box-cidade" style={{ width: '80%', gap: 10 }}>
							<div className="select-franquia" style={{ flex: 1 }}>
								<span>Número da agência</span>
								<Form.Item
									name="agencia"
									rules={[
										{ required: true, message: 'Insira o número da agência ou 0' },
										{ validator: validateNumber },
										{ validator: (rule, value, callback) => validateLength(rule, value, callback, 4) },
									]}
								>
									<Input id="agencia" className="input" maxLength={4} />
								</Form.Item>
							</div>
							<div className="input-franquia" style={{ width: '30%' }}>
								<span>Digito da agência</span>
								<Form.Item
									name="digitoAgencia"
									rules={[
										{ required: true, message: 'Insira o digito da agência ou 0' },
										{ validator: validateNumber },
										{ validator: (rule, value, callback) => validateLength(rule, value, callback, 1) },
									]}
								>
									<Input id="digitoAgencia" className="input" maxLength={1} />
								</Form.Item>
							</div>
						</div>
						<div className="box-cidade" style={{ width: '80%', gap: 10 }}>
							<div className="select-franquia" style={{ flex: 1 }}>
								<span>Número da conta</span>
								<Form.Item
									name="conta"
									rules={[
										{ required: true, message: 'Insira o número da conta ou 0' },
										{ validator: validateNumber },
										{ validator: (rule, value, callback) => validateLength(rule, value, callback, 13) },
									]}
								>
									<Input
										id="conta"
										// onChange={(e) => onCepChange(e.target.value)}
										className="input"
										maxLength={13}
									/>
								</Form.Item>
							</div>
							<div className="input-franquia" style={{ width: '30%' }}>
								<span>Dígito da conta</span>
								<Form.Item
									name="digitoConta"
									rules={[
										{ required: true, message: 'Insira o dígito da conta ou 0' },
										{ validator: validateNumber },
										{ validator: (rule, value, callback) => validateLength(rule, value, callback, 1) },
									]}
								>
									<Input id="digitoConta" className="input" maxLength={1} />
								</Form.Item>
							</div>
						</div>
						<div className="botoes" style={{ width: '80%' }}>
							{revisao ? (
								<>
									<Form.Item name="revisao" initialValue="1" />
									<Button type="primary" htmlType="submit" className="button-primary" id="revisao" style={{ width: 700 }}>
										Voltar para Revisão
									</Button>
								</>
							) : (
								<>
									<Link to="/franquias/create/step-9">
										<Button className="button-second" id="voltar" style={{ width: 345 }}>
											Voltar
										</Button>
									</Link>
									<button type="submit" className="button-primary" id="proximo" style={{ width: 345 }}>
										<span>Próximo</span>
									</button>
								</>
							)}
						</div>
					</div>
				</Form>
			</div>
			<Link to="/franquias">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
};

export default FranquiaCreateBanco;
