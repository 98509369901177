import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ICursoCleanDTO,
	ICursoDTO,
	ICursoFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useCursoService = () => {
	const resourceURL = 'curso';

	const replicarOferta = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<ICursoFullDTO>> =>
		http.patch<ICursoFullDTO>(resourceURL + `/replicar-oferta/${id}`, dto);

	return {
		...baseFind<ICursoCleanDTO, ICursoDTO, ICursoFullDTO>(resourceURL),
		...baseFindList<ICursoCleanDTO, ICursoDTO, ICursoFullDTO>(resourceURL),
		...baseFindById<ICursoCleanDTO, ICursoDTO, ICursoFullDTO>(resourceURL),
		createFull: (body): Promise<IAxiosResponseCustom<ICursoCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<ICursoCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<ICursoCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		replicarOferta
	};
};
