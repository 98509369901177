import {
	ILeadCampanhaCleanDTO,
	ILeadCampanhaDTO,
	ILeadCampanhaFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEscolaCampanhaService = () => {
	const resourceURL = 'campanha-lead-escola';

	return {
		...baseFindList<ILeadCampanhaCleanDTO, ILeadCampanhaDTO, ILeadCampanhaFullDTO>(resourceURL),
	};
};
