import {
	ILeadEstagioCleanDTO,
	ILeadEstagioDTO,
	ILeadEstagioFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEstagioService = () => {
	const resourceURL = 'lead-estagio';

	return {
		...baseFindList<ILeadEstagioCleanDTO, ILeadEstagioDTO, ILeadEstagioFullDTO>(resourceURL),
	};
};
