/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { AuthEnum } from '../enum/auth.enum';
import {
	ICategoriaConfessionalDTO,
	ICategoriaQuantidadeAlunosDTO,
	IEscolaPessoaTipoDTO,
	IGrupoContratoFullDTO,
	IGrupoContratoTipoDTO,
	IGrupoFullDTO,
	IGrupoPessoaDTO,
	IGrupoPessoaTipoDTO,
	IServicoAcademicoDTO,
	IServicoAcademicoFullDTO,
} from '../models/happy-code-api.model';
import { Predicate } from '../models/predicate.model';
import { useCategoriaConfessionalService } from '../services/categoria-confessional.service';
import { useCategoriaQuantidadeAlunosService } from '../services/categoria-quantidade-alunos.service';
import { useEscolaPessoaTipoService } from '../services/escola-pessoa-tipo.service';
import { useGrupoContratoTipoService } from '../services/grupo-contrato-tipo.service';
import { useGrupoPessoaTipoService } from '../services/grupo-pessoa-tipo.service';
import { useGrupoServicoAcademicoService } from '../services/grupo-servico-academico.service';
import { useGlobalContext } from './GlobalContext';

interface PropsEscolaContext {
	escolas: any[];
	estados: any[];
	grupos: any[];
	categoriasQuantidadeAlunos: ICategoriaQuantidadeAlunosDTO[];
	categoriasConfessionais: ICategoriaConfessionalDTO[];
	grupoPessoaTipos: IGrupoPessoaTipoDTO[];
	escolaPessoaTipos: IEscolaPessoaTipoDTO[];
	setGrupoParaCadastrar;
	setEscolaParaCadastrar;
	getCategoriaConfessionalById;
	getCategoriaQuantidadeAlunosById;
	escolaParaCadastrar: any;
	grupoParaCadastrar: IGrupoFullDTO;
	contratoParaCadastrar: IGrupoContratoFullDTO;
	servicosAcademicos: IServicoAcademicoFullDTO[];
	flRevisao: boolean;
	setFlRevisao;
	limparGrupoParaCadastrar: any;
	limparEscolaParaCadastrar: any;
	limparContratoParaCadastrar: any;
	grupoContratoTipos: IGrupoContratoTipoDTO[];
	getPessoaByCodigo: any;
	setContratoParaCadastrar: any;
	getContratoTiposById: any;
	fetchAll: any;
}

const DEFAULT_VALUE: PropsEscolaContext = {
	escolas: [],
	estados: [],
	grupos: [],
	grupoPessoaTipos: [],
	servicosAcademicos: [],
	categoriasQuantidadeAlunos: [],
	categoriasConfessionais: [],
	escolaParaCadastrar: {},
	grupoParaCadastrar: {} as any,
	contratoParaCadastrar: {} as any,
	grupoContratoTipos: {} as any,
	escolaPessoaTipos: {} as any,
	setGrupoParaCadastrar: () => {},
	setEscolaParaCadastrar: () => {},
	setContratoParaCadastrar: () => {},
	getCategoriaConfessionalById: () => {},
	getCategoriaQuantidadeAlunosById: () => {},
	flRevisao: false,
	setFlRevisao: () => {},
	limparGrupoParaCadastrar: () => {},
	limparEscolaParaCadastrar: () => {},
	limparContratoParaCadastrar: () => {},
	getPessoaByCodigo: () => {},
	getContratoTiposById: () => {},
	fetchAll: () => {},
};

const EscolaContext = createContext<PropsEscolaContext>(DEFAULT_VALUE);

export const EscolaContextProvider: React.FC = ({ children }: any) => {
	const [state, setState] = useState<PropsEscolaContext>(DEFAULT_VALUE);

	const setFlRevisao = (flRevisao) => {
		setState((state) => ({ ...state, flRevisao }));
	};

	const [grupoParaCadastrar, setGrupoParaCadastrar] = useState<any>();
	const [contratoParaCadastrar, setContratoParaCadastrar] = useState<any>();
	const [escolaParaCadastrar, setEscolaParaCadastrar] = useState<any>();
	const [categoriasQuantidadeAlunos, setCategoriasQuantidadeAlunos] =
		useState<ICategoriaQuantidadeAlunosDTO[]>();
	const [categoriasConfessionais, setCategoriasConfessionais] =
		useState<ICategoriaConfessionalDTO[]>();
	const [grupoPessoaTipos, setGrupoPessoaTipos] =
		useState<IGrupoPessoaTipoDTO[]>();
	const [escolaPessoaTipos, setEscolaPessoaTipos] =
		useState<IEscolaPessoaTipoDTO[]>();
	const [servicosAcademicos, setServicosAcademicos] =
		useState<IServicoAcademicoDTO[]>();
	const [grupoContratoTipos, setGrupoContratoTipos] =
		useState<IGrupoContratoTipoDTO[]>();
	const categoriaQuantidadeAlunosService =
		useCategoriaQuantidadeAlunosService();
	const categoriaConfessionalService = useCategoriaConfessionalService();
	const grupoPessoaTipoService = useGrupoPessoaTipoService();
	const escolaPessoaTipoService = useEscolaPessoaTipoService();
	const servicoAcademicoService = useGrupoServicoAcademicoService();
	const grupoContratoTipoService = useGrupoContratoTipoService();
	const { setIsGlobalLoading } = useGlobalContext();

	const fetchCategoriaQuantidadeAlunos = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		return categoriaQuantidadeAlunosService.findList(predicate);
	};

	const fetchCategoriaConfessional = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		return categoriaConfessionalService.findList(predicate);
	};

	const fetchGrupoPessoaTipo = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		return grupoPessoaTipoService.findList(predicate);
	};

	const fetchEscolaPessoaTipo = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		return escolaPessoaTipoService.findList(predicate);
	};

	const fetchServicosAcademico = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		return servicoAcademicoService.findList(predicate);
	};

	const fetchGrupoContrato = () => {
		setIsGlobalLoading(true);
		return grupoContratoTipoService
			.findList()
			.then((response) => {
				setGrupoContratoTipos(response.data);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const fetchAll = () => {
		setIsGlobalLoading(true);
		return fetchCategoriaQuantidadeAlunos()
			.then((response) => {
				setCategoriasQuantidadeAlunos(response.data);
				return fetchCategoriaConfessional();
			})
			.then((response) => {
				setCategoriasConfessionais(response.data);
				return fetchGrupoPessoaTipo();
			})
			.then((response) => {
				setGrupoPessoaTipos(response.data);
				return fetchGrupoContrato();
			})
			.then(() => {
				return fetchEscolaPessoaTipo();
			})
			.then((response) => {
				setEscolaPessoaTipos(response.data);
				return fetchServicosAcademico();
			})
			.then((response) => {
				setServicosAcademicos(response.data);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	useEffect(() => {
		if (localStorage.getItem(AuthEnum.USER_KEY)) fetchAll();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCategoriaConfessionalById = (id: number) => {
		return categoriasConfessionais?.find?.((item) => item.id === id);
	};

	const getCategoriaQuantidadeAlunosById = (id: number) => {
		return categoriasQuantidadeAlunos?.find?.((item) => item.id === id);
	};

	const getPessoaByCodigo = (codigo: string, pessoas: IGrupoPessoaDTO[]) => {
		return pessoas?.find?.((item) => item.tipo.codigo === codigo);
	};

	const getContratoTiposById = (id: number) => {
		return grupoContratoTipos?.find?.((item) => item.id === id);
	};

	const limparGrupoParaCadastrar = () => {
		setGrupoParaCadastrar(null);
	};

	const limparEscolaParaCadastrar = () => {
		setEscolaParaCadastrar(null);
	};

	const limparContratoParaCadastrar = () => {
		setContratoParaCadastrar(null);
	};

	return (
		<EscolaContext.Provider
			value={{
				...state,
				grupoParaCadastrar,
				contratoParaCadastrar,
				setGrupoParaCadastrar,
				setFlRevisao,
				escolaParaCadastrar,
				setEscolaParaCadastrar,
				categoriasQuantidadeAlunos,
				categoriasConfessionais,
				getCategoriaConfessionalById,
				getCategoriaQuantidadeAlunosById,
				grupoPessoaTipos,
				limparGrupoParaCadastrar,
				limparEscolaParaCadastrar,
				limparContratoParaCadastrar,
				getPessoaByCodigo,
				setContratoParaCadastrar,
				grupoContratoTipos,
				getContratoTiposById,
				escolaPessoaTipos,
				fetchAll,
				servicosAcademicos,
			}}
		>
			{children}
		</EscolaContext.Provider>
	);
};

export const useEscolaContext = (): PropsEscolaContext => {
	const context = useContext(EscolaContext);
	if (!context)
		throw new Error(
			'useEscolaContext must be used within a EscolaContextProvider'
		);
	return context;
};
