import "./styles.css";

interface Props {
    required?: boolean,
    msgError?: string,
    msgSuccess?: string
    styleDescription?: React.CSSProperties,
    style?: React.CSSProperties,
}

const Validation: React.FunctionComponent<Props> = ({ required, msgError, msgSuccess, styleDescription, style }: Props) => {
    return (
        <div className="box-validation" style={style}>
            {required && msgError ?
                <p style={styleDescription ? styleDescription : { color: "#FF0000" }}>{msgError}</p>
                : null}

            {required && msgSuccess ?
                <p style={styleDescription ? styleDescription : { color: "#008000" }}>{msgSuccess}</p>
                : null}
        </div>
    );
}

export default Validation;