import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import './_box-relatorio.scss';

interface BoxRelatorioProps {
	link: string;
	titulo: string;
}

export default function BoxRelatorio({ link, titulo }: BoxRelatorioProps) {
	return (
		<Link to={link} className="box-relatorio">
			<Unicons.UilAnalytics size="35" color={'var(--blue-transparent)'} />
			<p className="box-relatorio__texto">{titulo}</p>
		</Link>
	);
}
