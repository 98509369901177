import { DatePicker, Table } from 'antd';
import moment from 'moment';
// import exportFromJSON from 'export-from-json';
import { useEffect, useState } from 'react';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Predicate } from '../../../../models/predicate.model';
import { RelatorioMatriculaNovoDTO } from '../../../../models/relatorio.model';
import { useRelatorioService } from '../../../../services/relatorio.service';
import { ExportToExcel } from '../../../../util/exportToExcel';
import { getPeriodo } from '../../../../util/filtroRelatorio';
import MatriculaHubTableColumns from './matricula-hub-table-columns';
import { hoje } from '../../../../util/util';

const { RangePicker } = DatePicker;

function RelMatriculaNovo() {
	const service = useRelatorioService();
	const { setIsGlobalLoading, unidade } = useGlobalContext();

	const [data, setData] = useState<RelatorioMatriculaNovoDTO[]>();
	const [dataMatriculaInicio, setDataMatriculaInicio] = useState<moment.Moment>(moment(hoje()));
	const [dataMatriculaFim, setDataMatriculaFim] = useState<moment.Moment>(moment(hoje()));
	const [dataPagamentoInicio, setDataPagamentoInicio] =
		useState<moment.Moment>();
	const [dataPagamentoFim, setDataPagamentoFim] = useState<moment.Moment>();
	const [displayedData, setDisplayedData] = useState<
		RelatorioMatriculaNovoDTO[]
	>([]);

	useEffect(() => {
		if (unidade && unidade.id) {
			const predicate = new Predicate();
			setIsGlobalLoading(true);

			if (dataMatriculaInicio) {
				predicate.addOption(
					'dataMatriculaInicio',
					dataMatriculaInicio.toISOString(),
				);
				predicate.addOption('dataMatriculaFim', dataMatriculaFim.toISOString());
			}
			if (dataPagamentoInicio) {
				predicate.addOption(
					'dataPagamentoInicio',
					dataPagamentoInicio.toISOString(),
				);
				predicate.addOption('dataPagamentoFim', dataPagamentoFim.toISOString());
			}

			predicate.addOption('unidadeId', unidade.id);

			service.matriculasNovo(predicate).then(({ data }) => {
				setData(data);
				setDisplayedData(data);
				setIsGlobalLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		unidade,
		dataMatriculaInicio,
		dataMatriculaFim,
		dataPagamentoInicio,
		dataPagamentoFim,
	]);

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h3 className="title">Matrículas Realizadas</h3>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Período de Matrículas</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataMatriculaInicio(values && values[0]);
								setDataMatriculaFim(values && values[1]);
							}}
							value={[dataMatriculaInicio, dataMatriculaFim]}
							format={'DD-MM-YYYY'}
						/>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Data de Pagamento</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataPagamentoInicio(values && values[0]);
								setDataPagamentoFim(values && values[1]);
							}}
							value={getPeriodo(dataPagamentoInicio, dataPagamentoFim, hoje())}
							format={'DD-MM-YYYY'}
						/>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={displayedData}
							fileName={`Matriculas ` + hoje().toISOString()}
						/>
					</div>
				</div>
				<Table
					dataSource={data}
					columns={MatriculaHubTableColumns()}
					scroll={{ y: '65dvh' }}
					onChange={(_pagination, _filters, _sorter, extra) => {
						setDisplayedData(extra.currentDataSource);
					}}
				/>
			</div>
		</div>
	);
}

export default RelMatriculaNovo;
