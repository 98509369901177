import { useAlunoContext } from '../../context/AlunoContext';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useMatriculaService } from '../../services/matricula.service';
import { IMatriculaDTO } from '../../models/happy-code-api.model';

export default function useGetListOutrasMatriculas() {
	const { aluno } = useAlunoContext();
	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();

	const { findListOutrasMatriculas } = useMatriculaService();

	const [otherEnrollmentsData, setOtherEnrollmentsData] = useState<
		IMatriculaDTO[]
	>([]);

	const getListOutrasMatriculas = useCallback(async () => {
		if (!aluno.id || !idFinanceiro) return;

		await findListOutrasMatriculas(aluno.id, parseInt(idFinanceiro)).then(
			({ data }) => setOtherEnrollmentsData(data)
		);
	}, [aluno.id, idFinanceiro]);

	useEffect(() => {
		const controller = new AbortController();

		getListOutrasMatriculas();

		return () => controller.abort();
	}, [getListOutrasMatriculas]);

	return {
		otherEnrollmentsData,
	};
}
