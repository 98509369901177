
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ICursoAulaPadraoDTO } from '../../../../models/happy-code-api.model';
import { useAcademyAulaPadraoService } from '../../../../services/academy-aula-padrao';
import { useNotificationService } from '../../../../services/notification.service';
import { useGlobalContext } from '../../../../context/GlobalContext';

interface TItemLista {
    aula: ICursoAulaPadraoDTO;
}

const AcademyItemListAulaProdutoEscola = memo(({ aula }: TItemLista) => {
    const history = useHistory();
    const [ativo, setAtivo] = useState<boolean>();
    const aulaService = useAcademyAulaPadraoService();
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();
    const { idProdutoEscola } = useParams<any>();

    const goTo = () => {
        history.push(`/academy/aulas/produto-escolas/${idProdutoEscola}/create/${aula?.id}`)
    }

    useEffect(() => {
        setAtivo(aula.ativo);
    }, [aula.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a aula?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: aula?.descricao,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                aulaService.patch({ id: aula.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Aula alterada com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "40%" }}>
                <div className="nome">
                    <p>{aula.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <span>{aula.id}</span>
            </div>
            <div className="registro-opcao" style={{ width: "35%" }}>
                <Link to={`/academy/gestao-material/produto-escolas/${idProdutoEscola}/aulas/${aula?.id}/material`}>
                    <Button className="button-primary" id="criar-curso">
                        Ver material de apoio
                        <ArrowCircleRightOutlined style={{ marginLeft: 5 }} />
                    </Button>
                </Link>
            </div>
            <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>

        </div>
    )
})

AcademyItemListAulaProdutoEscola.displayName = 'AcademyItemListAulaProdutoEscola';


export default AcademyItemListAulaProdutoEscola;
