import { DatePicker, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';
import { ExportToExcel } from '../../../util/exportToExcel';

const { RangePicker } = DatePicker;

function RelFinanceiroSede() {
	const [dados, setData] = useState<[]>([]);
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [dataMatriculaInicio, setDataMatriculaInicio] =
		useState<moment.Moment>();
	const [dataMatriculaFim, setDataMatriculaFim] = useState<moment.Moment>();
	const service = useRelatorioService();

	useEffect(() => {
		const predicate = new Predicate();

		dataMatriculaInicio &&
			predicate.addOption(
				'dataMatriculaInicio',
				dataMatriculaInicio.toISOString()
			);
		dataMatriculaFim &&
			predicate.addOption('dataMatriculaFim', dataMatriculaFim.toISOString());

		service.financeirosSede(predicate).then(({ data }) => {
			setData(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataMatriculaFim, dataMatriculaInicio]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
	}, [dados]);

	const getPeriodo = (): any => {
		if (dataMatriculaInicio && dataMatriculaFim) {
			return [
				moment(dataMatriculaInicio, 'YYYY-MM-DD'),
				moment(dataMatriculaFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={18} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório Financeiro Sede
				</h1>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de matrículas</span>
						<br />
						<RangePicker
							value={getPeriodo()}
							onChange={(values) => {
								setDataMatriculaInicio(values && values[0]);
								setDataMatriculaFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel apiData={dados} fileName={'financeiros-sede'} />
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}

export default RelFinanceiroSede;
