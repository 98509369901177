/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from "react";
import { ICampanhaFullDTO } from "../models/happy-code-api.model";
import { getObject, removeKey, setObject } from "../util/store";

//Tipando as Props do contexto
interface PropsCampanhaContext {
  campanha: ICampanhaFullDTO;
  revisao: boolean,
  setCampanha: React.Dispatch<React.SetStateAction<ICampanhaFullDTO>>;
  setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
  removeCampanha: React.DispatchWithoutAction;
};

//Valor default do contexto
const DEFAULT_VALUE: PropsCampanhaContext = {
  campanha: {
    id: null,
    codigo: "",
    descricao: "",
    dataInicio: null,
    dataFim: null,
    percentualDesconto: 0,
    percentualDescontoMaterial: 0,
    nivelAcesso: null,
    ativo: true,
    financeiros: [],
    situacaoCampanha: {
      id: 3,
      codigo: "",
      descricao: "",
      ativo: true,
    },
    ofertas: [],
    campanhaClusters: [],
    campanhaPaises: [],
    campanhaUnidades: [],
    campanhaCursos: [],
    campanhaPlanoPagamentos: []
  },
  revisao: false,
  setCampanha: () => { }, //função de inicialização  
  setRevisao: () => { }, //função de inicialização  
  removeCampanha: () => { }, //função de inicialização  
};

//criando nosso contexto UserContext
const CampanhaContext = createContext<PropsCampanhaContext>(DEFAULT_VALUE);
const nameStorage = "@HappyCode/campanha"
const nameStorageRevisao = "@HappyCode/campanha/revisao"
// const nameStorageRevisao = "@HappyCode/campanha/revisao"
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const CampanhaContextProvider: React.FC = ({ children }: never) => {
  const [campanha, setCampanhaGlobal] = useState<ICampanhaFullDTO>(DEFAULT_VALUE.campanha);
  const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

  const setCampanha = (campanha) => {
    setCampanhaGlobal(campanha);
    setObject(nameStorage, campanha);
  }

  const setRevisao = (revisao) => {
    setRevisaoGlobal(revisao);
    setObject(nameStorageRevisao, revisao);
  }

  const removeCampanha = () => {
    setRevisao(false)
    setCampanhaGlobal(DEFAULT_VALUE.campanha);
    removeKey(nameStorage);
  }

  useEffect(() => {
    const campanha = getObject(nameStorage)
    if (campanha) {
      setCampanhaGlobal(campanha);
    }
    const revisao = getObject(nameStorageRevisao)
    if (revisao) {
      setRevisaoGlobal(revisao);
    }
  }, []);

  return (
    <CampanhaContext.Provider
      value={{
        campanha,
        revisao,
        setCampanha,
        setRevisao,
        removeCampanha
      }}>
      {children}
    </CampanhaContext.Provider>
  );
};

export const useCampanhaContext = (): PropsCampanhaContext => {
  const context = useContext(CampanhaContext);
  if (!context)
    throw new Error("useCampanhaContext must be used within a CampanhaContextProvider");
  return context;
};