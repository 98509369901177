import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress, Radio } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

import StepsCreate from './steps-create';
import './style.css';

const UserCreateStep5: React.FunctionComponent = () => {
    // const unidadeService = useUnidadeService();
    // const { setIsGlobalLoading } = useGlobalContext();
    // //const { usuario, setUsuario } = useUsuarioContext();
    // const [unidadeOptions, setUnidadeOptions] = useState<JSX.Element[]>();
    // const [unidadeDefaultValue, setUnidadeDefaultValue] = useState<string[]>();

    // const fetchInitialUnidadeData = async () => {
    //     setIsGlobalLoading(true);

    //     try {
    //         const { data: fetchedUnidadeList } = await unidadeService.findCleanList();

    //         if (fetchedUnidadeList) {
    //             const auxOptions: JSX.Element[] = [];
    //             fetchedUnidadeList.forEach((unidade) => {
    //                 auxOptions.push(
    //                     <Select.Option value={unidade.descricao} key={unidade.id}>
    //                         {unidade.descricao}
    //                     </Select.Option>
    //                 );
    //             });
    //             setUnidadeOptions(auxOptions);
    //         }
    //         setIsGlobalLoading(false);
    //     } catch (error) {
    //         setIsGlobalLoading(false);
    //         
    //     }
    // };

    // useEffect(() => {
    // 	fetchInitialUnidadeData();
    // 	const unidades = usuario?.usuarioUnidades;
    // 	if (unidades) {
    // 		setUnidadeDefaultValue(unidades);
    // 	}
    // }, []);

    // const handleSelectChange = (value: string[]) => {
    // 	const contextState = { ...usuario };
    // 	contextState.usuarioUnidades = value;
    // 	setUsuario(contextState);
    // };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // function tagRender(props: any) {
    //     const { label, closable, onClose } = props;
    //     const onPreventMouseDown = (event) => {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     };
    //     return (
    //         <Tag
    //             color="default"
    //             onMouseDown={onPreventMouseDown}
    //             closable={closable}
    //             onClose={onClose}
    //             style={{ marginRight: 3 }}
    //         >
    //             {label}
    //         </Tag>
    //     );
    // }

    return (
        <div className="container-fondo">
            <Header />
            <div className="progress">
                <Progress
                    className="progress-user"
                    percent={48}
                    strokeLinecap="square"
                    showInfo={false}
                />
            </div>
            <StepsCreate current={2} />
            <div className="franquia">
                <div className="user-row">
                    <h3>Quais franquias esse usuário terá acesso?</h3>
                    <div className="boxes">
                        <div className="box-check">
                            <Radio value={1} />
                            <div className="box-text">
                                <h6>Selecionar por</h6>
                                <p>País</p>
                            </div>
                        </div>
                        <div className="box-check">
                            <Radio value={2} />
                            <div className="box-text">
                                <h6>Selecionar por</h6>
                                <p>Cluster</p>
                            </div>
                        </div>
                        <div className="box-check-selected">
                            <Radio value={3} />
                            <div className="box-text">
                                <h6>Selecionar por</h6>
                                <p>Franquias</p>
                            </div>
                        </div>
                    </div>
                    <h4>Selecione a(s) franquias desejadas</h4>
                    {/* <div className="box-select">
                        <h5>Franquias</h5>
                        <Select
                            mode="multiple"
                            showArrow
                            tagRender={tagRender}
                            style={{ width: '100%' }}
                            //onChange={handleSelectChange}
                            value={unidadeDefaultValue}
                        >
                            {unidadeOptions}
                        </Select>
                    </div> */}
                    <div className="botoes">
                        <Link to="/usuarios/create/step-4">
                            <Button className="button-second" style={{ width: 345 }}>
                                Voltar
                            </Button>
                        </Link>
                        <Link to="/usuarios/create/step-6">
                            <Button className="button-primary" style={{ width: 345 }}>
                                Próximo
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <Link to="/usuarios">
                <div className="fechar">
                    <Unicons.UilTimes size="20" color="#353F49" />
                </div>
            </Link>
        </div>
    );
}

export default UserCreateStep5;