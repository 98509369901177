/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { DatePicker, Form, Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { GrupoPessoaTipoEnum } from '../../enum/grupo-pessoa-tipo.enum';

import { IEnderecoCleanDTO, IPessoaBasicoDTO, IPessoaCleanDTO, IPessoaCustomDTO, IPessoaDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { usePessoaService } from '../../services/pessoa.service';
import './style.css';
import { ContatoEnum } from '../../enum/contato.enum';
import { formatCpf, formatMoney, formatPhone, formatRG, formattedDate, toMoment } from '../../util/format';
import { Predicate } from '../../models/predicate.model';
import { useLogradouroService } from '../../services/logradouro.service';
import { useUnidadeContext } from '../../context/UnidadeContext';

interface TInputs {
  open: boolean;
  onClose: () => void;
  modoLGPD?: boolean;
  pessoa?: IPessoaCleanDTO;
  tipo?: string;
}

export const ModalUserSocio: React.FunctionComponent<TInputs> = ({ open, onClose, modoLGPD, pessoa, tipo }: TInputs) => {
  const pessoaService = usePessoaService();
  const { setIsGlobalLoading } = useGlobalContext()
  const { unidade, setUnidade } = useUnidadeContext();
  const asterisco = (<span style={{ color: "red" }}>*</span>);

  const [pessoaId, setPessoaId] = useState<number>();
  const [pessoaNome, setPessoaNome] = useState<string>();
  const [pessoaDataNascimento, setPessoaDataNascimento] = useState<Date>();
  const [pessoaCPF, setPessoaCPF] = useState<string>();
  const [pessoaCelular, setPessoaCelular] = useState<string>();
  const [pessoaEmailPessoal, setPessoaEmailPessoal] = useState<string>();
  const [pessoaEmailProfissional, setPessoaEmailProfissional] = useState<string>();
  const [pessoaRG, setPessoaRG] = useState<string>();
  const [newPessoa, setNewPessoa] = useState<IPessoaCustomDTO>();
  const [endereco, setEndereco] = useState<IEnderecoCleanDTO>();
  const logradouroService = useLogradouroService();
  const notification = useNotificationService();

  const onCepChange = (cep: string) => {
    if (cep.length !== 8) {
      return;
    }
    setIsGlobalLoading(true);
    const predicateLogradouro = new Predicate(10);
    predicateLogradouro.addOption('cep', cep);
    logradouroService.findCustom(predicateLogradouro)
      .then(({ data }) => {
        if (data && data.length > 0) {
          const logradouro = data[0];

          const enderecoLocal = {
            ...endereco, cep: logradouro.cep,
            bairro: logradouro.bairroNome,
            cidade: logradouro.cidadeNome,
            logradouro: logradouro.nomeCompleto,
            nomeUF: logradouro.cidadeEstadoNome,
            paisId: logradouro.cidadeEstadoPaisId
          };

          setNewPessoa({ ...newPessoa, endereco: enderecoLocal });
        } else {
          notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
        }
      })
      .finally(() => setIsGlobalLoading(false));
  }

  useEffect(() => {
    if (pessoaId) {

      pessoaService.findByIdCustom(pessoa.id).then(
        ({ data }) => {

          setPessoaNome(data.nome);
          setPessoaDataNascimento(data.dataNascimento);
          setPessoaCPF(data.cpf);
          setPessoaEmailPessoal(data.email);

          const contatosEmail = data.contatos.filter(cont => cont.tipoContatoId === ContatoEnum.EPF);

          if (contatosEmail.length > 0)
            setPessoaEmailProfissional(contatosEmail[0].descricao);

          const contatosTel = data.contatos.filter(cont => cont.tipoContatoId === ContatoEnum.CEL);

          if (contatosTel.length > 0)
            setPessoaCelular(contatosTel[0].descricao);

        }
      );

    }
  }, [pessoaId])

  useEffect(() => {

    if (open)
      setPessoaId(pessoa?.id);

  }, [])

  const salvar = () => {


    setIsGlobalLoading(true);
    if (pessoaId) {

      pessoaService.patch({ id: pessoaId, newPessoa }).then(
        () => {
          onClose();
        }
      ).finally(() => setIsGlobalLoading(false));

    } else {
      pessoaService.create(newPessoa)
        .then(() => {
          onClose();
        }).finally(() => setIsGlobalLoading(false));

    }
  }

  const adicionar = (idTipo: number, contato: string) => {
    const { contatos } = newPessoa;
    const contatosFiltrados = contatos?.filter(c => c.tipoContatoId !== idTipo) || [];
    contatosFiltrados?.push({
      descricao: contato, tipoContatoId: idTipo,
      id: null,
      codigo: '',
      tipoContatoCodigo: '',
      ativo: false
    });
    setNewPessoa({ ...newPessoa, contatos: contatosFiltrados });
  }

  if (!open) return null;
  
  return (
    <div className="modalContainer">
      <Form className="user-row" name="control-hooks">
        <div className="modalBox" style={{ height: '900px' }}>
          <button onClick={onClose} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
          <h3>{pessoaId ? "Edição de usuário" : "Criação Novo Usuário"}</h3>
          <div className="inputModal">
            <div><span>Nome completo {asterisco}</span></div>
            <Input
              id="nome"
              className="input"
              value={newPessoa?.nome}
              onChange={(e) => setNewPessoa({ ...newPessoa, nome: e.target.value })} />
          </div>
          <div className="inputModal">
            <span>CPF {asterisco}</span>

            <MaskedInput
              id="cpf"
              className="input"
              mask="111.111.111-11"
              maxLength={11}
              placeholder="000.000.000-00"
              value={formatCpf(newPessoa?.cpf)}
              onChange={(e) => setNewPessoa({ ...newPessoa, cpf: e.target.value })} />

          </div>
          <div className="inputModal">
            <span>Telefone {asterisco}</span>

            <Input
              id="telefone"
              className="input"
              value={newPessoa?.contatos?.find(c => c.tipoContatoId === ContatoEnum.CEL)?.descricao}
              onChange={(e) => adicionar(ContatoEnum.CEL, e.target.value)} />

          </div>

          <div className="inputModal">
            <span>E-mail pessoal {asterisco}</span>

            <Input
              id="emailPessoal"
              className="input"
              value={newPessoa?.contatos?.find(c => c.tipoContatoId === ContatoEnum.EPS)?.descricao}
              onChange={(e) => adicionar(ContatoEnum.EPS, e.target.value)} />

          </div>
          <div className="inputModal">
            <span>{tipo === "socio" ? "E-mail profissional" : "E-mail profissional (usado para cobranças)"} {asterisco}</span>

            <Input
              id="emailProfissional"
              className="input"
              value={newPessoa?.contatos?.find(c => c.tipoContatoId === ContatoEnum.EPF)?.descricao}
              onChange={(e) => adicionar(ContatoEnum.EPF, e.target.value)} />

          </div>
          <div className="inputModal">
            <span>RG </span>
            <Input
              id="cpf"
              className="input"
              maxLength={11}
              placeholder="Informe o rg"
              value={formatRG(newPessoa?.rg)}
              onChange={(e) => setNewPessoa({ ...newPessoa, rg: e.target.value })} />
          </div>
          <div className="inputModal">
            <h6>Data de Nascimento</h6>
            <span>
              <DatePicker
                value={toMoment(newPessoa?.dataNascimento)}
                onChange={(e) => setNewPessoa({ ...newPessoa, dataNascimento: e.toDate() })}
                placeholder="Data de nascimento"
                suffixIcon={null}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
              />
            </span>
          </div>
          <div className="inputModal">
            <h6>CEP</h6>
            <span>
              <Input
                id="cep"
                onChange={e => onCepChange(e.target.value)}
                className="input"
                maxLength={8}
              />
            </span>
          </div>
          <div className="inputModal">
            <h6>Número </h6>
            <span>
              <Input
                id="cep"
                onChange={e => setEndereco({ ...endereco, numero: e.target.value })}
                className="input"
              />
            </span>
          </div>
          <div className="botoes">
            <button onClick={onClose} className="btn-cancel" id="cancelar">Cancelar</button>
            <button onClick={salvar} className="btn-make" id="criar-usuario">Salvar usuário</button>
          </div>
        </div>
      </Form>
    </div>
  );
}
