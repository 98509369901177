import { useMemo } from "react";
import { useCrmEscolaContext } from "../../context/crm-escola.context";
import './step-cadastro.component.styles.scss';

const StepCadastroComponent = ({ qtItens = 5 }: any) => {

  const { indexCadastro } = useCrmEscolaContext();

  const render = useMemo(() => {
    const array = [];
    for (let x = 0; x < qtItens; x++) {
      array.push(<span className={(x + 1) === indexCadastro ? 'dot-cadastro-lead-ativo' : 'dot-cadastro-lead'}>●</span>)
    }

    return array;
  }, [qtItens, indexCadastro])

  return <div className="flex col">
    <span className="step-cadastro-label">{`Etapas (${indexCadastro}/${qtItens})`}</span>
    <div>
      {render}
    </div>
  </div>
}

export default StepCadastroComponent;
