import { AxiosResponse } from "axios";
import http from "../helpers/http-common";
import { IDmPilarCleanDTO, IDmPilarDTO, IDmPilarFullDTO, IDmPilarTotalIndicadorDTO } from "../models/happy-code-api.model";
import { baseFindList } from "./base/base-find-list.service";

export const useDmPilarService = () => {
  const resourceURL = 'dmpilar';

  const findPilarIndicadoresPorUnidade = (idUnidade: number): Promise<AxiosResponse<IDmPilarTotalIndicadorDTO[]>> => {
    return http.get(`${resourceURL}/unidade/${idUnidade}/total-indicador`);
  };

  const findHappyCoins = (idUnidade: number): Promise<AxiosResponse<number>> => {
    return http.get(`${resourceURL}/unidade/${idUnidade}/happy-coins`);
  };

  return {
    ...baseFindList<IDmPilarCleanDTO, IDmPilarDTO, IDmPilarFullDTO>(resourceURL),
    findPilarIndicadoresPorUnidade,
    findHappyCoins,
  };
}