import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
    IUsuarioProdutoStemplayCleanDTO,
    IUsuarioProdutoStemplayDTO,
    IUsuarioProdutoStemplayFullDTO
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useUsuarioProdutoStemplayService = () => {
    const resourceURL = '/usuario-stemplay';

    const findProdutoStemplayRotina = (): Promise<AxiosResponse<void>> =>
        http.get<void>(resourceURL + `/professor-produto`);

    return {
        ...baseFindById<IUsuarioProdutoStemplayCleanDTO, IUsuarioProdutoStemplayDTO, IUsuarioProdutoStemplayFullDTO>(resourceURL),
        ...baseFind<IUsuarioProdutoStemplayCleanDTO, IUsuarioProdutoStemplayDTO, IUsuarioProdutoStemplayFullDTO>(resourceURL),
        ...baseFindList<IUsuarioProdutoStemplayCleanDTO, IUsuarioProdutoStemplayDTO, IUsuarioProdutoStemplayFullDTO>(resourceURL),
        findProdutoStemplayRotina
    };
};
