import { useMatriculaService } from '../../services/matricula.service';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { IMatriculaDTO } from '../../models/happy-code-api.model';
import { useGlobalContext } from '../../context/GlobalContext';

interface ParamsType {
	idFinanceiro: string;
}

interface UseGetListOfMatriculasProps {
	idFinanceiroNumber?: number;
}

export default function useGetListOfMatriculas({
	idFinanceiroNumber,
}: UseGetListOfMatriculasProps) {
	const { setIsGlobalLoading } = useGlobalContext();

	const { findListMatriculas } = useMatriculaService();
	const { idFinanceiro } = useParams<ParamsType>();

	const [currentContractEnrollments, setCurrentContractEnrollments] = useState<
		IMatriculaDTO[]
	>([]);

	const getListOfMatriculas = useCallback(async () => {
		setIsGlobalLoading(true);

		const getIdFinanceiro = idFinanceiro
			? parseInt(idFinanceiro)
			: idFinanceiroNumber;

		if (!getIdFinanceiro) return;

		await findListMatriculas(getIdFinanceiro)
			.then(({ data }) => setCurrentContractEnrollments(data))
			.catch((error) => console.log(error))
			.finally(() => setIsGlobalLoading(false));
	}, [idFinanceiroNumber, setIsGlobalLoading, idFinanceiro]);

	useEffect(() => {
		const controller = new AbortController();

		getListOfMatriculas();

		return () => controller.abort();
	}, [getListOfMatriculas]);

	return {
		currentContractEnrollments,
	};
}
