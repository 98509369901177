import { useCallback, useEffect, useState } from 'react';
import { Predicate } from '../../../../../../models/predicate.model';
import { useAlunoContext } from '../../../../../../context/AlunoContext';
import { useMatriculaService } from '../../../../../../services/matricula.service';
import { IMatriculaCustomDTO } from '../../../../../../models/happy-code-api.model';

export default function useStudentCourses() {
	const { aluno } = useAlunoContext();

	const addPredicateOnRequest = useCallback((): void | Predicate => {
		if (!aluno.id) return;

		const predicate = new Predicate(50);
		predicate.addOption('aluno.id', aluno.id);
		return predicate;
	}, [aluno.id]);

	const [isLoading, setIsLoading] = useState(false);

	const { findCustom } = useMatriculaService();

	const [coursesData, setCoursesData] = useState<IMatriculaCustomDTO[]>([]);

	const getAllCourses = useCallback(async (): Promise<void> => {
		setIsLoading(true);

		const predicate = addPredicateOnRequest();
		if (!predicate) return;

		await findCustom(predicate)
			.then(({ data }) => setCoursesData(data))
			.finally(() => setIsLoading(false));
	}, [addPredicateOnRequest]);

	useEffect(() => {
		const controller = new AbortController();

		getAllCourses();

		return () => controller.abort();
	}, [getAllCourses]);

	return {
		isLoading,
		coursesData,
	};
}
