import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { ChangeEvent, useEffect, useState } from 'react';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { ICurriculoDTO, ICursoDTO, ILogCleanDTO, IProdutoEscolaDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useCurriculoService } from '../../../../services/curriculo.service';
import { useCursoService } from '../../../../services/curso.service';
import { useLogService } from '../../../../services/log.service';
import { useProdutoEscolaService } from '../../../../services/produto-escola.service';
import FiltrosCurso from './filtros-curso';
import AcademyItemListCurriculo from './item-lista-curriculo';
import AcademyCursoItemListCurso from './item-lista-curso';
import AcademyItemListProdutoEscola from './item-lista-produto-escola';
import './style.css';

export const AcademyCurso: React.FunctionComponent = () => {
    const [data, setData] = useState<PageableResponse<ICursoDTO>>();
    const [dataCurriculo, setDataCurriculo] = useState<PageableResponse<ICurriculoDTO>>();
    const [dataProdutoEscola, setDataProdutoEscola] = useState<PageableResponse<IProdutoEscolaDTO>>();
    const [predicate] = useState<Predicate>(new Predicate());
    const cursoService = useCursoService();
    const curriculoService = useCurriculoService();
    const produtoEscolaService = useProdutoEscolaService();
    const { setIsGlobalLoading } = useGlobalContext();
    const [tipoProduto, setTipoProduto] = useState<number>(1);

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso gestao material cursos academy",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const findCurso = () => {
        setIsGlobalLoading(true);
        cursoService.find(predicate)
            .then(({ data }) => {
                setData(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    const findCurriculo = () => {
        setIsGlobalLoading(true);
        curriculoService.find(predicate)
            .then(({ data }) => {
                setDataCurriculo(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    const findProdutoEscola = () => {
        setIsGlobalLoading(true);
        produtoEscolaService.find(predicate)
            .then(({ data }) => {
                setDataProdutoEscola(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        if (tipoProduto === 1) {
            findCurso();
        } else if (tipoProduto === 2) {
            findCurriculo();
        } else if (tipoProduto === 3) {
            findProdutoEscola();
        }
    }

    const ordenar = (campo) => {
        predicate.addSort(campo);
        if (tipoProduto === 1) {
            findCurso();
        } else if (tipoProduto === 2) {
            findCurriculo();
        } else if (tipoProduto === 3) {
            findProdutoEscola();
        }
    }

    return (
        <div className="container">
            <NewMenu selecionado={30} />
            <div className="dashboard" style={{ paddingTop: 40, height: '100vh', overflowY: 'scroll' }}>

                <div className="box-title">
                    <h3 className="title">{tipoProduto === 1 ? `Cursos (${data? data.totalElements : 'carregando...'})` : `Currículo (${dataCurriculo?.totalElements})`}</h3>
                </div>

                <FiltrosCurso
                    findCurso={findCurso}
                    findCurriculo={findCurriculo}
                    findProdutoEscola={findProdutoEscola}
                    setTipoProduto={setTipoProduto}
                    tipoProduto={tipoProduto}
                    predicate={predicate}
                />

                <div className="tabela">
                    {tipoProduto === 1 ?
                        <div className="titles-users">
                            <div className="item" style={{ width: '20%' }}>
                                <span>CURSO</span>
                                <div className="arrows" onClick={() => ordenar('descricao')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>ID</span>
                                <div className="arrows" onClick={() => ordenar('id')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>TURMAS</span>
                                <div className="arrows" onClick={() => ordenar('modalidade.descricao')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>TIPOS</span>
                                <div className="arrows" onClick={() => ordenar('tipoCurso.descricao')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>
                            <div className="item" style={{ width: '20%' }} />
                        </div>
                        : null}
                    {tipoProduto === 2 ?
                        <div className="titles-users">
                            <div className="item" style={{ width: '20%' }}>
                                <span>Currículo</span>
                                <div className="arrows" onClick={() => ordenar('descricao')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>ID</span>
                                <div className="arrows" onClick={() => ordenar('id')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>NEGÓCIO</span>
                                <div className="arrows" onClick={() => ordenar('negocio')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div style={{ width: '40%' }} />
                        </div>
                        : null}
                    {tipoProduto === 3 ?
                        <div className="titles-users">
                            <div className="item" style={{ width: '20%' }}>
                                <span>PRODUTO</span>
                                <div className="arrows" onClick={() => ordenar('descricao')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>ID</span>
                                <div className="arrows" onClick={() => ordenar('id')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div className="item" style={{ width: '20%' }}>
                                <span>NEGÓCIO</span>
                                <div className="arrows" onClick={() => ordenar('negocio')}>
                                    <Unicons.UilDirection size="20" color="#92A9CB" />
                                </div>
                            </div>

                            <div style={{ width: '40%' }} />
                        </div>
                        : null}

                    {tipoProduto === 1 ?
                        <>
                            <div className="tabela-body">
                                {data && data.content.map((curso, index) => (
                                    <AcademyCursoItemListCurso curso={curso} key={index} />
                                ))}
                            </div>
                            <div className="paginacao">
                                <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                            </div>
                        </>
                        : null}
                    {tipoProduto === 2 ?
                        <>
                            <div className="tabela-body">
                                {dataCurriculo && dataCurriculo.content.map((curriculo, index) => (
                                    <AcademyItemListCurriculo curriculo={curriculo} key={index} />
                                ))}
                            </div>
                            <div className="paginacao">
                                <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={dataCurriculo ? dataCurriculo.pageable.pageNumber + 1 : 0} count={dataCurriculo ? dataCurriculo.totalPages : 0} />
                            </div>
                        </>
                        : null}
                    {tipoProduto === 3 ?
                        <>
                            <div className="tabela-body">
                                {dataProdutoEscola && dataProdutoEscola.content.map((produtoEscola, index) => (
                                    <AcademyItemListProdutoEscola produtoEscola={produtoEscola} key={index} />
                                ))}
                            </div>
                            <div className="paginacao">
                                <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={dataProdutoEscola ? dataProdutoEscola.pageable.pageNumber + 1 : 0} count={dataProdutoEscola ? dataProdutoEscola.totalPages : 0} />
                            </div>
                        </>
                        : null}
                </div>
            </div>
        </div>
    );
}