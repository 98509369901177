import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	INegocioUnidadeCleanDTO,
	INegocioUnidadeFullDTO,
} from '../models/happy-code-api.model';
import { INegocioUnidadeDTO } from './../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';
import { baseFindList } from './base/base-find-list.service';

export const useNegocioUnidadeService = () => {
	const resourceURL = 'negocio-unidade';

	const patchCustom = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<INegocioUnidadeDTO>> =>
		http.patch<INegocioUnidadeDTO>(resourceURL + `/custom/${id}`, dto);

	return {
		...baseFind<INegocioUnidadeCleanDTO,INegocioUnidadeDTO,INegocioUnidadeFullDTO>(resourceURL),
		...baseFindById<INegocioUnidadeCleanDTO,INegocioUnidadeDTO,INegocioUnidadeFullDTO>(resourceURL),
		...baseFindList<INegocioUnidadeCleanDTO,INegocioUnidadeDTO,INegocioUnidadeFullDTO>(resourceURL),
		patchCustom,
	};
};
