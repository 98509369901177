
import http from '../helpers/http-common';
import { IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyCurso = () => {
	const resourceURL = '/aca-curso';

	const enviarArquivo = (
		body: any,
	): Promise<IAxiosResponseCustom<IAcaCursoDTO>> =>
		http.post(`${resourceURL}`, body);

	const editarArquivoCustom = (
		trilhaId: number,
		body: any,
	): Promise<IAxiosResponseCustom<IAcaCursoDTO>> =>
		http.patch(`${resourceURL}/custom/${trilhaId}`, body);

	return {
		...baseFind<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(resourceURL),
		...baseFindList<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(resourceURL),
		...baseFindById<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(resourceURL),
		...baseCreate<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(resourceURL),
		...baseUpdate<IAcaCursoCleanDTO, IAcaCursoDTO, IAcaCursoFullDTO>(resourceURL),
		enviarArquivo,
		editarArquivoCustom,
		/*
		createFull: (body): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		*/
	};
};
