
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Input, Row } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import fondo from '../../assets/img/matriculas/fondo.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useNotificationService } from '../../services/notification.service';
import { useTurmaService } from '../../services/turma.service';
import { ITurmaFullDTO } from './../../models/happy-code-api.model';
import ItemListTurma from './item-lista';
import { MatriculaProgresso } from './progresso';
import './style.css';
moment().locale('pt-br');

export const MatriculasCreateStep1: React.FunctionComponent = () => {
  const { setIsGlobalLoading, unidade } = useGlobalContext();
  const { matricula, setMatricula } = useAlunoContext();

  const [isOpen, setIsOpen] = useState(false);
  const [listTurma, setListTurma] = useState<PageableResponse<ITurmaFullDTO>>();
  const [turmaSelected, setTurmaSelected] = useState<ITurmaFullDTO>();
  const [turmaPredicate] = useState<Predicate>(new Predicate());
  const [descricao, setDescricao] = useState<string>();

  const history = useHistory();
  const notification = useNotificationService();
  const turmaService = useTurmaService();

  const findTurma = () => {
    const today = new Date();
    turmaPredicate.addOption('curso.id', matricula.turmaCurso.id)
    turmaPredicate.addOption('unidade.id', unidade.id)
    turmaPredicate.addOption('dataFim', moment(today).format('YYYY-MM-DD'), Operators.GREATER_THAN)
    return turmaService.findFull(turmaPredicate)
      .then(({ data }) => {
        setListTurma(data);
      });
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    findTurma().finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao !== undefined) {
        setIsGlobalLoading(true);
        turmaPredicate.addOption('descricao', descricao, Operators.CONTAINS);
        findTurma().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  const handleSubmit = () => {
    if (turmaSelected?.matriculas?.length >= turmaSelected?.maxAlunos && turmaSelected?.maxAlunos != null) {
      notification({ description: 'A capacidade máxima de alunos da turma foi atingida.', type: 'warning', message: 'Aviso' })
      return;
    }

    if (!turmaSelected) {
      notification({ description: 'É necessário selecionar uma turma.', type: 'warning', message: 'Aviso' })
      return;
    }

    else {
      const { turma } = matricula;
      turma.id = turmaSelected?.id;
      setMatricula({ ...matricula, turma });
      history.push("/matriculas/create/step-2");
    }
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    setIsGlobalLoading(true);
    turmaPredicate.setPage(page);
    findTurma().finally(() => setIsGlobalLoading(false));
  }

  return (
    <div className="container-fondo">
      <Header />
      <MatriculaProgresso step={1} percent={16} />
      <div className="franquia">
        <div className="space1">
        </div>
        <div className="user-row" style={{ zIndex: 2 }}>
          <h3>Selecione uma turma de <strong>{matricula.turmaCurso.descricao}</strong></h3>
          <div className="filtros" style={{ width: '100%' }}>
            <div className="input-text" style={{ width: '100%' }}>
              <Input onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar pelo nome" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
            </div>
          </div>
          <div className="matriculas" style={{ width: '100%' }}>
            <Row className='turmas'>
              {listTurma && listTurma.content.map((turma, index) => (
                <ItemListTurma turmaSelected={turmaSelected} setTurmaSelected={setTurmaSelected} turma={turma} key={index} />
              ))}
            </Row>
          </div>

          <div className="paginacao" style={{ width: '100%' }}>
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={listTurma ? listTurma.pageable.pageNumber + 1 : 0} count={listTurma ? listTurma.totalPages : 0} />
          </div>

          <div className="botoes">
            <Link to="/matriculas/create/curso">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <Button onClick={() => handleSubmit()} className="button-primary" style={{ width: 345 }}>Próximo</Button>
          </div>

        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a adição desta Nova Matricula?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/alunos'
      />
      <img src={fondo} alt="" className="img-fondo" />
    </div>
  );
}