import { SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Input, Progress } from 'antd';
import 'antd/dist/antd.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { Operators } from '../../enum/operators.enum';

import { IUnidadeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useUnidadePessoaService } from '../../services/unidade-pessoa.service';
import { useUnidadeService } from '../../services/unidade.service';
import StepsCreateFranquia from './steps-create-franquia';
import './style.css';

const UserCreateFranquiaStep2: React.FunctionComponent = () => {

  const { usuario, setUsuario, revisao } = useUsuarioContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [unidades, setUnidades] = useState<IUnidadeDTO[]>([]);
  const [busca, setBusca] = useState<string>('');
  const [predicate] = useState<Predicate>(new Predicate());

  const unidadeService = useUnidadeService();
  const unidadePessoaService = useUnidadePessoaService();

  const fetchInitialUnidadeData = async () => {
    setIsGlobalLoading(true);
    unidadeService.find(predicate)
      .then(({ data }) => setUnidades(data.content))
      .finally(() => setIsGlobalLoading(false));
  };

  useEffect(() => {
    fetchInitialUnidadeData();
    const predicate = new Predicate();
    predicate.addOption('pessoa.id', usuario.pessoa.id);
    unidadePessoaService.findList(predicate)
      .then(({ data }) => {
        if (data.length > 0) {
          const { funcao, usuarioUnidades } = usuario;
          funcao.id = data[0].tipoUnidadePessoa.id;
          funcao.codigo = data[0].tipoUnidadePessoa.codigo;
          funcao.descricao = data[0].tipoUnidadePessoa.descricao;

          const unidadesPessoa = data.filter(up => !usuarioUnidades.some(uu => uu.unidadeId === up.unidade.id)).map(uu => ({ id: undefined, ativo: true, unidadeId: uu.unidade.id, unidadeDescricao: uu.unidade.descricao }));
          setUsuario({ ...usuario, funcao, usuarioUnidades: [...usuarioUnidades, ...unidadesPessoa] });
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (busca)
        predicate.addOption('descricao', busca, Operators.CONTAINS);
      else
        predicate.removeOption('descricao');
      fetchInitialUnidadeData();
    }, 800);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca]);

  function onChangeUnidade(e: CheckboxChangeEvent, unidade: IUnidadeDTO) {
    e.preventDefault();
    e.stopPropagation();
    const { usuarioUnidades } = usuario;
    if (e.target.checked) {
      usuarioUnidades.push({ id: undefined, ativo: true, unidadeId: unidade.id, unidadeDescricao: unidade.descricao });
      setUsuario({ ...usuario, usuarioUnidades })
    } else {
      setUsuario({ ...usuario, usuarioUnidades: usuarioUnidades.filter(tp => tp.unidadeId !== unidade.id) })
    }
  }

  const optionsUnidade = unidades.map(unidade => {
    const selected = usuario.usuarioUnidades.some(p => p.unidadeId === unidade.id)
    return (
      <div key={unidade.id} className={selected ? "box-franquias-selected" : "box-franquias"}>
        <Checkbox
          onChange={(e) => onChangeUnidade(e, unidade)}
          checked={selected}
        />
        <div className="franquia-info">
          <h5>{unidade.descricao}</h5>
          <div className="dados">
            <div className="endereco">
              <h6>Endereço</h6>
              <p>{unidade.endereco.logradouro + ', ' + unidade.endereco.numero + ' - ' + unidade.endereco.cidade + ', ' + unidade.endereco.uf}</p>
            </div>
            <div className="endereco">
              <h6>Gestor</h6>
              <p>{unidade.gestor.nome}</p>
            </div>
          </div>
          {/* <p>Ver mais detalhes</p> */}
        </div>
      </div>)
  })

  return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={50}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreateFranquia current={1} />
			<div className="franquia">
				<div className="user-row">
					<h3 id="colaborador-title" style={{ textAlign: 'left' }}>
						Selecione as franquias que deseja adicionar
					</h3>
					<div
						className="input-text"
						style={{ width: '100%', marginBottom: 15 }}
					>
						<Input
							placeholder="Buscar Franquias"
							prefix={<SearchOutlined rev={undefined} />}
							onChange={(e) => setBusca(e.target.value)}
						/>
					</div>
					{optionsUnidade}

					<div className="botoes">
						{revisao ? (
							<Link to="/usuarios/create/franquia/step-4">
								<Button
									type="primary"
									className="button-primary"
									style={{ width: 700 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						) : (
							<>
								<Link to="/usuarios/create/franquia/step-1">
									<Button className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/usuarios/create/franquia/step-3">
									<Button className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Link to="/usuarios">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
}

export default UserCreateFranquiaStep2;