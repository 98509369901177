import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { ICurriculoCleanDTO, ICurriculoDTO, ILogCleanDTO, IRecursoCleanDTO, IRecursoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';

import { useLogService } from '../../services/log.service';
import FiltrosCurriculo from './filtros-recurso';
import ItemListRecurso from './item-lista';
import './style.css';
import { useRecursoService } from '../../services/recurso.service';
import FiltrosRecurso from './filtros-recurso';

const Recurso: React.FunctionComponent = () => {
  const [data, setData] = useState<PageableResponse<IRecursoDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const recursoService = useRecursoService();
  const { setIsGlobalLoading } = useGlobalContext();

  const history = useHistory();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso recurso",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    logService.registraLog(log)
  }, [])

  const findRecurso = () => {
    setIsGlobalLoading(true);
    recursoService.find(predicate)
      .then(({ data }) => setData(data))
      .finally(() => setIsGlobalLoading(false));
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findRecurso();
  }

  const goToAdd = () => {
    history.push("/recurso/create/step-1");
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findRecurso();
  }

  const handleRecurso = (recurso: IRecursoCleanDTO, state: boolean) => {
    recurso.ativo = state;
    setIsGlobalLoading(true);
    recursoService.patch(recurso)
      .then(() => findRecurso())
      .finally(() => setIsGlobalLoading(false));
  }

  return (
    <div className="container">
      <NewMenu selecionado={96} />
      <div className="dashboard">
        <div className="box-title">
          <h3 className="title">Recursos ({data?.totalElements})</h3>
          <Button className="button-primary" id="criar-campanha" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Recurso</Button>
        </div>
        <FiltrosRecurso findRecurso={findRecurso} predicate={predicate} />
        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '30%' }}>
              <span>Nome</span>
              <div className="arrows" onClick={() => ordenar('descricaoRecurso')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '100%' }}>
              <span>ID</span>
              <div className="arrows" onClick={() => ordenar('id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '5%' }}></div>
          </div>
          <div className="tabela-body">
            {data && data.content.map((recurso, index) => (
              <ItemListRecurso handleRecurso={(recurso, state) => handleRecurso(recurso, state)} recurso={recurso} key={index} />
            ))}
          </div>
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recurso;