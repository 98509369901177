/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { IUsuarioFullDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsUsuarioContext {
	usuario: IUsuarioFullDTO;
	setUsuario: React.Dispatch<React.SetStateAction<IUsuarioFullDTO>>;
	removeUsuario: React.DispatchWithoutAction;
	revisao: boolean;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
};

//Valor default do contexto
const DEFAULT_VALUE: PropsUsuarioContext = {
	usuario: {
		id: null,
		usuario: '',
		login: '',
		ativo: true,
		urlArquivo: '',
		acessoB2c: false,
		acessoB2b: false,
		acessoComercial: false,
		nivelAcesso: null,
		perfil: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		pessoa: {
			id: null,
			email: '',
			descricaoEmpresa: '',
			observacao: '',
			descricaoProfissao: '',
			descricaoRestricao: '',
			dataNascimento: null,
			permiteUsoImagem: false,
			foto: null,
			nome: '',
			contatos: [],
			dataAdmissao: null,
			cpf: '',
			rg: '',
			ativo: true,
			unidadePessoas: [],
			identificadorPagarMe: '',
		},
		funcao: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		arquivo: {
			id: null,
			codigo: '',
			url: '',
			ativo: true,
		},
		usuarioClusters: [],
		usuarioModulos: [],
		usuarioPaises: [],
		usuarioUnidades: [],
		instrutorStemplay: false
	},
	setUsuario: () => { }, //função de inicialização
	removeUsuario: () => { }, //função de inicialização
	revisao: false,
	setRevisao: () => { }, //função de inicialização
};

//criando nosso contexto UserContext
const UsuarioContext = createContext<PropsUsuarioContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/usuario';
const nameStorageRevisao = "@HappyCode/unidade/revisao"
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const UsuarioContextProvider: React.FC = ({ children }: any) => {
	const [usuario, setUsuarioGlobal] = useState<IUsuarioFullDTO>(
		DEFAULT_VALUE.usuario
	);

	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const setUsuario = (usuario) => {
		setUsuarioGlobal(usuario);
		setObject(nameStorage, usuario);
	};

	const removeUsuario = () => {
		setRevisao(false);
		setUsuarioGlobal(DEFAULT_VALUE.usuario);
		removeKey(nameStorage);
	};

	useEffect(() => {
		const usuario = getObject(nameStorage);
		if (usuario) {
			setUsuarioGlobal(usuario);
		}
		const revisao = getObject(nameStorageRevisao)
		if (revisao) {
			setRevisaoGlobal(revisao);
		}
	}, []);

	return (
		<UsuarioContext.Provider
			value={{
				usuario,
				setUsuario,
				removeUsuario,
				revisao,
				setRevisao,
			}}
		>
			{children}
		</UsuarioContext.Provider>
	);
};

export const useUsuarioContext = (): PropsUsuarioContext => {
	const context = useContext(UsuarioContext);
	if (!context)
		throw new Error(
			'useUsuarioContext must be used within a UsuarioContextProvider'
		);
	return context;
};
