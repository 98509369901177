import {
	IStatusUnidadeCleanDTO,
	IStatusUnidadeDTO,
	IStatusUnidadeFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useStatusUnidadeService = () => {
	const resourceURL = 'status-unidade';

	return {
		...baseFind<
			IStatusUnidadeCleanDTO,
			IStatusUnidadeDTO,
			IStatusUnidadeFullDTO
		>(resourceURL),
		...baseFindList<
			IStatusUnidadeCleanDTO,
			IStatusUnidadeDTO,
			IStatusUnidadeFullDTO
		>(resourceURL),
	};
};
