import {
  IFinanceiroCleanDTO,
  IFinanceiroDTO,
  IFinanceiroFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useStatusEscolaService = () => {
  const resourceURL = 'statusescola';

  return {
    ...baseFind<IFinanceiroCleanDTO, IFinanceiroDTO, IFinanceiroFullDTO>(
      resourceURL
    ),
    ...baseFindList<IFinanceiroCleanDTO, IFinanceiroDTO, IFinanceiroFullDTO>(
      resourceURL
    ),
  };
};
