import {
	ITipoUnidadePessoaCleanDTO,
	ITipoUnidadePessoaDTO,
	ITipoUnidadePessoaFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useTipoUnidadePessoaService = () => {
	const resourceURL = 'tipo-unidade-pessoa';

	return {
		...baseFindList<
			ITipoUnidadePessoaCleanDTO,
			ITipoUnidadePessoaDTO,
			ITipoUnidadePessoaFullDTO
		>(resourceURL),
	};
};
