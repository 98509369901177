import { useEffect, useState } from 'react';
import { IAlunoLeadCustomDTO } from '../../../models/happy-code-api.model';
import { ContatoEnum } from '../../../enum/contato.enum';

const useValidation = (aluno: IAlunoLeadCustomDTO) => {
    const [validationFild, setValidationFild] = useState('');

    const responsavelAtivo = aluno?.pessoa?.pessoaResponsaveis?.filter(responsavel => responsavel.ativo === true);

    const validationFieldsCustom = (email?: string, cep?: string) => {
        if(email){
          const re = /\S+@\S+\.\S+/;
          if (!re.test(email)){
              return "E-mail inválido!";
          }
        }
        if(cep){
          if(cep.length !== 8){
            return "O CEP deve ter 8 digitos!";
        }
    }
      }

    useEffect(() => {
        let descricao = '';
        responsavelAtivo?.forEach((pessoaResp) => {
            if (!pessoaResp?.responsavel?.nome) descricao = 'É necessário informar o nome!';
            else if (!pessoaResp.responsavel.cpf) descricao = 'É necessário informar o CPF!';
            else if (!pessoaResp?.responsavel?.contatos?.find((c) => c.tipoContatoId === ContatoEnum.CEL)?.descricao) descricao = 'É necessário informar o telefone(1)!';
            else if (!pessoaResp.responsavel.endereco?.numero) descricao = 'É necessário informar o número do endereço!'; 
            else if (!pessoaResp.responsavel.email) descricao = 'É necessário informar o e-mail!';
            else if (!pessoaResp.responsavel.dataNascimento) descricao = 'É necessário informar a data de nascimento!';
            else if (!pessoaResp.grauParentesco) descricao = 'É necessário informar o grau de parentesco!';
            else if (!pessoaResp?.responsavel?.endereco?.cep) descricao = 'É necessário informar o CEP';
            else if (!pessoaResp.responsavel.endereco?.logradouro) descricao = 'É necessário informar o endereço';

            
            if (!descricao) {
                descricao = validationFieldsCustom(pessoaResp.responsavel.email, pessoaResp.responsavel.endereco.cep);
            }

        });
        setValidationFild(descricao);
    }, [aluno]);



    return { validationFild };
};

export default useValidation;



