import { Button, Collapse, Space, Input, Select } from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import '../../shared.scss';
import './style.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ModalLeadDuplicata = (props: Props) => {
  const { open, onClose } = props;
  const { Panel } = Collapse;

  const { Option } = Select;

  function handleChange(value: any) {
    console.log(`selected ${value}`);
  }

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal-leads">
          <Button onClick={onClose} className="button-x">x</Button>
          <div className="flex flex-row justify-start align-center w-100 mb-20">
            <h3 className="w-25">Rosiane Camargo</h3>
            <div className="status">Novo</div>
            <div className="icons flex flex-row justify-start align-center w-100">
              <div className="starts">
                <label htmlFor="check_1"><Unicons.UilStar color="var(--cinza-azulado)" size={20} /></label>
                <input type="checkbox" id="check_1" />
                <label htmlFor="check_1"><Unicons.UilStar color="var(--cinza-azulado)" size={20} /></label>
                <input type="checkbox" id="check_1" />
                <label htmlFor="check_1"><Unicons.UilStar color="var(--cinza-azulado)" size={20} /></label>
                <input type="checkbox" id="check_1" />
                <label htmlFor="check_1"><Unicons.UilStar color="var(--cinza-azulado)" size={20} /></label>
                <input type="checkbox" id="check_1" />
                <label htmlFor="check_1"><Unicons.UilStar color="var(--cinza-azulado)" size={20} /></label>
                <input type="checkbox" id="check_1" />
              </div>
              <Unicons.UilFileAlt color="var(--cinza-azulado-200)" size={24} />
            </div>
          </div>
          <div className="flex flex-row justify-between align-start">
            <div className="w-50 mr-10">
              <Space className="w-100" direction="vertical">
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                  <Panel header="Dados pessoais" key="1">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>Nome</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Rosiane Camargo" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Email</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="R.Camargo@emcil.com.br" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Celular</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="(99) 99999-9999" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Empresa</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Empresa ABC" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>CPF / CNPJ</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="123.456.789-00" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Tipo de Pessoa</small>
                      </div>
                      <div className="input-modal">
                        <Select style={{ width: '100%' }} bordered={false} defaultValue="gestor" onChange={handleChange}>
                          <Option value="gestor">Gestor(a) Comercial</Option>
                          <Option value="lucy">Lucy</Option>
                          <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                      </div>
                      <div className="text-over-input">
                        <small>Origem</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Campanha MKT Brasil" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Campanha</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Campnha A" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse collapsible="header">
                  <Panel header="Endereço" key="2">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>CEP</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="CEP" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Estado</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Cidade</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Bairro</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Lograduro</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Numero</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse collapsible="header">
                  <Panel header="Produto" key="3">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>Produto de Interesse</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o produto de interesse" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Valor</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse collapsible="header">
                  <Panel header="Responsável" key="3">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>Usuário</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Camila Pedroso" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Equipe</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Curitiba - PR" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </Space>
              <Button className="button-red mt-20 mb-20">Confirmar duplicidade e descartar lead</Button>
              <h6>Cadastrado em: 01/01/2022 13:15:38</h6>
            </div>
            <div className="w-50">
              <Space className="w-100" direction="vertical">
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                  <Panel header="Dados pessoais" key="1">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>Nome</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Rosiane Camargo" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Email</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="R.Camargo@emcil.com.br" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Celular</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="(99) 99999-9999" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Empresa</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Empresa ABC" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>CPF / CNPJ</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="123.456.789-00" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Tipo de Pessoa</small>
                      </div>
                      <div className="input-modal">
                        <Select style={{ width: '100%' }} bordered={false} defaultValue="gestor" onChange={handleChange}>
                          <Option value="gestor">Gestor(a) Comercial</Option>
                          <Option value="lucy">Lucy</Option>
                          <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                      </div>
                      <div className="text-over-input">
                        <small>Origem</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Campanha MKT Brasil" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Campanha</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Campnha A" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse collapsible="header">
                  <Panel header="Endereço" key="2">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>CEP</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="CEP" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Estado</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Cidade</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Bairro</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Lograduro</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Numero</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse collapsible="header">
                  <Panel header="Produto" key="3">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>Produto de Interesse</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o produto de interesse" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Valor</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Digite o valor" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Collapse collapsible="header">
                  <Panel header="Responsável" key="3">
                    <div className="box-collapse">
                      <div className="text-over-input">
                        <small>Usuário</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Camila Pedroso" bordered={false} />
                      </div>
                      <div className="text-over-input">
                        <small>Equipe</small>
                      </div>
                      <div className="input-modal">
                        <Input placeholder="Curitiba - PR" bordered={false} />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </Space>
              <Button className="button-red mt-20 mb-20">Confirmar duplicidade e descartar lead</Button>
              <h6>Cadastrado em: 01/01/2022 13:15:38</h6>
              <div className="flex flex-row justify-end">
                <Button className="button-primary mt-20">Não são o mesmo Lead</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
