// import { Sidebar } from "../../sub-components/Sidebar";
import { Form, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import NewMenu from '../../components/NewMenu';
import {
	ILeadCustomDTO,
	ILogCleanDTO,
} from '../../models/happy-code-api.model';
import { Header } from './components/Header';
import { Lead } from './components/Lead';
import { CrmContext, CrmContextProvider } from './context/crm.context';
import './shared.scss';
import './style.scss';
import { useLogService } from '../../services/log.service';
import { useParams } from 'react-router-dom';

const Board = () => {
	const { idLead } = useParams<any>();
	const [state, setState] = useContext(CrmContext);
	const { Option } = Select;
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso tela crm b2c',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const onChangeQuantidadeItensPorPagina = (e) => {
		setState((state) => ({ ...state, qtLeadsPorPaginas: e }));
	};

	return (
		<>
			<NewMenu selecionado={20} />
			<div
				className="dashboard-crm"
				style={{ height: '100vh', overflowY: 'scroll' }}
			>
				<Header filtros={true} button="Novo Lead" modal="lead" />
				<div className="flex-col">
					<div
						className="flex-row flex align-center justify-end"
						style={{ marginRight: 45, marginTop: 10 }}
					>
						<div>Exibir</div>
						<div className="filtro-estado" style={{ marginLeft: 10 }}>
							<Form.Item name={'quantidae'}>
								<Select
									onChange={onChangeQuantidadeItensPorPagina}
									defaultValue={10}
									dropdownStyle={{ color: 'black' }}
									style={{ width: '100%' }}
									bordered={true}
								>
									<Option key={10} value={10}>
										{'10 itens'}
									</Option>
									<Option key={20} value={20}>
										{'20 itens'}
									</Option>
									<Option key={50} value={50}>
										{'50 itens'}
									</Option>
									<Option key={100} value={100}>
										{'100 itens'}
									</Option>
									<Option key={99999999} value={99999999}>
										{'todos'}
									</Option>
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className="area-tasks flex flex-row justify-between align-start">
						<div
							className="flex flex-row justify-start align-start"
							style={{ overflowX: 'scroll', padding: 20, gap: 10 }}
						>
							{state?.boards?.map?.((board) => {
								return (
									<div key={board?.id} className="area-column">
										<div className="header flex flex-row justify-between align-center">
											<h3>{board?.dsLeadEstagio}</h3>
											<h3>({board?.leads?.length})</h3>
										</div>
										<div className="body">
											{board?.leads?.map?.((lead: ILeadCustomDTO) => {
												return (
													<Lead
														leadSelected={lead.id == idLead}
														leadParamsId={idLead}
														key={lead.id}
														lead={lead}
														type={lead.flLeadNovo ? 'sem' : 'hoje'}
													/>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export const Crm = () => {
	return (
		<CrmContextProvider>
			<div className="container-happy">{<Board />}</div>
		</CrmContextProvider>
	);
};
