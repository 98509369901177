import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { ModalUploadArquivo } from '../../../../components/ModalUploadArquivo';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IMaterialDTO } from '../../../../models/happy-code-api.model';
import { useAcademyMaterialService } from '../../../../services/academy-material';
import { useNotificationService } from '../../../../services/notification.service';

interface TItemLista {
    material: IMaterialDTO;
}

const AcademyItemListMaterial = memo(({ material }: TItemLista) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [ativo, setAtivo] = useState<boolean>();
    const { setIsGlobalLoading } = useGlobalContext();
    const academymaterialService = useAcademyMaterialService();
    const notification = useNotificationService();

    useEffect(() => {
        setAtivo(material.ativo);
    }, [material.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente excluir o material?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: material?.codigo,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                academymaterialService.delete({ id: material?.id })
                    .then(() => {
                        notification({
                            description: `Material excluido com sucesso.`,
                            type: 'success',
                            message: 'Sucesso!',
                        });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const finalizationModal = () => {
        setIsOpen(false);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Excluir
                </a>
            </Menu.Item>
        </Menu>
    );


    const alterDescription = () => {
        const tipoVisualizacao = [];
        if (material?.acessoB2c) {
            tipoVisualizacao.push("B2C ");
        }
        if (material?.acessoB2b) {
            tipoVisualizacao.push("B2B ");
        }
        return tipoVisualizacao;
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">

            <div className="user" id="cursos" style={{ width: "15%" }}>
                <div className="nome">
                    <p>{material?.codigo}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "5%" }}>
                <p>{material.id}</p>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "15%" }}>
                <p>{material?.negocio?.descricao}</p>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{material?.curriculo?.descricaoCurriculo}</p>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{material?.produto?.descricao}</p>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "15%" }}>
                <p>{alterDescription()}</p>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "15%" }}>
                <p>{material?.tipoMaterial?.descricao}</p>
            </div>

            <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar" onClick={() => setIsOpen(true)}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>

            {isOpen ?
                <ModalUploadArquivo
                    open={isOpen}
                    onClose={finalizationModal}
                    verb={'patch'}
                    material={material}
                />
                : null}

        </div>
    )
})

AcademyItemListMaterial.displayName = 'AcademyItemListMaterial';


export default AcademyItemListMaterial;
