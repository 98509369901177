/* eslint-disable react-hooks/rules-of-hooks */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CloseOutlined } from "@mui/icons-material";
import { Button, Input, Radio, Space } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { PresentImage } from "../../../../components/ApresentarImagem";
import Header from "../../../../components/Header";
import Modal from '../../../../components/Modal';
import { useGlobalContext } from "../../../../context/GlobalContext";
import "../../../../global.css";
import { IConectaDTO } from "../../../../models/happy-code-api.model";
import { useAcademyArquivoService } from "../../../../services/academy-arquivo";
import { useAcademyConectaService } from "../../../../services/academy-conceta";
import { useNotificationService } from "../../../../services/notification.service";
import { validateFileExtension } from "../../../../util/util";

const ConectaCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const arquivoService = useAcademyArquivoService();
    const conectaService = useAcademyConectaService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [conecta, setConecta] = useState<IConectaDTO>();
    const { idConecta } = useParams<any>()
    const [sendFileCapa, setSendFileCapa] = useState<File | null>(null);
    const [validaCapa, setValidaCapa] = useState<boolean>(false);

    const findConectas = () => {
        setIsGlobalLoading(true);
        conectaService.findById({ id: idConecta })
            .then((response) => {
                setConecta(response.data);
                setValidaCapa(response.data.capa ? true : false);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const enviarArquivo = async () => {
        setIsGlobalLoading(true);
        const fetchTrilha = async () => {
            try {
                let updatedUpload = { ...conecta };
                if (sendFileCapa) {
                    const { data } = await arquivoService.upload(sendFileCapa);
                    updatedUpload = {
                        ...updatedUpload,
                        arquivo: {
                            id: data.id,
                            codigo: data.codigo,
                            url: data.url,
                            ativo: false
                        }
                    };
                } else {
                    updatedUpload = {
                        ...updatedUpload,
                        arquivo: null,
                    }
                }

                if (!idConecta) {
                    await conectaService.create(updatedUpload)
                    history.push(`/academy/gestao-conecta/conecta`);
                    notification({
                        description: `Conecta cadastrado com sucesso, por gentileza atualizar a página.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                } else {
                    await conectaService.editarArquivoCustom(conecta.id, updatedUpload);
                    history.push(`/academy/gestao-conecta/conecta`);
                    notification({
                        description: `Conecta alterado com sucesso, por gentileza atualizar a página.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                }
            } catch (error) {
                notification({
                    description: `Desculpe, não foi possível editar a conecta.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }
            setIsGlobalLoading(false);
        };
        fetchTrilha();
    };

    const submitValidationFields = () => {
        if (conecta?.id && sendFileCapa && !validaCapa && validateFileExtension(sendFileCapa?.name)) {
            document.getElementById('arquivoCapa')?.focus();
            notification({
                description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (!conecta?.id && !sendFileCapa && !validaCapa && validateFileExtension(sendFileCapa?.name)) {
            document.getElementById('arquivoCapa')?.focus();
            notification({
                description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (validaCapa && validateFileExtension(conecta?.capa)) {
            document.getElementById('linkCapa')?.focus();
            notification({
                description: `A extensão do link selecionado para a "capa" não é permitida. Por favor, selecione um link com extensão válida (.jpg, .jpeg, .png, .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (!conecta?.conteudo) {
            document.getElementById('conteudo')?.focus();
            notification({
                description: `O conteúdo não foi informado para o campo "Conteúdo do Conecta". Por favor, inserir o conteúdo no editor.`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }
        enviarArquivo();
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve) => {
                    loader.file.then((file) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64String = reader.result?.toString().split(',')[1] || '';
                            resolve({ default: `data:image/png;base64,${base64String}` });
                        };
                        reader.readAsDataURL(file);
                    });
                });
            },
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository")
            .createUploadAdapter = (loader) => {
                return uploadAdapter(loader);
            };
    }

    const limparArquivoCapa = () => {
        setSendFileCapa(null);
        setConecta({ ...conecta, arquivo: null })
    }

    useEffect(() => {
        if (idConecta) {
            findConectas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idConecta])

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ justifyContent: "normal", width: "65%" }}>
                <div className="space1" />
                <div className="user-row">
                    <div className="card-form" style={{ width: "150%" }} >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 id="taxas-h3" style={{ marginBottom: 10 }}>Insira informações para cadastro do Conecta</h3>
                            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
                        </div>
                        <div className="input-box">
                            <label htmlFor="nome">Título</label>
                            <Input
                                id="nome"
                                defaultValue={conecta?.nome}
                                value={conecta?.nome}
                                onChange={(e) => setConecta({ ...conecta, nome: e.target.value })} />
                        </div>

                        <div className="input-box">
                            <label htmlFor="checkedCapa">Tipo do arquivo Capa</label>
                            <Radio.Group
                                value={validaCapa}
                                defaultValue={validaCapa}
                                onChange={(e) => setValidaCapa(e.target.value)}
                            >
                                <Space direction="vertical">
                                    <Radio value={false} id="checkedCapa">Arquivo</Radio>
                                    <Radio value={true} onClick={limparArquivoCapa}>Link</Radio>
                                </Space>
                            </Radio.Group>
                        </div>

                        {!validaCapa ?
                            <div className="input-box">
                                <label htmlFor="arquivoCapa">Upload arquivo capa</label>
                                <Input
                                    id="arquivoCapa"
                                    type="file"
                                    onChange={(e) => {
                                        setSendFileCapa(e.target.files?.[0] || null)
                                        setConecta({ ...conecta, capa: null })
                                    }} />
                            </div>
                            :
                            <div className="input-box">
                                <label htmlFor="linkCapa">Informar link capa</label>
                                <Input
                                    id="linkCapa"
                                    defaultValue={conecta?.capa}
                                    value={conecta?.capa}
                                    onChange={(e) => setConecta({ ...conecta, capa: e.target.value })} />
                            </div>
                        }

                        <PresentImage
                            file={sendFileCapa}
                            link={conecta?.capa ? conecta?.capa : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + conecta?.arquivo?.url}
                            size={"100%"}
                        />

                        <div className="input-box">
                            <label htmlFor="editor">Conteúdo do Conecta</label>
                            <CKEditor
                                id="conteudo"
                                config={{
                                    extraPlugins: [uploadPlugin],
                                    mediaEmbed: {
                                        previewsInData: true, // Ative a visualização de incorporação na visualização de dados
                                    },
                                }}
                                editor={ClassicEditor}
                                data={conecta?.conteudo}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setConecta(prev => ({ ...prev, conteudo: data }));
                                }}
                            />
                        </div>

                        <div className="botoes" style={{ marginTop: 10 }}>
                            <Link to={`/academy/gestao-conecta/conecta`}>
                                <Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>Voltar</Button>
                            </Link>
                            <Button
                                style={{ width: 345 }}
                                type="primary"
                                id="proximo"
                                className="button-primary"
                                onClick={submitValidationFields}>Salvar</Button>
                        </div>
                    </div>
                </div>
                <Modal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    title='Você deseja cancelar a operação?'
                    subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                    textButton1='Não'
                    textButton2='Sim, Cancelar'
                    styleButton1='button-line'
                    styleButton2='button-danger'
                    link2={`/academy/gestao-conecta/conecta`}
                />

            </div>
        </div >
    )
}

export default ConectaCreate;
