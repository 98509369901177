import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu } from 'antd';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO, ISalaCleanDTO, ISalaDTO } from "../../models/happy-code-api.model";
import { useCurriculoService } from '../../services/curriculo.service';
import { useSalaContext } from '../../context/SalaContext';

interface TItemLista {
    sala: ISalaDTO;
    handleSala: (sala: ISalaCleanDTO, state: boolean) => void;
}

const ItemListSala = memo(({ sala, handleSala }: TItemLista) => {
    const { editBtnIsVisible} = useSalaContext();


    const { setIsGlobalLoading } = useGlobalContext();

    const handleMenuItem = (sala: ISalaCleanDTO) => {
        if (sala.ativo) {
            return (
                <Menu.Item onClick={() => handleSala(sala, false)}>
                    <a rel="noopener noreferrer">
                        Inativar Sala
                    </a>
                </Menu.Item>
            )
        }
        if (!sala.ativo) {
            return (
                <Menu.Item onClick={() => handleSala(sala, true)}>
                    <a rel="noopener noreferrer">
                        Ativar Sala
                    </a>
                </Menu.Item>
            )
        }
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            {handleMenuItem(sala)}
        </Menu>
    );
    const history = useHistory();



    const goTo = () => {
        history.push(`/sala/create/step-2/${sala.id}`)
        editBtnIsVisible(true);

    }

    const handleStatus = (sala) => {

        if (sala.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Ativo</p>
                </div>
            )
        }

        if (!sala.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className="standby">Inativo</p>
                </div>
            )
        }
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '30%' }}>
                <div className="nome">
                    <p>{sala.descricaoSala}</p>
                    {handleStatus(sala)}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{sala.id}</span>
            </div>
            <div className="funcao-user" id="campanhas" style={{ width: '100%' }}>
                <span>{sala.capacidadeMaxima}</span>
            </div>
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListSala.displayName = 'ItemListSala';

export default ItemListSala;