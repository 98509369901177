import {
	IEscolaPessoaTipoCleanDTO,
	IEscolaPessoaTipoDTO,
	IEscolaPessoaTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useEscolaPessoaTipoService = () => {
	const resourceURL = 'escola-pessoa-tipo';

	return {
		...baseFindList<IEscolaPessoaTipoCleanDTO, IEscolaPessoaTipoDTO, IEscolaPessoaTipoFullDTO>(resourceURL),
	};
};
