/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { getObject, removeKey, setObject } from '../util/store';
import { ICurriculoCleanDTO, ISalaCleanDTO, ITurmaFullDTO, IUnidadeCleanDTO, IUnidadePessoaCleanDTO } from './../models/happy-code-api.model';

//Tipando as Props do contexto
interface PropsTurmaContext {
	turma: ITurmaFullDTO;
	revisao: boolean;
	setTurma: React.Dispatch<React.SetStateAction<ITurmaFullDTO>>;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	removeTurma: React.DispatchWithoutAction;
	editBtn: boolean;
	editBtnIsVisible: (isVisible: boolean) => void;
}

//Valor default do contexto
const DEFAULT_VALUE: PropsTurmaContext = {
	turma: {
		unidadePessoa: {} as IUnidadePessoaCleanDTO,
		sala: {} as ISalaCleanDTO,
		curriculo: {} as ICurriculoCleanDTO,
		unidade: {} as IUnidadeCleanDTO,
	} as ITurmaFullDTO,

	editBtn: false,
	editBtnIsVisible: () => { },
	revisao: false,
	setTurma: () => { }, //função de inicialização
	setRevisao: () => { }, //função de inicialização
	removeTurma: () => { }, //função de inicialização
};

//criando nosso contexto UserContext
const TurmaContext = createContext<PropsTurmaContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/turma';
const nameStorageRevisao = '@HappyCode/turma/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const TurmaContextProvider: React.FC = ({ children }: any) => {
	const [turma, setTurmaGlobal] = useState<ITurmaFullDTO>(DEFAULT_VALUE.turma);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);
	const [editBtn, setEditBtn] = useState(false);

	const setTurma = (turma) => {
		setTurmaGlobal(turma);
		setObject(nameStorage, turma);
	};

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const removeTurma = () => {
		setRevisao(false);
		setTurmaGlobal(DEFAULT_VALUE.turma);
		removeKey(nameStorage);
	};

	useEffect(() => {
		const turma = getObject(nameStorage);
		if (turma) {
			setTurmaGlobal(turma);
		}
		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}
	}, []);
	const editBtnIsVisible = (Boolean: boolean) => {
		setEditBtn(Boolean);
	};

	return (
		<TurmaContext.Provider
			value={{
				turma,
				revisao,
				setTurma,
				setRevisao,
				removeTurma,
				editBtn,
				editBtnIsVisible,
			}}
		>
			{children}
		</TurmaContext.Provider>
	);
};

export const useTurmaContext = (): PropsTurmaContext => {
	const context = useContext(TurmaContext);
	if (!context) throw new Error('useTurmaContext must be used within a TurmaContextProvider');
	return context;
};
