import { useAlunoContext } from '../../../../../context/AlunoContext';
import { IHoraUnidadeCleanDTO } from '../../../../../models/happy-code-api.model';
import useCheckIfPageIsUpgrade from '../../../../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';
import useCalcularSaldoTempoContrato from './use-calcular-saldo-tempo-contrato.hook';

interface UseCalcularTotalTurmaOfertaProps {
	ofertaData: IHoraUnidadeCleanDTO;
}

export default function useCalcularTotalTurmaOferta({
	ofertaData,
}: UseCalcularTotalTurmaOfertaProps) {
	const { matricula } = useAlunoContext();

	const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const { calcularSaldoTempoContrato } = useCalcularSaldoTempoContrato();

	const calcularTotalTurmaOferta = (): number => {
		const curta = matricula?.turmas.filter(
			(tipo) => tipo?.turma?.curriculoNegocioTipoCurriculoCodigo === 'CRT'
		);
		const colonia = matricula?.turmas.filter(
			(tipo) => tipo?.turma?.curriculoNegocioTipoCurriculoCodigo === 'COL'
		);

		const curriculo = matricula?.turmas.filter(
			(tipo) =>
				tipo?.turma?.curriculoNegocioTipoCurriculoCodigo !== 'CRT' &&
				tipo?.turma?.curriculoNegocioTipoCurriculoCodigo !== 'COL'
		);

		const duracoes = curriculo.map(
			(duracao) => duracao.turma.curriculo.duracaoTotal
		);

		const totalDuracao = duracoes.reduce((acc, duracao) => acc + duracao, 0);

		let valorTotal = 0;

		if (curta.length > 0) {
			valorTotal += curta.length * ofertaData?.valorOfertaCurta;
		}

		if (colonia.length > 0) {
			valorTotal += colonia.length * ofertaData?.valorOfertaColonia;
		}

		const { saldoTempoContrato } = calcularSaldoTempoContrato();

		const totalInstallments = isUpgradePage ? saldoTempoContrato : 12;

		if (totalDuracao === 90) {
			valorTotal += ofertaData?.valorOfertaUmaHoraMeia * totalInstallments;
		}

		if (totalDuracao === 180) {
			valorTotal += ofertaData?.valorOfertaTresHora * totalInstallments;
		}

		if (totalDuracao === 270) {
			valorTotal += ofertaData?.valorOfertaQuatroHoraMeia * totalInstallments;
		}

		if (totalDuracao === 360) {
			valorTotal += ofertaData?.valorOfertaSeisHora * totalInstallments;
		}

		if (totalDuracao > 360) {
			const horasExcedentes = totalDuracao - 360;
			valorTotal += (ofertaData?.valorOfertaSeisHora + ((horasExcedentes / 90) * ofertaData?.valorAdicionalOferta)) * totalInstallments;
		}

		return valorTotal;
	};

	return {
		calcularTotalTurmaOferta,
	};
}
