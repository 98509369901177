import http from '../helpers/http-common';
import {
	IEscolaCleanDTO, IEscolaDTO, IEscolaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useEscolaService = () => {
	const resourceURL = 'escola';

	const cancelar = (
		idEscola: number
	): Promise<IAxiosResponseCustom<any>> =>
		http.patch(`${resourceURL}/cancelar/${idEscola}`);

	return {
		...baseFindList<IEscolaCleanDTO, IEscolaDTO, IEscolaFullDTO>(resourceURL),
		...baseFind<IEscolaCleanDTO, IEscolaDTO, IEscolaFullDTO>(resourceURL),
		...baseCreate<IEscolaCleanDTO, IEscolaDTO, IEscolaFullDTO>(resourceURL),
		...baseUpdate<IEscolaCleanDTO, IEscolaDTO, IEscolaFullDTO>(resourceURL),
		...baseFindById<IEscolaCleanDTO, IEscolaDTO, IEscolaFullDTO>(resourceURL),
		cancelar,
	};
};
