import * as Unicons from '@iconscout/react-unicons';
import { Button, Collapse, DatePicker, Form, Input, Rate, Select, Space, Tag } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import whatSapp from "../../../../../assets/img/crm/whatsapp-icon.png";
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { ILeadCampanhaCleanDTO, ILeadCustomDTO, ILeadEstagioDTO, ILeadHistoricoDTO, ILeadInteresseDTO, ILeadOrigemDTO, ILeadStatusCleanDTO, ILeadTipoDTO, ILogCleanDTO, IUnidadeCleanDTO } from "../../../../../models/happy-code-api.model";
import { Predicate } from '../../../../../models/predicate.model';
import { useLeadHistoricoService } from '../../../../../services/lead-historico.service';
import { useLeadInteresseService } from '../../../../../services/lead-interesse.service';
import { useLeadService } from '../../../../../services/lead.service';
import { toMoment } from '../../../../../util/format';
import { CrmContext } from '../../../context/crm.context';
import useTipoRetornoState from "../../../selectors/tipo-retorno.state";
import '../../../shared.scss';
import { ModalNote } from '../lead-anotacao/note';
import { ModalLeadDuplicata } from '../leadduplicata';
import { ModalResposta } from '../resposta';
import '../style.scss';
import useLeadDetalheState from "./selectors/lead-detalhe.state";
import { useLogService } from '../../../../../services/log.service';

interface Props {
    open: boolean;
    type: string;
    boxColor: string;
    onClose: () => void;
    leadId?: number;
    lead: ILeadCustomDTO;
}

export const LeadDetalhe = (props: Props) => {
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const state = useLeadDetalheState(props)
    const { open, onClose } = props;
    const { Panel } = Collapse;
    const [context, setState] = useContext(CrmContext)
    const [interesses, setInteresses] = useState<ILeadInteresseDTO[]>();
    const [historicos, setHistoricos] = useState<ILeadHistoricoDTO[]>();
    const [descricao, setDescricao] = useState<string>();
    const leadService = useLeadService();
    const historicoService = useLeadHistoricoService();
    const interesseService = useLeadInteresseService();
    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso detalhe lead",
        descricaoTipo: "Modal lead detalhe",
        idTipo: state.state.lead.id,
        id: null,
        descricaoUsuario: usuario.usuario,
        ativo: true
    });

    useEffect(() => {
        if (open === true) {
            logService.registraLog(log)
        }
    }, [open])

    const { Option } = Select;

    const [isOpenModalResposta, setIsOpenModalResposta] = useState(false);
    const [isOpenModalDuplicata, setIsOpenModalDuplicata] = useState(false);
    const [isOpenModalNote, setIsOpenModalNote] = useState(false);

    const { getTipoRetorno,
        getDsLabelTipoRetorno,
        getBoxColorTipoRetorno, } = useTipoRetornoState();
    const type = getTipoRetorno(state?.state.lead);

    useEffect(() => {
        //const findLead = () => {
        const predicate = new Predicate();
        predicate.addOption('lead.id', props.lead.id)
        if (props.open) {
            setIsGlobalLoading(true);
            historicoService.findList(predicate)
                .then((response) => setHistoricos(response.data))
                .catch((e) => console.log(e))
                .finally(() => setIsGlobalLoading(false));
            interesseService.findList(predicate)
                .then((response) => setInteresses(response.data))
                .catch((e) => console.log(e))
                .finally(() => setIsGlobalLoading(false));
        }
        //}
    }, [props.open])

    const onChangeModalResposta = () => {
        if (isOpenModalResposta === true) {
            setIsOpenModalResposta(false)
        } else {
            setIsOpenModalResposta(true)
        }
    }

    const onCloseModalResposta = () => {
        setIsOpenModalResposta(false)
    }

    const onChangeModalDuplicata = () => {
        if (isOpenModalDuplicata === true) {
            setIsOpenModalDuplicata(false)
        } else {
            setIsOpenModalDuplicata(true)
        }
    }

    const onCloseModalDuplicata = () => {
        setIsOpenModalDuplicata(false)
    }

    const onChangeModalNote = () => {
        if (isOpenModalNote === true) {
            setIsOpenModalNote(false)
        } else {
            setIsOpenModalNote(true)
        }
    }

    const onCloseModalNote = () => {
        setIsOpenModalNote(false)
    }

    const renderItemHistorico = (item: ILeadHistoricoDTO, index: number) => {
        const Icone = state.getHistoricoIcone(item);
        const IconeTipoContato = state.getHistoricoIconeTipoHistorico(item);
        const isIconeTipoContatoEmpty = !IconeTipoContato;
        const flSubdescricaoIdentica = item.tipoLeadHistorico.dsTipoLeadHistorico === item.dsHistorico;

        return <div key={item?.id} style={{ display: 'flex', flex: 1, marginTop: 5, marginBottom: 5 }} className={`lead-historico flex flex-row justify-start align-center ${index % 2 === 0 ? '' : props.boxColor}`}>
            <div className="dot"></div>
            <div className="box-name">
                <h6>{item?.usuario?.pessoa?.nome}</h6>
                <p>{moment(item.dataCriacao).format('DD/MM/YYYY hh:mm:ss')}</p>
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
                {flSubdescricaoIdentica && <div className="flex flex-row align-center box-status">
                    <Icone /> {state.getText(item.dsHistorico, item.id)} <span>{state.renderComponenteVerMais(item)}</span>
                </div>}

                {!flSubdescricaoIdentica && <div className="flex flex-column align-start box-status w-100">
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                        <h6><Icone /></h6>
                        <h6>{item.tipoLeadHistorico.dsTipoLeadHistorico}</h6>
                    </div>

                    <small
                        onClick={() => state.onDetalheClick(item)}
                        className='small-historico'
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                        {!isIconeTipoContatoEmpty && <IconeTipoContato size={15} style={{ marginRight: 5 }} />}

                        <small style={{ fontSize: 16, lineHeight: "25px" }}>
                            {state.getText(item.dsHistorico, item.id)}
                            <small style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                {state.renderComponenteVerMais(item)}
                            </small>
                        </small>
                    </small>
                </div>}
            </div>

        </div >
    }

    if (!open) return null

    function enviarMensagem() {
        const celular = `55${state?.state.lead?.cdDddCelular}${state?.state.lead?.cdCelular?.replace('-', '')}`;
        let texto = `Olá, ${state?.state.lead?.dsNomeLead}\nDesejo que esteja bem!\n\nSou da Happy ${state?.state.lead?.unidade?.descricao}, a escola do futuro!\nVi que você tem interesse em conhecer a gente. Por isso, estou entrando em contato contigo.\n\nFalta pouco para você conhecer o novo jeito de aprender!\nQual é o melhor dia e horário para nos fazer uma visita?\n\nAhh, você pode trazer a criança ou adolescente que deseja matricular para fazer uma aula experimental gratuita!😉`;

        texto = window.encodeURIComponent(texto);

        return window.open("https://web.whatsapp.com/send?phone=" + celular + "&text=" + texto, "_blank");
        //Obs.. use "_system", no lugar de blank, caso você esteja usando Phonegap / Cordova / Ionic ou qualquer um baseado em webview;
    }

    const onSearch = (searchText: string) => {
        setDescricao(searchText);
    };

    return (
        <>
            <div className="flex flex-row justify-center align-center modal-container">
                <div className={`flex flex-column justify-start align-start modal-leads${props.type}`}>
                    <Button onClick={onClose} className="button-x">x</Button>
                    <Form
                        style={{ maxWidth: '100%' }}
                        name="basic"
                        form={state.form}
                    >
                        <div className="flex flex-row justify-start align-center w-100 mb-20">
                            <h3 style={{ whiteSpace: 'nowrap', marginRight: 15 }}>{state?.state.lead?.dsNomeLead}</h3>
                            <div className="alin-cem-whatsapp">
                                {state?.state.lead?.cdCelular !== undefined ?
                                    <a onClick={enviarMensagem} rel="noreferrer">
                                        <Image className="crm-whatsapp" src={whatSapp} alt="telefone-lead" />
                                    </a>
                                    : null}
                            </div>
                            <div className="status">{getDsLabelTipoRetorno(type)}</div>
                            <div className="icons flex flex-row justify-start align-center w-100">
                                <Form.Item initialValue={state?.state.lead?.nrAvaliacaoLead} name={'nrAvaliacaoLead'}>
                                    <Rate
                                        defaultValue={state?.state.lead?.nrAvaliacaoLead}
                                    />
                                </Form.Item>
                                <Unicons.UilFileAlt style={{ cursor: 'pointer' }} onClick={onChangeModalNote} color="var(--cinza-azulado-200)" size={24} />
                            </div>
                        </div>
                        <div className="flex flex-row justify-between align-start">
                            <div className="w-40 mr-10">
                                <Space className="w-100" direction="vertical">
                                    <Collapse collapsible="header" defaultActiveKey={['1']}>
                                        <Panel header="Dados pessoais" key="1">
                                            <div className="box-collapse">

                                                <div className="text-over-input">
                                                    <span>ID<span className="vermelho"></span></span>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item initialValue={state?.state.lead?.id} name={'id'} rules={[{ required: false }]}>
                                                        <Input id="id" className="input" defaultValue={state?.state.lead?.id} disabled />
                                                    </Form.Item>
                                                </div>


                                                <div className="text-over-input">
                                                    <span>CPF / CNPJ<span className="vermelho"></span></span>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item initialValue={state?.state.lead?.cdCpfCnpj === null ? state?.state.lead?.cdCpfCnpj : ""} name={'cdCpfCnpj'} rules={[{ required: false, message: 'Insira o CPF / CNPJ' }]}>
                                                        <Input id="cdCpfCnpj" className="input" defaultValue={state?.state.lead?.cdCpfCnpj} />
                                                    </Form.Item>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Nome<small className="vermelho">*</small></small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item initialValue={state?.state.lead?.dsNomeLead} name={'dsNomeLead'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o nome completo' }]}>
                                                        <Input placeholder="Rosiane Camargo" bordered={false} defaultValue={state?.state.lead?.dsNomeLead} />
                                                    </Form.Item>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Email</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item initialValue={state?.state.lead?.cdEmail} name={'cdEmail'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o e-mail' }]}>
                                                        <Input placeholder="R.Camargo@emcil.com.br" bordered={false} defaultValue={state?.state.lead?.cdEmail} />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item name={['status', "id"]} style={{ display: 'none' }} />

                                                <div className="flex-1 flex">
                                                    <div className="text-over-input">
                                                        <small>Status<small className="vermelho">*</small></small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item
                                                            name={['status', "id"]}
                                                            fieldKey={['status', "nome"]}
                                                            initialValue={state?.state.lead?.status?.id}
                                                            style={{ width: '100%' }} rules={[{ required: false, message: 'Defina o status do Lead' }]}>
                                                            <Select style={{ width: '100%' }} bordered={false} defaultValue={state?.state.lead?.status?.id}>
                                                                {context.leadStatus?.map?.((status: ILeadStatusCleanDTO) => <Option key={status?.id} value={status?.id}>{status?.dsLeadStatus}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="flex" style={{ width: '20%' }}>
                                                        <div className="text-over-input">
                                                            <small>DDD</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item initialValue={state?.state.lead?.cdDddCelular}
                                                                name={'cdDddCelular'} style={{ width: '100%' }}
                                                                rules={[{ required: false, message: '' }]}>
                                                                <MaskedInput mask="11" defaultValue={state?.state.lead?.cdDddCelular} placeholder="99" bordered={false} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="horizontal-separator" />
                                                    <div className="flex flex-2">
                                                        <div className="text-over-input">
                                                            <small>Celular</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item initialValue={state?.state.lead?.cdCelular}
                                                                name={'cdCelular'} style={{ width: '100%' }}
                                                                rules={[{ required: false, message: 'Informe o celular' }]}>
                                                                <MaskedInput mask="11111-1111" defaultValue={state?.state.lead?.cdCelular}
                                                                    placeholder='Celular' bordered={false} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="flex" style={{ width: '20%' }}>
                                                        <div className="text-over-input">
                                                            <small>DDD</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item initialValue={state?.state.lead?.cdDddTelefone} name={'cdDddTelefone'} style={{ width: '100%' }}
                                                                rules={[{ required: false, message: '' }]}>
                                                                <MaskedInput mask="11" defaultValue={state?.state.lead?.cdDddTelefone} placeholder="99" bordered={false} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="horizontal-separator" />
                                                    <div className="flex flex-2">
                                                        <div className="text-over-input">
                                                            <small>Telefone</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item initialValue={state?.state.lead?.cdTelefone} name={'cdTelefone'}
                                                                style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o celular' }]}>
                                                                <MaskedInput mask="11111-1111" defaultValue={state?.state.lead?.cdTelefone}
                                                                    placeholder='Celular' bordered={false} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex-1 flex">
                                                    <div className="text-over-input">
                                                        <small>Campanha<small className="vermelho"></small></small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item
                                                            name={['campanha', "id"]}
                                                            fieldKey={['campanha', "nome"]}
                                                            initialValue={state?.state.lead?.campanha?.id}
                                                            style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a campanha' }]}>
                                                            <Select style={{ width: '100%' }} allowClear={true} optionFilterProp="children" showSearch bordered={false} defaultValue={state?.state.lead?.campanha?.id}>
                                                                {context.campanhas?.map?.((campanha: ILeadCampanhaCleanDTO) => <Option key={campanha.id} value={campanha.id}>{campanha.dsLeadCampanha}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="flex-1 flex">
                                                    <div className="text-over-input">
                                                        <small>Tipo Lead<small className="vermelho">*</small></small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item
                                                            name={['tipo', "id"]}
                                                            fieldKey={['tipo', "nome"]}
                                                            initialValue={state?.state.lead?.tipo?.id}
                                                            style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione o tipo do lead' }]}>
                                                            <Select style={{ width: '100%' }} bordered={false} defaultValue={state?.state.lead?.tipo?.id}>
                                                                {context.leadTipos?.map?.((leadTipo: ILeadTipoDTO) => <Option key={leadTipo.id} value={leadTipo.id}>{leadTipo.dsLeadTipo}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>


                                                <div className="text-over-input">
                                                    <small>Data Próximo Retorno</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={state?.state.lead?.dhProximoRetorno ? toMoment(state?.state.lead?.dhProximoRetorno) : undefined}
                                                        name={'dhProximoRetorno'} style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a data de retorno' }]}>
                                                        <DatePicker
                                                            // onChange={(value) => setCampo('dhProximoRetorno', value)}
                                                            placeholder="Data retorno"
                                                            suffixIcon={null}
                                                            format={'DD-MM-YYYY'}
                                                            // value={state?.state.lead?.dhProximoRetorno ? toMoment(state?.state.lead?.dhProximoRetorno) : undefined}
                                                            defaultValue={state?.state.lead?.dhProximoRetorno ? toMoment(state?.state.lead?.dhProximoRetorno) : undefined}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="flex-1 flex">
                                                    <div className="text-over-input">
                                                        <small>Estágio<small className="vermelho">*</small></small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item
                                                            name={['estagio', "id"]}
                                                            fieldKey={['estagio', "nome"]}
                                                            initialValue={state?.state.lead?.estagio?.id}
                                                            style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione o estágio do lead' }]}>
                                                            <Select id="estagio" style={{ width: '100%' }} bordered={false} defaultValue={state?.state.lead?.estagio?.id}>
                                                                {context.leadEstagios?.map?.((estagio: ILeadEstagioDTO) => <Option key={estagio?.id} value={estagio?.id}>{estagio?.dsLeadEstagio}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="flex-1 flex">
                                                    <div className="text-over-input">
                                                        <small>Origem<small className="vermelho">*</small></small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item
                                                            name={['origem', "id"]}
                                                            fieldKey={['origem', "nome"]}
                                                            initialValue={state?.state.lead?.origem?.id}
                                                            style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a origem do lead' }]}>
                                                            <Select id="origem" style={{ width: '100%' }} bordered={false} defaultValue={state?.state.lead?.origem?.id}>
                                                                {context.leadOrigens?.map?.((origem: ILeadOrigemDTO) => <Option key={origem.id} value={origem.id}>{origem.dsLeadOrigem}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="flex-1 flex">
                                                    <div className="text-over-input">
                                                        <small>Unidade<small className="vermelho"></small></small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item
                                                            name={['unidade', "id"]}
                                                            fieldKey={['unidade', "nome"]}
                                                            initialValue={state?.state.lead?.unidade?.id}
                                                            style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a unidade' }]}>
                                                            <Select style={{ width: '100%' }} bordered={false} defaultValue={state?.state.lead?.unidade?.id} disabled={true}>
                                                                {context.unidades?.map?.((unidade: IUnidadeCleanDTO) => <Option key={unidade.id} value={unidade.id}>{unidade.descricao}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse collapsible="header">
                                        <Panel header="Endereço" key="2">

                                            <Form.Item name={['endereco', "id"]} style={{ display: 'none' }} />

                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>CEP</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item name={['endereco', "cep"]}
                                                        initialValue={state?.state.lead?.endereco?.cep}
                                                        rules={[{ required: false, message: 'Insira o CEP' }]}>
                                                        <Input
                                                            id="cep"
                                                            defaultValue={state?.state.lead?.endereco?.cep}
                                                            onChange={e => state.onCepChange(e.target.value)}
                                                            className="input"
                                                            maxLength={8}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item name={['endereco', "pais", "id"]} style={{ display: 'none' }} />

                                                <div className="text-over-input">
                                                    <small>País</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        name={['endereco', "paisId"]}
                                                        fieldKey={['endereco', "nome"]}
                                                        initialValue={state?.state.lead?.endereco?.paisId}
                                                        rules={[{ required: false, message: 'Selecione um País' }]}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            bordered={false}
                                                            onSelect={(e) => state.handleChangePais(e)}
                                                            id="pais"
                                                            placeholder="Selecione um País"
                                                            allowClear
                                                            defaultValue={state?.state.lead?.endereco?.paisId}
                                                        >
                                                            {context.paises && context.paises.map((item, index) => (
                                                                <Select.Option key={index} value={item.id} >{item.nome}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Estado</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item name={['endereco', "nomeUF"]}
                                                        initialValue={state?.state.lead?.endereco?.nomeUF}
                                                        rules={[{ required: false, message: 'Selecione um Estado' }]}>
                                                        <Select
                                                            id="estado"
                                                            placeholder="Selecione um Estado"
                                                            allowClear
                                                            defaultValue={state?.state.lead?.endereco?.nomeUF}
                                                        >
                                                            {context.estados && context.estados.map((item) => (
                                                                <Select.Option key={item.id} value={item.nome}>{item.nome}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Cidade</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={state?.state.lead?.endereco?.cidade}
                                                        name={['endereco', "cidade"]} rules={[{ required: false, message: 'Selecione uma Cidade' }]}>
                                                        <Input
                                                            id="cidade"
                                                            className="input"
                                                            defaultValue={state?.state.lead?.endereco?.cidade}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="row">
                                                    <div className="flex flex-1">
                                                        <div className="text-over-input">
                                                            <small>Logradouro</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item
                                                                initialValue={state?.state.lead?.endereco?.logradouro}
                                                                name={['endereco', "logradouro"]} rules={[{ required: false, message: 'Insira o Logradouro' }]}>
                                                                <Input
                                                                    id="logradouro"
                                                                    className="input"
                                                                    defaultValue={state?.state.lead?.endereco?.logradouro}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="horizontal-separator" />
                                                    <div className="flex" style={{ width: '20%' }}>
                                                        <div className="text-over-input">
                                                            <small>Número</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item
                                                                initialValue={state?.state.lead?.endereco?.numero}
                                                                name={['endereco', "numero"]} rules={[{ required: false, message: 'Insira o Número' }]}>
                                                                <Input
                                                                    id="numero"
                                                                    className="input"
                                                                    defaultValue={state?.state.lead?.endereco?.numero}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Complemento</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={state?.state.lead?.endereco?.complemento}
                                                        name={['endereco', "complemento"]}>
                                                        <Input
                                                            id="complemento"
                                                            className="input"
                                                            defaultValue={state?.state.lead?.endereco?.complemento}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse collapsible="header">
                                        <Panel header="Produto" key="3">
                                            <div className="box-collapse">
                                                <div className="row flex-wrap">
                                                    {/* onClose={(e) => removerInteresse(interesse)}> */}
                                                    {interesses?.map?.((interesse) => {
                                                        return <Tag style={{ marginBottom: 10, padding: 10 }} key={interesse.id} closable>
                                                            {interesse.curso?.descricao} - R$ {interesse.vlLeadInteresse}
                                                        </Tag>;
                                                    })}
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </Space>

                            </div>
                            <div className="w-60 box-historico">
                                <div className="header-historico">
                                    <span>Histórico</span>
                                </div>
                                <div className="body-historico w-100 flex flex-row justify-start align-start">
                                    <div className="line-vertical flex justify-center align-start">
                                        <div className="line"></div>
                                    </div>
                                    <div className="leads-vertical w-90 flex flex-column">
                                        {historicos?.map(renderItemHistorico).reverse()}
                                    </div>
                                </div>
                                <div className="footer-historico flex flex-row justify-end align-center mt-30">
                                    {/* <Button onClick={onChangeModalDuplicata} className="button-duplicate">
                    <Unicons.UilSync size={14} className="mr-5" />
                    Possivel Duplicata
                  </Button> */}

                                    {state?.state.lead?.ativo && <Button className="button-primary" onClick={state.onAlterarLead}>
                                        Salvar
                                    </Button>}

                                    {state?.state.lead?.ativo && <Button onClick={onChangeModalResposta} className="button-descartar">
                                        <Unicons.UilThumbsDown size={14} className="mr-5" />
                                        Descartar
                                    </Button>}
                                    {state?.state.lead?.estagio?.cdLeadEstagio !== 'MTO' && state?.state.lead?.ativo && <Button className="button-venda" onClick={state.onMarcarVenda}>
                                        <Unicons.UilThumbsUp size={14} className="mr-5" />
                                        Matricular
                                    </Button>}
                                </div>
                            </div>
                        </div>

                    </Form>
                </div>
                <ModalResposta
                    open={isOpenModalResposta}
                    onClose={onCloseModalResposta}
                    status="Descartar lead"
                    text={`Deseja mesmo descartar as informações do lead "${state?.state.lead?.dsNomeLead}?"`}
                    onCancelar={onCloseModalResposta}
                    onOkay={state.onDescartarLead}
                />
                <ModalLeadDuplicata
                    open={isOpenModalDuplicata}
                    onClose={onCloseModalDuplicata}
                />
                <ModalNote
                    open={isOpenModalNote}
                    onClose={onCloseModalNote}
                    lead={state?.state.lead}
                />
            </div >
        </>
    )
}
