import useGetCobrancasByIdFinanceiro from '../../../../globalHooks/cobrancas/use-get-cobrancas-by-id-financeiro.hook';
import { useParams } from 'react-router-dom';
import useCalcularSaldoContratoAtual from './hooks/use-calcular-saldo-contrato-atual.hook';
import useGetTypeCurrentContractHook from './hooks/use-get-type-current-contract.hook';

export default function useStep3Hook() {
	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();

	const { cobrancasByIdFinanceiro } = useGetCobrancasByIdFinanceiro({
		idFinanceiro,
	});

	const { calcularSaldoContratoAtual } = useCalcularSaldoContratoAtual({
		cobrancasByIdFinanceiro,
	});
	const { getTypeCurrentContract } = useGetTypeCurrentContractHook({
		cobrancasByIdFinanceiro,
	});

	return {
		calcularSaldoContratoAtual,
		getTypeCurrentContract,
	};
}
