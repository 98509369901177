import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { TableState } from '../../enum/table-state.enum';

import ImgLogoStemplay from '../../assets/img/box-stemplay/logo.png';
import ModalRelatorioStemplay from '../../components/ModalRelatorioStemplay';
import { IAulaCustomDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMatriculaService } from '../../services/matricula.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import { IMatriculaFullDTO } from './../../models/happy-code-api.model';
import { useAulaService } from './../../services/aula.service';
import { useTurmaService } from './../../services/turma.service';
import './style.css';

export const StemplayRelatorioIndividual: React.FunctionComponent = () => {
    const { id } = useParams<any>();
    const { removeTurma } = useTurmaContext();
    const [turma, setTurma] = useState<ITurmaFullDTO>();
    const [listAula, setListAula] = useState<PageableResponse<IAulaCustomDTO>>();
    const [listMatricula, setListMatricula] = useState<PageableResponse<IMatriculaFullDTO>>();
    const [tableState, setTableState] = useState<TableState>(TableState.ALUNO);
    const { setIsGlobalLoading } = useGlobalContext();
    const turmaService = useTurmaService();
    const aulaService = useAulaService();
    const matriculaService = useMatriculaService();
    const [predicateAula] = useState<Predicate>(new Predicate());
    const [matriculaPredicate] = useState<Predicate>(new Predicate());
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    const findAula = () => {
        predicateAula.addOption('turma.id', id);
        return aulaService.findCustom(predicateAula)
            .then((response) => {
                setListAula(response.data);
            })
    }

    const findMatricula = () => {
        matriculaPredicate.addOption('turma.id', id);
        return matriculaService.findFull(matriculaPredicate)
            .then((response) => {
                setListMatricula(response.data);
            })
    }

    const findTurma = () => {
        return turmaService.findFullById({ id })
            .then((response) => {
                setTurma(response.data);
            });
    }


    useEffect(() => {
        removeTurma();
        if (id) {
            setIsGlobalLoading(true);
            Promise.all([
                findTurma(),
                findAula(),
                findMatricula()
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const statusTurma = (turma: ITurmaFullDTO) => {
        if (isBeforeDate(turma.dataInicio)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Não Iniciado</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        if (isDateBetween(turma.dataInicio, turma.dataFim)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Em andamento</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        if (isAfterDate(turma.dataFim)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Finalizado</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        return null;
    }

    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicateAula.setPage(page);
        setIsGlobalLoading(true);
        findAula().finally(() => setIsGlobalLoading(false));
    }

    const onPageChangeMatricula = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        matriculaPredicate.setPage(page);
        setIsGlobalLoading(true);
        findMatricula().finally(() => setIsGlobalLoading(false));
    }

    const qtdAulaRealizadas = () => {
        if (turma && turma.aulas) {
            return turma.aulas.filter((aula) => isBeforeDate(aula.data)).length;
        }
        return 0;
    }

    const getPorcentagem = () => {
        if (turma && turma.aulas) {
            return parseInt(((qtdAulaRealizadas() / turma.aulas.length) * 100).toPrecision(2));
        }
        return 0;
    }

    const arrReferencia = [{
        nome: 'Lucas',
        sobrenome: 'Silva',
        id: 1,
        fases: 30,
        tempo: '5h 20min 10s',
    },
    {
        nome: 'Matheus',
        sobrenome: 'Oliveira',
        id: 2,
        fases: 50,
        tempo: '10h 50min 12s',
    },
    {
        nome: 'Julia',
        sobrenome: 'Torres',
        id: 3,
        fases: 20,
        tempo: '3h 50min 20s',
    }, {
        nome: 'Bruna',
        sobrenome: 'Rodrigues',
        id: 4,
        fases: 50,
        tempo: '10h 0min 16s',
    }
    ]


    return (
        <div className="container-fondo">
            <NewMenu selecionado={30} />
            <div className="franquia" id="aluno-detalhe-row">
                <div className="franquia-row" style={{ marginTop: 70 }} id="row-aluno-box">
                    <div className="head-botoes">
                        <Link to="/Stemplay/progresso">
                            <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
                        </Link>
                        <h5 style={{ marginLeft: 15 }}>Relatórios Individuais</h5>
                        <div className="botao">
                            <Button className="button-primary" onClick={() => setIsOpen(true)}>Imprimir</Button>
                            <ModalRelatorioStemplay
                                open={isOpen}
                                onClose={() => setIsOpen(false)}
                                title='Atenção'
                                subtitle='Para imprimir colorido, ao clicar no botão "Imprimir", selecione "Mais Definições" na sua página de impressão e depois marque a caixa "Gráficos de Segundo Plano".'
                                textButton1='Voltar'
                                textButton2='Imprimir'
                                styleButton1='button-line'
                                styleButton2='button-danger'
                                link2='/Stemplay/turmas'
                                link1='/Stemplay/relatorio-individual'
                            />
                        </div>
                    </div>
                    <div className="box-data-aluno">
                        <div className="data-aluno" style={{ height: 'auto', width: '100%' }}>
                            <div className="relatorio-individual">
                                <img className="logo" src={ImgLogoStemplay} alt=''></img>
                            </div>
                            <div>
                                <div className="data-relatorio-individual">
                                    <h1>Nome da turma - Professor: Bruno</h1>
                                    <h1>Relatórios Individuais</h1>
                                </div>
                                <div className="data-relatorio-aluno">
                                    {arrReferencia.map((map) => (
                                        <div key={id} className="relatorio-stemplay-individual" id="relatorio-stemplay-individual">
                                            <div className="userRelatorioIndividual">
                                                <div className="column-relatorio-individual">
                                                    <p className="nome-relatorio">{map.nome} {map.sobrenome}</p>
                                                </div>
                                                <div className="column-relatorio-individual">
                                                    <p>{map.fases}% total de fases concluídas!</p>
                                                </div>
                                                <div className="column-relatorio-individual">
                                                    <p>{map.tempo} de tempo total jogado!</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StemplayRelatorioIndividual