import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICursoUnidadeDTO } from '../../models/happy-code-api.model';
import { useCursoUnidadeService } from '../../services/curso-unidade.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney } from '../../util/format';
import ModalCreate from '../ModalCreate/index';
import { Tags } from '../Tags';
import './style.css';

interface Props {
    setRestartOferta: React.Dispatch<React.SetStateAction<boolean>>;
    oferta: ICursoUnidadeDTO;
}

export const MenuDetalheCursoOferta: React.FunctionComponent<Props> = ({ setRestartOferta, oferta }: Props) => {
    const cursoUnidadeService = useCursoUnidadeService();
    const history = useHistory();
    const { curso, setRevisao } = useCursoContext();
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const rotaMatricula = location.pathname === `/cursos/oferta/step-1/${oferta?.id}`;
    const rotaRematricula = location.pathname === `/cursos/oferta/step-2/${oferta?.id}`;

    const saveOferta = () => {
        setIsOpen(true);
    }

    const patchOferta = () => {
        setIsGlobalLoading(true);
        cursoUnidadeService.patchCustom(oferta.id, {
            id: oferta.id,
            valorMensalidadeMinimo: oferta.valorMensalidadeMinimo,
            valorSplitMatricula: oferta.valorSplitMatricula,
            numeroParcelasMaximo: oferta.numeroParcelasMaximo,
            taxaMatricula: oferta.taxaMatricula,
            valorSplitTaxaMatricula: oferta.valorSplitTaxaMatricula,
            numeroProrrogacaoDiasMatricula: oferta.numeroProrrogacaoDiasMatricula,
            valorMensalidadeMinimoRematricula: oferta.valorMensalidadeMinimoRematricula,
            valorSplitRematricula: oferta.valorSplitRematricula,
            numeroParcelasMaximoRematricula: oferta.numeroParcelasMaximoRematricula,
            taxaRematricula: oferta.taxaRematricula,
            valorSplitTaxaRematricula: oferta.valorSplitTaxaRematricula,
            numeroProrrogacaoDiasRematricula: oferta.numeroProrrogacaoDiasRematricula,
        }).then(() => {
            saveOferta();
            history.push(`/cursos-oferta/${oferta?.curso?.id}`);
            notification({
                description: `Oferta cadastrada com sucesso.`,
                type: 'success',
                message: 'Sucesso!',
            });
        }).catch(() => {
            notification({
                description: `Desculpe, não foi possível cadastrar o oferta.`,
                type: 'error',
                message: 'Erro!',
            });
        }).finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        setRevisao(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const validarCampos = () => {
        if (rotaMatricula && oferta?.valorMensalidadeMinimo <= oferta?.valorSplitMatricula) {
            return notification({
                description: `Desculpe, não foi possível cadastrar o oferta da matrícula, valor do split (${formatMoney(oferta?.valorSplitMatricula)}) não pode ser maior que o preço mínimo (${formatMoney(oferta?.valorMensalidadeMinimo)}).`,
                type: 'error',
                message: 'Erro!',
            });
        }
        if (rotaRematricula && oferta?.valorMensalidadeMinimoRematricula <= oferta?.valorSplitRematricula) {
            return notification({
                description: `Desculpe, não foi possível cadastrar o oferta da rematrícula, valor do split (${formatMoney(oferta?.valorSplitRematricula)}) não pode ser maior que o preço mínimo (${formatMoney(oferta?.valorMensalidadeMinimoRematricula)}).`,
                type: 'error',
                message: 'Erro!',
            });
        }
        patchOferta();
    }

    return (
        <div className="menu-box-revisao-float">
            <div className="bloco-1">
                <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
                <h1 id="title">Hub: {oferta?.unidade?.descricao}</h1>
                <h1 id="title">{curso?.descricao}</h1>
                <div className="ativo">
                    <div className="dot-verde"></div>
                    <span>Ativo</span>
                </div>
                <h6 style={{ marginBottom: 0 }}>quant. de aulas</h6>
                <span>{curso?.duracao}</span>
                <h6 style={{ marginBottom: 0 }}>duraçãoem min</h6>
                <span>{curso?.duracaoMinutos} Mins</span>
                <div id="min-tag" style={{ marginBottom: 15 }}>
                    <Tags tipo={curso.modalidade.descricao} />
                </div>
                {rotaMatricula || rotaRematricula ?
                    <>
                        <Button
                            id="salvar"
                            style={{ width: 265, marginBottom: 5 }}
                            className="button-primary"
                            onClick={() => setRestartOferta(true)}>Default Oferta</Button>
                        <Button
                            id="salvar"
                            style={{ width: 265 }}
                            className="button-primary"
                            onClick={validarCampos}>Salvar</Button>
                    </>
                    : null}
            </div>
            <ModalCreate
                open={isOpen}
                title="Oferta salva com sucesso!"
                textButton2="Voltar a página de Cursos"
                styleButton2="button-primary"
                link2="/cursos"
            />
        </div>
    );
}