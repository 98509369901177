import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ISalaCleanDTO,
	ISalaDTO,
	ISalaFullDTO,
	ISalaRecursoCustomDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useSalaService = () => {
	const resourceURL = 'sala';

	const createCustom = (
		sala: ISalaDTO
	): Promise<AxiosResponse<any>> => {
		return http.post(`${resourceURL}/create-custom`, sala);
	};

	const findCustom = (
		salaId: number
	): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/find-custom/${salaId}`);
	};

	const patchCustom = (
		salaRecurso: ISalaRecursoCustomDTO
	): Promise<AxiosResponse<any>> => {
		return http.patch(`${resourceURL}/patch-custom`, salaRecurso);
	};

	return {
		...baseFind<ISalaCleanDTO, ISalaDTO, ISalaFullDTO>(resourceURL),
		...baseFindList<ISalaCleanDTO, ISalaDTO, ISalaFullDTO>(
			resourceURL
		),
		...baseUpdate<ISalaCleanDTO, ISalaDTO, ISalaFullDTO>(
			resourceURL
		),
		...baseFindById<ISalaCleanDTO, ISalaDTO, ISalaFullDTO>(
			resourceURL
		),
		...baseCreate<ISalaCleanDTO, ISalaDTO, ISalaFullDTO>(
			resourceURL
		),
		createFull: (body): Promise<IAxiosResponseCustom<ISalaCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patchFull: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<ISalaCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		createCustom,
		findCustom,
		patchCustom,
	};
};
