import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	INegocioCleanDTO,
	IStatusCobrancaCleanDTO,
	ITipoCurriculoCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useNegocioService } from '../../../services/negocio.service';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useStatusCobrancaService } from '../../../services/status-cobranca.service';
import { useTipoCurriculoService } from '../../../services/tipo-curriculo.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { getPeriodo } from '../../../util/filtroRelatorio';
import { hoje } from '../../../util/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelMatriculaSedeCurriculoPrematriculado() {
	const dataPadrao = new Date(2023, 10, 5);
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const [dados, setData] = useState<[]>([]);
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [dataMatriculaInicio, setDataMatriculaInicio] = useState<moment.Moment>(
		moment(moment(hoje()))
	);
	const [dataMatriculaFim, setDataMatriculaFim] = useState<moment.Moment>(
		moment(moment(hoje()))
	);
	const [statusCobranca, setStatusCobranca] =
		useState<IStatusCobrancaCleanDTO[]>();
	const [statusCobrancaId, setStatusCobrancaId] = useState<number | null>(0);
	const [dataPagoInicio, setDataPagoInicio] = useState<moment.Moment>();
	const [dataPagoFim, setDataPagoFim] = useState<moment.Moment>();
	const [predicate] = useState<Predicate>(new Predicate());
	const [negocios, setNegocios] = useState<INegocioCleanDTO[]>();
	const [negocioDescricao, setNegocioDescricao] = useState<string | null>();
	const [tipoCurriculos, setTipoCurriculos] =
		useState<ITipoCurriculoCleanDTO[]>();
	const [tipoCurriculoDescricao, setTipoCurriculoDescricao] = useState<
		string | null
	>();
	const [matricula, setMatricula] = useState();

	const service = useRelatorioService();
	const negocioService = useNegocioService();
	const tipoCurriculoService = useTipoCurriculoService();
	const statusCobrancaService = useStatusCobrancaService();

	const findStatusCobranca = () => {
		return statusCobrancaService
			.findCleanList(predicate)
			.then((response) => setStatusCobranca(response.data));
	};

	const findNegocio = () => {
		return negocioService
			.findCleanList()
			.then((response) => setNegocios(response.data));
	};

	const findTipoCurriculo = () => {
		return tipoCurriculoService
			.findCleanList()
			.then((response) => setTipoCurriculos(response.data));
	};

	const filtrarMatricula = () => {
		let filtro;
		if (matricula === null || matricula === undefined || matricula === '') {
			filtro = '';
		}
		if (matricula === 0) {
			filtro = 0;
		}
		if (matricula === 1) {
			filtro = 1;
		}
		return filtro;
	};

	useEffect(() => {
		setIsGlobalLoading(true);
		predicate.removeAllOption();

		if (dataMatriculaInicio) {
			predicate.addOption(
				'dataMatriculaInicio',
				dataMatriculaInicio.toISOString()
			);
		} else {
			predicate.addOption(
				'dataMatriculaInicio',
				moment(dataPadrao, 'YYYY-MM-DD').toISOString()
			);
		}
		dataMatriculaInicio &&
			predicate.addOption(
				'dataMatriculaInicio',
				dataMatriculaInicio.toISOString()
			);
		dataMatriculaFim &&
			predicate.addOption('dataMatriculaFim', dataMatriculaFim.toISOString());
		dataPagoInicio &&
			predicate.addOption('dataPagoInicio', dataPagoInicio.toISOString());
		dataPagoFim &&
			predicate.addOption('dataPagoFim', dataPagoFim.toISOString());
		unidade?.id && predicate.addOption('idUnidade', unidade?.id);
		predicate.addOption('flRematricula', filtrarMatricula());
		negocioDescricao && predicate.addOption('negocio', negocioDescricao);
		tipoCurriculoDescricao &&
			predicate.addOption('tipoCurriculo', tipoCurriculoDescricao);

		if (statusCobrancaId != null) {
			statusCobrancaId >= 0 &&
				predicate.addOption('idStatusCobranca', statusCobrancaId);
		}

		service
			.matriculaSedeCurriculoPrematriculado(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));

		findStatusCobranca();
		findNegocio();
		findTipoCurriculo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dataMatriculaFim,
		dataMatriculaInicio,
		dataPagoInicio,
		dataPagoFim,
		statusCobrancaId,
		matricula,
		negocioDescricao,
		tipoCurriculoDescricao,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
	}, [dados]);

	const disabledDate = (current) => {
		return current && current < moment('2023-11-05', 'YYYY-MM-DD');
	};

	const getPeriodoPago = (): any => {
		if (dataPagoInicio && dataPagoFim) {
			return [
				moment(dataPagoInicio, 'YYYY-MM-DD'),
				moment(dataPagoFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const selectStatusCobranca = statusCobranca?.map((statusCobranca) => (
		<Option key={statusCobranca.id} value={statusCobranca.id}>
			{statusCobranca.descricao}
		</Option>
	));
	const selectNegocio = negocios?.map((negocio) => (
		<Option key={negocio.id} value={negocio.descricao}>
			{negocio.descricao}
		</Option>
	));
	const selectTipoCurriculo = tipoCurriculos?.map((tipoCurriculo) => (
		<Option key={tipoCurriculo.descricao} value={tipoCurriculo.descricao}>
			{tipoCurriculo.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={18} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório Pré-Matriculado Novo Modelo (Após 05/11)
				</h1>
				<div
					className="flex flex-row justify-start items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Período de matrículas</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataMatriculaInicio(values && values[0]);
								setDataMatriculaFim(values && values[1]);
							}}
							value={getPeriodo(
								dataMatriculaInicio,
								dataMatriculaFim,
								dataPadrao
							)}
							disabledDate={disabledDate}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Período de pagamento</span>
						<br />
						<RangePicker
							value={getPeriodoPago()}
							onChange={(values) => {
								setDataPagoInicio(values && values[0]);
								setDataPagoFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
				</div>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Tipo Contrato</span>
						<br />
						<Select
							allowClear
							showSearch
							style={filtroStyle}
							placeholder={'Selecione'}
							defaultValue={matricula}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setMatricula(e)}
						>
							<Option value="">Todos</Option>
							<Option value={0}>Matrícula</Option>
							<Option value={1}>Rematrícula</Option>
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Negócio</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							placeholder={'Selecione'}
							defaultValue={negocioDescricao}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setNegocioDescricao(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectNegocio}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Tipo Currículo</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							placeholder={'Selecione'}
							defaultValue={tipoCurriculoDescricao}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setTipoCurriculoDescricao(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectTipoCurriculo}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Status da Cobrança</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={statusCobrancaId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setStatusCobrancaId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							<Select.Option value={0}>Todos</Select.Option>
							<Select.Option value={99999}>Vencidos</Select.Option>
							{selectStatusCobranca}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={dados}
							fileName={
								`Matrícula diária sede Currículo ` + hoje().toISOString()
							}
						/>
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}
export default RelMatriculaSedeCurriculoPrematriculado;
