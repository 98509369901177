import { useHistory } from 'react-router-dom';

export default function useGoBackOnPage() {
	const history = useHistory();

	const goBackOnPage = () => history.goBack();

	return {
		goBackOnPage,
	};
}
