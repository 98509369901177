import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Col, Progress, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import MenuDetalheCampanha from '../../components/MenuDetalheCampanha/index';
import { useCampanhaContext } from '../../context/CampanhaContext';

import { formattedDate } from '../../util/format';
import './style.css';

const CampanhasCreateStep6: React.FunctionComponent = () => {

  const { campanha, setRevisao } = useCampanhaContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = useParams<any>();

  const history = useHistory();
  const goTo = (url) => history.push(url);

  useEffect(() => {
    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
      </div>
      {/* <MenuPrincipal selecionado="5" /> */}
      <MenuDetalheCampanha id={id || campanha.id} />
      <div className="franquia" style={{ top: 70, backgroundColor: '#F5F5F9' }}>
        <div className="franquia-row">
          <h5>Revisão de dados</h5>
          <div className="row-revisao">
            {/* <ul className="menu-revisao-detalhe">
              <li className="selected" style={{width:'20%'}}>
                Dados Básicos
              </li>
              <li style={{width:'20%'}}>
                Periodo de vigência
              </li>
              <li style={{width:'20%'}}>
                Descontos
              </li>
              <li style={{width:'20%'}}>
                Local de Oferta
              </li>
              <li style={{width:'20%'}}>
                Cursos
              </li>
            </ul> */}
            <div className="box-revisao">
              <div className="dados">
                <Button className="button-line" id="editar-1" onClick={() => goTo("/campanhas/create/step-1")}>Editar</Button>
                <h4>Dados Básicos</h4>
                <div className="nome">
                  <h5>Nome</h5>
                  <span style={{ fontSize: 18 }}>{campanha.codigo}</span>
                </div>
                <div className="dados-franquia">
                  <div className="cpf" style={{ marginRight: 0 }}>
                    <h6>DESCRIÇÃO</h6>
                    <span>{campanha.descricao}</span>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button className="button-line" id="editar-2" onClick={() => goTo("/campanhas/create/step-2")}>Editar</Button>
                <h4>Período de vigência</h4>
                <div id="data-info">
                  <div className="box-data">
                    <h5>Inicio</h5>
                    <p>{formattedDate(campanha.dataInicio)}</p>
                  </div>
                  <Unicons.UilArrowRight size="20" color="#6A7C96" />
                  <div className="box-data" style={{ marginLeft: 10 }}>
                    <h5>Fim</h5>
                    <p>{formattedDate(campanha.dataFim)}</p>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button className="button-line" id="editar-3" onClick={() => goTo("/campanhas/create/step-3")}>Editar</Button>
                <h4>Percentuais de desconto(%).</h4>
                <div id="data-info">
                  <Row>
                    {campanha.campanhaPlanoPagamentos.map(campanhaPlanoPagamento => (
                      <Col span={6} key={campanhaPlanoPagamento.planoPagamentoId}>
                        <Text>{campanhaPlanoPagamento?.planoPagamentoDescricao}: {campanhaPlanoPagamento.percentual}%</Text>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button className="button-line" id="editar-4" onClick={() => goTo("/campanhas/create/step-4")}>Editar</Button>
                <h4>Onde esse curso será criado?</h4>
                {campanha.campanhaPaises.length > 0 ?
                  <>
                    <h4>Paises</h4>
                    {campanha.campanhaPaises.map((campanhaPais) =>
                      <div id="curso-campanhas" key={campanhaPais.id}>
                        <h5>{campanhaPais.paisNome}</h5>
                      </div>)}
                  </>
                  :
                  campanha.campanhaClusters.length > 0 ?
                    <>
                      <h4>Clusters</h4>
                      {campanha.campanhaClusters.map((campanhaCluster) =>
                        <div id="curso-campanhas" key={campanhaCluster.id}>
                          <h5>{campanhaCluster.clusterCodigo}</h5>
                        </div>)}
                    </>
                    : campanha.campanhaUnidades.length > 0 &&
                    <>
                      <h4>Franquias</h4>
                      {campanha.campanhaUnidades.map((campanhaUnidade) =>
                        <div id="curso-campanhas" key={campanhaUnidade.id}>
                          <h5>{campanhaUnidade.unidadeDescricao}</h5>
                        </div>)}
                    </>
                }
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button className="button-line" id="editar-5" onClick={() => goTo("/campanhas/create/step-5")}>Editar</Button>
                <h4>Curso onde será aplicada</h4>
                <div id="box-campanha">
                  {campanha.campanhaCursos.map(cc => (
                    <div id="curso-campanhas" key={cc.id}>
                      {/* <h5>Happy School</h5> */}
                      <div id="user-franquias-revisao">
                        <h4>{cc.cursoDescricao}</h4>
                        <p>{cc.cursoNegocioDescricao}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="botoes" style={{ paddingBottom: 30 }}>
            <Link to="/campanhas/create/step-5">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <Link to="/campanhas">
              <Button className="button-primary" style={{ width: 345 }}>Próximo</Button>
            </Link>
          </div> */}
        </div>
      </div>
      <div className="botao-voltar-revisao">
        <Button className="button-second" id="revisao" icon={<ArrowLeftOutlined rev={undefined} />} onClick={() => history.goBack()}>
          Voltar
        </Button>
      </div>
    </div>
  )
}

export default CampanhasCreateStep6;