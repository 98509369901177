import { useState } from 'react'
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import card from '../../../assets/img/franquias/card.svg';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import '../style.css';
import StepsCreate from './steps-create';
import { useNotificationService } from '../../../services/notification.service';

export const FranquiaCreateStep4: React.FunctionComponent = () => {
  const { unidade, revisao } = useUnidadeContext();
  const notification = useNotificationService();
  const history = useHistory();

  const goToStep7 = () => {
    if (unidade && unidade.responsavelLegal.id && unidade.gestor.id) {
      history.push("/franquias/create/step-7");
    }
    else {
			if (!unidade.responsavelLegal.id) {
				notification({ description: `Selecione o responsável legal!`, type: 'warning', message: 'Aviso!' });
        return
			}
			if (!unidade.gestor.id) {
				notification({ description: `Selecione o gestor!`, type: 'warning', message: 'Aviso!' });
        return
			}
		}
  }

  const renderResponsavelLegal = (nome) => {
    return (
      <div className="card">
        <div className="check">
          <Unicons.UilCheck size="20" color="#FFFFFF" />
        </div>
        <h4>Responsável legal </h4>
        <div className="dados-box">
          <div className="circle-avatar"></div>
          <div className="dados-responsavel">
            <h4>{nome}</h4>
            {/* <span>Ver mais detalhes</span> */}
          </div>
        </div>
        <Link to="/franquias/create/responsavel">
          <Button className="button-line" style={{ width: 310 }}>Alterar</Button>
        </Link>
      </div>
    )
  }

  const renderResponsavelSocio = (nome) => {
    return (
      <div className="card">
        <div className="check">
          <Unicons.UilCheck size="20" color="#FFFFFF" />
        </div>
        <h4>Sócio</h4>
        <div className="dados-box">
          <div className="circle-avatar"></div>
          <div className="dados-responsavel">
            <h4>{nome}</h4>
            {/* <span>Ver mais detalhes</span> */}
          </div>
        </div>
        <Link to="/franquias/create/socio">
          <Button className="button-line" style={{ width: 310 }}>Alterar</Button>
        </Link>
      </div>
    )
  }

  const renderGestor = (nome) => {
    return (
      <div className="card">
        <div className="check">
          <Unicons.UilCheck size="20" color="#FFFFFF" />
        </div>
        <h4>Gestor</h4>
        <div className="dados-box">
          <div className="circle-avatar"></div>
          <div className="dados-responsavel">
            <h4>{nome}</h4>
            {/* <span>Ver mais detalhes</span> */}
          </div>
        </div>
        <Link to="/franquias/create/gestor">
          <Button className="button-line" style={{ width: 310 }}>Alterar</Button>
        </Link>
      </div>
    )
  }
  
  return (
    <div className="container">
      <Header />
      <Progress />
      <StepsCreate current={3} />
      <div className="franquia" style={{ top: 70 }}>
        <div className="franquia-row">
          <h5>Responsáveis</h5>
          <div className="box-cards" style={{ flexWrap: 'wrap' }}>
            {unidade?.responsavelLegal?.id ? (
              renderResponsavelLegal(unidade.responsavelLegal.nome)
            ) : (
              <div className="card">
                <h4>Responsável legal <span id="ob">*</span></h4>
                <img src={card} alt={"Card"} className="" />
                <p>Ops, você ainda não adicionou nenhum usuário aqui</p>
                <Link to="/franquias/create/responsavel">
                  <div id="criar-responsavel" className="button-primary" style={{ width: 310 }}>
                    <Unicons.UilPlus size="20" color="#FFFFFF" />
                    <span>Adicionar Responsável Legal</span>
                  </div>
                </Link>
              </div>
            )}
            {unidade?.gestor?.id ? (
              renderGestor(unidade.gestor.nome)
            ) : (
              <div className="card">
                <h4>Gestor <span id="ob">*</span></h4>
                <img src={card} alt={"Card"} />
                <p>Ops, você ainda não adicionou nenhum usuário aqui</p>
                <Link to="/franquias/create/gestor">
                  <div id="criar-gestor" className="button-primary" style={{ width: 310 }}>
                    <Unicons.UilPlus size="20" color="#FFFFFF" />
                    <span>Adicionar Gestor</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <h5>Sócios</h5>
          <div className="box-cards" style={{ flexWrap: 'wrap' }}>
            {unidade?.unidadePessoas.filter(pessoa => pessoa.tipoUnidadePessoaDescricao === "Sócio").map(socio =>
              renderResponsavelSocio(socio.pessoaNome)
            )}
            <div className="card" style={{ marginTop: '10xp' }}>
              <h4>Sócio</h4>
              <img src={card} alt={"Card"} />
              <p>Ops, você ainda não adicionou nenhum usuário aqui</p>
              <Link to="/franquias/create/socio">
                <div id="criar-gestor" className="button-primary" style={{ width: 310 }}>
                  <Unicons.UilPlus size="20" color="#FFFFFF" />
                  <span>Adicionar sócio</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="botoes">
            {revisao ?
              <Link to="/franquias/create/step-10">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 490 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/franquias/create/step-3">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <button onClick={() => goToStep7()} style={{ width: 345 }} className="button-primary" id="proximo"><span>Próximo</span></button>
              </>
            }
          </div>
        </div>
      </div>
      <Link to="/franquias">
        <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
      </Link>
    </div>
  )
}
