import { useGlobalContext } from '../context/GlobalContext';
import { PerfilEnum } from '../enum/perfil.enum';

export default function useProfileIsFranquia() {
	const { usuario } = useGlobalContext();

	const thisProfileIsSede = usuario?.perfil === PerfilEnum.FRANQUIA;

	return thisProfileIsSede;
}
