/* Graficos */
import { Column, Pie } from '@ant-design/charts';
import * as Unicons from '@iconscout/react-unicons';
import { Button, DatePicker } from 'antd';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Gold from '../../assets/img/dashboard/gold.svg';
import Hand from '../../assets/img/dashboard/hand.svg';
import FondoLeft from '../../assets/img/dashboard/left.svg';
import FondoRigth from '../../assets/img/dashboard/rigth.svg';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';

import { IAulaDTO, IPessoaAvaliacaoSistemaDTO, ITurmaFullDTO, IUsuarioDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAulaService } from '../../services/aula.service';
import { useTurmaService } from '../../services/turma.service';
import { useUsuarioService } from '../../services/usuario.service';
import { formattedDate, isAfterDate, removeAcentos } from '../../util/format';
import { dateDifferenceText, differenceInYears, isThisWeek, isToday } from '../../util/util';
import './style.css';

import { Rate } from 'antd';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { usePessoaAvaliacaoSistemaService } from '../../services/pessoa-avaliacao-sistema.service';
import { useNotificationService } from '../../services/notification.service';

const customIcons = {
	1: <FrownOutlined rev={undefined} />,
	2: <FrownOutlined rev={undefined} />,
	3: <MehOutlined rev={undefined} />,
	4: <SmileOutlined rev={undefined} />,
	5: <SmileOutlined rev={undefined} />,
};

export const Instrutor: React.FunctionComponent = () => {
	const [turmaGraphConfig, setTurmaGraphConfig] = useState({
		width: 340,
		height: 160,
		padding: 10,
		data: [],
		autoFit: false,
		angleField: 'value',
		colorField: 'type',
		radius: 1,
		innerRadius: 0.6,
	});

	const [semanaGraphConfig, setSemanaGraphConfig] = useState({
		data: [],
		height: 150,
		width: 700,
		xField: 'diaDaSemana',
		yField: 'qtd',
	});

	const [currentUser, setCurrentUser] = useState<IUsuarioDTO>();
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
	const [weekList, setWeekList] = useState<
		{
			diaTexto: string;
			dia: number;
		}[]
	>([]);

	const { usuario, setIsGlobalLoading, unidade } = useGlobalContext();
	const [turmaList, setTurmaList] = useState<ITurmaFullDTO[]>([]);
	const [aulaList, setAulaList] = useState<IAulaDTO[]>([]);
	const [todayAulaList, setTodayAulaList] = useState<IAulaDTO[]>([]);
	const [showRate, setShowRate] = useState<boolean>(false);
	const [rate, setRate] = useState<number>(3);

	const usuarioService = useUsuarioService();
	const turmaService = useTurmaService();
	const aulaService = useAulaService();
	const pessoaAvaliacaoService = usePessoaAvaliacaoSistemaService();

	const notification = useNotificationService();

	const dayHours = Array(24)
		.fill(24 - new Date().getHours() + ':00')
		.map((_, i) => i + ':' + '00');

	const fetchPessoa = async () => {
		const usuarioId = usuario?.id;
		return usuarioService.findById({ id: usuarioId }).then(({ data }) => {
			setCurrentUser(data);
			return data;
		});
	};

	const fetchTurmasByPessoaId = async (idPessoa: number) => {
		const predicate = new Predicate();
		predicate.addOption('unidadePessoa.pessoaId', idPessoa);
		predicate.addOption('unidadePessoa.unidadeId', unidade?.id);
		predicate.addOption('ativo', true);
		return turmaService.findFull(predicate).then(({ data }) => data.content);
	};

	const fetchAulasByPessoaId = async (idPessoa: number, predicate = new Predicate()) => {
		predicate.size = 100;

		return aulaService.findByTurmaUnidadeIdAndPessoaId(unidade?.id, idPessoa).then(({ data }) => data);
	};

	const fetchPessoaAndTurmas = async () => {
		try {
			const currUser = await fetchPessoa();
			const turmasRes = await fetchTurmasByPessoaId(currUser.pessoa.id);
			setTurmaList(turmasRes);
			const aulasRes = await fetchAulasByPessoaId(currUser.pessoa.id);
			setAulaList(aulasRes);
			return Promise.resolve('Fetched');
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const getCurrentDate = (format: string) => {
		moment.locale('pt-br');
		const currentDate = moment().format(format);

		return currentDate;
	};

	const generateWeekDates = (current = new Date()) => {
		const week: Date[] = [];
		current.setDate(current.getDate() - current.getDay());
		for (let i = 0; i < 7; i++) {
			week.push(new Date(current));
			current.setDate(current.getDate() + 1);
		}

		const t = week.map((day) => {
			const str = moment(day).format('DD, dddd');

			const splitted = str.split(',');

			return {
				diaTexto: removeAcentos(splitted[1].trim().slice(0, 3)).toUpperCase(),
				dia: parseInt(splitted[0]),
			};
		});

		return t;
	};

	const handleDatePicker = async (dataSelecionada: moment.Moment) => {
		if (!dataSelecionada) return;
		setSelectedDate(dataSelecionada);
		setWeekList(generateWeekDates(dataSelecionada.toDate()));

		const predicate = new Predicate();

		predicate.addOption('calendario.data', dataSelecionada.format('YYYY-MM-DD'), Operators.EQUAL);

		setIsGlobalLoading(true);
		try {
			const aulas = await fetchAulasByPessoaId(currentUser.pessoa.id, predicate);
			setTodayAulaList(aulas);
			setIsGlobalLoading(false);
			return;
		} catch (error) {
			setIsGlobalLoading(false);

			console.error(error);
			return;
		}
	};

	useEffect(() => {
		setWeekList(generateWeekDates());
	}, []);

	useEffect(() => {
		if (usuario && usuario.id) {
			const requests = [fetchPessoaAndTurmas()];
			setIsGlobalLoading(true);

			Promise.all(requests).finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usuario.id]);

	useEffect(() => {
		const res = Object(groupBy(turmaList, 'curso.descricao'));
		const graphData: { turma: string; numeroDeTurmas: number }[] = [];

		Object.keys(res).map((key) => {
			graphData.push({
				numeroDeTurmas: res[key].length,
				turma: key,
			});
		});

		let qtdTurma = 0;
		graphData.map((e) => {
			qtdTurma += e.numeroDeTurmas;
		});

		const config = {
			width: 340,
			height: 160,
			padding: 10,
			data: graphData,
			autoFit: false,
			angleField: 'numeroDeTurmas',
			colorField: 'turma',
			radius: 1,
			innerRadius: 0.6,
			label: {
				type: 'inner',
				offset: '-50%',
				content: '{value}',
				style: {
					textAlign: 'center',
					fontSize: 14,
				},
			},
			interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
			statistic: {
				title: false,
				content: {
					style: {
						whiteSpace: 'pre-wrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						width: '100',
					},
					content: qtdTurma,
				},
			},
		};
		setTurmaGraphConfig(config);
	}, [turmaList]);

	useEffect(() => {
		const graphData: { diaDaSemana: string; turma: string; qtd: number }[] = [];
		const res = Object(groupBy(orderBy(aulaList, 'calendario.data'), 'calendario.data'));

		Object.keys(res).forEach((key) => {
			if (isToday(key)) {
				setTodayAulaList(res[key]);
			}

			if (isThisWeek(key)) {
				res[key].forEach((aula: IAulaDTO) => {
					const diaSemana = removeAcentos(aula.calendario.nomeDiaSemana.slice(0, 3)).toUpperCase();

					const turmaName = aula.turma.descricao;
					const qd = res[key].length;

					const graphItem = {
						diaDaSemana: diaSemana,
						turma: turmaName,
						qtd: qd,
					};

					if (!graphData.find((gData) => gData.diaDaSemana === diaSemana && gData.qtd === qd && gData.turma === turmaName)) {
						graphData.push(graphItem);
					}
				});
			}
		});

		const config = {
			data: graphData,
			height: 150,
			width: 450,
			xField: 'diaDaSemana',
			yField: 'qtd',
			xAxis: {
				label: {
					autoHide: true,
					autoRotate: false,
				},
			},
			meta: {
				diaDaSemana: { alias: 'Dia da semana' },
				turmas: { alias: 'Nome da turma' },
				qtd: { alias: 'Turmas ativas' },
			},
			minColumnWidth: 5,
			maxColumnWidth: 5,
			columnStyle: {
				radius: [20, 20, 0, 0],
			},
			isStack: true,
			seriesField: 'turma',
			groupField: 'diaDaSemana',
		};

		setSemanaGraphConfig(config);
	}, [aulaList]);

	const handleAvaliacaoClick = async () => {
		setShowRate(false);
		const payload: IPessoaAvaliacaoSistemaDTO = {
			ativo: true,
			numero: rate,
			id: null,
			pessoa: currentUser.pessoa,
		};
		try {
			await pessoaAvaliacaoService.create(payload);

			notification({
				//  description: '',
				type: 'success',
				message: 'Feedback Enviado!',
			});
		} catch (error) {
			console.error(error);
		}
	};

	const renderTurmaCard = (hour: string) => {
		const aulas = todayAulaList?.filter((aula) => aula.horario.horaInicio == `${hour}:00`);

		if (aulas.length > 0) {
			return aulas.map((aula) => (
				<div key={aula.id} className="aulas">
					<div className="aula">
						<div className="inset-red"></div>
						<h3>{aula.turma?.descricao}</h3>
						<Tags tipo={aula.turma?.curso?.descricao} />
						<p>{aula.turma?.unidadePessoa?.pessoaNome}</p>
					</div>
				</div>
			));
		}
	};

	return (
		<div className="container" style={{ backgroundColor: '#EBEFFC' }}>
			<NewMenu selecionado={14} />
			<div className="instrutor">
				<img src={FondoLeft} alt="" className="img-left" />
				<div className="fondo-rigth">
					<img src={FondoRigth} alt="" />
					<div className="fondo-blue"></div>
				</div>
				<div className="dashboard-instrutor">
					<div className="head">
						<div className="info">
							<h4>
								<img src={Hand} /> Olá, <strong>{currentUser?.pessoa.nome}</strong>
							</h4>
							<h6>Hoje</h6>
							<p>{getCurrentDate(`DD MMMM YYYY, dddd`)}</p>
						</div>
						<div className="jornada">
							<h6>
								<img src={Gold} /> Sua jornada conosco
							</h6>
							<p>{dateDifferenceText(currentUser?.pessoa?.dataAdmissao)}</p>
						</div>
					</div>
					<div className="head">
						<div className="prodata">
							<h4>Minhas turmas ativas</h4>
							<div className="data">
								<Pie {...turmaGraphConfig} />
							</div>
						</div>
						<div className="data-line">
							<h4>Happy Semana </h4>
							<div className="lines">
								<Column {...semanaGraphConfig} />
							</div>
						</div>
					</div>
					<div className="body-data">
						<div className="left">
							<div className="calendar">
								<div className="cal-head">
									<DatePicker style={{ width: '100%' }} format={'DD MMMM, YYYY'} onChange={(e) => handleDatePicker(e)} defaultValue={selectedDate} />
									<div className="days">
										{weekList.length > 0 &&
											weekList.map((day) => (
												<div className={parseInt(selectedDate.format('DD')) === day.dia ? 'day-selected' : 'day'} key={day.dia}>
													<h4>{day.diaTexto}</h4>
													<h3>{day.dia}</h3>
												</div>
											))}
									</div>
								</div>
								<div className="cal-body">
									<div className="title">
										<h3>{getCurrentDate(`dddd, DD MMMM, YYYY`)}</h3>
										<h2>Você tem {todayAulaList.length} aulas hoje</h2>
									</div>
									<div className="cards">
										{dayHours.map((hour) => (
											<div className="card" key={hour}>
												<h6>{hour}</h6>
												<div style={{ width: '-webkit-fill-available' }}>{todayAulaList.length > 0 && renderTurmaCard(hour)}</div>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="pesquisa">
								<h4>Queremos te ouvir! Sua opinião é fundamental para uma Happy melhor!</h4>
								{!showRate && (
									<Button className="button-primary" style={{ width: 700 }} onClick={() => setShowRate(true)}>
										Registrar Opinião
									</Button>
								)}
								{showRate && (
									<>
										<Rate
											defaultValue={rate}
											character={({ index }) => customIcons[index + 1]}
											onChange={(rateSelected) => setRate(rateSelected)}
											style={{ fontSize: 50, marginBottom: 5 }}
										/>
										<Button className="button-primary" style={{ width: 700 }} onClick={handleAvaliacaoClick}>
											Enviar Feedback
										</Button>
									</>
								)}
							</div>
						</div>
						<div className="right">
							<div className="gestao">
								<Link to="/turmas/professor" className="title">
									<h4>Gestão de aulas</h4>
									<Unicons.UilArrowRight color="var(--primary-color)" />
								</Link>
								<div className="info">
									<Unicons.UilExclamationTriangle color="#ECD60F" />
									<p>{`Você tem ${(aulaList.length > 0 && aulaList.filter((aula) => isAfterDate(aula.data)).length) || 0} aula(s) sem lançamento`}</p>
								</div>
							</div>
							<div className="turmas">
								<div className="title">
									<h4>Turmas em andamento</h4>
									<Link to="/turmas/professor">
										<h5>
											Ver todas
											<Unicons.UilArrowRight color="var(--primary-color)" />
										</h5>
									</Link>
								</div>
								<h3>{turmaList.length}</h3>
								<div className="cajas">
									{turmaList.length > 0 &&
										turmaList.map((turma) => (
											<div className="box" key={turma.id}>
												<Unicons.UilEllipsisH color="#92A9CB" className="pon" />
												<div className="box-body">
													<h4>{turma.descricao}</h4>
													<p>ID #{turma.id}</p>
													<Tags tipo={turma?.cursoModalidadeDescricao ? turma?.cursoModalidadeDescricao : turma?.salaModalidadeDescricao} />
													<h6>
														<div className="dot-green"></div>
														{turma.ativo ? 'Em andamento' : 'Inativa'}
													</h6>
													<h5>Alunos</h5>
													<p className="color-black">{turma.matriculas.length}</p>
													<h5>
														{`${formattedDate(turma.dataInicio)} - ${formattedDate(turma.dataFim)}  (${differenceInYears(
															turma.dataFim,
															turma.dataInicio
														)} ano)`}
													</h5>
													<div className="datas">
														<div className="data">
															{turma.horarios?.length > 0 &&
																turma.horarios?.map((horario) => (
																	<h5 key={horario.id}>
																		<strong>{horario.diaSemana} </strong>
																		{horario.descricaoHorario}
																	</h5>
																))}
														</div>
													</div>
												</div>
												<Link className="detalhes" to={`/turmas/professor/detalhe/${turma.id}`}>
													<p>Ver Detalhes</p>
												</Link>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
