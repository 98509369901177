/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from '@material-ui/lab';
import { Button, Row } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ColPessoa from '../../../components/ColPessoa';
import Header from '../../../components/Header';
import { ModalUser } from '../../../components/ModalUser/index';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { GrupoPessoaTipoEnum } from '../../../enum/grupo-pessoa-tipo.enum';
import { Operators } from '../../../enum/operators.enum';
import { PerfilEnum } from '../../../enum/perfil.enum';
import { IGrupoPessoaDTO, IPessoaCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { usePessoaService } from '../../../services/pessoa.service';
import '../styles/escola-grupo-create-step.styles.scss';

export const GrupoCreateResponsavelScreen: React.FunctionComponent = () => {
    const { grupoParaCadastrar, setGrupoParaCadastrar, grupoPessoaTipos } = useEscolaContext();
    const { setIsGlobalLoading, usuario, unidade } = useGlobalContext();
    const params = useParams<any>();
    const [criandUsuario, setCriandUsuario] = useState<boolean>(false);
    const [usuarioEmEdicao, setUsuarioEmEdicao] = useState<IPessoaCleanDTO>(null);
    const [listPessoa, setListPessoa] = useState<PageableResponse<IPessoaCleanDTO>>();
    const [selected, setSelected] = useState<IPessoaCleanDTO>();
    const [predicate] = useState<Predicate>(new Predicate());
    const [nome, setNome] = useState<string>();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const history = useHistory();
    const pessoaService = usePessoaService();

    // const location = useLocation();

    const findPessoa = () => {
        setUsuarioEmEdicao(null);
        setIsGlobalLoading(true);
        if (usuario.perfil === PerfilEnum.FRANQUIA && unidade.id) {
            predicate.addOption('unidadePessoas.unidade.id', unidade.id);
        }
        return pessoaService.find(predicate)
            .then(({ data }) => {
                setListPessoa(data);
            }).finally(() => setIsGlobalLoading(false))
    }

    useEffect(() => {
        findPessoa();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuario, unidade]);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (nome) {
                setIsGlobalLoading(true);
                predicate.addOption('nome', nome, Operators.CONTAINS);
                findPessoa().finally(() => setIsGlobalLoading(false));
            }
        }, 1500);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nome]);

    const onConfirmar = () => {
        if (params.tipo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO)
            grupoParaCadastrar.focalFinanceiro = selected as any;
        else if (params.tipo === GrupoPessoaTipoEnum.FOCAL_OPERACIONAL)
            grupoParaCadastrar.focalOperacional = selected as any;
        else {

            const tipo = grupoPessoaTipos.find((item) => item.codigo === params.tipo);

            const index = grupoParaCadastrar.pessoas?.findIndex?.(
                (item) => item.tipo.codigo === tipo.codigo);

            if (index >= 0)

                grupoParaCadastrar.pessoas[index] = {
                    id: grupoParaCadastrar.pessoas[index]?.id,
                    pessoa: selected as any,
                    tipo,
                    ativo: true,
                    grupo: null
                }

            else {

                if (!grupoParaCadastrar.pessoas)
                    grupoParaCadastrar.pessoas = [];

                const grupo: IGrupoPessoaDTO = { pessoa: selected as any, tipo, ativo: true, grupo: null, id: null }
                grupoParaCadastrar.pessoas.push(grupo);
            }
        }
        setGrupoParaCadastrar({ ...grupoParaCadastrar });
        setConfirmado(true);
    }

    useEffect(() => {

        if (confirmado)
            history.push("/escolas/grupos/create/step-3");

    }, [confirmado]);

    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        setIsGlobalLoading(true);
        predicate.setPage(page);
        findPessoa().finally(() => setIsGlobalLoading(false));
    }

    const descricaoParams = () => {
        if (params.tipo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO) {
            return "responsável financeiro"
        }
        if (params.tipo === GrupoPessoaTipoEnum.FOCAL_OPERACIONAL) {
            return "responsável operacional"
        }
        if (params.tipo === GrupoPessoaTipoEnum.RESPONSAVEL_LEGAL) {
            return "responsável legal"
        }
        if (params.tipo === GrupoPessoaTipoEnum.RESPONSAVEL_NEGOCIACAO) {
            return "responsável negociação"
        }
    }

    return (
        <div className="container">
            <Header />
            <Progress />
            <div className="franquia-responsavel" style={{ marginTop: 80 }}>
                <div className="franquia-row">
                    <h3>Selecione o {descricaoParams()} do grupo</h3>
                    <span>Caso o responsável legal dessa unidade não possua um usuário, crie no botão abaixo</span>
                    <Button
                        onClick={() => setCriandUsuario(true)}
                        icon={<Unicons.UilPlus size="16" color="var(--primary-color" />}
                        className="button-second"
                        id="criar-usuario"
                        style={{ width: 700, marginTop: 25, padding: '16px 24px' }}
                    >
                        Criar novo Usuário
                    </Button>
                    <div className="line"></div>
                    <h5>Responsáveis legais já cadastrados na Happy Code</h5>
                    <div className="busca">
                        <Unicons.UilSearch size="20" color="#6A7C96" />

                        <input
                            onChange={(event) => setNome(event.target.value)}
                            type="text"
                            id="busca"
                            placeholder="Buscar Usuário" />

                    </div>
                    <br />
                    <div className="matriculas" style={{ width: '100%' }}>
                        <Row className='turmas'>

                            {listPessoa
                                &&
                                listPessoa.content.map(
                                    (pessoa, index) => (

                                        <ColPessoa
                                            pessoa={pessoa}
                                            selected={selected}
                                            setSelected={setSelected}
                                            setUsuarioEmEdicao={setUsuarioEmEdicao}
                                            key={index}
                                        />

                                    )
                                )
                            }

                        </Row>
                    </div>
                    <div className="paginacao" style={{ width: '100%' }}>

                        <Pagination
                            onChange={(event, page) => onPageChange(event, page - 1)}
                            page={listPessoa ? listPessoa.pageable.pageNumber + 1 : 0}
                            count={listPessoa ? listPessoa.totalPages : 0} />

                    </div>
                    <div className="botoes">
                        <Link to="/escolas/grupos/create/step-3">
                            <Button className="button-second" id="cancelar" style={{ width: 345 }}>
                                Cancelar
                            </Button>
                        </Link>

                        <button disabled={!selected} onClick={() => onConfirmar()} className="button-primary" id="confirmar" style={{ width: 345 }}>
                            Confirmar
                        </button>

                    </div>
                </div>
            </div>
            <Link to="/escolas/grupos/create/step-3">
                <div className="botao-voltar">
                    <Unicons.UilArrowLeft size="20" color="#895CF2" />
                    <span>Voltar</span>
                </div>
            </Link>
            <ModalUser tipo={params.tipo} open={criandUsuario} onClose={() => setCriandUsuario(false)} modoLGPD />

            <ModalUser
                tipo={params.tipo}
                key={new Date().getTime()}
                open={usuarioEmEdicao != null}
                onClose={
                    () => {
                        setUsuarioEmEdicao(null);
                        findPessoa();
                    }
                }
                pessoa={usuarioEmEdicao}
                modoLGPD />

        </div>
    )
}