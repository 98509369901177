import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import { ISituacaoCampanhaCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useSituacaoCampanhaService } from '../../services/situacao-campanha.service';

interface TFiltrosCampanha {
    predicate: Predicate;
    findCampanha: () => void;
}
const FiltrosCampanha = memo(({ predicate, findCampanha }: TFiltrosCampanha) => {
    //export default function FiltrosCampanha({ predicate, findCampanha }: TFiltrosCampanha) {
    const [descricao, setDescricao] = useState<string>();
    const [situacaoCampanhaId, setSituacaoCampanhaId] = useState<number>();
    const [situacaoCampanha, setSituacaoCampanha] = useState<ISituacaoCampanhaCleanDTO[]>();
    const serviceSituacaoCampanha = useSituacaoCampanhaService();


    const situacoesCampanha = situacaoCampanha?.map((data) => (<Select.Option key={data.id} value={data.id}>{data.descricao}</Select.Option>))

    useEffect(() => {
        serviceSituacaoCampanha.findCleanList().then(({ data }) => setSituacaoCampanha(data))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('codigo', descricao, Operators.CONTAINS);
            }
            if (situacaoCampanhaId) {
                predicate.addOption('situacaoCampanhaId', situacaoCampanhaId);
            }
            // predicate.addOption('dataInicio', nowDate(), Operators.LESS_THAN_OR_EQUAL);
            // predicate.addOption('dataFim', nowDate(), Operators.GREATER_THAN_OR_EQUAL);

            findCampanha();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, situacaoCampanhaId]);

    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '90%' }}>
                <Input id="buscar" value={descricao} placeholder="Buscar Campanha" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} onChange={e => setDescricao(e.target.value)} />
            </div>
            <div className="filtro-estado" id="input-dashboard">
                <span>Status</span>
                <Select className="ant-dropdown-link" defaultValue="0" style={{ width: 120 }} onChange={e => setSituacaoCampanhaId(parseInt(e))}>
                    <Select.Option value="0">Todos</Select.Option>
                    {situacoesCampanha}
                </Select>
            </div>
        </div>
    </>
    )
})

FiltrosCampanha.displayName = 'FiltrosCampanha'

export default FiltrosCampanha;