import { Form } from "antd";
import { useState } from "react";
import { useCrmEscolaContext } from "../../../../context/crm-escola.context";

const useContainerNovoLeadState = () => {
  const { flModalOpen, setIndexCadastro, indexCadastro } = useCrmEscolaContext();


  return {
    flModalOpen,
    setIndexCadastro,
    indexCadastro,
  }
}

export default useContainerNovoLeadState;
