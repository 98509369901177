import {
	ILeadInteresseCleanDTO,
	ILeadInteresseDTO,
	ILeadInteresseFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadInteresseService = () => {
	const resourceURL = 'lead-interesse';

	return {
		...baseFindList<ILeadInteresseCleanDTO, ILeadInteresseDTO, ILeadInteresseFullDTO>(resourceURL),
	};
};
