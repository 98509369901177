/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import { cloneDeep } from 'lodash';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
	IAlunoLeadCustomDTO,
	ICrmLeadCustomDTO,
	ILeadEscolaFullDTO,
	IMatriculaCustomDTO,
	IStatusAluno,
	ITurmaCleanDTO
} from '../models/happy-code-api.model';
import { useVwLeadService } from '../services/vwlead.service';
import { getObject, removeKey, setObject } from '../util/store';

interface RevisaoDetalhe {
	ativo: boolean;
	alunoId: number;
}

//Tipando as Props do contexto
interface PropsAlunoContext {
	aluno: IAlunoLeadCustomDTO;
	setAluno: React.Dispatch<React.SetStateAction<IAlunoLeadCustomDTO>>;
	removeAluno: React.DispatchWithoutAction;
	revisao: boolean;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	revisaoDetalhe: RevisaoDetalhe;
	setRevisaoDetalhe: React.Dispatch<React.SetStateAction<RevisaoDetalhe>>;
	matricula: IMatriculaCustomDTO;
	setMatricula: React.Dispatch<React.SetStateAction<IMatriculaCustomDTO>>;
	removeMatricula: React.DispatchWithoutAction;
	revisaoMatricula: boolean;
	setRevisaoMatricula: React.Dispatch<React.SetStateAction<boolean>>;
	detalheMatricula: IMatriculaCustomDTO;
	setDetalheMatricula: React.Dispatch<React.SetStateAction<IMatriculaCustomDTO>>;
	detalheMatriculaRevisao: boolean;
	setDetalheMatriculaRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	leadEscolaResponsavel: ILeadEscolaFullDTO;
	setLeadEscolaResponsavel: React.Dispatch<React.SetStateAction<ILeadEscolaFullDTO>>;
	lead: ICrmLeadCustomDTO;
	setLead: React.Dispatch<React.SetStateAction<ICrmLeadCustomDTO>>;
	dataInicioContrato: Date;
	setDataInicioContrato: Dispatch<SetStateAction<Date>>;
	setMatriculas: React.Dispatch<React.SetStateAction<IMatriculaCustomDTO[]>>;
	matriculas: IMatriculaCustomDTO[];
}

//Valor default do contexto
const statusAlunoDefault: IStatusAluno = 'ATIVO';
const matriculaCustomDTO: IMatriculaCustomDTO = {
	statusMatricula: {
		id: 1,
		codigo: null,
		descricao: null,
		ativo: true,
	},
	diaVencimento: null,
	horasVendido: null,
	cobrancaUnica: null,
	turma: {} as ITurmaCleanDTO,
	turmas: [],
	financeiro: {
		id: null,
		ativo: true,
		campanha: null,
		responsavelFinanceiro: null,
		quantidadeParcelas: null,
		desconto: null,
		descontoCampanha: null,
		valorMensalidade: null,
		valorMensalidadeComDesconto: null,
		valorTotal: null,
		valorTotalComDesconto: null,
		valorMinimoCurso: null,
		valorOferta: null,
		valorSede: null,
		valorFranquia: null,
		split: null,
		cobrancaUnica: null,
		adiamento2aParcela: null,
		descontoDiluicao: null,
		deveDiluirSaldoAPagar: null,
		saldoAPagar: null,
		cupomDesconto: null,
		matriculas: [],
		numeroSorteio: null,
		financeiroOrigem: null,
	},
	turmaCurso: {
		id: null,
		codigo: '',
		descricao: '',
		duracao: 0,
		duracaoMinutos: 0,
		numeroMaxParcelas: 0,
		numeroMinParcelas: 0,
		valorDescontoMaximo: 0,
		valorDescontoMinimo: 0,
		valorMensalidadeMinimo: 0,
		valorSplitMatricula: 0,
		taxaMatricula: false,
		valorSplitTaxaMatricula: 0,
		numeroProrrogacaoDiasMatricula: 0,
		valorMensalidadeMinimoRematricula: 0,
		valorSplitRematricula: 0,
		numeroParcelasMaximoRematricula: 0,
		taxaRematricula: false,
		valorSplitTaxaRematricula: 0,
		numeroProrrogacaoDiasRematricula: 0,
		nivelAcesso: null,
		ativo: true,
		tipoCurso: null,
	},
	responsavelFinanceiro: null,
	aluno: {
		id: null,
		emailAcesso: null,
		statusAluno: null,
		observacao: null,
		restricao: null,
		anoEscolar: null,
		escola: null,
		ativo: true,
		pessoa: null,
		unidade: null,
	},
	financeiroRematricula: null,
	cupomAluno: {
		id: null,
		cupomNome: null,
		alunoId: null,
		valor: null,
		percentual: null,
		aplicado: null,
		vigenciaInicio: null,
		vigenciaFim: null,
		ativo: true,
	},
	diasProrrogacao: null,
	valorTaxa: null,
	cursoUnidade: {
		curso: null,
		unidade: null,
		id: null,
		unidadeId: null,
		unidadeCodigo: '',
		unidadeDescricao: '',
		valorMensalidadeMinimo: null,
		valorSplitMatricula: null,
		numeroParcelasMaximo: null,
		taxaMatricula: false,
		valorSplitTaxaMatricula: null,
		numeroProrrogacaoDiasMatricula: null,
		valorMensalidadeMinimoRematricula: null,
		valorSplitRematricula: null,
		numeroParcelasMaximoRematricula: null,
		taxaRematricula: false,
		valorSplitTaxaRematricula: null,
		numeroProrrogacaoDiasRematricula: null,
		valorOfertaMatricula: null,
		valorOfertaRematricula: null,
		ativo: true,
	},
	idTipoPagamento: null,
	turmaCurriculoNegocioDescricao: null,
	turmaCurriculoNegocioTransferencia: false,
	upgrade: false,
	id: null,
	dataMatricula: null,
	qtdParcelasEmAberto: null,
	saldoAPagar: null,
	ativo: true,
	qtdParcelasVencidas: null,
	dataInicioContrato: null,
	rematricula: null,
	oferta: {
		id: null,
		valorMensalidade: null,
		ativo: true,
	},
	turmaCurriculoNegocioId: 0
};
const alunoDefault: IAlunoLeadCustomDTO = {
	id: null,
	ativo: true,
	emailAcesso: '',
	statusAluno: statusAlunoDefault,
	observacao: '',
	restricao: '',
	anoEscolar: 0,
	escola: '',
	leadId: null,
	pessoa: {
		id: null,
		ativo: true,
		cpf: '',
		rg: '',
		email: '',
		descricaoEmpresa: '',
		observacao: '',
		descricaoProfissao: '',
		descricaoRestricao: '',
		dataNascimento: null,
		dataAdmissao: null,
		permiteUsoImagem: false,
		nome: '',
		contatos: [],
		unidadePessoas: [],
		identificadorPagarMe: '',
		foto: null,
		genero: null,
		endereco: null,
		pessoaResponsaveis: [
			{
				id: null,
				ativo: true,
				ordem: 0,
				principal: false,
				grauParentesco: '',
				responsavel: {
					ativo: true,
					id: null,
					cpf: '',
					rg: '',
					email: '',
					descricaoEmpresa: '',
					observacao: '',
					descricaoProfissao: '',
					descricaoRestricao: '',
					dataNascimento: null,
					dataAdmissao: null,
					foto: null,
					permiteUsoImagem: false,
					nome: '',
					contatos: [],
					unidadePessoas: [],
					identificadorPagarMe: '',
					genero: null,
					endereco: {
						ativo: true,
						id: null,
						cep: '',
						nomeUF: '',
						uf: '',
						cidade: '',
						bairro: '',
						logradouro: '',
						numero: '',
						complemento: '',
						referencia: '',
						latitude: null,
						longitude: null,
						parent: null,
						paisId: 51,
					},
					pessoaResponsaveis: [],
					documentos: [],
					trocarResponsavel: false
				},
			},
		],
		documentos: [],
		trocarResponsavel: false
	},
	unidade: null,
	matriculas: [],
};

const DEFAULT_VALUE = {
	aluno: alunoDefault,
	setAluno: () => { },
	removeAluno: () => { },

	revisao: false,
	setRevisao: () => { },
	revisaoDetalhe: { ativo: false, alunoId: null },
	setRevisaoDetalhe: () => { },

	matricula: matriculaCustomDTO,
	setMatricula: () => { },
	removeMatricula: () => { },

	revisaoMatricula: false,
	setRevisaoMatricula: () => { },

	detalheMatricula: matriculaCustomDTO,
	setDetalheMatricula: () => { },

	detalheMatriculaRevisao: false,
	setDetalheMatriculaRevisao: () => { },

	leadEscolaResponsavel: null,
	setLeadEscolaResponsavel: () => { },

	lead: null,
	setLead: () => { },

	leadId: null,
	setLeadId: () => { },

	dataInicioContrato: new Date(),
	setDataInicioContrato: () => { },

	setMatriculas: () => [],
	matriculas: []
};

//criando nosso contexto UserContext
const AlunoContext = createContext<PropsAlunoContext>(DEFAULT_VALUE);

const nameStorage = '@HappyCode/aluno';
const nameStorageRevisao = '@HappyCode/aluno/revisao';
const nameStorageDetalheRevisao = '@HappyCode/aluno/detalhe/revisao';
const nameStorageMatricula = '@HappyCode/matricula';
const nameStorageMatriculaRevisao = '@HappyCode/matricula/revisao';
const nameStorageDetalheMatricula = '@HappyCode/matricula/detalhe';
const nameStorageDetalheMatriculaRevisao = '@HappyCode/matricula/detalhe/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const AlunoContextProvider: React.FC = ({ children }: any) => {
	const leadService = useVwLeadService();
	const [aluno, setAlunoGlobal] = useState<IAlunoLeadCustomDTO>(DEFAULT_VALUE.aluno);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);
	const [revisaoDetalhe, setRevisaoDetalheGlobal] = useState<RevisaoDetalhe>(DEFAULT_VALUE.revisaoDetalhe);

	const [matricula, setMatriculaGlobal] = useState<IMatriculaCustomDTO>(DEFAULT_VALUE.matricula);
	const [revisaoMatricula, setRevisaoMatriculaGlobal] = useState<boolean>(DEFAULT_VALUE.revisaoMatricula);

	const [detalheMatricula, setDetalheMatriculaGlobal] = useState<IMatriculaCustomDTO>(DEFAULT_VALUE.detalheMatricula);
	const [detalheMatriculaRevisao, setDetalheMatriculaRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.detalheMatriculaRevisao);

	const [leadEscolaResponsavel, setLeadEscolaResponsavel] = useState<ILeadEscolaFullDTO>(DEFAULT_VALUE.leadEscolaResponsavel);
	const [lead, setLead] = useState<ICrmLeadCustomDTO>();
	const [matriculas, setMatriculas] = useState<IMatriculaCustomDTO[]>([]);

	const [dataInicioContrato, setDataInicioContrato] = useState<Date>(DEFAULT_VALUE.dataInicioContrato);

	const findLead = () => {
		leadService.findCustomIndexById({ id: aluno.leadId })
			.then(({ data }) => {
				setLead(data);
			});
	};

	const setAluno = (aluno) => {
		setAlunoGlobal(aluno);
		setObject(nameStorage, aluno);
	};

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const setRevisaoDetalhe = (revisao) => {
		setRevisaoDetalheGlobal(revisao);
		setObject(nameStorageDetalheRevisao, revisao);
	};

	const setMatricula = (matricula) => {
		setMatriculaGlobal(matricula);
		setObject(nameStorageMatricula, matricula);
	};

	const setRevisaoMatricula = (revisao) => {
		setRevisaoMatriculaGlobal(revisao);
		setObject(nameStorageMatriculaRevisao, revisao);
	};

	const setDetalheMatricula = (matricula) => {
		setObject(nameStorageDetalheMatricula, matricula);
		setDetalheMatriculaGlobal(matricula);
	};

	const setDetalheMatriculaRevisao = (revisao) => {
		setDetalheMatriculaRevisaoGlobal(revisao);
		setObject(nameStorageDetalheMatriculaRevisao, revisao);
	};

	const removeMatricula = () => {
		setRevisaoMatricula(false);
		removeKey(nameStorageMatricula);
		setMatricula(matriculaCustomDTO);
	};

	const removeAluno = () => {
		setRevisao(false);
		setRevisaoDetalhe({
			ativo: false,
			alunoId: null,
		});
		removeKey(nameStorage);
		setAluno(cloneDeep(DEFAULT_VALUE.aluno));
	};

	useEffect(() => {
		const aluno = getObject(nameStorage);
		if (aluno) {
			setAlunoGlobal(aluno);
		}

		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}

		const revisaoDetalhe = getObject(nameStorageDetalheRevisao);
		if (revisaoDetalhe) {
			setRevisaoDetalheGlobal(revisaoDetalhe);
		}

		const matricula = getObject(nameStorageMatricula);
		if (matricula) {
			setMatriculaGlobal(matricula);
		}

		const revisaoMatricula = getObject(nameStorageMatriculaRevisao);
		if (revisaoMatricula) {
			setRevisaoMatriculaGlobal(revisaoMatricula);
		}

		const detalheMatricula = getObject(nameStorageDetalheMatricula);
		if (detalheMatricula) {
			setDetalheMatriculaGlobal(detalheMatricula);
		}

		const detalheMatriculaRevisao = getObject(nameStorageDetalheMatriculaRevisao);
		if (detalheMatriculaRevisao) {
			setDetalheMatriculaRevisaoGlobal(detalheMatriculaRevisao);
		}
	}, []);

	useEffect(() => {
		if (aluno?.leadId) {
			findLead();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aluno?.leadId]);

	return (
		<AlunoContext.Provider
			value={{
				aluno,
				setAluno,
				removeAluno,
				revisao,
				setRevisao,
				revisaoDetalhe,
				setRevisaoDetalhe,
				matricula,
				revisaoMatricula,
				setMatricula,
				removeMatricula,
				setRevisaoMatricula,
				detalheMatricula,
				setDetalheMatricula,
				detalheMatriculaRevisao,
				setDetalheMatriculaRevisao,
				leadEscolaResponsavel,
				setLeadEscolaResponsavel,
				lead,
				setLead,
				dataInicioContrato,
				setDataInicioContrato,
				setMatriculas,
				matriculas
			}}
		>
			{children}
		</AlunoContext.Provider>
	);
};

export const useAlunoContext = (): PropsAlunoContext => {
	const context = useContext(AlunoContext);
	if (!context) throw new Error('useAlunoContext must be used within a AlunoContextProvider');
	return context;
};
