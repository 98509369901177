import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IDashSectionDTO } from '../../../models/happy-code-api.model';
import { useDashSectionService } from '../../../services/dash-section.service';
import { Predicate } from '../../../models/predicate.model';
import { ReportCard } from '../../../types/report-card.type';

export default function useFindPowerBiDashboards() {
	const { setIsGlobalLoading } = useGlobalContext();

	const predicate = useMemo(() => new Predicate(), []);

	const { findList } = useDashSectionService();

	const [dashboards, setDashboards] = useState<IDashSectionDTO[]>([]);

	const findDashboards = useCallback(async () => {
		setIsGlobalLoading(true);

		predicate.addOption('ativo', true);

		return await findList(predicate)
			.then((response) => setDashboards(response.data))
			.catch((error) => console.log(error))
			.finally(() => setIsGlobalLoading(false));
	}, [setIsGlobalLoading]);

	useEffect(() => {
		const controller = new AbortController();

		findDashboards();

		return () => controller.abort();
	}, [findDashboards]);

	const buildDashboardDataToReportScreen = useCallback((): ReportCard[] => {
		return dashboards.map(({ dashReport, codigoPbi, nome }, index) => {
			return {
				id: index,
				title: nome,
				link: `/relatorio/${dashReport.codigoPbi}/${codigoPbi}`,
			};
		});
	}, [dashboards]);

	return {
		dashboards,
		buildDashboardDataToReportScreen,
	};
}
