import * as Unicons from '@iconscout/react-unicons';
import { Domain, School } from '@mui/icons-material';
import { useState } from 'react';
import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import LogoGrande from '../../assets/img/header/logo.svg';
import Logo from '../../assets/img/menu/logo.svg';
import { useGlobalContext } from '../../context/GlobalContext';
import { Avatar } from '../Avatar';
import './style.scss';
import useLogoutUserHook from '../../globalHooks/use-logout-user.hook';

interface Props {
	selecionado?: number;
}

const NewMenu = ({ selecionado }: Props) => {
	const { usuario, token, unidade } = useGlobalContext();
	const [menuCollapse, setMenuCollapse] = useState(true);

	const menuIconClick = () =>
		menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
	const returnAcessoFuncao = (listaFuncoes: string[]) =>
		listaFuncoes.some((funcao) => usuario && funcao === usuario.funcao);

	const logout = useLogoutUserHook();

	//Para liberar o botão do crm apenas para as unidades pilotos.
	//Comentado por hora pois o piloto acabou;
	const idUnidadeUser = JSON.parse(
		localStorage.getItem('@HappyCode/login/unidade')
	);
	let piloto = false;
	if (
		idUnidadeUser?.id == null || //Acesso SEDE
		idUnidadeUser?.id == 19 || //Florianópolis
		idUnidadeUser?.id == 21 || //Goiânia
		idUnidadeUser?.id == 54 || //Uberaba
		idUnidadeUser?.id == 57 || //Conselheiro Lafaiete
		idUnidadeUser?.id == 59 || //Piracicaba
		idUnidadeUser?.id == 65 //Pouso Alegre
	) {
		piloto = true;
	}

	const acessoExternoCrme = () => {
		window.open(
			`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRME}?t=${token?.access_token}`,
			'_blank'
		);
	};

	const acessoExternoCrm = () => {
		window.open(
			`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRM}?t=${token?.access_token}&u=${unidade?.id}`,
			'_blank'
		);
	};

	const renderSubMenuEscolas = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={
						[21, 22, 24, 25, 23].includes(selecionado) ? 'active' : null
					}
					title={'Escolas'}
					icon={
						<Domain
							fontSize="small"
							htmlColor={
								[21, 22, 24, 25, 23].includes(selecionado)
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 23}
							icon={
								<Domain
									fontSize="small"
									htmlColor={
										selecionado === 21
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Grupos
							<Link to="/escolas/grupos" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 22}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 22
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Escolas
							<Link to="/escolas" />
						</MenuItem>
					)}

					{/* {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-alunos" active={selecionado === 23} icon={<Unicons.UilBuilding size="20" color={selecionado === 23 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Alunos
        <Link to="/escola-alunos" />
      </MenuItem>} */}

					{returnAcessoFuncao(['ADM', 'TEC', 'GSF']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 24}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 24
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Financeiro
							<Link to="/escolas-financeiro" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 25}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 25
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							CRM
							<Link to="/escolas/crm" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuExpansao = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={[21].includes(selecionado) ? 'active' : null}
					title={'Expansão'}
					icon={
						<Unicons.UilSuitcaseAlt
							size="20"
							color={
								selecionado === 21
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 34}
							icon={
								<Unicons.UilFilter
									fontSize="small"
									htmlColor={
										selecionado === 34
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							CRM - Expansão
							<Link to={''} onClick={acessoExternoCrme} />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuAcademy = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={[30, 31, 33, 32].includes(selecionado) ? 'active' : null}
					title={'Academy'}
					icon={
						<School
							fontSize="small"
							htmlColor={
								[30, 31, 33, 32].includes(selecionado)
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 31}
							icon={
								<Unicons.UilBuilding
									fontSize="small"
									htmlColor={
										selecionado === 31
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Gestão de Trilhas
							<Link to="/academy/gestao-trilha/trilha" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 32}
							icon={
								<Unicons.UilBuilding
									fontSize="small"
									htmlColor={
										selecionado === 32
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Gestão de Webinars
							<Link to="/academy/gestao-webinar/webinar" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 33}
							icon={
								<Unicons.UilPlug
									Size="small"
									htmlColor={
										selecionado === 33
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Gestão de Conectas
							<Link to="/academy/gestao-conecta/conecta" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-grupos"
							active={selecionado === 30}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 30
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Gestão de Materiais
							<Link to="/academy/gestao-material/cursos" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSubMenuHubsFranquias = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={
						[10, 11, 12, 13, 20, 99].includes(selecionado) ? 'active' : null
					}
					title={'Hubs'}
					icon={
						<Unicons.UilBuilding
							size="20"
							color={
								[10, 11, 12, 13, 20, 99].includes(selecionado)
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 10}
							icon={
								<Unicons.UilSmile
									size="20"
									color={
										selecionado === 10
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Alunos
							<Link to="/alunos" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-financeiro"
							active={selecionado === 11}
							icon={
								<Unicons.UilDollarSignAlt
									size="20"
									color={
										selecionado === 11
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Financeiro
							<Link to="/financeiro" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 12}
							icon={
								<Unicons.UilBookmark
									size="20"
									color={
										selecionado === 12
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Ofertas
							<Link to="/curso-franquia/step-2" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-turmas"
							active={selecionado === 99}
							icon={
								<Unicons.UilArchway
									size="20"
									color={
										selecionado === 99
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Salas
							<Link to="/sala" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-turmas"
							active={selecionado === 13}
							icon={
								<Unicons.UilBookReader
									size="20"
									color={
										selecionado === 13
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Turmas
							<Link to="/turmas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-crm"
							active={selecionado === 20}
							icon={
								<Unicons.UilFilter
									size="20"
									color={
										selecionado === 20
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							CRM
							<Link to="" onClick={acessoExternoCrm} />
						</MenuItem>
					)}

					{/*returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-crm" active={selecionado === 30} icon={<Unicons.UilPuzzlePiece
        size="20" color={selecionado === 30 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Stemplay
        <Link to="/stemplay" />
      </MenuItem>*/}
				</SubMenu>
			)
		);
	};

	const renderSubMenuFaq = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={[4, 77].includes(selecionado) ? 'active' : null}
					title={'FAQ'}
					icon={
						<Unicons.UilQuestionCircle
							size="20"
							color={
								[4, 77].includes(selecionado)
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-manual"
							active={selecionado === 4}
							icon={
								<Unicons.UilQuestionCircle
									size="20"
									color={
										selecionado === 4
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							FAQ Hubs
							<Link to="/faq/b2c" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-manual"
							active={selecionado === 77}
							icon={
								<Unicons.UilQuestionCircle
									size="20"
									color={
										selecionado === 77
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							FAQ Escolas
							<Link to="/faq/b2b" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};
	const renderSubMenuParametrizacao = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-franquias"
					className={
						[26, 28, 27, 29, 105, 97, 96, 7, 101].includes(selecionado)
							? 'active'
							: null
					}
					title={'Parametrizações'}
					icon={
						<Unicons.UilSetting
							size="20"
							color={
								[26, 28, 27, 29, 105, 97, 96, 7, 101].includes(selecionado)
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 26}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 26
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Pagamento
							<Link to="/franquias/pagamento-tipo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-autonomia"
							active={selecionado === 105}
							icon={
								<Unicons.UilClipboardNotes
									size="20"
									color={
										selecionado === 105
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Autonomia
							<Link to="/autonomia" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 97}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 97
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Configuração splits
							<Link to="/hora" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 96}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 96
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Recursos
							<Link to="/recurso" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-campanhas"
							active={selecionado === 7}
							icon={
								<Unicons.UilBolt
									size="20"
									color={
										selecionado === 7
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Campanhas
							<Link to="/campanhas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 27}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 27
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Metas Tipo
							<Link to="/metas-tipo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 28}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 28
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Metas
							<Link to="/metas" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 29}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 29
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Mensagem Home
							<Link to="/mensagem-home" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 101}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 101
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Rodar Rotinas
							<Link to="/rodar-rotinas" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};
	const renderSubMenuHubsSede = () => {
		return (
			returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
				<SubMenu
					id="submenu-hubs"
					className={
						[1, 5, 6, 8, 9, 10, 98, 107, 108, 109].includes(selecionado)
							? 'active'
							: null
					}
					title={'Hubs'}
					icon={
						<Unicons.UilBuilding
							size="20"
							color={
								[1, 5, 6, 8, 9, 10, 98, 107, 108, 109].includes(selecionado)
									? 'var(--primary-color)'
									: 'var(--blue-transparent)'
							}
						/>
					}
				>
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-alunos"
							active={selecionado === 10}
							icon={
								<Unicons.UilSmile
									size="20"
									color={
										selecionado === 10
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Alunos
							<Link to="/alunos" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 108}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 108
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Currículos
							<Link to="/curriculo" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 122}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 122
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Produtos Escolas
							<Link to="/produto-escola" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 6}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 6
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Cursos
							<Link to="/cursos" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-cursos"
							active={selecionado === 107}
							icon={
								<Unicons.UilGraduationCap
									size="20"
									color={
										selecionado === 107
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Negócios
							<Link to="/negocio" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-franquias"
							active={selecionado === 1}
							icon={
								<Unicons.UilBuilding
									size="20"
									color={
										selecionado === 1
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Franquias
							<Link to="/franquias" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-financeiro-sede"
							active={selecionado === 9}
							icon={
								<Unicons.UilDollarSign
									size="20"
									color={
										selecionado === 9
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Financeiro
							<Link to="/financeiro-sede" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-taxas"
							active={selecionado === 8}
							icon={
								<Unicons.UilReceipt
									size="20"
									color={
										selecionado === 8
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Taxas
							<Link to="/taxas" />
						</MenuItem>
					)}
				</SubMenu>
			)
		);
	};

	const renderSidebarSede = () => {
		return (
			<>
				<Menu iconShape="round">
					<MenuItem
						id="menu-home"
						active={selecionado === 0}
						icon={
							<Unicons.UilHome
								size="20"
								color={
									selecionado === 0
										? 'var(--primary-color)'
										: 'var(--blue-transparent)'
								}
							/>
						}
					>
						Home
						<Link to="/" />
					</MenuItem>

					{renderSubMenuHubsSede()}
					{renderSubMenuEscolas()}
					{renderSubMenuExpansao()}
					{renderSubMenuAcademy()}
					{renderSubMenuParametrizacao()}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-usuarios"
							active={selecionado === 3}
							icon={
								<Unicons.UilUsersAlt
									size="20"
									color={
										selecionado === 3
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Usuarios
							<Link to="/usuarios" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && (
						<MenuItem
							id="menu-indicadores"
							active={selecionado === 18}
							icon={
								<Unicons.UilAnalytics
									size="20"
									color={
										selecionado === 18
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Relatórios
							<Link to="/relatorio-sede" />
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const renderSidebarFranquia = () => {
		return (
			<>
				<Menu iconShape="round">
					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-home"
							active={selecionado === 0}
							icon={
								<Unicons.UilHome
									size="20"
									color={
										selecionado === 0
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Home
							<Link to="/franquia" />
						</MenuItem>
					)}

					{renderSubMenuHubsFranquias()}
					{renderSubMenuEscolas()}

					{returnAcessoFuncao(['ADM', 'TEC']) && (
						<MenuItem
							id="menu-colaboradores"
							active={selecionado === 9}
							icon={
								<Unicons.UilUsersAlt
									size="20"
									color={
										selecionado === 9
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Colaboradores
							<Link to="/colaboradores" />
						</MenuItem>
					)}

					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-rel-financeiro"
							active={selecionado === 15}
							icon={
								<Unicons.UilAnalytics
									size="20"
									color={
										selecionado === 15
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							Relatórios
							<Link to="/relatorio" />
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && (
						<MenuItem
							id="menu-manual"
							active={selecionado === 16}
							icon={
								<Unicons.UilClipboardNotes
									size="20"
									color={
										selecionado === 16
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
							<a
								href="https://suporte.happygrupo.com.br"
								className="chamado"
								target="blank"
							>
								Chamados
							</a>
						</MenuItem>
					)}
					{returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && [6,29,11].includes(unidade?.id) && (
						<MenuItem
							id="menu-gpt"
							active={selecionado === 27}
							icon={
								<Unicons.UilCommentAltDots
									size="20"
									color={
										selecionado === 27
											? 'var(--primary-color)'
											: 'var(--blue-transparent)'
									}
								/>
							}
						>
			
								Happy GPT		
								<Link to={"/happy-gpt"}/>
						</MenuItem>
					)}

					{renderSubMenuFaq()}

					{/*returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && <MenuItem id="menu-indicadores" active={selecionado === 30} icon={<Unicons.UilPuzzlePiece size="20" color={selecionado === 30 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Stemplay
          <Link to="/stemplay" />
        </MenuItem>*/}
				</Menu>
			</>
		);
	};

	return (
		<>
			<div id="header" style={{ backgroundColor: 'white' }}>
				<ProSidebar collapsed={menuCollapse}>
					<SidebarHeader>
						<div className="logotext">
							<p>
								{menuCollapse ? (
									<img src={Logo} alt="" onClick={menuIconClick} />
								) : (
									<img src={LogoGrande} alt="" onClick={menuIconClick} />
								)}
							</p>
						</div>
					</SidebarHeader>
					<SidebarContent>
						{usuario && usuario.perfil === 'SEDE'
							? renderSidebarSede()
							: renderSidebarFranquia()}
					</SidebarContent>
					<SidebarFooter>
						<Menu>
							<MenuItem
								className="item-avatar"
								icon={<Avatar base64={usuario?.arquivo} size={30} />}
							>
								<div className="box-avatar">
									<h6>{usuario?.usuario}</h6>
									<p>{usuario?.perfil}</p>
								</div>
								<Link to="/usuarios/profile" />
							</MenuItem>
							<MenuItem
								onClick={logout}
								className="item-danger"
								icon={
									<Unicons.UilExport
										style={{ transform: 'rotate(90deg)' }}
										size="20"
										color="var(--red-color)"
									/>
								}
							>
								Sair
							</MenuItem>
						</Menu>
					</SidebarFooter>
				</ProSidebar>
			</div>
		</>
	);
};

export default NewMenu;
