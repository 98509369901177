
import http from '../helpers/http-common';
import { IConectaCleanDTO, IConectaDTO, IConectaFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyConectaService = () => {
	const resourceURL = '/conecta';

	const editarArquivo = (
		webinarId: number,
		body: any,
	): Promise<IAxiosResponseCustom<IConectaDTO>> =>
		http.patch(`${resourceURL}/${webinarId}`, body);

	const editarArquivoCustom = (
		webinarId: number,
		body: any,
	): Promise<IAxiosResponseCustom<IConectaDTO>> =>
		http.patch(`${resourceURL}/custom/${webinarId}`, body);

	return {
		...baseFind<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
		...baseFindList<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
		...baseFindById<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
		...baseCreate<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
		...baseUpdate<IConectaCleanDTO, IConectaDTO, IConectaFullDTO>(resourceURL),
		editarArquivo,
		editarArquivoCustom,
	};
};
