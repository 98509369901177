import {
	ILeadEscolaOrigemCleanDTO,
	ILeadEscolaOrigemDTO,
	ILeadEscolaOrigemFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadEscolaOrigemService = () => {
	const resourceURL = 'lead-escola-origem';

	return {
		...baseFindList<ILeadEscolaOrigemCleanDTO, ILeadEscolaOrigemDTO, ILeadEscolaOrigemFullDTO>(resourceURL),
		...baseFind<ILeadEscolaOrigemCleanDTO, ILeadEscolaOrigemDTO, ILeadEscolaOrigemFullDTO>(resourceURL),
		...baseCreate<ILeadEscolaOrigemCleanDTO, ILeadEscolaOrigemDTO, ILeadEscolaOrigemFullDTO>(resourceURL),
		...baseUpdate<ILeadEscolaOrigemCleanDTO, ILeadEscolaOrigemDTO, ILeadEscolaOrigemFullDTO>(resourceURL),
		...baseFindById<ILeadEscolaOrigemCleanDTO, ILeadEscolaOrigemDTO, ILeadEscolaOrigemFullDTO>(resourceURL),
	};
};
