import * as Unicons from '@iconscout/react-unicons';
import { Button, Col, Row } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import { ICobrancaCustomDTO, ICobrancaDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useCobrancaService } from '../../services/cobranca.service';
import { useMatriculaService } from '../../services/matricula.service';
import { daysBetween, formatMoney, formattedDate, nowDate } from '../../util/format';
import { TagsFinanceiro } from '../TagsFinanceiro';
import { ModalFinanceiroCancelamentoStep3 } from './cancelamento-3';
import './style.css';

interface Text {
	open: boolean;
	onClose: () => void;
	cobranca: ICobrancaCustomDTO;
}

export const ModalFinanceiroCancelamentoStep2: React.FunctionComponent<Text> = ({ open, onClose, cobranca }: Text) => {
	const today = new Date();
	let total = 0;

	const [isOpen, setIsOpen] = useState(false);
	const [cobrancaSelectedList, setCobrancaSelectedList] = useState<ICobrancaDTO[]>([]);
	const { setIsGlobalLoading, usuario } = useGlobalContext();
	const matriculaService = useMatriculaService();
	const cobrancaService = useCobrancaService();
	const [isOpenAndCobrancaUnica, setIsOpenAndCobrancaUnica] = useState(false);
	const [multa, setMulta] = useState(false);
	const [isOpenFalha, setIsOpenFalha] = useState(false);
	const [msg, setMsg] = useState();

	const findCobrancaByUnidade = () => {
		setIsGlobalLoading(true);

		const predicate = new Predicate();
		predicate.removeOption('statusCobrancaCodigo');
		predicate.addMultiOption('statusCobrancaCodigo', 'AGR');
		predicate.addMultiOption('statusCobrancaCodigo', 'GRD');
		predicate.addMultiOption('dataVencimento', nowDate(), Operators.GREATER_THAN_OR_EQUAL);
		predicate.addOption('financeiroId', cobranca.financeiro.id);

		return cobrancaService
			.findList(predicate)
			.then(({ data }) => setCobrancaSelectedList(data))
			.finally(() => setIsGlobalLoading(false));
	};

	const cancelarMatricula = (isMulta: boolean) => {
		const matriculas = cobranca?.financeiro.matriculas || [];
		setIsGlobalLoading(true);
		matriculaService
			.cancelar(cobranca.financeiro.id, isMulta, null, usuario.usuario) //CANCELADA
			.then(() => {
				setIsOpen(true);
				//setIsOpenAndCobrancaUnica(false);
			})
			// .then(() => matriculaService.baixarTermoCancelamento(matricula[0]?.id)
			// .then((response) => {
			//     const blob = new Blob([response.data], {
			//         type: 'application/x-pdf',
			//     });
			//     FileSaver.saveAs(blob, `contrato_${matricula[0]?.id}.pdf`);
			// }))
			.catch(() => setIsOpenFalha(true))
			.finally(() => setIsGlobalLoading(false));
	};

	const cancelarComMultaCobrancaUnica = (isOpen: boolean) => {
		//setIsOpenAndCobrancaUnica(isOpen);
	};

	useEffect(() => {
		if (cobranca) {
			setIsGlobalLoading(true);
			Promise.all([findCobrancaByUnidade()]).finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cobranca]);

	const closeModal = () => {
		setIsOpenAndCobrancaUnica(false);
	};

	const onFinish = () => {
		setIsOpen(true);
	};

	const onFinishFalha = (msg: any) => {
		setIsOpenFalha(true);
		setMsg(msg);
	};

	const fecharModais = () => {
		setIsOpen(false);
		closeModal();
		onClose();
		setIsOpenFalha(false);
	};

	if (!open) return null;

	return (
		<div className="modalContainerFinanceiro">
			<div className="modal-user-financeiro">
				{cobrancaSelectedList.length > 0 ? (
					<>
						<div className="body">
							<h3 style={{ textAlign: 'center' }}>Confirmar cancelamento de contrato?</h3>
							{/* <h4 style={{ textAlign: 'center' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor volutpat vel placerat lacus metus. </h4> */}
							<div className="box-tabela" style={{ marginTop: 20 }}>
								<div className="tabela">
									{/* <div className="titles-users"> */}
									<Row className="titles-users" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
										<Col className="item gutter-row" span={6}>
											<span>VENCIMENTO</span>
										</Col>
										<Col className="item gutter-row" span={6}>
											<span>PARCELA</span>
										</Col>
										<Col className="item gutter-row" span={6}>
											<span>VALOR</span>
										</Col>
										<Col className="item gutter-row" span={6}>
											<span>STATUS</span>
										</Col>
										<Col className="item gutter-row" span={6}>
											<span>DIAS VENCIDOS</span>
										</Col>
									</Row>
									{cobrancaSelectedList.map((cb) => {
										total += cb.valorCobranca;
										return (
											<Row key={cb.id} className="registro-user" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className="funcao-user gutter-row" span={6}>
													<p>{formattedDate(cb.dataVencimento)}</p>
												</Col>
												<Col className="funcao-user gutter-row" span={6}>
													<p>{cb?.parcela}</p>
												</Col>
												<Col className="funcao-user gutter-row" span={6}>
													<p>{formatMoney(cb.valorCobranca)}</p>
												</Col>
												<Col className="funcao-user gutter-row" span={6}>
													<TagsFinanceiro codigo={cb?.statusCobranca?.codigo} descricao={cb?.statusCobranca?.descricao}></TagsFinanceiro>
												</Col>
												<Col className="funcao-user gutter-row" span={6}>
													<p>{daysBetween(cb.dataVencimento)}</p>
												</Col>
											</Row>
										);
									})}

									<div className="valor" style={{ marginTop: 20 }}>
										<h6>Valor total em aberto</h6>
										<p>{formatMoney(total)}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="line"></div>
						<div className="botoes">
							<Button onClick={onClose} style={{ minWidth: 145 }} className="button-second">
								Voltar
							</Button>
							<Button
								className="button-danger"
								style={{ marginLeft: 8, minWidth: 145 }}
								onClick={() =>
									(cobranca?.financeiro.cobrancaUnica == true && cobranca?.statusCobranca?.codigo === 'PGO') || cobranca?.financeiro.quantidadeParcelas < 12
										? (setMulta(false), cancelarMatricula(false))
										: cancelarMatricula(false)
								}
							>
								{cobranca?.parcela === 0 || cobranca?.statusCobranca?.codigo === 'IST' ? <>cancelar cobrança</> : <>apenas cancelar contrato</>}
							</Button>
							{cobranca?.parcela !== 0 ||
							(cobranca?.statusCobranca?.codigo !== 'IST' && cobranca?.statusCobranca?.codigo === 'PGO') ||
							(cobranca?.statusCobranca?.codigo === 'GRD' && cobranca?.tipoPagamento?.codigo === 'CRC') ? (
								<Button
									className="button-danger"
									style={{ marginLeft: 8, minWidth: 145 }}
									onClick={() =>
										cobranca?.financeiro.cobrancaUnica == true || cobranca?.financeiro.quantidadeParcelas < 12
											? (setMulta(true), cancelarMatricula(true))
											: cancelarMatricula(true)
									}
								>
									cancelar contrato COM multa rescisória
								</Button>
							) : null}
						</div>
					</>
				) : null}
				{cobranca?.dataVencimento.toString() < nowDate() ? (
					<div className="body">
						<h3 style={{ textAlign: 'center' }}>Cancelamento</h3>
						<div className="line"></div>
						<p style={{ textAlign: 'center', paddingTop: 15 }}>Cobranças vencidas não podem ser canceladas</p>
						<div className="line"></div>
						<div className="botoes">
							<Button onClick={onClose} style={{ minWidth: 145, margin: '0 auto', marginTop: 20 }} className="button-second">
								Voltar
							</Button>
						</div>
					</div>
				) : null}
			</div>
			{isOpen && (
				<div className="modalContainer">
					<div className="modalBox">
						<div className="circle-check">
							<Unicons.UilCheck size="100" color="#1EBE5E" />
						</div>
						<h3>{'Contrato cancelado'}</h3>
						{/* <h6>{subtitle}</h6> */}
						<div className="line"></div>
						<div className="botoes">
							<Button id="button2" onClick={() => fecharModais()} className={'button-primary'} style={{ marginLeft: 8, minWidth: 145 }}>
								{'Voltar'}
							</Button>
						</div>
					</div>
				</div>
			)}
			{isOpenFalha && (
				<div className="modalContainer">
					<div className="modalBox">
						<div className="circle-check-red">
							<Unicons.UilTimesCircle size="100" color="red" />
						</div>
						<h3>{'Falha ao cancelar'}</h3>
						{<h6>{msg}</h6>}
						<div className="line"></div>
						<div className="botoes">
							<Button id="button2" onClick={() => fecharModais()} className={'button-primary'} style={{ marginLeft: 8, minWidth: 145 }}>
								{'Voltar'}
							</Button>
						</div>
					</div>
				</div>
			)}
			{/* <ModalFinanceiroCancelamentoStep3
                    open={isOpenAndCobrancaUnica}
                    isMulta={multa}
                    onClose={closeModal}
                    cobranca={cobranca}
                    onFinish={onFinish}
                    onFinishFalha={onFinishFalha}
                    usuario={usuario.usuario}
                /> */}
		</div>
	);
};
