import { Link, useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { ICobrancaDTO, ICursoCleanDTO, IMatriculaUpgradeFullDTO } from "../../models/happy-code-api.model";
import { useGlobalContext } from "../../context/GlobalContext";
import NewMenu from "../../components/NewMenu";
import './style.css'
import { TagsFinanceiro } from "../../components/TagsFinanceiro";
import { formatMoney } from "../../util/format";
import { useCursoService } from "../../services/curso.service";
import { Predicate } from "../../models/predicate.model";
import { InputNumber } from "antd";
import { useCobrancaService } from "../../services/cobranca.service";
import { useMatriculaUpgradeService } from "../../services/matricula-upgrade.service";
import { useNotificationService } from "../../services/notification.service";

const AlunosUpgradeCobranca = () => {
    const { idMatricula } = useParams<any>();
    const { setIsGlobalLoading } = useGlobalContext();
    const [cobrancas, setCobrancas] = useState<ICobrancaDTO[]>();
    const [cursosPlus, setCursosPlus] = useState<ICursoCleanDTO[]>();
    const [upgrade, setUpgrade] = useState<IMatriculaUpgradeFullDTO>();
    const matriculaUpgrade = useMatriculaUpgradeService();
    const cobrancaService = useCobrancaService();
    const cursoService = useCursoService();
    const notification = useNotificationService();
    const history = useHistory();

    const fetchCobrancas = () => {
        setIsGlobalLoading(true);
        cobrancaService.findByMatriculaId(idMatricula)
            .then((response) => setCobrancas(response.data))
            .catch(e => console.log(e))
            .finally(() => setIsGlobalLoading(false));
    }

    const fetchCursos = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption("tipoCurso.id", 10);

        cursoService.findCleanList(predicate).then(response => setCursosPlus(response.data))
            .catch(e => console.log(e))
            .finally(() => setIsGlobalLoading(false));
    }   

    useEffect(() => {
        fetchCobrancas();
        fetchCursos();
        setUpgrade({
            ...upgrade,
            "matriculaId": idMatricula
        })
    }, [])


    function handleChange(field:string, value: string | number) {        
        setUpgrade({
          ...upgrade,
          [field]: value
        });
      }
    const enviar = () => {
        setIsGlobalLoading(true);
        matriculaUpgrade.enviarMatriculaUpgrade(upgrade)
            .then(() => {
                notification({ description: `Upgrade realizado com sucesso.`, type: 'success', message: 'Sucesso!' }),
                history.push(`/alunos/detalhe-matricula/${idMatricula}`)
            })
            .finally(() => setIsGlobalLoading(false));
    }

    return (
        <>
            <div className="container-fondo">
                <NewMenu selecionado={3} />
                <div className="dashboard">
                    <h3 className="title">Situação financeira</h3>
                    <div className="tabela">
                        <div className="titles-users">
                            <div className="item" style={{ width: '19%' }}>
                                <span>Parcela</span>
                                <div className="arrows" >
                                </div>
                            </div>
                            <div className="item" style={{ width: '18%' }}>
                                <span>Valor</span>
                                <div className="arrows" >
                                </div>
                            </div>
                            <div className="item" style={{ width: '13%' }}>
                                <span>Status</span>
                                <div className="arrows" >
                                </div>
                            </div>
                        </div>
                        {cobrancas?.map((cob, index) => (
                            <div key={index} className="registro-user-upgrade">
                                <div className="item" style={{ width: '19%' }}>
                                    <span>{cob.parcela}</span>
                                </div>
                                <div className="item" style={{ width: '18%' }}>
                                    <span>{formatMoney(cob.valorCobranca)}</span>
                                </div>
                                <div className="funcao-user" style={{ width: '13%' }}>
                                    <span>{<TagsFinanceiro codigo={cob.statusCobranca?.codigo} descricao={cob.statusCobranca?.descricao}></TagsFinanceiro>}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="input-upgrade">
                        <div className="input-box"  style={{ width: '15%', height: "100%" }}>
                            <p style={{ fontSize: '10px' }}>Curso referência</p>
                            <select className="select-upgrade" onChange={(e) =>handleChange("cursoId", e.target.value)} placeholder="Selecione o curso referência" >
                                <option className="select-upgrade-opt" value='' key={''}></option>
                                {cursosPlus?.map(plus =>
                                <>
                                    <option className="select-upgrade-opt" value={plus.id} key={plus.id}>{plus.descricao}</option>
                                </>
                                )}
                            </select>
                        </div>
                        <div className="input-box" style={{ height: "100%", marginLeft: '1%', width: '15%' }}>
                            <p style={{ fontSize: '10px' }}>Valor adicional por parcela</p>
                            <InputNumber id="input-upgrade" type={"number"} min={1} max={299} onChange={(e) => handleChange("valorAdicional", e)} />
                        </div>
                    </div>
                    <div className="botoes-upgrade">
                        <Link to={`/alunos/detalhe-matricula/${idMatricula}`}>
                            <button className="button-primary" >Voltar</button>
                        </Link>
                        <button className="button-primary" onClick={enviar}>Enviar</button>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </>
    )
}

export default AlunosUpgradeCobranca;