import http from "../helpers/http-common";
import { IMensagemHomeCleanDTO, IMensagemHomeDTO, IMensagemHomeFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

interface Props {
	id?: number;
}

export const useMensagemHomeService = () => {
	const resourceURL = '/mensagem-home';	

	return {
		...baseFind<IMensagemHomeCleanDTO, IMensagemHomeDTO, IMensagemHomeFullDTO>(resourceURL),
		...baseFindList<IMensagemHomeCleanDTO, IMensagemHomeDTO, IMensagemHomeFullDTO>(resourceURL),
		...baseFindById<IMensagemHomeCleanDTO, IMensagemHomeDTO, IMensagemHomeFullDTO>(resourceURL),
		...baseCreate<IMensagemHomeCleanDTO, IMensagemHomeDTO, IMensagemHomeFullDTO>(resourceURL),
		...baseUpdate<IMensagemHomeCleanDTO, IMensagemHomeDTO, IMensagemHomeFullDTO>(resourceURL),
		delete: (body: Props) => http.delete(`${resourceURL}/${body.id}`),		
	};
};
