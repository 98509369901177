import { Table, TableColumnsType } from 'antd';
import { formatMoney } from '../../util/format';
import "./style.css";

const TabelaTaxaGateway = () => {

    const data = [
        {
            key: '1',
            tipoPagamento: "Processamento",
            bandeira: "Processamento",
            parcelaInicio: 1,
            parcelaFim: null,
            valor: 0.40,
            percentual: null
        },
        {
            key: '2',
            tipoPagamento: "Boleto",
            bandeira: "Boleto",
            parcelaInicio: 1,
            parcelaFim: null,
            valor: 1.89,
            percentual: null,
        },
        {
            key: '3',
            tipoPagamento: "PIX",
            bandeira: "PIX",
            parcelaInicio: 1,
            parcelaFim: null,
            valor: null,
            percentual: 0.89,
        },
        {
            key: '4',
            tipoPagamento: "Cartão de Crédito",
            bandeira: "Visa",
            parcelaInicio: 1,
            parcelaFim: null,
            valor: null,
            percentual: 2.29,
        },
        {
            key: '5',
            tipoPagamento: "Cartão de Crédito",
            bandeira: "Visa",
            parcelaInicio: 2,
            parcelaFim: 6,
            valor: null,
            percentual: 2.58,
        },
        {
            key: '6',
            tipoPagamento: "Cartão de Crédito",
            bandeira: "Visa",
            parcelaInicio: 7,
            parcelaFim: 12,
            valor: null,
            percentual: 2.75,
        },
        {
            key: '7',
            tipoPagamento: "Cartão de Crédito",
            bandeira: "Mastercard",
            parcelaInicio: 1,
            parcelaFim: null,
            valor: null,
            percentual: 2.29,
        },
        {
            key: '8',
            tipoPagamento: "Cartão de Crédito",
            bandeira: "Mastercard",
            parcelaInicio: 2,
            parcelaFim: 6,
            valor: null,
            percentual: 2.58,
        },
        {
            key: '9',
            tipoPagamento: "Cartão de Crédito",
            bandeira: "Mastercard",
            parcelaInicio: 7,
            parcelaFim: 12,
            valor: null,
            percentual: 2.75,
        },
    ];

    const columns: TableColumnsType<any> = [
        {
            title: 'Tipo de Pagamento',
            dataIndex: 'tipoPagamento',
            align: "center",
        },
        {
            title: 'Bandeira',
            dataIndex: 'bandeira',
            align: "center",
        },
        {
            title: 'Parcelas',
            key: 'parcelas',
            align: 'center',
            render: (_value, record) => {
                const inicio = record.parcelaInicio;
                const fim = record.parcelaFim ? record.parcelaFim : '';
                const seta = record.parcelaFim ? ' → ' : '';
                return `${inicio}${seta}${fim}x`;
            }
        },
        {
            title: 'Taxa',
            dataIndex: 'taxa',
            align: "center",
            render: (_value, record, _index) => {
                const valor = record.valor;
                const percentual = record.percentual;
                return valor ? formatMoney(valor) : `${percentual}%`;
            },
        },
    ];

    return (
        <Table className="mb-20" style={{ whiteSpace: "nowrap", textAlign: "center" }} pagination={false} dataSource={data} columns={columns} />
    );
}

export default TabelaTaxaGateway;
