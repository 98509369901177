import home from '../../assets/img/home/home.svg';
import NewMenu from '../../components/NewMenu';

import './style.css';

export const Home: React.FunctionComponent = () => {
  return (
    <div className="container">
      <NewMenu selecionado={0} />
      <div className="dashboard">
        <img id="img-home" src={home} alt={"logo"} style={{ width: '100%' }} />
      </div>
    </div>
  );
}