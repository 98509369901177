import { Col, DatePicker, Row, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	IGrupoContratoTipoDTO,
	IGrupoDTO,
	IStatusCobrancaCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useGrupoContratoTipoService } from '../../../services/grupo-contrato-tipo.service';
import { useGrupoService } from '../../../services/grupo.service';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useStatusCobrancaService } from '../../../services/status-cobranca.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { hoje } from '../../../util/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

export interface Root {
	C01_UNIDADE: number;
	C02_ATUAÇÃO: string;
	C03_GRUPO: string;
	C04_TIPO_CONTRATO: string;
	C05_NR_PARCELA: number;
	C06_VL_TOTAL: number;
	C07_VL_SPLIT: number;
	C08_DH_VENCIMENTO: Date;
	C09_STATUS_DA_COBRANÇA: string;
	C10_DH_PAGAMENTO: Date;
	C11_RESPONSÁVEL_FINANCEIRO: string;
	C12_NR_LICENCA_INTRA: number;
	C13_VALOR_UNITÁRIO_INTRA: number;
	C14_NR_LICENCA_EXTRA: number;
	C15_VALOR_UNITÁRIO_EXTRA: number;
	C16_NR_LICENCA_ACADEMICA: number;
	C17_VL_SERVICO_ACADEMICO: number;
	CXX_ID_GRUPO: number;
	CXX_ID_GRUPO_CONTRATO_TIPO: number;
	CXX_ID_FINANCEIRO: number;
	CXX_ID_SERVICO_ACADEMICO: number;
	CXX_ID_STATUS_COBRANCA: string;
	CXX_NR_PARCELAS: number;
	CXX_NR_LICENCA_TOTAL_INTRA: number;
	CXX_NR_LICENCA_TOTAL_EXTRA: number;
	CXX_NR_LICENCA_TOTAL_ACADEMICO: number;
}

function RelFinanceiroEscola() {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const service = useRelatorioService();
	const grupoService = useGrupoService();
	const statusCobrancaService = useStatusCobrancaService();
	const grupoContratoTipoService = useGrupoContratoTipoService();

	const [dados, setData] = useState<Root[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();

	const [predicate] = useState<Predicate>(new Predicate());

	const [dataVencimentoInicio, setDataVencimentoInicio] =
		useState<moment.Moment>();
	const [dataVencimentoFim, setDataVencimentoFim] = useState<moment.Moment>();
	const [dataPagamentoInicio, setDataPagamentoInicio] =
		useState<moment.Moment>();
	const [dataPagamentoFim, setDataPagamentoFim] = useState<moment.Moment>();

	const [grupo, setGrupo] = useState<IGrupoDTO[]>();
	const [grupoId, setGrupoId] = useState<number>();

	const [licencaId, setLicencaId] = useState<number[]>();

	const [grupoContratoTipo, setGrupoContratoTipo] =
		useState<IGrupoContratoTipoDTO[]>();
	const [grupoContratoTipoId, setGrupoContratoTipoId] = useState<
		number | null
	>();

	const [statusCobranca, setStatusCobranca] =
		useState<IStatusCobrancaCleanDTO[]>();
	const [statusCobrancaId, setStatusCobrancaId] = useState<number | null>(0);

	const findStatusCobranca = () => {
		return statusCobrancaService
			.findCleanList(predicate)
			.then((response) => setStatusCobranca(response.data));
	};

	const findGrupo = () => {
		setIsGlobalLoading(true);
		return grupoService
			.findList(predicate)
			.then(({ data }) => {
				setGrupo(data as any);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findGrupoContratoTipo = () => {
		setIsGlobalLoading(true);
		return grupoContratoTipoService
			.findList()
			.then((response) => {
				setGrupoContratoTipo(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		findGrupo();
		findStatusCobranca();
		findGrupoContratoTipo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (unidade && unidade.id) {
			const predicate = new Predicate();
			setIsGlobalLoading(true);
			predicate.removeAllOption();

			if (licencaId !== undefined) {
				licencaId.map((li) => {
					li === 1
						? predicate.addOption('tipoLicencaIntraId', li)
						: li === 2
						? predicate.addOption('tipoLicencaExtraId', li)
						: li === 3
						? predicate.addOption('tipoLicencaAcademicaId', li)
						: null;
				});
			}

			unidade.id && predicate.addOption('unidadeId', unidade.id);
			grupoId && predicate.addOption('grupoId', grupoId);
			grupoContratoTipoId &&
				predicate.addOption('tipoContratoId', grupoContratoTipoId);
			dataVencimentoInicio &&
				predicate.addOption(
					'dataVencimentoInicio',
					dataVencimentoInicio.toISOString()
				);
			dataVencimentoFim &&
				predicate.addOption(
					'dataVencimentoFim',
					dataVencimentoFim.toISOString()
				);
			dataPagamentoInicio &&
				predicate.addOption(
					'dataPagamentoInicio',
					dataPagamentoInicio.toISOString()
				);
			dataPagamentoFim &&
				predicate.addOption('dataPagamentoFim', dataPagamentoFim.toISOString());

			if (statusCobrancaId != null) {
				statusCobrancaId >= 0 &&
					predicate.addOption('statusCobrancaId', statusCobrancaId);
			}

			service
				.financeiroEscola(predicate)
				.then(({ data }) => {
					setData(data);
				})
				.finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dataVencimentoInicio,
		dataVencimentoFim,
		dataPagamentoInicio,
		dataPagamentoFim,
		unidade,
		grupoId,
		grupoContratoTipoId,
		licencaId,
		statusCobrancaId,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	let nrLicencaIntra = 0;
	let nrLicencaExtra = 0;
	let nrLicencaAcademica = 0;
	let valorParcelaSplit = 0;
	let valorTotalSplit;
	let valorParcela = 0;
	let valorTotalReceita;

	dados?.forEach((dado) => {
		nrLicencaIntra += parseFloat(
			String(dado.CXX_NR_LICENCA_TOTAL_INTRA).replace(',', '.')
		);
		nrLicencaExtra += parseFloat(
			String(dado.CXX_NR_LICENCA_TOTAL_EXTRA).replace(',', '.')
		);
		nrLicencaAcademica += parseFloat(
			String(dado.CXX_NR_LICENCA_TOTAL_ACADEMICO).replace(',', '.')
		);
		valorParcelaSplit += parseFloat(
			String(dado.C07_VL_SPLIT).replace(',', '.')
		);
		valorTotalSplit = valorParcelaSplit.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
		valorParcela += parseFloat(String(dado.C06_VL_TOTAL).replace(',', '.'));
		valorTotalReceita = valorParcela.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
	});

	const getPeriodo = (): any => {
		if (dataVencimentoInicio && dataVencimentoFim) {
			return [
				moment(dataVencimentoInicio, 'YYYY-MM-DD'),
				moment(dataVencimentoFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const getPeriodoPago = (): any => {
		if (dataPagamentoInicio && dataPagamentoFim) {
			return [
				moment(dataPagamentoInicio, 'YYYY-MM-DD'),
				moment(dataPagamentoFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const selectStatusCobranca = statusCobranca?.map((statusCobranca) => (
		<Option key={statusCobranca.id} value={statusCobranca.id}>
			{statusCobranca.descricao}
		</Option>
	));
	const selectGrupos = grupo?.map((grupo) =>
		grupo?.unidade?.id === unidade?.id ? (
			<Option key={grupo?.id} value={grupo?.id}>
				{grupo?.nomeFantasia}
			</Option>
		) : null
	);
	const selectGrupoContratoTipo = grupoContratoTipo?.map((contrato) => (
		<Option key={contrato?.id} value={contrato.id}>
			{contrato?.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div className="dashboard">
				<div className="box-header-contador-relatorio">
					<div>
						<h1>Licença Intra</h1>
						<p>{nrLicencaIntra}</p>
					</div>
					<div>
						<h1>Licença Extra</h1>
						<p>{nrLicencaExtra}</p>
					</div>
					<div>
						<h1>Licença Serviço</h1>
						<p>{nrLicencaAcademica}</p>
					</div>
					<div>
						<h1>Total Split Sede</h1>
						<p>{valorTotalSplit}</p>
					</div>
					<div>
						<h1>Receita Total</h1>
						<p>{valorTotalReceita}</p>
					</div>
				</div>
				<h1>Relatório financeiro Escola</h1>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Row style={{ marginBottom: 10 }}>
					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Grupos</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={grupoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setGrupoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectGrupos}
						</Select>
					</Col>
					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Tipo Licença</span>
						<br />
						<Select
							showSearch
							allowClear
							mode="multiple"
							style={filtroStyle}
							defaultValue={licencaId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setLicencaId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							<Option value={1}>Intra</Option>
							<Option value={2}>Extra</Option>
							<Option value={3}>Serviço</Option>
						</Select>
					</Col>
					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Tipo Contrato</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={grupoContratoTipoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setGrupoContratoTipoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectGrupoContratoTipo}
						</Select>
					</Col>
					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Status da Cobrança</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={statusCobrancaId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setStatusCobrancaId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							<Select.Option value={0}>Todos</Select.Option>
							<Select.Option value={99999}>Vencidos</Select.Option>
							{selectStatusCobranca}
						</Select>
					</Col>
					<Col flex={2} style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de Vencimento</span>
						<br />
						<RangePicker
							value={getPeriodo()}
							onChange={(values) => {
								setDataVencimentoInicio(values && values[0]);
								setDataVencimentoFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</Col>
					<Col flex={2} style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de Pagamento</span>
						<br />
						<RangePicker
							value={getPeriodoPago()}
							onChange={(values) => {
								setDataPagamentoInicio(values && values[0]);
								setDataPagamentoFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</Col>
					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={dados}
							fileName={`Financeiro ` + hoje().toISOString()}
						/>
					</Col>
				</Row>
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}

export default RelFinanceiroEscola;
