import * as Unicons from '@iconscout/react-unicons';
import { Paid } from '@mui/icons-material';
import { useGlobalContext } from '../../context/GlobalContext';
import { FuncaoEnum } from '../../enum/funcao.enum';
import { IUnidadeKpiCustomDTO } from '../../models/happy-code-api.model';
import { formatPercent } from '../../util/format';

interface Props {
    kpi: IUnidadeKpiCustomDTO;
    contratoAtivo: number;
}

const IndicadoresHome: React.FC<Props> = ({ kpi, contratoAtivo }: Props) => {
    const { usuario } = useGlobalContext();
    return (
        <div className="boxes-dash">
            <div
                className="box-amarelo"
                style={{ width: '80%', height: 120, marginRight: 10 }}
            >
                <div style={{ display: 'flex' }}>
                    <h4 style={{ marginRight: 1 }}>
                        <Unicons.UilSmile />
                    </h4>
                    <h6>Contratos Ativos</h6>
                </div>
                <div
                    className="alin-kpi-home"
                    style={{ justifyContent: 'center' }}
                >
                    <h4>{contratoAtivo || '0'}</h4>
                </div>
            </div>
            <div
                className="box-blue"
                style={{ width: '80%', height: 120, marginRight: 10 }}
            >
                <div style={{ display: 'flex' }}>
                    <h4 style={{ marginRight: 1 }}>
                        <Unicons.UilGraduationCap />
                    </h4>
                    <h6>Contratos Mês</h6>
                </div>
                <div className="alin-kpi-home">
                    <h4>
                        {kpi?.quantidadeMatricula || '0'} /{' '}
                        {kpi?.valorHubMatricula || '0'}
                    </h4>
                    <h4>
                        {kpi?.valorHubMatricula
                            ? formatPercent(
                                kpi?.quantidadeMatricula /
                                kpi?.valorHubMatricula || 0
                            )
                            : null}
                    </h4>
                </div>
            </div>
            <div
                className="box-green"
                style={{ width: '80%', height: 120, marginRight: 10 }}
            >
                <div style={{ display: 'flex' }}>
                    <h4 style={{ marginRight: 1 }}>
                        <Unicons.UilGraduationCap />
                    </h4>
                    <h6>Rematrículas Mês</h6>
                </div>
                <div className="alin-kpi-home">
                    {/* <h4>
											{kpi?.quantidadeRematricula || '0'} /{' '}
											{kpi?.valorHubRematricula || '0'}{' '}
										</h4>
										<h4>
											{kpi?.valorHubRematricula
												? formatPercent(
														kpi?.quantidadeRematricula /
															kpi?.valorHubRematricula || 0
												  )
												: null}
										</h4> */}
                    <h4>EM REVISÃO</h4>
                </div>
            </div>

            {usuario.funcao !== FuncaoEnum.GESTAO_ACADEMICA_CODIGO &&
                usuario.funcao !== FuncaoEnum.INSTRUTOR_CODIGO ? (
                <div
                    className="box-red"
                    style={{ width: '100%', height: 120 }}
                >
                    <div style={{ display: 'flex' }}>
                        <h4 style={{ marginRight: 1 }}>
                            <Paid />
                        </h4>
                        <h6>Resultado Financeiro</h6>
                    </div>
                    <div className="alin-kpi-home">
                        {/* <h4>
												{formatValue(kpi?.valorRecebido || 0)} /{' '}
												{formatValue(kpi?.valorFaturado || 0)}{' '}
											</h4>
											<h4>
												{formatPercent(
													kpi?.valorRecebido /
														kpi?.valorFaturado || 0
												)}{' '}
											</h4> */}
                        <h4>EM REVISÃO</h4>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default IndicadoresHome;
