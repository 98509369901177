import {
	ICategoriaConfessionalCleanDTO,
	ICategoriaConfessionalDTO,
	ICategoriaConfessionalFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useCategoriaConfessionalService = () => {
	const resourceURL = 'categoriaconfessional';

	return {
		...baseFindList<ICategoriaConfessionalCleanDTO, ICategoriaConfessionalDTO, ICategoriaConfessionalFullDTO>(resourceURL),
	};
};
