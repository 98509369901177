import * as Unicons from '@iconscout/react-unicons';
import { ArrowLeftOutlined } from '@mui/icons-material';
import { Button, Progress } from 'antd';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { GrupoPessoaTipoEnum } from '../../../enum/grupo-pessoa-tipo.enum';
import '../../../theme/flex.scss';
import { MenuRevisaoEscolaGrupoComponent } from '../components/menu-revisao-grupo-component/menu-revisao-escola-grupo.component';
import '../styles/escola-grupo-create-step.styles.scss';

export const GrupoCreateStepRevisaoScreen: React.FunctionComponent = () => {
  const { unidade, setRevisao } = useUnidadeContext();
  const { grupoParaCadastrar,
    setFlRevisao,
    getCategoriaConfessionalById,
    getCategoriaQuantidadeAlunosById,
    getPessoaByCodigo, grupoPessoaTipos } = useEscolaContext();
  const history = useHistory();

  useEffect(() => {
    if (!grupoParaCadastrar) {
      window.history.replaceState({}, document.title);
      history.replace('/escolas');
      return;
    }

    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getQtdDocumentoPendente = () => {
    return 8 - unidade.unidadeDocumentos.reduce((acc, curr) => {
      if (acc.some(u => u.tipoDocumentoId === curr.tipoDocumentoId)) return acc;
      else return [...acc, curr];
    }, []).length;
  }

  const renderDadosBasicos = () => {
    return <div className="box-revisao">
      <div className="dados">
        <Button className="button-line" onClick={() => {
          history.push("/escolas/grupos/create/step-1");
          setFlRevisao(true);
        }}>Editar</Button>

        <div className='header-revisao'>
          <h4>Dados Básicos</h4>
        </div>

        <div className="nome revisao-nome">
          <h5>Nome Fantasia</h5>
          <span>{grupoParaCadastrar?.nomeFantasia}</span>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>CNPJ</h6>
            <span>{grupoParaCadastrar?.cnpj}</span>
          </div>
          <div className="cpf">
            <h6>Quant. Alunos</h6>
            <span>{getCategoriaQuantidadeAlunosById(grupoParaCadastrar?.categoriaQuantidadeAlunos?.id)?.descricao}</span>
          </div>
        </div>

        {/* <div className="dados-franquia">
          <div className="cpf">
            <h6>Razão social</h6>
            <span>{grupoParaCadastrar?.dsRazaoSocial}</span>
          </div>
        </div> */}
        <div className="dados-franquia">
          <div className="cpf">
            <h6>Grupo e-mail</h6>
            <span>{grupoParaCadastrar?.email}</span>
          </div>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>Categoria Confessional</h6>
            <span>{getCategoriaConfessionalById(grupoParaCadastrar?.categoriaConfessional?.id)?.descricao}</span>
          </div>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>Responsável pelo suporte</h6>
            <span>{grupoParaCadastrar?.unidade?.descricao || "SEDE"}</span>
          </div>
        </div>
      </div>
      <div className="line"></div>
    </div>
  }

  const renderLocalizacao = () => {
    return <div className="box-revisao">
      <div className="dados">
        <Link to="/escolas/grupos/create/step-2">
          <Button className="button-line">Editar</Button>
        </Link>

        <div className='header-revisao'>
          <h4>Localização</h4>
        </div>

        <div className="dados-franquia">
          <div className="cpf">
            <h6>CEP</h6>
            <span>{grupoParaCadastrar?.endereco.cep}</span>
          </div>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>ENDEREÇO</h6>
            <span>{grupoParaCadastrar?.endereco?.logradouro}</span>
          </div>
          <div className="cpf">
            <h6>NUMERO</h6>
            <span>{grupoParaCadastrar?.endereco?.numero}</span>
          </div>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>COMPLEMENTO</h6>
            <span>{grupoParaCadastrar?.endereco?.complemento}</span>
          </div>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>Cidade</h6>
            <span>{grupoParaCadastrar?.endereco?.cidade}</span>
          </div>
          <div className="cpf">
            <h6>Estado</h6>
            <span>{grupoParaCadastrar?.endereco?.nomeUF}</span>
          </div>
        </div>
      </div>
      <div className="line"></div>
    </div>
  }

  const renderFocais = () => {

    return <div className="box-revisao">
      <div className="dados">
        <Link to="/escolas/grupos/create/step-3">
          <Button className="button-line">Editar</Button>
        </Link>

        <div className='header-revisao'>
          <h4>Focais</h4>
        </div>

        {grupoPessoaTipos?.map((item) => {
          if (item.codigo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO) {
            return <div className="dados-franquia" key={item.id}>
              <div className="cpf">
                <h6>{item?.descricao}</h6>
                <span>{grupoParaCadastrar?.focalFinanceiro?.nome}</span>
              </div>
            </div>
          }

          if (item.codigo === GrupoPessoaTipoEnum.FOCAL_OPERACIONAL) {
            return <div className="dados-franquia" key={item.id}>
              <div className="cpf">
                <h6>{item?.descricao}</h6>
                <span>{grupoParaCadastrar?.focalOperacional?.nome}</span>
              </div>
            </div>
          }

          return <div className="dados-franquia" key={item.id}>
            <div className="cpf">
              <h6>{item?.descricao}</h6>
              <span>{getPessoaByCodigo(item?.codigo, grupoParaCadastrar?.pessoas)?.pessoa?.nome}</span>
            </div>
          </div>
        })}

      </div>
      <div className="line"></div>
    </div>
  }

  const renderFinanceiro = () => {
    return <div className="box-revisao">
      <div className="dados">
        <Link to="/franquias/create/step-14">
          <Button className="button-line">Editar</Button>
        </Link>
        <h4>Dados Bancários</h4>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>Número do banco</h6>
            <span>{unidade?.banco}</span>
          </div>
        </div>
        <div className="dados-franquia">
          <div className="cpf">
            <h6>Número da agência</h6>
            <span>{unidade?.agencia}</span>
          </div>
          <div className="cpf">
            <h6>Dígito da agência</h6>
            <span>{unidade?.digitoAgencia}</span>
          </div>
        </div>

        <div className="dados-franquia">
          <div className="cpf">
            <h6>Número da conta</h6>
            <span>{unidade?.conta}</span>
          </div>
          <div className="cpf">
            <h6>Dígito da conta</h6>
            <span>{unidade?.digitoConta}</span>
          </div>
        </div>
      </div>
    </div>
  }

  return (<div id="escola-grupo-create-step" className="container-fondo">
    <Header />
    <div className="progress">
      <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
    </div>
    <MenuRevisaoEscolaGrupoComponent qtdDocumentoPendente={getQtdDocumentoPendente()} />
    <div className="franquia" >
      <div className="franquia-row">
        <h5>Revisão de Dados</h5>
        <div className="row-revisao">
          {renderDadosBasicos()}
          {renderLocalizacao()}
          {renderFocais()}
        </div>
      </div>
    </div>
    <Link to="/escolas/grupos/">
      <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
    </Link>
    <div className="botao-voltar-revisao">
      <Button className="button-second" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
        Voltar
      </Button>
    </div>
  </div>
  )
}