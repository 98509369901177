
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PresentImage } from '../../../../components/ApresentarImagem';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IConectaDTO as IConectaDTO } from '../../../../models/happy-code-api.model';
import { useNotificationService } from '../../../../services/notification.service';
import { useAcademyConectaService } from '../../../../services/academy-conceta';

interface TItemLista {
    conecta: IConectaDTO;
}

const AcademyConectaItemList = memo(({ conecta }: TItemLista) => {
    const history = useHistory();
    const [ativo, setAtivo] = useState<boolean>();
    const conectaService = useAcademyConectaService();
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();

    const goTo = () => {
        history.push(`/academy/conectas/${conecta?.id}/create`)
    }

    useEffect(() => {
        setAtivo(conecta.ativo);
    }, [conecta.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a conecta?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: conecta?.nome,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                conectaService.patch({ id: conecta.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Conecta alterada com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <PresentImage
                    link={conecta?.capa ? conecta?.capa : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + conecta?.arquivo?.url}

                />
                <div className="nome">
                    <p>{conecta?.nome}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{conecta?.id}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "45%" }}></div>
            <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

AcademyConectaItemList.displayName = 'AcademyConectaItemList';


export default AcademyConectaItemList;
