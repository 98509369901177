import * as Unicons from '@iconscout/react-unicons';
import { Rating } from "@material-ui/lab";
import moment from 'moment';
import { useEffect, useState } from "react";
import { ILeadCustomDTO } from "../../../../models/happy-code-api.model";
import useTipoRetornoState from '../../selectors/tipo-retorno.state';
import '../../shared.scss';
import { LeadDetalhe } from "../Modal/lead-detalhe/lead-detalhe";
import './style.scss';
import { useHistory } from 'react-router-dom';

interface Props {
  type?: string,
  lead: ILeadCustomDTO,
  leadSelected: boolean,
  leadParamsId: any,
}

export const Lead = (props: Props) => {
  const [isOpenLeadDetalhe, setisOpenLeadDetalhe] = useState(props.leadSelected);
  const [lead, setLead] = useState(props.lead);
  const history = useHistory();

  const onChangeLeadDetalhe = () => {
    if (isOpenLeadDetalhe === true) {
      setisOpenLeadDetalhe(false)
    } else {
      setisOpenLeadDetalhe(true)
    }
  }

  const onCloseLeadDetalhe = () => {
    if (props.leadParamsId) {
      history.push(`/franquia`);
    }
    setisOpenLeadDetalhe(false)
  }

  useEffect(() => {
    setLead({ ...props.lead });
  }, [props.lead]);

  const { getTipoRetorno,
    getDsLabelTipoRetorno,
    getBoxColorTipoRetorno, } = useTipoRetornoState();
  const type = getTipoRetorno(lead);

  const isPossuiAnotacoesAgendamentosFuturos = () => {
    return lead.anotacoes?.some((anotacao) => moment(anotacao.dhRetorno).startOf('day').isAfter(moment().startOf('day')));
  }

  return (<><div onClick={onChangeLeadDetalhe} className={`lead-${type}`}>   
      <Unicons.UilDraggabledots className="dragg" />
      <h3>{lead?.dsNomeLead}</h3>
      <div className="status">{getDsLabelTipoRetorno(type)}</div>
      <h5>{lead?.campanha?.dsLeadCampanha}</h5>
      {lead?.dhProximoRetorno && <h5>{moment(lead?.dhProximoRetorno).format('DD/MM/YYYY hh:mm:ss')}</h5>}
      <div className="line-lead"></div>
      <div className="icons flex flex-row justify-end align-center w-100">
        <div>
          <Rating
            name="Avaliação do Lead"
            value={lead?.nrAvaliacaoLead}
            disabled
          />
        </div>
        {isPossuiAnotacoesAgendamentosFuturos() && <Unicons.UilFileAlt color="var(--verde-futuro)" size={24} />}
        <Unicons.UilSync color="var(--cinza-azulado-200)" size={22} />
      </div>
  </div>
    <LeadDetalhe
      open={isOpenLeadDetalhe}
      onClose={onCloseLeadDetalhe}
      type={type === 'sem' ? '' : '-' + type}
      boxColor={getBoxColorTipoRetorno(type)}
      lead={lead}
    />
  </>);
}
