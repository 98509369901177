/* eslint-disable react-hooks/rules-of-hooks */
import { Button, DatePicker, InputNumber, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { IFinanceiroEscolaCustomDTO } from "../../models/happy-code-api.model";
import { useFinanceiroEscolaService } from "../../services/financeiro-escola.service";
import { useNotificationService } from "../../services/notification.service";
import { useTaxaCobrancaService } from "../../services/taxa-cobranca.service";
import moment from "moment";

interface Text {
    open: boolean;
    onClose: () => void;
    id: number;
    escola: boolean;
}

export const ModalBaixa: React.FunctionComponent<Text> = ({ open, onClose, id, escola }: Text) => {

    if (!open) return null

    const { setIsGlobalLoading } = useGlobalContext();
    const [file, setFile] = useState<any>();
    const financeiroEscolaService = useFinanceiroEscolaService();
    const taxaCobrancaService = useTaxaCobrancaService();
    const notification = useNotificationService();
    const [dtoObservacao, setDtoObservacao] = useState<IFinanceiroEscolaCustomDTO>();
    const asterisco = (<span style={{ color: "red" }}>*</span>);
    const maxLenght = 255;
    const [contador, setContador] = useState<number>(maxLenght);
    const [color, setColor] = useState<string>('blue');

    const enviarBaixa = () => {
        setIsGlobalLoading(true);
        if (escola === true) {
            financeiroEscolaService.baixaManualObservacao(dtoObservacao, id)
                .then(() => financeiroEscolaService.baixaManual(file[0], id)
                    .then(() => notification({ description: 'Cobrança alterada com sucesso!', type: 'success', message: 'Sucesso' })))
                .finally(() => { setIsGlobalLoading(false), onClose() });
        } else {
            taxaCobrancaService.baixaManualObservacao(dtoObservacao, id)
                .then(() => taxaCobrancaService.baixaManual(file[0], id)
                    .then(() => notification({ description: 'Cobrança alterada com sucesso!', type: 'success', message: 'Sucesso' })))
                .finally(() => { setIsGlobalLoading(false), onClose() });
        }
    }

    const changeObs = (obs: string) => {
        setDtoObservacao({ ...dtoObservacao, observacao: obs })
        setContador(maxLenght - obs.length)
        if (contador < 200) {
            setColor('#F5B111');
        }
        if (contador < 100) {
            setColor('red');
        }

    }
    
    return (
        <div className="modalContainerFinanceiro">
            <div className="modal-user-financeiro">
                <div className="body">
                    <h3 style={{ textAlign: 'center' }}>Baixa manual</h3>
                </div>
                <div className="input-box" id="box-aluno" style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Row >
                        <span>Justificativa {asterisco}</span>
                        <TextArea className="input"
                            rows={2}
                            cols={100}
                            maxLength={maxLenght}
                            onChange={(e) => changeObs(e.target.value)}
                            style={{ backgroundColor: '#eeeeee', marginTop: 10, marginBottom: 10 }}
                        />
                    </Row>
                    <p style={{ color: color }}>{contador}</p>
                </div>
                <div className="input-box" id="box-aluno">
                    <Row >
                        <span>Valor pago {asterisco}</span>
                    </Row>
                    <Row>
                        <InputNumber
                            decimalSeparator="."
                            type="number"
                            width={'100%'}
                            onChange={(e) => setDtoObservacao({ ...dtoObservacao, valorPago: e as number })}
                        ></InputNumber>
                    </Row>
                </div>
                <div className="input-box" id="box-aluno">
                    <span>Data pagamento {asterisco}</span>
                    <DatePicker
                        onChange={(e) => setDtoObservacao({ ...dtoObservacao, dataPagamento: e.toDate() })} >

                    </DatePicker>
                </div>
                <div className="input-box" id="box-aluno" style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <span>Comprovante {asterisco}</span>  <br />
                    <input required={true} type="file" onChange={e => setFile(e.target.files)}></input>
                </div>
                <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <p>Data de pagamento</p>
                    <DatePicker
                        allowClear={true}
                        placeholder={'Nova data'}
                        size={"large"}
                        format={'DD/MM/YYYY'}
                        defaultValue={moment(Date.now())}
                        onChange={(e) => setDtoObservacao({ ...dtoObservacao, dataBaixa: moment(e).toDate() })}
                        getPopupContainer={triggerNode => {
                            return triggerNode.parentElement
                        }}
                    />
                </div>
                <div className="botoes">
                    <Button className={'button-second'} onClick={onClose}>Voltar</Button>
                    <Button className='button-danger' disabled={file === undefined || dtoObservacao === undefined} onClick={enviarBaixa} style={{ marginLeft: 8, minWidth: 145 }}>Baixar</Button>
                </div>
            </div>
        </div>
    )
}
