import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useCampanhaContext } from '../../context/CampanhaContext';

interface Props {
    current: number;
}

function StepsCreate({ current }: Props): ReactElement {

    const { revisao } = useCampanhaContext();

    return (
        <div className="menu-user">
            <h3>Criação de negócio</h3>
            <Steps direction="vertical" current={revisao ? 5 : current}>
                <Steps.Step title="Dados Básicos" />
                <Steps.Step title="Unidades" />
                <Steps.Step title="Matricula" />
                <Steps.Step title="Rematricula" />                
                <Steps.Step title="Revisão" />
            </Steps>
        </div>
    )
}

export default StepsCreate
