import { useEffect } from 'react';
import { ContatoEnum } from '../../../enum/contato.enum';
import { IAlunoLeadCustomDTO, IEnderecoCleanDTO, IPessoaCustomDTO, IPessoaResponsavelCustomDTO } from '../../../models/happy-code-api.model';

const useAlunoCrmLead = (lead, aluno, setAluno) => {

    useEffect(() => {
        if (lead) {
            setAluno((aluno: IAlunoLeadCustomDTO) => {
                aluno.pessoa.pessoaResponsaveis = [];

                const endereco = lead.endereco ? lead.endereco : {} as IEnderecoCleanDTO;

                const novoResponsavel: IPessoaResponsavelCustomDTO = {
                    responsavel: {
                        cpf: lead.cdCpfCnpj,
                        rg: '',
                        email: lead.cdEmail,
                        endereco: endereco,
                        genero: null,
                        ativo: true,
                        id: null,
                        descricaoEmpresa: '',
                        observacao: '',
                        descricaoProfissao: '',
                        descricaoRestricao: '',
                        dataNascimento: null,
                        dataAdmissao: null,
                        permiteUsoImagem: false,
                        nome: lead.dsNomeLead,
                        foto: null,
                        contatos: [
                            {
                                tipoContatoId: ContatoEnum.CEL,
                                descricao: lead.cdDddCelular + lead.cdCelular,
                                id: null,
                                codigo: null,
                                ativo: true,
                                tipoContatoCodigo: '',
                            },
                            {
                                tipoContatoId: ContatoEnum.TEL,
                                descricao: lead.cdDddTelefone + lead.cdTelefone,
                                id: null,
                                codigo: null,
                                ativo: true,
                                tipoContatoCodigo: '',
                            },
                            {
                                tipoContatoId: ContatoEnum.EPS,
                                descricao: lead.cdEmail,
                                id: null,
                                codigo: null,
                                ativo: true,
                                tipoContatoCodigo: '',
                            },
                        ],
                        unidadePessoas: [],
                        identificadorPagarMe: '',
                        pessoaResponsaveis: [],
                        documentos: [],
                    } as IPessoaCustomDTO,
                    id: null,
                    ordem: 0,
                    principal: true,
                    grauParentesco: '',
                    ativo: true,
                };

                aluno?.pessoa?.pessoaResponsaveis?.push(novoResponsavel);
                return { ...aluno };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lead]);

    return { aluno, setAluno };
};

export default useAlunoCrmLead;
