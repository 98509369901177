import * as Unicons from '@iconscout/react-unicons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import MenuDocumentos from '../../../components/MenuDocumentos';
import ModalHistorico from '../../../components/ModalHistorico';
import Progress from '../../../components/Progress';
import '../style.css';

const FranquiaCreateStep11: React.FunctionComponent = () => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container">
      <Header />
      <Progress />
      <MenuDocumentos />
      <div className="franquia" style={{ top: 70 }}>
        <div className="franquia-row">
          <h5>Documentos de Franquia</h5>
          <div className="row-clasificacao">
            <div className="text-span">9 documentos pendentes</div>
            <div className="box-documentos">
              <div className="franja-verde"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>COF</h6>
                    <span>Esse documento é COF.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Pendente</p>
                  </div>
                </div>
                <div className="line-docs"></div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja-vermelho"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Contrato</h6>
                    <span>Esse documento é Contrato.</span>
                  </div>
                  <div className="dot-red">
                    <div className="dot-vermelho"></div>
                    <h6>Falha no envio de um ou mais arquivos</h6>
                  </div>
                </div>
                <button className="button-upload">
                  <Unicons.UilCloudUpload size="20" color="#895CF2" className="icon-upload" />
                  Fazer Upload
                </button>
                <div className="line-docs"></div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-upload">
                      <Unicons.UilRedo size="20" color="#92A9CB" />
                      <div className="check-doc-red">
                        <Unicons.UilTimes size="16" color="#FFFFFF" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line-docs"></div>
                <div className="historico">
                  <button onClick={() => setIsOpen(true)} >
                    <span>Ver histórico</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja-verde"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Certidão Negativa de Débito da Receita Federal e Estadual</h6>
                    <span>Esse documento é Certidão Negativa de Débito da Receita Federal e Estadual.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Pendente</p>
                  </div>
                </div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja-verde"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Certidão Negativa da Prefeitura</h6>
                    <span>Esse documento é Certidão Negativa da Prefeitura.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Pendente</p>
                  </div>
                </div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja-verde"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Certidão dos Distribuidores Fiscal, Civel, Trabalhista e Criminal nas esferas Estadual e Federal</h6>
                    <span>Esse documento é Certidão dos Distribuidores Fiscal, Civel, Trabalhista e Criminal nas esferas Estadual e Federal.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Pendente</p>
                  </div>
                </div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Certidão de Protesto</h6>
                    <span>Esse documento é Certidão de Protesto.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-orange"></div>
                    <span>Pendente</span>
                  </div>
                </div>
                <button className="button-upload">
                  <Unicons.UilCloudUpload size="20" color="#895CF2" className="icon-upload" />
                  Fazer Upload
                </button>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja-verde"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Antecedentes criminais</h6>
                    <span>Esse documento é Antecedentes criminais.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Pendente</p>
                  </div>
                </div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja-verde"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Declaração de Imposto de Renda dos últimos 2 anos</h6>
                    <span>Esse documento é Declaração de Imposto de Renda dos últimos 2 anos.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Pendente</p>
                  </div>
                </div>
                <div className="arquivos-box">
                  <span>Arquivos</span>
                  <div className="arquivos">
                    <div className="nome">
                      <Unicons.UilPaperclip size="20" color="#92A9CB" className="icon-upload" />
                      <p>nomedoarquivo.pdf</p>
                    </div>
                    <div className="check-doc">
                      <Unicons.UilCheck size="16" color="#FFFFFF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-documentos">
              <div className="franja"></div>
              <div className="documentos">
                <div className="descricao">
                  <div>
                    <h6>Laudo de vistoria dos bombeiros</h6>
                    <span>Esse documento é Laudo de vistoria dos bombeiros.</span>
                  </div>
                  <div className="dot">
                    <div className="dot-orange"></div>
                    <span>Pendente</span>
                  </div>
                </div>
                <button className="button-upload">
                  <Unicons.UilCloudUpload size="20" color="#895CF2" className="icon-upload" />
                  Fazer Upload
                </button>
              </div>
            </div>
            <div className="botoes">
              <Link to="/franquias/create/step-10">
                <div className="voltar"><span>Cancelar</span></div>
              </Link>
              <Link to="/franquias/create/step-12">
                <div className="proximo"><span>Salvar Alterações</span></div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ModalHistorico open={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}

export default FranquiaCreateStep11;