import { Button, Form, Select } from 'antd';
import Modal from '../../../../../components/Modal';
import '../../../shared.scss';
import BotoesCadastroComponent from '../../BotoesCadastro/botoes-cadastro.component';
import StepCadastroComponent from '../../StepCadastro/step-cadastro.component';
import './novo-lead-step-6.scss';
import useNovoLeadStep6ComponentState from './novo-lead-step-6.state';

interface Props {
  onClose?: () => void;
  onNext?: () => void;
  onFecharModal?: () => void;
}

export const NovoLeadStep6 = (props: Props) => {

  const {
    leadParaCadastrar,
    onFinish,
    onFecharModal,
    leadStatus,
    isModalConfirmacao,
    onCloseModalConfirmacao,
    onCadastrarLead,
    setIsModalConfirmacao,
  } = useNovoLeadStep6ComponentState();

  const [form] = Form.useForm();

  const { Option } = Select;

  return (
    <>
      <div id="novo-lead-step-1" className="flex flex-row justify-center align-center modal-container modalBox">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onFecharModal} className="button-x">x</Button>
          <div className='container-titulo'>
            <h1>Cadastrar Lead</h1>
          </div>
          <h3>Status</h3>
          <Form
            style={{ minWidth: '100%' }}
            name="control-hooks"
            initialValues={leadParaCadastrar}
            form={form}
            onFinish={onFinish}
          >

            <StepCadastroComponent />
            <BotoesCadastroComponent />
          </Form>
        </div>

        {/* <ModalResposta
          open={isModalSucessoOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link="crm"
        />
        <ModalResposta
          open={isModalErroOpen}
          onClose={onClose1}
          status="Ocorreu um erro!"
          text="Ocorreu um erro ao salvar o lead, por favor, tente novamente."
          link="crm"
        /> */}

        <Modal
          open={isModalConfirmacao}
          onClose={() => setIsModalConfirmacao(false)}
          title="Você deseja cadastrar o Lead?"
          subtitle=''
          textButton2="Sim"
          textButton1="Não, Cancelar"
          styleButton2="button-second"
          styleButton1="button-line-red"
          button2={onCadastrarLead}
        />
      </div >
    </>
  )
}
