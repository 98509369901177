import http from '../helpers/http-common';
import {
	IUnidadeAtuacaoCleanDTO,
	IUnidadeAtuacaoDTO,
	IUnidadeAtuacaoFullDTO,
	IUnidadeCaracteristicaCleanDTO,
	IUnidadeCaracteristicaDTO,
	IUnidadeCaracteristicaFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useUnidadeCaracteristicaService = () => {
	const resourceURL = 'unidade-caracteristica';

	return {
		...baseFindList<IUnidadeCaracteristicaCleanDTO, IUnidadeCaracteristicaDTO, IUnidadeCaracteristicaFullDTO>(
			resourceURL
		),
	};
};
