import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import { IFuncaoCleanDTO, IPerfilCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useFuncaoService } from '../../services/funcao.service';
import { usePerfilService } from '../../services/perfil.service';

interface TFiltros {
    predicate: Predicate;
    findUsuario: () => void;
}
const Filtros = memo(({ predicate, findUsuario }: TFiltros) => {
    const [descricao, setDescricao] = useState<string>();
    const [status, setStatus] = useState<number | string>();
    const [funcao, setFuncao] = useState<number | string>();
    const [perfil, setPerfil] = useState<number | string>();

    const [funcoes, setFuncoes] = useState<IFuncaoCleanDTO[]>();
    const [perfis, setPerfis] = useState<IPerfilCleanDTO[]>();

    const serviceFuncao = useFuncaoService();
    const perfilService = usePerfilService();


    const selectFuncoes = funcoes?.map((data) => (<Select.Option key={data.id} value={data.id}>{data.descricao}</Select.Option>))
    const selectPerfis = perfis?.map((data) => (<Select.Option key={data.id} value={data.id}>{data.descricao}</Select.Option>))

    useEffect(() => {
        serviceFuncao.findCleanList()
            .then(({ data }) => setFuncoes(data));
        perfilService.findCleanList()
            .then(({ data }) => setPerfis(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('pessoa.nome', descricao, Operators.CONTAINS);
            }
            if (funcao) {
                predicate.addOption('funcao.id', funcao);
            }
            if (perfil) {
                predicate.addOption('perfil.id', perfil);
            }
            if (status) {
                predicate.addOption('ativo', status);
            }
            findUsuario();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, funcao, perfil, status]);

    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '70%' }}>
                <Input id="buscar" value={descricao} placeholder="Buscar Usuário" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} onChange={e => setDescricao(e.target.value)} />
            </div>
            <div className="filtro-estado" id="input-dashboard">
                <span>Função</span>
                <Select className="ant-dropdown-link" defaultValue={null} style={{ width: 120 }} onChange={e => setFuncao(parseInt(e))}>
                    <Select.Option value={null}>Todos</Select.Option>
                    {selectFuncoes}
                </Select>
            </div>
            <div className="filtro-cidade" id="input-usuario">
                <span>Tipo de Usuários</span>
                <Select defaultValue={null} onChange={(e) => setPerfil(e)}>
                    <Select.Option value={null}>Todos</Select.Option>
                    {selectPerfis}
                </Select>
            </div>
            <div className="filtro-estado" id="input-dashboard">
                <span>Status</span>
                <Select defaultValue={null} onChange={(e) => setStatus(e)}>
                    <Select.Option value={null}>Todos</Select.Option>
                    <Select.Option value="true">Ativo</Select.Option>
                    <Select.Option value="false">Inativo</Select.Option>
                </Select>
            </div>
        </div>
    </>
    )
})

Filtros.displayName = 'Filtros'

export default Filtros;