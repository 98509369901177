import { CloseOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Input,
	InputNumber,
	Progress,
	Select,
	Steps,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import ModalCreate from '../../components/ModalCreate';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Predicate } from '../../models/predicate.model';
import { useCurriculoService } from '../../services/curriculo.service';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { useNegocioService } from '../../services/negocio.service';
import { useNotificationService } from '../../services/notification.service';
import { useSalaService } from '../../services/sala.service';
import { useTurmaService } from '../../services/turma.service';
import { useUnidadePessoaService } from '../../services/unidade-pessoa.service';
import {
	ICurriculoCleanDTO,
	INegocioDTO,
	INegocioUnidadeDTO,
	ISalaCleanDTO,
	ITurmaFullDTO,
	IUnidadePessoaCleanDTO,
} from './../../models/happy-code-api.model';
import './style.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const TurmasCreateStep2: React.FunctionComponent = () => {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const { turma, setTurma, removeTurma, editBtn, editBtnIsVisible } =
		useTurmaContext();
	const { Step } = Steps;
	const [isOpen, setIsOpen] = useState(false);
	const [listProfessor, setListProfessor] = useState<IUnidadePessoaCleanDTO[]>(
		[]
	);
	const [listNegocios, setListNegocios] = useState<INegocioUnidadeDTO[]>();
	const [listCurriculos, setListCurriculos] = useState<ICurriculoCleanDTO[]>();
	const [listSalas, setListSalas] = useState<ISalaCleanDTO[]>();
	const [modalSucesso, setModalSucesso] = useState<boolean>(false);
	const negocioUnidadeService = useNegocioUnidadeService();
	const negocioService = useNegocioService();
	const notification = useNotificationService();
	const curriculoService = useCurriculoService();
	const salaService = useSalaService();
	const unidadePessoaService = useUnidadePessoaService();
	const turmaService = useTurmaService();
	const [negocioSelected, setNegocioSelected] = useState<INegocioDTO>();

	const findNegocios = () => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		negocioUnidadeService
			.findList(predicate)
			.then((response) => setListNegocios(response.data))
			.finally(() => setIsGlobalLoading(false));
	};

	const findNegocio = () => {
		setIsGlobalLoading(true);
		if (turma?.curriculoNegocioId) {
			negocioService
				.findById({ id: turma?.curriculoNegocioId })
				.then((response) => {
					setNegocioSelected(response.data);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const findCurriculos = () => {
		if (turma?.curriculoNegocioId) {
			setIsGlobalLoading(true);
			const predicate = new Predicate();
			predicate.addOption('ativo', true);
			predicate.addOption('negocio.id', turma?.curriculoNegocioId);
			curriculoService
				.findCleanList(predicate)
				.then((response) => setListCurriculos(response.data))
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const findProfessor = () => {
		const professorPredicate = new Predicate();
		professorPredicate.addOption('tipoUnidadePessoa.id', 3);
		professorPredicate.addOption('unidade.id', unidade.id);
		professorPredicate.addOption('pessoa.ativo', true);
		return unidadePessoaService
			.findCleanList(professorPredicate)
			.then((response) => {
				setListProfessor(response.data);
			});
	};

	const findSalas = () => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		salaService
			.findCleanList(predicate)
			.then((response) => setListSalas(response.data))
			.finally(() => setIsGlobalLoading(false));
	};

	const getPeriodo = (): any => {
		if (turma.dataInicio && turma.dataFim) {
			return [
				moment(turma.dataInicio, 'YYYY-MM-DD'),
				moment(turma.dataFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const updateTurma = (payload: ITurmaFullDTO) => {
		setIsGlobalLoading(true);
		payload.unidade.id = unidade.id;
		turmaService
			.patchFull(payload)
			.then(() => {
				setModalSucesso(true);
				removeTurma();
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const saveTurma = (payload: ITurmaFullDTO) => {
		setIsGlobalLoading(true);
		payload.unidade.id = unidade.id;
		turmaService
			.createFull(payload)
			.then(() => {
				setModalSucesso(true);
				removeTurma();
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const handleSubmitSave = () => {
		if (negocioSelected?.codigo === 'HEN' && turma.horarios.length < 2) {
			return notification({
				description: `Não é possível prosseguir pois o negócio selecionado precisa ter 2 horários cadastrados.`,
				type: 'warning',
				message: 'Aviso!',
			});
		}

		setTurma((prev) => ({
			...prev,
			curso: null,
		}));

		turma.horarios.map((horario) => {
			horario.horaInicio += ':00';
			horario.horaFim += ':00';
			return horario;
		});

		turma.horarios.forEach((horario) => {
			if (horario.horaInicio.length > 8) {
				horario.horaInicio = horario.horaInicio.slice(0, 8);
			}
			if (horario.horaFim.length > 8) {
				horario.horaFim = horario.horaFim.slice(0, 8);
			}
		});
		if (turma.id) {
			updateTurma(turma);
		} else {
			saveTurma(turma);
		}
		editBtnIsVisible(false);
	};

	useEffect(() => {
		if (unidade?.id) {
			findCurriculos();
			findNegocios();
			findSalas();
			findProfessor();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id]);

	useEffect(() => {
		findCurriculos();
		findNegocio();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [turma?.curriculoNegocioId]);

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={70}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<div className="menu-user">
				<h3>Criação de turma</h3>
				<Steps direction="vertical" current={1}>
					<Step title="Dados Básicos" />
					<Step title="Revisão" />
				</Steps>
			</div>
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<div className="container-rev">
						<h3>Revisão de dados</h3>
						{editBtn ? (
							<Link to={unidade?.id ? '/turmas/create/step-1' : '/turmas'}>
								<Button
									className="button-line"
									style={{ position: 'absolute', top: '15px', right: '15px' }}
								>
									Editar{' '}
								</Button>
							</Link>
						) : null}
						<div className="input-box-rev">
							<h5>Nome da Turma </h5>
							<Input
								disabled={true}
								placeholder="Nome turma"
								value={turma?.codigo}
								style={{ backgroundColor: 'transparent' }}
							/>
						</div>
						<div className="input-box-rev">
							<h5>Máximo de alunos na turma</h5>
							<InputNumber
								style={{ backgroundColor: 'transparent' }}
								disabled={true}
								className="w-100"
								min={0}
								defaultValue={turma.maxAlunos}
								value={turma?.maxAlunos}
								onChange={(e) => setTurma({ ...turma, maxAlunos: e })}
								placeholder="Máximo de alunos na turma"
							/>
						</div>
						<div className="input-box-rev" id="box-aluno">
							<h5>Produto</h5>
							<Select
								className="w-100"
								placeholder="Selecione o produto"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={turma?.curriculoNegocioId}
								disabled={true}
								value={turma?.curriculoNegocioId}
								filterOption={(input, option) =>
									option?.text?.toLowerCase().includes(input.toLowerCase())
								}
							>
								{listNegocios?.map((negocioUnidade: INegocioUnidadeDTO) => (
									<Option
										key={negocioUnidade.negocio.id}
										value={negocioUnidade.negocio.id}
										text={negocioUnidade.negocio.descricao}
									>
										{negocioUnidade.negocio.descricao}
									</Option>
								))}
							</Select>
						</div>
						<div className="input-box-rev" id="box-aluno">
							<h5>Curriculo</h5>
							<Select
								disabled={true}
								className="w-100"
								placeholder="Selecione um curriculo"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={turma.curriculo?.id}
								value={turma.curriculo?.id}
								filterOption={(input, option) =>
									option?.text?.toLowerCase().includes(input.toLowerCase())
								}
							>
								{listCurriculos &&
									listCurriculos?.map((curriculo: ICurriculoCleanDTO) => (
										<Option
											key={curriculo?.id}
											value={curriculo?.id}
											text={curriculo?.descricaoCurriculo}
										>
											{curriculo?.descricaoCurriculo}
										</Option>
									))}
							</Select>
						</div>
						<div className="input-box-rev" id="box-aluno">
							<h5>Período</h5>
							<RangePicker
								disabled={true}
								className="w-100"
								value={getPeriodo()}
								onChange={(values) => {
									if (values) {
										setTurma({
											...turma,
											dataInicio: values[0].toDate(),
											dataFim: values[1].toDate(),
										});
									}
								}}
								format={'DD-MM-YYYY'}
							/>
						</div>
						<div className="inputs-box">
							<div className="input-box-rev" id="box-aluno">
								<h5>Sala</h5>
								<Select
									style={{ minWidth: 296 }}
									disabled={true}
									className="w-100"
									placeholder="Selecione uma sala"
									allowClear
									showSearch
									optionFilterProp="children"
									defaultValue={turma.sala.id}
									value={turma.sala.id}
									filterOption={(input, option) =>
										option?.text?.toLowerCase().includes(input.toLowerCase())
									}
								>
									{listSalas &&
										listSalas?.map((sala: ISalaCleanDTO) => (
											<Option
												key={sala.id}
												value={sala.id}
												text={sala.descricaoSala}
											>
												{sala.descricaoSala}
											</Option>
										))}
								</Select>
							</div>
							<div className="input-box-rev" id="box-aluno">
								<h5>Instrutor</h5>
								<Select
									style={{ minWidth: 296 }}
									disabled={true}
									className="w-100"
									placeholder="Selecione uma sala"
									allowClear
									showSearch
									optionFilterProp="children"
									defaultValue={turma.unidadePessoa.id}
									value={turma.unidadePessoa.id}
									filterOption={(input, option) =>
										option?.text?.toLowerCase().includes(input.toLowerCase())
									}
								>
									{listProfessor &&
										listProfessor?.map((prof: IUnidadePessoaCleanDTO) => (
											<Option
												key={prof.id}
												value={prof.id}
												text={prof.pessoaNome}
											>
												{prof.pessoaNome}
											</Option>
										))}
								</Select>
							</div>
						</div>
						<div className="input-box-rev">
							<h5>Horário</h5>
							{turma.horarios && turma.horarios.map((hora, index) => (
								<p key={index}>
									{hora.codigo +
										' - ' +
										hora.horaInicio +
										' às ' +
										hora.horaFim}
								</p>
							))}
						</div>
						<div className="botoes">
							<Link to="/turmas/create/step-1">
								<Button className="button-second" style={{ width: 345 }}>
									Voltar
								</Button>
							</Link>
							<div onClick={() => handleSubmitSave()}>
								<Button className="button-primary" style={{ width: 345 }}>
									Salvar
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição desta Nova turma?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/turmas"
			/>
			<ModalCreate
				open={modalSucesso}
				title="Turma criada com sucesso"
				textButton1="Voltar"
				textButton2="Voltar Inicio"
				styleButton1="modal-none"
				styleButton2="button-primary"
				link2="/turmas"
			/>
		</div>
	);
};
