import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAlunoContext } from '../../../../context/AlunoContext';
import { useAlunoService } from '../../../../services/aluno.service';
import { useHistory } from 'react-router-dom';
import useRegisterLog from '../../../../globalHooks/use-register-log.hook';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Predicate } from '../../../../models/predicate.model';
import { IAlunoCustomIndexDTO } from '../../../../models/happy-code-api.model';
import { PageableResponse } from '../../../../models/response.model';
import { Operators } from '../../../../enum/operators.enum';

enum StudentAndParamFilter {
	Student = 'aluno',
	Parent = 'responsavel',
}

type StudentAndParamFilterType = 'aluno' | 'responsavel';

export default function useAlunos() {
	const { setAluno, removeAluno, removeMatricula } = useAlunoContext();
	const [studentParentFilter, setStudentParentFilter] = useState<
		StudentAndParamFilterType | string
	>('aluno');

	const inputSearchLabel =
		studentParentFilter === 'aluno' ? 'aluno' : 'responsável';

	const [search, setSearch] = useState<string>();
	const [statusId, setStatusId] = useState<number | string>('TDS');

	const alunoService = useAlunoService();
	const history = useHistory();

	useRegisterLog({
		descricaoEvento: 'Acesso tela Alunos franquia',
		descricaoTipo: 'Acesso a tela',
	});

	const { unidade, setIsGlobalLoading } = useGlobalContext();

	const predicate = useMemo(() => new Predicate(), []);

	const addFranchiseUnityParams = useCallback(() => {
		if (!unidade.id) return predicate.removeOption('unidade.id');
		return predicate.addOption('unidade.id', unidade.id);
	}, [unidade.id]);

	const addStatusParams = useCallback(() => {
		predicate.sort = 'asc';

		if (!statusId || statusId === 'TDS') {
			predicate.addSort('ativo');
			return predicate.removeOption('ativo');
		}

		predicate.addSort('id');
		return predicate.addOption('ativo', statusId);
	}, [predicate, statusId]);

	const [studentData, setStudentData] =
		useState<PageableResponse<IAlunoCustomIndexDTO>>();

	const getStudentData = useCallback(async () => {
		setIsGlobalLoading(true);

		await alunoService.findCustom(predicate)
			.then(({ data }) => setStudentData(data))
			.catch((error) => console.error(`Error to get students: ${error}`))
			.finally(() => setIsGlobalLoading(false));
	}, [setIsGlobalLoading, statusId]);

	useEffect(() => {
		const controller = new AbortController();

		predicate.removeAllOption();

		removeAluno();
		removeMatricula();

		addFranchiseUnityParams();
		addStatusParams();

		getStudentData();

		return () => controller.abort();
	}, [getStudentData, addFranchiseUnityParams, addStatusParams]);

	const getStudentAndParentFilter = {
		[StudentAndParamFilter.Student]: 'pessoa.nome',
		[StudentAndParamFilter.Parent]: 'pessoa.pessoaResponsaveis.responsavelNome',
	};

	useEffect(() => {
		predicate.removeAllOption();

		if (!search) return;
		const debouncedSearch = setTimeout(() => {
			const filterOption = getStudentAndParentFilter[studentParentFilter];

			predicate.addOption(filterOption, search || '', Operators.CONTAINS);

			addFranchiseUnityParams();
			addStatusParams();

			getStudentData();
		}, 1200);
		return () => clearTimeout(debouncedSearch);
	}, [
		search,
		statusId,
		unidade.id,
		getStudentData,
		addStatusParams,
		addFranchiseUnityParams,
	]);

	const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);

		addFranchiseUnityParams();
		addStatusParams();

		getStudentData();
	};

	const goTo = (id: number) => {
		setIsGlobalLoading(true);
		alunoService
			.findCustomById(id)
			.then(({ data }) => setAluno(data))
			.finally(() => {
				setIsGlobalLoading(false);
				history.push(`/alunos/detalhe`);
			});
	};

	const studentsTitle = `Alunos (${
		studentData?.totalElements ?? 'carregando...'
	})`;

	const createStudentClick = () => {
		history.push('/alunos/create/step-1');
		removeAluno();
	};

	return {
		studentsTitle,
		createStudentClick,
		goTo,
		onPageChange,
		inputSearchLabel,
		setStudentParentFilter,
		setSearch,
		setStatusId,
		studentData,
	};
}
