import * as Unicons from '@iconscout/react-unicons';
import { Modal as AntdModal, Dropdown, Menu } from 'antd';
import { useState } from 'react';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IFinanceiroEscolaComFocalDTO } from '../../../../models/happy-code-api.model';
import { useNotificationService } from '../../../../services/notification.service';
import './style.css';

import { format } from 'date-fns';
import moment from 'moment';
import DivStatusCobranca from '../../../../components/DivStatusCobranca';
import Modal from '../../../../components/Modal';
import { ModalBaixa } from '../../../../components/ModalFinanceiro/modal-baixa';
import { FuncaoEnum } from '../../../../enum/funcao.enum';
import { TipoStatusCobranca } from '../../../../enum/tipoStatusCobranca';
import { IDataNotas } from '../../../../models/relatorio.model';
import { useEnotasService } from '../../../../services/enotas.service';
import { useFinanceiroEscolaService } from '../../../../services/financeiro-escola.service';
import { useGrupoService } from '../../../../services/grupo.service';
import { currencyFormatter, daysBetween, isAfterDate } from '../../../../util/format';

interface Props {
    financeiro: IFinanceiroEscolaComFocalDTO;
    buscarDados: any,
}

const ItemTabelaGrupoFinanceiroComponent: React.FunctionComponent<Props> = ({ financeiro, buscarDados }: Props) => {

    const notification = useNotificationService();
    const { unidade, usuario } = useGlobalContext();
    const [open, setOpen] = useState(false);
    const { setIsGlobalLoading } = useGlobalContext();
    const grupoService = useGrupoService();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [cobrancaSelected, setCobrancaSelected] = useState<IFinanceiroEscolaComFocalDTO>();
    const [flExibirModalDesativarGrupo, setFlExibirModalDesativarGrupo] = useState(false);
    const enotasService = useEnotasService();
    const financeiroEscolaService = useFinanceiroEscolaService();
    const [modalCobranca, setModalCobranca] = useState<boolean>(false);
    

    const enviarCobrancaUnitaria = (idFinanceiro) => {
        setIsGlobalLoading(true);
        financeiroEscolaService.findEnvioEmailCobrancaB2b(true, idFinanceiro)
            .then(() => {
                notification({
                    description: `Envio da cobrança com sucesso.`,
                    type: 'success',
                    message: 'Sucesso',
                });
            }).catch((err) => {
                notification({
                    description: `Ocorreu um erro: ${err}`,
                    type: "error",
                    message: "Erro!"
                })
            }).finally(() => {
                setModalCobranca(false);
                setTimeout(() => {
                    setIsGlobalLoading(false);   
                },1000)
            });
    }
    const onDesativarGrupo = () => {
        setFlExibirModalDesativarGrupo(false);
        setIsGlobalLoading(true);
        grupoService.patch({
            id: financeiro.id,
            ativo: false,
        }).then((response) => {
            buscarDados();

            if (response.status >= 200 && response.status <= 299) {
                notification({ description: 'Grupo desativado com sucesso!', type: 'success', message: 'Sucesso' });
            }
        })
            .finally(() => setIsGlobalLoading(false));
    }

    const finalizaBaixa = () => {
        setIsOpen(false);
        buscarDados();
    }

    const itensDropdown = (cobranca: IFinanceiroEscolaComFocalDTO) => {

        const menuItem2aVia = (semMulta: boolean) => {
            let parametro = "";
            let descricao = "";

            if (semMulta) {

                parametro = cobranca.id.toString().padStart(9, "0")
                    + (new Date()).toISOString().substring(0, 10);


                parametro = "?" + Buffer.from(parametro).toString("base64");
                descricao = "- sem multa de atraso";
            }

            return (
                cobranca?.status.codigo !== "PGO"
                && cobranca?.status.codigo !== "IST"
                && cobranca?.status.codigo !== "CAN"
                &&
                (<Menu.Item><li>

                    <a target="_blank"
                        href={`/checkout-escola/${cobranca.pedido}${parametro}`}
                        onClick={() => descricao === "- sem multa de atraso" ? notification({ description: "Este checkout terá duração até a meia noite de hoje!", type: 'warning', message: 'Aviso!', duration: 0 }) : null}
                        rel="noopener noreferrer">
                        Emitir 2ª. via {descricao}
                    </a>
                </li></Menu.Item>)
            );

        }

        const baixarNota = (notaFiscal: IDataNotas) => {
            if (notaFiscal?.id == null) {
                AntdModal.error({
                    title: 'Não foi possível baixar a nota fiscal',
                    content: `${notaFiscal[0].codigo + " - " + notaFiscal[0].mensagem}`,
                });
            } else {
                if (notaFiscal.status === 'Autorizada') {
                    if (notaFiscal.tipo === 'NF-e') {
                        window.open(notaFiscal?.linkDanfe);
                    } else {
                        window.open(notaFiscal?.linkDownloadPDF);
                    }
                } else {
                    AntdModal.error({
                        title: 'Não foi possível baixar a nota fiscal',
                        content: `${notaFiscal?.status + " - " + notaFiscal?.motivoStatus}`,
                    });
                }
            }

        }

        const buscarNotaServico = () => {
            setIsGlobalLoading(true);
            enotasService.buscarNotaServico(cobranca?.id)
                .then((response) => {
                    baixarNota(response.data);
                })
                .catch(() => {
                    notification({ description: "A nota não foi gerada", type: 'warning', message: 'Aviso!' })
                })
                .finally(() => setIsGlobalLoading(false));
        }

        const buscarNotaProduto = () => {
            setIsGlobalLoading(true);
            enotasService.buscarNotaProduto(cobranca?.id)
                .then((response) => {
                    baixarNota(response.data)
                })
                .catch(() => {
                    notification({ description: "A nota não foi gerada", type: 'warning', message: 'Aviso!' })
                })
                .finally(() => setIsGlobalLoading(false));
        }

        const abrirBaixaCobranca = (cobranca) => {
            setCobrancaSelected(cobranca);
            setIsOpen(true);
        }

        return (
            <Menu>
                {menuItem2aVia(false)}
                {isAfterDate(cobranca.vencimento) && menuItem2aVia(true)}
                {cobranca?.nfeServico !== undefined ?
                    <Menu.Item>
                        <li>
                            <a onClick={buscarNotaServico}>
                                Nota de serviço
                            </a>
                        </li>
                    </Menu.Item>
                    : null}

                {cobranca?.nfeProduto !== undefined ?
                    <Menu.Item>
                        <li>
                            <a onClick={buscarNotaProduto}>
                                Nota de produto
                            </a>
                        </li>
                    </Menu.Item>
                    : null}

                {unidade.id == null && (cobranca?.status.codigo === "AGR" || cobranca?.status.codigo === "GRD" || cobranca?.status.codigo === "FLH")
                    ?
                    <Menu.Item>
                        <a onClick={() => (abrirBaixaCobranca(cobranca))} rel="noopener noreferrer">
                            Baixar cobrança
                        </a>
                    </Menu.Item>
                    : null}
                {[FuncaoEnum.TECNOLOGIA_CODIGO, FuncaoEnum.GESTAO_FINANCEIRO_CODIGO].toString().includes(usuario?.funcao) &&
                    [TipoStatusCobranca.CODIGO_A_GERAR, TipoStatusCobranca.CODIGO_GERADO, TipoStatusCobranca.CODIGO_FALHA].toString().includes(cobranca?.status?.codigo) ?
                    <Menu.Item>
                        <li>
                        <a onClick={() => setModalCobranca(true)}>
                                Envio de cobrança
                            </a>
                        </li>
                    </Menu.Item>
                    : null}
            </Menu>
        )
    }

    if (!open) {
        return (
            <div className="registro-user" style={{ paddingTop: 20, paddingBottom: 20 }} id="registro-financeiro">
                <Modal
                    open={flExibirModalDesativarGrupo}
                    onClose={() => setFlExibirModalDesativarGrupo(false)}
                    title='Você deseja desativar o grupo?'
                    subtitle='Essa ação não pode ser desfeita.'
                    textButton1='Não'
                    textButton2='Sim, desativar'
                    styleButton1='button-line'
                    styleButton2='button-danger'
                    button2={onDesativarGrupo}
                />
                <div className="registros-row" >
                    <div className="registro" style={{ width: '14%', display: 'flex', flexDirection: 'column' }}>
                        <span>{financeiro?.contratoGrupoNome}</span>
                    </div>
                    <div className="registro" style={{ width: '15%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        {financeiro?.contratoGrupoUnidadeDescricao || 'Sede'}
                    </div>
                    <div className="registro" style={{ width: '10%', display: 'flex', flexDirection: 'column' }}>
                        <span>{financeiro.contratoGrupoEnderecoCidade + '/' + financeiro.contratoGrupoEnderecoUf}</span>
                    </div>
                    <div className="registro" style={{ width: '10%', display: 'flex', flexDirection: 'column' }}>
                        <span>{financeiro.contratoGrupoFocalFinanceiroNome}</span>
                        {/* <span>Focal Financeiro</span> */}
                    </div>
                    <div className="registro" style={{ width: '10%', display: 'flex', flexDirection: 'column' }}>
                        <span>{financeiro?.vencimento ? format(moment(financeiro?.vencimento).toDate(), 'dd/MM/yyyy') : ''}</span>
                    </div>
                    <div className="registro" style={{ width: '10%', display: 'flex', flexDirection: 'column' }}>
                        <span>{currencyFormatter(financeiro?.valorTotal || 0)}</span>
                    </div>
                    <div className="registro" style={{ width: '8%', display: 'flex', flexDirection: 'column' }}>
                        <span>{currencyFormatter(financeiro?.valorSplit || 0)}</span>
                    </div>
                    <div className="registro" style={{ width: '8%', display: 'flex', flexDirection: 'column' }}>
                        <span>{financeiro?.dataPagamento != null ? format(moment(financeiro?.dataPagamento).toDate(), 'dd/MM/yyyy') : null}</span>
                    </div>
                    <DivStatusCobranca
                        codigo={financeiro?.status?.codigo}
                        descricao={financeiro?.status?.descricao}
                        tooltip={financeiro?.mensagemFalha} />
                    <div className="registro" style={{ width: '3%', display: 'flex', flexDirection: 'column' }}>
                        {(financeiro?.dataPagamento < financeiro?.vencimento) ||
                            financeiro?.status.codigo == 'PGO' ||
                            financeiro?.status.codigo == 'CAN' ||
                            financeiro?.status.codigo == 'IST' ?
                            <p>{"0"}</p> : <p>{daysBetween(financeiro.vencimento)}</p>
                        }
                    </div>
                    <div className="registro" style={{ width: '10%', display: 'flex', flexDirection: 'column' }}>
                        <span>{(financeiro?.status.codigo == 'PGO' && financeiro?.tipoPagamento === undefined) ?
                            'Manual' : financeiro?.tipoPagamento?.descricao}</span>
                    </div>

                    <div className="registro" style={{ width: '3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className="registro" style={{ width: "2%" }}>
                            <Dropdown overlay={itensDropdown(financeiro)} placement="bottomRight" arrow>
                                <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <ModalBaixa
                    open={isOpen}
                    onClose={finalizaBaixa}
                    id={cobrancaSelected?.id}
                    escola={true}
                />
                 <Modal
                    open={modalCobranca}
                    onClose={() => setModalCobranca(false)}
                    title='Envio de cobrança'
                    subtitle='Deseja realmente enviar esta cobrança?'
                    textButton1='Não'
                    textButton2='Sim, enviar'
                    styleButton1='button-line'
                    styleButton2='button-primary'
                    button2={() => enviarCobrancaUnitaria(financeiro?.id)}
                />
            </div>
        )
    }

    return (
        <div className="registros-body-detalhes">
            <div className="row-1">
                <div className="registros-row">

                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span className="gestor">{financeiro?.vencimento}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span className="gestor">{currencyFormatter(financeiro?.valorTotal || 0)}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span className="gestor">{currencyFormatter(financeiro?.valorSplit || 0)}</span>
                    </div>
                </div>
                <div className="registro-opcao">
                    <div className="circle" onClick={() => setOpen(!open)}>
                        <Unicons.UilAngleDown size="20" color="#6A7C96" />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ItemTabelaGrupoFinanceiroComponent;