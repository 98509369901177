import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';

import { IMetaTipoDTO, IMetaTipoValorEnum } from '../../models/happy-code-api.model';
import { useMetaTipoService } from '../../services/meta-tipo.service';
import { useNotificationService } from '../../services/notification.service';

const MetaTipoCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const metaTipoService = useMetaTipoService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [metaTipo, setMetaTipo] = useState<IMetaTipoDTO>();
    const { idMetaTipo } = useParams<any>()
    const { TextArea } = Input;

    const findMetaTipo = () => {
        setIsGlobalLoading(true);
        metaTipoService.findById({ id: idMetaTipo })
            .then((response) => {
                setMetaTipo(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const createMetaTipo = () => {
        setIsGlobalLoading(true);
        metaTipoService.create(metaTipo)
            .then(() => {
                history.push("/metas-tipo");
                notification({
                    description: `Meta criada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível cadastrar a meta.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const patchMetaTipo = () => {
        setIsGlobalLoading(true);
        metaTipoService.patch(metaTipo)
            .then(() => {
                history.push("/metas-tipo");
                notification({
                    description: `Meta editada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível editar a meta.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const sendRequest = () => {
        if (!idMetaTipo) {
            createMetaTipo();
        } else {
            patchMetaTipo();
        }
    }

    useEffect(() => {
        if (idMetaTipo) {
            findMetaTipo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idMetaTipo])

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ position: "initial", width: 0 }}>
                <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
                <div className="space1" />
                <div className="user-row">
                    <h3 id="taxas-h3">Insira o nome e detalhes do Tipo de Meta</h3>
                    <Input
                        hidden
                        defaultValue={idMetaTipo}
                        value={idMetaTipo} />
                    <div className="input-box">
                        <p>Nome do Tipo de Meta</p>
                        <Input
                            defaultValue={metaTipo?.nome}
                            value={metaTipo?.nome}
                            onChange={(e) => setMetaTipo({ ...metaTipo, nome: e.target.value })} />
                    </div>
                    <div className="input-box">
                        <p>Descreva o funcionamento do Tipo de meta</p>
                        <TextArea rows={4}
                            defaultValue={metaTipo?.descricao}
                            value={metaTipo?.descricao}
                            onChange={(e) => setMetaTipo({ ...metaTipo, descricao: e.target.value })}></TextArea>
                    </div>
                    <div className="input-box">
                        <p>Tipo Valor ou Percentual</p>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Selecione a quantidade"
                            allowClear
                            defaultValue={metaTipo?.metaTipoValor}
                            value={metaTipo?.metaTipoValor}
                            onChange={(e: IMetaTipoValorEnum) => setMetaTipo({ ...metaTipo, metaTipoValor: e })}
                        >
                            <Select.Option value="ABSOLUTO">Absoluto</Select.Option>
                            <Select.Option value="PERCENTUAL">Percentual</Select.Option>
                        </Select>
                    </div>
                    <div className="botoes">
                        <Link to="/metas">
                            <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                        </Link>
                        <Button
                            style={{ width: 345 }}
                            type="primary"
                            id="proximo"
                            className="button-primary"
                            onClick={sendRequest}>Salvar</Button>
                    </div>
                </div>
            </div>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a operação?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/metas-tipo'
            />
        </div>
    )
}

export default MetaTipoCreate;
