import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useColaboradorContext } from '../../context/ColaboradorContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { ILogCleanDTO, IPessoaCustomCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { usePessoaService } from '../../services/pessoa.service';
import Filtros from './filtros';
import ItemList from './item-lista';
import './style.css';
import { useLogService } from '../../services/log.service';

export const Colaboradores: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IPessoaCustomCleanDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const { removeColaborador, setRevisao } = useColaboradorContext();

	const pessoaService = usePessoaService();

	const history = useHistory();
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: "Acesso tela colaboradores franquia",
		descricaoTipo: "Acesso a tela",
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true
	});

	useEffect(() => {
		logService.registraLog(log)
	}, [])

	const findColaborador = () => {
		setIsGlobalLoading(true);
		predicate.addSortDescricao('unidadePessoas.pessoaNome');
		if (unidade.id) {
			pessoaService
				.findCustomClean(predicate, unidade.id)
				.then(({ data }) => setData(data))
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findColaborador();
	};

	const goToAdd = () => {
		history.push('/colaboradores/create/step-1');
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findColaborador();
	}

	useEffect(() => {
		setIsGlobalLoading(true);
		removeColaborador();
		setRevisao(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (unidade.id) findColaborador();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id]);

	return (
		<div className="container">
			<NewMenu selecionado={9} />
			<div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
				<div className="box-title">
					<h3 className="title">Colaboradores ({data?.totalElements})</h3>
					<Button
						className="button-primary"
						icon={<PlusOutlined rev={undefined} />}
						onClick={goToAdd}
					>
						Novo Colaborador
					</Button>
				</div>
				<Filtros predicate={predicate} findColaborador={findColaborador} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '30%' }}>
							<span>NOME</span>
							<div className="arrows" onClick={() => ordenar('unidadePessoas.pessoaNome')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>FUNÇÃO</span>
							<div className="arrows" onClick={() => ordenar('unidadePessoas.tipoUnidadePessoaDescricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '45%' }}>
							<span>DATA ADMISSÃO</span>
							<div className="arrows" onClick={() => ordenar('unidadePessoas.dataAdmissao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }}></div>
					</div>
					{data &&
						data.content.map((colaborador, index) => (
							<ItemList colaborador={colaborador} key={index} findColaborador={findColaborador} />
						))}
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
