import * as Unicons from '@iconscout/react-unicons';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import '../style.css';
import StepsCreate from './steps-create';
import { MaskedInput } from 'antd-mask-input';

const FranquiaCreate: React.FunctionComponent = () => {
  const { unidade, setUnidade, revisao } = useUnidadeContext();

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    const franquiaDataFromContext = unidade;
    if (franquiaDataFromContext) {
      form.setFieldsValue(franquiaDataFromContext);
    }
  }, [form, unidade]);

  const handleUnidade = (franquiaCrud) => {
    const contextState = { ...unidade, ...franquiaCrud };
    setUnidade(contextState);
    if (franquiaCrud.revisao === "1") {
      history.push("/franquias/create/step-10");
    } else {
      history.push("/franquias/create/step-2");
    }
  };

  return (
    <div className="container">
      <Header />
      <Progress />
      <StepsCreate current={0} />
      <div className="franquia" style={{ top: 70 }}>
        <Form
          form={form}
          name="franquia-row"
          onFinish={handleUnidade}
          className="franquia-row"
        >
          <h5>Insira o nome e dados básicos da franquia</h5>

          <div className="input-franquia">
            <span>Nome da franquia</span>
            <Form.Item name="descricao" rules={[{ required: true, message: 'Insira o nome da franquia' }]}>
              <Input
                id="nome"
                className="input"
              />
            </Form.Item>
          </div>
          <div className="input-franquia">
            <span>Inscrição estadual</span>
            <Form.Item name="inscricaoEstadual" rules={[{ required: true, message: 'Insira a inscrição estadual'}]}>
              <Input
                id="inscricao-estadual"
                className="input"
                maxLength={11}
              />
            </Form.Item>
          </div>
          <div className="input-franquia">
            <span>CNPJ</span>
            <Form.Item name="cnpj" rules={[{ required: true, message: 'Insira o CNPJ'}, {validator: (_, value:string, callback) => 
              value.length < 18 && value.length >= 1   ? callback('Por favor, insira o CNPJ completo') : callback()
            }]}>
              {/* <Input
                id="cnpj"
                type=""
                className="input"
              /> */}

              <CpfCnpj
                id="cnpj"
                className="input"
                type="tel"
              />
            </Form.Item>
          </div>
          <div className="input-franquia">
            <span>Razão Social</span>
            <Form.Item name="razaoSocial" rules={[{ required: true, message: 'Insira a Razão Social' }]}>
              {<Input
                id="razaoSocial"
                className="input"
              />}
            </Form.Item>
            </div>
          <div className="input-franquia">
            <span>Telefone do Comandante</span>
            <Form.Item name="telefoneComandante" rules={[{ required: true, message: 'Insira a Número de telefone' }]}>
						<MaskedInput
              id = "telefoneComandante"
							mask="(11) 11111-1111"
							placeholder="(00)0000-0000"
							className="input"
						/>
            </Form.Item>
            </div>
          <div className="input-franquia">
            <span>Telefone do Guerreiro</span>
            <Form.Item name="telefoneGuerreiro" rules={[{ required: true, message: 'Insira a Número de telefone' }]}>
            <MaskedInput
              id = "telefoneGuerreiro"
							mask="(11) 11111-1111"
							placeholder="(00)0000-0000"
							className="input"
						/>
            </Form.Item>
          </div>
          <div className="botoes">
            {revisao ?
              <>
                <Form.Item name="revisao" initialValue="1" />
                <Button type="primary" htmlType="submit" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </>
              : <>
                <Link to="/franquias">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <button type="submit" id="proximo" className="button-primary" style={{ width: 345 }}><span>Próximo</span></button>
              </>
            }
          </div>

        </Form>
      </div >
      <Link to="/franquias">
        <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
      </Link>
    </div >
  )
}

export default FranquiaCreate;