import { Col, Collapse, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu/index copy';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	IAcaCursoCleanDTO,
	ITrilhaCleanDTO,
	IUsuarioDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useAcademyCurso } from '../../../services/academy-curso';
import { useAcademyTrilha } from '../../../services/academy-trilha';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useUsuarioService } from '../../../services/usuario.service';
import './style.css';

export interface Root {
	CXX_ID_USUARIO?: number;
	CXX_ID_UNIDADE?: number;
	CXX_ID_CURSO?: number;
	C01_NM_USUARIO?: string;
}

export interface Root2 {
	key?: React.Key;
	C01_TRILHA?: string;
	C02_PERCENTUAL_TRILHA?: number;
	CXX_PERCENTUAL_TRILHA?: string;
	C03_CURSO?: string;
	C04_PERCENTUAL_CURSO?: number;
	CXX_PERCENTUAL_CURSO?: number;
	CXX_ID_TRILHA?: number;
	CXX_ID_CURSO?: number;
	CXX_ID_FUNCAO?: number;
	CXX_ID_USUARIO?: number;
}

const { Option } = Select;
const { Panel } = Collapse;

function RelProgressoTrilhaInstrutorAcademy() {
	const { unidade } = useGlobalContext();
	const service = useRelatorioService();
	const trilhaService = useAcademyTrilha();
	const cursoService = useAcademyCurso();
	const usuarioService = useUsuarioService();

	const [dadosInstrutor, setDataInstrutor] = useState<Root[]>();
	const [columnsInstrutor, setColumnsInstrutor] = useState<ReportColumns[]>();

	const [dadosTrilhaCurso, setDataTrilhaCurso] = useState<Root2[]>();
	const [columnsTrilhaCurso, setColumnsTrilhaCurso] =
		useState<ReportColumns[]>();
	const [trilhas, setTrilhas] = useState<ITrilhaCleanDTO[]>();
	const [trilhaId, setTrilhaId] = useState<number>(0);

	const [cursos, setCursos] = useState<IAcaCursoCleanDTO[]>();
	const [cursoId, setCursoId] = useState<number>(0);

	const [usuarios, setUsuarios] = useState<IUsuarioDTO[]>();
	const [usuarioId, setUsuarioId] = useState<number>(0);

	const findUsuario = () => {
		const predicate = new Predicate();
		predicate.addOption('pessoa.ativo', true);
		unidade?.id &&
			predicate.addOption('pessoa.unidadePessoas.unidade.id', unidade?.id);
		return usuarioService.findList(predicate).then(({ data }) => {
			setUsuarios(data);
		});
	};

	const findTrilha = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		return trilhaService.findCleanList(predicate).then(({ data }) => {
			setTrilhas(data);
		});
	};

	const findCurso = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		trilhaId && predicate.addOption('trilha.id', trilhaId);
		return cursoService.findList(predicate).then(({ data }) => {
			setCursos(data);
		});
	};

	useEffect(() => {
		const predicate = new Predicate();

		if (unidade.id) {
			predicate.addOption('unidadeId', unidade.id);
			usuarioId && predicate.addOption('usuarioIstrutorId', usuarioId);
			trilhaId && predicate.addOption('trilhaId', trilhaId);
			cursoId && predicate.addOption('cursoId', cursoId);

			service.instrutorAcademy(predicate).then(({ data }) => {
				setDataInstrutor(data);
			});

			service
				.progressoTrilhaCursoInstrutorAcademy(predicate)
				.then(({ data }) => {
					setDataTrilhaCurso(data);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id, usuarioId, trilhaId, cursoId]);

	useEffect(() => {
		if (unidade?.id) {
			findCurso();
			findTrilha();
			findUsuario();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id, usuarioId, trilhaId, cursoId]);

	useEffect(() => {
		if (dadosInstrutor && dadosInstrutor.length > 0) {
			setColumnsInstrutor(dadosInstrutor[dadosInstrutor.length - 1]['colunas']);
			dadosInstrutor.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dadosInstrutor]);
	useEffect(() => {
		if (dadosTrilhaCurso && dadosTrilhaCurso.length > 0) {
			setColumnsTrilhaCurso(
				dadosTrilhaCurso[dadosTrilhaCurso.length - 1]['colunas']
			);
			dadosTrilhaCurso.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dadosTrilhaCurso]);

	const selectUsuario = usuarios?.map((usuario) => (
		<Option key={usuario.id} value={usuario.id} text={usuario?.pessoa?.nome}>
			{usuario?.pessoa?.nome}
		</Option>
	));
	const selectTrilhas = trilhas?.map((trilha) => (
		<Option key={trilha.id} value={trilha.id}>
			{trilha?.nome1 + ' ' + trilha?.nome2}
		</Option>
	));
	const selectCursos = cursos?.map((curso) => (
		<Option key={curso.id} value={curso.id}>
			{curso?.nome}
		</Option>
	));

	const filtroStyle = { minWidth: 150, border: '1px solid gainsboro' };

	const trilhasProcessadas = new Set<string>();
	const dadosFiltrados =
		dadosTrilhaCurso &&
		dadosTrilhaCurso.reduce((acc, item) => {
			if (!trilhasProcessadas.has(item.C01_TRILHA)) {
				acc.push({
					C01_TRILHA: item.C01_TRILHA,
					CXX_PERCENTUAL_TRILHA: trilhaId
						? item.CXX_PERCENTUAL_TRILHA + ' %'
						: '',
				});
				trilhasProcessadas.add(item.C01_TRILHA);
			}
			return acc;
		}, [] as Root2[]);

	const columns: ColumnsType<Root2> = [
		{
			title: 'TRILHA',
			dataIndex: 'C01_TRILHA',
			key: 'C01_TRILHA',
		},
		{
			title: 'PERCENTUAL TRILHA',
			dataIndex: 'CXX_PERCENTUAL_TRILHA',
			key: 'CXX_PERCENTUAL_TRILHA',
		},
	];

	const dataTableNoTrilhas = [
		{
			'TRILHAS NÃO INICIADAS': 'Nenhuma trilha iniciada',
			'PERCENTUAL TRILHA': '0%',
		},
	];

	const columnsTableNoTrilhas: ColumnsType<any> = [
		{
			title: 'TRILHA',
			dataIndex: 'TRILHAS NÃO INICIADAS',
			key: 'TRILHAS NÃO INICIADAS',
		},
		{
			title: 'PERCENTUAL TRILHA',
			dataIndex: 'PERCENTUAL TRILHA',
			key: 'PERCENTUAL TRILHA',
		},
	];

	const expandedRowRender = () => {
		const columns = [
			{
				title: 'CURSO',
				dataIndex: 'C03_CURSO',
				key: 'C03_CURSO',
			},
			{
				title: 'PERCENTUAL',
				dataIndex: 'C04_PERCENTUAL_CURSO',
				key: 'C04_PERCENTUAL_CURSO',
			},
		];

		return <Table columns={columns} dataSource={dadosTrilhaCurso} />;
	};

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div className="dashboard">
				<h1
					style={{ fontWeight: 'bold', marginBottom: 10, fontSize: '1.3rem' }}
				>
					Acompanhamento de desenvolvimento do colaborador
				</h1>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Row
					style={{ marginBottom: 10, display: 'flex', flexFlow: 'row nowrap' }}
				>
					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Usuário</span>
						<br />
						<Select
							style={filtroStyle}
							value={usuarioId}
							showSearch
							allowClear
							optionFilterProp="children"
							filterOption={(input, option) =>
								option &&
								option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setUsuarioId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectUsuario}
						</Select>
					</Col>

					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Trilha</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={trilhaId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setTrilhaId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectTrilhas}
						</Select>
					</Col>

					<Col flex={2}>
						<span style={{ fontSize: '0.8em' }}>Curso</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={cursoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setCursoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectCursos}
						</Select>
					</Col>
				</Row>

				<Collapse
					expandIconPosition="right"
					activeKey={[1, usuarioId ? 2 : null]}
				>
					<Panel header="Instrutor" key="1">
						<Table
							onRow={(record) => {
								return {
									onClick: () => {
										setUsuarioId(record.CXX_ID_USUARIO);
									},
								};
							}}
							dataSource={dadosInstrutor}
							columns={columnsInstrutor}
							bordered
						/>
					</Panel>
					<Panel header="Trilha" key="2">
						{dadosFiltrados && dadosFiltrados.length > 0 ? (
							<Table
								columns={columns}
								expandable={{ expandedRowRender }}
								dataSource={dadosFiltrados}
								pagination={false}
							/>
						) : (
							<Table
								columns={columnsTableNoTrilhas}
								dataSource={dataTableNoTrilhas}
								pagination={false}
							/>
						)}
					</Panel>
				</Collapse>
			</div>
		</div>
	);
}

export default RelProgressoTrilhaInstrutorAcademy;
