import { CloseOutlined, IdcardOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Progress } from 'antd';
import 'antd/dist/antd.css';
import { UploadFile } from 'antd/lib/upload/interface';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AvatarUpload } from '../../components/AvatarUpload/index';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useUsuarioContext } from '../../context/UsuarioContext';

import { ContatoEnum } from '../../models/pessoa.model';
import { IArquivoCleanDTO } from './../../models/happy-code-api.model';
import StepsCreate from './steps-create';
import './style.css';


const UserCreate: React.FunctionComponent = () => {
	const { usuario, setUsuario, revisao } = useUsuarioContext();

	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);

	const onChangeInputs = (property: string, value: string) => {
		const usuarioCopy = { ...usuario };
		usuarioCopy.pessoa[property] = value;
		setUsuario(usuarioCopy);
	};

	const handleUserCrudSubmitStep1 = () => {
		history.push('/usuarios/create/step-2');
	};

	const setContato = (idTipo: number, value: string) => {
		const usuarioCopy = { ...usuario };
		const contactToUpdateIndex = usuarioCopy.pessoa.contatos.findIndex(
			(c) => c.tipoContatoId === idTipo
		);

		if (idTipo === ContatoEnum.EPF) {
			usuarioCopy.pessoa.email = value;
			usuarioCopy.login = value;
		}

		if (contactToUpdateIndex !== -1) {
			usuarioCopy.pessoa.contatos[contactToUpdateIndex].descricao = value;
		} else {
			usuarioCopy.pessoa.contatos.push({
				id: null,
				descricao: value,
				ativo: true,
				codigo: '',
				tipoContatoCodigo: '',
				tipoContatoId: idTipo,
			});
		}
		setUsuario(usuarioCopy);
	};

	const onAvatarUpload = (arquivo: UploadFile<IArquivoCleanDTO>) => {
		if (arquivo) {
			usuario.arquivo = arquivo.response;
		} else {
			usuario.arquivo = null;
		}
	}

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={16}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={0} />
			<div className="franquia">
				<Form className="user-row" onFinish={handleUserCrudSubmitStep1}>
					<h3>Preencha os dados básicos do usuário</h3>
					<div className="aluno">
						<AvatarUpload arquivo={usuario.arquivo} onUpload={(arquivos) => onAvatarUpload(arquivos)} />
					</div>
					<div className="input-box">
						<p>Nome Completo *</p>
						<Form.Item rules={[{ required: true, len: 200 }]}>
							<Input
								id="nome"
								maxLength={200}
								value={usuario.pessoa.nome}
								onChange={(e) => onChangeInputs('nome', e.target.value)}
								// placeholder="Maria Silva Oliveira Gonçalves"
								className="input"
								prefix={<UserOutlined rev={undefined} />}
							/>
						</Form.Item>
					</div>
					<div className="inputs-box">
						<div className="input-box">
							<p>E-mail profissional</p>
							<Form.Item rules={[{ required: true }]}>
								<Input
									id="email-profissional"
									value={
										usuario.pessoa.contatos.find(
											(c) => c.tipoContatoId === ContatoEnum.EPF
										)?.descricao
									}
									onChange={(e) => setContato(ContatoEnum.EPF, e.target.value)}
									// placeholder="email@gmail.com"
									className="input"
									prefix={<IdcardOutlined rev={undefined} />}
								/>
							</Form.Item>
						</div>
						<div className="input-box">
							<p>E-mail pessoal</p>
							<Form.Item rules={[{ required: true }]}>
								<Input
									id="email-pessoal"
									value={
										usuario.pessoa.contatos.find(
											(c) => c.tipoContatoId === ContatoEnum.EPS
										)?.descricao
									}
									onChange={(e) => setContato(ContatoEnum.EPS, e.target.value)}
									// placeholder="email@gmail.com"
									className="input"
									prefix={<SmileOutlined rev={undefined}/>}
								/>
							</Form.Item>
						</div>
					</div>
					<div className="botoes">
						{revisao ? (
							<Link to="/usuarios/create/step-7">
								<Button
									id="revisao"
									type="primary"
									className="button-primary"
									style={{ width: 700 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						) : (
							<>
								<Link to="/usuarios">
									<Button id="voltar" className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Button
									id="proximo"
									className="button-primary"
									htmlType="submit"
									style={{ width: 345 }}
								>
									Próximo
								</Button>
							</>
						)}
					</div>
				</Form>
			</div>

			<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title='Você deseja cancelar a criação desse usuário?'
				subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
				textButton1='Não'
				textButton2='Sim, Cancelar'
				styleButton1='button-line'
				styleButton2='button-danger'
				link2='/usuarios'
			/>
		</div>
	);
}

export default UserCreate;