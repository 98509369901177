import http from '../helpers/http-common';
import {
	ILeadCleanDTO,
	ILeadCustomDTO,
	ILeadDTO,
	ILeadFullDTO
} from '../models/happy-code-api.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useLeadService = () => {
	const resourceURL = 'lead';

	const alterarStatus = (idLead: number, cdEstagio: string) => {
		return http.put(`${resourceURL}/${idLead}/estagio/${cdEstagio}`)
	}

	const findFullByIdCustom = (idLead: number) => {
		return http.get(`${resourceURL}/${idLead}/custom`)
	}
	const patch = (idLead: number, body: any, idAluno?: number) => {
		return http.patch(`${resourceURL}/${idLead}/${idAluno || ''}`, body)
	}

	const findCustomIndex = (predicate: Predicate = new Predicate()) => {
		return http.get(`${resourceURL}/find-custom-index`, formatHttpParams(predicate))
	}

	return {
		...baseCreate<ILeadCleanDTO, ILeadDTO, ILeadFullDTO>(resourceURL),
		...baseFindById<ILeadCleanDTO, ILeadDTO, ILeadFullDTO>(resourceURL),
		...baseFind<ILeadCleanDTO, ILeadCustomDTO, ILeadFullDTO>(resourceURL),
		...baseDelete(resourceURL),
		alterarStatus,
		findFullByIdCustom,
		patch,
		findCustomIndex
	};
};
