import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { Predicate } from '../../models/predicate.model';
import { TagExcelencia } from './tag';
import { useRelNotaUnidadeService } from '../../services/relnotaunidade';
import { AxiosResponse } from 'axios';
import { PageableResponse } from '../../models/response.model';
import { IRelNotaUnidadeDTO } from '../../models/happy-code-api.model';
import { format, setMonth, setYear } from 'date-fns';
import { useNotificationService } from '../../services/notification.service';
import { ptBR } from 'date-fns/locale';

interface Mes {
  mes: string
}

interface ItemsProps {
  mes: string
  pilar: string,
  indicador: string,
  text: string,
  periocidade: string,
  data: any
}
  
const Cabecalheo = () => {
  return (
    <div className="titles">
      <div className="item" style={{ width: '8%' }}>
        <span className="title">MES REF.</span>
      </div>
      <div className="item" style={{ width: '15%' }}>
        <span>PILAR DE EXCELENCIA</span>
      </div>
      <div className="item" style={{ flex: 1 }}>
        <span>INDICADOR</span>
      </div>
      <div className="item" style={{ width: '10%' }}>
        <span>PERIOCIDADE</span>
        <div className="arrows">
          <Unicons.UilDirection size="20" color="#92A9CB" />
        </div>
      </div>
      <div className="item" style={{ width: '10%' }}>
        <span>RESULTADO</span>
        <div className="arrows">
          <Unicons.UilDirection size="20" color="#92A9CB" />
        </div>
      </div>
      <div className="item" style={{ width: '8%' }}>
        <span>PESO</span>
        <div className="arrows">
          <Unicons.UilDirection size="20" color="#92A9CB" />
        </div>
      </div>
      <div className="item" style={{ width: '8%' }}>
        <span>PONTUAÇÃO</span>
        <div className="arrows">
          <Unicons.UilDirection size="20" color="#92A9CB" />
        </div>
      </div>
    </div>
  )
}

const Mes = ({ mes }) => {
  return (
    <div className="excelencia-mes">
      <h6 className="title-mes">{mes}</h6>
    </div>
  )
}

const Item = ({ rel, key }: { rel: IRelNotaUnidadeDTO, key: number }) => {
  return (
    <div className="body" key={key}>
      <div className="item" style={{ width: '8%' }}>
        <p style={{ fontWeight: 700 }}>{rel.mes}/{rel.ano}</p>
      </div>
      <div className="item" style={{ width: '15%' }}>
        <h5>{rel.dsPilar}</h5>
      </div>
      <div className="item" style={{ flex: 1 }}>
        <h5>{rel.dsIndicador}</h5>
        {/* <p>{rel.v}</p> */}
      </div>
      <div className="item" style={{ width: '10%' }}>
        <p>{'Mensal'}</p>
      </div>
      <div className="item" style={{ width: '10%' }}>
        <p>{(rel.percentual || 0.00).toFixed(2)?.replaceAll('.', ',')}%</p>
      </div>
      <div className="item" style={{ width: '8%' }}>
        <p>{rel.peso}</p>
      </div>
      <div className="item" style={{ width: '8%' }}>
        <p>{(rel.nota || 0.00).toFixed(2)?.replaceAll('.', ',')}</p>
      </div>
    </div>
  )
}

export const TabelaExcelencia = ({ filtroMes, filtroAno }: any) => {

  const { setIsGlobalLoading, unidade } = useGlobalContext();
  const [pageHistorico, setPageHistorico] = useState<any>();
  const [state, setState] = useState<any>();
  const relNotaUnidadeService = useRelNotaUnidadeService();
  const [historicoResponse, setHistoricoResponse] = useState<AxiosResponse<PageableResponse<IRelNotaUnidadeDTO>>>();
  const [historicosAgrupados, setHistoricosAgrupados] = useState<any[]>();
  const notification = useNotificationService();
  const predicate = new Predicate();

  const fetchHistoricos = (novosPredicate?: any[]) => {
    if (!unidade.id) return;

    setIsGlobalLoading(true);
    predicate.addOption('ativo', true);
    // predicate.addOption('idUnidade', unidade.id);
    predicate.sort = 'asc';
    predicate.addSort('dataCriacao');
    predicate.size = 10
    predicate.setPage(pageHistorico);

    novosPredicate?.forEach((pred) => {
      predicate.addMultiOption(pred.key, pred.value);
    });

    setState((state) => ({
      ...state,
      filtro: { ...state?.filtro, flFiltrarHistoricos: false },
    }));

    relNotaUnidadeService.findCustom(predicate, unidade.id)
      .then((response) => {
        if (response.status === 200) {
          setHistoricoResponse(response);
          const data = response.data?.content;
          //setHistoricos(response.data?.content.reverse());

          const groups = data.reduce((groups, game) => {
            const result = new Date(game.ano, game.mes - 1, 1)

            const date = format(result, 'LLLL/yyyy', { locale: ptBR });
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(game);
            return groups;
          }, {});



          const groupArrays = Object.keys(groups).map((date) => {
            return {
              data: date,
              leads: (groups[date] as []).sort((a: IRelNotaUnidadeDTO, b: IRelNotaUnidadeDTO) => {
                const dataA = new Date();
                dataA.setMonth(a.mes);
                dataA.setFullYear(a.ano, a.mes)

                const dataB = new Date();
                dataB.setMonth(b.mes);
                dataB.setFullYear(b.ano, b.mes)

                if (dataA < dataB) {
                  return 1;
                }

                if (dataA > dataB) return -1;

                return 0;
              }),
            };
          });


          setHistoricosAgrupados(groupArrays);
          return;
        }
        throw new Error();
      })
      .catch((e) => {
        notification({
          description: 'Falha ao buscar o histórico',
          type: 'error',
          message: 'Falha!',

        });
      })
      .finally(() => {
        setIsGlobalLoading(false);
      });
  };


  useEffect(() => {
    if (unidade) {
      fetchHistoricos();
    }
  }, [unidade])

  useEffect(() => {
    if (unidade?.id && ((pageHistorico !== null && pageHistorico !== undefined) || (filtroMes !== null && filtroMes !== undefined) || (filtroAno !== null && filtroAno !== undefined))) {
      predicate.removeAllOption();

      filtroMes !== -1 && predicate.addOption('mes', filtroMes);
      filtroAno !== -1 && predicate.addOption('ano', filtroAno);

      fetchHistoricos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHistorico, filtroMes, filtroAno]);

  useEffect(() => {
    setPageHistorico(0)
  }, [filtroMes, filtroAno]);


  const onHistoricoPageChange = (event, page) => {
    event.preventDefault();
    setPageHistorico(page);
  };


  return (
    <div className="tabela-excelencia">
      <Cabecalheo />

      {historicosAgrupados?.map((mes, index) => (
        <>
          <Mes mes={mes.data} key={index} />
          {mes.leads.map((indicador, index) => (
            <Item
              key={index}
              rel={indicador}
            />
          ))}
        </>
      ))}
      <div className="paginacao">
        <Pagination
          onChange={(event, page) => onHistoricoPageChange(event, page - 1)}
          page={historicoResponse?.data ? historicoResponse?.data.pageable.pageNumber + 1 : 0}
          count={historicoResponse?.data ? historicoResponse?.data.totalPages : 0} />      </div>
    </div>
  )
}

