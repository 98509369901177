import { useCallback, useEffect, useState } from 'react';
import { Predicate } from '../models/predicate.model';
import { useEstadoService } from '../services/estado.service';
import { FilterState } from '../types/filter-state.type';

export default function useFindStates() {
	const estadoService = useEstadoService();

	const [listOfStates, setListOfStates] = useState<FilterState[]>();

	const findStatesData = useCallback(async () => {
		const sizeLenght = 100;
		const predicateEstado = new Predicate(sizeLenght);

		return await estadoService
			.findClean(predicateEstado)
			.then(({ data: { content } }) =>
				setListOfStates(content as FilterState[])
			);
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		findStatesData();
		return () => controller.abort();
	}, [findStatesData]);

	return {
		listOfStates,
	};
}
