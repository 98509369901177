import { ICobrancaDTO } from '../../../../../models/happy-code-api.model';
import { useCallback } from 'react';

interface UseGetPaymentTypeFromContractHookProps {
	cobrancasByIdFinanceiro: ICobrancaDTO[];
}

export default function useGetTypeCurrentContractHook({
	cobrancasByIdFinanceiro,
}: UseGetPaymentTypeFromContractHookProps) {
	const getTypeCurrentContract = useCallback(() => {
		if (!cobrancasByIdFinanceiro.length) return;

		const allChargsWithPaymentType = cobrancasByIdFinanceiro.filter(
			({ tipoPagamento }) => tipoPagamento
		);

		const getLastCharge =
			allChargsWithPaymentType[allChargsWithPaymentType.length - 1];

		return {
			codigo: getLastCharge.tipoPagamento?.codigo || '-',
			descricao: getLastCharge.tipoPagamento?.descricao || '-',
		};
	}, [cobrancasByIdFinanceiro]);

	return {
		getTypeCurrentContract,
	};
}
