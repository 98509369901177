import { AxiosResponse } from 'axios';
import { ITipoUnidadeCleanDTO, ITipoUnidadeDTO, ITipoUnidadeFullDTO } from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import http from '../helpers/http-common';

export const useTipoUnidadeService = () => {
	const resourceURL = 'tipo-unidade';

	const findTipoUnidadeById = (
		unidadeId: number
	): Promise<AxiosResponse<ITipoUnidadeCleanDTO>> => {
		return http.get(`${resourceURL}/unidade/${unidadeId}`);
	};

	return {
		...baseFind<ITipoUnidadeCleanDTO, ITipoUnidadeDTO, ITipoUnidadeFullDTO>(resourceURL),
		...baseFindList<ITipoUnidadeCleanDTO, ITipoUnidadeDTO, ITipoUnidadeFullDTO>(resourceURL),
		...baseFindById<ITipoUnidadeCleanDTO, ITipoUnidadeDTO, ITipoUnidadeFullDTO>(resourceURL),
		findTipoUnidadeById
	};
};
