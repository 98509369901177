import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { ChevronLeftRounded } from '@mui/icons-material';
import * as Unicons from '@iconscout/react-unicons';
import { Report } from 'powerbi-report-component';
import config from '../../../powerbi/config.json';
import './_dashboard-powerbi.styles.scss';
import useDashboardPowerBi from './use-dashbord-powerbi';
import MenuSidebarAndContainer from '../../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';
import useGoBackOnPage from '../../../globalHooks/use-go-back-on-page.hook';

interface ParamsType {
	id: string;
	idsec: string;
}

export default function DashboardPowerBi() {
	const { id: reportIdPowerBi, idsec: sectionIdPowerBi } =
		useParams<ParamsType>();

	const {
		handleReportLoad,
		setFullscreen,
		printReport,
		extraSettings,
		tokenPowerBi,
		handleReportRender,
	} = useDashboardPowerBi();

	const { goBackOnPage } = useGoBackOnPage();

	return (
		<MenuSidebarAndContainer itemMenuSelected={18}>
			<div className="report-powerbi__title-content">
				<div className="title-content__title">
					<Button
						className="arrow-button"
						shape="circle"
						onClick={goBackOnPage}
						icon={<ChevronLeftRounded />}
					/>
					<h1>Dashboard</h1>
				</div>
				<div className="title-content__buttons-div">
					<Button className="button-second" onClick={setFullscreen}>
						<Unicons.UilExpandAlt size={16} />
						Tela cheia
					</Button>
					<Button className="button-second" onClick={printReport}>
						<Unicons.UilPrint size={16} />
						Imprimir
					</Button>
				</div>
			</div>
			{tokenPowerBi && (
				<Report
					tokenType="Aad"
					accessToken={tokenPowerBi}
					embedUrl={config.embedUrl}
					embedId={reportIdPowerBi}
					pageName={sectionIdPowerBi}
					reportMode="View"
					extraSettings={extraSettings}
					permissions="All"
					style={{
						height: '100vh',
						borderWidth: 0,
						backgroundColor: 'transparent',
						window: '100vw',
						borderRadius: '16px',
					}}
					onLoad={handleReportLoad}
					onRender={handleReportRender}
				/>
			)}{' '}
		</MenuSidebarAndContainer>
	);
}
