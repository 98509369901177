import { DatePicker, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';

function RelAniversario() {
	const { unidade } = useGlobalContext();
	const [dados, setData] = useState<[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [mesAniversario, setMesAniversario] = useState<moment.Moment>();

	const service = useRelatorioService();

	useEffect(() => {
		if (unidade && unidade.id) {
			const predicate = new Predicate();

			predicate.addOption('unidadeId', unidade.id);
			predicate.addOption('mesAniversario', mesAniversario?.toISOString());

			if (mesAniversario != undefined) {
				service
					.aniversariantes(predicate)
					.then(({ data }) => setData(data))
					.catch((error) => console.log(error));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade, mesAniversario]);

	useEffect(() => {
		if (mesAniversario == undefined) {
			setMesAniversario(moment());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório Aniversariantes do mês
				</h1>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Mês de aniversário</span>
						<br />
						<DatePicker
							picker="month"
							onChange={setMesAniversario}
							format={'MM-YYYY'}
							style={{ width: '200px' }}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}

export default RelAniversario;
