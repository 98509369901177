import axios, { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IAlunoCleanDTO,
	IAlunoCustomIndexDTO,
	IAlunoDTO,
	IAlunoFullDTO,
	IAlunoLeadCustomDTO,
	IAlunoPessoaCustomDTO,
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { PageableResponse } from '../models/response.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAlunoService = () => {
	const resourceURL = 'aluno';
	let ajaxRequest = null;

	const findCustomById = (
		id: number
	): Promise<AxiosResponse<IAlunoLeadCustomDTO>> =>
		http.get<IAlunoLeadCustomDTO>(resourceURL + `/custom/${id}`);

	const findResponsavelAlunoById = (
		id: number
	): Promise<AxiosResponse<IAlunoLeadCustomDTO>> =>
		http.get<IAlunoLeadCustomDTO>(resourceURL + `/responsavel-aluno/${id}`);

	const findCustom = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<PageableResponse<IAlunoCustomIndexDTO>>> => {
		// cancel  previous ajax if exists
		if (ajaxRequest) {
			ajaxRequest.cancel();
		}
		// creates a new token for upcomming ajax (overwrite the previous one)
		ajaxRequest = axios.CancelToken.source();
		const { token: cancelToken } = ajaxRequest;
		return http.get<PageableResponse<IAlunoCustomIndexDTO>>(
			resourceURL + `/custom/`,
			{ ...formatHttpParams(predicate), cancelToken }
		);
	};

	const findAlunoPessoaCustom = (
		unidadeId: number
	): Promise<AxiosResponse<IAlunoPessoaCustomDTO[]>> =>
		http.get<IAlunoPessoaCustomDTO[]>(
			resourceURL + `/dash-custom/${unidadeId}`
		);

	const findAlunoRotina = (): Promise<AxiosResponse<void>> =>
		http.get<void>(resourceURL + `/status-alunos`);

	return {
		...baseCreate<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
		...baseFind<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
		...baseFindById<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
		...baseUpdate<IAlunoCleanDTO, IAlunoDTO, IAlunoFullDTO>(resourceURL),
		findCustomById,
		findCustom,
		findAlunoPessoaCustom,
		findAlunoRotina,
		findResponsavelAlunoById,
	};
};
