import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IGrupoContratoCleanDTO,
	IGrupoContratoDTO,
	IGrupoContratoFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useGrupoContratoService = () => {
	const resourceURL = 'grupo-contrato';

	const rotinaEnvioLicencaStemplay = (
		to: string,
		cc: string
	): Promise<AxiosResponse<void>> =>
		http.post<void>(resourceURL + `/atualizacao-licenca-stemplay/${to}/${cc}`);

	return {
		...baseFindList<
			IGrupoContratoCleanDTO,
			IGrupoContratoDTO,
			IGrupoContratoFullDTO
		>(resourceURL),
		...baseFind<
			IGrupoContratoCleanDTO,
			IGrupoContratoDTO,
			IGrupoContratoFullDTO
		>(resourceURL),
		...baseCreate<
			IGrupoContratoCleanDTO,
			IGrupoContratoDTO,
			IGrupoContratoFullDTO
		>(resourceURL),
		...baseUpdate<
			IGrupoContratoCleanDTO,
			IGrupoContratoDTO,
			IGrupoContratoFullDTO
		>(resourceURL),
		...baseFindById<
			IGrupoContratoCleanDTO,
			IGrupoContratoDTO,
			IGrupoContratoFullDTO
		>(resourceURL),
		rotinaEnvioLicencaStemplay,

		// anexar: (idContrato: number, body): Promise<IAxiosResponseCustom<IGrupoContratoCleanDTO>> =>
		//     http.post(`${resourceURL}/anexar/${idContrato}`, body),

		// desanexar: (idContrato: number): Promise<IAxiosResponseCustom<IGrupoContratoDTO>> =>
		//     http.post(`${resourceURL}/desanexar/${idContrato}`),

		cancelar: (
			idContrato: number,
			isComMulta: boolean
		): Promise<IAxiosResponseCustom> =>
			http.post(`${resourceURL}/cancelar/${idContrato}/${isComMulta}`),

        inativarContrato: (idContrato: number): Promise<AxiosResponse<void>> =>
            http.patch(`${resourceURL}/inativar/${idContrato}`),
	};
};
