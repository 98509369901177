import {
	IUsuarioUnidadeCleanDTO,
	IUsuarioUnidadeDTO,
	IUsuarioUnidadeFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useUsuarioUnidadeService = () => {
	const resourceURL = 'usuario-unidade';

	return {
		...baseFind<
			IUsuarioUnidadeCleanDTO,
			IUsuarioUnidadeDTO,
			IUsuarioUnidadeFullDTO
		>(resourceURL),
		...baseFindList<
			IUsuarioUnidadeCleanDTO,
			IUsuarioUnidadeDTO,
			IUsuarioUnidadeFullDTO
		>(resourceURL),
		...baseFindById<
			IUsuarioUnidadeCleanDTO,
			IUsuarioUnidadeDTO,
			IUsuarioUnidadeFullDTO
		>(resourceURL),
	};
};
