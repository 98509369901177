import { Rating } from "@material-ui/lab";
import { Button, Checkbox, Collapse, DatePicker, Form, Input, Select, Space, Tag, Typography } from 'antd';
import { MaskedInput } from "antd-mask-input";
import { ICursoCleanDTO, ILeadCampanhaCleanDTO, ILeadEstagioDTO, ILeadOrigemDTO, ILeadStatusCleanDTO, ILeadTipoDTO, IUnidadeCleanDTO } from "../../../../../models/happy-code-api.model";
import '../../../shared.scss';
import { ModalResposta } from '../resposta';
import '../style.scss';
import useLeadNewState from "./selectors/leadnew.state";
import CurrencyFormat from 'react-currency-format';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ModalLeadNew = (props: Props) => {

  const {
    onClose,
    onClose1,
    onSubmit,
    handleChangePais,
    open,
    Option,
    isModalSucessoOpen,
    isModalErroOpen,
    state,
    setCampo,
    onCepChange,
    form,
    onChangeValorInteresse,
    removerInteresse,
    interesses,
    adicionarInteresse,
    vlLeadInteresse,
    selecionarCurso,
    cursoInteresse,
    unidadeUsuarioLogado,
  } = useLeadNewState(props);

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center items-center modal-container">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onClose} className="button-x">x</Button>
          <h3 >Cadastrar novo lead</h3>
          <Form
            style={{ maxWidth: '100%' }}
            name="basic"
            // initialValues={{ remember: true }}
            form={form}
          >
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="box-check" >
                <div className="inputModal">
                  <Form.Item name={'flLeadNovo'} initialValue={true}>
                    <Checkbox
                      id="flLeadNovo"
                      defaultChecked={true}
                    />
                    <span style={{ marginLeft: 10 }}>Lead novo?</span>
                  </Form.Item>
                </div>
              </div>
              <div>
                <Typography>Avaliação do Lead</Typography>
                <Form.Item name={'nrAvaliacaoLead'}>
                  <Rating
                    name="nrAvaliacaoLead"
                    id="nrAvaliacaoLead"
                    onChange={(event, newValue) => {
                      setCampo('nrAvaliacaoLead', newValue);
                    }}
                  />
                </Form.Item>

              </div>
            </div>


            <Space className="w-100" direction="vertical">
              <Collapse collapsible="header" defaultActiveKey={[1, 2, 3]}>
                <Collapse.Panel header="Dados gerais" key="1">
                  <div className="row">
                    <div className="flex-1 flex">
                      <div className="inputModal">
                        <span>CPF / CNPJ<span className="vermelho"></span></span>
                        <Form.Item name={'cdCpfCnpj'} rules={[{ required: false, message: 'Insira o CPF / CNPJ' }]}>
                          <Input id="cdCpfCnpj" className="input" />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="horizontal-separator" />

                    <div className="flex-2 flex">
                      <div className="inputModal">
                        <span>Nome<span className="vermelho">*</span></span>
                        <Form.Item name={'dsNomeLead'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o nome completo' }]}>
                          <Input id="dsNomeLead" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="flex-2 flex">

                      <div className="inputModal">
                        <span>E-mail<span className="vermelho"></span></span>
                        <Form.Item name={'cdEmail'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o e-mail' }]}>
                          <Input id="cdEmail" />
                        </Form.Item>
                      </div>

                    </div>

                    <div className="horizontal-separator" />

                    <Form.Item name={['status', "id"]} style={{ display: 'none' }} />

                    <div className="flex-1 flex">
                      <div className="inputModal">
                        <span>Status<span className="vermelho">*</span></span>
                        <Form.Item
                          name={['status', "id"]}
                          fieldKey={['status', "nome"]}
                          style={{ width: '100%' }} rules={[{ required: true, message: 'Defina o status do Lead' }]}>
                          <Select style={{ width: '100%' }} bordered={false} >
                            {state.leadStatus?.map?.((status: ILeadStatusCleanDTO) => <Option key={status.id} value={status.id}>{status.dsLeadStatus}</Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: 'flex' }}>
                      <div className="inputModal" style={{ width: '20%' }}>
                        <span>DDD<span className="vermelho"></span></span>
                        <Form.Item name={'cdDddCelular'} style={{ width: '100%' }} rules={[{ required: false, message: '' }]}>
                          <MaskedInput mask="11" value={state?.formControl?.cdDddCelular}
                            placeholder='Celular' bordered={false} />
                        </Form.Item>
                      </div>
                      <div className="horizontal-separator" />
                      <div className="inputModal" style={{ width: '80%' }}>
                        <span>Celular<span className="vermelho"></span></span>
                        <Form.Item name={'cdCelular'} style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o celular' }]}>
                          <MaskedInput mask="11111-1111" value={state?.formControl?.cdCelular}
                            placeholder='Celular' bordered={false} />
                        </Form.Item>
                        <div />
                      </div>
                    </div>

                    <div className="horizontal-separator" />
                    <div className="horizontal-separator" />
                    <div className="horizontal-separator" />

                    <div style={{ display: 'flex' }}>
                      <div className="inputModal" style={{ width: '20%' }}>
                        <span>DDD</span>
                        <Form.Item name={'cdDddTelefone'} style={{ width: '100%' }} rules={[{ required: false, message: '' }]}>
                          <MaskedInput mask="11" value={state?.formControl?.cdDddTelefone} />
                        </Form.Item>
                      </div>
                      <div className="horizontal-separator" />
                      <div className="inputModal" style={{ width: '80%' }}>
                        <span>Telefone</span>
                        <Form.Item name={'cdTelefone'} style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o telefone' }]}>
                          <MaskedInput mask="11111-1111" value={state?.formControl?.cdTelefone}
                          // onChange={e => setCampo('cdTelefone', e.target.value)} placeholder='Celular' bordered={false}
                          />
                        </Form.Item>
                        <div />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="flex-1 flex">
                      <div className="inputModal">
                        <span>Campanha</span>
                        <Form.Item
                          name={['campanha', "id"]}
                          fieldKey={['campanha', "nome"]}
                          style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a campanha' }]}>
                          <Select style={{ width: '100%' }} bordered={false}>
                            {state.campanhas?.map?.((campanha: ILeadCampanhaCleanDTO) => <Option key={campanha.id} value={campanha.id}>{campanha.dsLeadCampanha}</Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="horizontal-separator" />
                    <div className="flex-1 flex">
                      <div className="inputModal">
                        <span>Tipo Lead<span className="vermelho">*</span></span>
                        <Form.Item
                          name={['tipo', "id"]}
                          fieldKey={['tipo', "nome"]}
                          style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione o tipo do lead' }]}>
                          <Select id="estagio" style={{ width: '100%' }} bordered={false}>
                            {state.leadTipos?.map?.((leadTipo: ILeadTipoDTO) => <Option key={leadTipo.id} value={leadTipo.id}>{leadTipo.dsLeadTipo}</Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="flex">
                      <div className="inputModal">
                        <span>Data do próximo retorno</span>
                        <Form.Item name={'dhProximoRetorno'} style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a data de retorno' }]}>
                          <DatePicker
                            // value={moment(state?.formControl?.dhProximoRetorno)}
                            onChange={(value) => setCampo('dhProximoRetorno', value)}
                            placeholder="Data retorno"
                            suffixIcon={null}
                            format={'DD-MM-YYYY'}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="horizontal-separator" />
                    <div className="flex flex-1">
                      <div className="inputModal">
                        <span>Estágio<span className="vermelho">*</span></span>
                        <Form.Item
                          name={['estagio', "id"]}
                          fieldKey={['estagio', "nome"]}
                          style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione o estágio inicial do lead' }]}>
                          <Select id="estagio" style={{ width: '100%' }} bordered={false}>
                            {state.leadEstagios?.map?.((estagio: ILeadEstagioDTO) => <Option key={estagio.id} value={estagio.id}>{estagio.dsLeadEstagio}</Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="horizontal-separator" />
                    <div className="flex flex-1">
                      <div className="inputModal">
                        <span>Origem<span className="vermelho">*</span></span>
                        <Form.Item
                          name={['origem', "id"]}
                          fieldKey={['origem', "nome"]}
                          style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione a origem do lead' }]}>
                          <Select id="origem" style={{ width: '100%' }} bordered={false}>
                            {state.leadOrigens?.map?.((origem: ILeadOrigemDTO) => <Option key={origem.id} value={origem.id}>{origem.dsLeadOrigem}</Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div >

                  <div className="row">
                    <div className="inputModal">
                      <span>Unidade</span>
                      <Form.Item
                        name={['unidade', "id"]}
                        initialValue={unidadeUsuarioLogado.id}
                        fieldKey={['unidade', "nome"]}
                        style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione a unidade' }]}>
                        <Select style={{ width: '100%' }} bordered={false} defaultValue={unidadeUsuarioLogado.id} disabled={true}>
                          {state.unidades?.map?.((unidade: IUnidadeCleanDTO) => <Option key={unidade.id} value={unidade.id}>{unidade.descricao}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Collapse.Panel>

                <Collapse.Panel header="Endereço" key="2">
                  <div className="box-collapse">
                    <Form.Item name="id" />
                    <div className="input-franquia">
                      <span>CEP<span className="vermelho"></span></span>
                      <Form.Item name={['endereco', "cep"]}
                        rules={[{ required: false, message: 'Insira o CEP' }]}>
                        <Input
                          id="cep"
                          onChange={e => onCepChange(e.target.value)}
                          className="input"
                          maxLength={8}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item name={['endereco', "pais", "id"]} style={{ display: 'none' }} />
                    <div className="select-franquia">
                      <span>País<span className="vermelho"></span></span>
                      <Form.Item
                        name={['endereco', "paisId"]}
                        fieldKey={['endereco', "nome"]}
                        rules={[{ required: false, message: 'Selecione um País' }]}>
                        <Select
                          style={{ width: '100%' }}
                          bordered={false}
                          onSelect={(e) => handleChangePais(e)}
                          id="pais"
                          placeholder="Selecione um País"
                          allowClear
                        >
                          {state.paises && state.paises.map((item, index) => (
                            <Select.Option key={index} value={item.id} >{item.nome}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="box-cidade">
                      <div className="select-franquia">
                        <span>Estado<span className="vermelho"></span></span>
                        <Form.Item name={['endereco', "nomeUF"]} rules={[{ required: false, message: 'Selecione um Estado' }]}>
                          <Select
                            id="estado"
                            placeholder="Selecione um Estado"
                            allowClear
                          >
                            {state.estados && state.estados.map((item) => (
                              <Select.Option key={item.id} value={item.nome}>{item.nome}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="select-franquia">
                        <span>Cidade<span className="vermelho"></span></span>
                        <Form.Item name={['endereco', "cidade"]} rules={[{ required: false, message: 'Selecione uma Cidade' }]}>
                          <Input
                            id="cidade"
                            className="input"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="box-complemento">
                      <div className="input-franquia">
                        <span>Logradouro<span className="vermelho"></span></span>
                        <Form.Item name={['endereco', "logradouro"]} rules={[{ required: false, message: 'Insira o Logradouro' }]}>
                          <Input
                            id="logradouro"
                            className="input"
                          />
                        </Form.Item>
                      </div>
                      <div className="input-franquia" style={{ width: "17%" }}>
                        <span>Número<span className="vermelho"></span></span>
                        <Form.Item name={['endereco', "numero"]} rules={[{ required: false, message: 'Insira o Número' }]}>
                          <Input
                            id="numero"
                            className="input"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-franquia">
                      <span>Complemento</span>
                      <Form.Item name={['endereco', "complemento"]}>
                        <Input
                          id="complemento"
                          className="input"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Collapse.Panel>

                <Collapse.Panel header="Produtos" key="3" style={{ width: '100%' }}>
                  <div className="row justify-center">
                    <div className="flex flex-1">
                      <div className="inputModal">
                        <span>Cursos</span>
                        <Select id="interesses" style={{ width: '100%' }} bordered={false} onSelect={(e) => selecionarCurso(e)} value={cursoInteresse}>
                          {state.cursos?.map?.((curso: ICursoCleanDTO) => <Option key={curso.id} value={curso.id}>{curso.descricao}</Option>)}
                        </Select>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="inputModal">
                        <span>Valor de interesse</span>
                        <Form.Item
                          style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a campanha' }]}>
                          <CurrencyFormat value={vlLeadInteresse} isNumericString={true} decimalScale={2} onValueChange={(e) => onChangeValorInteresse(e)} thousandSeparator={true} prefix={'R$'} />
                        </Form.Item>
                      </div>
                    </div>
                    <Button
                      disabled={!vlLeadInteresse || !cursoInteresse}
                      onClick={adicionarInteresse}
                      type="link"
                      shape="round"
                      style={{ top: 10 }}
                      icon={<PlusOutlined rev={undefined} />}
                      size={'large'}
                    />
                  </div>

                  <div className="row flex-wrap">
                    {interesses?.map((interesse) => {
                      return <Tag style={{ marginBottom: 10, padding: 10 }} key={interesse} closable onClose={(e) => removerInteresse(interesse)}>
                        {interesse.curso.descricao} - R$ {interesse.vlLeadInteresse}
                      </Tag>;
                    })}
                  </div>
                </Collapse.Panel>
              </Collapse>

            </Space>

            <div className="vertical-separator" />

            <div className="flex flex-row justify-end w-100">
              <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
              <Button onClick={onSubmit} className="button-primary">Salvar</Button>
            </div>
          </Form>
        </div>
        <ModalResposta
          open={isModalSucessoOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link="crm"
        />
        <ModalResposta
          open={isModalErroOpen}
          onClose={onClose1}
          status="Ocorreu um erro!"
          text="Ocorreu um erro ao salvar o lead, por favor, tente novamente."
          link="crm"
        />
      </div >
    </>
  )
}
