import {
	ILeadStatusCleanDTO,
	ILeadStatusDTO,
	ILeadStatusFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadStatusService = () => {
	const resourceURL = 'lead-status';

	return {
		...baseFindList<ILeadStatusCleanDTO, ILeadStatusDTO, ILeadStatusFullDTO>(resourceURL),
	};
};
