import moment from 'moment';

export const getPeriodo = (
	dataMatriculaInicio: moment.Moment,
	dataMatriculaFim: moment.Moment,
	dataPadrao: Date
): any => {
	if (dataMatriculaInicio && dataMatriculaFim) {
		return [
			moment(dataMatriculaInicio, 'YYYY-MM-DD'),
			moment(dataMatriculaFim, 'YYYY-MM-DD'),
		];
	}
	return [null, moment(dataPadrao, 'YYYY-MM-DD')];
};

export const getPeriodoSemPadrao = (
	dataMatriculaInicio: moment.Moment,
	dataMatriculaFim: moment.Moment
): any => {
	if (dataMatriculaInicio && dataMatriculaFim) {
		return [
			moment(dataMatriculaInicio, 'YYYY-MM-DD'),
			moment(dataMatriculaFim, 'YYYY-MM-DD'),
		];
	}
};
