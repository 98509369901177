import { useCallback, useEffect, useState } from 'react';
import useGetListOfMatriculas from '../../../globalHooks/matricula/use-get-list-of-matriculas.hook';
import { useAlunoContext } from '../../../context/AlunoContext';

interface UseUpgradeIsAvailableProps {
	idFinanceiro?: number;
}

export default function useUpgradeIsAvailable({
	idFinanceiro,
}: UseUpgradeIsAvailableProps) {
	const [upgradeButtonVisible, setUpgradeButtonVisible] = useState(false);

	const { currentContractEnrollments } = useGetListOfMatriculas({
		idFinanceiroNumber: idFinanceiro,
	});

	const thenReleaseUpgrade = () => setUpgradeButtonVisible(true);
	const thenBlockUpgrade = () => setUpgradeButtonVisible(false);

	const checkIfUpgradeIsAvailable = useCallback((): void => {
		if (currentContractEnrollments.length > 0) return thenReleaseUpgrade();

		return thenBlockUpgrade();
	}, [currentContractEnrollments.length]);

	const { setDataInicioContrato } = useAlunoContext();

	useEffect(() => {
		checkIfUpgradeIsAvailable();

		setDataInicioContrato(currentContractEnrollments[0]?.dataInicioContrato);
	}, [
		checkIfUpgradeIsAvailable,
		currentContractEnrollments,
		setDataInicioContrato,
	]);

	return {
		upgradeButtonVisible,
	};
}
