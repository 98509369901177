export enum ContatoEnum {
	TEL = 1,
	EPS = 2,
	CEL = 3,
	EPF = 4,
	EAL = 5,

	TEL_CODIGO = 'TEL',
	EPS_CODIGO = 'EPS',
	CEL_CODIGO = 'CEL',
	EPF_CODIGO = 'EPF',
	EAL_CODIGO = 'EAL',
}
