import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useUnidadeContext } from '../../../../context/UnidadeContext';

interface Props {
  current: number;
}

function MenuLateralCreateComponent({ current }: Props): ReactElement {

  const { revisao } = useUnidadeContext();

  return (
    <div className="menu-user">
      <h3>Criação de Grupos</h3>
      <Steps direction="vertical" current={revisao ? 8 : current}>
        <Steps.Step title="Dados Básicos" />
        <Steps.Step title="Localização" />
        <Steps.Step title="Focais" />
        {/* <Steps.Step title="Contrato" /> */}
        {/* <Steps.Step title="Financeiro" /> */}
        <Steps.Step title="Revisão" />
      </Steps>
    </div>
  )
}

export default MenuLateralCreateComponent
