import { Button, Checkbox, Col, Form, InputNumber, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';

import { ICobrancaCustomDTO, IEstornoDTO } from '../../models/happy-code-api.model';
import { useCobrancaService } from '../../services/cobranca.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney, formattedDate } from '../../util/format';
import { TagsFinanceiro } from '../TagsFinanceiro';
import * as Unicons from '@iconscout/react-unicons';
import './style.css';
interface Text {
    open: boolean;
    onClose: () => void;
}

export const ModalSelecionar: React.FunctionComponent<Text> = ({ open, onClose }: Text) => {

    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();
    //const cobrancaService = useCobrancaService(); -- 'USE' conexão com  o back
    const [isOpenAndAluno, setIsOpenAndAluno] = useState<boolean>(false);
    const [mostrarModalAluno, setMostrarModalAluno] = useState<boolean>(false);


    const closeModal = () => {
        setIsOpenAndAluno(false);
    }

    const fecharModais = () => {
        closeModal();
        onClose();
    }


    {/* PUXA
    const estornaCobranca = () => {
        setIsGlobalLoading(true);
        cobrancaService.estornarCobranca(cobrancaEstornoParcial)
        .then(() => {
            fecharModais();
            notification({
                type: 'success',
                message: `Estorno efetuado com sucesso!`,
            });
            
        })
        .catch()
        .finally(() => {
            setIsGlobalLoading(false);
        });
    }
*/}


    if (!open) return null

    return (
        <div className="modalContainerFinanceiro-estorno">
            <div className="modal-user-financeiro-estorno">
                <div className="body">
                    <h3 style={{ textAlign: 'center' }}>Selecionar alunos</h3>

                    <div className="box-tabela" style={{ marginTop: 20 }}>
                        <div className="tabela">
                            <Row className="titles-users">
                                <Col className="item gutter-row" span={6}>
                                    <span>ALUNO</span>
                                </Col>
                            </Row>

                            <Row className="registro-user">
                                <Col className="funcao-user gutter-row" span={6}>
                                    <p>oi</p>
                                </Col>
                                <Col className="funcao-user gutter-row" span={6}>
                                    <Unicons.UilPlusCircle size="20" color="#92A9CB" />
                                </Col>
                            </Row>

                            <Form>
                                <Row className="registro-user">
                                    <Col>
                                        <div className="check-radios">
                                            <div id="check-estorno-parcial">

                                            </div>
                                        </div>

                                        <div className="check-radios">
                                            <div id="check-estorno-parcial">
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>

                        </div>
                    </div>
                </div>

                <div className="line"></div>
                <div className="botoes">
                    <Button onClick={onClose} style={{ minWidth: 145 }} className="estornar-button-second">Voltar</Button>
                </div>
            </div>
            <ModalSelecionar
                open={isOpenAndAluno}
                onClose={closeModal}
            />
        </div >
    );
}

export default ModalSelecionar;