import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Input, Dropdown, Select, Menu } from 'antd';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';

import { ICursoDTO, IModalidadeDTO, ITurmaDTO, ITurmaFullDTO, ITurmaKpiDTO, IUsuarioDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCursoService } from '../../services/curso.service';
import { useModalidadeService } from '../../services/modalidade.service';
import { useTurmaService } from '../../services/turma.service';
import { useUsuarioService } from '../../services/usuario.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import './style.css';

export const StemplayTurmas: React.FunctionComponent = () => {
  const { setTurma, removeTurma } = useTurmaContext();
  const { setIsGlobalLoading, usuario, unidade } = useGlobalContext();

  const [listTurma, setListTurma] = useState<PageableResponse<ITurmaFullDTO>>(null);
  const [listCurso, setListCurso] = useState<ICursoDTO[]>(null);
  const [usuarioTurma, setUsuarioTurma] = useState<IUsuarioDTO>(null);
  const [kpi, setKpi] = useState<ITurmaKpiDTO>(null);
  const [modalidadeId, setModalidadeId] = useState<number | string>();
  const [cursoId, setCursoId] = useState<number | string>();
  const [listModalidade, setListModalidade] = useState<IModalidadeDTO[]>(null);
  const [turmaPredicate] = useState<Predicate>(new Predicate());
  const [descricao, setDescricao] = useState<string>();

  const history = useHistory();
  const location = useLocation();

  const turmaService = useTurmaService();
  const cursoService = useCursoService();
  const usuarioService = useUsuarioService();
  const modalidadeService = useModalidadeService();

  const professorPath = '/turmas/professor';

  const isProfessorPath = () => {
    if (location.pathname === professorPath) {
      return true;
    }
    return false;
  }

  const findUsuario = () => {
    return usuarioService.findById(usuario)
      .then((response) => {
        turmaPredicate.addOption('unidadePessoa.pessoa.id', response.data.pessoa.id);
        setUsuarioTurma(response.data);
      })
  }

  const findTurma = () => {
    if (isProfessorPath()) {
      turmaPredicate.addOption('unidadePessoa.pessoa.id', usuarioTurma?.pessoa?.id);
    }
    turmaPredicate.addOption('unidade.id', unidade.id);
    turmaPredicate.addOption('ativo', true);
    return turmaService.findFull(turmaPredicate)
      .then((response) => setListTurma(response.data));
  }

  const findCurso = () => {
    return cursoService.findList()
      .then((response) => {
        setListCurso(response.data);
      });
  }

  const findModalide = () => {
    return modalidadeService.findList()
      .then((response) => {
        setListModalidade(response.data);
      });
  }

  const getKPI = () => {
    if (isProfessorPath()) {
      turmaPredicate.addOption('unidadePessoa.pessoa.id', usuarioTurma?.pessoa?.id);
    }
    turmaPredicate.addOption('unidade.id', unidade.id);
    return turmaService.getKpi(turmaPredicate)
      .then((response) => {
        setKpi(response.data);
      })
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    removeTurma();
    if (unidade.id) {
      if (isProfessorPath()) {
        Promise.all([
          findUsuario(),
          findCurso(),
          findModalide(),
          getKPI(),
          findTurma(),
        ]).finally(() => setIsGlobalLoading(false));
      } else {
        Promise.all([
          findTurma(),
          getKPI(),
          findCurso(),
          findModalide(),
        ]).finally(() => setIsGlobalLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unidade.id]);

  // useEffect(() => {
  //   setIsGlobalLoading(true);
  //   if (isProfessorPath()) {
  //     findUsuario().finally(() => setIsGlobalLoading(false));
  //   } else {
  //     findTurma().finally(() => setIsGlobalLoading(false));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    unidade.id && getKPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioTurma]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao !== undefined) {
        setIsGlobalLoading(true);
        turmaPredicate.addOption('descricao', descricao, Operators.CONTAINS);
        unidade.id && findTurma().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  useEffect(() => {
    if (cursoId && cursoId !== 'TDS') {
      turmaPredicate.addOption('cursoId', cursoId);
    } else {
      turmaPredicate.removeOption('cursoId');
    }
    if (modalidadeId && modalidadeId !== 'TDS') {
      turmaPredicate.addOption('cursoModalidadeId', modalidadeId);
    } else {
      turmaPredicate.removeOption('cursoModalidadeId');
    }
    if (modalidadeId || cursoId || usuarioTurma) {
      setIsGlobalLoading(true);
      Promise.all([
        findTurma(),
        getKPI(),
      ]).finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalidadeId, cursoId, usuarioTurma]);

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    turmaPredicate.setPage(page);
    setIsGlobalLoading(true);
    findTurma().finally(() => setIsGlobalLoading(false));
  }


  const onModalidadeChange = (modalidade) => {
    setModalidadeId(modalidade);
  }

  const onCursoChange = (curso) => {
    setCursoId(curso);
  }

  const goToEdit = (turma: ITurmaDTO) => {
    setIsGlobalLoading(true);
    turmaService.findFullById(turma)
      .then((response) => {
        setTurma(response.data);
        history.push("/turmas/create/step-1")
      }).finally(() => setIsGlobalLoading(false));
  }

  const statusTurma = (turma: ITurmaDTO) => {
    if (isBeforeDate(turma.dataInicio)) {
      return (
        <div className="estado-user">
          <div className="dot-orange"></div>
          <p className='inativo' style={{ marginRight: 10 }}>Não Iniciado</p>
        </div>
      )
    }
    if (isDateBetween(turma.dataInicio, turma.dataFim)) {
      return (
        <div className="estado-user">
          <div className="dot-verde"></div>
          <p className='ativo' style={{ marginRight: 10 }}>Em andamento</p>
        </div>
      )
    }
    if (isAfterDate(turma.dataFim)) {
      return (
        <div className="estado-user">
          <div className="dot-orange"></div>
          <p className='inativo' style={{ marginRight: 10 }}>Finalizado</p>
        </div>
      )
    }
    return null;
  }

  const itensDropdown = () => {
    return (
      <Menu>
        <Menu.Item>
          <a>excluir</a>
        </Menu.Item>
        <Menu.Item>
          <Link to="/Stemplay/edit">
            <a>editar</a>
          </Link>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div className="container">
      <NewMenu selecionado={30} />
      <div className="dashboard">
        <div className="box-title">
          <div id="turma">
            <h3>Turmas Stemplay ({listTurma?.totalElements})</h3>
          </div>
          <Link to="/Stemplay/create">
            <Button className="button-primary" icon={<PlusOutlined rev={undefined} />}>Nova Turma</Button>
          </Link>
        </div>
        <div className="filtros">
          <div className="input-text" style={{ width: '85%' }}>
            <Input id="buscar" onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
          </div>
        </div>

        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '18%' }}>
              <span>NOME DA TURMA</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>ETAPA ESCOLAR</span>
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>ANO/ CONTEÚDO</span>
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>PACOTE</span>
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>Nº ALUNOS</span>
            </div>

          </div>

          {listTurma && listTurma.content.map((turma, index) => (
            <div key={index} className="registro-user" id="registro-financeiro">
              <div className="user" id="cursos" style={{ width: '20%', paddingLeft: 10 }}>
                <div className="nome">
                  <p>{turma.descricao}</p>
                  <div className="estado-user">
                    <h4 className="ativo">{statusTurma(turma)}</h4>
                  </div>
                </div>
              </div>
              <div className="funcao-user" id="alunos" style={{ width: '15%' }}>
                <Tags tipo={turma.curso.descricao} />
              </div>
              <div className="funcao-user" id="turma" style={{ width: '5%' }}>
                <p>{turma.matriculas.filter(m => m.ativo === true).length}</p>
              </div>

              <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar">
                  <Link to="/Stemplay/detalhe">
                    <Unicons.UilEye size="20" color="#92A9CB" />
                  </Link>
                </a>
              </div>
              <div className="registro-opcao" style={{ width: "5%" }}>
                <Dropdown overlay={itensDropdown()} placement="bottomRight" arrow>
                  <Unicons.UilEdit className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
              </div>
            </div>
          ))}
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={listTurma ? listTurma.pageable.pageNumber + 1 : 0} count={listTurma ? listTurma.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StemplayTurmas