import http from '../helpers/http-common';
import {
	ILogCleanDTO
} from '../models/happy-code-api.model';

export const useLogService = () => {
	const resourceURL = 'log';

	const registraLog = (log: ILogCleanDTO) => {
		return http.post(`${resourceURL}/inserir-log`, log)
	}

	return {
		registraLog
	};
};
