import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../../../enum/operators.enum';
import { Predicate } from '../../../../models/predicate.model';


interface TFiltrosAula {
    predicate: Predicate;
    findAula: () => void;
}
const FiltrosAula = memo(({ predicate, findAula }: TFiltrosAula) => {
    const [descricao, setDescricao] = useState<string>();
    const [status, setStatus] = useState<number>();

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('descricao', descricao, Operators.CONTAINS);
            }
            if (status) {
                predicate.addOption('ativo', status == 1 ? true : false);
            }
            findAula();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, status]);
    
    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '85%' }}>
                <Input id="buscar" value={descricao} placeholder="Buscar Curso" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} onChange={e => setDescricao(e.target.value)} />
            </div>

            <div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
                <span>Status</span>
                <Select className="ant-dropdown-link" defaultValue="0" style={{ width: '100%' }} onChange={e => setStatus(parseInt(e))}>
                    <Select.Option value="0">Todos</Select.Option>
                    <Select.Option key={1} value={1}>Ativo</Select.Option>
                    <Select.Option key={2} value={2}>Inativo</Select.Option>
                </Select>
            </div>
        </div>
    </>
    )
})

FiltrosAula.displayName = 'FiltrosAula'

export default FiltrosAula;

