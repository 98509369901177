/* eslint-disable react-hooks/exhaustive-deps */
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from "../../../context/GlobalContext";
import { IGrupoDTO, ILeadEscolaFullDTO, IUnidadeDTO } from "../../../models/happy-code-api.model";
import { Predicate } from "../../../models/predicate.model";
import { useGrupoService } from "../../../services/grupo.service";
import { useUnidadeService } from "../../../services/unidade.service";
import '../../../theme/flex.scss';
import EscolaGruposFormActionComponent from '../components/escola-form-action-component/escola-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-form-fechar-component/escola-form-fechar.component';
import MenuLateralCreateComponent from '../components/menu-lateral-create-component/menu-lateral-create.component';
import '../styles/escola-create-step.styles.scss';
import { useLeadEscolaService } from "../../../services/lead-escola.service";
import { PerfilEnum } from "../../../enum/perfil.enum";

const EscolaCreateStep1Screen: React.FunctionComponent = () => {
    //const { unidade, } = useUnidadeContext();
    const { idLead } = useParams<any>();
    const { setIsGlobalLoading, unidade, usuario } = useGlobalContext();

    const { escolaParaCadastrar,
        setEscolaParaCadastrar,
        flRevisao,
        setFlRevisao,
        categoriasQuantidadeAlunos,
        categoriasConfessionais, } = useEscolaContext();

    const unidadeService = useUnidadeService();

    const history = useHistory();
    const [form] = Form.useForm();
    const grupoService = useGrupoService();
    const [grupos, setGrupos] = useState<IGrupoDTO[]>();
    const [franquias, setFranquias] = useState<IUnidadeDTO[]>();
    const leadService = useLeadEscolaService();
    const [lead, setLead] = useState<ILeadEscolaFullDTO>();

    const required = true;

    // useEffect(() => {
    //   const franquiaDataFromContext = unidade;
    //   if (franquiaDataFromContext) {
    //     form.setFieldsValue(franquiaDataFromContext);
    //   }
    // }, [form, unidade]);

    // useEffect(() => {
    //   if (lead) {

    //     if (!escolaParaCadastrar?.cnpj)

    //       setEscolaParaCadastrar({
    //         ... escolaParaCadastrar, 
    //         cnpj: lead.cpfOuCnpj, 
    //         razaoSocial: lead.nome, 
    //         nomeFantasia: lead.nome,
    //         grupo: lead.grupo,
    //         categoriasQuantidadeAlunos: lead.categoriaQuantidadeAlunos,
    //         // categoriasConfessionais: lead.categoriaConfessional,
    //         unidade: lead.unidade
    //       });

    //   }
    // }, [lead]);

    useEffect(() => {
        unidadeService.findList().then((response) => {
            setFranquias(response.data);
        });
    }, [unidade])

    const handleUnidade = (franquiaCrud) => {
        const contextState = { ...escolaParaCadastrar, ...franquiaCrud, };

        if (franquiaCrud.unidade?.id) {
            contextState.unidade.descricao = franquias.filter(franquia => franquia.id === franquiaCrud.unidade?.id)[0].descricao;
        } else {
            contextState.unidade = null;
        }

        setEscolaParaCadastrar({ ...contextState });
        if (flRevisao) {
            setFlRevisao(false);
            return history.replace("/escolas/create/step-4");
        }
        history.push("/escolas/create/step-2");
    };

    const fetchGrupos = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('ativo', true);

        if (usuario?.perfil === PerfilEnum.FRANQUIA) {
            predicate.addOption('unidade.id', unidade.id);
        }

        grupoService.findList(predicate)
            .then((response) => {
                setGrupos(response.data);
            }).finally(() => {
                setIsGlobalLoading(false);
            })
    }

    const fetchResponsavelSuporte = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('ativo', true);

        if (usuario?.perfil === PerfilEnum.FRANQUIA) {
            predicate.addOption('id', unidade.id);
        }

        unidadeService.findList(predicate)
            .then((response) => {
                setFranquias(response.data);
            }).finally(() => {
                setIsGlobalLoading(false);
            })
    }

    const fetchLead = () => {

        leadService.findFullById({ id: idLead })
            .then(
                ({ data }) => {
                    setLead(data);
                }
            );

    }

    useEffect(() => {
        fetchGrupos();
        fetchResponsavelSuporte();
        // fetchLead();
    }, [unidade, usuario]);

    return (
        <div id="escola-grupo-create-step-1" className="container">
            <Header />
            <Progress />
            <MenuLateralCreateComponent current={0} />
            <div className="franquia">
                <Form
                    form={form}
                    name="franquia-row"
                    onFinish={handleUnidade}
                    className="franquia-row"
                // initialValues={{cnpj: escolaParaCadastrar?.CpfCnpj}}
                >
                    <h5>Insira o nome e dados básicos da escola</h5>

                    <div className="input-franquia">
                        <span>CNPJ</span>
                        <Form.Item
                            name="cnpj"
                            initialValue={escolaParaCadastrar?.cnpj}
                            rules={[{ required: required, message: 'Insira o CNPJ' }]}>
                            <CpfCnpj
                                id="cnpj"
                                className="input"
                                type="tel"
                            />
                        </Form.Item>
                    </div>

                    <div className="input-franquia">
                        <span>Razão Social</span>
                        <Form.Item
                            name="razaoSocial"
                            initialValue={escolaParaCadastrar?.razaoSocial}
                            rules={[{ required: required, message: 'Insira a razão social' }]}>
                            <Input
                                id="razaoSocial"
                                className="input"
                            />
                        </Form.Item>
                    </div>


                    <div className="input-franquia">
                        <span>Nome fantasia</span>
                        <Form.Item
                            name="nomeFantasia"
                            initialValue={escolaParaCadastrar?.nomeFantasia}
                            rules={[{ required: required, message: 'Insira o nome fantasia da escola' }]}>
                            <Input
                                id="nomeFantasia"
                                className="input"
                            />
                        </Form.Item>
                    </div>


                    {/* <div className="input-franquia">
            <span>Razão Social</span>
            <Form.Item
              name="dsRazaoSocial"
              initialValue={escolaParaCadastrar?.dsRazaoSocial}
              rules={[{ required: required, message: 'Insira a razão social do grupo' }]}>
              <Input
                id="razaoSocial"
                className="input"
              />
            </Form.Item>
          </div> */}

                    <div className="select-franquia">
                        <span>Grupo mantenedor / Rede Educacional</span>

                        <Form.Item
                            name={['grupo', "id"]}
                            fieldKey={['grupo', "id"]}
                            initialValue={escolaParaCadastrar?.grupo?.id}
                            rules={[{ required: required, message: 'Selecione o grupo' }]}>

                            <Select
                                id="grupo"
                                placeholder="Selecione o grupo"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={
                                    (input, option) =>
                                        option.texto.toLowerCase().includes(input.toLowerCase())
                                }>
                                {grupos && grupos.map((item, index) => (
                                    <Select.Option key={index} value={item.id} texto={item.nomeFantasia}>{item.nomeFantasia}</Select.Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </div>

                    <div className="select-franquia">
                        <span>Quantidade de alunos</span>
                        <Form.Item
                            initialValue={escolaParaCadastrar?.categoriaQuantidadeAlunos?.id}
                            name={['categoriaQuantidadeAlunos', "id"]}
                            fieldKey={['categoriaQuantidadeAlunos', "id"]}
                            rules={[{ required: required, message: 'Selecione a quantidade' }]}>
                            <Select
                                id="categoriaQuantidadeAlunos"
                                placeholder="Selecione a quantidade"
                                allowClear
                            >
                                {categoriasQuantidadeAlunos && categoriasQuantidadeAlunos.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="select-franquia">
                        <span>Categoria confessional</span>
                        <Form.Item
                            name={['categoriaConfessional', "id"]}
                            fieldKey={['categoriaConfessional', "id"]}
                            initialValue={escolaParaCadastrar?.categoriaConfessional?.id}
                            rules={[{ required: required, message: 'Selecione a categoria' }]}>
                            <Select
                                id="categoriaConfessional"
                                placeholder="Selecione a categoria"
                                allowClear
                            >
                                {categoriasConfessionais && categoriasConfessionais.map((item, index) => (
                                    <Select.Option key={index} value={item.id} ke>{item.descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="select-franquia">
                        <span>Responsável pelo suporte</span>
                        <Form.Item
                            name={['unidade', "id"]}
                            fieldKey={['unidade', "id"]}
                            rules={[{ required: false, message: 'Selecione o responsável' }]}
                            initialValue={!escolaParaCadastrar?.unidade?.id ? null : escolaParaCadastrar?.unidade?.id}>
                            <Select
                                id="unidade"
                                placeholder="Selecione o responsável"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={
                                    (input, option) =>
                                        option?.texto?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {!unidade.id || usuario.perfil === PerfilEnum.SEDE ?
                                    <Select.Option key={-1} value={null}>SEDE</Select.Option>
                                    : null}
                                {franquias && franquias.map((item, index) => (
                                    (item.id === unidade.id || (!unidade.id && item.id) ?
                                        <Select.Option key={index} value={item.id} texto={item.descricao}>{item.descricao}</Select.Option>
                                        : null)
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <EscolaGruposFormActionComponent revisao={flRevisao} rotaAnterior={'/escolas/'} />
                </Form>
            </div >

            <EscolaGruposFormFecharComponent />
        </div >
    )
}

export default EscolaCreateStep1Screen;