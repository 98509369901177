import { SearchOutlined } from '@mui/icons-material';
import { Col, DatePicker, Input, Row, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import { ITurmaCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useTurmaService } from '../../../services/turma.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { formattedDate } from '../../../util/format';
import { hoje } from '../../../util/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Root {
	C01_MATRÍCULA: number;
	C02_ALUNO: string;
	C03_CURSO: string;
	C04_TURMA: string;
	C05_NOTA_PRIMEIRA_PROVA: number;
	C06_NOTA_SEGUNDA_PROVA: number;
	C07_NOTA_FINAL: number;
	C08_DATA_REGISTRO_PROVA: Date;
	CXX_RESPONSÁVEL: string;
	CXX_ID_CURSO: number;
	CXX_DT_INICIO: Date;
	CXX_DT_FIM: Date;
	CXX_ID_TURMA: number;
}

function RelRegistroAvaliacao() {
	const { unidade } = useGlobalContext();
	const service = useRelatorioService();
	const turmaService = useTurmaService();

	const [dados, setData] = useState<Root[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();

	const [dataRegistroProvaIni, setdataRegistroProvaIni] =
		useState<moment.Moment>();
	const [dataRegistroProvaFim, setdataRegistroProvaFim] =
		useState<moment.Moment>();

	const [turmas, setTurmas] = useState<ITurmaCleanDTO[]>();
	const [turmaId, setTurmaId] = useState<number>(0);

	const [search, setSearch] = useState<string>();

	const findTurma = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		return turmaService.findCleanList(predicate).then(({ data }) => {
			setTurmas(data);
		});
	};

	useEffect(() => {
		if (unidade?.id) {
			findTurma();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id, turmaId]);

	useEffect(() => {
		const predicate = new Predicate();

		if (unidade.id) {
			search && predicate.addOption('pessoaAlunoNm', search);

			predicate.addOption('unidadeId', unidade.id);
			turmaId && predicate.addOption('turmaId', turmaId);
			dataRegistroProvaIni &&
				predicate.addOption(
					'dataRegistroProvaIni',
					dataRegistroProvaIni.toISOString()
				);
			dataRegistroProvaFim &&
				predicate.addOption(
					'dataRegistroProvaFim',
					dataRegistroProvaFim.toISOString()
				);

			service.registroAvaliacao(predicate).then(({ data }) => {
				setData(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		unidade?.id,
		turmaId,
		dataRegistroProvaIni,
		dataRegistroProvaFim,
		search,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	const getPeriodo = (): any => {
		if (dataRegistroProvaIni && dataRegistroProvaFim) {
			return [
				moment(dataRegistroProvaIni, 'YYYY-MM-DD'),
				moment(dataRegistroProvaFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const selectTurmas = turmas?.map((turma) => (
		<Option key={turma.id} value={turma.id}>
			{turma.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 150, border: '1px solid gainsboro' };
	const dadosFiltrado = dados?.find((dados) => dados.CXX_ID_TURMA === turmaId);

	const dadosExportExcel = () => {
		const arrayDados: any[] = [];

		if (dados) {
			dados.map((dado) => {
				arrayDados.push({
					MATRÍCULA: dado['C01_MATRÍCULA'],
					ALUNO: dado.C02_ALUNO,
					CURSO: dado.C03_CURSO,
					TURMA: dado.C04_TURMA,
					'NOTA PRIMEIRA PROVA': dado.C05_NOTA_PRIMEIRA_PROVA,
					'NOTA SEGUNDA PROVA': dado.C06_NOTA_SEGUNDA_PROVA,
					'NOTA FINAL': dado.C07_NOTA_FINAL,
					'DATA REGISTRO PROVA': dado.C08_DATA_REGISTRO_PROVA,
				});
			});
		}

		const arrayNew = [...arrayDados];

		return arrayNew;
	};

	return (
		<div className="container" style={{ backgroundColor: '#fff' }}>
			<NewMenu selecionado={1} />

			<div>
				<header className="rel-header">
					<h1 className="mb-20">Registro de avaliação</h1>
					<p>
						<strong>Nome do curso:</strong> {dadosFiltrado?.C03_CURSO}
					</p>
					<p>
						<strong>Nome da turma:</strong> {dadosFiltrado?.C04_TURMA}
					</p>
					<div className="flex space-between">
						<p>
							<strong>Data de início:</strong>{' '}
							{formattedDate(dadosFiltrado?.CXX_DT_INICIO)}
						</p>
						<p>
							<strong>Data de fim:</strong>{' '}
							{formattedDate(dadosFiltrado?.CXX_DT_FIM)}
						</p>
					</div>
				</header>

				<div className="dashboard" style={{ top: 0 }}>
					<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
					<Row style={{ marginBottom: 10 }}>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Buscar</span>
							<br />
							<div
								className="input-text"
								style={{
									width: '90%',
									height: 32,
									border: '1px solid gainsboro',
								}}
							>
								<Input
									placeholder="Buscar"
									prefix={
										<SearchOutlined
											style={{ color: '#6A7C96', fontSize: 20 }}
										/>
									}
									onChange={(e) => setSearch(e.target.value)}
								/>
							</div>
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Turma</span>
							<br />
							<Select
								showSearch
								allowClear
								style={filtroStyle}
								defaultValue={turmaId}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								onChange={(e) => setTurmaId(e)}
							>
								{/* <Option value={0}>Todos</Option> */}
								{selectTurmas}
							</Select>
						</Col>
						<Col flex={2} style={{ paddingRight: '1em' }}>
							<span style={{ fontSize: '0.8em' }}>
								Data do registro da prova
							</span>
							<br />
							<RangePicker
								value={getPeriodo()}
								onChange={(values) => {
									setdataRegistroProvaIni(values && values[0]);
									setdataRegistroProvaFim(values && values[1]);
								}}
								format={'DD-MM-YYYY'}
							/>
							{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Excel</span>
							<br />
							<ExportToExcel
								apiData={dadosExportExcel()}
								fileName={`Frequência de avaliação ` + hoje().toISOString()}
							/>
						</Col>
					</Row>
					<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
				</div>
			</div>
		</div>
	);
}

export default RelRegistroAvaliacao;
