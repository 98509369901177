import { AxiosResponse } from "axios";
import http from "../helpers/http-common";
import { IDmIndicadorCleanDTO, IDmIndicadorDTO, IDmIndicadorFullDTO, IDmIndicadorTotalDTO } from "../models/happy-code-api.model";
import { baseFindList } from "./base/base-find-list.service";

export const useDmIndicadorService = () => {
  const resourceURL = 'dmindicador';

  const findIndicadoresPorUnidade = (idUnidade: number): Promise<AxiosResponse<IDmIndicadorTotalDTO[]>> => {
    return http.get(`${resourceURL}/unidade/${idUnidade}`);
  };

  return {
    ...baseFindList<IDmIndicadorCleanDTO, IDmIndicadorDTO, IDmIndicadorFullDTO>(resourceURL),
    findIndicadoresPorUnidade,
  };
}