import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ITurmaCleanDTO,
	ITurmaCrossSellDTO,
	ITurmaCustomDTO,
	ITurmaDTO,
	ITurmaFullDTO,
	ITurmaGradeSemanalDTO,
	ITurmaKpiDTO,
	ITurmaKpiGraficoTurmaDTO
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useTurmaService = () => {
	const resourceURL = 'turma';

	const getKpi = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<ITurmaKpiDTO>> => {
		return http.get(`${resourceURL}/kpi`, formatHttpParams(predicate));
	};

	const findCrossSelling = (dto: ITurmaCrossSellDTO): Promise<AxiosResponse<ITurmaDTO[]>> => {
		return http.post(`${resourceURL}/cross-sell`, dto)
	}

	const findGraficoTurmasAtivaPorCurso = (
		unidadeId: number,
	): Promise<AxiosResponse<ITurmaKpiGraficoTurmaDTO[]>> =>
		http.get<ITurmaKpiGraficoTurmaDTO[]>(resourceURL + `/dash-turmas-ativa-curso/${unidadeId}`);

	const findTurmaSemana = (horaInicio?: string, unidadeId?: number, predicate: Predicate = new Predicate()): Promise<AxiosResponse<ITurmaCustomDTO>> =>
		http.get(resourceURL + `/custom-turma-semana?unidadeId=${unidadeId}`, formatHttpParams(predicate))

	const findGradeTurmaSemanal = (predicate: Predicate = new Predicate()): Promise<AxiosResponse<ITurmaGradeSemanalDTO>> =>
		http.get(resourceURL + `/turma-grade-semanal`, formatHttpParams(predicate))

	return {
		...baseFind<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseFindList<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseFindById<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseCreate<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		...baseUpdate<ITurmaCleanDTO, ITurmaDTO, ITurmaFullDTO>(resourceURL),
		getKpi,
		findGraficoTurmasAtivaPorCurso,
		findTurmaSemana,
		findCrossSelling,
		findGradeTurmaSemanal
	};
};
