export enum FuncaoEnum {
  ADMINISTRADOR_CODIGO = 'ADM',
  GESTAO_ACADEMICA_CODIGO = 'GSA',
  GESTAO_FINANCEIRO_CODIGO = 'GSF',
  INSTRUTOR_CODIGO = 'IST',
  TECNOLOGIA_CODIGO = 'TEC',

  ID_ADMINISTRADOR = '1',
  ID_GESTAO_ACADEMICA = '2',
  ID_GESTAO_FINANCEIRO = '3',
  ID_INSTRUTOR = '4',
  ID_TECNOLOGIA = 5,
}