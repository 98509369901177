import { Button, Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEscolaContext } from '../../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { useUnidadeContext } from '../../../../../context/UnidadeContext';
import { IEstadoCleanDTO, IPaisCleanDTO } from '../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../models/predicate.model';
import { useEstadoService } from '../../../../../services/estado.service';
import { useLogradouroService } from '../../../../../services/logradouro.service';
import { useNotificationService } from '../../../../../services/notification.service';
import { usePaisService } from '../../../../../services/pais.service';
import '../../../shared.scss';
import BotoesCadastroComponent from '../../BotoesCadastro/botoes-cadastro.component';
import StepCadastroComponent from '../../StepCadastro/step-cadastro.component';
import './novo-lead-step-3.scss';
import useNovoLeadStep3ComponentState from './novo-lead-step-3.state';

interface Props {
  onClose?: () => void;
  onNext?: () => void;
  onFecharModal?: () => void;
}

export const NovoLeadStep3 = (props: Props) => {

  const { state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
  } = useNovoLeadStep3ComponentState();

  const [form] = Form.useForm();
  const { unidade, setUnidade, revisao } = useUnidadeContext();



  const { escolaParaCadastrar, setEscolaParaCadastrar, flRevisao, setFlRevisao } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [paisList, setPaisList] = useState<IPaisCleanDTO[]>();
  const [listEstado, setListEstado] = useState<IEstadoCleanDTO[]>();

  const notification = useNotificationService();

  const history = useHistory();
  const paisService = usePaisService();
  const estadoService = useEstadoService();
  const logradouroService = useLogradouroService();

  const required = true;


  const onCepChange = (cep: string) => {
    if (cep.length !== 8) {
      return;
    }
    setIsGlobalLoading(true);
    const predicateLogradouro = new Predicate(10);
    predicateLogradouro.addOption('cep', cep);
    logradouroService.findCustom(predicateLogradouro)
      .then(({ data }) => {
        if (data && data.length > 0) {
          const logradouro = data[0];
          const { endereco } = unidade;
          endereco.cep = logradouro.cep;
          endereco.bairro = logradouro.bairroNome;
          endereco.cidade = logradouro.cidadeNome;
          endereco.logradouro = logradouro.nomeCompleto;
          endereco.nomeUF = logradouro.cidadeEstadoNome;
          endereco.paisId = logradouro.cidadeEstadoPaisId;

          form.setFieldsValue(endereco);
        } else {
          notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
        }
      })
      .finally(() => setIsGlobalLoading(false));
  }

  const findPais = () => {
    const predicate = new Predicate();
    return paisService.findCleanList(predicate)
      .then(({ data }) => {
        setPaisList(data);
      })
  }

  const findEstados = () => {
    const predicateEstado = new Predicate(100);
    return estadoService.findClean(predicateEstado).then(({ data }) => {
      setListEstado(data.content);
    })
  }

  useEffect(() => {
    if (escolaParaCadastrar?.endereco) {
      const { endereco } = escolaParaCadastrar;
      form.setFieldsValue(endereco);
    }
  }, [form, escolaParaCadastrar]);

  useEffect(() => {
    setIsGlobalLoading(true);
    Promise.all([
      findPais(),
      findEstados(),
    ]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnidade = (franquiaCrud) => {
    franquiaCrud['uf'] = listEstado.find((estado) => estado.nome == franquiaCrud['nomeUF'])?.codigo;
    const contextState = { ...escolaParaCadastrar, endereco: franquiaCrud };
    setEscolaParaCadastrar(contextState);

    if (flRevisao) {
      setFlRevisao(false);
      return history.replace("/escolas/create/step-4");
    }

    history.push("/escolas/create/step-3");
  };


  return (
    <>
      <div id="novo-lead-step-1" className="flex flex-row justify-center align-center modal-container modalBox">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onFecharModal} className="button-x">x</Button>
          <div className='container-titulo'>
            <h1>Cadastrar Lead</h1>
          </div>
          <h3>Contatos</h3>
          <Form
            style={{ minWidth: '100%' }}
            name="control-hooks"
            initialValues={leadParaCadastrar}
            form={form}
            onFinish={onFinish}
          >

            <div className="col">
              <div className="flex width-100">
                <div className="inputModal">
                  <span>Cargo<span className="vermelho"></span></span>
                  <Form.Item name={'cargoContato'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o cargo' }]}>
                    <Input id="cargoContato" />
                  </Form.Item>
                </div>
              </div>

              <div className="flex width-100">
                <div className="inputModal">
                  <span>Nome<span className="vermelho">*</span></span>
                  <Form.Item name={'nomeContato'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o nome do contato' }]}>
                    <Input id="nomeContato" />
                  </Form.Item>
                </div>
              </div>

              <div className="row width-100">
                <div className="inputModal" style={{ width: '20%' }}>
                  <span>DDD<span className="vermelho">*</span></span>
                  <Form.Item name={'dddTelefoneContato'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o DDD' }]}>
                    <MaskedInput mask="11"
                      placeholder='DDD' bordered={false} />
                  </Form.Item>
                </div>
                <div className="horizontal-separator" />
                <div className="inputModal" style={{ width: '80%' }}>
                  <span>Telefone<span className="vermelho">*</span></span>
                  <Form.Item name={'telefoneContato'} style={{ width: '100%' }} rules={[{ required: true, message: 'Informe o Telefone' }]}>
                    <MaskedInput mask="11111-1111"
                      placeholder='Telefone' bordered={false} />
                  </Form.Item>
                  <div />
                </div>
              </div>

              <div className="flex width-100">
                <div className="inputModal">

                  <span>E-mail<span className="vermelho"></span></span>
                  <Form.Item name={'emailContato'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o e-mail' }]}>
                    <Input id="emailContato" />
                  </Form.Item>
                </div>
              </div>

              <div className="horizontal-separator" />

            </div>

            <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
            </div>
            <div className="vertical-separator" />
            <StepCadastroComponent />
            <BotoesCadastroComponent />
          </Form>
        </div>

        {/* <ModalResposta
          open={isModalSucessoOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link="crm"
        />
        <ModalResposta
          open={isModalErroOpen}
          onClose={onClose1}
          status="Ocorreu um erro!"
          text="Ocorreu um erro ao salvar o lead, por favor, tente novamente."
          link="crm"
        /> */}

      </div >
    </>
  )
}
