import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu } from 'antd';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO } from "../../models/happy-code-api.model";
import { useCurriculoService } from '../../services/curriculo.service';

interface TItemLista {
    curriculo: ICurriculoDTO;
    handleCurriculo: (curriculo: ICurriculoCleanDTO, state: boolean) => void;
}

const ItemListCurriculo = memo(({ curriculo, handleCurriculo }: TItemLista) => {

    const curriculoService = useCurriculoService();
    const [curriculoFull, setCurriculoFull] = useState<ICurriculoFullDTO>();
    const { setIsGlobalLoading } = useGlobalContext();

    const handleMenuItem = (curriculo: ICurriculoCleanDTO) => {
        if (curriculo.ativo) {
            return (
                <Menu.Item onClick={() => handleCurriculo(curriculo, false)}>
                    <a rel="noopener noreferrer">
                        Inativar Curriculo
                    </a>
                </Menu.Item>
            )
        }
        if (!curriculo.ativo) {
            return (
                <Menu.Item onClick={() => handleCurriculo(curriculo, true)}>
                    <a rel="noopener noreferrer">
                        Ativar Curriculo
                    </a>
                </Menu.Item>
            )
        }
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            {handleMenuItem(curriculo)}
        </Menu>
    );
    const history = useHistory();

    

    const goTo = () => {
        setIsGlobalLoading(true);
        curriculoService.findFullById({id: curriculo.id})
            .then(({ data }) => setCurriculoFull(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/curriculo/create/step-1/${curriculo.id}`)
            });
    }

    const handleStatus = (curriculo) => {       

        if (curriculo.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Ativo</p>
                </div>
            )
        }

        if (!curriculo.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className="standby">Inativo</p>
                </div>
            )
        }
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '30%' }}>
                <div className="nome">
                    <p>{curriculo.descricaoCurriculo}</p>
                    {handleStatus(curriculo)}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{curriculo.id}</span>
            </div>  
            <div className="funcao-user" id="campanhas" style={{ width: '15%' }}>
                <span>{curriculo.faixaEtariaIni + " - " + curriculo.faixaEtariaFim}</span>
            </div>
            <div className="funcao-user" id="cursos-modalidade" style={{ width: '15%' }}>
                <p>{curriculo.duracaoTotal}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '25%' }}>
                <p>{curriculo?.negocio?.descricao}</p>
            </div>         
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListCurriculo.displayName = 'ItemListCurriculo';

export default ItemListCurriculo;