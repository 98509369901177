import { intervalToDuration } from 'date-fns';
import moment from 'moment';

const calculateTimeBetweenDates = (firstDate: string, secondDate: string) => {
	const date1 = moment(firstDate).toDate();
	const date2 = moment(secondDate).toDate();

	return intervalToDuration({
		start: date2,
		end: date1,
	});
};

export const buildTimeInterval = (
	firstDate: string,
	secondDate: string
): string => {
	if (!firstDate || !secondDate) return '0 ano; 0 mês';

	const { days, months, years } = calculateTimeBetweenDates(
		firstDate,
		secondDate
	);

	if (years > 0) return `${years} ano(s); ${months} mês(es); ${days} dia(s)`;

	if (months > 0) return `${months} mês(es); ${days} dia(s)`;

	if (days >= 0) return `0 mês; ${days} dia(s)`;

	return '0 anos; 0 meses;';
};
