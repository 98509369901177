import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Space, Steps } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import fondo from '../../assets/img/matriculas/fondo.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { IPessoaCustomDTO } from '../../models/happy-code-api.model';
import { usePessoaService } from '../../services/pessoa.service';
import { Predicate } from './../../models/predicate.model';
import { MatriculaProgresso } from './progresso';
import './style.css';

export const MatriculasCreateStep2: React.FunctionComponent = () => {
  const [listPessoa, setListPessoa] = useState<IPessoaCustomDTO[]>([]);

  const { Step } = Steps;
  const [isOpen, setIsOpen] = useState(false);
  const [isResponsavelOpen, setIsResponsavelOpen] = useState(false);
  const [pessoaSelected, setPessoaSelected] = useState<IPessoaCustomDTO>(null);
  const [cpf, setCpf] = useState<string>();
  const pessoaService = usePessoaService();
  const [pessoaPredicate] = useState<Predicate>(new Predicate());
  const { setIsGlobalLoading } = useGlobalContext();
  const history = useHistory();
  const { matricula, setMatricula } = useAlunoContext();

  const findPessoa = () => {
    pessoaPredicate.size = 3;
    pessoaPredicate.addOption('tutelados.pessoa.id', matricula?.aluno?.pessoa?.id);
    return pessoaService.findCustom(pessoaPredicate)
      .then((response) => {
        setListPessoa(response.data.content);
      })
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    findPessoa().finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (cpf !== undefined) {
        setIsGlobalLoading(true);
        pessoaPredicate.addOption('cpf', cpf);
        findPessoa().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf]);

  const handleSubmit = () => {
    if (pessoaSelected) {
      matricula.responsavelFinanceiro = pessoaSelected;
      setMatricula({ ...matricula });
      history.push("/matriculas/create/curso");
    }
  }

  return (
    <div className="container-fondo">
      <Header />
      <MatriculaProgresso step={2} percent={32} />
      <div className="franquia">        
        <div className="user-row" id="row-aluno">
          <h3>Busque e selecione o responsável financeiro desta matrícula</h3>
          <div className="filtros" style={{ width: '100%', marginBottom: 16 }}>
            <div className="input-text" style={{ width: '100%' }}>
              <Input onChange={(e) => setCpf(e.target.value)} placeholder="Buscar pelo CPF" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
            </div>
            {/* <div className="filtro-estado" id="input-dashboard">
              <span>Periodo</span>
              <Dropdown overlay={menu}>
                <a href="/" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  Todos<DownOutlined style={{ marginLeft: 15 }} />
                </a>
              </Dropdown>
            </div> */}
          </div>
          <div className="matriculas" style={{ width: '100%' }}>
            {/* <Button onClick={() => setIsResponsavelOpen(true)} className="button-second" icon={<PlusOutlined rev={undefined} />} style={{ width: '100%' }}>Cadastrar novo responsável</Button> */}
            <Radio.Group onChange={(e) => setPessoaSelected(e.target.value)} className="radio-group" name="radiogroup">
              <Space direction="vertical">
                {listPessoa && listPessoa.map((pessoa, index) => (
                  <div key={index} className={pessoaSelected && pessoaSelected.id === pessoa.id ? 'box-responsavel-selected' : 'box-responsavel'}>
                    <Radio value={pessoa} />
                    <div className="dados">
                      <h4>{pessoa?.nome}</h4>
                      <div className="funcao">
                        <p>Responsável Principal</p>
                      </div>
                      <h6>Ver mais detalhes</h6>
                    </div>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </div>
          <div className="botoes">
            <Link to="/matriculas/create/curso">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <Button onClick={() => handleSubmit()} className="button-primary" style={{ width: 345 }}>Próximo</Button>
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a adição desta Nova Matricula?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/alunos'
      />
      <img src={fondo} alt="" className="img-fondo" />
    </div>
  );
}