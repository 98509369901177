import { Col, Row, Table } from 'antd';
import { useState } from 'react';
import MsgInfoSistema from '../MsgInfoSistema';
import info from '../../assets/img/financeiro-info/info.svg';
import "./style.css";

const listaServico = ["Contratação de treinamento e formação da equipe INTRA", "Contratação de treinamento e formação da equipe EXTRA", "Contratação de treinamento e formação da equipe AMBOS", "Contratação de instrutor da Happy INTRA", "Contratação de instrutor da Happy EXTRA", "Contratação de instrutor da Happy AMBOS"]

const dataSourceIntra = [
    {
        key: '1',
        de: 0,
        ponteiro: <span className="seta" />,
        ate: 200,
        valorLicenca: "R$ 14,90",
    },
    {
        key: '1',
        de: 201,
        ponteiro: <span className="seta" />,
        ate: 400,
        valorLicenca: "R$ 12,90",
    },
    {
        key: '1',
        de: 401,
        ponteiro: <span className="seta" />,
        ate: "Máximo",
        valorLicenca: "R$ 10,90",
    },
];

const columnsPrecificacao = [
    {
        title: 'De',
        dataIndex: 'de',
        key: 'de',
    },
    {
        title: 'Quantidade Licença',
        dataIndex: 'ponteiro',
        key: 'ponteiro',
    },
    {
        title: 'Até',
        dataIndex: 'ate',
        key: 'ate',
    },
    {
        title: 'Valor Licença',
        dataIndex: 'valorLicenca',
        key: 'valorLicenca',
    },
];

const dataSourceExtra = [
    {
        key: '1',
        de: 0,
        ponteiro: <span className="seta" />,
        ate: "Máximo",
        valorLicenca: "R$ 39,90",
    },
];

const dataSourceServico = [
    {
        key: '1',
        de: 0,
        ponteiro: <span className="seta" />,
        ate: "Máximo",
        valorLicenca: "R$ 25,00",
    },
];

const TabelaPrecificacaoHub = () => {
    const [hideDisplay, setHideDisplay] = useState(false);

    return (
        <Row>
            <Col>
                <h1>Licença Intra</h1>
                <Table style={{ width: 400, marginBottom: 100, marginRight: 30 }} pagination={false} dataSource={dataSourceIntra} columns={columnsPrecificacao} />
            </Col>
            <Col>
                <h1>Licença Extra</h1>
                <Table style={{ width: 400, marginBottom: 100, marginRight: 30 }} pagination={false} dataSource={dataSourceExtra} columns={columnsPrecificacao} />
            </Col>
            <Col>
                <h1>Licença de Serviço  <img className="config-icons-financeiro" src={info}
                    onClick={(e) => setHideDisplay(!hideDisplay)}
                />
                </h1>

                {hideDisplay && (
                    <MsgInfoSistema
                        setHideDisplay={setHideDisplay}
                        hideDisplay={!hideDisplay}
                        icon={info}
                        title={"Licença Acadêmica"}
                        descricaoOne={"Licença de serviço acadêmico vai ser contabilizada caso seja inserido o tipo de serviço e tenha valor."}
                        observacao={"O serviço só existe caso no contrato tiver licença intra ou extra, o serviço não é obrigatório podendo o contrato ter licença intra ou extra e não ter o serviço."}
                        listTitle={"Lista de Serviços"}
                        listItem={listaServico}
                    />
                )}
                <Table style={{ width: 400, marginBottom: 100 }} pagination={false} dataSource={dataSourceServico} columns={columnsPrecificacao} />
            </Col>
        </Row>
    );
}

export default TabelaPrecificacaoHub;