import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { useGlobalContext } from '../../context/GlobalContext';
import { useNotificationService } from '../../services/notification.service';
import { useUnidadeTipoPagamentoService } from '../../services/unidade-tipo-pagamento';
import { IUnidadeTipoPagamentoDTO } from './../../models/happy-code-api.model';
import './style.css';

interface Props {
    unidadeTipoPagamento: IUnidadeTipoPagamentoDTO;
    findUnidade: () => void;
}

const DropdownFranquia: React.FunctionComponent<Props> = ({ unidadeTipoPagamento, findUnidade }: Props) => {

    const notification = useNotificationService();
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const unidadeTipoPagamentoService = useUnidadeTipoPagamentoService();

    const menu = (unidadeTipoPagamento: IUnidadeTipoPagamentoDTO) => {

        const showDeleteConfirm = (unidadeTipoPagamento: IUnidadeTipoPagamentoDTO) => {
            Modal.confirm({
                title: `Deseja realmente ${unidadeTipoPagamento.ativo ? 'desativar' : 'ativar'} o tipo de pagamento?`,
                icon: <ExclamationCircleTwoTone rev={undefined} />,
                content: "Nome: " + unidadeTipoPagamento?.tipoPagamento.descricao,
                okText: 'Yes',
                okType: `${unidadeTipoPagamento.ativo ? 'danger' : 'primary'}`,
                cancelText: 'Não',
                onOk: () => {
                    unidadeTipoPagamentoService.patch({ id: unidadeTipoPagamento.id, ativo: !unidadeTipoPagamento.ativo })
                        .then(() => notification({ description: 'Unidade alterada com sucesso!', type: 'success', message: 'Sucesso' }))
                        .finally(() => { setIsGlobalLoading(false), findUnidade() });
                },
            });
        }

        return (
            <Menu>
                <Menu.Item >
                    <a onClick={(e) => showDeleteConfirm(unidadeTipoPagamento)} rel="noopener noreferrer">
                        Desativar/Ativar tipo pagamento
                    </a>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="registros-row" >
                <div className="registro" style={{ width: "25%" }}>
                    <span>{unidadeTipoPagamento?.unidade?.descricao}</span>
                </div>
                <div className="registro" style={{ width: "25%" }}>
                    <span>{unidadeTipoPagamento?.tipoPagamento.descricao}</span>
                </div>
                <div className="registro" style={{ width: "25%" }}>
                    <span>{unidadeTipoPagamento?.tipoCobranca.descricao}</span>
                </div>
                <div className="registro" style={{ width: "25%" }}>
                    <span>{unidadeTipoPagamento?.ativo === true ? "Ativo" : "Inativo"}</span>
                </div>
                <div className="funcao-user" style={{ width: '3%' }}>
                    <Dropdown overlay={menu(unidadeTipoPagamento)} placement="bottomRight" arrow>
                        <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                    </Dropdown>
                </div>
            </div>
        </div>
    )

}

export default DropdownFranquia;