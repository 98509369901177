import { Button } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';


import { INegocioUnidadeDTO } from '../../models/happy-code-api.model';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { useNotificationService } from '../../services/notification.service';
import './modal-oferta.css';

interface Props {
    open: boolean;
    // eslint-disable-next-line
    onClose: (state) => void;
    negocioUnidade?: INegocioUnidadeDTO;
}

export const ModalOferta: React.FunctionComponent<Props> = ({ open, negocioUnidade, onClose }: Props) => {
    const [valor, setValor] = useState<number>();
    const [valorRematricula, setValorRematricula] = useState<number>();
    const { turma } = useTurmaContext();
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const negocioUnidadeService = useNegocioUnidadeService();
    const notification = useNotificationService();
    
    const onChangeValorOferta = (value) => {
        setValor(value.floatValue);
    }

    const onChangeValorOfertaRematricula = (value) => {
        setValorRematricula(value.floatValue);
    }

    useEffect(() => {
        setValor(negocioUnidade?.valorMinimo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = () => {
        if (valor >= negocioUnidade?.valorMinimo) {
            if (negocioUnidade && negocioUnidade.id) {
                negocioUnidade.valorOferta = valor;
                negocioUnidade.valorOfertaRematricula = valorRematricula;
                setIsGlobalLoading(true);
                negocioUnidadeService.patchCustom(negocioUnidade.id, negocioUnidade)
                    .then(() => onClose(false)).finally(() => setIsGlobalLoading(false));
            } 
        } else {
            notification({ description: 'Atenção', type: 'warning', message: 'O Valor de oferta deve ser maior que o valor mínimo do curso' });
        }
    }   
    
    const valorSugerido = () => {
        return unidade.capital ? negocioUnidade.valorSugeridoCapital : negocioUnidade.valorSugerido;
    }

    if (!open) return null;

    return (
        <div className="modalContainer">
            <div className="modal-user-franquia">
                <h3>Edição de oferta - {turma?.curriculoNegocioDescricao}</h3>
                <div className="form-modal-oferta">
                    <div className="input-box disabled inputMoneyCustom">
                        <p>Valor mínimo do curso para Matrícula</p>
                        <CurrencyFormat disabled={true} value={negocioUnidade?.valorMinimo} isNumericString={true} decimalScale={2} thousandSeparator={true} prefix={'R$'} />
                        <p>Valor sugerido</p>
                        <CurrencyFormat disabled={true} value={valorSugerido()} isNumericString={true} decimalScale={2} thousandSeparator={true} prefix={'R$'} />
                    </div>
                    <div className="input-box inputMoneyCustom">
                        <p>Valor de oferta matrícula</p>
                        <CurrencyFormat value={valor} isNumericString={true} decimalScale={2} onValueChange={onChangeValorOferta} thousandSeparator={true} prefix={'R$'} />
                    </div>
                </div>
                <div className='line'></div>
                <div className="form-modal-oferta">
                    <div className="input-box disabled inputMoneyCustom">
                        <p>Valor mínimo do curso para Rematrícula</p>
                        <CurrencyFormat disabled={true} value={negocioUnidade?.valorMinimoRematricula} isNumericString={true} decimalScale={2} thousandSeparator={true} prefix={'R$'} />
                        <p>Valor sugerido</p>
                        <CurrencyFormat disabled={true} value={valorSugerido()} isNumericString={true} decimalScale={2} thousandSeparator={true} prefix={'R$'} />
                    </div>
                    <div className="input-box inputMoneyCustom">
                        <p>Valor de oferta rematrícula</p>
                        <CurrencyFormat value={valorRematricula} isNumericString={true} decimalScale={2} onValueChange={onChangeValorOfertaRematricula} thousandSeparator={true} prefix={'R$'} />
                    </div>
                </div>
                <div className="botoes">
                    <Button
                        id="sim"
                        className="button-line"
                        onClick={() => onClose(false)}
                        style={{ marginLeft: 8, minWidth: 145 }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        id="sim"
                        className="button-primary"
                        onClick={() => handleSubmit()}
                        style={{ marginLeft: 8, minWidth: 145 }}
                    >
                        Salvar alterações
                    </Button>
                </div>
            </div>
        </div>
    )
}