import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress } from 'antd';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { MenuDetalheTaxa } from '../../components/MenuDetalheTaxa/index';
import { useTaxaFixaContext } from '../../context/TaxaContext';

import { formatMoney, formattedDate } from '../../util/format';
import './style.css';

const TaxasCreateStep6: React.FunctionComponent = () => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = useParams<any>();

  const { taxaFixa, setRevisao } = useTaxaFixaContext();

  const history = useHistory();
  const goTo = (url) => history.push(url);

  useEffect(() => {
    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
      </div>
      {/* <MenuPrincipal selecionado="6" /> */}
      <MenuDetalheTaxa id={id || taxaFixa.id} />
      <div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
        {taxaFixa &&
          <div className="franquia-row">
            <h5>Revisão de Dados</h5>
            <div className="row-revisao">
              {/* <ul className="menu-revisao-detalhe">
              <li className="selected" style={{ width: '34%' }}>
                Dados Básicos
              </li>
              <li style={{ width: '33%' }}>
                Período de vigência
              </li>
              <li style={{ width: '33%' }}>
                Franquias
              </li>
            </ul> */}
              <div className="box-revisao">
                <div className="dados">
                  <Button id="editar-step-1" className="button-line" onClick={() => goTo("/taxas/create/step-1")}>Editar</Button>
                  <h4>Dados Básicos</h4>
                  <div className="nome">
                    <h5>Nome</h5>
                    <span style={{ fontSize: 18 }}>{taxaFixa.codigo}</span>
                  </div>
                  <div className="dados-franquia">
                    <div className="cpf" style={{ marginRight: 0 }}>
                      <h6>DESCRIÇÃO</h6>
                      <span>{taxaFixa.descricao}</span>
                    </div>
                  </div>
                </div>
                <div className="line" style={{ marginBottom: 20 }}></div>
                <div className="dados">
                  <Button id="editar-step-2" className="button-line" onClick={() => goTo("/taxas/create/step-2")}>Editar</Button>
                  <h4>Período de vigência</h4>
                  <div id="data-info">
                    <div className="box-data">
                      <h5>Inicio</h5>
                      <p>{formattedDate(taxaFixa.dataInicial)}</p>
                    </div>
                    <Unicons.UilArrowRight size="20" color="#6A7C96" />
                    <div className="box-data" style={{ marginLeft: 10 }}>
                      <h5>Fim</h5>
                      <p>{formattedDate(taxaFixa.dataFinal)}</p>
                    </div>
                  </div>
                </div>
                <div className="line" style={{ marginBottom: 20 }}></div>
                <div className="dados">
                  <Button id="editar-step-3" className="button-line" onClick={() => goTo("/taxas/create/step-3")}>Editar</Button>
                  <h4>Preço</h4>
                  <div id="price-info">
                    <p>{formatMoney(taxaFixa.valor)}</p>
                  </div>
                </div>
                <div className="line" style={{ marginBottom: 20 }}></div>
                <div className="dados">
                  <Button id="editar-step-4" className="button-line" onClick={() => goTo("/taxas/create/step-4")}>Editar</Button>
                  {taxaFixa.taxaPaises.length > 0 ?
                    <>
                      <h4>Paises</h4>
                      {taxaFixa.taxaPaises.map((taxaFixaPais) =>
                        <div id="user-franquias-revisao" key={taxaFixaPais.id}>
                          <h4>{taxaFixaPais.paisNome}</h4>
                        </div>)}
                    </>
                    :
                    taxaFixa.taxaClusters.length > 0 ?
                      <>
                        <h4>Clusters</h4>
                        {taxaFixa.taxaClusters.map((taxaFixaCluster) =>
                          <div id="user-franquias-revisao" key={taxaFixaCluster.id}>
                            <h4>{taxaFixaCluster.clusterCodigo}</h4>
                          </div>)}
                      </>
                      : taxaFixa.taxaUnidades.length > 0 &&
                      <>
                        <h4>Franquias</h4>
                        {taxaFixa.taxaUnidades.map((taxaFixaUnidade) =>
                          <div id="user-franquias-revisao" key={taxaFixaUnidade.id}>
                            <h4>{taxaFixaUnidade.unidadeDescricao}</h4>
                          </div>)}
                      </>
                  }
                </div>
              </div>
            </div>
            {/* <div className="botoes" style={{ paddingBottom: 30 }}>
            <Link to="/usuarios/create/step-6">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <Link to="/usuarios/create/step-8">
              <Button className="button-primary" style={{ width: 345 }}>Próximo</Button>
            </Link>
          </div> */}
          </div>}
      </div>
      <div className="botao-voltar-revisao">
        <Button id="voltar" className="button-second" icon={<ArrowLeftOutlined rev={undefined} />} onClick={() => history.goBack()}>
          Voltar
        </Button>
      </div>
    </div>
  )
}

export default TaxasCreateStep6;