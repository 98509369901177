import {
	ArrowLeftOutlined,
	IdcardOutlined,
	SmileOutlined,
	UserOutlined
} from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Input, Radio, Select, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import MenuDetalheUser from '../../components/MenuDetalheUser';
import { useUsuarioContext } from '../../context/UsuarioContext';

import './style.css';

const FranquiaCreateStep8: React.FunctionComponent = () => {
	const { usuario } = useUsuarioContext();

	const options = [
		{ value: 'franquia-1' },
		{ value: 'franquia-2' },
		{ value: 'franquia-3' },
		{ value: 'franquia-4' },
	];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function tagRender(props: any) {
		const { label, closable, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color="default"
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		);
	}

	return (
		<div className="container-fondo">
			<Header />
			<Menu selecionado="6" />
			<MenuDetalheUser />
			<div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
				<div className="franquia-row">
					<h5>Revisão de Dados</h5>
					<div className="row-revisao">
						<ul className="menu-revisao-detalhe">
							<li className="selected" style={{ width: '34%' }}>
								Dados Básicos
							</li>
							<li style={{ width: '33%' }}>Franquias</li>
							<li style={{ width: '33%' }}>Controle</li>
						</ul>
						<div className="box-revisao">
							<div className="dados">
								<Button className="button-line" style={{ maxWidth: 75 }}>
									Editar
								</Button>
								<h4>Dados Básicos</h4>
								<div className="input-box">
									<p>Nome Completo</p>
									<Input
										placeholder="Maria Silva Oliveira Gonçalves"
										className="input"
										prefix={<UserOutlined rev={undefined} />}
										value={usuario.pessoa.nome || 'N/A'}
									/>
								</div>
								<div
									className="dados-franquia"
									style={{ justifyContent: 'space-between' }}
								>
									<div className="input-box" style={{ width: '48%' }}>
										<p>E-mail profissional</p>
										<Input
											placeholder="email@gmail.com"
											className="input"
											prefix={<IdcardOutlined rev={undefined} />}
											value={usuario.pessoa.email || 'N/A'}
										/>
									</div>
									<div className="input-box" style={{ width: '48%' }}>
										<p>E-mail pessoal</p>
										<Input
											placeholder="email@gmail.com"
											className="input"
											prefix={<SmileOutlined rev={undefined} />}
											value={usuario.pessoa.email || 'N/A'}
										/>
									</div>
								</div>
								<div className="botoes" style={{ paddingBottom: 30 }}>
									<Link to="/usuarios/create/step-6">
										<Button className="button-second" style={{ width: 320 }}>
											Cancelar
										</Button>
									</Link>
									<Link to="/usuarios/create/step-8">
										<Button className="button-primary" style={{ width: 320 }}>
											Salvar Alterações
										</Button>
									</Link>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<Button className="button-line" style={{ width: 75 }}>
									Editar
								</Button>
								<h4>Franquias</h4>
								<div id="user-franquias-revisao">
									<div className="box-select">
										<Select
											defaultValue="franquia-1"
											mode="multiple"
											showArrow
											tagRender={tagRender}
											style={{ width: '100%' }}
											options={options}
										/>
									</div>
								</div>
								<div className="botoes" style={{ paddingBottom: 30 }}>
									<Link to="/usuarios/create/step-6">
										<Button className="button-second" style={{ width: 320 }}>
											Cancelar
										</Button>
									</Link>
									<Link to="/usuarios/create/step-8">
										<Button className="button-primary" style={{ width: 320 }}>
											Salvar Alterações
										</Button>
									</Link>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<Button className="button-line">Editar</Button>
								<h4>Controle</h4>
								<div className="boxes-radios">
									<div className="box-radios">
										<div className="check-select">
											<Unicons.UilAngleRight size="20" color="#353F49" />
											<Checkbox />
											<h5>Módulo 1</h5>
											<Unicons.UilInfoCircle size="20" color="#92A9CB" />
										</div>
										<div className="check-radios">
											<Radio.Group name="radiogroup" defaultValue={1}>
												<Space direction="vertical">
													<Radio value={1}>Edição e Leitura</Radio>
													<Radio value={2}>Somente Leitura</Radio>
												</Space>
											</Radio.Group>
										</div>
									</div>
									<div className="box-radios">
										<div className="check-select">
											<Unicons.UilAngleRight size="20" color="#353F49" />
											<Checkbox />
											<h5>Módulo 1</h5>
											<Unicons.UilInfoCircle size="20" color="#92A9CB" />
										</div>
										<div className="check-radios">
											<Radio.Group name="radiogroup" defaultValue={1}>
												<Space direction="vertical">
													<Radio value={1}>Edição e Leitura</Radio>
													<Radio value={2}>Somente Leitura</Radio>
												</Space>
											</Radio.Group>
										</div>
									</div>
									<div className="box-radios">
										<div className="check-select">
											<Unicons.UilAngleRight size="20" color="#353F49" />
											<Checkbox />
											<h5>Módulo 1</h5>
											<Unicons.UilInfoCircle size="20" color="#92A9CB" />
										</div>
										<div className="check-radios">
											<Radio.Group name="radiogroup" defaultValue={1}>
												<Space direction="vertical">
													<Radio value={1}>Edição e Leitura</Radio>
													<Radio value={2}>Somente Leitura</Radio>
												</Space>
											</Radio.Group>
										</div>
									</div>
								</div>
								<div className="boxes-radios">
									<div className="box-radios">
										<div className="check-select">
											<Unicons.UilAngleRight size="20" color="#353F49" />
											<Checkbox />
											<h5>Módulo 1</h5>
											<Unicons.UilInfoCircle size="20" color="#92A9CB" />
										</div>
										<div className="check-radios">
											<Radio.Group name="radiogroup" defaultValue={1}>
												<Space direction="vertical">
													<Radio value={1}>Edição e Leitura</Radio>
													<Radio value={2}>Somente Leitura</Radio>
												</Space>
											</Radio.Group>
										</div>
									</div>
									<div className="box-radios">
										<div className="check-select">
											<Unicons.UilAngleRight size="20" color="#353F49" />
											<Checkbox />
											<h5>Módulo 1</h5>
											<Unicons.UilInfoCircle size="20" color="#92A9CB" />
										</div>
										<div className="check-radios">
											<Radio.Group name="radiogroup" defaultValue={1}>
												<Space direction="vertical">
													<Radio value={1}>Edição e Leitura</Radio>
													<Radio value={2}>Somente Leitura</Radio>
												</Space>
											</Radio.Group>
										</div>
									</div>
									<div className="box-radios">
										<div className="check-select">
											<Unicons.UilAngleRight size="20" color="#353F49" />
											<Checkbox />
											<h5>Módulo 1</h5>
											<Unicons.UilInfoCircle size="20" color="#92A9CB" />
										</div>
										<div className="check-radios">
											<Radio.Group name="radiogroup" defaultValue={1}>
												<Space direction="vertical">
													<Radio value={1}>Edição e Leitura</Radio>
													<Radio value={2}>Somente Leitura</Radio>
												</Space>
											</Radio.Group>
										</div>
									</div>
								</div>
							</div>
							<div className="botoes" style={{ paddingBottom: 30 }}>
								<Link to="/usuarios/create/step-6">
									<Button className="button-second" style={{ width: 320 }}>
										Cancelar
									</Button>
								</Link>
								<Link to="/usuarios/create/step-8">
									<Button className="button-primary" style={{ width: 320 }}>
										Salvar Alterações
									</Button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link to="/usuarios/create/step-7">
				<div className="botao-voltar-revisao">
					<Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>
						Voltar
					</Button>
				</div>
			</Link>
		</div>
	);
}

export default FranquiaCreateStep8;