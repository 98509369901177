import http from '../helpers/http-common';
import {
	IEscolaCleanDTO,
	IGrupoCleanDTO,
	IGrupoDTO,
	IGrupoFullDTO
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useGrupoService = () => {
	const resourceURL = 'grupo';

	const cancelar = (
		idGrupo: number
	): Promise<IAxiosResponseCustom<any>> =>
		http.patch(`${resourceURL}/cancelar/${idGrupo}`);

	const findCustom = (escolaId: number) => {
		return http.get(`/${resourceURL}/find-custom?escolaId=${escolaId}`)
	}

	return {
		findCustom,
		...baseFindList<IGrupoCleanDTO, IGrupoDTO, IGrupoFullDTO>(resourceURL),
		...baseFind<IGrupoCleanDTO, IGrupoDTO, IGrupoFullDTO>(resourceURL),
		...baseCreate<IGrupoCleanDTO, IGrupoDTO, IGrupoFullDTO>(resourceURL),
		...baseUpdate<IGrupoCleanDTO, IGrupoDTO, IGrupoFullDTO>(resourceURL),
		...baseFindById<IGrupoCleanDTO, IGrupoDTO, IGrupoFullDTO>(resourceURL),
		cancelar,
	};
};
