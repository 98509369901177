import { useCallback, useEffect, useState } from 'react';
import useGetCobrancasByIdFinanceiro from '../../../../../globalHooks/cobrancas/use-get-cobrancas-by-id-financeiro.hook';
import { useParams } from 'react-router-dom';
import { TipoStatusCobranca } from '../../../../../enum/tipoStatusCobranca';

export default function useLimitInstallmentsNumbers() {
	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();
	const { cobrancasByIdFinanceiro } = useGetCobrancasByIdFinanceiro({
		idFinanceiro,
	});

	const [installmentsNumbers, setInstallmentsNumbers] = useState<number[]>([]);

	const lastInstallmentPaid = useCallback(() => {
		const installmentsPaid = cobrancasByIdFinanceiro.filter(
			({ statusCobranca }) =>
				statusCobranca.codigo === TipoStatusCobranca.CODIGO_PAGO
		);

		const lastElement = installmentsPaid.length - 1;

		return installmentsPaid[lastElement].parcela;
	}, [cobrancasByIdFinanceiro]);

	const lastInstallment = useCallback(() => {
		const lastItem = cobrancasByIdFinanceiro.length - 1;

		return cobrancasByIdFinanceiro[lastItem].parcela;
	}, [cobrancasByIdFinanceiro]);

	const limitInstallmentsNumbers = useCallback((): void => {
		if (!cobrancasByIdFinanceiro.length) return;

		const getLastInstallment = lastInstallment();
		const getLastInstallmentPaid = lastInstallmentPaid();

		const calculateInstallments = getLastInstallment - getLastInstallmentPaid;
		const getInstallments =
			calculateInstallments === 0 ? 1 : calculateInstallments;

		const installmentsLimit = Array.from(
			{ length: getInstallments },
			(_, i) => i + 1
		);

		setInstallmentsNumbers(installmentsLimit);
	}, [cobrancasByIdFinanceiro.length, lastInstallment, lastInstallmentPaid]);

	useEffect(() => {
		limitInstallmentsNumbers();

		return () => setInstallmentsNumbers([]);
	}, [limitInstallmentsNumbers]);

	return {
		installmentsNumbers,
	};
}
