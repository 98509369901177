import * as Unicons from '@iconscout/react-unicons';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUnidadeContext } from '../../context/UnidadeContext';
import { Predicate } from '../../models/predicate.model';
import { INegocioUnidadeDTO, IUnidadeCustomDTO } from './../../models/happy-code-api.model';
import { useNegocioUnidadeService } from './../../services/negocio-unidade.service';
import { useUnidadeService } from './../../services/unidade.service';
import './style.css';

interface Props {
    unidade: IUnidadeCustomDTO;
}

const DropdownFranquia: React.FunctionComponent<Props> = ({ unidade }: Props) => {

    // const notification = useNotificationService();

    const [open, setOpen] = useState(false);
    const [negocioUnidade, setNegocioUnidade] = useState<INegocioUnidadeDTO[]>([]);
    const negocioUnidadeService = useNegocioUnidadeService();
    const history = useHistory();
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const { setUnidade } = useUnidadeContext();
    const unidadeService = useUnidadeService();
    // const [unidade, setUnidadeItem] = useState(props.unidade)

    useEffect(() => {
        const getNegocioUnidade = () => {
            const predicate = new Predicate();
            predicate.addOption('unidadeId', unidade.id);
            negocioUnidadeService.find(predicate)
                .then(({ data }) => {
                    setNegocioUnidade(data.content);
                })
        }

        if ((!negocioUnidade || negocioUnidade.length === 0) && open) {
            getNegocioUnidade();
        }
    }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

    const goToRevisao = () => {
        setIsGlobalLoading(true);
        unidadeService.findFullById(unidade)
            .then(({ data }) => {
                setUnidade(data);
                history.push("/franquias/create/step-10");
            }).finally(() => setIsGlobalLoading(false));
    }

    const getStatusCss = () => {
        if (unidade.statusUnidadeDescricao === 'Em Operação') return 'estado';
        if (unidade.statusUnidadeDescricao === 'Em implantação') return 'estado-amarelo';
        if (unidade.statusUnidadeDescricao === 'Em distrato') return 'estado-amarelo';
        if (unidade.statusUnidadeDescricao === 'Fechada') return 'estado-vermelho';
        if (unidade.statusUnidadeDescricao === 'Suspenso') return 'estado-cinza';
        return 'estado';
    }

    // const showDeleteConfirm = () => {
    //     Modal.confirm({
    //         title: `Deseja realmente ${unidade.ativo ? 'desativar' : 'ativar'} a unidade?`,
    //         icon: <ExclamationCircleTwoTone rev={undefined} />,
    //         content: "Nome: " + unidade.descricao,
    //         okText: 'Yes',
    //         okType: `${unidade.ativo ? 'danger' : 'primary'}`,
    //         cancelText: 'No',
    //         onOk: () => {
    //             unidadeService.patch({ id: unidade.id, ativo: !unidade.ativo })
    //                 .then(() => notification({ description: 'Unidade alterada com sucesso!', type: 'success', message: 'Sucesso' }))
    //                 .finally(() => setIsGlobalLoading(false));
    //         },
    //         // onCancel: () => { },
    //     });
    // }

    // const menu = (
    //     <Menu>
    //         {/* <Menu.Item>
    //             <a rel="noopener noreferrer">
    //                 Editar
    //             </a>
    //         </Menu.Item> */}
    //         <Menu.Item onClick={showDeleteConfirm} >
    //             <a rel="noopener noreferrer">
    //                 Desativar/Ativar Franquia
    //             </a>
    //         </Menu.Item>
    //     </Menu>
    // );

    const itensDropdown = () => {
        return (
            <Menu>
                <Menu.Item>
                    <li>
                        <Link to={`/franquias/vinculo-externo/${unidade?.id}`} rel="noopener noreferrer">
                            ID de serviço
                        </Link>
                    </li>
                </Menu.Item>
            </Menu>
        )
    }

    if (!open) {
        return (
            <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
                <div className="registros-row" >
                    <div className="registro" style={{ width: "25%" }}>
                        <span>{unidade?.descricao}</span>
                        <div className={getStatusCss()}>
                            <div className="dot"></div>
                            <span>{unidade.statusUnidadeDescricao}</span>
                        </div>
                    </div>
                    <div className="registro" style={{ width: "5%" }}>
                        {unidade?.pendencia && (
                            <Unicons.UilExclamationTriangle size="20" color="#F59300" />
                        )}
                    </div>
                    <div className="registro" style={{ width: "12%" }}>
                        <span>{unidade.codigo}</span>
                    </div>
                    <div className="registro" style={{ width: "18%" }}>
                        <span>{unidade.enderecoNomeUf}</span>
                    </div>
                    <div className="registro" style={{ width: "15%" }}>
                        <span>{unidade.enderecoCidade}</span>
                    </div>
                    <div className="registro" style={{ width: "30%" }}>
                        <span className="gestor">{unidade.gestorNome}</span>
                    </div>
                </div>
                {usuario?.funcao === 'TEC' ?
                    <div className="registro" style={{ width: "2%" }}>
                        <Dropdown overlay={itensDropdown()} placement="bottomRight" arrow>
                            <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                        </Dropdown>
                    </div>
                    : null}
                <div onClick={() => setOpen(!open)} className="registro-opcao">
                    <div className="circle">
                        <Unicons.UilAngleDown size="20" color="#6A7C96" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="registros-body-detalhes">
            <div className="row-1">
                <div className="registros-row">
                    <div className="registro" style={{ width: "25%" }}>
                        <span>{unidade?.descricao}</span>
                        <div className={getStatusCss()}>
                            <div className="dot"></div>
                            <span>{unidade.statusUnidadeDescricao}</span>
                        </div>
                    </div>
                    <div className="registro" style={{ width: "5%" }}>
                        {unidade?.pendencia && (
                            <Unicons.UilExclamationTriangle size="20" color="#F59300" />
                        )}
                    </div>
                    <div className="registro" style={{ width: "12%" }}>
                        <span>{unidade.codigo}</span>
                    </div>
                    <div className="registro" style={{ width: "18%" }}>
                        <span>{unidade.enderecoNomeUf}</span>
                    </div>
                    <div className="registro" style={{ width: "15%" }}>
                        <span>{unidade.enderecoCidade}</span>
                    </div>
                    <div className="registro" style={{ width: "30%" }}>
                        <span className="gestor">{unidade.gestorNome}</span>
                    </div>
                    <div className="registro" style={{ width: "2%" }}></div>
                    {/* <div className="registro" id="registro-icones" style={{ width: "10%" }}>
                        <a href="">
                            <Unicons.UilEdit size="20" color="#92A9CB" />
                        </a> 
                        <Dropdown overlay={menu} placement="bottomRight" arrow>
                            <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                        </Dropdown>
                    </div> */}
                </div>
                <div className="registro-opcao">
                    <div className="circle" onClick={() => setOpen(!open)}>
                        <Unicons.UilAngleDown size="20" color="#6A7C96" />
                    </div>
                </div>
            </div>
            <div className="row-2">
                <div className="dados">
                    <h6>CNPJ</h6>
                    <span>{unidade.cnpj}</span>
                    <br />
                    <h6>Endereço</h6>
                    <span>{unidade.enderecoLogradouro},{unidade.enderecoNumero} - {unidade.enderecoBairro}, {unidade.enderecoCidade} - {unidade.enderecoNomeUf}, {unidade.enderecoCep} - {unidade.enderecoPaisNome}</span>
                </div>
                <div className="line"></div>
                <div className="modelos">
                    <h6>MODELO UNIDADES</h6>
                    <span>{unidade.modeloUnidadeDescricao}</span>
                </div>
                <div className="line"></div>
                <div className="negocios">
                    <h6>Negócios da unidade ({negocioUnidade.length})</h6>
                    {negocioUnidade && negocioUnidade.map(negocio => (
                        <span key={negocio.id}>{negocio.negocio.descricao}</span>
                    ))}
                </div>

            </div>
            <Button onClick={() => goToRevisao()} style={{ marginTop: 20 }} className="button-primary">
                Ver mais detalhes
            </Button>
        </div >
    )


}

export default DropdownFranquia;