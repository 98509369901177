export enum TipoMaterial {
    CODIG_ZIP = "zip",
    CODIG_PDF = "pdf",
    CODIG_AIA = "aia",
    CODIG_PPTX = "pptx",
    CODIG_CAPX = "capx",
    CODIG_DOCX = "docx",
    CODIG_EXE = "exe",
    CODIG_RAR = "rar",
    CODIG_PNG = "png",
    CODIG_MCWORLD = "mcworld",
    CODIG_UF = "uf2",
    CODIG_EV3 = "ev3",
    CODIG_SB3 = "sb3",
    CODIG_SJR = "sjr",
    CODIG_LINK = "link",
    CODIG_OFICIAL = "oficial",

    ID_ZIP = 1,
    ID_PDF = 2,
    ID_AIA = 3,
    ID_PPTX = 4,
    ID_CAPX = 5,
    ID_DOCX = 6,
    ID_EXE = 7,
    ID_RAR = 8,
    ID_PNG = 9,
    ID_MCWORLD = 10,
    ID_UF = 11,
    ID_EV3 = 12,
    ID_SB3 = 13,
    ID_SJR = 14,
    ID_LINK = 15,
    ID_OFICIAL = 16,
}
