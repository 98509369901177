import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import useProfileIsFranquia from '../../../globalHooks/useProfileIsFranquia';
import { Predicate } from '../../../models/predicate.model';
import { FilterState } from '../../../types/filter-state.type';
import { FilterDefaultValue } from '../../../enum/filter-default-value.enum';
import { SortingEnum } from '../../../enum/sorting.enum';
import { useGrupoService } from '../../../services/grupo.service';
import { PageableResponse } from '../../../models/response.model';
import { IGrupoFullDTO } from '../../../models/happy-code-api.model';
import { Operators } from '../../../enum/operators.enum';

export default function useFindGroupSchools() {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const predicate = useMemo(() => new Predicate(), []);

	const thisProfileIsFranquia = useProfileIsFranquia();
	const addProfileFranquiaParams = useCallback(
		() =>
			thisProfileIsFranquia &&
			unidade.id &&
			predicate.addOption('unidadeId', unidade.id),
		[thisProfileIsFranquia, unidade.id]
	);

	const [estadoId, setEstadoId] = useState<FilterState['id'] | string>();
	const onFilterStateChange = (stateValue: FilterState['id'] | string) =>
		setEstadoId(stateValue);
	const addStateParams = useCallback(() => {
		if (!estadoId) return;

		if (estadoId !== FilterDefaultValue.DEFAULT)
			return predicate.addOption('enderecoUf', estadoId);

		return predicate.removeOption('enderecoUf');
	}, [estadoId]);

	const [filtroStatus, setFiltroStatus] = useState<string>();
	const onFilterStatusChange = (status: string) => setFiltroStatus(status);
	const addStatusParams = useCallback(() => {
		if (!filtroStatus) return;

		if (filtroStatus !== FilterDefaultValue.DEFAULT)
			return predicate.addOption('ativo', filtroStatus);

		return predicate.removeOption('ativo');
	}, [filtroStatus]);

	const [filterGroupName, setFilterGroupName] = useState<string>(
		SortingEnum.ASC
	);
	const onFilterGroupNameChange = (value: string) => setFilterGroupName(value);
	const addGroupNameSortingParams = useCallback(() => {
		const sort =
			filterGroupName === SortingEnum.ASC ? SortingEnum.DESC : SortingEnum.ASC;

		predicate.sort = sort;
		predicate.addSort('nomeFantasia');
	}, [filterGroupName]);

	const { find } = useGrupoService();

	const [schoolGroup, setSchoolGroup] =
		useState<PageableResponse<IGrupoFullDTO>>();

	const findSchoolGroups = useCallback(async () => {
		setIsGlobalLoading(true);

		addProfileFranquiaParams();
		addStateParams();
		addStatusParams();
		addGroupNameSortingParams();

		return await find(predicate)
			.then(({ data }) =>
				setSchoolGroup(data as PageableResponse<IGrupoFullDTO>)
			)
			.finally(() => setIsGlobalLoading(false));
	}, [
		setIsGlobalLoading,
		thisProfileIsFranquia,
		unidade.id,
		estadoId,
		filtroStatus,
		filterGroupName,
		addProfileFranquiaParams,
		addStateParams,
		addStatusParams,
	]);

	useEffect(() => {
		const controller = new AbortController();
		findSchoolGroups();
		return () => controller.abort();
	}, [findSchoolGroups]);

	const [searchbarValue, setSearchbarValue] = useState<string>('');

	const TIME_IN_MILISECONDS = 800;

	useEffect(() => {
		const debouncedSearch = setTimeout(() => {
			predicate.addOption(
				'nomeFantasia',
				searchbarValue || '',
				Operators.CONTAINS
			);
			findSchoolGroups();
		}, TIME_IN_MILISECONDS);

		return () => clearTimeout(debouncedSearch);
	}, [findSchoolGroups, searchbarValue]);

	const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findSchoolGroups();
	};

	return {
		setSearchbarValue,
		schoolGroup,
		onFilterStateChange,
		onFilterStatusChange,
		findSchoolGroups,
		onFilterGroupNameChange,
		onPageChange,
	};
}
