import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Form, Input, InputNumber, Radio, Select, TimePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import ModalHistorico from '../../../components/ModalHistorico';
import Progress from '../../../components/Progress';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { Predicate } from '../../../models/predicate.model';
import { useUnidadeAtuacaoService } from '../../../services/unidade-atuacao.service';
import { useUnidadeCaracteristicaService } from '../../../services/unidade-caracteristica.service';
import { formatHora } from '../../../util/format';
import '../style.css';
import { IClusterCleanDTO, IModeloUnidadeCleanDTO, IStatusUnidadeCleanDTO, IUnidadeAtuacaoCleanDTO, IUnidadeCaracteristicaCleanDTO } from './../../../models/happy-code-api.model';
import { useClusterService } from './../../../services/cluster.service';
import { useModeloUnidadeService } from './../../../services/modelo-unidade.service';
import { useStatusUnidadeService } from './../../../services/status-unidade.service';
import StepsCreate from './steps-create';
import { useNotificationService } from '../../../services/notification.service';

export const FranquiaCreateStep9: React.FunctionComponent = () => {
  const { unidade, setUnidade } = useUnidadeContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [listStatusUnidade, setListStatusUnidade] = useState<IStatusUnidadeCleanDTO[]>([]);
  const [listModeloUnidade, setListModeloUnidade] = useState<IModeloUnidadeCleanDTO[]>([]);
  const [listCluster, setListCluster] = useState<IClusterCleanDTO[]>([]);
  const [listAtuacao, setListAtuacao] = useState<IUnidadeAtuacaoCleanDTO[]>();
  const [listCaracteristica, setListCaracteristica] = useState<IUnidadeCaracteristicaCleanDTO[]>();
  const [diaFuncionamento, setDiaFuncionamento] = useState(unidade.diaFuncionamento ? unidade.diaFuncionamento : null);
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const notification = useNotificationService();
  const horarioFormat = 'HH:mm';

  const toggle = () => {
    const tg = !isOpen;
    setIsOpen(tg);
    return tg;
  }

  const [statusUnidade, setStatusUnidade] = useState<IStatusUnidadeCleanDTO>({
    id: null,
    ativo: null,
    descricao: null,
    codigo: null
  });
  const [cluster, setCluster] = useState<IClusterCleanDTO>({
    id: null,
    ativo: null,
    descricao: null,
    codigo: null
  });
  const [modelo, setModelo] = useState<IModeloUnidadeCleanDTO>({
    id: null,
    ativo: null,
    descricao: null,
    codigo: null
  });
  const [atuacao, setAtuacao] = useState<IUnidadeAtuacaoCleanDTO>({
    id: null,
    ativo: null,
    descricao: null,
    codigo: null
  });
  const [caracteristica, setCaracteristica] = useState<IUnidadeCaracteristicaCleanDTO>({
    id: null,
    ativo: null,
    descricao: null,
    codigo: null
  });

  const statusUnidadeService = useStatusUnidadeService();
  const modeloUnidadeService = useModeloUnidadeService();
  const unidadeAtuacaoService = useUnidadeAtuacaoService();
  const unidadeCaracteristicaService = useUnidadeCaracteristicaService();
  const clusterService = useClusterService();
  const history = useHistory();

  useEffect(() => {
    setUnidade({
      ...unidade,
      horaAulaInicio: unidade?.horaAulaInicio ? unidade?.horaAulaInicio : moment("09:00", "HH:mm").toDate(),
      horaAbertura: unidade?.horaAbertura ? unidade?.horaAbertura : moment("08:00", "HH:mm").toDate(),
      horaFechamento: unidade?.horaFechamento ? unidade?.horaFechamento : moment("18:00", "HH:mm").toDate(),
      horaAlmocoInicio: unidade?.horaAlmocoInicio ? unidade?.horaAlmocoInicio : moment("12:00", "HH:mm").toDate(),
      horaAlmocoFim: unidade?.horaAlmocoFim ? unidade?.horaAlmocoFim : moment("13:00", "HH:mm").toDate()
    });

    const franquiaDataFromContext = unidade;
    if (franquiaDataFromContext) {
      setStatusUnidade(unidade.statusUnidade);
      setModelo(unidade.modeloUnidade);
      setCluster(unidade.cluster);
      setAtuacao(unidade.atuacao);
      setCaracteristica(unidade.caracteristica);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const findStatusUnidade = () => {
    return statusUnidadeService.findCleanList().then(({ data }) => {
      setListStatusUnidade(data);
    })
  }

  const findModeloUnidade = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true)
    return modeloUnidadeService.findCleanList(predicate).then(({ data }) => {
      setListModeloUnidade(data);
    })
  }

  const findAtuacaoUnidade = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true)
    return unidadeAtuacaoService.findCleanList(predicate).then(({ data }) => {
      setListAtuacao(data);
    })
  }

  const findCaracteristicaUnidade = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true)
    return unidadeCaracteristicaService.findCleanList(predicate).then(({ data }) => {
      setListCaracteristica(data);
    })
  }


  const findCluster = () => {
    return clusterService.findCleanList().then(({ data }) => {
      setListCluster(data);
    })
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    Promise.all([
      findStatusUnidade(),
      findModeloUnidade(),
      findCluster(),
      findCaracteristicaUnidade(),
      findAtuacaoUnidade()
    ]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnidade = () => {
    if (cluster.id && statusUnidade.id && modelo.id) {
      const form = {
        caracteristica: listCaracteristica.find(item => item.id === caracteristica.id),
        atuacao: listAtuacao.find(item => item.id === atuacao.id),
        cluster: listCluster.find(item => item.id == cluster.id),
        statusUnidade: listStatusUnidade.find(item => item.id === statusUnidade.id),
        modeloUnidade: listModeloUnidade.find(item => item.id === modelo.id),
        diaFuncionamento: diaFuncionamento,
      }

      const contextState = { ...unidade, ...form };
      setUnidade(contextState);
      history.push("/franquias/create/step-14");
      
    }
    else {
      if (!statusUnidade.id) {
        notification({
          description: `Selecione o status da unidade!`,
          type: 'warning',
          message: 'Aviso!',
        });
        return
      }
      if (!modelo.id) {
        notification({
          description: `Selecione o modelo da unidade!`,
          type: 'warning',
          message: 'Aviso!',
        });
        return
      }
			if (!cluster.id) {
				notification({
					description: `Selecione o clusterização!`,
					type: 'warning',
					message: 'Aviso!',
				});
        return
			}
		}
  };

  return (
    <div className="container">
      <Header />
      <Progress />
      <StepsCreate current={5} />
      <div className="franquia" style={{ top: 70 }}>
        <Form
          form={form}
          className="franquia-row"
          name="control-hooks"
          onFinish={handleUnidade}
        >
          <h5>Classificação de Unidade</h5>
          <div className="row-clasificacao">
            <div className="select-franquia" style={{ width: '100%' }}>
              <span>Capital</span>
              <Checkbox checked={unidade?.capital} onChange={() => setUnidade({ ...unidade, capital: !unidade.capital })}
              />
            </div>
            <div className="status">
              <h4>Status <span id="ob">*</span></h4>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra vivamus leo pretium.</p> */}
              <Radio.Group value={statusUnidade ? statusUnidade.id : null} onChange={(value) => setStatusUnidade(prev => ({ ...prev, id: value.target.value }))}>
                {listStatusUnidade && listStatusUnidade.map((status, index) => (
                  <div key={index} className="status-row">
                    <Radio id={"status-" + index} value={status.id}></Radio>
                    <span>{status.descricao}</span>
                  </div>
                ))}
              </Radio.Group>
            </div>
            <div className="status">
              <h4>Modelo <span id="ob">*</span></h4>
              <Radio.Group value={modelo ? modelo.id : null} onChange={(value) => setModelo(prev => ({ ...prev, id: value.target.value }))}>
                {listModeloUnidade && listModeloUnidade.map((modelo, index) => (
                  <div key={index} className="status-row">
                    <Radio id={"modelo-" + index} value={modelo.id}></Radio>
                    <span>{modelo.descricao}</span>
                  </div>
                ))}
              </Radio.Group>
            </div>
            <div className="status">
              <h4>Atuação</h4>
              <Radio.Group value={atuacao ? atuacao.id : null} onChange={(value) => setAtuacao(prev => ({ ...prev, id: value.target.value }))}>
                {listAtuacao && listAtuacao.map((at, index) => (
                  <div key={index} className="status-row">
                    <Radio id={"atuacao-" + index} value={at.id}></Radio>
                    <span>{at.descricao}</span>
                  </div>
                ))}
              </Radio.Group>
            </div>

            <div className="status">
              <h4>Característica</h4>
              {/* <p>Unidades podem ter apenas um modelo.</p> */}
              <Radio.Group value={caracteristica ? caracteristica.id : null} onChange={(value) => setCaracteristica(prev => ({ ...prev, id: value.target.value }))}>
                {listCaracteristica && listCaracteristica.map((carac, index) => (
                  <div key={index} className="status-row">
                    <Radio id={"caracteristica-" + index} value={carac.id}></Radio>
                    <span>{carac.descricao}</span>
                  </div>
                ))}
              </Radio.Group>
            </div>
            <div className="status">
              <h4>Clusterização  <span id="ob">*</span></h4>
             
              <Select
                value={cluster?.id}
                placeholder="Selecione uma Clusterização"
                onChange={(value: number) => setCluster(prev => ({ ...prev, id: value }))}
                allowClear style={{ width: '100%', marginBottom: 20 }}>
                {listCluster && listCluster.map((cluster, index) => (
                  <Select.Option id={"cluster-" + index} key={index.toString()} value={cluster.id}>{cluster.descricao}</Select.Option>
                ))}
              </Select>
            </div>
            <div className="status">
              <h4>Dia de funcionamento</h4>
              <Input
                value={diaFuncionamento}
                placeholder="Descreva os dias de funcionamento"
                onChange={(e) => setDiaFuncionamento(e.target.value)}>
              </Input>
            </div>
            <div style={{ width: '100%' }}>
              <div className="status" >
                <h4>Horário de funcionamento</h4>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <label style={{ marginRight: '10px' }}>Horário de abertura:  </label>
                  <TimePicker
                    onChange={(e) => setUnidade({ ...unidade, horaAbertura: e?.toDate() })}
                    defaultValue={moment(unidade.horaAbertura, horarioFormat)}
                    value={moment(unidade.horaAbertura, horarioFormat)}
                    format={horarioFormat}
                    allowClear
                  />

                  <label style={{ marginRight: '10px', marginLeft: '10px' }}>Horário de fechamento:</label>
                  <TimePicker
                    onChange={(e) => setUnidade({ ...unidade, horaFechamento: e?.toDate() })}
                    defaultValue={moment(unidade.horaFechamento, horarioFormat)}
                    value={moment(unidade.horaFechamento, horarioFormat)}
                    format={"HH:mm"}
                    allowClear
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div className="status" >
                <h4>Horário de almoço</h4>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <label style={{ marginRight: '10px' }}>Horário de inicio:  </label>
                  <TimePicker
                    onChange={(e) => setUnidade({ ...unidade, horaAlmocoInicio: e?.toDate() })}
                    defaultValue={moment(unidade.horaAlmocoInicio, horarioFormat)}
                    value={moment(unidade.horaAlmocoInicio, horarioFormat)}
                    format={"HH:mm"}
                    allowClear
                  />

                  <label style={{ marginRight: '10px', marginLeft: '10px' }}>Horário de fim:</label>
                  <TimePicker
                    onChange={(e) => setUnidade({ ...unidade, horaAlmocoFim: e?.toDate() })}
                    defaultValue={moment(unidade.horaAlmocoFim, horarioFormat)}
                    value={moment(unidade.horaAlmocoFim, horarioFormat)}
                    format={"HH:mm"}
                    allowClear
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div className="status" >
                <h4>Horário de aulas</h4>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <label style={{ marginRight: '10px' }}>Horário de inicio:  </label>
                  <TimePicker
                    onChange={(e) => setUnidade({ ...unidade, horaAulaInicio: e?.toDate() })}
                    defaultValue={moment(unidade?.horaAulaInicio, horarioFormat)}
                    value={moment(unidade?.horaAulaInicio, horarioFormat)}
                    format={horarioFormat}
                    allowClear
                  />
                </div>
              </div>
            </div>

            <div className="input-box">
              <p>Intervalo em minutos</p>
              <InputNumber defaultValue={unidade?.intervalo !== null ? unidade?.intervalo : 0} min={0} value={unidade?.intervalo} id="descricao" onChange={e => setUnidade({ ...unidade, intervalo: e })} />
            </div>

            <div className="botoes">
              <Link to="/franquias/create/step-7">
                <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
              </Link>
              <button type="submit" id="proximo" style={{ width: 345 }} className="button-primary"><span>Próximo</span></button>
            </div>
          </div>

        </Form>
      </div>
      <Link to="/franquias">
        <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
      </Link>
      <ModalHistorico open={isOpen} onClose={toggle} />
    </div>
  )
}
