import { useContext, useEffect, useState } from "react";
import { useEscolaContext } from "../../../../../context/EscolaContext";
import { useGlobalContext } from "../../../../../context/GlobalContext";
import { Predicate } from "../../../../../models/predicate.model";
import { useGrupoService } from "../../../../../services/grupo.service";
import { useLeadCampanhaService } from "../../../../../services/lead-campanha.service";
import { useLeadEscolaCampanhaService } from "../../../../../services/lead-escola-campanha.service";
import { useLeadEscolaEstagioService } from "../../../../../services/lead-escola-estagio.service";
import { useLeadEscolaOrigemService } from "../../../../../services/lead-escola-origem.service";
import { useCrmEscolaContext } from "../../../context/crm-escola.context";

const useNovoLeadStep1ComponentState = () => {
  const [state, setState] = useState();
  const [grupos, setGrupos] = useState<any>();
  const { leadParaCadastrar, setLeadParaCadastrar, onFecharModal, setIndexCadastro, indexCadastro } = useCrmEscolaContext();
  const { categoriasQuantidadeAlunos } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const grupoService = useGrupoService();
  const leadEscolaOrigemService = useLeadEscolaOrigemService();
  const leadEscolaCampanhaService = useLeadEscolaCampanhaService();
  const leadEscolaEstagioService = useLeadEscolaEstagioService();
  const [leadOrigens, setLeadOrigens] = useState<any>();
  const [leadCampanhas, setLeadCampanhas] = useState<any>();
  const [leadEstagios, setLeadEstagios] = useState<any>();
  const [dtProximoRetorno, setDtProximoRetorno] = useState<any>();
  const [avaliacaoLead, setAvaliacaoLead] = useState<any>();

  const fetchGrupos = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    return grupoService.findList().then((response) => {
      setGrupos(response.data);
    })
  }

  const fetchOrigens = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    return leadEscolaOrigemService.findList().then((response) => {
      setLeadOrigens(response.data);
    })
  }

  const fetchCampanhas = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    return leadEscolaCampanhaService.findList().then((response) => {
      setLeadCampanhas(response.data);
    })
  }

  const fetchEstagios = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    return leadEscolaEstagioService.findList().then((response) => {
      setLeadEstagios(response.data);
    })
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    fetchGrupos()
      .then(() => fetchOrigens())
      .then(() => fetchCampanhas())
      .then(() => fetchEstagios())
      .finally(() => {
        setIsGlobalLoading(false);
      });
  }, [])

  const onFinish = (form) => {
    setLeadParaCadastrar((state) => ({
      ...state, ...form,
      dataProximoRetorno: dtProximoRetorno,
      avaliacaoLead: avaliacaoLead,
    }));
    setIndexCadastro(indexCadastro + 1)
  }

  return {
    state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
    leadOrigens,
    leadCampanhas,
    leadEstagios,
    setDtProximoRetorno,
    dtProximoRetorno,
    avaliacaoLead,
    setAvaliacaoLead,
  }
}

export default useNovoLeadStep1ComponentState;
