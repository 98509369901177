import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { ICursoDTO, ILogCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCursoService } from '../../services/curso.service';
import { useLogService } from '../../services/log.service';
import FiltrosCurso from './filtros-curso';
import ItemListCurso from './item-lista';
import './style.css';

export const Cursos: React.FunctionComponent = () => {
  const [data, setData] = useState<PageableResponse<ICursoDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const cursoService = useCursoService();
  const { setIsGlobalLoading } = useGlobalContext();
  const { removeCurso, setRevisao } = useCursoContext();

  const history = useHistory();
  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso Curso sede",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    logService.registraLog(log)
  }, [])

  const findCurso = () => {
    setIsGlobalLoading(true);
    cursoService.find(predicate)
      .then(({ data }) => {
        setData(data);
      }).finally(() => setIsGlobalLoading(false));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findCurso();
  }

  const goToAdd = () => {
    history.push("/cursos/create/step-1");
  }

  useEffect(() => {
    removeCurso();
    setRevisao(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findCurso();
  }

  return (
    <div className="container">
      <NewMenu selecionado={6} />
      <div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
        <div className="box-title">
          <h3 className="title">Cursos ({data?.totalElements})</h3>
          <Button className="button-primary" id="criar-curso" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Curso</Button>
        </div>
        <FiltrosCurso findCurso={findCurso} predicate={predicate} />
        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '30%' }}>
              <span>CURSO</span>
              <div className="arrows" onClick={() => ordenar('descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>ID</span>
              <div className="arrows" onClick={() => ordenar('id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '13%' }}>
              <span>MODALIDADE</span>
              <div className="arrows" onClick={() => ordenar('modalidade.descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '8%' }}>
              <span>TIPO</span>
              <div className="arrows" onClick={() => ordenar('tipoCurso.descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '14%' }}>
              <span>NEGOCIO</span>
              <div className="arrows" onClick={() => ordenar('negocio.descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>AULAS</span>
              <div className="arrows" onClick={() => ordenar('duracao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>MINUTOS</span>
              <div className="arrows" onClick={() => ordenar('duracaoMinutos')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '5%' }}></div>
          </div>
          <div className="tabela-body">
            {data && data.content.map((curso, index) => (
              <ItemListCurso curso={curso} key={index} />
            ))}
          </div>
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}
