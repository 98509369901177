
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { ITopicoAulaDTO } from '../../../../models/happy-code-api.model';
import { useAcademyTopicoService } from '../../../../services/academy-topico';
import { useNotificationService } from '../../../../services/notification.service';
interface TItemLista {
    topico: ITopicoAulaDTO;
}

const AcademyItemListAcaTopico = memo(({ topico }: TItemLista) => {
    const history = useHistory();
    const [ativo, setAtivo] = useState<boolean>();
    const topicoService = useAcademyTopicoService();
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();
    const { idTrilha, idCurso } = useParams<any>();

    const goTo = () => {
        history.push(`/academy/trilhas/${idTrilha}/cursos/${idCurso}/aula/${topico?.aula?.id}/topico/${topico?.id}/create`)
    }

    useEffect(() => {
        setAtivo(topico.ativo);
    }, [topico.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a aula?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: topico?.descricao,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                topicoService.patch({ id: topico.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Aula alterada com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "50%" }}>
                <div className="nome">
                    <p>{topico?.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}>
                <span>{topico?.id}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "15%" }}>
                <span>{topico?.numeroTopicoAula}</span>
            </div>

            {topico?.descricao !== "Feedback" ?
                <div className="registro-opcao" style={{ width: "5%" }}>
                    <a id="editar" onClick={goTo}>
                        <Unicons.UilEdit size="20" color="#92A9CB" />
                    </a>
                    <Dropdown overlay={menu} placement="bottomRight" arrow>
                        <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                    </Dropdown>
                </div>
                : null}

        </div>
    )
})

AcademyItemListAcaTopico.displayName = 'AcademyItemListAcaTopico';


export default AcademyItemListAcaTopico;
