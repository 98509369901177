import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';

import { MenuDetalheNegocio } from '../../components/MenuDetalheNegocio';
import { useNegocioContext } from '../../context/NegocioContext';
import { formatMoney } from '../../util/format';
import './style.css';

export const NegocioCreateStep6: React.FunctionComponent = () => {
  const { negocio, setRevisao } = useNegocioContext();
  const history = useHistory();
  const goTo = (url) => history.push(url);

  useEffect(() => {
    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
      </div>
      <MenuDetalheNegocio />
      <div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
        <div className="franquia-row">
          <h5>Revisão de Dados</h5>
          <div className="row-revisao">
            <div className="box-revisao">
              <div className="dados">
                <Button id="editar-step-1" className="button-line" onClick={() => goTo("/negocio/create/step-1")}>Editar</Button>
                <h4>Dados Básicos</h4>
                <div className="nome">
                  <h5>nome do negócio</h5>
                  <span style={{ fontSize: 18 }}>{negocio?.descricao}</span>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>tipo currículo</h6>
                    <span>{negocio?.tipoCurriculo?.descricao}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>código do negócio</h6>
                    <span>{negocio?.codigo}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>hora aula</h6>
                    <span>{negocio?.horaSemanal}</span>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button id="editar-step-3" className="button-line" onClick={() => goTo("/negocio/create/step-2")}>Editar</Button>
                <>
                  <h4>Franquias</h4>
                  {negocio?.negocioUnidades.map((negocio) =>
                    <div id="user-franquias-revisao" key={negocio.id}>
                      <h4>{negocio.unidadeDescricao}</h4>
                    </div>)}
                </>
              </div>              
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button id="editar-step-4" className="button-line" onClick={() => goTo("/negocio/create/step-3")}>Editar</Button>
                <h4>Matrícula</h4>
                <div className="nome">
                  <h5>Preço Mínimo Matrícula</h5>
                  <span style={{ fontSize: 18 }}>{formatMoney(negocio?.valorMinimo)}</span>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Valor split</h6>
                    <span>{formatMoney(negocio?.valorHora)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Valor sugerido</h6>
                    <span>{formatMoney(negocio?.valorSugerido)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Quant. Parcelas</h6>
                    <span>{negocio?.numeroParcelasMaximo}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Valor split taxa</h6>
                    <span>{formatMoney(negocio?.valorSplitTaxa)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Prorrogação dias</h6>
                    <span>{negocio?.prorrogacaoDiasMatricula}</span>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button id="editar-step-4" className="button-line" onClick={() => goTo("/negocio/create/step-4")}>Editar</Button>
                <h4>Rematrícula</h4>
                <div className="nome">
                  <h5>Preço Mínimo Matrícula</h5>
                  <span style={{ fontSize: 18 }}>{formatMoney(negocio?.valorMinimoRematricula)}</span>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Valor split</h6>
                    <span>{formatMoney(negocio?.valorSplitTaxaRematricula)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Valor sugerido</h6>
                    <span>{formatMoney(negocio?.valorSugerido)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Quant. Parcelas</h6>
                    <span>{negocio?.numeroParcelasMaximoRematricula}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Valor split taxa</h6>
                    <span>{formatMoney(negocio?.valorSplitTaxaRematricula)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Prorrogação dias</h6>
                    <span>{negocio?.prorrogacaoDiasRematricula}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="botao-voltar-revisao">
        <Button id="voltar" className="button-second" icon={<ArrowLeftOutlined rev={undefined} />} onClick={() => history.goBack()}>
          Voltar
        </Button>
      </div>
    </div >
  )
}