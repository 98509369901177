import { useState } from 'react';
import {
	INegocioCleanDTO,
	IStatusCobrancaCleanDTO,
	ITipoCursoCleanDTO,
	ITipoPagamentoCleanDTO,
} from '../../../models/happy-code-api.model';
import { useTipoCursoService } from '../../../services/tipo-curso.service';
import { useNegocioService } from '../../../services/negocio.service';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useStatusCobrancaService } from '../../../services/status-cobranca.service';
import { Predicate } from '../../../models/predicate.model';
import { useTipoPagamentoService } from '../../../services/tipo-pagamento.service';

const useRelatorioState = () => {
	const tipoCursoService = useTipoCursoService();
	const negocioService = useNegocioService();
	const statusCobrancaService = useStatusCobrancaService();
	const tipoPagamentoService = useTipoPagamentoService();

	const [tipoCursos, setTipoCursos] = useState<ITipoCursoCleanDTO[]>([]);
	const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);
	const [statusCobrancas, setStatusCobrancas] = useState<IStatusCobrancaCleanDTO[]>([]);
	const [listTipoPagamento, setListTipoPagamento] = useState<
		ITipoPagamentoCleanDTO[]
	>([]);

	const findTipoCurso = () => {
		return tipoCursoService.findCleanList().then(({ data }) => {
			setTipoCursos(data);
		});
	};

	const findNegocio = async () => {
		return negocioService.findCleanList().then(({ data }) => {
			setNegocios(data);
		});
	};

	const findStatusCobranca = async () => {
		return statusCobrancaService.findCleanList().then(({ data }) => {
			setStatusCobrancas(data);
		});
	};

	const findTipoPagamento = () => {
		const tipoPagamentoPredicate = new Predicate();
		return tipoPagamentoService.findCleanList(tipoPagamentoPredicate)
			.then((response) => setListTipoPagamento(response.data));
	};

	const selectTipoCurso: ColumnFilterItem[] = tipoCursos.map((tipoCurso) => ({
		text: tipoCurso.descricao,
		value: tipoCurso.descricao,
	}));

	const selectNegocios: ColumnFilterItem[] = negocios.map((negocio) => ({
		text: negocio.descricao,
		value: negocio.descricao,
	}));

	const selectStatusCobranca: ColumnFilterItem[] = statusCobrancas.map(
		(statusCobranca) => ({
			text: statusCobranca.descricao,
			value: statusCobranca.descricao,
		}),
	);

	const selectTipoPagamento: ColumnFilterItem[] = listTipoPagamento.map(
		(statusCobranca) => ({
			text: statusCobranca.descricao,
			value: statusCobranca.descricao,
		}),
	);

	const selectTipoContrato: ColumnFilterItem[] = [
		{
			text: 'Matrícula',
			value: 'Matrícula',
		},
		{
			text: 'Rematrícula',
			value: 'Rematrícula',
		},
	];

	const selectRematriculaRealizada: ColumnFilterItem[] = [
		{
			text: 'Sim',
			value: 'OK',
		},
		{
			text: 'Não',
			value: '',
		},
	];

	const selectUpgrade: ColumnFilterItem[] = [
		{
			text: 'Sim',
			value: true,
		},
		{
			text: 'Não',
			value: false,
		},
	];

	const selectHorasContratadas: ColumnFilterItem[] = [
		{
			text: '1h30min',
			value: 90,
		},
		{
			text: '3h',
			value: 180,
		},
		{
			text: '4h30min',
			value: 270,
		},
		{
			text: '6h',
			value: 360,
		},
		{
			text: '> 7h30min',
			value: 450,
		},
	];

	const selectPossuiData: ColumnFilterItem[] = [
		{
			text: 'Possui data',
			value: true,
		},
		{
			text: 'Não possui data',
			value: false,
		},
	];

	const renderDate = (date: string): string => {
		const myDate = new Date(date);
		const day = myDate.getUTCDate();
		const month = myDate.getUTCMonth() + 1;
		const year = myDate.getUTCFullYear();
		return `${day.toString().padStart(2, '0')}/${month
			.toString()
			.padStart(2, '0')}/${year.toString()}`;
	};

	const renderCurrency = (value: number): string => {
		if (value === undefined || value === null)
			return 'R$ 0,00';

		return value.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
	};

	const renderCurrencyList = (valueList: string): string => {
		return valueList.length > 0
			? valueList
				.split(';')
				.map((value) => renderCurrency(Number(value)))
				.join(`\n`)
			: '';
	};

	return {
		selectTipoCurso,
		selectNegocios,
		selectTipoContrato,
		selectUpgrade,
		selectHorasContratadas,
		selectPossuiData,
		renderDate,
		renderCurrency,
		renderCurrencyList,
		findTipoCurso,
		findNegocio,
		selectRematriculaRealizada,
		findStatusCobranca,
		selectStatusCobranca,
		findTipoPagamento,
		selectTipoPagamento,
	};
};

export default useRelatorioState;
