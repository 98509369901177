import { SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { DatePicker, Dropdown, Input, Menu, Select } from 'antd';
import { isBefore, parseISO } from 'date-fns';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { ModalFinanceiroSede } from '../../components/ModalFinanceiroSede';
import NewMenu from "../../components/NewMenu";
import { TagsFinanceiro } from '../../components/TagsFinanceiro';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';

import { ILogCleanDTO, IStatusCobrancaCleanDTO, ITaxaCobrancaCustomDTO, ITaxaCobrancaKpiDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useStatusCobrancaService } from '../../services/status-cobranca.service';
import { useTaxaCobrancaService } from '../../services/taxa-cobranca.service';
import { daysBetween, formatMomentLocalDate, formatMoney, isAfterDate } from '../../util/format';
import './style.css';
import { useLogService } from '../../services/log.service';
import { ModalBaixa } from '../../components/ModalFinanceiro/modal-baixa';

const { RangePicker } = DatePicker;

export const FinanceiroSede: React.FunctionComponent = () => {

  const [predicate] = useState<Predicate>(new Predicate());
  const [kpiPredicate] = useState<Predicate>(new Predicate());
  const [data, setData] = useState<PageableResponse<ITaxaCobrancaCustomDTO>>();
  const [taxaCobrancaSelected, setTaxaCobrancaSelected] = useState<ITaxaCobrancaCustomDTO>();
  const [listStatusCobranca, setListStatusCobranca] = useState<IStatusCobrancaCleanDTO[]>();
  const [statusCobrancaId, setStatusCobrancaId] = useState<string>();
  // const [competencia, setCompetencia] = useState<string>();
  const [kpi, setKpi] = useState<ITaxaCobrancaKpiDTO>(null);
  const [descricao, setDescricao] = useState<string>();
  const [dataInicio, setDataInicio] = useState<moment.Moment>();
  const [dataFim, setDataFim] = useState<moment.Moment>();
  const dataAtual = formatMomentLocalDate(moment(Date()));
  const [cobrancaSelected, setCobrancaSelected] = useState<ITaxaCobrancaCustomDTO>();
  const [isOpenBaixa, setIsOpenBaixa] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState(false);
  // const [isOpen1, setIsOpen1] = useState(false);

  const taxaCobrancaService = useTaxaCobrancaService();
  const statusCobrancaService = useStatusCobrancaService();
  const { setIsGlobalLoading } = useGlobalContext();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso financeiro sede",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    logService.registraLog(log)
  }, [])

  const findKpi = () => {
    return taxaCobrancaService.getKpi(kpiPredicate)
      .then((response) => {
        setKpi(response.data);
      });
  }

  const findTaxaCobranca = () => {
    setIsGlobalLoading(true);
    findKpi();
    return taxaCobrancaService.findCustom(predicate)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  }

  const findStatusCobranca = () => {
    return statusCobrancaService.findCleanList(predicate)
      .then((response) => {
        setListStatusCobranca(response.data);
      });
  }

  const abrirNegociacao = (taxaCobranca) => {
    setTaxaCobrancaSelected(taxaCobranca);
    setIsOpen(true);
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    Promise.all([
      findTaxaCobranca(),
      findStatusCobranca(),
    ]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusCobrancaId && statusCobrancaId !== 'TDS' && statusCobrancaId !== 'VNC') {
      predicate.addOption('statusCobrancaId', statusCobrancaId);
      kpiPredicate.addOption('statusCobrancaId', statusCobrancaId);
    } else {
      predicate.removeOption('statusCobrancaId');
      kpiPredicate.removeOption('statusCobrancaId');
    }

    if (statusCobrancaId && statusCobrancaId === 'VNC') {
      predicate.addMultiOption('statusCobrancaId', 1, Operators.OR);
      predicate.addMultiOption('statusCobrancaId', 2, Operators.OR);
      predicate.addMultiOption('statusCobrancaId', 7, Operators.OR);
      kpiPredicate.addMultiOption('statusCobrancaId', 1, Operators.OR);
      kpiPredicate.addMultiOption('statusCobrancaId', 2, Operators.OR);
      kpiPredicate.addMultiOption('statusCobrancaId', 7, Operators.OR);
      predicate.addOption('dataVencimento', dataAtual, Operators.LESS_THAN);
      kpiPredicate.addOption('dataVencimento', dataAtual, Operators.LESS_THAN);
    }
    findTaxaCobranca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCobrancaId]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao) {
        predicate.addOption('unidade.descricao', descricao, Operators.CONTAINS);
        kpiPredicate.addOption('unidade.descricao', descricao, Operators.CONTAINS);
      } else {
        predicate.removeOption('unidade.descricao');
        kpiPredicate.removeOption('unidade.descricao');
      }
      findTaxaCobranca();
    }, 800);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);


  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      predicate.removeOption('dataVencimento');
      kpiPredicate.removeOption('dataVencimento');
      if ((dataInicio || dataFim)) {
        predicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
        predicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
        kpiPredicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
        kpiPredicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
      }
      findTaxaCobranca();
    }, 800);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInicio, dataFim]);

  const finalizaBaixa = () => {
    setIsOpenBaixa(false);
  }

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findTaxaCobranca();
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findTaxaCobranca();
  }

  const getPeriodo = (): any => {
    if (dataInicio && dataFim) {
      return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')]
    }
    return null;
  }

  const abrirBaixaCobranca = (taxaCobranca) => {
    setCobrancaSelected(taxaCobranca);
    setIsOpenBaixa(true);
  }

  const itensDropdown = (taxaCobranca: ITaxaCobrancaCustomDTO) => {
    return (
      <Menu>

        <Menu.Item>
          <a target="blank" href={`/checkout-sede/${taxaCobranca.codigoPedido + (moment(taxaCobranca.dataVencimento).isAfter(Date.now()) ? '' : '/true')}`} rel="noopener noreferrer">
            Emitir 2ª. via
          </a>
        </Menu.Item>

        {moment(taxaCobranca.dataVencimento).isBefore(Date.now()) && (taxaCobranca.taxaFranquia === null || taxaCobranca.taxaFranquia === false) ?
          <Menu.Item>
            <a target="blank" href={`/checkout-sede/${taxaCobranca.codigoPedido}`} rel="noopener noreferrer">
              Emitir 2ª. via - sem multa de atraso
            </a>
          </Menu.Item> : null}
        <Menu.Item>
          <a onClick={() => abrirNegociacao(taxaCobranca)} rel="noopener noreferrer">
            Renegociação
          </a>
        </Menu.Item>
        {/* <Menu.Item>
          <a onClick={() => abrirCancelamento(taxaCobranca)} rel="noopener noreferrer">
            Cancelamento
          </a>
        </Menu.Item> */}
        {(taxaCobranca?.statusCobranca.codigo === "AGR" || taxaCobranca?.statusCobranca.codigo === "GRD" || taxaCobranca?.statusCobranca.codigo === "FLH")
          ?
          <Menu.Item>
            <a onClick={() => (abrirBaixaCobranca(taxaCobranca))} rel="noopener noreferrer">
              Baixar cobrança
            </a>
          </Menu.Item>
          : null}
      </Menu>
    )
  }

  return (
    <div className="container">
      <NewMenu selecionado={9} />
      <div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
        {kpi ? (
          <div className="box-title" style={{ marginTop: 25 }}>
            <div id="turma">
              <h3 className="title">Financeiro</h3>
              <h6>Saldo total</h6>
              <h4>{formatMoney(kpi.valorTotal)}</h4>
            </div>
            <div className="boxes-financeiro">
              <div className="box-financeiro">
                <div className="icon">
                  <Unicons.UilCheck size="18" color="#1EBE5E" />
                </div>
                <div className="pago">
                  <h3>{formatMoney(kpi.valorEmDia)}</h3>
                  <h4>Pagos em dia</h4>
                </div>
              </div>
              <div className="box-financeiro">
                <div className="icon">
                  <Unicons.UilCalendarAlt size="18" color="#1EBE5E" />
                </div>
                <div className="pago">
                  <h3>{formatMoney(kpi.valorEmAtraso)}</h3>
                  <h4>Pagos em atraso</h4>
                </div>
              </div>
              <div className="box-financeiro">
                <div className="icon-aberto">
                  <Unicons.UilClockNine size="18" color="#FF975B" />
                </div>
                <div className="pago">
                  <h3>{formatMoney(kpi.valorEmAberto)}</h3>
                  <h4>Gerado</h4>
                </div>
              </div>
              <div className="box-financeiro">
                <div className="icon-vencido">
                  <Unicons.UilExclamationTriangle size="18" color="#FF4D4F" />
                </div>
                <div className="pago">
                  <h3>{formatMoney(kpi.valorVencido)}</h3>
                  <h4>Vencidos</h4>
                </div>
              </div>
              <div className="box-financeiro">
                <div className="icon-vencido">
                  <Unicons.UilExclamationTriangle size="18" color="#FF4D4F" />
                </div>
                <div className="pago">
                  <h3>{formatMoney(kpi.valorCancelado)}</h3>
                  <h4>Cancelado</h4>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="filtros">
          <div className="input-text" style={{ flex: 1 }}>
            <Input value={descricao} onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
          </div>
          <div className="filtro-estado input-dashboard">
            <span>Vencimento</span>
            <RangePicker value={getPeriodo()} onChange={(values) => { setDataInicio(values && values[0]); setDataFim(values && values[1]); }} format={'DD-MM-YYYY'} style={{ marginLeft: 10 }} />
            {/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
          </div>
          <div className="filtro-estado input-dashboard" style={{ width: 'auto' }}>
            <span>Status</span>
            <Select defaultValue="TDS" onChange={value => setStatusCobrancaId(value.toString())} style={{ width: '100%' }}>
              <Select.Option value="TDS">Todos</Select.Option>
              <Select.Option value="VNC">Vencidos</Select.Option>
              {listStatusCobranca && listStatusCobranca.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.descricao}</Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '19%' }}>
              <span>FRANQUIA</span>
              <div className="arrows" onClick={() => ordenar('unidade.descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '13%' }}>
              <span>CIDADE/ESTADO</span>
              <div className="arrows" onClick={() => ordenar('unidade.enderecoCidade')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '12%' }}>
              <span>GESTOR</span>
              <div className="arrows" onClick={() => ordenar('unidade.gestorNome')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '8%' }}>
              <span>TIPO</span>
              <div className="arrows" onClick={() => ordenar('taxaRenegociacaoOriginada')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>REFERENCIA</span>
              <div className="arrows" onClick={() => ordenar('competencia')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>VALOR</span>
              <div className="arrows" onClick={() => ordenar('valorCobranca')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '13%' }}>
              <span>STATUS</span>
              <div className="arrows" onClick={() => ordenar('statusCobranca.codigo')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>DIAS VENCIDOS</span>
              <div className="arrows" onClick={() => ordenar('dataVencimento')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
          </div>
          {data && data.content.map((taxaCobranca, index) => (
            <div key={index} className="registro-user" id="registro-financeiro">
              <div className="user" id="cursos" style={{ width: '19%' }}>
                <div className="nome" style={{ marginLeft: 10 }}>
                  <p>{taxaCobranca.unidade.descricao}</p>
                  {/* <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">{taxaCobranca.ativo ? 'Ativo' : 'Desativado'}</p>
                  </div> */}
                </div>
              </div>
              <div className="funcao-user" style={{ width: '13%' }}>
                <p>{taxaCobranca.unidade.enderecoCidade}/{taxaCobranca.unidade.enderecoNomeUf}</p>
              </div>
              <div className="funcao-user" id="gestor-color" style={{ width: '12%' }}>
                <p>{taxaCobranca.unidade.gestorNome}</p>
              </div>
              <div className="funcao-user" style={{ width: '8%' }}>
                <p>{taxaCobranca.taxaRenegociacaoOriginada ? 'Renegociação' : 'Normal'}</p>
              </div>
              <div className="funcao-user" style={{ width: '10%' }}>
                <p>{taxaCobranca.competencia}</p>
              </div>
              <div className="funcao-user" style={{ width: '10%' }}>
                <p>{formatMoney(taxaCobranca.valorCobranca)}</p>
              </div>
              <div className="funcao-user" style={{ width: '13%' }}>
                {
                  taxaCobranca?.statusCobranca?.codigo === 'GRD' && isAfterDate(taxaCobranca.dataVencimento) ?
                    <TagsFinanceiro codigo="VENCIDO" descricao="Vencido"></TagsFinanceiro>
                    : taxaCobranca?.statusCobranca?.codigo === 'PGO' && isBefore(parseISO(taxaCobranca?.dataPagamento?.toString()), parseISO(taxaCobranca.dataVencimento.toString())) ?
                      <TagsFinanceiro codigo="PGOATRASO" descricao={taxaCobranca?.statusCobranca?.descricao}></TagsFinanceiro>
                      : <TagsFinanceiro codigo={taxaCobranca?.statusCobranca?.codigo} descricao={taxaCobranca?.statusCobranca?.descricao}></TagsFinanceiro>
                }
              </div>
              <div className="funcao-user" style={{ width: '12%' }}>
                <p>{daysBetween(taxaCobranca.dataVencimento)}</p>
              </div>
              {taxaCobranca.statusCobranca.codigo === 'AGR' || taxaCobranca.statusCobranca.codigo === 'GRD' ? (
                <div className="funcao-user" style={{ width: '3%' }}>
                  <Dropdown overlay={itensDropdown(taxaCobranca)} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                  </Dropdown>
                </div>
              ) : null}
            </div>
          ))}
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
      <ModalFinanceiroSede
        open={isOpen}
        taxaCobranca={taxaCobrancaSelected}
        onClose={() => setIsOpen(false)}
      />
      <ModalBaixa
        open={isOpenBaixa}
        onClose={finalizaBaixa}
        id={cobrancaSelected?.id}
        escola={false}
      />
    </div>
  );
}