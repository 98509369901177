import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import ModalCreateUser from '../../components/ModalCreateUser';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';

import {
	ILogCleanDTO,
	IUsuarioDTO
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useUsuarioService } from '../../services/usuario.service';
import Filtros from './filtros';
import ItemList from './item-lista';
import './style.css';
import { useLogService } from '../../services/log.service';


export const Usuarios: React.FunctionComponent = () => {
	const { setRevisao, removeUsuario } = useUsuarioContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState<PageableResponse<IUsuarioDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());

	const userService = useUsuarioService();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: "Acesso Usuarios",
		descricaoTipo: "Acesso a tela",
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true
	});

	useEffect(() => {
		logService.registraLog(log)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		removeUsuario();
		setRevisao(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const findUsuario = () => {
		setIsGlobalLoading(true);
		userService.find(predicate)
			.then(({ data }) => setData(data))
			.finally(() => setIsGlobalLoading(false));
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findUsuario();
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findUsuario();
	}

	return (
		<div className="container">
			<NewMenu selecionado={3} />
			<div className="dashboard" style={{ paddingTop: 40, height: '100vh', overflowY: 'scroll' }}>
				<div className="box-title">
					<h3 className="title">Usuários ({data?.totalElements})</h3>
					<Button id="criar-usuario" className="button-primary" icon={<PlusOutlined rev={undefined} />} onClick={() => setIsOpen(true)}>Criar Usuário</Button>
					{/* <Button
						className="button-primary"
						onClick={() => setIsOpen(true)}
						icon={<PlusOutlined rev={undefined} />}
					>
						Adicionar Usuário
					</Button> */}
				</div>
				<Filtros findUsuario={findUsuario} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '20%' }}>
							<span>NOME</span>
							<div className="arrows" onClick={() => ordenar('pessoa.nome')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>FUNÇÃO</span>
							<div className="arrows" onClick={() => ordenar('funcao.descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>TIPO DE USUÁRIO</span>
							<div className="arrows" onClick={() => ordenar('perfil.descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '35%' }}>
							<span>ACESSO ACADEMY</span>
							<div className="arrows" onClick={() => ordenar('perfil.descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>FILTRAR PRODUTO PELO STEMPLAY</span>
							<div className="arrows" onClick={() => ordenar('instrutorStemplay')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						{/* <div className="item" style={{ width: '30%' }}>
							<span>FRANQUIA</span>
							<div className="arrows">
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div> */}
						<div className="item" style={{ width: '5%' }}></div>
					</div>
					{data && data.content.map((usuario, index) => (
						<ItemList usuario={usuario} key={index} />
					))}
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
			{isOpen && <ModalCreateUser open={isOpen} onClose={() => setIsOpen(false)} />}
		</div>
	);
};
