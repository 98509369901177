import * as Unicons from '@iconscout/react-unicons';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { InputNumber } from 'antd';
import { useTaxaCobrancaService } from '../../services/taxa-cobranca.service';
import { useNotificationService } from '../../services/notification.service';
import { currencyFormatter, currencyParser } from '../../util/format';
import { ITaxaCobrancaCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';

interface Text {
    idUnidade: number;
    onClose: () => void;
    open: boolean;
}

export const ModalTaxa: React.FunctionComponent<Text> = ({ idUnidade, onClose, open }: Text) => {
    if (!open) return null

    const { setIsGlobalLoading } = useGlobalContext();
    const [valor, setValor] = useState<number>();    
    const notification = useNotificationService();

    const taxaCobrancaService = useTaxaCobrancaService();

    const gerarTaxa = () => {
        setIsGlobalLoading(true);
        taxaCobrancaService.gerarTaxa(idUnidade, valor).then(() => {
            notification({ description: "Taxa gerada com sucesso!", type: 'success', message: 'Sucesso!' }), onClose() }
        ).catch((error) =>
            console.log(error)
        ).finally(() => { setIsGlobalLoading(false), onClose() });
    }
    
    return (
        <div className="modalContainer">
            <div className="modal-box-upload-material" style={{ maxHeight: 250 }}>
                <button onClick={onClose} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
                <h3>Cadastro de taxa</h3>
                <div className="line" />
                < div style={{ maxHeight: 300, width: "100%", margin: "auto", padding: 20 }}>
                    <div className="input-box" id="box-aluno">
                        <span>Valor da taxa</span>
                        <InputNumber                            
                            min={0}
                            onChange={(e) => setValor(e)}
                            value={valor}
                            formatter={currencyFormatter}
                            parser={currencyParser}
                            decimalSeparator=','
                            
                        />
                    </div>
                </div>

                <div className="line" />
                <div className="botoes">
                    <button onClick={onClose} className="btn-cancel" id="cancelar">Cancelar</button>
                    <button onClick={() => gerarTaxa()} className="btn-make" id="criar-usuario">Gerar taxa</button>
                </div>
            </div>
        </div >
    )
}