import {
	IServicoAcademicoCleanDTO,
	IServicoAcademicoDTO,
	IServicoAcademicoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useGrupoServicoAcademicoService = () => {
	const resourceURL = 'servicoacademico';

	return {
		...baseFindList<IServicoAcademicoCleanDTO, IServicoAcademicoDTO, IServicoAcademicoFullDTO>(resourceURL),
	};
};
