import { useAlunoContext } from "../../../context/AlunoContext";
import { ContatoEnum } from "../../../enum/contato.enum";

const useContato = () => {
    const { aluno, setAluno } = useAlunoContext();

    const handleContato = (idTipo: number, value: string, respIndex) => {
        const responsavelList = [...aluno.pessoa.pessoaResponsaveis];

        const alunoToUpdateIndex = responsavelList[respIndex].responsavel.contatos.findIndex((c) => c.tipoContatoId === idTipo);

        if (idTipo === ContatoEnum.EPS) {
            responsavelList[respIndex].responsavel.email = value;
        }

        if (alunoToUpdateIndex !== -1) {
            responsavelList[respIndex].responsavel.contatos[alunoToUpdateIndex].descricao = value;
        } else {
            responsavelList[respIndex].responsavel.contatos.push({
                id: null,
                descricao: value,
                ativo: true,
                codigo: '',
                tipoContatoCodigo: '',
                tipoContatoId: idTipo,
            });
        }

        setAluno({
            ...aluno,
            pessoa: { ...aluno.pessoa, pessoaResponsaveis: responsavelList },
        });
    };

    return { handleContato };
};

export default useContato;
