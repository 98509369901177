import http from '../helpers/http-common';
import {
	ITaxaFixaCleanDTO,
	ITaxaFixaDTO,
	ITaxaFixaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useTaxaFixaService = () => {
	const resourceURL = 'taxa-fixa';

	return {
		...baseFind<ITaxaFixaCleanDTO, ITaxaFixaDTO, ITaxaFixaFullDTO>(resourceURL),
		...baseFindById<ITaxaFixaCleanDTO, ITaxaFixaDTO, ITaxaFixaFullDTO>(
			resourceURL
		),
		createFull: (body): Promise<IAxiosResponseCustom<ITaxaFixaFullDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patchFull: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<ITaxaFixaCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
