import { useCallback, useEffect, useState } from 'react';
import http from '../../../helpers/http-common';
import { useGlobalContext } from '../../../context/GlobalContext';
import * as pbi from 'powerbi-client';

interface ResponseTokenPowerBi {
	data: {
		access_token: string;
	};
}

export default function useDashboardPowerBi() {
	const [tokenPowerBi, setTokenPowerBi] = useState('');

	const getPowerBiToken = useCallback(async (): Promise<void> => {
		return await http
			.get('token-power-bi')
			.then(({ data }: ResponseTokenPowerBi) =>
				setTokenPowerBi(data.access_token)
			);
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		getPowerBiToken();
		return () => controller.abort();
	}, [getPowerBiToken]);

	const {
		unidade: { id },
	} = useGlobalContext();

	const [report, setReport] = useState(null);

	const handleReportLoad = (report) => {
		console.log(report);

		const filter = {
			$schema: 'http://powerbi.com/product/schema#basic',
			target: {
				table: 'DIM_UNIDADE',
				column: 'ID_UNIDADE',
			},
			operator: 'In',
			values: [id],
		};

		report.setFilters([filter]).catch(function (errors) {
			console.log(errors);
		});

		report.getFilters().then((allTargetFilters) => {
			allTargetFilters.push(filter);

			report.setFilters(allTargetFilters);
		});

		setReport(report);
	};

	const handleReportRender = (report) => setReport(report);

	const setFullscreen = () => report && report.fullscreen();

	const printReport = () => report && report.fullscreen();

	const extraSettings = {
		filterPaneEnabled: false,
		navContentPaneEnabled: false,
	};

	return {
		handleReportLoad,
		setFullscreen,
		printReport,
		extraSettings,
		tokenPowerBi,
		handleReportRender,
	};
}
