import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { useAlunoService } from '../../services/aluno.service';
import { useCrmLeadService } from '../../services/crm-lead.service';
import { getAge } from '../../util/util';
import ModalCreateAluno from '../ModalCreateAluno';
import { Avatar } from './../Avatar/index';
import './style.css';

interface Props {
	id?: number;
}

export const MenuDetalheAluno: React.FunctionComponent<Props> = ({
	id,
}: Props) => {
	const { aluno, removeAluno, lead } = useAlunoContext();
	const { setIsGlobalLoading } = useGlobalContext();
	const [isOpen, setIsOpen] = useState(false);

	const history = useHistory();

	const alunoService = useAlunoService();
	const crmLeadService = useCrmLeadService();

	const save = () => {
		setIsGlobalLoading(true);
		if (id) {
			alunoService.patch(aluno)
				.then(() => {
					setIsOpen(true);
				})
				.catch((err) => console.log({ err }))
				.finally(() => setIsGlobalLoading(false))
		} else {
			alunoService.create(aluno)
				.then((response) => {
					if (lead?.id) {
						crmLeadService.atualizarLeadAluno(lead.id, response.data?.id);
					}
					setIsOpen(true);
				})
				.catch((err) => console.log({ err }))
				.finally(() => setIsGlobalLoading(false))
		}
	};

	const novoAluno = () => {
		removeAluno();
		history.push('/alunos/create/step-1');
	}

	return (
		<div className="menu-box-revisao-float" id="menu-colaborador">
			<div className="bloco-1">
				{/* <Unicons.UilEllipsisH
					size="20"
					color="rgba(52, 68, 83, 0.5)"
					className="mas"
				/> */}
				<Avatar size={115} arquivo={aluno?.pessoa?.foto} />
				<h1 id="title">{aluno.pessoa.nome || 'Alexandre Ramos Silva'}</h1>
				<div id="standy-campanha">
					<div id="dot-green-aluno"></div>
					<span id="green">Ativo</span>
				</div>
				<h6 style={{ marginBottom: 0 }}>E-mail Aluno</h6>
				<span>{aluno.emailAcesso}</span>
				<h6 style={{ marginBottom: 0 }}>Idade</h6>
				<span>
					{aluno.pessoa.dataNascimento
						? getAge(aluno.pessoa.dataNascimento)
						: 'N/A'}
				</span>
				<h6 style={{ marginBottom: 0 }}>Observações</h6>
				<span>{aluno.observacao || 'N/A'}</span>
				{id && (
					<Button className="button-line" icon={<EditOutlined rev={undefined} />}>
						Editar Aluno
					</Button>
				)}
				<Button className="button-primary" onClick={() => save()} style={{ marginTop: 10 }}>
					Adicionar Aluno
				</Button>
			</div>
			<ModalCreateAluno
				open={isOpen}
				title="Aluno"
				textButton1="Voltar para tela inicial"
				textButton2="Novo aluno"
				styleButton1="button-line"
				styleButton2="button-primary"
				onClose={novoAluno}
				link2="/alunos/create/step-1"
				link1="/alunos"
			/>
		</div>
	);
};
