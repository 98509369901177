import * as Unicons from '@iconscout/react-unicons';
import './style.css';

const MenuDocumentos: React.FunctionComponent = () => {
  return (
    <div className="menu-documentos-box">
      <h5>Edição</h5>
      <p>Documentos da franquia</p>
      <div className="documento">
        <div className="check">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>COF</p>
      </div>
      <div className="documento">
        <div className="check-red">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Contrato</p>
      </div>
      <div className="documento">
        <div className="check">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Certidão Negativa de Débito da Receita Federal e Estadual</p>
      </div>
      <div className="documento">
        <div className="check">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Certidão Negativa da Prefeitura</p>
      </div>
      <div className="documento">
        <div className="check">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Certidão dos Distribuidores Fiscal, Civel, Trabalhista eCriminal nas esferas Estadual e Federal</p>
      </div>
      <div className="documento">
        <div className="check-red">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Certidão de Protesto</p>
      </div>
      <div className="documento">
        <div className="check">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Antecedentes criminais</p>
      </div>
      <div className="documento">
        <div className="check">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Declaração de Imposto de Renda dos últimos 2 anos</p>
      </div>
      <div className="documento">
        <div className="check-red">
          <Unicons.UilCheck size="16" color="#FFFFFF" />
        </div>
        <p>Laudo de vistoria dos bombeiros</p>
      </div>
    </div>
  );
}

export default MenuDocumentos;