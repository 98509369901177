import {
	IFuncaoCleanDTO,
	IFuncaoDTO,
	IFuncaoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useFuncaoService = () => {
	const resourceURL = 'funcao';

	return {
		...baseFindList<IFuncaoCleanDTO, IFuncaoDTO, IFuncaoFullDTO>(resourceURL),
		...baseFind<IFuncaoCleanDTO, IFuncaoDTO, IFuncaoFullDTO>(resourceURL),
	};
};
