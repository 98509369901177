import { AxiosResponse } from "axios";
import { IRelNotaUnidadeCleanDTO, IRelNotaUnidadeDTO, IRelNotaUnidadeFullDTO } from "../models/happy-code-api.model";
import { Predicate, formatHttpParams } from "../models/predicate.model";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from './base/base-find.service';
import { PageableResponse } from "../models/response.model";
import http from "../helpers/http-common";

export const useRelNotaUnidadeService = () => {
  const resourceURL = 'rel-nota-unidade';


  const findCustom = (
    predicate: Predicate = new Predicate(), idUnidade: number
  ): Promise<AxiosResponse<PageableResponse<IRelNotaUnidadeDTO>>> =>
    http.get<PageableResponse<IRelNotaUnidadeDTO>>(resourceURL + `/custom/unidade/${idUnidade}`, formatHttpParams(predicate));
  return {
    ...baseFindList<IRelNotaUnidadeCleanDTO, IRelNotaUnidadeDTO, IRelNotaUnidadeFullDTO>(resourceURL),
    ...baseFind<IRelNotaUnidadeCleanDTO, IRelNotaUnidadeDTO, IRelNotaUnidadeFullDTO>(resourceURL),
    findCustom,
  };
}