import { IDiaSemana } from '../models/happy-code-api.model';

export const WEEK_DAYS_ACRONYMS: IDiaSemana[] = [
	'SEG',
	'TER',
	'QUA',
	'QUI',
	'SEX',
	'SAB',
];
