import * as Unicons from '@iconscout/react-unicons';
import { FormInstance } from "antd";
import { useCrmEscolaContext } from '../../context/crm-escola.context';
import './botoes-cadastro.component.styles.scss';

interface Props {
  paginaAtual?: number,
  form: FormInstance;

}
const BotoesCadastroComponent = () => {

  const { onCancelarCadastro, onVoltarStep, indexCadastro } = useCrmEscolaContext();

  return <div id='contaner-botao-cadastro'>
    {indexCadastro === 1 && <button id="botao-cancelar-cadastro-crm" onClick={onCancelarCadastro}>
      Cancelar
    </button>}

    {indexCadastro > 1 && <button id="botao-cancelar-cadastro-crm" onClick={onVoltarStep}>
      Voltar
    </button>}

    <button type="submit" id="botao-avancar-cadastro-crm-ativo" >
      {indexCadastro === 5 ? 'Concluir' : 'Avançar'}
      <Unicons.UilArrowRight color='white' />
    </button>
  </div>
}

export default BotoesCadastroComponent;
