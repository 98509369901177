import { AxiosResponse } from 'axios';
import {
	IAulaCleanDTO, IAulaFullDTO
} from '../models/happy-code-api.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import http from './../helpers/http-common';
import { IAulaDTO } from './../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';
import { baseFindList } from './base/base-find-list.service';

export const useAulaService = () => {
	const resourceURL = 'aula';

	const findByUnidadeId = (
		id: number,
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<IAulaDTO[]>> =>
		http.get<IAulaDTO[]>(
			resourceURL + `/unidade/${id}`,
			formatHttpParams(predicate)
		);

	const findByTurmaUnidadeIdAndPessoaId = (
		idUnidade: number,
		idPessoa: number,
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<IAulaDTO[]>> =>
		http.get<IAulaDTO[]>(
			resourceURL + `/unidade/${idUnidade}/pessoa/${idPessoa}`,
			formatHttpParams(predicate)
		);

	const findCustom = (
		predicate: Predicate = new Predicate()
	): Promise<any> => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	};

	const findUnidadeCustom = (
		idUnidade: number,
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<IAulaDTO[]>> =>
		http.get<IAulaDTO[]>(
			resourceURL + `/unidade/${idUnidade}`, formatHttpParams(predicate)
		);

	return {
		...baseCreate<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
		...baseFind<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
		...baseFindList<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
		...baseFindById<IAulaCleanDTO, IAulaDTO, IAulaFullDTO>(resourceURL),
		findCustom,
		findByUnidadeId,
		findByTurmaUnidadeIdAndPessoaId,
		findUnidadeCustom
	};
};
