import EditIcon from '@mui/icons-material/Edit';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, DatePicker, Progress, Select, Switch } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BoxOffer } from '../../components/BoxOffer';
import { CalendarOffer } from '../../components/Calendar';
import Header from '../../components/Header';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import '../../global.css';
import useCheckIfPageIsUpgrade from '../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';
import { ICurriculoCleanDTO, IHoraUnidadeCleanDTO, IPessoaCustomDTO, ITurmaCustomDTO, ITurmaOfferDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useCurriculoService } from '../../services/curriculo.service';
import { useHoraUnidadeService } from '../../services/hora-unidade.service';
import { useNotificationService } from '../../services/notification.service';
import { usePessoaService } from '../../services/pessoa.service';
import { useTurmaService } from '../../services/turma.service';
import { formatHora, formatMoney } from '../../util/format';
import { renderMinutesToHour } from '../../util/render-minutes-to-hour.util';
import { getAge } from '../../util/util';
import './style.css';
import useOffer from './use-offer.hook';
import useOffersObject from './hooks/use-offers-object.hook';

export const OfferPage = () => {
	const { offersObjectArray } = useOffersObject();
	const { checkFiltro, offersChipFilter, removeFiltros, isCheckedState, offerFilterActions } = useOffer();
	const { unidade, setIsGlobalLoading } = useGlobalContext();
	const { matricula, setMatricula } = useAlunoContext();
	const { isUpgradePage } = useCheckIfPageIsUpgrade();
	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();
	const history = useHistory();

	//const [listPessoa, setListPessoa] = useState<IPessoaCustomDTO[]>();
	const [isOpen, setIsOpen] = useState(false);
	const [turmaSemana, setTurmaSemana] = useState<ITurmaCustomDTO>();
	const [predicateTurma] = useState<Predicate>(new Predicate());
	const [checkedFaixaEtaria, setCheckedFaixaEtaria] = useState(false);
	const [oferta, setOferta] = useState<IHoraUnidadeCleanDTO>();
	const [listCurriculo, setListCurriculo] = useState<ICurriculoCleanDTO[]>();
	const [idCurriculo, setIdCurriculo] = useState<number>(-1);
	const [dataInicial, setDataInicial] = useState<moment.Moment>();
	const [dataFinal, setDataFinal] = useState<moment.Moment>();
	const [horaInicialFiltro, setHoraInicioFiltro] = useState('tds');

	const pessoaService = usePessoaService();
	const turmaService = useTurmaService();
	const horaUnidadeService = useHoraUnidadeService();
	const curriculoService = useCurriculoService();
	const notification = useNotificationService();

	const findCurriculo = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('turmas.unidade.id', unidade?.id);
		curriculoService.findCleanList(predicate).then((response) => setListCurriculo(response.data));
	};

	const findPessoa = () => {
		const pessoaPredicate = new Predicate();
		return pessoaService.findCustomResponsavel(pessoaPredicate, matricula?.aluno?.pessoa?.id).then((response) => {
			//setListPessoa(response.data);
			if (response.data?.length > 0) {
				//matricula.responsavelFinanceiro = response.data[0];
				setMatricula((prev) => ({
					...prev,
					responsavelFinanceiro: response.data[0],
				}));
			}
		});
	};

	const filterCoursesOtherThanRegular = useCallback((turmas: ITurmaCustomDTO) => {
		const daysOfTheWeek: string[] = Object.keys(turmas);

		for (const day of daysOfTheWeek) {
			const turmasOfThisDay = turmas[day];

			for (const turma of turmasOfThisDay) {
				if (turma.turma.curriculo.negocio.tipoCurriculo.codigo !== 'RGL') turmasOfThisDay.splice(turmasOfThisDay.indexOf(turma), 1);
			}
		}

		return turmas;
	}, []);

	const findTurmas = () => {
		setIsGlobalLoading(true);
		predicateTurma.addOption('ativo', true);
		predicateTurma.addOption('unidade.id', unidade.id);

		return turmaService
			.findTurmaSemana(null, unidade.id, predicateTurma)
			.then((response) => {
				setTurmaSemana(response.data);
				if (isUpgradePage) {
					const filteredTurmas = filterCoursesOtherThanRegular(response.data);
					setTurmaSemana(filteredTurmas);
				}
			})
			.catch((e) => console.log(e))
			.finally(() => setIsGlobalLoading(false));
	};

	const findHoraVenda = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		horaUnidadeService.findClean(predicate).then((response) => setOferta(response.data.content[0]));
	};

	const calcularMinutosDeAlmoco = (): number => {
		const horaInicio = unidade.horaAlmocoInicio;
		const horaFim = unidade.horaAlmocoFim;

		const partesInicio = [horaInicio.getHours().toString(), horaInicio.getMinutes().toString()];
		const partesFim = [horaFim.getHours().toString(), horaFim.getMinutes().toString()];

		const minutosInicio = parseInt(partesInicio[0]) * 60 + parseInt(partesInicio[1]);
		const minutosFim = parseInt(partesFim[0]) * 60 + parseInt(partesFim[1]);

		return minutosFim - minutosInicio - unidade.intervalo;
	};

	const criarGradeAPartirDaPrimeiraHoraDaFranquia = () => {
		const primeiroHorario = formatHora(unidade?.horaAulaInicio);
		const horaFechamentoHub = formatHora(unidade?.horaFechamento);
		const intervalo = unidade.intervalo;
		const horarioDaGrade = [
			{
				horaInicial: null,
				horaFinal: null,
			},
		];

		const horasDeAtuacao = parseInt(horaFechamentoHub?.toString()) - parseInt(primeiroHorario);

		const maximoAulaDia = ((horasDeAtuacao * 60) / (90 + intervalo)).toFixed();

		let ultimaHoraInformada = primeiroHorario;
		for (let i = 0; i < parseInt(maximoAulaDia); i++) {
			if (ultimaHoraInformada === unidade?.horaAlmocoInicio?.toString().substring(0, 5)) {
				const minutosAlmoco = calcularMinutosDeAlmoco();
				const horaFimAlmoco = new Date(`2023-09-14T${ultimaHoraInformada}`);
				horaFimAlmoco.setMinutes(horaFimAlmoco.getMinutes() + minutosAlmoco);
				ultimaHoraInformada = horaFimAlmoco.toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				});
			}
			const horaFim = new Date(`2023-09-14T${ultimaHoraInformada}`);
			horaFim.setHours(horaFim.getHours() + 1);
			horaFim.setMinutes(horaFim.getMinutes() + 30);
			const novaHoraFim = horaFim.toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit',
			});

			const horario = {
				horaInicial: ultimaHoraInformada,
				horaFinal: novaHoraFim,
			};

			horarioDaGrade.push(horario);
			horaFim.setMinutes(horaFim.getMinutes() + intervalo);
			ultimaHoraInformada = horaFim.toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit',
			});
		}
		return horarioDaGrade;
	};
	const OpenFiltros = () => {
		setIsOpen(!isOpen);
	};

	const handleResponsavel = () => {
		history.push('/matriculas/create/step-2');
	};

	const validaOfertas = () => {
		if (
			!oferta?.valorOfertaUmaHoraMeia &&
			!oferta?.valorOfertaTresHora &&
			!oferta?.valorOfertaQuatroHoraMeia &&
			!oferta?.valorOfertaSeisHora &&
			!oferta?.valorAdicionalOferta
		) {
			return notification({
				description: `Não é possível prosseguir pois um dos cursos não possui oferta informada.`,
				type: 'warning',
				message: 'Aviso!',
			});
		}

		const nextUpgradePage = isUpgradePage ? idFinanceiro : '';

		history.push(`/matriculas/create/step-3/${nextUpgradePage}`);
	};

	const voltaDetalhe = () => {
		history.push('/alunos/detalhe');
	};

	const calcularTotalValorCurriculoSemDesconto = () => {
		let valorTotal = 0;
		const duracaoTotal = 0;

		matricula?.turmas?.forEach((turma) => {
			if (turma?.turma?.curriculoNegocioTipoCurriculoCodigo === 'CRT') {
				valorTotal += oferta?.valorOfertaCurta;
			} else if (turma?.turma?.curriculoNegocioTipoCurriculoCodigo === 'COL') {
				valorTotal += oferta?.valorOfertaColonia;
			} else if (turma.turma.curriculo.duracaoTotal === 90) {
				valorTotal += oferta?.valorOfertaUmaHoraMeia;
			} else if (turma.turma.curriculo.duracaoTotal === 180) {
				valorTotal += oferta?.valorOfertaTresHora;
			} else if (turma.turma.curriculo.duracaoTotal === 270) {
				valorTotal += oferta?.valorOfertaQuatroHoraMeia;
			} else if (turma.turma.curriculo.duracaoTotal === 360) {
				valorTotal += oferta?.valorOfertaSeisHora;
			}
		});

		if (duracaoTotal > 360) {
			const horasExcedentes = duracaoTotal - 360;
			valorTotal += oferta?.valorOfertaSeisHora + (horasExcedentes / 90) * oferta?.valorAdicionalOferta;
		}

		return valorTotal;
	};

	const calcularValorPorCurriculo = (turma: ITurmaOfferDTO) => {
		if (turma?.turma?.curriculoNegocioTipoCurriculoCodigo === 'CRT') {
			return oferta?.valorOfertaCurta;
		}
		if (turma?.turma?.curriculoNegocioTipoCurriculoCodigo === 'COL') {
			return oferta?.valorOfertaColonia;
		}
		if (turma.turma.curriculo.duracaoTotal === 90) {
			return oferta?.valorOfertaUmaHoraMeia;
		}

		if (turma.turma.curriculo.duracaoTotal === 180) {
			return oferta?.valorOfertaTresHora;
		}

		if (turma.turma.curriculo.duracaoTotal === 270) {
			return oferta?.valorOfertaQuatroHoraMeia;
		}

		if (turma.turma.curriculo.duracaoTotal === 360) {
			return oferta?.valorOfertaSeisHora;
		}

		if (turma.turma.curriculo.duracaoTotal > 360) {
			const horasExcedentes = turma.turma.curriculo.duracaoTotal - 360;
			return oferta?.valorOfertaSeisHora + (horasExcedentes / 90) * oferta?.valorAdicionalOferta;
		}
	};

	const calcularTotalTurmaOferta = () => {
		const curta = matricula?.turmas.filter((tipo) => tipo?.turma?.curriculoNegocioTipoCurriculoCodigo === 'CRT');
		const colonia = matricula?.turmas.filter((tipo) => tipo?.turma?.curriculoNegocioTipoCurriculoCodigo === 'COL');

		const curriculo = matricula?.turmas.filter(
			(tipo) => tipo?.turma?.curriculoNegocioTipoCurriculoCodigo !== 'CRT' && tipo?.turma?.curriculoNegocioTipoCurriculoCodigo !== 'COL'
		);
		const duracoes = curriculo.map((duracao) => duracao?.turma?.curriculo?.duracaoTotal);
		const totalDuracao = duracoes.reduce((acc, duracao) => acc + duracao, 0);

		let valorTotal = 0;

		if (curta.length > 0) {
			valorTotal += curta.length * oferta?.valorOfertaCurta;
		}

		if (colonia.length > 0) {
			valorTotal += colonia.length * oferta?.valorOfertaColonia;
		}

		if (totalDuracao === 90) {
			valorTotal += oferta?.valorOfertaUmaHoraMeia;
		}

		if (totalDuracao === 180) {
			valorTotal += oferta?.valorOfertaTresHora;
		}

		if (totalDuracao === 270) {
			valorTotal += oferta?.valorOfertaQuatroHoraMeia;
		}

		if (totalDuracao === 360) {
			valorTotal += oferta?.valorOfertaSeisHora;
		}

		if (totalDuracao > 360) {
			const horasExcedentes = totalDuracao - 360;
			valorTotal += oferta?.valorOfertaSeisHora + (horasExcedentes / 90) * oferta?.valorAdicionalOferta;
		}

		return valorTotal;
	};

	useEffect(() => {
		if (!unidade.id) return;

		Promise.all([findCurriculo(), findHoraVenda(), findPessoa()]);
	}, [unidade.id]);

	useEffect(() => {
		predicateTurma.removeAllOption();

		if (!unidade.id) return;

		if (offersChipFilter.length > 0) {
			for (let i = 0; i <= offersChipFilter?.length; i++) {
				if (offersChipFilter[i] !== undefined) {
					predicateTurma.addMultiOption('curriculo.negocio.nomeCurto', offersChipFilter[i]);
				}
			}

			if (checkedFaixaEtaria) {
				predicateTurma.addMultiOption('curriculo.faixaEtariaIni', getAge(matricula.aluno.pessoa.dataNascimento), Operators.LESS_THAN_OR_EQUAL, true);
				predicateTurma.addMultiOption('curriculo.faixaEtariaFim', getAge(matricula.aluno.pessoa.dataNascimento), Operators.GREATER_THAN_OR_EQUAL);
			}

			if (idCurriculo !== -1) {
				predicateTurma.addOption('curriculo.id', idCurriculo);
			}

			if (dataInicial) {
				predicateTurma.addOption('dataInicio', dataInicial?.toISOString().substring(0, 10), Operators.GREATER_THAN_OR_EQUAL);
			}

			if (dataFinal) {
				predicateTurma.addOption('dataFim', dataFinal?.toISOString().substring(0, 10), Operators.LESS_THAN_OR_EQUAL);
			}

			findTurmas();
		}

		if (checkFiltro === false) {
			setTurmaSemana(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offersChipFilter?.length, checkedFaixaEtaria, dataInicial, dataFinal, idCurriculo, checkFiltro, horaInicialFiltro, unidade.id]);

	return (
		<>
			<Header />
			<div className="progress">
				<Progress className="progress-user" percent={25} strokeLinecap="square" showInfo={false} />
			</div>
			<div className="container-offer-page" style={{ overflowX: 'auto' }}>
				<div className="aluno-box d-flex-col-start-star">
					<div className="dados">
						<h4>Dados do aluno</h4>
						<p>
							<span>Aluno: </span>
							{matricula?.aluno?.pessoa?.nome}
						</p>
						<p>
							<span>Idade: </span>
							{getAge(matricula?.aluno?.pessoa?.dataNascimento) + ' anos'}
						</p>
						<p>
							<span>Responsável Financeiro</span>
						</p>
						<div className="responsavel-box d-flex-row-between-center">
							<p>{matricula?.responsavelFinanceiro?.nome}</p>
							<Button onClick={() => handleResponsavel()}>
								<EditIcon sx={{ color: '#1A4DA1' }} />
							</Button>
						</div>
					</div>
					<div className="oferta">
						<h4>Visualização de oferta</h4>
						<div className="filters d-flex-row-start-center gap-10">
							<Switch checked={checkFiltro} onClick={removeFiltros} />
							<p>{'Filtros'}</p>
						</div>
						<div className="boxes">
							{offersObjectArray?.map((object, index) => (
								<BoxOffer key={index} {...object} isCheckedState={isCheckedState} offerFilterActions={offerFilterActions} />
							))}
						</div>
					</div>
					<div className="resultado">
						<h4>Ofertas selecionadas</h4>
						<div className="box-resultado d-flex-row-start-center w-100">
							<div className="w-25">
								<h5>Curso</h5>
							</div>
							<div className="w-25">
								<h5>CH. Semanal</h5>
							</div>
							<div style={{ flex: 1 }}>
								<h5>Valor</h5>
							</div>
						</div>
						{matricula?.turmas.map((t, index) => (
							<div key={index} className="box-resultado d-flex-row-start-center w-100">
								<div className="w-25">
									<p>{t?.turma.curriculo?.descricaoCurriculo}</p>
								</div>
								<div className="w-25">
									<p>{renderMinutesToHour(t?.turma.curriculo.duracaoTotal)} </p>
								</div>
								<div className="w-25">
									<p>{formatMoney(calcularValorPorCurriculo(t))}</p>
								</div>
							</div>
						))}
						<hr />
						<div className="box-resultado d-flex-row-start-center w-100" style={{ margin: 0, marginTop: 20 }}>
							<div className="w-25">
								<h5>Total</h5>
							</div>
							<div className="w-25">
								<h5>{renderMinutesToHour(matricula.turmas.reduce((totalMinutos, turma) => totalMinutos + turma.turma.curriculo.duracaoTotal, 0))}</h5>
							</div>
							<div style={{ flex: 1 }}>
								<h5>{formatMoney(calcularTotalTurmaOferta())}</h5>
							</div>
						</div>
						<div className="box-resultado d-flex-row-start-center w-100" style={{ margin: 0, marginBottom: 20 }}>
							<div className="w-50" />
							<div>
								<h5 style={{ color: 'red' }}>({formatMoney(calcularTotalValorCurriculoSemDesconto() - calcularTotalTurmaOferta())})</h5>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div className="w-100">
							<Button onClick={() => voltaDetalhe()} className="button-second">
								Voltar
							</Button>
						</div>
						<div className="w-100" style={{ marginLeft: '5px' }}>
							<Button onClick={validaOfertas} className="button-primary" disabled={matricula?.turmas.length === 0}>
								Confirmar Oferta
							</Button>
						</div>
					</div>
				</div>
				<div className="box-calendar">
					<div className="filtros d-flex-row-between-center">
						<div className="title d-flex-row-start-center">
							<div className="number">1</div>
							<h1>Seleção de Oferta</h1>
						</div>
						<div className="filters d-flex-row-start-center gap-10">
							<input type="checkbox" name="" id="" onClick={() => setCheckedFaixaEtaria(!checkedFaixaEtaria)} />
							<p>Ver apenas turmas da faixa etária do aluno</p>
							<button className="button-filter" onClick={OpenFiltros}>
								{isOpen ? <FilterAltOffOutlinedIcon sx={{ color: '#1A4DA1' }} /> : <FilterAltOutlinedIcon sx={{ color: '#1A4DA1' }} />}
								Filtros
							</button>
						</div>
					</div>
					{isOpen && (
						<div className="filtros-box">
							<div className="d-flex-row-between-center-wrap gap-10">
								<div className="filtro-select">
									<span>Data Inicio</span>
									<DatePicker
										allowClear={true}
										placeholder={'Data Inicial'}
										size={'large'}
										format={'DD/MM/YYYY'}
										onChange={setDataInicial}
										getPopupContainer={(triggerNode) => {
											return triggerNode.parentElement;
										}}
									></DatePicker>
								</div>
								<div className="filtro-select">
									<span>Data Fim</span>
									<DatePicker
										allowClear={true}
										placeholder={'Data Inicial'}
										size={'large'}
										format={'DD/MM/YYYY'}
										onChange={setDataFinal}
										getPopupContainer={(triggerNode) => triggerNode.parentElement}
									></DatePicker>
								</div>
								<div className="filtro-select">
									<span>Horário início</span>
									<Select onChange={(value) => setHoraInicioFiltro(String(value))}>
										<Select.Option value="tds">Todos</Select.Option>
										{criarGradeAPartirDaPrimeiraHoraDaFranquia()
											.filter((g) => g.horaInicial !== null)
											.map((h, index) => (
												<Select.Option key={index} value={h.horaInicial}>
													{h.horaInicial}
												</Select.Option>
											))}
									</Select>
								</div>
								<div className="filtro-select">
									<span>Currículo</span>
									<Select style={{ width: '100%' }} onChange={(e) => setIdCurriculo(parseInt(e?.toString(), 10))}>
										<Select.Option key={null} value={-1}>
											Todos
										</Select.Option>
										{listCurriculo &&
											listCurriculo.map((curriculo, index) => (
												<Select.Option key={index} value={curriculo.id}>
													{curriculo.descricaoCurriculo}
												</Select.Option>
											))}
									</Select>
								</div>
								<div className="d-flex-row-between-center gap-10">
									<button className="button-white">Limpar</button>
									<button className="button-primary">Aplicar Filtros</button>
								</div>
							</div>
						</div>
					)}
					<div className="calendario">
						<div className="body-calendario">
							<CalendarOffer turmaSemana={turmaSemana} telaTurma={false} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
