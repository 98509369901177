
import { ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO } from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyAulaPadraoService = () => {
	const resourceURL = '/curso-aula-padrao';

	return {
		...baseFind<ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO>(resourceURL),
		...baseFindList<ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO>(resourceURL),
		...baseFindById<ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO>(resourceURL),
		...baseCreate<ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO>(resourceURL),
        ...baseUpdate<ICursoAulaPadraoCleanDTO, ICursoAulaPadraoDTO, ICursoAulaPadraoFullDTO>(resourceURL),

		// createFull: (body): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.post(`${resourceURL}/full`, body),
		// patch: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		// patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		// 	http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),

	};
};
