import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalCreate from '../../components/ModalCreate/index';
import { Tags } from '../../components/Tags/index';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCursoService } from '../../services/curso.service';
import './style.css';

export const MenuDetalheCurso: React.FunctionComponent = () => {

  const { curso, removeCurso, setRevisao } = useCursoContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);
  const service = useCursoService();

  const save = () => {
    setIsGlobalLoading(true)
    if (curso.id) {
      service.patchFull(curso)
        .then(() => {
          removeCurso();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    } else {
      service.createFull(curso)
        .then(() => {
          removeCurso();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    }
  }

  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1">
        <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
        <h1 id="title">{curso.descricao}</h1>
        <div className="ativo">
          <div className="dot-verde"></div>
          <span>Ativo</span>
        </div>
        <h6 style={{ marginBottom: 0 }}>quant. de aulas</h6>
        <span>{curso.duracao}</span>
        <h6 style={{ marginBottom: 0 }}>duraçãoem min</h6>
        <span>{curso.duracaoMinutos} Mins</span>
        <div id="min-tag" style={{ marginBottom: 15 }}>
          <Tags tipo={curso.modalidade.descricao} />
        </div>
        {curso?.id ?
          <>
            <Link to={`/cursos-oferta/${curso.id}`}>
              <Button id="salvar" style={{ width: 265, marginBottom: 5 }} className="button-primary">Alterar Oferta por Hub</Button>
            </Link>
            <Button id="salvar" style={{ width: 265 }} className="button-primary" onClick={() => save()}>Salvar</Button>
          </>
          : <Button id="salvar" style={{ width: 265 }} className="button-primary" onClick={() => save()}>Salvar</Button>}
      </div>
      <ModalCreate
        open={isOpen}
        //onClose={() => setIsOpen(false)}
        title="Curso salvo com sucesso!"
        textButton1="Criar outro curso"
        textButton2="Voltar a página inicial"
        styleButton1="button-line"
        styleButton2="button-primary"
        link1="/cursos/create/step-1"
        link2="/cursos"
      />
    </div>
  );
}