import { Button, DatePicker, Input, Radio, Space, notification } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import imgHeader from "../../assets/img/coleta-informacao-lead/email-prospect-abf.png";
import agradecimento from "../../assets/img/coleta-informacao-lead/agradecimento.png";
import ModalOperation from '../../components/ModalOperation';
import Validation from '../../components/Validation';
import { useGlobalContext } from '../../context/GlobalContext';
import { Auth } from '../../models/auth.model';
import { ILeadAbfDTO } from '../../models/happy-code-api.model';
import { useAuthService } from '../../services/auth.service';
import { useLeadAbfService } from '../../services/lead-abf.service';
import { formatCpf, formatMomentLocalDateTime, formatPhone, formatRG } from '../../util/format';
import loginImg from "../../assets/img/coleta-informacao-lead/login.png";
import './styles.css';

function PaginaColetaInformacao() {
    const required = <span style={{ color: "red" }}>*</span>;
    const stadoCivil = ["Solteiro (a)", "Casado (a)", "Separado (a)", "Divorciado (a)", "Viúvo (a)"];
    const tempoDedicadoNegocio = ["Sem disponibilidade", "Alguns dias na semana", "Meio período", "Full time"];
    const capitalDisponivel = ["Até R$ 150 mil", "R$ 150 mil a R$ 200 mil", "R$ 200 mil a R$ 300 mil", "Acima de R$ 300 mil"];
    const [form, setForm] = useState<ILeadAbfDTO>();
    // const [checked, setChecked] = useState<boolean>(false);
    const { setIsGlobalLoading } = useGlobalContext();
    const [token, setToken] = useState<Auth>();
    const authService = useAuthService();
    const leadAbfService = useLeadAbfService();
    const [senha, setSenha] = useState<string>();
    const { leadCodigoId } = useParams<any>();
    const [isLogado, setIsLogado] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const findLead = () => {
        setIsGlobalLoading(true);
        authService.basic()
            .then(({ data }) => {
                setToken(data);
                return data;
            })
            .then(async (responseToken) => {
                const { data } = await leadAbfService.findLead(leadCodigoId, senha, responseToken.access_token)
                setForm(data);
                setIsLogado(true);                
            }).finally(() => setIsGlobalLoading(false));
    }

    const patch = () => {
        setIsGlobalLoading(true)
        leadAbfService.patchLead(form, token.access_token)
            .then(() => {
                setOpen(true);
                setIsLogado(false);
            }).finally(() => setIsGlobalLoading(false));
    }

    const submitValidationFields = () => {
        setForm({
            ...form,
            nomeCompleto: form.nomeCompleto ? form.nomeCompleto : "",
            estadoCivil: form.estadoCivil ? form.estadoCivil : "",
            cpf: form.cpf ? form.cpf : "",
            rg: form.rg ? form.rg : "",
            dataNascimento: form.dataNascimento ? form.dataNascimento : null,
            email: form.email ? form.email : "",
            celular: form.celular ? form.celular : "",
            endereco: form.endereco ? form.endereco : "",
            cidadeInteresse: form.cidadeInteresse ? form.cidadeInteresse : "",
            capitalDisponivel: form.capitalDisponivel ? form.capitalDisponivel : "",
            experienciaEducacao: form.experienciaEducacao ? form.experienciaEducacao : "",
            tempoDedicadoHappy: form.tempoDedicadoHappy ? form.tempoDedicadoHappy : "",
            possuiFilhos: form.possuiFilhos ? form.possuiFilhos : "",
        })

        if (!form?.nomeCompleto) {
            notification.warning({
                message: 'Nome inválido!',
                description: 'O campo "Nome completo" está vazio, por gentileza informar.',
            });
            document.getElementById('nome').focus();
            return;
        }
        if (!form?.estadoCivil) {
            notification.warning({
                message: 'Estado Civil inválido!',
                description: 'O campo "Estado Civil" está vazio, por gentileza informar.',
            });
            document.getElementById('estadoCivil').focus();
            return;
        }
        if (!form?.cpf) {
            notification.warning({
                message: 'CPF inválido!',
                description: 'O campo "CPF" está vazio, por gentileza informar.',
            });
            document.getElementById('cpf').focus();
            return;
        }
        if (!form?.rg) {
            notification.warning({
                message: 'RG inválido!',
                description: 'O campo "RG" está vazio, por gentileza informar.',
            });
            document.getElementById('rg').focus();
            return;
        }
        if (!form?.dataNascimento) {
            notification.warning({
                message: 'Data de nascimento inválido!',
                description: 'O campo "Data de nascimento" está vazio, por gentileza informar.',
            });
            document.getElementById('dataNascimento').focus();
            return;
        }
        if (!form?.email) {
            notification.warning({
                message: 'E-mail inválido!',
                description: 'O campo "E-mail" está vazio, por gentileza informar.',
            });
            document.getElementById('email').focus();
            return;
        }
        if (!form?.celular) {
            notification.warning({
                message: 'Celular inválido!',
                description: 'O campo "Celular" está vazio, por gentileza informar.',
            });
            document.getElementById('celular').focus();
            return;
        }
        if (!form?.endereco) {
            notification.warning({
                message: 'Endereço inválido!',
                description: 'O campo "Endereço" está vazio, por gentileza informar.',
            });
            document.getElementById('endereco').focus();
            return;
        }
        if (!form?.cidadeInteresse) {
            notification.warning({
                message: 'Cidade de interesse inválido!',
                description: 'O campo "Cidade de interesse" está vazio, por gentileza informar.',
            });
            document.getElementById('cidadeInteresse').focus();
            return;
        }
        if (!form?.capitalDisponivel) {
            notification.warning({
                message: 'Capital disponível inválido!',
                description: 'O campo "Capital disponível" está vazio, por gentileza informar.',
            });
            document.getElementById('capitalDisponivel').focus();
            return;
        }
        if (!form?.experienciaEducacao) {
            notification.warning({
                message: 'Experiência em educação inválido!',
                description: 'O campo "Experiência em educação" está vazio, por gentileza informar.',
            });
            document.getElementById('experienciaEducacao').focus();
            return;
        }
        if (!form?.tempoDedicadoHappy) {
            notification.warning({
                message: 'Tempo dedicado ao negócio inválido!',
                description: 'O campo "Tempo dedicado ao negócio" está vazio, por gentileza informar.',
            });
            document.getElementById('tempoDedicadoNegocio').focus();
            return;
        }
        if (form?.tempoDedicadoHappy !== "Full time" && !form?.responsavelHappy) {
            notification.warning({
                message: 'Responsável pelo negócio inválido!',
                description: 'O campo "Se não for operador, quem estará a frente da Happy" está vazio, por gentileza informar.',
            });
            document.getElementById('responsavel').focus();
            return;
        }
        if (!form?.possuiFilhos) {
            notification.warning({
                message: 'Tem filho(s) inválido!',
                description: 'O campo "Tem filho(s)" está vazio, por gentileza informar.',
            });
            document.getElementById('filhos').focus();
            return;
        }
        if (form?.possuiFilhos === "Sim" && !form?.idadeFilhos) {
            notification.warning({
                message: 'Idade do(s) Filho(s) inválido!',
                description: 'O campo "Idade do(s) Filho(s)" está vazio, por gentileza informar.',
            });
            document.getElementById('idadeFilho').focus();
            return;
        }
        patch();
    }

    if (!isLogado && !open) {
        return (
            <div className="container-coleta-informacao-login">
                <img src={loginImg} alt="logo-img" />
                <div className="box-coleta-informacao-login">
                    <p style={{ paddingBottom: 10 }}>Informe, por favor, os <strong style={{ fontWeight: 900 }}>4 últimos números do seu celular de contato (mesmo que recebeu esta mensagem)</strong> e clique no botão Acessar Perfil.</p>
                    <p style={{ paddingBottom: 10 }}>Após isso, siga os passos abaixo:</p>

                    <p>1. Confira as informações já informadas na ABF e complete as demais</p>
                    <p style={{ paddingBottom: 10 }}>2. Clique no botão <strong style={{ fontWeight: 900 }}>“Atualizar dados do Perfil”</strong> para concluir o processo.</p>

                    <p style={{ paddingBottom: 10 }}>Após a conclusão das informações, nossa equipe de consultores fará novo contato com o resultado da análise.</p>

                    <div className="input-coleta-informacao">
                        <Input
                            style={{ marginBottom: 5 }}
                            id="senhaCelular"
                            className="input input-alin-info"
                            placeholder="Senha (xx)xxxxx-0000"
                            onChange={(e) => setSenha(e.target.value)}
                        />
                    </div>
                    <div
                        className="box-button-coleta-informacao"
                        style={{ justifyContent: "center" }}>
                        <Button
                            style={{ width: 250 }}
                            className="button-primary"
                            onClick={() => findLead()}
                        >Acessar</Button>
                    </div>
                </div>

            </div >
        );
    }

    if (isLogado && !open) {
        return (
            <div className="container-coleta-informacao">
                <div className="box-form-coleta-informacao">

                    <div className="box-img-header">
                        <img
                            className="img-header-coleta-informacao"
                            src={imgHeader} alt="img-header"
                        />
                        <h2>FALTA POUCO PARA <br />
                            CONCLUIRMOS A SUA ANÁLISE DE PERFIL.</h2>
                    </div>
                    <h2 className="title-coleta-informacao">
                        Peço, por gentileza, que <strong style={{ fontWeight: 900 }}>confirme as informações já coletadas em sua visita,
                            e complete os demais dados solicitados</strong>.
                        Após completar o processo, clique no botão “Atualizar Informações” e aguarde nossa mensagem de conclusão.
                    </h2>
                    <div className="box-coleta-informacao">
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="nome">Nome Completo {required}</label>
                            <Input
                                id="nome"
                                className="input input-alin-info"
                                placeholder="Marcos Vinicius Rodrigues"
                                defaultValue={form?.nomeCompleto}
                                value={form?.nomeCompleto}
                                onChange={(e) => setForm({ ...form, nomeCompleto: e.target.value })}
                            />
                            <Validation
                                required={form?.nomeCompleto === ""}
                                msgError="Informar o nome completo" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                id="estadoCivil"
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="estadoCivil">Estado Civil {required}</label>
                            <Radio.Group
                                defaultValue={form?.estadoCivil}
                                value={form?.estadoCivil}
                                onChange={(e) => setForm({ ...form, estadoCivil: e.target.value })}
                            >
                                <Space direction="vertical" >
                                    {stadoCivil.map((item, index) =>
                                        <Radio key={index} value={item} id="estadoCivil">{item}</Radio>
                                    )}
                                </Space>
                            </Radio.Group>
                            <Validation
                                required={form?.estadoCivil === ""}
                                msgError="Informar o estado civil" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                id="cpf"
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="cpf">CPF {required}</label>
                            <Input
                                id="cpf"
                                className="input input-alin-info"
                                placeholder="Informe seu CPF"
                                defaultValue={form?.cpf}
                                value={formatCpf(form?.cpf)}
                                onChange={(e) => setForm({ ...form, cpf: e.target.value })}
                            />
                            <Validation
                                required={form?.cpf === ""}
                                msgError="Informar o CPF" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="rg">RG {required}</label>
                            <Input
                                id="rg"
                                className="input input-alin-info"
                                placeholder="Informe seu RG"
                                defaultValue={form?.rg}
                                value={formatRG(form?.rg)}
                                onChange={(e) => setForm({ ...form, rg: e.target.value })}
                            />
                            <Validation
                                required={form?.rg === ""}
                                msgError="Informar o RG" />
                        </div>
                        <div
                            className="input-coleta-informacao"
                            style={{ maxWidth: 250 }} >
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="dataNascimento">Data de nascimento {required}</label>
                            <DatePicker
                                id="dataNascimento"
                                className="input input-alin-info"
                                format={'DD/MM/YYYY'}
                                defaultValue={moment(form.dataNascimento)}
                                onChange={(e) => setForm({ ...form, dataNascimento: formatMomentLocalDateTime(e) })}
                            />
                            <Validation
                                required={form?.dataNascimento === null}
                                msgError="Informar a data de nascimento" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="email">E-mail {required}</label>
                            <Input
                                id="email"
                                className="input input-alin-info"
                                placeholder="Informe seu e-mail"
                                defaultValue={form?.email}
                                value={form?.email}
                                onChange={(e) => setForm({ ...form, email: e.target.value })}
                            />
                            <Validation
                                required={form?.email === ""}
                                msgError="Informar o E-mail" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="celular">Celular {required}</label>
                            <Input
                                id="celular"
                                className="input input-alin-info"
                                placeholder="Informe seu celular"
                                defaultValue={form?.celular}
                                value={formatPhone(form?.celular)}
                                onChange={(e) => setForm({ ...form, celular: e.target.value })}
                            />
                            <Validation
                                required={form?.celular === ""}
                                msgError="Informar o celular" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="endereco">Endereço {required}</label>
                            <Input
                                id="endereco"
                                className="input input-alin-info"
                                placeholder="Informe seu endereço completo"
                                defaultValue={form?.endereco}
                                value={form?.endereco}
                                onChange={(e) => setForm({ ...form, endereco: e.target.value })}
                            />
                            <Validation
                                required={form?.endereco === ""}
                                msgError="Informar o endereço" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="cidadeInteresse">Cidade de interesse {required}</label>
                            <Input
                                id="cidadeInteresse"
                                className="input input-alin-info"
                                placeholder="Informe interesse de Cidade"
                                defaultValue={form?.cidadeInteresse}
                                value={form?.cidadeInteresse}
                                onChange={(e) => setForm({ ...form, cidadeInteresse: e.target.value })}
                            />
                            <Validation
                                required={form?.cidadeInteresse === ""}
                                msgError="Informar o interesse de Cidade" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                id="experienciaEducacao"
                                style={{ fontWeight: 700, marginBottom: 10 }}
                                htmlFor="experienciaEducacao">Experiência em educação {required}</label>
                            <Radio.Group
                                defaultValue={form?.experienciaEducacao}
                                value={form?.experienciaEducacao}
                                onChange={(e) => setForm({ ...form, experienciaEducacao: e.target.value })}
                            >
                                <Space direction="vertical">
                                    <Radio value={"Sem experiência"} id="experienciaEducacao">Sem experiência</Radio>
                                    <Radio value={"Até 1 ano"}>Até 1 ano</Radio>
                                    <Radio value={"De 1 a 4 anos"}>De 1 a 4 anos</Radio>
                                    <Radio value={"Acima de 4 anos"}>Acima de 4 anos</Radio>
                                </Space>
                            </Radio.Group>
                            <Validation
                                required={form?.experienciaEducacao === ""}
                                msgError="Informar o experiência em educação" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                id="tempoDedicadoNegocio"
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="tempoDedicadoNegocio">Tempo dedicado ao negócio {required}</label>
                            <Radio.Group
                                defaultValue={form?.tempoDedicadoHappy}
                                value={form?.tempoDedicadoHappy}
                                onChange={(e) => setForm({ ...form, tempoDedicadoHappy: e.target.value })}
                            >
                                <Space direction="vertical" >
                                    {tempoDedicadoNegocio.map((item, index) =>
                                        <Radio key={index} value={item} id="tempoDedicadoNegocio">{item}</Radio>
                                    )}
                                </Space>
                            </Radio.Group>
                            <Validation
                                required={form?.tempoDedicadoHappy === ""}
                                msgError="Informar o tempo dedicado ao negócio" />
                        </div>
                        {form?.tempoDedicadoHappy && form?.tempoDedicadoHappy !== "Full time" ?
                            <div className="input-coleta-informacao">
                                <label
                                    id="responsavel"
                                    style={{ fontWeight: 700, marginBottom: 5 }}
                                    htmlFor="responsavel">Se não for operador, quem estará a frente da Happy {required}</label>
                                <Input
                                    id="responsavel"
                                    className="input input-alin-info"
                                    placeholder="Informe o responsável"
                                    defaultValue={form?.tempoDedicadoHappy !== "Full time" ? form?.responsavelHappy : null}
                                    value={form?.tempoDedicadoHappy !== "Full time" ? form?.responsavelHappy : null}
                                    onChange={(e) => setForm({ ...form, responsavelHappy: e.target.value })}
                                />
                                <Validation
                                    required={form?.responsavelHappy === ""}
                                    msgError="Informar o responsável" />
                            </div>
                            : null}
                        <div className="input-coleta-informacao">
                            <label
                                id='capitalDisponivel'
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="capitalDisponivel">Capital disponível {required}</label>
                            <Radio.Group
                                defaultValue={form?.capitalDisponivel}
                                value={form?.capitalDisponivel}
                                onChange={(e) => setForm({ ...form, capitalDisponivel: e.target.value })}
                            >
                                <Space direction="vertical" >
                                    {capitalDisponivel.map((item, index) =>
                                        <Radio key={index} value={item} id="capitalDisponivel">{item}</Radio>
                                    )}
                                </Space>
                            </Radio.Group>
                            <Validation
                                required={form?.capitalDisponivel === ""}
                                msgError="Informar o capital disponível" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                id="filhos"
                                style={{ fontWeight: 700, marginBottom: 10 }}
                                htmlFor="filhos">Tem filho(s)? {required}</label>
                            <Radio.Group
                                defaultValue={form?.possuiFilhos}
                                value={form?.possuiFilhos}
                                onChange={(e) => setForm({ ...form, possuiFilhos: e.target.value })}
                            >
                                <Space direction="vertical">
                                    <Radio value={"Sim"} id="filhos">Sim</Radio>
                                    <Radio value={"Não"}>Não</Radio>
                                </Space>
                            </Radio.Group>
                            <Validation
                                required={form?.possuiFilhos === ""}
                                msgError="Informar se tem filho(s)" />
                        </div>
                        <div className="input-coleta-informacao">
                            <label
                                style={{ fontWeight: 700, marginBottom: 5 }}
                                htmlFor="idadeFilho">Idade do(s) Filho(s) {form?.possuiFilhos === "Sim" ? required : null}</label>
                            <Input
                                id="idadeFilho"
                                className="input input-alin-info"
                                placeholder="16 e 18"
                                disabled={form?.possuiFilhos !== "Sim"}
                                defaultValue={form?.possuiFilhos === "Sim" ? form?.idadeFilhos : null}
                                value={form?.possuiFilhos === "Sim" ? form?.idadeFilhos : null}
                                onChange={(e) => setForm({ ...form, idadeFilhos: e.target.value })}
                            />
                        </div>
                        <div className="box-button-coleta-informacao">
                            <Button
                                className="button-primary"
                                onClick={submitValidationFields}
                            >Atualizar dados para análise </Button>
                        </div>
                    </div>
                </div >
            </div>
        );
    }

    if (open) {
        return (
            <div className="container-modal-finalizacao">
                <div className="box-modal-finalizacao">
                    <div style={{ width: "50%", height: "50%" }} />
                    {/* <div className="alin-title-modal-finalizacao">
                        <h2 style={{ color: "#fff", maxWidth: 300 }}>AGORA SIM!<br />
                            VAMOS AVANÇAR COM A SUA JORNADA!</h2>
                        <h2 style={{ color: "#fff", maxWidth: 300 }}>
                            Muito OBRIGADO pela confirmação das informações de seu perfil!
                            Vejo que estamos a poucos passos de transformar a educação da sua cidade! Após análise, faremos novo contato!
                            <br />
                            Até logo, Futuro (a) Comandante!
                        </h2>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default PaginaColetaInformacao