import {
	ITipoDocumentoCleanDTO,
	ITipoDocumentoDTO,
	ITipoDocumentoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useTipoDocumentoService = () => {
	const resourceURL = 'tipo-documento';

	return {
		...baseFindList<
			ITipoDocumentoCleanDTO,
			ITipoDocumentoDTO,
			ITipoDocumentoFullDTO
		>(resourceURL),
	};
};
