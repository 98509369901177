import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImgStemplay from '../../assets/img/box-stemplay/download.png';
import fondo from '../../assets/img/matriculas/fondo.svg';
import BoxStemplay from '../../components/BoxStemplay';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';

import { Predicate } from '../../models/predicate.model';
import { useCursoService } from '../../services/curso.service';
import './style.css';
moment().locale('pt-br');

export const Stemplay: React.FunctionComponent = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [descricao, setDescricao] = useState<string>();
    const cursoService = useCursoService();
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const history = useHistory();
    const [cursoPredicate] = useState<Predicate>(new Predicate(6));
    const { turma, setTurma } = useTurmaContext();

    //VER COMO FAZ O LOADING ENTRE AS PAGINAS
    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        //setIsGlobalLoading(true);
        cursoPredicate.setPage(page);
        //findCurso().finally(() => setIsGlobalLoading(false));
    }

    return (
        <div className="container-fondo">
            <NewMenu selecionado={30} />
            <div className="franquia" id="aluno-detalhe-row">
                <div className="user-row" style={{ width: '900px', display: 'flex', alignItems: 'center' }}>
                    <div className="logo-stemplay">
                        <img className="logo" src={ImgStemplay} alt=''></img>
                    </div>
                    <div className="matriculas-curso">
                        <BoxStemplay />
                    </div>
                </div>
            </div>
            <img src={fondo} alt="" className="img-fondo" />
        </div>
    );
}

export default Stemplay