import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICursoUnidadeDTO, INegocioUnidadeDTO } from '../../models/happy-code-api.model';
import { useCursoUnidadeService } from '../../services/curso-unidade.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney } from '../../util/format';
import ModalCreate from '../ModalCreate/index';
import { Tags } from '../Tags';
import './style.css';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';

interface Props {
    setRestartOferta: React.Dispatch<React.SetStateAction<boolean>>;
    oferta: INegocioUnidadeDTO;
}

export const MenuDetalheNegocioOferta: React.FunctionComponent<Props> = ({ setRestartOferta, oferta }: Props) => {
    const negocioUnidadeService = useNegocioUnidadeService();
    const history = useHistory();
    const { curso, setRevisao } = useCursoContext();
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const rotaMatricula = location.pathname === `/negocio-oferta/step-1/${oferta?.id}`;
    const rotaRematricula = location.pathname === `/negocio-oferta/step-2/${oferta?.id}`;

    const saveOferta = () => {
        setIsOpen(true);
    }

    const patchOferta = () => {
        setIsGlobalLoading(true);
        negocioUnidadeService.patchCustom(oferta.id, {
            id: oferta?.id,
            valorMinimo: oferta.valorMinimo,
            valorHora: oferta.valorHora,
            numeroParcelasMaximo: oferta.numeroParcelasMaximo,
            flTaxaMatricula: oferta.flTaxaMatricula,
            valorSplitTaxa: oferta.valorSplitTaxa,
            prorrogacaoDiasMatricula: oferta.prorrogacaoDiasMatricula,
            valorMinimoRematricula: oferta.valorMinimoRematricula,
            valorHoraRematricula: oferta.valorHoraRematricula,
            numeroParcelasMaximoRematricula: oferta.numeroParcelasMaximoRematricula,
            flTaxaRematricula: oferta.flTaxaRematricula,
            valorSplitTaxaRematricula: oferta.valorSplitTaxaRematricula,
            prorrogacaoDiasRematricula: oferta.prorrogacaoDiasRematricula,
            valorSugerido: oferta.valorSugerido,
        }).then(() => {
            saveOferta();
            history.push(`/negocio-oferta/${oferta?.negocio?.id}`);
            notification({
                description: `Oferta cadastrada com sucesso.`,
                type: 'success',
                message: 'Sucesso!',
            });
        }).catch(() => {
            notification({
                description: `Desculpe, não foi possível cadastrar o oferta.`,
                type: 'error',
                message: 'Erro!',
            });
        }).finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        setRevisao(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const validarCampos = () => {
        if (rotaMatricula && oferta?.valorMinimo <= oferta?.valorHora) {
            return notification({
                description: `Desculpe, não foi possível cadastrar o oferta da matrícula, valor do split (${formatMoney(oferta?.valorHora)}) não pode ser maior que o preço mínimo (${formatMoney(oferta?.valorMinimo)}).`,
                type: 'error',
                message: 'Erro!',
            });
        }
        if (rotaRematricula && oferta?.valorMinimoRematricula <= oferta?.valorHoraRematricula) {
            return notification({
                description: `Desculpe, não foi possível cadastrar o oferta da rematrícula, valor do split (${formatMoney(oferta?.valorHoraRematricula)}) não pode ser maior que o preço mínimo (${formatMoney(oferta?.valorMinimoRematricula)}).`,
                type: 'error',
                message: 'Erro!',
            });
        }
        patchOferta();
    }

    return (
        <div className="menu-box-revisao-float">
            <div className="bloco-1">
                <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
                <h1 id="title">Hub: {oferta?.unidade?.descricao}</h1>
                <h1 id="title">{oferta?.negocio?.descricao}</h1>
                <div className="ativo">
                    <div className="dot-verde"></div>
                    <span>Ativo</span>
                </div>                
                {rotaMatricula || rotaRematricula ?
                    <>
                        <Button
                            id="salvar"
                            style={{ width: 265, marginBottom: 5 }}
                            className="button-primary"
                            onClick={() => setRestartOferta(true)}>Default Oferta</Button>
                        <Button
                            id="salvar"
                            style={{ width: 265 }}
                            className="button-primary"
                            onClick={validarCampos}>Salvar</Button>
                    </>
                    : null}
            </div>
            <ModalCreate
                open={isOpen}
                title="Oferta salva com sucesso!"
                textButton2="Voltar a página de Cursos"
                styleButton2="button-primary"
                link2="/cursos"
            />
        </div>
    );
}