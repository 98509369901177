import { ArrowLeftOutlined, SearchOutlined, PlusOutlined, SelectOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import ModalSelecionar from '../../components/ModalSelecionar';
import ModalNovoAluno from '../../components/ModalNovoAluno';
import { Button, Input, Progress, Select } from 'antd';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ImgYoutube from '../../assets/img/box-curso/youtube.svg';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { TableState } from '../../enum/table-state.enum';

import { IAulaCustomDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMatriculaService } from '../../services/matricula.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import { IMatriculaFullDTO } from './../../models/happy-code-api.model';
import { useAulaService } from './../../services/aula.service';
import { useTurmaService } from './../../services/turma.service';
import './style.css';

export const StemplayTurmasDetalhe: React.FunctionComponent = () => {
  const { id } = useParams<any>();
  const { removeTurma } = useTurmaContext();
  const [turma, setTurma] = useState<ITurmaFullDTO>();
  const [listAula, setListAula] = useState<PageableResponse<IAulaCustomDTO>>();
  const [listMatricula, setListMatricula] = useState<PageableResponse<IMatriculaFullDTO>>();
  const [tableState, setTableState] = useState<TableState>(TableState.ALUNO);
  const { setIsGlobalLoading } = useGlobalContext();
  const turmaService = useTurmaService();
  const aulaService = useAulaService();
  const matriculaService = useMatriculaService();
  const [predicateAula] = useState<Predicate>(new Predicate());
  const [matriculaPredicate] = useState<Predicate>(new Predicate());
  const history = useHistory();
  const [mostrarModalAluno, setMostrarModalAluno] = useState<boolean>(false);
  const [mostrarModalNovoAluno, setMostrarModalNovoAluno] = useState<boolean>(false);

  const findAula = () => {
    predicateAula.addOption('turma.id', id);
    return aulaService.findCustom(predicateAula)
      .then((response) => {
        setListAula(response.data);
      })
  }

  const findMatricula = () => {
    matriculaPredicate.addOption('turma.id', id);
    return matriculaService.findFull(matriculaPredicate)
      .then((response) => {
        setListMatricula(response.data);
      })
  }

  const findTurma = () => {
    return turmaService.findFullById({ id })
      .then((response) => {
        setTurma(response.data);
      });
  }


  useEffect(() => {
    removeTurma();
    if (id) {
      setIsGlobalLoading(true);
      Promise.all([
        findTurma(),
        findAula(),
        findMatricula()
      ]).finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const statusTurma = (turma: ITurmaFullDTO) => {
    if (isBeforeDate(turma.dataInicio)) {
      return (
        <div className="box-dot">
          <div className="dot"></div>
          <p style={{ marginRight: 10 }}>Não Iniciado</p>
          <Unicons.UilEllipsisV color="#92A9CB" size="24" />
        </div>
      )
    }
    if (isDateBetween(turma.dataInicio, turma.dataFim)) {
      return (
        <div className="box-dot">
          <div className="dot"></div>
          <p style={{ marginRight: 10 }}>Em andamento</p>
          <Unicons.UilEllipsisV color="#92A9CB" size="24" />
        </div>
      )
    }
    if (isAfterDate(turma.dataFim)) {
      return (
        <div className="box-dot">
          <div className="dot"></div>
          <p style={{ marginRight: 10 }}>Finalizado</p>
          <Unicons.UilEllipsisV color="#92A9CB" size="24" />
        </div>
      )
    }
    return null;
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicateAula.setPage(page);
    setIsGlobalLoading(true);
    findAula().finally(() => setIsGlobalLoading(false));
  }

  const onPageChangeMatricula = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    matriculaPredicate.setPage(page);
    setIsGlobalLoading(true);
    findMatricula().finally(() => setIsGlobalLoading(false));
  }

  const qtdAulaRealizadas = () => {
    if (turma && turma.aulas) {
      return turma.aulas.filter((aula) => isBeforeDate(aula.data)).length;
    }
    return 0;
  }

  const getPorcentagem = () => {
    if (turma && turma.aulas) {
      return parseInt(((qtdAulaRealizadas() / turma.aulas.length) * 100).toPrecision(2));
    }
    return 0;
  }
  const aluno = () => {
    setMostrarModalAluno(true);
  }

  const closeModalAluno = () => {
    setMostrarModalAluno(false);
  }
  const novoAluno = () => {
    setMostrarModalNovoAluno(true);
  }

  const closeModalNovoAluno = () => {
    setMostrarModalNovoAluno(false);
  }

  const arrReferencia = [{
    nome: 'Lucas',
    sobrenome: 'Silva',
    id: 1,
    usuario: 'lusilva',
    senha: 'lululu',
    pacote: 'x',
    acao: 'y'
  },
  {
    nome: 'Matheus',
    sobrenome: 'Oliveira',
    id: 2,
    usuario: 'maoliv',
    senha: 'maaat',
    pacote: 'x',
    acao: 'y'
  },
  {
    nome: 'Julia',
    sobrenome: 'da Silva',
    id: 3,
    usuario: 'jujuju34',
    senha: 'juliasilva',
    pacote: 'x',
    acao: 'y'
  }, {
    nome: 'Bruna',
    sobrenome: 'Araujo',
    id: 4,
    usuario: 'bruaraujo',
    senha: 'bru123',
    pacote: 'x',
    acao: 'y'
  }
  ]


  return (
    <div className="container-fondo">
      <NewMenu selecionado={30} />
      <div className="franquia" id="aluno-detalhe-row">
        <div className="franquia-row" style={{ marginTop: 70 }} id="row-aluno-box">
          <div className="head-botoes" id="detalhe-turma">
            <Link to="/Stemplay/turmas">
              <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
            </Link>
            <h5 style={{ marginLeft: 15 }}>Detalhes de turma</h5>
            <Button className="button-primary" onClick={() => aluno()} icon={<SelectOutlined rev={undefined} />}>Selecionar Alunos</Button>
            <Button className="button-primary" onClick={() => novoAluno()} icon={<PlusOutlined rev={undefined} />}>Novo Aluno</Button>
          </div>
          <div className='modalselect'>
            <ModalSelecionar
              open={mostrarModalAluno}
              onClose={closeModalAluno}
            />
            <ModalNovoAluno
              open={mostrarModalNovoAluno}
              onClose={closeModalNovoAluno}
            />
          </div>
          <div className="box-data-aluno">
            <div className="data-aluno" style={{ minHeight: '270', width: '100%' }}>
              <div id="turma-detalhe-s">
                <h5>Nome da Turma</h5>
                <div className="data" style={{ marginTop: '35px' }}>
                  <div className="data1">
                    <div className="dat">
                      <div className="dat2">
                        <Unicons.UilGraduationCap size="20" color="#6A7C96" />
                        <p>etapa escolar</p>
                      </div>
                      <Select
                        style={{ width: '100%' }}
                        placeholder={'Selecione uma opção'}
                      //onChage={(e) => onChangeEtapa(e)}
                      >
                      </Select>
                    </div>
                    <div className="dat">
                      <div className="dat2">
                        <Unicons.UilFolder size="20" color="#6A7C96" />
                        <p>conteúdo</p>
                      </div>
                      <Select
                        style={{ width: '100%' }}
                        placeholder={'Selecione uma opção'}
                      //onChage={(e) => onChangeEtapa(e)}
                      ></Select>
                    </div>
                    <div className="dat">
                      <div className="dat2" style={{ marginBottom: "32px" }}>
                        <Unicons.UilArrowGrowth size="20" color="#6A7C96" />
                        <Link to="/Stemplay/progresso">
                          <p>progresso</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>

            </div>
          </div>
          <div className="filtros" style={{ width: '100%', marginBottom: 0 }}>
            <div className="input-text" style={{ width: '100%' }}>
              <Input placeholder="Buscar" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
            </div>
          </div>


          <div className="row-stemplay">
            <div className="tabela-stemplay stemplay-tabela">
              <div className="titles-users" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '125px' }}>
                <div className="item-stemplay">
                  <span>NOME</span>
                </div>
                <div className="item-stemplay">
                  <span>SOBRENOME</span>
                </div>
                <div className="item-stemplay">
                  <span>USUÁRIO</span>
                </div>
                <div className="item-stemplay">
                  <span>SENHA</span>
                </div>
              </div>
            </div>

            {arrReferencia.map((map) => (
              <div key={id} className="registro-stemplay" id="registro-stemplay">
                <div className="userStemplay">
                  <div className="column-stemplay">
                    <p>{map.nome}</p>
                  </div>
                  <div className="column-stemplay">
                    <p>{map.sobrenome}</p>
                  </div>
                  <div className="column-stemplay">
                    <p>{map.usuario}</p>
                  </div>
                  <div className="column-stemplay">
                    <p>{map.senha}</p>
                  </div>
                </div>
              </div>
            ))}

            {tableState === TableState.AULA ? (
              <div className="paginacao" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={listAula ? listAula.pageable.pageNumber + 1 : 0} count={listAula ? listAula.totalPages : 0} />
              </div>
            ) : (
              <div className="paginacao" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Pagination onChange={(event, page) => onPageChangeMatricula(event, page - 1)} page={listMatricula ? listMatricula.pageable.pageNumber + 1 : 0} count={listMatricula ? listMatricula.totalPages : 0} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StemplayTurmasDetalhe