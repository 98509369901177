import { SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Input, Progress, Radio } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { Operators } from '../../enum/operators.enum';

import { IPessoaDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { usePessoaService } from '../../services/pessoa.service';
import StepsCreateFranquia from './steps-create-franquia';
import './style.css';

const UserCreateFranquiaStep1: React.FunctionComponent = () => {

  const { usuario, setUsuario, revisao } = useUsuarioContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [listPessoa, setListPessoa] = useState<IPessoaDTO[]>();
  const [selected, setSelected] = useState<string>("1");
  const [predicate] = useState<Predicate>(new Predicate());
  const [nome, setNome] = useState<string>('');

  const pessoaService = usePessoaService();

  const findPessoa = () => {
    setIsGlobalLoading(true);
    return pessoaService.findPessoaSemUsuario(predicate)
      .then(({ data }) => {
        setListPessoa(data.content);
      }).finally(() => setIsGlobalLoading(false))
  }

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      setIsGlobalLoading(true);
      predicate.addOption('nome', nome, Operators.CONTAINS);
      findPessoa()
    }, 800);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  // const renderUnidadePessoas = (pessoaId: number) => {
  //   const predicateUsuarioUnidade = new Predicate();
  //   predicateUsuarioUnidade.addOption('pessoa.id', pessoaId);
  //   return usuarioUnidadeService.findCleanList(predicateUsuarioUnidade)
  // }

  const onChange = e => {
    const pessoa = e.target.value;
    setSelected(e.target.value);
    setUsuario({ ...usuario, pessoa, login: pessoa.email });
  }

  return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={16}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreateFranquia current={0} />
			<div className="franquia">
				<div className="user-row">
					<h3 id="colaborador-title">Selecione um colaborador</h3>
					<div
						className="input-text"
						style={{ width: '100%', marginBottom: 15 }}
					>
						{/* <input type="text" placeholder="Buscar Usuário" /> */}
						<Input
							placeholder="Maria Silva Oliveira Gonçalves"
							prefix={<SearchOutlined rev={undefined} />}
							onChange={(event) => setNome(event.target.value)}
						/>
					</div>
					<Radio.Group
						style={{ width: '100%' }}
						onChange={onChange}
						value={selected}
					>
						{listPessoa &&
							listPessoa.map((item, index) => (
								<div
									key={index}
									className={
										usuario.pessoa.id === item.id
											? 'box-colaborador-selected'
											: 'box-colaborador'
									}
								>
									<Radio value={item} checked={item.id === usuario.pessoa.id} />
									<div className="box-text">
										<h3>{item.nome}</h3>
										<div className="box-unidade">
											<h6>Unidades que tem acesso</h6>
											<h5>
												{/* {renderUnidadePessoas(item.id).then(({ data }) => data.map(uu => uu.unidadeDescricao + ', ').slice(0, -2))} */}
											</h5>
										</div>
									</div>
								</div>
							))}
					</Radio.Group>
					<div className="botoes">
						{revisao ? (
							<Link to="/usuarios/create/franquia/step-4">
								<Button
									type="primary"
									className="button-primary"
									style={{ width: 700 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						) : (
							<>
								<Link to="/usuarios">
									<Button className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/usuarios/create/franquia/step-2">
									<Button className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Link to="/usuarios">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
}

export default UserCreateFranquiaStep1;