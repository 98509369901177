import { Progress, Steps } from 'antd';
import 'antd/dist/antd.css';

import './style.css';

interface Props { step: number; percent: number }
export const MatriculaProgresso: React.FunctionComponent<Props> = ({ step, percent }: Props) => {

  const { Step } = Steps;

  return (
    <>
      <div className="progress">
        <Progress className="progress-user" percent={percent} strokeLinecap="square" showInfo={false} />
      </div>
      <div className="menu-user">
        <h3>Criação de matricula</h3>
        <Steps direction="vertical" current={step}>
          <Step title="Seleção de curso" />
          <Step title="Seleção de turma" />
          <Step title="Responsável financeiro" />
          <Step title="Plano de pagamento" />
          <Step title="Pagamento de Matrícula" />
          <Step title="Emissão de contrato" />
        </Steps>
      </div>
    </>
  );

}
