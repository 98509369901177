import { Rating } from "@material-ui/lab";
import { Button, DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { ICampanhaLeadEscolaCleanDTO, ILeadEscolaEstagioCleanDTO, ILeadEscolaOrigemDTO } from '../../../../../models/happy-code-api.model';
import '../../../shared.scss';
import BotoesCadastroComponent from '../../BotoesCadastro/botoes-cadastro.component';
import StepCadastroComponent from '../../StepCadastro/step-cadastro.component';
import './novo-lead-step-4.scss';
import useNovoLeadStep4ComponentState from './novo-lead-step-4.state';

interface Props {
  onClose?: () => void;
  onNext?: () => void;
  onFecharModal?: () => void;
}

export const NovoLeadStep4 = (props: Props) => {

  const { state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
    leadOrigens,
    leadCampanhas,
    leadEstagios,
    setDtProximoRetorno,
    dtProximoRetorno,
    setAvaliacaoLead,
    avaliacaoLead,
  } = useNovoLeadStep4ComponentState();

  const [form] = Form.useForm();

  const { Option } = Select;

  const setCampo = (dsCampo, valor) => {
    const field = {
      [dsCampo]: valor,
      [dtProximoRetorno]: valor,
    }
  }


  return (
    <>
      <div id="novo-lead-step-1" className="flex flex-row justify-center align-center modal-container modalBox">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onFecharModal} className="button-x">x</Button>
          <div className='container-titulo'>
            <h1>Cadastrar Lead</h1>
          </div>
          <h3>Informações do Lead</h3>
          <Form
            style={{ minWidth: '100%' }}
            name="control-hooks"
            initialValues={leadParaCadastrar}
            form={form}
            onFinish={onFinish}
          >

            <div className="col">
              <div className="flex width-100">
                <div className="inputModal">
                  <span>Responsável<span className="vermelho"></span></span>
                  <Form.Item name={'responsavel'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o nome do responsável' }]}>
                    <Input id="responsavel" />
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-1">
                <div className="inputModal">
                  <span>Origem<span className="vermelho">*</span></span>
                  <Form.Item
                    name={['origem', "id"]}
                    fieldKey={['origem', "nome"]}
                    style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione a origem do lead' }]}>
                    <Select id="origem" style={{ width: '100%' }} bordered={false}>
                      {leadOrigens?.map?.((origem: ILeadEscolaOrigemDTO) => <Option key={origem.id} value={origem.id}>{origem.descricao}</Option>)}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-1">
                <div className="inputModal">
                  <span>Campanhas</span>
                  <Form.Item
                    name={['campanha', "id"]}
                    fieldKey={['campanha', "nome"]}
                    style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a campanha' }]}>
                    <Select
                      style={{ width: '100%' }}
                      bordered={false}
                      showSearch
                      optionFilterProp="children"
                      filterOption={
                        (input, option) =>
                          option.texto.toLowerCase().includes(input.toLowerCase())
                      }>
                      {leadCampanhas?.map?.((campanha: ICampanhaLeadEscolaCleanDTO) =>
                        <Option key={campanha.id} value={campanha.id} texto={campanha.descricao}>{campanha.descricao}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-1">
                <div className="inputModal">
                  <span>Estágio<span className="vermelho">*</span></span>
                  <Form.Item
                    name={['estagio', "id"]}
                    fieldKey={['estagio', "nome"]}
                    style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione o estágio inicial do lead' }]}>
                    <Select id="estagio" style={{ width: '100%' }} bordered={false}>
                      {leadEstagios?.map?.((estagio: ILeadEscolaEstagioCleanDTO) => <Option key={estagio.id} value={estagio.id}>{estagio.descricao}</Option>)}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="flex flex-1">
                <div className="inputModal">
                  <h6>Data Próximo Retorno</h6>
                  <Form.Item
                    name={['dataProximoRetorno']}
                    fieldKey={['dataProximoRetorno']}
                    style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione  a data do próximo retorno' }]}
                    initialValue={moment(dtProximoRetorno)}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={'DD-MM-YYYY'}
                      onChange={e => setDtProximoRetorno(e)}
                      defaultValue={moment(dtProximoRetorno)}
                    />
                  </Form.Item>

                </div>
              </div>


              <div className="horizontal-separator" />

            </div>

            <h3>Avaliação do Lead</h3>

            <div className="flex width-100">
              <Form.Item name={'avaliacaoLead'}>
                <Rating
                  name="avaliacaoLead"
                  id="avaliacaoLead"
                  defaultValue={0}
                  size={'large'}
                  onChange={(event, newValue: any) => {
                    setAvaliacaoLead(newValue);
                    setCampo('avaliacaoLead', newValue);
                  }}
                />
              </Form.Item>
            </div>


            <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
            </div>
            <div className="vertical-separator" />
            <StepCadastroComponent />
            <BotoesCadastroComponent />
          </Form>
        </div>

        {/* <ModalResposta
          open={isModalSucessoOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link="crm"
        />
        <ModalResposta
          open={isModalErroOpen}
          onClose={onClose1}
          status="Ocorreu um erro!"
          text="Ocorreu um erro ao salvar o lead, por favor, tente novamente."
          link="crm"
        /> */}

      </div >
    </>
  )
}
