import { ArrowLeftOutlined, SelectOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Select } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { TableState } from '../../enum/table-state.enum';

import { IAulaCustomDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMatriculaService } from '../../services/matricula.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import { IMatriculaFullDTO } from './../../models/happy-code-api.model';
import { useAulaService } from './../../services/aula.service';
import { useTurmaService } from './../../services/turma.service';
import './style.css';

export const StemplayTurmasProgresso: React.FunctionComponent = () => {
    const { id } = useParams<any>();
    const { removeTurma } = useTurmaContext();
    const [turma, setTurma] = useState<ITurmaFullDTO>();
    const [listAula, setListAula] = useState<PageableResponse<IAulaCustomDTO>>();
    const [listMatricula, setListMatricula] = useState<PageableResponse<IMatriculaFullDTO>>();
    const [tableState, setTableState] = useState<TableState>(TableState.ALUNO);
    const { setIsGlobalLoading } = useGlobalContext();
    const turmaService = useTurmaService();
    const aulaService = useAulaService();
    const matriculaService = useMatriculaService();
    const [predicateAula] = useState<Predicate>(new Predicate());
    const [matriculaPredicate] = useState<Predicate>(new Predicate());
    const history = useHistory();
    const [mostrarModalAluno, setMostrarModalAluno] = useState<boolean>(false);
    const [mostrarModalNovoAluno, setMostrarModalNovoAluno] = useState<boolean>(false);

    const findAula = () => {
        predicateAula.addOption('turma.id', id);
        return aulaService.findCustom(predicateAula)
            .then((response) => {
                setListAula(response.data);
            })
    }

    const findMatricula = () => {
        matriculaPredicate.addOption('turma.id', id);
        return matriculaService.findFull(matriculaPredicate)
            .then((response) => {
                setListMatricula(response.data);
            })
    }

    const findTurma = () => {
        return turmaService.findFullById({ id })
            .then((response) => {
                setTurma(response.data);
            });
    }


    useEffect(() => {
        removeTurma();
        if (id) {
            setIsGlobalLoading(true);
            Promise.all([
                findTurma(),
                findAula(),
                findMatricula()
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const statusTurma = (turma: ITurmaFullDTO) => {
        if (isBeforeDate(turma.dataInicio)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Não Iniciado</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        if (isDateBetween(turma.dataInicio, turma.dataFim)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Em andamento</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        if (isAfterDate(turma.dataFim)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Finalizado</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        return null;
    }

    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicateAula.setPage(page);
        setIsGlobalLoading(true);
        findAula().finally(() => setIsGlobalLoading(false));
    }

    const onPageChangeMatricula = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        matriculaPredicate.setPage(page);
        setIsGlobalLoading(true);
        findMatricula().finally(() => setIsGlobalLoading(false));
    }

    const qtdAulaRealizadas = () => {
        if (turma && turma.aulas) {
            return turma.aulas.filter((aula) => isBeforeDate(aula.data)).length;
        }
        return 0;
    }

    const getPorcentagem = () => {
        if (turma && turma.aulas) {
            return parseInt(((qtdAulaRealizadas() / turma.aulas.length) * 100).toPrecision(2));
        }
        return 0;
    }
    const aluno = () => {
        setMostrarModalAluno(true);
    }

    const closeModalAluno = () => {
        setMostrarModalAluno(false);
    }
    const novoAluno = () => {
        setMostrarModalNovoAluno(true);
    }

    const closeModalNovoAluno = () => {
        setMostrarModalNovoAluno(false);
    }

    const arrReferencia = [
        {
            nome: 'Lucas',
            sobrenome: 'Silva',
            progresso: [1, 2, 3, 0, 2, 3, 1, -1, 0, 2, 3, -1, -1, 2, 2, 3, 1, -1, 1, 0, 2, 3, 2, 0, 1, 0, 3, -1, 0, 1],
            porcentagem: 0.1,
        },
        {
            nome: 'Bruna',
            sobrenome: 'Souza',
            progresso: [1, 1, -1, 0, 2, 3, 1, -1, 0, 1, 2, 2, -1, -1, 0, 1, 1, 0, -1, 3, 0, 3, 2, 0, 1, 0, 3, -1, 0, 1],
            porcentagem: 0.1,
        }
    ]

    const funcaoVerificaCor = (numero: number) => {
        if (numero === -1) {
            return "cinza";
        }
        if (numero === 0) {
            return "bolinha-vermelho";
        }
        if (numero === 1) {
            return "bolinha-amarelo";
        }
        if (numero === 2) {
            return "bolinha-verde";
        }
        if (numero === 3) {
            return "bolinha-azul";
        }

    }

    const arrayFases = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];



    return (
        <div className="container-fondo">
            <NewMenu selecionado={30} />
            <div className="franquia" id="aluno-detalhe-row">
                <div className="progress-row" style={{ marginTop: 70 }} id="row-aluno-box">
                    <div className="head-botoes">
                        <Link to="/Stemplay/detalhe">
                            <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
                        </Link>
                        <h5 style={{ marginLeft: 15 }}>Progresso de turma</h5>
                        <div className="botao">
                            <Link to="/Stemplay/relatorio-individual">
                                <Button className="button-primary" icon={<SelectOutlined rev={undefined} />}>Relatório Individual</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="box-data-aluno">
                        <div className="data-aluno" style={{ width: '50%' }}>
                            <div id="turma-detalhe-s">
                                <h1 style={{ fontSize: '25px' }}>Nome da Turma</h1>
                                <div className="data" style={{ marginTop: '50px' }}>
                                    <div className="data1">
                                        <div className="dat">
                                            <div className="dat2">
                                                <Unicons.UilGraduationCap size="20" color="#6A7C96" />
                                                <p>etapa escolar</p>
                                            </div>
                                        </div>
                                        <div className="dat">
                                            <div className="dat2">
                                                <Unicons.UilClockEight size="20" color="#6A7C96" />
                                                <p>ano</p>
                                            </div>
                                        </div>
                                        <div className="dat">
                                            <div className="dat2">
                                                <Unicons.UilSmile size="20" color="#6A7C96" />
                                                <p>alunos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="progresso">
                            <div className='data-progresso'>
                                <h1>Progresso</h1>
                            </div>
                            <div className="data" style={{ marginTop: '0px' }}>
                                <div className="data2">
                                    <div className="datt">
                                        <div className="datt2">
                                            <Unicons.UilRocket size="20" color="#6A7C96" />
                                            <p>missões</p>
                                        </div>
                                    </div>
                                    <div className="datt">
                                        <div className="datt2">
                                            <Unicons.UilLayerGroup size="20" color="#6A7C96" />
                                            <p>fases</p>
                                        </div>
                                    </div>
                                    <div className="datt">
                                        <div className="datt2">
                                            <Unicons.UilCheckCircle size="22" color="#6A7C96" />
                                            <p>% concluído</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-missao">
                        <div className='missao-content-1'>
                            <h2>Missão</h2>
                            <Select className='missao-placeholder'
                                style={{ width: '100%' }}
                                placeholder={'Selecione uma missão'}
                            ></Select>
                        </div>
                        <div className="missao-content-2">
                            <h1 className="missao-vazio">Esta turma não tem missão.</h1>
                        </div>
                        <div className="missao-content-3" style={{ gap: '12px' }}>
                            <input type="checkbox" className="cinza" checked>
                            </input>
                            <p>não jogou</p>
                            <input type="checkbox" className="vermelho" checked>
                            </input>
                            <p>0 moeda</p>
                            <input type="checkbox" className="amarelo" checked>
                            </input>
                            <p>1 moeda</p>
                            <input type="checkbox" className="verde" checked>
                            </input>
                            <p>2 moedas</p>
                            <input type="checkbox" className="azul" checked>
                            </input>
                            <p>3 moedas</p>
                        </div>
                    </div>
                    <div className="column-missao">
                        <div className="tabela">
                            <div className="titles-users" style={{ marginTop: 30 }}>
                                <div className="item-stemplay" style={{ width: '85%' }}>
                                    <span>NOME</span>
                                </div>
                                {arrayFases.map((fases, index) =>
                                    <div key={index} className="item-stemplay" style={{ width: '15%' }}>
                                        <span>{fases}</span>
                                    </div>
                                )}
                                <div className="item-stemplay" style={{ width: '30%' }}>
                                    <span>%</span>
                                </div>
                                <div className="item-stemplay" style={{ width: '80%' }}>
                                    <span>TEMPO DE JOGO</span>
                                </div>
                            </div>
                        </div>

                        {arrReferencia.map((ref, index) => (
                            <div key={index} className="tabela">
                                <div className="titles-users">
                                    <div className="relatorio-stemplay" style={{ width: '15%' }}>
                                        <p>{ref.nome} {ref.sobrenome}</p>
                                    </div>
                                    <div className="bolinha-row" style={{ width: '80%' }} >
                                        {ref.progresso.map((x, index) =>
                                            <div key={index} style={{ width: '15%' }}>
                                                <p className={funcaoVerificaCor(x)}></p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relatorio-stemplay" style={{ width: '5%' }}>
                                        <p>50%</p>
                                    </div>
                                    <div className="relatorio-stemplay" style={{ width: '14%' }}>
                                        <p>00:00:00</p>
                                    </div>
                                </div>
                            </div>
                        ))}


                        {tableState === TableState.AULA ? (
                            <div className="paginacao" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={listAula ? listAula.pageable.pageNumber + 1 : 0} count={listAula ? listAula.totalPages : 0} />
                            </div>
                        ) : (
                            <div className="paginacao" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Pagination onChange={(event, page) => onPageChangeMatricula(event, page - 1)} page={listMatricula ? listMatricula.pageable.pageNumber + 1 : 0} count={listMatricula ? listMatricula.totalPages : 0} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StemplayTurmasProgresso