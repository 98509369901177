import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tags } from '../../components/Tags';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { IUsuarioDTO } from "../../models/happy-code-api.model";
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioService } from '../../services/usuario.service';
import { Avatar } from './../../components/Avatar/index';
import { TipoAcessoAcademy } from '../../enum/tipoAcessoAcademy';

interface TItemLista {
    usuario: IUsuarioDTO;
}

const ItemList = memo(({ usuario }: TItemLista) => {
    const [ativo, setAtivo] = useState<boolean>();

    const notification = useNotificationService();

    const history = useHistory();
    const usuarioService = useUsuarioService();
    const { setIsGlobalLoading } = useGlobalContext();
    // const [usuario, setUsuarioItem] = useState(props.usuario)

    const { setUsuario } = useUsuarioContext();

    useEffect(() => {
        setAtivo(usuario.ativo);
    }, [usuario.ativo])

    const goTo = () => {
        setIsGlobalLoading(true);
        usuarioService.findFullById(usuario)
            .then(({ data }) => setUsuario(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/usuarios/create/step-7`)
            });
    }

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'desativar' : 'ativar'} o usuário?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: "Nome: " + usuario.pessoa.nome,
            okText: 'Yes',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'No',
            onOk: () => {
                usuarioService.patch({ id: usuario.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Usuário alterado com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false));
            },
            // onCancel: () => { },
        });
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            <Menu.Item onClick={showDeleteConfirm} >
                <a rel="noopener noreferrer">
                    Desativar/Ativar Usuário
                </a>
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="registro-user" id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '20%' }}>
                <Avatar arquivo={usuario.arquivo} />
                <div className="nome">
                    <p>{usuario.pessoa.nome}</p>
                    <div className="estado-user">
                        <div className={ativo ? "dot-verde" : "dot-vermelho"}></div>
                        <p className={ativo ? "ativo" : "inativo"}>{ativo ? "Ativo" : "Inativo"}</p>
                    </div>
                </div>
            </div>
            <div className="funcao-user" style={{ width: '20%' }}>
                <Tags tipo={usuario?.funcao?.descricao || 'colaborador'} />
            </div>
            <div className="funcao-user" id="usuario" style={{ width: '20%' }}>
                <Tags tipo={usuario.perfil.descricao} />
            </div>
            <div className="funcao-user" id="usuario" style={{ width: '35%' }}>
                {usuario?.acessoB2c ?
                    <div className="mr5">
                        <Tags tipo={TipoAcessoAcademy.CODIGO_B2C} />
                    </div>
                    : null}
                {usuario?.acessoB2b ?
                    <div className="mr5">
                        <Tags tipo={TipoAcessoAcademy.CODIGO_B2B} />
                    </div>
                    : null}
                {usuario?.acessoComercial ?
                    <div className="mr5">
                        <Tags tipo={TipoAcessoAcademy.CODIGO_COMERCIAL} />
                    </div>
                    : null}
            </div>
            <div className="funcao-user" id="usuario" style={{ width: '20%' }}>
                <p>{usuario.instrutorStemplay ? <span className="active font-weight-bold">Ativo</span> : <span className="inactive font-weight-bold">Inativo</span>}</p>
            </div>
            {/* <div className="funcao-user" id="usuario" style={{ width: '30%' }}>
                <p>{usuario.usuarioUnidades.map((unidade, index, arr) => {
                    let aux = ''

                    switch (index) {
                        case 0:
                            aux += unidade.unidadeDescricao
                            break;
                        case 1:
                        case 2:
                        case 3:
                            aux += ',' + unidade.unidadeDescricao
                            break;
                        default:
                            break;
                    }

                    if (index > 3 && index === (arr.length - 1)) {
                        aux += ' e +' + arr.length
                    }
                    return aux
                }, [])}
                </p>
            </div> */}
            {/* <p>BA - Vitória da Conquista, BA - Vitória da Conquista, BA - Vitória da Conquista, e +2</p> */}
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemList.displayName = 'ItemList';


export default ItemList;