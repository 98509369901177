import { Table } from 'antd';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';
import { ExportToExcel } from '../../../util/exportToExcel';

function RelInadimplenciaHub() {
	const { unidade } = useGlobalContext();
	const service = useRelatorioService();
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [dados, setData] = useState<[]>();

	useEffect(() => {
		const predicate = new Predicate();

		unidade.id && predicate.addOption('unidadeId', unidade.id);

		service
			.inadimplenciaHub(predicate)
			.then(({ data }) => setData(data))
			.catch((error) => console.log(error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	return (
		<div className="container">
			<NewMenu selecionado={1} />.
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório financeiro
				</h1>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<div style={{ marginBottom: 10 }}>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={dados}
							fileName={'relatório inadimplência'}
						/>
					</div>
				</div>
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}

export default RelInadimplenciaHub;
