import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Progress, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { useSalaContext } from '../../context/SalaContext';
import '../../global.css';
import { IModalidadeCleanDTO, IRecursoCleanDTO } from '../../models/happy-code-api.model';
import { useModalidadeService } from '../../services/modalidade.service';
import { useNotificationService } from '../../services/notification.service';
import { useRecursoService } from '../../services/recurso.service';
import { useSalaService } from '../../services/sala.service';
import StepsCreate from './steps-create';
import './style.css';

const SalaCreateStep1: React.FunctionComponent = () => {
  const { unidade } = useGlobalContext();
  const [recursoList, setRecursoList] = useState<IRecursoCleanDTO[]>();
  const [modalidadeList, setModalidadeList] = useState<IModalidadeCleanDTO[]>();
  const [isOpen, setIsOpen] = useState(false);
  const recursoService = useRecursoService();
  const modalidadeService = useModalidadeService();
  const salaService = useSalaService();
  const { id } = useParams<any>();
  const { sala, setSala } = useSalaContext();
  const { salaRecurso, setSalaRecurso } = useSalaContext();
  const { resetSala } = useSalaContext();

  const fetchsala = () => {
    salaService.findCustom(id).then((response) => setSalaRecurso(response.data));
  }

  const fetchRecursos = () => {
    return recursoService.findCleanList().then((response) => setRecursoList(response.data));
  }

  const fetchModalidade = () => {
    return modalidadeService.findCleanList().then((response) => setModalidadeList(response.data));
  }

  useEffect(() => {
    setSala({ ...sala, unidade: { ...sala?.unidade, id: unidade?.id } })
  }, [unidade?.id])

  useEffect(() => {
    if (id) {
      fetchsala()
    }
    fetchRecursos();
    fetchModalidade();

  }, [])

  const history = useHistory();
  const onFinish = () => {
    history.push("/sala");
  }

  const onSalaChange = (op) => {
    if (id) {
      setSalaRecurso({ ...salaRecurso, recursos: op.map(o => ({ id: null, ativo: true, descricaoRecurso: o.value })) })
    } else {
      setSala({ ...sala, recursos: op.map(o => ({ id: null, ativo: true, descricaoRecurso: o.value })) })
    }
  }

  const onSalaDescricaoChange = (op) => {
    if (id) {
      setSalaRecurso({ ...salaRecurso, sala: { ...salaRecurso?.sala, descricaoSala: op.target.value } })
    } else {
      setSala({ ...sala, descricaoSala: op.target.value })
    }
  }

  const onSalaMaxAlunoChange = (op) => {
    if (id) {
      setSalaRecurso({ ...salaRecurso, sala: { ...salaRecurso?.sala, capacidadeMaxima: op } })
    } else {
      setSala({ ...sala, capacidadeMaxima: op })
    }
  }


  const onModalidadeChange = (op) => {
    const modalidade = {
      id: op.id,
      ativo: true,
      descricao: op.value,
      codigo: '',
    };

    if (id) {
      setSalaRecurso({
        ...salaRecurso,
        sala: {
          ...salaRecurso.sala,
          modalidade: modalidade,
        },
      });
    } else {
      setSala({
        ...sala,
        modalidade: modalidade,
      });
    }
  };

  const optionsRecursos = recursoList?.map(u => <Select.Option key={u.id} value={u.descricaoRecurso}>{u.descricaoRecurso}</Select.Option>)
  const optionsModalidade = modalidadeList?.map(u => <Select.Option key={u.id} value={u.descricao}>{u.descricao}</Select.Option>)

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={16} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">
        <div className="user-row">
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3 id="taxas-h3">Insira o nome da sala</h3>
            <div className="input-box">
              <p>Nome da sala</p>
              <Input
                style={{ width: "100%" }}
                id="nome"
                value={sala?.descricaoSala}
                onChange={e => onSalaDescricaoChange(e)} />
            </div>
            <div className="input-box">
              <p>Capacidade máxima</p>
              <InputNumber
                style={{ width: "100%" }}
                id="descricao"
                min={0}
                value={sala?.capacidadeMaxima}
                onChange={e => onSalaMaxAlunoChange(e)} />
            </div>
            <div className="input-box">
              <p>Recursos</p>
              <Select mode="multiple"
                style={{ width: "100%" }}
                placeholder="Selecione os recursos"
                defaultValue={salaRecurso?.recursos?.map(s => (s?.descricaoRecurso))}
                value={sala?.recursos?.map(s => (s?.descricaoRecurso))}
                onChange={(value, op) => onSalaChange(op)}>
                {optionsRecursos}
              </Select>
            </div>
            <div className="input-box">
              <p>Modalidade</p>
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione a modalidade"
                defaultValue={salaRecurso?.sala?.modalidade?.descricao}
                value={sala?.modalidade?.descricao}
                onChange={(value, op) => onModalidadeChange(op)}
              >
                {optionsModalidade}
              </Select>
            </div>
            <div className="botoes">
              <Link to="/sala">
                <Button onClick={() => resetSala()} className="button-second" id="voltar" style={{ width: 345 }}>Cancelar</Button>
              </Link>
              <Link to="/sala/create/step-2">
                <Button type="primary" id="proximo" className="button-primary" style={{ width: 345 }}>Próximo</Button>
              </Link>
            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa sala?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/sala'
      />
    </div>
  )

}

export default SalaCreateStep1;