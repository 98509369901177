import ClipLoader from "react-spinners/ClipLoader";
import { ICobrancaPagarMeDTO } from '../../models/happy-code-api.model';
import { formatMoney } from '../../util/format';
import Style from './style.module.css';

interface Props {
    cobranca: ICobrancaPagarMeDTO;
    finaliza: () => void;
    recaptchaRef?: any;
    setCurrent: (current: any) => void;
    step: any;
    btnVisible: boolean;
    parcela: number;
    addOneDay: boolean;
}

const Step3 = ({ cobranca, finaliza, recaptchaRef, setCurrent, step, btnVisible, parcela, addOneDay }: Props) => {

    const formatPagamentoDS = () => {
        switch (cobranca?.tipoPagamento?.codigo) {
            case 'CRC':
                return 'Cartão de Crédito'
            case 'CRR':
                return 'Crédito Recorrente'
            case 'BOL':
                return 'Boleto'
            case 'PIX':
                return 'Pix'
            case 'PMF':
                return 'Máquina Física'
            case 'null':
                return 'Não selecionado'
            default:
                return 'Não selecionado'
        }
    }

    const formatarData = (data: Date, dias: number): string => {
        const date = new Date(data);
        date.setDate(date.getDate() + dias);
        const dia = String(date.getDate()).padStart(2, '0');
        const mes = String(date.getMonth() + 1).padStart(2, '0');
        const ano = date.getFullYear();
        return `${dia}/${mes}/${ano}`;
    }

    return (
        <div className={Style.container_rev}>
            <div className={Style.first_line}>
                <div>
                    <h5>PARCELA À PAGAR</h5>
                    <p className='ti5'>{cobranca?.cobrancaUnica ? 'Parcela 1/1' : `Parcela  ${parcela}/${cobranca?.quantidadeParcelas}`}</p>
                </div>
                <div>
                    <h5>VENCIMENTO</h5>
                    <p className='ti5'>{cobranca?.dataVencimento ? formatarData(cobranca?.dataVencimento, addOneDay ? 0 : 1) : 'ERRO: dado não encontrado.'}</p>
                </div>
                <div>
                    <h5>VALOR DA PARCELA</h5>
                    {/* <p className='ti5'>{`${cobranca?.cobrancaUnica ? cobranca?.parcela : 1}x ${formatMoney(cobranca?.valorCobranca)}`}</p> */}
                    <p className='ti5'>{`${cobranca?.cobrancaUnica ? cobranca?.parcela + 'x de ' + formatMoney(cobranca?.valorCobranca / cobranca?.parcela) : formatMoney(cobranca?.valorCobranca)}`}</p>
                </div>
            </div>

            <div className={Style.second_line}>
                <div>
                    <h5>FORMA DA PAGAMENTO</h5>
                    <p className='ti5'>{formatPagamentoDS()}</p>
                </div>
            </div>

            {/* <div className={Style.container_captcha}>
                            <ReCaptchaV2  ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY}/>
                        </div> */}
            {btnVisible ? <button disabled={true} className={Style.btn_step3}><ClipLoader color='#FFF' /></button>
                : <button onClick={finaliza} className={Style.btn_step3}>concluir pagamento</button>}
            {window.innerWidth < 768 ? <button onClick={() => setCurrent(step.PAGAMENTO)} className={Style.btn_step3_voltar}>VOLTAR</button> : null}
        </div>
    )
}

export default Step3