import { useHistory } from 'react-router-dom';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { useLogService } from '../../../services/log.service';
import { ILogCleanDTO } from '../../../models/happy-code-api.model';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useEffect } from 'react';

export default function useGroupListagem() {
	const history = useHistory();
	const onCriarGrupoPress = (): void =>
		history.push('/escolas/grupos/create/step-1');

	const { removeUnidade, setRevisao } = useUnidadeContext();

	const logService = useLogService();
	const log: ILogCleanDTO = {
		descricaoEvento: 'Acesso tela grupos',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	};

	const {
		limparContratoParaCadastrar,
		limparEscolaParaCadastrar,
		limparGrupoParaCadastrar,
		fetchAll,
	} = useEscolaContext();

	useEffect(() => {
		logService.registraLog(log);

		fetchAll();

		limparEscolaParaCadastrar();
		limparContratoParaCadastrar();
		limparGrupoParaCadastrar();

		removeUnidade();
		setRevisao(false);
	}, []);

	return {
		onCriarGrupoPress,
	};
}
