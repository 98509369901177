export enum TipoUnidadePessoa {
    ID_ADM = "1",
    ID_GST = "2",
    ID_ISTHUB = "3",
    ID_ISTEPS = "5",
    ID_ISTABS = "6",
    ID_SOC = "4",

    CODIGO_ADM = "ADM",
    CODIGO_GST = "GST",
    CODIGO_ISTHUB = "ISTHUB",
    CODIGO_ISTEPS = "ISTEPS",
    CODIGO_ISTABS = "ISTABS",
    CODIGO_SOC = "SOC",

    DESCRICAO_ADM = "Administrativo/Vendas",
    DESCRICAO_GST = "Gerencia",
    DESCRICAO_ISTHUB = "Instrutor Hub",
    DESCRICAO_ISTEPS = "Instrutor Empresa",
    DESCRICAO_ISTABS = "Instrutor Ambos",
    DESCRICAO_SOC = "Sócio",
    
}