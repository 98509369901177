import {
	IPerfilCleanDTO,
	IPerfilDTO,
	IPerfilFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const usePerfilService = () => {
	const resourceURL = 'perfil';

	return {
		...baseFindList<IPerfilCleanDTO, IPerfilDTO, IPerfilFullDTO>(resourceURL),
	};
};
