export enum TipoStatusCobranca {
    CODIGO_A_GERAR = "AGR",
    CODIGO_GERADO = "GRD",
    CODIGO_PAGO = "PGO",
    CODIGO_ESTORNADO = "EST",
    CODIGO_CANCELADO = "CAN",
    CODIGO_ISENTO = "IST",
    CODIGO_FALHA = "FLH",
    CODIGO_REPACTUADO = "RPC",
    CODIGO_VENCIDO = "VNC",

    DESCRICAO_A_GERAR = "A gerar",
    DESCRICAO_GERADO = "Gerado",
    DESCRICAO_PAGO = "Pago",
    DESCRICAO_ESTORNADO = "Estornado",
    DESCRICAO_CANCELADO = "Cancelado",
    DESCRICAO_ISENTO = "Isento",
    DESCRICAO_FALHA = "Falha",
    DESCRICAO_REPACTUADO = "Repactuado",
    DESCRICAO_VENCIDO = "Vencido",
}
