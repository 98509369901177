
interface Props {
    file?: File;
    base64?: string;
    link?: string;
    size?: number | string;
}

export const PresentImage = (props: Props) => {
    const { file, base64, link, size } = props;

    const validarTipoArquivo = () => {
        if (file) {
            return URL.createObjectURL(file)
        }
        if (base64) {
            return `data:image/png;base64,${base64}`
        }
        if (link) {
            return link
        }
    }

    return (
        <div>
            {(file || base64 || link) && <img src={validarTipoArquivo()} style={{ height: size ?? 40, width: size ?? 40 }} />}
        </div>
    );
};
