import { useMemo } from 'react';
import '../../../shared.scss';
import { NovoLeadStep1 } from '../novo-lead-step-1/novo-lead-step-1';
import { NovoLeadStep2 } from '../novo-lead-step-2/novo-lead-step-2';
import { NovoLeadStep3 } from '../novo-lead-step-3/novo-lead-step-3';
import { NovoLeadStep4 } from '../novo-lead-step-4/novo-lead-step-4';
import { NovoLeadStep5 } from '../novo-lead-step-5/novo-lead-step-5';
import { NovoLeadStep6 } from '../novo-lead-step-6/novo-lead-step-6';
import '../style.scss';
import useContainerNovoLeadState from './selectors/container-novo-lead.state';


export const ContainerNovoLead = () => {
  const { flModalOpen,
    setIndexCadastro,
    indexCadastro, } = useContainerNovoLeadState();

  const renderConteudo = useMemo(() => {
    if (indexCadastro === 1) {
      return <NovoLeadStep1 />
    }
    if (indexCadastro === 2) {
      return <NovoLeadStep2 />
    }
    if (indexCadastro === 3) {
      return <NovoLeadStep3 />
    }
    if (indexCadastro === 4) {
      return <NovoLeadStep4 />
    }
    if (indexCadastro === 5) {
      return <NovoLeadStep5 />
    }

    if (indexCadastro === 6) {
      return <NovoLeadStep6 />
    }

    return <></>
  }, [indexCadastro])

  if (!flModalOpen) return null;

  return <div className="flex justify-center align-center modal-container">
    {renderConteudo}
  </div>
}
