import { Button, DatePicker, Form, Rate, Select } from 'antd';
import { useState } from "react";
import { ILeadCampanhaCleanDTO, ILeadEscolaOrigemDTO, IStatusLeadEscolaDTO } from "../../../../../models/happy-code-api.model";
import { useCrmEscolaContext } from "../../../context/crm-escola.context";
import '../../../shared.scss';
import { ModalResposta } from '../resposta';
import '../style.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onChangeFiltroGeral: (any) => void;
}

export const ModalFilter = (props: Props) => {
  const { open, onClose } = props;
  const { Option } = Select;
  const { state, } = useCrmEscolaContext();
  const [form] = Form.useForm();

  function handleChange(value: any) {
    return;
  }

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const onChangeModal1 = () => {
    form.validateFields().then(() => {
      if (isOpen1 === true) {
        setIsOpen1(false)
      } else {
        setIsOpen1(true)
      }
    });

  }

  const onClose1 = () => {
    setIsOpen1(false)
  }

  const onChangeModal2 = () => {
    if (isOpen2 === true) {
      setIsOpen2(false)
    } else {
      setIsOpen2(true)
    }
  }

  const onClose2 = () => {
    setIsOpen2(false)
  }

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={onClose} className="button-x">x</Button>

          <Form
            style={{ maxWidth: '100%' }}
            name="basic"
            // initialValues={{ remember: true }}
            form={form}
          >
            <h3>Filtrar resultados</h3>
            <div className="text-over-input">
              <small>Status</small>
            </div>
            <div className="input-modal mb-10">
              <Form.Item
                name={['status', "id"]}
                fieldKey={['status', "nome"]}
                style={{ width: '100%' }} rules={[{ required: false, message: 'Defina o status do Lead' }]}>
                <Select style={{ width: '100%' }} bordered={false} >
                  {state?.leadStatus?.map?.((status: IStatusLeadEscolaDTO) => <Option key={status.id} value={status.id}>{status.descricao}</Option>)}
                </Select>
              </Form.Item>
            </div>

            <div className="text-over-input">
              <small>Campanha</small>
            </div>
            <div className="input-modal mb-10">
              <Form.Item
                name={['campanha', "id"]}
                fieldKey={['campanha', "nome"]}
                style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a campanha' }]}>
                <Select style={{ width: '100%' }} bordered={false}>
                  {state?.campanhas?.map?.((campanha: ILeadCampanhaCleanDTO) => <Option key={campanha.id} value={campanha.id}>{campanha.dsLeadCampanha}</Option>)}
                </Select>
              </Form.Item>
            </div>

            {/* <div className="text-over-input">
              <small>Tipo de Lead</small>
            </div>
            <div className="input-modal mb-10">
              <Form.Item
                name={['tipo', "id"]}
                fieldKey={['tipo', "nome"]}
                style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione o tipo do lead' }]}>
                <Select id="estagio" style={{ width: '100%' }} bordered={false}>
                  {leadTipos?.map?.((leadTipo: ILeadTipoDTO) => <Option key={leadTipo.id} value={leadTipo.id}>{leadTipo.dsLeadTipo}</Option>)}
                </Select>
              </Form.Item>
            </div> */}

            <div className="text-over-input">
              <small>Origem</small>
            </div>
            <div className="input-modal mb-10">
              <Form.Item
                name={['origem', "id"]}
                fieldKey={['origem', "nome"]}
                style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a origem do lead' }]}>
                <Select id="origem" style={{ width: '100%' }} bordered={false}>
                  {state?.leadOrigens?.map?.((origem: ILeadEscolaOrigemDTO) => <Option key={origem.id} value={origem.id}>{origem.descricao}</Option>)}
                </Select>
              </Form.Item>
            </div>

            {/* <div className="text-over-input">
              <small>Unidade</small>
            </div>
            <div className="input-modal mb-10">
              <Form.Item
                name={['unidade', "id"]}
                fieldKey={['unidade', "nome"]}
                style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a unidade' }]}>
                <Select style={{ width: '100%' }} bordered={false}>
                  {unidades?.map?.((unidade: IUnidadeCleanDTO) => <Option key={unidade.id} value={unidade.id}>{unidade.descricao}</Option>)}
                </Select>
              </Form.Item>
            </div> */}

            <div className="text-over-input">
              <small>Filtro por</small>
            </div>
            <div className="input-modal mb-10">
              <Form.Item name={'filtroPor'} style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione o tipo de data' }]}>
                <Select style={{ width: '100%' }} bordered={false} defaultValue={"data-criacao"}>
                  <Option value="data-criacao">Data de criação</Option>
                  <Option value="data-retorno">Data de retorno</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <div className="w-50" style={{ marginRight: 5 }}>
                <div className="text-over-input">
                  <small>Data de início</small>
                </div>
                <div className="input-modal mb-10">
                  <Form.Item name={'dataInicio'} style={{ width: '100%' }} rules={[{ required: false, message: 'Informe a data de Início' }]}>
                    <DatePicker
                      // value={moment(state?.formControl?.dhProximoRetorno)} 
                      suffixIcon={null}
                      format={'DD-MM-YYYY'}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="w-50" style={{ marginLeft: 5 }}>
                <div className="text-over-input">
                  <small>Data de fim</small>
                </div>
                <div className="input-modal mb-10">
                  <Form.Item name={'dataFim'} style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a data de retorno' }]}>
                    <DatePicker
                      // value={moment(state?.formControl?.dhProximoRetorno)}
                      // onChange={(value) => setCampo('dhProximoRetorno', value)}
                      suffixIcon={null}
                      format={'DD-MM-YYYY'}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <h6 className="mt-5 mb-10">Filtro por avaliação</h6>
            <div className="flex flex-row align-center">
              <Form.Item name={'avaliacaoLead'} style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a avaliação' }]}>
                <Rate />
              </Form.Item>
            </div>
            <div className="flex flex-row justify-end w-100 mt-30">
              <Button onClick={onChangeModal2} className="button-blue mr-5">Limpar Filtros</Button>
              <Button onClick={onChangeModal1} className="button-primary">Aplicar Filtros</Button>
            </div>
          </Form>
        </div>
        <ModalResposta
          open={isOpen1}
          onClose={onClose1}
          status="Filtrar resultados"
          text="Os filtros foram aplicados com sucesso!"
          onOkay={() => {
            props.onChangeFiltroGeral(form.getFieldsValue());
            onClose1();
            onClose();
          }}
          link=""
        />
        <ModalResposta
          open={isOpen2}
          onClose={onClose2}
          status="Limpar filtros"
          text="Os filtros aplicados foram removidos com êxito."
          link=""
          onOkay={() => {
            props.onChangeFiltroGeral(form.resetFields());
            onClose2();
            onClose();
          }}
        />
      </div>
    </>
  )
}
