import { useState } from "react";

import * as Unicons from '@iconscout/react-unicons';
import { Button, Cascader, Input, Select } from "antd";
import '../../shared.scss';
import { Checkboxtag } from "../Checkboxtag";
import { ContainerNovoLead } from '../Modal/container-novo-lead/container-novo-lead';
import { ModalFilter } from '../Modal/filter/filter';
import useFiltroState from "./selectors/filtro.state";
import './style.scss';
import { useCrmEscolaContext } from "../../context/crm-escola.context";

interface Props {
  filtros?: boolean;
  button?: string;
  modal?: string
}

export const Header = (props: Props) => {

  const {
    strFiltro,
    onChangeFiltro,
    totalResultados,
    totalResultadosFiltrados,
    onChangeFiltroTipoRetorno,
    onChangeFiltroGeral,
  } = useFiltroState();

  let botao;

  const { filtros, button, modal } = props;
  const { Option } = Select;

  const [isOpenEquipe, setIsOpenEquipe] = useState(false);
  const [isOpenUsuario, setIsOpenUsuario] = useState(false);
  const [isOpenCargo, setIsOpenCargo] = useState(false);
  const { setFlModalOpen, flModalOpen, setFilterSearch, filterSearch } = useCrmEscolaContext();
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const onChangeModalEquipe = () => {
    if (isOpenEquipe === true) {
      setIsOpenEquipe(false)
    } else {
      setIsOpenEquipe(true)
    }
  }

  const onChangeModalUsuario = () => {
    if (isOpenUsuario === true) {
      setIsOpenUsuario(false)
    } else {
      setIsOpenUsuario(true)
    }
  }

  const onChangeModalCargo = () => {
    if (isOpenCargo === true) {
      setIsOpenCargo(false)
    } else {
      setIsOpenCargo(true)
    }
  }

  const onChangeModalLead = () => {
    if (flModalOpen) {
      setFlModalOpen(false)
    } else {
      setFlModalOpen(true)
    }
  }

  const onCloseLead = () => {
    setFlModalOpen(false)
  }

  const onChangeModalFilter = () => {
    if (isOpenFilter === true) {
      setIsOpenFilter(false)
    } else {
      setIsOpenFilter(true)
    }
  }

  const onCloseFilter = () => {
    setIsOpenFilter(false)
  }

  if (modal === 'equipe') {
    botao = <Button onClick={onChangeModalEquipe} className="button-primary">{button}</Button>;
  }

  if (modal === 'users') {
    botao = <Button onClick={onChangeModalUsuario} className="button-primary">{button}</Button>;
  }

  if (modal === 'cargo') {
    botao = <Button onClick={onChangeModalCargo} className="button-primary">{button}</Button>;
  }

  if (modal === 'lead') {
    botao = <Button onClick={onChangeModalLead} className="button-primary">{button}</Button>;
  }

  const selectAfter = (
    <Select
      style={{ height: 30, display: "flex", alignItems: "center", }}
      defaultValue={1}
      onChange={(e) => setFilterSearch(e)}>
      <Select.Option value={1}>Nome escola</Select.Option>
      <Select.Option value={2}>Nome contato</Select.Option>
    </Select>
  );


  return (
    <>
      <div className="header-box">
        <div className="search-box flex flex-row justify-between align-center">
          <div className="flex flex-row justify-start align-center">

            <div className="input-text" style={{ overflow: 'hidden', paddingTop: 10 }}>
              <Input
                height={50}
                placeholder="Buscar"
                onChange={onChangeFiltro}
                bordered={false}
                addonBefore={
                  <Unicons.UilSearch size="20" color="var(--cinza-azulado-500)" />}
                addonAfter={selectAfter} />
            </div>

            {strFiltro && strFiltro?.length >= 3 && <div className="total flex flex-row align-center">
              <h6>Total de Resultados</h6>
              <p>{totalResultados} Leads</p>
            </div>}
          </div>
          {botao}
        </div>
        <div className={`search-box flex flex-row justify-start align-center`}>
          <div onClick={onChangeModalFilter} className="filter-box flex justify-center align-center">
            <Unicons.UilFilter color="var(--white)" size={20} />
          </div>
          <Checkboxtag tipo='sem' onChangeFiltro={onChangeFiltroTipoRetorno} />
          <Checkboxtag tipo='atrasado' onChangeFiltro={onChangeFiltroTipoRetorno} />
          <Checkboxtag tipo='hoje' onChangeFiltro={onChangeFiltroTipoRetorno} />
          <Checkboxtag tipo='futuro' onChangeFiltro={onChangeFiltroTipoRetorno} />
          <Checkboxtag tipo='descartado' onChangeFiltro={onChangeFiltroTipoRetorno} />
        </div>
        <ContainerNovoLead
        />
        <ModalFilter
          open={isOpenFilter}
          onClose={onCloseFilter}
          onChangeFiltroGeral={onChangeFiltroGeral}
        />
      </div>
    </>
  );


}
