import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { TipoAcessoAcademy } from '../../enum/tipoAcessoAcademy';
import '../../global.css';
import StepsCreate from './steps-create';
import './style.css';

const UserCreateStep9: React.FunctionComponent = () => {
	const [selected, setSelected] = useState<number[]>([]);
	const { usuario, setUsuario, revisao } = useUsuarioContext();
	const tipoAcesso = [
		{ codigo: TipoAcessoAcademy.CODIGO_B2C, descricao: TipoAcessoAcademy.DESCRICAO_B2C },
		{ codigo: TipoAcessoAcademy.CODIGO_B2B, descricao: TipoAcessoAcademy.DESCRICAO_B2B },
		{ codigo: TipoAcessoAcademy.CODIGO_COMERCIAL, descricao: TipoAcessoAcademy.DESCRICAO_COMERCIAL },
	]

	const handleRoleSelected = (index: number) => {
		if (selected.includes(index)) {
			setSelected(selected.filter((item) => item !== index)); // Remove o índice se já estiver selecionado
		} else {
			setSelected([...selected, index]); // Adiciona o índice se não estiver selecionado
		}

		// Definir as propriedades corretas de acordo com o código de acesso
		if (index === 0) {
			setUsuario({ ...usuario, acessoB2c: !selected.includes(index) });
		}
		if (index === 1) {
			setUsuario({ ...usuario, acessoB2b: !selected.includes(index) });
		}
		if (index === 2) {
			setUsuario({ ...usuario, acessoComercial: !selected.includes(index) });
		}
	};

	useEffect(() => {

		setSelected([
			usuario?.acessoB2c ? 0 : null,
			usuario?.acessoB2b ? 1 : null,
			usuario?.acessoComercial ? 2 : null
		])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={32}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={3} />
			<div className="franquia" style={{ left: '0' }}>
				<div className="space1"></div>
				<div className="user-row">
					<h3>Qual é a visão do usuário no Academy?</h3>
					<div className="boxes">
						{tipoAcesso.map((acesso, index) => {
							return (
								<button
									id={"funcao-" + index}
									key={index}
									className={selected.includes(index) ? 'box-selected' : 'box'}
									onClick={() => handleRoleSelected(index)}
								>
									<Unicons.UilHouseUser
										size="20"
										color={selected.includes(index) ? '#FFF' : 'var(--primary-color)'}
									/>
									<h4>{acesso.codigo}</h4>
									<p>{acesso.descricao}</p>
								</button>
							);
						})}
					</div>
					{selected.find(permissao => permissao === 1) ?
						<>
							<h3>Filtrar produto StemPlay ?</h3>
							<div>
								<label>usuário B2B: </label>
								<Checkbox
									id="instrutorStemplay"
									checked={usuario?.instrutorStemplay}
									onChange={(e) => setUsuario({ ...usuario, instrutorStemplay: e.target.checked })}
								/>
							</div>
						</>
						: null}
					<div className="botoes">
						{revisao ? (
							<Link to="/usuarios/create/step-7">
								<Button
									id="revisao"
									type="primary"
									className="button-primary"
									style={{ width: 700 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						) : (
							<>
								<Link to="/usuarios/create/step-6">
									<Button id="voltar" className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/usuarios/create/step-7">
									<Button id="proximo" className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Link to="/usuarios">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
}

export default UserCreateStep9;