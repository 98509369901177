import * as Unicons from '@iconscout/react-unicons';
import { Domain } from '@mui/icons-material';
import { useState } from "react";
import {
  Menu,
  MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useHistory } from 'react-router-dom';
import LogoGrande from '../../assets/img/header/logo.svg';
import Logo from '../../assets/img/menu/logo.svg';
import { useGlobalContext } from "../../context/GlobalContext";
import { ILogCleanDTO } from '../../models/happy-code-api.model';
import { useLogService } from '../../services/log.service';
import { clear } from '../../util/store';
import { Avatar } from '../Avatar';
import "./style.scss";

interface Props {
  selecionado?: number;
}

const NewMenu = ({ selecionado }: Props) => {
  const { usuario } = useGlobalContext();
  const [menuCollapse, setMenuCollapse] = useState(true);
  const history = useHistory();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Logout",
    descricaoTipo: "Logout",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  }); 
  
  const menuIconClick = () => menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  const returnAcessoFuncao = (listaFuncoes: string[]) => listaFuncoes.some(funcao => usuario && funcao === usuario.funcao);
  
  const logout = () => {
    logService.registraLog(log).then(() => clear());    
    history.push('/login')
  }

  //Para liberar o botão do crm apenas para as unidades pilotos.
  //Comentado por hora pois o piloto acabou;
  /*const idUnidadeUser = JSON.parse(localStorage.getItem('@HappyCode/login/unidade'));
  let piloto = false;
      if (
        idUnidadeUser?.id == 6 ||   //belém
        idUnidadeUser?.id == 7 ||   //ipanema
        idUnidadeUser?.id == 8 ||   //mostardeiro
        idUnidadeUser?.id == 9 ||   //barra da tijuca
        idUnidadeUser?.id == 12 ||  //alphaville
        idUnidadeUser?.id == 18 ||  //curitiba
        idUnidadeUser?.id == 26 ||  //freguesia
        idUnidadeUser?.id == 31 ||  //uberlandia
        idUnidadeUser?.id == 33 ||  //chapeco
        idUnidadeUser?.id == 40 ||  //maringa
        idUnidadeUser?.id == 43 ||  //botafogo
        idUnidadeUser?.id == 54 ||  //uberaba
        idUnidadeUser?.id == 56 ||  //santarem
        idUnidadeUser?.id == 59 ||  //piracicaba
        idUnidadeUser?.id == 60     //sao jose
      ){
          piloto = true;
      }*/

  const renderSubMenuEscolas = () => {
    return returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <SubMenu id="submenu-hubs" className={[21].includes(selecionado) ? 'active' : null} title={'Escolas'} icon={<Domain fontSize='small' htmlColor={[21].includes(selecionado) ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
      {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-grupos" active={selecionado === 21} icon={<Domain fontSize='small' htmlColor={selecionado === 21 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Grupos
        <Link to="/escolas/grupos" />
      </MenuItem>}

      {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-grupos" active={selecionado === 22} icon={<Unicons.UilBuilding size="20" color={selecionado === 22 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Escolas
        <Link to="/escolas" />
      </MenuItem>}

      {/* {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-alunos" active={selecionado === 23} icon={<Unicons.UilBuilding size="20" color={selecionado === 23 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Alunos
        <Link to="/escola-alunos" />
      </MenuItem>} */}

      {returnAcessoFuncao(['ADM', 'TEC', 'GSF']) && <MenuItem id="menu-alunos" active={selecionado === 24} icon={<Unicons.UilBuilding size="20" color={selecionado === 24 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Financeiro
        <Link to="/escolas-financeiro" />
      </MenuItem>}

      {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-alunos" active={selecionado === 25} icon={<Unicons.UilBuilding size="20" color={selecionado === 25 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        CRM
        <Link to="/escolas/crm" />
      </MenuItem>}
    </SubMenu>
  }

  const renderSubMenuHubsFranquias = () => {
    return returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <SubMenu id="submenu-hubs" className={[10, 13].includes(selecionado) ? 'active' : null} title={'Hubs'} icon={<Unicons.UilBuilding size="20" color={[10, 13].includes(selecionado) ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
      {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-alunos" active={selecionado === 10} icon={<Unicons.UilSmile size="20" color={selecionado === 10 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Alunos
        <Link to="/alunos" />
      </MenuItem>}

      {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-financeiro" active={selecionado === 13} icon={<Unicons.UilDollarSignAlt size="20" color={selecionado === 13 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Financeiro
        <Link to="/financeiro" />
      </MenuItem>}

      {returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && <MenuItem id="menu-cursos" active={selecionado === 11} icon={<Unicons.UilBookmark size="20" color={selecionado === 11 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Cursos
        <Link to="/curso-franquia" />
      </MenuItem>}

      {returnAcessoFuncao(['ADM', 'GSA', 'TEC']) && <MenuItem id="menu-turmas" active={selecionado === 12} icon={<Unicons.UilBookReader size="20" color={selecionado === 12 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Turmas
        <Link to="/turmas" />
      </MenuItem>}

      {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-crm" active={selecionado === 20} icon={<Unicons.UilFilter
        size="20" color={selecionado === 20 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        CRM
        <Link to="/crm" />
      </MenuItem>}
    </SubMenu>
  }

  const renderSubMenuHubsSede = () => {
    return returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <SubMenu id="submenu-hubs" className={[10, 13].includes(selecionado) ? 'active' : null} title={'Hubs'} icon={<Unicons.UilBuilding size="20" color={[10, 13].includes(selecionado) ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
      {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-franquias" active={selecionado === 1} icon={<Unicons.UilBuilding size="20" color={selecionado === 1 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Franquias
        <Link to="/franquias" />
      </MenuItem>}
      {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-autonomia" active={selecionado === 2} icon={<Unicons.UilClipboardNotes size="20" color={selecionado === 2 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Autonomia
        <Link to="/autonomia" />
      </MenuItem>}
      {returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && <MenuItem id="menu-cursos" active={selecionado === 4} icon={<Unicons.UilGraduationCap size="20" color={selecionado === 4 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Cursos
        <Link to="/cursos" />
      </MenuItem>}
      {returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && <MenuItem id="menu-campanhas" active={selecionado === 5} icon={<Unicons.UilBolt size="20" color={selecionado === 5 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Campanhas
        <Link to="/campanhas" />
      </MenuItem>}
      {returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && <MenuItem id="menu-taxas" active={selecionado === 6} icon={<Unicons.UilReceipt size="20" color={selecionado === 6 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Taxas
        <Link to="/taxas" />
      </MenuItem>}
      {returnAcessoFuncao(['ADM', 'GSF', 'TEC']) && <MenuItem id="menu-financeiro-sede" active={selecionado === 7} icon={<Unicons.UilDollarSign size="20" color={selecionado === 7 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Financeiro
        <Link to="/financeiro-sede" />
      </MenuItem>}
      {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-alunos" active={selecionado === 10} icon={<Unicons.UilSmile size="20" color={selecionado === 10 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
        Alunos
        <Link to="/alunos" />
      </MenuItem>}
    </SubMenu>
  }



  const renderSidebarSede = () => {
    return <>
      <Menu iconShape="round">
        <MenuItem id="menu-home" active={selecionado === 0} icon={<Unicons.UilHome size="20" color={selecionado === 0 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Home
          <Link to="/" />
        </MenuItem>

        {renderSubMenuHubsSede()}
        {renderSubMenuEscolas()}

        {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-usuarios" active={selecionado === 3} icon={<Unicons.UilUsersAlt size="20" color={selecionado === 3 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Usuarios
          <Link to="/usuarios" />
        </MenuItem>}

        {returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && <MenuItem id="menu-indicadores" active={selecionado === 18} icon={<Unicons.UilAnalytics size="20" color={selecionado === 18 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Relatórios
          <Link to="/relatorio-sede" />
        </MenuItem>}
        {returnAcessoFuncao(['ADM', 'GSA', 'GSF', 'TEC']) && <MenuItem id="menu-indicadores" active={selecionado === 8} icon={<Unicons.UilChartLine size="20" color={selecionado === 8 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Indicadores
          <Link to="/indicadores" />
        </MenuItem>}

      </Menu>
    </>
  }

  const renderSidebarFranquia = () => {
    return <>
      <Menu iconShape="round">
        {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-home" active={selecionado === 0} icon={<Unicons.UilHome size="20" color={selecionado === 0 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Home
          <Link to="/franquia" />
        </MenuItem>}

        {renderSubMenuHubsFranquias()}

        {returnAcessoFuncao(['ADM', 'TEC']) && <MenuItem id="menu-colaboradores" active={selecionado === 9} icon={<Unicons.UilUsersAlt size="20" color={selecionado === 9 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Colaboradores
          <Link to="/colaboradores" />
        </MenuItem>}

        {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-rel-financeiro" active={selecionado === 15} icon={<Unicons.UilAnalytics size="20" color={selecionado === 15 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Relatórios
          <Link to="/relatorio" />
        </MenuItem>}
        {/* {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-rel-dashboard" active={selecionado === 17} icon={<Unicons.UilChart size="20" color={selecionado === 17 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Dashboards
          <Link to="/analytics" />
        </MenuItem>} */}
        {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-manual" active={selecionado === 16} icon={<Unicons.UilClipboardNotes
          size="20" color={selecionado === 16 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          <a href="https://suporte.happygrupo.com.br" className="chamado" target="blank">
            Chamados</a>
        </MenuItem>}
        {returnAcessoFuncao(['ADM', 'GSF', 'GSA', 'TEC']) && <MenuItem id="menu-manual" active={selecionado === 19} icon={<Unicons.UilBook
          size="20" color={selecionado === 19 ? "var(--primary-color)" : "var(--blue-transparent)"} />}>
          Manual
          <Link to="/manual" />
        </MenuItem>}

      </Menu>
    </>
  }

  return (
    <>
      <div id="header" style={{ backgroundColor: 'white' }}>
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              <p>{menuCollapse ? <img src={Logo} alt="" onClick={menuIconClick} /> : <img src={LogoGrande} alt="" onClick={menuIconClick} />}</p>
            </div>
          </SidebarHeader>
          <SidebarContent>
            {usuario && usuario.perfil === 'SEDE' ? renderSidebarSede() : renderSidebarFranquia()}
          </SidebarContent>
          <SidebarFooter>
            <Menu>
              <MenuItem className="item-avatar" icon={<Avatar base64={usuario?.arquivo} size={30} />}>
                <div className="box-avatar">
                  <h6>{usuario?.usuario}</h6>
                  <p>{usuario?.perfil}</p>
                </div>
                <Link to="/usuarios/profile" />
              </MenuItem>
              <MenuItem onClick={logout} className="item-danger" icon={<Unicons.UilExport style={{ transform: 'rotate(90deg)' }} size="20" color="var(--red-color)" />}>Sair</MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default NewMenu;