import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu } from 'antd';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCampanhaContext } from '../../context/CampanhaContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICampanhaCleanDTO } from "../../models/happy-code-api.model";
import { useCampanhaService } from '../../services/campanha.service';
import { formattedDate, isDateBetween } from "../../util/format";

interface TItemLista {
    campanha: ICampanhaCleanDTO;
    handleCampanha: (campanha: ICampanhaCleanDTO, state: boolean) => void;
}

const ItemListCampanha = memo(({ campanha, handleCampanha }: TItemLista) => {

    const campanhaService = useCampanhaService();
    const { setIsGlobalLoading } = useGlobalContext();

    const handleMenuItem = (campanha: ICampanhaCleanDTO) => {
        if (campanha.ativo) {
            return (
                <Menu.Item onClick={() => handleCampanha(campanha, false)}>
                    <a rel="noopener noreferrer">
                        Finalizar Campanha
                    </a>
                </Menu.Item>
            )
        }
        if (!campanha.ativo) {
            return (
                <Menu.Item onClick={() => handleCampanha(campanha, true)}>
                    <a rel="noopener noreferrer">
                        Ativar Campanha
                    </a>
                </Menu.Item>
            )
        }
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            {handleMenuItem(campanha)}
        </Menu>
    );
    const history = useHistory();

    const { setCampanha } = useCampanhaContext();

    const goTo = () => {
        setIsGlobalLoading(true);
        campanhaService.findFullById(campanha)
            .then(({ data }) => setCampanha(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/campanhas/create/step-6`)
            });
    }

    const handleStatus = (campanha) => {
        if (isDateBetween(campanha.dataInicio, campanha.dataFim)) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Em vigência</p>
                </div>
            )
        }

        if (campanha.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Ativo</p>
                </div>
            )
        }

        if (!campanha.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className="standby">Inativo</p>
                </div>
            )
        }
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '30%' }}>
                <div className="nome">
                    <p>{campanha.codigo}</p>
                    {handleStatus(campanha)}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{campanha.id}</span>
            </div>
            <div className="funcao-user" id="campanhas" style={{ width: '15%' }}>
                <span>{formattedDate(campanha.dataInicio)} - {formattedDate(campanha.dataFim)}</span>
            </div>
            <div className="funcao-user" id="cursos-modalidade" style={{ width: '15%' }}>
                <p>{campanha.percentualDesconto}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '25%' }}>
                <p>{campanha.percentualDescontoMaterial}</p>
            </div>
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListCampanha.displayName = 'ItemListCampanha';

export default ItemListCampanha;