import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, InputNumber, Row, Select } from 'antd';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ModalCreate from '../../components/ModalCreate';
import { useGlobalContext } from '../../context/GlobalContext';
import { Predicate } from '../../models/predicate.model';
import { useCobrancaService } from '../../services/cobranca.service';
import { useFinanceiroService } from '../../services/financeiro.service';
import { useNotificationService } from '../../services/notification.service';
import { ICobrancaCustomDTO, INegociacaoDTO } from './../../models/happy-code-api.model';
import { daysBetween, formatMoney, isAfterDate, toMoment } from './../../util/format';
import { TagsFinanceiro } from './../TagsFinanceiro/index';
import './style.css';
moment.locale('pt-BR');

interface Text {
    open: boolean;
    onClose: () => void;
    cobranca: ICobrancaCustomDTO;
    tela: string;
}

export const ModalFinanceiro: React.FunctionComponent<Text> = ({ open, onClose, cobranca, tela }: Text) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpenComposicao, setIsOpenComposicao] = useState(false);
    const [isOpenTabela, setIsOpenTabela] = useState(false);
    const [cobrancas, setCobrancas] = useState<ICobrancaCustomDTO[]>([]);
    const [cobrancasSelected, setCobrancasSelected] = useState<ICobrancaCustomDTO[]>([]);
    const [parcelaSelected, setParcelaSelected] = useState<number>(1);
    const [dtVencimento, setDtVencimento] = useState<moment.Moment>(moment());

    const [predicate] = useState<Predicate>(new Predicate(50));

    const cobrancaService = useCobrancaService();
    const financeiroService = useFinanceiroService();

    const notification = useNotificationService();

    const [descontoValor, setDescontoValor] = useState<number>();
    const [descontoPercentual, setDescontoPercentual] = useState<number>();

    const findCobrancaByUnidade = () => {
        predicate.removeOption('statusCobrancaCodigo')
        predicate.addMultiOption('statusCobrancaCodigo', 'AGR');
        predicate.addMultiOption('statusCobrancaCodigo', 'GRD');
        predicate.addMultiOption('statusCobrancaCodigo', 'FLH');
        predicate.addMultiOption('statusCobrancaCodigo', 'CAN');
        predicate.addMultiOption('statusCobrancaCodigo', 'PGO');
        predicate.addOption('financeiro.id', cobranca.financeiro.id);
        return cobrancaService.findCustom(predicate)
            .then((response) => setCobrancas(response.data.content));
    }

    function resetField() {
        setCobrancasSelected([]);
        setDescontoValor(null);
        setDescontoPercentual(null);
        setParcelaSelected(1);
        setIsOpenComposicao(false);
        setIsOpenTabela(false);
    }

    const closeModal = () => {
        resetField();
        onClose();
    }

    const finalizar = () => {
        resetField();
        setIsOpen1(false);
        setIsOpenComposicao(false);
        onClose();
    }

    const openResumoNegociacao = () => {
        setIsOpenTabela(true);
        setIsOpen(true);
    }

    const getTotal = useMemo((): number => {
        let total = 0;
        cobrancasSelected.map(item =>
            isAfterDate(item.dataVencimento) ? total += (((2 / 100) * item.valorCobranca) + item.valorCobranca + ((0.033 / 100) * item.valorCobranca * daysBetween(item.dataVencimento))) : total += item.valorCobranca)
        return total;
    }, [cobrancasSelected])

    const getTotalCobranca = useMemo((): number => {
        let total = 0;
        cobrancasSelected.map(item =>
            total += item.valorCobranca)
        return total;
    }, [cobrancasSelected])

    const getTotalJuros = useMemo((): number => {
        let total = 0;
        total = getTotal - getTotalCobranca;
        return total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cobrancasSelected])

    const getTotalFranquia = useMemo((): number => {
        let total = 0;
        cobrancasSelected.map(item =>
            total += item.valorParcelaFranquia)
        return total;
    }, [cobrancasSelected])

    const getTotalSede = useMemo((): number => {
        let total = 0;
        cobrancasSelected.map(item =>
            total += item.valorParcelaSede)
        return total;
    }, [cobrancasSelected])

    const save = () => {
        const negociacao: INegociacaoDTO = {
            financeiroId: cobranca.financeiro.id,
            quantidadeParcelas: parcelaSelected,
            valorTotal: getTotal,
            valorTotalSede: getTotalSede,
            valorTotalComDesconto: (getTotal - descontoValor),
            dataVencimento: dtVencimento.toDate(),
            cobrancas: cobrancasSelected,
        }
        const matriculas = cobranca?.financeiro.matriculas || [];
        
        financeiroService.renegociacao(negociacao)
            .then(() => { setIsOpen1(true) })
            // .then(() => financeiroService.baixarTermoRenegociacao(matriculas[0]?.id).then((response) => {
            //     const blob = new Blob([response.data], {
            //         type: 'application/x-pdf',
            //     });
            //     FileSaver.saveAs(blob, `renegociacao_${matriculas[0]?.id}.pdf`);
            // }))
            .catch(error => notification({ message: "Erro!", description: error.response.data.message, type: "error" }))
    }

    useEffect(() => {
        resetField();
        if (cobranca) {
            setIsOpen(false);
            setIsGlobalLoading(true);
            Promise.all([
                findCobrancaByUnidade(),
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cobranca]);

    const onChangeParcela = (numeroParcela) => {
        setParcelaSelected(numeroParcela);
    }

    const addArray = (cobranca: ICobrancaCustomDTO) => {
        if (cobrancasSelected.includes(cobranca)) {
            const cobs = cobrancasSelected.findIndex(c => c == cobranca);
            cobrancasSelected.splice(cobs);
        } else {
            setCobrancasSelected([...cobrancasSelected, cobranca])
        }
    }

    const findDescontoValor = (vlDesconto) => {
        setDescontoValor(vlDesconto);
        const descontoPercentual = ((vlDesconto / getTotalCobranca) * 100);
        setDescontoPercentual(descontoPercentual);
    }


    const encontraMaximo = (): number => {
        let valorMaximoDesconto = getTotalJuros;
        let valorTotalCobranca = 0;
        cobrancasSelected.map(cob => {
            valorMaximoDesconto = valorMaximoDesconto += cob.valorParcelaFranquia;
            valorTotalCobranca = valorTotalCobranca += cobranca.valorCobranca
        });

        return valorMaximoDesconto;
    }

    const encontraMaximoPercentual = (): number => {
        let valorTotal = getTotalJuros;
        let valorTotalSede = 0;
        let percentual = 0;

        cobrancasSelected.map(cob => {
            valorTotal = valorTotal += cob.valorCobranca;
            valorTotalSede = valorTotalSede += cob.valorParcelaSede;
        })
        percentual = (valorTotalSede / valorTotal) * 100;
        return (100 - percentual);
    }

    const findDesconto = (perDesconto) => {
        setDescontoPercentual(perDesconto);
        let valorTotal = getTotalJuros;
        cobrancasSelected.map(cob => {
            valorTotal = valorTotal += cob.valorCobranca;
        })
        const valorDesconto = (valorTotal / 100 * perDesconto);
        setDescontoValor(valorDesconto)
    }

    const openComposicao = () => {
        setIsOpen(true);
        setIsOpenTabela(false);
        setIsOpenComposicao(true);
    }    

    if (!open) return null

    const parcelaParaVerificar = () => {
        return cobrancas.filter(cob => cobrancas.filter(c => c.parcela === 0)).length
    }

    return (
        <div className="modalContainerFinanceiro">
            <div className="modal-user-financeiro">
                <div className="body">
                    <Button onClick={() => closeModal()} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
                    <h3>Renegociação</h3>
                    <h5>Aluno</h5>
                    <div className="detalhes-aluno">
                        <div className="data">
                            <h6>{cobranca?.financeiroMatriculaAlunoPessoaNome}</h6>
                        </div>
                    </div>
                    <div className="line"></div>
                    {!isOpen &&
                        <div className="box-tabela">
                            <h5 style={{ marginBottom: 20 }}>Parcelas</h5>
                            <div className="tabela">
                                <div className="titles-users">
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>Tipo</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>PARCELA</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>VALOR</span>
                                    </div>
                                    <div className="item" style={{ width: '25%' }}>
                                        <span>STATUS</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>DIAS VENCIDOS</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>SELECIONAR COBRANÇA</span>
                                    </div>
                                </div>
                                {cobrancas && cobrancas.filter(cob => cob.parcela > (parcelaParaVerificar() > 1 ? 0 : 1) && cob.statusCobranca.codigo !== 'PGO').map((item, index) => (
                                    <div key={index} className="registro-user">
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <div className="nome" style={{ marginLeft: 10 }}>
                                                <p>{item?.ativo ? 'Renegociação' : 'Normal'}</p>
                                            </div>
                                        </div>
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <p>{item.parcela}</p>
                                        </div>
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <p>{formatMoney(item.valorCobranca)}</p>
                                        </div>
                                        <div className="funcao-user" style={{ width: '25%' }}>
                                            <TagsFinanceiro codigo={item?.statusCobranca?.codigo} descricao={item?.statusCobranca?.descricao}></TagsFinanceiro>
                                        </div>
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <p>{daysBetween(item.dataVencimento)}</p>
                                        </div>
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <Checkbox
                                                id="check-box-estorno"
                                                onChange={(e) => addArray(e.target.value)}
                                                value={item}
                                            />
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>}
                    {isOpenTabela && <div className="box-tabela">
                        <h5>Resumo renegociação</h5>
                        {getTotalJuros > 0.1 ?
                            <>
                                <div className="valor">
                                    <h6>Valor total cobranças:</h6>
                                    <p>{formatMoney(getTotal - getTotalJuros)}</p>
                                </div>
                                <div className="valor">
                                    <h6>Multa e juros:</h6>
                                    <p>{formatMoney(getTotalJuros)}</p>
                                </div>
                            </>
                            : null}
                        <div className="valor">
                            <h6>Valor total</h6>
                            <p>{formatMoney(getTotal - descontoValor)}</p>
                        </div>

                        <div style={{ width: "100%" }}>
                            <Row style={{ marginBottom: 5 }}>
                                <Col style={{ width: "49%", marginRight: "2%" }}>
                                    <div className="input-box" style={{ marginBottom: 0 }}>
                                        <p>Desconto percentual (%)</p>
                                        <InputNumber
                                            decimalSeparator="."
                                            style={{ width: '100%' }}
                                            onChange={(e) => findDesconto(e)}
                                            type={"number"}
                                            defaultValue={0}
                                            maxLength={5}
                                            max={encontraMaximoPercentual()}
                                            className="input"
                                            value={parseFloat(descontoPercentual?.toFixed(2)?.replace(/(\d)(?=(\d{8})+(?!\d))/g, '$1.'))}
                                        />
                                        <p style={{ fontSize: '10px' }}>O desconto máximo é de R$: {(getTotalFranquia + getTotalJuros).toFixed(2)}</p>
                                    </div>
                                </Col>
                                <Col style={{ width: "49%" }}>
                                    <div className="input-box" style={{ marginBottom: 0 }}>
                                        <p>Desconto Valor (R$)</p>
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            onChange={(e) => findDescontoValor(e)}
                                            type={"number"}
                                            defaultValue={0}
                                            maxLength={7}
                                            max={encontraMaximo()}
                                            className="input"
                                            value={parseFloat(descontoValor?.toFixed(2)?.replace(/(\d)(?=(\d{7})+(?!\d))/g, '$1.'))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="input-box" style={{ width: '100%', marginTop: 15 }}>
                            <h6>Quantidade de parcelas</h6>
                            <Select
                                style={{ width: '100%' }}
                                placeholder={'Selecione uma parcela'}
                                onChange={(e) => onChangeParcela(e)}
                                defaultValue={1}
                                getPopupContainer={triggerNode => {
                                    return triggerNode.parentElement
                                }}>
                                {Array.from(Array(12), (e, i) => i + 1).map((item, index) =>
                                    <Select.Option key={index} value={index + 1}>{item}x {formatMoney(descontoValor ? (getTotal - descontoValor) / item : getTotal / item)} </Select.Option>
                                )}

                            </Select>
                        </div>

                        <div>
                            <p style={{ fontSize: '15px' }}>Data de vencimento da 1ª parcela (as demais ficarão no mesmo dia de cada mês)</p>
                            <DatePicker
                                format={'DD-MM-YYYY'}
                                value={toMoment(dtVencimento)}
                                onChange={(e) => setDtVencimento(e)}
                                placeholder={'Data de nascimento'}
                                suffixIcon={true}
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div className="line" />

                        <div className="line" />
                        <div className="valor">
                            <h6>Valor total com desconto</h6>
                            <p>{formatMoney(getTotal - descontoValor)}</p>
                        </div>
                    </div>}
                    {isOpenComposicao &&
                        <div className="box-tabela">
                            <div className="tabela">
                                <div className="titles-users">
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>VALOR</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>VALOR SEDE</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>VALOR HUB</span>
                                    </div>
                                    <div className="item" style={{ width: '20%' }}>
                                        <span>STATUS</span>
                                    </div>
                                </div>
                                {Array.from(Array(parcelaSelected), (e, i) => i + 1).map((item, index) =>
                                    <div key={index} className="registro-user">
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <p>{((getTotal - descontoValor) / parcelaSelected).toFixed(2)}</p>
                                        </div>
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <p>{(getTotalSede / parcelaSelected).toFixed(2)}</p>
                                        </div>
                                        <div className="funcao-user" style={{ width: '20%' }}>
                                            <p>{(((getTotal - descontoValor) - getTotalSede) / parcelaSelected).toFixed(2)}</p>
                                        </div>

                                        <div className="funcao-user" style={{ width: '25%' }}>
                                            <TagsFinanceiro codigo={"AGR"} descricao={"A GERAR"}></TagsFinanceiro>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>}
                </div>
                <div className="line"></div>
                <div className="botoes">
                    <Button onClick={() => closeModal()} style={{ minWidth: 145 }} className="button-second">Cancelar</Button>
                    {!isOpen ?
                        <Button disabled={cobrancasSelected.length < 1 ? true : false} onClick={() => openResumoNegociacao()} className="button-primary" style={{ marginLeft: 8, minWidth: 145 }}>Proximo</Button>
                        :
                        isOpenTabela ?
                            <Button onClick={() => openComposicao()} className="button-primary" style={{ marginLeft: 8, minWidth: 145 }}>Proximo</Button>
                            :
                            <Button onClick={save} className="button-primary" style={{ marginLeft: 8, minWidth: 145 }}>Salvar</Button>
                    }
                </div>

            </div>
            <ModalCreate
                open={isOpen1}
                setOpen={finalizar}
                title='Renegociação gerada'
                textButton1='Voltar'
                textButton2='Voltar Inicio'
                styleButton1='modal-none'
                styleButton2='button-primary'
                link2={`/${tela}/detalhe`}
            />
        </div>
    );
}