import { useAlunoContext } from '../../../../../context/AlunoContext';
import moment from 'moment';
import { useCallback } from 'react';

export default function useCalcularSaldoTempoContrato() {
	const { dataInicioContrato } = useAlunoContext();

	const calcularSaldoTempoContrato = useCallback(() => {
		const currentDate = moment();
		const endEnrollmentDate = moment(dataInicioContrato).add(1, 'year');

		const saldoTempoContrato = currentDate.isBefore(endEnrollmentDate)
			? endEnrollmentDate.diff(dataInicioContrato, 'months')
			: endEnrollmentDate.diff(currentDate, 'months');

		const parcelas = Array.from(
			{ length: saldoTempoContrato },
			(_, i) => i + 1
		);

		return {
			saldoTempoContrato,
			parcelas,
		};
	}, [dataInicioContrato]);

	return {
		calcularSaldoTempoContrato,
	};
}
