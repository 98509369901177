import { Button, Input, Space, TableColumnsType, TableColumnType } from 'antd';
import { RelatorioMatriculaNovoDTO } from '../../../../models/relatorio.model';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@mui/icons-material';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import useRelatorioState from '../../states/use-relatorio-state';
import { getAge } from '../../../../util/util';

const MatriculaSedeTableColumns = () => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');

	const {
		selectTipoCurso,
		selectNegocios,
		selectUpgrade,
		selectHorasContratadas,
		renderCurrency,
		renderDate,
		findNegocio,
		findTipoCurso,
		selectStatusCobranca,
		findStatusCobranca,
		findTipoPagamento,
		selectTipoPagamento,
	} = useRelatorioState();

	useEffect(() => {
		findNegocio();
		findTipoCurso();
		findStatusCobranca();
		findTipoPagamento();
	}, []);

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: string,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (
		dataIndex: string,
	): TableColumnType<RelatorioMatriculaNovoDTO> => ({
		filterDropdown: ({
											 setSelectedKeys,
											 selectedKeys,
											 confirm,
											 clearFilters,
										 }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					placeholder={`Buscar ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Buscar
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Resetar
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const columns: TableColumnsType<RelatorioMatriculaNovoDTO> = [
		{
			title: 'Id Contrato',
			dataIndex: 'idContrato',
			sorter: (a, b) => a.idContrato - b.idContrato,
			width: 100,
		},
		{
			title: 'Nome Unidade',
			dataIndex: 'nomeUnidade',
			...getColumnSearchProps('nomeUnidade'),
			width: 150,
		},
		{
			title: 'Data de Matrícula',
			dataIndex: 'dataMatricula',
			sorter: (a, b) =>
				new Date(a.dataMatricula).getTime() -
				new Date(b.dataMatricula).getTime(),
			render(_value, record, _index) {
				return renderDate(record.dataMatricula.toString());
			},
			width: 120,
		},
		{
			title: 'Nome Aluno',
			dataIndex: 'nomeAluno',
			...getColumnSearchProps('nomeAluno'),
			width: 180,
		},
		{
			title: 'Idade',
			dataIndex: 'dataNascimentoAluno',
			width: 88,
			sorter: (a, b) => getAge(a.dataNascimentoAluno) - getAge(b.dataNascimentoAluno),
			render(_value, record, _index) {
				return getAge(record.dataNascimentoAluno);
			},
		},
		{
			title: 'Negócio',
			dataIndex: 'negocio',
			filters: selectNegocios,
			onFilter: (value: string, record) => record.negocio.includes(value),
			width: 150,
		},
		{
			title: 'Tipo Curso',
			dataIndex: 'tipoCurso',
			filters: selectTipoCurso,
			onFilter: (value: string, record) => record.tipoCurso.includes(value),
			width: 100,
		},
		{
			title: 'Upgrade',
			dataIndex: 'isUpgrade',
			filters: selectUpgrade,
			render(_value, record, _index) {
				return record.isUpgrade ? 'Sim' : 'Não';
			},
			onFilter: (value: boolean, record) => {
				return record.isUpgrade == value;
			},
			width: 96,
		},
		{
			title: 'Horas Contratadas',
			dataIndex: 'horasContratadas',
			sorter: (a, b) => a.horasContratadas - b.horasContratadas,
			render(_value, record, _index) {
				const hours = Math.floor(record.horasContratadas / 60);
				const minutes = record.horasContratadas % 60;
				return minutes == 0 ? `${hours}h` : `${hours}h${minutes}min`;
			},
			filters: selectHorasContratadas,
			onFilter: (value: number, record) =>
				value == 450
					? record.horasContratadas >= value
					: record.horasContratadas == value,
			width: 150,
		},
		{
			title: 'Data de Pagamento',
			dataIndex: 'dataPagamento',
			render(_value, record, _index) {
				return record.dataPagamento
					? renderDate(record.dataPagamento.toString())
					: '';
			},
			width: 120,
		},
		{
			title: 'Forma de Pagamento',
			dataIndex: 'formaPagamento',
			width: 120,
			filters: selectTipoPagamento,
			onFilter: (value: string, record) =>
				record.formaPagamento?.includes(value),
		},
		{
			title: 'Status Cobrança',
			dataIndex: 'statusCobranca',
			width: 120,
			filters: selectStatusCobranca,
			onFilter: (value: string, record) =>
				record.statusCobranca?.includes(value),
		},
		{
			title: 'Valor Total com Desconto',
			dataIndex: 'valorTotalComDesconto',
			sorter: (a, b) => a.valorTotalComDesconto - b.valorTotalComDesconto,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorTotalComDesconto);
			},
			width: 150,
		},
		{
			title: 'Total de Parcelas',
			dataIndex: 'totalParcelas',
			sorter: (a, b) => a.totalParcelas - b.totalParcelas,
			width: 120,
		},
		{
			title: 'Valor Mensalidade com Desconto',
			dataIndex: 'valorMensalidadeComDesconto',
			sorter: (a, b) =>
				a.valorMensalidadeComDesconto - b.valorMensalidadeComDesconto,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorMensalidadeComDesconto);
			},
			width: 170,
		},
		{
			title: 'Valor Total Licença',
			dataIndex: 'valorTotalLicenca',
			sorter: (a, b) => a.valorTotalLicenca - b.valorTotalLicenca,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorTotalLicenca);
			},
			width: 120,
		},
		{
			title: 'Valor Mensal Licença',
			dataIndex: 'valorSede',
			sorter: (a, b) => a.valorSede - b.valorSede,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorSede);
			},
			width: 120,
		},
		{
			title: 'Valor Líquido HUB',
			dataIndex: 'valorLiquido',
			sorter: (a, b) => a.valorLiquido - b.valorLiquido,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorLiquido);
			},
			width: 150,
		},
		{
			title: 'Taxa de Matrícula',
			dataIndex: 'taxaMatricula',
			sorter: (a, b) => a.taxaMatricula - b.taxaMatricula,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.taxaMatricula);
			},
			width: 120,
		},
	];

	return columns;
};

export default MatriculaSedeTableColumns;
