import {
	IMatriculaContratoCleanDTO,
	IMatriculaContratoDTO,
	IMatriculaContratoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseCreate } from './base/base-create.service';
import { baseUpdate } from './base/base-update.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';

export const useMatriculaContratoService = () => {
	const resourceURL = 'matricula-contrato';

	return {
		...baseFindList<IMatriculaContratoCleanDTO, IMatriculaContratoDTO, IMatriculaContratoFullDTO>(resourceURL),
		...baseCreate<IMatriculaContratoCleanDTO, IMatriculaContratoDTO, IMatriculaContratoFullDTO>(resourceURL),
		...baseUpdate<IMatriculaContratoCleanDTO, IMatriculaContratoDTO, IMatriculaContratoFullDTO>(resourceURL),
		...baseFindById<IMatriculaContratoCleanDTO, IMatriculaContratoDTO, IMatriculaContratoFullDTO>(resourceURL),
		...baseDelete(resourceURL),
	};
};
