import { Button } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import MoonLoader from 'react-spinners/ClipLoader';
import { ModalEnvioLicencaStemplay } from '../../components/ModalEnvioLicencaStemplay.tsx';
import { ModalEnvioNfsXml } from '../../components/ModalEnvioNfsXml';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useAlunoService } from '../../services/aluno.service';
import { useFinanceiroEscolaService } from '../../services/financeiro-escola.service';
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioProdutoStemplayService } from '../../services/usuario-produto-stemplay';
import styles from './styles.module.css';

export const RotinasAtualizacao: React.FunctionComponent = () => {
	const { usuario } = useGlobalContext();
	const alunoService = useAlunoService();
	const usuarioProdutoStemplayService = useUsuarioProdutoStemplayService();
	const financeiroEscolaService = useFinanceiroEscolaService();
	const notification = useNotificationService();
	const [loading, setLoading] = useState(false);
	const [isOpenNfsXml, setIsOpenNfsXml] = useState<boolean>(false);
	const [isOpenLicencaStemplay, setIsOpenLicencaStemplay] = useState<boolean>(false);

	const executionRotinaAluno = () => {
		setLoading(true);
		alunoService
			.findAlunoRotina()
			.then(() => {
				setTimeout(() => {
					notification({
						description: `Rotina executada com sucesso.`,
						type: 'success',
						message: 'Sucesso',
					});
					setLoading(false);
				}, 10000);
			})
			.catch((err) => {
				notification({
					description: `Ocorre um erro. ${err}`,
					type: 'error',
					message: 'Erro!',
				});
				setLoading(false);
			});
	};

	const executionRotinaProdutoStemplay = () => {
		setLoading(true);
		usuarioProdutoStemplayService
			.findProdutoStemplayRotina()
			.then(() => {
				notification({
					description: `Rotina executada com sucesso.`,
					type: 'success',
					message: 'Sucesso',
				});
				setLoading(false);
			})
			.catch((err) => {
				notification({
					description: `Ocorreu um erro: ${err}`,
					type: 'error',
					message: 'Erro!',
				});
				setLoading(false);
			});
	};

	const executionRotinaEnvioCobrancaB2b = () => {
		setLoading(true);
		financeiroEscolaService
			.findRotinaEnvioEmailCobrancaB2b(true)
			.then(() => {
				notification({
					description: `Rotina executada com sucesso.`,
					type: 'success',
					message: 'Sucesso',
				});
				setLoading(false);
			})
			.catch((err) => {
				notification({
					description: `Ocorreu um erro: ${err}`,
					type: 'error',
					message: 'Erro!',
				});
				setLoading(false);
			});
	};

	const finalizationModalNfsXml = () => {
		setIsOpenNfsXml(false);
	};

	const finalizationModalLicencaStemplay = () => {
		setIsOpenLicencaStemplay(false);
	};

	return (
		<div className="container-fondo">
			<NewMenu selecionado={101} />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row" style={{ width: '80%' }}>
					<div className="card-form">
						<div>
							<div className={styles.container} id="botoes-auto">
								<div>
									<h3>Rotina do Aluno</h3>
									{loading ? (
										<Button className={`${styles.btn_disabled} button-primary`}>Carregando</Button>
									) : (
										<Button className="button-primary" onClick={() => executionRotinaAluno()}>
											Atualizar
										</Button>
									)}
								</div>
								<div>
									<h3>Rotina Produto Stemplay</h3>
									{loading ? (
										<Button className={`${styles.btn_disabled} button-primary`}>Carregando</Button>
									) : (
										<Button className="button-primary" onClick={() => executionRotinaProdutoStemplay()}>
											Atualizar
										</Button>
									)}
								</div>
								{usuario?.login === 'bruno.azevedo@happy.com.br' ? (
									<>
										<div>
											<h3>Envio de Cobrança B2B</h3>
											{loading ? (
												<Button className={`${styles.btn_disabled} button-primary`}>Carregando</Button>
											) : (
												<Button className="button-primary" onClick={() => executionRotinaEnvioCobrancaB2b()}>
													Atualizar
												</Button>
											)}
										</div>
										<div>
											<h3>Envio de NFs (xml.zip)</h3>
											{loading ? (
												<Button className={`${styles.btn_disabled} button-primary`}>Carregando</Button>
											) : (
												<Button className="button-primary" onClick={() => setIsOpenNfsXml(true)}>
													Atualizar
												</Button>
											)}
										</div>
										<div className="mt40">
											<h3>Atualizar licença Stemplay B2B</h3>
											{loading ? (
												<Button className={`${styles.btn_disabled} button-primary`}>Carregando</Button>
											) : (
												<Button className="button-primary" onClick={() => setIsOpenLicencaStemplay(true)}>
													Atualizar
												</Button>
											)}
										</div>
									</>
								) : null}
								{usuario?.login === 'leticia.gabaldi@happy.com.br' ||
								usuario?.login === 'borges.gabriel@happy.com.br' ||
								usuario?.login === 'davidson.saldanha@happy.com.br' ? (
									<>
										<div>
											<h3>Envio de NFs (xml.zip)</h3>
											{loading ? (
												<Button className={`${styles.btn_disabled} button-primary`}>Carregando</Button>
											) : (
												<Button className="button-primary" onClick={() => setIsOpenNfsXml(true)}>
													Atualizar
												</Button>
											)}
										</div>
									</>
								) : null}
							</div>
							{loading ? (
								<div>
									<p className={styles.text}>Aguarde... isso pode demorar um pouco</p>
									<p className={styles.text}>
										<MoonLoader color="black" />
									</p>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<ModalEnvioNfsXml open={isOpenNfsXml} onClose={finalizationModalNfsXml} />
			<ModalEnvioLicencaStemplay open={isOpenLicencaStemplay} onClose={finalizationModalLicencaStemplay} />
		</div>
	);
};
