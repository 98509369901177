import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
    IFinanceiroCleanDTO,
    IFinanceiroDTO,
    IFinanceiroFullDTO,
    INegociacaoDTO,
} from '../models/happy-code-api.model';
import { reducao } from '../util/util';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useFinanceiroService = () => {
    const resourceURL = 'financeiro';

    const renegociacao = (body: INegociacaoDTO) =>
        http.post(`${resourceURL}/renegociacao`, body);

    const saldoAPagarResponse = (
        idFinanceiro: number
    ): Promise<AxiosResponse<number>> =>
        http.get<number>(resourceURL + `/saldo-a-pagar/${idFinanceiro}`);

    const saldoLiquidoAPagar = (financeiro: IFinanceiroCleanDTO): number =>
        reducao(financeiro.saldoAPagar, financeiro.descontoDiluicao);

    const baixarTermoRenegociacao = (matriculaId: number) =>
        http.get(`${resourceURL}/termo-renegociacao/${matriculaId}`, {
            responseType: 'blob',
        });

    const desconto = (financeiro: IFinanceiroDTO, oferta:number): number => {

        const desconto = Math.max(
            oferta
            * Math.max(financeiro.desconto || 0, financeiro.descontoCampanha || 0) / 100,
            financeiro.cupomDesconto?.valor || 0);

        return desconto;

    }

    const calcular = (financeiro: IFinanceiroDTO, oferta: number) => {
        
        financeiro.valorTotal = oferta + saldoLiquidoAPagar(financeiro);
        const desc = desconto(financeiro, oferta);
        const saldoAPagar = saldoLiquidoAPagar(financeiro);
        
        financeiro.valorTotalComDesconto = oferta - desc + saldoAPagar;
        
        financeiro.valorMensalidade = financeiro.valorTotal / financeiro.quantidadeParcelas;
        
        financeiro.valorMensalidadeComDesconto =
        financeiro.valorTotalComDesconto / financeiro.quantidadeParcelas;
    }

    return {
        ...baseFind<IFinanceiroCleanDTO, IFinanceiroDTO, IFinanceiroFullDTO>(
            resourceURL
        ),
        ...baseFindList<IFinanceiroCleanDTO, IFinanceiroDTO, IFinanceiroFullDTO>(
            resourceURL
        ),
        renegociacao,
        saldoAPagarResponse,
        calcular,
        saldoLiquidoAPagar,
        desconto,
        baixarTermoRenegociacao
    };
};
