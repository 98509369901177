import { Button, Col } from "antd";
import { memo, useState } from "react";
import * as Unicons from '@iconscout/react-unicons';
import { format } from "date-fns";
import moment from "moment";
import './style.css';
import { IPessoaCleanDTO } from "../../models/happy-code-api.model";

interface TParametros {
  pessoa: IPessoaCleanDTO
  selected: IPessoaCleanDTO
  setSelected: (setSelected: IPessoaCleanDTO) => void
  setUsuarioEmEdicao: (setUsuarioEmEdicao: IPessoaCleanDTO) => void
}

const ColPessoa = memo(({ pessoa, selected, setSelected, setUsuarioEmEdicao }: TParametros) => {
  
  const isSelected = (id: number) => {
    return selected && selected.id === id;
  }

  return (
    <Col style={{ marginBottom: 10 }} span={12}>

      <div 
      onClick={() => setSelected(pessoa)} 
      className={isSelected(pessoa.id) ? "box-pessoa-selected" : "box-pessoa"}>

        <h3>#{pessoa.id} {pessoa.nome}</h3>
        <h6>
          Nascimento: 
          {pessoa?.dataNascimento && moment(pessoa?.dataNascimento).format("DD/MM/YYYY")}
        </h6>
        <h6>CPF: {pessoa?.cpf}</h6>

        <Button
        onClick={() => setUsuarioEmEdicao(pessoa)}
        className="button-second"
        // style={{ width: 700, marginTop: 25, padding: '16px 24px' }}
        >
          
          Editar
        </Button>
      </div>
    </Col>
  )

})

ColPessoa.displayName = 'ColPessoa';

export default ColPessoa;
