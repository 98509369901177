import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlunoContext } from '../../../../../context/AlunoContext';
import { IMatriculaDTO } from '../../../../../models/happy-code-api.model';

interface UseNavigateToUpgradeProps {
	matriculaData: IMatriculaDTO;
}

export default function useNavigateToUpgrade({
	matriculaData,
}: UseNavigateToUpgradeProps) {
	const { matricula, setMatricula, aluno } = useAlunoContext();
	const { push } = useHistory();
	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();

	const navigateToUpgrade = useCallback(() => {
		if (!matriculaData?.turma?.id) return;

		setMatricula({ ...matricula, aluno });
		push(
			`/matriculas/create/curso/${idFinanceiro}/${matriculaData?.turma?.id}`
		);
	}, [matriculaData?.turma?.id]);

	return {
		navigateToUpgrade,
	};
}
