import http from '../helpers/http-common';
import {
	ITipoFaturamentoCleanDTO,
	ITipoFaturamentoDTO,
	ITipoFaturamentoFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/response.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useTipoFaturamentoService = () => {
	const resourceURL = 'tipo-faturamento';

	return {
		...baseFindList<
			ITipoFaturamentoCleanDTO,
			ITipoFaturamentoDTO,
			ITipoFaturamentoFullDTO
		>(resourceURL),
		...baseFind<
			ITipoFaturamentoCleanDTO,
			ITipoFaturamentoDTO,
			ITipoFaturamentoFullDTO
		>(resourceURL),
		patch: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<ITipoFaturamentoCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
	};
};
