import { FilterDefaultValue } from '../../../enum/filter-default-value.enum';
import { SortingEnum } from '../../../enum/sorting.enum';

interface GroupFiltersContent {
	value: string;
	label: string;
}

export default function useGroupContentFilters() {
	const groupFiltersContent: GroupFiltersContent[] = [
		{
			value: FilterDefaultValue.DEFAULT,
			label: 'Todos',
		},
		{
			value: 'true',
			label: 'Ativo',
		},
		{
			value: 'false',
			label: 'Inativo',
		},
	];

	const groupNameGroupFiltersContent: GroupFiltersContent[] = [
		{
			value: SortingEnum.ASC,
			label: 'Crescente',
		},
		{
			value: SortingEnum.DESC,
			label: 'Decrescente',
		},
	];

	return {
		groupFiltersContent,
		groupNameGroupFiltersContent,
	};
}
