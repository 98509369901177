import { Link } from "react-router-dom";
import * as Unicons from '@iconscout/react-unicons';
import { useEscolaContext } from "../../../../context/EscolaContext";

const EscolaGruposFormFecharComponent = ({ dsLink, onClick }: any) => {
  const { setGrupoParaCadastrar } = useEscolaContext();

  const defaultOnClick = () => {
    setGrupoParaCadastrar(null);
  }
  return <Link to={dsLink || "/escolas/grupos"} onClick={onClick || defaultOnClick}>
    <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
  </Link >
}

export default EscolaGruposFormFecharComponent;
