import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { Tags } from '../../components/Tags';
import { useColaboradorContext } from '../../context/ColaboradorContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { IPessoaCustomCleanDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { usePessoaService } from '../../services/pessoa.service';
import { formattedDate } from '../../util/format';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { useUsuarioService } from '../../services/usuario.service';

interface TItemLista {
	colaborador: IPessoaCustomCleanDTO;
	findColaborador : () => void;
};

const ItemList = memo(({ colaborador, findColaborador }: TItemLista) => {
	const history = useHistory();
	const pessoaService = usePessoaService();
	const usuarioService = useUsuarioService();
	const { setIsGlobalLoading } = useGlobalContext();

	const { setColaborador } = useColaboradorContext();
	const notification = useNotificationService();

	const goTo = () => {
		setIsGlobalLoading(true);
		pessoaService
			.findByIdCustom(colaborador.id)
			.then(({ data }) => setColaborador(data))
			.finally(() => {
				setIsGlobalLoading(false);
				history.push(`/colaboradores/create/step-4/${colaborador.id}`);
			});
	};

	const funcao =
		(colaborador.unidadePessoas.length > 0 &&
			colaborador.unidadePessoas[0].tipoUnidadePessoaDescricao) ||
		'N/A';

	const menu = (colaborador: IPessoaCustomCleanDTO) => {

		const showDeleteConfirm = (colaborador: IPessoaCustomCleanDTO) => {

			Modal.confirm({
				title: `Deseja realmente ${colaborador.ativo ? 'desativar' : 'ativar'} o colaborador?`,
				icon: <ExclamationCircleTwoTone rev={undefined} />,
				content: "Nome: " + colaborador.nome,
				okText: 'Yes',
				okType: `${colaborador.ativo ? 'danger' : 'primary'}`,
				cancelText: 'No',
				onOk: () => {
					setIsGlobalLoading(true);
					usuarioService.patchPessoaUsuarioHub(colaborador.id)
						.then(() => {
							notification({ description: 'Colaborador alterado com sucesso!', type: 'success', message: 'Sucesso' });
							findColaborador();
						})
						.finally(() => setIsGlobalLoading(false));
				},
				// onCancel: () => { },
			});
		}

		return (
			<Menu>
				<Menu.Item >
					<a onClick={(e) => showDeleteConfirm(colaborador)} rel="noopener noreferrer">
						Desativar/Ativar Colaborador
					</a>
				</Menu.Item>
			</Menu>
		)
	};

	return (
		<div className="registro-user" id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '30%' }}>
				<Avatar size={40} arquivo={colaborador?.foto} />
				<div className="nome">
					<p>{colaborador.nome}</p>
					<div className="estado-user">
						<div
							className={colaborador.ativo ? 'dot-verde' : 'dot-vermelho'}
						></div>
						<p className={colaborador.ativo ? 'ativo' : 'inativo'}>
							{colaborador.ativo ? 'Ativo' : 'Inativo'}
						</p>
					</div>
				</div>
			</div>
			<div className="funcao-user" id="colaboradores" style={{ width: '20%' }}>
				{/* TODO - Trazer a informacao da funcao do colaborador na listagem */}
				<Tags tipo={funcao} />
			</div>
			<div className="funcao-user" id="cursos-id" style={{ width: '45%' }}>
				<p>{formattedDate(colaborador.dataAdmissao) || 'N/A'}</p>
			</div>
			<div className="funcao-user" style={{ width: '3%' }}>
				<Dropdown overlay={menu(colaborador)} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
				</Dropdown>
			</div>
			<div onClick={goTo} className="registro-opcao" style={{ width: '4%' }}>
				<div className="circle">
					<Unicons.UilAngleRight size="20" color="#6A7C96" />
				</div>
			</div>
		</div>
	);
});

ItemList.displayName = 'ItemList';

export default ItemList;
