import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MenuDetalheUserProfile from '../../components/MenuDetalheUser/perfil';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';

import { IUsuarioFullDTO } from '../../models/happy-code-api.model';
import { useUsuarioService } from '../../services/usuario.service';
import { Avatar } from './../../components/Avatar/index';
import './style.css';

export const Profile: React.FunctionComponent = () => {
  const { usuario: usuarioLogado, setIsGlobalLoading } = useGlobalContext();
  const usuarioService = useUsuarioService();
  const history = useHistory();

  const [usuario, setUsuario] = useState<IUsuarioFullDTO>();

  useEffect(() => {
    setIsGlobalLoading(true)
    usuarioLogado && usuarioService
      .findFullById(usuarioLogado)
      .then(({ data }) => {
        setUsuario(data);
      })
      .finally(() => setIsGlobalLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioLogado])

  return (
    <div className="container-fondo" style={{ paddingTop: 0 }}>
      <NewMenu selecionado={0} />
      <MenuDetalheUserProfile usuario={usuario} />
      <div className="franquia" style={{ backgroundColor: '#F5F5F9', height: '100vh', overflowY: 'scroll', paddingTop: 30 }}>
        <div className="franquia-row" >
          <h5>Meu Perfil</h5>
          <div className="row-revisao" style={{ paddingBottom: 50 }}>
            {/* <ul className="menu-revisao-detalhe">
              <li className="selected" style={{ width: '33.3%' }}>
                Dados Básicos
              </li>
              <li style={{ width: '33.3%' }}>
                Função
              </li>
              <li style={{ width: '33.3%' }}>
                Contato
              </li>
            </ul> */}
            <div className="box-revisao">
              <div className="dados">
                {/* <Button className="button-line">Editar</Button> */}
                <h4>Dados Básicos</h4>
                <div className="box-aluno">
                  <Avatar size={115} arquivo={usuario?.arquivo} />
                  <div className="dados-colaborador">
                    <div className="nome">
                      <h5>Nome Completo</h5>
                      <span style={{ fontSize: 18 }}>{usuario?.pessoa.nome}</span>
                    </div>
                    <div className="dados-franquia">
                    </div>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                {/* <Button className="button-line">Editar</Button> */}
                <h4>Função</h4>
                <div className="tags-perfil">
                  <Tags tipo={usuario?.funcao.descricao} />
                  <Tags tipo={usuario?.perfil.codigo} />
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                {/* <Button className="button-line">Editar</Button> */}
                <h4>Contato</h4>
                {/* <div className="dados-franquia">
                  <div className="cpf">
                    <h6>TELEFONE</h6>
                    <span>(00)0000-0000</span>
                  </div>
                </div> */}
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Email Pessoal</h6>
                    <span>{usuario?.pessoa.email}</span>
                  </div>
                  {usuario?.pessoa.contatos.map((contato, index) => {
                    <div className="cpf" key={index}>
                      <h6>{contato.tipoContatoCodigo}</h6>
                      <span>{contato.descricao}</span>
                    </div>

                  })}
                  {/* <div className="cpf">
                    <h6>Email Professional</h6>
                    <span>
                      {
                        usuario?.pessoa.contatos.find(
                          (c) => c.tipoContatoId === ContatoEnum.EPF
                        )?.descricao
                      }
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                {/* <Button
                  onClick={() => goTo('/usuarios/create/step-3')}
                  className="button-line"
                >
                  Editar
                </Button> */}
                {/* <div id="user-franquias-revisao"> */}
                {usuario?.usuarioPaises.length > 0 ?
                  <>
                    <h4>Paises</h4>
                    {usuario?.usuarioPaises.map((usuarioPais) =>
                      <div id="curso-usuarios" key={usuarioPais.id}>
                        <h5>{usuarioPais.paisNome}</h5>
                      </div>)}
                  </>
                  : usuario?.usuarioClusters.length > 0 ?
                    <>
                      <h4>Clusters</h4>
                      {usuario?.usuarioClusters.map((usuarioCluster) =>
                        <div id="curso-usuarios" key={usuarioCluster.id}>
                          <h5>{usuarioCluster.clusterDescricao}</h5>
                        </div>)}
                    </>
                    : usuario?.usuarioUnidades.length > 0 &&
                    <>
                      <h4>Franquias</h4>
                      {usuario?.usuarioUnidades.map((usuarioUnidade) =>
                        <div id="curso-usuarios" key={usuarioUnidade.id}>
                          <h5>{usuarioUnidade.unidadeDescricao}</h5>
                        </div>)}
                    </>
                }
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              {/* <div className="dados">
                <Button className="button-line">Editar</Button>
                <h4>Controle</h4>
                <div className="boxes-controle">
                  <div className="box-controle">
                    <h3>Modulo 1 <Unicons.UilInfoCircle size="14" color="var(--primary-color)" /></h3>
                    <h6>Somente leitura</h6>
                  </div>
                  <div className="box-controle">
                    <h3>Modulo 1 <Unicons.UilInfoCircle size="14" color="var(--primary-color)" /></h3>
                    <h6>Somente leitura</h6>
                  </div>
                  <div className="box-controle">
                    <h3>Modulo 1 <Unicons.UilInfoCircle size="14" color="var(--primary-color)" /></h3>
                    <h6>Somente leitura</h6>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div style={{ height: 70 }}>
            <p style={{ color: 'transparent' }}>Tranparent</p>
          </div>
          {/* <div className="botoes" style={{ paddingBottom: 30 }}>
            <Link to="/usuarios">
              <Button className="button-line-red" icon={<Unicons.UilExport style={{ transform: 'rotate(90deg)', marginRight: 10 }} color="var(--red-color)" size="14" />}> Sair</Button>
            </Link>
          </div> */}
        </div>
      </div>
      <div className="botao-voltar-revisao">
        <Button className="button-second" onClick={() => history.goBack()} icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
      </div>
    </div>
  );
}