import { Button, Form, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';

import { ILogCleanDTO, ITipoFaturamentoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useLogService } from '../../services/log.service';
import { useNotificationService } from '../../services/notification.service';
import { useTipoFaturamentoService } from '../../services/tipo-faturamento.service';
import './style.css';

const Autonomia: React.FunctionComponent = () => {
    const [autonomia, setAutonomia] = useState<ITipoFaturamentoDTO>()
    const service = useTipoFaturamentoService();
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso autonomia",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const saveAutonomia = () => {
        setIsGlobalLoading(true);
        service.patch({ id: autonomia.id, valorAutonomiaDesconto: autonomia.valorAutonomiaDesconto })
            .finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        setIsGlobalLoading(true);
        service
            .findList(new Predicate())
            .then(({ data }) => data[0] && setAutonomia(data[0]))
            .finally(() => setIsGlobalLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const save = () => {
        try {
            saveAutonomia();
            notification({ message: "Sucesso!", description: "Alterações feitas com sucesso!" });
        } catch (error) {
            notification({ message: "Falha!", description: "houve um problema na alteração!" });
        }
    }

    return (
        <div className="container">
            <NewMenu selecionado={105} />
            <div className="dashboard" style={{ backgroundColor: '#f5f5f9', paddingTop: 50 }}>
                <div className="autonomia-row">
                    <Form layout={"vertical"}>
                        <div className="boxes-autonomia">
                            <div className="box-autonomia">
                                <div className="title">
                                    <h3>Autonomias de Desconto</h3>
                                </div>
                                <div className="line" style={{ marginTop: 10, marginBottom: 10 }}></div>
                                <h5>Autonomia de desconto sobre o valor mínimo</h5>
                                <Form.Item name="Autonomia de desconto sobre o valor mínimo" rules={[{ required: true, message: 'Insira a Autonomia de desconto sobre o valor mínimo' }]} style={{ width: '100%', marginTop: 20 }}>
                                    <div className="input-box">
                                        <InputNumber
                                            id="desconto-valor-minimo"
                                            style={{ width: '100%' }}
                                            formatter={value => `${value}%`}
                                            parser={value => parseInt(value.replace('%', ''))}
                                            value={autonomia?.valorAutonomiaDesconto}
                                            min={0}
                                            max={100}
                                            onChange={value => setAutonomia({ ...autonomia, valorAutonomiaDesconto: value })}
                                        />
                                    </div>
                                </Form.Item>
                                <div className="botoes" id="botoes-auto" style={{ justifyContent: "flex-end" }}>
                                    <Button className="button-second" style={{ width: 160, marginRight: 10 }}>Cancelar</Button>
                                    <Button className="button-primary" id="botao-salvar-autonomia" onClick={save} style={{ width: 180 }}>Salvar Alterações</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
export default Autonomia;