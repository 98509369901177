import { Button, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICobrancaCustomDTO, INfeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNfeService } from '../../services/nfe-service';
import './style.css';

interface Text {
    open: boolean;
    onClose: () => void;
    cobranca: ICobrancaCustomDTO;
    idEnota: string;
}

export const ModalNfe: React.FunctionComponent<Text> = ({ open, onClose, cobranca, idEnota }: Text) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const nfeService = useNfeService();
    const [nfeAutorizada, setNfeAutorizada] = useState<INfeDTO>(null);

    const findNfesAutorizada = async () => {
        const predicate = new Predicate();
        predicate.addOption('idEnota', idEnota);
        nfeService.findCustom(idEnota)
            .then((response) => {
                setNfeAutorizada(response.data)
            });
    }

    const closeModalNfes = () => {
        setNfeAutorizada(null);
        onClose();
    }

    useEffect(() => {
        if (idEnota) {
            setIsGlobalLoading(true);
            Promise.all([
                findNfesAutorizada(),
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idEnota]);

    if (!open) return null
    
    return (
        <div className="modalContainerFinanceiro">
            <div className="modal-user-financeiro">
                <div className="body">
                    <h3 style={{ textAlign: 'center' }}>{idEnota === cobranca?.identificadorEnotas ? "Nota fiscal serviço" : "Nota fiscal produto"}</h3>
                    <h4 style={{ textAlign: 'center' }}>{cobranca?.identificadorEnotas ? cobranca?.identificadorEnotas : cobranca?.identificadorEnotasProduto}</h4>

                    <div className="box-tabela" style={{ marginTop: 20 }}>
                        <div className="tabela">
                            <>
                                <Row className="titles-users" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <p>{"Status: "}</p><p style={{ color: nfeAutorizada?.status === 'Autorizada' ? 'green' : 'red', marginLeft: '5px' }}>{nfeAutorizada?.status}</p>
                                </Row>
                                <Row className="titles-users" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {"ID Cobranca: " + cobranca?.id}
                                </Row>
                                {nfeAutorizada?.motivoStatus ?
                                    <Row className="titles-users" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        {"Motivo: " + nfeAutorizada?.motivoStatus}
                                    </Row> : null}
                                <line></line>
                            </>
                        </div>
                    </div>
                </div>
                <div className="line"></div>
                <div className='legenda' style={{ fontSize: 13, marginLeft: 5 }}>
                    <h3>Legenda</h3>
                    <p><li>Autorizada: Nota fiscal já autorizada e encaminhada ao responsável.</li></p>
                    <p><li>Negada: Nota fiscal negada, não encaminhada ao responsável, verificar o campo motivo.</li></p>
                    <p><li>Cancelada: Nota fiscal cancelada.</li></p>
                </div>
                <div className="botoes">
                    <Button onClick={closeModalNfes} style={{ minWidth: 145 }} className="button-second">Voltar</Button>
                    {nfeAutorizada != null && nfeAutorizada.linkDanfe != null && nfeAutorizada.linkDanfe != "" ?
                        <a href={nfeAutorizada.linkDanfe}>
                            <Button className="button-danger">Baixar nota</Button>
                        </a>
                        : null}
                </div>
            </div>
        </div>
    );
}