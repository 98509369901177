import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AnaliticsImage from '../../assets/img/excelencia/analytics.svg';
import PilarImage from '../../assets/img/excelencia/signal-alt.svg';
import { IDmIndicadorTotalDTO, IDmPilarTotalIndicadorDTO } from '../../models/happy-code-api.model';
import useAccordionExcelenciaState from './states/accordion-excelencia.state';
import { formatExelencia } from '../../util/format';

export const AccordionExcelencia = () => {

  const {
    pilaresIndicadores,
    totalPorIndicadores,
    estilosPilar,
  } = useAccordionExcelenciaState();

  const Content1 = () => {
    return (
      <div className="d-flex-row-between-center gap-10">
        {pilaresIndicadores?.map?.((item: IDmPilarTotalIndicadorDTO) => {
          const estilo = estilosPilar[item?.dsPilar];
          return <div
            key={item?.idPilar}
            className="box-pilares"
            style={{ backgroundColor: estilo?.cor, ...estilo?.estilo }}>
            <img src={estilo?.icone} alt="Image" />
            <h6>{item?.dsPilar}</h6>
            <h2 style={{ fontSize: 16 }}>{formatExelencia(item?.totalNota)} / {formatExelencia(item?.totalPeso / 100)}</h2>
          </div>
        })}
      </div>
    )
  }

  const Content2 = () => {
    return (<div className="d-flex-row-between-center gap-10">

      {pilaresIndicadores?.map?.((item: IDmPilarTotalIndicadorDTO) => {
        const estilo = estilosPilar[item?.dsPilar];
        const indicadores = totalPorIndicadores
          ?.filter((itemIndicador) => itemIndicador.idPilar === item?.idPilar) as any;


        return indicadores?.map?.((item: IDmIndicadorTotalDTO) => {
          return <div key={item?.idIndicador}
            className="minibox-pilares"
            style={{ flex: 1, backgroundColor: estilo?.cor + '80' }} >
            <h6>{item?.dsIndicador}</h6>
            <h2 style={{ fontSize: 12 }}>{formatExelencia(item?.totalNota)} / {formatExelencia(item?.totalPeso / 100)}</h2>
          </div>
        })
      })}
    </div>
    )
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <img src={PilarImage} alt="Pilar Image" style={{ height: 15, width: 'auto', marginTop: 5 }} />
          <h6 className="title-accordion">Pilares de Excelência:</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Content1 />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <img src={AnaliticsImage} alt="Analitics Image" />
          <h6 className="title-accordion">Indicadores por Pilar:</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Content2 />
        </AccordionDetails>
      </Accordion>
    </>
  );
}