import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Input, Progress } from 'antd';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tags } from '../../../../components/Tags';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { useTurmaContext } from '../../../../context/TurmaContext';
import { TableState } from '../../../../enum/table-state.enum';
import {
	IAulaCustomDTO,
	IMatriculaFullDTO,
	ITurmaFullDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useMatriculaService } from '../../../../services/matricula.service';
import {
	isAfterDate,
	isBeforeDate,
	isDateBetween,
} from '../../../../util/format';
import { useAulaService } from '../../../../services/aula.service';
import { useTurmaService } from '../../../../services/turma.service';
import '../../style.css';
import Title from '../../../../components/Title/title.component';
import MenuSidebarAndContainer from '../../../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';
import './detalhe.styles.scss';

export const TurmasDetalhe: React.FunctionComponent = () => {
	const { id } = useParams<any>();
	const { removeTurma } = useTurmaContext();
	const [turma, setTurma] = useState<ITurmaFullDTO>();
	const [listAula, setListAula] = useState<PageableResponse<IAulaCustomDTO>>();
	const [listMatricula, setListMatricula] =
		useState<PageableResponse<IMatriculaFullDTO>>();
	const [tableState, setTableState] = useState<TableState>(TableState.ALUNO);
	const { setIsGlobalLoading } = useGlobalContext();
	const turmaService = useTurmaService();
	const aulaService = useAulaService();
	const matriculaService = useMatriculaService();
	const [predicateAula] = useState<Predicate>(new Predicate());
	const [matriculaPredicate] = useState<Predicate>(new Predicate());
	const history = useHistory();

	const findAula = () => {
		predicateAula.addOption('turma.id', id);
		return aulaService.findCustom(predicateAula).then((response) => {
			setListAula(response.data);
		});
	};

	const findMatricula = () => {
		matriculaPredicate.addOption('turma.id', id);
		return matriculaService.findFull(matriculaPredicate).then((response) => {
			setListMatricula(response.data);
		});
	};

	const findTurma = () => {
		return turmaService.findFullById({ id }).then((response) => {
			setTurma(response.data);
		});
	};

	useEffect(() => {
		removeTurma();
		if (id) {
			setIsGlobalLoading(true);
			Promise.all([findTurma(), findAula(), findMatricula()]).finally(() =>
				setIsGlobalLoading(false)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const statusTurma = (turma: ITurmaFullDTO) => {
		if (!turma.ativo) {
			return (
				<div className="box-detalhe-turma-dot">
					<div className="dot-detalhe-turma-vermelho"></div>
					<p className="detalhe-turma-p-vermelho" style={{ marginRight: 10 }}>
						Inativo
					</p>
				</div>
			);
		}
		if (isBeforeDate(turma.dataInicio)) {
			return (
				<div className="box-detalhe-turma-dot">
					<div className="dot-detalhe-turma-standby"></div>
					<p className="detalhe-turma-p-standby" style={{ marginRight: 10 }}>
						Não Iniciado
					</p>
				</div>
			);
		}
		if (isDateBetween(turma.dataInicio, turma.dataFim)) {
			return (
				<div className="box-detalhe-turma-dot">
					<div className="dot-detalhe-turma-verde"></div>
					<p className="detalhe-turma-p-verde" style={{ marginRight: 10 }}>
						Em andamento
					</p>
				</div>
			);
		}
		if (isAfterDate(turma.dataFim)) {
			return (
				<div className="box-detalhe-turma-dot">
					<div className="dot-detalhe-turma-verde"></div>
					<p className="detalhe-turma-p-verde" style={{ marginRight: 10 }}>
						Concluído
					</p>
				</div>
			);
		}
		return null;
	};

	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicateAula.setPage(page);
		setIsGlobalLoading(true);
		findAula().finally(() => setIsGlobalLoading(false));
	};

	const onPageChangeMatricula = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		matriculaPredicate.setPage(page);
		setIsGlobalLoading(true);
		findMatricula().finally(() => setIsGlobalLoading(false));
	};

	const qtdAulaRealizadas = () => {
		if (turma && turma.aulas) {
			return turma.aulas.filter((aula) => isBeforeDate(aula.data)).length;
		}
		return 0;
	};

	const getPorcentagem = () => {
		if (turma && turma.aulas) {
			return parseInt(
				((qtdAulaRealizadas() / turma.aulas.length) * 100).toPrecision(2)
			);
		}
		return 0;
	};

	const { push } = useHistory();

	const turmaDataInicio = moment(turma?.dataInicio).format('DD/MM/YYYY');
	const turmaDataFim = moment(turma?.dataFim).format('DD/MM/YYYY');
	const turmaDuracao = moment(turma?.dataInicio).to(turma?.dataFim, true);

	const alunosAtivos = turma?.matriculas?.length ?? 0;
	const minutagem = (
		(turma?.curso?.duracaoMinutos / turma?.aulas?.length) *
		qtdAulaRealizadas()
	).toFixed(0);

	return (
		<>
			<MenuSidebarAndContainer itemMenuSelected={12}>
				<div className={'turma-detalhes-wrapper__title-back-button'}>
					<Button
						onClick={() => push('/turmas')}
						className="button-second"
						icon={<ArrowLeftOutlined rev={undefined} />}
					>
						Voltar
					</Button>
					<Title title={'Detalhes da Turma'} />
				</div>

				<div className={'turma-info-and-turma-progress'}>
					<div className={'turma-info'}>
						<div className={'turma-info__turma-title-duration'}>
							<h5>{turma?.descricao}</h5>

							<div className={'turma-title-duration__duration-status'}>
								<small>
									{turmaDataInicio} - {turmaDataFim} ({turmaDuracao})
								</small>
								{turma && statusTurma(turma)}
							</div>
						</div>

						<div className={'turma-info__alunos-ativos-minutos'}>
							<div className={'alunos-ativos-minutos-container'}>
								<Unicons.UilSmile size="14" color="#6A7C96" />
								<p>{alunosAtivos} alunos ativos</p>
							</div>

							<div className={'alunos-ativos-minutos-container'}>
								<Unicons.UilGraduationCap size="14" color="#6A7C96" />
								<p>
									{qtdAulaRealizadas()}/{turma?.aulas?.length}
								</p>
							</div>

							<div className={'alunos-ativos-minutos-container'}>
								<Unicons.UilClock size="14" color="#6A7C96" />
								<p>
									{minutagem}/{turma?.curso?.duracaoMinutos} minutos
								</p>
							</div>
						</div>

						<div className={'turma-info__horarios-container'}>
							<small>Horários</small>
							<div className={'horarios-container'}>
								{turma &&
									turma.horarios?.map(
										({ id, diaSemana, horaInicio, horaFim }) => (
											<div className="horarios-container__horario-box" key={id}>
												<p className={'horario-box__dia-semana'}>{diaSemana}</p>
												<p className={'horario-box__horario'}>
													{horaInicio.slice(0, -3)} - {horaFim.slice(0, -3)}
												</p>
											</div>
										)
									)}
							</div>
						</div>
					</div>

					<div className={'turma-progress-container'}>
						<Progress
							type="circle"
							percent={getPorcentagem()}
							width={80}
							strokeColor="var(--primary-color)"
							trailColor="rgba(203, 212, 237, 0.2);"
						/>

						<div className="turma-progress-container__progress-text">
							<label>Andamento</label>
							<p>
								{qtdAulaRealizadas()} de {turma?.aulas?.length} aulas
							</p>
						</div>
					</div>
				</div>

				<div className="filtros" style={{ width: '100%', marginBottom: 0 }}>
					<div className="input-text" style={{ width: '100%' }}>
						<Input
							placeholder="Buscar"
							prefix={
								<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
							}
						/>
					</div>
				</div>
				<div className="row-revisao">
					<ul className="menu-revisao-detalhe pointer">
						<li
							onClick={() => setTableState(TableState.ALUNO)}
							className={tableState === TableState.ALUNO ? 'selected' : ''}
							style={{ width: '25%' }}
						>
							Alunos
						</li>
						<li
							onClick={() => setTableState(TableState.AULA)}
							className={tableState === TableState.AULA ? 'selected' : ''}
							style={{ width: '25%' }}
						>
							Aulas
						</li>
					</ul>
					{tableState === TableState.AULA ? (
						<div className="box-revisao">
							<div className="dados">
								<div className="tabela">
									<div className="titles-users">
										<div className="item" style={{ width: '20%' }}>
											<span>NOME</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '15%' }}>
											<span>DATA</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '20%' }}>
											<span>STATUS</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '20%' }}>
											<span>ALUNOS PRESENTES</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '20%' }}>
											<span>ALUNOS FALTOSOS</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '5%' }}></div>
									</div>
									{listAula && listAula.content.length === 0
										? 'Nenhuma aula'
										: null}
									{listAula &&
										listAula.content.map((aula, index) => (
											<div key={index} className="registro-user">
												<div
													className="funcao-user"
													id="alunos"
													style={{ width: '20%' }}
												>
													<p>{aula.descricao}</p>
												</div>
												<div className="funcao-user" style={{ width: '15%' }}>
													<p>{moment(aula.data).format('DD/MM/YYYY')}</p>
												</div>
												<div className="funcao-user" style={{ width: '20%' }}>
													<Tags
														tipo={
															aula.possuiLancamento ? 'lancado' : 'pendente'
														}
													/>
												</div>
												<div className="funcao-user" style={{ width: '20%' }}>
													<p>{aula.alunosPresentes}</p>
												</div>
												<div className="funcao-user" style={{ width: '0%' }}>
													<p>{aula.alunosFaltosos}</p>
												</div>
											</div>
										))}
								</div>
							</div>
						</div>
					) : (
						<div className="box-revisao">
							<div className="dados">
								<div className="tabela">
									<div className="titles-users">
										<div className="item" style={{ width: '70%' }}>
											<span>NOME</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '15%' }}>
											<span>MATRICULA</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '15%' }}>
											<span>FALTA</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
									</div>
									{listMatricula &&
										listMatricula.content.length === 0 &&
										'Nenhum Aluno'}
									{listMatricula &&
										listMatricula.content.map((matricula) =>
											matricula.statusMatricula.id != 3 ? (
												<div key={matricula.id} className="registro-user">
													<div
														className="user"
														id="cursos"
														style={{ width: '70%' }}
													>
														<div className="nome" style={{ marginBottom: 0 }}>
															<p>{matricula.alunoPessoaNome}</p>
															<div className="estado-user">
																<div className="dot-verde"></div>
																<p className="ativo">
																	{matricula.ativo ? 'Ativo' : 'Desativado'}
																</p>
															</div>
														</div>
													</div>
													<div
														className="funcao-user"
														id="alunos"
														style={{ width: '15%' }}
													>
														<p>#{matricula.id}</p>
													</div>
													<div className="funcao-user" style={{ width: '15%' }}>
														<p>
															{
																matricula.frequencias.filter(
																	(frequencia) => !frequencia.frequencia
																).length
															}
														</p>
													</div>
													{/* <div onClick={() => history.push(`/turmas/observacoes/aluno/${matricula.aluno.id}`)} className="funcao-user pointer" id="aluno-responsavel" style={{ width: '20%' }}>
                          <p>Ver observações</p>
                        </div>
                        <div onClick={() => history.push(`/turmas/skills/aluno/${matricula.aluno.id}`)} className="funcao-user pointer" id="aluno-responsavel" style={{ width: '20%' }}>
                          <p>Ver skills</p>
                        </div> */}
												</div>
											) : null
										)}
								</div>
							</div>
						</div>
					)}
					{tableState === TableState.AULA ? (
						<div
							className="paginacao"
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
							}}
						>
							<Pagination
								onChange={(event, page) => onPageChange(event, page - 1)}
								page={listAula ? listAula.pageable.pageNumber + 1 : 0}
								count={listAula ? listAula.totalPages : 0}
							/>
						</div>
					) : (
						<div
							className="paginacao"
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
							}}
						>
							<Pagination
								onChange={(event, page) =>
									onPageChangeMatricula(event, page - 1)
								}
								page={listMatricula ? listMatricula.pageable.pageNumber + 1 : 0}
								count={listMatricula ? listMatricula.totalPages : 0}
							/>
						</div>
					)}
				</div>
			</MenuSidebarAndContainer>
		</>
	);
};
