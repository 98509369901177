import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Progress, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import '../../global.css';
import { ICurriculoDTO, INegocioCleanDTO } from '../../models/happy-code-api.model';
import { useCurriculoService } from '../../services/curriculo.service';
import { useNegocioService } from '../../services/negocio.service';
import StepsCreate from './steps-create';
import './style.css';

const CurriculoCreateStep1: React.FunctionComponent = () => {

  const [curriculo, setCurriculo] = useState<ICurriculoDTO>();
  const [negocioList, setNegocioList] = useState<INegocioCleanDTO[]>();
  const { id } = useParams<any>();

  const { TextArea } = Input;
  const [isOpen, setIsOpen] = useState(false);
  const negocioService = useNegocioService();
  const curriculoService = useCurriculoService();

  const fetchNegocios = () => {
    return negocioService.findCleanList().then((response) => setNegocioList(response.data));
  }

  const save = () => {
    if (!curriculo.id) {
      curriculoService.create(curriculo);
    } else {
      curriculoService.update(curriculo);
    }
  }

  const fetchCurriculo = () => {
    curriculoService.findById({ id: id }).then((response) => setCurriculo(response.data));
  }

  useEffect(() => {
    if (id) {
      fetchCurriculo();
    }

    fetchNegocios();
  }, [])

  const history = useHistory();
  const onFinish = () => {
    history.push("/curriculo");
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={16} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">
        <div className="user-row">
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3 id="taxas-h3">Insira o nome do curriculo</h3>
            {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
            <div className="input-box">
              <p>Nome do Curriculo</p>
              <Input style={{ width: "100%" }} value={curriculo?.descricaoCurriculo} id="nome" onChange={e => setCurriculo({ ...curriculo, descricaoCurriculo: e.target.value })} />
            </div>


            <div className="input-box">
              <p>Faixa etaria inicial</p>
              <InputNumber style={{ width: "100%" }} min={0} value={curriculo?.faixaEtariaIni} id="descricao" onChange={e => setCurriculo({ ...curriculo, faixaEtariaIni: e })} />
            </div>


            <div className="input-box">
              <p>Faixa etaria final</p>
              <InputNumber style={{ width: "100%" }} min={0} value={curriculo?.faixaEtariaFim} id="descricao" onChange={e => setCurriculo({ ...curriculo, faixaEtariaFim: e })} />
            </div>


            <div className="input-box">
              <p>Número de aulas</p>
              <InputNumber style={{ width: "100%" }} min={0} value={curriculo?.quantidadeAulas} id="descricao" onChange={e => setCurriculo({ ...curriculo, quantidadeAulas: e })} />
            </div>


            <div className="input-box">
              <p>Duração semanal em minutos</p>
              <InputNumber style={{ width: "100%" }} min={0} value={curriculo?.duracaoTotal} id="descricao" onChange={e => setCurriculo({ ...curriculo, duracaoTotal: e })} />
            </div>


            <div className="input-box">
              <p>Negócio</p>
              <Select style={{ width: "100%" }} value={curriculo?.negocio?.id} onChange={(e) => setCurriculo({ ...curriculo, negocio: { ...curriculo?.negocio, id: e } })}>
                {negocioList && negocioList.map((negocio) =>
                  <Select.Option key={negocio.id} value={negocio.id}>{negocio.descricao}</Select.Option>
                )}
              </Select>


            </div>

            <div className="botoes">
              <Link to="/curriculo">
                <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
              </Link>
              <Button onClick={save} type="primary" htmlType="submit" id="proximo" className="button-primary" style={{ width: 345 }}>Salvar</Button>


            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação desse curriculo?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/curriculo'
      />
    </div>
  )

}

export default CurriculoCreateStep1;