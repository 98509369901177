import { useCallback, useEffect, useState } from 'react';
import useFindPowerBiDashboards from '../DashboardReport/use-find-powerbi-dashboards.hook';
import { ReportsOject } from '../../../types/reports-object.type';

export default function useReports() {
	const { buildDashboardDataToReportScreen } = useFindPowerBiDashboards();

	const [reports, setReports] = useState<ReportsOject[]>([]);

	const initilizateReportsData = useCallback((): ReportsOject[] => {
		return [
			{
				id: 1,
				title: 'Relatórios',
				reportCards: [
					// {
					// 	id: 1,
					// 	title:
					// 		'Relatório de Matrículas e Rematrículas - Novo Anterior (Até 05/11)',
					// 	link: '/relatorio/matricula',
					// },
					{
						id: 2,
						title: 'Relatório de Matrícula',
						link: '/relatorio/matricula',
					},
					// {
					// 	id: 3,
					// 	title: 'Relatório Financeiro',
					// 	link: '/relatorio/financeiro',
					// },
					{
						id: 4,
						title: 'Relatório Aniversariantes',
						link: '/relatorio/aniversario',
					},
					{
						id: 5,
						title: 'Relatório Conciliação Bancária',
						link: '/relatorio/conciliacao',
					},
					{
						id: 6,
						title: 'Relatório Notas Fiscais',
						link: '/relatorio/notas',
					},
					// {
					// 	id: 7,
					// 	title: 'Oportunidades de Rematrícula',
					// 	link: '/relatorio/rematricula',
					// },
					{
						id: 8,
						title: 'Relatório de Rematrícula',
						link: '/relatorio/rematricula',
					},
					// {
					// 	id: 9,
					// 	title: 'Relatório Inadimplencia Hub',
					// 	link: '/relatorio/inadimplencia-hub',
					// },
					// {
					// 	id: 10,
					// 	title: 'Pré-Matriculado - Novo Modelo (Após 05/11)',
					// 	link: '/relatorio-sede/matricula-diaria-franquia-curriculo-prematriculado',
					// },
					// {
					// 	id: 11,
					// 	title: 'Matrículas e Rematrículas - Novo Modelo (Após 05/11)',
					// 	link: '/relatorio-sede/matricula-diaria-franquia-curriculo',
					// },
					{
						id: 12,
						title: 'Relatório financeiro detalhado',
						link: '/relatorio/financeiro-detalhado',
					},
				],
			},
			{
				id: 2,
				title: 'Relatórios Escolas',
				reportCards: [
					{
						id: 1,
						title: 'Relatório Financeiro',
						link: '/relatorio/financeiro-escola',
					},
					{
						id: 2,
						title: 'Relatório Conciliação Bancária',
						link: '/relatorio/conciliacao-escola',
					},
				],
			},
			{
				id: 3,
				title: 'Relatórios Academy',
				reportCards: [
					// {
					//   id: 1,
					//   title: 'Registro de frequência por turma',
					//   link: '/relatorio/registro-frequencia-turma-academy',
					// },
					// {
					//   id: 2,
					//   title: 'Registro de frequência por aluno',
					//   link: '/relatorio/registro-frequencia-aluno-academy',
					// },
					// {
					//   id: 3,
					//   title: 'Registro de tarefa',
					//   link: '/relatorio/registro-tarefa',
					// },
					// {
					//   id: 4,
					//   title: 'Registro de avaliação',
					//   link: '/relatorio/registro-avaliacao',
					// },
					{
						id: 5,
						title: 'Progresso de trilha por instrutor',
						link: '/relatorio/progresso-trilha-instrutor-academy',
					},
				],
			},
			{
				id: 4,
				title: 'Dashboards',
				reportCards: buildDashboardDataToReportScreen(),
			},
		];
	}, [buildDashboardDataToReportScreen]);

	const updateReports = useCallback(() => {
		setReports(initilizateReportsData());
	}, [initilizateReportsData]);

	useEffect(() => {
		const controller = new AbortController();

		updateReports();

		return () => controller.abort();
	}, [updateReports]);

	return {
		reports,
	};
}
