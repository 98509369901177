import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO } from "../../models/happy-code-api.model";
import { useNotificationService } from '../../services/notification.service';
import { useProdutoEscolaService } from '../../services/produto-escola.service';

interface TItemLista {
    produtoEscola: IProdutoEscolaDTO;
    handleprodutoEscola: (produtoEscola: IProdutoEscolaCleanDTO, state: boolean) => void;
}

const ItemListProdutoEscola = memo(({ produtoEscola, handleprodutoEscola }: TItemLista) => {
    const history = useHistory();
    const produtoEscolaService = useProdutoEscolaService();
    const [produtoEscolaFull, setProdutoEscolaFull] = useState<IProdutoEscolaFullDTO>();
    const { setIsGlobalLoading } = useGlobalContext();
    const [ativo, setAtivo] = useState<boolean>();
    const notification = useNotificationService();

    useEffect(() => {
        setAtivo(produtoEscola.ativo);
    }, [produtoEscola.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a aula?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: produtoEscola?.descricao,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                produtoEscolaService.patch({ id: produtoEscola.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Produto alterada com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
        </Menu>
    );

    const goTo = () => {
        history.push(`/produto-escola/create/step-1/${produtoEscola.id}`)
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '15%' }}>
                <div className="nome">
                    <p>{produtoEscola?.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '15%' }}>
                <span style={{marginRight:7}}>{produtoEscola.id}</span>
            </div>
            <div className="funcao-user" id="campanhas" style={{ width: '15%' }}>
                <span>{produtoEscola.faixaEtariaIni + " - " + produtoEscola.faixaEtariaFim}</span>
            </div>
            <div className="funcao-user" id="campanhas" style={{ width: '20%' }}>
                <span>{produtoEscola.anoEscolar}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '15%' }}>
                <p>{produtoEscola?.negocio?.descricao}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '20%' }}>
                <p>{produtoEscola?.codigo}</p>
            </div>
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListProdutoEscola.displayName = 'ItemListProdutoEscola';

export default ItemListProdutoEscola;