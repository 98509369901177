import { Button, Form } from 'antd';
import { useHistory } from 'react-router-dom';

interface EscolaGruposFormActionComponentProps {
	revisao?: boolean;
	rotaAnterior?: string;
	onBack?: () => void;
}

const EscolaGruposFormActionComponent = ({
	revisao,
	rotaAnterior,
	onBack,
}: EscolaGruposFormActionComponentProps) => {
	const history = useHistory();

	return (
		<div className="botoes">
			{revisao ? (
				<>
					<Form.Item name="revisao" initialValue="1" />
					<Button
						type="primary"
						htmlType="submit"
						className="button-primary"
						id="revisao"
						style={{ width: 700 }}
					>
						Voltar para Revisão
					</Button>
				</>
			) : (
				<>
					<Button
						className="button-second"
						id="voltar"
						style={{ width: 345 }}
						onClick={() => {
							onBack?.();
							history.push(rotaAnterior);
						}}
					>
						Voltar
					</Button>
					<button
						type="submit"
						className="button-primary"
						id="proximo"
						style={{ width: 345 }}
					>
						Próximo
					</button>
				</>
			)}
		</div>
	);
};

export default EscolaGruposFormActionComponent;
