import { Button, Modal, Table, TableColumnsType } from 'antd';
import { useEffect, useState } from 'react';
import { IHoraUnidadeFullDTO, IUnidadeCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useHoraUnidadeService } from '../../services/hora-unidade.service';
import { formatMoney } from '../../util/format';
import "./styles.css";

interface Props {
    visible: boolean;
    title: string;
    unidade: IUnidadeCleanDTO;
    onClose: () => void;
}

const ModalLicencaHub: React.FC<Props> = ({ visible, title, unidade, onClose }: Props) => {
    const horaUnidadeService = useHoraUnidadeService();
    const [data, setData] = useState<IHoraUnidadeFullDTO>();
    const predicate = new Predicate();

    const findLicencaHub = () => {
        predicate.addOption('ativo', true)
        predicate.addOption('unidade.id', unidade.id)
        return horaUnidadeService.findFull(predicate)
            .then((response) => {
                setData(response.data.content[0]);
            });
    }

    useEffect(() => {
        if (unidade.id) {
            findLicencaHub()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidade]);

    const columns: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split 1h30',
            dataIndex: 'splitUmHoraEMeia',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUmHoraEMeia);
            },
        },
        {
            title: 'Split 3h',
            dataIndex: 'splitTresHoras',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitTresHoras);
            },
        },
        {
            title: 'Split 4h30',
            dataIndex: 'splitQuatroHorasEMeia',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitQuatroHorasEMeia);
            },
        },
        {
            title: 'Split 6h',
            dataIndex: 'splitSeisHoras',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitSeisHoras);
            },
        },
        {
            title: 'Split Adicional',
            dataIndex: 'splitAdicional',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitAdicional);
            },
        },
    ];

    const columnsUpgrade: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split 1h30',
            dataIndex: 'splitUpgradeUmaHoraEMeiaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeUmaHoraEMeiaCapital);
            },
        },
        {
            title: 'Split 3h',
            dataIndex: 'splitUpgradeTresHorasCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeTresHorasCapital);
            },
        },
        {
            title: 'Split 4h30',
            dataIndex: 'splitUpgradeQuatroHorasEMeiaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeQuatroHorasEMeiaCapital);
            },
        },
        {
            title: 'Split 6h',
            dataIndex: 'splitUpgradeSeisHorasCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeSeisHorasCapital);
            },
        },
        {
            title: 'Split Adicional',
            dataIndex: 'splitAdicionalUpgrade',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitAdicionalUpgrade);
            },
        },
    ];

    const columnsCapital: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split 1h30',
            dataIndex: 'splitUmHoraEMeiaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUmHoraEMeiaCapital);
            },
        },
        {
            title: 'Split 3h',
            dataIndex: 'splitTresHorasCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitTresHorasCapital);
            },
        },
        {
            title: 'Split 4h30',
            dataIndex: 'splitQuatroHorasEMeiaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitQuatroHorasEMeiaCapital);
            },
        },
        {
            title: 'Split 6h',
            dataIndex: 'splitSeisHorasCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitSeisHorasCapital);
            },
        },
        {
            title: 'Split Adicional',
            dataIndex: 'splitAdicionalCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitAdicionalCapital);
            },
        },
    ];

    const columnsUpgradeCapital: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split 1h30',
            dataIndex: 'splitUpgradeUmaHoraEMeiaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeUmaHoraEMeiaCapital);
            },
        },
        {
            title: 'Split 3h',
            dataIndex: 'splitUpgradeTresHorasCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeTresHorasCapital);
            },
        },
        {
            title: 'Split 4h30',
            dataIndex: 'splitUpgradeQuatroHorasEMeiaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeQuatroHorasEMeiaCapital);
            },
        },
        {
            title: 'Split 6h',
            dataIndex: 'splitUpgradeSeisHorasCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitUpgradeSeisHorasCapital);
            },
        },
        {
            title: 'Split Adicional',
            dataIndex: 'splitAdicionalUpgradeCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitAdicionalUpgradeCapital);
            },
        },
    ];

    const columnsCurta: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split 1h30',
            dataIndex: 'splitCurtaUmHoraEMeia',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitCurtaUmHoraEMeia);
            },
        },
        {
            title: 'Split 3h',
            dataIndex: 'splitCurtaTresHoras',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitCurtaTresHoras);
            },
        },
        {
            title: 'Split 4h30',
            dataIndex: 'splitCurtaQuatroHorasEMeia',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitCurtaQuatroHorasEMeia);
            },
        },
        {
            title: 'Split 6h',
            dataIndex: 'splitCurtaSeisHoras',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitCurtaSeisHoras);
            },
        },
    ];


    const columnsColonia: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split Colônia',
            dataIndex: 'splitColonia',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitColonia);
            },
        },
    ];

    const columnsColoniaCapital: TableColumnsType<IHoraUnidadeFullDTO> = [
        {
            title: 'Split Colônia',
            dataIndex: 'splitColoniaCapital',
            align: "center",
            render: (_value, record, _index) => {
                return formatMoney(record.splitColoniaCapital);
            },
        },
    ];






    const dataSourceIntra = [
        {
            key: '1',
            de: 0,
            ponteiro: <span className="seta" />,
            ate: 200,
            valorLicenca: "R$ 14,90",
        },
        {
            key: '1',
            de: 201,
            ponteiro: <span className="seta" />,
            ate: 400,
            valorLicenca: "R$ 12,90",
        },
        {
            key: '1',
            de: 401,
            ponteiro: <span className="seta" />,
            ate: "Máximo",
            valorLicenca: "R$ 10,90",
        },
    ];

    const columnsPrecificacao = [
        {
            title: 'De',
            dataIndex: 'de',
            key: 'de',
        },
        {
            title: 'Quantidade Licença',
            dataIndex: 'ponteiro',
            key: 'ponteiro',
        },
        {
            title: 'Até',
            dataIndex: 'ate',
            key: 'ate',
        },
        {
            title: 'Valor Licença',
            dataIndex: 'valorLicenca',
            key: 'valorLicenca',
        },
    ];

    const dataSourceExtra = [
        {
            key: '1',
            de: 0,
            ponteiro: <span className="seta" />,
            ate: "Máximo",
            valorLicenca: "R$ 39,90",
        },
    ];

    const dataSourceServico = [
        {
            key: '1',
            de: 0,
            ponteiro: <span className="seta" />,
            ate: "Máximo",
            valorLicenca: "R$ 25,00",
        },
    ];


    return (
        <Modal
            className="modal__licenca__hub"
            title={title + unidade?.descricao}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="submit" className="buttom__licenca__hub" onClick={onClose}>
                    Fechar
                </Button>,
            ]}
        >
            <header>
                <h1>Licenças B2C</h1>
            </header>

            {unidade.capital ?
                <>
                    <h1>Matrícula</h1>
                    <Table
                        className="mb-20 w-100"
                        style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        pagination={false}
                        dataSource={data ? [data] : []}
                        columns={columnsCapital} />
                    <h1>Upgrade</h1>

                    <Table
                        className="mb-20 w-100"
                        style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        pagination={false}
                        dataSource={data ? [data] : []}
                        columns={columnsUpgradeCapital} />
                    <h1>Colônia</h1>
                    <Table
                        className="mb-20 w-100"
                        style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        pagination={false}
                        dataSource={data ? [data] : []}
                        columns={columnsColoniaCapital} />
                </>
                :
                <>
                    <h1>Matrícula</h1>
                    <Table
                        className="mb-20 w-100"
                        style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        pagination={false}
                        dataSource={data ? [data] : []}
                        columns={columns} />

                    <h1>Upgrade</h1>
                    <Table
                        className="mb-20 w-100"
                        style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        pagination={false}
                        dataSource={data ? [data] : []}
                        columns={columnsUpgrade} />
                    <h1>Colônia</h1>
                    <Table
                        className="mb-20 w-100"
                        style={{ whiteSpace: "nowrap", textAlign: "center" }}
                        pagination={false}
                        dataSource={data ? [data] : []}
                        columns={columnsColonia} />
                </>
            }
            <h1>Curta</h1>
            <Table
                className="mb-20 w-100"
                style={{ whiteSpace: "nowrap", textAlign: "center" }}
                pagination={false}
                dataSource={data ? [data] : []}
                columns={columnsCurta} />

            <header>
                <h1>Licenças B2B</h1>
            </header>

            <h1>Licença Intra</h1>
            <Table
                className="mb-20 w-100"
                style={{ whiteSpace: "nowrap", textAlign: "center" }}
                pagination={false}
                dataSource={dataSourceIntra}
                columns={columnsPrecificacao} />
            <h1>Licença Extra</h1>
            <Table
                className="mb-20 w-100"
                style={{ whiteSpace: "nowrap", textAlign: "center" }}
                pagination={false}
                dataSource={dataSourceExtra}
                columns={columnsPrecificacao} />
            <h1>Licença de Serviço</h1>
            <Table
                className="mb-20 w-100"
                style={{ whiteSpace: "nowrap", textAlign: "center" }}
                pagination={false}
                dataSource={dataSourceServico}
                columns={columnsPrecificacao} />
        </Modal>
    );
};

export default ModalLicencaHub;
