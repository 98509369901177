import {
	ITipoPagamentoCleanDTO,
	ITipoPagamentoDTO,
	ITipoPagamentoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useTipoPagamentoService = () => {
	const resourceURL = 'tipo-pagamento';

	return {
		...baseFindList<
			ITipoPagamentoCleanDTO,
			ITipoPagamentoDTO,
			ITipoPagamentoFullDTO
		>(resourceURL),
		...baseFind<
			ITipoPagamentoCleanDTO,
			ITipoPagamentoDTO,
			ITipoPagamentoFullDTO
		>(resourceURL),
	};
};
