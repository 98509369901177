import { clear } from '../util/store';
import { useLogService } from '../services/log.service';
import { ILogCleanDTO } from '../models/happy-code-api.model';
import { useHistory } from 'react-router-dom';

export default function useLogoutUserHook() {
	const { registraLog } = useLogService();

	const log: ILogCleanDTO = {
		descricaoEvento: 'Logout',
		descricaoTipo: 'Logout',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	};

	const { push } = useHistory();

	const logoutUser = () => {
		registraLog(log).then(() => clear());
		push('/login');
	};

	return logoutUser;
}
