import ImgPainel from '../../assets/img/box-stemplay/painel.svg';
import ImgAlunos from '../../assets/img/box-stemplay/alunos.svg';
import ImgTurmas from '../../assets/img/box-stemplay/turmas.svg';
import { Link, useHistory } from 'react-router-dom';
import './style.css';

export const BoxStemplay: React.FunctionComponent = () => {

  return (
    <section className="content" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
      <div className="box-stemplay">
        <div className="description">
          <Link to="/Stemplay/painel">
            <img className="img" src={ImgPainel} alt="" />
          </Link>
          <h5>Painel</h5>
        </div>
      </div>

      <div className="box-stemplay">
        <div className="description">
          <Link to="/Stemplay/alunos">
            <img className="img" src={ImgAlunos} alt="" />
          </Link>
          <h5>Alunos</h5>
        </div>
      </div>

      <div className="box-stemplay">
        <div className="description">
          <Link to="/Stemplay/turmas">
            <img className="img" src={ImgTurmas} alt="" />
          </Link>
          <h5>Turmas</h5>
        </div>
      </div>
    </section>
  );
}

export default BoxStemplay;