import {
	IResponsavelFinanceiroCleanDTO,
	IResponsavelFinanceiroDTO,
	IResponsavelFinanceiroFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';

export const useResponsavelFinanceiroService = () => {
	const resourceURL = 'responsavel-financeiro';

	return {
		...baseFindById<
			IResponsavelFinanceiroCleanDTO,
			IResponsavelFinanceiroDTO,
			IResponsavelFinanceiroFullDTO
		>(resourceURL),
	};
};
