import useFetchMatriculaData from './hooks/use-fetch-matricula-data.hook';
import useNavigateToUpgrade from './hooks/use-navigate-to-upgrade.hook';
import useUpgradeIsAvailable from '../../hooks/use-upgrade-is-available.hook';

export default function useDetalheMatricula() {
	const { matriculaData } = useFetchMatriculaData();

	const { upgradeButtonVisible } = useUpgradeIsAvailable({});

	const { navigateToUpgrade } = useNavigateToUpgrade({
		matriculaData,
	});

	return {
		upgradeButtonVisible,
		navigateToUpgrade,
	};
}
