import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useUsuarioContext } from '../../context/UsuarioContext';

interface Props {
    current: number;
}

function StepsCreate({ current }: Props): ReactElement {

    const { revisao } = useUsuarioContext();

    return (
        <div className="menu-user">
            <h3>Criação de Usuário</h3>
            <Steps direction="vertical" current={revisao ? 4 : current}>
                <Steps.Step title="Dados Básicos" />
                <Steps.Step title="Função" />
                <Steps.Step title="Seleção de Franquia" />
                {/* <Steps.Step title="Controle" /> */}
                <Steps.Step title="Visão Academy" />
                <Steps.Step title="Revisão" />
            </Steps>
        </div>
    )
}

export default StepsCreate;
