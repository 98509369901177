import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IMetaDTO } from '../../models/happy-code-api.model';
import { useMetaService } from '../../services/meta.service';
import { useNotificationService } from '../../services/notification.service';

interface TItemLista {
    meta: IMetaDTO;
    refreshList: () => void;
}

const ItemListMeta = memo(({ meta, refreshList }: TItemLista) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const metaService = useMetaService();
    const [ativo, setAtivo] = useState<boolean>();

    useEffect(() => {
        setAtivo(meta.ativo);
    }, [meta.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a meta?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: meta?.metaTipo?.nome,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                metaService.patch({ id: meta.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Meta alterada com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const showDelete = () => {
        Modal.confirm({
            title: `Deseja realmente excluír a meta para todas as unidades?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: meta?.metaTipo?.nome,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                metaService.delete({ id: meta?.id })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Meta excluída com sucesso!', type: 'success', message: 'Sucesso' });
                        refreshList();
                    }).finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to={`/metas-unidade/${meta?.id}`}>
                    Atribuir valor na meta do Hub
                </Link>
            </Menu.Item>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
            <Menu.Item onClick={showDelete}>
                <a rel="noopener noreferrer">
                    Excluir
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <div className="nome">
                    <p>{meta?.metaTipo?.nome}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{meta?.periodicidade}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{meta?.exercicio}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "10%" }}></div>
            <div className="registro-opcao" style={{ width: "20%" }}>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListMeta.displayName = 'ItemListMeta';


export default ItemListMeta;
