import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalCreate from '../../components/ModalCreate/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { useNegocioContext } from '../../context/NegocioContext';
import { useNegocioService } from '../../services/negocio.service';
import './style.css';

export const MenuDetalheNegocio: React.FunctionComponent = () => {

  const { negocio, removeNegocio, setRevisao } = useNegocioContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);
  const negocioService = useNegocioService();

  const save = () => {
    setIsGlobalLoading(true)
    if (negocio.id) {
      negocioService.patchFull(negocio)
        .then(() => {
          removeNegocio();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    } else {
      negocioService.createFull(negocio)
        .then(() => {
          removeNegocio();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    }
  }

  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1">
        <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
        <h1 id="title">{negocio?.descricao}</h1>
        <div className="ativo">
          <div className="dot-verde"></div>
          <span>Ativo</span>
        </div>  
        <h6 style={{ marginBottom: 0 }}>Código</h6>
        <span>{negocio?.codigo}</span>      
        {negocio?.id ?
          <>
            <Link to={`/negocio-oferta/${negocio.id}`}>
              <Button id="salvar" style={{ width: 265, marginBottom: 5 }} className="button-primary">Alterar Oferta por Hub</Button>
            </Link>
            <Button id="salvar" style={{ width: 265 }} className="button-primary" onClick={() => save()}>Salvar</Button>
          </>
          : <Button id="salvar" style={{ width: 265 }} className="button-primary" onClick={() => save()}>Salvar</Button>}
      </div>
      <ModalCreate
        open={isOpen}
        //onClose={() => setIsOpen(false)}
        title="Negócio salvo com sucesso!"
        textButton1="Criar outro Negócio"
        textButton2="Voltar a página inicial"
        styleButton1="button-line"
        styleButton2="button-primary"
        link1="/negocio/create/step-1"
        link2="/negocio"
      />
    </div>
  );
}