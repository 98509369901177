import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

import './style.css';

const Dashboard: React.FunctionComponent = () => {
  return (
    <div className="container">
      <Header />
      <Menu selecionado="1" />
      <div className="dashboard">
        <div className="box-title">
          <h3 className="title">
            Franquias (80)
          </h3>
          <Link to="/franquias/create">
            <div className="adicionar">
              <div className="circle">
                <Unicons.UilPlus size="20" color="#FFFFFF" />
              </div>
              <span>Adicionar Franquia</span>
            </div>
          </Link>
        </div>
        <div className="filtros">
          <div className="input-text">
            <Unicons.UilSearch size="20" color="#6A7C96" />
            <input type="text" placeholder="Buscar Franquia" />
          </div>
          <div className="filtro-estado">
            <span>Estado</span>
            <select name="" id="">
              <option value="">Todos</option>
            </select>
            <Unicons.UilAngleDown size="20" color="#6A7C96" />
          </div>
          <div className="filtro-cidade">
            <span>Cidade</span>
            <select name="" id="">
              <option value="">Todos</option>
            </select>
            <Unicons.UilAngleDown size="20" color="#6A7C96" />
          </div>
          <div className="filtro-estado">
            <span>Status</span>
            <select name="" id="">
              <option value="">Todos</option>
            </select>
            <Unicons.UilAngleDown size="20" color="#6A7C96" />
          </div>
        </div>
        <div className="tabela">
          <div className="titles">
            <div className="item" style={{ width: "300px" }}>
              <span>NOME DA FRANQUIA</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "70px" }}>

            </div>
            <div className="item" style={{ width: "150px" }}>
              <span>CODIGO</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "200px" }}>
              <span>ESTADO</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: "150px" }}>
              <span>GESTOR</span>
              <div className="arrows">
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
          </div>
          <div className="tabela-body">
            <div className="registros">
              <div className="registros-body-detalhes">
                <div className="row-1">
                  <div className="registros-row">
                    <div className="registro" style={{ width: "300px" }}>
                      <span>Happy Code Perdizes</span>
                      <div className="estado">
                        <div className="dot"></div>
                        <span>Em Operação</span>
                      </div>
                    </div>
                    <div className="registro" style={{ width: "70px" }}>
                      <Unicons.UilExclamationTriangle size="20" color="#F59300" />
                    </div>
                    <div className="registro" style={{ width: "150px" }}>
                      <span>#0001</span>
                    </div>
                    <div className="registro" style={{ width: "200px" }}>
                      <span>São Paulo</span>
                    </div>
                    <div className="registro" style={{ width: "300px" }}>
                      <span className="gestor">Jose da Silva</span>
                    </div>
                    <div className="registro" style={{ width: "50px" }}>
                      <Unicons.UilEdit size="20" color="#92A9CB" />
                    </div>
                    <div className="registro" style={{ width: "50px" }}>
                      <Unicons.UilEllipsisH size="20" color="#92A9CB" />
                    </div>
                  </div>
                  <div className="registro-opcao">
                    <Link to="/franquias">
                      <div className="circle">
                        <Unicons.UilAngleDown size="20" color="#6A7C96" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row-2">
                  <div className="dados">
                    <h6>CNPJ</h6>
                    <span>31.194.918/0001-42</span>
                    <br />
                    <h6>Endereço</h6>
                    <span>R. Bueno de Andrade, 841 - Aclimação, São Paulo - SP, 01526-000 - Brasil</span>
                  </div>
                  <div className="line"></div>
                  <div className="modelos">
                    <h6>MODELO UNIDADES</h6>
                    <span>Fit</span>
                  </div>
                  <div className="line"></div>
                  <div className="negocios">
                    <h6>Negócios da unidade (3)</h6>
                    <span>Happy Code</span>
                    <span>Happy Speech</span>
                    <span>Happy Bussiness</span>
                  </div>
                </div>
                <Link to="">
                  <button>Ver mas detalhes</button>
                </Link>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado">
                      <div className="dot"></div>
                      <span>Em Operação</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                    <Unicons.UilExclamationTriangle size="20" color="#F59300" />
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Maria Silva Oliveira Gonçalves</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado-amarelo">
                      <div className="dot"></div>
                      <span>Implantação</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Jose da Silva</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado">
                      <div className="dot"></div>
                      <span>Em Operação</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Jose da Silva</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado-vermelho">
                      <div className="dot"></div>
                      <span>Fechada</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Jose da Silva</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado-cinza">
                      <div className="dot"></div>
                      <span>Suspensa</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                    <Unicons.UilExclamationTriangle size="20" color="#F59300" />
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Jose da Silva</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado">
                      <div className="dot"></div>
                      <span>Em Operação</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Jose da Silva</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="registros-body">
                <div className="registros-row">
                  <div className="registro" style={{ width: "300px" }}>
                    <span>Happy Code Perdizes</span>
                    <div className="estado-cinza">
                      <div className="dot"></div>
                      <span>Suspensa</span>
                    </div>
                  </div>
                  <div className="registro" style={{ width: "70px" }}>
                  </div>
                  <div className="registro" style={{ width: "150px" }}>
                    <span>#0001</span>
                  </div>
                  <div className="registro" style={{ width: "200px" }}>
                    <span>São Paulo</span>
                  </div>
                  <div className="registro" style={{ width: "250px" }}>
                    <span className="gestor">Jose da Silva</span>
                  </div>
                </div>
                <div className="registro-opcao">
                  <div className="circle">
                    <Unicons.UilAngleDown size="20" color="#6A7C96" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;