import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu } from 'antd';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICurriculoCleanDTO, ICurriculoDTO, ICurriculoFullDTO, IRecursoCleanDTO, IRecursoDTO } from "../../models/happy-code-api.model";
import { useCurriculoService } from '../../services/curriculo.service';

interface TItemLista {
    recurso: IRecursoDTO;
    handleRecurso: (curriculo: IRecursoCleanDTO, state: boolean) => void;
}

const ItemListRecurso = memo(({ recurso, handleRecurso }: TItemLista) => {

    const curriculoService = useCurriculoService();    
    const { setIsGlobalLoading } = useGlobalContext();

    const handleMenuItem = (recurso: IRecursoCleanDTO) => {
        if (recurso.ativo) {
            return (
                <Menu.Item onClick={() => handleRecurso(recurso, false)}>
                    <a rel="noopener noreferrer">
                        Inativar recurso
                    </a>
                </Menu.Item>
            )
        }
        if (!recurso.ativo) {
            return (
                <Menu.Item onClick={() => handleRecurso(recurso, true)}>
                    <a rel="noopener noreferrer">
                        Ativar recurso
                    </a>
                </Menu.Item>
            )
        }
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            {handleMenuItem(recurso)}
        </Menu>
    );
    const history = useHistory();

    

    const goTo = () => {
        history.push(`/recurso/create/step-1/${recurso.id}`)
    }

    const handleStatus = (curriculo) => {       

        if (curriculo.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Ativo</p>
                </div>
            )
        }

        if (!curriculo.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className="standby">Inativo</p>
                </div>
            )
        }
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '30%' }}>
                <div className="nome">
                    <p>{recurso.descricaoRecurso}</p>
                    {handleStatus(recurso)}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '100%' }}>
                <span>{recurso.id}</span>
            </div>
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListRecurso.displayName = 'ItemListRecurso';

export default ItemListRecurso;