/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { IUsuarioDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useEnotasService } from '../../services/enotas.service';
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioService } from '../../services/usuario.service';
import { formatMomentLocalDateTime } from '../../util/format';

interface TInputs {
    open: boolean;
    onClose: () => void;
}

interface SendNfsXml {
    to: string;
    cc: string;
    dataInicio: Date;
    dataFim: Date;
}

const { RangePicker } = DatePicker;

export const ModalEnvioNfsXml: React.FunctionComponent<TInputs> = ({ open, onClose }: TInputs) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const enotasService = useEnotasService();
    const usuarioService = useUsuarioService();
    const [sendNfs, setSendNfs] = useState<SendNfsXml>();
    const [usuarios, setUsuarios] = useState<IUsuarioDTO[]>();

    const findUsuario = () => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        return usuarioService.findList(predicate).then(({ data }) => {
            setUsuarios(data);
        })
    };

    const cleanFilds = () => {
        setSendNfs({
            ...sendNfs,
            cc: null,
            to: null,
            dataInicio: null,
            dataFim: null
        });
    }

    const closeModal = () => {
        cleanFilds();
        onClose();
    }

    const executionRotinaEnvioNfsXml = () => {
        setIsGlobalLoading(true);
        enotasService.findRotinaEnvioEmailNfsXml(
            sendNfs?.to,
            sendNfs?.cc,
            sendNfs?.dataInicio,
            sendNfs?.dataFim
        )
            .then(() => {
                notification({
                    description: `Rotina executada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso',
                });
                setIsGlobalLoading(false)
                closeModal();
            }).catch((err) => {
                notification({
                    description: `Ocorreu um erro: ${err}`,
                    type: "error",
                    message: "Erro!"
                });
                setIsGlobalLoading(false)
            });
    }

    const submitValidationFields = () => {
        if (!sendNfs?.to) {
            return notification({
                description: `O campo "Destinatario" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }
        if (!sendNfs?.dataInicio && !sendNfs?.dataFim) {
            return notification({
                description: `O campo "data" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        executionRotinaEnvioNfsXml();
    }

    const getPeriodo = (): any => {
        if (sendNfs?.dataInicio && sendNfs?.dataFim) {
            return [moment(sendNfs?.dataInicio), moment(sendNfs?.dataFim)];
        }
        return null;
    };

    useEffect(() => {
        findUsuario();
    }, []);

    if (!open) return null;

    return (
        <div className="modalContainer">
            <div className="modal-box-upload-material" style={{ maxHeight: 500 }}>
                <button onClick={closeModal} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
                <h3>Envio de NFs</h3>
                <div className="line" />
                <div style={{ maxHeight: 350, width: "100%", margin: "auto", padding: 20 }}>
                    <div className="input-box" id="box-aluno">
                        <label htmlFor="to">Destinatário</label>
                        <Select
                            id="to"
                            placeholder="Selecione"
                            style={{ width: '100%' }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            defaultValue={sendNfs?.to}
                            onChange={(e) => setSendNfs({ ...sendNfs, to: e })}
                            filterOption={(input, option) =>
                                option?.text?.toLowerCase().includes(input.toLowerCase())}
                        >
                            {usuarios && usuarios.map(usuario =>
                                <Select.Option key={usuario.id} value={usuario?.login} text={usuario?.login}>{usuario?.login}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <div className="input-box" id="box-aluno">
                        <label htmlFor="cc">Cópia</label>
                        <Select
                            id="cc"
                            placeholder="Selecione"
                            style={{ width: '100%' }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            defaultValue={sendNfs?.cc}
                            onChange={(e) => setSendNfs({ ...sendNfs, cc: e })}
                            filterOption={(input, option) =>
                                option?.text?.toLowerCase().includes(input.toLowerCase())}
                        >
                            {usuarios && usuarios.map(usuario =>
                                <Select.Option key={usuario.id} value={usuario?.login} text={usuario?.login}>{usuario?.login}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <div className="input-box" id="box-aluno">
                        <label htmlFor="data">Período</label>
                        <RangePicker
                            id="data"
                            style={{ width: "100%", marginLeft: 10 }}
                            value={getPeriodo()}
                            onChange={(e) => setSendNfs({ ...sendNfs, dataInicio: formatMomentLocalDateTime(e[0]), dataFim: formatMomentLocalDateTime(e[1]) })}
                        />
                    </div>
                </div>

                <div className="line" />
                <div className="botoes">
                    <button onClick={closeModal} className="btn-cancel" id="cancelar">Cancelar</button>
                    <button onClick={submitValidationFields} className="btn-make" id="criar-usuario">Enviar</button>
                </div>
            </div>
        </div >
    );
}
