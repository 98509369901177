import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import { useTaxaFixaContext } from '../../context/TaxaContext';

import { ILogCleanDTO, ITaxaFixaDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useTaxaFixaService } from '../../services/taxa-fixa.service';
import FiltrosTaxa from './filtros-taxa';
import ItemLista from './item-lista';
import './style.css';
import { useLogService } from '../../services/log.service';

const Taxas: React.FunctionComponent = () => {
  const [data, setData] = useState<PageableResponse<ITaxaFixaDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const taxaFixaService = useTaxaFixaService();
  const { setIsGlobalLoading } = useGlobalContext();
  const { removeTaxaFixa, setRevisao } = useTaxaFixaContext();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso taxa fixa",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    logService.registraLog(log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory();

  const findTaxaFixa = () => {
    setIsGlobalLoading(true);
    taxaFixaService.find(predicate)
      .then(({ data }) => {
        setData(data);
      }).finally(() => setIsGlobalLoading(false));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findTaxaFixa();
  }

  useEffect(() => {
    removeTaxaFixa();
    setRevisao(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToAdd = () => {
    history.push("/taxas/create/step-1");
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findTaxaFixa();
  }

  return (
    <div className="container">
      <NewMenu selecionado={8} />
      <div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
        <div className="box-title">
          <h3 className="title">Taxas fixas ({data?.totalElements})</h3>
          <Button id="criar-taxa" className="button-primary" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Taxa</Button>
        </div>
        <FiltrosTaxa findTaxaFixa={findTaxaFixa} predicate={predicate} />
        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '25%' }}>
              <span>NOME</span>
              <div className="arrows" onClick={() => ordenar('codigo')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>VALOR</span>
              <div className="arrows" onClick={() => ordenar('valor')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>PERIODO</span>
              <div className="arrows" onClick={() => ordenar('dataInicial')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '50%' }}>
              <span>DESCRIÇÃO</span>
              <div className="arrows" onClick={() => ordenar('descricao')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '5%' }}></div>
          </div>

          <div className="tabela-body">
            {data && data.content.map((taxa, index) => (
              <ItemLista taxa={taxa} key={index} />
            ))}
          </div>
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Taxas;