import {
	IGrupoContratoArquivoCleanDTO,
	IGrupoContratoArquivoDTO,
	IGrupoContratoArquivoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseCreate } from './base/base-create.service';
import { baseUpdate } from './base/base-update.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';

export const useGrupoContratoArquivoService = () => {
	const resourceURL = 'grupo-contrato-arquivo';

	return {
		...baseFindList<IGrupoContratoArquivoCleanDTO, IGrupoContratoArquivoDTO, IGrupoContratoArquivoFullDTO>(resourceURL),
		...baseCreate<IGrupoContratoArquivoCleanDTO, IGrupoContratoArquivoDTO, IGrupoContratoArquivoFullDTO>(resourceURL),
		...baseUpdate<IGrupoContratoArquivoCleanDTO, IGrupoContratoArquivoDTO, IGrupoContratoArquivoFullDTO>(resourceURL),
		...baseFindById<IGrupoContratoArquivoCleanDTO, IGrupoContratoArquivoDTO, IGrupoContratoArquivoFullDTO>(resourceURL),
		...baseDelete(resourceURL),
	};
};
