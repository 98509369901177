import * as Unicons from '@iconscout/react-unicons';
import { Tooltip } from 'antd';
import { IRenegociacaoCleanDTO } from '../../models/happy-code-api.model';
import './style.css';

interface Tag {
	codigo: string;
	descricao: string;
	tooltip?: string;
	renegociacaoOriginada?: IRenegociacaoCleanDTO;
	observacao?: string;
};

export const TagsFinanceiro: React.FunctionComponent<Tag> = ({ codigo, descricao, tooltip, renegociacaoOriginada, observacao }: Tag) => {

	const DivTooltip = (tooltip, observacao) => {
		return (
			<div>
				{tooltip} <br/>
				{observacao ? observacao : ''}
			</div>);
	}

	const DivTooltipRenegociacao = () => {
		const tooltip = "Parcela Renegociada";
		return (<div>{tooltip}</div>);
	}

	const Descricao = () => {

		return (<>
			<span>{descricao}</span>
			{tooltip
				&& <>
					<Tooltip overlay={DivTooltip(tooltip, observacao)} placement="top" >
						<span><Unicons.UilQuestionCircle size="18" /></span>
					</Tooltip>
					<br />
				</>
			}
		</>)
	}

	const DescricaoRenegociacao = () => {

		return (<>
			<span>{descricao}</span>
			<>
				<Tooltip overlay={DivTooltipRenegociacao} placement="top" >
					<span><Unicons.UilQuestionCircle size="18" /></span>
				</Tooltip>
				<br />
			</>
		</>)
	}

	if (renegociacaoOriginada && codigo === 'AGR') {
		return (
			<div className="tag-container-colaborador">
				<Unicons.UilClockNine size="14" color="#CF6D34" />
				{DescricaoRenegociacao()}
			</div>
		);
	}

	if (codigo === 'CAN' || codigo === 'VENCIDO') {
		return (
			<div className="tag-container-comercial">
				<Unicons.UilExclamationTriangle size="14" color="#CF6B6C" />
				<span>{descricao}</span>
			</div>
		);
	}

	if (codigo === 'PGO') {
		return (
			<div className="tag-container-sede">
				<Unicons.UilCalendarAlt size="14" color="#478953" />
				<span>{descricao}</span>
			</div>
		);
	}

	if (codigo === 'PGOATRASO') {
		return (
			<div className="tag-container-sede">
				<Unicons.UilCheck size="14" color="#478953" />
				<span>{descricao}</span>
			</div>
		);
	}

	if (codigo === 'GRD' || codigo === 'AGR') {
		return (
			<div className="tag-container-colaborador">
				<Unicons.UilClockNine size="14" color="#CF6D34" />
				<span>{descricao}</span>
			</div>
		);
	}

	if (codigo === 'FLH') {
		return (
			<div className="tag-container-comercial">
				<Unicons.UilTimesSquare size="14" color="#CF6B6C" />
				{Descricao()}
			</div>
		);
	}

	return (
		<div className="tag-container-gestor">
			<Unicons.UilDesktop size="14" color="#895CF2" />
			{Descricao()}
		</div>
	);

};
