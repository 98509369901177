import { useMemo, useState } from 'react';
import { ICobrancaPagarMeDTO } from '../../../models/happy-code-api.model';

const useCheckoutEscolaState = () => {
	const tiposPagamento = useMemo(
		() => ({
			CRC: {
				idPagamento: 'CRC',
				dsPagamento: 'Cartão de Crédito',
			},
			CRD: {
				idPagamento: 'CRD',
				dsPagamento: 'Cartão de Débito',
			},
			BOL: {
				idPagamento: 'BOL',
				dsPagamento: 'Boleto',
			},
			CRR: {
				idPagamento: 'CRR',
				dsPagamento: 'Crédito Recorrente',
			},
			PIX: {
				idPagamento: 'PIX',
				dsPagamento: 'PIX',
			},
		}),
		[]
	);

	const [cobranca, setCobranca] = useState<ICobrancaPagarMeDTO>();

	const isRecorrente = useMemo(
		() => cobranca?.tipoPagamento?.codigo === tiposPagamento.CRR.idPagamento,
		[cobranca?.tipoPagamento?.codigo, tiposPagamento]
	);

	const isCartaoCredito = useMemo(
		() => cobranca?.tipoPagamento?.codigo === tiposPagamento.CRC.idPagamento,
		[cobranca?.tipoPagamento?.codigo, tiposPagamento]
	);

	return {
		tiposPagamento,
		cobranca,
		setCobranca,
		isRecorrente,
		isCartaoCredito,
	};
};

export default useCheckoutEscolaState;
