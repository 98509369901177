import {
	IPlanoPagamentoCleanDTO,
	IPlanoPagamentoDTO,
	IPlanoPagamentoFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const usePlanoPagamentoService = () => {
	const resourceURL = 'plano-pagamento';

	return {
		...baseFind<
			IPlanoPagamentoCleanDTO,
			IPlanoPagamentoDTO,
			IPlanoPagamentoFullDTO
		>(resourceURL),
		...baseFindList<
			IPlanoPagamentoCleanDTO,
			IPlanoPagamentoDTO,
			IPlanoPagamentoFullDTO
		>(resourceURL),
		...baseFindById<
			IPlanoPagamentoCleanDTO,
			IPlanoPagamentoDTO,
			IPlanoPagamentoFullDTO
		>(resourceURL),
	};
};
