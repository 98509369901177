import {
    ILeadHistoricoCleanDTO,
    ILeadHistoricoDTO,
    ILeadHistoricoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadHistoricoService = () => {
	const resourceURL = 'lead-historico';

	return {
		...baseFindList<ILeadHistoricoCleanDTO, ILeadHistoricoDTO, ILeadHistoricoFullDTO>(resourceURL),
	};
};
