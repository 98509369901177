import './modal.css';
import icon from './information-icon.png';


const ModalCustom = () => {
	const redirect = () => {
		window.close();
		window.open('https://happy.com.br', '_blank');
	};

	return (
		<div className="background">
			<div className="modal">
				<div className="modal-box-text">
					<img
						className="informative-icon"
						src={icon}
						alt="icone de informação"
					/>
					<h1>Informativo</h1>
					<p>
						Indentificamos que a cobrança ja está paga.<br></br>Obrigado!
					</p>
				</div>
				<div className="modal-box-btn">
					<button onClick={redirect}>OK</button>
				</div>
			</div>
		</div>
	);
};

export default ModalCustom;
