import * as Unicons from '@iconscout/react-unicons';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTaxaFixaContext } from '../../context/TaxaContext';
import { ITaxaFixaCleanDTO } from "../../models/happy-code-api.model";
import { useTaxaFixaService } from '../../services/taxa-fixa.service';
import { formatMoney, formattedDate, isDateBetween } from "../../util/format";

interface TItemLista {
    taxa: ITaxaFixaCleanDTO
}

const ItemList = memo(({ taxa }: TItemLista) => {
    // const menu = (
    //     <Menu>
    //         {/* <Menu.Item>
    //             <a rel="noopener noreferrer">
    //                 Editar
    //             </a>
    //         </Menu.Item> */}
    //         <Menu.Item>
    //             <a rel="noopener noreferrer">
    //                 Finalizar Taxa
    //             </a>
    //         </Menu.Item>
    //     </Menu>
    // );
    const history = useHistory();

    const taxaFixaService = useTaxaFixaService();
    const { setIsGlobalLoading } = useGlobalContext();

    const { setTaxaFixa } = useTaxaFixaContext();

    const goTo = () => {
        setIsGlobalLoading(true);
        taxaFixaService.findFullById(taxa)
            .then(({ data }) => setTaxaFixa(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/taxas/create/step-6/${taxa.id}`)
            });
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="taxas" style={{ width: '25%' }}>
                <div className="nome">
                    <p>{taxa.codigo}</p>
                    {isDateBetween(taxa.dataInicial, taxa.dataFinal) &&
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Em vigência</p>
                        </div>
                    }
                </div>
            </div>
            <div className="funcao-user" id="taxas" style={{ width: '10%' }}>
                <span>{formatMoney(taxa.valor)}</span>
            </div>
            <div className="funcao-user" id="taxas" style={{ width: '15%' }}>
                <span>{formattedDate(taxa.dataInicial)} - {formattedDate(taxa.dataFinal)}</span>
            </div>
            <div className="funcao-user" style={{ width: '50%' }}>
                <p>{taxa.descricao}</p>
            </div>
            {/* <div className="registro" style={{ width: "50px" }}>
                  <Unicons.UilEdit size="20" color="#92A9CB" />
                </div>
                <div className="registro" style={{ width: "50px" }}>
                  <Unicons.UilEllipsisH size="20" color="#92A9CB" />
                </div> */}
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                {/* <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown> */}
            </div>
        </div>
    )
})

ItemList.displayName = 'ItemList';

export default ItemList;