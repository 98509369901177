/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from '@material-ui/lab';
import { Button, Row } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import { ModalUser } from '../../../components/ModalUser/index';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Operators } from '../../../enum/operators.enum';
import { IPessoaCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { usePessoaService } from '../../../services/pessoa.service';
import '../styles/escola-create-step.styles.scss';
import ColPessoa from '../../../components/ColPessoa';
import { EscolaPessoaTipoEnum } from '../../../enum/escola-pessoa-tipo.enum';
import { PerfilEnum } from '../../../enum/perfil.enum';

export const EscolaCreateResponsavelScreen: React.FunctionComponent = () => {
  const { escolaParaCadastrar, setEscolaParaCadastrar, escolaPessoaTipos } = useEscolaContext();
  const { setIsGlobalLoading, usuario, unidade } = useGlobalContext();
  const params = useParams<any>();
  const [inserindoUsuario, setInserindoUsuario] = useState<boolean>(false);
  const [usuarioEmEdicao, setUsuarioEmEdicao] = useState<IPessoaCleanDTO>(null);
  const [listPessoa, setListPessoa] = useState<PageableResponse<IPessoaCleanDTO>>();
  const [selected, setSelected] = useState<IPessoaCleanDTO>();
  const [predicate] = useState<Predicate>(new Predicate());
  const [nome, setNome] = useState<string>();
  const [confirmado, setConfirmado] = useState<boolean>(false);
  const history = useHistory();
  const pessoaService = usePessoaService();

  const findPessoa = () => {
    setIsGlobalLoading(true);
    if (usuario.perfil === PerfilEnum.FRANQUIA && unidade.id) {
      predicate.addOption('unidadePessoas.unidade.id', unidade.id);
    }
    return pessoaService.find(predicate)
      .then(({ data }) => {
        setListPessoa(data);
      }).finally(() => setIsGlobalLoading(false))
  }

  useEffect(() => {
    findPessoa();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario, unidade]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (nome) {
        setIsGlobalLoading(true);
        predicate.addOption('nome', nome, Operators.CONTAINS);
        findPessoa().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  const onConfirmar = () => {
    if (params.tipo === EscolaPessoaTipoEnum.FOCAL_FINANCEIRO)
      escolaParaCadastrar.focalFinanceiro = selected as any;
    else if (params.tipo === EscolaPessoaTipoEnum.FOCAL_OPERACIONAL)
      escolaParaCadastrar.focalOperacional = selected as any;
    else {

      const tipo = escolaPessoaTipos.find((item) => item.codigo === params.tipo);

      const index = escolaParaCadastrar.pessoas?.findIndex?.(
        (item) => item.tipo.codigo === tipo.codigo);

      if (index >= 0)

        escolaParaCadastrar.pessoas[index] = {
          id: escolaParaCadastrar.pessoas[index]?.id,
          pessoa: selected as any,
          tipo,
        }

      else {

        if (!escolaParaCadastrar.pessoas)
          escolaParaCadastrar.pessoas = [];

        escolaParaCadastrar.pessoas.push({ pessoa: selected as any, tipo });
      }
    }
    setEscolaParaCadastrar({ ...escolaParaCadastrar });
    setConfirmado(true);
  }

  useEffect(() => {

    if (confirmado)
      history.push("/escolas/create/step-3");

  }, [confirmado]);

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    setIsGlobalLoading(true);
    predicate.setPage(page);
    findPessoa().finally(() => setIsGlobalLoading(false));
  }

  const descricaoParams = () => {
    if (params.tipo === EscolaPessoaTipoEnum.FOCAL_FINANCEIRO) {
      return "responsável financeiro"
    }
    if (params.tipo === EscolaPessoaTipoEnum.FOCAL_OPERACIONAL) {
      return "responsável operacional"
    }
    if (params.tipo === EscolaPessoaTipoEnum.RESPONSAVEL_LEGAL) {
      return "responsável legal"
    }
    if (params.tipo === EscolaPessoaTipoEnum.RESPONSAVEL_NEGOCIACAO) {
      return "responsável negociação"
    }
  }

  const edicaoUsuario = (usuarioEmEdicao) => {

    if (usuarioEmEdicao)

      return (
        <ModalUser
          key={new Date().getTime()}
          open={usuarioEmEdicao != null}
          onClose={
            () => {
              setUsuarioEmEdicao(null);
              findPessoa();
            }
          }
          pessoa={usuarioEmEdicao}
          modoLGPD />
      );

    else

      return null;

  }

  return (
    <div className="container">
      <Header />
      <Progress />
      <div className="franquia-responsavel" style={{ marginTop: 60 }}>
        <div className="franquia-row">
          <h3>Selecione o {descricaoParams()} da escola</h3>
          <span>Caso o responsável legal dessa unidade não possua um usuário, crie no botão abaixo</span>
          <Button
            onClick={() => setInserindoUsuario(true)}
            icon={<Unicons.UilPlus size="16" color="var(--primary-color" />}
            className="button-second"
            id="criar-usuario"
            style={{ width: 700, marginTop: 25, padding: '16px 24px' }}
          >
            Criar novo Usuário
          </Button>
          <div className="line"></div>
          <h5>Responsáveis legais já cadastrados na Happy Code</h5>
          <div className="busca">
            <Unicons.UilSearch size="20" color="#6A7C96" />

            <input
              onChange={(event) => setNome(event.target.value)}
              type="text"
              id="busca"
              placeholder="Buscar Usuário" />

          </div>
          <br />
          <div className="matriculas" style={{ width: '100%' }}>
            <Row className='turmas'>

              {listPessoa
                &&
                listPessoa.content.map(
                  (pessoa, index) => (

                    <ColPessoa
                      selected={selected}
                      setSelected={setSelected}
                      pessoa={pessoa}
                      key={index}
                      setUsuarioEmEdicao={setUsuarioEmEdicao} />
                  )
                )
              }

            </Row>
          </div>
          <div className="paginacao" style={{ width: '100%' }}>

            <Pagination
              onChange={(event, page) => onPageChange(event, page - 1)}
              page={listPessoa ? listPessoa.pageable.pageNumber + 1 : 0}
              count={listPessoa ? listPessoa.totalPages : 0} />

          </div>
          <div className="botoes">
            <Link to="/escolas/create/step-3">
              <Button className="button-second" id="cancelar" style={{ width: 345 }}>
                Cancelar
              </Button>
            </Link>

            <button disabled={!selected} onClick={() => onConfirmar()} className="button-primary" id="confirmar" style={{ width: 345 }}>
              Confirmar
            </button>

          </div>
        </div>
      </div>
      <Link to="/escolas/create/step-3">
        <div className="botao-voltar">
          <Unicons.UilArrowLeft size="20" color="#895CF2" />
          <span>Voltar</span>
        </div>
      </Link>

      {inserindoUsuario
        && <ModalUser open={inserindoUsuario} onClose={() => setInserindoUsuario(false)} modoLGPD />
      }

      {edicaoUsuario(usuarioEmEdicao)}


    </div>
  )
}
