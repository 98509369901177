import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import { Predicate } from '../../models/predicate.model';

interface TFiltrosCurriculo {
    predicate: Predicate;
    findHoraSede: () => void;
}
const FiltroHoraSede = memo(({ predicate, findHoraSede }: TFiltrosCurriculo) => {

    const [descricao, setDescricao] = useState<string>();
    const [status, setStatus] = useState<string>('Todos');
    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('descricao', descricao, Operators.CONTAINS);
            }
            if (status != 'Todos') {
                predicate.addOption('ativo', status);
            }

            findHoraSede();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, status]);

    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '90%' }}>
                <Input id="buscar" value={descricao} placeholder="Buscar hora" prefix={<SearchOutlined rev={undefined} style={{ color: '#6A7C96' }} />} onChange={e => setDescricao(e.target.value)} />
            </div>
            <div className="filtro-estado" id="input-dashboard">
                <span>Status</span>
                <Select className="ant-dropdown-link" defaultValue="Todos" style={{ width: 120 }} onChange={e => setStatus(e)}>
                    <Select.Option value="Todos">Todos</Select.Option>
                    <Select.Option value="true">Ativo</Select.Option>
                    <Select.Option value="false">Inativo</Select.Option>

                </Select>
            </div>
        </div>
    </>
    )
})

FiltroHoraSede.displayName = 'FiltroHoraSede'

export default FiltroHoraSede;