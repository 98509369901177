import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import { Theme, withStyles } from '@material-ui/core/styles';
import { ExpandMore, SearchOutlined } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import NewMenu from "../../components/NewMenu";
import { ILogCleanDTO } from "../../models/happy-code-api.model";
import { useLogService } from "../../services/log.service";
import { faqData } from "./faqB2b-data";
import "./style.css";

const FaqB2B = () => {
    const [search, setSearch] = useState<string>("");
    const [expanded, setExpanded] = useState<string | false>('');

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso tela relatorios franquia",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const Accordion = withStyles({
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
        root: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme: Theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiAccordionDetails);

    const handleChange = (panel: string) => (e: ChangeEvent<{ string, unknown }>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className="container">
            <NewMenu selecionado={77} />
            <div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
                <div className="box-title">
                    <h3 className="title">FAQ Escolas</h3>
                </div>
                <div className="box-faq">
                    <div>
                        <h3 style={{ textAlign: "center", fontSize: "2em" }}>Perguntas Frequentes</h3>
                    </div>
                    <div className="box-faq-search">
                        <Input
                            className="input-faq-search"
                            type="text"
                            placeholder="Pesquisar"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            endAdornment={<SearchOutlined style={{ color: '#6A7C96', fontSize: 20 }} />} />
                    </div>
                    <div>
                        {faqData.filter((faq) => search === "" ? true : faq.questao.toLowerCase().includes(search.toLowerCase()))
                            .map((faq, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expanded === `panel${index}`}
                                    onChange={handleChange(`panel${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}bh-content`}
                                        id={`panel${index}bh-header`}>
                                        <Typography>{faq.questao}</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Typography className="faq-image">{faq.resposta}</Typography>
                                    </AccordionDetails>
                                    <AccordionDetails className="faq-content" style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography align="center">{faq?.imagem}</Typography>
                                        {faq && faq?.body?.map((body, index) =>
                                            <div key={index}>
                                                <Typography align="center">{body?.imagem}</Typography>
                                                <Typography align="left" className="faq-image">{body?.texto}</Typography>
                                            </div>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqB2B;
