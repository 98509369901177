import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Progress, Radio, RadioChangeEvent, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { INegocioCleanDTO, ITipoCursoCleanDTO } from '../../models/happy-code-api.model';
import { useNegocioService } from '../../services/negocio.service';
import { useTipoCursoService } from '../../services/tipo-curso.service';
import StepsCreate from './steps-create';
import './style.css';

export const CursosCreateStep1: React.FunctionComponent = () => {

  const { setIsGlobalLoading } = useGlobalContext();
  const { curso, setCurso, revisao } = useCursoContext();

  const [isOpen, setIsOpen] = useState(false);

  const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([])
  const [tiposCursos, setTiposCursos] = useState<ITipoCursoCleanDTO[]>([])

  const negocioService = useNegocioService();
  const tipoCursoService = useTipoCursoService();

  useEffect(() => {
    setIsGlobalLoading(true)
    negocioService.findCleanList()
      .then(({ data }) => setNegocios(data));
    tipoCursoService.findCleanList()
      .then(({ data }) => setTiposCursos(data))
      .finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeNegocio = (e: RadioChangeEvent) => {
    const negocio = e.target.value;
    setCurso({ ...curso, negocio });
  };

  const onChangeTipoCurso = e => {
    const tipoCurso = e.target.value;
    setCurso({ ...curso, tipoCurso });
  };

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={20} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">
        <div className="user-row">
          <h3 id="taxas-h3">Insira o nome e detalhes do curso</h3>
          {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
          <div className="input-box">
            <p>Nome do Curso</p>
            <Input value={curso.descricao} placeholder="Nome do Curso" id="nome" style={{ width: '100%' }} onChange={e => setCurso({ ...curso, descricao: e.target.value })} />
          </div>
          <div id="cursos-boxes">
            <div id="curso-box">
              <h4>Quantidade de aulas</h4>
              <InputNumber id="quantia-aulas" value={curso.duracao} onChange={e => setCurso({ ...curso, duracao: e })} style={{ width: '100%' }} />
            </div>
            <div id="curso-box">
              <h4>Duração em minutos</h4>
              <Input id="duracao" addonAfter={<ClockCircleOutlined rev={undefined}/>} defaultValue={curso.duracaoMinutos} onChange={e => setCurso({ ...curso, duracaoMinutos: parseInt(e.target.value) })} />
            </div>
          </div>
          <h4>Negócio</h4>
          <Radio.Group onChange={e => onChangeNegocio(e)} value={curso.negocio}>
            <div id="cursos-check-dash">
              {negocios.map(negocio => (
                <div id="boxes-radios-cursos" key={negocio.id}>
                  <div id={curso.negocio.id === negocio.id ? "box-radio-selected" : "box-radio"} style={{ width: '100%' }}>
                    <div id="check">
                      <Radio id={"negocio-" + negocio.id} value={negocio} key={negocio.descricao}>{negocio.descricao}</Radio>
                    </div>
                    {/* <p>{negocio.descricao}</p> */}
                  </div>
                </div>))}
            </div>
          </Radio.Group>
          <h4>Tipo</h4>
          <Radio.Group onChange={onChangeTipoCurso} value={curso.tipoCurso}>
            <div id="cursos-check-dash">
              {tiposCursos.map(tipoCurso => (
                <div id="boxes-radios-cursos" key={tipoCurso.id}>
                  <div id={curso.tipoCurso.id === tipoCurso.id ? "box-radio-selected" : "box-radio"} style={{ width: '100%' }}>
                    <div id="check">
                      <Radio id={"tipo-" + tipoCurso.id} value={tipoCurso} name={tipoCurso.descricao}>{tipoCurso.descricao}</Radio>
                    </div>
                    {/* <p>{tipoCurso.descricao}</p> */}
                  </div>
                </div>))}
            </div>
          </Radio.Group>
          <div className="botoes">
            {revisao ?
              <Link to="/cursos/create/step-6">
                <Button type="primary" id="revisao" className="button-primary" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/cursos">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/cursos/create/step-2">
                  <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação deste curso?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/cursos'
      />
    </div>
  )

}