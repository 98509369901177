import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import Header from '../../components/Header';
import MenuDetalheColaborador from '../../components/MenuDetalheColaborador';
import { Tags } from '../../components/Tags/index';
import { useColaboradorContext } from '../../context/ColaboradorContext';
import { ContatoEnum } from '../../enum/contato.enum';

import { formattedDate } from '../../util/format';
import './style.css';

export const ColaboradoresCreateStep4: React.FunctionComponent = () => {
	const { colaborador, setRevisao } = useColaboradorContext();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { id } = useParams<any>();

	const history = useHistory();

	const goTo = (url) => history.push(url);

	useEffect(() => {
		setRevisao(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="container-fondo">
			<Header />
			<MenuDetalheColaborador id={id || colaborador.id} />
			<div className="franquia" style={{ backgroundColor: '#F5F5F9', marginTop: 60 }}>
				<div className="franquia-row">
					<h5>Revisão de Dados</h5>
					<div className="row-revisao">
						<ul className="menu-revisao-detalhe">
							<li className="selected" style={{ width: '34%' }}>
								<a href="#dadosBasicos">Dados Básicos</a>
							</li>
							<li style={{ width: '33%' }}>
								<a href="#funcao">Função</a>
							</li>
							<li style={{ width: '33%' }}>
								<a href="#contato">Contato</a>
							</li>
						</ul>
						<div id="dadosBasicos" className="box-revisao">
							<div className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/colaboradores/create/step-1')}
								>
									Editar
								</Button>
								<h4>Dados Básicos</h4>
								<div className="box-colaborador">
									<Avatar size={115} arquivo={colaborador?.foto} />
									<div className="dados-colaborador">
										<div className="nome">
											<h5>Nome Completo</h5>
											<span style={{ fontSize: 18 }}>{colaborador.nome}</span>
										</div>
										<div className="dados-franquia">
											<div className="cpf">
												<h6>CPF</h6>
												<span>{colaborador.cpf}</span>
											</div>
											<div className="cpf">
												<h6>Data de Nascimento</h6>
												<span>{formattedDate(colaborador.dataNascimento)}</span>
											</div>
										</div>
										<div className="dados-franquia">
											<div className="cpf">
												<h6>Data de Admissão</h6>
												<span>{formattedDate(colaborador.dataAdmissao)}</span>
											</div>
											<div className="cpf">
												<h6>Status</h6>
												<span>{colaborador.ativo ? 'Ativo' : 'Inativo'}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/colaboradores/create/step-2')}
								>
									Editar
								</Button>
								<h4 id="funcao">Função</h4>
								<div id="boxes-tags">
									<Tags
										tipo={
											colaborador.unidadePessoas[0]
												?.tipoUnidadePessoaDescricao || ''
										}
									/>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<Button
									className="button-line"
									onClick={() => goTo('/colaboradores/create/step-3')}
								>
									Editar
								</Button>
								<h4 id="contato">Contato</h4>
								<div className="dados-franquia">
									<div className="cpf">
										<h6>TELEFONE</h6>
										<span>
											{
												colaborador.contatos.find(
													(c) => c.tipoContatoId === ContatoEnum.TEL
												)?.descricao
											}
										</span>
									</div>
								</div>
								<div className="dados-franquia">
									<div className="cpf">
										<h6>Email</h6>
										<span>
											{
												colaborador.contatos.find(
													(c) => c.tipoContatoId === ContatoEnum.EPS
												)?.descricao
											}
										</span>
									</div>
									<div className="cpf">
										<h6>Email Corporativo</h6>
										<span>
											{
												colaborador.contatos.find(
													(c) => c.tipoContatoId === ContatoEnum.EPF
												)?.descricao
											}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="botao-voltar-revisao">
				<Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />} onClick={() => history.goBack()}>
					Voltar
				</Button>
			</div>
		</div>
	);
};
