import useOffersFilters from './hooks/use-offers-filters.hook';

export default function useOffer() {
	//const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const { offerFilterActions, isCheckedState, checkFiltro, removeFiltros, offersChipFilter } = useOffersFilters();

	return {
		offerFilterActions,
		isCheckedState,
		checkFiltro,
		removeFiltros,
		offersChipFilter,
	};
}
