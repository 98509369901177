// Q01img2
// 

import { styled } from "@material-ui/core";

interface FaqDataItem {
    imagem: JSX.Element,
    texto: string | JSX.Element,
};

interface Props {
    questao: string,
    resposta: string,
    imagem?: JSX.Element,
    body?: FaqDataItem[]
}

const renderList = (texto) => {
    return (
        <ul>
            <li style={{ display: "flex", justifyContent: "center" }}>{texto}</li>
        </ul>
    );
}

export const faqData: Props[] = [
    // {
    //     questao: "Home",
    //     resposta: `Na aba “Home”, você terá acesso à Dashboards relacionados aos cursos, turmas, alunos matriculados no mês corrente ou nos anteriores, além de matrículas realizadas no mês correspondente. Será possível também visualizar o cronograma do dia, quais aulas acontecerão e os eventos agendados.
    //     Além disso, você conseguirá ver os alunos e colaboradores cadastrados recentemente, bem como alguns insights.`,
    //     imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q05img01.png"} />
    // },

    {
        questao: "1. Como cadastrar um novo colaborador?",
        resposta: "Para cadastrar um novo colaborador, basta clicar no botão “+ Novo Colaborador”:",
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q06img01.png"} />, texto: "Então, você será redirecionado para a página de cadastro onde deverá inserir informações como:	Dados básicos;	Função;	Contato. " },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q06img02.png"} />, texto: "Ao inserir os dados básicos, clique em próximo, para definir a função do colaborador a ser cadastrado:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q06img03.png"} />, texto: "Clique em próximo para adicionar as informações de contato:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q06img04.png"} />, texto: "Por fim, faça a revisão dos dados cadastrados. Estando todos os dados corretos, clique em “Salvar Alterações” e pronto, o novo colaborador estará cadastrado. Você poderá retornar a tela inicial ou realizar um novo cadastro." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q06img05.png"} />, texto: null },
            { imagem: null, texto: "Caso seja necessário que o colaborador acesse o sistema, como: gestão acadêmica, gestão financeira e etc., além do cadastro, deverá ser solicitado a criação do usuário ao Templo Happy." },
        ],
    },
    {
        questao: "2. Como cadastrar/alterar o valor de oferta para um curso?",
        resposta: `Para cadastrar a oferta de um curso pela primeira vez, acesse “Cursos” no menu lateral do Happy Space. Teremos listados todos os cursos oferecidos no seu Hub. Basta clicar no quadro com o nome do curso desejado, conforme exemplo abaixo:`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q07img01.png"} />, texto: "Acessando a página do curso, você poderá adicionar o valor da oferta, sendo que o valor não poderá ser abaixo do mínimo estipulado pela franqueadora. Para isso, clique em “adicionar oferta”, insira um valor igual ou superior ao mínimo e clique em “Salvar alterações”." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q07img02.png"} />, texto: null },
        ],
    },
    {
        questao: "3. Como criar uma turma?",
        resposta: `Para criar uma turma, você pode utilizar o atalho por meio da aba “Cursos”, após cadastrar a oferta ou efetivamente pela aba “Turmas”, o processo será o mesmo. Ao clicar no botão “+ Nova Turma”, você será redirecionado para a aba de criação de turmas. Aqui, você deverá iniciar com os dados básicos, como: Nome da turma, máximo de alunos, curso e período.`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q08img01.png"} />, texto: "O próximo passo é definir qual instrutor será responsável pela turma. Então, clique sobre o nome de um dos instrutores e depois em próximo:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q08img02.png"} />, texto: "O último passo é para definir os dias da semana que acontecerão as aulas deste curso. Basta selecionar o dia, adicionar o horário das aulas e clicar em “Salvar”. Pronto! Sua turma foi criada." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q08img03.png"} />, texto: null },
        ],
    },
    {
        questao: "4. Como cadastrar um novo aluno?",
        resposta: `Para Cadastrar um novo aluno, basta clicar no botão “+ Novo Aluno”.`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img01.png"} />, texto: "Abrindo a tela de cadastro você deverá iniciar colocando os dados básicos do aluno: nome completo, CPF, data de nascimento, sexo, escola, ano escolar, restrições e observações gerais. Poderá ser colocada uma foto para facilitar na identificação. Finalizando, clique em “Próximo.”" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img02.png"} />, texto: "Em seguida, você deverá adicionar as informações de contato do Aluno e clicar em “Próximo”:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img03.png"} />, texto: "Neste momento, você deverá cadastrar o responsável pelo aluno. Poderão ser cadastrados até 3 responsáveis, mas somente um deles será o responsável principal." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img04.png"} />, texto: null },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img05.png"} />, texto: "Para cadastrar mais que um responsável, basta clicar no botão “ + Adicionar Responsável”, ao final da página. Feito isso, clique em próximo." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img06.png"} />, texto: "Finalizando o cadastro dos responsáveis, será necessário adicionar imagens dos documentos do aluno e do responsável principal. Essa atividade poderá ser realizada em outro momento, porém o recomendado é que seja feita no ato da matrícula. Para adicionar as imagens, clique no botão “Fazer Upload” e, após adicionado todos os documentos, vá para revisão do cadastro." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img07.png"} />, texto: "Estando todos os dados corretos, clique no botão “Adicionar Aluno” e pronto, o aluno estará cadastrado no sistema." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q09img08.png"} />, texto: null },
        ],
    },
    {
        questao: "5. Como realizar uma nova matrícula?",
        resposta: `Para matricular o aluno em um curso, você deve ir a página inicial da aba “Alunos” e clicar no ícone “ > ” do aluno correspondente. Na sequência, clique no botão “Matricular em Novo Curso” e, então, inicie o processo de matrícula do aluno.`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img01.png"} />, texto: "O primeiro passo no processo de matrícula é a escolha do curso em que o aluno será inserido. Para isso, basta clicar em um dos cursos que aparecerão na tela conforme imagem abaixo:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img02.png"} />, texto: "Ao selecionar o curso, você irá automaticamente para a seleção de turma, que é onde deverá ser indicada a turma que o aluno será alocado, e então clique em próximo." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img03.png"} />, texto: "O próximo passo será a escolha do Responsável Financeiro. Basta selecionar um dos responsáveis listados e clicar em próximo." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img04.png"} />, texto: "Chegou o momento de definir a forma que o responsável irá realizar o pagamento do curso. O valor do curso estará fixado de acordo com o valor da oferta, estipulado na hora de cadastrar a turma do curso ofertado. Só será possível alterar o valor do curso, neste momento, utilizando o desconto de autonomia ou com o desconto de alguma campanha vigente. Selecione também a data para vencimentos dos pagamentos, podendo ser os dias 05, 10, 15 e 20." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img05.png"} />, texto: "Clicando em próximo, você poderá finalizar o plano de pagamento entre as opções de pagamento do contrato ou pagamento da parcela da matrícula. Essas opções serão melhor discutidas no item “Pagamento”." },
        ],
    },
    {
        questao: "6. O que é o pagamento da parcela da matrícula?",
        resposta: `Essa forma de pagamento refere-se ao valor parcelado do curso.`,
        body: [
            { imagem: null, texto: "Exemplo 1: no plano de pagamento foi selecionada a opção de dividir o pagamento do curso em 5x. Sendo assim, ao escolher essa opção, será gerada 1/5 parcelas para pagamento e, as demais, ficarão disponíveis no cadastro do aluno, conforme vencimento escolhido, ex.: R$3.000,00 dividido em 5 parcelas de R$600,00." },
            { imagem: null, texto: "Exemplo 2: no plano de pagamento foi selecionada a opção de não dividir o pagamento (1x). Sendo assim, ao escolher essa opção, será gerado um pagamento no valor total do curso, ex.: uma parcela de R$3.000,00.Esse pagamento pode ser feito por meio de: pix, boleto, cartão de crédito ou crédito recorrente." },
        ],
    },
    {
        questao: "7. O que é o pagamento do contrato?",
        resposta: `Essa forma de pagamento refere-se ao valor total do curso, podendo ou não ser parcelado no cartão de crédito do cliente.`,
        body: [
            { imagem: null, texto: "Exemplo: no plano de pagamento foi selecionada a opção de dividir o pagamento do curso em 5x. Sendo assim, ao escolher essa opção, será gerado um pagamento com o valor total do curso, mas com a opção de parcelar pelo cartão de crédito do cliente, ex.: uma parcela de R$3.000,00 com até 5 parcelas de R$600,00.Esse pagamento pode ser feito apenas por meio de cartão de crédito." },
        ],
    },
    {
        questao: "8. Onde acessar e baixar o contrato de uma matrícula?",
        resposta: `Após gerar o plano de pagamento você pode realizar o envio do contrato para assinatura digital na tela seguinte:`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img06.png"} />, texto: "O responsável financeiro selecionado no ato da matrícula receberá no e-mail cadastrado o contrato e poderá seguir com a leitura e assinatura dele." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img07.png"} />, texto: "Caso prefira, você também pode fazer o download do arquivo e a impressão utilizando a opção “Emitir Contrato”:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img08.png"} />, texto: "Outra opção é dentro do cadastro do aluno, que você acessa ao clicar no ícone ' > ' e selecionando o curso que deseja visualizar mais informações:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img09.png"} />, texto: "Nessa tela você têm informações referente à turma que o aluno está matriculado, financeiro, responsável pelo pagamento do contrato e a possibilidade de reenviar o mesmo para assinatura. Quando o contrato for assinado, a opção “Baixar contrato assinado” ficará disponível para download." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img10.png"} />, texto: null },
        ],
    },
    {
        questao: "9. Como trocar o aluno de turma e/ou curso?",
        resposta: `É possível realizar a troca de turma e/ou curso de um aluno acessando seu cadastro ao clicar no ícone ' > ' e, em seguida, selecionar o curso desejado. Nessa tela é possível atuar com o upgrade da matrícula – falaremos mais no tópico Upgrade de matrícula – e efetuar a transferência clicando no botão “Transferir”:`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img11.png"} />, texto: "Neste momento você terá acesso à todas as turmas cadastradas no sistema, sendo possível buscar pelo nome da nova turma à qual o aluno irá fazer parte. Basta clicar sobre a turma desejada e no botão “Transferir”. Pronto, o aluno foi transferido de turma e/ou curso." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img12.png"} />, texto: null },
        ],
    },
    {
        questao: "10. Como cancelar uma matrícula?",
        resposta: `O cancelamento da matrícula pode ser realizado acessando o cadastro do aluno ao clicar no ícone ' > ' e rolando para baixo, até a seção “Extratos Financeiros” onde podemos visualizar todo o plano de pagamento do aluno. Na frente de uma parcela com status “A gerar”, passe o mouse sobre o ícone “ ... ” e selecionando a opção “Cancelar Matrícula”:`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img13.png"} />, texto: "A tela que abre em seguida confirma a opção de cancelamento do contrato e apresenta: • uma visão das parcelas não vencidas; • a opção de aplicar ou não a multa rescisória." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img14.png"} />, texto: "Se efetuado o cancelamento com multa rescisória, uma nova parcela será gerada ao final do plano de pagamento e as demais parcelas com status “Cancelado”. Caso contrário, nenhuma parcela será gerada e o status das demais parcelas mudará para “Cancelado”:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q10img15.png"} />, texto: "Para cancelamento de contratos com alunos você também pode consultar o procedimento disponível na intranet." },
        ],
    },
    {
        questao: "11. Como rematricular um aluno?",
        resposta: `Para atuar com a rematrícula de um aluno você deve ir na página inicial da aba “Alunos” e clicar no ícone ' > ' do aluno correspondente. Na sequência, clique no botão “Rematricular em curso regular” para iniciar o processo de rematrícula do aluno.`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q11img01.png"} />, texto: "Na sequência, uma tela com os cursos regulares que o aluno já está ou foi matriculado irá aparecer. Selecione um dos cursos para continuar com a rematrícula." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q11img02.png"} />, texto: "Na rematrícula é possível diluir o saldo a pagar do contrato vigente (se houver). Além disso, será possível aplicar uma porcentagem de desconto no valor em aberto das parcelas a serem diluídas (opcional)." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q11img04.png"} />, texto: "Você também pode adicionar uma campanha, que fornece condições diferenciadas. Um exemplo é a campanha “Acelera”, onde adiciona +1 parcela no plano de pagamento com um valor diferenciado." },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q11img05.png"} />, texto: "Além disso, na rematrícula você pode adiar a segunda parcela:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q11img06.png"} />, texto: "Note que não é possível combinar a diluição das parcelas com uma campanha ou adiamento da segunda parcela, sendo necessário escolher qual benefício deseja utilizar no momento. Finalizada essa tela, o processo de pagamento é o mesmo que o da matrícula, sendo necessário escolher a forma de pagamento desse contrato (se à vista – pagamento do contrato – ou parcelado – pagamento da parcela da matrícula) e qual meio de pagamento o cliente irá utilizar." },
        ],
    },
    {
        questao: "12. Onde posso acessar os relatórios?",
        resposta: `Na aba de relatórios será possível obter relatórios do Hub, Escolas (se em operação) e do Academy:`,
        body: [
            { imagem: null, texto: "A) Hubs: Matrículas; Financeiro; Aniversariantes; Conciliação Bancária; Notas Fiscais; Rematrículas; Inadimplência." },
            { imagem: null, texto: "B) Escolas: Financeiro." },
            { imagem: null, texto: "C) Academy: Frequência por turma; Frequência por aluno" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q15img01.png"} />, texto: null },
            { imagem: null, texto: "Ao selecionar algum relatório para visualizar será possível ter acesso à tela abaixo. Alguns relatórios terão a opção “Export” para baixar os dados em uma planilha do Excel:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q15img02.png"} />, texto: "Os campos no topo são os filtros, sempre que um filtro for escolhido a tela será atualizada, trazendo as informações correspondentes. Também será possível, em alguns relatórios, exportar os dados para uma planilha do Excel, de modo que serão considerados os filtros de tela, dessa forma, o que estiver filtrado é o que será exportado." },
        ],
    },
    {
        questao: "13. Onde posso acessar os dashboards",
        resposta: `Na aba de dashboards, será possível obter um acompanhamento do Hub no que envolve o resultado de matrículas, financeiro, recebimentos e controle:`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q16img01.png"} />, texto: null },
        ],
    },
    {
        questao: "14. Como utilizar os filtros do CRM?",
        resposta: `Acessando o menu lateral, na aba "CRM" será possível fazer toda a gestão dos leads cadastrados manualmente ou vindos da LP do Hub, por exemplo.`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img01.png"} />, texto: null },
            { imagem: null, texto: "Selecionando o filtro desejado o CRM é atualizado, trazendo somente os leads conforme a busca realizada na barra de pesquisa ou os filtros:" },
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img02.png"} />, texto: "Utilizando dos filtros de busca, temos leads:" },
            { imagem: null, texto: renderList("• Sem retorno")},
            {imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img03.png"} />,  texto: renderList("• Com retorno atrasado")},
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img04.png"} />, texto: renderList("• Com retorno para hoje")},
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img05.png"} />, texto: renderList("• Com retorno futuro agendado")},
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img06.png"} />, texto: renderList("• Descartado")},
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img07.png"} />, texto: null },
        ],
    },
    {
        questao: "14. Como funcionam as etapas do lead no CRM?",
        resposta: `O lead segue um fluxo de 5 etapas onde, a cada atualização de estágio, é enviado no e-mail cadastrado do lead uma comunicação automática. As etapas citadas são:`,
        body: [
            {imagem: null, texto: "Primeiro contato: etapa em que o lead se cadastra na LP, por exemplo;"},
            {imagem: null, texto: "Apresentação: etapa em que já foi realizado o primeiro contato com o lead;"},
            {imagem: null, texto: "Proposta: etapa em que o lead está em negociação com um consultor do Hub;"},
            {imagem: null, texto: "Matrícula: etapa em que o lead foi convertido em matrícula;"},
            {imagem: null, texto: "Descartado: etapa em que ficam leads perdidos, perfil incompatível, entre outros."},
        ],
    },
    {
        questao: "15. Como cadastrar um novo lead?",
        resposta: `Utilizando o botão “Novo Lead” no canto superior direito é possível preencher um formulário com informações desse lead e salvar. Preenchendo o campo “data do próximo retorno” serão aplicados os filtros de retorno nesse lead,  podem ser editadas mais tarde ao clicar sobre um lead; Preenchendo o campo “estágio”, será onde você escolherá em qual das 5 etapas o lead melhor se encaixa:`,
        body: [
            { imagem: <img src={"https://happycodefiles.blob.core.windows.net/faq-b2c/Q17img08.png"} />, texto: null },
        ],
    },
];