import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, DatePicker, Input, Radio, Space, Switch } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useAlunoContext } from '../../context/AlunoContext';
import { IPessoaCustomDTO } from '../../models/happy-code-api.model';
import { ContatoEnum } from '../../models/pessoa.model';
import { toMoment } from '../../util/format';
import { disabledDateAfterToday } from '../../util/util';
import useAddResponsavel from './hooks/use-addResponsavel.hook';
import useAlunoCrmLead from './hooks/use-aluno-crm-lead.hook';
import useContato from './hooks/use-contato.hook';
import useCpfSearch from './hooks/use-cpf-search.hook';
import useEndereco from './hooks/use-endereco.hook';
import usePrincipal from './hooks/use-principal.hook';
import useResponsavelDeletion from './hooks/use-responsavel-deletion.hook';
import useResponsavelSelect from './hooks/use-responsavel-select.hook';
import { useSortedResponsaveis } from './hooks/use-sorted-responsaveis.hook';
import { useUpdateAluno } from './hooks/use-update-aluno.hook';
import useValidation from './hooks/use-validation.hook';
import { ProgressoNovoAluno } from './progresso';
import './style.css';

export const AlunosCreateStep3: React.FunctionComponent = () => {
	const { revisao, aluno, setAluno, revisaoDetalhe, lead } = useAlunoContext();
	const [responsavelSelected, setResponsavelSelected] = useState<IPessoaCustomDTO>(null);
	const { validationFild } = useValidation(aluno);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { handleSwitchPrincipal, previousPrincipal, setPreviousPrincipal, isOpen2, setIsOpen2, openModalTrocarResponsavel } = usePrincipal();
	const { handleResponsavelSelect } = useResponsavelSelect(handleSwitchPrincipal);
	const { handleContato } = useContato();
	const { onCepChange } = useEndereco();
	const { pessoaResponsavelList, setCPF } = useCpfSearch();
	const { handleAdicionarResponsavel, isOnePersonTurn, setIsOnePersonTurn } = useAddResponsavel();
	const { updateAluno } = useUpdateAluno(aluno, setAluno);
	const { orderListResponsavel } = useSortedResponsaveis();
	const { deleteResponsavel } = useResponsavelDeletion(setIsOnePersonTurn);
	useAlunoCrmLead(lead, aluno, setAluno);

	useEffect(() => {
		if (aluno?.pessoa?.pessoaResponsaveis.length === 1) {
			aluno.pessoa.pessoaResponsaveis[0].principal = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="container-fondo">
			<Header />
			<ProgressoNovoAluno step={1} percent={50} />
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<h3 style={{ marginBottom: 2 }}>Responsáveis do aluno</h3>
					<h6 id="taxas-h6">O aluno pode ter no máximo 3 responsáveis, sendo um deles, o principal</h6>
					<div className="filtros" style={{ width: '100%' }}>
						<div className="input-text" style={{ width: '100%' }}>
							<Input
								onChange={(e) => setCPF(e.target.value)}
								placeholder="Buscar pelo CPF"
								prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />}
							/>
						</div>
					</div>
					<Radio.Group
						value={responsavelSelected?.id}
						onChange={(value) => setResponsavelSelected(pessoaResponsavelList.find((i) => i.id === value.target.value))}
						className="radio-group"
						name="radiogroup"
					>
						<Space direction="vertical">
							{pessoaResponsavelList &&
								pessoaResponsavelList.map((responsavel) => (
									<div
										key={responsavel.id}
										className={responsavelSelected && responsavelSelected?.id === responsavel?.id ? 'radio-turma-selected' : 'radio-turma'}
									>
										<Radio value={responsavel.id} onChange={() => handleResponsavelSelect(responsavel)}>
											<p>{responsavel.nome}</p>
										</Radio>
									</div>
								))}
						</Space>
					</Radio.Group>

					{aluno?.pessoa?.pessoaResponsaveis.map((responsavel, index) => (
						<div className="aluno-responsavel" key={index}>
							<div className="title">
								<h6>Responsável {index + 1}</h6>
								{index > 0 && !responsavel?.id ?
									<Button onClick={() => deleteResponsavel(index)} icon={<Unicons.UilTrashAlt size="20" color="#FF0000" />} />
									: null}
							</div>
							<div className={`dados ${!responsavel?.ativo ? "disabled-element" : ""}`}>
								<h4>Dados Básicos</h4>
								<div className="input-box">
									<p>
										Nome completo <span id="ob">*</span>
									</p>
									<Input
										maxLength={64}
										value={responsavel.responsavel.nome || ''}
										onChange={(e) => {
											const update = aluno?.pessoa?.pessoaResponsaveis;
											update[index].responsavel.nome = e.target.value;
											setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
										}}
										placeholder="Maria Silva Oliveira Gonçalves"
										className="input"
										disabled={!responsavel?.ativo}
									/>
								</div>
								<div className="inputs-box">
									<div className="input-box" id="box-aluno">
										<p>
											CPF <small>(Somente números)</small> <span id="ob">*</span>
										</p>
										<MaskedInput
											mask="111.111.111-11"
											maxLength={11}
											value={responsavel.responsavel.cpf || ''}
											onChange={(e) => {
												const update = aluno?.pessoa?.pessoaResponsaveis;
												update[index].responsavel.cpf = e.target.value;
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
											}}
											placeholder="000.000.000-00"
											className="input"
											disabled={!responsavel?.ativo}
										/>
									</div>
									<div className="input-box" id="box-aluno">
										<p>
											Data de Nascimento <span id="ob">*</span>
										</p>
										<DatePicker
											value={toMoment(responsavel?.responsavel?.dataNascimento) || null}
											onChange={(e) => {
												const update = aluno?.pessoa?.pessoaResponsaveis;
												update[index].responsavel.dataNascimento = e?.toDate();
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
											}}
											placeholder="00/00/0000"
											className="input"
											style={{ width: '100%' }}
											format={'DD-MM-YYYY'}
											disabledDate={disabledDateAfterToday}
											disabled={!responsavel?.ativo}
										/>
									</div>
								</div>
								<div className="input-box">
									<p>
										Grau de parentesco <span id="ob">*</span>
									</p>
									<Input
										value={responsavel.grauParentesco || ''}
										onChange={(e) => {
											const update = aluno?.pessoa?.pessoaResponsaveis;
											update[index].grauParentesco = e.target.value;
											setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
										}}
										placeholder="Grau de parentesco"
										className="input"
										disabled={!responsavel?.ativo}
									/>
								</div>
								<div className="inputs-box">
									<div className="input-box" id="box-aluno">
										<p>Empresa</p>
										<Input
											value={responsavel.responsavel.descricaoEmpresa || ''}
											onChange={(e) => {
												const update = aluno?.pessoa?.pessoaResponsaveis;
												update[index].responsavel.descricaoEmpresa = e.target.value;
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
											}}
											placeholder="Empresa"
											className="input"
											disabled={!responsavel?.ativo}
										/>
									</div>
									<div className="input-box" id="box-aluno">
										<p>Profissão</p>
										<Input
											value={responsavel.responsavel.descricaoProfissao || ''}
											onChange={(e) => {
												const update = aluno?.pessoa?.pessoaResponsaveis;
												update[index].responsavel.descricaoProfissao = e.target.value;
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
											}}
											placeholder="Profissão"
											className="input"
											disabled={!responsavel?.ativo}
										/>
									</div>
								</div>
								<h4>Endereço</h4>
								<div className="input-box">
									<p>
										CEP <small>(Somente números)</small> <span id="ob">*</span>
									</p>
									<Input
										maxLength={8}
										value={responsavel?.responsavel?.endereco?.cep || ''}
										onChange={(e) => onCepChange(e.target.value, index)}
										placeholder="00000000"
										className="input"
										disabled={!responsavel?.ativo}
									/>
								</div>
								<div className="input-box">
									<p>
										Endereço <span id="ob">*</span>
									</p>
									<Input
										value={responsavel?.responsavel?.endereco?.logradouro || ''}
										onChange={(e) => {
											const update = aluno?.pessoa?.pessoaResponsaveis;
											update[index].responsavel.endereco.logradouro = e.target.value;
											setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
										}}
										placeholder="Rua Alexandre Barros, 316 - Rio de Janeiro, RJ (000000000)"
										className="input"
										maxLength={120}
										disabled={!responsavel?.ativo}
									/>
								</div>
								<div className="inputs-box">
									<div className="input-box" id="box-aluno">
										<p>
											Número <span id="ob">*</span>
										</p>
										<Input
											value={responsavel?.responsavel?.endereco?.numero || ''}
											onChange={(e) => {
												const update = aluno?.pessoa?.pessoaResponsaveis;
												update[index].responsavel.endereco.numero = e.target.value;
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
											}}
											placeholder="123"
											className="input"
											maxLength={10}
											disabled={!responsavel?.ativo}
										/>
									</div>
									<div className="input-box" id="box-aluno">
										<p>Complemento</p>
										<Input
											value={responsavel?.responsavel?.endereco?.complemento || ''}
											onChange={(e) => {
												const update = aluno?.pessoa?.pessoaResponsaveis;
												update[index].responsavel.endereco.complemento = e.target.value;
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
											}}
											placeholder="Casa 15"
											className="input"
											maxLength={128}
											disabled={!responsavel?.ativo}
										/>
									</div>
								</div>
								<h4>Contato</h4>
								<div className="inputs-box">
									<div className="input-box" id="box-aluno">
										<p>
											Telefone 1 <small>(Somente números)</small> <span id="ob">*</span>
										</p>
										{
											<MaskedInput
												mask="(11) 11111-1111"
												value={responsavel?.responsavel?.contatos?.find((c) => c.tipoContatoId === ContatoEnum.CEL)?.descricao || ''}
												onChange={(e) => handleContato(ContatoEnum.CEL, e.target.value, index)}
												placeholder="(00)0000-0000"
												className="input"
												disabled={!responsavel?.ativo}
											/>
										}
									</div>
									<div className="input-box" id="box-aluno">
										<p>
											Telefone 2 <small>(Somente números)</small>
										</p>
										{
											<MaskedInput
												mask="(11)11111-1111"
												value={responsavel?.responsavel?.contatos?.find((c) => c.tipoContatoId === ContatoEnum.TEL)?.descricao || ''}
												onChange={(e) => handleContato(ContatoEnum.TEL, e.target.value, index)}
												placeholder="(00)0000-0000"
												className="input"
												disabled={!responsavel?.ativo}
											/>
										}
									</div>
								</div>
								<div className="input-box">
									<p>E-mail <span id="ob">*</span></p>
									<Input
										value={responsavel?.responsavel?.contatos?.find((c) => c.tipoContatoId === ContatoEnum.EPS)?.descricao || ''}
										maxLength={64}
										onChange={(e) => handleContato(ContatoEnum.EPS, e.target.value, index)}
										placeholder="email@email.com"
										className="input"
										disabled={!responsavel?.ativo}
									/>
								</div>

								<div className='flex justify-between w-100'>
									<div>
										<div className="text-responsavel flex w-100 ">
											<h5>Este é o Responsável Principal</h5>
											<Unicons.UilInfoCircle size="20" color="rgba(52, 68, 83, 0.5)" />
										</div>
										<Switch
											checked={aluno?.pessoa?.pessoaResponsaveis[index].principal}
											onChange={() => {
												openModalTrocarResponsavel(index);
											}}
											disabled={!aluno?.pessoa?.pessoaResponsaveis[index].ativo}
										/>
									</div>
									<div className={aluno?.pessoa?.pessoaResponsaveis[index].ativo ? "responsavel__ativo" : "responsavel__inativo"}>
										<div className="text-responsavel flex w-100 ">
											<h5>{aluno?.pessoa?.pessoaResponsaveis[index].ativo ? "Ativo" : "Inativo"}</h5>
											<Unicons.UilInfoCircle size="20" color="rgba(52, 68, 83, 0.5)" />
										</div>
										<Switch
											checked={aluno?.pessoa?.pessoaResponsaveis[index].ativo}
											onChange={(e) => {
												const update = [...aluno.pessoa.pessoaResponsaveis];
												update[index].ativo = e;
												setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: update } });
												orderListResponsavel(aluno, setAluno);
											}}
											disabled={aluno?.pessoa?.pessoaResponsaveis[index].principal || !aluno?.pessoa?.pessoaResponsaveis[index].id}
										/>
									</div>
								</div>
							</div>
						</div>
					))}

					{validationFild ?
						<div style={{ color: 'red', textAlign: 'center', width: '100%', marginTop: 10, marginBottom: 10 }}>{validationFild}</div>
						: null}

					<div className="botoes">
						{revisaoDetalhe.ativo && (
							<Button
								type="primary"
								disabled={validationFild !== undefined}
								className="button-primary"
								style={{ width: 345 }}
								onClick={updateAluno}>
								Salvar Alterações
							</Button>
						)}
						{revisao && (
							<Link to="/alunos/create/step-5">
								<Button type="primary" disabled={validationFild !== undefined} className="button-primary" style={{ width: 345 }}>
									Voltar para Revisão
								</Button>
							</Link>
						)}
						{!revisao && !revisaoDetalhe.ativo && (
							<>
								<Link to="/alunos/create/step-1">
									<Button className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/alunos/create/step-4">
									<Button disabled={validationFild !== undefined} className="button-primary" style={{ width: 345, margin: '0 1rem' }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
					<Button
						className="button-primary"
						style={{ width: 700, marginTop: '1.5rem' }}
						icon={<PlusOutlined rev={undefined} />}
						disabled={isOnePersonTurn}
						onClick={handleAdicionarResponsavel}
					>
						Adicionar novo responsável
					</Button>
				</div>
			</div>
			<Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição de um novo aluno?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/alunos"
			/>
			<Modal
				open={isOpen2}
				onClose={() => {
					setIsOpen2(false);
					setPreviousPrincipal(previousPrincipal);
				}}
				title="Deseja efetuar a troca do responsável?"
				subtitle="Todos os contratos em aberto pássara para o responsável principal, cobranças recorrêntes será cancelada a assinatura."
				textButton1="Não"
				textButton2="Sim"
				styleButton1="button-line"
				styleButton2="button-danger"
				button2={() => {
					if (previousPrincipal !== null) {
						handleSwitchPrincipal(previousPrincipal);
					}
				}}
			/>
		</div>
	);
};
