import { SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from '@mui/material';
import { DatePicker, Input, Select } from "antd";
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import NewMenu from "../../../components/NewMenu";
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { Operators } from '../../../enum/operators.enum';
import { PerfilEnum } from '../../../enum/perfil.enum';
import { IFinanceiroEscolaComFocalDTO, IFinanceiroEscolaKPIsDTO, ILogCleanDTO, IStatusCobrancaCleanDTO, ITipoPagamentoCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { useEstadoService } from '../../../services/estado.service';
import { useFinanceiroEscolaService } from '../../../services/financeiro-escola.service';
import { useLogService } from '../../../services/log.service';
import { useStatusCobrancaService } from '../../../services/status-cobranca.service';
import { useTipoPagamentoService } from '../../../services/tipo-pagamento.service';
import { formatMomentLocalDate, formatMoney } from '../../../util/format';
import ItemTabelaEscolaFinanceiroComponent from '../components/item-tabela-grupo-financeiro-component/item-tabela-grupo-financeiro.component';
import '../styles/escola-grupos.screen.styles.scss';
import { useNotificationService } from '../../../services/notification.service';

const { RangePicker } = DatePicker;

const GrupoFinanceiroListagemScreen = () => {

    // const { dadosFinanceiros } = useGrupoFinanceiroListagemScreenState();

    const [listTipoPagamento, setListTipoPagamento] = useState<ITipoPagamentoCleanDTO[]>();
    const { removeUnidade, setRevisao } = useUnidadeContext();
    const { unidade } = useGlobalContext();
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const [data, setData] = useState<PageableResponse<IFinanceiroEscolaComFocalDTO>>();
    const [listEstado, setListEstado] = useState<any[]>();
    const [predicate] = useState<Predicate>(new Predicate());
    const [kpiPredicate] = useState<Predicate>(new Predicate());
    const [estadoId, setEstadoId] = useState<number | string>();
    const [filtroStatus, setFiltroStatus] = useState<string>();
    const estadoService = useEstadoService();
    const financeiroEscolaService = useFinanceiroEscolaService();
    const [descricao, setDescricao] = useState<string>("");
    const [dataInicio, setDataInicio] = useState<moment.Moment>();
    const [dataFim, setDataFim] = useState<moment.Moment>();
    const [tipoPagamentoId, setTipoPagamentoId] = useState<number | string>();
    const statusCobrancaService = useStatusCobrancaService();
    const [listStatusCobranca, setListStatusCobranca] = useState<IStatusCobrancaCleanDTO[]>();
    const [statusCobrancaId, setStatusCobrancaId] = useState<number | string>();
    const [kpi, setKpi] = useState<IFinanceiroEscolaKPIsDTO>(null);
    const dataAtual = formatMomentLocalDate(moment(Date()));
    const tipoPagamentoService = useTipoPagamentoService();
    const notification = useNotificationService();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso tela financeiro escolas",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const buscarDados = () => {
        let unidadeId;
        setIsGlobalLoading(true);
        if (!usuario?.perfil) return;
        if (usuario.perfil === PerfilEnum.FRANQUIA) {
            unidadeId = unidade.id;
        }

        if (dataInicio != null && dataFim != null) {
            predicate.addMultiOption('vencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
            predicate.addMultiOption('vencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
            kpiPredicate.addMultiOption('vencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
            kpiPredicate.addMultiOption('vencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
        }

        predicate.addOption('ativo', true);
        kpiPredicate.addOption('ativo', true);
        return financeiroEscolaService.findComFocal(predicate, unidadeId)
            .then(({ data }) => {
                setData(data as any);
            })
            .then(() => financeiroEscolaService.findKpi(kpiPredicate, unidadeId))
            .then(({ data }) => {
                setKpi(data as any);
            })
            .finally(() => setIsGlobalLoading(false));
    }

    const findTipoPagamento = () => {
        const tipoPagamentoPredicate = new Predicate();
        return tipoPagamentoService.findCleanList(tipoPagamentoPredicate)
            .then((response) => setListTipoPagamento(response.data));
    }

    const findStatusCobranca = () => {
        return statusCobrancaService.findCleanList(predicate)
            .then((response) => setListStatusCobranca(response.data));
    }

    useEffect(() => {
        const findEstados = () => {
            const predicateEstado = new Predicate(100);
            return estadoService.findClean(predicateEstado).then(({ data }) => {
                setListEstado(data.content);
            })
        }

        removeUnidade();
        setRevisao(false);
        findStatusCobranca();
        findTipoPagamento();
        Promise.all([
            findEstados(),
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidade, usuario]);

    useEffect(() => {
        predicate.removeAllOption();
        kpiPredicate.removeAllOption();

        if (descricao) {
            predicate.addOption('contratoGrupoNomeFantasia', descricao, Operators.CONTAINS);
        }

        if (statusCobrancaId && statusCobrancaId?.toString() === 'TDS') {
            predicate.removeOption('statusId');
            kpiPredicate.removeOption('statusId');
        }

        if (statusCobrancaId && statusCobrancaId !== 'TDS' && statusCobrancaId !== 'VNC') {
            predicate.addOption('statusId', statusCobrancaId);
            kpiPredicate.addOption('statusId', statusCobrancaId);
        }

        if (tipoPagamentoId && tipoPagamentoId !== 'TDS') {
            predicate.addOption('tipoPagamentoId', tipoPagamentoId);
            kpiPredicate.addOption('tipoPagamentoId', tipoPagamentoId);
        }
        if (statusCobrancaId && statusCobrancaId == 'VNC') {
            predicate.addMultiOption('statusId', 1, Operators.OR);
            predicate.addMultiOption('statusId', 2, Operators.OR);
            predicate.addMultiOption('statusId', 7, Operators.OR);
            kpiPredicate.addMultiOption('statusId', 1, Operators.OR);
            kpiPredicate.addMultiOption('statusId', 2, Operators.OR);
            kpiPredicate.addMultiOption('statusId', 7, Operators.OR);
            if (dataInicio === undefined && dataFim === undefined) {
                predicate.addMultiOption('vencimento', dataAtual, Operators.LESS_THAN, true);
                kpiPredicate.addMultiOption('vencimento', dataAtual, Operators.LESS_THAN, true);
            } else {
                predicate.addMultiOption('vencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
                predicate.addMultiOption('vencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
                kpiPredicate.addMultiOption('vencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
                kpiPredicate.addMultiOption('vencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
            }
        }

        if (statusCobrancaId || tipoPagamentoId || (statusCobrancaId && statusCobrancaId === 'TDS')
            || (statusCobrancaId && statusCobrancaId === 'TDS')) {
            buscarDados();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoPagamentoId, statusCobrancaId, unidade, usuario, dataInicio, dataFim, descricao]);

    useEffect(() => {
        if (estadoId && estadoId !== 'TDS') {
            predicate.addOption('enderecoUf', estadoId);
        } else {
            predicate.removeOption('enderecoUf');
        }
        if (filtroStatus && filtroStatus !== 'null') {
            predicate.addOption('ativo', filtroStatus);
        } else {
            predicate.removeOption('ativo');
        }
        if (estadoId || filtroStatus) {
            buscarDados();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estadoId, filtroStatus, unidade, usuario]);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeOption('vencimento');
            kpiPredicate.removeOption('vencimento');
            if ((dataInicio || dataFim)) {
                predicate.addMultiOption('vencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
                predicate.addMultiOption('vencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
                kpiPredicate.addMultiOption('vencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
                kpiPredicate.addMultiOption('vencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
            }
            setIsGlobalLoading(true);
            Promise.all([
                buscarDados(),
                // findKpi(),
            ]).finally(() => setIsGlobalLoading(false));
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataInicio, dataFim, unidade, usuario]);


    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            buscarDados();
        }, 2000);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, unidade, usuario]);


    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.addOption('nomeFantasia', "", Operators.CONTAINS);
            buscarDados();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidade, usuario]);


    const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        buscarDados();
    };

    const ordenar = (campo) => {
        predicate.addSort(campo);
        buscarDados();
    }
    
    const renderIndicadores = () => {
        return <div className="box-title">
            <div id="turma" style={{ marginRight: 20 }}>
                <h6>Receita total</h6>
                <h4>{formatMoney((kpi?.valorTotal - kpi?.valorIsento) || 0.0)}</h4>
            </div>
            {usuario.perfil != PerfilEnum.FRANQUIA ?
                <div id="turma">
                    <h6>Receita split</h6>
                    <h4>{formatMoney(kpi?.valorSplit || 0.0)}</h4>
                </div> : null
            }
            <div className="boxes-financeiro" >
                <div className="box-financeiro">
                    <div className="icon-em-dia">
                        <Unicons.UilCheck size="18" color="#1EBE5E" />
                    </div>
                    <div className="pago">
                        <h3>{formatMoney(kpi?.valorEmDia || 0)}</h3>
                        <h4>Pagos em dia</h4>
                    </div>
                </div>
                <div className="box-financeiro">
                    <div className="icon-atraso">
                        <Unicons.UilCalendarAlt size="18" color="#F59300" />
                    </div>
                    <div className="pago">
                        <h3>{formatMoney(kpi?.valorEmAtraso || 0)}</h3>
                        <h4>Pagos em atraso</h4>
                    </div>
                </div>
                <div className="box-financeiro">
                    <div className="icon-receber">
                        <Unicons.UilClockNine size="18" color="#2196F3" />
                    </div>
                    <div className="pago">
                        <h3>{formatMoney(kpi?.valorEmAberto)}</h3>
                        <h4>A receber</h4>
                    </div>
                </div>
                <div className="box-financeiro">
                    <div className="icon-vencido">
                        <Unicons.UilExclamationTriangle size="18" color="#DCC70A" />
                    </div>
                    <div className="pago">
                        <h3>{formatMoney(kpi?.valorVencido)}</h3>
                        <h4>Vencidos</h4>
                    </div>
                </div>
                <div className="box-financeiro">
                    <div className="icon-cancelado">
                        <Unicons.UilTimesCircle size="18" color="#FF4D4F" />
                    </div>
                    <div className="pago">
                        <h3>{formatMoney(kpi?.valorCancelado)}</h3>
                        <h4>Cancelados</h4>
                    </div>
                </div>
                <div className="box-financeiro">
                    <div className="icon-estornado">
                        <Unicons.UilMoneyBillSlash size="18" color="#3F51B5" />
                    </div>
                    <div className="pago">
                        <h3>{formatMoney(kpi?.valorIsento)}</h3>
                        <h4>Isentos</h4>
                    </div>
                </div>
            </div>
        </div>
    }

    const getPeriodo = (): any => {
        if (dataInicio && dataFim) {
            return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')]
        }
        return null;
    }

    const renderFiltros = () => {
        return <div className="filtros" style={{ marginTop: 25 }}>
            <div className="input-text" style={{ width: '100%' }}>
                <Input value={descricao} onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
            </div>
            <div className="filtro-estado " id="input-dashboard">
                <span>Tipo</span>
                <Select onChange={(value) => setTipoPagamentoId(value.toString())}>
                    <Select.Option value="TDS">Todos</Select.Option>
                    {listTipoPagamento && listTipoPagamento.filter(tipo => tipo.codigo === "PIX" || tipo.codigo === "BOL").map((item) => (
                        <Select.Option key={item.id} value={item.id}>{item.descricao}</Select.Option>
                    ))}
                </Select>
            </div>
            <div className="filtro-estado ">
                <span style={{ marginRight: 10 }}>Vencimento</span>
                <RangePicker
                    style={{ width: '250px' }}
                    value={getPeriodo()}
                    onChange={(values) => { setDataInicio(values && values[0]); setDataFim(values && values[1]); }} format={'DD-MM-YYYY'} />
                {/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
            </div>
            <div className="filtro-estado " id="input-dashboard">
                <span>Status</span>
                <Select listHeight={400} onChange={(value) => setStatusCobrancaId(value.toString())}>
                    <Select.Option value="TDS">Todos</Select.Option>
                    <Select.Option value="VNC">Vencidos</Select.Option>
                    {listStatusCobranca && listStatusCobranca.map((item) => (
                        <Select.Option key={item.id} value={item.id}>{item.descricao}</Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    }
    
    const renderTabela = () => {
        return <div className="tabela">
            <div className="titles">
                <div className="item" style={{ width: '14%', display: 'flex' }}>
                    <span>NOME DO GRUPO</span>
                    <div className="arrows" onClick={() => ordenar('contratoGrupoNomeFantasia')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '15%', display: 'flex' }}>
                    <span>RESPONSÁVEL</span>
                    <div className="arrows" onClick={() => ordenar('contratoGrupoUnidadeDescricao')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '10%', display: 'flex' }}>
                    <span>CIDADE/ESTADO</span>
                    <div className="arrows" onClick={() => ordenar('contratoGrupoEnderecoCidade')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '8%', display: 'flex' }}>
                    <span>FOCAL FINANCEIRO</span>
                    <div className="arrows" onClick={() => ordenar('contratoGrupoFocalFinanceiroNome')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '8%', display: 'flex' }}>
                    <span>VENCIMENTO</span>
                    <div className="arrows" onClick={() => ordenar('vencimento')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '9%', display: 'flex' }}>
                    <span>VALOR TOTAL</span>
                    <div className="arrows" onClick={() => ordenar('valorTotal')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '9%', display: 'flex' }}>
                    <span>VALOR SPLIT</span>
                    <div className="arrows" onClick={() => ordenar('valorSplit')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '8%', display: 'flex' }}>
                    <span>DATA PAGAMENTO</span>
                    <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                    <span>STATUS</span>
                    <div className="arrows" onClick={() => ordenar('status.descricao')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '8%', display: 'flex' }}>
                    <span>DIAS VENCIDOS</span>
                    <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>
                <div className="item" style={{ width: '10%', display: 'flex' }}>
                    <span>TIPO</span>
                    <div className="arrows" onClick={() => ordenar('tipoPagamento.descricao')}>
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                    </div>
                </div>               
            </div>
            <div className="tabela-body">
                {data && data.content.map((financeiro, index) => (
                    <ItemTabelaEscolaFinanceiroComponent financeiro={financeiro} key={index} buscarDados={buscarDados} />
                ))}
            </div>
        </div>
    }


    return <div id="escola-grupos" className="container">
        <NewMenu selecionado={24} />
        <div className="dashboard" style={{ paddingTop: 40, height: '100vh', overflowY: 'scroll' }}>
            {renderIndicadores()}
            {renderFiltros()}
            {renderTabela()}
            <div className="paginacao">
                <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
            </div>
        </div>
    </div>
}

export default GrupoFinanceiroListagemScreen;