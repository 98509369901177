import * as Unicons from '@iconscout/react-unicons';
import { Col } from "antd";
import moment from "moment";
import { memo } from "react";
import { ITurmaFullDTO } from "../../models/happy-code-api.model";
import { isAfterDate, isBeforeDate, isDateBetween } from "../../util/format";

interface TItemLista {
    turma: ITurmaFullDTO
    turmaSelected: ITurmaFullDTO
    setTurmaSelected: (setTurmaSelected: ITurmaFullDTO) => void
}

const ItemListTurma = memo(({ turma, setTurmaSelected, turmaSelected }: TItemLista) => {

    const statusTurma = (turma: ITurmaFullDTO) => {
        if (isBeforeDate(turma.dataInicio)) {
            return (
                <div className="status">
                    <div className="dot-orange"></div>
                    <p>Não Iniciado</p>
                </div>
            )
        }
        if (isDateBetween(turma.dataInicio, turma.dataFim)) {
            return (
                <div className="status">
                    <div className="dot-green"></div>
                    <p>Em andamento</p>
                </div>
            )
        }
        if (isAfterDate(turma.dataFim)) {
            return (
                <div className="status">
                    <div className="dot-orange"></div>
                    <p>Finalizado</p>
                </div>
            )
        }
        return null;
    }

    const isSelected = (id) => {
        return turmaSelected && turmaSelected.id === id;
    }

    return (

        <Col style={{ marginBottom: 20 }} span={12}>

            <div onClick={() => setTurmaSelected(turma)} className={isSelected(turma.id) ? "turma-selected" : "turma"}>
                <Unicons.UilEllipsisH size="20" className="mais" color="#FFFFFF" />
                <h3>{turma.descricao}</h3>
                <h6>ID #{turma?.id}</h6>
                {statusTurma(turma)}
                <div className="dados">
                    <div className="col">
                        <h4>Instrutor</h4>
                        <p>{turma?.unidadePessoa?.pessoaNome}</p>
                    </div>
                    <div className="col">
                        <h4>Alunos</h4>
                        <p>{turma?.matriculas?.length ?? 0}</p>
                    </div>
                </div>
                <h4>{moment(turma.dataInicio).format('DD/MM/YYYY')} - {moment(turma.dataFim).format('DD/MM/YYYY')} ({moment(turma.dataInicio).to(turma.dataFim, true)})</h4>
                <div className="boxes">
                    {turma.horarios.map(horario => (
                        <div className="box" key={horario.id}>
                            <h6>{horario.diaSemana}</h6>
                            <p>{horario.horaInicio.slice(0, -3)} - {horario.horaFim.slice(0, -3)}</p>
                        </div>
                    ))}
                </div>
            </div>

        </Col>
    )
})

ItemListTurma.displayName = 'ItemListTurma';

export default ItemListTurma;