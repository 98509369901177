import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Progress, Row } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCampanhaContext } from '../../context/CampanhaContext';

import { usePlanoPagamentoService } from '../../services/plano-pagamento.service';
import StepsCreate from './steps-create';
import './style.css';

const CampanhasCreateStep3: React.FunctionComponent = () => {

  const { campanha, setCampanha, revisao } = useCampanhaContext();
  const [isOpen, setIsOpen] = useState(false);

  const planoPagamentoService = usePlanoPagamentoService();

  useEffect(() => {
    if (!campanha.campanhaPlanoPagamentos || campanha.campanhaPlanoPagamentos.length <= 0) {
      planoPagamentoService.findList()
        .then(({ data }) =>
          setCampanha({
            ...campanha, campanhaPlanoPagamentos:
              data.map(pl => ({ id: null, campanhaId: campanha.id, planoPagamentoId: pl.id, planoPagamentoDescricao: pl.descricao, planoPagamentoNumeroParcelas: pl.numeroParcelas, percentual: 0, ativo: true }))
          }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setParcelas = (value, planoPagamentoId) => {
    const campanhaPlanoPagamentos = campanha.campanhaPlanoPagamentos.map(cpp => cpp.planoPagamentoId === planoPagamentoId ? { ...cpp, percentual: value } : cpp);
    setCampanha({ ...campanha, campanhaPlanoPagamentos })
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={48} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={2} />
      <div className="franquia">
        <div className="user-row">
          {/* <h3 id="taxas-h3">Selecione os descontos da campanha</h3>
          <h4>Parcela</h4>
          <div className="input-box">
            <InputNumber
              id="parcela"
              style={{ width: '100%' }}
              formatter={value => `${value}%`}
              parser={value => parseInt(value.replace('%', ''))}
              value={campanha?.percentualDesconto}
              min={0}
              max={100}
              onChange={value => setCampanha({ ...campanha, percentualDesconto: value })}
            />
          </div>
          <h4>Material didatico</h4>
          <div className="input-box">
            <InputNumber
              id="material-didatico"
              style={{ width: '100%' }}
              formatter={value => `${value}%`}
              parser={value => parseInt(value.replace('%', ''))}
              value={campanha?.percentualDescontoMaterial}
              min={0}
              max={100}
              onChange={value => setCampanha({ ...campanha, percentualDescontoMaterial: value })}
            />
          </div>
          <div className="space1">
          </div> */}
          <h3 id="taxas-h3">Preencha os percentuais de desconto(%).</h3>
          <Row>
            {campanha.campanhaPlanoPagamentos.map(campanhaPlanoPagamento => (
              <div className="space-align-block" key={campanhaPlanoPagamento.planoPagamentoId}>
                <Col span={20} >
                  {campanhaPlanoPagamento?.planoPagamentoDescricao} ({campanhaPlanoPagamento?.planoPagamentoNumeroParcelas}x)
                  <InputNumber
                    id={"pl-" + campanhaPlanoPagamento.planoPagamentoId}
                    style={{ width: '100%' }}
                    //formatter={value => `${value}%`}
                    //parser={value => parseFloat(value.replace('%', ''))}
                    value={campanhaPlanoPagamento.percentual}
                    min={0}
                    max={100}
                    onChange={value => setParcelas(value, campanhaPlanoPagamento.planoPagamentoId)}
                    stringMode
                    decimalSeparator=','
                  />
                </Col>
              </div>
            ))}
          </Row>
          <div className="botoes">
            {revisao ?
              <Link to="/campanhas/create/step-6">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/campanhas/create/step-2">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/campanhas/create/step-4">
                  <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa campanha?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/campanhas'
      />
    </div>
  )

}

export default CampanhasCreateStep3;