import { CloseOutlined } from '@ant-design/icons';
import { Button, InputNumber, Progress, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import info from '../../assets/img/financeiro-info/info.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';

import MsgInfoSistema from '../../components/MsgInfoSistema';
import { useNegocioContext } from '../../context/NegocioContext';
import { useNegocioService } from '../../services/negocio.service';
import { useNotificationService } from '../../services/notification.service';
import { currencyFormatter, currencyParser, formatMoney } from '../../util/format';
import StepsCreate from './steps-create';
import './style.css';

export const NegocioCreateStep4: React.FunctionComponent = () => {

	const { negocio, setNegocio, revisao } = useNegocioContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const [isOpen, setIsOpen] = useState(false);    
    const parcelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; 
	const negocioService = useNegocioService();   
    const history = useHistory();    
    const notification = useNotificationService();
    const [hideDisplay, setHideDisplay] = useState(false);

    const patchOferta = () => {
        setIsGlobalLoading(true);
        negocioService.replicarOferta(negocio?.id, {
            id: negocio?.id,
            valorMinimo: negocio.valorMinimo,
            valorHora: negocio.valorHora,
            numeroParcelasMaximo: negocio.numeroParcelasMaximo,
            flTaxaMatricula: negocio.flTaxaMatricula,
            valorSplitTaxa: negocio.valorSplitTaxa,
            prorrogacaoDiasMatricula: negocio.prorrogacaoDiasMatricula,
            valorMinimoRematricula: negocio.valorMinimoRematricula,
            valorHoraRematricula: negocio.valorHoraRematricula,
            numeroParcelasMaximoRematricula: negocio.numeroParcelasMaximoRematricula,
            flTaxaRematricula: negocio.flTaxaRematricula,
            valorSplitTaxaRematricula: negocio.valorSplitTaxaRematricula,
            prorrogacaoDiasRematricula: negocio.prorrogacaoDiasRematricula,
            valorSugerido: negocio.valorSugerido,
        }).then(() => {
            history.push(`/negocio/create/step-6`);
            notification({
                description: `Oferta alterada com sucesso.`,
                type: 'success',
                message: 'Sucesso!',
            });
        }).catch(() => {
            notification({
                description: `Desculpe, não foi possível alterar oferta.`,
                type: 'error',
                message: 'Erro!',
            });
        }).finally(() => setIsGlobalLoading(false));
    }

    return (
        <div className="container-fondo">
            <Header />
            <div className="progress">
                <Progress className="progress-user" percent={20} strokeLinecap="square" showInfo={false} />
            </div>
            <StepsCreate current={3} />
            <div className="franquia">
                <div className="user-row">

                    <div className="card-form">
                        <h3 id="taxas-h3" style={{ fontWeight: 700 }}>Rematrícula</h3>
                        <h3 id="taxas-h3">1. Qual será o preço mínimo desse negocio?</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={negocio?.valorMinimoRematricula}
                                value={negocio?.valorMinimoRematricula}
                                onChange={(e) => setNegocio({ ...negocio, valorMinimoRematricula: e })}
                            />
                        </div>
                        <h3 id="taxas-h3">1. Qual será o preço sugerido desse negocio?</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={negocio?.valorSugerido}
                                value={negocio?.valorSugerido}
                                onChange={(e) => setNegocio({ ...negocio, valorSugerido: e })}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <h3 id="taxas-h3">2. Preencha o valor do split.</h3>
                            <img className="config-icons-financeiro" src={info} onClick={(e) => setHideDisplay(true)} />
                            {hideDisplay && (
                                <MsgInfoSistema
                                    setHideDisplay={setHideDisplay}
                                    hideDisplay={false}
                                    icon={info}
                                    title={"Valor Split"}
                                    descricaoOne={`O valor inserido no campo valor do split não pode ser superior ao preço mínimo da matrícula.`}
                                    observacao={`Preço mínimo informado: ${formatMoney(negocio?.valorHoraRematricula)}`}
                                />
                            )}
                        </div>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={negocio?.valorMinimoRematricula}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                decimalSeparator=","
                                defaultValue={negocio?.valorHoraRematricula}
                                value={negocio?.valorHoraRematricula}
                                onChange={(e) => setNegocio({ ...negocio, valorHoraRematricula: e })} />
                        </div>

                        <h3 id="taxas-h3">3. Qual será o máximo de parcelas?</h3>
                        <div className="input-box" style={{ width: "100%" }}>
                            <Select
                                id="valor"
                                style={{ background: 'white', width: '100%', marginBottom: 5 }}
                                defaultValue={negocio?.numeroParcelasMaximoRematricula}
                                value={negocio?.numeroParcelasMaximoRematricula}
                                onChange={(e) => setNegocio({ ...negocio, numeroParcelasMaximoRematricula: e })}>
                                {parcelas.map((parcela, index) =>
                                    <Select.Option key={index} value={parcela}>{parcela}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <h3 id="taxas-h3">4. Deseja utilizar a taxa de Rematrícula?</h3>
                        <div className="input-box" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "max-content" }}>
                            <span style={{ width: 200 }}>Habilitar taxa: </span>
                            <Radio.Group
                                style={{ display: "flex", justifyContent: "center" }}
                                defaultValue={negocio?.flTaxaRematricula}
                                value={negocio?.flTaxaRematricula}
                                onChange={(e) => setNegocio({ ...negocio, flTaxaRematricula: e.target.value })} >
                                <Radio value={false}>Não</Radio>
                                <Radio value={true}>Sim</Radio>
                            </Radio.Group>
                        </div>

                        <h3 id="taxas-h3">Preencha o valor do split da taxa de Matrícula.</h3>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                decimalSeparator=","
                                disabled={!negocio?.flTaxaRematricula ? true : false}
                                defaultValue={negocio?.flTaxaRematricula ? negocio?.valorSplitTaxaRematricula : 0}
                                value={negocio?.flTaxaRematricula ? negocio?.valorSplitTaxaRematricula : 0}
                                onChange={(e) => setNegocio({ ...negocio, valorSplitTaxaRematricula: e })} />
                        </div>
                        <h3 id="taxas-h3">Preencha a prorrogação da primeira parcela da Matrícula.</h3>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={60}
                                disabled={!negocio?.flTaxaRematricula ? true : false}
                                defaultValue={negocio?.flTaxaRematricula ? negocio?.prorrogacaoDiasRematricula : 0}
                                value={negocio?.flTaxaRematricula ? negocio?.prorrogacaoDiasRematricula : 0}
                                onChange={(e) => setNegocio({ ...negocio, prorrogacaoDiasRematricula: e })} />
                        </div>
                    </div>

                    <div className="botoes">
                        {revisao ?
                            <div>
                                <Button
                                    type="primary"
                                    className="button-primary"
                                    id="revisao"
                                    style={{ width: 700, marginBottom: 10 }}
                                    onClick={patchOferta}
                                    disabled={negocio?.valorMinimoRematricula <= negocio?.valorHoraRematricula ? true : false}
                                >Replicar todas ofertas</Button>
                                <Link to="/negocio/create/step-6">
                                    <Button
                                        style={{ width: 700 }}
                                        id="revisao"
                                        className="button-primary"
                                        type="primary"
                                        disabled={negocio?.valorMinimoRematricula <= negocio?.valorHoraRematricula ? true : false}>Voltar para Revisão</Button>
                                </Link>
                            </div>
                            : <>
                                <Link to="/negocio/create/step-2">
                                    <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                                </Link>
                                <Link to="/negocio/create/step-6">
                                    <Button
                                        className="button-primary"
                                        id="proximo"
                                        style={{ width: 345 }}
                                        disabled={negocio?.valorMinimoRematricula <= negocio?.valorHoraRematricula ? true : false}>Próximo</Button>
                                </Link>
                            </>
                        }
                    </div>

                </div>
            </div>
            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a criação deste negocio?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/negocio'
            />
        </div >
    )

}