import * as Unicons from "@iconscout/react-unicons";
import { Button, DatePicker, Form, Input, Radio } from 'antd';
import { ILeadCustomDTO } from "../../../../../models/happy-code-api.model";
import '../../../shared.scss';
import '../style.scss';
import useNoteState from "./selectors/note.state";

interface Props {
  open: boolean;
  onClose: () => void;
  lead: ILeadCustomDTO;
}

export const ModalNote = (props: Props) => {
  const { open,
    onClose,
    value,
    onChange,
    isSelect,
    isSelect1,
    isSelect2,
    isSelect3,
    setValue,
    onSalvar,
    form,
    clearFields,
  } = useNoteState(props);
  const { TextArea } = Input;

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal">
          <Button onClick={() => {
            clearFields();
            onClose();
          }} className="button-x">x</Button>

          <Form
            style={{ maxWidth: '100%' }}
            name="basic"
            // initialValues={{ remember: true }}
            form={form}
          >
            <h3 >Adicionar anotações</h3>
            <h6 id="text-note">Preencha as informações para retorno antes de mover o card do Lead.</h6>

            <div className="text-over-input">
              <small>Anotações</small>
            </div>
            <div className="input-textarea mb-20">
              <Form.Item name={'dsLeadAnotacao'} rules={[{ required: true, message: 'Informe a descrição' }]} >
                <TextArea id="dsLeadAnotacao" bordered={false} cols={100} maxLength={2000} />
              </Form.Item>
            </div>

            <span className="mb-10">Tipo de contato</span>
            <Form.Item name={'dsTipoLeadAnotacao'} rules={[{ required: true, message: 'Qual o tipo do contato?' }]}>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={'Whatsapp'}>
                  <Unicons.UilWhatsapp size={14} color={setValue} />
                  <span className={`ml-5 ${isSelect} `}>Whatsapp</span>
                </Radio>
                <Radio value={'Ligação'}>
                  <Unicons.UilCalling size={14} color="var(--azul-happy)" />
                  <span className={`ml-5 ${isSelect1} `}>Ligação</span>
                </Radio>
                <Radio value={'Email'}>
                  <Unicons.UilEnvelope size={14} color="var(--azul-happy)" />
                  <span className={`ml-5 ${isSelect2} `}>Email</span>
                </Radio>
                <Radio value={'Presencial'}>
                  <Unicons.UilUsersAlt size={14} color="var(--azul-happy)" />
                  <span className={`ml-5 ${isSelect3} `}>Presencial</span>
                </Radio>
              </Radio.Group>
            </Form.Item>

            <div className="text-over-input">
              <small>Agendar retorno</small>
            </div>
            <div className="input-modal mb-20">
              <Form.Item name={'dhRetorno'} style={{ width: '100%' }} rules={[{ required: false, message: 'Qual o próximo retorno?' }]}>
                <DatePicker
                  id="dhRetorno"
                  placeholder="Data retorno"
                  suffixIcon={null}
                  format={'DD-MM-YYYY'}
                  style={{ width: '100%', borderWidth: 1, }}
                />
              </Form.Item>
              <Unicons.UilCalender color="var(--cinza-azulado-500" size={18} />
            </div>
            <div className="flex flex-row justify-end w-100">
              <Button onClick={() => {
                clearFields();
                onClose();
              }} className="button-blue mr-5">Cancelar</Button>
              <Button className="button-primary" onClick={onSalvar} disabled={
                !form.isFieldsTouched() ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              } >Salvar</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}
