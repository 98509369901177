interface Props {
    contador?: number;
    limite?: number;
}

const ContadorCaracteres: React.FunctionComponent<Props> = ({ contador, limite }: Props) => {
    return (
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
            <span>{contador ? contador : 0} de {limite}</span>
        </div>
    );
}

export default ContadorCaracteres;