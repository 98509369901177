import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Radio } from 'antd';
import 'antd/dist/antd.css';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AvatarUpload } from '../../components/AvatarUpload';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { IArquivoCleanDTO, IGeneroCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useGeneroService } from '../../services/genero.service';
import { formatMomentLocalDateTime, toMoment } from '../../util/format';
import { disabledDateAfterToday } from '../../util/util';
import { useUpdateAluno } from './hooks/use-update-aluno.hook';
import { ProgressoNovoAluno } from './progresso';
import './style.css';

export const AlunosCreateStep1: React.FunctionComponent = () => {
	const { aluno, setAluno, revisao, revisaoDetalhe } = useAlunoContext();
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const generoService = useGeneroService();
	const [generoList, setGeneroList] = useState<IGeneroCleanDTO[]>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { TextArea } = Input;
	const { idLead } = useParams<any>();
	const { updateAluno } = useUpdateAluno(aluno, setAluno);

	const findGenero = async () => {
		const predicate = new Predicate();
		setIsGlobalLoading(true);
		return generoService.findCleanList(predicate)
			.then(({ data }) => {
				setGeneroList(data);
			}).finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		setAluno({ ...aluno, leadId: idLead });
		findGenero();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeRadioGenero = (genero: IGeneroCleanDTO) => {
		setAluno({
			...aluno,
			pessoa: {
				...aluno.pessoa,
				genero,
			},
		});
	};

	const onChangePessoaInputs = (property: string, value: string) => {
		const alunoCopy = { ...aluno, unidade };
		alunoCopy.pessoa[property] = value;
		setAluno(alunoCopy);
	};

	const onAvatarUpload = (arquivo: UploadFile<IArquivoCleanDTO>) => {
		if (arquivo) {
			aluno.pessoa.foto = arquivo.response;
		} else {
			aluno.pessoa.foto = null;
		}
	}

	function validar(): boolean {
		let desativarBotao
		if (!aluno.pessoa.nome || !aluno.pessoa.dataNascimento || !aluno.pessoa.genero) {
			desativarBotao = true;
		} else {
			desativarBotao = false;
		}
		return desativarBotao
	}

	return (
		<div className="container-fondo">
			<Header />
			<ProgressoNovoAluno step={0} percent={25} />
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<h3 className="mat">Preencha os dados básicos do aluno</h3>
					<div className="aluno">
						<AvatarUpload arquivo={aluno.pessoa.foto} onUpload={(arquivos) => onAvatarUpload(arquivos)} />
					</div>
					<div className="input-box">
						<p>
							Nome Completo <span id="ob">*</span>
						</p>
						<Input
							value={aluno.pessoa.nome}
							onChange={(e) => onChangePessoaInputs('nome', e.target.value)}
							placeholder="Maria Silva Oliveira Gonçalves"
							className="input"
							prefix={<UserOutlined rev={undefined} />}
						/>
					</div>
					<div className="inputs-box">
						<div className="input-box" id="box-aluno">
							<p>
								CPF <small>(Somente números)</small>
							</p>
							<Input
								maxLength={11}
								value={aluno.pessoa.cpf}
								onChange={(e) => onChangePessoaInputs('cpf', e.target.value)}
								placeholder="000.000.000-00"
								className="input"
								prefix={<UserOutlined rev={undefined} />}
							/>
						</div>
						<div className="input-box" id="box-aluno">
							<p>
								Data de Nascimento <span id="ob">*</span>
							</p>
							<DatePicker
								value={toMoment(aluno.pessoa.dataNascimento)}
								onChange={(e) =>
									setAluno({
										...aluno,
										pessoa: {
											...aluno.pessoa,
											dataNascimento: formatMomentLocalDateTime(e),
										},
									})
								}
								placeholder="Data de nascimento"
								suffixIcon={null}
								format={'DD-MM-YYYY'}
								style={{ width: '100%' }}
								disabledDate={disabledDateAfterToday}
							/>
						</div>
					</div>
					<div className="input-box">
						<p id="sexo">
							Gênero <span id="ob">*</span>
						</p>
						<Radio.Group value={aluno.pessoa.genero?.id}>
							{generoList &&
								generoList.map((genero) => (
									<Radio
										key={genero.id}
										value={genero.id}
										onClick={() => onChangeRadioGenero(genero)}
									>
										{genero.descricao}
									</Radio>
								))}
						</Radio.Group>
					</div>
					<div className="input-box">
						<p>Escola</p>
						<Input
							maxLength={255}
							value={aluno.escola}
							onChange={(e) => setAluno({ ...aluno, escola: e.target.value })}
							placeholder="Escola"
							className="input"
							style={{ width: '100%' }}
							prefix={<UserOutlined rev={undefined} />}
						/>
					</div>
					<div className="input-box">
						<p>Ano Escolar</p>
						<Input
							value={aluno.anoEscolar}
							onChange={(e) =>
								setAluno({ ...aluno, anoEscolar: Number(e.target.value) })
							}
							placeholder="Ano Escolar"
							className="input"
							prefix={<UserOutlined rev={undefined} />}
						/>
					</div>
					<div className="input-box">
						<p>Restrições</p>
						<TextArea
							maxLength={4000}
							value={aluno.restricao}
							onChange={(e) =>
								setAluno({ ...aluno, restricao: e.target.value })
							}
							rows={4}
							placeholder="Restrições"
						/>
					</div>
					<div className="input-box">
						<p>Observações Gerais</p>
						<TextArea
							maxLength={4000}
							value={aluno.observacao}
							onChange={(e) =>
								setAluno({ ...aluno, observacao: e.target.value })
							}
							rows={4}
							placeholder="Observações Gerais"
						/>
					</div>
					<div className="botoes">
						{revisao && (
							<Link to="/alunos/create/step-5">
								<Button
									type="primary"
									className="button-primary"
									style={{ width: 345 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						)}
						{revisaoDetalhe.ativo && (
							<Button
								type="primary"
								className="button-primary"
								style={{ width: 345 }}
								onClick={updateAluno}>
								Salvar Alterações
							</Button>
						)}
						{!revisao && !revisaoDetalhe.ativo && (
							<>
								<Link to="/alunos">
									<Button className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/alunos/create/step-3">
									<Button disabled={validar()} className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição de um novo aluno?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/alunos"
			/>
		</div>
	);
};
