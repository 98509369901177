import * as Unicons from '@iconscout/react-unicons';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { TableState } from '../../enum/table-state.enum';

import GraphStemplay from '../../components/GraphStemplay';
import PieStemplay from '../../components/PieStemplay';
import { IAulaCustomDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMatriculaService } from '../../services/matricula.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import { IMatriculaFullDTO } from './../../models/happy-code-api.model';
import { useAulaService } from './../../services/aula.service';
import { useTurmaService } from './../../services/turma.service';
import './style.css';

export const StemplayPainel: React.FunctionComponent = () => {
    const { id } = useParams<any>();
    const { removeTurma } = useTurmaContext();
    const [turma, setTurma] = useState<ITurmaFullDTO>();
    const [listAula, setListAula] = useState<PageableResponse<IAulaCustomDTO>>();
    const [listMatricula, setListMatricula] = useState<PageableResponse<IMatriculaFullDTO>>();
    const [tableState, setTableState] = useState<TableState>(TableState.ALUNO);
    const { setIsGlobalLoading } = useGlobalContext();
    const turmaService = useTurmaService();
    const aulaService = useAulaService();
    const matriculaService = useMatriculaService();
    const [predicateAula] = useState<Predicate>(new Predicate());
    const [matriculaPredicate] = useState<Predicate>(new Predicate());
    const history = useHistory();
    const [mostrarModalAluno, setMostrarModalAluno] = useState<boolean>(false);
    const [mostrarModalNovoAluno, setMostrarModalNovoAluno] = useState<boolean>(false);

    const findAula = () => {
        predicateAula.addOption('turma.id', id);
        return aulaService.findCustom(predicateAula)
            .then((response) => {
                setListAula(response.data);
            })
    }

    const findMatricula = () => {
        matriculaPredicate.addOption('turma.id', id);
        return matriculaService.findFull(matriculaPredicate)
            .then((response) => {
                setListMatricula(response.data);
            })
    }

    const findTurma = () => {
        return turmaService.findFullById({ id })
            .then((response) => {
                setTurma(response.data);
            });
    }


    useEffect(() => {
        removeTurma();
        if (id) {
            setIsGlobalLoading(true);
            Promise.all([
                findTurma(),
                findAula(),
                findMatricula()
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const statusTurma = (turma: ITurmaFullDTO) => {
        if (isBeforeDate(turma.dataInicio)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Não Iniciado</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        if (isDateBetween(turma.dataInicio, turma.dataFim)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Em andamento</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        if (isAfterDate(turma.dataFim)) {
            return (
                <div className="box-dot">
                    <div className="dot"></div>
                    <p style={{ marginRight: 10 }}>Finalizado</p>
                    <Unicons.UilEllipsisV color="#92A9CB" size="24" />
                </div>
            )
        }
        return null;
    }

    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicateAula.setPage(page);
        setIsGlobalLoading(true);
        findAula().finally(() => setIsGlobalLoading(false));
    }

    const onPageChangeMatricula = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        matriculaPredicate.setPage(page);
        setIsGlobalLoading(true);
        findMatricula().finally(() => setIsGlobalLoading(false));
    }

    const qtdAulaRealizadas = () => {
        if (turma && turma.aulas) {
            return turma.aulas.filter((aula) => isBeforeDate(aula.data)).length;
        }
        return 0;
    }

    const getPorcentagem = () => {
        if (turma && turma.aulas) {
            return parseInt(((qtdAulaRealizadas() / turma.aulas.length) * 100).toPrecision(2));
        }
        return 0;
    }
    const aluno = () => {
        setMostrarModalAluno(true);
    }

    const closeModalAluno = () => {
        setMostrarModalAluno(false);
    }
    const novoAluno = () => {
        setMostrarModalNovoAluno(true);
    }

    const closeModalNovoAluno = () => {
        setMostrarModalNovoAluno(false);
    }

    const arrReferencia = [{
        nome: 'Lucas',
        sobrenome: 'Silva',
        id: 1,
        ranking: 1,
        tempo: '155h20m',
    },
    {
        nome: 'Matheus',
        sobrenome: 'Oliveira',
        id: 2,
        ranking: 2,
        tempo: '100h40m3s',
    },
    {
        nome: 'Julia',
        sobrenome: 'da Silva',
        id: 3,
        ranking: 3,
        tempo: '90h25m40s',
    }, {
        nome: 'Bruna',
        sobrenome: 'Araujo',
        id: 4,
        ranking: 4,
        tempo: '20h30m',
    }
    ]

    const [semanaGraphConfig, setSemanaGraphConfig] = useState({
        data: [],
        height: 150,
        width: 390,
        xField: 'diaDaSemana',
        yField: 'qtd',
    });


    return (
        <div className="container-fondo">
            <NewMenu selecionado={30} />
            <div className="franquia" id="aluno-detalhe-row">
                <div className="franquia-row" style={{ marginTop: 70 }} id="row-aluno-box">

                    <div className="box-data-aluno">
                        <div className="data-aluno" style={{ minHeight: '270', width: '100%' }}>
                            <div id="turma-detalhe-s">
                                <h5>Alunos</h5>
                                <div className="data" style={{ marginTop: '40px', marginLeft: '60px' }}>
                                    <div className="data1-aluno">
                                        <div className="dat">
                                            <div className="data-aluno-stemplay">
                                                <div className="content-aluno-stemplay">
                                                    <Unicons.UilKid className="icon-stemplay-painel" size="40" color="#6A7C96" />
                                                    <p>990</p>
                                                </div>
                                                <p>fundamental I</p>
                                            </div>
                                        </div>
                                        <div className="dat">
                                            <div className="data-aluno-stemplay">
                                                <div className="content-aluno-stemplay">
                                                    <Unicons.UilSmile className="icon-stemplay-painel" size="40" color="#6A7C96" />
                                                    <p>527</p>
                                                </div>
                                                <p>fundamental II</p>
                                            </div>
                                        </div>
                                        <div className="dat">
                                            <div className="data-aluno-stemplay">
                                                <div className="content-aluno-stemplay">
                                                    <Unicons.UilGrin className="icon-stemplay-painel" size="40" color="#6A7C96" />
                                                    <p>14</p>
                                                </div>
                                                <p>ensino médio</p>
                                            </div>
                                        </div>
                                        <div className="dat">
                                            <div className="data-aluno-stemplay">
                                                <div className="content-aluno-stemplay">
                                                    <Unicons.UilGraduationCap className="icon-stemplay-painel" size="40" color="#6A7C96" />
                                                    <p>1531</p>
                                                </div>
                                                <p>total de alunos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-painel-stemplay" style={{ width: '100%' }}>
                        <div className="box-painel" style={{ width: '57%' }}>
                            <div className="data-aluno" style={{ minHeight: '270', width: '100%' }}>
                                <div id="turma-detalhe-s">
                                    <h5>Licenças</h5>
                                    <div className="data" style={{ marginTop: '40px', marginLeft: '30px' }}>
                                        <div className="data1-aluno">
                                            <div className="dat">
                                                <div className="data-aluno-stemplay">
                                                    <div className="content-aluno-stemplay">
                                                        <Unicons.UilFileAlt className="icon-stemplay-painel" size="40" color="#6A7C96" />
                                                        <p>29997</p>
                                                    </div>
                                                    <p style={{ textAlign: 'center' }}>licenças contratadas</p>
                                                </div>
                                            </div>
                                            <div className="dat">
                                                <div className="data-aluno-stemplay">
                                                    <div className="content-aluno-stemplay">
                                                        <Unicons.UilFilePlusAlt className="icon-stemplay-painel" size="40" color="#6A7C96" />
                                                        <p>28466</p>
                                                    </div>
                                                    <p style={{ textAlign: 'center' }}>licenças restantes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-painel" style={{ width: '40%' }}>
                            <div className="data-aluno" style={{ minHeight: '270', width: '100%' }}>
                                <div id="turma-detalhe-s">
                                    <h5>Produtos</h5>
                                    <div className="data" style={{ marginTop: '15px' }}>
                                        <div className="data1-aluno">
                                            <div className="dat">
                                                <div className="data-box-aluno-stemplay">
                                                    <Unicons.UilCircle size="10" color="#6A7C96" />
                                                    <p>cientistas digitais</p>
                                                </div>
                                                <div className="data-box-aluno-stemplay">
                                                    <Unicons.UilCircle size="10" color="#6A7C96" />
                                                    <p>little</p>
                                                </div>
                                                <div className="data-box-aluno-stemplay">
                                                    <Unicons.UilCircle size="10" color="#6A7C96" />
                                                    <p>universe</p>
                                                </div>
                                                <div className="data-box-aluno-stemplay">
                                                    <Unicons.UilCircle size="10" color="#6A7C96" />
                                                    <p>galaxy</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-data-aluno" style={{ marginTop: '20px' }}>
                        <div className="data-aluno" style={{ height: 'fit-content', width: '100%' }}>
                            <div id="turma-detalhe-s">
                                <h5>Base de alunos - Evolução</h5>
                                <div className="data" style={{ marginTop: '40px', marginLeft: '30px' }}>
                                    <div className="data1-aluno">
                                        <GraphStemplay />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-painel-stemplay" style={{ width: '100%' }}>
                        <div className="box-painel" style={{ width: '49%' }}>
                            <div className="data-aluno" style={{ minHeight: '270', width: '100%' }}>
                                <div id="turma-detalhe-s">
                                    <h5>Alunos por Categoria</h5>
                                    <div className="data" style={{ marginLeft: '10px' }}>
                                        <div className="data1-aluno">
                                            <div className="dat">
                                                <div className="data-aluno-stemplay">
                                                    <div className="content-aluno-stemplay">
                                                        <PieStemplay />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-painel" style={{ width: '48%' }}>
                            <div className="data-aluno" style={{ minHeight: '270', width: '100%' }}>
                                <div id="turma-detalhe-s">
                                    <h5>Ranking</h5>
                                    <div className="data" style={{ marginTop: '40px' }}>
                                        <div className="tabela-stemplay stemplay-tabela">
                                            <div className="titles-users" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '125px' }}>
                                                <div className="item-stemplay">
                                                    <span>ALUNO</span>
                                                </div>
                                                <div className="item-stemplay">
                                                    <span>TEMPO</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {arrReferencia.map((map) => (
                                    <div key={id} className="registro-stemplay" id="registro-stemplay">
                                        <div className="userStemplay">
                                            <div className="column-stemplay">
                                                <p>{map.ranking}. {map.nome} {map.sobrenome}</p>
                                            </div>
                                            <div className="column-stemplay">
                                                <p>{map.tempo}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default StemplayPainel