/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { IUsuarioDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useGrupoContratoService } from '../../services/grupo-contrato.service';
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioService } from '../../services/usuario.service';

interface TInputs {
    open: boolean;
    onClose: () => void;
}

interface SendLicencaStemplay {
    to: string;
    cc: string;
}

export const ModalEnvioLicencaStemplay: React.FunctionComponent<TInputs> = ({ open, onClose }: TInputs) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const grupoContratoService = useGrupoContratoService();
    const usuarioService = useUsuarioService();
    const [sendLicencaStemplay, setSendLicencaStemplay] = useState<SendLicencaStemplay>();
    const [usuarios, setUsuarios] = useState<IUsuarioDTO[]>();

    const findUsuario = () => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        return usuarioService.findList(predicate).then(({ data }) => {
            setUsuarios(data);
        })
    };

    const cleanFilds = () => {
        setSendLicencaStemplay({
            ...sendLicencaStemplay,
            cc: null,
            to: null,
        });
    }

    const closeModal = () => {
        cleanFilds();
        onClose();
    }

    const executionRotinaEnvioLicencaStemplay = () => {
        setIsGlobalLoading(true);
        grupoContratoService.rotinaEnvioLicencaStemplay(
            sendLicencaStemplay?.to,
            sendLicencaStemplay?.cc,
        )
            .then(() => {
                notification({
                    description: `Rotina executada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso',
                });
                setIsGlobalLoading(false)
                closeModal();
            }).catch((err) => {
                notification({
                    description: `Ocorreu um erro: ${err}`,
                    type: "error",
                    message: "Erro!"
                });
                setIsGlobalLoading(false)
            });
    }

    const submitValidationFields = () => {
        if (!sendLicencaStemplay?.to) {
            return notification({
                description: `O campo "Destinatario" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        executionRotinaEnvioLicencaStemplay();
    }

    useEffect(() => {
        findUsuario();
    }, []);

    if (!open) return null;

    return (
        <div className="modalContainer">
            <div className="modal-box-upload-material" style={{ maxHeight: 500 }}>
                <button onClick={closeModal} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
                <h3>Atualização de licença do Stemplay e envio de E-mail</h3>
                <div className="line" />
                <div style={{ maxHeight: 350, width: "100%", margin: "auto", padding: 20 }}>
                    <div className="input-box" id="box-aluno">
                        <label htmlFor="to">Destinatário</label>
                        <Select
                            id="to"
                            placeholder="Selecione"
                            style={{ width: '100%' }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            defaultValue={sendLicencaStemplay?.to}
                            onChange={(e) => setSendLicencaStemplay({ ...sendLicencaStemplay, to: e })}
                            filterOption={(input, option) =>
                                option?.text?.toLowerCase().includes(input.toLowerCase())}
                        >
                            {usuarios && usuarios.map(usuario =>
                                <Select.Option key={usuario.id} value={usuario?.login} text={usuario?.login}>{usuario?.login}</Select.Option>
                            )}
                        </Select>
                    </div>
                    <div className="input-box" id="box-aluno">
                        <label htmlFor="cc">Cópia</label>
                        <Select
                            id="cc"
                            placeholder="Selecione"
                            style={{ width: '100%' }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            defaultValue={sendLicencaStemplay?.cc}
                            onChange={(e) => setSendLicencaStemplay({ ...sendLicencaStemplay, cc: e })}
                            filterOption={(input, option) =>
                                option?.text?.toLowerCase().includes(input.toLowerCase())}
                        >
                            {usuarios && usuarios.map(usuario =>
                                <Select.Option key={usuario.id} value={usuario?.login} text={usuario?.login}>{usuario?.login}</Select.Option>
                            )}
                        </Select>
                    </div>
                </div>

                <div className="line" />
                <div className="botoes">
                    <button onClick={closeModal} className="btn-cancel" id="cancelar">Cancelar</button>
                    <button onClick={submitValidationFields} className="btn-make" id="criar-usuario">Enviar</button>
                </div>
            </div>
        </div >
    );
}
