import * as Unicons from '@iconscout/react-unicons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import MenuDetalhe from '../../../components/MenuDetalhe';
import ModalCreate from '../../../components/ModalCreate';
import '../style.css';

const FranquiaCreateStep13: React.FunctionComponent = () => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container">
      <Header />
      <Menu />
      <MenuDetalhe />
      <div className="franquia" style={{ top: 70 }}>
        <div className="franquia-row">
          <h5>Revisão de Dados</h5>
          <div className="row-revisao">
            <ul className="menu-revisao">
              <li className="selected">
                Dados
              </li>
              <li>
                Localização
              </li>
              <li>
                Contrato
              </li>
              <li>
                Responsaveis
              </li>
              <li>
                Documentos
              </li>
              <li>
                Classificação
              </li>
            </ul>
            <div className="box-revisao">
              <div className="dados">
                {/* <button>Editar</button> */}
                <h4>Dados Básicos</h4>
                <div className="input">
                  <span>Nome da Franquia</span>
                  <input type="text" placeholder="Rio de janeiro III" />
                </div>
                <div className="dados-franquia-inputs">
                  <div className="input">
                    <span>Status</span>
                    <select name="" id="">
                      <option value="">Ativo</option>
                    </select>
                  </div>
                  <div className="input">
                    <span>CNPJ</span>
                    <input type="text" placeholder="000000000" />
                  </div>
                  <div className="input">
                    <span>Inscrição Estadual</span>
                    <input type="text" placeholder="000000000" />
                  </div>
                </div>
                <div className="botoes" style={{ paddingBottom: 30 }}>
                  <Link to="/franquias/create/step-9">
                    <div className="voltar"><span>Cancelar</span></div>
                  </Link>
                  <Link to="/franquias/create/step-11">
                    <div className="proximo"><span>Salvar Alterações</span></div>
                  </Link>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                {/* <button>Editar</button> */}
                <h4>Localização</h4>
                <div className="input">
                  <span>CEP</span>
                  <input type="text" placeholder="00000-000" />
                </div>
                <div className="dados-franquia-inputs">
                  <div className="input" style={{ width: '75%' }}>
                    <span>Endereço</span>
                    <input type="text" placeholder="Rua Alexandre Passos, Barra da Tijuca" />
                  </div>
                  <div className="input" style={{ width: '24%' }}>
                    <span>Número</span>
                    <input type="text" placeholder="413" />
                  </div>
                </div>
                <div className="input">
                  <span>Complemento</span>
                  <input type="text" placeholder="Bloco 1, sala 1243" />
                </div>
                <div className="botoes" style={{ paddingBottom: 30 }}>
                  <Link to="/franquias/create/step-9">
                    <div className="voltar"><span>Cancelar</span></div>
                  </Link>
                  <Link to="/franquias/create/step-11">
                    <div className="proximo"><span>Salvar Alterações</span></div>
                  </Link>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                {/* <button>Editar</button> */}
                <h4>Contrato</h4>
                <div className="input-revisao">
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Assiantura</span>
                      <input type="text" placeholder="21/10/2012" />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Abertura</span>
                      <input type="text" placeholder="21/10/2012" />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                </div>
                <div className="input-revisao">
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Renovação</span>
                      <input type="text" placeholder="21/10/2012" />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Término</span>
                      <input type="text" placeholder="21/10/2012" />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                </div>
                <div className="botoes" style={{ paddingBottom: 30 }}>
                  <Link to="/franquias/create/step-9">
                    <div className="voltar"><span>Cancelar</span></div>
                  </Link>
                  <Link to="/franquias/create/step-11">
                    <div className="proximo"><span>Salvar Alterações</span></div>
                  </Link>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                {/* <button>Editar</button> */}
                <h4>Responsaveis</h4>
                <h4>Responsável legal</h4>
                <div className="dados-franquia">
                  <div className="box-selected">
                    <div className="circle"><div className="circle-selected"></div></div>
                    <div className="box-text">
                      <h4>Mariana Paz Silva Maria</h4>
                      <div className="tags">
                        Responsável legal
                      </div>
                      <span>Ver mais detalhes</span>
                    </div>
                  </div>
                </div>
                <h4>Gestor</h4>
                <div className="dados-franquia">
                  <div className="box-selected">
                    <div className="circle"><div className="circle-selected"></div></div>
                    <div className="box-text">
                      <h4>Mariana Paz Silva Maria</h4>
                      <div className="tags">
                        Gestor
                      </div>
                      <span>Ver mais detalhes</span>
                    </div>
                  </div>
                </div>
                <button className="novo-usuario">
                  <Unicons.UilPlus size="20" color="#895CF2" />
                  <span>Criar novo Usuário</span>
                </button>
                <div className="busca">
                  <Unicons.UilSearch size="20" color="#6A7C96" />
                  <input type="text" placeholder="Buscar Franquia" />
                </div>
                <div className="botoes" style={{ paddingBottom: 30 }}>
                  <Link to="/franquias/create/step-9">
                    <div className="voltar"><span>Cancelar</span></div>
                  </Link>
                  <Link to="/franquias/create/step-11">
                    <div className="proximo"><span>Salvar Alerações</span></div>
                  </Link>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Documentos</h4>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>COF</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc-red">
                    <Unicons.UilTimes size="16" color="#FFFFFF" />
                  </div>
                  <p>Contrato</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão Negativa de Débito da Receita Federal e Estadual</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão Negativa da Prefeitura</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão dos Distribuidores Fiscal, Civel, Trabalhista e <br></br>Criminal nas esferas Estadual e Federal</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão de Protesto</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Antecedentes criminais</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Declaração de Imposto de Renda dos últimos 2 anos</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos" style={{ paddingBottom: 30 }}>
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Laudo de vistoria dos bombeiros</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                {/* <button>Editar</button> */}
                <h4>Classificação de unidade</h4>
                <h4 style={{ marginBottom: 0 }}>Status</h4>
                <div className="status">
                  <div className="status-row">
                    <div className="check-selected">
                      <div className="circle"></div>
                    </div>
                    <span>Ativo</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Implantação</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Suspenso</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Em distrato</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Fechada</span>
                  </div>
                </div>
                <h4>Negocios da unidade</h4>
                <div className="botoes" style={{ marginBottom: 12 }}>
                  <button className="selected">
                    <Unicons.UilCheck size="20" color="#FFFFFF" className="status-icon" />
                    Happy Code
                  </button>
                  <button>
                    <Unicons.UilSquareFull size="20" color="#CBD4ED" className="status-icon" />
                    Happy Money
                  </button>
                </div>
                <div className="botoes" style={{ marginBottom: 12 }}>
                  <button className="button">
                    <Unicons.UilSquareFull size="20" color="#CBD4ED" className="status-icon" />
                    Happy Speech
                  </button>
                  <button className="button">
                    <Unicons.UilSquareFull size="20" color="#CBD4ED" className="status-icon" />
                    Happy School
                  </button>
                  <button className="selected-button">
                    <Unicons.UilCheck size="20" color="#FFFFFF" className="status-icon" />
                    Happy Bussiness
                  </button>
                </div>
                <h4 style={{ marginBottom: 0 }}>Modelo</h4>
                <div className="status">
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Go</span>
                  </div>
                  <div className="status-row">
                    <div className="check-selected">
                      <div className="circle"></div>
                    </div>
                    <span>Fit</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Labs</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Standar</span>
                  </div>
                  <div className="status-row">
                    <div className="check"></div>
                    <span>Premium</span>
                  </div>
                </div>
                <h4>Alocação de precificação</h4>
                <div className="box-select">
                  <div className="info">
                    <span>Alocação de precificação</span>
                    <select name="" id="">
                      <option value="">G2</option>
                    </select>
                  </div>
                  <Unicons.UilAngleDown size="20" color="rgba(52, 68, 83, 0.5)" />
                </div>
                <h4>Clusterização</h4>
                <div className="box-select">
                  <div className="info">
                    <span>Clusterização</span>
                    <select name="" id="">
                      <option value="">G2</option>
                    </select>
                  </div>
                  <Unicons.UilAngleDown size="20" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
            </div>
          </div>
          <div className="botoes" style={{ paddingBottom: 30 }}>
            <Link to="/franquias/create/step-12">
              <button className="voltar"><span>Voltar</span></button>
            </Link>
            <button onClick={() => setIsOpen(true)} className="proximo"><span>Próximo</span></button>
          </div>
        </div>
      </div>
      <Link to="/franquias/create/step-12">
        <div className="botao-voltar-revisao">
          <Unicons.UilArrowLeft size="20" color="#895CF2" />
          <span>Voltar</span>
        </div>
      </Link>
      <ModalCreate
        open={isOpen}
      //onClose={() => setIsOpen(false)} 
      />
    </div>
  )
}

export default FranquiaCreateStep13;