import { Button, Form } from "antd";
import { Link, useHistory } from "react-router-dom";

const EscolaFormActionComponent = ({ revisao, rotaAnterior }: any) => {

  const history = useHistory();

  return <div className="botoes">
    {revisao ?
      <>
        <Form.Item name="revisao" initialValue="1" />
        <Button type="primary" htmlType="submit" className="button-primary" id="revisao" style={{ width: 345 }}>Voltar para Revisão</Button>
      </>
      : <>
        <Button className="button-second" id="voltar" style={{ width: 345 }} onClick={() => {
          history.replace(rotaAnterior);
        }}>Voltar</Button>
        <button type="submit" className="button-primary" id="proximo" style={{ width: 345 }}><span>Próximo</span></button>
      </>
    }
  </div>
}

export default EscolaFormActionComponent;
