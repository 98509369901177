import {
	Button,
	Checkbox,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Select,
} from 'antd';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	IGrupoContratoFullDTO,
	ILeadEscolaFullDTO,
} from '../../../models/happy-code-api.model';
import { useGrupoContratoService } from '../../../services/grupo-contrato.service';
import { useGrupoService } from '../../../services/grupo.service';
import { useLeadEscolaService } from '../../../services/lead-escola.service';
import { useNotificationService } from '../../../services/notification.service';
import '../../../theme/flex.scss';
import { currencyFormatter, currencyParser } from '../../../util/format';
import EscolaGruposFormActionComponent from '../components/escola-grupos-form-action-component/escola-grupos-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-grupos-form-fechar-component/escola-grupos-form-fechar.component';
import MenuLateralCreateContratosComponent from '../components/menu-lateral-create-contrato-component/menu-lateral-create-contrato.component';
import '../styles/escola-grupo-create-step.styles.scss';
import { buildTimeInterval } from '../../../util/buildTimeInterval';

const GrupoContratoCreateStep1Screen: React.FunctionComponent = () => {
	const {
		contratoParaCadastrar,
		setContratoParaCadastrar,
		grupoContratoTipos,
		flRevisao,
		servicosAcademicos,
	} = useEscolaContext();

	const history = useHistory();
	const [form] = Form.useForm();
	const { idLead } = useParams<any>();
	const [dtInicio, setDtInicio] = useState<any>();
	const [dtFim, setDtFim] = useState<any>();
	const { idGrupo } = useParams<any>();
	const grupoService = useGrupoService();
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const [valorServico, setValorServico] = useState<number>(0);
	const [valorLicencaIntra, setValorLicencaIntra] = useState<number>(0);
	const [qtdLicencaIntra, setQtdLicencaIntra] = useState<number>(0);
	const [valorLicencaExtra, setValorLicencaExtra] = useState<number>(0);
	const [qtdLicencaExtra, setQtdLicencaExtra] = useState<number>(0);
	const [lead, setLead] = useState<ILeadEscolaFullDTO>();
	const contratoService = useGrupoContratoService();
	const leadEscolaService = useLeadEscolaService();
	const [cobrancaAutomaticaIsChecked, setCobrancaAutomaticaIsChecked] =
		useState<boolean>(false);

	const definirLicencas = (contratoParaCadastrar) => {
		setValorLicencaIntra(contratoParaCadastrar?.valorLicencaIntra || 0);
		setQtdLicencaIntra(contratoParaCadastrar?.qtdLicencaIntra || 0);
		setValorLicencaExtra(contratoParaCadastrar?.valorLicencaExtra || 0);
		setQtdLicencaExtra(contratoParaCadastrar?.qtdLicencaExtra || 0);
		setValorServico(contratoParaCadastrar?.valorServico || 0);
	};

	useEffect(() => {
		if (lead) {
			if (!valorLicencaIntra)
				setValorLicencaIntra(lead.precoPropostoIntra || 0);

			if (!valorLicencaExtra)
				setValorLicencaExtra(lead.precoPropostoExtra || 0);
		}
	}, [lead]);

	const fetchLead = () => {
		leadEscolaService.findFullById({ id: idLead }).then(({ data }) => {
			setLead(data);
		});
	};

	const trocarServico = (servico) => {
		if (servico) {
			if (!valorServico) setValorServico(25);
		} else setValorServico(0);

		contratoParaCadastrar.servico = servico;
		setContratoParaCadastrar(contratoParaCadastrar);
	};

	const fetchData = (idGrupo: number) => {
		setIsGlobalLoading(true);
		return grupoService.findCleanById({ id: idGrupo }).finally(() => {
			setIsGlobalLoading(false);
		});
	};

	useEffect(() => {
		if (!isNull(idGrupo) && !isUndefined(idGrupo)) {
			fetchData(idGrupo).then((response) => {
				if (response.data)
					setContratoParaCadastrar((contrato) => ({
						...contrato,
						grupo: response.data,
					}));
			});
		}
	}, [idGrupo]);

	useEffect(() => {
		if (!dtInicio || !dtFim) {
			setDtFim(contratoParaCadastrar?.vigenciaFim);
			setDtInicio(contratoParaCadastrar?.vigenciaInicio);
		}

		if (idLead) fetchLead();

		definirLicencas(contratoParaCadastrar);
	}, []);

	const onSubmitForm = (form) => {
		if (!dtInicio || !dtFim)
			return notification({
				description: 'Preencha as datas de vigências',
				type: 'error',
				message: 'Atenção!',
			});

		contratoParaCadastrar.valorLicencaIntra = valorLicencaIntra;
		contratoParaCadastrar.qtdLicencaIntra = qtdLicencaIntra;
		contratoParaCadastrar.valorLicencaExtra = valorLicencaExtra;
		contratoParaCadastrar.qtdLicencaExtra = qtdLicencaExtra;
		contratoParaCadastrar.valorServico = valorServico;

		setContratoParaCadastrar((contratoParaCadastrar: IGrupoContratoFullDTO) => {
			const tipo = grupoContratoTipos.find(({ id }) => id === form.tipo.id);
			const dsTempoTotal = buildTimeInterval(dtInicio, dtFim);

			return {
				...contratoParaCadastrar,
				...form,
				vigenciaFim: dtFim,
				vigenciaInicio: dtInicio,
				dsTempoTotal,
				tipo,
			};
		});

		if (flRevisao) {
			return history.push(`/escolas/grupos/${idGrupo}/contratos/create/step-4`);
		}
		history.push(`/escolas/grupos/${idGrupo}/contratos/create/step-2`);
	};

	const salvar = () => {
		setIsGlobalLoading(true);

		const contrato: any = {
			id: contratoParaCadastrar.id,
			descricao: form.getFieldValue('descricao'),
			emails: form.getFieldValue('emails'),
			observacoes: form.getFieldValue('observacoes'),
			envioAutomatico: contratoParaCadastrar?.envioAutomatico,
		};

		contratoService
			.patchClean(contrato)
			.then(({ data }) => {
				contratoParaCadastrar.descricao = data.descricao;
				contratoParaCadastrar.emails = data?.emails?.replaceAll(" ", "");
				contratoParaCadastrar.observacoes = data.observacoes;
				contratoParaCadastrar.envioAutomatico = data.envioAutomatico;

				setContratoParaCadastrar(contratoParaCadastrar);
				history.goBack();
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const checkboxCobrancaAutomaticaOnChange = () => {
		setContratoParaCadastrar((previousState) => ({
			...previousState,
			envioAutomatico: !previousState.envioAutomatico,
		}));
	};
	const divLicencaIntra = () => {
		return (
			<div
				className="line"
				style={{ display: 'flex', flex: 1, width: '100%', marginBottom: 10 }}
			>
				<div className="flex flex-1">
					<div className="input-franquia">
						<span>Quantidade de intra</span>

						<InputNumber
							placeholder={'Digite a quantidade de licenças intra'}
							min={0}
							value={qtdLicencaIntra}
							onChange={(qtd) => setQtdLicencaIntra(qtd)}
							disabled={contratoParaCadastrar?.id != null}
						/>
					</div>
				</div>
				<div className="flex flex-1">
					<div className="input-franquia">
						<span>Valor unitário</span>

						<InputNumber
							placeholder={'Digite o valor unitário da licença intra'}
							min={0}
							decimalSeparator=","
							formatter={currencyFormatter}
							parser={currencyParser}
							value={valorLicencaIntra}
							onChange={setValorLicencaIntra}
							disabled={contratoParaCadastrar?.id != null}
						/>
					</div>
				</div>
			</div>
		);
	};

	const divLicencaExtra = () => {
		return (
			<div
				className="line"
				style={{ display: 'flex', flex: 1, width: '100%', marginBottom: 10 }}
			>
				<div className="flex flex-1">
					<div className="input-franquia">
						<span>Quantidade de extra</span>

						<InputNumber
							placeholder={'Digite a quantidade de licenças extra'}
							min={0}
							value={qtdLicencaExtra}
							onChange={(qtd) => setQtdLicencaExtra(qtd)}
							disabled={contratoParaCadastrar?.id != null}
						/>
					</div>
				</div>
				<div className="flex flex-1">
					<div className="input-franquia">
						<span>Valor unitário</span>

						<InputNumber
							placeholder={'Digitre o valor unitário da licença extra'}
							value={valorLicencaExtra}
							onChange={setValorLicencaExtra}
							decimalSeparator=","
							formatter={currencyFormatter}
							parser={currencyParser}
							disabled={contratoParaCadastrar?.id != null}
						/>
					</div>
				</div>
			</div>
		);
	};

	const componentLicencas = () => {
		return (
			<>
				<span
					style={{
						color: '#344453',
						fontSize: 16,
						fontWeight: 600,
						paddingBottom: 10,
					}}
				>
					Licenças contratadas
				</span>
				{divLicencaIntra()}
				{divLicencaExtra()}
				<div className="cobranca-automatica-wrapper">
					<span className="cobranca-automatica-wrapper__label">
						Envio automático de cobrança?
					</span>
					<Checkbox
						id="cobranca-automatica"
						checked={contratoParaCadastrar?.envioAutomatico}
						onChange={checkboxCobrancaAutomaticaOnChange}
					>
						{contratoParaCadastrar?.envioAutomatico ? 'Ativado' : 'Desativado'}
					</Checkbox>
				</div>
			</>
		);
	};

	const componentVigencia = () => {
		return (
			<>
				<span
					style={{
						color: '#344453',
						fontSize: 16,
						fontWeight: 600,
						paddingTop: 10,
						paddingBottom: 10,
					}}
				>
					Vigência
				</span>

				<div
					className="line"
					style={{ display: 'flex', flex: 1, width: '100%', marginBottom: 20 }}
				>
					<div className="flex flex-1">
						<div className="input-box" style={{ width: '100%', marginTop: 15 }}>
							<h6>Data Início</h6>

							<DatePicker
								style={{ width: '100%' }}
								format={'DD-MM-YYYY'}
								onChange={(e) => setDtInicio(e)}
								defaultValue={
									contratoParaCadastrar?.vigenciaInicio
										? moment(contratoParaCadastrar?.vigenciaInicio)
										: null
								}
								disabled={contratoParaCadastrar?.id != null}
								getPopupContainer={(triggerNode) => {
									return triggerNode.parentElement;
								}}
							/>
						</div>
					</div>
					<div style={{ width: 10, flex: 'unset' }} />
					<div className="flex flex-1">
						<div className="input-box" style={{ width: '100%', marginTop: 15 }}>
							<h6>Data Fim</h6>

							<DatePicker
								style={{ width: '100%' }}
								format={'DD-MM-YYYY'}
								onChange={(e) => setDtFim(e)}
								defaultValue={
									contratoParaCadastrar?.vigenciaFim
										? moment(contratoParaCadastrar?.vigenciaFim)
										: null
								}
								disabled={contratoParaCadastrar?.id != null}
								getPopupContainer={(triggerNode) => {
									return triggerNode.parentElement;
								}}
							/>
						</div>
					</div>
				</div>

				<div className="input-franquia ">
					<span>Tempo total</span>
					<Input
						className="input"
						value={buildTimeInterval(dtInicio, dtFim)}
						contentEditable={false}
					/>
				</div>
			</>
		);
	};

	const componentServicos = () => {
		return (
			<>
				<span
					style={{
						color: '#344453',
						fontSize: 16,
						fontWeight: 600,
						paddingBottom: 10,
					}}
				>
					Serviços Acadêmicos
				</span>

				<div
					className="line"
					style={{ display: 'flex', flex: 1, width: '100%', marginBottom: 10 }}
				>
					<div className="flex flex-1">
						<div className="input-franquia">
							<span>Serviços</span>

							<Form.Item
								name={['servico', 'id']}
								fieldKey={['servico', 'id']}
								initialValue={contratoParaCadastrar?.servico?.id}
								// rules={[{ required: true, message: 'Selecione o serviço' }]}
							>
								<Select
									id="tipo"
									allowClear
									onChange={trocarServico}
									disabled={contratoParaCadastrar?.id != null}
								>
									{servicosAcademicos?.map((item) => {
										return (
											<Select.Option key={item.id} value={item.id}>
												{item.descricao}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</div>
					</div>

					<div
						className="line"
						style={{ display: 'flex', flex: 1, width: '100%' }}
					>
						<div className="flex flex-1">
							<div className="input-franquia">
								<span>Valor mensal do serviço</span>

								<InputNumber
									placeholder={'Digite o valor mensal do serviço'}
									min={0}
									decimalSeparator=","
									formatter={currencyFormatter}
									parser={currencyParser}
									value={valorServico}
									onChange={setValorServico}
									disabled={contratoParaCadastrar?.id != null}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="container" id="escola-grupo-create-step">
			<Header />
			<Progress />
			<MenuLateralCreateContratosComponent current={1} />
			<Form
				form={form}
				name="control-hooks"
				initialValues={contratoParaCadastrar}
				onFinish={onSubmitForm}
			>
				<div className="franquia">
					<div className="franquia-row">
						<h5>Oferta</h5>
						<div
							className="col"
							style={{
								display: 'flex',
								flex: 1,
								width: '100%',
								marginBottom: 10,
							}}
						>
							<div className="input-franquia">
								<span>Descrição do contrato</span>
								<Form.Item
									name="descricao"
									initialValue={contratoParaCadastrar?.descricao}
									rules={[{ required: true, message: 'Descrição do contrato' }]}
								>
									<Input id="descricao" className="input" maxLength={500} />
								</Form.Item>
							</div>
						</div>

						<div className="input-franquia ">
							<span>Tipo de contrato</span>
							<Form.Item
								name={['tipo', 'id']}
								fieldKey={['tipo', 'id']}
								initialValue={contratoParaCadastrar?.tipo?.id}
								rules={[{ required: true, message: 'Tipo de contrato' }]}
							>
								<Select
									id="tipo"
									allowClear
									disabled={contratoParaCadastrar?.id != null}
								>
									{grupoContratoTipos?.map((item) => {
										return (
											<Select.Option key={item.id} value={item.id}>
												{item.descricao}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</div>
						{componentVigencia()}
						{componentLicencas()}
						{componentServicos()}
						<div className="input-franquia">
							<span>E-mails para cobrança</span>
							<Form.Item
								name="emails"
								initialValue={contratoParaCadastrar?.emails?.replaceAll(" ", "")}
							>
								<Input.TextArea
									className="input"
									rows={2}
									cols={100}
									maxLength={200}
								/>
							</Form.Item>
						</div>
						<div
							className="col"
							style={{
								display: 'flex',
								flex: 1,
								width: '100%',
								marginBottom: 10,
							}}
						>
							<div className="input-franquia">
								<span>Observações</span>

								<Form.Item
									name="observacoes"
									initialValue={contratoParaCadastrar?.observacoes}
									rules={[{ message: 'Observações' }]}
								>
									<Input.TextArea
										id="observacoes"
										className="input"
										rows={3}
										cols={100}
										maxLength={500}
									/>
								</Form.Item>
							</div>
						</div>
						{contratoParaCadastrar?.id ? (
							<div className="botoes">
								<Button
									className="button-second"
									onClick={() => history.goBack()}
								>
									Cancelar alterações
								</Button>
								<Button className="button-primary" onClick={() => salvar()}>
									Salvar
								</Button>
							</div>
						) : (
							<EscolaGruposFormActionComponent
								revisao={flRevisao}
								rotaAnterior={`/escolas/grupos/${idGrupo}/contratos`}
							/>
						)}
					</div>
				</div>
			</Form>
			<EscolaGruposFormFecharComponent
				dsLink={`/escolas/grupos/${idGrupo}/contratos`}
				onClick={() => setContratoParaCadastrar(null)}
			/>
		</div>
	);
};

export default GrupoContratoCreateStep1Screen;
