import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../../../enum/operators.enum';
import { Predicate } from '../../../../models/predicate.model';

interface TFiltrosConecta {
    predicate: Predicate;
    findConecta: () => void;
}

const FiltrosConecta = memo(({ predicate, findConecta }: TFiltrosConecta) => {
    const [nome, setNome] = useState<string>();
    const [status, setStatus] = useState<number>();

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (nome) {
                predicate.addOption('nome', nome, Operators.CONTAINS);
            }
            if (status === null || status === undefined) {
                predicate.addOption('ativo', true);
            }
            if (status) {
                predicate.addOption('ativo', status === 1 ? true : false);
            }
            findConecta();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nome, status]);

    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '85%' }}>
                <Input
                    id="buscar"
                    value={nome}
                    placeholder="Buscar Curso"
                    prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />}
                    onChange={e => setNome(e.target.value)}
                />
            </div>
            <div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
                <span>Status</span>
                <Select className="ant-dropdown-link" style={{ width: '100%' }} onChange={(e: number) => setStatus(e)}>
                    <Select.Option value={0}>Todos</Select.Option>
                    <Select.Option key={1} value={1}>Ativo</Select.Option>
                    <Select.Option key={2} value={2}>Inativo</Select.Option>
                </Select>
            </div>
        </div>
    </>
    )
})

FiltrosConecta.displayName = 'FiltrosTrilhaWebinar'

export default FiltrosConecta;

