import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IUnidadeTipoPagamentoCleanDTO,
	IUnidadeTipoPagamentoDTO,
	IUnidadeTipoPagamentoFullDTO
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useUnidadeTipoPagamentoService = () => {
	const resourceURL = 'unidade-tipo-pagamento';

	const custom = (predicate: Predicate = new Predicate()): Promise<AxiosResponse<IUnidadeTipoPagamentoDTO[]>> => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	};


	return {
		...baseCreate<IUnidadeTipoPagamentoCleanDTO, IUnidadeTipoPagamentoDTO, IUnidadeTipoPagamentoFullDTO>(resourceURL),
		...baseFindById<IUnidadeTipoPagamentoCleanDTO, IUnidadeTipoPagamentoDTO, IUnidadeTipoPagamentoFullDTO>(resourceURL),
		...baseFind<IUnidadeTipoPagamentoCleanDTO, IUnidadeTipoPagamentoDTO, IUnidadeTipoPagamentoFullDTO>(resourceURL),
		...baseUpdate<IUnidadeTipoPagamentoCleanDTO, IUnidadeTipoPagamentoDTO, IUnidadeTipoPagamentoFullDTO>(resourceURL),
		...baseFindList<IUnidadeTipoPagamentoCleanDTO, IUnidadeTipoPagamentoDTO, IUnidadeTipoPagamentoFullDTO>(resourceURL),
		...baseDelete(resourceURL),
		custom
	};
};
