import { Button, InputNumber } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import fondo from '../../assets/img/matriculas/fondo.svg';
import Modal from '../../components/Modal';
import NewMenu from "../../components/NewMenu";
import { useCursoFranquiaContext } from '../../context/CursoFranquiaContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCursoUnidadeService } from '../../services/curso-unidade.service';
import { useHoraUnidadeService } from '../../services/hora-unidade.service';
import { useLogService } from '../../services/log.service';
import { useNegocioService } from '../../services/negocio.service';
import { useNotificationService } from '../../services/notification.service';
import { currencyFormatter, currencyParser } from '../../util/format';
import { IHoraUnidadeFullDTO, ILogCleanDTO, INegocioCleanDTO } from './../../models/happy-code-api.model';
import './style.css';
moment().locale('pt-br');

export const CursoFranquiaRevisao: React.FunctionComponent = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<PageableResponse<IHoraUnidadeFullDTO>>();
    const [descricao, setDescricao] = useState<string>();
    const cursoUnidadeService = useCursoUnidadeService();
    const negocio = useNegocioService();
    const horaUnidadeService = useHoraUnidadeService();
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const history = useHistory();
    const [cursoPredicate] = useState<Predicate>(new Predicate(6));
    const [negocioPredicate] = useState<Predicate>(new Predicate(6));
    const { turma, setTurma } = useTurmaContext();
    const { oferta, setOferta } = useCursoFranquiaContext();
    const notification = useNotificationService();


    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso tela cursos franquia",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
        setOferta(oferta)
    }, [])

    const findCurso = () => {
        negocioPredicate.addOption('ativo', true)
        negocioPredicate.addOption('unidade.id', unidade.id)
        return horaUnidadeService.findFull(negocioPredicate)
            .then((response) => {
                setOferta(response.data.content[0]);
            });
    }

    useEffect(() => {
        if (!unidade.id) return;

        setIsGlobalLoading(true);
        findCurso().finally(() => setIsGlobalLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [unidade.id]);


    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        setIsGlobalLoading(true);
        negocioPredicate.setPage(page);
        findCurso().finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (descricao !== undefined) {
                setIsGlobalLoading(true);
                negocioPredicate.addOption('negocio.descricao', descricao, Operators.CONTAINS);
                findCurso().finally(() => setIsGlobalLoading(false));
            }
        }, 1500);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao]);

    const handleSubmit = (negocio: INegocioCleanDTO) => {
        if (negocio) {
            turma.curriculoNegocioId = negocio?.id
            setTurma(turma);
            history.push("/curso-franquia/turma");
        }
    }

    return (
        <div className="container-fondo" style={{ paddingTop: 0 }}>
            <NewMenu selecionado={12} />
            <div className="franquia" id="aluno-detalhe-row">
                <div className="user-row" style={{ width: 900, zIndex: 2 }}>
                    <div className="container-rev">
                        <div className="card-form">
                            <h3>Revisão de dados</h3>
                            <h3 id="taxas-h3">1. Oferta para uma hora e meia</h3>
                            <Link to="/curso-franquia"><Button className="button-line" style={{ position: "absolute", top: "15px", right: '15px' }} >Editar </Button></Link>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    value={oferta?.valorOfertaUmaHoraMeia}
                                    disabled={true}

                                />
                            </div>
                            <h3 id="taxas-h3">2. Oferta para três horas</h3>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    value={oferta?.valorOfertaTresHora}
                                    disabled={true}

                                />
                            </div>
                            <h3 id="taxas-h3">3. Oferta para quatro horas e meia</h3>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    disabled={true}
                                    value={oferta?.valorOfertaQuatroHoraMeia}
                                />
                            </div>
                            <h3 id="taxas-h3">4. Oferta para seis horas</h3>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    disabled={true}
                                    value={oferta?.valorOfertaSeisHora}
                                />
                            </div>
                            <h3 id="taxas-h3">5. Oferta valor adicional</h3>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    disabled={true}
                                    value={oferta?.valorAdicionalOferta}
                                />
                            </div>
                            <h3 id="taxas-h3">6. Oferta para Curta</h3>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    disabled={true}
                                    value={oferta?.valorOfertaCurta}
                                />
                            </div>
                            <h3 id="taxas-h3">7. Oferta para Colônia</h3>
                            <div className="input-box-rev">
                                <InputNumber
                                    id="valor"
                                    style={{ width: '100%', backgroundColor: "transparent", fontSize: "1.1rem", marginBottom: '45px' }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    min={0}
                                    disabled={true}
                                    value={oferta?.valorOfertaColonia} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a adição desta Nova Matricula?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/alunos'
            />
            <img src={fondo} alt="" className="img-fondo" />
        </div>
    );
}