import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useColaboradorContext } from '../../context/ColaboradorContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { TipoUnidadePessoa } from '../../enum/tipoUnidadePessoa';
import { ITipoUnidadePessoaCleanDTO, IUnidadePessoaCleanDTO } from '../../models/happy-code-api.model';
import { useTipoUnidadePessoaService } from '../../services/tipo-unidade-pessoa.service';
import StepsCreate from './steps-create';
import './style.css';

export const ColaboradoresCreateStep2: React.FunctionComponent = () => {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const [selected, setSelected] = useState<number>();
	const [funcoesList, setFuncoesList] = useState<ITipoUnidadePessoaCleanDTO[]>(
		[]
	);
	const tipoUnidadePessoaService = useTipoUnidadePessoaService();
	const { colaborador, setColaborador, revisao, removeColaborador } =
		useColaboradorContext();

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const fetchInitialFuncaoData = async () => {
			setIsGlobalLoading(true);
			try {
				const { data: fetchFuncaoList } =
					await tipoUnidadePessoaService.findCleanList();
				setFuncoesList(fetchFuncaoList);
				setIsGlobalLoading(false);
			} catch (error) {
				//
			}
		};
		fetchInitialFuncaoData();
		if (colaborador.unidadePessoas.length > 0) {
			setSelected(colaborador.unidadePessoas[0].tipoUnidadePessoaId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRoleSelected = (funcao: ITipoUnidadePessoaCleanDTO) => {
		setSelected(funcao.id);

		const colaboradorCopy = { ...colaborador };

		colaboradorCopy.unidadePessoas.splice(
			0,
			colaboradorCopy.unidadePessoas.length
		);

		const unidadePessoa: IUnidadePessoaCleanDTO = {
			ativo: true,
			dataAdmissao: colaboradorCopy.dataAdmissao,
			id: null,
			tipoUnidadePessoaId: funcao.id,
			tipoUnidadePessoaDescricao: funcao.descricao,
			unidadeId: unidade.id,
			statusUnidadePessoaId: 1,
			pessoaId: colaboradorCopy.id,
			pessoaNome: colaboradorCopy.nome,
			tipoUnidadePessoaCodigo: ''
		}
		colaboradorCopy.unidadePessoas.push(unidadePessoa);

		setColaborador(colaboradorCopy);
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={50}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={1} />
			<div className="franquia">
				<div className="user-row" style={{ width: "50%" }}>
					<h3>Selecione a função do colaborador</h3>
					<div style={{ flexWrap: "wrap" }} className="boxes">
						{funcoesList && funcoesList.filter(funcao => funcao.codigo !== TipoUnidadePessoa.CODIGO_SOC).map((funcoes) => {
							return (
								<button
									key={funcoes.id}
									className={selected === funcoes.id ? 'box-selected' : 'box'}
									onClick={() => handleRoleSelected(funcoes)}
								>
									<Unicons.UilHouseUser
										size="20"
										color={selected === funcoes.id ? '#FFF' : 'var(--primary-color'}
									/>
									<h4>{funcoes.codigo}</h4>
									<p>{funcoes.descricao}</p>
								</button>
							);
						})}
					</div>
					<div className="botoes">
						{revisao ? (
							<Link to="/colaboradores/create/step-4">
								<Button type="primary" className="button-primary" style={{ width: 345 }}>Voltar para Revisão</Button>
							</Link>
						) : (
							<>
								<Link to="/colaboradores/create/step-1">
									<Button className="button-second" style={{ width: 345 }}>Voltar</Button>
								</Link>
								<Link to="/colaboradores/create/step-3">
									<Button className="button-primary" style={{ width: 345 }}>Próximo</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				id="cancelar"
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
					removeColaborador();
				}}
				title="Você deseja cancelar a adição de um novo colaborador?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/colaboradores"
			/>
		</div>
	);
};
