
import http from '../helpers/http-common';
import { ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyTrilha = () => {
	const resourceURL = '/trilha';

	const editarArquivo = (
		trilhaId: number,
		body: any,
	): Promise<IAxiosResponseCustom<ITrilhaDTO>> =>
		http.patch(`${resourceURL}/${trilhaId}`, body);

	const enviarArquivo = (
		body: any,
	): Promise<IAxiosResponseCustom<ITrilhaDTO>> =>
		http.post(`${resourceURL}`, body);

	const editarArquivoCustom = (
		trilhaId: number,
		body: any,
	): Promise<IAxiosResponseCustom<ITrilhaDTO>> =>
		http.patch(`${resourceURL}/custom/${trilhaId}`, body);

	return {
		...baseFind<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
		...baseFindList<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
		...baseFindById<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
		...baseCreate<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
		...baseUpdate<ITrilhaCleanDTO, ITrilhaDTO, ITrilhaFullDTO>(resourceURL),
		editarArquivo,
		enviarArquivo,
		editarArquivoCustom,


		/*
		createFull: (body): Promise<IAxiosResponseCustom<ITrilhaCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<ITrilhaCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<ITrilhaCleanDTO>> =>
		http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		*/
	};
};
