import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { ITurmaDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { useTurmaService } from '../../services/turma.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';

interface Props {
    turma: ITurmaFullDTO;
}

const ItemListCurriculo: React.FunctionComponent<Props> = ({ turma }: Props) => {
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();
    const { setTurma } = useTurmaContext();
    const turmaService = useTurmaService();
    const history = useHistory();
    const professorPath = '/turmas/professor';

    const isProfessorPath = () => {
        if (location.pathname === professorPath) {
            return true;
        }
        return false;
    }

    const statusTurma = (turma: ITurmaDTO) => {

        if (!turma.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-vermelho"></div>
                    <p className='inativo' style={{ marginRight: 10 }}>Inativo</p>
                </div>
            )
        }

        if (isBeforeDate(turma.dataInicio)) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className='standby' style={{ marginRight: 10 }}>Não Iniciado</p>
                </div>
            )
        }
        if (isDateBetween(turma.dataInicio, turma.dataFim)) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className='ativo' style={{ marginRight: 10 }}>Em andamento</p>
                </div>
            )
        }

        if (isAfterDate(turma.dataFim)) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className='ativo' style={{ marginRight: 10 }}>Concluído</p>
                </div>
            )
        }
        return null;
    }

    const goToDetalhe = (turma: ITurmaDTO) => {
        if (isProfessorPath()) {
            history.push("/turmas/detalhe/professor/" + turma.id);
        } else {
            history.push("/turmas/detalhe/" + turma.id);
        }
    }

    const goToEdit = (turma: ITurmaDTO) => {
        setIsGlobalLoading(true);

        turmaService.findFullById(turma)
            .then((response) => {
                setTurma(response.data);
                history.push("/turmas/create/step-1")
            }).finally(() => setIsGlobalLoading(false));
    }

    const menu = (turma: ITurmaDTO) => {

        const showDeleteConfirm = (turma: ITurmaDTO) => {

            Modal.confirm({
                title: `Deseja realmente ${turma.ativo ? 'desativar' : 'ativar'} a turma?`,
                icon: <ExclamationCircleTwoTone rev={undefined} />,
                content: "Nome: " + turma.descricao,
                okText: 'Yes',
                okType: `${turma.ativo ? 'danger' : 'primary'}`,
                cancelText: 'No',
                onOk: () => {
                    setIsGlobalLoading(true);
                    turmaService.patchFull({ id: turma.id, ativo: turma.ativo == true ? false : true })
                        .then(() => {
                            notification({ description: 'Turma alterada com sucesso!', type: 'success', message: 'Sucesso' });
                        })
                        .finally(() => setIsGlobalLoading(false));
                },
            });
        }

        return (
            <Menu>
                <Menu.Item >
                    <a onClick={(e) => showDeleteConfirm(turma)} rel="noopener noreferrer">
                        Desativar/Ativar Turma
                    </a>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <div className="registro-user" id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '20%', paddingLeft: 10 }}>
                <div className="nome">
                    <p>{turma.descricao}</p>
                    <div className="estado-user">
                        <h4 className="ativo">{statusTurma(turma)}</h4>
                    </div>
                </div>
            </div>

            <div className="funcao-user" id="alunos" style={{ width: '15%' }}>
                <Tags tipo={turma?.curso?.id !== undefined ? turma?.curso?.descricao : turma?.curriculo?.descricaoCurriculo} />
            </div>
            <div className="funcao-user" id="turma" style={{ width: '5%' }}>
                <p>{turma.matriculas.filter(m => m.ativo === true).length}</p>
            </div>
            <div className="funcao-user" id="edade" style={{ width: '10%' }}>
                <Tags tipo={turma?.cursoModalidadeDescricao ? turma?.cursoModalidadeDescricao : turma?.salaModalidadeDescricao} />
            </div>
            {isProfessorPath() ? (
                null
            ) : (
                <div className="funcao-user" style={{ width: '20%' }}>
                    <p>{turma?.unidadePessoa?.pessoaNome}</p>
                </div>
            )}
            <div className="funcao-user" style={{ width: '10%' }}>
                <p>{moment(turma.dataInicio).format("DD/MM/YYYY")}</p>
            </div>
            <div className="funcao-user" style={{ width: '10%' }}>
                <p>{moment(turma.dataFim).format("DD/MM/YYYY")}</p>
            </div>
            <div className="funcao-user" style={{ width: '10%' }}>
                <Dropdown overlay={menu(turma)} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#6A7C96" />
                </Dropdown>

                <div onClick={() => goToDetalhe(turma)} className="registro-opcao">
                    <a id="editar">
                        <Unicons.UilEye size="20" color="#92A9CB" />
                    </a>
                </div>
                {turma?.curriculo != null ?
                    <div onClick={() => goToEdit(turma)} className="registro-opcao">
                        <a id="editar">
                            <Unicons.UilEdit size="20" color="#92A9CB" />
                        </a>
                    </div>
                    : <></>}
            </div>
        </div>
    );
}

export default ItemListCurriculo