/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { Form, Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { GrupoPessoaTipoEnum } from '../../enum/grupo-pessoa-tipo.enum';

import { IPessoaBasicoDTO, IPessoaCleanDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { usePessoaService } from '../../services/pessoa.service';
import './style.css';
import { ContatoEnum } from '../../enum/contato.enum';
import { formatCpf, formatMoney, formatPhone } from '../../util/format';

interface TInputs {
  open: boolean;
  onClose: () => void;
  modoLGPD?: boolean;
  pessoa?: IPessoaCleanDTO;
  tipo?: string;
}

export const ModalUser: React.FunctionComponent<TInputs> = ({ open, onClose, modoLGPD, pessoa, tipo }: TInputs) => {
  const pessoaService = usePessoaService();
  const { setIsGlobalLoading } = useGlobalContext()
  const asterisco = (<span style={{ color: "red" }}>*</span>);

  const [pessoaId, setPessoaId] = useState<number>();
  const [pessoaNome, setPessoaNome] = useState<string>();
  const [pessoaDataNascimento, setPessoaDataNascimento] = useState<Date>();
  const [pessoaCPF, setPessoaCPF] = useState<string>();
  const [pessoaCelular, setPessoaCelular] = useState<string>();
  const [pessoaEmailPessoal, setPessoaEmailPessoal] = useState<string>();
  const [pessoaEmailProfissional, setPessoaEmailProfissional] = useState<string>();
  const [pessoaRG, setPessoaRG] = useState<string>();

  const notification = useNotificationService();

  useEffect(() => {
    if (pessoaId) {

      pessoaService.findByIdCustom(pessoa.id).then(
        ({ data }) => {

          setPessoaNome(data.nome);
          setPessoaDataNascimento(data.dataNascimento);
          setPessoaCPF(data.cpf);
          setPessoaEmailPessoal(data.email);

          const contatosEmail = data.contatos.filter(cont => cont.tipoContatoId === ContatoEnum.EPF);

          if (contatosEmail.length > 0)
            setPessoaEmailProfissional(contatosEmail[0].descricao);

          const contatosTel = data.contatos.filter(cont => cont.tipoContatoId === ContatoEnum.CEL);

          if (contatosTel.length > 0)
            setPessoaCelular(contatosTel[0].descricao);

        }
      );

    }
  }, [pessoaId])

  useEffect(() => {

    if (open)
      setPessoaId(pessoa?.id);

  }, [])

  const salvar = () => {
    let erro = "";

    if (!pessoaNome)
      erro = "Insira o nome completo";
    else if (tipo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO && !pessoaCPF)
      erro = "Insira o cpf";
    else if (!pessoaEmailPessoal)
      erro = "Insira o email pessoal";
    else if (!pessoaEmailProfissional)
      erro = "Insira o e-mail profissional";
    else if (tipo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO && !pessoaCelular)
      erro = "Insira o telefone do focal financeiro";

    if (erro)
      notification({ message: "Erro!", description: erro, type: "error" });
    else {
      setIsGlobalLoading(true);
      if (pessoaId) {

        const pessoaBasico: IPessoaBasicoDTO = {
          id: pessoaId,
          nome: pessoaNome,
          cpf: pessoaCPF,
          dataNascimento: pessoaDataNascimento,
          email: pessoaEmailPessoal,
          emailProfissional: pessoaEmailProfissional,
          celular: pessoaCelular,
          ativo: true,
          rg: pessoaRG,        
        };

        pessoaService.patchBasico(pessoaBasico).then(
          () => {
            onClose();
          }
        ).finally(() => setIsGlobalLoading(false));

      } else {

        const payload = {
          id: null,
          codigo: pessoaNome,
          email: pessoaEmailPessoal,
          dataNascimento: pessoaDataNascimento,
          nome: pessoaNome,
          cpf: pessoaCPF,
          celular: pessoaCelular,
          contatos: [{ tipoContatoId: 4, descricao: pessoaEmailProfissional }]
        }

        pessoaService.create(payload)
          .then(() => {
            onClose();
          }).finally(() => setIsGlobalLoading(false));

      }
    }
  }

  if (!open) return null;

  return (
    <div className="modalContainer">
      <Form className="user-row" name="control-hooks">
        <div className="modalBox">
          <button onClick={onClose} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
          <h3>{pessoaId ? "Edição de usuário" : "Criação Novo Usuário"}</h3>
          <div className="inputModal">
            <div><span>Nome completo {asterisco}</span></div>

            <Input
              id="nome"
              className="input"
              value={pessoaNome}
              onChange={(e) => setPessoaNome(e.target.value)} />

          </div>
          <div className="inputModal">
            <span>CPF {!modoLGPD && asterisco}</span>

            <MaskedInput
              id="cpf"
              className="input"
              mask="111.111.111-11"
              maxLength={11}
              placeholder="000.000.000-00"
              value={formatCpf(pessoaCPF)}
              onChange={(e) => setPessoaCPF(e.target.value)} />

          </div>
          {tipo === GrupoPessoaTipoEnum.FOCAL_FINANCEIRO
            &&
            <div className="inputModal">
              <span>Telefone {asterisco}</span>

              <Input
                id="telefone"
                className="input"
                value={formatPhone(pessoaCelular)}
                onChange={(e) => setPessoaCelular(e.target.value)} />

            </div>
          }
          <div className="inputModal">
            <span>E-mail pessoal {asterisco}</span>

            <Input
              id="emailPessoal"
              className="input"
              value={pessoaEmailPessoal}
              onChange={(e) => setPessoaEmailPessoal(e.target.value)} />

          </div>
          <div className="inputModal">
            <span>E-mail profissional (usado para cobranças) {asterisco}</span>

            <Input
              id="emailProfissional"
              className="input"
              value={pessoaEmailProfissional}
              onChange={(e) => setPessoaEmailProfissional(e.target.value)} />

          </div>
          { }
          <div className="botoes">
            <button onClick={onClose} className="btn-cancel" id="cancelar">Cancelar</button>
            <button onClick={salvar} className="btn-make" id="criar-usuario">Salvar usuário</button>
          </div>
        </div>
      </Form>
    </div>
  );
}