import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { IOperacaoFullEscolaDTO } from '../models/relatorio.model';


export const usePagarMeEscolaService = () => {
	const resourceURL = 'pagar-me-escola';

	const operacoes = (
		recipientId: string, page: number, dataFim: number,dataInicio: number, count: number
	): Promise<AxiosResponse<IOperacaoFullEscolaDTO[]>> =>
		http.get<IOperacaoFullEscolaDTO[]>(
			resourceURL + `/operacao/${recipientId}/${page}/${dataInicio}/${dataFim}/${count}`
		);
		
	return { operacoes };
};