
import { Skeleton, Table } from 'antd';

interface Props {
    loading: boolean;
    active: boolean;
    rowCount: number;
    columns: any[];
    children: JSX.Element;
    className?: string;
    
}
export default function SkeletonTable({
  loading,
  active,
  rowCount,
  columns,
  children,
  className,
}: Props): JSX.Element {
  return loading ? (
    <Table
      className={className}
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column, index) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton
                key={index}
                title
                active={active}
                paragraph={false}
                className={className}
              />
            );
          },
        };
      })}
    />
  ) : (
    <>{children}</>
  );
}