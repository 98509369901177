import * as Unicons from '@iconscout/react-unicons';
import './style.css';
import { TipoAcessoAcademy } from '../../enum/tipoAcessoAcademy';
import { School } from '@mui/icons-material';

interface Tag {
	tipo: string;
};

export const Tags: React.FunctionComponent<Tag> = ({ tipo }: Tag) => {
	
	if (!tipo) return null;

	if (tipo.toLowerCase() === 'sede') {
		return (
			<div className="tag-container-sede">
				<Unicons.UilGlobe size="14" color="#478953" />
				<span>Sede</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'admin') {
		return (
			<div className="tag-container-admin">
				<Unicons.UilDollarSignAlt size="14" color="#306F75" />
				<span>Administrativo</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'colaborador') {
		return (
			<div className="tag-container-colaborador">
				<Unicons.UilWrench size="14" color="#CF6D34" />
				<span>Colaborador</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'franquia') {
		return (
			<div className="tag-container-franquia">
				<Unicons.UilGlobe size="14" color="#AA5556" />
				<span>Franquia</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'instrutor') {
		return (
			<div className="tag-container-instrutor">
				<Unicons.UilGraduationCap size="12" color="#3771A8" />
				<span>Instrutor</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'professor') {
		return (
			<div className="tag-container-instrutor">
				<Unicons.UilGraduationCap size="12" color="#3771A8" />
				<span>Professor</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'gestor' || tipo.toLowerCase() === 'gerencia') {
		return (
			<div className="tag-container-gestor">
				<Unicons.UilSetting size="12" color="#895CF2" />
				<span>Gestor</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'externo') {
		return (
			<div className="tag-container-sede">
				<Unicons.UilTrees size="14" color="#478953" />
				<span>Externo</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'presencial') {
		return (
			<div className="tag-container-colaborador">
				<Unicons.UilUsersAlt size="14" color="#CF6D34" />
				<span>Presencial</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'online') {
		return (
			<div className="tag-container-gestor">
				<Unicons.UilDesktop size="14" color="#895CF2" />
				<span>Online</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'híbrido') {
		return (
			<div className="tag-container-franquia">
				<Unicons.UilDirection size="14" color="#AA5556" />
				<span>Híbrido</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'comercial') {
		return (
			<div className="tag-container-comercial">
				<Unicons.UilDollarSignAlt size="14" color="#CF6B6C" />
				<span>Comercial</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'games') {
		return (
			<div className="tag-games">
				<span>Games</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'vencido') {
		return (
			<div className="tag-container-comercial">
				<Unicons.UilExclamationTriangle size="14" color="#CF6B6C" />
				<span>Vencido</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'pago') {
		return (
			<div className="tag-container-sede">
				<Unicons.UilCheck size="14" color="#478953" />
				<span>Pago</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'aberto') {
		return (
			<div className="tag-container-colaborador">
				<Unicons.UilClockNine size="14" color="#CF6D34" />
				<span>Em aberto</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'responsavel') {
		return (
			<div className="tag-container-gestor">
				<span>Responsável Financeiro</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'youtube') {
		return (
			<div className="tag-youtube">
				<span>Youtube</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'lancado') {
		return (
			<div className="tag-container-sede">
				<Unicons.UilCheck size="14" color="#478953" />
				<span>Lançado</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'pendente') {
		return (
			<div className="tag-container-comercial">
				<Unicons.UilExclamationTriangle size="14" color="#CF6B6C" />
				<span>Pendente</span>
			</div>
		);
	}

	if (tipo.toLowerCase() === 'acontecer') {
		return (
			<div className="tag-container-colaborador">
				<Unicons.UilClockNine size="14" color="#CF6D34" />
				<span>Acontecer</span>
			</div>
		);
	}

	if (tipo === TipoAcessoAcademy.CODIGO_B2C) {
		return (
			<div className="tag-container-academy">
				<School style={{ color: "#cf6d34", fontSize: 14 }} />
				<span>Hub</span>
			</div>
		);
	}

	if (tipo === TipoAcessoAcademy.CODIGO_B2B) {
		return (
			<div className="tag-container-academy">
				<School style={{ color: "#cf6d34", fontSize: 14 }} />
				<span>Empresas</span>
			</div>
		);
	}

	if (tipo === TipoAcessoAcademy.CODIGO_COMERCIAL) {
		return (
			<div className="tag-container-academy">
				<School style={{ color: "#cf6d34", fontSize: 14 }} />
				<span>Comercial</span>
			</div>
		);
	}

	return (
		<div className="tag-container-gestor">
			<Unicons.UilDesktop size="14" color="#895CF2" />
			<span>{tipo}</span>
		</div>
	);
};
