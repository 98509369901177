import { Fragment } from 'react';
import BoxRelatorio from '../../../components/BoxRelatorio';
import useRegisterLog from '../../../globalHooks/use-register-log.hook';
import useReportsHeadOffice from '../states/use-reports-head-office.hook';
import './_report-head-office.styles.scss';
import './style.css';
import MenuSidebarAndContainer from '../../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';

function RelatorioSede() {
	useRegisterLog({
		descricaoEvento: 'Acesso relatório sede',
		descricaoTipo: 'Acesso a tela',
	});

	const { reportsHeadOffice } = useReportsHeadOffice();

	return (
		<MenuSidebarAndContainer itemMenuSelected={18}>
			{reportsHeadOffice.map(({ title, id, reportCards }) => {
				return (
					<Fragment key={id}>
						<div
							className="box-title"
							style={{
								marginBottom: '0px',
							}}
						>
							<h3 className="title">{title}</h3>
						</div>

						<div className="relatorios-box-wrapper">
							{reportCards.map(({ id, title, link }) => (
								<BoxRelatorio key={id} titulo={title} link={link} />
							))}
						</div>
					</Fragment>
				);
			})}
		</MenuSidebarAndContainer>
	);
}

export default RelatorioSede;
