import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { INfeCleanDTO, INfeDTO, INfeFullDTO } from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useNfeService = () => {
	const resourceURL = 'nfe';

	const findCustom = (idEnota: string): Promise<AxiosResponse<INfeDTO>> => {
		return http.get(`${resourceURL}/find-custom/${idEnota}`);
	}
	
	return { 
		...baseFindList<INfeCleanDTO, INfeDTO, INfeFullDTO>(
			resourceURL
		),
		...baseFind<INfeCleanDTO, INfeDTO, INfeFullDTO>(resourceURL),
		findCustom,
	  };
};