import { Col, DatePicker, Row, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	ICursoCleanDTO,
	ITurmaCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useCursoService } from '../../../services/curso.service';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useTurmaService } from '../../../services/turma.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { formattedDate } from '../../../util/format';
import './style.css';
import { hoje } from '../../../util/util';
interface Root {
	['C01_MATRÍCULA']: number;
	C02_ALUNO: string;
	['C03_PRESENÇA']: string;
	C04_DATA_AULA: Date;
	C05_DATA_REGISTRO: Date;
	CXX_ALUNO_ATIVO: boolean;
	CXX_CURSO: number;
	CXX_ID_CURSO: number;
	CXX_TURMA: number;
	CXX_ID_TURMA: number;
	CXX_ID_UNIDADE: number;
	CXX_DT_INICIO: Date;
	CXX_DT_FIM: Date;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelRegistroFrequenciaTurmaAcademy() {
	const { unidade } = useGlobalContext();
	const service = useRelatorioService();
	const cursoService = useCursoService();
	const turmaService = useTurmaService();

	const [dados, setData] = useState<Root[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();

	const [dataAulaInicio, setDataAulaInicio] = useState<moment.Moment>();
	const [dataAulaFim, setDataAulaFim] = useState<moment.Moment>();

	const [cursos, setCursos] = useState<ICursoCleanDTO[]>();
	const [cursoId, setCursoId] = useState<number>(0);

	const [turmas, setTurmas] = useState<ITurmaCleanDTO[]>();
	const [turmaId, setTurmaId] = useState<number>(0);

	const findCurso = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('cursoUnidades.unidade.id', unidade?.id);
		return cursoService.findCleanList(predicate).then(({ data }) => {
			setCursos(data);
		});
	};

	const findTurma = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade?.id);
		cursoId && predicate.addOption('curso.id', cursoId);
		return turmaService.findCleanList(predicate).then(({ data }) => {
			setTurmas(data);
		});
	};

	useEffect(() => {
		if (unidade.id) {
			findCurso();
			findTurma();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id, cursoId]);

	useEffect(() => {
		if (unidade.id) {
			const predicate = new Predicate();

			predicate.addOption('unidadeId', unidade?.id);
			turmaId && predicate.addOption('turmaId', turmaId);
			cursoId && predicate.addOption('cursoId', cursoId);
			dataAulaInicio &&
				predicate.addOption('dataAulaIni', dataAulaInicio.toISOString());
			dataAulaFim &&
				predicate.addOption('dataAulaFim', dataAulaFim.toISOString());

			service.registroFrequenciaTurma(predicate).then(({ data }) => {
				setData(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id, turmaId, cursoId, dataAulaInicio, dataAulaFim]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	const getPeriodo = (): any => {
		if (dataAulaInicio && dataAulaFim) {
			return [
				moment(dataAulaInicio, 'YYYY-MM-DD'),
				moment(dataAulaFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const selectCursos = cursos?.map((curso) => (
		<Option key={curso.id} value={curso.id}>
			{curso.descricao}
		</Option>
	));
	const selectTurmas = turmas?.map((turma) => (
		<Option key={turma.id} value={turma.id}>
			{turma.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	let totalPresente = 0;
	let totalAusentes = 0;

	dados?.forEach((dado) => {
		totalPresente += dado.C03_PRESENÇA === 'Presente' ? 1 : 0;
		totalAusentes += dado.C03_PRESENÇA === 'Ausente' ? 1 : 0;
	});

	const dadosFiltrado = dados?.find((dados) => dados?.CXX_ID_TURMA === turmaId);

	const dadosExportExcel = () => {
		const arrayDados: any[] = [];

		if (dados) {
			dados.map((dado) => {
				arrayDados.push({
					MATRÍCULA: dado['C01_MATRÍCULA'],
					ALUNO: dado.C02_ALUNO,
					PRESENÇA: dado['C03_PRESENÇA'],
					'DATA AULA': dado.C04_DATA_AULA,
					'DATA REGISTRO': dado.C05_DATA_REGISTRO,
				});
			});
		}

		const arrayNew = [...arrayDados];

		return arrayNew;
	};

	return (
		<div className="container" style={{ backgroundColor: '#fff' }}>
			<NewMenu selecionado={1} />

			<div className="w-100">
				<div className="flex justify-between">
					<div className="w-70">
						<header className="rel-header">
							<h1 className="mb-20">Registro de Frequência por Turma</h1>
							<p>
								<strong>Nome do curso:</strong> {dadosFiltrado?.CXX_CURSO}
							</p>
							<p>
								<strong>Nome da turma:</strong> {dadosFiltrado?.CXX_TURMA}
							</p>
							<div className="flex space-between">
								<p>
									<strong>Data de início:</strong>{' '}
									{formattedDate(dadosFiltrado?.CXX_DT_INICIO)}
								</p>
								<p>
									<strong>Data de fim:</strong>{' '}
									{formattedDate(dadosFiltrado?.CXX_DT_FIM)}
								</p>
							</div>
						</header>
					</div>
					<div className="w-30">
						<div className="box-header-contador-relatorio mt-50">
							<div className="box-informe">
								<h1>Alunos presentes</h1>
								<p>{totalPresente}</p>
							</div>
							<div className="box-informe">
								<h1>Alunos ausentes</h1>
								<p>{totalAusentes}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="dashboard" style={{ paddingTop: 50 }}>
					<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
					<Row style={{ marginBottom: 10 }}>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Currículo</span>
							<br />
							<Select
								showSearch
								allowClear
								style={filtroStyle}
								defaultValue={cursoId}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								onChange={(e) => setCursoId(e)}
							>
								{selectCursos}
							</Select>
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Turma</span>
							<br />
							<Select
								showSearch
								allowClear
								style={filtroStyle}
								defaultValue={turmaId}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								onChange={(e) => setTurmaId(e)}
							>
								{/* <Option value={0}>Todos</Option> */}
								{selectTurmas}
							</Select>
						</Col>
						<Col flex={2} style={{ paddingRight: '1em' }}>
							<span style={{ fontSize: '0.8em' }}>Período da aula</span>
							<br />
							<RangePicker
								value={getPeriodo()}
								onChange={(values) => {
									setDataAulaInicio(values && values[0]);
									setDataAulaFim(values && values[1]);
								}}
								format={'DD-MM-YYYY'}
							/>
							{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Excel</span>
							<br />
							<ExportToExcel
								apiData={dadosExportExcel()}
								fileName={`Frequência de turmas ` + hoje().toISOString()}
							/>
						</Col>
					</Row>
					<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
				</div>
			</div>
		</div>
	);
}

export default RelRegistroFrequenciaTurmaAcademy;
