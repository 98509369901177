/* eslint-disable react-hooks/rules-of-hooks */
import { DeleteOutlined, SendOutlined, ShareAltOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Bot from '../../assets/img/menu/logo.svg';
import MenuSidebarAndContainer from '../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';
import { useGlobalContext } from '../../context/GlobalContext';
import './style.css';

import Messages from './messages';
import { useGptHook } from './useGptHook';

export function HappyGPT() {
	const { isGlobalLoading, prompt, messages, loading, setPrompt, handleSubmit, showDeleteConfirm, shareConversation } = useGptHook();

	const { usuario, unidade } = useGlobalContext();

	return (
		<MenuSidebarAndContainer itemMenuSelected={27}>
			{[6, 29, 11].includes(unidade?.id) ? (
				<div className="container-gpt">
					<div className="header-gpt">
						<h1>Happy GPT</h1>
						<div className="icon-left">
							<Tooltip title="Compartilhar conversa">
								<ShareAltOutlined rev={undefined} onClick={shareConversation} />
							</Tooltip>
						</div>
						<div className="icon-right">
							<Tooltip title="Limpar conversa">
								<DeleteOutlined onClick={showDeleteConfirm} rev={undefined} />
							</Tooltip>
						</div>
					</div>
					<div className="container-chat">
						{messages.length === 0 && (
							<div className="title-gpt-chat">
								<img src={Bot} alt="Happy GPT" />
								<h2>Olá, {usuario?.usuario.split(' ')[0]}! Estou aqui para ajudar. O que você gostaria de saber?</h2>
							</div>
						)}
						{messages.map((message, index) => (
							<Messages key={index} message={message} index={index} loading={loading} messages={messages} />
						))}
						<div className="footer-gpt">
							<form onSubmit={(e) => handleSubmit(e)}>
								<div className={`container-input-text ${loading ? 'loading-animation' : ''}`}>
									<div className="scroll-to-bottom" style={{ display: messages.length > 4 ? 'block' : 'none' }}>
										<UpOutlined className="arrow" rev={undefined} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
									</div>
									<TextArea
										disabled={loading || isGlobalLoading}
										placeholder="Como posso te ajudar? (Use o atalho Ctrl+Enter para enviar)"
										className="text-area"
										value={prompt}
										onChange={(e) => setPrompt(e.target.value)}
										onKeyDown={(e) => {
											if (e.ctrlKey && e.key === 'Enter') {
												handleSubmit(e);
											}
										}}
									/>
									<Button disabled={loading || prompt.length === 0} type="primary" htmlType="submit">
										<SendOutlined alt="Enviar" rev={undefined} />
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			) : (
				<h2>Essa unidade ainda não possui o Happy GPT</h2>
			)}
		</MenuSidebarAndContainer>
	);
}
