import * as Unicons from '@iconscout/react-unicons';
import { Button, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { Operators } from '../../../enum/operators.enum';
import { IPessoaDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { usePessoaService } from '../../../services/pessoa.service';
import '../style.css';
import { ModalUser } from './../../../components/ModalUser/index';

export const FranquiaCreateResponsavel: React.FunctionComponent = () => {
  const { unidade, setUnidade } = useUnidadeContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [listPessoa, setListPessoa] = useState<IPessoaDTO[]>();
  const [selected, setSelected] = useState<string>("1");
  const [predicate] = useState<Predicate>(new Predicate());
  const [nome, setNome] = useState<string>();

  const history = useHistory();
  const pessoaService = usePessoaService();

  const findPessoa = () => {
    setIsGlobalLoading(true);
    return pessoaService.find(predicate)
      .then(({ data }) => {
        setListPessoa(data.content);
      }).finally(() => setIsGlobalLoading(false))
  }

  useEffect(() => {
    findPessoa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (nome) {
        setIsGlobalLoading(true);
        predicate.addOption('nome', nome, Operators.CONTAINS);
        findPessoa().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  const onChange = e => {
    const responsavelLegal = e.target.value;
    const contextState = { ...unidade, responsavelLegal: responsavelLegal };
    setSelected(e.target.value);
    setUnidade(contextState);
  }

  const onConfirmar = () => {
    history.push("/franquias/create/step-4");
  }

  return (
    <div className="container">
      <Header />
      <Progress />
      <div className="franquia-responsavel">
        <div className="franquia-row" style={{ marginTop: 100 }}>
          <h3>Selecione o responsável legal</h3>
          <span>Caso o responsável legal dessa unidade não possua um usuário, crie no botão abaixo</span>
          <Button icon={<Unicons.UilPlus size="16" color="var(--primary-color" />} className="button-second" id="criar-usuario" style={{ width: 700, marginTop: 25, padding: '16px 24px' }} onClick={() => setIsOpen(true)}>
            Criar novo Usuário
          </Button>
          <div className="line"></div>
          <h5>Responsáveis legais já cadastrados na Happy Code</h5>
          <div className="busca">
            <Unicons.UilSearch size="20" color="#6A7C96" />
            <input onChange={(event) => setNome(event.target.value)} type="text" id="busca" placeholder="Buscar Usuário" />
          </div>
          <Radio.Group style={{ width: '100%' }} onChange={onChange} value={selected}>
            {listPessoa && listPessoa.map((item, index) => (
              <div key={index} className="box">
                <Radio id={"user-" + index} value={item} />
                <div className="box-text">
                  <h4>{item.nome}</h4>
                  {/* <span>Ver mais detalhes</span> */}
                </div>
              </div>
            ))}
          </Radio.Group>
          <div className="botoes">
            <Link to="/franquias/create/step-4">
              <Button className="button-second" id="cancelar" style={{ width: 345 }}>
                Cancelar
              </Button>
            </Link>

            <button disabled={!selected} onClick={() => onConfirmar()} className="button-primary" id="confirmar" style={{ width: 345 }}>
              Confirmar
            </button>

          </div>
        </div>
      </div>
      <Link to="/franquias/create/step-4">
        <div className="botao-voltar">
          <Unicons.UilArrowLeft size="20" color="#895CF2" />
          <span>Voltar</span>
        </div>
      </Link>
      <ModalUser open={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}