import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Input } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DropdownFranquiaTipoPagamento from '../../../components/DropdownFranquiaTipoPagamento';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { Operators } from '../../../enum/operators.enum';
import { ILogCleanDTO, IUnidadeTipoPagamentoDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { useEstadoService } from '../../../services/estado.service';
import { useStatusUnidadeService } from '../../../services/status-unidade.service';
import { useUnidadeTipoPagamentoService } from '../../../services/unidade-tipo-pagamento';
import '../style.css';
import { useLogService } from '../../../services/log.service';

const VinculoTipoPagamento: React.FunctionComponent = () => {
	const { removeUnidade, setRevisao } = useUnidadeContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const [data, setData] = useState<PageableResponse<IUnidadeTipoPagamentoDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());	
	const [descricao, setDescricao] = useState<string>();	

	const history = useHistory();

	const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso Tipo pagamento",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

	//const cidadeService = useCidadeService();
	const unidadeTipoPagamentoService = useUnidadeTipoPagamentoService();
	
	const findUnidade = () => {
		setIsGlobalLoading(true);
		return unidadeTipoPagamentoService.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	}

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findUnidade();
	}

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			predicate.addOption('unidade.descricao', descricao || "", Operators.CONTAINS);
			findUnidade();
		}, 800);
		return () => clearTimeout(typingTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descricao]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findUnidade();
	}

	const goToAdd = () => {
		history.push("/franquias/create");
	}

	return (
		<div className="container">
			<NewMenu selecionado={26} />
			<div className="dashboard">				
				<form className="filtros">
					<div className="input-text" style={{ width: '70%' }}>
						<Input id="buscar" onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar Franquia" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
					</div>
				</form>
				<div className="tabela">
					<div className="titles">
						<div className="item" style={{ width: "22%" }}>
							<span>NOME DA FRANQUIA</span>
							<div className="arrows" onClick={() => ordenar('unidade.descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						
						<div className="item" style={{ width: "25%" }}>
							<span>Tipo Pagamento</span>
							<div className="arrows" onClick={() => ordenar('tipoPagamento.descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: "25%" }}>
							<span>Tipo Cobrança</span>
							<div className="arrows" onClick={() => ordenar('tipoCobranca.descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: "25%" }}>
							<span>Ativo</span>
							<div className="arrows" onClick={() => ordenar('ativo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: "3%" }}>
							<span>Ação</span>							
						</div>
					</div>
					<div className="tabela-body">
						{data && data.content.map((unidadeTipoPagamento, index) => (
							<DropdownFranquiaTipoPagamento unidadeTipoPagamento={unidadeTipoPagamento} findUnidade={findUnidade} key={index} />
						))}
					</div>
				</div>
				<div className="paginacao">
					<Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
				</div>
			</div>
		</div>
	);
}

export default VinculoTipoPagamento;