import { ITipoMaterialCleanDTO, ITipoMaterialDTO, ITipoMaterialFullDTO } from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useAcademyTipoMaterialService = () => {
	const resourceURL = '/tipo-material';

	return {
		...baseFindList<ITipoMaterialCleanDTO, ITipoMaterialDTO, ITipoMaterialFullDTO>(resourceURL),
		...baseFind<ITipoMaterialCleanDTO, ITipoMaterialDTO, ITipoMaterialFullDTO>(resourceURL),
		...baseFindList<ITipoMaterialCleanDTO, ITipoMaterialDTO, ITipoMaterialFullDTO>(resourceURL),
		...baseFindById<ITipoMaterialCleanDTO, ITipoMaterialDTO, ITipoMaterialFullDTO>(resourceURL),
	};
};
