import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../../../enum/operators.enum';
import { IModalidadeCleanDTO, ITipoCursoCleanDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { useModalidadeService } from '../../../../services/modalidade.service';
import { useTipoCursoService } from '../../../../services/tipo-curso.service';

interface TFiltrosCurso {
    predicate: Predicate;
    findCurso: () => void;
}
const FiltrosCurso = memo(({ predicate, findCurso }: TFiltrosCurso) => {
    const [descricao, setDescricao] = useState<string>();
    const [status, setStatus] = useState<number>();
    const [modalidadeId, setModalidadeId] = useState<number>();
    const [modalidades, setModalidades] = useState<IModalidadeCleanDTO[]>([]);
    const [tipoCursoId, setTipoCursoId] = useState<number>();
    const [tipoCursos, setTipoCursos] = useState<ITipoCursoCleanDTO[]>([]);

    const serviceTipoCurso = useTipoCursoService();
    const serviceModalidade = useModalidadeService();

    const selectModalidades = modalidades.map((modalidade) => (<Select.Option key={modalidade.id} value={modalidade.id}>{modalidade.descricao}</Select.Option>))
    const selectTipoCursos = tipoCursos.map((tipoCurso) => (<Select.Option key={tipoCurso.id} value={tipoCurso.id}>{tipoCurso.descricao}</Select.Option>))

    useEffect(() => {
        serviceModalidade.findCleanList(new Predicate()).then(({ data }) => setModalidades(data))
        serviceTipoCurso.findCleanList(new Predicate()).then(({ data }) => setTipoCursos(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('nome', descricao, Operators.CONTAINS);
            }
            if (modalidadeId) {
                predicate.addOption('modalidadeId', modalidadeId);
            }
            if (tipoCursoId) {
                predicate.addOption('tipoCursoId', tipoCursoId);
            }
            if (status === null || status === undefined) {
                predicate.addOption('ativo', true);
            }
            if (status) {
                predicate.addOption('ativo', status === 1 ? true : false);
            }
            findCurso();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, status, modalidadeId, tipoCursoId]);

    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '55%' }}>
                <Input id="buscar" value={descricao} placeholder="Buscar Curso" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} onChange={e => setDescricao(e.target.value)} />
            </div>
            <div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
                <span>Modalidade</span>
                <Select className="ant-dropdown-link" defaultValue="0" style={{ width: '100%' }} onChange={e => setModalidadeId(parseInt(e))}>
                    <Select.Option value="0">Todos</Select.Option>
                    {selectModalidades}
                </Select>
            </div>
            <div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
                <span>Tipo</span>
                <Select className="ant-dropdown-link" defaultValue="0" style={{ width: '100%' }} onChange={e => setTipoCursoId(parseInt(e))}>
                    <Select.Option value="0">Todos</Select.Option>
                    {selectTipoCursos}
                </Select>
            </div>
            <div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
                <span>Status</span>
                <Select className="ant-dropdown-link" style={{ width: '100%' }} onChange={(e: number) => setStatus(e)}>
                    <Select.Option value={0}>Todos</Select.Option>
                    <Select.Option key={1} value={1}>Ativo</Select.Option>
                    <Select.Option key={2} value={2}>Inativo</Select.Option>
                </Select>
            </div>
        </div>
    </>
    )
})

FiltrosCurso.displayName = 'FiltrosCurso'

export default FiltrosCurso;

