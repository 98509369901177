import * as Unicons from '@iconscout/react-unicons';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlunoContext } from '../../../../../../context/AlunoContext';
import { useGlobalContext } from '../../../../../../context/GlobalContext';
import {
	ILeadEscolaFullDTO, ILeadEscolaHistoricoDTO
} from '../../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../../models/predicate.model';
import { useEstadoService } from '../../../../../../services/estado.service';
import { useLeadEscolaService } from '../../../../../../services/lead-escola.service';
import { useLogradouroService } from '../../../../../../services/logradouro.service';
import { useNotificationService } from '../../../../../../services/notification.service';
import { toMoment } from '../../../../../../util/format';
import { PropsCrmEscolaContext, useCrmEscolaContext } from '../../../../context/crm-escola.context';

interface Props {
	open: boolean;
	type: string;
	boxColor: string;
	onClose: () => void;
	lead: ILeadEscolaFullDTO;
}

const useLeadDetalheState = (props: Props) => {
	const history = useHistory();
	const { setLeadEscolaResponsavel, removeAluno, removeMatricula } =
		useAlunoContext();

	const [state, setState] = useState({
		estados: [],
		lead: props.lead,
		showMore: [],
	});

	const [form] = Form.useForm();
	const { setIsGlobalLoading } = useGlobalContext();
	const logradouroService = useLogradouroService();
	const estadoService = useEstadoService();
	const notification = useNotificationService();
	const leadEscolaService = useLeadEscolaService();
	const { paises,
		refresh, setCrmEscolaContext, state: stateCrmEscola } = useCrmEscolaContext();

	useEffect(() => {
		setState((state) => ({ ...state, lead: { ...props.lead } }));

		if (props.lead.dataProximoRetorno) {
			//props.lead.dhProximoRetorno = toMoment(props?.lead.dhProximoRetorno);
			form.setFieldsValue({
				...props.lead,
				dhProximoRetorno: toMoment(props?.lead?.dataProximoRetorno),
			});
		}
	}, [props.lead]);

	const getHistoricoIcone = (historico: ILeadEscolaHistoricoDTO) => {
		if (historico.tipo.icone == 'profile') {
			return Unicons.UilUserSquare;
		}

		if (historico.tipo.icone === 'copy') {
			return Unicons.UilFileAlt;
		}
		return Unicons.UilFileAlt;
	};

	const getHistoricoIconeTipoHistorico = (historico: ILeadEscolaHistoricoDTO) => {
		
		if (historico.tipo.icone === 'whatsapp') {
			return Unicons.UilWhatsapp;
		}

		if (historico.tipo.icone === 'ligacao') {
			return Unicons.UilCalling;
		}

		if (historico.tipo.icone === 'email') {
			return Unicons.UilEnvelope;
		}

		if (historico.tipo.icone === 'presencial') {
			return Unicons.UilUsersAlt;
		}
	};

	const findEstadoByPais = (idPais) => {
		const predicate = new Predicate();
		predicate.addOption('pais.id', idPais);

		estadoService.findClean(predicate).then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, estados: response?.data?.content }));
			}
		});
	};

	const onCepChange = (cep: string) => {
		if (cep.length !== 8) {
			return;
		}
		setIsGlobalLoading(true);
		const predicateLogradouro = new Predicate(10);
		predicateLogradouro.addOption('cep', cep);
		logradouroService
			.findCustom(predicateLogradouro)
			.then(({ data }) => {
				if (data && data.length > 0) {
					const logradouro = data[0];
					// const { endereco } = unidade;

					setState((state) => {
						const paisSelecionado = paises.find(
							(item) => logradouro.cidadeEstadoPaisId === item.id
						);

						const endereco = {};

						(endereco as any).cep = logradouro.cep;
						(endereco as any).bairro = logradouro.bairroNome;
						(endereco as any).cidade = logradouro.cidadeNome;
						(endereco as any).logradouro = logradouro.nomeCompleto;
						(endereco as any).nomeUF = logradouro.cidadeEstadoNome;
						(endereco as any).paisId = paisSelecionado.id;

						form.setFieldsValue({
							endereco,
						});

						findEstadoByPais(paisSelecionado.id);
						return { ...state };
					});
				} else {
					notification({
						description: 'CEP não encontrado',
						type: 'warning',
						message: 'Aviso',
					});
				}
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const handleChangePais = (e) => {
		// handleChangeEndereco('pais', e);
		findEstadoByPais(e);
	};

	const onMarcarVenda = () => {
		// setIsGlobalLoading(true);
		// leadEscolaService.alterarStatus(props.lead.id, 'MTO').then((response) => {
		//   if (response.status >= 200 && response.status <= 299) {

		//     setCrmEscolaContext((crmContext: PropsCrmEscolaContext) => {
		//       const boardAntigo = crmboards.find((board) => board.id === props.lead.estagio.id)
		//       const boardNovo = crmboards.find((board) => board.cdLeadEstagio === 'MTO')
		//       const itensRemovidos = boardAntigo.leads.splice(boardAntigo.leads.findIndex(lead => lead.id === props.lead.id), 1);
		//       itensRemovidos.forEach((itemRemovido) => itemRemovido.estagio = boardNovo);
		//       boardNovo.leads = boardNovo.leads.concat(itensRemovidos);
		//       return { ...crmContext }
		//     })

		//     notification({ description: 'Lead movido para finalizado!', type: 'success', message: 'Sucesso!' });
		//   }
		removeAluno();
		removeMatricula();

		setLeadEscolaResponsavel(props.lead);
		history.push('/alunos/create/step-1');

		// }).catch ((e) => {
		//   notification({ description: 'Falha ao marcar a venda!', type: 'error', message: 'Erro!' });
		// }).finally(() => {
		//   setIsGlobalLoading(false);
		// })
	};

	const onDescartarLead = () => {
		setIsGlobalLoading(true);
		leadEscolaService
			.delete({ id: props.lead.id })
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					setCrmEscolaContext((crmContext: PropsCrmEscolaContext) => {
						const boardAntigo = stateCrmEscola?.boardsTemp.find(
							(board) => board.id === props.lead.estagio.id
						);
						boardAntigo.leads.splice(
							boardAntigo.leads.findIndex((lead) => lead.id === props.lead.id),
							1
						);
						return { ...crmContext };
					});
					notification({
						description: 'Lead descartado!',
						type: 'success',
						message: 'Sucesso!',
					});
				}
			})
			.catch((e) => {
				notification({
					description: 'Falha ao descartar o lead!',
					type: 'error',
					message: 'Erro!',
				});
			})
		refresh().finally(() => {
			props.onClose();
			setIsGlobalLoading(false);
		});
	};

	const put = () => {
		const body = { ...state.lead, ...form.getFieldsValue() };
		body.cpfOuCnpj = (body?.cpfOuCnpj as string || '')
			.replaceAll('.', '')
			.replaceAll('-', '')
			.replaceAll('/', '');

		body.campanha =
			!!body.campanha.id || body.campanha.nome ? body.campanha : undefined;


		if (!body.grupo?.id)
			body.grupo = null;
			
		if (!body.unidade?.id)
			body.unidade = null;

		setIsGlobalLoading(true);

		leadEscolaService
			.patch(body.id, body, null)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					return refresh().finally(() => {
						props.onClose();
						notification({
							description: 'Lead alterado!',
							type: 'success',
							message: 'Sucesso!',
						});
					});
				}
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const onAlterarLead = () => {
		form
			.validateFields()
			.then((response) => {
				return put();
			})
	};

	const isExpandido = (idItem: number) => {
		return state.showMore.includes(idItem);
	};

	const toggleVerMais = (idItem: number) => {
		setState((state) => {
			const showMore = [...state.showMore];
			isExpandido(idItem)
				? showMore.splice(showMore.indexOf(idItem), 1)
				: showMore.push(idItem);
			return { ...state, showMore: showMore };
		});
	};

	const exibirMostrarMais = (dsTexto: string) => {
		return dsTexto.length > 100;
	};

	const getText = (dsTexto: string, idItem: number) => {
		if (exibirMostrarMais(dsTexto)) {
			if (isExpandido(idItem)) {
				return dsTexto;
			}
			return dsTexto?.substring(0, 100) + '...';
		}

		return dsTexto;
	};

	const renderComponenteVerMais = (item: ILeadEscolaHistoricoDTO) => {
		if (isExpandido(item?.id)) {
			return 'ver menos';
		}

		if (exibirMostrarMais(item?.descricao)) {
			return 'ver mais';
		}

		return '';
	};

	const onDetalheClick = (item: ILeadEscolaHistoricoDTO) => {
		if (!exibirMostrarMais(item.descricao)) {
			return;
		}

		toggleVerMais(item.id);
	};

	return {
		state,
		getHistoricoIcone,
		form,
		onCepChange,
		handleChangePais,
		onMarcarVenda,
		onDescartarLead,
		onAlterarLead,
		getHistoricoIconeTipoHistorico,
		toggleVerMais,
		isExpandido,
		onDetalheClick,
		getText,
		renderComponenteVerMais,
	};
};

export default useLeadDetalheState;
