import { getObjectFromLocalStorage } from '../../util/store';
import { AuthEnum } from '../../enum/auth.enum';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface User {
	active: boolean;
}

interface Token {
	access_token: string;
}

export default function usePrivateRoute() {
	const userCredentials = getObjectFromLocalStorage<User>(AuthEnum.USER_KEY);
	const token = getObjectFromLocalStorage<Token>(AuthEnum.TOKEN_KEY);

	const isUserActive = userCredentials && userCredentials.active;
	const isTokenValid = token && token.access_token;

	const checkIfUserIsLogged = useCallback(
		(): boolean => !!(isUserActive && isTokenValid),
		[isUserActive, isTokenValid]
	);

	const { push } = useHistory();

	const redirectUserIfNotLogged = useCallback(() => {
		if (checkIfUserIsLogged()) return;

		return push('/login');
	}, []);

	useEffect(() => {
		redirectUserIfNotLogged();
	}, []);

	return {
		checkIfUserIsLogged,
	};
}
