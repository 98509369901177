import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Progress, Steps, TimePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import ModalCreate from '../../components/ModalCreate';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import '../../global.css';
import { IHorarioCleanDTO, ITurmaCustomDTO, ITurmaFullDTO } from '../../models/happy-code-api.model';
import { useTurmaService } from '../../services/turma.service';
import { horarioConfig } from '../../models/horarios.model';
import './style.css';
import { CalendarOffer } from '../../components/Calendar';
import { Predicate } from '../../models/predicate.model';


export const TurmasCreateStep3: React.FunctionComponent = () => {

  const { Step } = Steps;
  const [isOpen, setIsOpen] = useState(false);
  const [horarioList, setHorarioList] = useState<IHorarioCleanDTO[]>(horarioConfig);
  const [turmaSemana, setTurmaSemana] = useState<ITurmaCustomDTO>();
  const [horarioSelectedList, setHorarioSelectedList] = useState<string[]>([]);
  const [modalSucesso, setModalSucesso] = useState<boolean>(false);
  const { turma, setTurma, removeTurma } = useTurmaContext();
  const { unidade, setIsGlobalLoading } = useGlobalContext();
  const turmaService = useTurmaService();
  const horarioFormat = 'HH:mm';
  const [predicateTurma] = useState<Predicate>(new Predicate());

  
  const findTurmas = () => {
    setIsGlobalLoading(true);

    predicateTurma.addOption('unidade.id', unidade.id)

    //const dataHoraFormatada = moment(`2023-09-26T${horaInicialFiltro}`);
    //predicateTurma.addOption('horarios.horaInicio', '09:00:00')
    return turmaService.findTurmaSemana(null, unidade.id, predicateTurma)
      .then((response) => setTurmaSemana(response.data))
      .catch((e) => console.log(e))
      .finally(() => setIsGlobalLoading(false));
  }

  const onChange = (horario: IHorarioCleanDTO, checked: boolean) => {
    if (checked) {
      setHorarioSelectedList([...horarioSelectedList, horario.codigo]);
    } else {
      setHorarioSelectedList([...horarioSelectedList.filter((item) => item !== horario.codigo)]);
    }
  }

  useEffect(() => {
    if (turma.horarios.length > 0) {
      setHorarioSelectedList([...turma.horarios.map((item) => item.codigo)]);
      setHorarioList(horarioConfig.map(horario => turma.horarios.find((item) => item.codigo === horario.codigo) || horario))
    }
    findTurmas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChecked = (horario: IHorarioCleanDTO) => {
    const item = horarioSelectedList.find((item) => item === horario.codigo);
    return item ? true : false;
  }

  const getTimeValue = (horario: IHorarioCleanDTO, hora: string) => {
    if (horario.horaInicio && hora === 'INICIO') {
      return moment(horarioList.find((item) => item.codigo === horario.codigo).horaInicio, horarioFormat);
    }

    if (horario.horaFim && hora === 'FIM') {
      return moment(horarioList.find((item) => item.codigo === horario.codigo).horaFim, horarioFormat);
    }

    return moment('00:00', horarioFormat);

  }

  const onChangeTime = (value, index, hora) => {
    if (hora === 'INICIO' && value) {
      horarioList[index].horaInicio = value;
    }
    if (hora === 'FIM' && value) {
      horarioList[index].horaFim = value;
    }
    setHorarioList([...horarioList]);
  }

  const saveTurma = (payload: ITurmaFullDTO) => {
    setIsGlobalLoading(true);
    payload.unidade.id = unidade.id;
    turmaService.createFull(payload)
      .then(() => {
        setModalSucesso(true);
        removeTurma();
      }).finally(() => setIsGlobalLoading(false));
  }

  const updateTurma = (payload: ITurmaFullDTO) => {
    setIsGlobalLoading(true);
    payload.unidade.id = unidade.id;
    turmaService.patchFull(payload)
      .then(() => {
        setModalSucesso(true);
        removeTurma();
      }).finally(() => setIsGlobalLoading(false));
  }

  const handleSubmit = () => {
    const horarios = horarioList.filter((horario) => horarioSelectedList.includes(horario.codigo) && horario.horaInicio && horario.horaFim).map((horario) => {
      horario.horaInicio += ':00';
      horario.horaFim += ':00';
      return horario;
    });

    horarios.forEach((horario) => {
      if (horario.horaInicio.length > 8) {
        horario.horaInicio = horario.horaInicio.slice(0, 8);
      }
      if (horario.horaFim.length > 8) {
        horario.horaFim = horario.horaFim.slice(0, 8);
      }
    });

    if (horarios.length > 0) {
      turma.horarios = horarios;
      setTurma({ ...turma });
      if (turma.id) {
        updateTurma(turma);
      } else {
        saveTurma(turma);
      }
    }
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={75} strokeLinecap="square" showInfo={false} />
      </div>
      <div className="menu-user">
        <h3>Criação de turma</h3>
        <Steps direction="vertical" current={3}>
          <Step title="Dados Básicos" />
          <Step title="Instrutor" />
          <Step title="Dias de semana" />
          {/* <Step title="Revisão" /> */}
        </Steps>
      </div>
      <div className="franquia">        
        <div className="user-row" id="row-aluno">
          <h3>Selecione os dias e horários de aula dessa turma</h3>
          <div className="container-offer-page">
            <div className="box-calendar">
              <div className="calendario">
                <div className="body-calendario">
                  <CalendarOffer turmaSemana={turmaSemana} telaTurma={true}/>
                </div>
              </div>
            </div>
          </div>
          <div className="botoes">
            <Link to="/turmas/create/step-2">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <div onClick={() => handleSubmit()}>
              <Button className="button-primary" style={{ width: 345 }}>Salvar</Button>
            </div>
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a adição desta Nova turma?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/turmas'
      />
      <ModalCreate
        open={modalSucesso}
        title='Turma criada com sucesso'
        textButton1='Voltar'
        textButton2='Voltar Inicio'
        styleButton1='modal-none'
        styleButton2='button-primary'
        link2='/turmas'
      />
    </div>
  );

}