import {
	ICategoriaQuantidadeAlunosCleanDTO,
	ICategoriaQuantidadeAlunosDTO,
	ICategoriaQuantidadeAlunosFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useCategoriaQuantidadeAlunosService = () => {
	const resourceURL = 'categoriaquantidadealunos';

	return {
		...baseFindList<ICategoriaQuantidadeAlunosCleanDTO, ICategoriaQuantidadeAlunosDTO, ICategoriaQuantidadeAlunosFullDTO>(resourceURL),
	};
};
