import { Modal } from 'antd';

interface ModalProps<T> {
    title: string;
    content: string | JSX.Element;
    okText?: string;
    cancelText?: string;
    onOk: () => void;
    onCancel?: () => void;
    additionalProps?: T;
}

const ModalAntdInativar = <T,>({ title, content, okText = 'Sim', cancelText = 'Não', onOk, onCancel, additionalProps }: ModalProps<T>) => {
    return Modal.confirm({
        title,
        content,
        okText,
        cancelText,
        onOk,
        onCancel,
        ...additionalProps
    });
};

export default ModalAntdInativar;
