import * as Unicons from '@iconscout/react-unicons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTurmaContext } from '../../context/TurmaContext';
import { IDiaSemana, IHorarioCleanDTO } from '../../models/happy-code-api.model';
import './style.css';
interface MiniaturaProps {
	horaInicio: string;
	horaFim: string;
	dia: IDiaSemana;
}

export const MiniaturaTurma = ({ horaInicio, horaFim, dia }: MiniaturaProps) => {
	const { setTurma } = useTurmaContext();
	const [horario, setHorario] = useState<IHorarioCleanDTO>({
		id: null,
		horaInicio: horaInicio,
		horaFim: horaFim,
		codigo: dia,
		descricao: null,
		diaSemana: dia,
		descricaoHorario: null,
		ativo: true,
	});

	const history = useHistory();

	const selectHora = () => {
		setHorario(horario);

		setTurma((prev) => ({
			...prev,
			horarios: [...(prev?.horarios || []), horario],
		}));

		history.push('/turmas/create/step-1');
	};

	return (
		<div onClick={() => selectHora()} className={`box-adicionarHora-miniatura`}>
			<div className="d-flex-row-between-center box-info w-100">
				<div className="box-sala" style={{ display: 'flex', flexDirection: 'row' }}>
					<Unicons.UilPlusCircle size="28" color="#1A4DA1" />
				</div>
			</div>
		</div>
	);
};
