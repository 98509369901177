import * as Unicons from '@iconscout/react-unicons';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IGrupoContratoFullDTO } from '../../../../models/happy-code-api.model';
import { useNotificationService } from '../../../../services/notification.service';
import './style.css';

import { format } from 'date-fns';
import Modal from '../../../../components/Modal';
import { PerfilEnum } from '../../../../enum/perfil.enum';
import { useGrupoContratoService } from '../../../../services/grupo-contrato.service';
import { currencyFormatter } from '../../../../util/format';

interface Props {
    contrato: IGrupoContratoFullDTO;
    buscarDados: any,
    goToRevisao?: any,
}

const ItemTabelaContratoComponent: React.FunctionComponent<Props> = ({ contrato, buscarDados, goToRevisao }: Props) => {

    const notification = useNotificationService();
    const grupoContratoService = useGrupoContratoService();

    const [open, setOpen] = useState(false);
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const [cancelandoContrato, setCancelandoContrato] = useState(false);

    useEffect(() => {
        // const getNegocioUnidade = () => {
        //   const predicate = new Predicate();
        //   predicate.addOption('grupoId', grupo.id);
        //   negocioUnidadeService.find(predicate)
        //     .then(({ data }) => {
        //       setNegocioUnidade(data.content);
        //     })
        // }

        // if ((!negocioUnidade || negocioUnidade.length === 0) && open) {
        //   getNegocioUnidade();
        // }
    }, [open]) // eslint-disable-line react-hooks/exhaustive-deps



    const getStatusCss = () => {

        if (contrato.ativo) {
            return (
                <div className="estado">
                    <div className="dot-verde"></div>
                    <p className='ativo' style={{ marginRight: 10 }}>Ativo</p>
                </div>
            );
        }

        if (!contrato.ativo) {
            return (
                <div className="estado">
                    <div className="dot-vermelho"></div>
                    <p className='inativo' style={{ marginRight: 10 }}>Inativo</p>
                </div>
            );
        }
    }

    const inativar = () => {
        setIsGlobalLoading(true);

        grupoContratoService.inativarContrato(contrato.id)
            .then((response) => {
                buscarDados();
                if (response.status >= 200 && response.status <= 299)
                    notification({ description: 'Contrato alterado com sucesso!', type: 'success', message: 'Sucesso' });
            }).finally(() => setIsGlobalLoading(false));
    }

    const cancelarContrato = () => {
        setCancelandoContrato(false);
        setIsGlobalLoading(true);

        grupoContratoService.cancelar(contrato.id, false)
        .then((response) => {
                buscarDados();
                if (response.status >= 200 && response.status <= 299)
                    notification({ description: 'Contrato cancelado com sucesso!', type: 'success', message: 'Sucesso' });
            }
        ).finally(() => setIsGlobalLoading(false));

    }

    const solicitarConfirmacao = () => {
        setCancelandoContrato(true);
    }

    const finalizaCancelamento = () => {
        setCancelandoContrato(false);
    }

    const itensDropdown = () => {
        return (
            <Menu>
                <Menu.Item onClick={inativar}>
                    <a rel="noopener noreferrer">Suspender/Ativar</a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => setCancelandoContrato(true)}>cancelar contrato</a>
                </Menu.Item>
            </Menu>
        )
    }

    // const focalOperacional = contrato.pessoas?.find?.((item) => item.tipo.codigo === 'OPERACAO_ESCOLA');

    if (!open) {
        return (
            <div className="registro-user" style={{ paddingTop: 20, paddingBottom: 20 }} id="registro-financeiro">
                <Modal
                    open={cancelandoContrato}
                    onClose={() => setCancelandoContrato(false)}
                    title='Você deseja cancelar o contrato?'
                    subtitle='Essa ação não pode ser desfeita.'
                    textButton1='Não'
                    textButton2='Sim, cancelar'
                    styleButton1='button-line'
                    styleButton2='button-danger'
                    button2={cancelarContrato}
                />
                <div className="registros-row" >
                    <div className="registro" style={{ flex: 0.5, display: 'flex', flexDirection: 'column' }}>
                        <span>{contrato?.id}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{contrato?.descricao}</span>
                        <h4>{getStatusCss()}</h4>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span>{contrato.tipo.descricao}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{format(new Date(contrato.vigenciaInicio), 'dd/MM/yyyy')}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{format(new Date(contrato.vigenciaFim), 'dd/MM/yyyy')}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{currencyFormatter(contrato.valorTotal)}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {usuario.perfil === PerfilEnum.SEDE && usuario?.funcao === 'ADM' || usuario.funcao === 'TEC' ?
                            <div className="registro" style={{ width: "2%" }}>
                                <Dropdown overlay={itensDropdown()} placement="bottomRight" arrow>
                                    <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                                </Dropdown>
                            </div>
                            : null}
                        <div onClick={() => setOpen(!open)} className="registro-opcao">
                            <div className="circle">
                                <Unicons.UilAngleDown size="20" color="#6A7C96" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="registros-body-detalhes">
            <div className="row-1">
                <div className="registros-row">
                    <div className="registro" style={{ flex: 0.5, display: 'flex', flexDirection: 'column' }}>
                        <span>{contrato?.id}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{contrato?.descricao}</span>
                        <h4>{getStatusCss()}</h4>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span>{contrato.tipo.descricao}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span>{format(new Date(contrato.vigenciaInicio), 'dd/MM/yyyy')}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span>{format(new Date(contrato.vigenciaFim), 'dd/MM/yyyy')}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span className="gestor">{currencyFormatter(contrato.valorTotal)}</span>
                    </div>
                </div>
                <div className="registro-opcao">
                    <div className="circle" onClick={() => setOpen(!open)}>
                        <Unicons.UilAngleDown size="20" color="#6A7C96" />
                    </div>
                </div>
            </div>
            <div className="row-2">
                <div className="dados">
                    <h6>CNPJ</h6>
                    <span>{contrato.grupo.cnpj}</span>
                    <br />
                    <h6>Tipo</h6>
                    <span>{contrato.tipo.descricao}</span>
                </div>
                <div className="line"></div>
                <div className="modelos">
                    <h6>Valor Intra</h6>
                    <span>{currencyFormatter(contrato.valorLicencaIntra)}</span>
                </div>
                <div className="line"></div>
                <div className="negocios">
                    <h6>Valor Extra</h6>
                    {currencyFormatter(contrato.valorLicencaExtra)}
                </div>
            </div>
            <Button onClick={() => goToRevisao()} style={{ marginTop: 20 }} className="button-primary">
                Ver mais detalhes
            </Button>
        </div >
    )


}

export default ItemTabelaContratoComponent;