import { Button, Select } from 'antd';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	IRelatorioNotas,
	IStatusENotas,
} from '../../../models/relatorio.model';
import { useEnotasService } from '../../../services/enotas.service';

function RelNotasSede() {
	const { setIsGlobalLoading } = useGlobalContext();
	const [notas, setNotas] = useState<IRelatorioNotas>();
	const relatorioService = useEnotasService();
	const [page, setPage] = useState<number>(0);
	const [status, setStatus] = useState<IStatusENotas>();
	const empresaId = '97F168DF-254C-46D7-985F-BD9F7A620800';

	useEffect(() => {
		if (status === null || status === undefined) {
			setStatus('Autorizada');
		}
		setIsGlobalLoading(true);
		if (status != null || status != undefined) {
			relatorioService
				.relatorioNotasSede(empresaId, page, status)
				.then((response) => setNotas(response.data))
				.catch((erro) => console.log(erro))
				.finally(() => setIsGlobalLoading(false));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, [page, status]);

	const pageChangeMais = (): number => {
		setPage(page + 1);
		return page;
	};

	const pageChangeMenos = (): number => {
		setPage(page - 1);
		return page;
	};

	function onChangeStatus(status) {
		if (status === 'Autorizada') {
			setStatus('Autorizada'), setPage(0);
		}
		if (status === 'AguardandoAutorizacao') {
			setStatus('AguardandoAutorizacao'), setPage(0);
		}
		if (status === 'AutorizadaAguardandoGeracaoPDF') {
			setStatus('AutorizadaAguardandoGeracaoPDF'), setPage(0);
		}
		if (status === 'Cancelada') {
			setStatus('Cancelada'), setPage(0);
		}
		if (status === 'CancelamentoNegado') {
			setStatus('CancelamentoNegado'), setPage(0);
		}
		if (status === 'AutorizacaoSolicitada') {
			setStatus('AutorizacaoSolicitada'), setPage(0);
		}
		if (status === 'EmProcessoDeAutorizacao') {
			setStatus('EmProcessoDeAutorizacao'), setPage(0);
		}
		if (status === 'SolicitandoAutorizacao') {
			setStatus('SolicitandoAutorizacao'), setPage(0);
		}
		if (status === 'Negada') {
			setStatus('Negada'), setPage(0);
		}
	}

	const downloadPdf = (empresaId: string, notaId: string): any => {
		setIsGlobalLoading(true);
		relatorioService
			.downloadPdf(empresaId, notaId)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				FileSaver.saveAs(blob, `nota.pdf`);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const downloadXml = (empresaId: string, notaId: string) => {
		setIsGlobalLoading(true);
		relatorioService
			.downloadXml(empresaId, notaId)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/json',
				});
				FileSaver.saveAs(blob, `nota.xml`);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Notas fiscais de produto
				</h1>
				<div className="flex flex-row items-end" style={{ marginBottom: 10 }}>
					<div className="datePicker">
						<span>Status</span>
						<Select defaultValue="" size="small" onChange={onChangeStatus}>
							<Select.Option value="Autorizada">Autorizada</Select.Option>
							<Select.Option value="AguardandoAutorizacao">
								Aguardando Autorizacao
							</Select.Option>
							<Select.Option value="AutorizadaAguardandoGeracaoPDF">
								Autorizada Aguardando Geracao PDF
							</Select.Option>
							<Select.Option value="Cancelada">Cancelada</Select.Option>
							<Select.Option value="CancelamentoNegado">
								Cancelamento Negado
							</Select.Option>
							<Select.Option value="AutorizacaoSolicitada">
								Autorizacao Solicitada
							</Select.Option>
							<Select.Option value="EmProcessoDeAutorizacao">
								Em Processo De Autorizacao
							</Select.Option>
							<Select.Option value="SolicitandoAutorizacao">
								Solicitando Autorizacao
							</Select.Option>
							<Select.Option value="Negada">Negada</Select.Option>
						</Select>
					</div>
				</div>
				<table id="tableConciliacao">
					<thead>
						<tr id="top">
							<td id="colunas">Ambiente de Emissão</td>
							<td id="colunas">Status</td>
							{status !== 'Autorizada' ? (
								<td id="colunas">Motivo Falha</td>
							) : null}
							<td id="colunas">Data Emissão</td>
							<td id="colunas">Cliente</td>
							<td id="colunas">E-mail</td>
							{status == 'Autorizada' ? <td id="colunas">Baixar PDF</td> : null}
							{status == 'Autorizada' ? <td id="colunas">Baixar XML</td> : null}
						</tr>
					</thead>
					<tbody>
						{notas?.data?.map((nota, index) => (
							<tr key={index}>
								<td id="colunas">{nota.ambienteEmissao}</td>
								<td id="colunas">{nota.status}</td>
								{status !== 'Autorizada' ? (
									<td id="colunas">{nota.motivoStatus}</td>
								) : null}
								<td id="colunas">
									{moment(nota.dataCriacao).format('DD/MM/YYYY')}
								</td>
								<td id="colunas">{nota.cliente.nome}</td>
								<td id="colunas">{nota.cliente.email}</td>
								{status == 'Autorizada' ? (
									<td id="colunas">
										<a
											rel="noopener noreferrer"
											onClick={() => downloadPdf(empresaId, nota.id)}
										>
											Download PDF
										</a>
									</td>
								) : null}
								{status == 'Autorizada' ? (
									<td id="colunas">
										<a
											rel="noopener noreferrer"
											onClick={() => downloadXml(empresaId, nota.id)}
										>
											Download XML
										</a>
									</td>
								) : null}
							</tr>
						))}
					</tbody>
				</table>
				<div className="botoesConciliacao">
					<Button
						className="botaoConciliacao"
						onClick={pageChangeMenos}
						disabled={page === 0}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-arrow-left-circle"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
							/>
						</svg>
					</Button>
					<p>{page}</p>
					<Button
						className="botaoConciliacao"
						onClick={pageChangeMais}
						disabled={notas?.data?.length < 20}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-arrow-right-circle"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
							/>
						</svg>
					</Button>
				</div>
				<div>
					<h3 className="title">Glossário</h3>
					<li>
						Ambiente de Emissão: Ambiente que foi utilizado para a emissão,
						sendo Produção para o ambiente real e Homologação para o ambiente de
						testes.
					</li>
					<li>Número: Número da nota fiscal.</li>
					<li>Status: Status da nota fiscal.</li>
					<li>Valor Total: Valor que consta na nota fiscal.</li>
					<li>Data Emissão: Data em que foi emitido o documento.</li>
					<li>Cliente: Responsável que recebeu a nota fiscal.</li>
					<li>Baixar: Botão para baixar a nota fiscal.</li>
					<li>
						Motivo Falha: Quando negada a emissão da nota, este campo informa o
						motivo.
					</li>
				</div>
			</div>
		</div>
	);
}

export default RelNotasSede;
