import * as Unicons from '@iconscout/react-unicons';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import { useEscolaContext } from '../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IGrupoFullDTO } from '../../../../models/happy-code-api.model';
import { useGrupoService } from '../../../../services/grupo.service';
import { useNotificationService } from '../../../../services/notification.service';
import './style.css';

interface Props {
	grupo: IGrupoFullDTO;
	buscarDados: () => void;
}

const ItemTabelaGrupoComponent: React.FunctionComponent<Props> = ({
	grupo,
	buscarDados,
}: Props) => {
	const notification = useNotificationService();

	const [accordionIsOpen, setAccordionIsOpen] = useState(false);
	const { setGrupoParaCadastrar } = useEscolaContext();
	const history = useHistory();
	const { setIsGlobalLoading, usuario } = useGlobalContext();
	const grupoService = useGrupoService();
	const [flExibirModalDesativarGrupo, setFlExibirModalDesativarGrupo] =
		useState(false);

	useEffect(() => {
		setGrupoParaCadastrar(null);
	}, []);

	const goToRevisao = () => {
		setIsGlobalLoading(true);
		grupoService.findFullById(grupo)
			.then(({ data }) => {
				setGrupoParaCadastrar(data);
				history.push('/escolas/grupos/create/step-4');
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const getStatusCss = (): JSX.Element => {
		const classNameDot = grupo.ativo ? 'dot-verde' : 'dot-vermelho';
		const classNameText = grupo.ativo ? 'ativo' : 'inativo';
		const text = grupo.ativo ? 'Ativo' : 'Inativo';

		return (
			<div className="estado">
				<div className={classNameDot}></div>
				<p className={classNameText} style={{ marginRight: 10 }}>
					{text}
				</p>
			</div>
		);
	};

	const onDesativarGrupo = () => {
		setFlExibirModalDesativarGrupo(false);
		setIsGlobalLoading(true);
		grupoService
			.cancelar(grupo.id)
			.then(({ status }) => {
				buscarDados();

				if (status >= 200 && status <= 299)
					notification({
						description: 'Grupo desativado com sucesso!',
						type: 'success',
						message: 'Sucesso',
					});
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const acoesItensDropdown = () => {
		return (
			<Menu>
				<Menu.Item>
					<Link
						to={`/escolas/grupos/${grupo?.id}/contratos`}
						rel="noopener noreferrer"
					>
						Gestão de contratos
					</Link>
				</Menu.Item>
				<Menu.Item>
					<div onClick={() => setFlExibirModalDesativarGrupo(true)}>
						Desativar grupo
					</div>
				</Menu.Item>
			</Menu>
		);
	};

	if (!accordionIsOpen) {
		return (
			<div
				className="registro-user"
				style={{ paddingTop: 20, paddingBottom: 20 }}
				id="registro-financeiro"
			>
				<Modal
					open={flExibirModalDesativarGrupo}
					onClose={() => setFlExibirModalDesativarGrupo(false)}
					title="Você deseja desativar o grupo?"
					subtitle="Essa ação não pode ser desfeita."
					textButton1="Não"
					textButton2="Sim, desativar"
					styleButton1="button-line"
					styleButton2="button-danger"
					button2={onDesativarGrupo}
				/>
				<div className="registros-row">
					<div
						className="registro"
						style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
					>
						<span>{grupo?.nomeFantasia}</span>
						{getStatusCss()}
					</div>
					<div
						className="registro"
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						sem informação
					</div>
					<div
						className="registro"
						style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
					>
						<span>{grupo?.endereco?.nomeUF}</span>
					</div>
					<div
						className="registro"
						style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
					>
						<span>{grupo?.endereco?.cidade}</span>
					</div>
					<div
						className="registro"
						style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
					>
						<span>{grupo?.unidade?.descricao ? grupo?.unidade?.descricao : "Sede"}</span>
					</div>
					<div
						className="registro"
						style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
					>
						<span className="gestor">{grupo?.focalOperacional?.nome}</span>
					</div>
					<div
						className="registro"
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<div className="registro" style={{ width: '2%' }}>
							<Dropdown
								overlay={acoesItensDropdown()}
								placement="bottomRight"
								arrow
							>
								<Unicons.UilEllipsisH
									className="button-dropdown"
									size="18"
									color="#6A7C96"
								/>
							</Dropdown>
						</div>
						<div
							onClick={() => setAccordionIsOpen(!accordionIsOpen)}
							className="registro-opcao"
						>
							<div
								className="circle"
								onClick={() => setAccordionIsOpen(!accordionIsOpen)}
							>
								{accordionIsOpen ? (
									<Unicons.UilAngleUp size="20" color="#6A7C96" />
								) : (
									<Unicons.UilAngleDown size="20" color="#6A7C96" />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="registros-body-detalhes">
			<div className="row-1">
				<div className="registros-row">
					<div
						className="registro"
						style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
					>
						<span>{grupo?.nomeFantasia}</span>
						<h4>{getStatusCss()}</h4>
					</div>
					<div className="registro" style={{ flex: 1, display: 'flex' }}>
						sem informação
					</div>
					<div className="registro" style={{ flex: 1, display: 'flex' }}>
						<span>{grupo.endereco?.nomeUF}</span>
					</div>
					<div className="registro" style={{ flex: 1, display: 'flex' }}>
						<span>{grupo.endereco?.cidade}</span>
					</div>
					<div className="registro" style={{ flex: 1, display: 'flex' }}>
						<span className="gestor">{grupo?.focalOperacional?.nome}</span>
					</div>
				</div>
				<div className="registro-opcao">
					<div
						className="circle"
						onClick={() => setAccordionIsOpen(!accordionIsOpen)}
					>
						{accordionIsOpen ? (
							<Unicons.UilAngleUp size="20" color="#6A7C96" />
						) : (
							<Unicons.UilAngleDown size="20" color="#6A7C96" />
						)}
					</div>
				</div>
			</div>
			<div className="row-2">
				<div className="dados">
					<h6>CNPJ</h6>
					<span>{grupo.cnpj}</span>
					<br />
					<h6>Endereço</h6>
					<span>
						{grupo.endereco?.logradouro},{grupo.endereco?.numero} -{' '}
						{grupo.endereco?.bairro}, {grupo.endereco?.cidade} -{' '}
						{grupo.endereco?.nomeUF}, {grupo.endereco?.cep}
					</span>
				</div>
				<div className="line"></div>
				<div className="modelos">
					<h6>CATEGORIA CONFESSIONAL</h6>
					<span>{grupo.categoriaConfessional?.descricao}</span>
				</div>
				<div className="line"></div>
				<div className="negocios">
					<h6>Categoria quantidade alunos</h6>
					{grupo.categoriaQuantidadeAlunos.descricao}
				</div>
			</div>
			<Button
				onClick={() => goToRevisao()}
				style={{ marginTop: 20 }}
				className="button-primary"
			>
				Ver mais detalhes
			</Button>
		</div>
	);
};

export default ItemTabelaGrupoComponent;
