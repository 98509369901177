import http from '../helpers/http-common';

import {ILeadEscolaCleanDTO, ILeadEscolaDTO, ILeadEscolaFullDTO} 
	from '../models/happy-code-api.model';

import { formatHttpParams, Predicate } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useLeadEscolaService = () => {
	const resourceURL = 'lead-escola';

	const alterarStatus = (idLead: number, cdEstagio: string) => {
		return http.put(`${resourceURL}/${idLead}/estagio/${cdEstagio}`)
	}

	const findFullByIdCustom = (idLead: number) => {
		return http.get(`${resourceURL}/${idLead}/custom`)
	}
	const patch = (idLead: number, body: any, idAluno?: number) => {
		return http.patch(`${resourceURL}/${idLead}/${idAluno || ''}`, body)
	}

	const findDaSede = (predicate: Predicate = new Predicate()) => {
		return http.get(`${resourceURL}/find-da-sede`, formatHttpParams(predicate))
  }

	return {
		...baseCreate<ILeadEscolaCleanDTO, ILeadEscolaDTO, ILeadEscolaFullDTO>(resourceURL),
		...baseFindById<ILeadEscolaCleanDTO, ILeadEscolaDTO, ILeadEscolaFullDTO>(resourceURL),
		...baseFind<ILeadEscolaCleanDTO, ILeadEscolaDTO, ILeadEscolaFullDTO>(resourceURL),
		...baseDelete(resourceURL),
		alterarStatus,
		findFullByIdCustom,
		patch,
    findDaSede
	};
};
