import { useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import '../../global.css';
import { IRecursoDTO } from '../../models/happy-code-api.model';
import { useRecursoService } from '../../services/recurso.service';
import StepsCreate from './steps-create';
import './style.css';

const RecursoCreateStep1: React.FunctionComponent = () => {

  const [recurso, setRecurso] = useState<IRecursoDTO>();
  const [isOpen, setIsOpen] = useState(false);
  const recursoService = useRecursoService();
  const { id } = useParams<any>();

  const save = () => {
    if (!id) {
      recursoService.create(recurso);
    } else {
      recursoService.patch(recurso);
    }
  }

  const fetchRecurso = () => {
    recursoService.findById({id: id}).then((response) => setRecurso(response.data))
  }

  useEffect(() => {
    if(id) {
      fetchRecurso();
    }
  }, [])

  const history = useHistory();
  const onFinish = () => {
    history.push("/recurso");
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={16} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">
        <div className="user-row">
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3 id="taxas-h3">Insira o nome do Recurso</h3>
            <Form.Item name="nome" id="nome" rules={[{ required: true, message: 'Nome do curriculo!' }]}>
              <div className="input-box">
                <p>Nome do Recurso</p>
                <Input value={recurso?.descricaoRecurso} id="nome" onChange={e => setRecurso({ ...recurso, descricaoRecurso: e.target.value })} />
              </div>
            </Form.Item>
            <div className="botoes">
              <Link to="/recurso">
                <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
              </Link>
              <Form.Item>
                <Button onClick={save} type="primary" htmlType="submit" id="proximo" className="button-primary" style={{ width: 345 }}>Salvar</Button>
              </Form.Item>

            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação desse recurso?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/recurso'
      />
    </div>
  )

}

export default RecursoCreateStep1;