import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { ICrmLeadCustomDTO, ICrmVwLeadCleanDTO, ICrmVwLeadCustomDTO, ICrmVwLeadFullDTO } from '../models/happy-code-api.model';

import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useVwLeadService = () => {
	const resourceURL = 'crm/vwlead';

	const findCustomIndex = (predicate: Predicate = new Predicate()) => {
		return http.get(`${resourceURL}/find-custom-index`, formatHttpParams(predicate))
	}

	const findCustomIndexById = (body): Promise<AxiosResponse<ICrmLeadCustomDTO>> => {
		return http.get(`${resourceURL}/${body.id}/custom`)
	}

	return {
		...baseCreate<ICrmVwLeadCleanDTO, ICrmVwLeadCustomDTO, ICrmVwLeadFullDTO>(
			resourceURL
		),
		...baseFindById<ICrmVwLeadCleanDTO, ICrmVwLeadCustomDTO, ICrmVwLeadFullDTO>(
			resourceURL
		),
		...baseFind<ICrmVwLeadCleanDTO, ICrmVwLeadCustomDTO, ICrmVwLeadFullDTO>(
			resourceURL
		),
		...baseDelete(resourceURL),
		findCustomIndex,
		findCustomIndexById,
	};
};
