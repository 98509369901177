import { Button, Checkbox, Form, Radio, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUsuarioContext } from '../../../context/UsuarioContext';
import { IClusterCleanDTO, IEscolaCleanDTO, IPaisCleanDTO, IUnidadeCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useClusterService } from '../../../services/cluster.service';
import { useEscolaService } from '../../../services/escola.service';
import { usePaisService } from '../../../services/pais.service';
import { useUnidadeService } from '../../../services/unidade.service';
import '../../../theme/flex.scss';
import EscolaGruposFormActionComponent from '../components/escola-grupos-form-action-component/escola-grupos-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-grupos-form-fechar-component/escola-grupos-form-fechar.component';
import MenuLateralCreateContratosComponent from '../components/menu-lateral-create-contrato-component/menu-lateral-create-contrato.component';
import '../styles/escola-grupo-create-step.styles.scss';

export const GrupoContratoCreateStep3Screen: React.FunctionComponent = () => {
  const { contratoParaCadastrar, setContratoParaCadastrar, flRevisao, setFlRevisao } = useEscolaContext();
  const history = useHistory();
  const [form] = Form.useForm();

  const [intra, setIntra] = useState<any>();
  const [extra, setExtra] = useState<any>();
  const [vlDesconto, setVlDesconto] = useState<any>();
  const [custo, setCusto] = useState<any>();
  const [parcelamento, setParcelamento] = useState<any>();
  const { idGrupo } = useParams<any>();
  const [value, setValue] = useState(3);

  const { usuario, setUsuario, revisao } = useUsuarioContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [escolas, setEscolas] = useState<IEscolaCleanDTO[]>([]);
  const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>([]);
  const [clusters, setClusters] = useState<IClusterCleanDTO[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const paisService = usePaisService();
  const escolaService = useEscolaService();


  const fetchEscolas = async () => {
    setIsGlobalLoading(true);
    const predicate = new Predicate();
    escolaService.findCleanList(predicate)
      .then(({ data }) => setEscolas(data))
      .finally(() => setIsGlobalLoading(false));
  };

  useEffect(() => {
    fetchEscolas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onFinish = (form) => {
    history.push(`/escolas/grupos/${idGrupo}/contratos/create/step-4`);
  }

  const onBack = () => {
    return;
  }

  const onChangeContratos = (c) => {
    setContratoParaCadastrar((contrato) => {
      return { ...contrato, escolas: c.map(o => ({ id: o.value, nomeFantasia: o.children })) }
    })
  }

  return (
    <div className="container" id="escola-grupo-create-step">
      <Header />
      <Progress />
      <MenuLateralCreateContratosComponent current={2} />
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <div className="franquia">
          <div className="user-row">
            <h3>Quais escolas esse contrato pertencerá?</h3>
            <div className="select-franquia">
              <h5>Escolas</h5>
              <Select
                mode="multiple"
                id="escolas"
                placeholder="Selecione a quantidade"
                showSearch
                allowClear
                defaultValue={contratoParaCadastrar?.escolas?.map?.(item => item?.id)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value, op) => onChangeContratos(op)}
              >
                {escolas?.length > 0 && escolas.map((item, index) => (
                  <Select.Option key={index} value={item.id} texto={item.nomeFantasia}>{item.nomeFantasia}</Select.Option>
                ))}
              </Select>

              {/* <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecione a(s) Franquia(s) desejada(s)"
                defaultValue={usuario.usuarioUnidades.map(c => (c.unidadeDescricao))}
                onChange={(value, op) => onChangeUnidade(op)}
              >
                {optionsEscolas}
              </Select> */}
            </div>

            <EscolaGruposFormActionComponent
              revisao={flRevisao}
              rotaAnterior={`/escolas/grupos/${idGrupo}/contratos/create/step-2`}
              onBack={onBack}
            />
          </div>
        </div>
        <EscolaGruposFormFecharComponent
          dsLink={`/escolas/grupos/${idGrupo}/contratos`}
          onClick={() => {
            setContratoParaCadastrar(null)
          }}
        />
      </Form>
    </div >
  )
}