import {
	ISkillCleanDTO,
	ISkillDTO,
	ISkillFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useSkillService = () => {
	const resourceURL = 'skill';

	return {
		...baseFind<ISkillCleanDTO, ISkillDTO, ISkillFullDTO>(resourceURL),
		...baseFindList<ISkillCleanDTO, ISkillDTO, ISkillFullDTO>(resourceURL),
		...baseCreate<ISkillCleanDTO, ISkillDTO, ISkillFullDTO>(resourceURL),
		...baseUpdate<ISkillCleanDTO, ISkillDTO, ISkillFullDTO>(resourceURL),
		...baseDelete(resourceURL),
	};
};
