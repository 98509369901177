import { IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useProdutoEscolaService = () => {
	const resourceURL = '/produto-escola';

	return {
		...baseFind<IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO>(resourceURL),
		...baseFindList<IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO>(resourceURL),
		...baseFindById<IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO>(resourceURL),
		...baseCreate<IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO>(resourceURL),
		...baseUpdate<IProdutoEscolaCleanDTO, IProdutoEscolaDTO, IProdutoEscolaFullDTO>(resourceURL),
	};
};
