import { ConfigProvider } from 'antd';
import locale from "antd/lib/locale/pt_BR";
import GlobalLoading from './components/GlobalLoading/index';
import { GlobalContextProvider } from './context/GlobalContext';
import './global.css';
import Routes from './routes';

const App = () => {
    return (
        <div className="App" >
            <GlobalContextProvider>
                <GlobalLoading />
                <ConfigProvider locale={locale} >
                    <Routes />
                </ConfigProvider>
            </GlobalContextProvider>
        </div>
    );
}

export default App;
