import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { ICobrancaPagarMeDTO } from '../../models/happy-code-api.model';
import { formatMoney, formatTime, formatTimeFromString } from '../../util/format';
import Style from './style.module.css';

interface Props {
    cobranca: ICobrancaPagarMeDTO;
    loading: boolean
}
const CursosContratados = ({ cobranca, loading }: Props) => {
    const [HoraSemana, setHoraSemana] = useState<string>();

    const getHourCurso = () => {
        setHoraSemana("01h30 por semana"); // definir valor padrão de 1h30 
    };

    const getHourCurriculo = () => {
        const duracaoSemanaArray = cobranca?.turmas?.map((turma) => turma?.curriculoNegocioHoraSemanal);
        const duracaoTotalSec = duracaoSemanaArray?.reduce((acc, cur) => acc + cur, 0);
        setHoraSemana(formatTime(duracaoTotalSec) + ' por semana');
    };

    useEffect(() => {
        if (cobranca?.turmas[0]?.curso?.id) {
            getHourCurso();
        } else {
            getHourCurriculo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cobranca]);

    return (
        <div>
            {cobranca?.turmas[0]?.curriculoNegocioTipoCurriculoCodigo === "RGL" || cobranca?.turmas[0]?.cursoTipoCursoCodigo === "RGL" ?
                <Skeleton loading={loading}
                    title={true}
                    active={true}
                    paragraph={{ rows: 4, width: ["25%", "85%", "85%", "25%"] }}>
                    <h5 className="mt10">HORAS CONTRATADAS</h5>
                    <p className="ti5 mb-10">{HoraSemana}</p>
                    <h5 className="mt10">CURRÍCULOS CONTRATADOS</h5>
                    <div className={Style.containerMiddle}>
                        <div style={{ maxWidth: "200px", minWidth: "200px", marginLeft: "3px" }}>
                            {cobranca?.turmas[0]?.curso?.id ? cobranca?.turmas?.map((turma) => (
                                <p style={{ margin: "10px 0px" }} key={turma.id}>{turma?.curso?.descricao}</p>
                            )) : cobranca?.turmas?.map((turma) => (
                                <p style={{ margin: "10px 0px" }} key={turma.id}>{turma?.curriculo?.descricaoCurriculo}</p>
                            ))}
                        </div>
                        <div>
                            {cobranca?.turmas?.map((turma) => (
                                <p style={{ margin: "10px 0px" }} key={turma.id}>{turma.horarios.map((horario, index) => {
                                    return `${horario.diaSemana}  ${formatTimeFromString(horario.horaInicio)} - ${formatTimeFromString(horario.horaFim)}  ${index < turma.horarios.length - 1 ? ' | ' : ''}`;
                                })}</p>
                            ))}
                        </div>
                    </div>
                    <h5 className="mt15">VALOR TOTAL DO CONTRATO</h5>
                    <p className="ti5 mb-10">{formatMoney(cobranca?.valorTotalContrato)}</p>
                </Skeleton>
                : null}
        </div>
    );
};
export default CursosContratados;
