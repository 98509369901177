import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { Operators } from '../../enum/operators.enum';

import { IFuncaoCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useFuncaoService } from './../../services/funcao.service';
import StepsCreate from './steps-create';
import './style.css';

const UserCreateStep2: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const [selected, setSelected] = useState<number>();
	const [funcoesList, setFuncoesList] = useState<IFuncaoCleanDTO[]>();
	const funcaoService = useFuncaoService();
	const { usuario, setUsuario, revisao } = useUsuarioContext();

	const fetchInitialFuncaoData = async () => {
		const predicateFuncao = new Predicate(1000);
		if (usuario.perfil.codigo === 'SEDE') {
			predicateFuncao.addOption('codigo', 'IST', Operators.NOT_EQUAL)
		}
		setIsGlobalLoading(true);
		try {
			const { data: fetchFuncaoList } = await funcaoService.findCleanList(
				predicateFuncao
			);
			setFuncoesList(fetchFuncaoList);
			setIsGlobalLoading(false);
		} catch (error) {
			//
		}
	};

	useEffect(() => {
		fetchInitialFuncaoData();
		const funcaoUsuario = usuario.funcao.id;
		if (funcaoUsuario) {
			setSelected(funcaoUsuario);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRoleSelected = (funcao: IFuncaoCleanDTO) => {
		setSelected(funcao.id);
		const usuarioCopy = { ...usuario };
		usuarioCopy.funcao = funcao;
		setUsuario(usuarioCopy);
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={32}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={1} />
			<div className="franquia">
				<div className="user-row">
					<h3>Qual é a função do usuário?</h3>
					<div className="boxes">
						{funcoesList &&
							funcoesList.map((funcoes) => {
								return (
									<button
										id={"funcao-" + funcoes.id}
										key={funcoes.id}
										className={selected === funcoes.id ? 'box-selected' : 'box'}
										onClick={() => handleRoleSelected(funcoes)}
									>
										<Unicons.UilHouseUser
											size="20"
											color={selected === funcoes.id ? '#FFF' : 'var(--primary-color)'}
										/>
										<h4>{funcoes.codigo}</h4>
										<p>{funcoes.descricao}</p>
									</button>
								);
							})}
					</div>
					<div className="botoes">
						{revisao ? (
							<Link to="/usuarios/create/step-7">
								<Button
									id="revisao"
									type="primary"
									className="button-primary"
									style={{ width: 700 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						) : (
							<>
								<Link to="/usuarios/create/step-1">
									<Button id="voltar" className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/usuarios/create/step-3">
									<Button id="proximo" className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Link to="/usuarios">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
}

export default UserCreateStep2;