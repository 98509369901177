import { CloseOutlined } from "@mui/icons-material";
import { Button, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../../../../components/Header";
import Modal from '../../../../components/Modal';
import { useGlobalContext } from "../../../../context/GlobalContext";
import "../../../../global.css";
import { IAcaAulaDTO } from "../../../../models/happy-code-api.model";
import { useAcademyAulaService } from "../../../../services/academy-aula";
import { useNotificationService } from "../../../../services/notification.service";
import TextArea from "antd/lib/input/TextArea";
import ContadorCaracteres from "../../../../components/ContadorCaracteres";

const AcaAulaCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const aulaService = useAcademyAulaService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [aula, setAula] = useState<IAcaAulaDTO>();
    const { idCurso, idTrilha, idAula } = useParams<any>()

    const findAulas = () => {
        setIsGlobalLoading(true);
        aulaService.findById({ id: idAula })
            .then((response) => {
                setAula(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const createAula = () => {
        setIsGlobalLoading(true);
        aulaService.create({ ...aula, curso: { id: idCurso } })
            .then(() => {
                history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas`);
                notification({
                    description: `Aula criada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível cadastrar a aula.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const patchAula = () => {
        setIsGlobalLoading(true);
        aulaService.patch(aula)
            .then(() => {
                history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas`);
                notification({
                    description: `Aula editada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível editar a aula.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const sendRequest = () => {
        if (!idAula) {
            createAula();
        } else {
            patchAula();
        }
    }

    useEffect(() => {
        if (idAula) {
            findAulas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idAula])


    const submitValidationFields = () => {


        if (!aula?.codigo || !aula?.ordem || !aula?.descricao) {
            document.getElementById('linkIcone')?.focus();
            notification({
                description: `Os campos não podem estar vazios.`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }
        sendRequest();
    }

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ justifyContent: "normal", width: "75%" }}>
                <div className="space1" />
                <div className="user-row">
                    <div className="card-form" >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 id="taxas-h3" style={{ marginBottom: 10 }}>Insira as informações para cadastro da aula</h3>
                            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
                        </div>
                        <div className="input-box">
                            <label htmlFor="descricao">Nome <span className="vermelho">*</span></label>
                            <Input
                                id="descricao"
                                defaultValue={aula?.codigo}
                                value={aula?.codigo}
                                onChange={(e) => setAula({ ...aula, codigo: e.target.value })} />
                        </div>
                        <div className="input-box">
                            <label htmlFor="descricao">Descrição</label>
                            <TextArea
                                id="descricao"
                                maxLength={200}
                                defaultValue={aula?.descricao}
                                value={aula?.descricao}
                                onChange={(e) => setAula({ ...aula, descricao: e.target.value })} />
                            <ContadorCaracteres
                                contador={aula?.descricao?.length}
                                limite={200} />
                        </div>
                        <div className="input-box">
                            <label htmlFor="duracao">Carga horária em minutos <span className="vermelho">*</span></label>
                            <InputNumber
                                style={{ width: "100%" }}
                                id="duracao"
                                defaultValue={aula?.duracao}
                                value={aula?.duracao}
                                onChange={(e) => setAula({ ...aula, duracao: e })} />
                        </div>

                        <div className="input-box">
                            <label htmlFor="ordem">Ordem <span className="vermelho">*</span></label>
                            <InputNumber
                                style={{ width: "100%" }}
                                id="ordem"
                                min={0}
                                defaultValue={aula?.ordem}
                                value={aula?.ordem}
                                onChange={(e) => setAula({ ...aula, ordem: e })} />
                        </div>

                        <div className="botoes" style={{ marginTop: 10 }}>
                            <Link to={`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas`}>
                                <Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>Voltar</Button>
                            </Link>
                            <Button
                                style={{ width: 345 }}
                                type="primary"
                                id="proximo"
                                className="button-primary"
                                onClick={submitValidationFields}>Salvar</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a operação?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2={`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas`}
            />
        </div>
    )
}

export default AcaAulaCreate;
