import { Column, ColumnConfig } from '@ant-design/charts';
import './style.css';

export const GraphStemplay: React.FunctionComponent = () => {
    const data = [
        {
            type: 'jan',
            alunos: 38,
        },
        {
            type: 'fev',
            alunos: 52,
        },
        {
            type: 'mar',
            alunos: 61,
        },
        {
            type: 'abr',
            alunos: 145,
        },
        {
            type: 'mai',
            alunos: 48,
        },
        {
            type: 'jun',
            alunos: 8,
        },
        {
            type: 'jul',
            alunos: 90,
        },
        {
            type: 'ago',
            alunos: 38,
        },
        {
            type: 'set',
            alunos: 20,
        },
        {
            type: 'out',
            alunos: 38,
        },
        {
            type: 'nov',
            alunos: 50,
        },
        {
            type: 'dez',
            alunos: 78,
        },
    ]

    const config: ColumnConfig = {
        data: data || [],
        xField: 'type',
        yField: 'alunos',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    return (
        <div className='graph-stemplay'>
            <Column {...config} />
        </div>
    )
};

export default GraphStemplay