import http from '../helpers/http-common';
import {
	ICursoEscolaFranquiaCleanDTO,
	ICursoEscolaFranquiaDTO,
	ICursoEscolaFranquiaFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useCursoEscolaFranquiaService = () => {
	const resourceURL = 'curso-escola-franquia';

	const findCustom = (cursoId: number) => {
		return http.get(`${resourceURL}/find-custom?cursoId=${cursoId}`);
	}

	return {
		findCustom,
		...baseFind<ICursoEscolaFranquiaCleanDTO, ICursoEscolaFranquiaDTO, ICursoEscolaFranquiaFullDTO>(resourceURL),
		...baseFindById<ICursoEscolaFranquiaCleanDTO, ICursoEscolaFranquiaDTO, ICursoEscolaFranquiaFullDTO>(resourceURL)
	};
};
