import { Predicate } from '../../../../../models/predicate.model';
import { useAlunoContext } from '../../../../../context/AlunoContext';
import { useMatriculaService } from '../../../../../services/matricula.service';
import { useCobrancaService } from '../../../../../services/cobranca.service';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { IMatriculaCustomDTO } from '../../../../../models/happy-code-api.model';

export default function useSubmitUpgradeMatricula() {
	const { setIsGlobalLoading } = useGlobalContext();

	const matriculaService = useMatriculaService();

	const { idFinanceiro } = useParams<{ idFinanceiro: string }>();

	const cobrancaService = useCobrancaService();

	const { setMatricula, matricula } = useAlunoContext();

	const { push } = useHistory();

	const buildUpgradeRecorrenciaData = (
		calculoTotalTurma: number,
		pagamentoSelected: number,
		valorTotalParcela: number
	) => {
		const valorTotalComDesconto = matricula.financeiro.valorTotalComDesconto;

		const matriculaComValorTotalAlterado: IMatriculaCustomDTO = {
			...matricula,
			financeiro: {
				...matricula.financeiro,
				valorTotal: calculoTotalTurma,
				valorTotalComDesconto,
				valorMensalidade: valorTotalParcela,
				valorMensalidadeComDesconto: valorTotalParcela,
			},
			idTipoPagamento: pagamentoSelected,
		};

		return matriculaComValorTotalAlterado;
	};

	const buildUpgradePixBoletoData = (
		pagamentoSelected: number,
		saldoContratoAtual: number,
		calculoTotalOfertaTurma: number,
		valorTotalParcela: number,
		sumOfUpgradeValueAndCurrentEnrollmentValue: number
	) => {
		const valorTotalComDesconto = sumOfUpgradeValueAndCurrentEnrollmentValue;

		const valorTotal = calculoTotalOfertaTurma + saldoContratoAtual;

		const matriculaComValorTotalAlterado: IMatriculaCustomDTO = {
			...matricula,
			financeiro: {
				...matricula.financeiro,
				valorTotalComDesconto,
				valorTotal,
				valorMensalidade: valorTotalParcela,
				valorMensalidadeComDesconto: valorTotalParcela,
				valorOferta: valorTotal,
			},
			idTipoPagamento: pagamentoSelected,
		};

		return matriculaComValorTotalAlterado;
	};

	const submitUpgradeMatricula = async (
		cobrancaUnica: boolean,
		pagamentoSelected: number,
		sumOfUpgradeValueAndCurrentEnrollmentValue: number,
		calculoTotalTurma: number,
		saldoContratoAtual: number,
		valorTotalParcela: number,
		contratoAntigoIsRecorrente: boolean
	) => {
		try {
			setIsGlobalLoading(true);

			const isRecorrencia = [1, 3].includes(pagamentoSelected);

			const matriculaComValorAlterado = contratoAntigoIsRecorrente
				? buildUpgradeRecorrenciaData(
						calculoTotalTurma,
						pagamentoSelected,
						valorTotalParcela
				  )
				: buildUpgradePixBoletoData(
						pagamentoSelected,
						saldoContratoAtual,
						calculoTotalTurma,
						valorTotalParcela,
						sumOfUpgradeValueAndCurrentEnrollmentValue
				  );

			const { data: matriculaGerada } =
				await matriculaService.createMatriculaUpgrade(parseInt(idFinanceiro), {
					...matriculaComValorAlterado,
					dataInicioContrato: new Date(),
					cobrancaUnica,
				});

			setMatricula({ ...matricula, id: matriculaGerada.id });
			
			if (matricula.financeiro.valorMensalidadeComDesconto === 0)
				push('/matriculas/create/step-5');

			if (pagamentoSelected === 7) push('/matriculas/create/step-5');

			if (matriculaGerada.id && pagamentoSelected !== 7)
				push(`/matriculas/create/step-4/${matriculaGerada.id}`);
		} catch (error) {
			console.error(error);
			setIsGlobalLoading(false);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	return {
		submitUpgradeMatricula,
	};
}
