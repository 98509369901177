
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import { Button } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ICursoDTO } from '../../../../models/happy-code-api.model';

interface TItemLista {
    curso: ICursoDTO;
}

const AcademyCursoItemListCurso = memo(({ curso }: TItemLista) => {
    const [ativo, setAtivo] = useState<boolean>();

    useEffect(() => {
        setAtivo(curso.ativo);
    }, [curso.ativo])

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "20%" }}>
                <div className="nome">
                    <p>{curso.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{curso.id}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{curso.modalidade.descricao}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{curso.tipoCurso.descricao}</p>
            </div>
            <div className="registro-opcao" style={{ width: "20%" }}>
                <Link to={`/academy/gestao-material/cursos/${curso.id}/aulas`} >
                    <Button className="button-primary" id="criar-curso">
                        Ver Aula
                        <ArrowCircleRightOutlined style={{ marginLeft: 5 }} />
                    </Button>
                </Link>
            </div>
        </div>
    )
})

AcademyCursoItemListCurso.displayName = 'AcademyCursoItemListCurso';


export default AcademyCursoItemListCurso;
