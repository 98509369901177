import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { INegocioCleanDTO, IProdutoEscolaDTO } from '../../models/happy-code-api.model';
import { useNegocioService } from '../../services/negocio.service';
import { useNotificationService } from '../../services/notification.service';
import { useProdutoEscolaService } from '../../services/produto-escola.service';
import './style.css';

const ProdutoEscolaCreateStep1: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const [produtoEscola, setProdutoEscola] = useState<IProdutoEscolaDTO>();
    const [negocioList, setNegocioList] = useState<INegocioCleanDTO[]>();
    const { idProduto } = useParams<any>();
    const [isOpen, setIsOpen] = useState(false);
    const negocioService = useNegocioService();
    const produtoEscolaService = useProdutoEscolaService();
    const notification = useNotificationService();

    const findNegocios = () => {
        return negocioService.findCleanList()
            .then((response) => setNegocioList(response.data));
    }

    const save = () => {
        if (!idProduto) {
            setIsGlobalLoading(true);
            produtoEscolaService.create(produtoEscola)
                .then(() => {
                    notification({
                        description: `Produto criada com sucesso.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                }).finally(() => setIsGlobalLoading(false));
        } else {
            setIsGlobalLoading(true);
            produtoEscolaService.patch(produtoEscola)
                .then(() => {
                    notification({
                        description: `Produto editado com sucesso.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                }).finally(() => setIsGlobalLoading(false));
        }
    }

    const findProdutoEscola = () => {
        produtoEscolaService.findById({ id: idProduto })
            .then((response) => setProdutoEscola(response.data));
    }

    useEffect(() => {
        if (idProduto) {
            findProdutoEscola();
        }

        findNegocios();
    }, [idProduto])

    const history = useHistory();
    const onFinish = () => {
        history.push("/produto-escola");
    }

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ left: 0 }}>
                <div className="user-row">
                    <Form
                        name="basic"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <h3 id="taxas-h3">Insira o nome do produto escola</h3>
                        {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
                        <div className="input-box">
                            <p>Nome do Curriculo</p>
                            <Input style={{ width: '100%' }} value={produtoEscola?.descricao} id="nome" onChange={e => setProdutoEscola({ ...produtoEscola, descricao: e.target.value })} />
                        </div>

                        <div className="input-box">
                            <p>Código</p>
                            <Input style={{ width: '100%' }} value={produtoEscola?.codigo} id="codigo" onChange={e => setProdutoEscola({ ...produtoEscola, codigo: e.target.value })} />
                        </div>


                        <div className="input-box">
                            <p>Faixa etaria inicial</p>
                            <InputNumber style={{ width: '100%' }} min={0} value={produtoEscola?.faixaEtariaIni} id="descricao" onChange={e => setProdutoEscola({ ...produtoEscola, faixaEtariaIni: e })} />
                        </div>


                        <div className="input-box">
                            <p>Faixa etaria final</p>
                            <InputNumber style={{ width: '100%' }} min={0} value={produtoEscola?.faixaEtariaFim} id="descricao" onChange={e => setProdutoEscola({ ...produtoEscola, faixaEtariaFim: e })} />
                        </div>

                        <div className="input-box">
                            <p>Ano Escolar</p>
                            <InputNumber style={{ width: '100%' }} min={0} value={produtoEscola?.anoEscolar} id="descricao" onChange={e => setProdutoEscola({ ...produtoEscola, anoEscolar: e })} />
                        </div>


                        <div className="input-box">
                            <p>Negócio</p>
                            <Select
                                style={{ width: "100%" }}
                                id="negocio"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={produtoEscola?.negocio?.id}
                                value={produtoEscola?.negocio?.id}
                                onChange={(e) => setProdutoEscola({ ...produtoEscola, negocio: { ...produtoEscola?.negocio, id: e } })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {negocioList && negocioList.map(negocio =>
                                    <Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>{negocio.descricao}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className="botoes">
                            <Link to="/produto-escola">
                                <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                            </Link>
                            <Button onClick={save} type="primary" htmlType="submit" id="proximo" className="button-primary" style={{ width: 345 }}>Salvar</Button>


                        </div>
                    </Form>
                </div>
            </div>
            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a criação desse produto?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/produto-escola'
            />
        </div>
    )

}

export default ProdutoEscolaCreateStep1;