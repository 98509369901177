import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export const useNotificationService = () => {
    // const n = (msg: string, type: 'success' | 'danger' | 'info' | 'default' | 'warning' = 'success', title?: string) => {
    //     store.addNotification({
    //         title,
    //         message: msg,
    //         type,
    //         insert: "bottom",
    //         container: "bottom-right",
    //         animationIn: ["animate__animated", "animate__fadeIn"],
    //         animationOut: ["animate__animated", "animate__fadeOut"],
    //         dismiss: {
    //             duration: 5000,
    //             onScreen: true,
    //             pauseOnHover: true,
    //         }
    //     });
    // }
    return (args: ArgsProps): void => {
        notification[args.type || 'success']({
            placement: 'bottomRight',
            ...args
        })
    }
}