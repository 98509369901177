import { Button } from 'antd';
import '../../shared.scss';
import './style.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  status: string;
  text: string;
  link?: string;
  onCancelar?: () => void,
  onOkay?: () => void,
}

export const ModalResposta = (props: Props) => {
  const { open, status, text, link, onCancelar, onOkay } = props;

  if (!open) return null

  return (
    <>
      <div className="flex flex-row justify-center align-center modal-container">
        <div className="flex flex-column justify-start align-start modal-resposta">
          <h3>{status}</h3>
          <p>{text}</p>
          {link && <a href={`/${link}`}>
            <div className="flex flex-row justify-end w-100">
              <Button className="button-primary mr-5">Ok</Button>
            </div>
          </a>}

          {!link && <div className="flex flex-row justify-end w-100">
            <Button className="button-primary mr-5" onClick={onOkay}>Ok</Button>
            {onCancelar && <Button className="button-primary mr-5" onClick={onCancelar}>Cancelar</Button>}
          </div>}
        </div>
      </div>
    </>
  )
}
