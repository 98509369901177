import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Progress, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTaxaFixaContext } from '../../context/TaxaContext';

import { IClusterCleanDTO, IPaisCleanDTO, IUnidadeCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useClusterService } from '../../services/cluster.service';
import { usePaisService } from '../../services/pais.service';
import { useUnidadeService } from '../../services/unidade.service';
import StepsCreate from './steps-create';
import './style.css';

const TaxasCreateStep4: React.FunctionComponent = () => {
  const { setIsGlobalLoading } = useGlobalContext();
  const { taxaFixa, setTaxaFixa, revisao } = useTaxaFixaContext();
  const [paises, setPaises] = useState<IPaisCleanDTO[]>([]);
  const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>([]);
  const [clusters, setClusters] = useState<IClusterCleanDTO[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [value, setValue] = useState(0);

  const paisService = usePaisService();
  const unidadeService = useUnidadeService();
  const clusterService = useClusterService();

  const fetchInitialCountryData = async () => {
    setIsGlobalLoading(true);
    const predicate = new Predicate();
    paisService.findCleanList(predicate)
      .then(({ data }) => setPaises(data))
      .finally(() => setIsGlobalLoading(false));
  };

  const fetchInitialClusterData = async () => {
    setIsGlobalLoading(true);
    const predicate = new Predicate();
    clusterService.findCleanList(predicate)
      .then(({ data }) => setClusters(data))
      .finally(() => setIsGlobalLoading(false));
  };

  const fetchInitialUnidadeData = async () => {
    setIsGlobalLoading(true);
    const predicate = new Predicate();
    unidadeService.findCleanList(predicate)
      .then(({ data }) => setUnidades(data))
      .finally(() => setIsGlobalLoading(false));
  };

  useEffect(() => {
    switch (taxaFixa.nivelAcesso) {
      case 'PAIS':
        fetchInitialCountryData().finally(() => setValue(1));
        break;
      case 'CLUSTER':
        fetchInitialClusterData().finally(() => setValue(2));
        break;
      case 'FRANQUIA':
        fetchInitialUnidadeData().finally(() => setValue(3));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangePais(e: CheckboxChangeEvent, pais: IPaisCleanDTO) {
    e.preventDefault();
    e.stopPropagation();
    const { taxaPaises } = taxaFixa;
    if (e.target.checked) {
      taxaPaises.push({ id: null, ativo: true, paisId: pais.id, paisNome: pais.nome });
      setTaxaFixa({ ...taxaFixa, taxaPaises })
    } else {
      setTaxaFixa({ ...taxaFixa, taxaPaises: taxaPaises.filter(tp => tp.paisId !== pais.id) })
    }
  }

  function onChangeCluster(e: CheckboxChangeEvent, cluster: IClusterCleanDTO) {
    e.preventDefault();
    e.stopPropagation();
    const { taxaClusters } = taxaFixa;
    if (e.target.checked) {
      taxaClusters.push({ id: null, ativo: true, clusterId: cluster.id, clusterCodigo: cluster.codigo, clusterDescricao: cluster.descricao });
      setTaxaFixa({ ...taxaFixa, taxaClusters })
    } else {
      setTaxaFixa({ ...taxaFixa, taxaClusters: taxaClusters.filter(tp => tp.clusterId !== cluster.id) })
    }
  }

  const onUnidadesChange = (op) => {
    setTaxaFixa({ ...taxaFixa, taxaUnidades: op.map(o => ({ id: null, ativo: true, unidadeId: o.key, unidadeDescricao: o.children })) })
  }

  const onChangeRadio = e => {
    const valueTmp = e.target.value;
    setValue(valueTmp);
    switch (valueTmp) {
      case 1:
        clusters.length === 0 && fetchInitialCountryData();
        setTaxaFixa({ ...taxaFixa, taxaClusters: [], taxaUnidades: [], nivelAcesso: 'PAIS' });
        break;
      case 2:
        clusters.length === 0 && fetchInitialClusterData();
        setTaxaFixa({ ...taxaFixa, taxaPaises: [], taxaUnidades: [], nivelAcesso: 'CLUSTER' });
        break;
      case 3:
        unidades.length === 0 && fetchInitialUnidadeData();
        setTaxaFixa({ ...taxaFixa, taxaClusters: [], taxaPaises: [], nivelAcesso: 'FRANQUIA' });
        break;
    }
  };

  const optionsUnidade = unidades.map(u => <Select.Option key={u.id} value={u.descricao}>{u.descricao}</Select.Option>)

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={80} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={3} />
      <div className="franquia">
        <div className="user-row">
          <h3 id="taxas-h3">Selecione as franquias onde a taxa será aplicada</h3>
          <Radio.Group onChange={onChangeRadio} value={value}>
            <div className="boxes">
              <div className={value === 1 ? "box-check-selected" : "box-check"}>
                <Radio id="radio-1" value={1} />
                <div className="box-text">
                  <h6>Selecionar por</h6>
                  <p>País</p>
                </div>
              </div>
              <div className={value === 2 ? "box-check-selected" : "box-check"}>
                <Radio id="radio-2" value={2} />
                <div className="box-text">
                  <h6>Selecionar por</h6>
                  <p>Cluster</p>
                </div>
              </div>
              <div className={value === 3 ? "box-check-selected" : "box-check"}>
                <Radio id="radio-3" value={3} />
                <div className="box-text">
                  <h6>Selecionar por</h6>
                  <p>Franquias</p>
                </div>
              </div>
            </div>
          </Radio.Group>
          {value === 1 && <><h4>Selecione o(s) países desejados</h4>
            {paises?.map((pais) => {
              return (
                <div
                  className="box-check"
                  style={{ marginBottom: 7 }}
                  key={pais.id}
                >
                  <Checkbox id={pais.sigla}
                    onChange={(e) => onChangePais(e, pais)}
                    checked={taxaFixa.taxaPaises.some(p => p.paisId === pais.id)}
                  />
                  <p>{pais.nome}</p>
                </div>
              );
            })}</>}
          {value === 2 && <><h4>Selecione o(s) Cluster desejados</h4>
            {clusters?.map((cluster) => {
              return (
                <div
                  className="box-check"
                  style={{ marginBottom: 7 }}
                  key={cluster.id}
                >
                  <Checkbox id={"cluster-" + cluster.id}
                    onChange={(e) => onChangeCluster(e, cluster)}
                    checked={taxaFixa.taxaClusters.some(p => p.clusterId === cluster.id)}
                  />
                  <p>{cluster.codigo}</p>
                </div>
              );
            })}</>}
          {/* {value === 2 && <><h4>Selecione o(s) Cluster desejados</h4>
            <div className="box-select">
              <h5>Clusters</h5>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={taxaFixa.taxaClusters.map(c => (c.clusterDescricao))}
                onChange={(value, op) => onClustersChange(op)}
              >
                {optionsCluster}
              </Select>
            </div></>} */}
          {value === 3 && <><h4>Selecione a(s) Franquia desejada</h4>
            <div className="box-select">
              <h5>Franquias</h5>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={taxaFixa.taxaUnidades.map(c => (c.unidadeDescricao))}
                onChange={(value, op) => onUnidadesChange(op)}
              >
                {optionsUnidade}
              </Select>
            </div></>}
          <div className="botoes">
            {revisao ?
              <Link to="/taxas/create/step-6">
                <Button id="revisao" type="primary" className="button-primary" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/taxas/create/step-3">
                  <Button id="voltar" className="button-second" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/taxas/create/step-6">
                  <Button id="proximo" className="button-primary" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa taxa?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-primary'
        link2='/taxas'
      />
    </div>
  );
}

export default TaxasCreateStep4;