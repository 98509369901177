import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import { IMetaUnidadeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMetaUnidadeService } from '../../services/meta-unidade.service';
import FiltrosMeta from './filtros-meta';
import ItemListMetaUnidade from './item-lista';

export const MetasUnidade: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const [data, setData] = useState<PageableResponse<IMetaUnidadeDTO>>();
    const [predicate] = useState<Predicate>(new Predicate());
    const metaUnidadeService = useMetaUnidadeService();
    const history = useHistory();
    const { idMeta } = useParams<any>();

    const findMetaUnidade = () => {
        setIsGlobalLoading(true);
        predicate.addOption('meta.id', idMeta)
        return metaUnidadeService.find(predicate)
            .then(({ data }) => {
                setData(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    const ordenar = (campo) => {
        predicate.addSort(campo);
        findMetaUnidade();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        findMetaUnidade();
    }

    const goToAdd = () => {
        history.push(`/meta/${idMeta}/metas-unidade/create`);
    }

    return (
        <div className="container">
            <NewMenu selecionado={28} />
            <div className="dashboard">
                <div className="box-title">
                    <h3 className="title">Meta Unidades({data?.totalElements})</h3>
                    <Button className="button-primary" id="criar-curso" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Meta</Button>
                </div>
                <FiltrosMeta findMetaUnidade={findMetaUnidade} predicate={predicate} />
                <div className="tabela">
                    <div className="titles-users">
                        <div className="item" style={{ width: '20%' }}>
                            <span>NOME</span>
                            <div className="arrows" onClick={() => ordenar('unidade.descricao')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '15%' }}>
                            <span>Data</span>
                            <div className="arrows" onClick={() => ordenar('periodicidade')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '15%' }}>
                            <span>Valor Sede</span>
                            <div className="arrows" onClick={() => ordenar('valorSede')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '15%' }}>
                            <span>Valor Hub</span>
                            <div className="arrows" onClick={() => ordenar('valorHub')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '20%' }} />
                    </div>
                    <div className="tabela-body">
                        {data && data.content.map((metaUnidade, index) => (
                            <ItemListMetaUnidade metaUnidade={metaUnidade} key={index} />
                        ))}
                    </div>
                    <div className="paginacao">
                        <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                    </div>
                </div>
            </div>
        </div>
    );
}
