import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import {
	ICursoAulaPadraoDTO,
	ILogCleanDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useAcademyAulaPadraoService } from '../../../../services/academy-aula-padrao';
import { useLogService } from '../../../../services/log.service';
import FiltrosAula from './filtros-aula';
import AcademyItemListAula from './item-lista';
import './style.css';
import ButtonVoltar from '../../../../components/ButtonVoltar';

export const 	AcademyAulas: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<ICursoAulaPadraoDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const aulaPadraoService = useAcademyAulaPadraoService();
	const { setIsGlobalLoading } = useGlobalContext();
	const history = useHistory();
	const { idCurso } = useParams<any>();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso gestao material aulas academy',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const findAula = () => {
		setIsGlobalLoading(true);
		predicate.addOption('curso.id', idCurso);
		aulaPadraoService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findAula();
	};

	const ordenar = (campo) => {
		if (campo) {
			predicate.addSort(campo);
		}
		findAula();
	};
	
	const goToAdd = () => {
		history.push(`/academy/aulas/${idCurso}/create`);
	};

	return (
		<div className="container">
			<NewMenu selecionado={30} />
			<div
				className="dashboard"
			>
				<div className="box-title">
					<ButtonVoltar rota="/academy/gestao-material/cursos">
						Voltar
					</ButtonVoltar>
					<h3 className="title">
						{data?.content[0]?.curso.descricao} {data?.totalElements} aula(s)
						
					</h3>
					<Button
						className="button-primary"
						id="criar-curso"
						icon={<PlusOutlined rev={undefined} />}
						onClick={goToAdd}
					>
						Criar Aula
					</Button>
				</div>

				<FiltrosAula findAula={findAula} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '40%' }}>
							<span>Aula</span>
							<div className="arrows" onClick={() => ordenar('descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '40%' }} />
					</div>
					<div className="tabela-body">
						{data &&
							data.content.map((aula, index) => (
								<AcademyItemListAula aula={aula} key={index} />
							))}
					</div>

					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
