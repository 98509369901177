import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IUnidadeDTO } from '../../../models/happy-code-api.model';
import { useUnidadeService } from '../../../services/unidade.service';

export const useUnidade = () => {
    const { id: unidadeId } = useParams<any>();
    const [unidade, setUnidade] = useState<IUnidadeDTO>();
    const unidadeService = useUnidadeService();

    const findUnidade = () => {
        unidadeService.findById({ id: unidadeId })
            .then(response => setUnidade(response.data));
    };

    useEffect(() => {
        findUnidade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidadeId]);

    return { unidade, setUnidade };
};
