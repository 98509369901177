import { SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CalendarTurma } from '../../components/CalendarTurma';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';
import '../../global.css';
import { ICurriculoCleanDTO, ILogCleanDTO, INegocioUnidadeDTO, ITurmaFullDTO, ITurmaGradeSemanalDTO, IUsuarioDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCurriculoService } from '../../services/curriculo.service';
import { useLogService } from '../../services/log.service';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { useTurmaService } from '../../services/turma.service';
import { useUsuarioService } from '../../services/usuario.service';
import { formatMomentLocalDate } from '../../util/format';
import ItemListCurriculo from './item-list';
import './style.css';

const { RangePicker } = DatePicker;

export const Turmas: React.FunctionComponent = () => {
	const { removeTurma } = useTurmaContext();
	const { setIsGlobalLoading, usuario, unidade } = useGlobalContext();
	const [listTurma, setListTurma] = useState<PageableResponse<ITurmaFullDTO>>(null);
	const [listCurriculo, setListCurriculo] = useState<ICurriculoCleanDTO[]>(null);
	const [listNegocioUnidade, setListNegocioUnidade] = useState<INegocioUnidadeDTO[]>(null);
	const [usuarioTurma, setUsuarioTurma] = useState<IUsuarioDTO>(null);
	const [curriculoId, setCurriculoId] = useState<number | string>();
	const [negocioId, setNegocioId] = useState<number | string>();
	const [turmaPredicate] = useState<Predicate>(new Predicate());
	const [descricao, setDescricao] = useState<string>();
	const [filtroAtivo, setFiltroAtivo] = useState<boolean>(true);
	const [visualizacao, setVisualizacao] = useState<number>(0);
	const [turmaSemana, setTurmaSemana] = useState<ITurmaGradeSemanalDTO>();
	const [predicateTurma] = useState<Predicate>(new Predicate());
	const [dataInicio, setDataInicio] = useState<moment.Moment>();
	const [dataFim, setDataFim] = useState<moment.Moment>();

	const location = useLocation();

	const turmaService = useTurmaService();
	const usuarioService = useUsuarioService();
	const curriculoService = useCurriculoService();
	const negocioUnidadeService = useNegocioUnidadeService();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: "Acesso tela turmas franquia",
		descricaoTipo: "Acesso a tela",
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true
	});

	const getPeriodo = (): any => {
		if (dataInicio && dataFim) {
			return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')]
		}
		return null;
	}

	useEffect(() => {
		logService.registraLog(log)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const findTurmas = () => {
		setIsGlobalLoading(true);
		predicateTurma.addOption('ativo', filtroAtivo)
		predicateTurma.addOption('unidade.id', unidade.id)

		//const dataHoraFormatada = moment(`2023-09-26T${horaInicialFiltro}`);
		//predicateTurma.addOption('horarios.horaInicio', '09:00:00')

		if (unidade.id) {
			return turmaService.findGradeTurmaSemanal(predicateTurma)
				.then((response) => setTurmaSemana(response.data))
				.catch((e) => console.log(e))
				.finally(() => setIsGlobalLoading(false));
		}
	}

	const professorPath = '/turmas/professor';

	const onChangeAtivo = () => {
		setFiltroAtivo(!filtroAtivo);
	}

	const isProfessorPath = () => {
		if (location.pathname === professorPath) {
			return true;
		}
		return false;
	}

	const findUsuario = () => {
		return usuarioService.findById(usuario)
			.then((response) => {
				turmaPredicate.addOption('unidadePessoa.pessoa.id', response.data.pessoa.id);
				setUsuarioTurma(response.data);
			})
	}

	const findTurma = () => {
		setIsGlobalLoading(true)
		if (isProfessorPath()) {
			turmaPredicate.addOption('unidadePessoa.pessoa.id', usuarioTurma?.pessoa?.id);
		}
		if (unidade.id) {
			turmaPredicate.addOption('unidade.id', unidade.id);
			turmaPredicate.addOption('ativo', filtroAtivo);
			return turmaService.findFull(turmaPredicate)
				.then((response) => setListTurma(response.data))
				.finally(() => setIsGlobalLoading(false));
		}
	}

	const findCurriculos = () => {
		curriculoService.findCleanList().then((response) => setListCurriculo(response.data))
	}

	const findNegocios = () => {
		const predicate = new Predicate();
		predicate.addOption('unidade.id', unidade.id);
		negocioUnidadeService.findList(predicate).then((response) => setListNegocioUnidade(response.data))
	}

	useEffect(() => {
		if (unidade?.id) {
			findTurma();
			findTurmas();
			findCurriculos();
			findNegocios();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtroAtivo]);

	useEffect(() => {
		setIsGlobalLoading(true);
		removeTurma();
		if (unidade.id) {
			if (isProfessorPath()) {
				Promise.all([
					findUsuario(),
					findTurma(),
				]).finally(() => setIsGlobalLoading(false));
			} else {
				Promise.all([
					findTurma(),
					findTurmas(),
				]).finally(() => setIsGlobalLoading(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id]);

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			if (descricao !== undefined) {
				setIsGlobalLoading(true);
				turmaPredicate.addOption('descricao', descricao, Operators.CONTAINS);
				predicateTurma.addOption('descricao', descricao, Operators.CONTAINS);
				unidade.id && findTurma().finally(() => setIsGlobalLoading(false));
				unidade.id && findTurmas().finally(() => setIsGlobalLoading(false));
			}
		}, 1500);
		return () => clearTimeout(typingTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descricao]);

	useEffect(() => {
		predicateTurma.removeAllOption();
		turmaPredicate.removeAllOption();

		if (curriculoId && curriculoId !== 'TDS') {
			turmaPredicate.addOption('curriculo.id', curriculoId);
			predicateTurma.addOption('curriculo.id', curriculoId);
		} else {
			turmaPredicate.removeOption('curriculo.id');
			predicateTurma.removeOption('curriculo.id');
		}
		if (negocioId && negocioId !== 'TDS') {
			predicateTurma.addOption('curriculo.negocio.id', negocioId);
		} else {
			predicateTurma.removeOption('curriculo.negocio.id');
		}
		if ((dataInicio || dataFim)) {
			predicateTurma.addMultiOption('dataInicio', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
			predicateTurma.addMultiOption('dataFim', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
		}
		if (dataInicio || dataFim || negocioId || curriculoId || usuarioTurma) {
			setIsGlobalLoading(true);
			Promise.all([
				findTurma(),
				findTurmas(),
				// getKPI(),
			]).finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [negocioId, curriculoId, usuarioTurma, dataInicio, dataFim,]);

	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		turmaPredicate.setPage(page);
		setIsGlobalLoading(true);
		findTurma().finally(() => setIsGlobalLoading(false));
	}

	const onNegocioChange = (negocio) => {
		setNegocioId(negocio);
	}

	const onCurriculoChange = (curriculo) => {
		setCurriculoId(curriculo);
	}

	return (
		<div className="container">
			<NewMenu selecionado={13} />
			<div className="dashboard" style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}>

				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
					<Button style={{ marginRight: '10px' }} className="button-primary" onClick={() => setVisualizacao(0)}>Visualização Grade</Button>
					<Button className="button-primary" onClick={() => setVisualizacao(1)}>Visualização Lista</Button>
				</div>

				<div className="filtros">
					<div className="input-text" style={{ width: '85%' }}>
						<Input id="buscar" onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
					</div>
					<div className="filtro-estado">
						<span>Data</span>
						<RangePicker style={{ width: '250px' }} value={getPeriodo()} onChange={(values) => { setDataInicio(values && values[0]); setDataFim(values && values[1]); }} format={'DD-MM-YYYY'} />
					</div>
					<div className="filtro-estado" id="input-dashboard" style={{ minWidth: '15% !important' }}>
						<span>Negócio</span>
						<Select style={{ width: '100%' }} defaultValue="TDS" onChange={onNegocioChange}>
							<Select.Option style={{ width: '100%' }} value="TDS">Todos</Select.Option>
							{listNegocioUnidade && listNegocioUnidade.map((item) => (
								<Select.Option style={{ width: '100%' }} key={item.id} value={item.negocio.id}>{item.negocio.descricao}</Select.Option>
							))}
						</Select>
					</div>
					<div className="filtro-estado" id="input-dashboard">
						<span>Curriculo</span>
						<Select style={{ height: '100%' }} defaultValue="TDS" onChange={onCurriculoChange}>
							<Select.Option style={{ height: '100%' }} value="TDS">Todos</Select.Option>
							{listCurriculo && listCurriculo.map((item) => (
								<Select.Option style={{ width: '100%' }} key={item.id} value={item.id}>{item.descricaoCurriculo}</Select.Option>
							))}
						</Select>
					</div>
					<div className="filtro-estado" id="input-dashboard">
						<span>Status</span>
						<Select defaultValue="true" onChange={onChangeAtivo}>
							<Select.Option value={"true"}>Ativo</Select.Option>
							<Select.Option value={"false"}>Inativo</Select.Option>
						</Select>
					</div>
				</div>

				{visualizacao === 1 ?
					<div className="tabela">
						<div className="titles-users">
							<div className="item" style={{ width: '20%' }}>
								<span>NOME DA TURMA</span>
								<div className="arrows">
									<Unicons.UilDirection size="20" color="#92A9CB" />
								</div>
							</div>
							<div className="item" style={{ width: '15%' }}>
								<span>Currículo</span>
								<div className="arrows">
									<Unicons.UilDirection size="20" color="#92A9CB" />
								</div>
							</div>
							<div className="item" style={{ width: '5%' }}>
								<span>ALUNOS</span>
							</div>
							<div className="item" style={{ width: '10%' }}>
								<span>MODALIDADE</span>
								<div className="arrows">
									<Unicons.UilDirection size="20" color="#92A9CB" />
								</div>
							</div>
							<div className="item" style={{ width: '20%' }}>
								<span>INSTRUTOR</span>
								<div className="arrows">
									<Unicons.UilDirection size="20" color="#92A9CB" />
								</div>
							</div>
							<div className="item" style={{ width: '10%' }}>
								<span>INICIO</span>
								<div className="arrows">
									<Unicons.UilDirection size="20" color="#92A9CB" />
								</div>
							</div>
							<div className="item" style={{ width: '10%' }}>
								<span>TERMINO</span>
								<div className="arrows">
									<Unicons.UilDirection size="20" color="#92A9CB" />
								</div>
							</div>
							<div className="item" style={{ width: '10%' }} />
						</div>

						{listTurma && listTurma.content.map((turma, index) => (
							<ItemListCurriculo key={index} turma={turma}/>
						))}

						<div className="paginacao">
							<Pagination
								onChange={(event, page) => onPageChange(event, page - 1)}
								page={listTurma ? listTurma.pageable.pageNumber + 1 : 0}
								count={listTurma ? listTurma.totalPages : 0}
							/>
						</div>
					</div>
					:
					<>
						<div className="container-offer-page" style={{ marginTop: '0px' }}>
							<div className="box-calendar">
								<div className="calendario">
									<div className="body-calendario">
										<CalendarTurma turmaSemana={turmaSemana} telaTurma={true} />
									</div>
								</div>
							</div>
						</div>
					</>
				}
			</div>
		</div>
	);
}