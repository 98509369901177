import { Checkbox, DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	IStatusCobrancaCleanDTO,
	ITipoCursoDTO,
	IUnidadeCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useStatusCobrancaService } from '../../../services/status-cobranca.service';
import { useTipoCursoService } from '../../../services/tipo-curso.service';
import { useUnidadeService } from '../../../services/unidade.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { getPeriodo } from '../../../util/filtroRelatorio';
import { hoje } from '../../../util/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelMatriculaSede() {
	const dataPadrao = new Date(2023, 10, 5);
	const { setIsGlobalLoading } = useGlobalContext();
	const [dados, setData] = useState<[]>([]);
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [dataMatriculaInicio, setDataMatriculaInicio] = useState<moment.Moment>(
		moment(new Date())
	);
	const [dataMatriculaFim, setDataMatriculaFim] = useState<moment.Moment>(
		moment(new Date())
	);
	const [listStatusCobranca, setListStatusCobranca] =
		useState<IStatusCobrancaCleanDTO[]>();
	const [statusCobrancaId, setStatusCobrancaId] = useState<number | string>();
	const [dataPagoInicio, setDataPagoInicio] = useState<moment.Moment>();
	const [dataPagoFim, setDataPagoFim] = useState<moment.Moment>();
	const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>();
	const [tipoCursos, setTipoCursos] = useState<ITipoCursoDTO[]>();
	const [unidadeId, setUnidadeId] = useState();
	const [tipoCursoId, setTipoCursoId] = useState<number>(0);
	const [flRematricula, setFlRematricula] = useState(0);
	const [predicate] = useState<Predicate>(new Predicate());

	const service = useRelatorioService();
	const unidadeService = useUnidadeService();
	const tipoCursoService = useTipoCursoService();
	const statusCobrancaService = useStatusCobrancaService();

	const findStatusCobranca = () => {
		return statusCobrancaService
			.findCleanList(predicate)
			.then((response) => setListStatusCobranca(response.data));
	};

	const fetchUnidades = () => {
		return unidadeService
			.findCleanList()
			.then((response) => setUnidades(response.data));
	};

	const findCurso = () => {
		const predicate = new Predicate();
		predicate.addSort('descricao');
		return tipoCursoService.findList(predicate).then(({ data }) => {
			setTipoCursos(data);
		});
	};

	useEffect(() => {
		setIsGlobalLoading(true);
		predicate.removeAllOption();

		if (dataMatriculaFim) {
			predicate.addOption('dataMatriculaFim', dataMatriculaFim.toISOString());
		} else {
			predicate.addOption(
				'dataMatriculaFim',
				moment(dataPadrao, 'YYYY-MM-DD').toISOString()
			);
		}
		dataMatriculaInicio &&
			predicate.addOption(
				'dataMatriculaInicio',
				dataMatriculaInicio.toISOString()
			);
		dataPagoInicio &&
			predicate.addOption('dataPagoInicio', dataPagoInicio.toISOString());
		dataPagoFim &&
			predicate.addOption('dataPagoFim', dataPagoFim.toISOString());
		tipoCursoId && predicate.addOption('idTipoCurso', tipoCursoId);
		unidadeId && predicate.addOption('idUnidade', unidadeId);
		flRematricula && predicate.addOption('flRematricula', flRematricula);
		if (statusCobrancaId == 'TDS') {
			predicate.removeOption('idStatusCobranca');
		} else {
			statusCobrancaId &&
				predicate.addOption('idStatusCobranca', statusCobrancaId);
		}
		service
			.matriculaSede(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));

		findCurso(), fetchUnidades(), findStatusCobranca();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dataMatriculaFim,
		dataMatriculaInicio,
		dataPagoInicio,
		dataPagoFim,
		tipoCursoId,
		statusCobrancaId,
		flRematricula,
		unidadeId,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
	}, [dados]);

	let valorTotal = 0;
	let valorTotalPago;
	let valorTotalSede = 0;
	let valorTotalSedeReal;
	let valorTotalFranquia = 0;
	let valorTotalFranquiaReal;
	let valorTotalCobranca = 0;
	let valorTotalCobrancaReal;
	let totalMatricula = 0;
	let totalCurta = 0;
	let totalRegular = 0;

	dados.forEach((dado) => {
		valorTotal += parseFloat(String(dado['C08_VALOR_TOTAL']).replace(',', '.'));
		valorTotalPago = valorTotal.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
		valorTotalSede += parseFloat(
			String(dado['C09_VALOR_SEDE']).replace(',', '.')
		);
		valorTotalSedeReal = valorTotalSede.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
		valorTotalFranquia += parseFloat(
			String(dado['C10_VALOR_FRANQUIA']).replace(',', '.')
		);
		valorTotalFranquiaReal = valorTotalFranquia.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
		valorTotalCobranca += parseFloat(
			String(dado['C11_VALOR_COBRANCA']).replace(',', '.')
		);
		valorTotalCobrancaReal = valorTotalCobranca.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
		totalMatricula = dados.length;
		dado['C06_TIPO_CURSO'] == 'Regular' || dado['C06_TIPO_CURSO'] == 'Plus'
			? totalRegular++
			: dado['C06_TIPO_CURSO'] == 'Curta'
			? totalCurta++
			: null;
	});

	const disabledDate = (current) => {
		return current && current > moment('2023-11-05', 'YYYY-MM-DD');
	};

	const getPeriodoPago = (): any => {
		if (dataPagoInicio && dataPagoFim) {
			return [
				moment(dataPagoInicio, 'YYYY-MM-DD'),
				moment(dataPagoFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const onChangeRematricula = () => {
		if (flRematricula == 0) {
			setFlRematricula(1);
		} else {
			setFlRematricula(0);
		}
	};

	const selectCursos = tipoCursos?.map((tipoCurso) => (
		<Option key={tipoCurso.id} value={tipoCurso.id}>
			{tipoCurso?.descricao}
		</Option>
	));
	const selectUnidade = unidades?.map((unidade) => (
		<Option key={unidade.id} value={unidade.id}>
			{unidade.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={18} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<div className="box-header-contador-relatorio">
					<div className="box-informe">
						<h1>Sede</h1>
						<p>{valorTotalSedeReal}</p>
					</div>
					<div className="box-informe">
						<h1>Franquia</h1>
						<p>{valorTotalFranquiaReal}</p>
					</div>
					<div className="box-informe">
						<h1>Vendido</h1>
						<p>{valorTotalPago}</p>
					</div>
					<div className="box-informe">
						<h1>Cobranças</h1>
						<p>{valorTotalCobrancaReal}</p>
					</div>
					<div className="box-informe">
						<h1>Matrículas Curta</h1>
						<p>{totalCurta}</p>
					</div>
					<div className="box-informe">
						<h1>Matrículas Regulares</h1>
						<p>{totalRegular}</p>
					</div>
					<div className="box-informe">
						<h1>Total matrículas</h1>
						<p>{totalMatricula}</p>
					</div>
				</div>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório de matrículas diário
				</h1>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Período de matrículas</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataMatriculaInicio(values && values[0]);
								setDataMatriculaFim(values && values[1]);
							}}
							value={getPeriodo(
								dataMatriculaInicio,
								dataMatriculaFim,
								dataPadrao
							)}
							disabledDate={disabledDate}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Período de pagamento</span>
						<br />
						<RangePicker
							value={getPeriodoPago()}
							onChange={(values) => {
								setDataPagoInicio(values && values[0]);
								setDataPagoFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Tipo Curso</span>
						<br />
						<Select
							allowClear
							showSearch
							style={filtroStyle}
							defaultValue={tipoCursoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setTipoCursoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectCursos}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Unidade</span>
						<br />
						<Select
							allowClear
							showSearch
							style={filtroStyle}
							defaultValue={unidadeId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setUnidadeId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectUnidade}
						</Select>
					</div>
					<div className="flex flex-row" style={{ marginTop: 20 }}>
						<span style={{ fontSize: '0.8em', marginRight: 5 }}>
							Rematricula
						</span>
						<Checkbox onChange={onChangeRematricula} />
					</div>
					<div
						className="filtro-estado"
						id="input-dashboard"
						style={{ margin: 10 }}
					>
						<span>Status</span>
						<Select
							listHeight={400}
							onChange={(value) => setStatusCobrancaId(value.toString())}
						>
							<Select.Option value="TDS">Todos</Select.Option>
							<Select.Option value="VNC">Vencidos</Select.Option>
							{listStatusCobranca &&
								listStatusCobranca.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.descricao}
									</Select.Option>
								))}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={dados}
							fileName={`Matrícula diária sede ` + hoje().toISOString()}
						/>
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}
export default RelMatriculaSede;
