import { ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO } from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyTopicoService = () => {
	const resourceURL = '/topico-aula';

	return {
		...baseFind<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(resourceURL),
		...baseFindList<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(resourceURL),
		...baseFindById<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(resourceURL),
		...baseCreate<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(resourceURL),
		...baseUpdate<ITopicoAulaCleanDTO, ITopicoAulaDTO, ITopicoAulaFullDTO>(resourceURL),
		/*
		createFull: (body): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IAcaCursoCleanDTO>> =>
		http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		*/
	};
};
