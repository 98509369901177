import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IconButton from '../../../../components/ButtonVoltar';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import {
	IAcaCursoDTO,
	ILogCleanDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useAcademyCurso } from '../../../../services/academy-curso';
import { useLogService } from '../../../../services/log.service';
import FiltrosCurso from './filtros-curso';
import AcademyCursoItemListCurso from './item-lista';
import './style.css';

export const AcademyAcaCurso: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const [data, setData] = useState<PageableResponse<IAcaCursoDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const cursoService = useAcademyCurso();
	const history = useHistory();
	const { idTrilha } = useParams<any>();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso gestao trilha curso academy',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const findCurso = () => {
		setIsGlobalLoading(true);
		predicate.addOption('trilha.id', idTrilha);
		cursoService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findCurso();
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findCurso();
	};

	const goToAdd = () => {
		history.push(`/academy/trilhas/${idTrilha}/cursos/create`);
	};

	return (
		<div className="container">
			<NewMenu selecionado={31} />
			<div className="dashboard">
				<div className="box-title">
					<IconButton rota="/academy/gestao-trilha/trilha">Voltar</IconButton>
					<h3 className="title">
						{data ? (
						<>
							{(data.content[0]?.trilha.nome1 || '') + ' ' + (data.content[0]?.trilha.nome2 || '')}{' '}
							{data.totalElements} curso(s)
						</>
						) : (
							<h3>carregando...</h3>
						)}

					</h3>
					<Button
						className="button-primary"
						id="criar-curso"
						icon={<PlusOutlined rev={undefined} />}
						onClick={goToAdd}
					>
						Criar Curso
					</Button>
				</div>

				<FiltrosCurso findCurso={findCurso} predicate={predicate} />
				<div className="tabela mt20">
					<div className="titles-users">
						<div className="item" style={{ width: '20%' }}>
							<span>CURSO</span>
							<div className="arrows" onClick={() => ordenar('descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>

						<div className="item" style={{ width: '20%' }}>
							<span>MODALIDADE</span>
							<div
								className="arrows"
								onClick={() => ordenar('modalidade.descricao')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>TIPO</span>
							<div
								className="arrows"
								onClick={() => ordenar('tipoCurso.descricao')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>NEGOCIO</span>
							<div
								className="arrows"
								onClick={() => ordenar('negocio.descricao')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>CÓDIGO PRODUTO</span>
							<div
								className="arrows"
								onClick={() => ordenar('codigoProduto')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}></div>
					</div>

					<div className="tabela-body">
						{data &&
							data.content.map((curso, index) => (
								<AcademyCursoItemListCurso curso={curso} key={index} />
							))}
					</div>

					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
