import { useNotificationService } from '../../../../../services/notification.service';
import { useState } from "react";
import { useEscolaContext } from "../../../../../context/EscolaContext";
import { useGlobalContext } from "../../../../../context/GlobalContext";
import { useLeadEscolaService } from "../../../../../services/lead-escola.service";
import { useCrmEscolaContext } from "../../../context/crm-escola.context";
import { PerfilEnum } from '../../../../../enum/perfil.enum';

const useNovoLeadStep5ComponentState = () => {
  const [state, setState] = useState();
  const [grupos, setGrupos] = useState<any>();

  const {
    leadParaCadastrar,
    setLeadParaCadastrar,
    onFecharModal,
    setIndexCadastro,
    indexCadastro,
    unidadeUsuarioLogado,
    limparLeadsParaCadastrar,
    refresh
  } = useCrmEscolaContext();

  const { categoriasQuantidadeAlunos } = useEscolaContext();
  const { setIsGlobalLoading, unidade, usuario } = useGlobalContext();
  const [leadOrigens, setLeadOrigens] = useState<any>();
  const [leadCampanhas, setLeadCampanhas] = useState<any>();
  const [leadEstagios, setLeadEstagios] = useState<any>();
  const [dtProximoRetorno, setDtProximoRetorno] = useState<any>();
  const [avaliacaoLead, setAvaliacaoLead] = useState<any>();
  const [intra, setIntra] = useState<any>();
  const [extra, setExtra] = useState<any>();
  const [isModalConfirmacao, setIsModalConfirmacao] = useState<boolean>(false);
  const [bodyFinal, setBodyFinal] = useState();
  const leadEscolaService = useLeadEscolaService();
  const notification = useNotificationService();

  const onFinish = (form) => {
    setIsModalConfirmacao(true);
    let unidadeLead;

    if (usuario.perfil === PerfilEnum.FRANQUIA) {
      unidadeLead = unidade;
    } else {
      unidadeLead = unidadeUsuarioLogado
    }

    const body = {
      ...leadParaCadastrar,
      ...form,
      precoPropostoIntra: intra,
      precoPropostoExtra: extra,
      novo: !leadParaCadastrar?.id,
      unidade: unidadeLead,
      campanha: leadParaCadastrar?.campanha?.id ? leadParaCadastrar?.campanha : null,
    };

    setLeadParaCadastrar({ ...body });
    setBodyFinal({ ...body });

    // setIndexCadastro(indexCadastro + 1)

  }

  const onCloseModalConfirmacao = () => {
    setIsModalConfirmacao(false);
  }

  const onCadastrarLead = () => {
    if (isModalConfirmacao && bodyFinal) {
      setIsGlobalLoading(true);
      leadEscolaService.create(bodyFinal).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setBodyFinal(null);
          setIsModalConfirmacao(false);
          limparLeadsParaCadastrar();
          refresh();
          notification({ description: 'Lead cadastrado!', type: 'success', message: 'Sucesso!' });

        }
      }).catch((e) => {
        notification({ description: 'Houve um problema! ' + e?.message, type: 'error', message: 'Falha no cadastro do lead!' });
      }).finally(() => {
        setIsGlobalLoading(false);
      });
    }

  }

  return {
    state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
    leadOrigens,
    leadCampanhas,
    leadEstagios,
    setDtProximoRetorno,
    dtProximoRetorno,
    avaliacaoLead,
    setAvaliacaoLead,
    intra,
    setIntra,
    extra,
    setExtra,
    isModalConfirmacao,
    setIsModalConfirmacao,
    onCloseModalConfirmacao,
    onCadastrarLead
  }
}

export default useNovoLeadStep5ComponentState;
