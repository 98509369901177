import http from '../helpers/http-common';
import {
	IRecursoCleanDTO,
	IRecursoDTO,
	IRecursoFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useRecursoService = () => {
	const resourceURL = 'recurso';

	return {
		...baseFind<IRecursoCleanDTO, IRecursoDTO, IRecursoFullDTO>(resourceURL),
		...baseFindList<IRecursoCleanDTO, IRecursoDTO, IRecursoFullDTO>(
			resourceURL
		),
		...baseUpdate<IRecursoCleanDTO, IRecursoDTO, IRecursoFullDTO>(
			resourceURL
		),
		...baseFindById<IRecursoCleanDTO, IRecursoDTO, IRecursoFullDTO>(
			resourceURL
		),
		...baseCreate<IRecursoCleanDTO, IRecursoDTO, IRecursoFullDTO>(
			resourceURL
		),
		createFull: (body): Promise<IAxiosResponseCustom<IRecursoCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patchFull: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<IRecursoCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
