import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import { Predicate } from '../../models/predicate.model';

interface TFiltrosCurso {
    predicate: Predicate;
    findMetaTipo: () => void;
}

const FiltrosHoraUnidade = memo(({ predicate, findMetaTipo }: TFiltrosCurso) => {
    const [descricao, setDescricao] = useState<string>();
    const [status, setStatus] = useState<number>();

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('unidade.descricao', descricao, Operators.CONTAINS);
            }
            if (status) {
                predicate.addOption('ativo', status == 1 ? true : false);
            }
            findMetaTipo();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, status]);

    return (
			<>
				<div className="filtros">
					<div className="input-text" style={{ width: '90%' }}>
						<Input
							id="buscar"
							value={descricao}
							placeholder="Buscar unidade"
							prefix={
								<SearchOutlined rev={undefined} style={{ color: '#6A7C96' }} />
							}
							onChange={(e) => setDescricao(e.target.value)}
						/>
					</div>
					<div
						className="filtro-estado input-dashboard"
						style={{ width: '15%' }}
					>
						<span>Status</span>
						<Select
							className="ant-dropdown-link"
							defaultValue="0"
							onChange={(e) => setStatus(parseInt(e))}
						>
							<Select.Option value="0">Todos</Select.Option>
							<Select.Option key={1} value={1}>
								Ativo
							</Select.Option>
							<Select.Option key={2} value={2}>
								Inativo
							</Select.Option>
						</Select>
					</div>
				</div>
			</>
		);
})

FiltrosHoraUnidade.displayName = 'FiltrosHoraUnidade'

export default FiltrosHoraUnidade;

