import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { ILogCleanDTO, INegocioCleanDTO, INegocioDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';

import { useNegocioContext } from '../../context/NegocioContext';
import { useLogService } from '../../services/log.service';
import { useNegocioService } from '../../services/negocio.service';
import FiltrosCurriculo from './filtros-negocio';
import ItemListNegocio from './item-lista';
import './style.css';

const Negocio: React.FunctionComponent = () => {

  const { removeNegocio, setRevisao } = useNegocioContext();
  const [data, setData] = useState<PageableResponse<INegocioDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const negocioService = useNegocioService();
  const { setIsGlobalLoading } = useGlobalContext();

  const history = useHistory();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso negocios",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    removeNegocio();
    setRevisao(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    logService.registraLog(log)
  }, [])

  const findNegocio = () => {
    setIsGlobalLoading(true);
    negocioService.find(predicate)
      .then(({ data }) => setData(data))
      .finally(() => setIsGlobalLoading(false));
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findNegocio();
  }

  const goToAdd = () => {
    history.push("/negocio/create/step-1");
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findNegocio();
  }

  const handleCampanha = (negocio: INegocioCleanDTO, state: boolean) => {
    negocio.ativo = state;
    setIsGlobalLoading(true);
    negocioService.patch(negocio)
      .then(() => findNegocio())
      .finally(() => setIsGlobalLoading(false));
  }

  return (
    <div className="container">
      <NewMenu selecionado={107} />
      <div className="dashboard">
        <div className="box-title">
          <h3 className="title">Negocios ({data?.totalElements})</h3>
          <Button className="button-primary" id="criar-campanha" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Negocio</Button>
        </div>
        <FiltrosCurriculo findNegocio={findNegocio} predicate={predicate} />
        <div className="tabela">
          <div className="titles-users">
            <div className="item" style={{ width: '20%' }}>
              <span>Nome</span>
              <div className="arrows" onClick={() => ordenar('codigo')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>ID</span>
              <div className="arrows" onClick={() => ordenar('id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '20%' }}>
              <span>Tipo Currículo</span>
              <div className="arrows" onClick={() => ordenar('tipoCurriculo.id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>   
            <div className="item" style={{ width: '20%' }}>
              <span>Código</span>
              <div className="arrows" onClick={() => ordenar('dataInicio')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>            
            <div className="item" style={{ width: '20%' }}></div>
          </div>
          <div className="tabela-body">
            {data && data.content.map((negocio, index) => (
              <ItemListNegocio handleCampanha={(negocio, state) => handleCampanha(negocio, state)} negocio={negocio} key={index} />
            ))}
          </div>
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Negocio;