import {
	IGrupoContratoTipoCleanDTO,
	IGrupoContratoTipoDTO,
	IGrupoContratoTipoFullDTO
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useGrupoContratoTipoService = () => {
	const resourceURL = 'grupo-contrato-tipo';

	return {
		...baseFindList<IGrupoContratoTipoCleanDTO, IGrupoContratoTipoDTO, IGrupoContratoTipoFullDTO>(resourceURL),
		...baseFind<IGrupoContratoTipoCleanDTO, IGrupoContratoTipoDTO, IGrupoContratoTipoFullDTO>(resourceURL),
		...baseCreate<IGrupoContratoTipoCleanDTO, IGrupoContratoTipoDTO, IGrupoContratoTipoFullDTO>(resourceURL),
		...baseUpdate<IGrupoContratoTipoCleanDTO, IGrupoContratoTipoDTO, IGrupoContratoTipoFullDTO>(resourceURL),
		...baseFindById<IGrupoContratoTipoCleanDTO, IGrupoContratoTipoDTO, IGrupoContratoTipoFullDTO>(resourceURL),
	};
};
