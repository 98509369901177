import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Progress, Select, Steps } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';

import { Predicate } from '../../models/predicate.model';
import { useCursoService } from '../../services/curso.service';
import { ICursoCleanDTO } from './../../models/happy-code-api.model';
import './style.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Form {
  id: number;
  descricao: string;
  dataInicio: any;
  dataFim: any;
  curso: any;
}

export const StemplayTurmaEdit: React.FunctionComponent = () => {
  const { setIsGlobalLoading, unidade } = useGlobalContext();
  const { turma, setTurma } = useTurmaContext();
  const { Step } = Steps;
  const [isOpen, setIsOpen] = useState(false);
  const [listCurso, setListCurso] = useState<ICursoCleanDTO[]>([]);
  const [cursoPredicate] = useState<Predicate>(new Predicate(100));
  const [descricao, setDescricao] = useState<string>();
  const [form, setForm] = useState<Form>({
    id: null,
    descricao: null,
    dataInicio: null,
    dataFim: null,
    curso: { id: null }
  });

  const history = useHistory();
  const cursoService = useCursoService();

  const findCurso = () => {
    setIsGlobalLoading(true);
    cursoPredicate.addOption('cursoUnidades.unidade.id', unidade.id);
    cursoPredicate.addOption('ativo', true);
    cursoService.findClean(cursoPredicate)
      .then((response) => setListCurso(response.data.content))
      .finally(() => setIsGlobalLoading(false));
  }

  useEffect(() => {
    findCurso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (turma) {
      form.curso.id = turma.curso.id;
      form.dataFim = turma.dataFim;
      form.dataInicio = turma.dataInicio;
      form.descricao = turma.descricao;
      form.id = turma.id;
      setForm({ ...form });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turma]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao !== undefined) {
        cursoPredicate.addOption('descricao', descricao, Operators.CONTAINS);
        findCurso()
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  const onSearch = (searchText: string) => {
    setDescricao(searchText);
  };

  const handleSubmit = () => {
    if (form.descricao && form.dataInicio && form.dataFim && form.curso.id) {
      turma.codigo = form.descricao;
      setTurma({ ...turma, ...form });
      history.push("/turmas/create/step-2");
    }
  };

  const handleCursoSelect = (data) => {
    setForm({ ...form, curso: { id: data.key } })
    setTurma({
      ...turma,
      curso: {
        ...turma.curso,
        id: data.key,
        descricao: data.value
      }
    })
  }

  const handleDescricao = (descricao) => {
    setForm({ ...form, descricao })
    setTurma({ ...turma, descricao })
  }

  const getPeriodo = (): any => {
    if (form.dataInicio && form.dataFim) {
      return [moment(form.dataInicio, 'YYYY-MM-DD'), moment(form.dataFim, 'YYYY-MM-DD')]
    }
    return null;
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
      </div>
      <div className="franquia">
        <div className="space1">
        </div>
        <div className="user-row" id="row-aluno">
          <h3>Dados básicos de turma</h3>
          <div className="input-box" >
            <p>ID</p>
            <Input disabled={true} value={form.id} className="input" />
          </div>
          <div className="input-box">
            <p>Nome da Turma <span id="ob">*</span></p>
            <Input value={form.descricao} onChange={(e) => handleDescricao(e.target.value)} placeholder="Nome turma" />
          </div>
          <div className="inputs-box">
            <div className="input-box" id="box-aluno">
              <p>Etapa Escolar<span id="ob">*</span></p>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Selecione uma turma"
                optionFilterProp="children"
                value={turma.curso.descricao}
                onChange={(v, data) => handleCursoSelect(data)}
                onSearch={onSearch}
              >
                {listCurso.map((curso: ICursoCleanDTO) => (
                  <Option key={curso.id} value={curso.descricao}>
                    {curso.descricao}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-box" id="box-aluno">
              <p>Ano<span id="ob">*</span></p>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Selecione uma turma"
                optionFilterProp="children"
                value={turma.curso.descricao}
                onChange={(v, data) => handleCursoSelect(data)}
                onSearch={onSearch}
              >
                {listCurso.map((curso: ICursoCleanDTO) => (
                  <Option key={curso.id} value={curso.descricao}>
                    {curso.descricao}
                  </Option>
                ))}
              </Select>
            </div>
          </div>


          <div className="botoes">
            <Link to="/Stemplay/turmas">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <div onClick={() => handleSubmit()}>
              <Button className="button-primary" style={{ width: 345 }}>Editar Turma</Button>
            </div>
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a edição desta nova turma?'
        subtitle='Todos os dados serão perdidos e essa ação não poderá ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/Stemplay/turmas'
      />
    </div>
  );
}
export default StemplayTurmaEdit