import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { IPerfilCleanDTO } from '../../models/happy-code-api.model';
import { usePerfilService } from '../../services/perfil.service';
import './style.css';

interface TInputs {
	open: boolean;
	onClose: () => void;
};

const ModalCreateUser: React.FunctionComponent<TInputs> = ({ open, onClose }: TInputs) => {
	const { setIsGlobalLoading } = useGlobalContext();
	const { usuario, setUsuario } = useUsuarioContext();

	const [perfilList, setPerfilList] = useState<IPerfilCleanDTO[]>();

	const perfilService = usePerfilService();

	useEffect(() => {
		setIsGlobalLoading(true);
		const fetchPerfilData = async () => {
			try {
				const { data } = await perfilService.findCleanList();
				setPerfilList(data);
				setIsGlobalLoading(false)
			} catch (error) {
				//
			}
		};
		fetchPerfilData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onClickPerfil = (perfil: IPerfilCleanDTO) => {
		setUsuario(state => ({ ...state, perfil, nivelAcesso: 'FRANQUIA' }));
	};

	const returnClassName = (id) => usuario.perfil.id === id ? 'box-select' : 'box';
	const returnColor = (id) => usuario.perfil.id === id ? "var(--white-color)" : "var(--primary-color)";

	if (!open) return null;

	return (
		<div className="modalContainer">
			<div className="modal-user">
				<button onClick={onClose} className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</button>
				<h3>Qual tipo de usuário você deseja criar?</h3>
				{/* <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
				<div className="boxes">
					{perfilList &&
						perfilList.map((perfil) => (
							<button id={"tipo-" + perfil.codigo}
								onClick={() => onClickPerfil(perfil)}
								className={returnClassName(perfil.id)}
								key={perfil.id}
							>
								{perfil.codigo === 'SEDE' ?
									<Unicons.UilGlobe size="20" color={returnColor(perfil.id)} />
									:
									<Unicons.UilBuilding size="20" color={returnColor(perfil.id)} />
								}
								<p>{perfil.descricao}</p>
							</button>
						))}
				</div>
				<div className="line"></div>
				<div className="botoes">
					<Button id="cancelar-usuario" className="button-line" style={{ marginRight: 10 }}>Cancelar Usuário</Button>
					<Link
						to={
							usuario.perfil.descricao === 'Sede'
								? '/usuarios/create/step-1'
								: '/usuarios/create/franquia/step-1'
						}
					>
						<Button id="proximo" className="button-primary" style={{ width: 120 }}>Próximo</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ModalCreateUser;