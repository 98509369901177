import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useLogService } from '../../../services/log.service';
import { ILogCleanDTO } from '../../../models/happy-code-api.model';

const useEscolaScreenState = () => {
	const { escolas, fetchAll } = useEscolaContext();

	const logService = useLogService();
	const log: ILogCleanDTO = {
		descricaoEvento: 'Acesso tela escolas',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	};

	useEffect(() => {
		logService.registraLog(log);

		fetchAll();
	}, []);

	const history = useHistory();

	const onCriarGrupoPress = () => history.push('/escolas/create/step-1');

	return {
		onCriarGrupoPress,
	};
};

export default useEscolaScreenState;
