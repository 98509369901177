import { CloseOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined } from '@mui/icons-material';
import { Button, InputNumber, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import info from '../../assets/img/financeiro-info/info.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import MsgInfoSistema from '../../components/MsgInfoSistema';
import { useGlobalContext } from '../../context/GlobalContext';

import { MenuDetalheNegocioOferta } from '../../components/MenuDetalheNegocioOferta';
import { INegocioUnidadeDTO } from '../../models/happy-code-api.model';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { currencyFormatter, currencyParser } from '../../util/format';

export const NegocioOfertaCreateStep1: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const negocioUnidadeService = useNegocioUnidadeService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [restartOferta, setRestartOferta] = useState<boolean>(false)
    const [oferta, setOferta] = useState<INegocioUnidadeDTO>();
    const { idNegocio } = useParams<any>();
    const parcelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const [hideDisplay, setHideDisplay] = useState(false);

    const findOferta = () => {
        setIsGlobalLoading(true);
        negocioUnidadeService.findById({ id: idNegocio })
            .then((response) => {
                setOferta(response.data);
            }).finally(() => setIsGlobalLoading(false));
    };

    useEffect(() => {
        findOferta();
    }, [])

    useEffect(() => {
        if (restartOferta) {
            setOferta({
                ...oferta,
                valorMinimo: oferta?.negocio.valorMinimo,
                valorHora: oferta?.negocio.valorHora,
                numeroParcelasMaximo: oferta?.negocio.numeroParcelasMaximo,
                flTaxaMatricula: oferta?.negocio.flTaxaMatricula,
                valorSplitTaxa: oferta?.negocio.valorSplitTaxa,
            })
            setRestartOferta(false);
        }
    }, [restartOferta])

    return (
        <div className="container-fondo">
            <Header />
            <MenuDetalheNegocioOferta
                setRestartOferta={setRestartOferta}
                oferta={oferta}
            />
            <div className="franquia" style={{ marginTop: 60 }}>                
                <div className="user-row">

                    <div className="card-form">
                        <h3 id="taxas-h3" style={{ fontWeight: 700 }}>Matrícula</h3>
                        <h3 id="taxas-h3">1. Qual será o preço mínimo desse curso?</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                defaultValue={oferta?.valorMinimo}
                                value={oferta?.valorMinimo}
                                min={0}
                                onChange={(e) => setOferta({ ...oferta, valorMinimo: e })}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <h3 id="taxas-h3">2. Preencha o valor do split.</h3>
                            <img className="config-icons-financeiro" src={info} onClick={(e) => setHideDisplay(true)} />
                            {hideDisplay && (
                                <MsgInfoSistema
                                    setHideDisplay={setHideDisplay}
                                    hideDisplay={false}
                                    icon={info}
                                    title={"Valor Split"}
                                    descricaoOne={"O valor inserido no campo valor do split não pode ser superior ao preço mínimo da matrícula."}
                                />
                            )}
                        </div>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={oferta?.valorMinimo}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                decimalSeparator=","
                                defaultValue={oferta?.valorHora}
                                value={oferta?.valorHora}
                                onChange={(e) => setOferta({ ...oferta, valorHora: e })} />
                        </div>

                        <h3 id="taxas-h3">3. Qual será o máximo de parcelas?</h3>
                        <div className="input-box" style={{ width: "100%" }}>
                            <Select
                                id="valor"
                                style={{ background: 'white', width: '100%', marginBottom: 5 }}
                                defaultValue={oferta?.numeroParcelasMaximo}
                                value={oferta?.numeroParcelasMaximo}
                                onChange={(e) => setOferta({ ...oferta, numeroParcelasMaximo: e })}>
                                {parcelas.map((parcela, index) =>
                                    <Select.Option key={index} value={parcela}>{parcela}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <h3 id="taxas-h3">4. Deseja utilizar a taxa de Matrícula?</h3>
                        <div className="input-box" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "max-content" }}>
                            <span style={{ width: 200 }}>Habilitar taxa: </span>
                            <Radio.Group
                                style={{ display: "flex", justifyContent: "center" }}
                                defaultValue={oferta?.flTaxaMatricula}
                                value={oferta?.flTaxaMatricula}
                                onChange={(e) => setOferta({ ...oferta, flTaxaMatricula: e.target.value })} >
                                <Radio value={false}>Não</Radio>
                                <Radio value={true}>Sim</Radio>
                            </Radio.Group>
                        </div>

                        <h3 id="taxas-h3">Preencha o valor do split da taxa de Matrícula.</h3>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                decimalSeparator=","
                                disabled={!oferta?.flTaxaMatricula ? true : false}
                                defaultValue={oferta?.flTaxaMatricula ? oferta?.valorSplitTaxa : 0}
                                value={oferta?.flTaxaMatricula ? oferta?.valorSplitTaxa : 0}
                                onChange={(e) => setOferta({ ...oferta, valorSplitTaxa: e })} />
                        </div>
                        <h3 id="taxas-h3">Preencha a prorrogação da primeira parcela da Matrícula.</h3>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={60}
                                disabled={!oferta?.valorSplitTaxa ? true : false}
                                defaultValue={oferta?.valorSplitTaxa ? oferta?.prorrogacaoDiasMatricula : 0}
                                value={oferta?.valorSplitTaxa ? oferta?.prorrogacaoDiasMatricula : 0}
                                onChange={(e) => setOferta({ ...oferta, prorrogacaoDiasMatricula: e })} />
                        </div>
                    </div>
                </div>
            </div>
            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
            <div className="botao-voltar-revisao">
                <Button id="voltar" className="button-second" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
                    Voltar
                </Button>
            </div>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a edição da oferta?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/cursos'
            />
        </div>
    )

}