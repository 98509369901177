import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ITaxaCobrancaCustomDTO } from '../../models/happy-code-api.model';
import { formatMoney } from '../../util/format';
import { ModalFinanceiroStep2Sede } from './step-2';
import './style.css';
moment.locale('pt-BR');

interface Text {
  open: boolean;
  onClose: () => void;
  taxaCobranca: ITaxaCobrancaCustomDTO;
  taxaCobrancaList: ITaxaCobrancaCustomDTO[];
}

interface Parcela {
  numeroParcela: number;
  valor: string;
  floatValue: number;
}

export const ModalFinanceiroStep1Sede: React.FunctionComponent<Text> = ({ open, taxaCobranca, taxaCobrancaList, onClose }: Text) => {
  const [parcelasList, setParcelasList] = useState<Parcela[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [desconto, setDesconto] = useState<number>(0);
  const [multa, setMulta] = useState<string | number>(2);
  const [juros, setJuros] = useState<string | number>(0.033);
  const [parcelaSelected, setParcelaSelected] = useState<Parcela>(null);
  const [dtVencimento, setDtVencimento] = useState<moment.Moment>(moment());

  const getTotal = () => {
    return taxaCobrancaList.map(item => item.valorCobranca).reduce((prev, next) => prev + next, 0);
  }

  const getTotalDesconto = () => {
    return getTotal() * (100 - desconto) / 100;
  }

  const handleParcelas = () => {
    const parcelasOption = [];
    for (let index = 1; index < 25; index++) {
      parcelasOption.push({ numeroParcela: index, valor: (getTotalDesconto() / index).toLocaleString('pt-br', { minimumFractionDigits: 2 }), floatValue: (getTotalDesconto() / index).toFixed(2) });
    }
    setParcelasList(parcelasOption);
  }


  useEffect(() => {
    if (taxaCobrancaList) {
      handleParcelas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxaCobrancaList, desconto]);

  const onChangeParcela = (numeroParcela) => {
    const parcela = parcelasList.find((parcela) => parcela.numeroParcela === numeroParcela);
    setParcelaSelected(parcela);
  }

  if (!open) return null

  return (
    <div className="modalContainerFinanceiro">
      <div className="modal-user-financeiro">
        <div className="body">
          <Button onClick={onClose} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
          <Button onClick={onClose} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
          <h3>Renegociação</h3>
          <h5>Responsável Financeiro</h5>
          <h6>{taxaCobranca?.unidade?.gestorNome}</h6>
          <h5>Unidade</h5>
          <div className="detalhes-aluno">
            <h6>{taxaCobranca?.unidade?.descricao}</h6>
            {/* <h6 id="link">Ver detalhes da unidade <Unicons.UilArrowRight size="14" color="var(--primary-color)" /></h6> */}
          </div>
          <div className="line"></div>
          <div className="box-tabela">
            <h5>Resumo renegociação</h5>
            <div className="valor">
              <h6>Valor total</h6>
              <p>{formatMoney(getTotal())}</p>
            </div>
            <div className="valor">
              <h6>Desconto (%)</h6>
              <InputNumber
                style={{ width: '15%' }}
                //formatter={value => `${value}%`}
                //parser={value => parseFloat(value.replace('%', ''))}
                value={desconto}
                min={0}
                max={100}
                onChange={e => setDesconto(e)}
                stringMode
                decimalSeparator=','
              />
            </div>
            <div className="valor">
              <h6>Multa (%)</h6>
              <InputNumber
                style={{ width: '15%' }}
                //formatter={value => `${value}%`}
                //parser={value => parseFloat(value.replace('%', ''))}
                value={multa}
                min={0}
                max={2}
                onChange={setMulta}
                stringMode
                decimalSeparator=','
              />
            </div>
            <div className="valor">
              <h6>Juros (%)</h6>
              <InputNumber
                style={{ width: '15%' }}
                //formatter={value => `${value}%`}
                //parser={value => parseFloat(value.replace('%', ''))}
                value={juros}
                min={0}
                max={0.033}
                onChange={setJuros}
                stringMode
                decimalSeparator=','
              />
            </div>
            <div className="valor">
              <h6>Quantidade de parcelas</h6>
              <Select
                style={{ width: '30%', textAlign: 'right' }}
                placeholder={'Selecione uma parcela'}
                onChange={onChangeParcela}
                getPopupContainer={triggerNode => {
                  return triggerNode.parentElement
                }}
              >
                {parcelasList && parcelasList.map((parcela, index) => (
                  <Select.Option key={index} value={parcela.numeroParcela}> {parcela.numeroParcela}x {parcela.valor} </Select.Option>
                ))}
              </Select>
            </div>
            <div className="valor">
              <h6>Data Vencimento</h6>
              <DatePicker
                id="vencimento"
                style={{ width: '30%' }}
                format={'DD-MM-YYYY'}
                onChange={e => setDtVencimento(e)}
                defaultValue={moment(dtVencimento)}
                getPopupContainer={triggerNode => {
                  return triggerNode.parentElement
                }} />
            </div>
            <div className="line"></div>
            <div className="valor">
              <h6>Valor total</h6>
              <p>{formatMoney(getTotalDesconto())}</p>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="botoes">
          <Button onClick={onClose} style={{ minWidth: 145 }} className="button-second">Cancelar</Button>
          <Button onClick={() => setIsOpen(true)} className="button-primary" style={{ marginLeft: 8, minWidth: 145 }}>Proximo</Button>
        </div>
      </div>
      <ModalFinanceiroStep2Sede
        open={isOpen}
        valorTotal={getTotalDesconto()}
        parcela={parcelaSelected}
        desconto={desconto}
        juros={juros}
        multa={multa}
        dtVencimento={dtVencimento}
        taxaCobrancaList={taxaCobrancaList}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}