import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import { INegocioCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNegocioService } from '../../services/negocio.service';

interface TFiltrosProdutoEscola {
    predicate: Predicate;
    findProdutoEscola: () => void;
}

const FiltrosProdutoEscola = memo(({ predicate, findProdutoEscola }: TFiltrosProdutoEscola) => {
    const [descricao, setDescricao] = useState<string>();
    const [status, setStatus] = useState<string>('Todos');
    const [negocioId, setNegocioId] = useState<number>();
    const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);

    const serviceNegocio = useNegocioService();

    const selectNegocios = negocios.map((negocio) => (<Select.Option key={negocio.id} value={negocio.id}>{negocio.descricao}</Select.Option>))

    useEffect(() => {
        serviceNegocio.findCleanList(new Predicate()).then(({ data }) => setNegocios(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            predicate.removeAllOption();

            if (descricao) {
                predicate.addOption('descricao', descricao, Operators.CONTAINS);
            }
            if (status != 'Todos') {
                predicate.addOption('ativo', status)
            }
            if (negocioId) {
                predicate.addOption('negocioId', negocioId);
            }

            findProdutoEscola();
        }, 800);
        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descricao, status, negocioId]);

    return (<>
        <div className="filtros">
            <div className="input-text" style={{ width: '90%' }}>
                <Input id="buscar" value={descricao} placeholder="Buscar Produto Escola" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} onChange={e => setDescricao(e.target.value)} />
            </div>
            <div className="filtro-estado input-dashboard" >
                <span>Negócio</span>
                <Select className="ant-dropdown-link" defaultValue="0" style={{ width: '100%', minWidth: 120 }} onChange={e => setNegocioId(parseInt(e))}>
                    <Select.Option value="0">Todos</Select.Option>
                    {selectNegocios}
                </Select>
            </div>
            <div className="filtro-estado" id="input-dashboard">
                <span>Status</span>
                <Select className="ant-dropdown-link" defaultValue="Todos" style={{ width: 120 }} onChange={e => setStatus(e)}>
                    <Select.Option value="Todos">Todos</Select.Option>
                    <Select.Option value="true">Ativo</Select.Option>
                    <Select.Option value="false">Inativo</Select.Option>
                </Select>
            </div>
        </div>
    </>
    )
})

FiltrosProdutoEscola.displayName = 'FiltrosProdutoEscola'

export default FiltrosProdutoEscola;