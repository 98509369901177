import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import { Button } from 'antd';
import { AxiosResponse } from 'axios';
import { getMonth, getYear } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ExcelenciaImage from '../../assets/img/excelencia/icon_excelencia.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { IMensagemHomeCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useDmPilarService } from '../../services/dmpilar';
import { useMensagemHomeService } from '../../services/mensagem-home';
import { getMonthName } from '../../util/format';

const MensagensComponent = () => {
	const { setIsGlobalLoading, unidade, tipoUnidade } = useGlobalContext();
	const mensagemHomeService = useMensagemHomeService();
	const [mensagemHome, setMensagemHome] = useState<IMensagemHomeCleanDTO>();
	const [happyCoins, setHappyCoins] = useState<number>();

	const dataAnterior = new Date().setMonth(new Date().getMonth() - 1);
	const mesAnterior = (getMonth(new Date()) + 12) % 12;
	const anoMesAnterior = getYear(dataAnterior);

	const { findHappyCoins } = useDmPilarService();

	const buscarDadosIndicadores = useCallback(() => {
		findHappyCoins(unidade.id).then((response: AxiosResponse<number>) => {
			setHappyCoins(response?.data);
		});
	}, [unidade]);

	useEffect(() => {
		if (unidade.id) {
			buscarDadosIndicadores();
		}
	}, [unidade]);

	useEffect(() => {
		setIsGlobalLoading(true);
		mensagemHomeService
			.findList(new Predicate())
			.then(({ data }) => {
				data[0] && setMensagemHome(data[0]);
			})
			.finally(() => setIsGlobalLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = 2;

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};
	

	return (
		<Box sx={{ flexGrow: 1, width: '35%', backgroundColor: '#0c2756', borderRadius: 8 }}>
			<AutoPlaySwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
				{/* {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))} */}

				{tipoUnidade?.id === 1 && (
					<div
						className="jornada"
						style={{
							width: '100%',
							backgroundColor: '#0c2756',
							flexDirection: 'row',
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<div>
							<img src={ExcelenciaImage} height={100} width={100} alt="Icon" />
						</div>

						<div style={{ marginLeft: 10, display: 'flex', flex: 1, flexDirection: 'column' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-around',
									width: '100%',
								}}
							>
								<div style={{ display: 'flex', flex: 1 }}>
									<h6 style={{ color: 'white', fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}>HAPPY POINTS: </h6>
								</div>
								<div style={{ display: 'flex', fontWeight: 'bold', fontSize: 30 }}>
									<h1 style={{ color: 'white' }}>{happyCoins ? happyCoins.toFixed(2) : 0.0}</h1>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-around',
									width: '100%',
								}}
							>
								<div style={{ display: 'flex', flex: 1 }}>
									<h6 style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>ACUMULADO ATÉ: </h6>
								</div>
								<div style={{ display: 'flex', fontWeight: 'bold', fontSize: 16 }}>
									<h1 style={{ color: 'white' }}>
										{getMonthName(mesAnterior)?.toUpperCase?.()}/{anoMesAnterior}
									</h1>
								</div>
							</div>

							<Link to={'/excelencia'}>
								<Button style={{ backgroundColor: '#fbaf1f', marginTop: 10 }}>Confira Seu Extrato</Button>
							</Link>
						</div>
					</div>
				)}

				<div className="jornada" style={{ width: '100%', backgroundColor: '#0c2756' }}>
					<h6>Atenção!</h6>
					<p style={{ fontSize: '0.7rem', textAlign: 'justify' }}>{mensagemHome?.mensagem}</p>
				</div>
			</AutoPlaySwipeableViews>
			<MobileStepper
				steps={maxSteps}
				position="static"
				style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
				activeStep={activeStep}
				nextButton={<div></div>}
				backButton={<div></div>}
			/>
		</Box>
	);

	// return <div
	//   className="jornada"
	//   style={{ width: '35%', backgroundColor: '#1057A0' }}
	// >
	//   <h6>Atenção!</h6>
	//   {/*<h6>Novos Alunos Chegando</h6>*/}
	//   <p style={{ fontSize: '0.7rem', textAlign: 'justify' }}>
	//     {mensagemHome?.mensagem}
	//   </p>
	// </div>
};

export default MensagensComponent;
