import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, DatePicker, Input, InputNumber, Progress, Select, Steps } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { horarioDaGrade } from '../../components/Calendar/funcoes';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import ModalCreate from '../../components/ModalCreate';
import { WEEK_DAYS_ACRONYMS } from '../../constants/week-days-acronyms.constant';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Predicate } from '../../models/predicate.model';
import { useCurriculoService } from '../../services/curriculo.service';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { useNegocioService } from '../../services/negocio.service';
import { useSalaService } from '../../services/sala.service';
import { useTurmaService } from '../../services/turma.service';
import { useUnidadePessoaService } from '../../services/unidade-pessoa.service';
import { formatHora } from '../../util/format';
import {
	ICurriculoCleanDTO,
	IDiaSemana,
	IHorarioCleanDTO,
	INegocioDTO,
	INegocioUnidadeDTO,
	ISalaCleanDTO,
	ITurmaFullDTO,
	IUnidadePessoaCleanDTO,
} from './../../models/happy-code-api.model';
import './style.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const TurmasCreateStep1: React.FunctionComponent = () => {
	const { setIsGlobalLoading, unidade, tipoUnidade } = useGlobalContext();
	const { turma, setTurma, removeTurma, editBtnIsVisible } = useTurmaContext();
	const { Step } = Steps;
	const [isOpen, setIsOpen] = useState(false);
	const [listProfessor, setListProfessor] = useState<IUnidadePessoaCleanDTO[]>([]);
	const [listNegocios, setListNegocios] = useState<INegocioUnidadeDTO[]>();
	const [listCurriculos, setListCurriculos] = useState<ICurriculoCleanDTO[]>();
	const [listSalas, setListSalas] = useState<ISalaCleanDTO[]>();
	const [novaHora, setNovaHora] = useState<string>();
	const [dia, setDia] = useState<IDiaSemana>();
	const [modalSucesso, setModalSucesso] = useState<boolean>(false);
	const negocioUnidadeService = useNegocioUnidadeService();
	const negocioService = useNegocioService();
	const curriculoService = useCurriculoService();
	const salaService = useSalaService();
	const unidadePessoaService = useUnidadePessoaService();
	const turmaService = useTurmaService();
	const [adicionarHorario, setAdicionarHorario] = useState<boolean>(false);
	const [negocioSelected, setNegocioSelected] = useState<INegocioDTO>();

	const findNegocios = () => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		negocioUnidadeService
			.findList(predicate)
			.then((response) => setListNegocios(response.data))
			.finally(() => setIsGlobalLoading(false));
	};

	const findNegocio = () => {
		setIsGlobalLoading(true);
		if (turma?.curriculoNegocioId) {
			negocioService
				.findById({ id: turma?.curriculoNegocioId })
				.then((response) => {
					setNegocioSelected(response.data);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const findCurriculos = () => {
		if (turma?.curriculoNegocioId) {
			setIsGlobalLoading(true);
			const predicate = new Predicate();
			predicate.addOption('ativo', true);
			predicate.addOption('negocio.id', turma?.curriculoNegocioId);
			curriculoService
				.findCleanList(predicate)
				.then((response) => setListCurriculos(response.data))
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const findProfessor = () => {
		const professorPredicate = new Predicate();
		professorPredicate.addOption('tipoUnidadePessoa.id', 3);
		professorPredicate.addOption('unidade.id', unidade.id);
		professorPredicate.addOption('pessoa.ativo', true);
		return unidadePessoaService.findCleanList(professorPredicate).then((response) => {
			setListProfessor(response.data);
		});
	};

	const findSalas = () => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		salaService
			.findCleanList(predicate)
			.then((response) => setListSalas(response.data))
			.finally(() => setIsGlobalLoading(false));
	};

	const getPeriodo = (): any => {
		if (turma.dataInicio && turma.dataFim) {
			return [moment(turma.dataInicio, 'YYYY-MM-DD'), moment(turma.dataFim, 'YYYY-MM-DD')];
		}
		return null;
	};

	const criarGradeAPartirDaPrimeiraHoraDaFranquia = () => {
		if (!unidade?.horaAulaInicio || !unidade?.horaFechamento) return [];

		const primeiroHorario = formatHora(unidade?.horaAulaInicio);
		const horaFechamentoHub = formatHora(unidade?.horaFechamento);

		return horarioDaGrade(primeiroHorario, horaFechamentoHub, tipoUnidade?.id === 1);
	};

	const onConfirmarHorario = (novaHora: string, dia: IDiaSemana) => {
		const horaSplitada = novaHora?.split('-');

		const novoHorario: IHorarioCleanDTO = {
			id: null,
			horaInicio: horaSplitada[0],
			horaFim: horaSplitada[1],
			codigo: dia,
			descricao: null,
			diaSemana: dia,
			descricaoHorario: null,
			ativo: true,
		};

		setTurma((prev) => ({
			...prev,
			horarios: [...prev.horarios, novoHorario],
		}));

		setAdicionarHorario(false);
	};

	const updateTurma = (payload: ITurmaFullDTO) => {
		setIsGlobalLoading(true);
		payload.unidade.id = unidade.id;
		turmaService
			.patchFull(payload)
			.then(() => {
				setModalSucesso(true);
				removeTurma();
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const saveTurma = (payload: ITurmaFullDTO) => {
		setIsGlobalLoading(true);
		payload.unidade.id = unidade.id;
		turmaService
			.createFull(payload)
			.then(() => {
				setModalSucesso(true);
				removeTurma();
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (unidade?.id) {
			findCurriculos();
			findNegocios();
			findSalas();
			findProfessor();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id]);

	useEffect(() => {
		findCurriculos();
		findNegocio();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [turma?.curriculoNegocioId]);

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress className="progress-user" percent={70} strokeLinecap="square" showInfo={false} />
			</div>
			<div className="menu-user">
				<h3>Criação de turma</h3>
				<Steps direction="vertical" current={0}>
					<Step title="Dados Básicos" />
					<Step title="Revisão" />
				</Steps>
			</div>
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<h3>Dados básicos de turma</h3>
					<div className="input-box">
						<p>
							Nome da Turma <span id="ob">*</span>
						</p>
						<Input
							placeholder="Nome turma"
							value={turma?.codigo}
							onChange={(e) =>
								setTurma({
									...turma,
									codigo: e.target.value,
									descricao: e.target.value,
								})
							}
						/>
					</div>
					<div className="input-box">
						<p>
							Máximo de alunos na turma<span id="ob">*</span>
						</p>
						<InputNumber
							className="w-100"
							min={0}
							defaultValue={turma.maxAlunos}
							value={turma?.maxAlunos}
							onChange={(e) => setTurma({ ...turma, maxAlunos: e })}
							placeholder="Máximo de alunos na turma"
						/>
					</div>

					<div className="input-box" id="box-aluno">
						<p>
							Produto<span id="ob">*</span>
						</p>
						<Select
							className="w-100"
							placeholder="Selecione o produto"
							allowClear
							showSearch
							optionFilterProp="children"
							defaultValue={turma?.curriculoNegocioId}
							value={turma?.curriculoNegocioId}
							onChange={(e) => setTurma({...turma, curriculoNegocioId: e })}
							filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
						>
							{listNegocios?.map((negocioUnidade: INegocioUnidadeDTO) => (
								<Option key={negocioUnidade.negocio.id} value={negocioUnidade.negocio.id} text={negocioUnidade.negocio.descricao}>
									{negocioUnidade.negocio.descricao}
								</Option>
							))}
						</Select>
					</div>

					<div className="input-box" id="box-aluno">
						<p>
							Curriculo<span id="ob">*</span>
						</p>
						<Select
							className="w-100"
							placeholder="Selecione um curriculo"
							allowClear
							showSearch
							optionFilterProp="children"
							defaultValue={turma.curriculo?.id}
							value={turma.curriculo?.id}
							onChange={(e) => setTurma({ ...turma, curriculo: { ...turma.curriculo, id: e } })}
							filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
						>
							{listCurriculos &&
								listCurriculos?.map((curriculo: ICurriculoCleanDTO) => (
									<Option key={curriculo?.id} value={curriculo?.id} text={curriculo?.descricaoCurriculo}>
										{curriculo?.descricaoCurriculo}
									</Option>
								))}
						</Select>
					</div>

					<div className="input-box" id="box-aluno">
						<p>
							Período<span id="ob">*</span>
						</p>
						<RangePicker
							className="w-100"
							value={getPeriodo()}
							onChange={(values) => {
								if (values) {
									setTurma({
										...turma,
										dataInicio: values[0].toDate(),
										dataFim: values[1].toDate(),
									});
								}
							}}
							format={'DD-MM-YYYY'}
						/>
					</div>

					<div className="inputs-box">
						<div className="input-box" id="box-aluno">
							<p>
								Sala<span id="ob">*</span>
							</p>
							<Select
								className="w-100"
								placeholder="Selecione uma sala"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={turma?.sala?.id}
								value={turma?.sala?.id}
								onChange={(e) => setTurma({ ...turma, sala: { ...turma.sala, id: e } })}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{listSalas &&
									listSalas?.map((sala: ISalaCleanDTO) => (
										<Option key={sala.id} value={sala.id} text={sala.descricaoSala}>
											{sala.descricaoSala}
										</Option>
									))}
							</Select>
						</div>
						<div className="input-box" id="box-aluno">
							<p>
								Instrutor<span id="ob">*</span>
							</p>
							<Select
								className="w-100"
								placeholder="Selecione uma sala"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={turma?.unidadePessoa?.id}
								value={turma?.unidadePessoa?.id}
								onChange={(e) =>
									setTurma({
										...turma,
										unidadePessoa: { ...turma.unidadePessoa, id: e },
									})
								}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{listProfessor &&
									listProfessor?.map((prof: IUnidadePessoaCleanDTO) => (
										<Option key={prof.id} value={prof.id} text={prof.pessoaNome}>
											{prof.pessoaNome}
										</Option>
									))}
							</Select>
						</div>
					</div>

					<div className="input-box">
						<p>
							Horário<span id="ob">*</span>
						</p>
						{turma.horarios && turma.horarios.map((hora, index) => (
							<p key={index}>{hora.codigo + ' - ' + hora.horaInicio + ' às ' + hora.horaFim}</p>
						))}
						{adicionarHorario === true ? (
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<Select onChange={(e) => setDia(e as IDiaSemana)} placeholder="Selecione o dia">
									{WEEK_DAYS_ACRONYMS.map((dia) => (
										<Select.Option key={dia} value={dia}>
											{dia}
										</Select.Option>
									))}
								</Select>

								<Select onChange={(e) => setNovaHora(e.toString())} placeholder="Selecione a hora">
									{criarGradeAPartirDaPrimeiraHoraDaFranquia()
										?.filter((g) => g.horaInicial !== null)
										.map((h, index) => (
											<Select.Option key={index} value={h.horaInicial + '-' + h.horaFinal}>
												{h.horaInicial + ' às ' + h.horaFinal}
											</Select.Option>
										))}
								</Select>
								<Button onClick={() => onConfirmarHorario(novaHora, dia)}>Confirmar</Button>
							</div>
						) : (
							<></>
						)}
						{(negocioSelected?.codigo === 'HEN' || negocioSelected?.codigo === 'CBO') && turma.horarios.length < 2 ? (
							<div onClick={() => setAdicionarHorario(true)}>
								<Button style={{ display: 'flex', flexDirection: 'row' }}>
									<Unicons.UilPlusCircle size="20" color="#1A4DA1" />
									{'Adicionar horário'}
								</Button>
							</div>
						) : (
							<></>
						)}
						{negocioSelected?.codigo === 'CUR' && turma.horarios.length < 1 ? (
							<div onClick={() => setAdicionarHorario(true)}>
								<Button style={{ display: 'flex', flexDirection: 'row' }}>
									<Unicons.UilPlusCircle size="20" color="#1A4DA1" />
									{'Adicionar horário'}
								</Button>
							</div>
						) : (
							<></>
						)}
						{negocioSelected?.codigo === 'COL' ? (
							<div onClick={() => setAdicionarHorario(true)}>
								<Button style={{ display: 'flex', flexDirection: 'row' }}>
									<Unicons.UilPlusCircle size="20" color="#1A4DA1" />
									{'Adicionar horário'}
								</Button>
							</div>
						) : (
							<></>
						)}
					</div>

					<div className="botoes">
						<Link to="/turmas">
							<Button className="button-second" onClick={() => editBtnIsVisible(false)} style={{ width: 345 }}>
								Cancelar
							</Button>
						</Link>
						<div>
							<Link to="/turmas/create/step-2">
								<Button className="button-primary" style={{ width: 345 }}>
									Proximo
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição desta Nova turma?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/turmas"
			/>
			<ModalCreate
				open={modalSucesso}
				title="Turma criada com sucesso"
				textButton1="Voltar"
				textButton2="Voltar Inicio"
				styleButton1="modal-none"
				styleButton2="button-primary"
				link2="/turmas"
			/>
		</div>
	);
};
