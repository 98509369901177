import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
  ICartaoDTO,
  ICobrancaPagarMeDTO,
  IFinanceiroEscolaCleanDTO,
  IFinanceiroEscolaComFocalDTO,
  IFinanceiroEscolaCustomDTO,
  IFinanceiroEscolaDTO,
  IFinanceiroEscolaFullDTO,
  IFinanceiroEscolaKPIsDTO,
  INegociacaoDTO,
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { IAxiosResponseCustom, PageableResponse } from '../models/response.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useFinanceiroEscolaService = () => {
  const resourceURL = 'financeiroescola';

  const renegociacao = (body: INegociacaoDTO) =>
    http.post(`${resourceURL}/renegociacao`, body);

  const saldoAPagarResponse = (
    idFinanceiro: number
  ): Promise<AxiosResponse<number>> =>
    http.get<number>(resourceURL + `/saldo-a-pagar/${idFinanceiro}`);
  
  const findComFocal = (predicate: Predicate = new Predicate(), idUnidade?: number):
    Promise<AxiosResponse<PageableResponse<IFinanceiroEscolaComFocalDTO>>> =>
    http.get(`${resourceURL}/comfocal` + `${idUnidade ? '?idUnidade=' + idUnidade : ''}`, formatHttpParams(predicate));

  const findKpi = (predicate: Predicate = new Predicate(), idUnidade?: number):
    Promise<AxiosResponse<PageableResponse<IFinanceiroEscolaKPIsDTO>>> =>
    http.get(`${resourceURL}/kpi` + `${idUnidade ? '?idUnidade=' + idUnidade : ''}`, formatHttpParams(predicate));

  const pagarme = (
    cobrancaPagarMeDTO: ICobrancaPagarMeDTO,
    token: string
  ): Promise<AxiosResponse<ICobrancaPagarMeDTO>> => {
    return http.post(`${resourceURL}/pagarme`, cobrancaPagarMeDTO, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const findStatus = (financeiroId: number, token: string): Promise<AxiosResponse<string>> => {

    return http.get(
      `${resourceURL}/${financeiroId}/status`, { headers: { Authorization: `Bearer ${token}` } });

  };

  const findCobrancaPagarme = (
    pedido: string,
    isencaoAcrescimos: string,
    token: string
  ): Promise<AxiosResponse<ICobrancaPagarMeDTO>> => {

    return http.get(
      `${resourceURL}/checkout?pedido=${pedido}&isencao=${isencaoAcrescimos}`,
      { headers: { Authorization: `Bearer ${token}` } });

  };

  const editarCartaoAssinatura = (idFinanceiro: number, cartaoDTO: ICartaoDTO
  ): Promise<IAxiosResponseCustom<any>> =>
    http.post(`${resourceURL}/editar-cartao-assinatura/${idFinanceiro}`, cartaoDTO);

  const baixaManual = (body: any, id: number) => {
    const formData = new FormData();
      formData.append('file', body)
		return http.post(`${resourceURL}/baixa-manual?id=${id}`, formData);
  }

  const baixaManualObservacao = (observacao: IFinanceiroEscolaCustomDTO, id: number) => {    
		return http.patch(`${resourceURL}/baixa-manual/${id}`, observacao);
  }

  const findRotinaEnvioEmailCobrancaB2b = (
    sendNow: boolean
  ): Promise<AxiosResponse<void>> =>
    http.get<void>(resourceURL + `/enviar-cobrancas/${sendNow}`);

  const findEnvioEmailCobrancaB2b = (
    sendNow: boolean,
    idFinanceiro: number,
  ): Promise<AxiosResponse<void>> =>
    http.get<void>(resourceURL + `/enviar-cobrancas/${sendNow}/${idFinanceiro}`);

  return {
    ...baseFind<IFinanceiroEscolaCleanDTO, IFinanceiroEscolaDTO, IFinanceiroEscolaFullDTO>(
      resourceURL
    ),
    ...baseFindList<IFinanceiroEscolaCleanDTO, IFinanceiroEscolaDTO, IFinanceiroEscolaFullDTO>(
      resourceURL
    ),
    ...baseUpdate<IFinanceiroEscolaCleanDTO, IFinanceiroEscolaDTO, IFinanceiroEscolaFullDTO>(
      resourceURL
    ),
    renegociacao,
    saldoAPagarResponse,    
    findComFocal,
    findKpi,
    pagarme,
    findStatus,
    findCobrancaPagarme,
    editarCartaoAssinatura,
    baixaManual,
    baixaManualObservacao,
    findRotinaEnvioEmailCobrancaB2b,
    findEnvioEmailCobrancaB2b
  };
};
