import User from '../../assets/img/header/defaultUser.png';
import Bot from '../../assets/img/menu/logo.svg';
import { IMessagesDTO } from '../../models/happy-code-api.model';
import { Loading } from './loading';
import { Sender } from './models';
import { useGptHook } from './useGptHook';

interface PropsMessage {
	message: IMessagesDTO;
	messages: IMessagesDTO[];
	index: number;
	loading: boolean;
}

function Messages({ message, index, loading, messages }: PropsMessage) {
	return (
		<>
			<div key={index} className={message.sender === Sender.USER ? 'user-message' : 'api-message'}>
				<img src={message.sender == Sender.USER ? User : Bot} />
				<div dangerouslySetInnerHTML={{ __html: typeof message.text === 'string' ? message.text : '' }} />
			</div>
			{loading && index == messages.length - 1 && <Loading />}
		</>
	);
}

export default Messages;
