import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { getMonth, getYear } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ExcelenciaIamge from '../../assets/img/excelencia/icon_excelencia.png';
import TableImage from '../../assets/img/excelencia/table.svg';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { Predicate } from '../../models/predicate.model';
import { useRelNotaUnidadeTotalService } from '../../services/relnotaunidadetotal';
import { getMonthName } from '../../util/format';
import { AccordionExcelencia } from './accordion';
import useExcelenciaScreenState from './states/excelencia.screen.state';
import './style.css';
import { TabelaExcelencia } from './tabela';

export const Excelencia: React.FunctionComponent = () => {

    const { unidade } = useGlobalContext();
    const mesAnterior = (getMonth(new Date()) + 12) % 12;
    const mesAnteriorRank = new Date().getMonth();
    const dataAnterior = new Date().setMonth(new Date().getMonth() - 1);
    const anoMesAnterior = getYear(dataAnterior);

    const { happyCoins } = useExcelenciaScreenState();
    const relNotaUnidadetotalService = useRelNotaUnidadeTotalService();

    const [filtroMes, setFiltroMes] = useState<string>('');
    const [filtroAno, setFiltroAno] = useState<string>('');
    const [rankingFinal, setRankingFinal] = useState({
        rankGeral: 0,
        nomeRank: '',
        valorRank: 0
    });

    useEffect(() => {
        if (unidade.id) {

            const predicate = new Predicate();
            predicate.addOption('idUnidade', unidade.id);
            predicate.addOption('ano', anoMesAnterior);
            predicate.addOption('mes', mesAnteriorRank);
            relNotaUnidadetotalService.findCleanList(predicate).then((response) => {
                const rank = response?.data?.[0];
                if (rank) {
                    let nomeRank = 'METROPOLE';
                    let valorRank = rank.idRankMetropole;
                    if (rank.tpUnidade === 'DISTRITO') {
                        nomeRank = 'DISTRITO';
                        valorRank = rank.idRankDistrito;
                    }

                    setRankingFinal({
                        rankGeral: rank.idRankGeral,
                        nomeRank,
                        valorRank
                    });

                }
            })
        }
    }, [unidade, mesAnterior, anoMesAnterior]);

    return (
        <div className="container">
            <NewMenu selecionado={0} />
            <div className="dashboard" style={{ height: '100vh', overflowY: 'scroll' }}>
                <div className="d-flex-row-start-center gap-10">
                    <Link to='/franquia'>
                        <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
                    </Link>
                    <h1 className="exc-title">Excelência Happy</h1>
                </div>
                <div className="d-flex-row-between-center row-blue">
                    <div>
                        <h2>HUB {unidade?.descricao}</h2>
                        <h6>ATÉ: {getMonthName(mesAnterior)}/{anoMesAnterior}</h6>
                    </div>
                    <div className="d-flex-row-between-center">
                        <img src={ExcelenciaIamge} alt="Icon" />
                        <div style={{ marginLeft: 10 }}>
                            <h2>{(happyCoins || 0.00)?.toFixed(2)?.replaceAll('.', ',')}</h2>
                            <h6>HAPPYPOINTS</h6>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex-col-start-star gap-5 flex-1" style={{ width: 150 }}>
                            <h6>POSIÇÃO NO RANKING:</h6>
                            <div className="d-flex-row-between-center gap-5 wp100">
                                <div className="box-geral">
                                    <p>GERAL: <span>{rankingFinal?.rankGeral}</span></p>
                                </div>
                                {rankingFinal.nomeRank && rankingFinal?.valorRank?.toString?.()?.length > 0 && <div className="box-metro">
                                    <p>{rankingFinal.nomeRank}: <span>{rankingFinal.valorRank}</span></p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <AccordionExcelencia />
                <div className="d-flex-row-between-center border-title-excelencia">
                    <div className="d-flex-row-start-center gap-3">
                        <img src={TableImage} alt="Table Image" style={{ marginTop: 2 }} />
                        <h6 className="title-accordion">Extrato de Excelência</h6>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div className="filtro-estado" id="input-dashboard" style={{ width: '100%', }}>
                            <span>Ano Referência</span>
                            <Select style={{ width: 50 }}
                                onChange={(e: any) => {
                                    setFiltroAno(e);
                                }}>
                                <Select.Option value="">Todos</Select.Option>
                                <Select.Option value="2024">2024</Select.Option>
                                <Select.Option value="2023">2023</Select.Option>
                            </Select>
                        </div>

                        <div style={{ width: 20 }}></div>

                        <div className="filtro-estado" id="input-dashboard" style={{ width: '100%', }}>
                            <span>Mês Referência</span>
                            <Select style={{ width: 100 }}
                                onChange={(e: any) => {
                                    setFiltroMes(e);
                                }}>
                                <Select.Option value="">Todos</Select.Option>
                                <Select.Option value="1">Janeiro</Select.Option>
                                <Select.Option value="2">Fevereiro</Select.Option>
                                <Select.Option value="3">Março</Select.Option>
                                <Select.Option value="4">Abril</Select.Option>
                                <Select.Option value="5">Maio</Select.Option>
                                <Select.Option value="6">Junho</Select.Option>
                                <Select.Option value="7">Julho</Select.Option>
                                <Select.Option value="8">Agosto</Select.Option>
                                <Select.Option value="9">Setembro</Select.Option>
                                <Select.Option value="10">Outubro</Select.Option>
                                <Select.Option value="11">Novembro</Select.Option>
                                <Select.Option value="12">Dezembro</Select.Option>
                            </Select>
                        </div>

                    </div>
                </div>
                <TabelaExcelencia
                    filtroMes={filtroMes}
                    filtroAno={filtroAno}

                />
            </div>
        </div>
    )
}