import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Button } from "antd";
import { useGlobalContext } from '../../context/GlobalContext';
import { IUnidadeListLeadCustomDTO } from "../../models/happy-code-api.model";

interface Props {
    lead: IUnidadeListLeadCustomDTO;
}

const ListLead: React.FunctionComponent<Props> = ({ lead }: Props) => {
    const { token, unidade } = useGlobalContext();

    const acessoExternoCrm = () => {
        window.open(`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRM}?t=${token?.access_token}&u=${unidade?.id}&l=${lead?.leadId}`, '_blank');
    }

    return (
        <div className="registro-user" id="registro-financeiro" style={{ paddingLeft: 20 }}>
            <div className="user" id="cursos" style={{ width: '25%' }}>
                {/* <Avatar arquivo={colaborador.foto} size={30} /> */}
                <div className="nome">
                    <p>{lead.leadNome}</p>
                    <div className="estado-user">
                        <div className={lead.ativo ? "dot-verde" : "dot-vermelho"}></div>
                        <p className={lead.ativo ? 'ativo' : 'inativo'}>{lead.ativo ? 'Ativo' : 'Inativo'}</p>
                    </div>
                </div>
            </div>

            <div className="funcao-user" style={{ width: '25%' }}>
                <p>{`(${lead.leadDdd || 'N/A'}) ${lead.leadCelular || 'N/A'}`}</p>
            </div>
            <div className="funcao-user" style={{ width: '25%' }}>
                <p>{lead?.leadEstagio}</p>
            </div>
            <div className="funcao-user" style={{ width: '25%' }}>
                <Button className="button-primary" onClick={acessoExternoCrm} >Acessar Lead <ArrowCircleRightIcon style={{ marginLeft: 5 }} /></Button>
            </div>
        </div>
    );
}

export default ListLead;