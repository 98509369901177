import { useAlunoContext } from '../../context/AlunoContext';
import { getAge } from '../../util/util';
import { Avatar } from '../Avatar';
import './menu-detalhe-matricula.styles.css';

export const MenuDetalheMatricula: React.FunctionComponent = () => {
	const { aluno, detalheMatricula } = useAlunoContext();

	return (
		<div className="menu-box-revisao-float">
			<div className="bloco-1">
				<Avatar arquivo={aluno.pessoa?.foto} size={115} />
				<h1 id="title">{aluno.pessoa.nome || 'Alexandre Ramos Silva'}</h1>
				<div id="standy-campanha">
					<div
						id={
							detalheMatricula.statusMatricula.codigo !== 'CANCELADO'
								? 'dot-green-aluno'
								: 'dot-orange-campanhas'
						}
					></div>
					<span
						id={
							detalheMatricula.statusMatricula.codigo !== 'CANCELADO'
								? 'green'
								: 'orange'
						}
					>
						{detalheMatricula.statusMatricula.descricao}
					</span>
				</div>
				{/* <h6 style={{ marginBottom: 0 }}>Email Pessoal</h6> */} 
				{/* <span style={{ wordWrap: 'break-word' }}>{aluno.pessoa.email}</span> */}
				<h6 style={{ marginBottom: 0 }}>Idade</h6>
				<span>
					{aluno.pessoa.dataNascimento
						? getAge(aluno.pessoa.dataNascimento)
						: 'N/A'}
				</span>
				<h6 style={{ marginBottom: 0 }}>Observações</h6>
				<span>{aluno.observacao || 'N/A'}</span>
			</div>
		</div>
	);
};
