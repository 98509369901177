import * as Unicons from "@iconscout/react-unicons";
import { Button, DatePicker, Form, Input, Radio } from 'antd';
import { ILeadEscolaFullDTO } from "../../../../../models/happy-code-api.model";
import '../../../shared.scss';
import useNoteState from "./selectors/note.state";
import '../style.scss';

interface Props {
    open: boolean;
    onClose: () => void;
    lead: ILeadEscolaFullDTO;
}

export const ModalNote = (props: Props) => {
    const { open,
        onClose,
        value,
        onChange,
        setValue,
        onSalvar,
        form,
        clearFields,
        setValidationFields,
        validationFields,
    } = useNoteState(props);
    const { TextArea } = Input;

    if (!open) return null

    return (
        <>
            <div className="flex flex-row justify-center align-center modal-container">
                <div className="flex flex-column justify-start align-start modal">
                    <Button onClick={() => {
                        clearFields();
                        onClose();
                    }} className="button-x">x</Button>

                    <Form
                        style={{ maxWidth: '100%' }}
                        name="basic"
                        // initialValues={{ remember: true }}
                        form={form}
                    >
                        <h3 >Adicionar anotações</h3>
                        <h6 id="text-note">Preencha as informações para retorno antes de mover o card do Lead.</h6>

                        <div className="text-over-input">
                            <small>Anotações</small>
                        </div>
                        <div className="input-textarea mb-20">
                            <Form.Item name={'anotacao'} rules={[{ required: true, message: 'Informe a descrição' }]} >
                                <TextArea
                                    id="anotacao"
                                    bordered={false}
                                    cols={100}
                                    maxLength={2000}/>
                            </Form.Item>
                        </div>

                        <span className="mb-10">Tipo de contato</span>
                        <Form.Item name={'tipo'} rules={[{ required: true, message: 'Qual o tipo do contato?' }]}>
                            <Radio.Group
                                onChange={onChange}
                                value={value}>
                                <Radio value={'Whatsapp'} onClick={() => setValidationFields(false)}>
                                    <Unicons.UilWhatsapp size={14} color={setValue} />
                                    <span className={`ml-5`}>Whatsapp</span>
                                </Radio>
                                <Radio value={'Ligação'} onClick={() => setValidationFields(false)}>
                                    <Unicons.UilCalling size={14} color="var(--azul-happy)" />
                                    <span className={`ml-5`}>Ligação</span>
                                </Radio>
                                <Radio value={'Email'} onClick={() => setValidationFields(false)}>
                                    <Unicons.UilEnvelope size={14} color="var(--azul-happy)" />
                                    <span className={`ml-5`}>Email</span>
                                </Radio>
                                <Radio value={'Presencial'} onClick={() => setValidationFields(false)}>
                                    <Unicons.UilUsersAlt size={14} color="var(--azul-happy)" />
                                    <span className={`ml-5`}>Presencial</span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        <div className="text-over-input">
                            <small>Agendar retorno</small>
                        </div>
                        <div className="input-modal mb-20">
                            <Form.Item name={'dataRetorno'} style={{ width: '100%' }} rules={[{ required: false, message: 'Qual o próximo retorno?' }]}>
                                <DatePicker
                                    id="dataRetorno"
                                    placeholder="Data retorno"
                                    suffixIcon={null}
                                    format={'DD-MM-YYYY'}
                                    style={{ width: '100%', borderWidth: 1, }}
                                />
                            </Form.Item>
                            <Unicons.UilCalender color="var(--cinza-azulado-500" size={18} />
                        </div>
                        <div className="flex flex-row justify-end w-100">
                            <Button onClick={() => {
                                clearFields();
                                onClose();
                            }} className="button-blue mr-5">Cancelar</Button>
                            <Button
                                className="button-primary"
                                onClick={onSalvar}
                                disabled={validationFields}
                            >Salvar</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}