import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useTaxaFixaContext } from '../../context/TaxaContext';

interface Props {
    current: number;
}

function StepsCreate({ current }: Props): ReactElement {

    const { revisao } = useTaxaFixaContext();

    return (
        <div className="menu-user">
            <h3>Criação de Taxa</h3>
            <Steps direction="vertical" current={revisao ? 4 : current}>
                <Steps.Step title="Dados Básicos" />
                <Steps.Step title="Periodo de vigência" />
                <Steps.Step title="Preço" />
                <Steps.Step title="Seleção de franquias" />
                <Steps.Step title="Revisão" />
            </Steps>
        </div>
    )
}

export default StepsCreate
