import { Modal, Button } from 'antd';
import TabelaTaxaGateway from '../TabelaTaxaGateway';
import "./styles.css";

interface Props {
    visible: boolean;
    title: string;
    onClose: () => void;
}

const ModalTaxaGateway: React.FC<Props> = ({ visible, title, onClose }: Props) => {
    return (
        <Modal
        className="modal__pagarme"
            title={title}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="submit" className="buttom__pagarme" onClick={onClose}>
                    Fechar
                </Button>,
            ]}
        >
            <TabelaTaxaGateway />
        </Modal>
    );
};

export default ModalTaxaGateway;
