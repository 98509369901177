
import * as Unicons from '@iconscout/react-unicons';
import { Button, Input, Progress } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

import './style.css';

const RecuperacaoSenha: React.FunctionComponent = () => {

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={20} strokeLinecap="square" showInfo={false} />
      </div>
      <div className="franquia" id="aluno-detalhe-row">
        <div className="user-row">
          <Button className="button-second" icon={<Unicons.UilArrowLeft size="18" color="var(--primary-color)" />} >Voltar</Button>
          <div className="box-title-senha">
            <h3 id="title-senha">Recuperação de senha</h3>
          </div>
          {/* <h6 id="sub-title-senha">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
          <div className="input-box">
            {/* <p>Nome da Taxa</p> */}
            <Input placeholder="Email" id="taxa" />
          </div>
          <div className="botoes" id="botoes-senha">
            <Link to="/taxas/create/step-6">
              <Button type="primary" className="button-primary" style={{ marginRight: 10 }}>Cancelar</Button>
            </Link>
            <Link to="/taxas">
              <Button className="button-second" >Enviar</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )

}

export default RecuperacaoSenha;