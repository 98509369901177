import * as Unicons from '@iconscout/react-unicons';
import { Button, DatePicker, Form } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import '../style.css';
import StepsCreate from './steps-create';

interface TInputs {
	dataContrato: moment.Moment;
	dataAbertura: moment.Moment;
	dataRenovacaoContrato: moment.Moment;
	dataTerminoContrato: moment.Moment;
	dataInicioFerias: moment.Moment;
	dataTerminoFerias: moment.Moment;
	revisao: string;
}

export const FranquiaCreateStep3: React.FunctionComponent = () => {
	const { unidade, setUnidade, revisao } = useUnidadeContext();
	//const { setIsGlobalLoading } = useGlobalContext();

	const history = useHistory();
	const [form] = Form.useForm();

	useEffect(() => {
		const franquiaDataFromContext = unidade;
		if (franquiaDataFromContext) {
			const formatedFormMoment = {
				dataContrato: moment(franquiaDataFromContext.dataContrato),
				dataAbertura: moment(franquiaDataFromContext.dataAbertura),
				dataRenovacaoContrato: moment(franquiaDataFromContext.dataContrato).add(5, 'years'),
				dataTerminoContrato: moment(franquiaDataFromContext.dataTerminoContrato),
				dataInicioFerias: moment(franquiaDataFromContext.dataInicioFerias),
				dataTerminoFerias: moment(franquiaDataFromContext.dataTerminoFerias)
			}
			form.setFieldsValue(formatedFormMoment);
		}
	}, [form, unidade]);

	const handleUnidade = (franquiaCrud: TInputs) => {
		const formatedForm = {
			dataContrato: franquiaCrud.dataContrato.toDate(),
			dataAbertura: franquiaCrud.dataAbertura.toDate(),
			dataRenovacaoContrato: franquiaCrud.dataRenovacaoContrato.toDate(),
			dataTerminoContrato: franquiaCrud.dataTerminoContrato.toDate(),
			dataInicioFerias: franquiaCrud.dataInicioFerias.toDate(),
			dataTerminoFerias: franquiaCrud.dataTerminoFerias.toDate(),
		}
		const contextState = { ...unidade, ...formatedForm };
		setUnidade(contextState);
		if (franquiaCrud.revisao === "1") {
			history.push("/franquias/create/step-10");
		} else {
			history.push("/franquias/create/step-4");
		}
	};

	return (
		<div className="container">
			<Header />
			<Progress />
			<StepsCreate current={2} />
			<div className="franquia" style={{ top: 70 }}>
				<Form
					form={form}
					className="franquia-row"
					name="control-hooks"
					onFinish={handleUnidade}
				>
					<h5>Preencha as datas referente aos contratos</h5>
					<div className="input-franquia-data">
						<div className="box-data">
							<span>Assinatura</span>
							<Form.Item name="dataContrato" rules={[{ required: true, message: 'Insira a Data da Assinatura do Contrato' }]}>
								<DatePicker id="data-assinatura" format={'DD-MM-YYYY'} suffixIcon={null} />
							</Form.Item>
						</div>
						<Unicons.UilCalendarAlt size="20" color="#6A7C96" />
					</div>
					<div className="input-franquia-data">
						<div className="box-data">
							<span>Abertura</span>
							<Form.Item name="dataAbertura" rules={[{ required: true, message: 'Insira a Data da Abertura' }]}>
								<DatePicker id="data-abertura" format={'DD-MM-YYYY'} suffixIcon={null} />
							</Form.Item>
						</div>
						<Unicons.UilCalendarAlt size="20" color="#6A7C96" />
					</div>
					<div className="input-franquia-data">
						<div className="box-data">
							<span>Renovação</span>
							<Form.Item name="dataRenovacaoContrato" rules={[{ required: true, message: 'Insira a Data de Renovação' }]}>
								<DatePicker id="data-renovacao" format={'DD-MM-YYYY'} suffixIcon={null} />
							</Form.Item>
						</div>
						<Unicons.UilCalendarAlt size="20" color="#6A7C96" />
					</div>
					<div className="input-franquia-data">
						<div className="box-data">
							<span>Término</span>
							<Form.Item name="dataTerminoContrato" rules={[{ required: true, message: 'Insira a Data do Término do Contrato' }]}>
								<DatePicker id="data-termino" format={'DD-MM-YYYY'} suffixIcon={null} />
							</Form.Item>
						</div>
						<Unicons.UilCalendarAlt size="20" color="#6A7C96" />
					</div>
					<h5>Preencha as datas referente a férias</h5>
					<div className="input-franquia-data">
						<div className="box-data">
							<span>Inicio das férias</span>
							<Form.Item name="dataInicioFerias">
								<DatePicker id="data-assinatura" format={'DD-MM-YYYY'} suffixIcon={null} />
							</Form.Item>
						</div>
						<Unicons.UilCalendarAlt size="20" color="#6A7C96" />
					</div>
					<div className="input-franquia-data">
						<div className="box-data">
							<span>Termino das férias</span>
							<Form.Item name="dataTerminoFerias">
								<DatePicker id="data-assinatura" format={'DD-MM-YYYY'} suffixIcon={null} />
							</Form.Item>
						</div>
						<Unicons.UilCalendarAlt size="20" color="#6A7C96" />
					</div>
					<div className="botoes">
						{revisao ?
							<>
								<Form.Item name="revisao" initialValue="1" />
								<Button type="primary" htmlType="submit" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
							</>
							: <>
								<Link to="/franquias/create/step-2">
									<Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
								</Link>
								<button type="submit" id="proximo" className="button-primary" style={{ width: 345 }}><span>Próximo</span></button>
							</>
						}
					</div>
				</Form>
			</div>
			<Link to="/franquias">
				<div className="fechar" id="cancelar"><Unicons.UilTimes size="20" color="#353F49" /></div>
			</Link>
		</div>
	)
}