import {
	IModeloUnidadeCleanDTO,
	IModeloUnidadeDTO,
	IModeloUnidadeFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useModeloUnidadeService = () => {
	const resourceURL = 'modelo-unidade';

	return {
		...baseFind<
			IModeloUnidadeCleanDTO,
			IModeloUnidadeDTO,
			IModeloUnidadeFullDTO
		>(resourceURL),
		...baseFindList<
			IModeloUnidadeCleanDTO,
			IModeloUnidadeDTO,
			IModeloUnidadeFullDTO
		>(resourceURL),
	};
};
