import { CloseOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined } from '@mui/icons-material';
import { Button, InputNumber, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import info from '../../assets/img/financeiro-info/info.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import MsgInfoSistema from '../../components/MsgInfoSistema';
import { useGlobalContext } from '../../context/GlobalContext';

import { MenuDetalheNegocioOferta } from '../../components/MenuDetalheNegocioOferta';
import { INegocioUnidadeDTO } from '../../models/happy-code-api.model';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { currencyFormatter, currencyParser } from '../../util/format';

export const HoraUnidadeCreateStep2: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const negocioUnidadeService = useNegocioUnidadeService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [restartOferta, setRestartOferta] = useState<boolean>(false)
    const [oferta, setOferta] = useState<INegocioUnidadeDTO>();
    const { idNegocio } = useParams<any>();
    const parcelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const [hideDisplay, setHideDisplay] = useState(false);

    const findOferta = () => {
        setIsGlobalLoading(true);
        negocioUnidadeService.findById({ id: idNegocio })
            .then((response) => {
                setOferta(response.data);
            }).finally(() => setIsGlobalLoading(false));
    };

    useEffect(() => {
        findOferta();
    }, [])

    useEffect(() => {
        if (restartOferta) {
            setOferta({
                ...oferta,
                valorMinimoRematricula: oferta?.negocio?.valorMinimoRematricula,
                valorHoraRematricula: oferta?.negocio?.valorHoraRematricula,
                numeroParcelasMaximoRematricula: oferta?.negocio?.numeroParcelasMaximoRematricula,
                flTaxaRematricula: oferta?.negocio?.flTaxaRematricula,
                valorSplitTaxaRematricula: oferta?.negocio?.valorSplitTaxaRematricula,
                prorrogacaoDiasRematricula: oferta?.negocio?.prorrogacaoDiasRematricula
            })
            setRestartOferta(false);
        }
    }, [restartOferta])

    return (
			<div className="container-fondo">
				<Header />
				<MenuDetalheNegocioOferta
					setRestartOferta={setRestartOferta}
					oferta={oferta}
				/>
				<div className="franquia" style={{ marginTop: 60 }}>
					<div className="user-row">
						<div className="card-form">
							<h3 id="taxas-h3" style={{ fontWeight: 700 }}>
								Rematrícula
							</h3>
							<h3 id="taxas-h3">1 .Qual será o preço mínimo desse curso?</h3>
							<div className="input-box">
								<InputNumber
									id="valor"
									style={{ width: '100%' }}
									formatter={currencyFormatter}
									parser={currencyParser}
									min={0}
									defaultValue={oferta?.valorMinimoRematricula}
									value={oferta?.valorMinimoRematricula}
									onChange={(e) =>
										setOferta({ ...oferta, valorMinimoRematricula: e })
									}
								/>
							</div>
							<div style={{ display: 'flex' }}>
								<h3 id="taxas-h3">2. Preencha o valor do split.</h3>
								<img
									className="config-icons-financeiro"
									src={info}
									onClick={(e) => setHideDisplay(true)}
								/>
								{hideDisplay && (
									<MsgInfoSistema
										setHideDisplay={setHideDisplay}
										hideDisplay={false}
										icon={info}
										title={'Valor Split'}
										descricaoOne={
											'O valor inserido no campo valor do split não pode ser superior ao preço mínimo da rematrícula.'
										}
									/>
								)}
							</div>
							<div
								className="input-box"
								style={{ width: '100%', marginBottom: 10 }}
							>
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									max={oferta?.valorMinimoRematricula}
									formatter={currencyFormatter}
									parser={currencyParser}
									decimalSeparator=","
									defaultValue={oferta?.valorHoraRematricula}
									value={oferta?.valorHoraRematricula}
									onChange={(e) =>
										setOferta({ ...oferta, valorHoraRematricula: e })
									}
								/>
							</div>

							<h3 id="taxas-h3">3. Qual será o máximo de parcelas?</h3>
							<div className="input-box" style={{ width: '100%' }}>
								<Select
									id="valor"
									style={{
										background: 'white',
										width: '100%',
										marginBottom: 5,
									}}
									defaultValue={oferta?.numeroParcelasMaximoRematricula}
									value={oferta?.numeroParcelasMaximoRematricula}
									onChange={(e) =>
										setOferta({ ...oferta, numeroParcelasMaximoRematricula: e })
									}
								>
									{parcelas.map((parcela, index) => (
										<Select.Option key={index} value={parcela}>
											{parcela}
										</Select.Option>
									))}
								</Select>
							</div>

							<h3 id="taxas-h3">4. Deseja utilizar a taxa de Rematrícula?</h3>
							<div
								className="input-box"
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									width: 'max-content',
								}}
							>
								<span style={{ width: 200 }}>Habilitar taxa: </span>
								<Radio.Group
									style={{ display: 'flex', justifyContent: 'center' }}
									defaultValue={oferta?.flTaxaRematricula}
									value={oferta?.flTaxaRematricula}
									onChange={(e) =>
										setOferta({ ...oferta, flTaxaRematricula: e.target.value })
									}
								>
									<Radio value={false}>Não</Radio>
									<Radio value={true}>Sim</Radio>
								</Radio.Group>
							</div>

							<h3 id="taxas-h3">
								Preencha o valor do split da taxa de Rematrícula.
							</h3>
							<div className="input-box">
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									formatter={currencyFormatter}
									parser={currencyParser}
									decimalSeparator=","
									disabled={!oferta?.flTaxaRematricula ? true : false}
									defaultValue={
										oferta?.flTaxaRematricula
											? oferta?.valorSplitTaxaRematricula
											: 0
									}
									value={
										oferta?.flTaxaRematricula
											? oferta?.valorSplitTaxaRematricula
											: 0
									}
									onChange={(e) =>
										setOferta({ ...oferta, valorSplitTaxaRematricula: e })
									}
								/>
							</div>

							<h3 id="taxas-h3">
								Preencha a prorrogação da primeira parcela da Rematrícula.
							</h3>
							<div className="input-box">
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									max={60}
									disabled={!oferta?.flTaxaRematricula ? true : false}
									defaultValue={
										oferta?.flTaxaRematricula
											? oferta?.prorrogacaoDiasRematricula
											: 0
									}
									value={
										oferta?.flTaxaRematricula
											? oferta?.prorrogacaoDiasRematricula
											: 0
									}
									onChange={(e) =>
										setOferta({ ...oferta, prorrogacaoDiasRematricula: e })
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<Button
					onClick={() => setIsOpen(true)}
					id="cancelar"
					className="button-fechar"
					icon={<CloseOutlined rev={undefined} />}
				/>
				<div className="botao-voltar-revisao">
					<Button
						id="voltar"
						className="button-second"
						icon={<ArrowLeftOutlined />}
						onClick={() => history.goBack()}
					>
						Voltar
					</Button>
				</div>
				<Modal
					open={isOpen}
					onClose={() => setIsOpen(false)}
					title="Você deseja cancelar a edição da oferta?"
					subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
					textButton1="Não"
					textButton2="Sim, Cancelar"
					styleButton1="button-line"
					styleButton2="button-danger"
					link2="/cursos"
				/>
			</div>
		);

}