import { Pie } from '@ant-design/charts';


export const PieStemplay: React.FunctionComponent = () => {
  const data = [
    {
      type: 'fundamental I',
      value: 27,
    },
    {
      type: 'fundamental II',
      value: 25,
    },
    {
      type: 'ensino médio',
      value: 18,
    }
  ]

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <div className='pie-stemplay'>
      <Pie {...config} />
    </div>
  )
};

export default PieStemplay