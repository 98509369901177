/* eslint-disable */
import * as Unicons from '@iconscout/react-unicons';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { IArquivoCleanDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { token } from '../../util/store';
import './style.css';

interface Props {
    onUpload: (arquivos: UploadFile<IArquivoCleanDTO>) => void;
    arquivo?: IArquivoCleanDTO
}
export const AvatarUpload: React.FunctionComponent<Props> = ({ onUpload, arquivo }: Props) => {
    const notification = useNotificationService();
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (arquivo && arquivo.url) {
            const foto = [{
                uid: '-1',
                name: arquivo.codigo,
                url: `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${arquivo.url}?name=${arquivo.codigo}`,
            }]
            setFileList(foto);
        }
    }, [])

    const onChange = ({ file, fileList }: UploadChangeParam) => {
        const { status } = file;
        setFileList(fileList);

        if (status === 'done') {
            notification({ description: `${file.name} file uploaded successfully.`, type: 'success', message: 'Sucesso!' });
            onUpload(fileList[0]);
        }
        if (status === 'removed') {
            onUpload(fileList[0]);
        }
    }

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };


    return (
        <ImgCrop rotate>
            <Upload
                headers={{ "Authorization": `Bearer ${token()}` }}
                action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
            >
                {fileList.length < 1 ? (
                    <div className="avatar">
                        <div className="camera">
                            <Unicons.UilCamera size="20" color="#FFFFFF" />
                        </div>
                    </div>
                ) : null}

            </Upload>
        </ImgCrop>
    );
}


