import { useMemo, useState } from 'react';
import { ICobrancaPagarMeDTO } from '../../../models/happy-code-api.model';

const useCheckoutState = () => {
	const tiposPagamento = useMemo(
		() => ({
			CRC: {
				idPagamento: 'CRC',
				dsPagamento: 'cartão de crédito',
			},
			BOL: {
				idPagamento: 'BOL',
				dsPagamento: 'boleto',
			},
			CRR: {
				idPagamento: 'CRR',
				dsPagamento: 'crédito recorrente',
			},
			PIX: {
				idPagamento: 'PIX',
				dsPagamento: 'pix',
			},
			MQF:{
				id: 'MQF',
				dsPagamento: 'máquina fisica',
			}
			
		}),
		[]
	);

	const [cobranca, setCobranca] = useState<ICobrancaPagarMeDTO>();

	const isRecorrente = useMemo(
		() => cobranca?.tipoPagamento?.codigo === tiposPagamento.CRR.idPagamento,
		[cobranca?.tipoPagamento?.codigo, tiposPagamento]
	);

	const isCartaoCredito = useMemo(
		() => cobranca?.tipoPagamento?.codigo === tiposPagamento.CRC.idPagamento,
		[cobranca?.tipoPagamento?.codigo, tiposPagamento]
	);

	return {
		tiposPagamento,
		cobranca,
		setCobranca,
		isRecorrente,
		isCartaoCredito,
	};
};

export default useCheckoutState;
