import { Form } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IUnidadeDTO } from '../../../models/happy-code-api.model';
import { useNotificationService } from '../../../services/notification.service';
import { useUnidadeService } from '../../../services/unidade.service';

export const useUnidadeForm = (unidade?: IUnidadeDTO) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const unidadeService = useUnidadeService();
    const notification = useNotificationService();
    const history = useHistory();
    const [form] = Form.useForm();

    const unidadeUpdate = (franquiaCrud) => {
        setIsGlobalLoading(true);
        const contextState = {
            ...unidade,
            ...franquiaCrud,
            identificadorPagarMe: franquiaCrud.identificadorPagarMe?.trim(),
            identificadorPagarMeV4: franquiaCrud.identificadorPagarMeV4?.trim(),
            facebookId: franquiaCrud.facebookId?.trim(),
            identificadorDevice: franquiaCrud.identificadorDevice?.trim(),
            dsInstagram: franquiaCrud.dsInstagram?.trim(),
            dsLpHub: franquiaCrud.dsLpHub?.trim(),
            identificadorEnotas: franquiaCrud.identificadorEnotas?.trim(),
            email: franquiaCrud.email?.trim(),
            telefone: franquiaCrud.telefone?.trim(),
        };

        unidadeService.patchClean(contextState)
            .then(() => {
                notification({
                    description: 'Dados da unidade alterados!',
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).finally(() => {
                setIsGlobalLoading(false);
                history.push("/franquias");
            });
    };

    useEffect(() => {
        if (unidade) {
            form.setFieldsValue({
                id: unidade?.id,
                identificadorPagarMe: unidade?.identificadorPagarMe,
                identificadorPagarMeV4: unidade?.identificadorPagarMeV4,
                facebookId: unidade?.facebookId,
                identificadorDevice: unidade?.identificadorDevice,
                dsInstagram: unidade?.dsInstagram,
                dsLpHub: unidade?.dsLpHub,
                identificadorEnotas: unidade?.identificadorEnotas,
                email: unidade?.email,
                telefone: unidade?.telefone,
            });
        }
    }, [unidade, form]);

    return { form, unidadeUpdate };
};
