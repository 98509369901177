import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { IRequestBodyDTO, IMessagesDTO } from '../models/happy-code-api.model';

export const useHappyGptService = () => {
	const resourceURL = '/happy-gpt';

	const request = (body: IRequestBodyDTO): Promise<AxiosResponse<IRequestBodyDTO>> => {
		return http.post(`${resourceURL}/process-prompt`, body);
	};

	const getMessages = (body: IRequestBodyDTO): Promise<AxiosResponse<IMessagesDTO[]>> => {
		return http.post(`${resourceURL}/get-messages`, body);
	};

	const inactivateConversation = (body: IRequestBodyDTO): Promise<AxiosResponse<void>> => {
		return http.post(`${resourceURL}/inactivate-thread`, body);
	};

	return {
		request,
		getMessages,
		inactivateConversation,
	};
};
