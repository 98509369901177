import { Button } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
	ITaxaCobrancaCustomDTO,
	INatureza,
} from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { useTaxaCobrancaService } from '../../services/taxa-cobranca.service';
import { formatMoney } from '../../util/format';
import ModalCreate from '../ModalCreate';
import './style.css';
moment.locale('pt-BR');

interface Parcela {
	numeroParcela: number;
	valor: string;
	floatValue: number;
	dtVencimento?: moment.Moment;
}

interface Text {
	open: boolean;
	onClose: () => void;
	parcela: Parcela;
	desconto: string | number;
	juros: string | number;
	multa: string | number;
	valorTotal: number;
	dtVencimento: moment.Moment;
	taxaCobrancaList: ITaxaCobrancaCustomDTO[];
}

export const ModalFinanceiroStep2Sede: React.FunctionComponent<Text> = ({
	open,
	parcela,
	desconto,
	valorTotal,
	dtVencimento,
	taxaCobrancaList,
	onClose,
}: Text) => {
	const [parcelasList, setParcelasList] = useState<ITaxaCobrancaCustomDTO[]>(
		[]
	);

	const taxaCobrancaService = useTaxaCobrancaService();
	const notification = useNotificationService();

	const handleParcelas = () => {
		const parcelasOption: ITaxaCobrancaCustomDTO[] = [];
		for (let index = 0; index < parcela.numeroParcela; index++) {
			// parcelasOption.push({ parcela: index + 1, valor: parcela.floatValue, dtVencimento: moment(dtVencimento).add(index, 'M').format('DD/MM/YYYY') });
			const parcelaOpt: ITaxaCobrancaCustomDTO = {
				id: null,
				parcela: index + 1,
				valorCobranca: valorTotal / parcela.numeroParcela,
				competencia: moment(dtVencimento).add(index, 'M').format('MM/YYYY'),
				dataVencimento: moment(dtVencimento).add(index, 'M').toDate(),
				dataCancelamento: null,
				dataPagamento: null,
				quantidadeReemissao: 0,
				valorDesconto: 0,
				valorEncargos: 0,
				valorPago: 0,
				link: null,
				codigoPedido: null,
				codigoTransacao: null,
				retornoTransacao: null,
				natureza: 'DEBITO',
				taxaRenegociacaoOrigem: null,
				taxaRenegociacaoOriginada: null,
				statusCobranca:
					valorTotal / parcela.numeroParcela > 0
						? { id: 2, codigo: 'GRD', descricao: '', ativo: true }
						: { id: 6, codigo: 'IST', descricao: '', ativo: true },
				unidade: taxaCobrancaList[0].unidade,
				ativo: true,
				taxaFranquia: null,
				valorOriginal: null,
			};

			parcelasOption.push(parcelaOpt);
		}
		setParcelasList(parcelasOption);
	};

	useEffect(() => {
		if (parcela) {
			handleParcelas();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parcela, desconto, dtVencimento]);

	const [isOpen, setIsOpen] = useState(false);
	if (!open) return null;

	const save = () => {
		taxaCobrancaService
			.renegociacao([...taxaCobrancaList, ...parcelasList])
			.then(() => setIsOpen(true))
			.catch((error) =>
				notification({
					message: 'Erro!',
					description: error.response.data.message,
					type: 'error',
				})
			);
	};

	const close = (boo: boolean) => {
		setIsOpen(boo);
		if (!boo) onClose();
	};

	return (
		<div className="modalContainerFinanceiro">
			<div className="modal-user-financeiro">
				<div className="body">
					<h3 style={{ textAlign: 'center' }}>Confirmar Renegociação</h3>
					<h4 style={{ textAlign: 'center' }}>
						O processo de renegociação cancela as parcelas originas e gera essas
						novas parcelas
					</h4>
					<div className="box-tabela" style={{ marginTop: 20 }}>
						<div className="tabela">
							<div className="titles-users">
								<div className="item" style={{ width: '20%' }}>
									<span>PARCELA</span>
								</div>
								<div className="item" style={{ width: '20%' }}>
									<span>VENCIMENTO</span>
								</div>
								<div className="item" style={{ width: '15%' }}>
									<span>VALOR</span>
								</div>
								<div className="item" style={{ width: '45%' }}></div>
							</div>
							{parcelasList &&
								parcelasList.map((parcela, index) => (
									<div key={index} className="registro-user">
										<div className="funcao-user" style={{ width: '20%' }}>
											<p>{parcela.parcela}</p>
										</div>
										<div className="funcao-user" style={{ width: '20%' }}>
											<p>{format(parcela.dataVencimento, 'dd/MM/yyyy')}</p>
										</div>
										<div className="funcao-user" style={{ width: '15%' }}>
											<p>{formatMoney(parcela.valorCobranca)}</p>
										</div>
										<div className="funcao-user" style={{ width: '45%' }}></div>
									</div>
								))}
							<div className="valor">
								<h6>Valor do curso</h6>
								<p>{formatMoney(valorTotal)}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="line"></div>
				<div className="botoes">
					<Button
						onClick={onClose}
						style={{ minWidth: 145 }}
						className="button-second"
					>
						Cancelar
					</Button>
					<Button
						onClick={save}
						className="button-danger"
						style={{ marginLeft: 8, minWidth: 145 }}
					>
						Gerar Renegociação
					</Button>
				</div>
			</div>
			<ModalCreate
				open={isOpen}
				setOpen={close}
				title="Renegociação gerada"
				textButton1="Voltar"
				textButton2="Voltar Inicio"
				styleButton1="modal-none"
				styleButton2="button-primary"
				link2="/financeiro-sede"
			/>
		</div>
	);
};
