import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { TipoDocumentoEnum } from '../../../enum/tipoDocumento.enum';
import { IUnidadeDocumentoCleanDTO } from '../../../models/happy-code-api.model';
import '../style.css';
import { BoxDocumentos } from './../../../components/BoxDocumentos/index';
import StepsCreate from './steps-create';

export const FranquiaCreateStep7: React.FunctionComponent = () => {
    const { unidade, setUnidade, revisao } = useUnidadeContext();

    const atualizaDocumentos = (uploads: any[], tipoDocumento: TipoDocumentoEnum) => {

        const contratos = uploads.map((response) => {
            if (response?.response) {
                return ({
                    id: null,
                    arquivoId: response.response.id,
                    arquivoCodigo: response.response.codigo,
                    arquivoUrl: response.response.url,
                    tipoDocumentoId: tipoDocumento,
                    unidadeId: unidade.id,
                    ativo: true
                })
            }
            return ({
                id: null,
                arquivoId: response.arquivoId,
                arquivoCodigo: response.name,
                arquivoUrl: response.arquivoUrl,
                tipoDocumentoId: tipoDocumento,
                unidadeId: unidade.id,
                ativo: true
            })
        })

        const { unidadeDocumentos } = unidade;
        setUnidade({ ...unidade, unidadeDocumentos: [...unidadeDocumentos.filter(ud => ud.tipoDocumentoId !== tipoDocumento), ...contratos] })
    }

    const onRemoveFile = (file: any) => {
        const { unidadeDocumentos } = unidade;
        setUnidade({ ...unidade, unidadeDocumentos: [...unidadeDocumentos.filter(ud => ud.arquivoId !== file.arquivoId)] });
    }

    const formatDefaultList = (files: IUnidadeDocumentoCleanDTO[]) => {
        return files.map((file, index) => ({
            uid: index.toString(),
            arquivoId: file.arquivoId,
            name: file.arquivoCodigo,
            status: 'done',
            url: `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${file.arquivoUrl}?name=${file.arquivoCodigo}`,
            arquivoUrl: file.arquivoUrl,
        }));
    }

    const getDefaultFileList = (tipoDocumento: TipoDocumentoEnum) => {
        const files = unidade.unidadeDocumentos.filter((doc) => doc.tipoDocumentoId === tipoDocumento);
        if (files.length > 0) {
            return formatDefaultList(files);
        }
        return [];
    }

    const getQtdDocPendente = () => {
        return 8 - unidade.unidadeDocumentos.reduce((acc, curr) => {
            if (acc.some(u => u.tipoDocumentoId === curr.tipoDocumentoId)) return acc;
            else return [...acc, curr];
        }, []).length;
    }

    const getStatus = (tipoDocumento: TipoDocumentoEnum) => {
        const files = unidade.unidadeDocumentos.filter((doc) => doc.tipoDocumentoId === tipoDocumento);
        if (files.length > 0) {
            return (
                <div className="dot">
                    <div className="dot-verde"></div>
                    <p>Concluído</p>
                </div>
            )
        }
        return (
            <div className="dot">
                <div className="dot-orange"></div>
                <span>Pendente</span>
            </div>
        );
    }

    return (
        <div className="container">
            <Header />
            <Progress />
            <StepsCreate current={4} />
            <div className="franquia" style={{ top: 70 }}>
                <div className="franquia-row">
                    <h5>Adicione os documentos da unidade</h5>
                    <div className="row-clasificacao">
                        <div className="text-span">{getQtdDocPendente()} documento(s) pendente(s)</div>
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.CTT)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.CTT)} title={'Contrato'} subtitle={'Esse documento é Contrato.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CTT)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.CNDRFE)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.CNDRFE)} title={'Certidão Negativa de Débito da Receita Federal e Estadual'} subtitle={'Esse documento é Certidão Negativa de Débito da Receita Federal e Estadual.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CNDRFE)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.CNP)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.CNP)} title={'Certidão Negativa da Prefeitura'} subtitle={'Esse documento é Certidão Negativa da Prefeitura.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CNP)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.CDFCTCEF)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.CDFCTCEF)} title={'Certidão dos Distribuidores Fiscal, Civel, Trabalhista e Criminal nas esferas Estadual e Federal'} subtitle={'Esse documento é Certidão dos Distribuidores Fiscal, Civel, Trabalhista e Criminal nas esferas Estadual e Federal.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CDFCTCEF)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.CPT)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.CPT)} title={'Certidão de Protesto'} subtitle={'Esse documento é Certidão de Protesto.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CPT)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.ACR)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.ACR)} title={'Antecedentes criminais'} subtitle={'Esse documento é Antecedentes criminais.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.ACR)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos statusDocumento={getStatus(TipoDocumentoEnum.DIR)} defaultFileList={getDefaultFileList(TipoDocumentoEnum.DIR)} title={'Declaração de Imposto de Renda dos últimos 2 anos'} subtitle={'Esse documento é Declaração de Imposto de Renda dos últimos 2 anos.'} setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.DIR)} onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.LVB)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.LVB)}
                            title={'Laudo de vistoria dos bombeiros'} subtitle={'Esse documento é Laudo de vistoria dos bombeiros.'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.LVB)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.RCC)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.RCC)}
                            title={'RG, CPF ou CNH'} subtitle={'Este campo é para CPF, RG ou CNH.'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.RCC)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.CRE)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.CRE)}
                            title={'Comprovante de Residência'} subtitle={'Este document é Comprovante de Residência'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CRE)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.CDC)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.CDC)}
                            title={'Certidão de casamento ou averbação de divórcio'} subtitle={'Este documento é Certidão de casamento ou averbação de divórcio'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CDC)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.CRC)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.CRC)}
                            title={'Documento de identificação do cônjuge (RG e CPF ou CNH)'} subtitle={'Este documento é Documento de identificação do cônjuge (RG e CPF ou CNH)'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CRC)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.IRA)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.IRA)}
                            title={'Imposto de Renda do último ano'} subtitle={'Este documento é Imposto de Renda do último ano'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.IRA)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.CPJ)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.CPJ)}
                            title={'Cartão CNPJ'} subtitle={'Este documento é Cartão CNPJ'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.CPJ)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.ACS)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.ACS)}
                            title={'Última alteração do Contrato Social'} subtitle={'Este documento é Última alteração do Contrato Social'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.ACS)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.SPC)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.SPC)}
                            title={'Pesquisa SCPC/Serasa'} subtitle={'Este documento é Pesquisa SCPC/Serasa'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.SPC)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />
                        <BoxDocumentos
                            statusDocumento={getStatus(TipoDocumentoEnum.ADC)}
                            defaultFileList={getDefaultFileList(TipoDocumentoEnum.ADC)}
                            title={'Aditivo do contrato'} subtitle={'Este documento é Aditivo do contrato'}
                            setFileList={(fileList) => atualizaDocumentos(fileList, TipoDocumentoEnum.ADC)}
                            onRemoveFile={(fileList) => onRemoveFile(fileList)} />

                        <div className="botoes">
                            {revisao ?
                                <Link to="/franquias/create/step-10">
                                    <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
                                </Link>
                                : <>
                                    <Link to="/franquias/create/step-4">
                                        <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                                    </Link>
                                    <Link to="/franquias/create/step-9">
                                        <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Link to="/franquias">
                <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
            </Link>
        </div>
    )
}