import "./style.css";
interface Props {
    setHideDisplay: React.Dispatch<React.SetStateAction<boolean>>,
    hideDisplay: boolean,
    icon?: string;
    title?: string,
    descricaoOne?: string,
    descricaoTwo?: string,
    descricaoThree?: string,
    observacao?: string,
    listTitle?: string,
    listItem?: string[],
}

const MsgInfoSistema: React.FC<Props> = ({ setHideDisplay, hideDisplay, icon, title, descricaoOne, descricaoTwo, descricaoThree, observacao, listTitle, listItem }: Props) => {
    return (
        <div className="box-msg-servico"
            onMouseLeave={() => setHideDisplay(hideDisplay)}>
            <h1>
                {icon ?
                    <img className="config-icons-financeiro" src={icon} />
                    : null}
                {title}
            </h1>
            <br />
            {descricaoOne ?
                <>
                    <p>{descricaoOne}</p>
                    <br />
                </>
                : null}
            {descricaoTwo ?
                <>
                    <p>{descricaoTwo}</p>
                    <br />
                </>
                : null}
            {descricaoThree ?
                <>
                    <p>{descricaoThree}</p>
                    <br />
                </>
                : null}
            {observacao ?
                <>
                    <p>Obs: {observacao}</p>
                    <br />
                </>
                : null}
            <h2>{listTitle}</h2>
            <br />
            {listItem && listItem.map((item, index) =>
                <ol key={index}>
                    <li>{`${index + 1}. ${item}`}</li>
                </ol>
            )}
        </div>
    );
}

export default MsgInfoSistema;