import { Button } from 'antd';
import { Link } from 'react-router-dom';
import imageModal from '../../assets/img/modal-aluno/image.svg';
import './style.css';

interface TInputs {
  title: string;
  textButton1: string;
  textButton2: string;
  styleButton1: string;
  styleButton2: string;
  link1: string;
  link2: string;
  open: boolean;
  onClose: () => void;
}
const ModalCreate: React.FunctionComponent<TInputs> = ({ open, title, textButton1, textButton2, styleButton1, styleButton2, link1, link2, onClose }: TInputs) => {

  if (!open) return null

  return (
    <div className="modalContainer">
      <div className="modalBox" style={{ width: 550, paddingTop: 140 }}>
        <img src={imageModal} alt="" className="image-modal" />
        <h3>{title} Criado(a) com Sucesso!</h3>
        {/* <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui sagittis massa congue ac nisl tellus. Nulla tempor non et.</h6> */}
        <div className="line"></div>
        <div className="botoes">
          <Link to={link1}>
            <Button style={{ minWidth: 145 }} className={styleButton1}>{textButton1}</Button>
          </Link>
          {!onClose ?
            <Link to={link2}>
              <Button className={styleButton2} style={{ marginLeft: 8, minWidth: 145 }}>{textButton2}</Button>
            </Link>
            :
            <Button onClick={() => onClose()} className={styleButton2} style={{ marginLeft: 8, minWidth: 145 }}>{textButton2}</Button>
          }
        </div>
      </div>
    </div>
  );
}

export default ModalCreate;