import { CloseOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Input, Progress } from 'antd';
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useColaboradorContext } from '../../context/ColaboradorContext';
import { ContatoEnum } from '../../enum/contato.enum';

import StepsCreate from './steps-create';
import './style.css';

export const ColaboradoresCreateStep3: React.FunctionComponent = () => {
	const { colaborador, setColaborador, revisao, removeColaborador } =
		useColaboradorContext();

	const [isOpen, setIsOpen] = useState(false);

	const setContato = (idTipo: number, value: string) => {
		const colaboradorCopy = { ...colaborador };
		const contactToUpdateIndex = colaboradorCopy.contatos.findIndex(
			(c) => c.tipoContatoId === idTipo
		);

		if (idTipo === ContatoEnum.EPS) {
			colaboradorCopy.email = value;
		}

		if (contactToUpdateIndex !== -1) {
			colaboradorCopy.contatos[contactToUpdateIndex].descricao = value;
		} else {
			colaboradorCopy.contatos.push({
				id: null,
				descricao: value,
				ativo: true,
				codigo: '',
				tipoContatoCodigo: '',
				tipoContatoId: idTipo,
			});
		}
		setColaborador(colaboradorCopy);
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={75}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={2} />
			<div className="franquia">
				<div className="user-row">
					<h3>Preencha os meios de contato desse colaborador</h3>
					<div className="input-box">
						<p>Telefone</p>
						<MaskedInput
							mask="(11) 11111-1111"
							value={
								colaborador.contatos.find(
									(c) => c.tipoContatoId === ContatoEnum.TEL
								)?.descricao
							}
							onChange={(e) => setContato(ContatoEnum.TEL, e.target.value)}
							placeholder="Telefone"
							className="input"
							prefix={<PhoneOutlined rev={undefined} />}
						/>
					</div>
					<div className="inputs-box">
						<div className="input-box">
							<p>E-mail pessoal</p>
							<Input
								value={
									colaborador.contatos.find(
										(c) => c.tipoContatoId === ContatoEnum.EPS
									)?.descricao
								}
								onChange={(e) => setContato(ContatoEnum.EPS, e.target.value)}
								placeholder="email@gmail.com"
								className="input"
								prefix={<MailOutlined rev={undefined} />}
							/>
						</div>
						<div className="input-box">
							<p>E-mail profissional</p>
							<Input
								value={
									colaborador.contatos.find(
										(c) => c.tipoContatoId === ContatoEnum.EPF
									)?.descricao
								}
								onChange={(e) => setContato(ContatoEnum.EPF, e.target.value)}
								placeholder="email@gmail.com"
								className="input"
								prefix={<MailOutlined rev={undefined} />}
							/>
						</div>
					</div>
					<div className="botoes">
						{revisao ? (
							<Link to="/colaboradores/create/step-4">
								<Button
									type="primary"
									className="button-primary"
									style={{ width: 700 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						) : (
							<>
								<Link to="/colaboradores/create/step-2">
									<Button className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/colaboradores/create/step-4">
									<Button className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				id="cancelar"
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
					removeColaborador();
				}}
				title="Você deseja cancelar a adição de um novo colaborador?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/colaboradores"
			/>
		</div>
	);
};
