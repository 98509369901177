import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useDmPilarService } from "../../../services/dmpilar";

const useExcelenciaScreenState = () => {

  const [happyCoins, setHappyCoins] = useState<number>();

  const { findHappyCoins } = useDmPilarService();

  const { setIsGlobalLoading, unidade } = useGlobalContext();
 
  const buscarDadosIndicadores = useCallback(() => {
    findHappyCoins(unidade.id).then((response: AxiosResponse<number>) => {
      setHappyCoins(response?.data);
    })
  }, [unidade])

  const buscarRanking = useCallback(() => {
    findHappyCoins(unidade.id).then((response: AxiosResponse<number>) => {
      setHappyCoins(response?.data);
    })
  }, [unidade])

  useEffect(() => {
    if (unidade.id) {
      buscarDadosIndicadores();
    }
  }, [unidade]);



  return {
    happyCoins,
  }
}

export default useExcelenciaScreenState;