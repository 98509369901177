import { useState } from 'react';
import { useAlunoContext } from '../../../context/AlunoContext';

const usePrincipal = () => {
    const { aluno, setAluno } = useAlunoContext();
    const [isOpen2, setIsOpen2] = useState<boolean>(false);
    const [previousPrincipal, setPreviousPrincipal] = useState(null);

    const openModalTrocarResponsavel = (index: number) => {
        setPreviousPrincipal(previousPrincipal);
        setIsOpen2(true);
        setPreviousPrincipal(index);
    };

    const handleSwitchPrincipal = (index) => {
        if (!aluno?.pessoa?.pessoaResponsaveis?.[index]) {
            console.error("Invalid index or pessoaResponsaveis array is undefined.");
            return;
        }

        const responsavelList = [...aluno.pessoa.pessoaResponsaveis];
        responsavelList.forEach((resp) => (resp.principal = false));
        responsavelList[index].principal = true;

        const sortedResponsavelList = [
            ...responsavelList.filter(resp => resp.principal),
            ...responsavelList.filter(resp => !resp.principal),
        ];

        setAluno({
            ...aluno,
            pessoa: {
                ...aluno.pessoa,
                pessoaResponsaveis: sortedResponsavelList,
                trocarResponsavel: true,
            },
        });

        setIsOpen2(false);
        setPreviousPrincipal(index);
    };

    return { handleSwitchPrincipal, previousPrincipal, setPreviousPrincipal, isOpen2, setIsOpen2, openModalTrocarResponsavel };
};

export default usePrincipal;
