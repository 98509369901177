/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Form, InputNumber, Select } from 'antd';
import { differenceInMonths, intervalToDuration } from 'date-fns';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useGrupoService } from '../../../services/grupo.service';
import '../../../theme/flex.scss';
import { currencyFormatter, currencyParser, toMoment } from '../../../util/format';
import EscolaGruposFormActionComponent from '../components/escola-grupos-form-action-component/escola-grupos-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-grupos-form-fechar-component/escola-grupos-form-fechar.component';
import MenuLateralCreateContratosComponent from '../components/menu-lateral-create-contrato-component/menu-lateral-create-contrato.component';
import '../styles/escola-grupo-create-step.styles.scss';

export const GrupoContratoCreateStep2Screen: React.FunctionComponent = () => {

  const { contratoParaCadastrar, setContratoParaCadastrar, flRevisao, setFlRevisao } =
    useEscolaContext();

  const history = useHistory();
  const [form] = Form.useForm();
  const [totalLicencaIntra, setTotalLicencaIntra] = useState<any>();
  const [totalLicencaExtra, setTotalLicencaExtra] = useState<any>();
  const [parcelamento, setParcelamento] = useState<any>();
  const [vencimentoInicial, setVencimentoInicial] = useState<moment.Moment>();
  const { idGrupo } = useParams<any>();
  const [splitLicencaIntra, setSplitLicencaIntra] = useState<any>(null);
  const [splitLicencaExtra, setSplitLicencaExtra] = useState<any>(null);
  const { setIsGlobalLoading } = useGlobalContext();
  const grupoService = useGrupoService();
  const [totalServico, setTotalServico] = useState<number>(0);
  const [custoTotalContratoBruto, setCustoTotalContratoBruto] = useState<number>(0);
  const [isSplit100Porcento, setIsSplit100Porcento] = useState<boolean>(false);

  const isNothing = (object: any) => {
    return object === undefined || object === null;
  }

  const rounded = (valor: number, decimais: number): number => {
    return Number(valor.toFixed(decimais));
  }

  const inicializar = (idUnidade: number) => {
    let isSplit100Porcento = true;
    let splitIntra = contratoParaCadastrar.valorLicencaIntra;
    let splitExtra = contratoParaCadastrar.valorLicencaExtra;

    if (idUnidade && contratoParaCadastrar.tipo.id === 2) {
      isSplit100Porcento = false;
      if (contratoParaCadastrar?.qtdLicencaIntra <= 200) {
        splitIntra = 14.90;
      } else if (contratoParaCadastrar?.qtdLicencaIntra <= 400) {
        splitIntra = 12.90;
      } else {
        splitIntra = 10.90;
      }
      splitExtra = 39.90;
    }
    setIsSplit100Porcento(isSplit100Porcento);
    setVencimentoInicial(toMoment(contratoParaCadastrar?.vencimentoInicial));

    const intervalo = intervalToDuration({
      start: new Date(contratoParaCadastrar.vigenciaInicio),
      end:  new Date (contratoParaCadastrar.vigenciaFim)
    });

    const qtdMeses = intervalo.years * 12 + intervalo.months;

    contratoParaCadastrar.totalLicencaIntra =
      rounded(
        contratoParaCadastrar.valorLicencaIntra * contratoParaCadastrar?.qtdLicencaIntra * qtdMeses,
        3);

    contratoParaCadastrar.totalLicencaExtra =
      rounded(
        contratoParaCadastrar.valorLicencaExtra * contratoParaCadastrar?.qtdLicencaExtra * qtdMeses,
        3);

    if (isSplit100Porcento) {
      contratoParaCadastrar.splitLicencaIntra = contratoParaCadastrar?.totalLicencaIntra;
      contratoParaCadastrar.splitLicencaExtra = contratoParaCadastrar?.totalLicencaExtra;
    } else {

      if (isNothing(contratoParaCadastrar.splitLicencaIntra))

        contratoParaCadastrar.splitLicencaIntra =
          rounded(splitIntra * contratoParaCadastrar?.qtdLicencaIntra * qtdMeses, 3);

      if (isNothing(contratoParaCadastrar.splitLicencaExtra))

        contratoParaCadastrar.splitLicencaExtra =
          rounded(splitExtra * contratoParaCadastrar?.qtdLicencaExtra * qtdMeses, 3);

    }
    setContratoParaCadastrar(contratoParaCadastrar);

    setTotalLicencaIntra(contratoParaCadastrar.totalLicencaIntra);
    setSplitLicencaIntra(contratoParaCadastrar.splitLicencaIntra);
    setTotalLicencaExtra(contratoParaCadastrar.totalLicencaExtra);
    setSplitLicencaExtra(contratoParaCadastrar.splitLicencaExtra);

    let qtdServico = 0;

    if (contratoParaCadastrar.servico?.id == 1 || contratoParaCadastrar.servico?.id == 3 || contratoParaCadastrar.servico?.id == 4 || contratoParaCadastrar.servico?.id == 6)
      qtdServico = contratoParaCadastrar.qtdLicencaIntra;

    if (contratoParaCadastrar.servico?.id == 2 || contratoParaCadastrar.servico?.id == 3 || contratoParaCadastrar.servico?.id == 5 || contratoParaCadastrar.servico?.id == 6)
      qtdServico = qtdServico + contratoParaCadastrar.qtdLicencaExtra;

    setTotalServico(rounded(contratoParaCadastrar.valorServico * qtdServico * qtdMeses, 3));

    setCustoTotalContratoBruto(
      contratoParaCadastrar.totalLicencaExtra
      + contratoParaCadastrar.totalLicencaIntra
      + rounded(contratoParaCadastrar.valorServico * qtdServico * qtdMeses, 3));

  }

  useEffect(() => {
    setIsGlobalLoading(true);

    grupoService.findById({ id: contratoParaCadastrar?.grupo.id }).then(
      ({ data }) => inicializar(data.unidade?.id)
    ).finally(() => setIsGlobalLoading(false));

  }, [])

  // const custoTotalContratoBruto = useMemo(() => {
  //   const qtdLicencaIntra = contratoParaCadastrar?.qtdLicencaIntra;
  //   const qtdLicencaExtra = contratoParaCadastrar?.qtdLicencaExtra;

  //   if (totalLicencaExtra === 'undefined' || totalLicencaIntra === 'undefined') {
  //     return 0.0;
  //   }

  //   return ((totalLicencaExtra * (qtdLicencaExtra || 1) + (totalLicencaIntra * (qtdLicencaIntra || 1)))) + (totalServico || 0);
  // }, [contratoParaCadastrar])

  // const custoTotalContratoLiquido = useMemo(() => {
  //   return custoTotalContratoBruto;
  // }, [custoTotalContratoBruto])

  const quantidadeParcelas = useMemo(() => {
    if (!!contratoParaCadastrar?.vigenciaFim && !!contratoParaCadastrar?.vigenciaInicio) {
      const qtdMeses = differenceInMonths(moment(contratoParaCadastrar?.vigenciaFim).toDate(), moment(contratoParaCadastrar?.vigenciaInicio).toDate());
      return qtdMeses <= 0 ? 1 : qtdMeses;
    }
  }, [contratoParaCadastrar])


  const onBack = () => {
    // if (flRevisao) return;

    setContratoParaCadastrar((contratoParaCadastrar) => {
      return {
        ...contratoParaCadastrar,
        totalLicencaIntra: totalLicencaIntra,
        splitLicencaIntra: splitLicencaIntra,
        totalLicencaExtra: totalLicencaExtra,
        splitLicencaExtra: splitLicencaExtra,
        qtdParcelas: parcelamento,
        valorTotal: custoTotalContratoBruto,
        vencimentoInicial: vencimentoInicial
      }
    });
  }

  const handleStep3 = (form) => {

    contratoParaCadastrar.splitLicencaIntra = splitLicencaIntra;
    contratoParaCadastrar.splitLicencaExtra = splitLicencaExtra;
    contratoParaCadastrar.vencimentoInicial = vencimentoInicial.toDate();

    setContratoParaCadastrar((contratoParaCadastrar) => {
      const contrato = {
        ...contratoParaCadastrar,
        ...form,
        valorTotal: custoTotalContratoBruto,
        // valorDesconto: vlDesconto || 0.00,
      };
      return JSON.parse(JSON.stringify(contrato));
    })

    if (flRevisao) {
      setFlRevisao(false);
      return history.replace(`/escolas/grupos/${idGrupo}/contratos/create/step-4`);
    }

    history.push(`/escolas/grupos/${idGrupo}/contratos/create/step-3`);
  }

  const componentLicencaExtra = () => {

    return (<>
      <div className="row" style={{ flex: 1, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div className="col" style={{ flex: 1, display: 'flex' }}>
          <span className='label-intra-extra'>Extra</span>
        </div>
      </div>
      <div className="line" style={{ display: 'flex', flex: 1, width: '100%', }}>
        <div className="input-franquia ">
          <span>Valor total da licença extra</span>

          <InputNumber
            value={totalLicencaExtra}
            style={{ padding: 10 }}
            onChange={setTotalLicencaExtra}
            decimalSeparator=','
            formatter={currencyFormatter}
            parser={currencyParser}
            disabled={true} />

        </div>
        <div className="input-franquia ">
          <span>Valor total do split da licença extra</span>

          <InputNumber
            placeholder={'Digite o valor total do split da licença extra'}
            min={0}
            decimalSeparator=','
            onChange={setSplitLicencaExtra}
            formatter={currencyFormatter}
            parser={currencyParser}
            value={splitLicencaExtra}
            disabled={isSplit100Porcento} />

        </div>
      </div>
    </>)
  }


  const componentParcelamento = () => {

    return (<>
      <div className="input-franquia ">
        <span>Parcelamento do contrato</span>

        <Form.Item
          name="qtdParcelas"
          initialValue={contratoParaCadastrar?.qtdParcelas}
          rules={[{ required: false, message: 'Parcelamento do contrato' }]}>

          <Select
            id="qtdParcelas"
            placeholder="Parcelamento do contrato"
            allowClear
            onChange={setParcelamento}
          >
            {/* <Select.Option key={1} value={1}>1 parcelax</Select.Option> */}
            {quantidadeParcelas && quantidadeParcelas >= 0 && new Array(quantidadeParcelas).fill(0).map((value, index) => {

              return <Select.Option key={index + 1} value={index + 1}>{index + 1} parcela{(index + 1) > 1 ? 's' : ''} ({currencyFormatter(custoTotalContratoBruto / (index + 1))})</Select.Option>

            })}
          </Select>
        </Form.Item>
      </div>
      <div className='flex flex-1'>
        <div className="input-box" style={{ width: '100%', marginTop: 15 }}>
          <h6>Vencimento inicial</h6>

          <DatePicker
            style={{ width: '100%' }}
            format={'DD-MM-YYYY'}
            onChange={e => setVencimentoInicial(e)}
            value={vencimentoInicial}
            getPopupContainer={triggerNode => { return triggerNode.parentElement }} />

        </div>
      </div>
    </>);

  }

  const componentServicos = () => {

    return (<>
      <span style={{ color: '#344453', fontSize: 16, fontWeight: 600, paddingTop: 10, paddingBottom: 10 }}>
        Serviços Educacionais
      </span>
      <div className="line" style={{ display: 'flex', flex: 1, width: '100%', }}>
        <div className="input-franquia ">
          <span>Valor total do serviço</span>

          <InputNumber
            placeholder={'Valor serviço'}
            min={0}
            formatter={currencyFormatter}
            style={{ padding: 10 }}
            value={totalServico}
            decimalSeparator=','
            disabled={true} />

        </div>
      </div>
    </>);

  }

  return (
    <div className="container" id="escola-grupo-create-step">
      <Header />
      <Progress />
      <MenuLateralCreateContratosComponent current={2} />
      <Form
        form={form}
        name="control-hooks"
        onFinish={handleStep3}
      >
        <div className="franquia">
          <div className="franquia-row">
            <h5>Financeiro</h5>
            <div className="input-franquia">
              <span>Custo total do contrato</span>

              <InputNumber
                placeholder={'Custo total do contrato'}
                value={custoTotalContratoBruto}
                min={0}
                formatter={currencyFormatter}
                stringMode
                decimalSeparator=','
                disabled={true} />

            </div>

            <span
              style={
                { color: '#344453', fontSize: 16, fontWeight: 600, paddingTop: 10, paddingBottom: 10 }
              }>

              Custo por licença
            </span>
            <div className="row" style={{ flex: 1, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div className="col" style={{ flex: 1, display: 'flex', }}>
                <span className='label-intra-extra'>Intra</span>
              </div>
            </div>

            <div className="line" style={{ display: 'flex', flex: 1, width: '100%', }}>
              <div className="input-franquia ">
                <span>Valor total da licença intra</span>

                <InputNumber
                  min={0}
                  onChange={setTotalLicencaIntra}
                  formatter={currencyFormatter}
                  style={{ padding: 10 }}
                  parser={currencyParser}
                  decimalSeparator=','
                  value={totalLicencaIntra}
                  disabled={true} />

              </div>
              <div className="input-franquia ">
                <span>Valor total do split de intra</span>

                <InputNumber
                  placeholder={'Digite o valor total do split'}
                  min={0}
                  decimalSeparator=','
                  onChange={setSplitLicencaIntra}
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  value={splitLicencaIntra}
                  disabled={isSplit100Porcento} />

              </div>
            </div>
            <div style={{ width: 40, flex: 'unset' }} />
            {componentLicencaExtra()}
            {componentServicos()}
            {componentParcelamento()}

            <EscolaGruposFormActionComponent
              revisao={flRevisao}
              rotaAnterior={`/escolas/grupos/${idGrupo}/contratos/create/step-1`}
              onBack={onBack}
            />
          </div>
        </div>

      </Form>
      <EscolaGruposFormFecharComponent
        dsLink={`/escolas/grupos/${idGrupo}/contratos`}
        onClick={() => {
          setContratoParaCadastrar(null)
        }}
      />
    </div>
  )
}