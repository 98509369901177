interface AlunosHeaderTable {
	key: number;
	width: string;
	title: string;
}

export default function UseAlunosTableColumns() {
	const alunosHeaderTable: AlunosHeaderTable[] = [
		{
			key: 1,
			width: '30%',
			title: 'Nome',
		},
		{
			key: 2,
			width: '10%',
			title: 'Código',
		},
		{
			key: 3,
			width: '10%',
			title: 'Idade',
		},
		{
			key: 4,
			width: '20%',
			title: 'Cursos Ativos',
		},
		{
			key: 5,
			width: '25%',
			title: 'Responsável Principal',
		},
		{
			key: 6,
			width: '5%',
			title: '',
		},
	];

	return {
		alunosHeaderTable,
	};
}
