import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import MatriculaImage from '../../../assets/img/excelencia/address-card.svg';
import ProspeccaoImage from '../../../assets/img/excelencia/chart-line.svg';
import AdimplenciaImage from '../../../assets/img/excelencia/money-check-alt.svg';
import RematriculaImage from '../../../assets/img/excelencia/user-edit.svg';
import AlunoImage from '../../../assets/img/excelencia/user-graduate.svg';
import { useGlobalContext } from "../../../context/GlobalContext";
import { IDmIndicadorTotalDTO, IDmPilarTotalIndicadorDTO } from "../../../models/happy-code-api.model";
import { useDmIndicadorService } from "../../../services/dmindicador";
import { useDmPilarService } from "../../../services/dmpilar";

const useAccordionExcelenciaState = () => {

  const [pilaresIndicadores, setPilaresIndicadores] = useState<IDmPilarTotalIndicadorDTO[]>();
  const [totalPorIndicadores, setTotalPorIndicadores] = useState<IDmIndicadorTotalDTO[]>();

  const { findIndicadoresPorUnidade } = useDmIndicadorService();
  const { findPilarIndicadoresPorUnidade } = useDmPilarService();

  const { unidade } = useGlobalContext();


  const estilosPilar = {
    'Base de Alunos': {
      icone: AlunoImage,
      cor: '#F57C00',
      estilo: { 'flex': 2 },
    },
    'Matricula': {
      icone: MatriculaImage,
      cor: '#1f61cc',
      estilo: { 'flex': 1 },
    },
    'Rematricula': {
      icone: RematriculaImage,
      cor: '#FBAF1F',
      estilo: { 'flex': 2 },
    },
    'Adimplência': {
      icone: AdimplenciaImage,
      cor: '#DC3735',
      estilo: { 'flex': 1 },

    },
    'Prospecção': {
      icone: ProspeccaoImage,
      cor: '#B0CB51',
      estilo: { 'flex': 4 },
    },
  }

  const buscarDadosIndicadores = useCallback(() => {
    findPilarIndicadoresPorUnidade(unidade.id).then((response: AxiosResponse<IDmPilarTotalIndicadorDTO[]>) => {
      const responseOrdernada = [];

      responseOrdernada.push(response.data.find((item) => item.dsPilar === 'Matricula'));
      responseOrdernada.push(response.data.find((item) => item.dsPilar === 'Base de Alunos'));
      responseOrdernada.push(response.data.find((item) => item.dsPilar === 'Rematricula'));
      responseOrdernada.push(response.data.find((item) => item.dsPilar === 'Adimplência'));
      responseOrdernada.push(response.data.find((item) => item.dsPilar === 'Prospecção'));

      setPilaresIndicadores(responseOrdernada);

      return findIndicadoresPorUnidade(unidade.id).then((responseIndicadores: AxiosResponse<IDmIndicadorTotalDTO[]>) => {
        setTotalPorIndicadores(responseIndicadores.data);
      })
    })
  }, [unidade])

  useEffect(() => {
    if (unidade.id) {
      buscarDadosIndicadores();
    }
  }, [unidade]);



  return {
    pilaresIndicadores,
    totalPorIndicadores,
    estilosPilar,
  }
}

export default useAccordionExcelenciaState;