import * as Unicons from '@iconscout/react-unicons';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import { useEscolaContext } from '../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IEscolaFullDTO } from '../../../../models/happy-code-api.model';
import { useEscolaService } from '../../../../services/escola.service';
import { useNotificationService } from '../../../../services/notification.service';
import './style.css';

interface Props {
    escola: IEscolaFullDTO;
    buscarDados: () => void;
}

const ItemTabelaEscolaComponent: React.FunctionComponent<Props> = ({ escola, buscarDados }: Props) => {

    const notification = useNotificationService();
    const [open, setOpen] = useState(false);
    const { setEscolaParaCadastrar } = useEscolaContext();
    const history = useHistory();
    const { setIsGlobalLoading, usuario } = useGlobalContext();
    const escolaService = useEscolaService();
    const [flExibirModalDesativarEscola, setFlExibirModalDesativarEscola] = useState(false);

    useEffect(() => {
        // const getNegocioUnidade = () => {
        //   const predicate = new Predicate();
        //   predicate.addOption('escolaId', escola.id);
        //   negocioUnidadeService.find(predicate)
        //     .then(({ data }) => {
        //       setNegocioUnidade(data.content);
        //     })
        // }

        // if ((!negocioUnidade || negocioUnidade.length === 0) && open) {
        //   getNegocioUnidade();
        // }
    }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

    const goToRevisao = () => {
        setIsGlobalLoading(true);
        escolaService.findFullById(escola)
            .then(({ data }) => {
                setEscolaParaCadastrar(data);
                history.push("/escolas/create/step-4");
            }).finally(() => setIsGlobalLoading(false));
    }

    const getStatusCss = () => {
        if (escola.ativo) {
            return (
                <div className="estado">
                    <div className="dot-verde"></div>
                    <p className='ativo' style={{ marginRight: 10 }}>Ativo</p>
                </div>
            );
        }

        if (!escola.ativo) {
            return (
                <div className="estado">
                    <div className="dot-vermelho"></div>
                    <p className='inativo' style={{ marginRight: 10 }}>Inativo</p>
                </div>
            );
        }
    }

    const onDesativarEscola = () => {
        setFlExibirModalDesativarEscola(false);
        setIsGlobalLoading(true);
        escolaService.cancelar(escola.id)
            .then((response) => {
                buscarDados();

                if (response.status >= 200 && response.status <= 299) {
                    notification({ description: 'Escola desativado com sucesso!', type: 'success', message: 'Sucesso' });
                }
            })
            .finally(() => setIsGlobalLoading(false));
    }

    const itensDropdown = () => {
        return (
            <Menu>
                <Menu.Item>
                    <li>
                        {/* <Link to={`/franquias/vinculo-externo/${escola?.id}`} rel="noopener noreferrer">
              renovar contrato
            </Link> */}
                    </li>
                    <Menu.Item>
                        <a onClick={() => setFlExibirModalDesativarEscola(true)}>
                            desativar escola
                        </a>
                    </Menu.Item>
                </Menu.Item>
            </Menu>
        )
    }

    if (!open) {
        return (
            <div className="registro-user" style={{ paddingTop: 20, paddingBottom: 20 }} id="registro-financeiro">
                <Modal
                    open={flExibirModalDesativarEscola}
                    onClose={() => setFlExibirModalDesativarEscola(false)}
                    title='Você deseja desativar a escola?'
                    subtitle='Essa ação não pode ser desfeita.'
                    textButton1='Não'
                    textButton2='Sim, desativar'
                    styleButton1='button-line'
                    styleButton2='button-danger'
                    button2={onDesativarEscola}
                />
                <div className="registros-row" >
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{escola?.nomeFantasia}</span>
                        <h4>{getStatusCss()}</h4>
                    </div>
                    {/* <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            sem informação
          </div> */}
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{escola.endereco?.nomeUF}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{escola.endereco?.cidade}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{escola?.unidade?.descricao ? escola?.unidade?.descricao : "Sede"}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span className="gestor">{escola?.focalOperacional?.nome}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {usuario?.funcao === 'ADM' || usuario.funcao === 'TEC' ?
                            <div className="registro" style={{ width: "2%" }}>
                                <Dropdown overlay={itensDropdown()} placement="bottomRight" arrow>
                                    <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                                </Dropdown>
                            </div>
                            : null}
                        <div onClick={() => setOpen(!open)} className="registro-opcao">
                            <div className="circle">
                                <Unicons.UilAngleDown size="20" color="#6A7C96" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="registros-body-detalhes">
            <div className="row-1">
                <div className="registros-row">
                    <div className="registro" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <span>{escola?.nomeFantasia}</span>
                        <h4>{getStatusCss()}</h4>
                    </div>
                    {/* <div className="registro" style={{ flex: 1, display: 'flex' }}>
            sem informação
          </div> */}
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span>{escola.endereco?.nomeUF}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span>{escola.endereco?.cidade}</span>
                    </div>
                    <div className="registro" style={{ flex: 1, display: 'flex' }}>
                        <span className="gestor">{escola?.focalOperacional?.nome}</span>
                    </div>
                </div>
                <div className="registro-opcao">
                    <div className="circle" onClick={() => setOpen(!open)}>
                        <Unicons.UilAngleDown size="20" color="#6A7C96" />
                    </div>
                </div>
            </div>
            <div className="row-2">
                <div className="dados">
                    <h6>CNPJ</h6>
                    <span>{escola.cnpj}</span>
                    <br />
                    <h6>Endereço</h6>
                    <span>{escola.endereco?.logradouro},{escola.endereco?.numero} - {escola.endereco?.bairro}, {escola.endereco?.cidade} - {escola.endereco?.nomeUF}, {escola.endereco?.cep}</span>
                </div>
                <div className="line"></div>
                <div className="modelos">
                    <h6>CATEGORIA CONFESSIONAL</h6>
                    <span>{escola?.categoriaConfessional?.descricao}</span>
                </div>
                <div className="line"></div>
                <div className="negocios">
                    <h6>Categoria quantidade alunos</h6>
                    {escola?.categoriaQuantidadeAlunos?.descricao}
                </div>
            </div>
            <Button onClick={() => goToRevisao()} style={{ marginTop: 20 }} className="button-primary">
                Ver mais detalhes
            </Button>
        </div >
    )


}

export default ItemTabelaEscolaComponent;