/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable
import { SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Alert, Button, Card, Checkbox, DatePicker, Divider, Dropdown, Input, Menu, Select } from 'antd';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import info from '../../assets/img/financeiro-info/info.svg';
import DivStatusCobranca from '../../components/DivStatusCobranca';
import { ModalFinanceiro } from '../../components/ModalFinanceiro';
import { ModalFinanceiroCancelamentoStep2 } from '../../components/ModalFinanceiro/cancelamento-2';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import { TipoStatusCobranca } from '../../enum/tipoStatusCobranca';

import { ModalNfe } from '../../components/ModalFinanceiro/ModalNfe';
import { ICobrancaCustomDTO, ICobrancaKpiDTO, ILogCleanDTO, IStatusCobrancaCleanDTO, ITipoPagamentoCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useCobrancaService } from '../../services/cobranca.service';
import { useLogService } from '../../services/log.service';
import { useNotificationService } from '../../services/notification.service';
import { useStatusCobrancaService } from '../../services/status-cobranca.service';
import { useTipoPagamentoService } from '../../services/tipo-pagamento.service';
import { daysBetween, formatMomentLocalDate, formatMoney, formattedDate } from '../../util/format';
import './style.css';

const { RangePicker } = DatePicker;

export const Financeiro: React.FunctionComponent = () => {
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const { nomeAluno } = useParams<any>();

    const [listTipoPagamento, setListTipoPagamento] = useState<ITipoPagamentoCleanDTO[]>();
    const [listStatusCobranca, setListStatusCobranca] = useState<IStatusCobrancaCleanDTO[]>();
    const [data, setData] = useState<PageableResponse<ICobrancaCustomDTO>>();
    const [cobrancaSelected, setCobrancaSelected] = useState<ICobrancaCustomDTO>();
    const [kpi, setKpi] = useState<ICobrancaKpiDTO>(null);
    // const [balance, setBalance] = useState<IGetBalanceResponseDTO>();
    const [statusCobrancaId, setStatusCobrancaId] = useState<string>("TDS");
    const [tipoPagamentoId, setTipoPagamentoId] = useState<number | string>("TDS");
    const [tipoPessoa, setTipoPessoa] = useState<boolean>(false);
    const [descricao, setDescricao] = useState<string>("");
    const [dataInicio, setDataInicio] = useState<moment.Moment>();
    const [dataFim, setDataFim] = useState<moment.Moment>();
    const [isOpen, setIsOpen] = useState(false);
    const [rematricula, setRematricula] = useState<boolean>(false);
    const [sortAluno, setSortAluno] = useState<boolean>(false);
    const [sortResponsavel, setSortResponsavel] = useState<boolean>(false);

    const [selected] = useState('registro-financeiro');
    const [filtro] = useState('filtros-botoes-financeiro');

    const [kpiPredicate] = useState<Predicate>(new Predicate());
    const [predicate] = useState<Predicate>(new Predicate());

    const [informationVlDiaOne, setInformationVlDiaOne] = useState(false);
    const [informationVlDiaTwo, setInformationVlDiaTwo] = useState(false);

    // const nfeService = useNfeService();
    const cobrancaService = useCobrancaService();
    const tipoPagamentoService = useTipoPagamentoService();
    const statusCobrancaService = useStatusCobrancaService();
    // const pagarmeService = usePagarMeService();
    const notification = useNotificationService();
    const dataAtual = formatMomentLocalDate(moment(Date()));
    const [isOpenModalNfe, setIsOpenModalNfe] = useState(false);
    const [idEnota, setIdEnota] = useState<string>();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso tela grupos",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const onChangeRematricula = () => {
        setRematricula(!rematricula);
    }

    const findCobranca = () => {
        unidade.id && predicate.addOption('unidadeId', unidade.id);

        if (descricao) {
            predicate.addOption('tipoPessoa', tipoPessoa);
            predicate.addOption('pessoaNome', descricao);
        }

        predicate.addOption('flRematricula', rematricula);

        return cobrancaService.findCustomComFinanceiro(predicate)
            .then((response) => setData(response.data));
    }

    const findStatusCobranca = () => {
        return statusCobrancaService.findCleanList(predicate)
            .then((response) => setListStatusCobranca(response.data));
    }

    const findTipoPagamento = () => {
        const tipoPagamentoPredicate = new Predicate();
        return tipoPagamentoService.findCleanList(tipoPagamentoPredicate)
            .then((response) => setListTipoPagamento(response.data));
    }

    const findKpi = () => {
        kpiPredicate.addOption('unidadeId', unidade.id);
        kpiPredicate.addOption('pessoaNome', descricao);
        kpiPredicate.addOption('flRematricula', rematricula);

        return cobrancaService.getKpi(kpiPredicate)
            .then((response) => setKpi(response.data));
    }

    useEffect(() => {
        if (nomeAluno && !statusCobrancaId && !descricao) {
            return;
        }
        if (unidade.id) {
            setIsGlobalLoading(true);
            Promise.all([
                findCobranca(),
                findKpi(),
                // findPagarMe(),
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidade.id]);

    useEffect(() => {
        if (nomeAluno) {
            setDescricao(nomeAluno)
            setStatusCobrancaId(TipoStatusCobranca.CODIGO_VENCIDO);
        }
        findTipoPagamento();
        findStatusCobranca();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (nomeAluno && !statusCobrancaId && !descricao) {
            return;
        }
        predicate.removeAllOption();
        kpiPredicate.removeAllOption();

        if (descricao) {
            predicate.addOption('tipoPessoa', tipoPessoa);
            predicate.addOption('pessoaNome', descricao);
            kpiPredicate.addOption('pessoaNome', descricao);
        }

        if (rematricula == true) {
            predicate.addOption('flRematricula', rematricula);
            kpiPredicate.addOption('flRematricula', rematricula);
        }

        if (tipoPagamentoId && tipoPagamentoId !== 'TDS') {
            predicate.addOption('tipoPagamentoId', tipoPagamentoId);
            kpiPredicate.addOption('tipoPagamentoId', tipoPagamentoId);
        }

        if (statusCobrancaId && statusCobrancaId !== 'TDS' && statusCobrancaId !== 'VNC') {
            const arrayFiltro = statusCobrancaId.split(",");
            for (let i = 0; i <= arrayFiltro.length; i++) {
                if (arrayFiltro[i] !== ',' && arrayFiltro[i] !== undefined && arrayFiltro[i] !== 'TDS' && arrayFiltro[i] !== 'VNC') {
                    predicate.addMultiOption('statusCobrancaId', arrayFiltro[i], Operators.OR);
                    kpiPredicate.addMultiOption('statusCobrancaId', arrayFiltro[i], Operators.OR);
                }
                if (arrayFiltro[i] === 'TDS' || arrayFiltro[i] === 'VNC') {
                    notification({
                        description: `Os filtros 'Todos' ou 'Vencidos' devem ser aplicados individualmente e foram removidos da seleção`,
                        type: 'warning',
                        message: 'Aviso!',
                    })
                }
            }
        }

        if (statusCobrancaId && statusCobrancaId == 'VNC') {
            predicate.addMultiOption('statusCobrancaId', 1, Operators.OR);
            predicate.addMultiOption('statusCobrancaId', 2, Operators.OR);
            predicate.addMultiOption('statusCobrancaId', 7, Operators.OR);
            kpiPredicate.addMultiOption('statusCobrancaId', 1, Operators.OR);
            kpiPredicate.addMultiOption('statusCobrancaId', 2, Operators.OR);
            kpiPredicate.addMultiOption('statusCobrancaId', 7, Operators.OR);
            predicate.addMultiOption('dataVencimento', dataAtual, Operators.LESS_THAN, true);
            kpiPredicate.addMultiOption('dataVencimento', dataAtual, Operators.LESS_THAN, true);
        }

        if (statusCobrancaId == null) {
            predicate.addMultiOption('statusCobrancaId', 5, Operators.NOT_EQUAL, true);
            kpiPredicate.addMultiOption('statusCobrancaId', 5, Operators.NOT_EQUAL, true);
            predicate.addMultiOption('statusCobrancaId', 8, Operators.NOT_EQUAL);
            kpiPredicate.addMultiOption('statusCobrancaId', 8, Operators.NOT_EQUAL);
        }

        if (tipoPagamentoId || statusCobrancaId || rematricula || !rematricula) {
            if (unidade.id) {
                setIsGlobalLoading(true);
                Promise.all([
                    findCobranca(),
                    findKpi(),
                ]).finally(() => setIsGlobalLoading(false));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoPagamentoId, statusCobrancaId, rematricula, descricao, tipoPessoa]);

    useEffect(() => {
        if (nomeAluno && !dataInicio && !dataFim) {
            return;
        }
        predicate.removeOption('dataVencimento');
        kpiPredicate.removeOption('dataVencimento');
        if ((dataInicio || dataFim)) {
            predicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
            predicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
            kpiPredicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataInicio), Operators.GREATER_THAN_OR_EQUAL);
            kpiPredicate.addMultiOption('dataVencimento', formatMomentLocalDate(dataFim), Operators.LESS_THAN_OR_EQUAL, true);
        }
        if (unidade.id) {
            setIsGlobalLoading(true);
            Promise.all([
                findCobranca(),
                findKpi(),
            ]).finally(() => setIsGlobalLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataInicio, dataFim]);

    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        setIsGlobalLoading(true);
        predicate.setPage(page);
        findCobranca().finally(() => setIsGlobalLoading(false));
    }

    const onChangeTipoPessoa = (tipoPessoa) => {
        if (tipoPessoa === 0) {
            setTipoPessoa(false)
        } else {
            setTipoPessoa(true)
        }
    }

    const getPeriodo = (): any => {
        if (dataInicio && dataFim) {
            return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')]
        }
        return null;
    }

    //Declaração de funções para modal
    // const abrirNegociacao = (cobranca) => {
    //     setCobrancaSelected(cobranca);
    //     setIsOpen(true);
    // }

    // const abrirCancelamento = (cobranca) => {
    //     setCobrancaSelected(cobranca);
    //     setIsOpen(true);
    // }

    const finalizaCancelamento = () => {
        setIsOpen(false);
        findCobranca();
        findKpi();
    }

    const finalizaModalNfe = () => {
        setIdEnota(null);
        setIsOpenModalNfe(false);
        findCobranca();
        findKpi();
    }

    const finalizaNegociacao = () => {
        setIsOpen(false);
        findCobranca();
        findKpi();
    }

    const cancelarPedido = (order: string) => {
        setIsGlobalLoading(true);
        cobrancaService.cancelarOrder(order)
            .then(() => notification({ description: "O pedido foi cancelado!", type: 'success', message: 'Aviso!' }))
            .catch(() => notification({ description: "Houve uma falha ao cancelar o pedido!", type: 'warning', message: 'Aviso!' }))
            .finally(() => setIsGlobalLoading(false));
    }

    //Atualizar estado de abriar modal para false
    useEffect(() => {
        setIsOpen(false);
    }, []);

    const openModalNfe = (idEnota: string, cobranca) => {
        setCobrancaSelected(cobranca);
        setIsOpenModalNfe(true);
        setIdEnota(idEnota);
    }

    const descricaoProduto = (cobranca: ICobrancaCustomDTO): string => {
        const descricaoNegocios = cobranca?.financeiro?.matriculas
            .map(m => m?.turma?.curriculo ? m?.turma?.curriculo?.descricaoCurriculo : m?.turma?.curso?.descricao)
            .join(', ');
        return descricaoNegocios;
    }

    const itensDropdown = (cobranca: ICobrancaCustomDTO) => {

        return (
            <Menu>
                {cobranca?.statusCobranca.codigo !== 'PGO' && cobranca.statusCobranca.codigo !== 'IST' && cobranca.statusCobranca.codigo !== 'CAN' ? (
                    <Menu.Item>
                        <a target="blank" href={`/checkout/${cobranca.codigoPedido}`} rel="noopener noreferrer">
                            Emitir 2ª. via
                        </a>
                    </Menu.Item>
                ) : null}
                {cobranca?.statusCobranca.codigo === "GRD" && cobranca?.tipoPagamento?.codigo === "PMF" && cobranca.order != null ? (
                    <Menu.Item>
                        <a onClick={() => cancelarPedido(cobranca.order)}>
                            Cancelar pedido Máquina Física
                        </a>
                    </Menu.Item>
                ) : null}
                {cobranca?.identificadorEnotas !== undefined ?
                    <Menu.Item>
                        <li>
                            <a onClick={() => openModalNfe(cobranca.identificadorEnotas, cobranca)}>
                                Nota de serviço
                            </a>
                        </li>
                    </Menu.Item>
                    : null}

                {cobranca?.identificadorEnotasProduto !== undefined ?
                    <Menu.Item>
                        <li>
                            <a onClick={() => openModalNfe(cobranca.identificadorEnotasProduto, cobranca)}>
                                Nota de produto
                            </a>
                        </li>
                    </Menu.Item>
                    : null}
            </Menu>
        )
    }

    const  ordenar = async (campo) => {
        setIsGlobalLoading(true);
        predicate.addSort(campo);
        await findCobranca();
        setIsGlobalLoading(false);
    }

    const ordenarAluno = () => {
        setSortAluno(!sortAluno);
        predicate.removeOption('sortResponsavel');
        predicate.addOption('sortAluno', sortAluno);
        findCobranca();
    }

    const ordenarResponsavel = () => {
        setSortResponsavel(!sortResponsavel);
        predicate.removeOption('sortAluno');
        predicate.addOption('sortResponsavel', sortResponsavel);
        findCobranca();
    }
    
    return (
        <div className="container">
            <NewMenu selecionado={11} />
            <div className="dashboard" style={{ paddingTop: 50, height: '100vh', overflowY: 'scroll' }}>
                <h3 className="title">Financeiro</h3>
                {kpi ? (
                    <Card>
                        <div className="box-title">
                            <div id="turma">
                                <h6>Saldo total</h6>
                                <h4>{formatMoney(kpi?.valorTotal)}</h4>
                            </div>
                            <div className="boxes-financeiro" >
                                <div className="box-financeiro" >
                                    <div className="icon">
                                        <Unicons.UilCheck size="16" color="#1EBE5E" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorEmDia)}</h3>
                                        <h4>Pagos em dia</h4>
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon">
                                        <Unicons.UilCalendarAlt size="16" color="#1EBE5E" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorEmAtraso)}</h3>
                                        <h4>Pagos em atraso</h4>
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon-aberto">
                                        <Unicons.UilClockNine size="16" color="#FF975B" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorEmAberto)}</h3>
                                        <Row>
                                            <h4>Em aberto</h4>
                                            <img className="config-icons-financeiro" src={info}
                                                onMouseEnter={() => setInformationVlDiaOne(true)}
                                                onMouseLeave={() => setInformationVlDiaOne(false)}
                                            />
                                        </Row>
                                        {informationVlDiaOne && (
                                            <Alert
                                                className="config-alert-financeiro"
                                                message="Valores em aberto"
                                                description='São referentes aos status "A gerar", "Gerado" e "Falha".'
                                                type="info"
                                                showIcon
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon-vencido">
                                        <Unicons.UilExclamationTriangle size="16" color="#FF4D4F" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorVencido)}</h3>
                                        <h4>Vencidos</h4>
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon-vencido">
                                        <Unicons.UilExclamationTriangle size="16" color="#FF4D4F" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorCancelado)}</h3>
                                        <h4>Cancelado</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div className="box-title" style={{ marginBottom: 0 }}>
                            <div id="turma">
                                <h6>Saldo HUB</h6>
                                <h4>{formatMoney(kpi?.valorTotalFranquia)}</h4>
                            </div>
                            <div className="boxes-financeiro">
                                <div className="box-financeiro">
                                    <div className="icon">
                                        <Unicons.UilCheck size="16" color="#1EBE5E" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorEmDiaFranquia)}</h3>
                                        <h4>Pagos em dia</h4>
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon">
                                        <Unicons.UilCalendarAlt size="18" color="#1EBE5E" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorEmAtrasoFranquia)}</h3>
                                        <h4>Pagos em atraso</h4>
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon-aberto">
                                        <Unicons.UilClockNine size="18" color="#FF975B" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorEmAbertoFranquia)}</h3>
                                        <Row>
                                            <h4>Em aberto</h4>
                                            <img className="config-icons-financeiro" src={info}
                                                onMouseEnter={() => setInformationVlDiaTwo(true)}
                                                onMouseLeave={() => setInformationVlDiaTwo(false)}
                                            />
                                        </Row>
                                        {informationVlDiaTwo && (
                                            <Alert
                                                className="config-alert-financeiro"
                                                message="Valores em aberto"
                                                description='São referentes aos status "A gerar", "Gerado" e "Falha".'
                                                type="info"
                                                showIcon
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon-vencido">
                                        <Unicons.UilExclamationTriangle size="18" color="#FF4D4F" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorVencidoFranquia)}</h3>
                                        <h4>Vencidos</h4>
                                    </div>
                                </div>
                                <div className="box-financeiro">
                                    <div className="icon-vencido">
                                        <Unicons.UilExclamationTriangle size="18" color="#FF4D4F" />
                                    </div>
                                    <div className="pago">
                                        <h3>{formatMoney(kpi?.valorCanceladoFranquia)}</h3>
                                        <h4>Cancelado</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                ) : null}
                <div className="flex justify-content-end mt25">
                    <div className="col align-items-center justify-content-center">
                        <span className='spanFinanceiro'>Rematricula</span>
                        <Checkbox onChange={onChangeRematricula} />
                    </div>
                    <div className="filtro-estado-financeiro ml10" id="input-dashboard">
                        <span className='spanFinanceiro'>Tipo Pessoa</span>
                        <Select
                            className="wp100"
                            defaultValue={0}
                            onChange={(e) => onChangeTipoPessoa(e)}>
                            <Select.Option value={0}>Aluno</Select.Option>
                            <Select.Option value={1}>Responsável</Select.Option>
                        </Select>
                    </div>
                    <div className="filtro-estado-financeiro ml10" id="input-dashboard">
                        <span className='spanFinanceiro'>Tipo Pagamento</span>
                        <Select
                            className="wp100"
                            defaultValue={"TDS"}
                            onChange={(value) => setTipoPagamentoId(value.toString())}>
                            <Select.Option value="TDS">Todos</Select.Option>
                            {listTipoPagamento && listTipoPagamento.map((item) => (
                                <Select.Option key={item.id} value={item.id}>{item.descricao}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="filtro-estado-financeiro ml10" id="input-dashboard">
                        <span className='spanFinanceiro'>Status Cobrança</span>
                        <Select
                            className="wp100"
                            mode='multiple'
                            listHeight={400}
                            onChange={(value) => setStatusCobrancaId(value.toString())}
                            defaultValue={nomeAluno ? TipoStatusCobranca.CODIGO_VENCIDO : statusCobrancaId}>
                            <Select.Option value="TDS">Todos</Select.Option>
                            <Select.Option value="VNC">Vencidos</Select.Option>
                            {listStatusCobranca && listStatusCobranca.map((item) => (
                                <Select.Option key={item.id} value={item.id}>{item.descricao}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="filtros mt10">
                    <div className="input-text wp100">
                        <Input
                            className="wp100"
                            onChange={(e) => setDescricao(e.target.value)}
                            placeholder="Buscar" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />}
                            value={descricao}
                        />
                    </div>
                    <div className="filtro-estado">
                        <span className='spanFinanceiro'>Vencimento</span>
                        <RangePicker style={{ width: '350px', marginLeft: 10 }} value={getPeriodo()} onChange={(values) => { setDataInicio(values && values[0]); setDataFim(values && values[1]); }} format={'DD-MM-YYYY'} />
                    </div>
                </div>
                {<div className={filtro}>
                    <Button onClick={() => setIsOpen(true)} className="button-second">Renegociação</Button>
                </div>}
                <div className="tabela">
                    <div className="titles-users">
                        <div className="item" style={{ width: '18%' }}>
                            <span>NOME DO ALUNO</span>
                            <div className="arrows" onClick={ordenarAluno}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '20%' }}>
                            <span>NOME DO RESPONSÁVEL</span>
                            <div className="arrows" onClick={ordenarResponsavel}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '18%' }}>
                            <span>FINANCEIRO</span>
                            <div className="arrows" onClick={() => ordenar('financeiro')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '10%' }}>
                            <span>TIPO</span>
                            <div className="arrows" onClick={() => ordenar('tipoPagamento')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '8%' }}>
                            <span>VENCIMENTO</span>
                            <div className="arrows" onClick={() => ordenar('dataVencimento')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '8%' }}>
                            <span>VALOR</span>
                            <div className="arrows" onClick={() => ordenar('valorCobranca')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '9%' }}>
                            <span>VALOR FRANQUIA</span>
                            <div className="arrows" onClick={() => ordenar('valorParcelaFranquia')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '9%' }}>
                            <span>DATA PAGAMENTO</span>
                            <div className="arrows" onClick={() => ordenar('dataPagamento')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '10%' }}>
                            <span>STATUS</span>
                            <div className="arrows" onClick={() => ordenar('statusCobranca')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '8%' }}>
                            <span>DIAS VENCIDOS</span>
                            <span style={{ width: '3%' }} />
                        </div>
                    </div>
                    {data && data.content.map((cobranca, index) => (
                        <div key={index} className="registro-user" id={cobrancaSelected && cobrancaSelected.id === cobranca.id ? selected : null}>
                            <div className="user" id="cursos" style={{ width: '20%' }}>
                                <div className="nome" style={{ marginLeft: 10 }}>
                                    <p>{cobranca.financeiro.matriculas[0]?.alunoPessoaNome}</p>
                                    {cobranca?.financeiro?.matriculas[0]?.aluno?.ativo ? (
                                        <div className="estado-user">
                                            <div className="dot-verde"></div>
                                            <p className="ativo">Ativo</p>
                                        </div>
                                    ) : <div className="estado-user">
                                        <div className="dot-orange"></div>
                                        <p className="inativo">Inativo</p>
                                    </div>}
                                </div>
                            </div>
                            <div className="funcao-user" style={{ width: '20%' }}>
                                <p>{cobranca?.financeiro?.responsavelFinanceiro?.pessoa?.nome}</p>
                            </div>
                            <div className="funcao-user" id="alunos" style={{ width: '20%' }}>
                                <p> </p><Tags tipo={`# ${cobranca.financeiro.id} - ${descricaoProduto(cobranca)}`} />
                            </div>
                            <div className="funcao-user" id="edade" style={{ width: '10%' }}>
                                <p>{cobranca?.tipoPagamento?.descricao}</p>
                            </div>
                            <div className="funcao-user" style={{ width: '10%' }}>
                                <p>{formattedDate(cobranca.dataVencimento)}</p>
                            </div>
                            <div className="funcao-user" style={{ width: '10%' }}>
                                <p>{formatMoney(cobranca.valorCobranca)}</p>
                            </div>
                            <div className="funcao-user" style={{ width: '10%' }}>
                                <p>{formatMoney(cobranca.valorParcelaFranquia)}</p>
                            </div>
                            <div className="funcao-user" style={{ width: '8%' }}>
                                <p>{formattedDate(cobranca.dataPagamento)}</p>
                            </div>

                            <DivStatusCobranca
                                codigo={cobranca?.statusCobranca?.codigo}
                                descricao={cobranca?.statusCobranca?.descricao}
                                tooltip={cobranca?.mensagemFalha} />

                            <div className="funcao-user" style={{ width: '4%' }}>
                                {(cobranca?.dataPagamento < cobranca?.dataVencimento) ||
                                    cobranca?.statusCobranca.codigo == 'PGO' ||
                                    cobranca?.statusCobranca.codigo == 'CAN' ||
                                    cobranca?.statusCobranca.codigo == 'IST' ?
                                    <p>{"0"}</p> : <p>{daysBetween(cobranca.dataVencimento)}</p>
                                }
                            </div>
                            {cobranca?.statusCobranca.codigo === 'AGR' || cobranca?.statusCobranca.codigo === 'CAN' || cobranca?.statusCobranca.codigo === 'GRD' || cobranca?.statusCobranca.codigo == 'IST' || cobranca?.statusCobranca.codigo == 'FLH' ||
                                (cobranca?.statusCobranca.codigo === 'PGO' && cobranca?.financeiro.cobrancaUnica == true) ||
                                (cobranca?.statusCobranca.codigo === 'PGO' && (cobranca?.identificadorEnotas !== undefined || cobranca?.identificadorEnotasProduto !== undefined)) ||
                                (cobranca?.statusCobranca.codigo === 'FLH' && (cobranca.tipoPagamento?.codigo === "CRR" || cobranca.tipoPagamento?.codigo === "CRC")) ? (
                                <div className="funcao-user" style={{ width: '3%' }}>
                                    <Dropdown overlay={itensDropdown(cobranca)} placement="bottomRight" arrow>
                                        <Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
                                    </Dropdown>
                                </div>
                            ) : <span style={{ width: '3%' }} />}
                        </div>
                    ))}

                    <div className="paginacao">
                        <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                    </div>
                </div>
            </div>
            <div>
                <ModalFinanceiro
                    open={isOpen}
                    onClose={finalizaNegociacao}
                    cobranca={cobrancaSelected}
                    tela={"financeiro"}
                />
            </div>
            <div>
                <ModalFinanceiroCancelamentoStep2
                    open={isOpen}
                    onClose={finalizaCancelamento}
                    cobranca={cobrancaSelected}
                />
            </div>
            <div>
                <ModalNfe
                    open={isOpenModalNfe}
                    onClose={finalizaModalNfe}
                    cobranca={cobrancaSelected}
                    idEnota={idEnota}
                />
            </div>
        </div>
    );
}
