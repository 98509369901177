/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { Button, Form } from 'antd';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import card from '../../../assets/img/franquias/card.svg';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { EscolaPessoaTipoEnum } from '../../../enum/escola-pessoa-tipo.enum';
import { GrupoPessoaTipoEnum } from '../../../enum/grupo-pessoa-tipo.enum';
import { IEscolaPessoaTipoDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useGrupoService } from '../../../services/grupo.service';
import EscolaFormActionComponent from '../components/escola-form-action-component/escola-form-action.component';
import EscolaFormFecharComponent from '../components/escola-form-fechar-component/escola-form-fechar.component';
import MenuLateralCreateComponent from '../components/menu-lateral-create-component/menu-lateral-create.component';
import '../styles/escola-create-step.styles.scss';
import { PerfilEnum } from '../../../enum/perfil.enum';


export const EscolaCreateStep3Screen: React.FunctionComponent = () => {

  const { escolaParaCadastrar, setEscolaParaCadastrar, escolaPessoaTipos, flRevisao } =
    useEscolaContext();

  const history = useHistory();
  const [form] = Form.useForm();
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const grupoService = useGrupoService();

  useEffect(() => {
    if (!escolaParaCadastrar) {
      window.history.replaceState({}, document.title);
      history.replace('/escolas');
      return;
    }
  }, [])

  const renderResponsavelLegal = (nome, dsLink, dsTitulo) => {

    function disabledFocal() {
      if (usuario.perfil === PerfilEnum.FRANQUIA && dsLink === `/escolas/grupos/create/responsavel/${GrupoPessoaTipoEnum.RESPONSAVEL_NEGOCIACAO}`) {
        return true;
      }
    }

    return (
      <div className="card" style={disabledFocal() ? { background: '#ccc' } : null} >
        <div className="check">
          <Unicons.UilCheck size="20" color="#FFFFFF" />
        </div>
        <h4>{dsTitulo}</h4>
        <div className="dados-box">
          <div className="circle-avatar"></div>
          <div className="dados-responsavel">
            <h4>{nome}</h4>
          </div>
        </div>
        <Link to={dsLink}>
          <Button disabled={disabledFocal()} className="button-line" style={{ width: 310 }}>Alterar</Button>
        </Link>
      </div>
    )
  }

  const handleStep3 = () => {
    history.push('/escolas/create/step-4');
  }

  const getObjeto = (escolaPessoaTipo: IEscolaPessoaTipoDTO) => {
    if (!escolaParaCadastrar) {
      return {
        data: null,
        link: escolaPessoaTipo.codigo,
      }
    }

    const { pessoas } = escolaParaCadastrar;
    let data = null;

    if (pessoas?.length) {
      data = pessoas.find((item) => item.tipo.codigo === escolaPessoaTipo.codigo);

      if (!data) {
        if (escolaPessoaTipo.codigo === EscolaPessoaTipoEnum.FOCAL_FINANCEIRO) {
          data = {
            pessoa: escolaParaCadastrar.focalFinanceiro
          };
        } else if (escolaPessoaTipo.codigo === EscolaPessoaTipoEnum.FOCAL_OPERACIONAL) {
          data = {
            pessoa: escolaParaCadastrar.focalOperacional
          };
        }
      }
    }

    return {
      data,
      link: escolaPessoaTipo.codigo,
    };

  }

  const copiarDoGrupo = (tipo: string) => {
    setIsGlobalLoading(true);

    grupoService.findFullById(escolaParaCadastrar.grupo)
      .then(({ data }) => {

        if (tipo === EscolaPessoaTipoEnum.FOCAL_FINANCEIRO)
          escolaParaCadastrar.focalFinanceiro = data.focalFinanceiro;
        else if (tipo === EscolaPessoaTipoEnum.FOCAL_OPERACIONAL)
          escolaParaCadastrar.focalOperacional = data.focalOperacional;
        else {

          const grupoPessoa = data.pessoas.find(
            (pessoa) =>
              pessoa.tipo.codigo === (
                tipo === EscolaPessoaTipoEnum.RESPONSAVEL_LEGAL ?
                  GrupoPessoaTipoEnum.RESPONSAVEL_LEGAL :
                  (tipo === EscolaPessoaTipoEnum.RESPONSAVEL_NEGOCIACAO ?
                    GrupoPessoaTipoEnum.RESPONSAVEL_NEGOCIACAO :
                    null
                  )
              )
          );

          const escolaPessoa = {
            pessoa: grupoPessoa.pessoa,
            tipo: escolaPessoaTipos.find((tp) => tp.codigo === tipo)
          };

          if (escolaParaCadastrar.pessoas)
            escolaParaCadastrar.pessoas.push(escolaPessoa);
          else
            escolaParaCadastrar.pessoas = [escolaPessoa];

        }
        setEscolaParaCadastrar(escolaParaCadastrar);
      })
      .finally(() => setIsGlobalLoading(false));
  }

  return (
    <div className="container">
      <Header />
      <Progress />
      <MenuLateralCreateComponent current={2} />
      <Form
        form={form}
        name="control-hooks"
        onFinish={handleStep3}
      >
        <div className="franquia" style={{ marginTop: 80 }}>
          <div className="franquia-row">
            <h5>Pessoas</h5>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' }}>
              {escolaPessoaTipos?.map((item, index) => {
                const pessoa = getObjeto(item);

                function disabledFocal() {
                  if (usuario.perfil === PerfilEnum.FRANQUIA && pessoa.link === `RES_NEGOCIACAO`) {
                    return true;
                  }
                }

                return <div key={index} className="box-cards" style={{ flex: '50%' }}>
                  {pessoa?.data?.pessoa?.id ? (
                    renderResponsavelLegal(
                      pessoa?.data?.pessoa?.nome,
                      '/escolas/create/responsavel/' + pessoa?.link,
                      item.descricao)
                  ) : (<div className="card" style={disabledFocal() ? { background: '#ccc' } : null}>
                    <h4>{item.descricao}</h4>
                    <img src={card} alt={"Card"} className="" />
                    {!disabledFocal() ?
                      <>
                        <p>Ops, você ainda não adicionou nenhum usuário aqui</p>
                        <Link to={'/escolas/create/responsavel/' + pessoa?.link}>
                          <div id="criar-responsavel" className="button-primary" style={{ width: 310, marginBottom: 10 }}>
                            <Unicons.UilPlus size="20" color="#FFFFFF" />
                            <span>Adicionar responsável</span>
                          </div>
                        </Link>

                        <Button
                          className="button-line"
                          style={{ width: 310 }}
                          onClick={() => copiarDoGrupo(item.codigo)}>

                          Copiar do grupo
                        </Button>
                      </>
                      : <p>Cadastramento do responsável pela Negociação deve ser feito pela Sede, campo não obrigatório</p>}
                  </div>)}
                </div>
              })}
            </div>
            <EscolaFormActionComponent revisao={flRevisao} rotaAnterior={'/escolas/create/step-2'} />
          </div>
        </div>
      </Form>
      <EscolaFormFecharComponent />
    </div>
  )
}