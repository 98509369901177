import { Button } from 'antd';
import './student-courses.styles.scss';
import useStudentCourses from './use-student-courses.hook';
import CourseCard from '../course-card/course-card.component';

interface StudentCoursesProps {
	fetchAlunoRematricula: () => void;
	handleNovoCursoClick: () => void;
}

export default function StudentCourses({
	fetchAlunoRematricula,
	handleNovoCursoClick,
}: StudentCoursesProps) {
	const { isLoading, coursesData } = useStudentCourses();

	return (
		<div className={'student-basic-info-wrapper'}>
			<div className={'student-basic-info-wrapper__title-buttons'}>
				<h4>Cursos</h4>

				<div className={'title-buttons__buttons-container'}>
					<Button
						className="button-line"
						onClick={() => fetchAlunoRematricula()}
					>
						Rematricular em curso regular
					</Button>
					<Button className="button-second" onClick={handleNovoCursoClick}>
						Matricular em Novo Curso
					</Button>
				</div>
			</div>
			<div className={'student-basic-info-wrapper__courses-section'}>
				{!isLoading &&
					coursesData &&
					coursesData.map((course) => (
						<CourseCard key={course.id} course={course} />
					))}
			</div>
		</div>
	);
}
