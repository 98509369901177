
import { styled } from "@material-ui/core";

interface FaqDataItem {
    imagem: JSX.Element,
    texto: string | JSX.Element,
};

interface Props {
    questao: string,
    resposta: string,
    imagem?: JSX.Element,
    body?: FaqDataItem[]
}

const renderList = (texto) => {
    return (
        <ul>
            <li>{texto}</li>
        </ul>
    );
}

export const faqData: Props[] = [
    {
        questao: "1. Como cadastrar um grupo?",
        resposta: "Todo cadastro deve iniciar pelo grupo pois é nele que será gerado o contrato com as escolas. Ele mantém agrupada todas as escolas pertencentes àquela rede. Exemplo: Temos o grupo Adventista e, dentro dele, diversos contratos de escolas Adventistas de todo o Brasil. Cada escola tem seu cadastro individual, conforme a localização: Americana/SP, Maringá/PR, etc... Para cadastrar um grupo clique em “+ Novo Grupo”",
        body: [
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q04img01.png" />, texto: "Em seguida, preencha todos os dados obrigatórios solicitados pelo sistema. No campo “Responsável pelo suporte” selecione o Hub Educacional que atua com a escola. Ao finalizar, clique em “Próximo”." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q04img02.png" />, texto: "Insira os dados de localização do grupo que está sendo cadastrado" },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q04img03.png" />, texto: null },
        ],
    },
    {
        questao: "2. Como adicionar focais ao grupo?",
        resposta: `As pessoas cadastradas no grupo, são as seguintes:`,
        body: [
            { imagem: null, texto: renderList("• Focal financeiro [obrigatório]: será o responsável pelo financeiro da escola;") },
            { imagem: null, texto: renderList("• Focal operacional [obrigatório]: Em contratos bipartite, é o gestor do Hub Educacional e para contratos tripartites, será o coordenador da escola ou um contato frequente;") },
            { imagem: null, texto: renderList("• Responsável pela negociação [não-obrigatório]: deve ser adicionado pelo Templo Happy;") },
            { imagem: null, texto: renderList("• Responsável legal do grupo [obrigatório]: é o proprietário ou diretor da escola. Para adicionar as pessoas, clique em “+ adicionar responsável” e na sequência, você pode adicionar um usuário existente, caso ele já possua cadastro no sistema, ou criar um novo usuário.") },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q04img04.png" />, texto: "Ao terminar de adicionar todos os responsáveis pelo grupo, clique em próximo e faça a “Revisão de Dados” e clique no botão “Salvar Grupo”." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q04img06.png" />, texto: "Dentro de um grupo, podem ser adicionas várias escolas. Veja como cadastrar uma escola no tópico a seguir." },
        ],
    },
    {
        questao: "3. Como cadastrar uma escola?",
        resposta: `Para cadastrar uma escola, passe o mouse sobre o ícone de escolas no sistema Happy Space, e clique em “Escolas”.`,
        body: [
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q05img01.png" />, texto: "Na sequência, clique em “+ Nova Escola”, para iniciar o cadastro." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q05img02.png" />, texto: "Preencha os dados obrigatórios solicitados pelo sistema e selecione o grupo correspondente a essa escola" },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q05img03.png" />, texto: "Preencha os dados de localização e clique em “Próximo”." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q05img04.png" />, texto: "Adicione as pessoas responsáveis em suas devidas funções. Nesse momento é possível copiar as pessoas que foram adicionadas no grupo, caso sejam as mesmas, clicando no botão “Copiar do grupo”." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q05img05.png" />, texto: "Ao adicionar todos os responsáveis, clique em próximo. Faça a revisão dos dados e clique no botão “Salvar Escola”." },
        ],
    },
    {
        questao: "4. Como gerar o link de pagamento referente ao contrato com a escola?",
        resposta: `Na tela financeiro (semelhante à visualização de Hub que você já está acostumado(a), você pode utilizar os filtros de data e status ou até mesmo filtrar pelo nome do contrato e gerar a segunda via da cobrança para pagamento.`,
        body: [
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q06img01.png" />, texto: "Há o filtro de busca, onde pode ser pesquisado o nome do grupo desejado para visualização do plano financeiro, data de vencimento, valor total, valor do split e status." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q06img02.png" />, texto: "Para gerar um novo boleto ou PIX, clique no ícone “...”, no lado direito da tela e selecione a opção “Pagar/emitir 2ª via”. Logo após, será possível visualizar a tela de checkout." },
        ],
    },
    {
        questao: "5. Como cadastrar um lead?",
        resposta: `Para cadastrar um novo lead no sistema de empresas, basta acessar a aba CRM do módulo B2B, e clicar no botão “Novo Lead”.`,
        body: [
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q07img01.png" />, texto: "Preencha todas as informações obrigatórias e clique em “Avançar”. Por fim, caso tenha um preço proposto de licença, informe os valores nos campos indicados e clique no botão “Concluir”." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q07img02.png" />, texto: "Confirme, para finalizar o cadastro do lead." },
        ],
    },
    {
        questao: "6. Acompanhamento do lead",
        resposta: `Para fazer o acompanhamento do seu lead, existem 07 estágios no CRM:`,
        body: [
            { imagem: null, texto: renderList("Cadastro: lead incluído no sistema;") },
            { imagem: null, texto: renderList("Contato sem retorno: contato realizado, porém sem retorno do lead;") },
            { imagem: null, texto: renderList("Follow up: etapa em que se recebe o retorno do lead;") },
            { imagem: null, texto: renderList("Agendamento: agendamento de reunião com o lead;") },
            { imagem: null, texto: renderList("Reunião: quando a reunião já foi realizada;") },
            { imagem: null, texto: renderList("Proposta: envio da proposta para o lead Contrato: proposta aceita e envio de contrato;") },
            { imagem: null, texto: renderList("Contrato: proposta aceita e envio de contrato.") },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q07img04.png" />, texto: "Ao clicar no lead já cadastrado, é possível ver o histórico de ações realizadas no cadastro, e para atualizar o atendimento, adicionar anotações e agendar um retorno, basta clicar no ícone de “documento”, conforme indicado na imagem." },
            { imagem: null, texto: "Ao adicionar as anotações desejadas, clique em “Salvar” para atualizar o histórico e status de atendimento. Para realizar a alteração destágio, clique no lead em que deseja fazer a alteração, vá na aba “informações do lead” e escolha o estágio em que o lead se encontra." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q07img05.png" />, texto: "Ao clicar no lead já cadastrado, é possível ver o histórico de ações realizadas no cadastro, e para atualizar o atendimento, adicionar anotações e agendar um retorno, basta clicar no ícone de “documento”, conforme indicado na imagem." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q07img06.png" />, texto: "Ao clicar no lead já cadastrado, é possível ver o histórico de ações realizadas no cadastro, e para atualizar o atendimento, adicionar anotações e agendar um retorno, basta clicar no ícone de “documento”, conforme indicado na imagem." },
        ],
    },
    {
        questao: "7. Como acessar os relatórios de escolas?",
        resposta: `Para acessar os relatórios, clique no ícone correspondente conforme a imagem abaixo e escolha qual relatório deseja ter acesso.`,
        body: [
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q08img01.png" />, texto: "O relatório financeiro apresenta a visão do financeiro do grupo por parcela. O usuário pode acompanhar as cobranças pagas, canceladas, a gerar, geradas, vencidas, entre outros status de pagamento. Além disso, contém KPI’s de totais de licença intra curricular, totais de licença extra curricular, totais de licença de serviço, total de split sede e receita total." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q08img02.png" />, texto: null },
        ],
    },
    {
        questao: "8. Gestão de contratos",
        resposta: `A inclusão e alteração de contratos é feita exclusivamente pelo Templo Happy. Para visualizar os detalhes de um contrato existente, encontre o grupo desejado, clique no ícone " ... " e selecione a opção “gestão de contratos".`,
        body: [
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q09img01.png" />, texto: "clique no ícone de expandir e selecione o botão “Ver mais detalhes.”" },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q09img02.png" />, texto: "Será possível visualizar a página “Revisão de Dados”, com todas as informações do contrato e o plano financeiro, com todos os custos e valores das parcelas." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q09img03.png" style={{ width: "70%", marginBottom: 10, marginTop: 20 }} />, texto: "Chegando ao fim da página no campo “Anexos”, clicando no ícone “Fazer Upload”, será possível anexar arquivos ou fazer o download dos arquivos anexados, não sendo possível excluí-los. A função de excluir, é exclusiva do Templo Happy." },
            { imagem: <img src="https://happycodefiles.blob.core.windows.net/faq-b2b/Q09img04.png" />, texto: null },
        ],
    },
    {
        questao: "9. O que é o “Responsável pelo Suporte”?",
        resposta: `É onde será definido se o contrato firmado com a escola será direto ou indireto. Por padrão, na criação da escola e grupo, o seu Hub Educacional já vem pré-selecionado, gerando um contrato indireto.`,
    },
    {
        questao: "10. O que são contratos diretos e indiretos?",
        resposta: `Escolas diretas são contratos realizados entre a escola e a Sede (franqueadora).
        Escolas indiretas são contratos realizados entre a escola e o Hub Educacional.
        Quando o cadastro é realizado pela escola, os contratos são sempre indiretos.`,
    },
    {
        questao: "11. O que é um contrato bipartite?",
        resposta: `O contrato bipartite é firmado entre a escola e o Hub Educacional, sem vínculo direto com a Sede (franqueadora).`,
    },
    {
        questao: "12. O que é um contrato tripartite?",
        resposta: `O contrato tripartite é firmado entre a escola, o Hub Educacional e o envolvimento da Sede (franqueadora).`,
    }
];