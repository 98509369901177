import { IMetaTipoCleanDTO, IMetaTipoDTO, IMetaTipoFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useMetaTipoService = () => {
	const resourceURL = '/meta-tipo';

	return {
		...baseFind<IMetaTipoCleanDTO, IMetaTipoDTO, IMetaTipoFullDTO>(resourceURL),
		...baseFindList<IMetaTipoCleanDTO, IMetaTipoDTO, IMetaTipoFullDTO>(resourceURL),
		...baseFindById<IMetaTipoCleanDTO, IMetaTipoDTO, IMetaTipoFullDTO>(resourceURL),
		...baseCreate<IMetaTipoCleanDTO, IMetaTipoDTO, IMetaTipoFullDTO>(resourceURL),
        ...baseUpdate<IMetaTipoCleanDTO, IMetaTipoDTO, IMetaTipoFullDTO>(resourceURL),
	};
};
