import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';

import { ILogCleanDTO, IMetaTipoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMetaTipoService } from '../../services/meta-tipo.service';
import FiltrosMeta from './filtros-meta';
import ItemListMetaTipo from './item-lista';
import { useLogService } from '../../services/log.service';

export const MetasTipo: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const [data, setData] = useState<PageableResponse<IMetaTipoDTO>>();
    const [predicate] = useState<Predicate>(new Predicate());
    const metaTipoService = useMetaTipoService();
    const history = useHistory();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso metas tipo",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const findMetaTipo = () => {
        setIsGlobalLoading(true);
        return metaTipoService.find(predicate)
            .then(({ data }) => {
                setData(data);
            }).finally(() => setIsGlobalLoading(false));
    }

    const goToAdd = () => {
        history.push("/metas-tipo/create");
    }

    const ordenar = (campo) => {
        predicate.addSort(campo);
        findMetaTipo();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        findMetaTipo();
    }

    return (
        <div className="container">
            <NewMenu selecionado={27} />
            <div className="dashboard">
                <div className="box-title">
                    <h3 className="title">Metas ({data?.totalElements})</h3>
                    <Button className="button-primary" id="criar-curso" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Tipo da Meta</Button>
                </div>
                <FiltrosMeta findMetaTipo={findMetaTipo} predicate={predicate} />
                <div className="tabela">
                    <div className="titles-users">
                        <div className="item" style={{ width: '30%' }}>
                            <span>NOME</span>
                            <div className="arrows" onClick={() => ordenar('nome')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '30%' }}>
                            <span>Tipo</span>
                            <div className="arrows" onClick={() => ordenar('metaTipoValor')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '20%' }}></div>
                        <div className="item" style={{ width: '20%' }} />
                    </div>
                    <div className="tabela-body">
                        {data && data.content.map((meta, index) => (
                            <ItemListMetaTipo meta={meta} key={index} />
                        ))}
                    </div>
                    <div className="paginacao">
                        <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                    </div>
                </div>
            </div>
        </div>
    );
}
