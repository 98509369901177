import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

export default function Main(): JSX.Element {
  return (
    <StrictMode>
      <App />
    </StrictMode>
  );
}

ReactDOM.render(<Main />, document.getElementById('root'));
