import { ReactElement } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { TagsFinanceiro } from './TagsFinanceiro';
import { IRenegociacaoCleanDTO } from '../models/happy-code-api.model';

interface Props {
  codigo: string;
  descricao: string;
  tooltip: string;
  width?: string;
  renegociacaoOriginada?: IRenegociacaoCleanDTO;
  observacao?: string;
}

function DivStatusCobranca({ codigo, descricao, tooltip, width, renegociacaoOriginada, observacao }: Props): ReactElement {
    
  const DivTooltip = (tooltip: string) => {

    if (codigo === "FLH")
      if (!tooltip)
        tooltip = "Erro desconhecido, favor contatar suporte técnico!";

    return (<TagsFinanceiro codigo={codigo} descricao={descricao} tooltip={tooltip} renegociacaoOriginada={renegociacaoOriginada} observacao={observacao} />);
    
	}

  if (!width)
    width = "13%";

  return (<div className="funcao-user" style={{ width: width, flexDirection: 'column' }}>{
    tooltip ? DivTooltip(tooltip) : <TagsFinanceiro codigo={codigo} descricao={descricao} renegociacaoOriginada={renegociacaoOriginada} observacao={observacao}/>
  }</div>)

}

export default DivStatusCobranca
