import { Button } from 'antd';
import { useCallback, useState } from 'react';
import ModalCreate from '../../../../components/ModalCreate/index';
import { useEscolaContext } from '../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { useUnidadeContext } from '../../../../context/UnidadeContext';
import { useEscolaService } from '../../../../services/escola.service';
import { useUnidadeService } from '../../../../services/unidade.service';
import './menu-revisao-escola.component.style.scss';

interface Props {
  qtdDocumentoPendente: number;
}

export const MenuRevisaoEscolaComponent: React.FunctionComponent<Props> = ({ qtdDocumentoPendente }: Props) => {
  const { escolaParaCadastrar, getCategoriaQuantidadeAlunosById, grupoPessoaTipos, limparEscolaParaCadastrar, setFlRevisao } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);

  const unidadeService = useUnidadeService();
  const escolaService = useEscolaService();
  const { unidade, revisao } = useUnidadeContext();

  const saveEscola = () => {

    setIsGlobalLoading(true);

    if (escolaParaCadastrar.unidade && escolaParaCadastrar.unidade?.id === -1) {
      escolaParaCadastrar.unidade = null;
    }

    if (escolaParaCadastrar.id) {
      // (escolaParaCadastrar.endereco as any).dataCriacao = new Date();
      return escolaService.patchFull(escolaParaCadastrar).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setIsOpen(true);
          limparEscolaParaCadastrar();
        }
      }).finally(() => {
        setIsGlobalLoading(false);
      });
    }

    escolaService.createFull(escolaParaCadastrar).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        setIsOpen(true);
        limparEscolaParaCadastrar();
      }
    }).finally(() => {
      setIsGlobalLoading(false);
    });

  }

  const onClickLink2 = useCallback(() => setFlRevisao(false), []);


  return (
    <div id="revisao-grupo">
      <div className="menu-box-revisao-float">
        <div className="bloco-1">
          <h4>Nome da Escola</h4>
          <h1>{escolaParaCadastrar?.nomeFantasia}</h1>
          <h6>CNPJ</h6>
          <p>{escolaParaCadastrar?.cnpj}</p>
          <h6>Quant. aluno</h6>
          <p>{getCategoriaQuantidadeAlunosById(escolaParaCadastrar?.categoriaQuantidadeAlunos?.id)?.descricao}</p>
          {/* <h6>Razão Social</h6> */}
          {/* <h1>{escolaParaCadastrar?.}</h1> */}
          <Button id="salvar" style={{ width: 250, cursor: 'pointer', }} className="button-primary" onClick={() => saveEscola()}>
            Salvar Escola
          </Button>
        </div>
      </div>
      <ModalCreate
        //onClose={() => setIsOpen(false)} 
        title={`Escola salva com sucesso!`}
        textButton1='Voltar a página inicial'
        textButton2='Criar outra escola'
        replaceLink2={true}
        replaceLink1={true}
        link1={'/escolas'}
        link2={'/escolas/create/step-1'}
        open={isOpen}
        styleButton1="button-line"
        styleButton2="button-primary"
        onClickLink2={onClickLink2}
      />
    </div>
  );
}