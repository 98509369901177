import { Button, Form, InputNumber, Select } from 'antd';
import Modal from '../../../../../components/Modal';
import { currencyFormatter, currencyParser } from '../../../../../util/format';
import '../../../shared.scss';
import BotoesCadastroComponent from '../../BotoesCadastro/botoes-cadastro.component';
import StepCadastroComponent from '../../StepCadastro/step-cadastro.component';
import './novo-lead-step-5.scss';
import useNovoLeadStep5ComponentState from './novo-lead-step-5.state';

interface Props {
  onClose?: () => void;
  onNext?: () => void;
  onFecharModal?: () => void;
}

export const NovoLeadStep5 = (props: Props) => {

  const { state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
    isModalConfirmacao,
    setIsModalConfirmacao,
    leadOrigens,
    leadCampanhas,
    leadEstagios,
    setDtProximoRetorno,
    dtProximoRetorno,
    setAvaliacaoLead,
    avaliacaoLead,
    intra,
    setIntra,
    extra,
    setExtra,
    onCadastrarLead
  } = useNovoLeadStep5ComponentState();

  const [form] = Form.useForm();

  const { Option } = Select;

  const setCampo = (dsCampo, valor) => {
    const field = {
      [dsCampo]: valor,
    }
    form.setFieldsValue(field);
  }


  return (
    <>
      <div id="novo-lead-step-1" className="flex flex-row justify-center align-center modal-container modalBox">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onFecharModal} className="button-x">x</Button>
          <div className='container-titulo'>
            <h1>Cadastrar Lead</h1>
          </div>
          <h3>Preço proposto por licença</h3>
          <Form
            style={{ minWidth: '100%' }}
            name="control-hooks"
            initialValues={leadParaCadastrar}
            form={form}
            onFinish={onFinish}
          >

            <div className="col">
              <div className="flex width-100">
                <div className="input-franquia ">
                  <span>Valor Licença Intra</span>

                  <Form.Item
                  initialValue={leadParaCadastrar?.valorLicencaIntra}
                  name="precoPropostoIntra">

                    <InputNumber
                      placeholder={'Intra'}
                      //formatter={value => `${value}%`}
                      //parser={value => parseFloat(value.replace('%', ''))}
                      // value={intra}
                      min={0.00}
                      onChange={setIntra}
                      formatter={currencyFormatter}
                      style={{ padding: 10, width: '100%' }}
                      className='inputNumberIntraExtra'
                      parser={currencyParser}
                      decimalSeparator=','
                    />

                  </Form.Item>
                </div>
              </div>
              <div className="horizontal-separator" />
            </div>

            <div className="col">
              <div className="flex width-100">
                <div className="input-franquia">
                  <span>Valor Licença Extra</span>

                  <Form.Item
                    name="precoPropostoExtra"
                    initialValue={leadParaCadastrar?.valorLicencaExtra}
                  >

                    <InputNumber
                      placeholder={'Extra'}
                      //formatter={value => `${value}%`}
                      //parser={value => parseFloat(value.replace('%', ''))}
                      value={extra}
                      min={0.00}
                      style={{ padding: 10 }}
                      onChange={setExtra}
                      decimalSeparator=','
                      formatter={currencyFormatter}
                      parser={currencyParser}
                      className='inputNumberIntraExtra'
                    />

                  </Form.Item>
                </div>
              </div>
            </div>

            <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
            </div>
            <div className="vertical-separator" />
            <StepCadastroComponent />
            <BotoesCadastroComponent />
          </Form>
        </div>

        <Modal
        open={isModalConfirmacao}
        onClose={() => setIsModalConfirmacao(false)}
        title="Você deseja cadastrar o Lead?"
        subtitle=''
        textButton2="Sim"
        textButton1="Não, Cancelar"
        styleButton2="button-second"
        styleButton1="button-line-red"
        button2={onCadastrarLead}/>

      </div >
    </>
  )
}
