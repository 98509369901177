/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { ICursoFullDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsCursoContext {
	curso: ICursoFullDTO;
	revisao: boolean;
	setCurso: React.Dispatch<React.SetStateAction<ICursoFullDTO>>;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	removeCurso: React.DispatchWithoutAction;
}

//Valor default do contexto
const DEFAULT_VALUE: PropsCursoContext = {
	curso: {
		id: null,
		codigo: '',
		descricao: '',
		duracao: 0,
		duracaoMinutos: 0,
		numeroMaxParcelas: 0,
		numeroMinParcelas: 0,
		valorDescontoMaximo: 0,
		valorDescontoMinimo: 0,
		valorMensalidadeMinimo: 0,
		valorSplitMatricula: 0,
		taxaMatricula: false,
		valorSplitTaxaMatricula: 0,
		numeroProrrogacaoDiasMatricula: 0,
		valorMensalidadeMinimoRematricula: 0,
		valorSplitRematricula: 0,
		numeroParcelasMaximoRematricula: 0,
		taxaRematricula: false,
		valorSplitTaxaRematricula: 0,
		numeroProrrogacaoDiasRematricula: 0,
		ativo: true,
		nivelAcesso: null,
		negocio: {
			id: null,
			codigo: null,
			descricao: null,
			cor: null,
			nomeCurto: null,
			horaSemanal: null,
			valorHora: null,
			valorHoraRematricula: null,
			flTaxaMatricula: null,
			valorSplitTaxa: null,
			flTaxaRematricula: null,
			valorSplitTaxaRematricula: null,
			valorOferta: null,
			valorOfertaRematricula: null,
			valorMinimo: null,
			valorMinimoRematricula: null,
			prorrogacaoDiasMatricula: null,
			prorrogacaoDiasRematricula: null,
			numeroParcelasMaximo: null,
			numeroParcelasMaximoRematricula: null,
			valorSugerido: null,
			valorSugeridoCapital: null,
			ativo: true,
			transferencia: false,
		},
		tipoCurso: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		modalidade: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: false,
		},
		situacaoCurso: {
			id: 1,
			codigo: '',
			descricao: '',
			ativo: false,
		},
		cursoEscolaFranquiaCustomDTO: {
			id: null,
			cursoId: null,
			escolaId: null,
			unidadeId: null,
		},
		cursoRegiaoPrecificacaos: [],
		ofertas: [],
		turmas: [],
		cursoClusters: [],
		cursoPaises: [],
		cursoUnidades: [],
		cursoPlanoPagamentos: [],
		cursoAulaPadraos: [],
	},
	revisao: false,
	setCurso: () => {}, //função de inicialização
	setRevisao: () => {}, //função de inicialização
	removeCurso: () => {}, //função de inicialização
};

//criando nosso contexto UserContext
const CursoContext = createContext<PropsCursoContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/curso';
const nameStorageRevisao = '@HappyCode/curso/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const CursoContextProvider: React.FC = ({ children }: any) => {
	const [curso, setCursoGlobal] = useState<ICursoFullDTO>(DEFAULT_VALUE.curso);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

	const setCurso = (curso) => {
		setCursoGlobal(curso);
		setObject(nameStorage, curso);
	};

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const removeCurso = () => {
		setRevisao(false);
		setCursoGlobal(DEFAULT_VALUE.curso);
		removeKey(nameStorage);
	};

	useEffect(() => {
		const curso = getObject(nameStorage);
		if (curso) {
			setCursoGlobal(curso);
		}
		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}
	}, []);

	return (
		<CursoContext.Provider
			value={{
				curso,
				revisao,
				setCurso,
				setRevisao,
				removeCurso,
			}}
		>
			{children}
		</CursoContext.Provider>
	);
};

export const useCursoContext = (): PropsCursoContext => {
	const context = useContext(CursoContext);
	if (!context)
		throw new Error(
			'useCursoContext must be used within a CursoContextProvider'
		);
	return context;
};
