import { Form } from "antd";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../../../context/GlobalContext";
import { useLeadEscolaAnotacaoService } from "../../../../../../services/lead-escola-anotacao.service";
import { useNotificationService } from "../../../../../../services/notification.service";
import { useCrmEscolaContext } from "../../../../context/crm-escola.context";

const useNoteState = (props) => {
    const leadAnotacaoService = useLeadEscolaAnotacaoService();
    const notification = useNotificationService();
    const { refresh } = useCrmEscolaContext();
    const { open, onClose } = props;
    const [lead, setLead] = useState(props.lead);
    const [form] = Form.useForm();
    const [value, setValue] = useState(1);
    const [validationFields, setValidationFields] = useState<boolean>(true);
    const { setIsGlobalLoading } = useGlobalContext();

    useEffect(() => {
        setLead({ ...props.lead });
    }, [props.lead]);

    const clearValidationFields = () => {
        setValidationFields(true)
    }

    const onChange = (e: any) => {
        setValue(e.target.value)
    };

    useEffect(() => {
        onChange;
    }, [props.lead])

    const post = () => {
        const body = {
            ...form.getFieldsValue(),
            lead: props.lead,
        };

        setIsGlobalLoading(true);

        leadAnotacaoService.create(body).then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return refresh().finally(() => {
                    onClose();
                    form.resetFields();
                    clearValidationFields();
                    return notification({ description: 'Anotação realizada!', type: 'success', message: 'Sucesso' });
                });
            }
            return notification({ description: 'Falha ao adicionar anotação!', type: 'error', message: 'Erro' });
        }).finally(() => {
            setIsGlobalLoading(false);
        });
    }

    const onSalvar = () => {
        form
            .validateFields()
            .then(() => {
                return post();
            })
            .catch((errors) => {
                notification({ description: 'Por favor, preencha todos os campos', type: 'warning', message: 'Aviso' });
            });
    }

    const clearFields = () => {
        form.resetFields();
        clearValidationFields();
    }

    return {
        open,
        onClose,
        onChange,
        value,
        setValue,
        onSalvar,
        form,
        clearFields,
        setValidationFields,
        validationFields,
    }
}

export default useNoteState;