import { Checkbox } from "antd";
import { useState } from "react";
import '../../shared.scss';
import './style.scss';

interface Props {
  tipo?: string;
  onChangeFiltro?: (string, boolean) => void;
}

export const Checkboxtag = (props: Props) => {

  const { tipo, onChangeFiltro } = props;
  const [isCheck, setIsCheck] = useState(`check-${tipo}`);
  const [isStyle, setIsStyle] = useState(`tag-${tipo}`);

  function onChange(e: any) {
    if (e.target.checked === true) {
      setIsStyle(`tag-${tipo}`);
      setIsCheck(`check-${tipo}`);
    } else {
      setIsStyle('tag-check');
      setIsCheck(`check-${tipo}`);
    }

    onChangeFiltro(tipo, e.target.checked);

  }


  if (tipo === 'atrasado') {
    return (
      <>
        <div className={`${isStyle} flex flex-row justify-between align-center`}>
          <Checkbox defaultChecked={true} className={isCheck} onChange={onChange} /><p>Retorno Atrasado</p>
        </div>
      </>
    )
  }
  if (tipo === 'hoje') {
    return (
      <>
        <div className={`${isStyle} flex flex-row justify-between align-center`}>
          <Checkbox defaultChecked={true} className={isCheck} onChange={onChange} /><p>Retorno para hoje</p>
        </div>
      </>
    )
  }

  if (tipo === 'futuro') {
    return (
      <>
        <div className={`${isStyle} flex flex-row justify-between align-center`}>
          <Checkbox defaultChecked={true} className={isCheck} onChange={onChange} /><p>Retorno Futuro</p>
        </div>
      </>
    )
  }

  if (tipo === 'descartado') {
    return (
      <>
        <div className={`${isStyle} flex flex-row justify-between align-center`}>
          <Checkbox defaultChecked={false} className={isCheck} onChange={onChange} /><p>Descartados</p>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={`${isStyle} flex flex-row justify-between align-center`}>
        <Checkbox defaultChecked={true} className={isCheck} onChange={onChange} /><p>Sem Retorno</p>
      </div>
    </>
  )

}
