import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { IModalidadeCleanDTO } from '../../models/happy-code-api.model';
import { useModalidadeService } from '../../services/modalidade.service';
import StepsCreate from './steps-create';
import './style.css';

export const CursosCreateStep2: React.FunctionComponent = () => {

  const { setIsGlobalLoading } = useGlobalContext();
  const { curso, setCurso, revisao } = useCursoContext();

  const [isOpen, setIsOpen] = useState(false);
  const [modalidades, setModalidades] = useState<IModalidadeCleanDTO[]>([])

  const modalidadeService = useModalidadeService();

  useEffect(() => {
    const findModalidade = () => {
      setIsGlobalLoading(true);
      modalidadeService.findCleanList()
        .then(({ data }) => setModalidades(data))
        .finally(() => setIsGlobalLoading(false));
    }
    findModalidade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={40} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={1} />
      <div className="franquia">
        <div className="user-row">
          <h3 id="taxas-h3">Qual será o modo de aplicação?</h3>
          {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}


          {/* <div className="boxes">
						{funcoesList &&
							funcoesList.map((funcoes) => {
								return (
									<button
										key={funcoes.id}
										className={selected === funcoes.id ? 'box-selected' : 'box'}
										onClick={() => handleRoleSelected(funcoes)}
									>
										<Unicons.UilHouseUser
											size="20"
											color={selected === funcoes.id ? '#FFF' : '#895CF2'}
										/>
										<h4>{funcoes.codigo}</h4>
										<p>{funcoes.descricao}</p>
									</button>
								);
							})}
					</div>
           */}
          <div id="box-aplicacao">
            {modalidades.map((modalidade) =>
              <div key={modalidade.id} id={curso.modalidade.id === modalidade.id ? 'box-selected' : 'box'} style={{ width: '48%' }}
                onClick={() => setCurso({ ...curso, modalidade })}>
                {modalidade.codigo == 'OLN' ?
                  <Unicons.UilDesktop size="20" color={curso.modalidade.id === modalidade.id ? '#FFF' : 'var(--primary-color)'} />
                  : modalidade.codigo == 'HBD' ?
                    <Unicons.UilDirection size="20" color={curso.modalidade.id === modalidade.id ? '#FFF' : 'var(--primary-color)'} />
                    : modalidade.codigo == 'PSC' ?
                      <Unicons.UilUsersAlt size="20" color={curso.modalidade.id === modalidade.id ? '#FFF' : 'var(--primary-color)'} />
                      : <Unicons.UilTrees size="20" color={curso.modalidade.id === modalidade.id ? '#FFF' : 'var(--primary-color)'} />
                }
                <p id={"modo-aplicacao-" + modalidade.id}>{modalidade.descricao}</p>
              </div>
            )}
            {/* <div id="box" style={{ width: '48%' }}>
              <Unicons.UilDirection size="20" color="#895CF2" />
              <p>Híbrido</p>
            </div>
          </div>
          <div id="box-aplicacao">
            <div id="box" style={{ width: '48%' }}>
              <Unicons.UilTrees size="20" color="#895CF2" />
              <p>Externo</p>
            </div>
            <div id="box" style={{ width: '48%' }}>
              <Unicons.UilUsersAlt size="20" color="#895CF2" />
              <p>Presencial</p>
            </div> */}
          </div>
          <div className="botoes">
            {revisao ?
              <Link to="/cursos/create/step-6">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/cursos/create/step-1">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/cursos/create/step-3">
                  <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação deste curso?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/cursos'
      />
    </div>
  )

}