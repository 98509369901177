import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { isBefore } from 'date-fns';
import { useState } from 'react';
import ModalCreate from '../../components/ModalCreate/index';
import { useCampanhaContext } from '../../context/CampanhaContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCampanhaService } from '../../services/campanha.service';
import { formattedDate, isDateBetween } from '../../util/format';
import './style.css';

interface Props {
  id: number;
}

const MenuDetalheCampanha: React.FunctionComponent<Props> = ({ id }: Props) => {

  const { campanha, removeCampanha } = useCampanhaContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [isOpen, setIsOpen] = useState(false);
  const service = useCampanhaService();

  const save = () => {
    setIsGlobalLoading(true)
    if (id || campanha.id) {
      service.patchFull(campanha)
        .then(() => {
          removeCampanha();
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    } else {
      service.createFull(campanha)
        .then(() => {
          removeCampanha();
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    }
  }

  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1">
        <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
        <h1 id="title">Nome da campanha</h1>
        <span style={{ fontSize: 18 }}>{campanha.codigo}</span>
        {isDateBetween(campanha.dataInicio, campanha.dataFim) ?
          <div id="standy-campanha">
            <div id="dot-verde-campanhas"></div>
            <p className="ativo">Em vigência</p>
          </div> :
          isBefore(campanha.dataInicio, new Date()) &&
          <div id="standy-campanha">
            <div id="dot-orange-campanhas"></div>
            <p className="ativo">A Iniciar</p>
          </div>
        }
        <h6 style={{ marginBottom: 0 }}>Descrição</h6>
        <p>{campanha.descricao}</p>
        <h6 style={{ marginBottom: 0 }}>Periodo</h6>
        <p>{`${formattedDate(campanha.dataInicio)} - ${formattedDate(campanha.dataFim)}`}</p>
        {/* <Button className="button-line" icon={<EditOutlined rev={undefined} />}>Editar</Button> */}
        <Button id="salvar" style={{ width: 250 }} className="button-primary" onClick={() => save()}>Salvar Alterações</Button>
      </div>
      <ModalCreate
        open={isOpen}
        //onClose={() => setIsOpen(false)}
        title="Campanha salva com sucesso!"
        textButton1="Criar outra campanha"
        textButton2="Voltar a página inicial"
        styleButton1="button-line"
        styleButton2="button-primary"
        link1="/campanhas/create/step-1"
        link2="/campanhas"
      />
    </div>
  );
}

export default MenuDetalheCampanha;