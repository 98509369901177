import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Pagination } from '@mui/material';
import { Button, Form, Input, Select } from 'antd';
import { ChangeEvent } from 'react';
import NewMenu from '../../../components/NewMenu';
import ItemTabelaEscolaComponent from '../components/item-tabela-escola-component/item-tabela-escola.component';
import useEscolaScreenState from '../states/use-escola.screen.state';
import '../styles/escola.screen.styles.scss';
import useFindStates from '../../../globalHooks/use-find-states.hook';
import { FilterDefaultValue } from '../../../enum/filter-default-value.enum';
import useFindSchools from '../states/use-find-schools.hook';
import useFindSchoolStatus from '../states/use-find-school-status.hook';
import { SortingEnum } from '../../../enum/sorting.enum';

const EscolaScreen = () => {
	const { onCriarGrupoPress } = useEscolaScreenState();

	const {
		schoolData,
		findSchoolData,
		setSearchbarValue,
		onFilterStateChange,
		onFilterSchoolStatusChange,
		onSortSchoolNameChange,
		onPageChange,
	} = useFindSchools();

	const { schoolStatusList } = useFindSchoolStatus();

	const renderTitulo = () => {
		return (
			<div className="box-title">
				<h3 className="title">Escolas ({schoolData?.totalElements})</h3>
				<Button
					id="criar-grupo"
					className="button-primary"
					onClick={onCriarGrupoPress}
					icon={<PlusOutlined rev={undefined} />}
				>
					Nova Escola
				</Button>
			</div>
		);
	};

	const { listOfStates } = useFindStates();

	const renderFiltros = () => {
		return (
			<form className="filtros">
				<Form.Item
					label="Buscar Escolas"
					labelAlign="left"
					className="d-flex-col-start w-100"
				>
					<Input
						id="buscar"
						prefix={
							<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
						}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setSearchbarValue(event.target.value)
						}
						className="searchbar-input"
					/>
				</Form.Item>
				<Form.Item labelAlign="left" label="Estado">
					<Select
						className="filtro-estado"
						defaultValue={FilterDefaultValue.DEFAULT}
						onChange={onFilterStateChange}
					>
						<Select.Option value={FilterDefaultValue.DEFAULT}>
							Todos
						</Select.Option>
						{listOfStates &&
							listOfStates.map(({ id, codigo, nome }) => (
								<Select.Option key={id} value={codigo}>
									{nome}
								</Select.Option>
							))}
					</Select>
				</Form.Item>

				<Form.Item labelAlign="left" label="Status">
					<Select
						className="filtro-estado"
						defaultValue={FilterDefaultValue.DEFAULT}
						onChange={onFilterSchoolStatusChange}
					>
						<Select.Option value={FilterDefaultValue.DEFAULT}>
							Todos
						</Select.Option>
						{schoolStatusList &&
							schoolStatusList.map(({ id, descricao }) => (
								<Select.Option key={id} value={id}>
									{descricao}
								</Select.Option>
							))}
					</Select>
				</Form.Item>

				<Form.Item labelAlign="left" label="Ordem Alfabética">
					<Select
						className="filtro-estado"
						defaultValue={SortingEnum.ASC}
						onChange={onSortSchoolNameChange}
					>
						<Select.Option value={SortingEnum.ASC}>Crescente</Select.Option>
						<Select.Option value={SortingEnum.DESC}>Decrescente</Select.Option>
					</Select>
				</Form.Item>
			</form>
		);
	};

	const renderTabela = () => {
		return (
			<div className="tabela">
				<div className="titles">
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>NOME DO GRUPO</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>ESTADO</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>CIDADE</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>RESPONSÁVEL SUPORTE</span>
						<div className="arrows">
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>FOCAL OPERACIONAL</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>AÇÕES</span>
						<div className="arrows">
						</div>
					</div>
				</div>
				<div className="tabela-body">
					{schoolData &&
						schoolData.content.map((escola, index) => (
							<ItemTabelaEscolaComponent
								escola={escola}
								key={index}
								buscarDados={findSchoolData}
							/>
						))}
				</div>
			</div>
		);
	};

	return (
		<div id="escola-grupos" className="container">
			<NewMenu selecionado={22} />
			<div
				className="dashboard"
				style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}
			>
				{renderTitulo()}
				{renderFiltros()}
				{renderTabela()}
				<div className="paginacao">
					<Pagination
						onChange={(event, page) => onPageChange(event, page - 1)}
						page={schoolData ? schoolData.pageable.pageNumber + 1 : 0}
						count={schoolData ? schoolData.totalPages : 0}
					/>
				</div>
			</div>
		</div>
	);
};

export default EscolaScreen;
