import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Progress } from 'antd';
import 'antd/dist/antd.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCampanhaContext } from '../../context/CampanhaContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { ICursoCleanDTO, INegocioFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNegocioService } from '../../services/negocio.service';
import StepsCreate from './steps-create';
import './style.css';

const CampanhasCreateStep5: React.FunctionComponent = () => {

  const { campanha, setCampanha, revisao } = useCampanhaContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const service = useNegocioService();

  const [isOpen, setIsOpen] = useState(false);
  const [negocios, setNegocios] = useState<INegocioFullDTO[]>([])

  useEffect(() => {
    setIsGlobalLoading(true);
    service.findFullList(new Predicate())
      .then(({ data }) => setNegocios(data))
      .finally(() => setIsGlobalLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangeNegocio(e: CheckboxChangeEvent, negocio: INegocioFullDTO) {
    e.preventDefault();
    e.stopPropagation();
    const { campanhaCursos } = campanha;
    if (e.target.checked) {
      const campanhaCursosTemp = campanhaCursos
        .concat(negocio.cursos
          .filter(c => campanhaCursos.every(cc => cc.cursoId !== c.id))
          .map(c => ({ id: null, ativo: true, cursoId: c.id, cursoCodigo: c.codigo, cursoDescricao: c.descricao, cursoNegocioCodigo: negocio.codigo, cursoNegocioDescricao: negocio.descricao, cursoNegocioId: negocio.id })));

      setCampanha({ ...campanha, campanhaCursos: campanhaCursosTemp });
    } else {
      setCampanha({ ...campanha, campanhaCursos: campanhaCursos.filter(cc => cc.cursoNegocioId !== negocio.id) })
    }
  }


  function onChangeCurso(e: CheckboxChangeEvent, curso: ICursoCleanDTO, negocio: INegocioFullDTO) {
    e.preventDefault();
    e.stopPropagation();
    const { campanhaCursos } = campanha;
    if (e.target.checked) {
      campanhaCursos.push({ id: null, ativo: true, cursoId: curso.id, cursoCodigo: curso.codigo, cursoDescricao: curso.descricao, cursoNegocioCodigo: negocio.codigo, cursoNegocioDescricao: negocio.descricao, cursoNegocioId: negocio.id });
      setCampanha({ ...campanha, campanhaCursos })
    } else {
      setCampanha({ ...campanha, campanhaCursos: campanhaCursos.filter(tp => tp.cursoId !== curso.id) })
    }
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={82} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={4} />
      <div className="franquia">
        <div className="user-row">
          <h3>Selecione onde será aplicada a campanha</h3>
          <div className="boxes-radios" id="check-wrap">
            {negocios.map(negocio => (
              <div className="box-radios" id="box-radios-wrap" key={negocio.id}>
                <div className="check-select">
                  <Unicons.UilAngleRight size="20" color="#353F49" />
                  <Checkbox onChange={(e) => onChangeNegocio(e, negocio)} id={"negocio-" + negocio.id.toString()} />
                  <h5>{negocio.descricao}</h5>
                  <Unicons.UilInfoCircle size="20" color="#92A9CB" />
                </div>
                <div className="check-radios" style={{ marginLeft: 40 }}>
                  {negocio.cursos.map(curso => (
                    <>
                      <div id="check-revisao">
                        <Checkbox
                          id={"curso-" + curso.id}
                          onChange={(e) => onChangeCurso(e, curso, negocio)}
                          checked={campanha.campanhaCursos.some(p => p.cursoId === curso.id)}
                        />
                        <p style={{ marginLeft: 10 }}>{curso.descricao}</p>
                      </div>
                    </>
                  ))}
                </div>
              </div>))}
          </div>
          <div className="botoes">
            {revisao ?
              <Link to="/campanhas/create/step-6">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/campanhas/create/step-4">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/campanhas/create/step-6">
                  <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa campanha?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/campanhas'
      />
    </div>
  )
}

export default CampanhasCreateStep5;