import { CloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useAlunoContext } from '../../context/AlunoContext';

import { ContatoEnum } from '../../models/pessoa.model';
import { ProgressoNovoAluno } from './progresso';
import './style.css';



export const AlunosCreateStep2: React.FunctionComponent = () => {
	const { aluno, setAluno, revisao, revisaoDetalhe } = useAlunoContext();
	const [isOpen, setIsOpen] = useState(false);

	const setAlunoContato = (idTipo: number, value: string) => {
		const alunoCopy = { ...aluno };
		const alunoToUpdateIndex = alunoCopy.pessoa.contatos.findIndex(
			(c) => c.tipoContatoId === idTipo
		);

		if (alunoToUpdateIndex !== -1) {
			alunoCopy.pessoa.contatos[alunoToUpdateIndex].descricao = value;
		} else {
			alunoCopy.pessoa.contatos.push({
				id: null,
				descricao: value,
				ativo: true,
				codigo: '',
				tipoContatoCodigo: '',
				tipoContatoId: idTipo,
			});
		}

		if (idTipo === ContatoEnum.EPS) {
			if (value == '') {
				value = null;
			}

			alunoCopy.pessoa.email = value;
		}

		if (idTipo === ContatoEnum.EAL) {
			alunoCopy.emailAcesso = value;
		}

		setAluno(alunoCopy);
	};

	return (
		<div className="container-fondo">
			{/* <Header />
			<ProgressoNovoAluno step={1} percent={40} />
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<h3 className='mat'>Preencha os dados de contato do aluno</h3>
					<div className="input-box" id="disabled-box">
						<p>
							Telefone <small>(Somente números)</small>
						</p>
						<MaskedInput
							mask="(11) 11111-1111"
							value={
								aluno.pessoa.contatos?.find(
									(c) => c.tipoContatoId === ContatoEnum.TEL
								)?.descricao
							}
							onChange={(e) => setAlunoContato(ContatoEnum.TEL, e.target.value)}
							placeholder="(00) 0000-0000"
							className="input"
						/>
					</div>
					<div className="input-box">
						<p>
							E-mail pessoal
						</p>
						<Input
							value={
								aluno.pessoa.contatos?.find(
									(c) => c.tipoContatoId === ContatoEnum.EPS
								)?.descricao
							}
							onChange={(e) => setAlunoContato(ContatoEnum.EPS, e.target.value)}
							placeholder="email@gmail.com"
							className="input"
						/>
					</div>
					<div className="input-box">
						<p>
							E-mail de aluno
						</p>
						<Input
							value={
								aluno.pessoa.contatos?.find(
									(c) => c.tipoContatoId === ContatoEnum.EAL
								)?.descricao
							}
							onChange={(e) => setAlunoContato(ContatoEnum.EAL, e.target.value)}
							placeholder="emailaluno@gmail.com"
							className="input"
						/>
					</div>
					<div className="botoes">
						{revisao && (
							<Link to="/alunos/create/step-5">
								<Button
									type="primary"
									className="button-primary"
									style={{ width: 345 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						)}
						{revisaoDetalhe.ativo && (
							<Link to={`/alunos/detalhe`}>
								<Button
									type="primary"
									className="button-primary"
									style={{ width: 345 }}
								>
									Voltar para Revisão
								</Button>
							</Link>
						)}
						{!revisao && !revisaoDetalhe.ativo && (
							<>
								<Link to="/alunos/create/step-1">
									<Button className="button-second" style={{ width: 345 }}>
										Voltar
									</Button>
								</Link>
								<Link to="/alunos/create/step-3">
									<Button className="button-primary" style={{ width: 345 }}>
										Próximo
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição de um novo aluno?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/alunos"
			/> */}
		</div>
	);
};
