import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ImgProgramacao from '../../../../assets/img/box-curso/programacao.svg';
import { BoxDocumentos } from '../../../../components/BoxDocumentos';
import { MenuDetalheMatricula } from '../../../../components/MenuDetalheMatricula/menu-detalhe-matricula.component';
import NewMenu from '../../../../components/NewMenu';
import { Tags } from '../../../../components/Tags';
import { useAlunoContext } from '../../../../context/AlunoContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import {
	IArquivoCleanDTO,
	IFinanceiroDTO,
	IMatriculaContratoDTO,
	IMatriculaDTO,
	IResponsavelFinanceiroDTO,
	ITurmaFullDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { useFinanceiroService } from '../../../../services/financeiro.service';
import { useMatriculaContratoService } from '../../../../services/matricula-contrato.service';
import { useMatriculaService } from '../../../../services/matricula.service';
import { useNotificationService } from '../../../../services/notification.service';
import { useResponsavelFinanceiroService } from '../../../../services/responsavel-financeiro.service';
import { useTurmaService } from '../../../../services/turma.service';
import {
	formatMoney,
	formattedDate,
	isAfterDate,
	isBeforeDate,
	removeSecondsFromHour,
} from '../../../../util/format';
import '../../style.css';
import './detalhe-matricula.styles.scss';
import useDetalheMatricula from './use-detalhe-matricula.hook';

export const AlunosDetalheMatricula: React.FunctionComponent = () => {
	const { idMatricula } = useParams<any>();
	const [open, setOpen] = useState<boolean>(false);
	const { setIsGlobalLoading } = useGlobalContext();
	const responsavelFinanceiroService = useResponsavelFinanceiroService();
	const turmaService = useTurmaService();
	const matriculaDocumetoService = useMatriculaContratoService();
	const financeiroService = useFinanceiroService();
	const matriculaService = useMatriculaService();
	const { detalheMatricula } = useAlunoContext();
	const history = useHistory();
	const notification = useNotificationService();
	const [matricula, setMatricula] = useState<IMatriculaDTO>();

	const [turma, setTurma] = useState<ITurmaFullDTO>(null);
	const [financeiro, setFinanceiro] = useState<IFinanceiroDTO>(null);
	const [matriculaDocumentos, setMatriculaDocumentos] = useState<
		IMatriculaContratoDTO[]
	>([]);
	const [predicateMatriculaDocumento] = useState<Predicate>(new Predicate());
	const [responsavelFinanceiro, setResponsavelFinanceiro] =
		useState<IResponsavelFinanceiroDTO>(null);

	const fetchMatricula = () => {
		if (idMatricula !== undefined) {
			setIsGlobalLoading(true);
			return matriculaService
				.findById({ id: idMatricula })
				.then((response) => {
					setMatricula(response.data);
				})
				.catch(() =>
					notification({
						message: 'Erro!',
						description: 'Matrícula não encontrada',
						type: 'error',
					})
				)
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const getMatriculaDocumento = () => {
		predicateMatriculaDocumento.addOption('matricula.id', idMatricula);
		predicateMatriculaDocumento.addSort('dhInsert');
		return matriculaDocumetoService
			.findList(predicateMatriculaDocumento)
			.then((response) => {
				setMatriculaDocumentos(response.data);
			});
	};

	const fetchResponsavelFinanceiro = async () => {
		const ultimoFinanceiro = detalheMatricula.financeiro;

		setFinanceiro(ultimoFinanceiro);

		const responsavelFinanceiroId = ultimoFinanceiro.responsavelFinanceiro.id;

		return responsavelFinanceiroService
			.findById({ id: responsavelFinanceiroId })
			.then((res) => {
				setIsGlobalLoading(false);
				setResponsavelFinanceiro(res.data);
			});
	};

	const fetchTurma = async () => {
		return turmaService
			.findFullById({ id: detalheMatricula?.turma?.id })
			.then((turma) => {
				setIsGlobalLoading(false);
				setTurma(turma.data);
			});
	};

	useEffect(() => {
		if (detalheMatricula?.turma?.id) {
			setIsGlobalLoading(true);
			Promise.all([
				fetchResponsavelFinanceiro(),
				fetchTurma(),
				fetchMatricula(),
			]).finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detalheMatricula]);

	useEffect(() => {
		if (idMatricula) {
			setIsGlobalLoading(true);
			Promise.all([getMatriculaDocumento()]).finally(() =>
				setIsGlobalLoading(false)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idMatricula]);

	const onUploadContratoMatricula = (
		uploads: UploadFile<IArquivoCleanDTO>[]
	) => {
		const contrato = uploads.map(({ response }) => ({
			matricula: {
				id: idMatricula,
				dataMatricula: null,
				jsRetornoContrato: null,
				ativo: true,
				qtdParcelasEmAberto: null,
				qtdParcelasVencidas: null,
				rematricula: null,
				saldoAPagar: null,
				dataInicioContrato: null,
			},

			arquivo: {
				id: response.id,
				codigo: response.codigo,
				url: response.url,
				ativo: true,
			},
			id: null,
			ativo: true,
		}))[0];

		matriculaDocumetoService.create(contrato).then();
	};

	const formatDefaultList = (files: IMatriculaContratoDTO[]): UploadFile[] => {
		return files.map((file, index) => ({
			uid: index.toString(),
			arquivoId: file.arquivo.id,
			id: file.id,
			name: file.arquivo.codigo,
			status: 'done',
			fileName: file.arquivo.codigo,
			url: `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${file.arquivo.url}?name=${file.arquivo.codigo}`,
		}));
	};

	const getDefaultFileList = () => {
		if (matriculaDocumentos && matriculaDocumentos.length > 0) {
			return formatDefaultList(matriculaDocumentos);
		}
		return [];
	};

	const onRemoveContratoMatricula = (arquivo) => {
		setIsGlobalLoading(true);
		matriculaDocumetoService
			.delete(arquivo)
			.then(() => {
				Promise.all([getMatriculaDocumento()]).finally(() =>
					setIsGlobalLoading(false)
				);
			})
			.catch(() => setIsGlobalLoading(false));
	};

	const qtdAulaRealizadas = () => {
		if (turma && turma.aulas) {
			return turma.aulas.filter((aula) => isBeforeDate(aula.data)).length;
		}
		return 0;
	};

	const baixarContrato = () => {
		setIsGlobalLoading(true);
		matriculaService.baixarContrato(idMatricula)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				FileSaver.saveAs(blob, `contrato_${idMatricula}.pdf`);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const baixarTermoCancelamento = () => {
		setIsGlobalLoading(true);
		matriculaService
			.baixarTermoCancelamento(idMatricula)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				FileSaver.saveAs(blob, `contrato_${idMatricula}.pdf`);
			})
			.catch(() =>
				notification({
					message: 'Erro!',
					description: 'Não foi possível baixar',
					type: 'error',
				})
			)
			.finally(() => setIsGlobalLoading(false));
	};

	const enviarContrato = () => {
		setIsGlobalLoading(true);
		matriculaService
			.baixarContrato(idMatricula)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				matriculaService
					.enviarContratoAssinatura(idMatricula, blob)
					.then(() =>
						notification({
							message: 'Sucesso!',
							description: 'E-mail enviado ao responsável!',
							type: 'success',
						})
					)
					.finally(() => setIsGlobalLoading(false));
			})
			.catch(() =>
				notification({
					message: 'Erro!',
					description: 'Não foi possível enviar',
					type: 'error',
				})
			);
	};

	const baixarTermoRenegociacao = () => {
		setIsGlobalLoading(true);
		financeiroService
			.baixarTermoRenegociacao(idMatricula)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				FileSaver.saveAs(blob, `renegociacao_${idMatricula}.pdf`);
			})
			.catch(() =>
				notification({
					message: 'Erro!',
					description: 'Não foi possível baixar',
					type: 'error',
				})
			)
			.finally(() => setIsGlobalLoading(false));
	};

	const dataImplementacaoContratoDigital = new Date(2022, 5, 13)
		.toISOString()
		.substring(0, 10);

	const dataDaMatricula =
		dataImplementacaoContratoDigital.toString() >=
		matricula?.dataMatricula.toString();

	const { upgradeButtonVisible, navigateToUpgrade } = useDetalheMatricula();

	const goTo = () => {
		history.push(`/alunos/detalhe`);
	}

	return (
		<div className="container-fondo">
			<NewMenu selecionado={3} />

			<div style={{ display: 'flex' }}>
				<MenuDetalheMatricula />
				<div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
					<div className="franquia-row">
						<h5>Detalhes de matrícula</h5>
						<div className="row-revisao">
							<div className="box-revisao">
								<div className="dados">
									<div className={'dados__title-upgrade-transferir-button'}>
										<h4>Turma</h4>

										<div className={'title-upgrade-transferir-button__buttons'}>
											{
												upgradeButtonVisible && (
											<Button
												className={'button-line'}
												onClick={navigateToUpgrade}
											>
												Upgrade
											</Button>
												)
											}
											<Link
												to={`/alunos/transferencia-turma/${idMatricula}`}
												className="button-second"
											>
												Transferir
											</Link>
										</div>
									</div>

									<div className="box-aluno" id="turma-detalhe">
										<div className="avatar-turma">
											<img src={ImgProgramacao} alt="" />
											<Tags tipo={detalheMatricula?.turmaCurso?.descricao} />
										</div>
										<div className="data">
											<h4>{detalheMatricula?.turma?.descricao}</h4>
											<p>
												{moment(turma?.dataInicio).format('DD/MM/YYYY')} -{' '}
												{moment(turma?.dataFim).format('DD/MM/YYYY')} (
												{moment(turma?.dataInicio).to(turma?.dataFim, true)})
											</p>
											<div className="dats">
												<div className="dat">
													<Unicons.UilSmile size="14" color="#6A7C96" />
													<p>{turma?.matriculas?.length ?? 0} alunos ativos</p>
												</div>
												<div className="dat">
													<Unicons.UilGraduationCap size="14" color="#6A7C96" />
													<p>
														{qtdAulaRealizadas()}/{turma?.aulas?.length}
													</p>
												</div>
												<div className="dat">
													<Unicons.UilClock size="14" color="#6A7C96" />
													<p>
														{(
															(turma?.curso?.duracaoMinutos /
																turma?.aulas?.length) *
															qtdAulaRealizadas()
														).toFixed(0)}
														/{turma?.curso?.duracaoMinutos} minutos
													</p>
												</div>
											</div>
											<div className="boxes">
												{turma?.horarios &&
													turma.horarios.map((horario) => (
														<div className="box" key={horario.id}>
															<h6>{horario.diaSemana}</h6>
															<p>
																{`${removeSecondsFromHour(
																	horario.horaInicio
																)} - ${removeSecondsFromHour(horario.horaFim)}`}
															</p>
														</div>
													))}
											</div>
										</div>
										{detalheMatricula?.turma?.dataFim &&
											isBeforeDate(detalheMatricula?.turma?.dataFim) && (
												<div className="box-dot" id="dot-2">
													<div className="dot"></div>
													<p>Em andamento</p>
												</div>
											)}
										{detalheMatricula?.turma?.dataFim &&
											isAfterDate(detalheMatricula?.turma?.dataFim) && (
												<div className="box-dot" id="dot-2">
													<div className="dot"></div>
													<p>Encerrada</p>
												</div>
											)}
									</div>
								</div>
								{financeiro && (
									<>
										<div className="line" style={{ marginBottom: 20 }}></div>
										<div className="dados">
											<h4>Financeiro</h4>
											<div id="box-border">
												<div className="resposavel-revisao">
													{/* <Button
													className="button-line"
													id="button-detalhe-aluno"
												>
													Editar
												</Button> */}
													<h4>Plano de pagamento</h4>
													<div
														className="line"
														style={{ marginBottom: 20 }}
													></div>
													<div id="logo-pagamento">
														<h5>Pagamento da matrícula</h5>
													</div>
													<div
														className="line"
														style={{ marginBottom: 20 }}
													></div>
													{
														<>
															<div id="logo-pagamento">
																<h4>Valor do curso</h4>
																<h6>
																	{financeiro?.valorTotal &&
																		formatMoney(financeiro.valorTotal)}
																</h6>
															</div>
															<div id="logo-pagamento">
																<h4>Parcelas</h4>
																<h6>
																	{financeiro?.valorTotal &&
																		financeiro?.quantidadeParcelas &&
																		formatMoney(
																			financeiro.valorTotal /
																				financeiro.quantidadeParcelas
																		)}
																</h6>
															</div>
															{/* <div id="logo-pagamento">
															<h4>Bolsa Franquia</h4>
															<h6 id="vermelho">
																{financeiro?.valorMensalidadeComDesconto &&
																	`- ${formatMoney(
																		financeiro.valorMensalidadeComDesconto
																	)}`}
															</h6>
														</div> */}
															<div id="logo-pagamento">
																<h4>Descontos</h4>
																<h6 id="vermelho">
																	{financeiro?.valorTotal &&
																		financeiro?.valorTotalComDesconto &&
																		formatMoney(
																			financeiro.valorTotal -
																				financeiro.valorTotalComDesconto
																		)}
																</h6>
															</div>
															<div
																className="line"
																style={{ marginBottom: 20 }}
															></div>
															<div id="logo-pagamento">
																<h2>Valor a Pagar</h2>
																<h3>
																	{financeiro?.valorTotalComDesconto &&
																		formatMoney(
																			financeiro.valorTotalComDesconto
																		)}
																</h3>
															</div>
														</>
													}
												</div>
											</div>
											<div id="box-border">
												<div className="resposavel-revisao">
													{/* <Button
													className="button-line"
													id="button-detalhe-aluno"
												>
													Editar
												</Button> */}
													<h4>Responsável Financeiro</h4>
													<Tags tipo="responsavel" />
													{responsavelFinanceiro?.pessoa && (
														<>
															<div className="nome" style={{ marginTop: 15 }}>
																<h5>Nome Completo</h5>
																<span style={{ fontSize: 18 }}>
																	{responsavelFinanceiro.pessoa.nome}
																</span>
															</div>
															<div className="dados-franquia">
																<div className="cpf">
																	<h6>CPF</h6>
																	<span>
																		{responsavelFinanceiro.pessoa.cpf}
																	</span>
																</div>
																<div className="cpf">
																	<h6>Data de Nascimento</h6>
																	<span>
																		{formattedDate(
																			responsavelFinanceiro.pessoa
																				.dataNascimento
																		)}
																	</span>
																</div>
															</div>

															<div
																style={{ display: open ? 'block' : 'none' }}
																id="example-collapse-text"
																className="collapsedText"
															>
																<div className="dados-franquia">
																	<div className="cpf">
																		<h6>Empresa</h6>
																		<span>
																			{
																				responsavelFinanceiro.pessoa
																					.descricaoEmpresa
																			}
																		</span>
																	</div>
																	<div className="cpf">
																		<h6>Profissao</h6>
																		<span>
																			{
																				responsavelFinanceiro.pessoa
																					.descricaoProfissao
																			}
																		</span>
																	</div>
																</div>
																<div className="nome" style={{ marginTop: 15 }}>
																	<h5>Restrições</h5>
																	<span style={{ fontSize: 18 }}>
																		{
																			responsavelFinanceiro.pessoa
																				.descricaoRestricao
																		}
																	</span>
																</div>
																<div className="nome" style={{ marginTop: 15 }}>
																	<h5>Observações</h5>
																	<span style={{ fontSize: 18 }}>
																		{responsavelFinanceiro.pessoa.observacao}
																	</span>
																</div>
															</div>
														</>
													)}
													<a onClick={() => setOpen((prevState) => !prevState)}>
														<p className="dethales-aluno">
															Mostrar {open ? 'menos' : 'mais'} detalhes
														</p>
													</a>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
							<div
								style={{ margin: 0, width: '100%', padding: '0 24px' }}
								id="user-row"
							>
								<div className="box-documentos">
									<div className="franja"></div>
									<div className="documentos">
										<div className="descricao">
											<div>
												<h6>Contrato</h6>
												<span>
													Baixar ou enviar contrato para assinatura digital
												</span>
											</div>
										</div>
										<div className="upload-component">
											{/* <Upload key={'upload-box-test'} defaultFileList={defaultFileList} maxCount={maxCount ?? null} headers={{ "Authorization": `Bearer ${token()}` }} action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`} onChange={onChange}> */}
											<div className="container-btn-infos">
												<div className="buttons">
													<button
														className="button-upload"
														id="upload"
														onClick={baixarContrato}
													>
														<Unicons.UilDownloadAlt
															size="20"
															color="var(--primary-color)"
															className="icon-upload"
														/>
														Baixar
													</button>
													<button
														hidden={dataDaMatricula}
														className="button-upload"
														id="upload"
														onClick={enviarContrato}
													>
														<Unicons.UilDownloadAlt
															size="20"
															color="var(--primary-color)"
															className="icon-upload"
														/>
														Enviar para assinatura digital
													</button>
													{matricula?.cdAssinado === null ||
													matricula?.cdAssinado === undefined ? (
														<a href={matricula?.cdAssinado} target="blank">
															<Button
																disabled={true}
																hidden={dataDaMatricula}
																className="button-upload"
																id="upload"
															>
																<Unicons.UilDownloadAlt
																	size="20"
																	color="var(--primary-color)"
																	className="icon-upload"
																/>
																(Assinatura Pendente) Baixar contrato assinado
															</Button>
														</a>
													) : (
														<a href={matricula?.cdAssinado} target="blank">
															<button
																hidden={dataDaMatricula}
																className="button-upload"
																id="upload"
															>
																<Unicons.UilDownloadAlt
																	size="20"
																	color="var(--primary-color)"
																	className="icon-upload"
																/>
																Baixar contrato assinado
															</button>
														</a>
													)}
												</div>
												<div className="infos">
													<p>Validade do contrato: (1 ano)</p>
													<br></br>
													<p>
														Data de início:{' '}
														{moment(matricula?.dataInicioContrato).format(
															'DD/MM/YYYY'
														)}
													</p>
													<br></br>
													<p>
														Data de término:{' '}
														{moment(matricula?.dataInicioContrato)
															.add(1, 'years')
															.format('DD/MM/YYYY')}
													</p>
												</div>
											</div>
											{/* </Upload> */}
										</div>
									</div>
								</div>
								{matricula?.statusMatricula.id === 3 ? (
									<div className="box-documentos">
										<div className="franja"></div>
										<div className="documentos">
											<div className="descricao">
												<div>
													<h6>Termo de cancelamento</h6>
													<span>Baixar termo de cancelamento</span>
												</div>
											</div>
											<div className="upload-component">
												{/* <Upload key={'upload-box-test'} defaultFileList={defaultFileList} maxCount={maxCount ?? null} headers={{ "Authorization": `Bearer ${token()}` }} action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`} onChange={onChange}> */}
												<button
													className="button-upload"
													id="upload"
													onClick={baixarTermoCancelamento}
												>
													<Unicons.UilDownloadAlt
														size="20"
														color="var(--primary-color)"
														className="icon-upload"
													/>
													Baixar
												</button>
											</div>
										</div>
									</div>
								) : null}
								{
									<div className="box-documentos">
										<div className="franja"></div>
										<div className="documentos">
											<div className="descricao">
												<div>
													<h6>Termo de renegociação</h6>
													<span>Baixar termo de renegociação</span>
												</div>
											</div>
											<div className="upload-component">
												{/* <Upload key={'upload-box-test'} defaultFileList={defaultFileList} maxCount={maxCount ?? null} headers={{ "Authorization": `Bearer ${token()}` }} action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`} onChange={onChange}> */}
												<button
													className="button-upload"
													id="upload"
													onClick={baixarTermoRenegociacao}
												>
													<Unicons.UilDownloadAlt
														size="20"
														color="var(--primary-color)"
														className="icon-upload"
													/>
													Baixar
												</button>
											</div>
										</div>
									</div>
								}
							</div>
							<div
								key={JSON.stringify(getDefaultFileList())}
								className="user-row"
								style={{ margin: 0, width: '100%', padding: '0 24px' }}
								id="user-row"
							>
								<BoxDocumentos
									maxCount={1}
									statusDocumento={true}
									defaultFileList={getDefaultFileList()}
									title={'Contrato de matricula'}
									subtitle={'Enviar o contrato assinado da matricula do aluno.'}
									setFileList={(fileList) =>
										onUploadContratoMatricula(fileList)
									}
									onRemoveFile={(fileList) =>
										onRemoveContratoMatricula(fileList)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="botao-voltar-revisao" style={{ top: '14%' }}>
				<Button
					className="button-second"
					icon={<ArrowLeftOutlined rev={undefined} />}
					onClick={goTo}
				>
					Voltar
				</Button>
			</div>
		</div>
	);
};
