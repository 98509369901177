import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IHoraSedeCleanDTO,
	IHoraSedeDTO,
	IHoraSedeFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';
import { baseCreate } from './base/base-create.service';

export const useHoraSedeService = () => {
	const resourceURL = 'hora-sede';

	const patchCustom = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<IHoraSedeDTO>> =>
		http.patch<IHoraSedeDTO>(resourceURL + `/custom/${id}`, dto);

	const replicarOferta = (
		id: number,
		dto: any,
	): Promise<AxiosResponse<IHoraSedeFullDTO>> =>
		http.patch<IHoraSedeFullDTO>(resourceURL + `/replicar-oferta/${id}`, dto);
	
	const createCustom = (dto : IHoraSedeFullDTO): Promise<AxiosResponse<IHoraSedeFullDTO>> =>
		http.post(resourceURL + `/create-custom`, dto)

	return {
		...baseFindList<IHoraSedeCleanDTO, IHoraSedeDTO, IHoraSedeFullDTO>(
			resourceURL
		),
		...baseFind<IHoraSedeCleanDTO, IHoraSedeDTO, IHoraSedeFullDTO>(resourceURL),
		...baseUpdate<IHoraSedeCleanDTO, IHoraSedeDTO, IHoraSedeFullDTO>(resourceURL),
		...baseCreate<IHoraSedeCleanDTO, IHoraSedeDTO, IHoraSedeFullDTO>(resourceURL),
		...baseFind<IHoraSedeCleanDTO, IHoraSedeDTO, IHoraSedeFullDTO>(resourceURL),
		...baseFindById<IHoraSedeCleanDTO, IHoraSedeDTO, IHoraSedeFullDTO>(resourceURL),
		replicarOferta,
		patchCustom,
		createCustom,
	};
};
