import http from '../helpers/http-common';
import {
	ICrmLeadCleanDTO,
	ICrmLeadCustomDTO,
	ICrmLeadDTO,
	ICrmLeadFullDTO
} from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseDelete } from './base/base-delete.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';

export const useCrmLeadService = () => {
	const resourceURL = '/crm/lead';

	const alterarStatus = (idLead: number, cdEstagio: string) => {
		return http.put(`${resourceURL}/${idLead}/estagio/${cdEstagio}`)
	}

	const patch = (idLead: number, body: any, idAluno?: number) => {
		return http.patch(`${resourceURL}/${idLead}/${idAluno || ''}`, body)
	}

	const atualizarLeadAluno = (idLead: number, idAluno?: number) => {
		return http.patch(`${resourceURL}/atualizar-lead-aluno/${idLead}/${idAluno}`)
	}

	const findCustomIndex = (predicate: Predicate = new Predicate()) => {
		return http.get(`${resourceURL}/find-custom-index`, formatHttpParams(predicate))
	}

	return {
		...baseCreate<ICrmLeadCleanDTO, ICrmLeadDTO, ICrmLeadFullDTO>(resourceURL),
		...baseFindById<ICrmLeadCleanDTO, ICrmLeadDTO, ICrmLeadFullDTO>(resourceURL),
		...baseFind<ICrmLeadCleanDTO, ICrmLeadCustomDTO, ICrmLeadFullDTO>(resourceURL),
		...baseDelete(resourceURL),
		alterarStatus,
		atualizarLeadAluno,
		patch,
		findCustomIndex
	};
};
