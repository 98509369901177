import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, DatePicker, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useCampanhaContext } from '../../context/CampanhaContext';

import { formatMomentLocalDateTime, toMoment } from '../../util/format';
import StepsCreate from './steps-create';
import './style.css';

const CampanhasCreateStep2: React.FunctionComponent = () => {

  const { campanha, setCampanha, revisao } = useCampanhaContext();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={32} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={1} />
      <div className="franquia">
        <div className="user-row">
          <h3 id="taxas-h3">Selecione o período de vigência</h3>
          <div className="inputs-box" id="box-tax">
            <div className="input-box">
              <p>Inicio</p>
              <DatePicker format={'DD-MM-YYYY'} id="data-inicio" value={toMoment(campanha.dataInicio)} style={{ width: '100%' }} onChange={e => setCampanha({ ...campanha, dataInicio: formatMomentLocalDateTime(e) })} />
            </div>
            <Unicons.UilArrowRight size="20" color="#6A7C96" />
            <div className="input-box">
              <p>Final</p>
              <DatePicker format={'DD-MM-YYYY'} id="data-final" value={toMoment(campanha.dataFim)} style={{ width: '100%' }} onChange={e => setCampanha({ ...campanha, dataFim: formatMomentLocalDateTime(e) })} />
            </div>
          </div>
          <div className="botoes">
            {revisao ?
              <Link to="/campanhas/create/step-6">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/campanhas/create/step-1">
                  <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                </Link>
                <Link to="/campanhas/create/step-3">
                  <Button className="button-primary" id="proximo" style={{ width: 345 }}>Próximo</Button>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa campanha?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/campanhas'
      />
    </div>
  )

}

export default CampanhasCreateStep2;