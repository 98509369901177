import { useState } from "react";
import { useAlunoContext } from "../../../context/AlunoContext";
import { IEnderecoCleanDTO, IPessoaCustomDTO, IPessoaResponsavelCustomDTO } from "../../../models/happy-code-api.model";
import { useNotificationService } from "../../../services/notification.service";

const useAddResponsavel = () => {
    const { aluno, setAluno } = useAlunoContext();
    const notification = useNotificationService();
    const [isOnePersonTurn, setIsOnePersonTurn] = useState<boolean>(false);

    const handleAdicionarResponsavel = () => {
        if (aluno?.pessoa?.pessoaResponsaveis?.length < 3) {
            const novoResponsavel: IPessoaResponsavelCustomDTO = {
                ativo: true,
                principal: false,
                responsavel: {
                    ativo: true,
                    contatos: [],
                    endereco: {} as IEnderecoCleanDTO,
                } as IPessoaCustomDTO,
            } as IPessoaResponsavelCustomDTO;

            setIsOnePersonTurn(true);
            setAluno((aluno) => ({
                ...aluno,
                pessoa: {
                    ...aluno.pessoa,
                    pessoaResponsaveis: [...aluno.pessoa.pessoaResponsaveis, novoResponsavel],
                },
            }));
        } else {
            notification({
                description: 'Máximo 3 Responsáveis',
                type: 'warning',
                message: 'Aviso',
            });
        }
    };

    return { handleAdicionarResponsavel, isOnePersonTurn, setIsOnePersonTurn };
};

export default useAddResponsavel;
