import * as Unicons from '@iconscout/react-unicons';
import { ArrowLeftOutlined } from '@mui/icons-material';
import { Button, Progress } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { format, intervalToDuration } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BoxDocumentos } from '../../../components/BoxDocumentos';
import Header from '../../../components/Header';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { PerfilEnum } from '../../../enum/perfil.enum';
import {
	IArquivoCleanDTO,
	IFinanceiroEscolaCleanDTO,
	IGrupoContratoArquivoDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useFinanceiroEscolaService } from '../../../services/financeiro-escola.service';
import { useGrupoContratoArquivoService } from '../../../services/grupo-contrato-arquivo-service';
import '../../../theme/flex.scss';
import { buildTimeInterval } from '../../../util/buildTimeInterval';
import { currencyFormatter, formatMoney } from '../../../util/format';
import { MenuRevisaoContratoComponent } from '../components/menu-revisao-contrato-component/menu-revisao-contrato.component';
import '../styles/_grupo-contrato-create-step-revisao.scss';
import '../styles/grupo-contrato-revisao-step.styles.scss';

export const GrupoContratoCreateStepRevisaoScreen: React.FunctionComponent =
	() => {
		const { contratoParaCadastrar, setFlRevisao, getContratoTiposById } =
			useEscolaContext();
		const history = useHistory();
		const { idGrupo } = useParams<any>();
		const [parcelas, setParcelas] = useState<IFinanceiroEscolaCleanDTO[]>();
		const financeiroService = useFinanceiroEscolaService();
		const contratoArquivoService = useGrupoContratoArquivoService();
		const [anexos, setAnexos] = useState<IGrupoContratoArquivoDTO[]>();
		const { setIsGlobalLoading, usuario, unidade } = useGlobalContext();
		const [anexosDefault, setAnexosDefault] = useState<UploadFile[]>();

		const findParcelas = () => {
			const predicate = new Predicate();

			predicate.addOption('contratoId', contratoParaCadastrar?.id || 0);

			financeiroService.findCleanList(predicate).then(({ data }) => {
				setParcelas(data);
			});
		};

		function validarUnidadeLogado() {
			if (!usuario.perfil) return;
			if (usuario.perfil === PerfilEnum.FRANQUIA && unidade) {
				return true;
			}
		}

		const fetchAnexos = () => {
			setAnexosDefault([
				{ uid: null, name: null, status: null, fileName: null, url: null },
			]);
			setAnexos(null);
			if (contratoParaCadastrar?.id) {
				const predicate = new Predicate();

				predicate.addOption('contratoId', contratoParaCadastrar.id);

				contratoArquivoService.findList(predicate).then(({ data }) => {
					setAnexos(data);
				});
			}
		};

		const tipoDeContrato = getContratoTiposById(
			contratoParaCadastrar?.tipo?.id
		)?.descricao;

		const envioAutomaticoCobrancas = contratoParaCadastrar?.envioAutomatico
			? 'Ativado'
			: 'Desativado';

		const vigenciaDataDeInicio = format(
			moment(contratoParaCadastrar?.vigenciaInicio).toDate(),
			'dd/MM/yyyy'
		);

		const vigenciaDataDeConclusao = format(
			moment(contratoParaCadastrar?.vigenciaFim).toDate(),
			'dd/MM/yyyy'
		);

		const vigenciaTempoTotal = buildTimeInterval(
			contratoParaCadastrar?.vigenciaInicio?.toString(),
			contratoParaCadastrar?.vigenciaFim?.toString()
		);

		const valorUnitário = currencyFormatter(
			contratoParaCadastrar?.valorLicencaIntra
		);

		const valorUnitarioExtra = currencyFormatter(
			contratoParaCadastrar?.valorLicencaExtra
		);

		const intra = currencyFormatter(contratoParaCadastrar?.totalLicencaIntra);

		const extra = currencyFormatter(contratoParaCadastrar?.totalLicencaExtra);

		useEffect(() => {
			setFlRevisao(true);
			findParcelas();
			fetchAnexos();
			validarUnidadeLogado();
		}, [usuario, unidade]);

		const renderContratoLabelText = (label: string, text: string) => {
			return (
				<div className="infos-contrato__contrato-label-text">
					<label className="contrato-label-text__label">{label}</label>
					<span className="contrato-label-text__text">{text}</span>
				</div>
			);
		};

		const renderDadosContrato = () => {
			return (
				<div className="revisao-dados__revisao-dados-container">
					<div className="revisao-dados-container__titulo-button">
						<h2 className="titulo-button__titulo">Contratos</h2>
						{usuario.perfil === PerfilEnum.SEDE && (
							<Link to={`/escolas/grupos/${idGrupo}/contratos/create/step-1`}>
								<Button className="button-line">Editar</Button>
							</Link>
						)}
					</div>
					<div className="revisao-dados-container__infos-contrato">
						{renderContratoLabelText('Tipo de Contrato', tipoDeContrato)}
						{renderContratoLabelText(
							'Envio Automático de Cobranças?',
							envioAutomaticoCobrancas
						)}
					</div>
					<hr className="revisao-dados-container__line-divisor" />

					<h3 className="revisao-dados-container__subtitle">Vigência</h3>

					<div className="revisao-dados-container__infos-contrato">
						{renderContratoLabelText('Data de início', vigenciaDataDeInicio)}
						{renderContratoLabelText(
							'Data de conclusão',
							vigenciaDataDeConclusao
						)}
						{renderContratoLabelText('Tempo Total', vigenciaTempoTotal)}
					</div>

					<hr className="revisao-dados-container__line-divisor" />

					<h3 className="revisao-dados-container__subtitle">
						Licenças Contratadas
					</h3>

					<div className="revisao-dados-container__infos-contrato">
						{renderContratoLabelText(
							'Quantidade Intra',
							contratoParaCadastrar?.qtdLicencaIntra.toString()
						)}
						{renderContratoLabelText('Valor Unitário', valorUnitário)}
						{renderContratoLabelText(
							'Quantidade Extra',
							(contratoParaCadastrar as any)?.qtdLicencaExtra
						)}
						{renderContratoLabelText('Valor Unitário', valorUnitarioExtra)}
						{renderContratoLabelText(
							'E-mails para cobrança',
							(contratoParaCadastrar as any)?.emails
						)}
						{renderContratoLabelText(
							'Observações',
							contratoParaCadastrar?.observacoes || 'Sem observações'
						)}
					</div>
					<hr className="revisao-dados-container__line-divisor" />
				</div>
			);
		};

		const totalServico = () => {
			let qtdServico = 0;

			if (
				contratoParaCadastrar?.servico?.id == 1 ||
				contratoParaCadastrar?.servico?.id == 3 ||
				contratoParaCadastrar?.servico?.id == 4 ||
				contratoParaCadastrar?.servico?.id == 6
			)
				qtdServico = contratoParaCadastrar?.qtdLicencaIntra;

			if (
				contratoParaCadastrar?.servico?.id == 2 ||
				contratoParaCadastrar?.servico?.id == 3 ||
				contratoParaCadastrar?.servico?.id == 5 ||
				contratoParaCadastrar?.servico?.id == 6
			)
				qtdServico = qtdServico + contratoParaCadastrar?.qtdLicencaExtra;

			// const qtdMeses = differenceInMonths(
			//     moment(contratoParaCadastrar?.vigenciaFim).toDate(),
			//     moment(contratoParaCadastrar?.vigenciaInicio).toDate())
			//     + 1;

			let qtdMeses = 0;

			if (contratoParaCadastrar) {
				const intervalo = intervalToDuration({
					start: new Date (contratoParaCadastrar?.vigenciaInicio),
					end: new Date (contratoParaCadastrar?.vigenciaFim),
				});

				qtdMeses = intervalo.years * 12 + intervalo.months;
			}

			return contratoParaCadastrar?.valorServico * qtdServico * qtdMeses;
		};

		const valorTotalDoServico = currencyFormatter(totalServico());

		const custoTotalDoContrato = currencyFormatter(
			contratoParaCadastrar?.valorTotal
		);

		useEffect(() => {
			setAnexosDefault(
				anexos?.map((anexo, index) => ({
					uid: index.toString(),
					arquivoId: anexo?.arquivo.id,
					id: anexo?.id,
					name: anexo?.arquivo.codigo,
					status: 'done',
					fileName: anexo?.arquivo.codigo,
					url:
						process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API +
						'arquivo/download/' +
						anexo?.arquivo.url +
						'?name=' +
						anexo?.arquivo.codigo,
				}))
			);
		}, [anexos]);

		const anexar = (uploads: UploadFile<IArquivoCleanDTO>[]) => {
			setIsGlobalLoading(true);

			const anexo: IGrupoContratoArquivoDTO = uploads.map(
				({ response: arquivo }) => ({
					id: null,
					contrato: contratoParaCadastrar,
					arquivo: {
						id: arquivo.id,
						codigo: arquivo.codigo,
						url: arquivo.url,
						ativo: true,
					},
					ativo: true,
				})
			)[0];

			contratoArquivoService
				.create(anexo)
				.then()
				.finally(() => {
					fetchAnexos();
					setIsGlobalLoading(false);
				});
		};

		const desanexar = (anexo) => {
			setIsGlobalLoading(true);

			contratoArquivoService
				.delete(anexo)
				.then()
				.finally(() => {
					fetchAnexos();
					setIsGlobalLoading(false);
				});
		};

		const renderFinanceiro = () => {
			return (
				<div className="revisao-dados__revisao-dados-container">
					<h2 className="titulo-button__titulo">Financeiro</h2>

					<h3 className="revisao-dados-container__subtitle">Custo</h3>

					<div className="revisao-dados-container__infos-contrato">
						{renderContratoLabelText('Intra', intra)}
						{renderContratoLabelText('Extra', extra)}
						{renderContratoLabelText(
							'Parcelamento do contrato',
							contratoParaCadastrar?.qtdParcelas?.toString()
						)}
					</div>

					<hr className="revisao-dados-container__line-divisor" />

					<h3 className="revisao-dados-container__subtitle">
						Serviços Educacionais
					</h3>

					<div className="revisao-dados-container__infos-contrato">
						{renderContratoLabelText(
							'Valor total do serviço',
							valorTotalDoServico
						)}
						{renderContratoLabelText(
							'Custo Total do contrato',
							custoTotalDoContrato
						)}
					</div>

					<div className="revisao-dados-container__table-label">
						<label className="table-label__label">Parcelas</label>
						<table style={{ border: 0 }}>
							<thead>
								<tr id="top">
									<td id="colunas" style={{ border: 0 }}>
										NÚMERO
									</td>
									<td id="colunas" style={{ border: 0 }}>
										VALOR
									</td>
									<td id="colunas" style={{ border: 0 }}>
										VENCIMENTO
									</td>
								</tr>
							</thead>
							<tbody>
								{parcelas?.map((parcela, index) => (
									<tr key={index}>
										<td id="colunas" style={{ border: 0 }}>
											{parcela.parcela}
										</td>
										<td id="colunas" style={{ border: 0 }}>
											{formatMoney(parcela.valorTotal)}
										</td>
										<td id="colunas" style={{ border: 0 }}>
											{moment(parcela.vencimento).format('DD/MM/YYYY')}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<hr className="revisao-dados-container__line-divisor" />
				</div>
			);
		};

		const renderEscolas = () => {
			return (
				<div className="revisao-dados__revisao-dados-container">
					<h2 className="titulo-button__titulo">Escolas</h2>

					<div className="revisao-dados-container__infos-contrato">
						{contratoParaCadastrar?.escolas?.map?.(({ id, nomeFantasia }) => (
							<>{renderContratoLabelText('Nome da Escola', nomeFantasia)}</>
						))}
					</div>

					<hr className="revisao-dados-container__line-divisor" />
				</div>
			);
		};

		const renderAnexos = (files: UploadFile[]) => {
			return (
				<>
					<div className="revisao-dados__revisao-dados-container">
						<h2 className="titulo-button__titulo">Anexos</h2>
					</div>

					<div
						key={JSON.stringify(files)}
						className="revisao-datos__anexo-wrapper"
						id="user-row"
					>
						{contratoParaCadastrar?.id ? (
							<BoxDocumentos
								maxCount={1}
								statusDocumento={true}
								defaultFileList={files}
								title={'Arquivo de contrato'}
								subtitle={'Enviar o arquivo do contrato assinado'}
								setFileList={(fileList) => anexar(fileList)}
								onRemoveFile={(arquivo) => desanexar(arquivo)}
								notRemove={validarUnidadeLogado()}
							/>
						) : (
							<>Para anexar um arquivo, é necessário salvar o contrato antes.</>
						)}
					</div>
				</>
			);
		};

		return (
			<>
				<Header />
				<div className="contrato-step-revisao__progress">
					<Progress
						className="progress-user"
						percent={100}
						strokeLinecap="square"
						showInfo={false}
					/>
				</div>
				<div className="contrato-step-revisao-wrapper">
					<div className="wrapper-column__menu-revisao-wrapper">
						<Button
							className="button-second"
							icon={<ArrowLeftOutlined />}
							onClick={() => history.goBack()}
						>
							Voltar
						</Button>
						<MenuRevisaoContratoComponent
							limparRevisao={() => setFlRevisao(false)}
						/>
					</div>
					<div className="wrapper-column__table-revisao-wrapper">
						<div className="table-revisao-wrapper__top-content">
							<h1 className="top_content__titulo">Revisão de Dados</h1>
							<Link
								to={`/escolas/grupos/${idGrupo}/contratos/`}
								className="top-content__close-button"
							>
								<Unicons.UilTimes size="20" color="#353F49" />
							</Link>
						</div>
						<div className="table-revisao-wrapper__revisao-dados">
							{renderDadosContrato()}
							{renderFinanceiro()}
							{renderEscolas()}
							{renderAnexos(anexosDefault)}
						</div>
					</div>
				</div>
			</>
		);
	};
