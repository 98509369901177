import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Progress, Radio } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useHoraSedeContext } from '../../context/HoraSedeContext';
import '../../global.css';
import { useHoraSedeService } from '../../services/hora-sede.service';
import StepsCreate from './steps-create';
import './style.css';
import { currencyFormatter, currencyParser } from '../../util/format';

const HoraSedeCreateStep1: React.FunctionComponent = () => {
  const { horaSede, setHoraSede } = useHoraSedeContext();

  const [isOpen, setIsOpen] = useState(false);
  const horaSedeService = useHoraSedeService();

  const fetchHora = () => {
    horaSedeService.findFullById({ id: horaSede?.id }).then((response) => setHoraSede(response.data));
  }

  useEffect(() => {
    if (horaSede?.id) {
      fetchHora();
    }
  }, [])

  const history = useHistory();
  const onFinish = () => {
    history.push("/hora");
  }

  const proximo = () => {
    history.push("/hora/create/step-2")
  }

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={16} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">

        <div className="user-row">
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3 id="taxas-h3">Insira as informações</h3>
            {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
            <div className="input-box">
              <p>Valor sugerido</p>
              <InputNumber decimalSeparator="."
                formatter={currencyFormatter}
                parser={currencyParser}
                minLength={0}
                value={horaSede?.valorSugerido}
                id="nome"
                onChange={e => setHoraSede({ ...horaSede, valorSugerido: e })} />
            </div>


            <div className="input-box">
              <p>Máximo de parcelas</p>
              <InputNumber
                min={0}
                max={12}
                value={horaSede?.numeroMaximoParcela}
                id="descricao"
                onChange={e => setHoraSede({ ...horaSede, numeroMaximoParcela: e })} />
            </div>

            <div className="input-box">
              <p>Máximo prorrogação em dias</p>
              <InputNumber minLength={0}
                value={horaSede?.numeroProrrogacaoDiasMatricula}
                id="descricao"
                onChange={e => setHoraSede({ ...horaSede, numeroProrrogacaoDiasMatricula: e })} />
            </div>

            <h3 id="taxas-h3">Deseja utilizar a taxa de Matrícula?</h3>
            <div className="input-box" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
              <span style={{ width: 200 }}>Habilitar taxa: </span>
              <Radio.Group
                style={{ display: "flex", justifyContent: "center" }}
                defaultValue={horaSede?.flTaxa}
                value={horaSede?.flTaxa}
                onChange={(e) => setHoraSede({ ...horaSede, flTaxa: e.target.value })} >
                <Radio value={false}>Não</Radio>
                <Radio value={true}>Sim</Radio>
              </Radio.Group>
            </div>
            <div className="input-box">
              <p>Valor da taxa</p>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={currencyFormatter}
                parser={currencyParser}
                decimalSeparator=","
                disabled={!horaSede?.flTaxa ? true : false}
                defaultValue={horaSede?.flTaxa ? horaSede?.valorSplitTaxa : 0}
                value={horaSede?.flTaxa ? horaSede?.valorSplitTaxa : 0}
                onChange={(e) => setHoraSede({ ...horaSede, valorSplitTaxa: e })} />
            </div>
            <div className="botoes">
              <Link to="/hora">
                <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
              </Link>
              <Button onClick={proximo} type="primary" htmlType="submit" id="proximo" className="button-primary" style={{ width: 345 }}>proximo</Button>
            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa configuração?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/hora'
      />
    </div>
  )

}

export default HoraSedeCreateStep1;