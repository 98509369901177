import { DatePicker, Table } from 'antd';
import { useEffect, useState } from 'react';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Predicate } from '../../../../models/predicate.model';
import { useRelatorioService } from '../../../../services/relatorio.service';
import { ExportToExcel } from '../../../../util/exportToExcel';
import { getPeriodoSemPadrao } from '../../../../util/filtroRelatorio';
import moment from 'moment';
import RematriculaHubTableColumns from './rematricula-hub-table-columns';
import { RelatorioRematriculaNovoDTO } from '../../../../models/relatorio.model';
import { hoje } from '../../../../util/util';

const { RangePicker } = DatePicker;

function RelRematriculaNovo() {
	const { unidade, setIsGlobalLoading } = useGlobalContext();
	const [data, setData] = useState<RelatorioRematriculaNovoDTO[]>();
	const service = useRelatorioService();

	const [dataVencimentoInicio, setDataVencimentoInicio] = useState<moment.Moment>();
	const [dataVencimentoFim, setDataVencimentoFim] = useState<moment.Moment>();
	const [dataPagamentoInicio, setDataPagamentoInicio] =
		useState<moment.Moment>();
	const [dataPagamentoFim, setDataPagamentoFim] = useState<moment.Moment>();
	const [displayedData, setDisplayedData] = useState<
		RelatorioRematriculaNovoDTO[]
	>([]);

	useEffect(() => {
		if (unidade && unidade.id) {
			const predicate = new Predicate();
			setIsGlobalLoading(true);

			dataVencimentoFim && predicate.addOption('vencimentoFim', dataVencimentoFim.toISOString());
			dataVencimentoInicio && predicate.addOption('vencimentoIni', dataVencimentoInicio.toISOString());

			dataPagamentoFim &&
				predicate.addOption('pagamentoFim', dataPagamentoFim.toISOString());
			dataPagamentoInicio &&
				predicate.addOption('pagamentoIni', dataPagamentoInicio.toISOString());

			predicate.addOption('unidadeId', unidade.id);

			service.rematriculaNovo(predicate).then(({ data }) => {
				setData(data);
				setDisplayedData(data);
				setIsGlobalLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		unidade,
		dataVencimentoInicio,
		dataVencimentoFim,
		dataPagamentoInicio,
		dataPagamentoFim,
	]);

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h3 className="title">
					Oportunidade de Rematrículas e Rematrículas Realizadas
				</h3>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Vencimento Contrato</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataVencimentoInicio(values && values[0]);
								setDataVencimentoFim(values && values[1]);
							}}
							value={getPeriodoSemPadrao(
								dataVencimentoInicio,
								dataVencimentoFim
							)}
							format={'DD-MM-YYYY'}
						/>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>
							Data de Pagamento Contrato
						</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataPagamentoInicio(values && values[0]);
								setDataPagamentoFim(values && values[1]);
							}}
							value={getPeriodoSemPadrao(dataPagamentoInicio, dataPagamentoFim)}
							format={'DD-MM-YYYY'}
						/>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={displayedData}
							fileName={`Matriculas ` + hoje().toISOString()}
						/>
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table
					dataSource={data}
					columns={RematriculaHubTableColumns()}
					scroll={{ y: 480 }}
					onChange={(_pagination, _filters, _sorter, extra) => {
						setDisplayedData(extra.currentDataSource);
					}}
				/>
			</div>
		</div>
	);
}

export default RelRematriculaNovo;
