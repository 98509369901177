import PropagateLoader from "react-spinners/PropagateLoader";
import { useGlobalContext } from '../../context/GlobalContext';

const GlobalLoading = (): React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> => {
    const { isGlobalLoading } = useGlobalContext();
    // let loadingLocal = 0;

    // http.interceptors.request.use(function (config) {
    //     // spinning start to show
    //     loadingLocal = loadingLocal + 1;
    //     setLoading(loadingLocal);
    //     return config
    // }, function (error) {
    //     loadingLocal = loadingLocal - 1;
    //     setLoading(loadingLocal);
    //     return Promise.reject(error);
    // });

    // http.interceptors.response.use(function (response) {
    //     // spinning hide
    //     loadingLocal = loadingLocal - 1;
    //     setLoading(loadingLocal);

    //     return response;
    // }, function (error) {
    //     loadingLocal = loadingLocal - 1;
    //     setLoading(loadingLocal);
    //     return Promise.reject(error);
    // });

    return isGlobalLoading ? (
        <div className="global-spinner-overlay" id="spinner">
            <PropagateLoader color={'#0C306C'} loading={isGlobalLoading} size={15} speedMultiplier={1.1}/>
        </div>
    ) : null
}

export default GlobalLoading