import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Pagination } from '@mui/material';
import { Breadcrumb, Button, Form, Input, Select } from 'antd';
import NewMenu from '../../../components/NewMenu';
import ItemTabelaGrupoComponent from '../components/item-tabela-grupo-component/item-tabela-grupo.component';
import useFindStates from '../../../globalHooks/use-find-states.hook';
import '../styles/_escola-grupos.screen.styles.scss';
import useProfileIsSede from '../../../globalHooks/useProfileIsSede';
import useGroupContentFilters from '../states/use-group-content-filters.state';
import { FilterDefaultValue } from '../../../enum/filter-default-value.enum';
import { SortingEnum } from '../../../enum/sorting.enum';
import useFindGroupSchools from '../states/use-find-group-schools.hook';
import useGroupListagem from '../states/use-group-listagem';
import { ChangeEvent } from 'react';

const GrupoListagemScreen = () => {
	const { listOfStates } = useFindStates();
	const { onCriarGrupoPress } = useGroupListagem();
	const {
		setSearchbarValue,
		schoolGroup,
		onFilterStateChange,
		onFilterStatusChange,
		findSchoolGroups,
		onFilterGroupNameChange,
		onPageChange,
	} = useFindGroupSchools();

	const thisProfileIsSede = useProfileIsSede();

	const renderTitulo = () => {
		return (
			<div className="box-title">
				<div>
					<h3 className="title">Grupos ({schoolGroup?.totalElements})</h3>
					<Breadcrumb>
						<Breadcrumb.Item href={thisProfileIsSede ? '/' : '/franquia'}>
							Home
						</Breadcrumb.Item>
						<Breadcrumb.Item>Grupos</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Button
					id="criar-grupo"
					className="button-primary"
					onClick={onCriarGrupoPress}
					icon={<PlusOutlined rev={undefined} />}
				>
					Novo Grupo
				</Button>
			</div>
		);
	};

	const { groupFiltersContent, groupNameGroupFiltersContent } =
		useGroupContentFilters();

	const renderFiltros = () => {
		return (
			<form className="filtros">
				<Form.Item
					label="Buscar Grupos"
					labelAlign="left"
					className="d-flex-col-start-star w-100"
				>
					<Input
						id="buscar"
						prefix={
							<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
						}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setSearchbarValue(event.target.value)
						}
						className="searchbar-input"
					/>
				</Form.Item>

				<Form.Item labelAlign="left" label="Estado">
					<Select
						className="filtro-estado"
						defaultValue={FilterDefaultValue.DEFAULT}
						onChange={onFilterStateChange}
					>
						<Select.Option value={FilterDefaultValue.DEFAULT}>
							Todos
						</Select.Option>
						{listOfStates &&
							listOfStates.map(({ id, codigo, nome }) => (
								<Select.Option key={id} value={codigo}>
									{nome}
								</Select.Option>
							))}
					</Select>
				</Form.Item>

				<Form.Item labelAlign="left" label="Status">
					<Select
						className="filtro-estado"
						defaultValue={FilterDefaultValue.DEFAULT}
						onChange={onFilterStatusChange}
					>
						{groupFiltersContent.map(({ value, label }, index) => (
							<Select.Option key={index} value={value}>
								{label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item labelAlign="left" label="Ordem Alfabética">
					<Select
						className="filtro-estado"
						defaultValue={SortingEnum.ASC}
						onChange={onFilterGroupNameChange}
					>
						{groupNameGroupFiltersContent.map(({ value, label }, index) => (
							<Select.Option key={index} value={value}>
								{label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</form>
		);
	};

	const renderTabela = () => {
		return (
			<div className="tabela">
				<div className="titles">
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>NOME DO GRUPO</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>TIPO DO CONTRATO</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>ESTADO</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>CIDADE</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>RESPONSÁVEL SUPORTE</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>FOCAL OPERACIONAL</span>
						<div className="arrows">
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>AÇÕES</span>
						<div className="arrows">
						</div>
					</div>
				</div>
				<div className="tabela-body">
					{schoolGroup &&
						schoolGroup?.content?.length > 0 &&
						schoolGroup.content.map((grupo, index) => (
							<ItemTabelaGrupoComponent
								grupo={grupo}
								key={index}
								buscarDados={findSchoolGroups}
							/>
						))}
				</div>
			</div>
		);
	};

	return (
		<div id="escola-grupos" className="container">
			<NewMenu selecionado={23} />
			<div
				className="dashboard"
				style={{ paddingTop: 40, height: '100vh', overflowY: 'scroll' }}
			>
				{renderTitulo()}
				{renderFiltros()}
				{renderTabela()}
				<div className="paginacao">
					<Pagination
						onChange={(event, page) => onPageChange(event, page - 1)}
						page={schoolGroup ? schoolGroup.pageable.pageNumber + 1 : 0}
						count={schoolGroup ? schoolGroup.totalPages : 0}
					/>
				</div>
			</div>
		</div>
	);
};

export default GrupoListagemScreen;
