import { InputNumber } from 'antd';
import './label-and-input-number.styles.scss';
import { currencyFormatter, currencyParser } from '../../util/format';

interface LabelAndInputNumberProps {
	label: string;
	defaultValue: number;
	value: number;
	onChange: (event: any) => void;
}

export default function LabelAndInputNumber({
	label,
	defaultValue,
	value,
	onChange,
}: LabelAndInputNumberProps) {
	return (
		<div className={'label-and-input-number'}>
			<label className={'label-and-input-number__label'}>{label}</label>

			<div className={'label-and-input-number__input'}>
				<InputNumber
					style={{ width: '100%' }}
					formatter={currencyFormatter}
					parser={currencyParser}
					min={0}
					defaultValue={defaultValue}
					value={value}
					onChange={(e) => onChange(e)}
				/>
			</div>
		</div>
	);
}
