import {
	ILeadEscolaEstagioCleanDTO,
	ILeadEscolaEstagioDTO,
	ILeadEscolaEstagioFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEscolaEstagioService = () => {
	const resourceURL = 'lead-escola-estagio';

	return {
		...baseFindList<ILeadEscolaEstagioCleanDTO, ILeadEscolaEstagioDTO, ILeadEscolaEstagioFullDTO>(resourceURL),
	};
};
