import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
// import exportFromJSON from 'export-from-json';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	ICursoCleanDTO,
	ITurmaCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useCursoService } from '../../../services/curso.service';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useTurmaService } from '../../../services/turma.service';
import { ExportToExcel } from '../../../util/exportToExcel';

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelFinanceiro() {
	const { unidade } = useGlobalContext();

	const [dados, setData] = useState<[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();

	const [dataMatriculaInicio, setDataMatriculaInicio] =
		useState<moment.Moment>();
	const [dataMatriculaFim, setDataMatriculaFim] = useState<moment.Moment>();
	const [dataPagoInicio, setDataPagoInicio] = useState<moment.Moment>();
	const [dataPagoFim, setDataPagoFim] = useState<moment.Moment>();

	// const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>();
	// const [unidadeId, setUnidadeId] = useState<number>(0);

	const [cursos, setCursos] = useState<ICursoCleanDTO[]>();
	const [cursoId, setCursoId] = useState<number>(0);

	const [turmas, setTurmas] = useState<ITurmaCleanDTO[]>();
	const [turmaId, setTurmaId] = useState<number>(0);

	const service = useRelatorioService();
	const cursoService = useCursoService();
	const turmaService = useTurmaService();

	// const exportar = () => {
	//     const fileName = 'download';
	//     const exportType = exportFromJSON.types.html;
	//     exportFromJSON({ data, fileName, exportType });
	// }

	// const findUnidade = () => {
	//     const predicate = new Predicate();
	//     predicate.addSort('descricao');
	//     return unidadeService.findCleanList(predicate)
	//         .then(({ data }) => {
	//             setUnidades(data);
	//         });
	// }

	const findCurso = () => {
		const predicate = new Predicate();
		predicate.addSort('descricao');
		predicate.addOption('cursoUnidades.unidade.id', unidade.id);
		return cursoService.findCleanList(predicate).then(({ data }) => {
			setCursos(data);
		});
	};

	const findTurma = () => {
		const predicate = new Predicate();
		predicate.addSort('codigo');
		predicate.addOption('unidade.id', unidade.id);
		return turmaService.findCleanList(predicate).then(({ data }) => {
			setTurmas(data);
		});
	};

	useEffect(() => {
		if (unidade && unidade.id) {
			findCurso();
			findTurma();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade]);

	useEffect(() => {
		if (unidade && unidade.id) {
			const predicate = new Predicate();

			predicate.addOption('unidadeId', unidade.id);
			turmaId && predicate.addOption('turmaId', turmaId);
			cursoId && predicate.addOption('cursoId', cursoId);
			dataMatriculaInicio &&
				predicate.addOption(
					'dataMatriculaInicio',
					dataMatriculaInicio.toISOString()
				);
			dataMatriculaFim &&
				predicate.addOption('dataMatriculaFim', dataMatriculaFim.toISOString());
			dataPagoInicio &&
				predicate.addOption('dataPagoInicio', dataPagoInicio.toISOString());
			dataPagoFim &&
				predicate.addOption('dataPagoFim', dataPagoFim.toISOString());

			service.financeiros(predicate).then(({ data }) => {
				setData(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		unidade,
		turmaId,
		cursoId,
		dataMatriculaInicio,
		dataMatriculaFim,
		dataPagoInicio,
		dataPagoFim,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	const getPeriodo = (): any => {
		if (dataMatriculaInicio && dataMatriculaFim) {
			return [
				moment(dataMatriculaInicio, 'YYYY-MM-DD'),
				moment(dataMatriculaFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const getPeriod = (): any => {
		if (dataPagoInicio && dataPagoFim) {
			return [
				moment(dataPagoInicio, 'YYYY-MM-DD'),
				moment(dataPagoFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	// const selectUnidades = unidades?.map((unidade) => (<Option key={unidade.id} value={unidade.id}>{unidade.descricao}</Option>))
	const selectCursos = cursos?.map((curso) => (
		<Option key={curso.id} value={curso.id}>
			{curso.descricao}
		</Option>
	));
	const selectTurmas = turmas?.map((turma) => (
		<Option key={turma.id} value={turma.id}>
			{turma.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório financeiro
				</h1>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Curso</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={cursoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setCursoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectCursos}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Turma</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={turmaId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setTurmaId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectTurmas}
						</Select>
					</div>
					<div style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de Pagamentos</span>
						<br />
						<RangePicker
							value={getPeriodo()}
							onChange={(values) => {
								setDataMatriculaInicio(values && values[0]);
								setDataMatriculaFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
							style={{ marginTop: 6 }}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de matrículas</span>
						<br />
						<RangePicker
							value={getPeriod()}
							onChange={(values) => {
								setDataPagoInicio(values && values[0]);
								setDataPagoFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
							style={{ marginTop: 6 }}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel apiData={dados} fileName={'financeiros'} />
					</div>
				</div>
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}

export default RelFinanceiro;
