import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd/lib/radio';
import { useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioContext } from '../../context/UsuarioContext';
import { useUsuarioService } from '../../services/usuario.service';
import ModalCreate from '../ModalCreate';
import { Tags } from '../Tags';
import './style.css';

const MenuDetalheUser: React.FunctionComponent = () => {
	//export default function MenuDetalheUser() {
	const { usuario, removeUsuario } = useUsuarioContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const [criado, setCriado] = useState(false);
	const [atualizado, setAtualizado] = useState(false);
	const userService = useUsuarioService();

	const save = () => {
		setIsGlobalLoading(true);
		if (usuario.id) {
			userService.patchFull(usuario)
				.then(() => {
					removeUsuario();
					setAtualizado(true);
				}).finally(() => setIsGlobalLoading(false));
		} else {
			userService.create(usuario)
				.then(() => {
					removeUsuario();
					setCriado(true);
				}).finally(() => setIsGlobalLoading(false));
		}
	};

	return (
		<div className="menu-box-revisao-float" style={{ marginTop: 20 }}>
			<div className="bloco-1">
				<Unicons.UilEllipsisH
					size="20"
					color="rgba(52, 68, 83, 0.5)"
					className="mas"
				/>
				<h1 id="title">{usuario.pessoa.nome || 'N/A'}</h1>
				<div className="ativo">
					<div className={usuario.ativo ? "dot-verde" : "dot-orange"}></div>
					<span>{usuario.ativo ? 'Ativo' : 'Inativo'}</span>
				</div>
				<h6 style={{ marginBottom: 0 }}>Email Pessoal</h6>
				<span style={{ fontSize: '0.7rem' }}>{usuario.login}</span>
				<div id="boxes-tags-detalhe">
					<Tags tipo={usuario.funcao.descricao || 'N/A'} />
				</div>
				<div id="boxes-tags-detalhe">
					<Tags tipo={usuario.perfil.codigo} />
				</div>
				<Button id="salvar" className="button-primary" onClick={() => save()}>{usuario.id ? "Editar" : "Adicionar"} Usuário</Button>
			</div>
			<ModalCreate
				open={criado || atualizado}
				//onClose={() => setIsOpen(false)}
				title={`Usuário ${criado ? "criado" : "atualizado"} com sucesso`}
				textButton1="Criar outro usuário"
				textButton2="Voltar a página inicial"
				styleButton1="button-line"
				styleButton2="button-primary"
				link1="/usuarios/create/step-1"
				link2="/usuarios"
			/>
		</div>
	);
}

export default MenuDetalheUser;