import { useGlobalContext } from '../context/GlobalContext';
import { PerfilEnum } from '../enum/perfil.enum';

export default function useProfileIsSede() {
	const { usuario } = useGlobalContext();

	const thisProfileIsSede = usuario?.perfil === PerfilEnum.SEDE;

	return thisProfileIsSede;
}
