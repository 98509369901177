import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IMatriculaCancelarDTO,
	IMatriculaCleanDTO,
	IMatriculaCustomDTO,
	IMatriculaDTO,
	IMatriculaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useMatriculaService = () => {
	const resourceURL = 'matricula';

	const findCustom = (
		predicate: Predicate = new Predicate(),
	): Promise<AxiosResponse<IMatriculaCustomDTO[]>> => {
		return http.get<IMatriculaCustomDTO[]>(
			resourceURL + '/custom/', formatHttpParams(predicate)
		);
	};

	const cancelar = (
		id: number,
		isMulta: boolean,
		formCancelar: IMatriculaCancelarDTO,
		usuario: string
	): Promise<IAxiosResponseCustom> =>
		http.post(
			`${resourceURL}/cancelar/${id}/${isMulta}/${usuario}`,
			formCancelar
		);

	const baixarContrato = (matriculaId: number) =>
		http.get(`${resourceURL}/contrato/${matriculaId}`, {
			responseType: 'blob',
		});

	const baixarTermoCancelamento = (matriculaId: number) =>
		http.get(`${resourceURL}/termo-cancelamento/${matriculaId}`, {
			responseType: 'blob',
		});

	const enviarContratoAssinatura = (matriculaId: number, blob: Blob): Promise<IAxiosResponseCustom> =>
		http.post(`${resourceURL}/contrato-assinar/${matriculaId}`, blob);

	const trocarTurma = (body): Promise<IAxiosResponseCustom<IMatriculaCleanDTO>> =>
		http.post(`${resourceURL}/trocar-turma`, body);

	const findUltimaMatriculaRegular = (
		idAluno: number
	): Promise<AxiosResponse<IMatriculaCustomDTO>> =>
		http.get<IMatriculaCustomDTO>(resourceURL + `/ultima-regular/${idAluno}`);

	const findMatriculasEmAndamento = (
		idAluno: number
	): Promise<AxiosResponse<IMatriculaCustomDTO[]>> =>
		http.get<IMatriculaCustomDTO[]>(resourceURL + `/em-andamento/${idAluno}`);

	const verificaCashBackCurta = (
		idAluno: number
	): Promise<AxiosResponse<boolean>> =>
		http.get<boolean>(resourceURL + `/cashback-curta/${idAluno}`);

	const findListMatriculas = (
		idFinanceiro: number
	): Promise<AxiosResponse<IMatriculaDTO[]>> =>
		http.get<IMatriculaDTO[]>(`${resourceURL}/list-matricula/${idFinanceiro}`);

	const findListOutrasMatriculas = (
		idAluno: number,
		idFinanceiro: number
	): Promise<AxiosResponse<IMatriculaDTO[]>> =>
		http.get<IMatriculaDTO[]>(
			`${resourceURL}/list-outras-matricula/${idAluno}/${idFinanceiro}`
		);

	const createMatriculaUpgrade = (
		idFinanceiro: number,
		matriculaCustom: IMatriculaCustomDTO
	): Promise<IAxiosResponseCustom<IMatriculaCleanDTO>> =>
		http.post(
			`${resourceURL}/create-matricula-upgrade/${idFinanceiro}`,
			matriculaCustom
		);

	const findListMatriculasAluno = (
		idAluno: number
	): Promise<AxiosResponse<IMatriculaCustomDTO[]>> =>
		http.get<IMatriculaCustomDTO[]>(`${resourceURL}/list-matricula-aluno/${idAluno}`);

	return {
		...baseFind<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
			resourceURL
		),
		...baseFindById<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
			resourceURL
		),
		...baseFindList<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
			resourceURL
		),
		...baseCreate<IMatriculaCleanDTO, IMatriculaDTO, IMatriculaFullDTO>(
			resourceURL
		),
		createFull: (body): Promise<IAxiosResponseCustom<IMatriculaCleanDTO>> =>
			http.post(`${resourceURL}`, body),
		patchFull: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<IMatriculaCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		findCustom,
		cancelar,
		baixarContrato,
		enviarContratoAssinatura,
		trocarTurma,
		findUltimaMatriculaRegular,
		findMatriculasEmAndamento,
		verificaCashBackCurta,
		baixarTermoCancelamento,
		findListMatriculas,
		findListOutrasMatriculas,
		createMatriculaUpgrade,
		findListMatriculasAluno
	};
};
