import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import { ITipoUnidadeCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useTipoUnidadePessoaService } from '../../services/tipo-unidade-pessoa.service';

interface TFiltros {
	predicate: Predicate;
	findColaborador: () => void;
};
const Filtros = memo(({ predicate, findColaborador }: TFiltros) => {
	const { unidade } = useGlobalContext();

	const [nome, setNome] = useState<string>();
	const [status, setStatus] = useState<number | string>();
	const [funcao, setFuncao] = useState<number | string>();

	const [funcoes, setFuncoes] = useState<ITipoUnidadeCleanDTO[]>();
	const tipoUnidadePessoa = useTipoUnidadePessoaService();

	const selectFuncoes = funcoes?.map((data) => (
		<Select.Option key={data.id} value={data.id}>
			{data.descricao}
		</Select.Option>
	));

	useEffect(() => {
		tipoUnidadePessoa.findCleanList().then(({ data }) => setFuncoes(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			predicate.removeAllOption();
			predicate.addOption('unidadePessoas.unidade.id', unidade.id);

			if (nome) {
				predicate.addOption('nome', nome, Operators.CONTAINS);
			}
			if (funcao) {
				predicate.addOption('unidadePessoas.tipoUnidadePessoaId', funcao);
			}
			if (status === 'TODOS') {
				predicate.removeOption('ativo');
			}
			if (status === 'false') {
				predicate.addOption('ativo', false);
			}
			if (status === 'true') {
				predicate.addOption('ativo', true);
			}
			unidade.id && findColaborador();
		}, 800);
		return () => clearTimeout(typingTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nome, funcao, status]);

	return (
		<>
			<div className="filtros">
				<div className="input-text" style={{ flex: 1 }}>
					<Input
						value={nome}
						placeholder="Buscar Colaborador"
						prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />}
						onChange={(e) => setNome(e.target.value)}
					/>
				</div>
				<div className="filtro-estado" id="input-dashboard">
					<span>Função</span>
					<Select
						className="ant-dropdown-link"
						defaultValue="0"
						style={{ width: 120 }}
						onChange={(e) => setFuncao(parseInt(e))}
					>
						<Select.Option value="0">Todos</Select.Option>
						{selectFuncoes}
					</Select>
				</div>
				<div className="filtro-estado" id="input-dashboard">
					<span>Status</span>
					<Select defaultValue='' onChange={(e) => setStatus(e)}>
						<Select.Option value="TODOS">Todos</Select.Option>
						<Select.Option value="true">Ativo</Select.Option>
						<Select.Option value="false">Inativo</Select.Option>
					</Select>
				</div>
			</div>
		</>
	);
});

Filtros.displayName = 'Filtros';

export default Filtros;
