import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';

interface Props {
	children?: React.ReactNode;
	rota: string;
	style?: React.CSSProperties;
}

const ButtonVoltar: React.FunctionComponent<Props> = ({
	children,
	rota,
	style,
}: Props) => {
	const history = useHistory();
	const [display, setDisplay] = useState<boolean>(false);

	const goToAdd = () => {
		history.push(rota);
	};

	return (
		<div
			style={style}
			className="box-voltar-tela"
			onMouseEnter={() => setDisplay(true)}
			onMouseLeave={() => setDisplay(false)}
			onClick={goToAdd}
		>
			<ArrowCircleLeftOutlined style={{ fontSize: '2.5rem' }} />
			{display ? <p>{children}</p> : null}
		</div>
	);
};

export default ButtonVoltar;
