import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { IUnidadeListRematriculaCustomDTO } from "../../models/happy-code-api.model";
import { daysBetween, daysBetweenBefore, formattedDate } from "../../util/format";

interface Props {
    rematricula: IUnidadeListRematriculaCustomDTO;
}

const ListRematricula: React.FunctionComponent<Props> = ({ rematricula }: Props) => {
    const currentDate = new Date(rematricula.dataMatricula);
    const dataContrato = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    const terminoContrato = dataContrato.toISOString().slice(0, 10);
    const history = useHistory();

    const goToAccess = () => {
        history.push(`/alunos/detalhe/${rematricula.alunoId}`);
    };

    const apresentarDiasVencimento = () => {
        if (daysBetweenBefore(terminoContrato)) {
            return "Vence em " + daysBetweenBefore(terminoContrato) + " Dias"
        } else {
            return "Vencido a " + daysBetween(terminoContrato) + " Dias"
        }
    }

    return (
        <div className="registro-user" id="registro-financeiro" style={{ paddingLeft: 20 }}>
            <div className="user" id="cursos" style={{ width: '20%' }}>
                <div className="nome">
                    <p>{rematricula.alunoNome}</p>
                    <div className="estado-user">
                        <div className={rematricula.ativo ? "dot-verde" : "dot-vermelho"}></div>
                        <p className={rematricula.ativo ? 'ativo' : 'inativo'}>{rematricula.ativo ? 'Ativo' : 'Inativo'}</p>
                    </div>
                </div>
            </div>

            <div className="funcao-user" style={{ width: '20%' }}>
                <p>{rematricula.matriculaId}</p>
            </div>
            <div className="funcao-user" style={{ width: '20%' }}>
                <p>{formattedDate(terminoContrato)}</p>
            </div>
            <div className="funcao-user" style={{ width: '20%' }}>
                <p>{apresentarDiasVencimento()}</p>
            </div>
            <div className="funcao-user" style={{ width: '20%' }}>
                <Button className="button-primary" onClick={goToAccess} >Rematrícular Aluno <ArrowCircleRightIcon style={{ marginLeft: 5 }} /></Button>
            </div>
        </div>
    );
}

export default ListRematricula;