export const LunchBox = () => {
	return (
		<>
			<div className="box-in-calendar-almoco d-flex-col-center-start">
				<p>Almoço</p>
			</div>
			<div className="box-in-calendar-almoco"></div>
			<div className="box-in-calendar-almoco"></div>
			<div className="box-in-calendar-almoco"></div>
			<div className="box-in-calendar-almoco"></div>
			<div className="box-in-calendar-almoco"></div>
			<div className="box-in-calendar-almoco"></div>
		</>
	);
};
