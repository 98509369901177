/* eslint-disable react-hooks/exhaustive-deps */
import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Select, Space, Steps } from "antd";
import { MaskedInput } from 'antd-mask-input';
import 'antd/dist/antd.css';
import { createRef, useEffect, useState } from 'react';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useParams } from 'react-router-dom';
import topo from '../../assets/img/pagamentos/confirmacao.png';
import informacoes from '../../assets/img/pagamentos/informacoes.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { Auth } from '../../models/auth.model';
import { useAuthService } from '../../services/auth.service';
import { useFinanceiroEscolaService } from '../../services/financeiro-escola.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney, isAfterDate, nowDateAddDays } from '../../util/format';
import useCheckoutEscolaState from './selectors/checkout-escola.state';
import './style.css';

// const { Header, Footer, Sider, Content } = Layout;
const { Step } = Steps;
const { Option } = Select;

function CheckoutEscola() {
    const { setIsGlobalLoading } = useGlobalContext();

    const [tamanhoCVV, setTamanhoCVV] = useState<number>();
    const [token, setToken] = useState<Auth>();
    const { cobranca, setCobranca, tiposPagamento } = useCheckoutEscolaState();
    const { idPedido, idTipo } = useParams<any>();
    const financeiroService = useFinanceiroEscolaService();
    const authService = useAuthService();
    const notification = useNotificationService();
    const step = { DADOS: 0, PAGAMENTO: 1, REVISAO: 2, CONCLUSAO: 3 };
    const [current, setCurrent] = useState(step.DADOS);
    const [isPrimeiraEmAberto, setIsPrimeiraEmAberto] = useState<boolean>(false);
    const [isCartaoCredito, setIsCartaoCredito] = useState<boolean>(false);
    const [isPgtoRecorrente, setIsPgtoRecorrente] = useState<boolean>(false);
    const [tipoPagamentoSelecionado, setTipoPagamentoSelecionado] = useState<string>("null");
    const [segmento, setSegmento] = useState<string>("");

    const onChange = current => {
        if (current > step.PAGAMENTO) {
            if (isCartaoCredito) {
                if (!cobranca.cartao.ano) {
                    notification({ description: "Preencha o ano do cartão", type: 'warning', message: 'Aviso!' });
                    return;
                }
                if (!cobranca.cartao.bandeira) {
                    notification({ description: "Preencha a bandeira do cartão", type: 'warning', message: 'Aviso!' });
                    return;
                }
                if (!cobranca.cartao.cvv) {
                    notification({ description: "Preencha o código de segurança do cartão", type: 'warning', message: 'Aviso!' });
                    return;
                }
                if (!cobranca.cartao.mes) {
                    notification({ description: "Preencha o mês do cartão", type: 'warning', message: 'Aviso!' });
                    return;
                }
                if (!cobranca.cartao.nome) {
                    notification({ description: "Preencha o nome do cartão", type: 'warning', message: 'Aviso!' });
                    return;
                }
                if (!cobranca.cartao.numero) {
                    notification({ description: "Preencha o número do cartão", type: 'warning', message: 'Aviso!' });
                    return;
                }
            }
        }
        setCurrent(current);
    }

    const recaptchaRef = createRef<any>();

    useEffect(() => {
        if (cobranca) {

            let descricao = "";

            try {
                descricao = tiposPagamento[tipoPagamentoSelecionado]?.dsPagamento;
            } catch (e) {
                descricao = tipoPagamentoSelecionado;
            }

            const { tipoPagamento, cartao, parcela } = cobranca;
            let tipoPagamentoCartao = null;
            let bandeira = null;
            let qtdParcelas = parcela;

            if (tipoPagamentoSelecionado === "CRC") {
                tipoPagamentoCartao = tipoPagamentoSelecionado;
                bandeira = "Mastercard";
                qtdParcelas = 1;
            } else if (tipoPagamentoSelecionado === "CRR") {
                tipoPagamentoCartao = tipoPagamentoSelecionado;
                bandeira = "Mastercard";
            }

            setCobranca({
                ...cobranca,
                tipoPagamento: { ...tipoPagamento, codigo: tipoPagamentoSelecionado, descricao },
                cartao: { ...cartao, tipo: tipoPagamentoCartao, bandeira },
                parcela: qtdParcelas
            });

        };
        setIsPgtoRecorrente(tipoPagamentoSelecionado == "CRR");
        setIsCartaoCredito("CRC;CRR".includes(tipoPagamentoSelecionado));
    }, [tipoPagamentoSelecionado]);

    function handleChange(value) {
        setCobranca({ ...cobranca, parcela: value })
    }

    function onChangeNumber(numero) {
        const { cartao } = cobranca;
        setCobranca({ ...cobranca, cartao: { ...cartao, numero } })
    }

    function onChangeBrand(bandeira) {
        if (bandeira === 'Amex') {
            setTamanhoCVV(4);
        } else {
            setTamanhoCVV(3);
        }
        const { cartao } = cobranca;
        setCobranca({ ...cobranca, cartao: { ...cartao, bandeira } })
    }

    function onChangeName(nome) {
        const { cartao } = cobranca;
        setCobranca({ ...cobranca, cartao: { ...cartao, nome } })
    }

    function onChangeMonth(mes) {
        const { cartao } = cobranca;
        setCobranca({ ...cobranca, cartao: { ...cartao, mes } })
    }

    function onChangeYear(ano) {
        const { cartao } = cobranca;
        setCobranca({ ...cobranca, cartao: { ...cartao, ano } })
    }

    function onChangeCvv(cvv) {
        const { cartao } = cobranca;
        setCobranca({ ...cobranca, cartao: { ...cartao, cvv } })
    }

    useEffect(() => {
        if (cobranca?.url != null && cobranca?.tipoPagamento?.codigo === "BOL") {
            window.location.assign(cobranca?.url);
        }
    }, [cobranca?.url])

    const finaliza = async () => {
        setIsGlobalLoading(true);

        if (!cobranca.tipoPagamento?.codigo) {
            notification({ description: "Selecione a forma de pagamento", type: 'warning', message: 'Aviso!' });
            return;
        }

        const captcha = await recaptchaRef.current.getValue();
        const environment = process.env.NODE_ENV;

        if (environment === 'development' || captcha) {
            financeiroService
                .pagarme(cobranca, token.access_token)
                .then(({ data }) => {
                    const { url, statusCobranca, acquirerReturnCode, qrCode } = data;
                    setCobranca({ ...cobranca, url, statusCobranca, acquirerReturnCode, qrCode });

                    if (!("AGR,FLH").includes(data.statusCobranca)) {
                        setCurrent(step.CONCLUSAO);
                    }

                    if (data.statusCobranca === "GRD") {
                        const interval = setInterval(() => {

                            financeiroService.findStatus(data.id, token.access_token).then(
                                ({ data }) => {
                                    if (data === 'PGO') {
                                        setCobranca({ ...cobranca, statusCobranca: data });
                                        clearInterval(interval);
                                    }
                                    if (cobranca?.url != null && cobranca.tipoPagamento.codigo === "BOL") {
                                        window.location.assign(cobranca.url);
                                        clearInterval(interval);
                                    }
                                }
                            )

                        }, 10000);
                    }
                    if (data.acquirerReturnCode && data.statusCobranca !== "PGO"
                        && (isCartaoCredito)) {

                        switch (data.acquirerReturnCode) {
                            case "0000":
                                Modal.error({ content: "Pagamento não autorizado (Antifraude)", title: 'Aviso!' });
                                break;

                            case "1000":
                                Modal.error({ content: "Transação não autorizada, oriente o portador a contatar o banco/emissor do cartão", title: 'Aviso!' });
                                break;

                            case "2002":
                                Modal.error({ content: "Transação com suspeita de fraude", title: 'Aviso!' });
                                break;

                            case "1001":
                                Modal.error({ content: "Pagamento não autorizado (Cartão vencido, a data de vencimento do cartão expirou)", title: 'Aviso!' });
                                break;

                            case "1011":
                                Modal.error({ content: "Pagamento não autorizado (Cartão inválido)", title: 'Aviso!' });
                                break;

                            case "1016":
                                Modal.error({ content: "Pagamento não autorizado (Saldo insuficiente)", title: 'Aviso!' });
                                break;

                            case "1022":
                                Modal.error({ content: "Pagamento não autorizado (Violação de segurança)", title: 'Aviso!' });
                                break;

                            default:
                                Modal.error({ content: "Pagamento não autorizado.", title: 'Aviso!' });
                                break;
                        }

                    }
                })
                .finally(() => setIsGlobalLoading(false))
        } else {
            setIsGlobalLoading(false)
            notification({ description: "reCaptcha Inválido", type: 'warning', message: 'Aviso!' });
        }

    }

    useEffect(() => {
        const isencao = location.search.substring(1);

        setIsGlobalLoading(true);
        authService.basic()
            .then(({ data }) => {
                setToken(data);
                return data;
            })
            .then(async (responseToken) => {

                const { data } = await financeiroService
                    .findCobrancaPagarme(idPedido, isencao, responseToken.access_token);

                if (data !== null) {

                    const isCobrancaUnica = data?.cobrancaUnica;

                    if (isAfterDate(data.dataVencimento)) //vencido
                        data.dataVencimento = nowDateAddDays(3);

                    if (!data.cartao)

                        data.cartao = {
                            tipo: isCobrancaUnica ? "CRR" : "CRC",
                            ano: null,
                            bandeira: null,
                            cvv: null,
                            mes: null,
                            nome: null,
                            numero: null
                        };

                    setIsPrimeiraEmAberto(data.primeiraCobrancaEmAberto);
                    data.tipoPagamento = null;
                    setCobranca(data);

                    let segmento = "";

                    if (data.contrato.valorLicencaIntra)
                        segmento = segmento + "intracurricular"

                    if (data.contrato.valorLicencaExtra)
                        segmento = segmento + " extracurricular"

                    if (data.contrato.valorServico)
                        segmento = segmento + " serviços acadêmicos"

                    segmento = segmento.trim();
                    segmento.replace(" ", ", ");
                    setSegmento(segmento);
                } else {
                    notification({ description: "Cobrança não encontrada", type: 'warning', message: 'Aviso!' });
                }
            })
            .finally(() => setIsGlobalLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const copiarLink = async () => {
        navigator.clipboard.writeText(cobranca.qrCode);
        notification({ description: "", type: 'success', message: 'Qr Code Copiado' });
    }

    const renderAviso = (aviso) => {
        return <div className='container-aviso-recorrencia-ativa'>
            <span className='texto-aviso-recorrencia-ativa'>{aviso}</span>
        </div>
    }

    const dadosCartao = () => {

        return (

            <Form
                name="basic"
                labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off">

                <h3>Dados do Cartão</h3>
                {cobranca?.tipoPagamento?.codigo === 'CRC' && <div className="input-franquia" id="box-pedido">
                    <h5>Quantidade de Parcelas</h5>
                    <Form.Item
                        name="e"
                        rules={[{ required: true, message: '' }]}
                        style={{ width: '100%' }}
                    >
                        <Select defaultValue="1" style={{ width: '100%' }} onChange={handleChange}>
                            {!cobranca.cobrancaUnica ?
                                <Option value="1">1</Option>
                                : <>
                                    {Array.from(Array(cobranca?.quantidadeParcelas), (e, i) => i + 1).map((plPagamento, index) => (
                                        cobranca?.quantidadeParcelas >= index ? (
                                            <Select.Option
                                                key={index + 1}
                                                value={index + 1}
                                            >
                                                {index + 1}x
                                            </Select.Option>
                                        ) : null))}
                                </>
                            }
                        </Select>
                    </Form.Item>
                </div>}
                <div className="justify">
                    <div style={{ width: '55%' }}>
                        <div className="input-franquia" id="box-pedido" >
                            <h5>Número de Cartão</h5>

                            <Form.Item
                                name="q"
                                rules={[{ required: true, message: '' }]}
                                initialValue={cobranca?.cartao?.numero}>

                                <MaskedInput
                                    mask="1111 1111 1111 1111"
                                    name="card"
                                    onChange={(e) => onChangeNumber(e.target.value)} />

                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ width: '35%' }}>
                        <div className="input-franquia" id="box-pedido">
                            <h5>Bandeira</h5>

                            <Form.Item
                                name="r"
                                rules={[{ required: true, message: '' }]}
                                initialValue={cobranca.cartao.bandeira}>

                                <Select defaultValue="" size='small' onChange={onChangeBrand}>
                                    <Option value="Mastercard">Mastercard</Option>
                                    <Option value="Visa">Visa</Option>
                                    <Option value="Diners">Diners Club</Option>
                                    <Option value="Amex">American Express</Option>
                                    <Option value="Elo">Elo</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="input-franquia" id="box-pedido">
                    <h5>Nome como está no Cartão</h5>

                    <Form.Item
                        name="w"
                        rules={[{ required: true, message: 'Campo Obrigatório' }]}
                        initialValue={cobranca.cartao.nome}>

                        <Input onChange={(e) => onChangeName(e.target.value)} />
                    </Form.Item>
                </div>
                <div className="justify">
                    <div style={{ width: '33%' }}>
                        <div className="input-franquia" id="box-pedido">
                            <h5>Mês</h5>

                            <Form.Item
                                name="mes"
                                rules={[{ required: true, message: 'Campo Obrigatório', len: 2 }]}
                                initialValue={cobranca.cartao.mes}>

                                <MaskedInput mask="11" name="MM" onChange={(e) => onChangeMonth(e.target.value)} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ width: '33%' }}>
                        <div className="input-franquia" id="box-pedido">
                            <h5>Ano</h5>

                            <Form.Item
                                name="ano"
                                rules={[{ required: true, message: 'Campo Obrigatório', len: 2 }]}
                                initialValue={cobranca.cartao.ano}>

                                <MaskedInput mask="11" name="AA" onChange={(e) => onChangeYear(e.target.value)} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ width: '33%' }}>
                        <div className="input-franquia" id="box-pedido">
                            <h5>CVV</h5>
                            <Form.Item
                                name="b"
                                rules={[{ required: true, message: 'Campo Obrigatório', len: tamanhoCVV }]}
                            >
                                <MaskedInput mask={tamanhoCVV == 4 ? "1111" : "111"} name="CVV" onChange={(e) => onChangeCvv(e.target.value)} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        )
    }

    if (!cobranca) return null;

    const editarCartaoAssinatura = () => {
        setIsGlobalLoading(true);
        financeiroService.editarCartaoAssinatura(cobranca.id, cobranca.cartao)
            .then(() => {
                notification({ description: "Sucesso na alteração do cartão!", type: 'success', message: 'Sucesso!' });
                setCurrent(step.CONCLUSAO)
            })
            .finally(() => {
                setIsGlobalLoading(false);
            });

    }

    const nodeIdentificacao = () => {

        return (
					<>
						<h2>Identificação</h2>
						<div className="input-franquia" id="box-pedido">
							<UserOutlined rev={undefined} className="icon-pedido" />
							<h3>Grupo</h3>
							<p>Nome: {cobranca?.grupo.nomeFantasia}</p>
							<h3>Responsável</h3>
							<p>Nome: {cobranca?.responsavel.nome}</p>
							<p>Contato: {cobranca?.responsavel.email}</p>
						</div>
					</>
				);

    }

    const nodePagamento = () => {

        return (
            <>
                <h2>Pagamento</h2>
                <div className="input-franquia" id="box-pedido">
                    <h3>{cobranca.tipoPagamento.descricao}</h3>
                    <p>{`${formatMoney(cobranca.valorCobranca)} em ${cobranca.cobrancaUnica ? cobranca.parcela : 1} parcela(s)`}</p>
                </div>
                <div className="catpcha">
                    <ReCaptchaV2 ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} />
                </div>
                <p className="parrafo-pedido">* O Código PIX e Boleto para o pagamento será gerado e exibido na tela após a conclusão do pedido.</p>
            </>
        );

    }

    const divNovocartao = () => {

        return (
            <div className="radio-pedido">
                <Space direction="vertical" style={{ width: '100%' }}>

                    {isCartaoCredito
                        &&
                        (isPgtoRecorrente ?
                            (isPrimeiraEmAberto ?
                                dadosCartao() :

                                renderAviso(
                                    "O pagamento recorrente deve ser ativado somente na primeira "
                                    + "parcela em aberto.")

                            ) :
                            dadosCartao()
                        )
                    }

                </Space>
            </div>
        );

    }

    const opcoesPagamento = () => {

        return (
            <div className="radio-pedido">
                <Radio.Group onChange={(e) => setTipoPagamentoSelecionado(e.target.value)} value={cobranca?.tipoPagamento?.codigo} style={{ width: '100%' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>

                        {/* {
                            !cobranca?.cobrancaUnica
                            && cobranca.quantidadeParcelas > 1
                            &&
                            <div className="radio-opcao" onClick={() => setTipoPagamentoSelecionado("CRR")}>
                                <Radio value='CRR'>Crédito Recorrente</Radio>
                            </div>
                        }
                        <div className="radio-opcao" onClick={() => setTipoPagamentoSelecionado("CRC")}>
                            <Radio value='CRC'>Cartão de Crédito</Radio>
                        </div> */}

                        <div className="radio-opcao" onClick={() => setTipoPagamentoSelecionado("BOL")}>
                            <Radio value='BOL'>Boleto</Radio>
                        </div>
                        <div className="radio-opcao" onClick={() => setTipoPagamentoSelecionado("PIX")}>
                            <Radio value='PIX'>PIX</Radio>
                        </div>
                        {divNovocartao()}
                    </Space>
                </Radio.Group>
            </div>
        );

    }

    const nodeBotoes = () => {

        return (
            <div className="botoes" id="botoes-pedido">
                {isPgtoRecorrente && idTipo === "novo-cartao" ?
                    <>
                        {idTipo === "novo-cartao" && <button onClick={() => editarCartaoAssinatura()} className="button-primary" style={{ width: 100 }}><span>Enviar</span></button>}
                    </> :
                    <>
                        {current > step.DADOS
                            &&

                            <Button
                                onClick={() => onChange(current - 1)}
                                className="button-second"
                                id="voltar"
                                style={{ width: 100, marginRight: 10 }}>

                                Voltar
                            </Button>
                        }
                        {current !== step.REVISAO && <button onClick={() => onChange(current + 1)} id="proximo" className="button-primary" style={{ width: 100 }}><span>Avançar</span></button>}
                        {current === step.REVISAO && <button onClick={() => finaliza()} className="button-primary" style={{ width: 100 }}><span>Concluir</span></button>}
                    </>
                }
            </div>
        );

    }

    const nodeDados = () => {

        return (<>
            <div className="content-left">
                <Steps current={current} onChange={onChange} responsive={true}>
                    {isPgtoRecorrente && idTipo === "novo-cartao" ?
                        <>
                            <Step status={idTipo === "novo-cartao" ? "process" : "wait"} title="Editar Cartão" />
                        </> :
                        <>
                            <Step status={current > step.DADOS ? "finish" : current < step.DADOS ? "wait" : "process"} title="Dados" />
                            <Step status={current > step.PAGAMENTO ? "finish" : current < step.PAGAMENTO ? "wait" : "process"} title="Pagamento" />
                            <Step status={current > step.REVISAO ? "finish" : current < step.REVISAO ? "wait" : "process"} title="Revisão" />
                        </>
                    }
                </Steps>

                {isPgtoRecorrente && idTipo === "novo-cartao" ?
                    divNovocartao() :
                    current === step.DADOS || current === step.REVISAO ?
                        <>
                            {nodeIdentificacao()}
                            {cobranca.tipoPagamento && current === step.REVISAO && nodePagamento()}
                        </> :
                        current === step.PAGAMENTO && opcoesPagamento()
                }

                {current !== step.CONCLUSAO && nodeBotoes()}
            </div>
            <div className="content-right">
                <h2>Pedido</h2>
                <div className="pedido">
                    <p>Contrato: {cobranca?.contrato?.descricao}</p>
                    <br /><p>Segmento: {segmento}</p>
                </div>
                <hr />
                <div className="pedido-total valores">
                    <p>Valor original</p><span>{formatMoney(cobranca.valorOriginal)}</span>
                </div>
                <div className="pedido-total valores">
                    <p>Encargos de mora</p><span>{formatMoney(cobranca.valorEncargos || 0)}</span>
                </div>
                <div className="pedido-total">
                    <p>Total</p>
                    <span>{formatMoney(cobranca.valorCobranca)}</span>
                </div>

                {/* <div className="pedido-total valores">
                    <p>Vencimento</p><span>{formattedDate(cobranca.dataVencimento)}</span>
                </div> */}

            </div>
        </>);

    }

    const nodeQRCode = () => {

        return (<>
            <h2>Chave PIX!</h2>
            <img src={cobranca.url} />
            <Button type="primary" onClick={copiarLink}>Copiar Qr Code</Button>
        </>);

    }

    const nodeCobrancaGerada = () => {

        return (<>
            <h2>Cobrança Gerada!</h2>
            {cobranca.tipoPagamento?.codigo === "BOL" ?
                <>
                    <a href={cobranca.url}>Click para abrir o Link do Boleto</a>
                    <p>Ou aguarde o redirecionamento.</p>
                </>
                :
                cobranca.tipoPagamento?.codigo === "PIX" && nodeQRCode()
            }
        </>);

    }

    return (
        <div className="layout-checkout">
            <div className="content-body">
                {cobranca.statusCobranca === 'PGO' ? <img src={informacoes} /> : <img src={topo} />}
            </div>
            {cobranca.statusCobranca !== "PGO"
                &&
                <div className="content-body">
                    {current !== step.CONCLUSAO && nodeDados()}
                    {current === step.CONCLUSAO
                        &&
                        <div className="content-center">
                            {idTipo === "novo-cartao" ?
                                <h2>Cartão alterado com sucesso!</h2> :
                                cobranca.statusCobranca === "GRD" ?
                                    nodeCobrancaGerada() :
                                    cobranca.statusCobranca === "IST" ? <h3>Cobrança Isenta!</h3> : null
                            }
                            <div className="line"></div>
                        </div>
                    }
                </div>
            }
        </div >
    )

}

export default CheckoutEscola
