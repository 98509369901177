import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import DivStatusCobranca from "../../components/DivStatusCobranca";
import { IUnidadeListInadimplenteCustomDTO } from "../../models/happy-code-api.model";
import { formatMoney, formattedDate } from "../../util/format";

interface Props {
    inadimplente: IUnidadeListInadimplenteCustomDTO;
}

const ListInadimplencia: React.FunctionComponent<Props> = ({ inadimplente }: Props) => {
    const history = useHistory();

    const goToAccess = () => {
        history.push(`/financeiro/${inadimplente.alunoNome}`);
    };

    return (
        <div className="registro-user" id="registro-financeiro" style={{ paddingLeft: 20 }}>
            <div className="user" id="cursos" style={{ width: '25%' }}>
                <div className="nome">
                    <p>{inadimplente.alunoNome}</p>
                    <div className="estado-user">
                        <div className={inadimplente.ativo ? "dot-verde" : "dot-vermelho"}></div>
                        <p className={inadimplente.ativo ? 'ativo' : 'inativo'}>{inadimplente.ativo ? 'Ativo' : 'Inativo'}</p>
                    </div>
                </div>
            </div>
            <div className="funcao-user" style={{ width: '25%' }}>
                <p>{inadimplente.numeroParcela}</p>
            </div>
            <div className="funcao-user" style={{ width: '25%' }}>
                <p>{formatMoney(inadimplente.valorCobranca)}</p>
            </div>
            <div className="funcao-user" style={{ width: '25%' }}>
                <Button className="button-primary" onClick={goToAccess} >Acessar Financeiro <ArrowCircleRightIcon style={{ marginLeft: 5 }} /></Button>
            </div>
        </div>
    );
}

export default ListInadimplencia;