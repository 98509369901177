import * as Unicons from '@iconscout/react-unicons';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatMoney } from '../../util/format';
import { INegocioUnidadeDTO } from '../../models/happy-code-api.model';

interface TItemLista {
    negocioUnidade: INegocioUnidadeDTO;
}

const ItemListNegocio = memo(({ negocioUnidade }: TItemLista) => {
    const [ativo, setAtivo] = useState<boolean>();
    const history = useHistory();
    
    useEffect(() => {
        setAtivo(negocioUnidade.ativo);
    }, [negocioUnidade.ativo])

    const goTo = () => {
        history.push(`/negocio-oferta/step-3/${negocioUnidade.id}`)
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <div className="nome">
                    <p>{negocioUnidade.unidade.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}>
                <span>{formatMoney(negocioUnidade.valorHora)}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}>
                <span>{formatMoney(negocioUnidade.valorHoraRematricula)}</span>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: "30%" }}></div>
            <div className="registro-opcao" style={{ width: "10%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
            </div>
        </div>
    )
})

ItemListNegocio.displayName = 'ItemListNegocio';


export default ItemListNegocio;
