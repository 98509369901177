import { useCallback, useEffect, useMemo, useState } from 'react';
import { Predicate } from '../../../models/predicate.model';
import { useStatusEscolaService } from '../../../services/status-escola.service';
import { useUnidadeContext } from '../../../context/UnidadeContext';

type SchoolStatusNames =
	| 'Em Implantação'
	| 'Ativo'
	| 'Encerrando'
	| 'Finalizando'
	| 'Distratado';

interface SchoolStatusList {
	ativo: boolean;
	id: number;
	descricao: SchoolStatusNames;
}

export default function useFindSchoolStatus() {
	const schoolStatusLimit = 100;
	const predicate = useMemo(() => new Predicate(schoolStatusLimit), []);

	const { findClean } = useStatusEscolaService();

	const [schoolStatusList, setSchoolStatusList] =
		useState<SchoolStatusList[]>();

	const findSchoolStatus = useCallback(async () => {
		return await findClean(predicate).then(({ data }) => {
			const getContentList = data.content as unknown as SchoolStatusList[];

			setSchoolStatusList(getContentList);
		});
	}, []);

	const { removeUnidade, setRevisao } = useUnidadeContext();

	useEffect(() => {
		const controller = new AbortController();
		findSchoolStatus();
		removeUnidade();
		setRevisao(false);
		return () => controller.abort();
	}, [findSchoolStatus]);

	return {
		schoolStatusList,
	};
}
