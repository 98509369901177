import { useCallback, useEffect, useState } from 'react';
import { useCobrancaService } from '../../services/cobranca.service';
import { ICobrancaDTO } from '../../models/happy-code-api.model';
import { useGlobalContext } from '../../context/GlobalContext';

interface UseGetCobrancasByIdFinanceiroProps {
	idFinanceiro: string;
}

export default function useGetCobrancasByIdFinanceiro({
	idFinanceiro,
}: UseGetCobrancasByIdFinanceiroProps) {
	const { setIsGlobalLoading } = useGlobalContext();

	const { findCobrancasByIdFinanceiro } = useCobrancaService();

	const [cobrancasByIdFinanceiro, setCobrancasByIdFinanceiro] = useState<
		ICobrancaDTO[]
	>([]);

	const getCobrancasByIdFinanceiro = useCallback(async () => {
		if (!idFinanceiro) return;

		setIsGlobalLoading(true);

		await findCobrancasByIdFinanceiro(parseInt(idFinanceiro))
			.then(({ data }) => setCobrancasByIdFinanceiro(data))
			.catch((error) => {
				setIsGlobalLoading(false);
				console.error(error);
			})
			.finally(() => setIsGlobalLoading(false));
	}, [idFinanceiro]);

	useEffect(() => {
		const controller = new AbortController();

		getCobrancasByIdFinanceiro();

		return () => controller.abort();
	}, [getCobrancasByIdFinanceiro]);

	return {
		cobrancasByIdFinanceiro,
	};
}
