import { useContext, useEffect, useState } from "react";
import { useEscolaContext } from "../../../../../context/EscolaContext";
import { useGlobalContext } from "../../../../../context/GlobalContext";
import { Predicate } from "../../../../../models/predicate.model";
import { useGrupoService } from "../../../../../services/grupo.service";
import { useCrmEscolaContext } from "../../../context/crm-escola.context";

const useNovoLeadStep1ComponentState = () => {
  const [state, setState] = useState();
  const [grupos, setGrupos] = useState<any>();
  const { leadParaCadastrar, setLeadParaCadastrar, onFecharModal, setIndexCadastro, indexCadastro } = useCrmEscolaContext();
  const { categoriasQuantidadeAlunos } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const grupoService = useGrupoService();



  const fetchGrupos = () => {
    setIsGlobalLoading(true);
    const predicate = new Predicate();
    predicate.addOption('ativo', true);

    grupoService.findList().then((response) => {
      setGrupos(response.data);
    }).finally(() => {
      setIsGlobalLoading(false);
    })
  }

  useEffect(() => {
    fetchGrupos();
  }, [])

  const onFinish = (form) => {
    setLeadParaCadastrar((state) => ({ ...state, ...form }));
    setIndexCadastro(indexCadastro + 1);
  }

  return {
    state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
  }
}

export default useNovoLeadStep1ComponentState;
