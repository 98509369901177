import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useTaxaFixaContext } from '../../context/TaxaContext';

import { currencyFormatter, currencyParser } from '../../util/format';
import StepsCreate from './steps-create';
import './style.css';

const TaxasCreateStep3: React.FunctionComponent = () => {

  const { taxaFixa, setTaxaFixa, revisao } = useTaxaFixaContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={60} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={2} />
      <div className="franquia">
        <div className="user-row">
          <h3 id="taxas-h3">Insira o preço da taxa</h3>
          <Form
            name="basic"
            // labelCol={{ span: 16 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
          >
            <Form.Item
              rules={[{ required: true, message: 'Insira o valor!' }]}
            >
              <div className="input-box">
                <InputNumber
                  id="valor"
                  name="valor"
                  style={{ width: '100%' }}
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  value={taxaFixa?.valor}
                  min={0}
                  onChange={value => setTaxaFixa({ ...taxaFixa, valor: value })}
                />
              </div>
            </Form.Item>
            <div className="botoes">
              {revisao ?
                <Link to="/taxas/create/step-6">
                  <Button id="revisao" type="primary" className="button-primary" style={{ width: 700 }}>Voltar para Revisão</Button>
                </Link>
                : <>
                  <Link to="/taxas/create/step-2">
                    <Button id="voltar" className="button-second" style={{ width: 345 }}>Voltar</Button>
                  </Link>
                  <Link to="/taxas/create/step-4">
                    <Button id="proximo" type="primary" htmlType="submit" className="button-primary" style={{ width: 345 }}>Próximo</Button>
                  </Link>
                </>
              }
            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa taxa?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-primary'
        link2='/taxas'
      />
    </div>
  )
}

export default TaxasCreateStep3;