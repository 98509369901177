/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import ModalCreate from '../../../../components/ModalCreate/index';
import { useEscolaContext } from '../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Predicate } from '../../../../models/predicate.model';
import { useFinanceiroEscolaService } from '../../../../services/financeiro-escola.service';
import { useGrupoContratoService } from '../../../../services/grupo-contrato.service';
import { useNotificationService } from '../../../../services/notification.service';
import './_menu-revisao-contrato.scss';

interface MenuRevisaoContratoProps {
	limparRevisao: () => void;
}

export function MenuRevisaoContratoComponent({
	limparRevisao,
}: MenuRevisaoContratoProps) {
	const {
		contratoParaCadastrar,
		setContratoParaCadastrar,
		limparContratoParaCadastrar,
	} = useEscolaContext();
	const { setIsGlobalLoading } = useGlobalContext();
	const [isCopiandoLink, setIsCopiandoLink] = useState<boolean>(false);
	const [isAcessandoCheckout, setIsAcessandoCheckout] =
		useState<boolean>(false);
	const [isFechando, setIsFechando] = useState<boolean>(false);
	const [idGrupoTemp, setIdGrupoTemp] = useState<number>();
	const contratoService = useGrupoContratoService();
	const financeiroService = useFinanceiroEscolaService();
	const notification = useNotificationService();

	useEffect(() => {
		if (isFechando) {
			limparContratoParaCadastrar();
			limparRevisao();
		}
	}, [isFechando]);

	const salvar = () => {
		setIsGlobalLoading(true);

		if (contratoParaCadastrar?.id) {
			return contratoService
				.patchFull(contratoParaCadastrar)
				.then((response) => {
					if (response.status >= 200 && response.status <= 299) {
						setIdGrupoTemp(contratoParaCadastrar.grupo.id);
						setIsCopiandoLink(true);
					}
				})
				.finally(() => {
					setIsGlobalLoading(false);
				});
		}

		contratoService
			.createFull({
				...contratoParaCadastrar,
				grupo: {
					id: contratoParaCadastrar?.grupo?.id,
				},
			})
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					setIdGrupoTemp(contratoParaCadastrar.grupo.id);
					setContratoParaCadastrar(response.data);
					setIsCopiandoLink(true);
				}
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const goToCheckout = () => {
		setIsFechando(false);

		const predicate = new Predicate();

		predicate.addOption('contratoId', contratoParaCadastrar.id);
		predicate.addOption('vencimento', contratoParaCadastrar.vencimentoInicial);

		financeiroService.findCleanList(predicate).then(({ data }) => {
			window.open('/checkout-escola/' + data[0].pedido);
			setIsAcessandoCheckout(false);
			setIsFechando(true);
		});
	};

	const copiarLink = async () => {
		setIsGlobalLoading(true);

		const predicate = new Predicate();

		predicate.addOption('contratoId', contratoParaCadastrar.id);
		predicate.addSort('vencimento');
		financeiroService
			.findClean(predicate)
			.then(({ data }) => {
				if (data.numberOfElements > 0) {
					navigator.clipboard.writeText(
						location.host + '/checkout-escola/' + data.content[0].pedido
					);
					notification({
						description: 'Cópia de link',
						type: 'success',
						message: 'Link copiado',
					});
					setIsCopiandoLink(false);
					setIsAcessandoCheckout(true);
				}
			})
			.finally(() => setIsGlobalLoading(false));
	};

	return (
		<>
			<div className="menu-revisao-contrato">
				<div className="menu-revisao-wrapper__text-container">
					<label className="text-container__label">Nome do Grupo</label>
					<h1 className="text-container__titulo">
						{contratoParaCadastrar?.grupo?.nomeFantasia}
					</h1>
				</div>
				<div className="menu-revisao-wrapper__text-container">
					<label className="text-container__label">CNPJ</label>
					<p className="text-container__titulo-secundario">
						{contratoParaCadastrar?.grupo?.cnpj}
					</p>
				</div>
				<div className="menu-revisao-wrapper__text-container">
					<label className="text-container__label">Descrição</label>
					<p className="text-container__titulo-secundario">
						#{contratoParaCadastrar?.id} {contratoParaCadastrar?.descricao}
					</p>
				</div>
				{!contratoParaCadastrar?.id && (
					<Button
						id="salvar"
						style={{ width: 250, cursor: 'pointer' }}
						className="button-primary"
						onClick={() => salvar()}
					>
						Salvar Contrato
					</Button>
				)}
			</div>

			<Modal
				open={isCopiandoLink}
				onClose={() => {
					setIsCopiandoLink(false);
					setIsAcessandoCheckout(true);
				}}
				title="Contrato salvo com sucesso!"
				subtitle="Deseja copiar o link do checkout?"
				textButton1="Não"
				styleButton1="button-line-red"
				textButton2="Sim"
				styleButton2="button-second"
				button2={copiarLink}
			/>

			<Modal
				open={isAcessandoCheckout}
				onClose={() => {
					setIsAcessandoCheckout(false);
					setIsFechando(true);
				}}
				title="Contrato salvo com sucesso!"
				subtitle="Deseja efetuar o checkout agora?"
				textButton1="Agora não"
				styleButton1="button-line-red"
				textButton2="Sim"
				styleButton2="button-second"
				button2={goToCheckout}
			/>

			<ModalCreate
				title={`Contrato salvo com sucesso!`}
				textButton1="Voltar a listagem"
				textButton2="Criar outro contrato"
				link1={`/escolas/grupos/${idGrupoTemp}/contratos`}
				link2={`/escolas/grupos/${idGrupoTemp}/contratos/create/step-1`}
				open={isFechando}
				styleButton1="button-line"
				styleButton2="button-primary"
			/>
		</>
	);
}
