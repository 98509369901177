import * as Unicons from '@iconscout/react-unicons';
import { Rating } from "@material-ui/lab";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useGlobalContext } from '../../../../context/GlobalContext';
import { ILeadEscolaFullDTO, ILogCleanDTO } from "../../../../models/happy-code-api.model";
import { useLogService } from '../../../../services/log.service';
import useTipoRetornoState from '../../selectors/tipo-retorno.state';
import '../../shared.scss';
import { LeadDetalhe } from "../Modal/lead-detalhe/lead-detalhe";
import './style.scss';

interface Props {
  type?: string,
  lead: ILeadEscolaFullDTO,
}

export const Lead = (props: Props) => {

  const [isOpenLeadDetalhe, setisOpenLeadDetalhe] = useState(false);
  const [lead, setLead] = useState(props.lead);
  const { usuario } = useGlobalContext();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso detalhe lead b2b",
    descricaoTipo: "Acesso a tela",
    idTipo: lead.id,
    id: null,
    descricaoUsuario: usuario.usuario,
    ativo: true
  });

  const onChangeLeadDetalhe = () => {
    if (isOpenLeadDetalhe === true) {
      setisOpenLeadDetalhe(false)
    } else {
      setisOpenLeadDetalhe(true)
      logService.registraLog(log)      
    }
  }

  const onCloseLeadDetalhe = () => {
    setisOpenLeadDetalhe(false)
  }

  useEffect(() => {
    setLead({ ...props.lead });
  }, [props.lead]);

  const { getTipoRetorno,
    getDsLabelTipoRetorno,
    getBoxColorTipoRetorno, } = useTipoRetornoState();
  const type = getTipoRetorno(lead);

  const isPossuiAnotacoesAgendamentosFuturos = () => {
    return lead.anotacoes?.some((anotacao) => moment(anotacao.dataRetorno).startOf('day').isAfter(moment().startOf('day')));
  }

  return (<><div onClick={onChangeLeadDetalhe} className={`lead-${type}`}>
    <Unicons.UilDraggabledots className="dragg" />
    <h3>{lead?.nome}</h3>
    <div className="status">{getDsLabelTipoRetorno(type)}</div>
    <h3>{lead?.nomeContato}</h3>
    <h5>{lead?.campanha?.descricao}</h5>
    {lead?.dataProximoRetorno && <h5>{moment(lead?.dataProximoRetorno).format('DD/MM/YYYY hh:mm:ss')}</h5>}
    <div className="line-lead"></div>
    <div className="icons flex flex-row justify-end align-center w-100">
      <div>
        <Rating
          name="Avaliação do Lead"
          value={lead?.avaliacaoLead}
          disabled
        />
      </div>
      {isPossuiAnotacoesAgendamentosFuturos() && <Unicons.UilFileAlt color="var(--verde-futuro)" size={24} />}
      <Unicons.UilSync color="var(--cinza-azulado-200)" size={22} />
    </div>
  </div>
    <LeadDetalhe
      open={isOpenLeadDetalhe}
      onClose={onCloseLeadDetalhe}
      type={type === 'sem' ? '' : '-' + type}
      boxColor={getBoxColorTipoRetorno(type)}
      lead={lead}
    />
  </>);
}
