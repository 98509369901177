import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import ModalCreate from '../../components/ModalCreate/index';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTaxaFixaContext } from '../../context/TaxaContext';
import { useTaxaFixaService } from '../../services/taxa-fixa.service';
import { formattedDate } from '../../util/format';
import './style.css';

interface Props {
  id?: number;
}

export const MenuDetalheTaxa: React.FunctionComponent<Props> = ({ id }: Props) => {

  const { taxaFixa, removeTaxaFixa, setRevisao } = useTaxaFixaContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [isOpen, setIsOpen] = useState(false);
  const service = useTaxaFixaService();

  const save = () => {
    setIsGlobalLoading(true)
    if (id || taxaFixa.id) {
      service.patchFull(taxaFixa)
        .then(() => {
          removeTaxaFixa();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    } else {
      service.createFull(taxaFixa)
        .then(() => {
          removeTaxaFixa();
          setRevisao(false);
          setIsOpen(true);
        })
        .finally(() => setIsGlobalLoading(false));
    }
  }

  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1">
        <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
        <h1 id="title">Nome da Taxa</h1>
        <span style={{ fontSize: 18 }}>{taxaFixa.codigo}</span>
        <div className="ativo">
          <div className="dot-verde"></div>
          <p>A Iniciar</p>
        </div>
        <h6 style={{ marginBottom: 0 }}>Descrição</h6>
        <p>{taxaFixa.descricao}</p>
        <h6 style={{ marginBottom: 0 }}>Periodo</h6>
        <p>{`${formattedDate(taxaFixa.dataInicial)} - ${formattedDate(taxaFixa.dataFinal)}`}</p>
        {/* <Button className="button-line" icon={<EditOutlined rev={undefined} />}>Editar Taxa</Button> */}
        <Button id="salvar" style={{ width: 250, marginTop: 10 }} className="button-primary" onClick={() => save()}>{id ? "Editar" : "Adicionar"} Taxa Fixa</Button>
      </div>
      <ModalCreate
        open={isOpen}
        //onClose={() => setIsOpen(false)}
        title="Taxa salva com sucesso!"
        subtitle="Cadastro concluído. Crie uma nova taxa fixa ou retorne ao menu principal."
        textButton1="Criar outra taxa"
        textButton2="Voltar a página inicial"
        styleButton1="button-line"
        styleButton2="button-primary"
        link1="/taxas/create/step-1"
        link2="/taxas"
      />
    </div>
  );
}