import { AxiosResponse } from "axios";
import http from "../helpers/http-common";
import { IMatriculaUpgradeFullDTO } from "../models/happy-code-api.model";

export const useMatriculaUpgradeService = () => {
	const resourceURL = '/matricula-upgrade';

	const enviarMatriculaUpgrade = (upgrade: IMatriculaUpgradeFullDTO): Promise<AxiosResponse<IMatriculaUpgradeFullDTO>> => {
		return http.post(resourceURL, upgrade);
	}

	return {
		enviarMatriculaUpgrade,
	}
}