import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import avatar from '../../assets/img/header/avatar.png';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';

import { IFrequenciaFullDTO, ISkillDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
// import { useAlunoSkillService } from '../../services/aluno-skill.service';
import { useAlunoService } from '../../services/aluno.service';
import { useFrequenciaService } from '../../services/frequencia.service';
import { useSkillService } from '../../services/skill.service';
import { IAlunoDTO } from './../../models/happy-code-api.model';
import './style.css';


export const TurmasSkills: React.FunctionComponent = () => {
  const { alunoId, idFrequencia } = useParams<any>();
  const { Option } = Select;
  const [skillList, setSkillList] = useState<ISkillDTO[]>();
  const [selectedSkillSoft, setSelectedSkillSoft] = useState<number[]>([]);
  const [selectedSkillHard, setSelectedSkillHard] = useState<number[]>([]);
  const [frequencia, setFrequencia] = useState<IFrequenciaFullDTO>();
  const [aluno, setAluno] = useState<IAlunoDTO>();
  // const alunoSkillService = useAlunoSkillService();
  const alunoService = useAlunoService();
  const skillService = useSkillService();
  const frequenciaService = useFrequenciaService();
  const { setIsGlobalLoading } = useGlobalContext();
  const history = useHistory();

  const findSkill = () => {
    return skillService.findList()
      .then((response) => {
        setSkillList(response.data);
      });
  }

  const findAlunoSkill = () => {
    const predicate = new Predicate();
    predicate.addOption('alunoId', alunoId);
    predicate.addOption('frequenciaId', idFrequencia);
    // return alunoSkillService.findList(predicate)
    //   .then((response) => {
    //     const skills = response.data.map((aluno) => aluno.skill);
    //     const soft = skills.filter((skill) => skill.tipoSkillCodigo === 'SOFT').map((item) => item.id);
    //     const hard = skills.filter((skill) => skill.tipoSkillCodigo === 'HARD').map((item) => item.id);
    //     setSelectedSkillHard(hard);
    //     setSelectedSkillSoft(soft);
    //   });
  }

  const getFrequencia = () => {
    return frequenciaService.findFullById({ id: idFrequencia })
      .then((response) => {
        setFrequencia(response.data);
      });
  }

  const getAluno = () => {
    return alunoService.findById({ id: alunoId })
      .then((response) => {
        setAluno(response.data);
      });
  }

  const saveAlunoSkill = () => {
    const payload: any[] = [];
    const soft = skillList.filter((skill) => selectedSkillSoft.includes(skill.id));
    const hard = skillList.filter((skill) => selectedSkillHard.includes(skill.id));
    const skills = [...soft, ...hard];
    skills.map((skill) => {
      payload.push({
        aluno: { id: aluno.id },
        ativo: true,
        frequencia: { id: frequencia.id },
        id: null,
        skill,
      })
    });
    setIsGlobalLoading(true);
    // return alunoSkillService.saveSkills(aluno.id, frequencia.id, payload)
    //   .then().finally(() => setIsGlobalLoading(false));
  }

  const handleChangeSoft = (values) => {
    setSelectedSkillSoft(values);
  }

  const handleChangeHard = (values) => {
    setSelectedSkillHard(values);
  }

  useEffect(() => {
    if (alunoId && idFrequencia) {
      setIsGlobalLoading(true);
      Promise.all([
        findSkill(),
        findAlunoSkill(),
        getFrequencia(),
        getAluno(),
      ]).finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alunoId, idFrequencia]);

  return (
    <div className="container-fondo">
      <NewMenu selecionado={12} />
      <div className="franquia" id="aluno-detalhe-row" style={{ backgroundColor: '#F5F5F9' }}>
        <div className="franquia-row" id="row-aluno-box" style={{ marginTop: 70 }}>
          <div className="head-botoes" id="detalhe-turma">
            <Button onClick={() => history.goBack()} className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
            <h5 style={{ marginLeft: 15 }}>Skill(s) de aluno</h5>
          </div>
          <div className="box-data-turma">
            <h5>Aluno</h5>
            <div className="input-box">
              <div id="avatar-skills">
                <img src={avatar} alt={"avatar"} className="avatar" />
                <h6 style={{ textAlign: 'start' }}>{aluno?.pessoa?.nome}</h6>
              </div>
            </div>
            <h5>Soft skills</h5>
            <div className="input-box">
              <p>Soft skills</p>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecione as Soft Skills"
                value={selectedSkillSoft.map(item => item)}
                onChange={(value) => handleChangeSoft(value)}
              >
                {skillList && skillList.filter((item) => item?.tipoSkillCodigo === 'SOFT').map((skill) => (
                  <Option key={skill.id} value={skill.id}>{skill.descricao}</Option>
                ))}
              </Select>
            </div>
            <h5>Hard skills</h5>
            <div className="input-box">
              <p>Hard skills</p>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecione as Hard Skills"
                value={selectedSkillHard.map(item => item)}
                onChange={(value) => handleChangeHard(value)}
              >
                {skillList && skillList.filter((item) => item?.tipoSkillCodigo === 'HARD').map((skill) => (
                  <Option key={skill.id} value={skill.id}>{skill.descricao}</Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <div style={{ display: 'flex' }} >
              <Button onClick={() => history.goBack()} className="button-second" style={{ marginRight: 20 }}>Voltar</Button>
              <Button className="button-primary" onClick={() => saveAlunoSkill()} >Registrar Skills</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}