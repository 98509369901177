import { Button, Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEscolaContext } from '../../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { useUnidadeContext } from '../../../../../context/UnidadeContext';
import { IEstadoCleanDTO, IPaisCleanDTO } from '../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../models/predicate.model';
import { useEstadoService } from '../../../../../services/estado.service';
import { useLogradouroService } from '../../../../../services/logradouro.service';
import { useNotificationService } from '../../../../../services/notification.service';
import { usePaisService } from '../../../../../services/pais.service';
import '../../../shared.scss';
import BotoesCadastroComponent from '../../BotoesCadastro/botoes-cadastro.component';
import StepCadastroComponent from '../../StepCadastro/step-cadastro.component';
import './novo-lead-step-2.scss';
import useNovoLeadStep2ComponentState from './novo-lead-step-2.state';

interface Props {
  onClose?: () => void;
  onNext?: () => void;
  onFecharModal?: () => void;
}

export const NovoLeadStep2 = (props: Props) => {

  const { state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
  } = useNovoLeadStep2ComponentState();

  const [form] = Form.useForm();
  const { unidade, setUnidade, revisao } = useUnidadeContext();



  const { escolaParaCadastrar, setEscolaParaCadastrar, flRevisao, setFlRevisao } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [paisList, setPaisList] = useState<IPaisCleanDTO[]>();
  const [listEstado, setListEstado] = useState<IEstadoCleanDTO[]>();

  const notification = useNotificationService();

  const history = useHistory();
  const paisService = usePaisService();
  const estadoService = useEstadoService();
  const logradouroService = useLogradouroService();

  const required = true;


  const onCepChange = (cep: string) => {
    if (cep.length !== 8) {
      return;
    }
    setIsGlobalLoading(true);
    const predicateLogradouro = new Predicate(10);
    predicateLogradouro.addOption('cep', cep);
    logradouroService.findCustom(predicateLogradouro)
      .then(({ data }) => {
        if (data && data.length > 0) {
          const logradouro = data[0];
          const { endereco } = unidade;
          endereco.cep = logradouro.cep;
          endereco.bairro = logradouro.bairroNome;
          endereco.cidade = logradouro.cidadeNome;
          endereco.logradouro = logradouro.nomeCompleto;
          endereco.nomeUF = logradouro.cidadeEstadoNome;
          endereco.paisId = logradouro.cidadeEstadoPaisId;

          form.setFieldsValue(endereco);
        } else {
          notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
        }
      })
      .finally(() => setIsGlobalLoading(false));
  }

  const findPais = () => {
    const predicate = new Predicate();
    return paisService.findCleanList(predicate)
      .then(({ data }) => {
        setPaisList(data);
      })
  }

  const findEstados = () => {
    const predicateEstado = new Predicate(100);
    return estadoService.findClean(predicateEstado).then(({ data }) => {
      setListEstado(data.content);
    })
  }

  useEffect(() => {
    if (escolaParaCadastrar?.endereco) {
      const { endereco } = escolaParaCadastrar;
      form.setFieldsValue(endereco);
    }
  }, [form, escolaParaCadastrar]);

  useEffect(() => {
    setIsGlobalLoading(true);
    Promise.all([
      findPais(),
      findEstados(),
    ]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnidade = (franquiaCrud) => {
    franquiaCrud['uf'] = listEstado.find((estado) => estado.nome == franquiaCrud['nomeUF'])?.codigo;
    const contextState = { ...escolaParaCadastrar, endereco: franquiaCrud };
    setEscolaParaCadastrar(contextState);

    if (flRevisao) {
      setFlRevisao(false);
      return history.replace("/escolas/create/step-4");
    }

    history.push("/escolas/create/step-3");
  };


  return (
    <>
      <div id="novo-lead-step-1" className="flex flex-row justify-center align-center modal-container modalBox">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onFecharModal} className="button-x">x</Button>
          <div className='container-titulo'>
            <h1>Cadastrar Lead</h1>
          </div>
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            initialValues={leadParaCadastrar?.endereco}
            style={{ width: '100%' }}
          >
            <div className="col">
              <Form.Item name="id" style={{ width: '100%' }} />
              <div className="input-franquia">
                <span>CEP</span>
                <Form.Item name="cep" rules={[{ required: false, message: 'Insira o CEP' }]}>
                  <Input
                    id="cep"
                    onChange={e => onCepChange(e.target.value)}
                    className="input"
                    maxLength={8}
                  />
                </Form.Item>
              </div>
              <div className="select-franquia">
                <span>País</span>
                <Form.Item name="paisId" rules={[{ required: false, message: 'Selecione um País' }]}>
                  <Select
                    id="pais"
                    placeholder="Selecione um País"
                    allowClear
                  >
                    {paisList && paisList.map((item, index) => (
                      <Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="box-cidade">
                <div className="select-franquia">
                  <span>Estado</span>
                  <Form.Item name="nomeUF" rules={[{ required: false, message: 'Selecione um Estado' }]}>
                    <Select
                      id="estado"
                      placeholder="Selecione um Estado"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={
                        (input, option) =>
                          option.texto.toLowerCase().includes(input.toLowerCase())
                      }>
                      {listEstado && listEstado.map((item) => (
                        <Select.Option key={item.id} value={item.nome} texto={item.nome} >{item.nome}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="select-franquia">
                  <span>Cidade</span>
                  <Form.Item name="cidade" rules={[{ required: false, message: 'Selecione uma Cidade' }]}>
                    <Input
                      id="cidade"
                      className="input"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="box-complemento">
                <div className="input-franquia">
                  <span>Logradouro</span>
                  <Form.Item name="logradouro" rules={[{ required: false, message: 'Insira o Logradouro' }]}>
                    <Input
                      id="logradouro"
                      className="input"
                    />
                  </Form.Item>
                </div>
                <div className="input-franquia" style={{ width: "17%" }}>
                  <span>Número</span>
                  <Form.Item name="numero" rules={[{ required: false, message: 'Insira o Número' }]}>
                    <Input
                      id="numero"
                      className="input"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="input-franquia">
                <span>Complemento</span>
                <Form.Item name="complemento">
                  <Input
                    id="complemento"
                    className="input"
                  />
                </Form.Item>
              </div>

            </div>

            <StepCadastroComponent />
            <BotoesCadastroComponent />
          </Form>
        </div>

        {/* <ModalResposta
          open={isModalSucessoOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link="crm"
        />
        <ModalResposta
          open={isModalErroOpen}
          onClose={onClose1}
          status="Ocorreu um erro!"
          text="Ocorreu um erro ao salvar o lead, por favor, tente novamente."
          link="crm"
        /> */}

      </div >
    </>
  )
}
