import http from '../helpers/http-common';
import {
	IModalidadeCleanDTO,
	IModalidadeDTO,
	IModalidadeFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindList } from './base/base-find-list.service';

export const useModalidadeService = () => {
	const resourceURL = 'modalidade';

	return {
		...baseFindList<IModalidadeCleanDTO, IModalidadeDTO, IModalidadeFullDTO>(
			resourceURL
		),
		createFull: (body): Promise<IAxiosResponseCustom<IModalidadeCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patchFull: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<IModalidadeCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
