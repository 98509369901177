import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Form, Input, Select } from 'antd';
import { ChangeEvent } from 'react';
import { Avatar } from '../../../../components/Avatar';
import { getAge } from '../../../../util/util';
import MenuSidebarAndContainer from '../../../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component';
import Title from '../../../../components/Title/title.component';
import './_alunos.styles.scss';
import useAlunos from './use-alunos.hook';
import UseAlunosTableColumns from './hooks/use-alunos-table-columns.hook';

export const Alunos: React.FunctionComponent = () => {
	const {
		studentsTitle,
		createStudentClick,
		goTo,
		onPageChange,
		inputSearchLabel,
		setStudentParentFilter,
		setSearch,
		setStatusId,
		studentData,
	} = useAlunos();

	const { alunosHeaderTable } = UseAlunosTableColumns();

	return (
		<MenuSidebarAndContainer itemMenuSelected={10}>
			<div className="alunos-top-content">
				<Title title={studentsTitle} />
				<Button
					className="button-primary"
					icon={<PlusOutlined rev={undefined} />}
					onClick={createStudentClick}
				>
					Novo Aluno
				</Button>
			</div>
			<form className="filtros">
				<Form.Item
					label={`Buscar por ${inputSearchLabel}`}
					labelAlign="left"
					className="d-flex-col-start-star w-100"
				>
					<Input
						id="buscar"
						prefix={
							<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
						}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setSearch(event.target.value)
						}
						className="searchbar-input"
					/>
				</Form.Item>

				<Form.Item labelAlign="left" label="Filtro">
					<Select
						className="filtro-estado"
						defaultValue="aluno"
						onChange={(e: string) => setStudentParentFilter(e)}
					>
						<Select.Option value="aluno">Aluno</Select.Option>
						<Select.Option value="responsavel">Responsável</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item labelAlign="left" label="Status">
					<Select
						defaultValue="TDS"
						className="filtro-estado"
						onChange={(e: string) => setStatusId(e)}
					>
						<Select.Option value="TDS">Todos</Select.Option>
						<Select.Option value="true">Ativo</Select.Option>
						<Select.Option value="false">Inativo</Select.Option>
					</Select>
				</Form.Item>
			</form>
			<table className="students-table">
				<tr className="students-table__students-header">
					{alunosHeaderTable.map(({ key, width, title }) => (
						<th
							key={key}
							className="students-header__column-header"
							style={{ width: width }}
						>
							{title}
						</th>
					))}
				</tr>
				{studentData &&
					studentData?.content.map((aluno, index) => (
						<tr key={index} className="students-table__row-user">
							<td className="row-user__user-cell" style={{ width: '30%' }}>
								<Avatar arquivo={aluno?.pessoa?.foto} />
								<div>
									<p className="user-cell__student-name">{aluno.pessoa.nome}</p>
									<div className="user-cell__student-status">
										<div
											className={`student-status__dot ${
												aluno.ativo ? 'green-dot' : 'red-dot'
											}`}
										></div>
										<p
											className={`student-status__status-text ${
												aluno.ativo ? 'active' : 'inactive'
											}`}
										>
											{aluno.ativo ? 'Ativo' : 'Inativo'}
										</p>
									</div>
								</div>
							</td>
							<td className="row-user__simple-cell" style={{ width: '10%' }}>
								#{aluno.id}
							</td>
							<td className="row-user__simple-cell" style={{ width: '10%' }}>
								{getAge(aluno.pessoa.dataNascimento)}
							</td>
							<td className="row-user__simple-cell" style={{ width: '20%' }}>
								{aluno.matriculas
									?.filter((matricula) => matricula.ativo)
									.map((matricula) =>
										matricula.turmaCursoDescricao
											? matricula.turmaCursoDescricao
											: matricula.turmaCurriculoNegocioDescricao + '; '
									)}
							</td>
							<td
								className="row-user__simple-cell row-user__parent-cell"
								id="aluno-responsavel"
								style={{ width: '25%' }}
							>
								{
									aluno.pessoa.pessoaResponsaveis?.find((pr) => pr.principal)
										?.responsavelNome
								}
							</td>
							<td
								onClick={() => goTo(aluno.id)}
								className="registro-opcao"
								style={{ width: '5%' }}
							>
								<i className="row-user__action-cell">
									<Unicons.UilAngleRight
										size="32"
										className="action-cell__action-icon"
									/>
								</i>
							</td>
						</tr>
					))}
			</table>
			<div className="paginacao">
				<Pagination
					onChange={(event, page) => onPageChange(event, page - 1)}
					page={studentData ? studentData.pageable.pageNumber + 1 : 0}
					count={studentData ? studentData.totalPages : 0}
				/>
			</div>
		</MenuSidebarAndContainer>
	);
};
