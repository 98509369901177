import { IMetaUnidadeCleanDTO, IMetaUnidadeDTO, IMetaUnidadeFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";
import { baseFindById } from "./base/base-find-id.service";
import { baseFindList } from "./base/base-find-list.service";
import { baseFind } from "./base/base-find.service";
import { baseUpdate } from "./base/base-update.service";

export const useMetaUnidadeService = () => {
	const resourceURL = '/meta-unidade';

	return {
		...baseFind<IMetaUnidadeCleanDTO, IMetaUnidadeDTO, IMetaUnidadeFullDTO>(resourceURL),
		...baseFindList<IMetaUnidadeCleanDTO, IMetaUnidadeDTO, IMetaUnidadeFullDTO>(resourceURL),
		...baseFindById<IMetaUnidadeCleanDTO, IMetaUnidadeDTO, IMetaUnidadeFullDTO>(resourceURL),
		...baseCreate<IMetaUnidadeCleanDTO, IMetaUnidadeDTO, IMetaUnidadeFullDTO>(resourceURL),
        ...baseUpdate<IMetaUnidadeCleanDTO, IMetaUnidadeDTO, IMetaUnidadeFullDTO>(resourceURL),
	};
};
