import {
	IStatusMatriculaCleanDTO,
	IStatusMatriculaDTO,
	IStatusMatriculaFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useStatusMatriculaService = () => {
	const resourceURL = 'status-matricula';

	return {
		...baseFind<IStatusMatriculaCleanDTO, IStatusMatriculaDTO, IStatusMatriculaFullDTO>(resourceURL),
		...baseFindList<IStatusMatriculaCleanDTO, IStatusMatriculaDTO, IStatusMatriculaFullDTO>(resourceURL),
	};
};
