import { Button, DatePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { ICobrancaCustomDTO, ICobrancaDTO } from "../../models/happy-code-api.model";
import { useCobrancaService } from "../../services/cobranca.service";
import { useNotificationService } from "../../services/notification.service";
import { formattedDate } from "../../util/format";
import ModalCreate from "../ModalCreate";

interface Text {
    open: boolean;
    onClose: () => void;
    cobranca: ICobrancaCustomDTO
}

export const ModalEditarVencimento: React.FunctionComponent<Text> = ({ open, onClose, cobranca }: Text) => {

    const { setIsGlobalLoading } = useGlobalContext();    
    const cobrancaService = useCobrancaService();
    const [novaDataVencimento, setNovaDataVencimento] = useState<moment.Moment>();
    const notification = useNotificationService();

    const onSend = () => {
        setIsGlobalLoading(true);
        cobrancaService.atualizarVencimento(cobranca.id, novaDataVencimento?.toISOString())
            .then(() => notification({
                description: `O vencimento foi atualizado com sucesso!`,
                type: 'success',
                message: 'Atualizado!'
            })
            )
            .catch(() => notification({
                description: `Houve uma falha ao atualizar!`,
                type: 'error',
                message: 'Falha ao atualizar!',
            }))
            .finally(() => { onClose(); setIsGlobalLoading(false) })
    }

    if (!open) return null
    return (

        <div className="modalContainerFinanceiro">
            <div className="modal-user-financeiro">
                <div className="body">
                    <h3>Alterar data de vencimento</h3>
                    <div className="line"></div>
                    <h5>Data vencimento atual:</h5>
                    <h5>{formattedDate(cobranca.dataVencimento)}</h5>
                    <div className="line"></div>
                    <h5>Selecione a nova data de vencimento</h5>
                    <DatePicker
                        allowClear={true}
                        placeholder={'Nova data'}
                        size={"large"}
                        format={'DD/MM/YYYY'}
                        defaultValue={moment(cobranca.dataVencimento)}
                        onChange={setNovaDataVencimento}
                        getPopupContainer={triggerNode => {
                            return triggerNode.parentElement
                        }}
                    ></DatePicker>
                    <div className="line"></div>
                    <div className="botoes">
                        <Button onClick={onClose} style={{ minWidth: 145, padding: "3px" }} className="button-primary">Cancelar</Button>
                        <Button onClick={onSend} className="button-primary" style={{ marginLeft: 8, minWidth: 145 }}>Enviar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarVencimento;