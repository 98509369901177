import { ArrowLeftOutlined, CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Input } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import fondo from '../../assets/img/matriculas/fondo.svg';
import Modal from '../../components/Modal';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';

import { Predicate } from '../../models/predicate.model';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { useTurmaService } from '../../services/turma.service';
import { isAfterDate, isBeforeDate, isDateBetween } from '../../util/format';
import { INegocioUnidadeFullDTO, ITurmaFullDTO } from './../../models/happy-code-api.model';
import { formatMoney } from './../../util/format';
import { ModalOferta } from './modal-oferta';
import './style.css';
moment().locale('pt-br');


export const CursoFranquiaTurma: React.FunctionComponent = () => {

  const { setIsGlobalLoading, unidade } = useGlobalContext();

  const [isOpen, setIsOpen] = useState(false);
  const [listTurma, setListTurma] = useState<ITurmaFullDTO[]>([]);
  const [negocioUnidade, setNegocioUnidade] = useState<INegocioUnidadeFullDTO>();
  const [turmaPredicate] = useState<Predicate>(new Predicate(30));
  const [descricao, setDescricao] = useState<string>();
  const [modalOferta, setModalOferta] = useState<boolean>(false);
  const negocioUnidadeService = useNegocioUnidadeService();

  const history = useHistory();
  const turmaService = useTurmaService();
  const { turma } = useTurmaContext();
  
  const findTurma = () => {
    turmaPredicate.addOption('curriculo.negocio.id', turma?.curriculoNegocioId);
    turmaPredicate.addOption('unidade.id', unidade.id);
    return turmaService.findFull(turmaPredicate)
      .then((response) => {
        setListTurma(response.data.content);
      });
  }

  const findCursoUnidade = () => {
    const predicate = new Predicate();
    predicate.addOption('negocio.id', turma?.curriculoNegocioId);
    predicate.addOption('unidade.id', unidade.id);
    return negocioUnidadeService.findFull(predicate)
      .then((response) => { setNegocioUnidade(response.data.content[0]) });
  }

  useEffect(() => {
    setIsGlobalLoading(true);
    Promise.all([
      findTurma(),
      findCursoUnidade(),
    ]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao !== undefined) {
        setIsGlobalLoading(true);
        turmaPredicate.addOption('descricao', descricao, Operators.CONTAINS);
        findTurma().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  const statusTurma = (turma: ITurmaFullDTO) => {
    if (isBeforeDate(turma?.dataInicio)) {
      return (
        <div className="status">
          <div className="dot-orange"></div>
          <p>Não Iniciado</p>
        </div>
      )
    }
    if (isDateBetween(turma?.dataInicio, turma?.dataFim)) {
      return (
        <div className="status">
          <div className="dot-green"></div>
          <p>Em andamento</p>
        </div>
      )
    }
    if (isAfterDate(turma?.dataFim)) {
      return (
        <div className="status">
          <div className="dot-orange"></div>
          <p>Finalizado</p>
        </div>
      )
    }
    return null;
  }

  const goToDetalhe = (turma: ITurmaFullDTO) => {
    history.push("/turmas/detalhe/" + turma?.id);
  }

  return (
    <div className="container-fondo" style={{ paddingTop: 0 }}>
      <NewMenu selecionado={11} />
      <div className="franquia" id="aluno-detalhe-row" style={{ height: '100vh', overflowY: 'scroll' }}>
        <div className="user-row" id="row-aluno" style={{ marginTop: 100, width: '70%' }}>
          <Link to="/curso-franquia/">
            <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
          </Link>
          <div id="head-turma">
            <div className='block-name-turma'>
              <h5 style={{ marginBottom: '0px !important' }}>{turma?.curso?.descricao}</h5>

              {negocioUnidade?.valorOferta != null ? (
                <div className="editar-oferta" style={{ flexDirection: "column", marginRight: 10 }}>
                  <p>Matrícula</p>
                  <h6>{negocioUnidade?.valorOferta && formatMoney(negocioUnidade?.valorOferta)}</h6>
                </div>
              ) : (
                <div className="editar-oferta" style={{ marginRight: 10 }}>
                  <p>Matrícula</p>
                </div>
              )}
              {negocioUnidade?.valorOfertaRematricula != null ? (
                <div className="editar-oferta" style={{ flexDirection: "column", marginRight: 10 }}>
                  <p>Rematrícula</p>
                  <h6>{negocioUnidade.valorOfertaRematricula && formatMoney(negocioUnidade?.valorOfertaRematricula)}</h6>
                </div>
              ) : (
                <div className="editar-oferta" style={{ marginRight: 10 }}>
                  <p>Rematrícula</p>
                </div>
              )}
            </div>
            <div>
              <Button
                style={{ marginRight: 10 }}
                className="button-primary"
                icon={<PlusOutlined rev={undefined} />}
                onClick={() => setModalOferta(true)}>Atualizar Oferta</Button>
            </div>
            <Link to="/turmas">
              <Button className="button-primary" icon={<PlusOutlined rev={undefined} />}>Nova turma</Button>
            </Link>
          </div>
          <div className="filtros" style={{ width: '100%' }}>
            <div className="input-text" style={{ width: '100%' }}>
              <Input onChange={(e) => setDescricao(e.target.value)} placeholder="Buscar pelo nome" prefix={<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />} />
            </div>
          </div>
          <div className="matriculas-curso" style={{ flexWrap: 'nowrap' }}>
            <div className='turmas'>
              {listTurma && listTurma?.map((turma, index) => (
                <div key={index} className="turma">
                  <Unicons.UilEllipsisH size="20" className="mais" color="#92A9CB" />
                  <h3>{turma?.descricao}</h3>
                  <h6>ID #{turma?.id}</h6>
                  {statusTurma(turma)}
                  <div className="dados">
                    <div className="col">
                      <h4>Instrutor</h4>
                      <p>{turma?.unidadePessoa?.pessoaNome}</p>
                    </div>
                    <div className="col">
                      <h4>Alunos</h4>
                      <p>{turma?.matriculas?.length ?? 0}</p>
                    </div>
                  </div>
                  <h4>{moment(turma?.dataInicio).format('DD/MM/YYYY')} - {moment(turma?.dataFim).format('DD/MM/YYYY')} ({moment(turma?.dataInicio).to(turma?.dataFim, true)})</h4>
                  <div className="boxes">
                    {turma?.horarios.map(horario => (
                      <div className="box" key={horario.id}>
                        <h6>{horario.diaSemana}</h6>
                        <p>{horario.horaInicio.slice(0, -3)} - {horario.horaFim.slice(0, -3)}</p>
                      </div>
                    ))}
                  </div>
                  <div onClick={() => goToDetalhe(turma)} className="detalhes">
                    <p>Ver detalhe</p>
                  </div>
                </div>
              ))}
              <div className="turma-line" style={{ width: '49%' }}>
                <Link to="/turmas">
                  <Button icon={<Unicons.UilPlus size="20" color="#6563FF" />}><h3>Nova Turma</h3></Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Button onClick={() => setIsOpen(true)} className="button-fechar-curso" icon={<CloseOutlined rev={undefined} />} />
      </div>
      <ModalOferta negocioUnidade={negocioUnidade} onClose={(state) => setModalOferta(state)} open={modalOferta} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a adição desta Nova Matricula?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/alunos'
      />
      <img src={fondo} alt="" className="img-fondo" />
    </div>
  );
}