import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';

import { MenuDetalheHoraSede } from '../../components/MenuDetalheHoraSede';
import { useHoraSedeContext } from '../../context/HoraSedeContext';
import { formatMoney } from '../../util/format';
import './style.css';

export const HoraSedeCreateStep6: React.FunctionComponent = () => {
  const { horaSede, setRevisao } = useHoraSedeContext();
  const history = useHistory();
  const goTo = (url) => history.push(url);

  useEffect(() => {
    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
      </div>
      <MenuDetalheHoraSede />
      <div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
        <div className="franquia-row">
          <h5>Revisão de Dados</h5>
          <div className="row-revisao">
            <div className="box-revisao">
              <div className="dados">
                <Button id="editar-step-1" className="button-line" onClick={() => goTo("/hora/create/step-1")}>Editar</Button>
                <h4>Dados Básicos</h4>
                <div className="nome">
                  <h5>Valor sugerido</h5>
                  <span style={{ fontSize: 18 }}>{horaSede?.valorSugerido}</span>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Parcelamento</h6>
                    <span>{horaSede?.numeroMaximoParcela}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Prorrogação máxima</h6>
                    <span>{horaSede?.numeroProrrogacaoDiasMatricula}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Taxa</h6>
                    <span>{horaSede?.flTaxa === true ? "Sim" : "Não"}</span>
                  </div>
                </div>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Valor taxa</h6>
                    <span>{horaSede?.valorSplitTaxa}</span>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button id="editar-step-3" className="button-line" onClick={() => goTo("/hora/create/step-2")}>Editar</Button>
                <>
                  <h4>Franquias</h4>
                  {horaSede?.horaUnidades.map((horaUnidade) =>
                    <div id="user-franquias-revisao" key={horaUnidade.id}>
                      <h4>{horaUnidade.unidadeDescricao}</h4>
                    </div>)}
                </>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button id="editar-step-4" className="button-line" onClick={() => goTo("/hora/create/step-3")}>Editar</Button>
                <h4>Distritos</h4>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Split uma hora e meia</h6>
                    <span style={{ fontSize: 18 }}>{formatMoney(horaSede?.splitUmHoraEMeia)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Split três horas</h6>
                    <span>{formatMoney(horaSede?.splitTresHoras)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Split quatro horas e meia</h6>
                    <span>{formatMoney(horaSede?.splitQuatroHorasEMeia)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Split seis horas</h6>
                    <span>{formatMoney(horaSede?.splitSeisHoras)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Valor adicional</h6>
                    <span>{formatMoney(horaSede?.splitAdicional)}</span>
                  </div>
                </div>
              </div>
              <div className="line" style={{ marginBottom: 20 }}></div>
              <div className="dados">
                <Button id="editar-step-4" className="button-line" onClick={() => goTo("/hora/create/step-4")}>Editar</Button>
                <h4>Metrópole</h4>
                <div className="dados-franquia" id="cursos-info">
                  <div className="cpf" id="cursos-info">
                    <h6>Split uma hora e meia</h6>
                    <span style={{ fontSize: 18 }}>{formatMoney(horaSede?.splitUmHoraEMeiaCapital)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Split três horas</h6>
                    <span>{formatMoney(horaSede?.splitTresHorasCapital)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Split quatro horas e meia</h6>
                    <span>{formatMoney(horaSede?.splitQuatroHorasEMeiaCapital)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Split seis horas</h6>
                    <span>{formatMoney(horaSede?.splitSeisHorasCapital)}</span>
                  </div>
                  <div className="cpf" id="cursos-info">
                    <h6>Valor adicional</h6>
                    <span>{formatMoney(horaSede?.splitAdicionalCapital)}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="botao-voltar-revisao">
        <Button id="voltar" className="button-second" icon={<ArrowLeftOutlined rev={undefined}/>} onClick={() => history.goBack()}>
          Voltar
        </Button>
      </div>
    </div >
  )
}