import { Link } from "react-router-dom";
import * as Unicons from '@iconscout/react-unicons';
import { useEscolaContext } from "../../../../context/EscolaContext";

const EscolaFormFecharComponent = () => {
  const { setEscolaParaCadastrar } = useEscolaContext();

  return <Link to="/escolas" onClick={() => {
    setEscolaParaCadastrar(null);
  }}>
    <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
  </Link>
}

export default EscolaFormFecharComponent;
