
import './style.css';

const Header: React.FunctionComponent = () => {
  return (
    <div className="progress">
      <div className="step"></div>
    </div>
  );
}

export default Header;