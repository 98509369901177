import { Operators } from '../enum/operators.enum';

export class CustomOption {
	public key: string;
	public value: unknown;

	public constructor(key: string, value: unknown) {
		this.key = key;
		this.value = value;
	}
}

export class Predicate {
	public page?: number;
	public size?: number;
	public sort?: string;

	public _arrCustomOptions?: CustomOption[];

	public get arrCustomOptions() {
		return this._arrCustomOptions;
	}

	public constructor(size = 10) {
		this.size = size;
		this.page = 0;
		this.sort = 'desc';
		this._arrCustomOptions = new Array<CustomOption>();
	}

	public addOption(key: string, value: any, op?: Operators) {
		this.removeOption(key);
		this._arrCustomOptions.push(
			new CustomOption(key, op ? op + '(' + value + ')' : value)
		);
	}

	public addSortDescricao (value : string){
		this.sort = 'asc'
		this.removeOption('sort');
		this._arrCustomOptions.push(
			new CustomOption('sort', value + ',' + this.sort)
		);
	}

	public addSort(value: string) {
		if (this.sort === 'asc') {
			this.sort = 'desc';
		} else {
			this.sort = 'asc';
		}
		this.removeOption('sort');
		this._arrCustomOptions.push(
			new CustomOption('sort', value + ',' + this.sort)
		);
	}

	public addMultiOption(
		key: string,
		value: any,
		op?: Operators,
		and?: boolean
	) {
		let predicate = value;
		if (op) {
			predicate = op + '(' + predicate + ')';
		}
		if (and) {
			predicate = 'and(' + predicate + ')';
		}

		this._arrCustomOptions.push(new CustomOption(key, predicate));
	}

	public removeOption(key: string) {
		this._arrCustomOptions = this._arrCustomOptions.filter(
			(item) => item.key !== key
		);
	}

	public removeAllOption() {
		this._arrCustomOptions = new Array<CustomOption>();
	}

	public setPage(page: number) {
		this.page = page;
	}
}

const mapToObj = (inputMap: CustomOption[]) => {
	const params = new URLSearchParams();
	inputMap.forEach((option) => params.append(option.key, `${option.value}`));
	return params;
};

export const formatHttpParams = (predicate: Predicate) => {
	const _arrCustomOptions: CustomOption[] = new Array<CustomOption>();
	_arrCustomOptions.push(new CustomOption('page', predicate.page));
	_arrCustomOptions.push(new CustomOption('size', predicate.size.toString()));

	if (predicate.arrCustomOptions) {
		predicate.arrCustomOptions.forEach((co) =>
			_arrCustomOptions.push(new CustomOption(`${co.key}`, `${co.value}`))
		);
	}
	return { params: mapToObj(_arrCustomOptions) };
};

export const formatHttpParamsList = (predicate: Predicate) => {
	const _arrCustomOptions: CustomOption[] = new Array<CustomOption>();
	if (predicate.arrCustomOptions) {
		predicate.arrCustomOptions.forEach((co) =>
			_arrCustomOptions.push(new CustomOption(`${co.key}`, `${co.value}`))
		);
	}
	return { params: mapToObj(_arrCustomOptions) };
};
