import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import 'antd/dist/antd.css';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BoxDocumentos } from '../../components/BoxDocumentos';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { TipoDocumentoEnum } from '../../enum/tipoDocumento.enum';

import {
	IDocumentoCleanDTO,
	ITipoDocumentoCleanDTO,
} from '../../models/happy-code-api.model';
import { useTipoDocumentoService } from '../../services/tipo-documento.service';
import { ProgressoNovoAluno } from './progresso';
// import { BoxDocumentos } from './../../../src/sub-components/BoxDocumentos/index';
import './style.css';

export const AlunosCreateStep4: React.FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [tipoDocumentoList, setTipoDocumentoList] =
		useState<ITipoDocumentoCleanDTO[]>();

	const { revisao, revisaoDetalhe, aluno, setAluno } = useAlunoContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const documentoService = useTipoDocumentoService();

	useEffect(() => {
		setIsGlobalLoading(true);
		documentoService
			.findCleanList()
			.then((response) => {
				setIsGlobalLoading(false);
				setTipoDocumentoList(response.data);
			})
			.catch(() => setIsGlobalLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const atualizaDocumentos = (
		uploads: any[],
		tipoDocumentoEnum: TipoDocumentoEnum
	) => {
		const tipoDocumento = tipoDocumentoList.find(
			(td) => td.id === tipoDocumentoEnum
		);

		const contratos: IDocumentoCleanDTO = uploads.map(({ response }) => ({
			pessoa: aluno.pessoa,
			tipoDocumentoId: tipoDocumento.id,
			arquivoId: response.id,
			id: null,
			ativo: true,
			codigo: response.codigo,
			urlImagemDocumento: response.url,
			arquivoUrl: response.url,
			pessoaId: null,
		}))[0];

		const documentosCpy = [...aluno.pessoa.documentos];
		documentosCpy.push(contratos);
		setAluno({
			...aluno,
			pessoa: {
				...aluno.pessoa,
				documentos: documentosCpy,
			},
		});
	};

	const onRemoveFile = (file: any) => {
		const { documentos } = aluno.pessoa;
		setAluno({
			...aluno,
			pessoa: {
				...aluno.pessoa,
				documentos: [...documentos.filter((ud) => ud.codigo !== file.name)],
			},
		});
	};

	const formatDefaultList = (files: IDocumentoCleanDTO[]): UploadFile[] => {
		return files.map((file, index) => ({
			uid: index.toString(),
			arquivoId: file.id,
			name: file.codigo,
			status: 'done',
			url: `${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/download/${file.arquivoUrl}?name=${file.codigo}`,
			arquivoUrl: file.arquivoUrl,
		}));
	};

	const getDefaultFileList = (tipoDocumento: TipoDocumentoEnum) => {
		const tipoDoc = tipoDocumentoList?.find((td) => td.id === tipoDocumento);
		const files = aluno.pessoa.documentos?.filter(
			(doc) => doc.tipoDocumentoId === tipoDoc?.id
		);

		if (files && files.length > 0) {
			return formatDefaultList(files);
		}
		return [];
	};

	const getStatus = (tipoDocumento: TipoDocumentoEnum) => {
		const files = aluno.pessoa.documentos?.filter(
			(doc) => doc.id === tipoDocumento
		);
		if (files && files.length > 0) {
			return (
				<div className="dot">
					<div className="dot-verde"></div>
					<p>Concluído</p>
				</div>
			);
		}
		return (
			<div className="dot">
				<div className="dot-orange"></div>
				<span>Pendente</span>
			</div>
		);
	};

	return (
		<div className="container-fondo">
			<Header />
			<ProgressoNovoAluno step={2} percent={75} />
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<h3 style={{ marginBottom: 2 }}>Documentos</h3>
					{/* <h6 id="taxas-h6">9 documentos pendentes</h6> */}
					{tipoDocumentoList && tipoDocumentoList.length && (
						<>
							<BoxDocumentos
								statusDocumento={getStatus(TipoDocumentoEnum.DIA)}
								defaultFileList={getDefaultFileList(TipoDocumentoEnum.DIA)}
								title={'Documento de identificação do aluno'}
								subtitle={
									'Esse documento é Documento de identificação do aluno.'
								}
								setFileList={(fileList) =>
									atualizaDocumentos(fileList, TipoDocumentoEnum.DIA)
								}
								onRemoveFile={(fileList) => onRemoveFile(fileList)}
							/>
							<BoxDocumentos
								statusDocumento={getStatus(TipoDocumentoEnum.DIRP)}
								defaultFileList={getDefaultFileList(TipoDocumentoEnum.DIRP)}
								title={'Documento de identificação do responsável principal'}
								subtitle={
									'Esse documento é Documento de identificação do responsável principal.'
								}
								setFileList={(fileList) =>
									atualizaDocumentos(fileList, TipoDocumentoEnum.DIRP)
								}
								onRemoveFile={(fileList) => onRemoveFile(fileList)}
							/>
							<BoxDocumentos
								statusDocumento={getStatus(TipoDocumentoEnum.CPR)}
								defaultFileList={getDefaultFileList(TipoDocumentoEnum.CPR)}
								title={'Comprovante de residência'}
								subtitle={'Esse documento é comprovante de residência.'}
								setFileList={(fileList) =>
									atualizaDocumentos(fileList, TipoDocumentoEnum.CPR)
								}
								onRemoveFile={(fileList) => onRemoveFile(fileList)}
							/>
						</>
					)}
					{revisaoDetalhe.ativo && (
						<Link to={`/alunos/detalhe`}>
							<Button
								type="primary"
								className="button-primary"
								style={{ width: 345 }}
							>
								Voltar para Revisão
							</Button>
						</Link>
					)}

					{revisao && (
						<Link to="/alunos/create/step-5">
							<Button
								type="primary"
								className="button-primary"
								style={{ width: 345 }}
							>
								Voltar para Revisão
							</Button>
						</Link>
					)}

					{!revisao && !revisaoDetalhe.ativo && (
						<div className="botoes">
							<Link to="/alunos/create/step-3">
								<Button className="button-second" style={{ width: 345 }}>
									Voltar
								</Button>
							</Link>
							<Link to="/alunos/create/step-5">
								<Button className="button-primary" style={{ width: 345 }}>
									Próximo
								</Button>
							</Link>
						</div>
					)}

					{/* <div className="botoes">
						<Link to="/alunos/create/step-3">
							<Button className="button-second" style={{ width: 345 }}>
								Voltar
							</Button>
						</Link>
						<Link to="/alunos/create/step-5">
							<Button className="button-primary" style={{ width: 345 }}>
								Próximo
							</Button>
						</Link>
					</div> */}
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição de um novo aluno?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/alunos"
			/>
		</div>
	);
};
