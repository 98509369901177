import { CloseOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Progress } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import 'antd/dist/antd.css';
import { UploadFile } from 'antd/lib/upload/interface';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AvatarUpload } from '../../components/AvatarUpload';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useColaboradorContext } from '../../context/ColaboradorContext';

import { IArquivoCleanDTO } from '../../models/happy-code-api.model';
import { formatMomentLocalDateTime, toMoment } from '../../util/format';
import StepsCreate from './steps-create';
import './style.css';

export const ColaboradoresCreateStep1: React.FunctionComponent = () => {
	const { colaborador, setColaborador, revisao, removeColaborador } =
		useColaboradorContext();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);

	const onChangeInputs = (property: string, value: string) => {
		const colaboradorCopy = { ...colaborador };
		colaboradorCopy[property] = value;
		setColaborador(colaboradorCopy);
	};

	const handleColaboradorSubmitStep1 = () => {
		history.push('/colaboradores/create/step-2');
	};

	const onAvatarUpload = (arquivo: UploadFile<IArquivoCleanDTO>) => {
		if (arquivo) {
			colaborador.foto = arquivo.response;
		} else {
			colaborador.foto = null;
		}
	}

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={25}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={0} />
			<div className="franquia">
				<Form
					className="user-row"
					onFinish={handleColaboradorSubmitStep1}
				>
					<h3>Preencha os dados básicos do colaborador</h3>
					<div className="colaborador">
						<AvatarUpload arquivo={colaborador.foto} onUpload={(arquivos) => onAvatarUpload(arquivos)} />
					</div>
					<div className="input-box">
						<p>Nome Completo</p>
						<Input
							value={colaborador.nome}
							onChange={(e) => onChangeInputs('nome', e.target.value)}
							placeholder="Maria Silva Oliveira Gonçalves"
							className="input"
							prefix={<UserOutlined rev={undefined} />}
						/>
					</div>
					<div className="inputs-box">
						<div className="input-box">
							<p>CPF</p>
							<MaskedInput
								mask="111.111.111-11"
								maxLength={11}
								value={colaborador.cpf}
								onChange={(e) => onChangeInputs('cpf', e.target.value)}
								placeholder="012.345.678-90"
								className="input"
								prefix={<IdcardOutlined rev={undefined} />}
							/>
						</div>
						<div className="input-box">
							<p>Data de Nascimento</p>
							<DatePicker
								format={'DD-MM-YYYY'}
								value={toMoment(colaborador.dataNascimento)}
								onChange={(e) =>
									setColaborador({
										...colaborador,
										dataNascimento: formatMomentLocalDateTime(e),
									})
								}
								placeholder={'Data de nascimento'}
								suffixIcon={null}
								style={{ width: '100%' }}
							/>
						</div>
					</div>
					<div className="input-box">
						<p>Data de Admissão<span id="ob"> *</span> </p>

						<DatePicker
							format={'DD-MM-YYYY'}
							value={toMoment(colaborador.dataAdmissao)}
							onChange={(e) =>
								setColaborador({
									...colaborador,
									dataAdmissao: formatMomentLocalDateTime(e),
								})
							}
							placeholder={'Data de admissão'}
							suffixIcon={null}
							style={{ width: '100%' }}
						/>
					</div>
					<div className="botoes">
						{revisao ? (
							<Link to="/colaboradores/create/step-4">
								<Button type="primary" className="button-primary" style={{ width: 700 }}>Voltar para Revisão</Button>
							</Link>
						) : (
							<>
								<Link to="/colaboradores">
									<Button className="button-second" style={{ width: 345 }}>Voltar</Button>
								</Link>
								<Button className="button-primary" htmlType="submit" style={{ width: 345 }}>Próximo</Button>
							</>
						)}
					</div>
				</Form>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				id="cancelar"
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
					removeColaborador();
				}}
				title="Você deseja cancelar a adição de um novo colaborador?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/colaboradores"
			/>
		</div>
	);
};
