import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IUnidadeCleanDTO,
	IUnidadeCustomDTO,
	IUnidadeDTO,
	IUnidadeFullDTO,
	IUnidadeHomeDTO
} from '../models/happy-code-api.model';
import { Predicate } from '../models/predicate.model';
import { formatHttpParams } from './../models/predicate.model';
import { PageableResponse } from './../models/response.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useUnidadeService = () => {
	const resourceURL = 'unidade';

	const customFind = (
		predicate: Predicate
	): Promise<AxiosResponse<PageableResponse<IUnidadeCustomDTO>>> => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	};

	const findHome = (
		unidadeId: number,
	): Promise<AxiosResponse<IUnidadeHomeDTO>> =>
		http.get(resourceURL + `/carregar-home/${unidadeId}`);

	return {
		...baseFind<IUnidadeCleanDTO, IUnidadeDTO, IUnidadeFullDTO>(resourceURL),
		...baseFindList<IUnidadeCleanDTO, IUnidadeDTO, IUnidadeFullDTO>(
			resourceURL
		),
		...baseCreate<IUnidadeCleanDTO, IUnidadeDTO, IUnidadeFullDTO>(resourceURL),
		...baseFindById<IUnidadeCleanDTO, IUnidadeDTO, IUnidadeFullDTO>(
			resourceURL
		),
		...baseUpdate<IUnidadeCleanDTO, IUnidadeDTO, IUnidadeFullDTO>(resourceURL),
		customFind,
		findHome
	};
};
