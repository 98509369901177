import { useContext, useEffect, useState } from "react";
import { Form } from "antd";
import { useNotificationService } from "../../../../../../services/notification.service";
import { useLeadAnotacaoService } from "../../../../../../services/lead-anotacao.service";
import { CrmContext } from "../../../../context/crm.context";
import { useGlobalContext } from "../../../../../../context/GlobalContext";
import { useLeadService } from "../../../../../../services/lead.service";

const useNoteState = (props) => {
  const leadAnotacaoService = useLeadAnotacaoService();
  const leadService = useLeadService();
  const notification = useNotificationService();
  const [crmContext, setCrmContext, refresh] = useContext(CrmContext);
  const { open, onClose } = props;
  const [lead, setLead] = useState(props.lead);
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [isSelect, setIsSelect] = useState('text-cinza');
  const [isSelect1, setIsSelect1] = useState('text-cinza');
  const [isSelect2, setIsSelect2] = useState('text-cinza');
  const [isSelect3, setIsSelect3] = useState('text-cinza');


  const { setIsGlobalLoading } = useGlobalContext();

  useEffect(() => {
    setLead({ ...props.lead });
  }, [props.lead]);

  const onChange = (e: any) => {
    setValue(e.target.value)
    if (e.target.value === 'Whatsapp') {
      setIsSelect('text-blue')
    } else {
      setIsSelect('text-cinza')
    }
    if (e.target.value === 'Ligação') {
      setIsSelect1('text-blue')
    } else {
      setIsSelect1('text-cinza')
    }
    if (e.target.value === 'Email') {
      setIsSelect2('text-blue')
    } else {
      setIsSelect2('text-cinza')
    }
    if (e.target.value === 'Presencial') {
      setIsSelect3('text-blue')
    } else {
      setIsSelect3('text-cinza')
    }
  };

  const post = () => {
    const body = {
      ...form.getFieldsValue(),
      lead: props.lead,
    };

    setIsGlobalLoading(true);

    leadAnotacaoService.create(body).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        // return leadService.findFullById({ id: (props.lead as ILeadFullDTO).id }).then((response) => {
        //   if (response.status >= 200 && response.status <= 299) {
        //     setCrmContext((crmContext) => {
        //       crmContext.boards.forEach((board) => {
        //         const index = board.leads?.findIndex(lead => lead.id === props.lead.id);
        //         board.leads[index] = response.data;
        //       });
        //       return { ...crmContext };
        //     })
        //   }
        // })

        return refresh().finally(() => {
          onClose();
          form.resetFields();
          return notification({ description: 'Anotação realizada!', type: 'success', message: 'Sucesso' });
        });
      }
      return notification({ description: 'Falha ao adicionar anotação!', type: 'error', message: 'Erro' });
    }).finally(() => {
      setIsGlobalLoading(false);
    });
  }

  const onSalvar = () => {
    form
      .validateFields()
      .then(() => {
        return post();
      })
      .catch((errors) => {
        notification({ description: 'Por favor, preencha todos os campos', type: 'warning', message: 'Aviso' });
      });
  }

  const clearFields = () => {
    form.resetFields();
  }

  return {
    open,
    onClose,
    onChange,
    isSelect,
    isSelect1,
    isSelect2,
    isSelect3,
    value,
    setValue,
    onSalvar,
    form,
    clearFields,
  }
}

export default useNoteState;