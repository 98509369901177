import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";
import { IHoraUnidadeCleanDTO } from "../models/happy-code-api.model";

interface CursoFranquiaContextProps {
  oferta: IHoraUnidadeCleanDTO | undefined;
  setOferta: Dispatch<SetStateAction<IHoraUnidadeCleanDTO | undefined>>;

  
}

const CursoFranquiaContext = createContext<CursoFranquiaContextProps | undefined>(undefined);

interface CursoFranquiaContextProviderProps {
  children: ReactNode;
}

export const CursoFranquiaContextProvider: React.FC<CursoFranquiaContextProviderProps> = ({ children }) => {
  const [oferta, setOferta] = useState<IHoraUnidadeCleanDTO | undefined>(undefined);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(true)



  const contextValue: CursoFranquiaContextProps = {
    oferta,
    setOferta,
  };

 
  return (
    <CursoFranquiaContext.Provider value={contextValue}>
      {children}
    </CursoFranquiaContext.Provider>
  );
};
export const useCursoFranquiaContext = () => {
	const context = useContext(CursoFranquiaContext);
	if (!context) {
		throw new Error('useSalaContext must be used within a SalaContextProvider');
	}
	return context;
};