import { useEffect, useState } from 'react';
import { useHappyGptService } from '../../services/happy-gpt.service';
import showdown from 'showdown';
import { Sender } from './models';
import { IMessagesDTO, IRequestBodyDTO } from '../../models/happy-code-api.model';
import { useGlobalContext } from '../../context/GlobalContext';
import { Modal } from 'antd';

export function useGptHook() {
	const [prompt, setPrompt] = useState<string>('');
	const [messages, setMessages] = useState<IMessagesDTO[]>([]);
	const [loading, setLoading] = useState(false);

	const service = useHappyGptService();
	const { setIsGlobalLoading, isGlobalLoading, usuario, unidade } = useGlobalContext();

	showdown.extension('targetBlank', function () {
		return [
			{
				type: 'output',
				regex: /(<a [^>]+?)(>)/g,
				replace: '$1 target="_blank"$2',
			},
		];
	});

	const converter = new showdown.Converter({
		extensions: ['targetBlank'],
	});

	const formatText = (text: string) => {
		return text.replace(/【\d+:\d+†\w+】/g, '');
	};

	const fetchMessagesHistory = async () => {
		setIsGlobalLoading(true);

		const model: IRequestBodyDTO = {
			message: prompt,
			userId: usuario?.id,
			unidadeId: unidade?.id,
		};

		try {
			const response = await service.getMessages(model);

			const messages = response.data.map((message) => ({
				sender: message.sender === 'api' ? Sender.API : Sender.USER,
				text: formatText(converter.makeHtml(message.text)),
			}));

			setMessages((prevMessages) => [...prevMessages, ...messages]);
		} catch (error) {
			console.error('Erro ao buscar mensagens:', error);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		if (prompt === '') return;
		try {
			setPrompt('');
			setLoading(true);
			setMessages((prevMessages) => [...prevMessages, { sender: Sender.USER, text: prompt }]);

			setTimeout(() => {
				window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
			}, 100);

			const model: IRequestBodyDTO = {
				message: prompt,
				userId: usuario?.id,
				unidadeId: unidade?.id,
			};
			const apiResponse = await service.request(model);

			const formattedResponse = formatText(converter.makeHtml(apiResponse.data.message));

			setTimeout(() => {
				setMessages((prevMessages) => [...prevMessages, { sender: Sender.API, text: formattedResponse }]);
				setLoading(false);
			}, 2000);
		} catch (error) {
			console.error('Erro ao processar o prompt: ', error);
			setLoading(false);
		}
	};

	const deleteConversation = async () => {
		setIsGlobalLoading(true);
		const model: IRequestBodyDTO = {
			message: null,
			userId: usuario?.id,
			unidadeId: unidade?.id,
		};
		try {
			await service.inactivateConversation(model);
			setMessages([]);
			setPrompt('');
			setIsGlobalLoading(false);
		} catch (error) {
			console.error('Erro ao deletar a conversa: ', error);
			setIsGlobalLoading(false);
		}
	};
	const showDeleteConfirm = () => {
		Modal.confirm({
			title: 'Limpar conversa',
			content: 'Deseja realmente limpar a conversa?',
			cancelText: 'Cancelar',
			okText: 'Sim, limpar',
			onOk: () => deleteConversation(),
		});
	};
	useEffect(() => {
		if (usuario.id && unidade?.id) fetchMessagesHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usuario, unidade]);
	const shareConversation = async () => {
		if (messages.length < 2) return;
		const model: IRequestBodyDTO = {
			message: prompt,
			userId: usuario?.id,
			unidadeId: unidade?.id,
		};
		setIsGlobalLoading(true);
		try {
			const response = await service.getMessages(model);
			const messages = response.data;
			const textContent =
				'Conversa Happy GPT:\n\n' +
				messages
					.map((message) => {
						return `${message.sender === Sender.API ? '🤖: ' : '👤: '} ${message.text}`;
					})
					.join('\n\n');

			await navigator.share({
				title: 'Conversa Happy GPT',
				text: textContent,
			});
		} catch (error) {
			alert('Erro ao compartilhar a conversa: ' + error);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	return {
		messages,
		loading,
		prompt,
		isGlobalLoading,
		setPrompt,
		fetchMessagesHistory,
		showDeleteConfirm,
		handleSubmit,
		shareConversation,
	};
}
