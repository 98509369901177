import axios from "axios";
import { ILeadAbfDTO } from "../models/happy-code-api.model";
import http from "../helpers/http-common";

export const useLeadAbfService = () => {
	const resourceURL = 'lead-abf';  

    const findLead = (leadCodigoId: string, senha: string, token: string) => {
		return http.get(`/${resourceURL}/${leadCodigoId}?senha=${senha}`, { headers: { Authorization: `Bearer ${token}` } })
	}

    const patchLead = (lead: ILeadAbfDTO, token: string) => {
		return http.patch(`/${resourceURL}`, lead, { headers: { Authorization: `Bearer ${token}` } })
	}

    return {
        findLead,
        patchLead
    }
}