import { Button } from 'antd';
import { Link } from 'react-router-dom';

import './style.css';

interface Text {
	title: string;
	subtitle: string;
	textButton1: string;
	textButton2: string;
	styleButton1: string;
	styleButton2: string;
	link2?: string;
	link1?: string;
	open: boolean;
	onClose: () => void;
	button2?: () => void;
}

const ModalRelatorioStemplay: React.FunctionComponent<Text> = ({
	open,
	onClose,
	title,
	subtitle,
	textButton1,
	textButton2,
	styleButton1,
	styleButton2,
	link1,
	link2,
	button2,
}: Text) => {
	if (!open) return null;

	return (
		<div className="modalContainer">
			<div className="modal-user-stemplay">
				<h3>{title}</h3>
				<h6>{subtitle}</h6>
				<div className="line-stemplay"></div>
				<div className="botoes-stemplay">
					<Button
						id="nao"
						onClick={onClose}
						style={{ minWidth: 145 }}
						className={styleButton1}
					>
						{textButton1}
					</Button>
					{link2 && (
						<Link to={link2}>
							<Button
								id="sim"
								className={styleButton2}
								style={{ marginLeft: 8, minWidth: 145 }}
							>
								{textButton2}
							</Button>
						</Link>
					)}
					{button2 && (
						<Button
							onClick={button2}
							className={styleButton2}
							style={{ marginLeft: 8, minWidth: 145 }}
						>
							{textButton2}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default ModalRelatorioStemplay;
