/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { ITaxaFixaFullDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsTaxaContext {
  taxaFixa: ITaxaFixaFullDTO;
  revisao: boolean;
  setTaxaFixa: React.Dispatch<React.SetStateAction<ITaxaFixaFullDTO>>;
  removeTaxaFixa: React.DispatchWithoutAction;
  setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
};

//Valor default do contexto
const DEFAULT_VALUE: PropsTaxaContext = {
  taxaFixa: {
    id: null,
    codigo: "",
    descricao: "",
    dataInicial: undefined,
    dataFinal: undefined,
    valor: 0,
    ativo: false,
    nivelAcesso: null,
    taxaPaises: [],
    taxaUnidades: [],
    taxaClusters: [],
    taxaCobrancaTaxaFixas: null
  },
  revisao: false,
  setTaxaFixa: () => { }, //função de inicialização
  removeTaxaFixa: () => { }, //função de inicialização
  setRevisao: () => { }, //função de inicialização
};

//criando nosso contexto UserContext
const TaxaContext = createContext<PropsTaxaContext>(DEFAULT_VALUE);
const nameStorage = "@HappyCode/taxaFixa"
const nameStorageRevisao = "@HappyCode/taxaFixa/revisao"
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const TaxaContextProvider: React.FC = ({ children }: any) => {
  const [taxaFixa, setTaxaFixaGlobal] = useState<ITaxaFixaFullDTO>(DEFAULT_VALUE.taxaFixa);
  const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

  const setTaxaFixa = (taxaFixa) => {
    setTaxaFixaGlobal(taxaFixa);
    setObject(nameStorage, taxaFixa);
  }

  const setRevisao = (revisao) => {
    setRevisaoGlobal(revisao);
    setObject(nameStorageRevisao, revisao);
  }

  const removeTaxaFixa = () => {
    setRevisao(false);
    setTaxaFixaGlobal(DEFAULT_VALUE.taxaFixa);
    removeKey(nameStorage);
  }

  useEffect(() => {
    const taxaFixa = getObject(nameStorage)
    if (taxaFixa) {
      setTaxaFixaGlobal(taxaFixa);
    }
    const revisao = getObject(nameStorageRevisao)
    if (revisao) {
      setRevisaoGlobal(revisao);
    }
  }, []);

  return (
    <TaxaContext.Provider
      value={{
        taxaFixa,
        revisao,
        setTaxaFixa,
        removeTaxaFixa,
        setRevisao
      }}>
      {children}
    </TaxaContext.Provider>
  );
};

export const useTaxaFixaContext = (): PropsTaxaContext => {
  const context = useContext(TaxaContext);
  if (!context)
    throw new Error('useTaxaContext must be used within a TaxaContextProvider');
  return context;
};
