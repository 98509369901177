/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Select, Steps } from "antd";
import 'antd/dist/antd.css';
import { createRef, useEffect, useState } from 'react';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useParams } from 'react-router-dom';
import topo from '../../assets/img/pagamentos/confirmacao.png';
import informacoes from '../../assets/img/pagamentos/informacoes.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { Auth } from '../../models/auth.model';
import { useAuthService } from '../../services/auth.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney } from '../../util/format'; import './style.css';
import useCheckoutSedeState from './selectors/checkoutsede.state';
import { useTaxaCobrancaService } from '../../services/taxa-cobranca.service';
import moment from 'moment';

// const { Header, Footer, Sider, Content } = Layout;
const { Step } = Steps;
const { Option } = Select;

function CheckoutSede() {
    const { setIsGlobalLoading } = useGlobalContext();

    const [token, setToken] = useState<Auth>();
    const { cobranca, setCobranca } = useCheckoutSedeState();
    const { id, multa } = useParams<any>();
    const cobrancaService = useTaxaCobrancaService();
    const authService = useAuthService();
    const notification = useNotificationService();
    const step = { DADOS: 0, PAGAMENTO: 1, REVISAO: 2, CONCLUSAO: 3 };
    const [current, setCurrent] = useState(step.DADOS);

    const recaptchaRef = createRef<any>();

    const finaliza = async () => {
        setIsGlobalLoading(true);

        const captcha = await recaptchaRef.current.getValue();
        const environment = process.env.NODE_ENV;

        if (environment === 'development' || captcha) {
            cobrancaService
                .pagarmeTaxa(cobranca, token.access_token)
                .then(({ data }) => {
                    const { url, statusCobranca, acquirerReturnCode, qrCode } = data;
                    setCobranca({ ...cobranca, url, statusCobranca, acquirerReturnCode, qrCode });

                    if (!("AGR,FLH").includes(data.statusCobranca)) {
                        setCurrent(step.CONCLUSAO);
                    }

                    if (data.statusCobranca === "GRD") {
                        setCurrent(step.PAGAMENTO)
                    }
                })
                .finally(() => setIsGlobalLoading(false))
        } else {
            setIsGlobalLoading(false)
            notification({ description: "reCaptcha Inválido", type: 'warning', message: 'Aviso!' });
        }
    }

    useEffect(() => {

        setIsGlobalLoading(true);
        authService.basic()
            .then(({ data }) => {
                setToken(data);
                return data;
            })
            .then(async (responseToken) => {

                const { data } = await cobrancaService
                    .findCheckout(id, multa === undefined ? false : true, responseToken.access_token);

                if (data !== null) {
                    moment(data.dataVencimento).isBefore(Date.now()) ? setCurrent(step.REVISAO) : null;
                    setCobranca(data);
                } else {
                    notification({ description: "Cobrança não encontrada", type: 'warning', message: 'Aviso!' });
                }
            })
            .finally(() => setIsGlobalLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const copiarLink = async () => {
        navigator.clipboard.writeText(cobranca.qrCode);
        notification({ description: "", type: 'success', message: 'Qr Code Copiado' });
    }

    if (!cobranca) return null;

    const nodeQRCode = () => {

        return (<>
            <h2>Chave PIX!</h2>
            <img src={cobranca.url} />
            <Button type="primary" onClick={copiarLink}>Copiar Qr Code</Button>
        </>);

    }

    const nodeDados = () => {
        return (
            <div className="content-right">
                <h2>Pedido</h2>
                <div className="pedido">
                    <p>Unidade: {cobranca?.unidade.descricao}</p>
                </div>
                <hr />
                <div className="pedido-total valores">
                    <p>Valor original</p><span>{formatMoney(cobranca.valorOriginal)}</span>
                </div>
                <div className="pedido-total valores">
                    <p>Encargos de mora</p><span>{formatMoney(cobranca.valorEncargos || 0)}</span>
                </div>
                <div className="pedido-total">
                    <p>Total</p>
                    <span>{formatMoney(cobranca.valorCobranca)}</span>
                </div>
            </div>
        )
    }

    const nodePagamento = () => {

        return (
            <>
                <div className="catpcha">
                    <ReCaptchaV2 ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} />
                </div>
                <p className="parrafo-pedido">* O Código PIX para o pagamento será gerado e exibido na tela após a conclusão do pedido.</p>
            </>
        );

    }

    return (
        <div className="layout-checkout">
            <div className="content-body">
                {cobranca.statusCobranca === 'PGO' ? <img src={informacoes} /> : <img src={topo} />}
            </div>
            <div className="content-body">
                <div className="content-right">
                    {current === step.REVISAO ?
                        <>
                            <div className="catpcha">
                                {nodePagamento()}
                            </div>
                            <button onClick={() => finaliza()} className="button-primary" style={{ width: 100, marginLeft: '25%' }}>Gerar pix</button>
                        </>
                        : null}
                    {(cobranca.statusCobranca === "GRD" && moment(cobranca.dataVencimento).isAfter(Date.now())) || current === step.PAGAMENTO ?
                        nodeQRCode()
                        : null}
                    <div className="line"></div>
                </div>

                {nodeDados()}

            </div>
        </div >
    )



}

export default CheckoutSede;
