import moment from 'moment';
import { ILeadEscolaCleanDTO } from '../../../models/happy-code-api.model';

const useTipoRetornoState = () => {
	const getTipoRetorno = (lead: ILeadEscolaCleanDTO) => {
		if (!lead.ativo) return 'descartado';
		if (!lead.dataProximoRetorno) return 'sem';
		if (
			moment(lead.dataProximoRetorno)
				.startOf('day')
				.isSame(moment().startOf('day'))
		)
			return 'hoje';
		if (moment(lead.dataProximoRetorno).isBefore(moment())) return 'atrasado';
		if (moment(lead.dataProximoRetorno).isAfter(moment())) return 'futuro';
	};

	const getDsLabelTipoRetorno = (tipoRetorno: string) => {
		if (tipoRetorno === 'descartado') return 'Descartado';
		if (tipoRetorno === 'sem') return 'Novo / Sem retorno';
		if (tipoRetorno === 'hoje') return 'Retornar hoje';
		if (tipoRetorno === 'atrasado') return 'Atrasado';
		if (tipoRetorno === 'futuro') return 'Retornar em breve';
	};

	const getBoxColorTipoRetorno = (tipoRetorno: string) => {
		if (tipoRetorno === 'descartado') return 'b-gray';
		if (tipoRetorno === 'sem') return 'b-blue';
		if (tipoRetorno === 'hoje') return 'b-amarelo';
		if (tipoRetorno === 'atrasado') return 'b-red';
		if (tipoRetorno === 'futuro') return 'b-verde';
	};

	return {
		getTipoRetorno,
		getDsLabelTipoRetorno,
		getBoxColorTipoRetorno,
	};
};

export default useTipoRetornoState;
