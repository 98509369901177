import { CloseOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../../components/Header';
import Modal from '../../../../components/Modal';
import { useHoraSedeContext } from '../../../../context/HoraSedeContext';
import StepsCreate from '../../steps-create';
import '../../style.css';
import LabelAndInputNumber from '../../../../components/label-and-input-number/label-and-input-number.component';
import '../step-3-and-step-4.styles.scss';
import useStep4 from './use-step-4.hook';

export const HoraSedeCreateStep4: React.FunctionComponent = () => {
	const { revisao } = useHoraSedeContext();
	const [isOpen, setIsOpen] = useState(false);

	const { inputsSectionContent } = useStep4();

	return (
		<div>
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={20}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>

			<div className={'split-metropole-container'}>
				<StepsCreate current={3} />

				<div className={'split-metropole-container__inputs-buttons-container'}>
					<div className={'inputs-buttons-container__title-close-button'}>
						<h1>Split Metrópole</h1>

						<Button
							onClick={() => setIsOpen(true)}
							id="cancelar"
							className="title-close-button__close-button"
							icon={<CloseOutlined rev={undefined} />}
						/>
					</div>

					<div className="inputs-buttons-container__inputs-container">
						{inputsSectionContent.map(({ title, inputsContent }, index) => (
							<div className={'inputs-container__split-section'} key={index}>
								<h2>{title}</h2>

								<div className={'split-section__inputs-container'}>
									{inputsContent.map(
										({ label, defaultValue, value, onChange }, index) => (
											<LabelAndInputNumber
												key={index}
												label={label}
												defaultValue={defaultValue}
												value={value}
												onChange={onChange}
											/>
										)
									)}
								</div>

								{index !== inputsSectionContent.length - 1 && (
									<div className={'split-section__divisor'}></div>
								)}
							</div>
						))}
					</div>

					<div className="inputs-buttons-container__buttons">
						{revisao ? (
							<Link to="/hora/create/step-6" className={'button-primary'}>
								Prosseguir
							</Link>
						) : (
							<>
								<Link to="/hora/create/step-2" className="button-second">
									Voltar
								</Link>
								<Link to="/hora/create/step-6" className="button-primary">
									Próximo
								</Link>
							</>
						)}
					</div>
				</div>
			</div>

			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a criação desta configuração?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/hora"
			/>
		</div>
	);
};
