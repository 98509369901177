
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { ITrilhaDTO } from '../../../../models/happy-code-api.model';
import { useNotificationService } from '../../../../services/notification.service';
import { useAcademyTrilha } from '../../../../services/academy-trilha';
import { PresentImage } from '../../../../components/ApresentarImagem';
import { TipoUnidadePessoa } from '../../../../enum/tipoUnidadePessoa';

interface TItemLista {
    trilha: ITrilhaDTO;
}

const AcademyTrilhaItemList = memo(({ trilha }: TItemLista) => {
    const history = useHistory();
    const [ativo, setAtivo] = useState<boolean>();
    const trilhaService = useAcademyTrilha();
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();

    const goTo = () => {
        history.push(`/academy/trilhas/${trilha?.id}/create`)
    }

    useEffect(() => {
        setAtivo(trilha.ativo);
    }, [trilha.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a trilha?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: trilha?.descricao,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                trilhaService.patch({ id: trilha.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Aula alterada com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
        </Menu>
    );

    const alterDescription = () => {
        const tipoVisualizacao = [];
        if (trilha?.acessoB2c) {
            tipoVisualizacao.push("B2C ");
        }
        if (trilha?.acessoB2b) {
            tipoVisualizacao.push("B2B ");
        }
        if (trilha?.acessoComercial) {
            tipoVisualizacao.push("COMERCIAL");
        }
        return tipoVisualizacao;
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <PresentImage
                    link={trilha?.icone ? trilha?.icone : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + trilha?.arquivoIcone?.url} />
                <div className="nome">
                    <p>{trilha?.nome1 + " " + trilha?.nome2}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "5%" }}>
                <p>{trilha?.id}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{trilha?.negocio?.descricao}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "10%" }}>
                <p>{alterDescription()}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "10%" }}></div>
            <div className="registro-opcao" style={{ width: "15%" }}>
                <Link to={`/academy/gestao-trilha/trilha/${trilha.id}/cursos`} >
                    <Button className="button-primary mr50" id="criar-curso">
                        Ver Curso
                        <ArrowCircleRightOutlined style={{ marginLeft: 5 }} />
                    </Button>
                </Link>
            </div>
            <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

AcademyTrilhaItemList.displayName = 'AcademyTrilhaItemList';


export default AcademyTrilhaItemList;
