import { IHorarioCleanDTO } from "./happy-code-api.model";

export const horarioConfig: IHorarioCleanDTO[] = [
    {
      id: null,
      descricao: null,
      codigo: 'SEG',
      diaSemana: 'SEG',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
    {
      id: null,
      descricao: null,
      codigo: 'TER',
      diaSemana: 'TER',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
    {
      id: null,
      descricao: null,
      codigo: 'QUA',
      diaSemana: 'QUA',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
    {
      id: null,
      descricao: null,
      codigo: 'QUI',
      diaSemana: 'QUI',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
    {
      id: null,
      descricao: null,
      codigo: 'SEX',
      diaSemana: 'SEX',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
    {
      id: null,
      descricao: null,
      codigo: 'SAB',
      diaSemana: 'SAB',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
    {
      id: null,
      descricao: null,
      codigo: 'DOM',
      diaSemana: 'DOM',
      descricaoHorario: '',
      horaInicio: null,
      horaFim: null,
      ativo: true
    },
  ]