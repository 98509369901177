import { useHistory } from 'react-router-dom';
import code from '../../assets/img/box-miniatura/code.png';
import colonia from '../../assets/img/box-miniatura/colonia.png';
import { default as cb, default as curta } from '../../assets/img/box-miniatura/curtas.png';
import english from '../../assets/img/box-miniatura/english.png';
import money from '../../assets/img/box-miniatura/money.png';
import speech from '../../assets/img/box-miniatura/speech.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { IMatriculaDTO, ITurmaGradeCleanDTO } from '../../models/happy-code-api.model';
import { useTurmaService } from '../../services/turma.service';
import useMiniatura from './use-miniatura.hook';

interface Props {
	cor: string;
	variant: 'code' | 'money' | 'speech' | 'english' | 'curta' | 'colônia' | string;
	turma: ITurmaGradeCleanDTO;
	telaTurma: boolean;
	horario: string;
	diaSemana: string;
	idMiniatura: number;
	currentContractEnrollments: IMatriculaDTO[];
	otherEnrollmentsData: IMatriculaDTO[];
}

export const CardMiniaturaTurma = ({ variant, cor, turma, telaTurma, idMiniatura, currentContractEnrollments, otherEnrollmentsData }: Props) => {
	const { turmaSelectedClass, selectTurmaOnClick, disabled, turmaCinzaClass, isTurmaSelected } = useMiniatura({
		turma,
		variant,
		currentContractEnrollments,
		otherEnrollmentsData,
	});

	const { setIsGlobalLoading, tipoUnidade } = useGlobalContext();
	const { setTurma, editBtnIsVisible } = useTurmaContext();
	const turmaService = useTurmaService();
	const history = useHistory();

	const variantImageMap: Record<string, string> = {
		code,
		colonia,
		curta,
		english,
		money,
		speech,
		cb,
	};

	const handleEditTurma = (turma) => {
		setIsGlobalLoading(true);
		turmaService
			.findFullById(turma)
			.then((response) => {
				setTurma(response.data);
				history.push('/turmas/create/step-2');
				editBtnIsVisible(true);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	return (
		<>
			<button
				disabled={disabled}
				onClick={() => (!telaTurma ? selectTurmaOnClick() : handleEditTurma(turma))}
				style={{ backgroundColor: !isTurmaSelected ? cor : '#1A4DA1' }}
				className={`box-${turmaSelectedClass()}-miniatura d-flex-col-start-star box-space-grid-${idMiniatura} ${turmaCinzaClass}`}
			>
				<div className="d-flex-row-start-center w-200 gap-3">
					<img src={variantImageMap[variant.replace('ô', 'o')]} alt="" />
					<p>{turma?.descricaoCurriculo + ' (' + turma?.faixaEtariaIni + ' - ' + turma?.faixaEtariaFim + ')'}</p>
				</div>
				<div className="d-flex-row-between-center box-info w-200">
					<div className="box-sala">
						<p>{turma?.descricaoSala}</p>
					</div>
					<div className="box-vaga">
						<p>{turma?.maxAlunos}</p>
					</div>
				</div>
			</button>
		</>
	);
};
