/* eslint-disable react-hooks/exhaustive-deps */
import * as Unicons from '@iconscout/react-unicons';
import { Input, Radio, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { TipoMaterial } from '../../enum/tipoMaterial';
import { ICurriculoCleanDTO, IMaterialDTO, INegocioCleanDTO, IProdutoEscolaCleanDTO, ITipoMaterialDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAcademyArquivoService } from '../../services/academy-arquivo';
import { useAcademyMaterialService } from '../../services/academy-material';
import { useAcademyTipoMaterialService } from '../../services/academy-tipo-material';
import { useCurriculoService } from '../../services/curriculo.service';
import { useNegocioService } from '../../services/negocio.service';
import { useNotificationService } from '../../services/notification.service';
import { validateFileExtensionArquivo } from '../../util/util';
import './style.css';
import { useProdutoEscolaService } from '../../services/produto-escola.service';

type verb = 'post' | 'patch';
interface TInputs {
    open: boolean;
    onClose: () => void;
    material?: IMaterialDTO;
    cursoAulaPadrao?: number;
    verb: verb;
}

export const ModalUploadArquivoCurriculo: React.FunctionComponent<TInputs> = ({ open, onClose, material, cursoAulaPadrao, verb }: TInputs) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const materialService = useAcademyMaterialService();
    const tipoMaterialService = useAcademyTipoMaterialService();
    const arquivoService = useAcademyArquivoService();
    const negocioService = useNegocioService();
    const curriculoService = useCurriculoService();
    const produtoStemplayService = useProdutoEscolaService();
    const [uploadMaterial, setUploadMaterial] = useState<IMaterialDTO>();
    const [tipoMaterial, setTipoMaterial] = useState<ITipoMaterialDTO[]>([]);
    const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);
    const [curriculos, setCurriculos] = useState<ICurriculoCleanDTO[]>([]);
    const [produtos, setProdutos] = useState<IProdutoEscolaCleanDTO[]>([]);
    const [sendFile, setSendFile] = useState<any>();
    
    const findMaterial = () => {
        setIsGlobalLoading(true);
        materialService.findById({ id: material.id })
            .then((response) => {
                setUploadMaterial(response.data);
                setUploadMaterial({
                    ...uploadMaterial,
                    acessoB2c: response.data.acessoB2c,
                    acessoB2b: response.data.acessoB2b
                });
            }).finally(() => setIsGlobalLoading(false));
    };

    const findTipoMaterial = () => {
        return tipoMaterialService.findList()
            .then(({ data }) => {
                setTipoMaterial(data as any);
            });
    };

    const findNegocio = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('ativo', true)
        negocioService.findList(predicate)
            .then((response) => {
                setNegocios(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findCurriculo = () => {
        if (uploadMaterial?.negocio?.id) {
            setIsGlobalLoading(true);
            const predicate = new Predicate();
            predicate.addOption('ativo', true)
            predicate.addOption('negocio.id', uploadMaterial?.negocio?.id)
            curriculoService.findList(predicate)
                .then((response) => {
                    setCurriculos(response.data);
                })
                .finally(() => setIsGlobalLoading(false));
        }
    };

    const findProduto = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('ativo', true)
        produtoStemplayService.findList(predicate)
            .then((response) => {
                setProdutos(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const enviarArquivo = () => {
        setIsGlobalLoading(true);
        const fetchMaterial = async () => {
            try {
                let updatedUploadMaterial;
                if (uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_LINK || uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_OFICIAL) {
                    updatedUploadMaterial = {
                        ...uploadMaterial,
                        enderecoArquivo: uploadMaterial.descricao,
                        descricao: uploadMaterial.descricao,
                        tamanhoArquivo: 0,
                        arquivo: null
                    };
                } else {
                    const { data } = await arquivoService.upload(sendFile[0]);
                    updatedUploadMaterial = {
                        ...uploadMaterial,
                        enderecoArquivo: data.url,
                        descricao: data.codigo,
                        arquivo: { id: data.id }
                    };
                }

                if (verb === 'post') {
                    updatedUploadMaterial.cursoAulaPadrao = { ...uploadMaterial.cursoAulaPadrao, id: cursoAulaPadrao };
                    materialService.enviarArquivo(updatedUploadMaterial)
                        .then(() => {
                            notification({
                                description: `Arquivo enviado com sucesso.`,
                                type: 'success',
                                message: 'Sucesso!',
                            });
                        });
                    onClose();
                }
                if (verb === 'patch') {
                    materialService.editarArquivo(material.id, updatedUploadMaterial)
                        .then(() => {
                            notification({
                                description: `Arquivo alterado com sucesso.`,
                                type: 'success',
                                message: 'Sucesso!',
                            });
                        });
                    onClose();
                }
            } catch (error) {
                notification({
                    description: `Desculpe, não foi possível enviar o arquivo..`,
                    type: 'error',
                    message: 'Erro!',
                });
            }
            setIsGlobalLoading(false);
        };
        fetchMaterial();
    };

    const submitValidationFields = () => {
        if (!uploadMaterial?.codigo) {
            return notification({
                description: `O campo "Nome do arquivo" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        if (!uploadMaterial?.acessoB2c && !uploadMaterial?.acessoB2b) {
            return notification({
                description: `O campo "Visualização Academy" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        if (uploadMaterial?.acessoB2c) {

            if (!uploadMaterial?.negocio?.id) {
                return notification({
                    description: `O campo "Negócio" está vazio, por gentileza informar.`,
                    type: 'warning',
                    message: 'Atenção!',
                });
            }

            if (!uploadMaterial?.curriculo?.id) {
                return notification({
                    description: `O campo "Currículo" está vazio, por gentileza informar.`,
                    type: 'warning',
                    message: 'Atenção!',
                });
            }

        }

        if (uploadMaterial?.acessoB2b) {

            if (!uploadMaterial?.produto?.id) {
                return notification({
                    description: `O campo "Produto Stemplay" está vazio, por gentileza informar.`,
                    type: 'warning',
                    message: 'Atenção!',
                });
            }

        }

        if (!uploadMaterial?.tipoMaterial?.id) {
            return notification({
                description: `O campo "Tipo do arquivo" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        if (uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_LINK && !uploadMaterial.descricao) {
            return notification({
                description: `O campo "Link do arquivo" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        if (!(uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_LINK ||
            uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_OFICIAL) &&
            uploadMaterial.tamanhoArquivo === "0") {
            return notification({
                description: `O campo "Escolher arquivo" está vazio, por gentileza informar.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        if (uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_OFICIAL) {
            const extensao = uploadMaterial?.descricao?.slice(uploadMaterial?.descricao.lastIndexOf('.')).toLowerCase();
            if (extensao !== ".pdf")
                return notification({
                    description: `A extensão do link selecionado não é permitida. Por favor, selecione um link com extensão válida (.pdf).`,
                    type: 'warning',
                    message: 'Atenção!',
                });
        }

        if (uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_LINK && validateFileExtensionArquivo(uploadMaterial?.descricao)) {
            return notification({
                description: `A extensão do link selecionado não é permitida. Por favor, selecione um link com extensão válida
                (.zip, .pdf, .aia, .pptx, .capx, .docx, .exe, .rar, .png, .mcworld, .uf2, .ev3, .sb3, .sjr).`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        enviarArquivo();
    }

    const defaultVisualizacao = () => {
        let acesso;
        if (uploadMaterial?.acessoB2c) {
            acesso = "B2C"
        }
        if (uploadMaterial?.acessoB2b) {
            acesso = "B2B"
        }
        return acesso;
    }

    const handleRadioChange = (value) => {
        setUploadMaterial({
            ...uploadMaterial,
            acessoB2c: value === 'B2C',
            acessoB2b: value === 'B2B',
        });
    };

    const limparCampos = () => {
        if (uploadMaterial?.acessoB2c) {
            setUploadMaterial({
                ...uploadMaterial,
                produto: null
            });
        } else {
            setUploadMaterial({
                ...uploadMaterial,
                negocio: null,
                curriculo: null
            });
        }
    }

    useEffect(() => {
        findNegocio();
        findProduto();
        findTipoMaterial();
        if (verb === 'patch') {
            findMaterial();
        }
    }, [verb]);

    useEffect(() => {
        findCurriculo();
    }, [uploadMaterial?.negocio?.id])

    useEffect(() => {
        limparCampos();
    }, [uploadMaterial?.acessoB2c, uploadMaterial?.acessoB2b]);

    if (!open) return null;

    return (
        <div className="modalContainer">
            <div className="modal-box-upload-material" style={{ maxHeight: 500 }}>
                <button onClick={onClose} className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></button>
                <h3>Upload Arquivo</h3>
                <div className="line" />
                < div style={{ maxHeight: 350, width: "100%", margin: "auto", padding: 20, overflowY: "scroll" }}>
                    <div className="input-box" id="box-aluno">
                        <label htmlFor="codigo">Nome do arquivo</label>
                        <Input
                            id="codigo"
                            className="input"
                            placeholder="Nome"
                            type="text"
                            style={{ width: '100%' }}
                            defaultValue={uploadMaterial?.codigo}
                            value={uploadMaterial?.codigo}
                            onChange={(e) => setUploadMaterial({ ...uploadMaterial, codigo: e.target.value })}
                        />
                    </div>
                    <div className="input-box">
                        <label htmlFor="tipoAcesso">Visualização Academy</label>
                        <Radio.Group
                            value={defaultVisualizacao()}
                            onChange={(e) => handleRadioChange(e.target.value)}
                        >
                            <Space direction="vertical">
                                <Radio value={"B2C"} id="tipoAcesso" >B2C</Radio>
                                <Radio value={"B2B"}>B2B</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                    {uploadMaterial?.acessoB2c || uploadMaterial?.acessoB2b ?
                        <>
                            {uploadMaterial?.acessoB2c ?
                                <>
                                    <div className="input-box">
                                        <label htmlFor="negocio">Negócio</label>
                                        <Select
                                            style={{ width: "100%" }}
                                            id="negocio"
                                            placeholder="Selecione"
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue={uploadMaterial?.negocio?.id}
                                            value={uploadMaterial?.negocio?.id}
                                            onChange={(e) => setUploadMaterial({ ...uploadMaterial, negocio: { ...uploadMaterial.negocio, id: e } })}
                                            filterOption={(input, option) =>
                                                option?.text?.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {negocios && negocios.map(negocio =>
                                                <Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>{negocio.descricao}</Select.Option>
                                            )}
                                        </Select>
                                    </div>

                                    <div className="input-box">
                                        <label htmlFor="curriculo">Currículo</label>
                                        <Select
                                            style={{ width: "100%" }}
                                            id="curriculo"
                                            placeholder="Selecione"
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue={uploadMaterial?.curriculo?.id}
                                            value={uploadMaterial?.curriculo?.id}
                                            onChange={(e) => setUploadMaterial({ ...uploadMaterial, curriculo: { ...uploadMaterial.curriculo, id: e } })}
                                            filterOption={(input, option) =>
                                                option?.text?.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {curriculos && curriculos.map(curriculo =>
                                                <Select.Option key={curriculo.id} value={curriculo.id} text={curriculo.descricaoCurriculo}>{curriculo.descricaoCurriculo}</Select.Option>
                                            )}
                                        </Select>
                                    </div>
                                </>
                                :
                                < div className="input-box">
                                    <label htmlFor="produtoStemplay">Produto Stemplay</label>
                                    <Select
                                        style={{ width: "100%" }}
                                        id="produtoStemplay"
                                        placeholder="Selecione"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        defaultValue={uploadMaterial?.produto?.id}
                                        value={uploadMaterial?.produto?.id}
                                        onChange={(e) => setUploadMaterial({ ...uploadMaterial, produto: { ...uploadMaterial.produto, id: e } })}
                                        filterOption={(input, option) =>
                                            option?.text?.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {produtos && produtos.map(produto =>
                                            <Select.Option key={produto.id} value={produto.id} text={produto?.descricao}>{produto?.descricao}</Select.Option>
                                        )}
                                    </Select>
                                </div>
                            }
                            <div className="input-box" id="box-aluno">
                                <label htmlFor="tipoMaterial">Tipo do arquivo</label>
                                <Select
                                    id="tipoMaterial"
                                    placeholder="Selecione"
                                    style={{ width: '100%' }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    defaultValue={uploadMaterial?.tipoMaterial?.id}
                                    value={uploadMaterial?.tipoMaterial?.id}
                                    onChange={(e) => setUploadMaterial({ ...uploadMaterial, tipoMaterial: { ...uploadMaterial?.tipoMaterial, id: e } })}
                                    filterOption={(input, option) =>
                                        option?.text?.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {tipoMaterial.map(material =>
                                        <Select.Option key={material.id} value={material.id}>{material.descricao}</Select.Option>
                                    )}
                                </Select>
                            </div>

                            {uploadMaterial?.tipoMaterial?.id && (uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_LINK || uploadMaterial?.tipoMaterial?.id === TipoMaterial.ID_OFICIAL) ?
                                < div className="input-box" id="box-aluno">
                                    <span>Link do arquivo</span>
                                    <Input className="input"
                                        type="text"
                                        style={{ width: '100%' }}
                                        defaultValue={uploadMaterial?.descricao}
                                        value={uploadMaterial?.descricao}
                                        onChange={(e) => setUploadMaterial({ ...uploadMaterial, descricao: e.target.value.trim() })}
                                    />
                                </div>
                                :
                                <Input
                                    type="file"
                                    onChange={(e) => {
                                        setSendFile(e.target.files);
                                        setUploadMaterial({
                                            ...uploadMaterial,
                                            descricao: e.target.value,
                                            tamanhoArquivo: e.target.files[0]?.size.toString()
                                        })
                                    }}
                                />
                            }
                        </>
                        : null}
                </div>

                <div className="line" />
                <div className="botoes">
                    <button onClick={onClose} className="btn-cancel" id="cancelar">Cancelar</button>
                    <button onClick={submitValidationFields} className="btn-make" id="criar-usuario">{verb === "patch" ? "Editar arquivo" : "Enviar arquivo"}</button>
                </div>
            </div>
        </div >
    );
}
