import * as Unicons from '@iconscout/react-unicons';
import { Button, Collapse, DatePicker, Form, Input, InputNumber, Rate, Select, Space } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import moment from "moment";
import { useState } from "react";
import { Image } from 'react-bootstrap';
import whatSapp from "../../../../../assets/img/crm/whatsapp-icon.png";

import {
    ICampanhaLeadEscolaCleanDTO,
    IGrupoCleanDTO,
    ILeadEscolaEstagioDTO,
    ILeadEscolaFullDTO,
    ILeadEscolaHistoricoDTO,
    ILeadEscolaOrigemDTO
} from "../../../../../models/happy-code-api.model";

import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { useHistory } from 'react-router-dom';
import { useEscolaContext } from '../../../../../context/EscolaContext';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { PerfilEnum } from '../../../../../enum/perfil.enum';
import { Predicate } from '../../../../../models/predicate.model';
import { useEscolaService } from '../../../../../services/escola.service';
import { useNotificationService } from '../../../../../services/notification.service';
import { currencyFormatter, currencyParser } from '../../../../../util/format';
import { useCrmEscolaContext } from '../../../context/crm-escola.context';
import useTipoRetornoState from "../../../selectors/tipo-retorno.state";
import '../../../shared.scss';
import { ModalNote } from '../lead-anotacao/note';
import { ModalLeadDuplicata } from '../leadduplicata';
import { ModalResposta } from '../resposta';
import '../style.scss';
import useLeadDetalheState from "./selectors/lead-detalhe.state";

interface Props {
    open: boolean;
    type: string;
    boxColor: string;
    onClose: () => void;
    lead: ILeadEscolaFullDTO;
}

export const LeadDetalhe = (props: Props) => {
    const history = useHistory();
    const escolaService = useEscolaService();
    const notification = useNotificationService();
    const { usuario } = useGlobalContext();
    const { escolaParaCadastrar, setEscolaParaCadastrar } = useEscolaContext();
    const leadDetalheState = useLeadDetalheState(props)
    const { open, onClose } = props;
    const { Panel } = Collapse;
    const {
        state,
        estados, } = useCrmEscolaContext();
    const [lead, setLead] = useState(props.lead);
    const { Option } = Select;

    const [isOpenModalResposta, setIsOpenModalResposta] = useState(false);
    const [isOpenModalDuplicata, setIsOpenModalDuplicata] = useState(false);
    const [isOpenModalNote, setIsOpenModalNote] = useState(false);

    const [intra, setIntra] = useState<any>();
    const [extra, setExtra] = useState<any>();

    const { getTipoRetorno,
        getDsLabelTipoRetorno,
        getBoxColorTipoRetorno, } = useTipoRetornoState();
    const type = getTipoRetorno(leadDetalheState?.state?.lead);

    const onChangeModalResposta = () => {
        if (isOpenModalResposta === true) {
            setIsOpenModalResposta(false)
        } else {
            setIsOpenModalResposta(true)
        }
    }

    const onCloseModalResposta = () => {
        setIsOpenModalResposta(false)
    }

    const onChangeModalDuplicata = () => {
        if (isOpenModalDuplicata === true) {
            setIsOpenModalDuplicata(false)
        } else {
            setIsOpenModalDuplicata(true)
        }
    }

    const onCloseModalDuplicata = () => {
        setIsOpenModalDuplicata(false)
    }

    const onChangeModalNote = () => {
        if (isOpenModalNote === true) {
            setIsOpenModalNote(false)
        } else {
            setIsOpenModalNote(true)
        }
    }

    const onCloseModalNote = () => {
        setIsOpenModalNote(false)
    }

    const onSort = (a: ILeadEscolaEstagioDTO, b: ILeadEscolaEstagioDTO) => {
        if (a.ordem < b.ordem) {
            return -1;
        }

        if (a.ordem > b.ordem) {
            return 1;
        }
        return 0;
    }


    const renderItemHistorico = (item: ILeadEscolaHistoricoDTO, index: number) => {
        const Icone = leadDetalheState.getHistoricoIcone(item);
        const IconeTipoContato = leadDetalheState.getHistoricoIconeTipoHistorico(item);
        const isIconeTipoContatoEmpty = !IconeTipoContato;
        const flSubdescricaoIdentica = item.tipo.descricao === item.descricao;

        return <div key={item?.id} style={{ display: 'flex', flex: 1, marginTop: 5, marginBottom: 5 }} className={`lead-historico flex flex-row justify-start align-center ${index % 2 === 0 ? '' : props.boxColor}`}>
            <div className="dot"></div>
            <div className="box-name">
                <h6>{item?.usuario?.pessoa?.nome}</h6>
                <p>{moment(item.dataCriacao).format('DD/MM/YYYY hh:mm:ss')}</p>
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
                {flSubdescricaoIdentica && <div className="flex flex-row align-center box-status">
                    <Icone /> {leadDetalheState.getText(item.descricao, item.id)} <span>{leadDetalheState.renderComponenteVerMais(item)}</span>
                </div>}

                {!flSubdescricaoIdentica && <div className="flex flex-column align-start box-status w-100">
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                        <h6><Icone /></h6>
                        <h6>{item.tipo.descricao}</h6>
                    </div>

                    <small
                        onClick={() => leadDetalheState.onDetalheClick(item)}
                        className='small-historico'
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                        {!isIconeTipoContatoEmpty && <IconeTipoContato size={20} style={{ marginRight: 10 }} />}

                        <small style={{ fontSize: 16, lineHeight: "25px" }}>
                            {leadDetalheState.getText(item.descricao, item.id)}
                            <small style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                {leadDetalheState.renderComponenteVerMais(item)}
                            </small>
                        </small>
                    </small>
                </div>}
            </div>

        </div >
    }

    if (!open) return null

    function enviarMensagem() {
        const celular = `55${lead?.dddCelular}${lead?.celular?.replace('-', '')}`;
        let texto = `Olá, ${lead?.nome}\nDesejo que esteja bem!\n\nSou da Happy ${lead?.unidade?.descricao}, a escola do futuro!\nVi que você tem interesse em conhecer a gente. Por isso, estou entrando em contato contigo.\n\nFalta pouco para você conhecer o novo jeito de aprender!\nQual é o melhor dia e horário para nos fazer uma visita?\n\nAhh, você pode trazer a criança ou adolescente que deseja matricular para fazer uma aula experimental gratuita!😉`;

        texto = window.encodeURIComponent(texto);

        return window.open("https://web.whatsapp.com/send?phone=" + celular + "&text=" + texto, "_blank");
        //Obs.. use "_system", no lugar de blank, caso você esteja usando Phonegap / Cordova / Ionic ou qualquer um baseado em webview;
    }

    const gerarContrato = () => {

        if (!leadDetalheState?.state?.lead?.escola?.id) {
            notification({
                description:
                    "ATENÇÃO: para gerar o contrato é necessário efetuar o cadastro da Escola no botão "
                    + "Cadastrar escola",
                type: "error",
                message: "Erro!"
            });
        } else if (leadDetalheState?.state?.lead?.grupo?.id) {
            history.push(
                "/escolas/grupos/"
                + leadDetalheState?.state?.lead?.grupo?.id
                + "/contratos/create/step-1/"
                + leadDetalheState?.state?.lead.id
            );
        } else {
            notification({
                description:
                    "ATENÇÃO: para gerar o contrato é necessário informar o Grupo no painel Dados da "
                    + "Entidade",
                type: "error",
                message: "Erro!"
            });
        }
    }

    const cadastrarEscola = () => {

        if (leadDetalheState?.state?.lead?.grupo?.id) {
            const endereco = leadDetalheState.state.lead.endereco;

            endereco.id = null;

            setEscolaParaCadastrar({
                ...escolaParaCadastrar,
                CpfCnpj: leadDetalheState.state.lead.cpfOuCnpj,
                razaoSocial: leadDetalheState.state.lead.nome,
                nomeFantasia: leadDetalheState.state.lead.nome,
                grupo: leadDetalheState.state.lead.grupo,
                categoriaQuantidadeAlunos: leadDetalheState.state.lead.categoriaQuantidadeAlunos,
                unidade: leadDetalheState.state.lead.unidade,
                endereco: endereco,
            });

            history.push("/escolas/create/step-1");
        } else

            notification({
                description:
                    "ATENÇÃO: para cadastrar a escola é necessário informar o Grupo no painel Dados da "
                    + "Entidade",
                type: "error",
                message: "Erro!"
            });

    }

    const prepararCadastramentoEscola = () => {
        const predicate = new Predicate();

        predicate.addOption("cnpj", lead.cpfOuCnpj);
        escolaService.findCleanList(predicate).then(
            ({ data }) => {

                if (data.length > 0)

                    notification({
                        description: "ATENÇAO: Já está cadastrada uma escola com o mesmo CNPJ!",
                        type: "error",
                        message: "Erro!"
                    });

                else
                    cadastrarEscola();

            }
        );

    }

    // ----- renderizacao

    const ComponenteBotoesCadastrais = () => {

        return (<>
            {!leadDetalheState?.state?.lead?.escola?.id
                &&
                <Button className="button-venda" onClick={() => prepararCadastramentoEscola()}>
                    Cadastrar escola
                </Button>
            }
            {
                (usuario.perfil === PerfilEnum.SEDE ?
                    <Button className="button-venda" onClick={() => gerarContrato()}>
                        <Unicons.UilThumbsUp size={14} className="mr-5" />Gerar contrato
                    </Button>
                    : null)
            }
        </>);

    }

    const DivBotoes = () => {

        return (
            <div className="footer-historico flex flex-row justify-end align-center mt-30">

                {/* <Button onClick={onChangeModalDuplicata} className="button-duplicate">
        <Unicons.UilSync size={14} className="mr-5" />
        Possivel Duplicata
        </Button> */}

                {leadDetalheState?.state?.lead.ativo
                    &&

                    <Button
                        className="button-primary"
                        style={{ marginTop: 12 }}
                        onClick={leadDetalheState.onAlterarLead}>

                        Salvar
                    </Button>
                }

                {leadDetalheState?.state?.lead.ativo
                    &&
                    <Button onClick={onChangeModalResposta} className="button-descartar">
                        <Unicons.UilThumbsDown size={14} className="mr-5" />Descartar
                    </Button>
                }

                {/* { leadDetalheState?.state?.lead?.estagio.codigo === 'CNT' 
          && leadDetalheState?.state?.lead.ativo
          && !leadDetalheState?.state?.lead?.escola?.id 
          &&
            <Button className="button-venda" onClick={() => cadastrarEscola()}>  
              Cadastrar escola
            </Button>
        }
        { leadDetalheState?.state?.lead?.estagio.codigo === 'CNT' 
          && leadDetalheState?.state?.lead.ativo 
          &&
            <Button className="button-venda" onClick={() => gerarContrato()}>  
              <Unicons.UilThumbsUp size={14} className="mr-5" />Gerar contrato
            </Button>
        } */}
                {leadDetalheState?.state?.lead?.estagio.codigo === 'CNT'
                    && leadDetalheState?.state?.lead.ativo
                    && ComponenteBotoesCadastrais()
                }

            </div>
        );

    }

    const ComponenteIdentificacao = () => {

        return (<>
            <div className="text-over-input">
                <span>ID</span>
            </div>
            <div className="input-modal">
                <Form.Item initialValue={leadDetalheState?.state?.lead.id} name={'id'} rules={[{ required: false }]}>
                    <Input id="id" className="input" defaultValue={leadDetalheState?.state?.lead.id} disabled />
                </Form.Item>
            </div>
            <div className="text-over-input">
                <small>Nome<small className="vermelho">*</small></small>
            </div>
            <div className="input-modal">
                <Form.Item initialValue={leadDetalheState?.state?.lead.nome} name={'nome'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o nome completo' }]}>
                    <Input placeholder="Rosiane Camargo" bordered={false} defaultValue={leadDetalheState?.state?.lead.nome} />
                </Form.Item>
            </div>
            <div className="text-over-input">
                <span>CPF / CNPJ<span className="vermelho"></span></span>
            </div>
            <div className="input-modal">
                <Form.Item
                    name="cpfOuCnpj"
                    rules={[{ required: false, message: 'Insira o CPF ou CNPJ' }]}>
                    <CpfCnpj
                        id="cpfOuCnpj"
                        className="input"
                        type="tel"
                    />
                </Form.Item>
            </div>
        </>);

    }

    const ComponenteTelefones = () => {

        return (<>
            <div className="row">
                <div className="flex" style={{ width: '20%' }}>
                    <div className="text-over-input">
                        <small>DDD<small className="vermelho">*</small></small>
                    </div>
                    <div className="input-modal">
                        <Form.Item initialValue={leadDetalheState?.state?.lead?.dddCelular}
                            name={'dddCelular'} style={{ width: '100%' }}
                            rules={[{ required: false, message: '' }]}>
                            <MaskedInput mask="11" defaultValue={leadDetalheState?.state?.lead?.dddCelular} placeholder="99" bordered={false} />
                        </Form.Item>
                    </div>
                </div>
                <div className="horizontal-separator" />
                <div className="flex flex-2">
                    <div className="text-over-input">
                        <small>Celular<small className="vermelho">*</small></small>
                    </div>
                    <div className="input-modal">
                        <Form.Item initialValue={leadDetalheState?.state?.lead?.celular}
                            name={'celular'} style={{ width: '100%' }}
                            rules={[{ required: false, message: 'Informe o celular' }]}>
                            <MaskedInput mask="11111-1111" defaultValue={leadDetalheState?.state?.lead?.celular}
                                placeholder='Celular' bordered={false} />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="flex" style={{ width: '20%' }}>
                    <div className="text-over-input">
                        <small>DDD</small>
                    </div>
                    <div className="input-modal">
                        <Form.Item initialValue={leadDetalheState?.state?.lead?.dddTelefone} name={'dddTelefone'} style={{ width: '100%' }}
                            rules={[{ required: false, message: '' }]}>
                            <MaskedInput mask="11" defaultValue={leadDetalheState?.state?.lead?.dddTelefone} placeholder="99" bordered={false} />
                        </Form.Item>
                    </div>
                </div>
                <div className="horizontal-separator" />
                <div className="flex flex-2">
                    <div className="text-over-input">
                        <small>Telefone</small>
                    </div>
                    <div className="input-modal">
                        <Form.Item initialValue={leadDetalheState?.state?.lead?.telefone} name={'telefone'}
                            style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o telefone' }]}>
                            <MaskedInput mask="11111-1111" defaultValue={leadDetalheState?.state?.lead?.telefone}
                                placeholder='Celular' bordered={false} />
                        </Form.Item>
                    </div>
                </div>
            </div>
        </>);

    }

    const ComponenteContatos = () => {

        return (<>
            <div className="text-over-input">
                <small>Email<small className="vermelho">*</small></small>
            </div>
            <div className="input-modal">
                <Form.Item initialValue={leadDetalheState?.state?.lead.email} name={'email'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o e-mail' }]}>
                    <Input placeholder="R.Camargo@emcil.com.br" bordered={false} defaultValue={leadDetalheState?.state?.lead.email} />
                </Form.Item>
            </div>
            {ComponenteTelefones()}
        </>);

    }

    const DivCampanha = () => {

        return (
            <div className="flex-1 flex">
                <div className="text-over-input">
                    <small>Campanha<small className="vermelho"></small></small>
                </div>
                <div className="input-modal">
                    <Form.Item
                        name={['campanha', "id"]}
                        fieldKey={['campanha', "id"]}
                        initialValue={leadDetalheState?.state?.lead?.campanha?.id}
                        style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a campanha' }]}>
                        <Select
                            id="campanha"
                            style={{ width: '100%' }}
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option && option.children.toLowerCase().indexOf(input.toLowerCase())
                            }>
                            {state?.campanhas?.map?.((campanha: ICampanhaLeadEscolaCleanDTO) =>
                                <Option key={campanha.id} value={campanha.id} text={campanha.descricao}>{campanha.descricao}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        );

    }

    const DivPorte = () => {

        return (
            <div className="flex-1 flex">
                <div className="text-over-input">
                    <span>Porte (Quant. alunos)<span className="vermelho"></span></span>
                </div>
                <div className="input-modal">
                    <Form.Item
                        initialValue={leadDetalheState?.state?.lead?.categoriaQuantidadeAlunos?.id}
                        name={['categoriaQuantidadeAlunos', "id"]}
                        fieldKey={['categoriaQuantidadeAlunos', "id"]}
                        rules={[{ required: false, message: 'Selecione a quantidade' }]}>
                        <Select
                            id="categoriaQuantidadeAlunos"
                            placeholder="Selecione a quantidade"
                            allowClear
                        >
                            {state?.categoriasQuantidadeAlunos && state?.categoriasQuantidadeAlunos.map((item, index) => (
                                <Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        );

    }

    const DivUnidade = () => {

        return (
            <div className="flex-1 flex">
                <div className="text-over-input">
                    <small>Unidade</small>
                </div>
                <div className="input-modal">
                    <Form.Item
                        name={['unidade', "id"]}
                        fieldKey={['unidade', "nome"]}
                        initialValue={leadDetalheState?.state?.lead?.unidade?.id}
                        style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a unidade' }]}>
                        <Select
                            disabled={usuario.perfil === PerfilEnum.FRANQUIA ? true : false}
                            style={{ width: '100%' }}
                            bordered={false}
                            defaultValue={leadDetalheState?.state?.lead?.unidade?.id}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().includes(input.toLowerCase())
                            }>
                            <Select.Option key="SEDE" value={null}>SEDE</Select.Option>
                            {state.unidades && state.unidades.map((item, index) => (
                                <Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        );

    }

    const DivGrupo = () => {

        return (
            <div className="flex-1 flex">
                <div className="text-over-input">
                    <small>Grupo<small className="vermelho"></small></small>
                </div>
                <div className="input-modal">

                    <Form.Item
                        name={['grupo', "id"]}
                        fieldKey={['grupo', "nome"]}
                        initialValue={leadDetalheState?.state?.lead?.grupo?.id}
                        style={{ width: '100%' }}>

                        <Select
                            style={{ width: '100%' }}
                            bordered={false}
                            defaultValue={leadDetalheState?.state?.lead?.grupo?.id}
                            allowClear>

                            {state?.grupos?.map?.(
                                (grupo: IGrupoCleanDTO) =>
                                    <Option key={grupo.id} value={grupo.id}>{grupo.nomeFantasia}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </div>
            </div>
        );

    }

    const DivEscola = () => {

        return (
            <div className="flex-1 flex">
                <div className="text-over-input">
                    <small>Escola cadastrada<small className="vermelho"></small></small>
                </div>

                {/* <div className="input-modal">{leadDetalheState?.state?.lead?.escola?.nomeFantasia}</div> */}

                <div className="input-modal">
                    <Form.Item
                        name="escola"
                        initialValue={leadDetalheState?.state?.lead?.escola?.nomeFantasia}
                    >
                        {leadDetalheState?.state?.lead?.escola?.nomeFantasia}
                    </Form.Item>
                </div>

            </div>
        );

    }

    const PanelDadosEntidade = () => {

        return (
            <Panel header="Dados da Entidade" key="1">
                <div className="box-collapse">
                    {ComponenteIdentificacao()}
                    {ComponenteContatos()}
                    {DivCampanha()}
                    {DivPorte()}
                    {DivUnidade()}
                    {DivGrupo()}
                    {DivEscola()}
                </div>
            </Panel>
        );

    }

    return (
        <>
            <div className="flex flex-row justify-center align-center modal-container">
                <div className={`flex flex-column justify-start align-start modal-leads${props.type}`}>
                    <Button onClick={onClose} className="button-x">x</Button>

                    <Form
                        style={{ maxWidth: '100%' }}
                        name="basic"
                        initialValues={props?.lead}
                        form={leadDetalheState.form}
                    >
                        <div className="flex flex-row justify-start align-center w-100 mb-20">
                            <h3 className="w-25">{leadDetalheState?.state?.lead?.nome}</h3>
                            <div className="alin-cem-whatsapp">
                                {lead?.celular !== undefined ?
                                    <a onClick={enviarMensagem} rel="noreferrer">
                                        <Image className="crm-whatsapp" src={whatSapp} alt="telefone-lead" />
                                    </a>
                                    : null}
                            </div>
                            <div className="status">{getDsLabelTipoRetorno(type)}</div>
                            <div className="icons flex flex-row justify-start align-center w-100">
                                <Form.Item initialValue={leadDetalheState?.state?.lead?.avaliacaoLead} name={'avaliacaoLead'}>
                                    <Rate
                                        defaultValue={leadDetalheState?.state?.lead?.avaliacaoLead}
                                    />
                                </Form.Item>
                                <Unicons.UilFileAlt style={{ cursor: 'pointer' }} onClick={onChangeModalNote} color="var(--cinza-azulado-200)" size={24} />
                            </div>
                        </div>
                        <div className="flex flex-row justify-between align-start">
                            <div className="w-40 mr-10">
                                <Form.Item name={['novo']} style={{ display: 'none' }} />
                                <Collapse collapsible="header" defaultActiveKey={['1']}>
                                    {PanelDadosEntidade()}
                                </Collapse>
                                <Space className="w-100" direction="vertical">
                                    <Collapse collapsible="header">
                                        <Panel header="Endereço" key="2">

                                            <Form.Item name={['endereco', "id"]} style={{ display: 'none' }} />

                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>CEP</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item name={['endereco', "cep"]}
                                                        initialValue={leadDetalheState?.state?.lead?.endereco?.cep}
                                                        rules={[{ required: false, message: 'Insira o CEP' }]}>
                                                        <Input
                                                            id="cep"
                                                            defaultValue={leadDetalheState?.state?.lead?.endereco?.cep}
                                                            onChange={e => leadDetalheState.onCepChange(e.target.value)}
                                                            className="input"
                                                            maxLength={8}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item name={['endereco', "pais", "id"]} style={{ display: 'none' }} />

                                                <div className="text-over-input">
                                                    <small>País</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        name={['endereco', "paisId"]}
                                                        fieldKey={['endereco', "nome"]}
                                                        initialValue={leadDetalheState?.state?.lead?.endereco?.paisId}
                                                        rules={[{ required: false, message: 'Selecione um País' }]}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            bordered={false}
                                                            onSelect={(e) => leadDetalheState.handleChangePais(e)}
                                                            id="pais"
                                                            placeholder="Selecione um País"
                                                            allowClear
                                                            defaultValue={leadDetalheState?.state?.lead?.endereco?.paisId}
                                                        >
                                                            {state?.paises && state?.paises.map((item, index) => (
                                                                <Select.Option key={index} value={item.id} >{item.nome}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Estado</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item name={['endereco', "nomeUF"]}
                                                        initialValue={leadDetalheState?.state?.lead?.endereco?.nomeUF}
                                                        rules={[{ required: false, message: 'Selecione um Estado' }]}>
                                                        <Select
                                                            id="estado"
                                                            placeholder="Selecione um Estado"
                                                            allowClear
                                                            defaultValue={leadDetalheState?.state?.lead.endereco?.nomeUF}
                                                        >
                                                            {estados && estados.map((item) => (
                                                                <Select.Option key={item.id} value={item.nome}>{item.nome}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Cidade</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={leadDetalheState?.state?.lead?.endereco?.cidade}
                                                        name={['endereco', "cidade"]} rules={[{ required: false, message: 'Selecione uma Cidade' }]}>
                                                        <Input
                                                            id="cidade"
                                                            className="input"
                                                            defaultValue={leadDetalheState?.state?.lead?.endereco?.cidade}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="row">
                                                    <div className="flex flex-1">
                                                        <div className="text-over-input">
                                                            <small>Logradouro</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item
                                                                initialValue={leadDetalheState?.state?.lead?.endereco?.logradouro}
                                                                name={['endereco', "logradouro"]} rules={[{ required: false, message: 'Insira o Logradouro' }]}>
                                                                <Input
                                                                    id="logradouro"
                                                                    className="input"
                                                                    defaultValue={leadDetalheState?.state?.lead?.endereco?.logradouro}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="horizontal-separator" />
                                                    <div className="flex" style={{ width: '20%' }}>
                                                        <div className="text-over-input">
                                                            <small>Número</small>
                                                        </div>
                                                        <div className="input-modal">
                                                            <Form.Item
                                                                initialValue={leadDetalheState?.state?.lead?.endereco?.numero}
                                                                name={['endereco', "numero"]} rules={[{ required: false, message: 'Insira o Número' }]}>
                                                                <Input
                                                                    id="numero"
                                                                    className="input"
                                                                    defaultValue={leadDetalheState?.state?.lead?.endereco?.numero}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-over-input">
                                                    <small>Complemento</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={leadDetalheState?.state?.lead?.endereco?.complemento}
                                                        name={['endereco', "complemento"]}>
                                                        <Input
                                                            id="complemento"
                                                            className="input"
                                                            defaultValue={leadDetalheState?.state?.lead?.endereco?.complemento}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse collapsible="header">
                                        <Panel header="Contatos" key="3">
                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>Cargo<small className="vermelho">*</small></small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={leadDetalheState?.state?.lead.cargoContato}
                                                        name={'cargoContato'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o cargo' }]}>
                                                        <Input placeholder="Cargo..." bordered={false} defaultValue={leadDetalheState?.state?.lead.cargoContato} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>Nome<small className="vermelho">*</small></small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={leadDetalheState?.state?.lead.nomeContato}
                                                        name={'nomeContato'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o nome do contato' }]}>
                                                        <Input placeholder="Cargo..." bordered={false} defaultValue={leadDetalheState?.state?.lead.nomeContato} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="flex" style={{ width: '20%' }}>
                                                    <div className="text-over-input">
                                                        <small>DDD</small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item initialValue={leadDetalheState?.state?.lead?.dddTelefoneContato} name={'dddTelefoneContato'} style={{ width: '100%' }}
                                                            rules={[{ required: false, message: '' }]}>
                                                            <MaskedInput mask="11" defaultValue={leadDetalheState?.state?.lead?.dddTelefoneContato} placeholder="99" bordered={false} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="horizontal-separator" />
                                                <div className="flex flex-2">
                                                    <div className="text-over-input">
                                                        <small>Telefone</small>
                                                    </div>
                                                    <div className="input-modal">
                                                        <Form.Item initialValue={leadDetalheState?.state?.lead?.telefoneContato} name={'telefoneContato'}
                                                            style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o telefoneContato' }]}>
                                                            <MaskedInput mask="11111-1111" defaultValue={leadDetalheState?.state?.lead?.telefoneContato}
                                                                placeholder='Celular' bordered={false} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>E-mail</small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={leadDetalheState?.state?.lead.emailContato}
                                                        name={'emailContato'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insira o nome do contato' }]}>
                                                        <Input placeholder="Cargo..." bordered={false} defaultValue={leadDetalheState?.state?.lead.emailContato} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse collapsible="header">
                                        <Panel header="Informações do Lead" key="3">
                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>Responsável pelo Lead<small className="vermelho">*</small></small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        initialValue={leadDetalheState?.state?.lead.responsavel}
                                                        name={'responsavel'} style={{ width: '100%' }} rules={[{ required: false, message: 'Responsável pelo Lead' }]}>
                                                        <Input placeholder="Responsável..." bordered={false} defaultValue={leadDetalheState?.state?.lead.responsavel} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="box-collapse">
                                                <div className="text-over-input">
                                                    <small>Origem<small className="vermelho">*</small></small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        name={['origem', "id"]}
                                                        fieldKey={['origem', "nome"]}
                                                        style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a origem do lead' }]}>
                                                        <Select id="origem" style={{ width: '100%' }} bordered={false}>
                                                            {state?.leadOrigens?.map?.((origem: ILeadEscolaOrigemDTO) => <Option key={origem.id} value={origem.id}>{origem.descricao}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>


                                            <div className="flex-1 flex">
                                                <div className="text-over-input">
                                                    <small>Estágio<small className="vermelho">*</small></small>
                                                </div>
                                                <div className="input-modal">
                                                    <Form.Item
                                                        name={['estagio', "id"]}
                                                        fieldKey={['estagio', "nome"]}
                                                        initialValue={leadDetalheState?.state?.lead?.estagio?.id}
                                                        style={{ width: '100%' }} rules={[{ required: true, message: 'Selecione o estágio do lead' }]}>
                                                        <Select id="estagio" style={{ width: '100%' }} bordered={false} defaultValue={leadDetalheState?.state?.lead?.estagio?.id}>
                                                            {state?.leadEstagios?.sort(onSort).map?.((estagio: ILeadEscolaEstagioDTO) => <Option key={estagio.id} value={estagio.id}>{estagio.descricao}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="text-over-input">
                                                <small>Data Próximo Retorno</small>
                                            </div>
                                            <div className="input-modal">
                                                <Form.Item
                                                    initialValue={moment(leadDetalheState?.state?.lead.dataProximoRetorno)}
                                                    name={['dataProximoRetorno']}
                                                    fieldKey={['dataProximoRetorno']}
                                                    valuePropName='date'
                                                    style={{ width: '100%' }} rules={[{ required: false, message: 'Selecione a data de retorno' }]}>
                                                    <DatePicker
                                                        // onChange={(value) => setCampo('dataProximoRetorno', value)}
                                                        placeholder="Data retorno"
                                                        suffixIcon={null}
                                                        format={'DD-MM-YYYY'}
                                                        // value={leadDetalheState?.state?.lead.dataProximoRetorno ? moment(state?.state?.lead.dataProximoRetorno) : undefined}
                                                        defaultValue={moment(leadDetalheState?.state?.lead.dataProximoRetorno)}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </div>

                                        </Panel>
                                    </Collapse>
                                    <Collapse collapsible="header">
                                        <Panel header="Financeiro" key="3">
                                            <div className="col">
                                                <div className="flex width-100">
                                                    <div className="input-franquia ">
                                                        <span>Valor Licença Intra</span>
                                                        <Form.Item
                                                            initialValue={leadDetalheState?.state?.lead?.precoPropostoIntra}
                                                            name="precoPropostoIntra"
                                                            rules={[{ required: false, message: 'Intra' }]}>
                                                            <InputNumber
                                                                placeholder={'Intra'}
                                                                //formatter={value => `${value}%`}
                                                                //parser={value => parseFloat(value.replace('%', ''))}
                                                                // value={intra}
                                                                min={0}
                                                                onChange={setIntra}
                                                                formatter={currencyFormatter}
                                                                style={{ padding: 10, width: '100%' }}
                                                                className='inputNumberIntraExtra'
                                                                parser={currencyParser}
                                                                decimalSeparator=','
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="horizontal-separator" />
                                            </div>

                                            <div className="col">
                                                <div className="flex width-100">
                                                    <div className="input-franquia">
                                                        <span>Valor Licença Extra</span>
                                                        <Form.Item
                                                            name="precoPropostoExtra"
                                                            initialValue={leadDetalheState?.state?.lead?.precoPropostoExtra}
                                                            rules={[{ required: false, message: 'Extra', }]}>
                                                            <InputNumber
                                                                placeholder={'Extra'}
                                                                //formatter={value => `${value}%`}
                                                                //parser={value => parseFloat(value.replace('%', ''))}
                                                                value={extra}
                                                                min={0}
                                                                style={{ padding: 10 }}
                                                                onChange={setExtra}
                                                                decimalSeparator=','
                                                                formatter={currencyFormatter}
                                                                parser={currencyParser}
                                                                className='inputNumberIntraExtra'
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse collapsible="header">
                                        <Panel header="Status" key="3">
                                            <div className="col">
                                                <div className="flex width-100">
                                                    <div className="input-franquia">
                                                        <span>Motivo declínio<span className="vermelho">*</span></span>
                                                        <Form.Item
                                                            initialValue={leadDetalheState?.state?.lead?.motivoDeclinio}
                                                            name={'motivoDeclinio'} style={{ width: '100%' }}
                                                            rules={[{ required: false, message: 'Insira o motivo' }]}>
                                                            <Input id="motivoDeclinio" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </Space>

                            </div>
                            <div className="w-60 box-historico">
                                <div className="header-historico">
                                    <span>Histórico</span>
                                </div>
                                <div className="body-historico w-100 flex flex-row justify-start align-start">
                                    <div className="line-vertical flex justify-center align-start">
                                        <div className="line"></div>
                                    </div>
                                    <div className="leads-vertical w-90 flex flex-column">
                                        {leadDetalheState?.state?.lead.historicos.map(renderItemHistorico as any).reverse()}
                                    </div>
                                </div>
                                {DivBotoes()}
                            </div>
                        </div>

                    </Form>
                </div>
                <ModalResposta
                    open={isOpenModalResposta}
                    onClose={onCloseModalResposta}
                    status="Descartar lead"
                    text={`Deseja mesmo descartar as informações do lead "${leadDetalheState?.state?.lead?.nome}?"`}
                    onCancelar={onCloseModalResposta}
                    onOkay={leadDetalheState.onDescartarLead}
                />
                <ModalLeadDuplicata
                    open={isOpenModalDuplicata}
                    onClose={onCloseModalDuplicata}
                />
                <ModalNote
                    open={isOpenModalNote}
                    onClose={onCloseModalNote}
                    lead={leadDetalheState?.state?.lead}
                />
            </div >
        </>
    )
}
