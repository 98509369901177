import {
	IGrupoPessoaTipoCleanDTO,
	IGrupoPessoaTipoDTO,
	IGrupoPessoaTipoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useGrupoPessoaTipoService = () => {
	const resourceURL = 'grupo-pessoa-tipo';

	return {
		...baseFindList<IGrupoPessoaTipoCleanDTO, IGrupoPessoaTipoDTO, IGrupoPessoaTipoFullDTO>(resourceURL),
	};
};
