import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { useGlobalContext } from '../../context/GlobalContext';

import { MenuDetalheNegocioOferta } from '../../components/MenuDetalheNegocioOferta';
import { INegocioUnidadeDTO } from '../../models/happy-code-api.model';
import { useNegocioUnidadeService } from '../../services/negocio-unidade.service';
import { formatMoney } from '../../util/format';

export const HoraUnidadeCreateStep3: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const negocioUnidade = useNegocioUnidadeService();
    const [restartOferta, setRestartOferta] = useState<boolean>(false)
    const { idNegocio } = useParams<any>();
    const [oferta, setOferta] = useState<INegocioUnidadeDTO>();

    const history = useHistory();
    const goTo = (url) => history.push(url);

    const findOferta = () => {
        setIsGlobalLoading(true);
        negocioUnidade.findById({ id: idNegocio })
            .then((response) => {
                setOferta(response.data);
            }).finally(() => setIsGlobalLoading(false));
    };

    useEffect(() => {
        findOferta();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container-fondo">
            <Header />
            <div className="progress">
                <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
            </div>
            <MenuDetalheNegocioOferta
                setRestartOferta={setRestartOferta}
                oferta={oferta}
            />
            <div className="franquia" style={{ backgroundColor: '#F5F5F9' }}>
                <div className="franquia-row">
                    <h5>Revisão de Dados</h5>
                    <div className="row-revisao">
                        <div className="box-revisao" style={{ width: "100%" }}>
                            <div className="dados">
                                <Button id="editar-step-4" className="button-line" onClick={() => goTo(`/negocio-oferta/step-1/${idNegocio}`)}>Editar</Button>
                                <h4>Preço Mínimo Matrícula</h4>
                                <h4>Matrícula</h4>
                                <div className="nome">
                                    <h5>Preço Mínimo Matrícula</h5>
                                    <span style={{ fontSize: 18 }}>{formatMoney(oferta?.valorMinimo)}</span>
                                </div>
                                <div className="dados-franquia" id="cursos-info">
                                    <div className="cpf" id="cursos-info">
                                        <h6>Valor split</h6>
                                        <span>{formatMoney(oferta?.valorHora)}</span>
                                    </div>
                                    <div className="cpf" id="cursos-info">
                                        <h6>Quant. Parcelas</h6>
                                        <span>{oferta?.numeroParcelasMaximo}</span>
                                    </div>
                                </div>
                                <div className="dados-franquia" id="cursos-info">
                                    <div className="cpf" id="cursos-info">
                                        <h6>Valor split taxa</h6>
                                        <span>{formatMoney(oferta?.valorSplitTaxa)}</span>
                                    </div>
                                    <div className="cpf" id="cursos-info">
                                        <h6>Prorrogação dias</h6>
                                        <span>{oferta?.prorrogacaoDiasMatricula}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="line" style={{ marginBottom: 20 }}></div>
                            <div className="dados">
                                <Button id="editar-step-4" className="button-line" onClick={() => goTo(`/negocio-oferta/step-2/${idNegocio}`)}>Editar</Button>
                                <h4>Rematrícula</h4>
                                <div className="nome">
                                    <h5>Preço Mínimo Matrícula</h5>
                                    <span style={{ fontSize: 18 }}>{formatMoney(oferta?.valorMinimoRematricula)}</span>
                                </div>
                                <div className="dados-franquia" id="cursos-info">
                                    <div className="cpf" id="cursos-info">
                                        <h6>Valor split</h6>
                                        <span>{formatMoney(oferta?.valorSplitTaxaRematricula)}</span>
                                    </div>
                                    <div className="cpf" id="cursos-info">
                                        <h6>Quant. Parcelas</h6>
                                        <span>{oferta?.numeroParcelasMaximoRematricula}</span>
                                    </div>
                                </div>
                                <div className="dados-franquia" id="cursos-info">
                                    <div className="cpf" id="cursos-info">
                                        <h6>Valor split taxa</h6>
                                        <span>{formatMoney(oferta?.valorSplitTaxaRematricula)}</span>
                                    </div>
                                    <div className="cpf" id="cursos-info">
                                        <h6>Prorrogação dias</h6>
                                        <span>{oferta?.prorrogacaoDiasRematricula}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="botao-voltar-revisao" style={{ top: "17%" }}>
                        <Button
                            id="voltar"
                            className="button-second"
                            icon={<ArrowLeftOutlined rev={undefined}/>}
                            onClick={() => history.goBack()}>
                            Voltar
                        </Button>
                    </div >
                </div >
            </div >
        </div >
    );
}