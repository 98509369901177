/* eslint-disable react-hooks/exhaustive-deps */
// import { Sidebar } from "../../sub-components/Sidebar";
import {Form, Select} from 'antd';
import {useEffect, useState} from 'react';
import NewMenu from '../../components/NewMenu';
import {ILeadEscolaEstagioDTO, ILeadEscolaFullDTO, ILogCleanDTO, IUnidadeDTO,} from '../../models/happy-code-api.model';
import {Header} from './components/Header';
import {Lead} from './components/Lead';
import {CrmEscolaContextProvider, useCrmEscolaContext,} from './context/crm-escola.context';
import './shared.scss';
import './style.scss';
import {useLogService} from '../../services/log.service';

const Board = () => {
	const {
		state,
		setCrmEscolaContext,
		fetchAll,
		isSede,
		onChangeUnidade,
		unidadeUsuarioLogado,
	} = useCrmEscolaContext();
	const { Option } = Select;

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso tela crm b2b',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	useEffect(() => {
		fetchAll();

		// if (!unidadeUsuarioLogado?.id)

		//   notification({
		//     description: "Selecione uma unidade para exibir os leads", type: "info", message: "Atenção"
		//   });
	}, []);

	const onChangeQuantidadeItensPorPagina = (e) => {
		setCrmEscolaContext((state) => ({ ...state, qtLeadsPorPaginas: e }));
	};

	const onSort = (a: IUnidadeDTO, b: IUnidadeDTO) => {
		if (a.descricao < b.descricao) {
			return -1;
		}

		if (a.descricao > b.descricao) {
			return 1;
		}
		return 0;
	};

	const onSortBoards = (a: ILeadEscolaEstagioDTO, b: ILeadEscolaEstagioDTO) => {
		if (a.ordem < b.ordem) {
			return -1;
		}

		if (a.ordem > b.ordem) {
			return 1;
		}
		return 0;
	};

	return (
		<>
			<NewMenu selecionado={25} />
			<div
				className="dashboard-crm"
				style={{ height: '100vh', overflowY: 'scroll' }}
			>
				{/* <Header 
    filtros={true} button={unidadeUsuarioLogado?.id ? "Novo Lead" : null} modal="lead" /> */}
				<Header filtros={true} button="Novo Lead" modal="lead" />
				<div className="flex-col">
					<div className="flex flex-row justify-end">
						{isSede && (
							<div
								className="flex-row flex align-center justify-end"
								style={{ marginRight: 45, marginTop: 10 }}
							>
								<div>Selecionar Unidade</div>
								<div className="filtro-estado" style={{ marginLeft: 10 }}>
									<Form.Item name={'unidade'}>
										<Select
											onChange={onChangeUnidade}
											defaultValue={-1}
											dropdownStyle={{ color: 'black' }}
											style={{ width: '100%' }}
											bordered={true}
										>
											<Option key={-1} value={-1}>
												(SEDE)
											</Option>
											{state?.unidades
												?.sort(onSort)
												.map?.((item: IUnidadeDTO) => (
													<Option key={item.id} value={item.id}>
														{item.descricao}
													</Option>
												))}
										</Select>
									</Form.Item>
								</div>
							</div>
						)}
						<div
							className="flex-row flex align-center justify-end"
							style={{ marginRight: 45, marginTop: 10 }}
						>
							<div>Exibir</div>
							<div className="filtro-estado" style={{ marginLeft: 10 }}>
								<Form.Item name={'quantidade'}>
									<Select
										onChange={onChangeQuantidadeItensPorPagina}
										defaultValue={10}
										dropdownStyle={{ color: 'black' }}
										style={{ width: '100%' }}
										bordered={true}
									>
										<Option key={10} value={10}>
											{'10 itens'}
										</Option>
										<Option key={20} value={20}>
											{'20 itens'}
										</Option>
										<Option key={50} value={50}>
											{'50 itens'}
										</Option>
										<Option key={100} value={100}>
											{'100 itens'}
										</Option>
										<Option key={200} value={200}>
											{'200 itens'}
										</Option>
										<Option key={1000} value={1000}>
											{'1000 itens'}
										</Option>
										{/*<Option key={99999999} value={99999999}>{'todos'}</Option>*/}
									</Select>
								</Form.Item>
							</div>
						</div>
					</div>
					<div className="area-tasks flex flex-row justify-between align-start">
						<div
							className="flex flex-row justify-start align-start"
							style={{ overflowX: 'scroll', padding: 20, gap: 10 }}
						>
							{state?.boards?.sort(onSortBoards).map?.((board) => {
								return (
									<div key={board?.id} className="area-column">
										<div className="header flex flex-row justify-between align-center">
											<h3>{board?.descricao}</h3>
											<h3>({board?.leads?.length})</h3>
										</div>
										<div className="body">
											{board?.leads?.map?.((lead: ILeadEscolaFullDTO) => {
												return (
													<Lead
														key={lead.id}
														lead={lead}
														type={lead.novo ? 'sem' : 'hoje'}
													/>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const CrmEscola = () => {
	return (
		<CrmEscolaContextProvider>
			<div className="container-happy">{<Board />}</div>
		</CrmEscolaContextProvider>
	);
};
