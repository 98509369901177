import * as Unicons from '@iconscout/react-unicons';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../assets/img/aluno/aluno.png';
import footer from '../../assets/img/menu/footer.svg';
import Logo from '../../assets/img/menu/logo.svg';
import { useGlobalContext } from '../../context/GlobalContext';
import './style.css';

interface Classe {
  selecionado?: string;
}

const Menu = memo((props: Classe) => {
  const { usuario } = useGlobalContext();

  const returnClassName = (menu) => props.selecionado === menu ? 'item-selected' : 'item';
  const returnColor = (menu) => props.selecionado === menu ? '#895CF2' : '#92A9CB';
  const returnAcessoFuncao = (listaFuncoes: string[]) => listaFuncoes.some(funcao => usuario && funcao === usuario.funcao);

  return (
    <div className="menu">
      <img src={Logo} alt="" />
      {usuario && usuario.perfil === 'SEDE' ?
        <ul className="list-menu">
          {returnAcessoFuncao(['ADM']) && <Link to="/dashboard">
            <li className={returnClassName("1")}>
              <Unicons.UilBuilding size="20" color={returnColor("1")} />
              <span>Franquias</span>
            </li>
          </Link>}
          {returnAcessoFuncao(['ADM']) && <Link to="/autonomia">
            <li className={returnClassName("2")}>
              <Unicons.UilClipboardNotes size="20" color={returnColor("2")} />
              <span>Autonomia</span>
            </li>
          </Link>}
          {returnAcessoFuncao(['ADM']) && <Link to="/usuarios">
            <li className={returnClassName("3")}>
              <Unicons.UilUsersAlt size="20" color={returnColor("3")} />
              <span>Usuários</span>
            </li>
          </Link>}
          {returnAcessoFuncao(['ADM', 'GSA', 'GSF']) && <Link to="/cursos">
            <li className={returnClassName("4")}>
              <Unicons.UilGraduationCap size="20" color={returnColor("4")} />
              <span>Cursos</span>
            </li>
          </Link>}
          {returnAcessoFuncao(['ADM', 'GSF']) && <Link to="/campanhas">
            <li className={returnClassName("5")}>
              <Unicons.UilBolt size="20" color={returnColor("5")} />
              <span>Campanhas</span>
            </li>
          </Link>}
          {returnAcessoFuncao(['ADM', 'GSF']) && <Link to="/taxas">
            <li className={returnClassName("6")}>
              <Unicons.UilReceipt size="20" color={returnColor("6")} />
              <span>Taxas</span>
            </li>
          </Link>}
          {returnAcessoFuncao(['ADM', 'GSF']) && <Link to="/financeiro-sede">
            <li className={returnClassName("7")}>
              <Unicons.UilDollarSign size="20" color={returnColor("7")} />
              <span>Financeiro</span>
            </li>
          </Link>}
        </ul>
        :
        <ul className="list-menu">
          {returnAcessoFuncao(['ADM']) && <Link to="/colaboradores">
            <li className={returnClassName("9")}>
              <Unicons.UilChartLine size="20" color={returnColor("9")} />
              <span>Colaboradores</span>
            </li>
          </Link>}
        </ul>}
      <img src={avatar} alt="" className="avatar" />
      <Unicons.UilExport style={{ transform: 'rotate(90deg)' }} size="20" color={returnColor("9")} />
      <img src={footer} alt="" className="footer" />
    </div>
  );
})

Menu.displayName = 'Menu';

export default Menu;