import {
	ISituacaoCampanhaCleanDTO,
	ISituacaoCampanhaDTO,
	ISituacaoCampanhaFullDTO,
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useSituacaoCampanhaService = () => {
	const resourceURL = 'situacao-campanha';

	return {
		...baseFindList<
			ISituacaoCampanhaCleanDTO,
			ISituacaoCampanhaDTO,
			ISituacaoCampanhaFullDTO
		>(resourceURL),
	};
};
