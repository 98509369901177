import {
	IPessoaAvaliacaoSistemaCleanDTO,
	IPessoaAvaliacaoSistemaDTO,
	IPessoaAvaliacaoSistemaFullDTO,
} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';

export const usePessoaAvaliacaoSistemaService = () => {
	const resourceURL = 'pessoa-avaliacao-sistema';

	return {
		...baseCreate<
			IPessoaAvaliacaoSistemaCleanDTO,
			IPessoaAvaliacaoSistemaDTO,
			IPessoaAvaliacaoSistemaFullDTO
		>(resourceURL),
	};
};
