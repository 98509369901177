import { useContext, useEffect, useState } from "react";
import { useEscolaContext } from "../../../../../context/EscolaContext";
import { useGlobalContext } from "../../../../../context/GlobalContext";
import { Predicate } from "../../../../../models/predicate.model";
import { useGrupoService } from "../../../../../services/grupo.service";
import { useCrmEscolaContext } from "../../../context/crm-escola.context";
import { PerfilEnum } from "../../../../../enum/perfil.enum";

const useNovoLeadStep1ComponentState = () => {
  const [grupos, setGrupos] = useState<any>();
  const { leadParaCadastrar, setLeadParaCadastrar, onFecharModal, setIndexCadastro, indexCadastro } = useCrmEscolaContext();
  const { state } = useCrmEscolaContext();
  const { setIsGlobalLoading, unidade, usuario } = useGlobalContext();
  const grupoService = useGrupoService();



  const fetchGrupos = () => {
    setIsGlobalLoading(true);
    if (!usuario?.perfil) return;

    const predicate = new Predicate();
    if (usuario?.perfil === PerfilEnum.FRANQUIA) {
      predicate.addOption('unidade.id', unidade.id);
    }
    predicate.addOption('ativo', true);
    grupoService.findList(predicate).then((response) => {
      setGrupos(response.data);
    }).finally(() => {
      setIsGlobalLoading(false);
    })
  }

  useEffect(() => {
    fetchGrupos();
  }, [usuario])

  const onFinish = (form) => {
    setLeadParaCadastrar((state) => ({ ...state, ...form }));
    setIndexCadastro(indexCadastro + 1)
  }

  return {
    state,
    leadParaCadastrar,
    categoriasQuantidadeAlunos: state?.categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
  }
}

export default useNovoLeadStep1ComponentState;
