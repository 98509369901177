import http from '../helpers/http-common';
import {
	ICampanhaCleanDTO,
	ICampanhaDTO,
	ICampanhaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useCampanhaService = () => {
	const resourceURL = 'campanha';

	return {
		...baseFind<ICampanhaCleanDTO, ICampanhaDTO, ICampanhaFullDTO>(resourceURL),
		...baseFindList<ICampanhaCleanDTO, ICampanhaDTO, ICampanhaFullDTO>(
			resourceURL
		),
		...baseUpdate<ICampanhaCleanDTO, ICampanhaDTO, ICampanhaFullDTO>(
			resourceURL
		),
		...baseFindById<ICampanhaCleanDTO, ICampanhaDTO, ICampanhaFullDTO>(
			resourceURL
		),
		createFull: (body): Promise<IAxiosResponseCustom<ICampanhaCleanDTO>> =>
			http.post(`${resourceURL}/full`, body),
		patchFull: (
			toUpdate: any
		): Promise<IAxiosResponseCustom<ICampanhaCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
