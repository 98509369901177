import { Progress, Steps } from 'antd';

//import 'antd/dist/antd.css';
//
//import './menu-detalhe-matricula.styles.css';

interface Props {
	step: number;
	percent: number;
}

export const ProgressoNovoAluno: React.FunctionComponent<Props> = ({
	step,
	percent,
}: Props) => {
	const { Step } = Steps;

	return (
		<>
			<div className="progress">
				<Progress
					className="progress-user"
					percent={percent}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<div className="menu-user">
				<h3>Criação de Aluno</h3>
				<Steps direction="vertical" current={step}>
					<Step title="Dados Básicos" />
					<Step title="Responsável" />
					<Step title="Documentos" />
					<Step title="Revisão" />
				</Steps>
			</div>
		</>
	);
};
