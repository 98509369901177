import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';

import { ILogCleanDTO, IMensagemHomeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useLogService } from '../../services/log.service';
import { useMensagemHomeService } from '../../services/mensagem-home';
import { useNotificationService } from '../../services/notification.service';
import './style.css';

const MensagemHome: React.FunctionComponent = () => {
    const [mensagemHome, setMensagemHome] = useState<IMensagemHomeDTO>();
    const maxLenght = 250;
    const [contador, setContador] = useState<number>(maxLenght);
    const mensagemHomeService = useMensagemHomeService();
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso mensagem home",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const saveMensagemHome = () => {
        setIsGlobalLoading(true);
        mensagemHomeService.patch({ id: mensagemHome.id, mensagem: mensagemHome.mensagem })
            .finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        setIsGlobalLoading(true);
        mensagemHomeService
            .findList(new Predicate())
            .then(({ data }) => data[0] && setMensagemHome(data[0]))
            .finally(() => setIsGlobalLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const save = () => {
        try {
            saveMensagemHome();
            notification({ message: "Sucesso!", description: "Alterações feitas com sucesso!" });
        } catch (error) {
            notification({ message: "Falha!", description: "houve um problema na alteração!" });
        }
    }

    const onChangeMensagem = (mensagem: string) => {
        setMensagemHome({ ...mensagemHome, mensagem: mensagem })
        setContador(maxLenght - mensagem.length)
    }

    return (
        <div className="container">
            <NewMenu selecionado={29} />
            <div className="dashboard" style={{ backgroundColor: '#f5f5f9' }}>
                <div className="autonomia-row">
                    <Form layout={"vertical"}>
                        <div className="boxes-autonomia">
                            <div className="box-autonomia">
                                <div className="title">
                                    <h3>Mensagem home</h3>
                                </div>
                                <div className="line" style={{ marginTop: 10, marginBottom: 10 }}></div>
                                <h5>Mensagem na tela da home dos hubs</h5>
                                <Form.Item name="Autonomia de desconto sobre o valor mínimo"
                                    rules={[{ required: true, message: 'Insira a mensagem da home dos hubs' }]}
                                    style={{ width: "100%" }}>
                                    <div className="input-box" style={{ marginTop: 10, height: "auto" }}>
                                        <TextArea
                                            id="desconto-valor-minimo"
                                            style={{ width: '100%', height: 150 }}
                                            value={mensagemHome?.mensagem}
                                            maxLength={maxLenght}
                                            onChange={(e) => onChangeMensagem(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <p>{contador}</p>
                                    </div>
                                </Form.Item>
                                <div className="botoes" id="botoes-auto" style={{ justifyContent: "flex-end" }}>
                                    <Button className="button-second" style={{ width: 160, marginRight: 10 }}>Cancelar</Button>
                                    <Button className="button-primary" id="botao-salvar-autonomia" onClick={save} style={{ width: 180 }}>Salvar Alterações</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
export default MensagemHome;