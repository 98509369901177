/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { IPessoaCustomCleanDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsColaboradorContext {
	colaborador: IPessoaCustomCleanDTO;
	setColaborador: React.Dispatch<React.SetStateAction<IPessoaCustomCleanDTO>>;
	removeColaborador: React.DispatchWithoutAction;
	revisao: boolean;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
};

//Valor default do contexto
const DEFAULT_VALUE: PropsColaboradorContext = {
	colaborador: {
		id: null,
		cpf: '',
		rg: '',
		email: '',
		descricaoEmpresa: '',
		observacao: '',
		descricaoProfissao: '',
		descricaoRestricao: '',
		dataNascimento: null,
		dataAdmissao: null,
		permiteUsoImagem: false,
		foto: null,
		nome: '',
		identificadorPagarMe: '',
		ativo: true,
		contatos: [],
		unidadePessoas: [],
	},
	setColaborador: () => { }, //função de inicialização
	removeColaborador: () => { }, //função de inicialização
	revisao: false,
	setRevisao: () => { }, //função de inicialização
};

//criando nosso contexto UserContext
const ColaboradorContext =
	createContext<PropsColaboradorContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/colaborador';
const nameStorageRevisao = '@HappyCode/colaborador/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const ColaboradorContextProvider: React.FC = ({ children }: any) => {
	const [colaborador, setColaboradorGlobal] = useState<IPessoaCustomCleanDTO>(
		DEFAULT_VALUE.colaborador
	);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const setColaborador = (colaborador) => {
		setColaboradorGlobal(colaborador);
		setObject(nameStorage, colaborador);
	};

	const removeColaborador = () => {
		DEFAULT_VALUE.colaborador.contatos = [];
		DEFAULT_VALUE.colaborador.unidadePessoas = [];
		setRevisao(false);
		setColaboradorGlobal(DEFAULT_VALUE.colaborador);
		removeKey(nameStorage);
	};

	useEffect(() => {
		const colaborador = getObject(nameStorage);
		if (colaborador) {
			setColaboradorGlobal(colaborador);
		}
		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}
	}, []);

	return (
		<ColaboradorContext.Provider
			value={{
				colaborador,
				setColaborador,
				removeColaborador,
				revisao,
				setRevisao,
			}}
		>
			{children}
		</ColaboradorContext.Provider>
	);
};

export const useColaboradorContext = (): PropsColaboradorContext => {
	const context = useContext(ColaboradorContext);
	if (!context)
		throw new Error(
			'useColaboradorContext must be used within a ColaboradorContextProvider'
		);
	return context;
};
