import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUnidadeContext } from '../../context/UnidadeContext';
import { useUnidadeService } from '../../services/unidade.service';
import ModalCreate from '../ModalCreate/index';
import './style.css';
import { ModalTaxa } from '../ModalTaxa';

interface Props {
  qtdDocumentoPendente: number;
}

export const MenuRevisao: React.FunctionComponent<Props> = ({ qtdDocumentoPendente }: Props) => {
  const { unidade, removeUnidade, setRevisao } = useUnidadeContext();
  const { setIsGlobalLoading } = useGlobalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalTaxa, setIsOpenModalTaxa] = useState<boolean>(false);

  const unidadeService = useUnidadeService();


  const saveFranquia = () => {
    unidade.tipoFaturamento.id = 1;
    unidade.tipoUnidade.id = 1;
    setIsGlobalLoading(true);
    if (!unidade.id) {
      unidadeService.createFull(unidade)
        .then(() => {
          removeUnidade();
          setRevisao(false);
          setIsOpen(true);
        }).finally(() => setIsGlobalLoading(false));
    } else {
      unidadeService.patchFull(unidade)
        .then(() => {
          removeUnidade();
          setRevisao(false);
          setIsOpen(true);
        }).finally(() => setIsGlobalLoading(false));
    }
  }

  return (
    <>
      <div className="menu-box-revisao-float">
        <div className="bloco-1">
          <h4>Nome da franquia</h4>
          <h1>{unidade?.descricao}</h1>
          <h6>CNPJ</h6>
          <p>{unidade?.cnpj}</p>
          <h6>Inscrição Estadual</h6>
          <p>{unidade?.inscricaoEstadual}</p>
          <Button id="salvar" style={{ width: 250 }} className="button-primary" onClick={() => saveFranquia()}>
            Salvar Alterações
          </Button>
        </div>
        {qtdDocumentoPendente > 0 ? (
          <div className="bloco-2">
            <div className="franja"></div>
            <div className="documentos">
              <div className="descricao">
                <Unicons.UilExclamationTriangle size="30" color="#E8D31A" style={{ marginRight: 10 }} />
                <div>
                  <h6>Atenção</h6>
                  <span>Essa franquia tem {qtdDocumentoPendente} documento(s) pendente(s).</span>
                </div>
              </div>
              <Link to="/franquias/create/step-7">
                <Button className="button-primary" style={{ marginTop: 10 }}>
                  Completar Agora
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
        <div className="bloco-1">
          <h6>Cadastro de taxa de franquia</h6>
          <Button id="salvar" style={{ width: 250 }} className="button-primary" onClick={() => setIsOpenModalTaxa(true)}>
            Cadastrar taxa
          </Button>
        </div>
      </div>
      <ModalCreate
        //onClose={() => setIsOpen(false)} 
        title={`Franquia salva com sucesso!`}
        textButton1='Voltar a página inicial'
        textButton2='Criar outra franquia'
        link1={'/franquias'}
        link2={'/franquias/create'}
        open={isOpen}
        styleButton1="button-line"
        styleButton2="button-primary"
      />
      <ModalTaxa
        idUnidade={unidade.id}
        onClose={() => setIsOpenModalTaxa(false)}
        open={isOpenModalTaxa} />

    </>
  );
}