import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu/index copy';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	IGrupoContratoTipoDTO,
	IGrupoDTO,
	IUnidadeCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useGrupoContratoTipoService } from '../../../services/grupo-contrato-tipo.service';
import { useGrupoService } from '../../../services/grupo.service';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useUnidadeService } from '../../../services/unidade.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { hoje } from '../../../util/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelEvolucaoLicencaEscolaSede() {
	const { setIsGlobalLoading } = useGlobalContext();
	const service = useRelatorioService();
	const grupoService = useGrupoService();
	const unidadeService = useUnidadeService();
	const grupoContratoTipoService = useGrupoContratoTipoService();

	const [dados, setData] = useState<[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();

	const [dataVencimentoInicio, setDataVencimentoInicio] =
		useState<moment.Moment>();
	const [dataVencimentoFim, setDataVencimentoFim] = useState<moment.Moment>();

	const [grupo, setGrupo] = useState<IGrupoDTO[]>();
	const [grupoId, setGrupoId] = useState<number>();

	const [licencaId, setLicencaId] = useState<number[]>();

	const [unidade, setUnidade] = useState<IUnidadeCleanDTO[]>();
	const [unidadeId, setUnidadeId] = useState<number | null>(0);

	const [grupoContratoTipo, setGrupoContratoTipo] =
		useState<IGrupoContratoTipoDTO[]>();
	const [grupoContratoTipoId, setGrupoContratoTipoId] = useState<number>();

	const findUnidade = () => {
		return unidadeService
			.findCleanList()
			.then((response) => setUnidade(response.data));
	};

	const findGrupoContratoTipo = () => {
		setIsGlobalLoading(true);
		return grupoContratoTipoService
			.findList()
			.then((response) => {
				setGrupoContratoTipo(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findGrupo = () => {
		setIsGlobalLoading(true);
		return grupoService
			.findList()
			.then(({ data }) => {
				setGrupo(data as any);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		findGrupo();
		findUnidade();
		findGrupoContratoTipo();
	}, []);

	useEffect(() => {
		const predicate = new Predicate();
		setIsGlobalLoading(true);
		predicate.removeAllOption();

		if (unidadeId != null) {
			unidadeId >= 0 && predicate.addOption('unidadeId', unidadeId);
		}

		if (licencaId !== undefined) {
			licencaId.map((li) => {
				li === 1
					? predicate.addOption('tipoLicencaIntraId', li)
					: li === 2
					? predicate.addOption('tipoLicencaExtraId', li)
					: li === 3
					? predicate.addOption('tipoLicencaAcademicaId', li)
					: null;
			});
		}

		grupoId && predicate.addOption('grupoId', grupoId);
		grupoContratoTipoId &&
			predicate.addOption('tipoContratoId', grupoContratoTipoId);
		dataVencimentoInicio &&
			predicate.addOption(
				'dataVencimentoInicio',
				dataVencimentoInicio.toISOString()
			);
		dataVencimentoFim &&
			predicate.addOption('dataVencimentoFim', dataVencimentoFim.toISOString());

		service
			.evolucaoLicencaEscola(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dataVencimentoInicio,
		dataVencimentoFim,
		unidadeId,
		grupoId,
		licencaId,
		grupoContratoTipoId,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	const getPeriodo = (): any => {
		if (dataVencimentoInicio && dataVencimentoFim) {
			return [
				moment(dataVencimentoInicio, 'YYYY-MM-DD'),
				moment(dataVencimentoFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const selectUnidades = unidade?.map((unidade) => (
		<Option key={unidade.id} value={unidade.id}>
			{unidade.descricao}
		</Option>
	));
	const selectGrupoContrato = grupoContratoTipo?.map((contrato) => (
		<Option key={contrato.id} value={contrato.id}>
			{contrato.descricao}
		</Option>
	));
	const selectGrupos = grupo?.map((grupo) => (
		<Option key={grupo?.id} value={grupo?.id}>
			{grupo?.nomeFantasia}
		</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório Contrato Escola
				</h1>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Grupos</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={grupoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setGrupoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectGrupos}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Tipo Licença</span>
						<br />
						<Select
							showSearch
							allowClear
							mode="multiple"
							style={filtroStyle}
							defaultValue={licencaId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setLicencaId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							<Option value={1}>Intra</Option>
							<Option value={2}>Extra</Option>
							<Option value={3}>Serviço</Option>
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Tipo Contrato</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={grupoContratoTipoId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setGrupoContratoTipoId(e)}
						>
							{/* <Option value={0}>Todos</Option> */}
							{selectGrupoContrato}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Unidades</span>
						<br />
						<Select
							showSearch
							allowClear
							style={filtroStyle}
							defaultValue={unidadeId}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(e) => setUnidadeId(e)}
						>
							<Option value={0}>Todos (Direta e Indireta)</Option>
							<Option value={null}>Todos Direta</Option>
							<Option value={99999}>Todos Indireta</Option>
							{selectUnidades}
						</Select>
					</div>
					<div style={{ paddingRight: '1em' }}>
						<span style={{ fontSize: '0.8em' }}>Período de Vigência</span>
						<br />
						<RangePicker
							value={getPeriodo()}
							onChange={(values) => {
								setDataVencimentoInicio(values && values[0]);
								setDataVencimentoFim(values && values[1]);
							}}
							format={'DD-MM-YYYY'}
						/>
						{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={dados}
							fileName={`Evolução de licenças ` + hoje().toISOString()}
						/>
					</div>
				</div>
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}
export default RelEvolucaoLicencaEscolaSede;
