import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IconButton from '../../../../components/ButtonVoltar';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import {
	ILogCleanDTO,
	ITopicoAulaDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useAcademyTopicoService } from '../../../../services/academy-topico';
import FiltrosAcaTopico from './filtros-topico';
import AcademyItemListAcaTopico from './item-lista';
import './style.css';
import { useLogService } from '../../../../services/log.service';

export const AcademyAcaTopico: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<ITopicoAulaDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const topicoService = useAcademyTopicoService();
	const { setIsGlobalLoading } = useGlobalContext();
	const history = useHistory();
	const { idTrilha, idCurso, idAula } = useParams<any>();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso gestao trilha tópicos academy',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const findTopico = () => {
		setIsGlobalLoading(true);
		predicate.addOption('aula.id', idAula);
		predicate.addSortDescricao('numeroTopicoAula');
		topicoService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findTopico();
	};

	const ordenar = (campo) => {
		if (campo) {
			predicate.addSort(campo);
		}
		findTopico();
	};

	const goToAdd = () => {
		history.push(
			`/academy/trilhas/${idTrilha}/cursos/${idCurso}/aula/${idAula}/topico/create`
		);
	};

	return (
		<div className="container">
			<NewMenu selecionado={31} />
			<div className="dashboard">
				<div className="box-title">
					<IconButton
						rota={`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas`}
					>
						Voltar
					</IconButton>
					<h3 className="title">
						{data?.content[0]?.aula?.codigo} {data?.totalElements} tópico(s)
					</h3>
					<Button
						className="button-primary"
						id="criar-curso"
						icon={<PlusOutlined rev={undefined} />}
						onClick={goToAdd}
					>
						Criar Tópico
					</Button>
				</div>

				<FiltrosAcaTopico findAula={findTopico} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '50%' }}>
							<span>TÓPICO</span>
							<div className="arrows" onClick={() => ordenar('descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '30%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>ORDEM</span>
							<div
								className="arrows"
								onClick={() => ordenar('numeroTopicoAula')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
					</div>
					<div className="tabela-body">
						{data &&
							data.content.map((topico, index) => (
								<AcademyItemListAcaTopico topico={topico} key={index} />
							))}
					</div>

					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
