
import http from '../helpers/http-common';
import { IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useAcademyWebinarService = () => {
	const resourceURL = '/webinar';

	const editarArquivo = (
		webinarId: number,
		body: any,
	): Promise<IAxiosResponseCustom<IWebinarDTO>> =>
		http.patch(`${resourceURL}/${webinarId}`, body);

	const editarArquivoCustom = (
		webinarId: number,
		body: any,
	): Promise<IAxiosResponseCustom<IWebinarDTO>> =>
		http.patch(`${resourceURL}/custom/${webinarId}`, body);

	return {
		...baseFind<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
		...baseFindList<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
		...baseFindById<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
		...baseCreate<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
		...baseUpdate<IWebinarCleanDTO, IWebinarDTO, IWebinarFullDTO>(resourceURL),
		editarArquivo,
		editarArquivoCustom,
	};
};
