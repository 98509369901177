import * as Unicons from '@iconscout/react-unicons';
import './style.css';


const MenuRevisao: React.FunctionComponent = () => {
  return (
    <div className="menu-box-revisao-float">
      <div className="bloco-1">
        <Unicons.UilEllipsisH size="20" color="rgba(52, 68, 83, 0.5)" className="mas" />
        <h1>Rio de Janeiro III</h1>
        <div className="ativo">
          <div className="dot-verde"></div>
          <span>Ativo</span>
        </div>
        <h6>CNPJ</h6>
        <span>0000000000</span>
        <h6>Inscrição Estadual</h6>
        <span>0000000000</span>
      </div>
      <div className="bloco-2">
        <div className="franja"></div>
        <div className="documentos">
          <div className="descricao">
            <Unicons.UilExclamationTriangle size="30" color="#E8D31A" style={{ marginRight: 10 }} />
            <div>
              <h6>Atenção</h6>
              <span>Essa franquia tem 6 documentos pendentes.</span>
            </div>
          </div>
          <button>
            Completar Agora
          </button>
        </div>
      </div>
    </div>
  );
}

export default MenuRevisao;