import * as Unicons from '@iconscout/react-unicons';
import { Button, Progress, Radio } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

import StepsCreate from './steps-create';
import './style.css';

const UserCreateStep4: React.FunctionComponent = () => {
    // const [defaultClusterValues, setDefaultClusterValues] = useState<number[]>();
    // const [clusterOptions, setClusterOptions] = useState<string[]>();
    // const { setIsGlobalLoading } = useGlobalContext();
    // const clusterService = useClusterService();

    // useEffect(() => {
    // 	const fetchInitialClusterData = async () => {
    // 		setIsGlobalLoading(true);

    // 		try {
    // 			const { data: fetchedClusterList } = await clusterService.findCleanList();
    // 			if (fetchedClusterList) {
    // 				const auxOptions: any = [];
    // 				fetchedClusterList.forEach((cluster) => {
    // 					auxOptions.push({
    // 						label: cluster.descricao,
    // 						value: cluster.id,
    // 					});
    // 				});
    // 				setClusterOptions(auxOptions);
    // 			}
    // 			setIsGlobalLoading(false);
    // 		} catch (error) {
    // 			setIsGlobalLoading(false);
    // 			
    // 		}
    // 	};
    // 	fetchInitialClusterData();

    // 	const clusters = usuario?.clusters;
    // 	if (clusters) {
    // 		setDefaultClusterValues(clusters);
    // 	}
    // }, []);

    // const onChange = (clusters) => {
    // 	setDefaultClusterValues(clusters);

    // 	const contextState = { ...usuarioForm };
    // 	contextState.clusters = clusters;
    // 	setUsuarioForm(contextState);
    // };

    return (
        <div className="container-fondo">
            <Header />
            <div className="progress">
                <Progress
                    className="progress-user"
                    percent={48}
                    strokeLinecap="square"
                    showInfo={false}
                />
            </div>
            <StepsCreate current={2} />
            <div className="franquia">
                <div className="user-row">
                    <h3>Quais franquias esse usuário terá acesso?</h3>
                    <div className="boxes">
                        <div className="box-check">
                            <Radio />
                            <div className="box-text">
                                <h6>Selecionar por</h6>
                                <p>País</p>
                            </div>
                        </div>
                        <div className="box-check-selected">
                            <Radio />
                            <div className="box-text">
                                <h6>Selecionar por</h6>
                                <p>Cluster</p>
                            </div>
                        </div>
                        <div className="box-check">
                            <Radio />
                            <div className="box-text">
                                <h6>Selecionar por</h6>
                                <p>Franquias</p>
                            </div>
                        </div>
                    </div>
                    <h4>Selecione o(s) clusters desejados</h4>

                    {/* <Checkbox.Group
                        options={clusterOptions}
                        //onChange={onChange}
                        value={defaultClusterValues}
                    /> */}
                    <div className="botoes">
                        <Link to="/usuarios/create/step-3">
                            <Button className="button-second" style={{ width: 345 }}>
                                Voltar
                            </Button>
                        </Link>
                        <Link to="/usuarios/create/step-5">
                            <Button className={'button-primary'} style={{ width: 345 }}>
                                Próximo
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <Link to="/usuarios">
                <div className="fechar">
                    <Unicons.UilTimes size="20" color="#353F49" />
                </div>
            </Link>
        </div>
    );
}

export default UserCreateStep4;