import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	ICupomAlunoCleanDTO,
	ICupomAlunoCustomDTO,
	ICupomAlunoDTO,
	ICupomAlunoFullDTO,
} from '../models/happy-code-api.model';
import { formatHttpParamsList, Predicate } from '../models/predicate.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useCupomAlunoService = () => {
	const resourceURL = 'cupom-aluno';

	const findByIdAluno = (idAluno: number): Promise<AxiosResponse<ICupomAlunoCustomDTO[]>> =>
		http.get(`${resourceURL}/custom/${idAluno}`);

	return {
		...baseFind<
			ICupomAlunoCleanDTO,
			ICupomAlunoDTO,
			ICupomAlunoFullDTO
		>(resourceURL),
		...baseFindList<
			ICupomAlunoCleanDTO,
			ICupomAlunoDTO,
			ICupomAlunoFullDTO
		>(resourceURL),
		...baseFindById<
			ICupomAlunoCleanDTO,
			ICupomAlunoDTO,
			ICupomAlunoFullDTO
		>(resourceURL),
		findByIdAluno
	};
};
