import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { ChevronLeftRounded } from '@mui/icons-material';
import { Pagination } from '@mui/material';
import { Breadcrumb, Button, Input, Select } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Operators } from '../../../enum/operators.enum';
import { PerfilEnum } from '../../../enum/perfil.enum';
import useProfileIsSede from '../../../globalHooks/useProfileIsSede';
import { IGrupoContratoFullDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { PageableResponse } from '../../../models/response.model';
import { useGrupoContratoService } from '../../../services/grupo-contrato.service';
import ItemTabelaContratoComponent from '../components/item-tabela-contrato-component/item-tabela-contrato.component';
import useEscolaGrupoContratosListagemScreenState from '../states/use-grupo-contrato-listagem-screen.state';
import '../styles/_escola-grupos.screen.styles.scss';

const GrupoContratoListagemScreen = () => {
	const {
		onCriarContratoPress,
		setDescricao,
		descricao,
		idGrupo,
		setContratoParaCadastrar,
	} = useEscolaGrupoContratosListagemScreenState();

	const { setIsGlobalLoading, usuario } = useGlobalContext();
	const [data, setData] = useState<PageableResponse<IGrupoContratoFullDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const [filtroStatus, setFiltroStatus] = useState<number>(1);
	const history = useHistory();
	const contratoService = useGrupoContratoService();

	const buscarDados = () => {
		if (!idGrupo) return;

		setIsGlobalLoading(true);
		predicate.removeOption('grupo.id');
		predicate.addOption('grupo.id', idGrupo);
		return contratoService
			.findFull(predicate)
			.then(({ data }) => {
				setData(data as any);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		buscarDados();
	};

	useEffect(() => {
		if (filtroStatus === 1) {
			predicate.addOption('ativo', true);
		} else if (filtroStatus === 2) {
			predicate.addOption('ativo', false);
		} else if (filtroStatus === 0) {
			predicate.removeOption('ativo');
		}
		buscarDados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtroStatus]);

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			predicate.addOption('descricao', descricao || '', Operators.CONTAINS);
			buscarDados();
		}, 800);

		return () => clearTimeout(typingTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descricao]);

	const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		buscarDados();
	};

	const onFiltroStatusChange = (e) => {
		setFiltroStatus(e);
	};

	const thisProfileIsSede = useProfileIsSede();

	const nomeDoGrupo =
		(data && data.content[0]?.grupo?.nomeFantasia) || 'Contratos';

	const voltarButtonOnClick = () => history.goBack();

	const renderTitulo = () => {
		return (
			<div className="box-title">
				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '12px',
						}}
					>
						<Button
							shape="circle"
							onClick={voltarButtonOnClick}
							icon={<ChevronLeftRounded />}
						/>
						<h3 className="title">
							{nomeDoGrupo} - ({data?.totalElements})
						</h3>
					</div>
					<Breadcrumb>
						<Breadcrumb.Item href={thisProfileIsSede ? '/' : '/franquia'}>
							Home
						</Breadcrumb.Item>
						<Breadcrumb.Item href="/escolas/grupos">Grupos</Breadcrumb.Item>
						<Breadcrumb.Item>Contratos</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				{usuario.perfil === PerfilEnum.SEDE && (
					<Button
						id="criar-grupo"
						className="button-primary"
						onClick={() => onCriarContratoPress(idGrupo)}
						icon={<PlusOutlined rev={undefined} />}
					>
						Novo Contrato
					</Button>
				)}
			</div>
		);
	};

	const renderFiltros = () => {
		return (
			<form className="filtros">
				<div className="input-text" style={{ width: '70%' }}>
					<Input
						id="buscar"
						onChange={(e: any) => setDescricao(e.target.value)}
						placeholder="Buscar Contratos"
						prefix={
							<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
						}
					/>
				</div>

				<div className="filtro-estado" id="input-dashboard">
					<span>Status</span>
					<Select defaultValue="TDS" onChange={onFiltroStatusChange}>
						<Select.Option value={0}>Todos</Select.Option>
						<Select.Option value={1}>Ativo</Select.Option>
						<Select.Option value={2}>Inativo</Select.Option>
					</Select>
				</div>
			</form>
		);
	};

	const renderTabela = () => {
		return (
			<div className="tabela">
				<div className="titles">
					<div className="item" style={{ flex: 0.5, display: 'flex' }}>
						<span>ID</span>
						<div className="arrows" onClick={() => ordenar('id')}>
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>NOME DO CONTRATO</span>
						<div className="arrows" onClick={() => ordenar('descricao')}>
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>TIPO DO CONTRATO</span>
						<div className="arrows" onClick={() => ordenar('tipo')}>
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>Vig. Início</span>
						<div className="arrows" onClick={() => ordenar('vigenciaInicio')}>
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>Vig. Fim</span>
						<div className="arrows" onClick={() => ordenar('vigenciaFim')}>
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>VALOR TOTAL</span>
						<div className="arrows" onClick={() => ordenar('valorTotal')}>
							<Unicons.UilDirection size="20" color="#92A9CB" />
						</div>
					</div>
					<div className="item" style={{ flex: 1, display: 'flex' }}>
						<span>AÇÕES</span>
					</div>
				</div>
				<div className="tabela-body">
					{data &&
						data.content.map((contrato, index) => (
							<ItemTabelaContratoComponent
								contrato={contrato}
								key={index}
								buscarDados={buscarDados}
								goToRevisao={() => {
									setIsGlobalLoading(true);
									contratoService
										.findFullById(contrato)
										.then(({ data }) => {
											setContratoParaCadastrar({ ...data });
											history.push(
												`/escolas/grupos/${data.grupo.id}/contratos/create/step-4`
											);
										})
										.finally(() => setIsGlobalLoading(false));
								}}
							/>
						))}
				</div>
			</div>
		);
	};

	return (
		<div id="escola-grupos" className="container">
			<NewMenu selecionado={23} />
			<div className="dashboard">
				{renderTitulo()}
				{renderFiltros()}
				{renderTabela()}
				<div className="paginacao">
					<Pagination
						onChange={(event, page) => onPageChange(event, page - 1)}
						page={data ? data.pageable.pageNumber + 1 : 0}
						count={data ? data.totalPages : 0}
					/>
				</div>
			</div>
		</div>
	);
};

export default GrupoContratoListagemScreen;
