import { createContext, useContext, useState, ReactNode } from 'react';
import {
	ISalaDTO,
	ISalaRecursoCustomDTO,
} from '../models/happy-code-api.model';

interface SalaContextProps {
	sala: ISalaDTO;
	setSala: React.Dispatch<React.SetStateAction<ISalaDTO>>;
	salaRecurso: ISalaRecursoCustomDTO;
	setSalaRecurso: React.Dispatch<React.SetStateAction<ISalaRecursoCustomDTO>>;
	resetSala: () => void;
	editBtn: boolean;
	editBtnIsVisible: (isVisible: boolean) => void
}

const SalaContext = createContext<SalaContextProps | undefined>(undefined);

interface SalaContextProviderProps {
	children: ReactNode;
}

export const SalaContextProvider: React.FC<SalaContextProviderProps> = ({
	children,
}) => {
	const initialSalaState: ISalaDTO = {
		id: null,
		descricaoSala: '',
		capacidadeMaxima: null,
		recursos: null,
		unidade: null,
		modalidade: null,
		ativo: false,
	};

	const [sala, setSala] = useState<ISalaDTO>(initialSalaState);
	const [salaRecurso, setSalaRecurso] = useState<ISalaRecursoCustomDTO>();
	const [editBtn, setEditBtn] = useState(false)

	const resetSala = () => {
		setSala(initialSalaState);
		setSalaRecurso(undefined);
		setEditBtn(false);
	};

	const editBtnIsVisible = (Boolean:boolean) => {
		setEditBtn(Boolean);
	}
	
	return (
		<SalaContext.Provider
			value={{ sala, setSala, salaRecurso, setSalaRecurso, resetSala, editBtn, editBtnIsVisible }}
		>
			{children}
		</SalaContext.Provider>
	);
};

export const useSalaContext = () => {
	const context = useContext(SalaContext);
	if (!context) {
		throw new Error('useSalaContext must be used within a SalaContextProvider');
	}
	return context;
};
