import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Select } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { IMetaCustomDTO, IMetaTipoCleanDTO, IMetaUnidadeDTO, IPeriodicidadeEnum, IUnidadeCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useMetaTipoService } from '../../services/meta-tipo.service';
import { useMetaUnidadeService } from '../../services/meta-unidade.service';
import { useMetaService } from '../../services/meta.service';
import { useNotificationService } from '../../services/notification.service';
import { useUnidadeService } from '../../services/unidade.service';

const MetaUnidadeCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const metaUnidadeService = useMetaUnidadeService();
    const metaTipoService = useMetaTipoService();
    const metaService = useMetaService();
    const unidadeService = useUnidadeService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [metaUnidade, setMetaUnidade] = useState<IMetaUnidadeDTO>();
    const [meta, setMeta] = useState<IMetaCustomDTO>();
    const [metaTipo, setMetaTipo] = useState<IMetaTipoCleanDTO[]>();
    const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>([]);
    const { idMetaUnidade } = useParams<any>()
    const { idMeta } = useParams<any>()
    const [predicate] = useState<Predicate>(new Predicate());
    const periodicidade: IPeriodicidadeEnum[] = ["MENSAL", "TRIMESTRAL", "SEMESTRAL", "ANUAL"];

    const findMetaTipo = () => {
        setIsGlobalLoading(true);
        predicate.addOption("ativo", true);
        metaTipoService.findList(predicate)
            .then((response) => {
                setMetaTipo(response.data);
            }).finally(() => setIsGlobalLoading(false));
    };

    const findMetaUnidade = () => {
        setIsGlobalLoading(true);
        metaUnidadeService.findById({ id: idMetaUnidade })
            .then((response) => {
                setMetaUnidade(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findUnidade = async () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        unidadeService.findCleanList(predicate)
            .then(({ data }) => setUnidades(data))
            .finally(() => setIsGlobalLoading(false));
    };

    const createMeta = () => {
        setIsGlobalLoading(true);
        metaService.criarMetaUnidades(meta)
            .then(() => {
                history.push(`/metas-unidade/${idMeta}`);
                notification({
                    description: `Meta criada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível cadastrar a meta.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const patchMetaUnidade = () => {
        setIsGlobalLoading(true);
        metaUnidadeService.patch(metaUnidade)
            .then(() => {
                history.push(`/metas-unidade/${metaUnidade?.meta?.id}`);
                notification({
                    description: `Meta editada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível editar a meta.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const sendRequest = () => {
        if (!idMetaUnidade) {
            createMeta();
        } else {
            patchMetaUnidade();
        }
    }

    const onUnidadeChange = (selectedOptions) => {
        setMeta({
            ...meta,
            unidade: selectedOptions.map(option => ({
                id: option.key,
                ativo: true,
                unidadeDescricao: option.value
            }))
        });
    }

    const optionsUnidade = unidades?.map(u => (
        <Select.Option key={u.id} value={u.descricao}>{u.descricao}</Select.Option>
    ));

    useEffect(() => {
        if (idMeta) {
            findUnidade();
            findMetaTipo();
            setMeta({ ...meta, id: idMeta });
        }
        if (idMetaUnidade) {
            findMetaUnidade();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idMetaUnidade])
    
    return (
        <>
            {idMetaUnidade ?
                <div className="container-fondo">
                    <Header />
                    <div className="franquia" style={{ position: "initial", width: 0 }}>
                        <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
                        <div className="space1" />
                        <div className="user-row">
                            <h3 id="taxas-h3">Insira o valor da meta</h3>
                            <Input
                                hidden
                                defaultValue={idMetaUnidade}
                                value={idMetaUnidade} />
                            <div className="input-box">
                                <p>Valor Sede</p>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    defaultValue={metaUnidade?.valorSede}
                                    value={metaUnidade?.valorSede}
                                    onChange={(value) => setMetaUnidade({ ...metaUnidade, valorSede: value })}
                                />
                            </div>
                            <div className="input-box">
                                <p>Valor Hub</p>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    defaultValue={metaUnidade?.valorHub}
                                    value={metaUnidade?.valorHub}
                                    onChange={(value) => setMetaUnidade({ ...metaUnidade, valorHub: value })}
                                />
                            </div>
                            <div className="botoes">
                                <Link to={`/metas-unidade/${metaUnidade?.meta?.id}`}>
                                    <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                                </Link>
                                <Button
                                    style={{ width: 345 }}
                                    type="primary"
                                    id="proximo"
                                    className="button-primary"
                                    onClick={sendRequest}>Salvar</Button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        title='Você deseja cancelar a criação dessa meta?'
                        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                        textButton1='Não'
                        textButton2='Sim, Cancelar'
                        styleButton1='button-line'
                        styleButton2='button-danger'
                        link2={`/metas-unidade/${metaUnidade?.meta?.id}`}
                    />
                </div>
                : null}

            {idMeta ?
                <div className="container-fondo">
                    <Header />
                    <div className="franquia" style={{ position: "initial", width: 0 }}>
                        <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
                        <div className="space1" />
                        <div className="user-row">
                            <h3 id="taxas-h3">Selecione o tipo e insira os detalhes da meta</h3>
                            <div className="input-box">
                                <p>Selecione Tipo Meta</p>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Selecione a meta"
                                    showSearch
                                    allowClear
                                    defaultValue={meta?.metaTipo?.id}
                                    value={meta?.metaTipo?.id}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.text?.toLowerCase().indexOf(input.toLowerCase()) !== -1}
                                    onChange={(e) => setMeta({ ...meta, metaTipo: { ...meta?.metaTipo, id: e } })}
                                >
                                    {metaTipo && metaTipo.map((tipoMeta) => (
                                        <Select.Option key={tipoMeta.id} text={tipoMeta.nome} value={tipoMeta.id}>{tipoMeta.nome}</Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="input-box">
                                <p>Informe o ano de exercício</p>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    onChange={(e) => setMeta({ ...meta, exercicio: e.year() })}
                                    picker="year"
                                />
                            </div>
                            <div className="input-box">
                                <p>Informe a periodicidade</p>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Selecione a quantidade"
                                    allowClear
                                    defaultValue={meta?.periodicidade}
                                    value={meta?.periodicidade}
                                    onChange={(value: IPeriodicidadeEnum) => setMeta({ ...meta, periodicidade: value })}
                                >
                                    {periodicidade.map((tempo) => (
                                        <Select.Option key={tempo} value={tempo}>
                                            {tempo}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="input-box">
                                <p>Selecione a(s) Franquia desejada</p>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecione a(s) Franquia(s) desejada(s)"
                                    defaultValue={meta?.unidade?.map(c => (c?.descricao))}
                                    onChange={(value, op) => onUnidadeChange(op)}
                                >
                                    {optionsUnidade}
                                </Select>
                            </div>
                            <div
                                className="row"
                                style={{ width: '100%' }}>
                                <div
                                    style={{ marginRight: 10 }}
                                    className="input-box">
                                    <p>Valor Sede</p>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        defaultValue={meta?.valorHub}
                                        value={meta?.valorSede}
                                        onChange={(value) => setMeta({ ...meta, valorSede: value })}
                                    />
                                </div>
                                <div className="input-box">
                                    <p>Valor Hub</p>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        defaultValue={meta?.valorHub}
                                        value={meta?.valorHub}
                                        onChange={(value) => setMeta({ ...meta, valorHub: value })}
                                    />
                                </div>
                            </div>
                            <div className="botoes">
                                <Link to={`/metas-unidade/${idMeta}`}>
                                    <Button className="button-second" id="voltar" style={{ width: 345 }}>
                                        Voltar
                                    </Button>
                                </Link>
                                <Button
                                    style={{ width: 345 }}
                                    type="primary"
                                    id="proximo"
                                    className="button-primary"
                                    onClick={createMeta}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        title='Você deseja cancelar a operação?'
                        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                        textButton1='Não'
                        textButton2='Sim, Cancelar'
                        styleButton1='button-line'
                        styleButton2='button-danger'
                        link2={`/metas-unidade/${idMeta}`}
                    />
                </div >
                : null}
        </>
    );
}

export default MetaUnidadeCreate;