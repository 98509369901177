import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Progress, Radio, Space } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

import StepsCreate from './steps-create';
import './style.css';

const UserCreateStep6: React.FunctionComponent = () => {

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress
					className="progress-user"
					percent={64}
					strokeLinecap="square"
					showInfo={false}
				/>
			</div>
			<StepsCreate current={3} />
			<div className="franquia">
				<div className="user-row">
					<h3>Selecione os módulos que esse usuário terá controle</h3>
					<div className="boxes-radios">
						<div className="box-radios">
							<div className="check-select">
								<Unicons.UilAngleRight size="20" color="#353F49" />
								<Checkbox />
								<h5>Módulo 1</h5>
								<Unicons.UilInfoCircle size="20" color="#92A9CB" />
							</div>
							<div className="check-radios">
								<Radio.Group name="radiogroup" defaultValue={1}>
									<Space direction="vertical">
										<Radio value={1}>Edição e Leitura</Radio>
										<Radio value={2}>Somente Leitura</Radio>
									</Space>
								</Radio.Group>
							</div>
						</div>
						<div className="box-radios">
							<div className="check-select">
								<Unicons.UilAngleRight size="20" color="#353F49" />
								<Checkbox />
								<h5>Módulo 1</h5>
								<Unicons.UilInfoCircle size="20" color="#92A9CB" />
							</div>
							<div className="check-radios">
								<Radio.Group name="radiogroup" defaultValue={1}>
									<Space direction="vertical">
										<Radio value={1}>Edição e Leitura</Radio>
										<Radio value={2}>Somente Leitura</Radio>
									</Space>
								</Radio.Group>
							</div>
						</div>
						<div className="box-radios">
							<div className="check-select">
								<Unicons.UilAngleRight size="20" color="#353F49" />
								<Checkbox />
								<h5>Módulo 1</h5>
								<Unicons.UilInfoCircle size="20" color="#92A9CB" />
							</div>
							<div className="check-radios">
								<Radio.Group name="radiogroup" defaultValue={1}>
									<Space direction="vertical">
										<Radio value={1}>Edição e Leitura</Radio>
										<Radio value={2}>Somente Leitura</Radio>
									</Space>
								</Radio.Group>
							</div>
						</div>
					</div>
					<div className="boxes-radios">
						<div className="box-radios">
							<div className="check-select">
								<Unicons.UilAngleRight size="20" color="#353F49" />
								<Checkbox />
								<h5>Módulo 1</h5>
								<Unicons.UilInfoCircle size="20" color="#92A9CB" />
							</div>
							<div className="check-radios">
								<Radio.Group name="radiogroup" defaultValue={1}>
									<Space direction="vertical">
										<Radio value={1}>Edição e Leitura</Radio>
										<Radio value={2}>Somente Leitura</Radio>
									</Space>
								</Radio.Group>
							</div>
						</div>
						<div className="box-radios">
							<div className="check-select">
								<Unicons.UilAngleRight size="20" color="#353F49" />
								<Checkbox />
								<h5>Módulo 1</h5>
								<Unicons.UilInfoCircle size="20" color="#92A9CB" />
							</div>
							<div className="check-radios">
								<Radio.Group name="radiogroup" defaultValue={1}>
									<Space direction="vertical">
										<Radio value={1}>Edição e Leitura</Radio>
										<Radio value={2}>Somente Leitura</Radio>
									</Space>
								</Radio.Group>
							</div>
						</div>
						<div className="box-radios">
							<div className="check-select">
								<Unicons.UilAngleRight size="20" color="#353F49" />
								<Checkbox />
								<h5>Módulo 1</h5>
								<Unicons.UilInfoCircle size="20" color="#92A9CB" />
							</div>
							<div className="check-radios">
								<Radio.Group name="radiogroup" defaultValue={1}>
									<Space direction="vertical">
										<Radio value={1}>Edição e Leitura</Radio>
										<Radio value={2}>Somente Leitura</Radio>
									</Space>
								</Radio.Group>
							</div>
						</div>
					</div>
					<div className="botoes">
						<Link to="/usuarios/create/step-3">
							<Button className="button-second" style={{ width: 345 }}>
								Voltar
							</Button>
						</Link>
						<Link to="/usuarios/create/step-9">
							<Button className="button-primary" style={{ width: 345 }}>
								Próximo
							</Button>
						</Link>
					</div>
				</div>
			</div>
			<Link to="/usuarios">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
}

export default UserCreateStep6;