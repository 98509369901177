import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import MenuDetalhe from '../../../components/MenuDetalhe';
import '../style.css';

const FranquiaCreateStep12: React.FunctionComponent = () => {

  return (
    <div className="container">
      <Header />
      <Menu />
      <MenuDetalhe />
      <div className="franquia" style={{ top: 70 }}>
        <div className="franquia-row">
          <h5>Revisão de Dados</h5>
          <div className="row-revisao">
            <ul className="menu-revisao-detalhe">
              <li className="selected">
                Localização
              </li>
              <li>
                Contrato
              </li>
              <li>
                Responsaveis
              </li>
              <li>
                Documentos
              </li>
              <li>
                Classificação
              </li>
            </ul>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Dados Básicos</h4>
                <div className="nome">
                  <h5>Nome da Franquia</h5>
                  <span>Rio de janeiro III</span>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Status</h6>
                    <span className="verde">Ativo</span>
                  </div>
                  <div className="cpf">
                    <h6>CNPJ</h6>
                    <span>0000000</span>
                  </div>
                  <div className="cpf">
                    <h6>Inscrição Estadual</h6>
                    <span>00-00000000</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Localização</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>CNPJ</h6>
                    <span>0000000</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>ENDEREÇO</h6>
                    <span>Rua Alexandre Passos, Barra da Tijuca</span>
                  </div>
                  <div className="cpf">
                    <h6>NUMERO</h6>
                    <span>15</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>COMPLEMENTO</h6>
                    <span>Bloco 1, sala 1243</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Dados de contrato</h4>
                <div className="dados-franquia">
                  <div className="cpf" style={{ marginRight: 15 }}>
                    <h6>Assinatura</h6>
                    <span>11/12/2021</span>
                  </div>
                  <div className="cpf" style={{ marginRight: 15 }}>
                    <h6> Abertura</h6>
                    <span>11/12/2021</span>
                  </div>
                  <div className="cpf" style={{ marginRight: 15 }}>
                    <h6>Renovação</h6>
                    <span>11/12/2021</span>
                  </div>
                  <div className="cpf" style={{ marginRight: 15 }}>
                    <h6>Término</h6>
                    <span>11/12/2021</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Responsaveis</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>RESPONSAVEL LEGAL</h6>
                    <span>Rodrigo Paiva Jesus</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>GESTOR</h6>
                    <span>Mariana Paz Silva Maria</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Documentos</h4>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>COF</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc-red">
                    <Unicons.UilTimes size="16" color="#FFFFFF" />
                  </div>
                  <p>Contrato</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão Negativa de Débito da Receita Federal e Estadual</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão Negativa da Prefeitura</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão dos Distribuidores Fiscal, Civel, Trabalhista e <br></br>Criminal nas esferas Estadual e Federal</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Certidão de Protesto</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Antecedentes criminais</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Declaração de Imposto de Renda dos últimos 2 anos</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
              <div className="documentos" style={{ paddingBottom: 30 }}>
                <div className="estado">
                  <div className="check-doc">
                    <Unicons.UilCheck size="16" color="#FFFFFF" />
                  </div>
                  <p>Laudo de vistoria dos bombeiros</p>
                </div>
                <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div>
              </div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <button>Editar</button>
                <h4>Classificação</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>STATUS</h6>
                    <span>Em Operação</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>NEGOCIOS DE UNIDADE</h6>
                    <span>Happy code, Happy Bussiness</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>MODELO</h6>
                    <span>Fit</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Alocação de precificação</h6>
                    <span>G2.</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Clusterização</h6>
                    <span>G2</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="botoes" style={{ paddingBottom: 30 }}>
            <Link to="/franquias/create/step-11">
              <div className="voltar"><span>Voltar</span></div>
            </Link>
            <Link to="/franquias/create/step-13">
              <div className="proximo"><span>Próximo</span></div>
            </Link>
          </div>
        </div>
      </div>
      <Link to="/franquias/create/step-9">
        <div className="botao-voltar-revisao">
          <Unicons.UilArrowLeft size="20" color="#895CF2" />
          <span>Voltar</span>
        </div>
      </Link>
    </div>
  )
}

export default FranquiaCreateStep12;