import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';

import { Predicate } from '../../models/predicate.model';
import { useTaxaCobrancaService } from '../../services/taxa-cobranca.service';
import { daysBetween, formatMoney } from '../../util/format';
import { TagsFinanceiro } from '../TagsFinanceiro';
import { ITaxaCobrancaCustomDTO } from './../../models/happy-code-api.model';
import { PageableResponse } from './../../models/response.model';
import { ModalFinanceiroStep1Sede } from './step-1';
import './style.css';

interface Text {
  open: boolean;
  onClose: () => void;
  taxaCobranca: ITaxaCobrancaCustomDTO
}

export const ModalFinanceiroSede: React.FunctionComponent<Text> = ({ open, onClose, taxaCobranca }: Text) => {

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<PageableResponse<ITaxaCobrancaCustomDTO>>();
  const [taxaCobrancaSelectedList, setTaxaCobrancaSelectedList] = useState<ITaxaCobrancaCustomDTO[]>([]);
  const [predicate] = useState<Predicate>(new Predicate());
  const taxaCobrancaService = useTaxaCobrancaService();
  const { setIsGlobalLoading } = useGlobalContext();

  const findTaxaCobrancaByUnidade = () => {
    predicate.removeOption('statusCobrancaCodigo')
    predicate.addMultiOption('statusCobrancaCodigo', 'AGR');
    predicate.addMultiOption('statusCobrancaCodigo', 'GRD');
    predicate.addOption('unidadeId', taxaCobranca.unidade.id);
    return taxaCobrancaService.findCustom(predicate)
      .then((response) => {
        setData(response.data);
      });
  }

  useEffect(() => {
    if (taxaCobranca) {
      setIsGlobalLoading(true);
      Promise.all([
        findTaxaCobrancaByUnidade(),
      ]).finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxaCobranca]);

  const onChange = (e) => {
    const value = e.target.value as ITaxaCobrancaCustomDTO;
    if (e.target.checked == true) {
      value.statusCobranca.id = 5; //CAN	Cancelado
      value.statusCobranca.codigo = 'CAN'; //CAN	Cancelado
      setTaxaCobrancaSelectedList([...taxaCobrancaSelectedList, value]);
    } else {
      setTaxaCobrancaSelectedList([...taxaCobrancaSelectedList.filter(item => item.id !== value.id)]);
    }
  }

  const openResumoNegociacao = () => {
    if (taxaCobrancaSelectedList.length > 0) {
      setIsOpen(true);
    }
  }

  if (!open) return null;

  return (
    <div className="modalContainerFinanceiro">
      <div className="modal-user-financeiro">
        <div className="body">
          <Button onClick={onClose} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
          <h3>Renegociação</h3>
          <h5>Responsável Financeiro</h5>
          <h6>{taxaCobranca?.unidade?.gestorNome}</h6>
          <h5>Unidade</h5>
          <div className="detalhes-aluno">
            <h6>{taxaCobranca?.unidade?.descricao}</h6>
            {/* <h6 id="link">Ver detalhes da unidade <Unicons.UilArrowRight size="14" color="var(--primary-color)" /></h6> */}
          </div>
          <div className="line"></div>
          <div className="box-tabela">
            <h5 style={{ marginBottom: 20 }}>Parcelas</h5>
            <div className="tabela">
              <div className="titles-users">
                <div className="item" style={{ width: '25%' }}>
                  <span>Tipo</span>
                  <div className="arrows">
                    <Unicons.UilDirection size="20" color="#92A9CB" />
                  </div>
                </div>
                <div className="item" style={{ width: '20%' }}>
                  <span>PARCELA</span>
                  <div className="arrows">
                    <Unicons.UilDirection size="20" color="#92A9CB" />
                  </div>
                </div>
                <div className="item" style={{ width: '12%' }}>
                  <span>VALOR</span>
                  <div className="arrows">
                    <Unicons.UilDirection size="20" color="#92A9CB" />
                  </div>
                </div>
                <div className="item" style={{ width: '23%' }}>
                  <span>STATUS</span>
                  <div className="arrows">
                    <Unicons.UilDirection size="20" color="#92A9CB" />
                  </div>
                </div>
                <div className="item" style={{ width: '20%' }}>
                  <span>DIAS VENCIDOS</span>
                  <div className="arrows">
                    <Unicons.UilDirection size="20" color="#92A9CB" />
                  </div>
                </div>
              </div>
              {data && data.content.map((item, index) => (
                <div key={index} className="registro-user">
                  <div className="funcao-user" style={{ width: '25%' }}>
                    <Checkbox checked={taxaCobrancaSelectedList.includes(item)} onChange={onChange} value={item} className="check-left" />
                    <div className="nome" style={{ marginLeft: 10 }}>
                      <p>{item.taxaRenegociacaoOriginada ? 'Renegociação' : 'Normal'}</p>
                    </div>
                  </div>
                  <div className="funcao-user" style={{ width: '20%' }}>
                    <p>{item.competencia}</p>
                  </div>
                  <div className="funcao-user" style={{ width: '12%' }}>
                    <p>{formatMoney(item.valorCobranca)}</p>
                  </div>
                  <div className="funcao-user" style={{ width: '23%' }}>
                    <TagsFinanceiro codigo={item?.statusCobranca?.codigo} descricao={item?.statusCobranca?.descricao}></TagsFinanceiro>
                  </div>
                  <div className="funcao-user" style={{ width: '16%' }}>
                    <p>{daysBetween(item.dataVencimento)}</p>
                  </div>
                  <div className="funcao-user" style={{ width: '4%' }}>
                    <Unicons.UilEllipsisH size="18" color="#6A7C96" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="botoes">
          <Button onClick={onClose} style={{ minWidth: 145 }} className="button-second">Cancelar</Button>
          <Button onClick={() => openResumoNegociacao()} className="button-primary" style={{ marginLeft: 8, minWidth: 145 }}>Proximo</Button>
        </div>
      </div>
      <ModalFinanceiroStep1Sede
        open={isOpen}
        taxaCobranca={taxaCobranca}
        taxaCobrancaList={taxaCobrancaSelectedList}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}