import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useUnidadeContext } from '../../../../context/UnidadeContext';

interface Props {
  current: number;
}

function MenuLateralCreateContratosComponent({ current }: Props): ReactElement {

  const { revisao } = useUnidadeContext();

  return (
    <div className="menu-user">
      <h3>Criação de Grupos</h3>
      <Steps direction="vertical" current={revisao ? 8 : current}>
        <Steps.Step title="Oferta" />
        <Steps.Step title="Financeiro" />
        <Steps.Step title="Escolas" />
        <Steps.Step title="Revisão" />
      </Steps>
    </div>
  )
}

export default MenuLateralCreateContratosComponent
