import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IAcaAulaDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useAcademyAulaService } from '../../../../services/academy-aula';
import AcademyItemListAcaAula from './item-lista';
import './style.css';
import FiltrosAcaAula from './filtros-aula';
import useRegisterLog from '../../../../globalHooks/use-register-log.hook';
import Title from '../../../../components/Title/title.component';

export const AcademyAcaAula: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IAcaAulaDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const aulaService = useAcademyAulaService();
	const { setIsGlobalLoading } = useGlobalContext();
	const history = useHistory();
	const { idTrilha, idCurso } = useParams<any>();

	useRegisterLog({
		descricaoEvento: 'Acesso gestao trilha aulas academy',
		descricaoTipo: 'Acesso a tela',
	});

	const findAula = () => {
		setIsGlobalLoading(true);
		predicate.addOption('curso.id', idCurso);
		predicate.sort = 'desc';
		predicate.addSort('ordem');
		aulaService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findAula();
	};

	const goToAdd = () => {
		history.push(`/academy/trilhas/${idTrilha}/cursos/${idCurso}/aula/create`);
	};

	return (
		<div className="container">
			<NewMenu selecionado={31} />
			<div className="dashboard">
				<div className="box-title">
					<div style={{ display: 'flex', gap: '16px' }}>
						<Button
							onClick={() =>
								history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos`)
							}
							className="button-second"
							icon={<ArrowLeftOutlined rev={undefined} />}
						>
							Voltar
						</Button>
						<Title title={data ? `${data?.content[0]?.curso.nome} aula(s)` : 'carregando...' }/>
					</div>
					<Button
						className="button-primary"
						id="criar-curso"
						icon={<PlusOutlined rev={undefined} />}
						onClick={goToAdd}
					>
						Criar Aula
					</Button>
				</div>

				<FiltrosAcaAula findAula={findAula} predicate={predicate} />

				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '30%' }}>
							<span>Aula</span>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>ID</span>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>ORDEM</span>
						</div>
						<div className="item" style={{ width: '20%' }} />
					</div>
					<div className="tabela-body">
						{data &&
							data.content.map((aula, index) => (
								<AcademyItemListAcaAula aula={aula} key={index} />
							))}
					</div>

					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
