import * as Unicons from '@iconscout/react-unicons';
import { Form } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlunoContext } from '../../../../../../context/AlunoContext';
import { useGlobalContext } from '../../../../../../context/GlobalContext';
import {
	ILeadCustomDTO, ILeadHistoricoDTO
} from '../../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../../models/predicate.model';
import { useEstadoService } from '../../../../../../services/estado.service';
import { useLeadService } from '../../../../../../services/lead.service';
import { useLogradouroService } from '../../../../../../services/logradouro.service';
import { useNotificationService } from '../../../../../../services/notification.service';
import { toMoment } from '../../../../../../util/format';
import { CrmContext, PropsCrmContext } from '../../../../context/crm.context';

interface Props {
	open: boolean;
	type: string;
	boxColor: string;
	onClose: () => void;
	lead?: ILeadCustomDTO;
}

const useLeadDetalheState = (props: Props) => {
	const history = useHistory();
	const { removeAluno, removeMatricula } =
		useAlunoContext();

	const [state, setState] = useState({
		estados: [],
		lead: props.lead,
		showMore: [],
	});

	const [form] = Form.useForm();
	const { setIsGlobalLoading } = useGlobalContext();
	const logradouroService = useLogradouroService();
	const estadoService = useEstadoService();
	const notification = useNotificationService();
	const leadService = useLeadService();
	const [context, setCrmContext, refresh] = useContext(CrmContext);

	useEffect(() => {
		setState((state) => ({ ...state, lead: { ...props.lead } }));

		if (props.lead?.dhProximoRetorno) {
			//props.lead?.dhProximoRetorno = toMoment(props?.lead?.dhProximoRetorno);
			form.setFieldsValue({
				...props.lead,
				dhProximoRetorno: toMoment(props?.lead?.dhProximoRetorno),
			});
		}
	}, [props.lead]);

	const getHistoricoIcone = (historico: ILeadHistoricoDTO) => {
		if (historico.tipoLeadHistorico.dsIconeTipoLeadHistorico == 'profile') {
			return Unicons.UilUserSquare;
		}

		if (historico.tipoLeadHistorico.dsIconeTipoLeadHistorico === 'copy') {
			return Unicons.UilFileAlt;
		}
		return Unicons.UilFileAlt;
	};

	const getHistoricoIconeTipoHistorico = (historico: ILeadHistoricoDTO) => {
		if (historico.tipoLeadHistorico.dsIconeTipoLeadHistorico === 'whatsapp') {
			return Unicons.UilWhatsapp;
		}

		if (historico.tipoLeadHistorico.dsIconeTipoLeadHistorico === 'ligacao') {
			return Unicons.UilCalling;
		}

		if (historico.tipoLeadHistorico.dsIconeTipoLeadHistorico === 'email') {
			return Unicons.UilEnvelope;
		}

		if (historico.tipoLeadHistorico.dsIconeTipoLeadHistorico === 'presencial') {
			return Unicons.UilUsersAlt;
		}
	};

	const findEstadoByPais = (idPais) => {
		const predicate = new Predicate();
		predicate.addOption('pais.id', idPais);

		estadoService.findClean(predicate).then((response) => {
			if (response.status === 200) {
				setState((state) => ({ ...state, estados: response?.data?.content }));
			}
		});
	};

	const onCepChange = (cep: string) => {
		if (cep.length !== 8) {
			return;
		}
		setIsGlobalLoading(true);
		const predicateLogradouro = new Predicate(10);
		predicateLogradouro.addOption('cep', cep);
		logradouroService
			.findCustom(predicateLogradouro)
			.then(({ data }) => {
				if (data && data.length > 0) {
					const logradouro = data[0];
					// const { endereco } = unidade;

					setState((state) => {
						const paisSelecionado = context.paises.find(
							(item) => logradouro.cidadeEstadoPaisId === item.id
						);

						const endereco = {};

						(endereco as any).cep = logradouro.cep;
						(endereco as any).bairro = logradouro.bairroNome;
						(endereco as any).cidade = logradouro.cidadeNome;
						(endereco as any).logradouro = logradouro.nomeCompleto;
						(endereco as any).nomeUF = logradouro.cidadeEstadoNome;
						(endereco as any).paisId = paisSelecionado.id;

						form.setFieldsValue({
							endereco,
						});

						findEstadoByPais(paisSelecionado.id);
						return { ...state };
					});
				} else {
					notification({
						description: 'CEP não encontrado',
						type: 'warning',
						message: 'Aviso',
					});
				}
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const handleChangePais = (e) => {
		findEstadoByPais(e);
	};

	const onMarcarVenda = () => {
		removeAluno();
		removeMatricula();
		// setLeadResponsavel(props.lead);
		history.push('/alunos/create/step-1');
	};

	const onDescartarLead = () => {
		setIsGlobalLoading(true);
		leadService
			.delete({ id: props.lead?.id })
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					setCrmContext((crmContext: PropsCrmContext) => {
						const boardAntigo = crmContext.boards.find(
							(board) => board.id === props.lead?.estagio.id
						);
						boardAntigo.leads.splice(
							boardAntigo.leads.findIndex((lead) => lead?.id === props.lead?.id),
							1
						);
						return { ...crmContext };
					});

					notification({
						description: 'Lead descartado!',
						type: 'success',
						message: 'Sucesso!',
					});
				}
			})
			.catch((e) => {
				notification({
					description: 'Falha ao descartar o lead!',
					type: 'error',
					message: 'Erro!',
				});
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const put = () => {
		const body = { ...form.getFieldsValue() };
		body.cdCpfCnpj = (body.cdCpfCnpj as string)
			.replaceAll('.', '')
			.replaceAll('-', '')
			.replaceAll('/', '');
		body.campanha =
			!!body.campanha.id || body.campanha.nome ? body.campanha : undefined;
		setIsGlobalLoading(true);

		leadService
			.patch(body.id, body, null)
			.then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					return refresh().finally(() => {
						props.onClose();
					});
				}
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	const onAlterarLead = () => {
		form
			.validateFields()
			.then(() => {
				return put();
			})
			.catch((errors) => {
				notification({
					description: 'Por favor, preencha todos os campos',
					type: 'warning',
					message: 'Aviso',
				});
			});
	};

	const isExpandido = (idItem: number) => {
		return state.showMore.includes(idItem);
	};

	const toggleVerMais = (idItem: number) => {
		setState((state) => {
			const showMore = [...state.showMore];
			isExpandido(idItem)
				? showMore.splice(showMore.indexOf(idItem), 1)
				: showMore.push(idItem);
			return { ...state, showMore: showMore };
		});
	};

	const exibirMostrarMais = (dsTexto: string) => {
		return dsTexto.length > 100;
	};

	const getText = (dsTexto: string, idItem: number) => {
		if (exibirMostrarMais(dsTexto)) {
			if (isExpandido(idItem)) {
				return dsTexto;
			}
			return dsTexto?.substring(0, 100) + '...';
		}

		return dsTexto;
	};

	const renderComponenteVerMais = (item: ILeadHistoricoDTO) => {
		if (isExpandido(item?.id)) {
			return 'ver menos';
		}

		if (exibirMostrarMais(item?.dsHistorico)) {
			return 'ver mais';
		}

		return '';
	};

	const onDetalheClick = (item: ILeadHistoricoDTO) => {
		if (!exibirMostrarMais(item.dsHistorico)) {
			return;
		}

		toggleVerMais(item.id);
	};

	return {
		state,
		getHistoricoIcone,
		form,
		onCepChange,
		handleChangePais,
		onMarcarVenda,
		onDescartarLead,
		onAlterarLead,
		getHistoricoIconeTipoHistorico,
		toggleVerMais,
		isExpandido,
		onDetalheClick,
		getText,
		renderComponenteVerMais,
	};
};

export default useLeadDetalheState;
