import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { formatHttpParams, Predicate } from '../models/predicate.model';
import {
	RelatorioMatriculaNovoDTO,
	RelatorioRematriculaNovoDTO,
} from '../models/relatorio.model';

export const useRelatorioService = () => {
	const resourceURL = 'relatorio';

	const matriculas = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/matricula`, formatHttpParams(predicate));
	};

	const matriculasNovo = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<RelatorioMatriculaNovoDTO[]>> => {
		return http.get(
			`${resourceURL}/matricula-novo`,
			formatHttpParams(predicate)
		);
	};

	const financeiros = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/financeiro`, formatHttpParams(predicate));
	};

	const aniversariantes = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/aniversariantes`,
			formatHttpParams(predicate)
		);
	};

	const conciliacao = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/conciliacao-bancaria`,
			formatHttpParams(predicate)
		);
	};

	const matriculaNaoEfetivada = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/matricula-nao-efetivada`,
			formatHttpParams(predicate)
		);
	};

	const financeirosSede = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/financeiro-sede`,
			formatHttpParams(predicate)
		);
	};

	const financeirosSede2 = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/financeiro-sede2`,
			formatHttpParams(predicate)
		);
	};

	const pagos = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/financeiro-pagos`,
			formatHttpParams(predicate)
		);
	};

	const matriculaSede = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/matricula-sede`,
			formatHttpParams(predicate)
		);
	};

	const matriculaSedeCurriculoPrematriculado = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/matricula-sede-curriculo-prematriculado`,
			formatHttpParams(predicate)
		);
	};

	const matriculaSedeCurriculo = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/matricula-sede-curriculo`,
			formatHttpParams(predicate)
		);
	};

	const inadimplencia = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/inadimplencia`,
			formatHttpParams(predicate)
		);
	};

	const inadimplenciaMensal = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/inadimplencia-mensal`,
			formatHttpParams(predicate)
		);
	};


	const inadimplenciaHub = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/inadimplencia-hub`,
			formatHttpParams(predicate)
		);
	};

	const relatorio3k = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/relatorio-3k`, formatHttpParams(predicate));
	};

	const rematricula = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/rematricula`, formatHttpParams(predicate));
	};

	const rematriculaNovo = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<RelatorioRematriculaNovoDTO[]>> => {
		return http.get(
			`${resourceURL}/rematricula-novo`,
			formatHttpParams(predicate)
		);
	};

	const contratoEscola = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/contrato-escola`,
			formatHttpParams(predicate)
		);
	};

	const financeiroEscola = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/financeiro-escola`,
			formatHttpParams(predicate)
		);
	};

	const evolucaoLicencaEscola = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/evolucao-licenca-escola`,
			formatHttpParams(predicate)
		);
	};

	const consolidacaoEscola = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/consolidacao-escola`,
			formatHttpParams(predicate)
		);
	};

	const registroFrequenciaTurma = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/registro-frequencia-turma-academy`,
			formatHttpParams(predicate)
		);
	};

	const registroFrequenciaAluno = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/registro-frequencia-aluno-academy`,
			formatHttpParams(predicate)
		);
	};

	const registroAlunoSkill = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/registro-aluno-skill-academy`,
			formatHttpParams(predicate)
		);
	};

	const instrutorAcademy = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/instrutor-academy`,
			formatHttpParams(predicate)
		);
	};

	const progressoTrilhaCursoInstrutorAcademy = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/progresso-trilha-curso-instrutor-academy`,
			formatHttpParams(predicate)
		);
	};

	const progressoAulaInstrutorAcademy = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/progresso-aula-instrutor-academy`,
			formatHttpParams(predicate)
		);
	};

	const registroAvaliacao = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/registro-avaliacao`,
			formatHttpParams(predicate)
		);
	};

	const registroTarefa = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(
			`${resourceURL}/registro-tarefa`,
			formatHttpParams(predicate)
		);
	};

	const relatorioExelenciaNotaTotal = (
		predicate: Predicate = new Predicate()
	): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/relatorio-exelencia-nota-total`, formatHttpParams(predicate));
	};

	const financeiroDetalhado = (predicate: Predicate = new Predicate()): Promise<AxiosResponse<any>> => {
		return http.get(`${resourceURL}/financeiro-detalhado`, formatHttpParams(predicate));
	};

	return {
		matriculas,
		matriculasNovo,
		financeiros,
		aniversariantes,
		matriculaNaoEfetivada,
		financeirosSede,
		financeirosSede2,
		pagos,
		matriculaSede,
		conciliacao,
		inadimplencia,
		inadimplenciaHub,
		relatorio3k,
		rematricula,
		rematriculaNovo,
		contratoEscola,
		financeiroEscola,
		evolucaoLicencaEscola,
		consolidacaoEscola,
		registroFrequenciaTurma,
		registroFrequenciaAluno,
		registroAlunoSkill,
		instrutorAcademy,
		progressoTrilhaCursoInstrutorAcademy,
		progressoAulaInstrutorAcademy,
		registroAvaliacao,
		registroTarefa,
		matriculaSedeCurriculoPrematriculado,
		matriculaSedeCurriculo,
		inadimplenciaMensal,
		relatorioExelenciaNotaTotal,
		financeiroDetalhado
	};
};
