/* eslint-disable react-hooks/exhaustive-deps */
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from "../../../context/GlobalContext";
import { IUnidadeDTO } from "../../../models/happy-code-api.model";
import { Predicate } from "../../../models/predicate.model";
import { useUnidadeService } from "../../../services/unidade.service";
import '../../../theme/flex.scss';
import EscolaGruposFormActionComponent from '../components/escola-grupos-form-action-component/escola-grupos-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-grupos-form-fechar-component/escola-grupos-form-fechar.component';
import MenuLateralCreateComponent from '../components/menu-lateral-create-component/menu-lateral-create.component';
import '../styles/escola-grupo-create-step.styles.scss';
import { PerfilEnum } from "../../../enum/perfil.enum";

const GrupoCreateStep1Screen: React.FunctionComponent = () => {
    const { unidade, usuario } = useGlobalContext();
    const [predicate] = useState<Predicate>(new Predicate());

    const { grupoParaCadastrar,
        setGrupoParaCadastrar,
        flRevisao,
        setFlRevisao,
        categoriasQuantidadeAlunos,
        categoriasConfessionais, } = useEscolaContext();

    const history = useHistory();
    const [form] = Form.useForm();
    const [franquias, setFranquias] = useState<IUnidadeDTO[]>();
    const unidadeService = useUnidadeService();
    const required = true;

    // useEffect(() => {
    //   const franquiaDataFromContext = unidade;
    //   if (franquiaDataFromContext) {
    //     form.setFieldsValue(franquiaDataFromContext);
    //   }
    // }, [form, unidade]);

    useEffect(() => {
        unidadeService.findList().then((response) => {
            setFranquias(response.data);
        });
    }, [unidade])

    const handleUnidade = (franquiaCrud) => {
        const contextState = { ...grupoParaCadastrar, ...franquiaCrud };

        contextState.unidade.descricao = "SEDE";

        if (franquiaCrud.unidade?.id)

            contextState.unidade.descricao =
                franquias.filter(franquia => franquia.id === franquiaCrud.unidade?.id)[0].descricao;

        setGrupoParaCadastrar({ ...contextState });
        if (flRevisao) {
            setFlRevisao(false);
            return history.replace("/escolas/grupos/create/step-4");
        }
        history.push("/escolas/grupos/create/step-2");
    };

    return (
        <div id="escola-grupo-create-step-1" className="container">
            <Header />
            <Progress />
            <MenuLateralCreateComponent current={0} />
            <div className="franquia">
                <Form
                    form={form}
                    name="franquia-row"
                    onFinish={handleUnidade}
                    className="franquia-row"
                >
                    <h5 style={{ marginBottom: 20 }}>Insira o nome e dados básicos do grupo</h5>
                    <div className="input-franquia">
                        <span>Nome fantasia</span>
                        <Form.Item
                            name="nomeFantasia"
                            initialValue={grupoParaCadastrar?.nomeFantasia}
                            rules={[{ required: required, message: 'Insira o nome do grupo' }]}>
                            <Input
                                id="nomeFantasia"
                                className="input"
                            />
                        </Form.Item>
                    </div>
                    <div className="input-franquia">
                        <span>CNPJ</span>
                        <Form.Item
                            name="cnpj"
                            initialValue={grupoParaCadastrar?.cnpj}
                            rules={[{ required: required, message: 'Insira o CNPJ' }]}>
                            <CpfCnpj
                                id="cnpj"
                                className="input"
                                type="tel"
                            />
                        </Form.Item>
                    </div>

                    <div className="input-franquia">
                        <span>Razão Social</span>
                        <Form.Item
                            name="razaoSocial"
                            initialValue={grupoParaCadastrar?.razaoSocial}
                            rules={[{ required: required, message: 'Insira a razão social do grupo' }]}>
                            <Input
                                id="razaoSocial"
                                className="input"
                            />
                        </Form.Item>
                    </div>

                    <div className="input-franquia">
                        <span>Grupo E-mail</span>
                        <Form.Item
                            name="email"
                            initialValue={grupoParaCadastrar?.email}
                            rules={[{ required: required, message: 'Inserir o e-mail do grupo' }]}>
                            <Input
                                id="email"
                                className="input"
                            />
                        </Form.Item>
                    </div>

                    <div className="select-franquia">
                        <span>Quantidade de alunos</span>
                        <Form.Item
                            initialValue={grupoParaCadastrar?.categoriaQuantidadeAlunos?.id}
                            name={['categoriaQuantidadeAlunos', "id"]}
                            fieldKey={['categoriaQuantidadeAlunos', "id"]}
                            rules={[{ required: required, message: 'Selecione a quantidade' }]}>
                            <Select
                                id="categoriaQuantidadeAlunos"
                                placeholder="Selecione a quantidade"
                                allowClear
                            >
                                {categoriasQuantidadeAlunos && categoriasQuantidadeAlunos.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="select-franquia">
                        <span>Categoria confessional</span>
                        <Form.Item
                            name={['categoriaConfessional', "id"]}
                            fieldKey={['categoriaConfessional', "id"]}
                            initialValue={grupoParaCadastrar?.categoriaConfessional?.id}
                            rules={[{ required: required, message: 'Selecione a categoria' }]}>
                            <Select
                                id="categoriaConfessional"
                                placeholder="Selecione a categoria"
                                allowClear
                            >
                                {categoriasConfessionais && categoriasConfessionais.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="select-franquia">
                        <span>Responsável pelo suporte</span>
                        <Form.Item
                            name={['unidade', "id"]}
                            fieldKey={['unidade', "id"]}
                            rules={[{ required: false, message: 'Selecione o responsável' }]}
                            initialValue={!grupoParaCadastrar?.unidade?.id ? null : grupoParaCadastrar?.unidade?.id}>
                            <Select
                                id="unidade"
                                // disabled={grupoParaCadastrar?.id ? true : false}
                                placeholder="Selecione o responsável"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={
                                    (input, option) =>
                                        option?.texto?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {!unidade.id || usuario.perfil === PerfilEnum.SEDE ?
                                    <Select.Option key={-1} value={null}>SEDE</Select.Option>
                                    : null}
                                {franquias && franquias.map((item, index) => (
                                    (item.id === unidade.id || (!unidade.id && item.id) ?
                                        <Select.Option key={index} value={item.id} texto={item.descricao}>{item.descricao}</Select.Option>
                                        : null)
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <EscolaGruposFormActionComponent revisao={flRevisao} rotaAnterior={'/escolas/grupos/'} />
                </Form>
            </div >
            <EscolaGruposFormFecharComponent />
        </div >
    )
}

export default GrupoCreateStep1Screen;