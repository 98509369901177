import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ImgYoutube from '../../assets/img/box-curso/youtube.svg';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import { useGlobalContext } from '../../context/GlobalContext';

import { IAulaFullDTO, IFrequenciaDTO, IMatriculaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAulaService } from '../../services/aula.service';
import { useFrequenciaService } from '../../services/frequencia.service';
import { useMatriculaService } from '../../services/matricula.service';
import { PresencaSwitch } from './presenca-switch';
import './style.css';

export const LancamentoAula: React.FunctionComponent = () => {
  const { id } = useParams<any>();
  const [aula, setAula] = useState<IAulaFullDTO>();
  const [listMatricula, setListMatricula] = useState<IMatriculaFullDTO[]>();
  const [listFrequecia, setListFrequencia] = useState<IFrequenciaDTO[]>([]);
  const [isView, setIsView] = useState<boolean>(false);
  const { setIsGlobalLoading } = useGlobalContext();
  const aulaService = useAulaService();
  const matriculaService = useMatriculaService();
  const frequenciaService = useFrequenciaService();
  const history = useHistory();

  const findAula = () => {
    return aulaService.findFullById({ id })
      .then((response) => {
        setAula(response.data);
      })
  }

  const findFrequencia = () => {
    const frequenciaPredicate = new Predicate();
    frequenciaPredicate.addOption('turma.id', aula.turma.id);
    frequenciaPredicate.addOption('dataPresenca', aula.data);
    return frequenciaService.findList(frequenciaPredicate)
      .then((response) => {
        const frequecias = response.data;
        if (frequecias.length === 0) {
          listMatricula.forEach((matricula) => {
            const frequecia: IFrequenciaDTO = {
              id: null,
              ativo: true,
              dataPresenca: aula.data,
              frequencia: true,
              matricula: matricula,
              turma: { ...aula.turma },
              frequenciaTarefa: null,
              frequenciaTipo: null,
              reforco: false
            }

            frequecias.push(frequecia);
          });
        } else {
          setIsView(true);
        }
        setListFrequencia(frequecias);
      });
  }

  const findMatricula = () => {
    const matriculaPredicate = new Predicate();
    matriculaPredicate.addOption('turma.id', aula.turma.id);
    return matriculaService.findFullList(matriculaPredicate)
      .then((response) => {
        setListMatricula(response.data);
      });
  }


  useEffect(() => {
    if (id) {
      setIsGlobalLoading(true);
      Promise.all([
        findAula(),
      ]).finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (aula) {
      setIsGlobalLoading(true);
      Promise.all([
        findMatricula(),
      ]).finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aula]);

  useEffect(() => {
    if (listMatricula) {
      setIsGlobalLoading(true);
      findFrequencia().finally(() => setIsGlobalLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMatricula]);

  const onChangeFrequencia = (presenca: boolean, matricula: IMatriculaFullDTO) => {
    if (listFrequecia && listFrequecia.length > 0) {
      listFrequecia.find((frequencia) => frequencia.matricula.id === matricula.id).frequencia = presenca;
    }
  }

  const registrarLancamento = () => {
    setIsGlobalLoading(true);
    if (isView) {
      frequenciaService.patchAll(listFrequecia)
        .then(() => {
          setIsView(true);
          setIsGlobalLoading(true);
          return findFrequencia().finally(() => setIsGlobalLoading(false));
        }).finally(() => setIsGlobalLoading(false));
    } else {
      frequenciaService.saveAll(listFrequecia)
        .then(() => {
          setIsView(true);
          setIsGlobalLoading(true);
          return findFrequencia().finally(() => setIsGlobalLoading(false));
        }).finally(() => setIsGlobalLoading(false));
    }

  }

  const getFrequenciaValue = (matricula: IMatriculaFullDTO): IFrequenciaDTO => {
    return listFrequecia.find((frequencia) => frequencia.matricula.id === matricula.id);
  }

  return (
    <div className="container-fondo">
      <NewMenu selecionado={12} />
      <div className="franquia" id="aluno-detalhe-row">
        <div className="franquia-row" style={{ marginTop: 70 }} id="row-aluno-box">
          <div className="head-botoes" id="detalhe-turma">
            <Link to="/turmas">
              <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>Voltar</Button>
            </Link>
            <h5 style={{ marginLeft: 15 }}>Lançamento de Aula</h5>
            <Button onClick={() => registrarLancamento()} style={{ width: 178, height: '32px!important', marginLeft: 'auto' }} className="button-primary">
              Registrar Lançamento
            </Button>
          </div>
          <div className="box-data-aluno">
            <div className="data-aluno" style={{ minHeight: 176, width: '100%' }}>
              <div id="turma-detalhe">
                <div className="avatar-turma" id="avatar-turma">
                  <img src={ImgYoutube} alt="" />
                  <Tags tipo={aula?.descricao} />
                </div>
                <div className="data">
                  <h4>{aula?.descricao} {moment(aula?.data).format('DD/MM/YYYY')}</h4>
                  <h5 style={{ fontSize: 18, color: '#6A7C96' }}>{aula?.turma?.descricao}</h5>
                  <div className="dats" style={{ width: '60%' }}>
                    <div className="dat">
                      <Unicons.UilSmile size="14" color="#6A7C96" />
                      <p>{listMatricula?.length} alunos ativos</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row-revisao">
            <ul className="menu-revisao-detalhe pointer">
              <li className={'selected'} style={{ width: '25%' }}>
                Alunos
              </li>
            </ul>
            <div className="box-revisao">
              <div className="dados">
                <div className="tabela">
                  <div className="titles-users">
                    <div className="item" style={{ width: '30%' }}>
                      <span>NOME</span>
                      <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                      </div>
                    </div>
                    <div className="item" style={{ width: '15%' }}>
                      <span>MATRICULA</span>
                      <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                      </div>
                    </div>
                    <div className="item" style={{ width: '15%' }}>
                      <span>PRESENÇA</span>
                      <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                      </div>
                    </div>
                    <div className="item" style={{ width: '20%' }}>
                      <span>OBSERVAÇÕES</span>
                      <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                      </div>
                    </div>
                    <div className="item" style={{ width: '20%' }}>
                      <span>SKILLS</span>
                      <div className="arrows">
                        <Unicons.UilDirection size="20" color="#92A9CB" />
                      </div>
                    </div>
                  </div>
                  {listMatricula && listMatricula.map((matricula) => (
                    <div key={matricula.id} className="registro-user">
                      <div className="user" id="cursos" style={{ width: '30%' }}>
                        <div className="nome" style={{ marginBottom: 0 }}>
                          <p>{matricula.alunoPessoaNome}</p>
                          <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">{matricula.ativo ? 'Ativo' : 'Desativado'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="funcao-user" id="alunos" style={{ width: '15%' }}>
                        <p>#{matricula.id}</p>
                      </div>
                      <div className="funcao-user" style={{ width: '15%', position: 'relative' }}>
                        <PresencaSwitch value={getFrequenciaValue(matricula)?.frequencia} onChange={(value) => onChangeFrequencia(value, matricula)} />
                      </div>
                      <div className="funcao-user pointer" id="aluno-responsavel" style={{ width: '20%', position: 'relative' }}>
                        <Button onClick={() => history.push(`/turmas/observacoes/aluno/${matricula.aluno.id}/frequencia/${getFrequenciaValue(matricula)?.id}`)} disabled={!isView} style={{ width: 90, height: '32px!important', position: 'unset' }} className="button-primary">
                          Adicionar
                        </Button>
                      </div>
                      <div className="funcao-user pointer" id="aluno-responsavel" style={{ width: '20%', position: 'relative' }}>
                        <Button onClick={() => history.push(`/turmas/skills/aluno/${matricula.aluno.id}/frequencia/${getFrequenciaValue(matricula)?.id}`)} disabled={!isView} style={{ width: 90, height: '32px!important', position: 'unset' }} className="button-primary">
                          Adicionar
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}