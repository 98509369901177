import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { ILogCleanDTO, ISalaCleanDTO, ISalaDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';

import { useLogService } from '../../services/log.service';
import { useSalaService } from '../../services/sala.service';
import FiltrosCurriculo from './filtros-sala';
import ItemListSala from './item-lista';
import './style.css';

const Sala: React.FunctionComponent = () => {
    const { unidade } = useGlobalContext();
    const [data, setData] = useState<PageableResponse<ISalaDTO>>();
    const [predicate] = useState<Predicate>(new Predicate());
    const salaService = useSalaService();
    const { setIsGlobalLoading } = useGlobalContext();

    const history = useHistory();

    const logService = useLogService();
    const [log] = useState<ILogCleanDTO>({
        descricaoEvento: "Acesso Sala",
        descricaoTipo: "Acesso a tela",
        idTipo: null,
        id: null,
        descricaoUsuario: null,
        ativo: true
    });

    useEffect(() => {
        logService.registraLog(log)
    }, [])

    const findSalas = () => {
        if (unidade.id != null) {
            setIsGlobalLoading(true);
            predicate.addOption('unidade.id', unidade.id)
            salaService.find(predicate)
                .then(({ data }) => setData(data))
                .finally(() => setIsGlobalLoading(false));
        }
    }

    const onPageChange = (event: ChangeEvent<any>, page: number) => {
        event.preventDefault();
        predicate.setPage(page);
        findSalas();
    }

    const goToAdd = () => {
        history.push("/sala/create/step-1");
    }

    const ordenar = (campo) => {
        predicate.addSort(campo);
        findSalas();
    }

    const handleSala = (sala: ISalaCleanDTO, state: boolean) => {
        sala.ativo = state;
        setIsGlobalLoading(true);
        salaService.patch(sala)
            .then(() => findSalas())
            .finally(() => setIsGlobalLoading(false));
    }

    useEffect(() => {
        findSalas()
    }, [unidade.id])    

    useEffect(() => {
        findSalas()
    }, []) 

    return (
        <div className="container">
            <NewMenu selecionado={99} />
            <div className="dashboard">
                <div className="box-title">
                    <h3 className="title">Salas ({data?.totalElements})</h3>
                    <Button className="button-primary" id="criar-campanha" icon={<PlusOutlined rev={undefined} />} onClick={goToAdd}>Criar Sala</Button>
                </div>
                <FiltrosCurriculo findSalas={findSalas} predicate={predicate} />
                <div className="tabela">
                    <div className="titles-users">
                        <div className="item" style={{ width: '30%' }}>
                            <span>Nome</span>
                            <div className="arrows" onClick={() => ordenar('descricaoSala')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '10%' }}>
                            <span>ID</span>
                            <div className="arrows" onClick={() => ordenar('id')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '100%' }}>
                            <span>Capacidade</span>
                            <div className="arrows" onClick={() => ordenar('capacidadeMaxima')}>
                                <Unicons.UilDirection size="20" color="#92A9CB" />
                            </div>
                        </div>
                        <div className="item" style={{ width: '5%' }}></div>
                    </div>
                    <div className="tabela-body">
                        {data && data.content.map((sala, index) => (
                            <ItemListSala handleSala={(sala, state) => handleSala(sala, state)} sala={sala} key={index} />
                        ))}
                    </div>
                    <div className="paginacao">
                        <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sala;