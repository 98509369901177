
import {  ICobrancaPagarMeDTO } from '../../models/happy-code-api.model';
import { formatMoney, formattedDate } from '../../util/format';
import SkeletonTable from './skeleton-table';
import Style from './style.module.css';
import { TableProps } from "antd";
import { Table } from 'antd';


 interface Props {
  cobranca: ICobrancaPagarMeDTO,
  cobrancaUnica: boolean,
 }


 const Step1 = ({cobranca, cobrancaUnica}:Props) => {
  interface DataType {
   key: number;
   parcela: string;
   valor: string;
   vencimento: string;
   status: string;
   tipoPagamento: string;
   pagamento: string;
 }
      const columns: TableProps<DataType>['columns'] = [
           {
            title: 'Parcela',
            dataIndex: 'parcela',
            key: 'parcela',
            },
            {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            },
            {
            title: 'Vencimento',
            dataIndex: 'vencimento',
            key: 'vencimento',
            },
            {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            },
            {
            title: 'Tipo de pagamento',
            dataIndex: 'tipoPagamento',
            key: 'tipoPagamento',
            },
            {
            title: 'Pagamento',
            dataIndex: 'pagamento',
            key: 'pagamento',
            },
      ];
      
      const data: DataType[] = cobranca?.listaCobranca?.map((cobranca) => ({
        key: cobranca?.id,
        parcela: cobranca?.parcela === 0 ? "Matricula" : (cobrancaUnica ? 'Parcela 1/1': `${cobranca?.parcela} / ${cobranca?.financeiro?.quantidadeParcelas}`),
        valor: formatMoney(cobranca?.valorCobranca),
        vencimento: formattedDate(cobranca?.dataVencimento),
        status: cobranca?.statusCobranca?.descricao,
        tipoPagamento: cobranca?.tipoPagamento?.descricao ? cobranca?.tipoPagamento?.descricao : '-',
        pagamento: cobranca?.dataPagamento ? formattedDate(cobranca.dataPagamento) : '-',
      }));


  return (
    <div className={Style.step_1_header}>
      <SkeletonTable loading={cobranca ? false : true } columns={columns} key={cobranca?.id} active={true} rowCount={4} className={Style.tableCheckout}> 
    <Table columns={columns} dataSource={data} pagination={false} size='small' scroll={window.innerWidth <= 1200 ? { x: true } : null}
     rowClassName={Style.rowClassCheckout} className={Style.tableCheckout}
/>
    </SkeletonTable>
    </div> 
  )
}






export default Step1