import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { Button, Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import '../../../shared.scss';
import BotoesCadastroComponent from '../../BotoesCadastro/botoes-cadastro.component';
import StepCadastroComponent from '../../StepCadastro/step-cadastro.component';
import './novo-lead-step-1.scss';
import useNovoLeadStep1ComponentState from './novo-lead-step-1.state';

interface Props {
  onClose?: () => void;
  onNext?: () => void;
  onFecharModal?: () => void;
}

export const NovoLeadStep1 = (props: Props) => {

  const {
    leadParaCadastrar,
    categoriasQuantidadeAlunos,
    grupos,
    onFinish,
    onFecharModal,
  } = useNovoLeadStep1ComponentState();

  const [form] = Form.useForm();

  return (
    <>
      <div id="novo-lead-step-1" className="flex flex-row justify-center align-center modal-container modalBox">
        <div className="flex flex-column justify-start align-start modal user-row">
          <Button onClick={onFecharModal} className="button-x">x</Button>
          <div className='container-titulo'>
            <h1>Cadastrar Lead</h1>
          </div>
          <h3>Dados gerais</h3>
          <Form
            style={{ minWidth: '100%' }}
            name="control-hooks"
            initialValues={leadParaCadastrar}
            form={form}
            onFinish={onFinish}
          >

            <div className="col">
              <div className="flex width-100">
                <div className="inputModal">
                  <span>Nome completo <span className="vermelho">*</span></span>
                  <Form.Item name={'nome'} style={{ width: '100%' }} rules={[{ required: true, message: 'Insira o nome' }]}>
                    <Input id="nome" />
                  </Form.Item>
                </div>
              </div>

              <div className="flex width-100">
                <div className="inputModal">
                  <span>CNPJ<span className="vermelho"></span></span>
                  <Form.Item
                    name="cpfOuCnpj"
                    rules={[{ required: false, message: 'Insira o CPF ou CNPJ' }]}>
                    <CpfCnpj
                      id="cpfOuCnpj"
                      className="input"
                      type="tel"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="row width-100">
                <div className="inputModal" style={{ width: '20%' }}>
                  <span>DDD</span>
                  <Form.Item name={'dddTelefone'} style={{ width: '100%' }} rules={[{ required: false, message: '' }]}>
                    <MaskedInput mask="11"
                      placeholder='DDD' bordered={false} />
                  </Form.Item>
                </div>
                <div className="horizontal-separator" />
                <div className="inputModal" style={{ width: '80%' }}>
                  <span>Telefone</span>
                  <Form.Item name={'telefone'} style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o Telefone' }]}>
                    <MaskedInput mask="11111-1111"
                      placeholder='Telefone' bordered={false} />
                  </Form.Item>
                  <div />
                </div>
              </div>

              <div className="row width-100">
                <div className="inputModal" style={{ width: '20%' }}>
                  <span>DDD<span className="vermelho"></span></span>
                  <Form.Item name={'dddCelular'} style={{ width: '100%' }} rules={[{ required: false, message: 'Insera o DDD' }]}>
                    <MaskedInput mask="11"
                      placeholder='DDD' bordered={false} />
                  </Form.Item>
                </div>
                <div className="horizontal-separator" />
                <div className="inputModal" style={{ width: '80%' }}>

                  <span>Celular<span className="vermelho"></span></span>
                  <Form.Item name={'celular'} style={{ width: '100%' }} rules={[{ required: false, message: 'Informe o celular' }]}>
                    <MaskedInput mask="11111-1111"
                      placeholder='Celular' bordered={false} />
                  </Form.Item>
                  <div />
                </div>
              </div>

              <div className="flex width-100">
                <div className="inputModal">
                  <span>E-mail<span className="vermelho"></span></span>
                  <Form.Item
                    name={'email'}
                    style={{ width: '100%' }}
                    rules={[{ required: false, message: 'Insira o e-mail' }]}>
                    <Input id="email" />
                  </Form.Item>
                </div>
              </div>

              <div className="flex width-100">
                <div className="inputModal">
                  <span>Porte (Quant. alunos)</span>
                  <Form.Item
                    initialValue={leadParaCadastrar?.categoriaQuantidadeAlunos?.id}
                    name={['categoriaQuantidadeAlunos', "id"]}
                    fieldKey={['categoriaQuantidadeAlunos', "id"]}
                    rules={[{ required: false, message: 'Selecione a quantidade' }]}>
                    <Select
                      id="categoriaQuantidadeAlunos"
                      placeholder="Selecione a quantidade"
                      allowClear
                    >
                      {categoriasQuantidadeAlunos && categoriasQuantidadeAlunos.map((item, index) => (
                        <Select.Option key={index} value={item.id}>{item.descricao}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="select-franquia">
                <span>Grupo mantenedor / Rede Educacional</span>
                <Form.Item
                  name={['grupo', "id"]}
                  fieldKey={['grupo', "id"]}
                  initialValue={leadParaCadastrar?.grupo?.id}
                  rules={[{ required: false, message: 'Selecione o grupo' }]}>
                  <Select
                    id="grupo"
                    placeholder="Selecione o responsável"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={
                      (input, option) =>
                        option.texto.toLowerCase().includes(input.toLowerCase())
                    }>
                    {grupos && grupos.map((item, index) => (
                      <Select.Option key={index} value={item.id} texto={item.nomeFantasia} ke>{item.nomeFantasia}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="horizontal-separator" />´

            </div>

            <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
              <div className="horizontal-separator" />
            </div>
            <div className="vertical-separator" />
            <StepCadastroComponent />
            <BotoesCadastroComponent />
          </Form>
        </div>

        {/* <ModalResposta
          open={isModalSucessoOpen}
          onClose={onClose1}
          status="Sucesso!"
          text="As informações do Lead foram salvas com sucesso."
          link="crm"
        />
        <ModalResposta
          open={isModalErroOpen}
          onClose={onClose1}
          status="Ocorreu um erro!"
          text="Ocorreu um erro ao salvar o lead, por favor, tente novamente."
          link="crm"
        /> */}

      </div >
    </>
  )
}
