import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ModalUploadArquivo } from '../../../../components/ModalUploadArquivo';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import {
	ILogCleanDTO,
	IMaterialDTO,
} from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useAcademyMaterialService } from '../../../../services/academy-material';
import FiltrosMaterial from './filtros-curso';
import AcademyItemListMaterial from './item-lista';
import './style.css';
import IconButton from '../../../../components/ButtonVoltar';
import { useLogService } from '../../../../services/log.service';

export const AcademyMaterial: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IMaterialDTO>>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [predicate] = useState<Predicate>(new Predicate());
	const materialService = useAcademyMaterialService();
	const { setIsGlobalLoading } = useGlobalContext();
	const { idCurso, idAula } = useParams<any>();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso gestao material academy',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const findMaterial = () => {
		setIsGlobalLoading(true);
		predicate.addOption('cursoAulaPadrao.id', idAula);
		materialService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findMaterial();
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findMaterial();
	};

	const finalizationModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	}

	const { push } = useHistory();

	const voltarButtonClick = () =>
		push(`/academy/gestao-material/cursos/${idCurso}/aulas`);

	return (
		<div className="container">
			<NewMenu selecionado={30} />
			<div className="dashboard">
				<div className="box-title">
					{/* <IconButton icon={} /> */}

					<h3 className="title">
						{data?.content[0]?.cursoAulaPadrao.descricao} ({data?.totalElements}{' '}
						materiais){' '}
					</h3>
					<Button
						className="button-primary"
						id="criar-curso"
						onClick={() => setIsOpen(true)}
						icon={<PlusOutlined rev={undefined} />}
					>
						Enviar novo arquivo
					</Button>
				</div>
				<FiltrosMaterial findMaterial={findMaterial} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '15%' }}>
							<span>NOME</span>
							<div className="arrows" onClick={() => ordenar('descricao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>NEGÓCIO</span>
							<div className="arrows" onClick={() => ordenar('negocio')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>CURRÍCULO</span>
							<div className="arrows" onClick={() => ordenar('curriculo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>PRODUTO</span>
							<div
								className="arrows"
								onClick={() => ordenar('produtoStemplay')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>VISUALIZAÇÃO</span>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>TIPO</span>
							<div
								className="arrows"
								onClick={() => ordenar('tipoMaterial.descricao')}
							>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }} />
					</div>
					<div className="tabela-body">
						{data &&
							data.content.map((material, index) => (
								<AcademyItemListMaterial
									material={material}
									key={index}
								/>
							))}
					</div>
					{isOpen ? (
						<ModalUploadArquivo
							open={isOpen}
							onClose={finalizationModal}
							cursoAulaPadrao={idAula}
							verb={'post'}
						/>
					) : null}
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
