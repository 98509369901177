import { ReactNode } from 'react';
import './_menu-sidebar-and-container.styles.scss';
import NewMenu from '../NewMenu';

interface MenuSidebarAndContainerProps {
	itemMenuSelected: number;
	children: ReactNode;
}
export default function MenuSidebarAndContainer({
	itemMenuSelected,
	children,
}: MenuSidebarAndContainerProps) {
	return (
		<main className="menu-sidebar-and-container">
			<NewMenu selecionado={itemMenuSelected} />
			<section className="menu-sidebar-and-container__children-wrapper">
				{children}
			</section>
		</main>
	);
}
