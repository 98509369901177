
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useAlunoService } from '../../../services/aluno.service';
import { useNotificationService } from '../../../services/notification.service';

export const useUpdateAluno = (aluno, setAluno) => {
    const { setIsGlobalLoading } = useGlobalContext();
    const alunoService = useAlunoService();
    const notification = useNotificationService();
    const history = useHistory();

    const updateAluno = async () => {
        setIsGlobalLoading(true);
        try {
            aluno.pessoa.pessoaResponsaveis.forEach((resp) => {
                const contatos = resp.responsavel.contatos.filter((cont) => cont.descricao && cont.descricao.trim() > ' ');
                resp.responsavel.contatos = contatos;
            });

            const { data } = await alunoService.patch(aluno);
            if (data) {
                const { data: alunoData } = await alunoService.findCustomById(data.id);
                setAluno(alunoData);
                history.push('/alunos/detalhe');
                notification({
                    description: 'Dados do aluno alterados!',
                    type: 'success',
                    message: 'Sucesso!',
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsGlobalLoading(false);
        }
    };

    return { updateAluno };
};
