import * as Unicons from '@iconscout/react-unicons';
import { Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { IEstadoCleanDTO, IPaisCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useLogradouroService } from '../../../services/logradouro.service';
import { useNotificationService } from '../../../services/notification.service';
import EscolaGruposFormActionComponent from '../components/escola-grupos-form-action-component/escola-grupos-form-action.component';
import EscolaGruposFormFecharComponent from '../components/escola-grupos-form-fechar-component/escola-grupos-form-fechar.component';
import MenuLateralCreateComponent from '../components/menu-lateral-create-component/menu-lateral-create.component';
import '../styles/escola-grupo-create-step.styles.scss';
import { useEstadoService } from '../../../services/estado.service';
import { usePaisService } from '../../../services/pais.service';


export const GrupoCreateStep2Screen: React.FunctionComponent = () => {
  const { unidade, setUnidade, revisao } = useUnidadeContext();
  const { grupoParaCadastrar, setGrupoParaCadastrar, flRevisao, setFlRevisao } = useEscolaContext();
  const { setIsGlobalLoading } = useGlobalContext();

  const [paisList, setPaisList] = useState<IPaisCleanDTO[]>();
  const [listEstado, setListEstado] = useState<IEstadoCleanDTO[]>();

  const notification = useNotificationService();

  const history = useHistory();
  const [form] = Form.useForm();
  const paisService = usePaisService();
  const estadoService = useEstadoService();
  const logradouroService = useLogradouroService();

  const required = true;

  const findPais = () => {
    const predicate = new Predicate();
    return paisService.findCleanList(predicate)
      .then(({ data }) => {
        setPaisList(data);
      })
  }

  const findEstados = () => {
    const predicateEstado = new Predicate(100);
    return estadoService.findClean(predicateEstado).then(({ data }) => {
      setListEstado(data.content);
    })
  }

  useEffect(() => {
    const { endereco } = grupoParaCadastrar;
    if (endereco) {
      form.setFieldsValue(endereco);
    }
  }, [form, grupoParaCadastrar]);

  useEffect(() => {
    setIsGlobalLoading(true);
    Promise.all([
      findPais(),
      findEstados(),
    ]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnidade = (franquiaCrud) => {
    franquiaCrud['uf'] = listEstado.find((estado) => estado.nome == franquiaCrud['nomeUF'])?.codigo;
    const contextState = { ...grupoParaCadastrar, endereco: franquiaCrud };
    setGrupoParaCadastrar(contextState);

    if (flRevisao) {
      setFlRevisao(false);
      return history.replace("/escolas/grupos/create/step-4");
    }

    history.push("/escolas/grupos/create/step-3");
  };

  const onCepChange = (cep: string) => {
    if (cep.length !== 8) {
      return;
    }
    setIsGlobalLoading(true);
    const predicateLogradouro = new Predicate(10);
    predicateLogradouro.addOption('cep', cep);
    logradouroService.findCustom(predicateLogradouro)
      .then(({ data }) => {
        if (data && data.length > 0) {
          const logradouro = data[0];
          const { endereco } = unidade;
          endereco.cep = logradouro.cep;
          endereco.bairro = logradouro.bairroNome;
          endereco.cidade = logradouro.cidadeNome;
          endereco.logradouro = logradouro.nomeCompleto;
          endereco.nomeUF = logradouro.cidadeEstadoNome;
          endereco.paisId = logradouro.cidadeEstadoPaisId;

          form.setFieldsValue(endereco);
        } else {
          notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
        }
      })
      .finally(() => setIsGlobalLoading(false));
  }


  return (
    <div className="container">
      <Header />
      <Progress />
      <MenuLateralCreateComponent current={1} />
      <div className="franquia" style={{ marginTop: 70 }}>
        <Form
          form={form}
          name="control-hooks"
          onFinish={handleUnidade}
        >
          <div className="franquia-row">
            <h5>Onde fica o Grupo mantenedor/Rede educacional?</h5>
            <Form.Item name="id" />
            <div className="input-franquia">
              <span>CEP</span>
              <Form.Item name="cep" rules={[{ required: required, message: 'Insira o CEP' }]}>
                <Input
                  id="cep"
                  onChange={e => onCepChange(e.target.value)}
                  className="input"
                  maxLength={8}
                />
              </Form.Item>
            </div>
            <div className="select-franquia">
              <span>País</span>
              <Form.Item name="paisId" rules={[{ required: required, message: 'Selecione um País' }]}>
                <Select
                  id="pais"
                  placeholder="Selecione um País"
                  allowClear
                >
                  {paisList && paisList.map((item, index) => (
                    <Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="box-cidade">
              <div className="select-franquia">
                <span>Estado</span>
                <Form.Item name="nomeUF" rules={[{ required: required, message: 'Selecione um Estado' }]}>
                  <Select
                    id="estado"
                    placeholder="Selecione um Estado"
                    allowClear
                  >
                    {listEstado && listEstado.map((item) => (
                      <Select.Option key={item.id} value={item.nome}>{item.nome}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="select-franquia">
                <span>Cidade</span>
                <Form.Item name="cidade" rules={[{ required: required, message: 'Selecione uma Cidade' }]}>
                  <Input
                    id="cidade"
                    className="input"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="box-complemento">
              <div className="input-franquia">
                <span>Logradouro</span>
                <Form.Item name="logradouro" rules={[{ required: required, message: 'Insira o Logradouro' }]}>
                  <Input
                    id="logradouro"
                    className="input"
                  />
                </Form.Item>
              </div>
              <div className="input-franquia" style={{ width: "17%" }}>
                <span>Número</span>
                <Form.Item name="numero" rules={[{ required: required, message: 'Insira o Número' }]}>
                  <Input
                    id="numero"
                    className="input"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="input-franquia">
              <span>Complemento</span>
              <Form.Item name="complemento">
                <Input
                  id="complemento"
                  className="input"
                />
              </Form.Item>
            </div>

            <EscolaGruposFormActionComponent revisao={flRevisao} rotaAnterior={'/escolas/grupos/create/step-1'} />
          </div>
        </Form>
      </div>
      <EscolaGruposFormFecharComponent />
    </div>
  )
}