import http from '../helpers/http-common';
import {
	IUnidadeAtuacaoCleanDTO,
	IUnidadeAtuacaoDTO,
	IUnidadeAtuacaoFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useUnidadeAtuacaoService = () => {
	const resourceURL = 'unidade-atuacao';

	return {
		...baseFindList<IUnidadeAtuacaoCleanDTO, IUnidadeAtuacaoDTO, IUnidadeAtuacaoFullDTO>(
			resourceURL
		),
	};
};
