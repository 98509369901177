import { CloseOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, notification, Progress, Spin, Steps } from 'antd';
import 'antd/dist/antd.css';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { useHistory } from 'react-router';
import fondo from '../../assets/img/matriculas/fondo.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import ModalCreateAluno from '../../components/ModalCreateAluno/index';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ContratoEnum } from '../../enum/contrato.enum';
import { useMatriculaService } from '../../services/matricula.service';
import { useNotificationService } from '../../services/notification.service';
import { MatriculaProgresso } from './progresso';

export const MatriculasCreateStep5: React.FunctionComponent = () => {

	const { Step } = Steps;
	const [isOpen, setIsOpen] = useState(false);
	const [contratoState, setContratoState] = useState<ContratoEnum>(ContratoEnum.EMITIR);

	const history = useHistory();
	const { matricula, removeMatricula } = useAlunoContext();
	const matriculaService = useMatriculaService();
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();

	const emitirContrato = () => {
		setContratoState(ContratoEnum.EMITINDO);
		setTimeout(() => {
			setContratoState(ContratoEnum.BAIXAR);
		}, 2000);
	}

	const baixarContrato = () => {
		setIsGlobalLoading(true)
		matriculaService.baixarContrato(matricula.id)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				FileSaver.saveAs(blob, `contrato.pdf`);
			}).finally(() => setIsGlobalLoading(false));
	}

	const enviarContrato = () => {
		setIsGlobalLoading(true)
		matriculaService.baixarContrato(matricula.id)
			.then((response) => {
				const blob = new Blob([response.data], {
					type: 'application/x-pdf',
				});
				matriculaService.enviarContratoAssinatura(matricula.id, blob).then(() =>
					notification({ message: "Sucesso!", description: "E-mail enviado ao responsável!", type: "success" }))
					.finally(() => setIsGlobalLoading(false));
			})
			.catch(() => notification({ message: "Erro!", description: "Não foi possível enviar", type: "error" }))
	}

	const finish = () => {
		setIsOpen(false);
		removeMatricula()
		history.push('/alunos/detalhe')
	}

	return (
		<div className="container-fondo">
			<Header />
			<MatriculaProgresso step={5} percent={80} />
			<div className="franquia">				
				<div className="user-row" id="row-aluno">
					<h3>Tudo pronto, emita o contrato para imprimir!</h3>
					<div className="matriculas">
						{contratoState === ContratoEnum.EMITIR ? (
							<div onClick={() => emitirContrato()} className="box-contrato">
								<Unicons.UilFileAlt size="20" color="var(--primary-color)" />
								<h4>Emitir Contrato</h4>
							</div>
						) : null}
						{contratoState === ContratoEnum.EMITINDO ? (
							<div className="box-contrato">
								<Spin />
								<h4>Emitindo Contrato...</h4>
							</div>
						) : null}
						{contratoState === ContratoEnum.BAIXAR ? (
							<div onClick={() => baixarContrato()} className="box-contrato-selected">
								<Unicons.UilDownloadAlt size="20" color="#FFFFFF" />
								<h4>Baixar Contrato</h4>
							</div>
						) : null}
					</div>
					<div className="matriculas">
						<div onClick={() => enviarContrato()} className="box-contrato-selected">
							<Unicons.UilFileAlt size="20" color="#FFFFFF" />
							<h4>Enviar para assinatura digital</h4>
						</div>
					</div>

					{/* <div className="botoes">
            <Link to="/matriculas/create/step-4">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <Button className="button-primary" onClick={() => setIsOpen(true)} style={{ width: 345 }}>Próximo</Button>
          </div> */}
				</div>
			</div>
			<Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title='Você deseja cancelar a adição desta Nova Matricula?'
				subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
				textButton1='Não'
				textButton2='Sim, Cancelar'
				styleButton1='button-line'
				styleButton2='button-danger'
				link2='/alunos'
			/>
			<ModalCreateAluno
				open={isOpen}
				onClose={finish}
				title="Matricula"
				textButton1="Voltar para tela inicial"
				textButton2="Nova matricula"
				styleButton1="button-line"
				styleButton2="button-primary"
				link1="/alunos"
				link2="/alunos/detalhe"
			/>
			<img src={fondo} alt="" className="img-fondo" />
		</div >
	);
}