/* eslint-disable react-hooks/rules-of-hooks */
import { CloseOutlined } from "@mui/icons-material";
import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../../../../components/Header";
import Modal from '../../../../components/Modal';
import { useGlobalContext } from "../../../../context/GlobalContext";
import { ICursoAulaPadraoDTO } from "../../../../models/happy-code-api.model";
import { useAcademyAulaPadraoService } from "../../../../services/academy-aula-padrao";
import { useNotificationService } from "../../../../services/notification.service";


const aulaProdutoEscolaStep1Create: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const aulaService = useAcademyAulaPadraoService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [aulaPadrao, setAulaPadrao] = useState<ICursoAulaPadraoDTO>();
    const { idProdutoEscola, idAula } = useParams<any>()

    const findAulas = () => {
        setIsGlobalLoading(true);
        aulaService.findById({ id: idAula })
            .then((response) => {
                setAulaPadrao(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const createAula = () => {
        setIsGlobalLoading(true);
        aulaService.create({ ...aulaPadrao, produto: { id: idProdutoEscola } })
            .then(() => {
                history.push(`/academy/gestao-material/produto-escolas/${idProdutoEscola}`);
                notification({
                    description: `Aula criada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível cadastrar a aula.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const patchAula = () => {
        setIsGlobalLoading(true);
        aulaService.patch(aulaPadrao)
            .then(() => {
                history.push(`/academy/gestao-material/produto-escolas/${idProdutoEscola}`);
                notification({
                    description: `Aula editada com sucesso.`,
                    type: 'success',
                    message: 'Sucesso!',
                });
            }).catch(() => {
                notification({
                    description: `Desculpe, não foi possível editar a aula.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }).finally(() => setIsGlobalLoading(false));
    }

    const sendRequest = () => {
        if (!idAula) {
            createAula();
        } else {
            patchAula();
        }
    }

    useEffect(() => {
        if (idAula) {
            findAulas();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idAula])

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ left: 0 }}>
                <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined />} />
                <div className="user-row">
                    <h3 id="taxas-h3">Insira a descrição da aula</h3>
                    <div className="input-box">
                        <p>Descrição</p>
                        <Input
                            defaultValue={aulaPadrao?.descricao}
                            value={aulaPadrao?.descricao}
                            onChange={(e) => setAulaPadrao({ ...aulaPadrao, descricao: e.target.value })} />
                    </div>

                    <div className="botoes">
                        <Link to={`/academy/gestao-material/produto-escolas/${idProdutoEscola}`}>
                            <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                        </Link>
                        <Button
                            style={{ width: 345 }}
                            type="primary"
                            id="proximo"
                            className="button-primary"
                            onClick={sendRequest}>Salvar</Button>
                    </div>
                </div>
            </div>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a operação?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2={`/academy/gestao-material/produto-escolas/${idProdutoEscola}`}
            />
        </div>
    )
}

export default aulaProdutoEscolaStep1Create;
