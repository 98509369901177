import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tags } from '../../components/Tags';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICursoDTO } from "../../models/happy-code-api.model";
import { useCursoService } from '../../services/curso.service';
import { useNotificationService } from '../../services/notification.service';

interface TItemLista {
    curso: ICursoDTO;
}

const ItemListCurso = memo(({ curso }: TItemLista) => {
    const [ativo, setAtivo] = useState<boolean>();

    const notification = useNotificationService();
    const history = useHistory();
    const cursoService = useCursoService();
    const { setIsGlobalLoading } = useGlobalContext();

    const { setCurso } = useCursoContext();

    const goTo = () => {
        setIsGlobalLoading(true);
        cursoService.findFullById(curso)
            .then(({ data }) => setCurso(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/cursos/create/step-6`)
            });
    }

    useEffect(() => {
        setAtivo(curso.ativo);
    }, [curso.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} o curso?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: curso.descricao,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                cursoService.patch({ id: curso.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Curso alterado com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
            // onCancel: () => { },
        });
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar Curso
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <div className="nome">
                    <p>{curso.descricao}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "10%" }}>
                <span>{curso.id}</span>
            </div>
            <div className="funcao-user" id="cursos-modalidade" style={{ width: "13%" }}>
                <Tags tipo={curso.modalidade.descricao} />
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "8%" }}>
                <p>{curso.tipoCurso.descricao}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "14%" }}>
                <p>{curso.negocio.descricao}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "10%" }}>
                <p>{curso.duracao}</p>
            </div>
            <div className="funcao-user" id="cursos-modalidade" style={{ width: "10%" }}>
                <p>{curso.duracaoMinutos}</p>
            </div>
            <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListCurso.displayName = 'ItemListCurso';


export default ItemListCurso;
