import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEscolaContext } from '../../../context/EscolaContext';
import { Predicate } from '../../../models/predicate.model';
import { useGrupoContratoService } from '../../../services/grupo-contrato.service';

interface Param {
	idGrupo?: string;
}

const useGrupoContratoListagemScreenState = () => {
	const { escolas, estados, grupos, setContratoParaCadastrar, setFlRevisao } =
		useEscolaContext();
	const [descricao, setDescricao] = useState();
	const [listStatusUnidade, setListStatusUnidade] = useState<any[]>();
	const history = useHistory();
	const contratoService = useGrupoContratoService();
	const { idGrupo } = useParams<Param>();

	const onCriarContratoPress = (idGrupo: string) => {
		return history.push(`/escolas/grupos/${idGrupo}/contratos/create/step-1`);
	};

	const fetchContratos = (idGrupo: string) => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('grupo.id', idGrupo);
		return contratoService.findFull(predicate);
	};

	useEffect(() => {
		if (idGrupo) {
			setContratoParaCadastrar(null);
			setFlRevisao(false);
			fetchContratos(idGrupo);
		}
	}, [idGrupo]);

	return {
		onCriarContratoPress,
		escolas,
		estados,
		setDescricao,
		listStatusUnidade,
		descricao,
		grupos,
		idGrupo,
		setContratoParaCadastrar,
	};
};

export default useGrupoContratoListagemScreenState;
