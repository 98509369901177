import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu } from 'antd';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useNegocioContext } from '../../context/NegocioContext';
import { IHoraSedeDTO, IHoraSedeFullDTO } from "../../models/happy-code-api.model";
import { useNegocioService } from '../../services/negocio.service';
import { useHoraSedeService } from '../../services/hora-sede.service';
import { useHoraSedeContext } from '../../context/HoraSedeContext';
import MsgInfoSistema from '../../components/MsgInfoSistema';
import info from '../../assets/img/financeiro-info/info.svg';

interface TItemLista {
    hora: IHoraSedeFullDTO;
    handleCampanha: (hora: IHoraSedeFullDTO, state: boolean) => void;
}

const ItemListHoraSede = memo(({ hora, handleCampanha }: TItemLista) => {

    const negocioService = useNegocioService();
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const { setNegocio } = useNegocioContext();
    const { horaSede, setHoraSede, revisao } = useHoraSedeContext();
    const horaSedeService = useHoraSedeService();
    const [hideDisplay, setHideDisplay] = useState<boolean>(false);

    const handleMenuItem = (hora: IHoraSedeFullDTO) => {
        if (hora.ativo) {
            return (
                <Menu.Item onClick={() => handleCampanha(hora, false)}>
                    <a rel="noopener noreferrer">
                        Inativar hora
                    </a>
                </Menu.Item>
            )
        }
        if (!hora.ativo) {
            return (
                <Menu.Item onClick={() => handleCampanha(hora, true)}>
                    <a rel="noopener noreferrer">
                        Ativar hora
                    </a>
                </Menu.Item>
            )
        }
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            {handleMenuItem(hora)}
        </Menu>
    );
    const history = useHistory();

    const goTo = () => {
        setIsGlobalLoading(true);
        horaSedeService.findFullById(hora)
            .then(({ data }) => setHoraSede(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/hora/create/step-6`)
            });
    }

    const handleStatus = (hora) => {

        if (hora.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Ativo</p>
                </div>
            )
        }

        if (!hora.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className="standby">Inativo</p>
                </div>
            )
        }
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{hora.id}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span onClick={(e) => setHideDisplay(!hideDisplay)}>Unidades <img className="config-icons-financeiro" src={info} onClick={(e) => setHideDisplay(true)} /></span>
                {hideDisplay && (
                    <MsgInfoSistema
                        setHideDisplay={setHideDisplay}
                        hideDisplay={!hideDisplay}
                        listTitle='Lista de unidades'
                        listItem={hora?.horaUnidades?.map(unidade => unidade?.unidadeDescricao)}
                    />
                )}
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{hora.splitUmHoraEMeia}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{hora.splitTresHoras}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{hora.splitQuatroHorasEMeia}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
                <span>{hora.splitSeisHoras}</span>
            </div>

            <div className="funcao-user" id="cursos-id" style={{ width: '15%' }}>
                <span>{hora.splitUmHoraEMeiaCapital}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '15%' }}>
                <span>{hora.splitTresHorasCapital}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '15%' }}>
                <span>{hora.splitQuatroHorasEMeiaCapital}</span>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '15%' }}>
                <span>{hora.splitSeisHorasCapital}</span>
            </div>
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListHoraSede.displayName = 'ItemListHoraSede';

export default ItemListHoraSede;