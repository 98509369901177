import {
	IStatusLeadEscolaCleanDTO,
	IStatusLeadEscolaDTO,
	IStatusLeadEscolaFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEscolaStatusService = () => {
	const resourceURL = 'status-lead-escola';

	return {
		...baseFindList<IStatusLeadEscolaCleanDTO, IStatusLeadEscolaDTO, IStatusLeadEscolaFullDTO>(resourceURL),
	};
};
