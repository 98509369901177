import { Button, Checkbox, Col, Form, InputNumber, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICobrancaCustomDTO, IEstornoDTO } from '../../models/happy-code-api.model';
import { useCobrancaService } from '../../services/cobranca.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney, formattedDate } from '../../util/format';
import { TagsFinanceiro } from '../TagsFinanceiro';
import './style.css';
interface Text {
    open: boolean;
    onClose: () => void;
    cobranca: ICobrancaCustomDTO;
}

export const ModalEstorno: React.FunctionComponent<Text> = ({ open, onClose, cobranca }: Text) => {

    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();
    const cobrancaService = useCobrancaService();
    const [isOpenAndEstorno, setIsOpenAndEstorno] = useState(false);
    const [cobrancaEstornoParcial, setCobrancaEstornoParcial] = useState<IEstornoDTO>();
    const [checkboxEstornoParcial, setCheckboxEstornoParcial] = useState(false);
    const [checkboxNovaCobranca, setCheckboxNovaCobranca] = useState(false);

    function restartCobranca() {
        setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, idCobranca: null });
        setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, amount: null });
        setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, motivo: null });
        setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, gerarNovaCobranca: null });
        setCheckboxEstornoParcial(false);
        setCheckboxNovaCobranca(false);
    }

    const closeModal = () => {
        setIsOpenAndEstorno(false);
    }

    const fecharModais = () => {
        restartCobranca();
        closeModal();
        onClose();
    }

    function checkCobrancaParcial() {
        setCheckboxEstornoParcial(!checkboxEstornoParcial);
        setCobrancaEstornoParcial({
            ...cobrancaEstornoParcial,
            gerarNovaCobranca: null,
            amount: null,
        });
    }

    function checkGeraCobranca() {
        setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, amount: null });
        if (!checkboxNovaCobranca) {
            setCheckboxNovaCobranca(true);
            setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, gerarNovaCobranca: true });
        } else {
            setCheckboxNovaCobranca(false);
            setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, gerarNovaCobranca: false });
        }
    }

    const estornaCobranca = () => {
        setIsGlobalLoading(true);
        cobrancaService.estornarCobranca(cobrancaEstornoParcial)
            .then(() => {
                fecharModais();
                notification({
                    description: `Estorno ${checkboxEstornoParcial ? "parcial" : "total"} no valor de ${checkboxEstornoParcial ? formatMoney(cobrancaEstornoParcial?.amount) : formatMoney(cobranca.valorCobranca)}, o pagamento será atualizado em instantes; por favor, recarregue a página.`,
                    type: 'success',
                    message: `Estorno efetuado com sucesso!`,
                });

            })
            .catch()
            .finally(() => {
                setIsGlobalLoading(false);
            });
    }

    function disabledButton() {
        if (!checkboxEstornoParcial && cobrancaEstornoParcial?.motivo) {
            return false;
        }
        if (!cobrancaEstornoParcial?.motivo) {
            return true;
        }
        if (checkboxEstornoParcial && cobrancaEstornoParcial?.amount <= parseFloat(0.01.toString())) {
            return true;
        }
    }

    useEffect(() => {
        restartCobranca();
        if (cobranca) {
            setCobrancaEstornoParcial({
                ...cobrancaEstornoParcial,
                idCobranca: cobranca.id,
                amount: null,
                motivo: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [cobranca]);

    if (!open) return null

    return (
        <div className="modalContainerFinanceiro-estorno">
            <div className="modal-user-financeiro-estorno">
                <div className="body">
                    <h3 style={{ textAlign: 'center' }}>Confirmar estorno da cobrança?</h3>

                    <div className="box-tabela" style={{ marginTop: 20 }}>
                        <div className="tabela">
                            <Row className="titles-users">
                                <Col className="item gutter-row" span={6}>
                                    <span>VENCIMENTO</span>
                                </Col>
                                <Col className="item gutter-row" span={6}>
                                    <span>VALOR</span>
                                </Col>
                                <Col className="item gutter-row" span={6}>
                                    <span>PARCELA</span>
                                </Col>
                                <Col className="item gutter-row" span={6}>
                                    <span>STATUS</span>
                                </Col>
                            </Row>

                            <Row className="registro-user">
                                <Col className="funcao-user gutter-row" span={6}>
                                    <p>{formattedDate(cobranca?.dataVencimento)}</p>
                                </Col>
                                <Col className="funcao-user gutter-row" span={6}>
                                    <p>{formatMoney(cobranca?.valorCobranca)}</p>
                                </Col>
                                <Col className="funcao-user gutter-row" span={6}>
                                    <p>{cobranca.financeiro.cobrancaUnica ? cobranca.financeiro.quantidadeParcelas : cobranca.parcela}</p>
                                </Col>
                                <Col className="funcao-user gutter-row" span={6}>
                                    <TagsFinanceiro codigo={cobranca?.statusCobranca?.codigo} descricao={cobranca?.statusCobranca?.descricao}></TagsFinanceiro>
                                </Col>
                            </Row>

                            <Form>
                                <Row className="registro-user">
                                    <Col>
                                        <div className="check-radios">
                                            <div id="check-estorno-parcial">
                                                <Form.Item
                                                    style={{ display: "inline-block", margin: "auto !important" }}
                                                    label="Estorno parcial"
                                                    name={'check-box-estorno'}>
                                                    <Checkbox
                                                        id="check-box-estorno"
                                                        disabled={checkboxNovaCobranca == true ? true : false}
                                                        checked={checkboxEstornoParcial}
                                                        onChange={checkCobrancaParcial}
                                                    />
                                                </Form.Item>
                                                {checkboxEstornoParcial ?
                                                    <>
                                                        <Form.Item
                                                            style={{ display: "inline-block", marginLeft: "10px" }}
                                                            label="Informar valor"
                                                            name={'estorno-amount'}
                                                            rules={[{ required: true, message: 'Informe o valor do estorno' }]} >

                                                            <InputNumber
                                                                decimalSeparator=","
                                                                id="estorno-amount"
                                                                className="campo-input-amount"
                                                                min={0}
                                                                max={cobranca?.valorCobranca}
                                                                required
                                                                defaultValue={null}
                                                                onChange={(e) => setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, amount: e })}
                                                                value={parseFloat(cobrancaEstornoParcial?.amount?.toFixed(3)?.replace(/(\d)(?=(\d{7})+(?!\d))/g, '$.'))} />
                                                        </Form.Item>
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="valor" style={{ marginTop: 20 }}>
                                    <Form.Item
                                        style={{ display: "inline-block" }}
                                        label="Informe motivo do estorno"
                                        name={'estorno-motivo'}
                                        rules={[{ required: true, message: 'Informe motivo do estorno' }]} >

                                        <TextArea id="estorno-motivo" className="input"
                                            rows={1}
                                            cols={100}
                                            maxLength={500}
                                            required
                                            placeholder="Descreva o motivo do estorno"
                                            onChange={(e) => setCobrancaEstornoParcial({ ...cobrancaEstornoParcial, motivo: e.target.value })} />
                                    </Form.Item>
                                </div>
                            </Form>

                            <div className="line"></div>
                            <div className="valor" style={{ marginTop: 20 }}>
                                <h6>Valor do estorno</h6>
                                <p>{checkboxEstornoParcial ? formatMoney(cobrancaEstornoParcial?.amount) : formatMoney(cobranca?.valorPago)}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="line"></div>
                <div className="botoes">
                    <Button onClick={onClose} style={{ minWidth: 145 }} className="estornar-button-second">Voltar</Button>

                    <Button
                        className="estornar-button-danger"
                        style={{ marginLeft: 8, minWidth: 145 }}
                        onClick={estornaCobranca}
                        disabled={disabledButton()}>Estornar valor</Button>
                </div>
            </div>
            <ModalEstorno
                open={isOpenAndEstorno}
                onClose={closeModal}
                cobranca={cobranca}
            />
        </div >
    );
}