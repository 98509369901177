import { TabSelectedEnum } from '../../enum/home.enum';
import { IUnidadeListHomeCustomDTO } from '../../models/happy-code-api.model';
import ListInadimplencia from '../../pages/Dashboard/listInadimplencia';
import ListLead from '../../pages/Dashboard/listLead';
import ListRematricula from '../../pages/Dashboard/listRematricula';
import { useLogService } from '../../services/log.service';

interface Props {
    topListasHome: IUnidadeListHomeCustomDTO;
    setTabSelected: React.Dispatch<React.SetStateAction<TabSelectedEnum>>;
    tabSelected: TabSelectedEnum;
}

const HomeListTop: React.FC<Props> = ({ setTabSelected, tabSelected, topListasHome }: Props) => {
    const logService = useLogService();

    const changeList = (value: TabSelectedEnum) => {
        setTabSelected(value);
        if (value === TabSelectedEnum.lead) {
            logService.registraLog({
                descricaoEvento: 'Acesso top Leads dash franquia',
                descricaoTipo: 'Acesso top leads',
                idTipo: null,
                id: null,
                descricaoUsuario: null,
                ativo: true,
            });
        }
        if (value === TabSelectedEnum.rematricula) {
            logService.registraLog({
                descricaoEvento: 'Acesso top rematriculas dash franquia',
                descricaoTipo: 'Acesso top rematriculas',
                idTipo: null,
                id: null,
                descricaoUsuario: null,
                ativo: true,
            });
        }
        if (value === TabSelectedEnum.inadimplente) {
            logService.registraLog({
                descricaoEvento: 'Acesso top devedores dash franquia',
                descricaoTipo: 'Acesso top devedores',
                idTipo: null,
                id: null,
                descricaoUsuario: null,
                ativo: true,
            });
        }
    };

    return (
        <div className="listagem row-revisao" style={{ overflow: 'hidden' }}>
            <ul className="menu-revisao-detalhe">
                <li
                    style={{ width: '50%', cursor: 'pointer' }}
                    onClick={() => changeList(TabSelectedEnum.lead)}
                    className={
                        tabSelected === TabSelectedEnum.lead
                            ? 'list-home-selected-lead'
                            : ''
                    }
                >
                    Top Leads
                </li>

                <li
                    style={{ width: '50%', cursor: 'pointer' }}
                    onClick={() => changeList(TabSelectedEnum.rematricula)}
                    className={
                        tabSelected === TabSelectedEnum.rematricula
                            ? 'list-home-selected-rematricula'
                            : ''
                    }
                >
                    Top Rematrículas
                </li>

                <li
                    style={{ width: '50%', cursor: 'pointer' }}
                    onClick={() => changeList(TabSelectedEnum.inadimplente)}
                    className={
                        tabSelected === TabSelectedEnum.inadimplente
                            ? 'list-home-selected-inadimplente'
                            : ''
                    }
                >
                    Top Devedores
                </li>
            </ul>

            <div className="title" style={{ marginBottom: 0, height: 50 }}>
                {tabSelected === TabSelectedEnum.lead && (
                    <>
                        <div className="tabela">
                            <h4 style={{ marginBottom: 10 }}>
                                Leads mais recentes
                            </h4>
                            <div className="titles-users" style={{ height: 50 }}>
                                <div className="item" style={{ width: '25%' }}>
                                    <span>NOME</span>
                                </div>
                                <div className="item" style={{ width: '25%' }}>
                                    <span>CELULAR</span>
                                </div>
                                <div className="item" style={{ width: '25%' }}>
                                    <span>ESTÁGIO</span>
                                </div>
                                <div className="item" style={{ width: '25%' }}>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {tabSelected === TabSelectedEnum.rematricula && (
                    <>
                        <div className="tabela">
                            <h4 style={{ marginBottom: 10 }}>
                                Alunos mais recentes para rematrícula
                            </h4>
                            <div className="titles-users" style={{ height: 50 }}>
                                <div className="item" style={{ width: '20%' }}>
                                    <span>NOME</span>
                                </div>
                                <div className="item" style={{ width: '20%' }}>
                                    <span>MATRÍCULA</span>
                                </div>
                                <div className="item" style={{ width: '20%' }}>
                                    <span>VENCIMENTO DO CONTRATO</span>
                                </div>
                                <div className="item" style={{ width: '20%' }}>
                                    <span>VENCIMENTO EM DIAS</span>
                                </div>
                                <div className="item" style={{ width: '20%' }}>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {tabSelected === TabSelectedEnum.inadimplente && (
                    <>
                        <div className="tabela">
                            <h4 style={{ marginBottom: 10 }}>
                                Cobranças em atraso
                            </h4>
                            <div className="titles-users" style={{ height: 50 }}>
                                <div className="item" style={{ width: '25%' }}>
                                    <span>NOME</span>
                                </div>
                                <div className="item" style={{ width: '25%' }}>
                                    <span>PARCELAS ABERTA</span>
                                </div>
                                <div className="item" style={{ width: '25%' }}>
                                    <span>VALOR COBRANÇA</span>
                                </div>
                                <div className="item" style={{ width: '25%' }}>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div
                className="tabela"
                style={{ overflowY: 'scroll', marginTop: 20 }}
            >
                {tabSelected === TabSelectedEnum.lead &&
                    topListasHome?.leads &&
                    topListasHome?.leads.map((lead) => (
                        <ListLead key={lead.leadId} lead={lead} />
                    ))}

                {tabSelected === TabSelectedEnum.rematricula &&
                    topListasHome?.rematriculas &&
                    topListasHome?.rematriculas.map((rematricula) => (
                        <ListRematricula
                            key={rematricula.alunoId}
                            rematricula={rematricula}
                        />
                    ))}
                {tabSelected === TabSelectedEnum.inadimplente &&
                    topListasHome?.inadimplentes &&
                    topListasHome?.inadimplentes.map((inadimplente) => (
                        <ListInadimplencia
                            key={inadimplente.alunoId}
                            inadimplente={inadimplente}
                        />
                    ))}
            </div>
        </div>
    );
};

export default HomeListTop;
