import { Form, Select } from "antd";
import { useContext, useEffect, useState } from 'react';
import { useGlobalContext } from "../../../../../../context/GlobalContext";
import { useUnidadeContext } from "../../../../../../context/UnidadeContext";
import { Predicate } from "../../../../../../models/predicate.model";
import { useEstadoService } from "../../../../../../services/estado.service";
import { useLeadCampanhaService } from '../../../../../../services/lead-campanha.service';
import { useLeadEstagioService } from "../../../../../../services/lead-estagio.service";
import { useLeadOrigemService } from "../../../../../../services/lead-origem.service";
import { useLeadStatusService } from '../../../../../../services/lead-status.service';
import { useLeadTipoService } from "../../../../../../services/lead-tipo.service";
import { useLeadService } from '../../../../../../services/lead.service';
import { useLogradouroService } from "../../../../../../services/logradouro.service";
import { useNotificationService } from "../../../../../../services/notification.service";
import { usePaisService } from "../../../../../../services/pais.service";
import { useUnidadeService } from "../../../../../../services/unidade.service";
import { CrmContext } from "../../../../context/crm.context";

const useLeadNewState = (props) => {

  const { open, onClose } = props;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [context] = useContext(CrmContext);

  const [vlLeadInteresse, setVlLeadInteresse] = useState(0);
  const [interesses, setInteresses] = useState([]);
  const [cursoInteresse, setCursoInteresse] = useState();
  const { unidade: unidadeUsuarioLogado } = useGlobalContext();

  const [state, setState] = useState({
    campanhas: [],
    leadStatus: [],
    leadEstagios: [],
    paises: [],
    estados: [],
    cidades: [],
    leadOrigens: [],
    leadTipos: [],
    unidades: [],
    cursos: [],
    formControl: {
      dsNomeLead: null,
      cdCpfCnpj: null,
      cdEmail: null,
      cdDddTelefone: null,
      cdTelefone: null,
      cdDddCelular: null,
      cdCelular: null,
      dhProximoRetorno: null,
      nrAvaliacaoLead: null,
      flLeadNovo: null,
      campanha: null,
      status: null,
      estagio: null,
      endereco: {
        pais: {
          id: null,
        },
      }
    }
  })

  useEffect(() => {
    setState((state) => ({ ...state, ...context }));
  }, [context])

  const [isModalSucessoOpen, setIsModalSucessoOpen] = useState(false);
  const [isModalErroOpen, setIsModalErroOpen] = useState(false);

  const leadCampanhaService = useLeadCampanhaService();
  const leadService = useLeadService();
  const leadStatusService = useLeadStatusService();
  const leadEstagioService = useLeadEstagioService();
  const logradouroService = useLogradouroService();
  const paisService = usePaisService();
  const estadoService = useEstadoService();
  const leadTipoService = useLeadTipoService();
  const leadOrigemService = useLeadOrigemService();
  const unidadeService = useUnidadeService();

  const { setIsGlobalLoading } = useGlobalContext();
  const notification = useNotificationService();

  const findEstadoByPais = (idPais) => {
    const predicate = new Predicate();
    predicate.addOption('pais.id', idPais);

    estadoService.findClean(predicate).then((response) => {
      if (response.status === 200) {
        setState((state) => ({ ...state, estados: response?.data?.content }));
      }
    });
  }

  const setCampo = (dsCampo, valor) => {
    const field = {
      [dsCampo]: valor,
    }
    form.setFieldsValue(field);
  }

  const setCampoEndereco = (dsCampo, valor) => {
    setState((state) => {
      const novoFormControl = state.formControl;
      novoFormControl.endereco[dsCampo] = valor;
      return { ...state, formControl: novoFormControl };
    });
  }

  const handleChangeEndereco = (campo, value: any) => {
    setCampoEndereco(campo, {
      id: value,
    })
  }

  const handleChangePais = (e) => {
    handleChangeEndereco('pais', e);
    findEstadoByPais(e);
  }

  const handleChange = (campo, value: any) => {
    setCampo(campo, {
      id: value,
    })
  }

  const post = () => {
    const body = { ...form.getFieldsValue() };
    body.cdCpfCnpj = (body.cdCpfCnpj as string).replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    body.campanha = !!body.campanha.id || body.campanha.nome ? body.campanha : undefined;
    body.interesses = interesses;
    setIsGlobalLoading(true);

    leadService.create(body).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        if (isModalSucessoOpen === true) {
          setIsModalSucessoOpen(false)
        } else {
          setIsModalSucessoOpen(true)
        }
        return;
      }

      if (isModalSucessoOpen === true) {
        setIsModalErroOpen(false)
      } else {
        setIsModalErroOpen(true)
      }

    }).finally(() => {
      setIsGlobalLoading(false);
    })
  }

  const onSubmit = () => {
    const cdCpfCnpj = { ...form.getFieldsValue() }?.cdCpfCnpj;
    if(cdCpfCnpj == null)
    {
      setCampo("cdCpfCnpj", "");
    }

    form
      .validateFields()
      .then(() => {
        return post();
      })
      .catch((errors) => {
        notification({ description: 'Por favor, preencha todos os campos', type: 'warning', message: 'Aviso' });
      });
  }

  const onClose1 = () => {
    setIsModalSucessoOpen(false);
    setIsModalErroOpen(false);
  }

  const onCepChange = (cep: string) => {
    if (cep.length !== 8) {
      return;
    }
    setIsGlobalLoading(true);
    const predicateLogradouro = new Predicate(10);
    predicateLogradouro.addOption('cep', cep);
    logradouroService.findCustom(predicateLogradouro)
      .then(({ data }) => {
        if (data && data.length > 0) {
          const logradouro = data[0];
          // const { endereco } = unidade;

          setState((state) => {
            const paisSelecionado = state.paises
              .find(item => logradouro.cidadeEstadoPaisId === item.id);

            (state.formControl.endereco as any).cep = logradouro.cep;
            (state.formControl.endereco as any).bairro = logradouro.bairroNome;
            (state.formControl.endereco as any).cidade = logradouro.cidadeNome;
            (state.formControl.endereco as any).logradouro = logradouro.nomeCompleto;
            (state.formControl.endereco as any).nomeUF = logradouro.cidadeEstadoNome;
            // (state.formControl.endereco as any).pais.id = paisSelecionado.id;
            (state.formControl.endereco as any).paisId = paisSelecionado.id;

            form.setFieldsValue({
              endereco: state.formControl.endereco,
            });

            findEstadoByPais(paisSelecionado.id);
            return ({ ...state, });
          });

        } else {
          notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' })
        }
      })
      .finally(() => setIsGlobalLoading(false));
  }


  const onChangeValorInteresse = (valor) => {
    setVlLeadInteresse(valor.floatValue);
  }

  const removerInteresse = (curso) => {
    setInteresses((interesses) => {
      const indexInteresseJaAdicionado = interesses.findIndex(interesse => interesse?.curso?.id === curso?.id);
      if (indexInteresseJaAdicionado >= 0) {
        interesses.splice(indexInteresseJaAdicionado, 1);
      }
      return [...interesses];
    })

  }

  const adicionarInteresse = (e) => {
    const curso = context.cursos.find(item => item.id === cursoInteresse);

    setInteresses((interesses) => {
      const indexInteresseJaAdicionado = interesses.findIndex(interesse => interesse?.curso?.id === curso?.id);
      if (indexInteresseJaAdicionado >= 0) {
        interesses.splice(indexInteresseJaAdicionado, 1);
      }

      interesses.push({
        curso,
        vlLeadInteresse,
      })
      return [...interesses,];
    });

    setCursoInteresse(null);
    setVlLeadInteresse(0);
  }

  const selecionarCurso = (e) => {
    setCursoInteresse(e);
  }

  return {
    onClose,
    onClose1,
    onSubmit,
    handleChange,
    handleChangeEndereco,
    handleChangePais,
    open,
    Option,
    isModalSucessoOpen,
    state,
    setCampo,
    isModalErroOpen,
    onCepChange,
    form,
    onChangeValorInteresse,
    removerInteresse,
    interesses,
    adicionarInteresse,
    vlLeadInteresse,
    selecionarCurso,
    cursoInteresse,
    unidadeUsuarioLogado,
  }
}

export default useLeadNewState;
