import { CloseOutlined } from '@ant-design/icons';
import { Button, InputNumber, Progress, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import MsgInfoSistema from '../../components/MsgInfoSistema';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { useCursoService } from '../../services/curso.service';
import { useNotificationService } from '../../services/notification.service';
import { currencyFormatter, currencyParser, formatMoney } from '../../util/format';
import StepsCreate from './steps-create';
import info from '../../assets/img/financeiro-info/info.svg';
import './style.css';

export const CursosCreateStep5: React.FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const parcelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const cursoService = useCursoService();
    const history = useHistory();
    const { curso, revisao, setCurso } = useCursoContext();
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const [hideDisplay, setHideDisplay] = useState(false);

    const patchOferta = () => {
        setIsGlobalLoading(true);
        cursoService.replicarOferta(curso?.id, {
            id: curso?.id,
            valorMensalidadeMinimo: curso.valorMensalidadeMinimo,
            valorSplitMatricula: curso.valorSplitMatricula,
            numeroMaxParcelas: curso.numeroMaxParcelas,
            taxaMatricula: curso.taxaMatricula,
            valorSplitTaxaMatricula: curso.valorSplitTaxaMatricula,
            numeroProrrogacaoDiasMatricula: curso.numeroProrrogacaoDiasMatricula,
            valorMensalidadeMinimoRematricula: curso.valorMensalidadeMinimoRematricula,
            valorSplitRematricula: curso.valorSplitRematricula,
            numeroParcelasMaximoRematricula: curso.numeroParcelasMaximoRematricula,
            taxaRematricula: curso.taxaRematricula,
            valorSplitTaxaRematricula: curso.valorSplitTaxaRematricula,
            numeroProrrogacaoDiasRematricula: curso.numeroProrrogacaoDiasRematricula,
        }).then(() => {
            history.push(`/cursos/create/step-6`);
            notification({
                description: `Oferta alterada com sucesso.`,
                type: 'success',
                message: 'Sucesso!',
            });
        }).catch(() => {
            notification({
                description: `Desculpe, não foi possível alterar oferta.`,
                type: 'error',
                message: 'Erro!',
            });
        }).finally(() => setIsGlobalLoading(false));
    }


    return (
        <div className="container-fondo">
            <Header />
            <div className="progress">
                <Progress className="progress-user" percent={20} strokeLinecap="square" showInfo={false} />
            </div>
            <StepsCreate current={4} />
            <div className="franquia">
                <div className="user-row">
                    <div className="card-form">
                        <h3 id="taxas-h3" style={{ fontWeight: 700 }}>Rematrícula</h3>
                        <h3 id="taxas-h3">1 .Qual será o preço mínimo desse curso?</h3>
                        <div className="input-box">
                            <InputNumber
                                id="valor"
                                style={{ width: '100%' }}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                min={0}
                                defaultValue={curso?.valorMensalidadeMinimoRematricula}
                                value={curso?.valorMensalidadeMinimoRematricula}
                                onChange={(e) => setCurso({ ...curso, valorMensalidadeMinimoRematricula: e })}
                            />
                        </div>

                        <div style={{ display: "flex" }}>
                            <h3 id="taxas-h3">2. Preencha o valor do split.</h3>
                            <img className="config-icons-financeiro" src={info} onClick={(e) => setHideDisplay(true)} />
                            {hideDisplay && (
                                <MsgInfoSistema
                                    setHideDisplay={setHideDisplay}
                                    hideDisplay={false}
                                    icon={info}
                                    title={"Valor Split"}
                                    descricaoOne={"O valor inserido no campo valor do split não pode ser superior ao preço mínimo da rematrícula."}
                                    observacao={`Preço mínimo informado: ${formatMoney(curso?.valorMensalidadeMinimoRematricula)}`}
                                />
                            )}
                        </div>

                        <div className="input-box" style={{ width: "100%", marginBottom: 10 }}>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={curso?.valorMensalidadeMinimoRematricula}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                decimalSeparator=","
                                defaultValue={curso?.valorSplitRematricula}
                                value={curso?.valorSplitRematricula}
                                onChange={(e) => setCurso({ ...curso, valorSplitRematricula: e })}
                            />
                        </div>

                        <h3 id="taxas-h3">3. Qual será o máximo de parcelas?</h3>
                        <div className="input-box" style={{ width: "100%" }}>
                            <Select
                                id="valor"
                                style={{ background: 'white', width: '100%', marginBottom: 5 }}
                                defaultValue={curso?.numeroParcelasMaximoRematricula}
                                value={curso?.numeroParcelasMaximoRematricula}
                                onChange={(e) => setCurso({ ...curso, numeroParcelasMaximoRematricula: e })}>
                                {parcelas.map((parcela, index) =>
                                    <Select.Option key={index} value={parcela}>{parcela}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <h3 id="taxas-h3">4. Deseja utilizar a taxa de Rematrícula?</h3>
                        <div className="input-box" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "max-content" }}>
                            <span style={{ width: 200 }}>Habilitar taxa: </span>
                            <Radio.Group
                                style={{ display: "flex", justifyContent: "center" }}
                                defaultValue={curso?.taxaRematricula}
                                value={curso?.taxaRematricula}
                                onChange={(e) => setCurso({ ...curso, taxaRematricula: e.target.value })} >
                                <Radio value={false}>Não</Radio>
                                <Radio value={true}>Sim</Radio>
                            </Radio.Group>
                        </div>

                        <h3 id="taxas-h3">Preencha o valor do split da taxa de Rematrícula.</h3>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                formatter={currencyFormatter}
                                parser={currencyParser}
                                decimalSeparator=","
                                disabled={!curso?.taxaRematricula ? true : false}
                                defaultValue={curso?.taxaRematricula ? curso?.valorSplitTaxaRematricula : 0}
                                value={curso?.taxaRematricula ? curso?.valorSplitTaxaRematricula : 0}
                                onChange={(e) => setCurso({ ...curso, valorSplitTaxaRematricula: e })} />
                        </div>

                        <h3 id="taxas-h3">Preencha a prorrogação da primeira parcela da Rematrícula.</h3>
                        <div className="input-box">
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                max={60}
                                disabled={!curso?.taxaRematricula ? true : false}
                                defaultValue={curso?.taxaRematricula ? curso?.numeroProrrogacaoDiasRematricula : 0}
                                value={curso?.taxaRematricula ? curso?.numeroProrrogacaoDiasRematricula : 0}
                                onChange={(e) => setCurso({ ...curso, numeroProrrogacaoDiasRematricula: e })} />
                        </div>
                    </div>

                    <div className="botoes">
                        {revisao ?
                            <div>
                                <Button
                                    type="primary"
                                    className="button-primary"
                                    id="revisao"
                                    style={{ width: 700, marginBottom: 10 }}
                                    onClick={patchOferta}
                                    disabled={curso?.valorMensalidadeMinimoRematricula <= curso?.valorSplitRematricula ? true : false}
                                >Replicar todas ofertas</Button>
                                <Link to="/cursos/create/step-6">
                                    <Button
                                        type="primary"
                                        className="button-primary"
                                        id="revisao"
                                        style={{ width: 700 }}
                                        disabled={curso?.valorMensalidadeMinimoRematricula <= curso?.valorSplitRematricula ? true : false}>Voltar para Revisão</Button>
                                </Link>
                            </div>
                            : <>
                                <Link to="/cursos/create/step-4">
                                    <Button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</Button>
                                </Link>
                                <Link to="/cursos/create/step-6">
                                    <Button
                                        className="button-primary"
                                        id="proximo"
                                        style={{ width: 345 }}
                                        disabled={curso?.valorMensalidadeMinimoRematricula <= curso?.valorSplitRematricula ? true : false}>Próximo</Button>
                                </Link>
                            </>
                        }
                    </div>

                </div>
            </div>
            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title='Você deseja cancelar a criação deste curso?'
                subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                textButton1='Não'
                textButton2='Sim, Cancelar'
                styleButton1='button-line'
                styleButton2='button-danger'
                link2='/cursos'
            />
        </div >
    )

}