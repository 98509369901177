import { useCallback, useEffect, useState } from 'react';
import { IMatriculaDTO } from '../../../../../models/happy-code-api.model';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import { useMatriculaService } from '../../../../../services/matricula.service';
import { useNotificationService } from '../../../../../services/notification.service';

export default function useFetchMatriculaData() {
	const { idMatricula } = useParams<any>();
	const { setIsGlobalLoading } = useGlobalContext();

	const { findById } = useMatriculaService();

	const [matriculaData, setMatriculaData] = useState<IMatriculaDTO>();

	const notification = useNotificationService();

	const fetchMatricula = useCallback(async () => {
		if (idMatricula !== undefined) {
			setIsGlobalLoading(true);

			findById({ id: idMatricula })
				.then(({ data }) => setMatriculaData(data))
				.catch(() =>
					notification({
						message: 'Erro!',
						description: 'Matrícula não encontrada',
						type: 'error',
					})
				)
				.finally(() => setIsGlobalLoading(false));
		}
	}, [idMatricula]);

	useEffect(() => {
		const controller = new AbortController();

		fetchMatricula();

		return () => controller.abort();
	}, [fetchMatricula]);

	return {
		matriculaData,
	};
}
