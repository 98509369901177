import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu } from 'antd';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { useNegocioContext } from '../../context/NegocioContext';
import { INegocioDTO } from "../../models/happy-code-api.model";
import { useNegocioService } from '../../services/negocio.service';

interface TItemLista {
    negocio: INegocioDTO;
    handleCampanha: (negocio: INegocioDTO, state: boolean) => void;
}

const ItemListNegocio = memo(({ negocio, handleCampanha }: TItemLista) => {

    const negocioService = useNegocioService();
    const { setIsGlobalLoading } = useGlobalContext();
    const { setNegocio } = useNegocioContext();

    const handleMenuItem = (negocio: INegocioDTO) => {
        if (negocio.ativo) {
            return (
                <Menu.Item onClick={() => handleCampanha(negocio, false)}>
                    <a rel="noopener noreferrer">
                        Inativar Negocio
                    </a>
                </Menu.Item>
            )
        }
        if (!negocio.ativo) {
            return (
                <Menu.Item onClick={() => handleCampanha(negocio, true)}>
                    <a rel="noopener noreferrer">
                        Ativar Negocio
                    </a>
                </Menu.Item>
            )
        }
    }

    const menu = (
        <Menu>
            {/* <Menu.Item>
                <a rel="noopener noreferrer">
                    Editar
                </a>
            </Menu.Item> */}
            {handleMenuItem(negocio)}
        </Menu>
    );
    const history = useHistory();

    const goTo = () => {
        setIsGlobalLoading(true);
        negocioService.findFullById(negocio)
            .then(({ data }) => setNegocio(data))
            .finally(() => {
                setIsGlobalLoading(false)
                history.push(`/negocio/create/step-6`)
            });
    }

    const handleStatus = (curriculo) => {

        if (curriculo.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-verde"></div>
                    <p className="ativo">Ativo</p>
                </div>
            )
        }

        if (!curriculo.ativo) {
            return (
                <div className="estado-user">
                    <div className="dot-orange"></div>
                    <p className="standby">Inativo</p>
                </div>
            )
        }
    }

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: '20%' }}>
                <div className="nome">
                    <p>{negocio.descricao}</p>
                    {handleStatus(negocio)}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: '20%' }}>
                <span>{negocio.id}</span>
            </div>
            <div className="funcao-user" id="campanhas" style={{ width: '20%' }}>
                <span>{negocio?.tipoCurriculo?.descricao}</span>
            </div>
            <div className="funcao-user" id="campanhas" style={{ width: '20%' }}>
                <span>{negocio.codigo}</span>
            </div>
            <div style={{ width: '15%' }} />
            <div className="registro-opcao" style={{ width: '5%' }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

ItemListNegocio.displayName = 'ItemListNegocio';

export default ItemListNegocio;