/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Operators } from '../../../enum/operators.enum';
import { ICidadeDTO, ICursoDTO, IEstadoDTO, ILeadCampanhaDTO, ILeadEstagioDTO, ILeadEstagioFullDTO, ILeadOrigemDTO, ILeadStatusDTO, ILeadTipoDTO, IPaisDTO, IUnidadeDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useCursoService } from '../../../services/curso.service';
import { useLeadCampanhaService } from '../../../services/lead-campanha.service';
import { useLeadEstagioService } from '../../../services/lead-estagio.service';
import { useLeadOrigemService } from '../../../services/lead-origem.service';
import { useLeadStatusService } from '../../../services/lead-status.service';
import { useLeadTipoService } from '../../../services/lead-tipo.service';
import { useLeadService } from '../../../services/lead.service';
import { usePaisService } from '../../../services/pais.service';
import { useUnidadeService } from '../../../services/unidade.service';
import { useParams } from 'react-router-dom';


const CrmContext = createContext<any>(null);
export interface PropsCrmContext {
    leadsStatus?: ILeadStatusDTO[];
    campanhas?: ILeadCampanhaDTO[];
    leadStatus?: ILeadStatusDTO[];
    leadEstagios?: ILeadEstagioDTO[];
    paises?: IPaisDTO[];
    estados?: IEstadoDTO[];
    cidades?: ICidadeDTO[];
    leadOrigens?: ILeadOrigemDTO[];
    leadTipos?: ILeadTipoDTO[];
    unidades?: IUnidadeDTO[];
    cursos?: ICursoDTO[];
    boards?: ILeadEstagioFullDTO[];
}

const CrmContextProvider: React.FC = ({ children }: any) => {
    const { idLead } = useParams<any>();
    const [state, setState] = useState({
        campanhas: [],
        leadStatus: [],
        leadEstagios: [],
        paises: [],
        estados: [],
        cidades: [],
        leadOrigens: [],
        leadTipos: [],
        unidades: [],
        cursos: [],
        boards: [],
        boardsFiltrados: [],
        boardsTemp: [],
        leadId: null,
        flRefresh: false,
        qtLeadsPorPaginas: 10,
    });

    const leadCampanhaService = useLeadCampanhaService();
    const leadStatusService = useLeadStatusService();
    const leadEstagioService = useLeadEstagioService();
    const paisService = usePaisService();
    const leadTipoService = useLeadTipoService();
    const leadOrigemService = useLeadOrigemService();
    const unidadeService = useUnidadeService();
    const cursoService = useCursoService();
    const leadService = useLeadService();
    const { unidade: unidadeUsuarioLogado } = useGlobalContext();

    const { setIsGlobalLoading } = useGlobalContext();


    const refreshLeads = async (dsBusca = '', estagiosTemp: ILeadEstagioDTO[] = null, flExibirLoading = true) => {
        flExibirLoading && setIsGlobalLoading(true);
        let estagios = estagiosTemp || state?.boards;

        estagios = await Promise.all(estagios.map(async (estagio: ILeadEstagioDTO) => {
            const predicate = new Predicate();
            predicate.sort = 'asc';
            predicate.size = state.qtLeadsPorPaginas;

            predicate.addOption('estagioCdLeadEstagio', estagio?.cdLeadEstagio, Operators.EQUAL);
            predicate.addOption('unidadeId', unidadeUsuarioLogado.id, Operators.EQUAL);
            idLead && predicate.addOption('id', idLead, Operators.EQUAL);
            dsBusca?.length >= 3 && predicate.addOption('dsNomeLead', dsBusca, Operators.CONTAINS);
            predicate.addSort('dataCriacao');
            const leadResponse = await leadService.findCustomIndex(predicate);
            if (leadResponse.status >= 200 && leadResponse.status <= 299) {
                estagio.leads = [...leadResponse.data.content];
            }
            return estagio;
        }));
        setState((state) => ({ ...state, boards: [...estagios], boardsTemp: [...estagios], flRefresh: true }));
        flExibirLoading && setIsGlobalLoading(false);
    }

    const refresh = async () => {
        setIsGlobalLoading(true);

        const predicate = new Predicate();
        predicate.addSort('nrLeadEstagioOrdem');

        const estagios: ILeadEstagioDTO[] = await leadEstagioService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadEstagios: response.data }));
                return response.data;
            }
            return null;
        });

        if (!estagios || !unidadeUsuarioLogado?.id) {
            setIsGlobalLoading(false);
            return;
        };
        await refreshLeads(null, estagios, false);
        setIsGlobalLoading(false);
    }

    useEffect(() => {
        if (state?.qtLeadsPorPaginas) {
            refresh();
        }
    }, [state?.qtLeadsPorPaginas]);

    useEffect(() => {
        if (!unidadeUsuarioLogado) return;

        setIsGlobalLoading(true);

        leadCampanhaService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, campanhas: response.data }));
            }
        });

        leadStatusService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadStatus: response.data }));
            }
        });

        // leadEstagioService.findList().then((response) => {
        //   if (response.status === 200) {
        //     setState((state) => ({ ...state, leadEstagios: response.data }));
        //   }
        // });

        paisService.findCleanList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, paises: response.data }));
            }
        })

        leadTipoService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadTipos: response?.data }));
            }
        });

        leadOrigemService.findList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, leadOrigens: response?.data }));
            }
        });

        unidadeService.findCleanList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, unidades: response?.data }));
            }
        });

        cursoService.findCleanList().then((response) => {
            if (response.status === 200) {
                setState((state) => ({ ...state, cursos: response?.data }));
            }
        });


        refresh();

        // const intervalId = setInterval(() => {
        //   refresh()
        // }, 10000);

        // return () => clearInterval(intervalId);

    }, [unidadeUsuarioLogado]);



    return <CrmContext.Provider value={[state, setState, refresh, refreshLeads]}>
        {children}
    </CrmContext.Provider>
}

export {
    CrmContextProvider,
    CrmContext
};

