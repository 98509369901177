import { SearchOutlined } from '@mui/icons-material';
import { Col, DatePicker, Input, Row, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { useGlobalContext } from '../../../context/GlobalContext';
import {
	ICursoCleanDTO,
	IMatriculaCleanDTO,
	ITurmaCleanDTO,
} from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useCursoService } from '../../../services/curso.service';
import { useMatriculaService } from '../../../services/matricula.service';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useTurmaService } from '../../../services/turma.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import { formattedDate } from '../../../util/format';
import { hoje } from '../../../util/util';
interface Root {
	['C01_MATRÍCULA']: number;
	C02_ALUNO: string;
	['C03_RESPONSÁVEL']: string;
	C04_CURSO: string;
	C05_TURMA: string;
	['C06_PRESENÇA']: string;
	CXX_ID_CURSO: number;
	CXX_ID_TURMA: number;
	CXX_ID_UNIDADE: number;
	CXX_DS_UNIDADE: string;
	CXX_DT_INICIO: Date;
	CXX_DT_FIM: Date;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

function RelRegistroFrequenciaAlunoAcademy() {
	const { unidade } = useGlobalContext();
	const service = useRelatorioService();
	const cursoService = useCursoService();
	const turmaService = useTurmaService();
	const matriculaService = useMatriculaService();

	const [dados, setData] = useState<Root[]>();
	const [dadosSkill, setDataSkill] = useState<[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [columns2, setColumns2] = useState<ReportColumns[]>();

	const [dataAulaIni, setDataAulaIni] = useState<moment.Moment>();
	const [dataAulaFim, setDataAulaFim] = useState<moment.Moment>();

	const [cursos, setCursos] = useState<ICursoCleanDTO[]>();
	const [cursoId, setCursoId] = useState<number>(0);

	const [turmas, setTurmas] = useState<ITurmaCleanDTO[]>();
	const [turmaId, setTurmaId] = useState<number>(0);

	const [matriculas, setMatriculas] = useState<IMatriculaCleanDTO[]>();
	const [matriculaId, setMatriculaId] = useState<number>(0);

	const [filtro, setFiltro] = useState<string>('aluno');
	const [search, setSearch] = useState<string>();

	const findMatricula = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('aluno.ativo', true);
		predicate.addOption('turma.ativo', true);
		predicate.addOption('aluno.unidade.id', unidade.id);
		return matriculaService.findCleanList(predicate).then(({ data }) => {
			setMatriculas(data);
		});
	};

	const findCurso = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('cursoUnidades.unidade.id', unidade.id);
		matriculaId && predicate.addOption('turmas.matriculas.id', matriculaId);
		return cursoService.findCleanList(predicate).then(({ data }) => {
			setCursos(data);
		});
	};

	const findTurma = () => {
		const predicate = new Predicate();
		predicate.addOption('ativo', true);
		predicate.addOption('unidade.id', unidade.id);
		cursoId && predicate.addOption('curso.id', cursoId);
		return turmaService.findCleanList(predicate).then(({ data }) => {
			setTurmas(data);
		});
	};

	useEffect(() => {
		if (unidade?.id) {
			findCurso();
			findTurma();
			findMatricula();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id, matriculaId, cursoId, turmaId]);

	useEffect(() => {
		const predicate = new Predicate();

		if (unidade.id) {
			if (filtro === 'aluno') {
				search && predicate.addOption('pessoaAlunoNm', search);
			} else {
				search && predicate.addOption('pessoaResponsavelNm', search);
			}

			predicate.addOption('unidadeId', unidade.id);
			matriculaId && predicate.addOption('matriculaId', matriculaId);
			turmaId && predicate.addOption('turmaId', turmaId);
			cursoId && predicate.addOption('cursoId', cursoId);
			dataAulaIni &&
				predicate.addOption('dataAulaIni', dataAulaIni.toISOString());
			dataAulaFim &&
				predicate.addOption('dataAulaFim', dataAulaFim.toISOString());

			service.registroFrequenciaAluno(predicate).then(({ data }) => {
				setData(data);
			});

			service.registroAlunoSkill(predicate).then(({ data }) => {
				setDataSkill(data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		unidade?.id,
		matriculaId,
		turmaId,
		cursoId,
		dataAulaIni,
		dataAulaFim,
		search,
	]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	useEffect(() => {
		if (dadosSkill && dadosSkill.length > 0) {
			setColumns2(dadosSkill[dadosSkill.length - 1]['colunas']);
			dadosSkill.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dadosSkill]);

	const getPeriodo = (): any => {
		if (dataAulaIni && dataAulaFim) {
			return [
				moment(dataAulaIni, 'YYYY-MM-DD'),
				moment(dataAulaFim, 'YYYY-MM-DD'),
			];
		}
		return null;
	};

	const selectMatricula = matriculas?.map((matricula) => (
		<Option key={matricula.id} value={matricula.id}>
			{matricula.id.toString()}
		</Option>
	));
	const selectCursos = cursos?.map((curso) => (
		<Option key={curso.id} value={curso.id}>
			{curso.descricao}
		</Option>
	));
	const selectTurmas = turmas?.map((turma) => (
		<Option key={turma.id} value={turma.id}>
			{turma.descricao}
		</Option>
	));
	const filtroStyle = { minWidth: 150, border: '1px solid gainsboro' };

	let totalPresente = 0;
	let totalAusentes = 0;
	let totalAtrasado = 0;

	dados?.forEach((dado) => {
		totalPresente += dado?.C06_PRESENÇA === 'Presente' ? 1 : 0;
		totalAusentes += dado?.C06_PRESENÇA === 'Ausente' ? 1 : 0;
		totalAtrasado += dado?.C06_PRESENÇA === 'Atrasado' ? 1 : 0;
	});

	const dadosFiltrado = dados?.find(
		(dados) => dados['C01_MATRÍCULA'] === matriculaId
	);

	const renderContent = (filtro) => {
		switch (filtro) {
			case 'aluno':
				return search;
			case 'responsavel':
				return search;
			default:
				return null;
		}
	};

	return (
		<div className="container" style={{ backgroundColor: '#fff' }}>
			<NewMenu selecionado={1} />

			<div>
				<div className="flex justify-between">
					<div className="w-70">
						<header className="rel-header">
							<h1 className="mb-20">Registro de Frequência por Aluno</h1>
							<div className="flex space-between">
								<p>
									<strong>Nome do aluno:</strong>{' '}
									{matriculaId
										? dadosFiltrado?.C02_ALUNO
										: renderContent(filtro === 'aluno' ? filtro : null)}
								</p>
								<p>
									<strong>Unidade:</strong> {unidade?.descricao}
								</p>
							</div>
							<p>
								<strong>Nome do responsável:</strong>{' '}
								{matriculaId
									? dadosFiltrado?.C03_RESPONSÁVEL
									: renderContent(filtro === 'responsavel' ? filtro : null)}
							</p>
							<p>
								<strong>Nome do curso:</strong> {dadosFiltrado?.C04_CURSO}
							</p>
							<p>
								<strong>Nome da turma:</strong> {dadosFiltrado?.C05_TURMA}
							</p>
							<div className="flex space-between">
								<p>
									<strong>Data de início:</strong>{' '}
									{formattedDate(dadosFiltrado?.CXX_DT_INICIO)}
								</p>
								<p>
									<strong>Data de fim:</strong>{' '}
									{formattedDate(dadosFiltrado?.CXX_DT_FIM)}
								</p>
							</div>
						</header>
					</div>
					<div className="w-40">
						<div className="box-header-contador-relatorio mt-50">
							<div className="box-informe">
								<h1>Alunos presentes</h1>
								<p>{totalPresente}</p>
							</div>
							<div className="box-informe">
								<h1>Alunos ausentes</h1>
								<p>{totalAusentes}</p>
							</div>
							<div className="box-informe">
								<h1>Alunos atrasados</h1>
								<p>{totalAtrasado}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="dashboard" style={{ top: 50 }}>
					<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
					<Row style={{ marginBottom: 10 }}>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Buscar {filtro}</span>
							<br />
							<div
								className="input-text"
								style={{
									width: '90%',
									height: 32,
									border: '1px solid gainsboro',
								}}
							>
								<Input
									placeholder="Buscar"
									prefix={
										<SearchOutlined
											style={{ color: '#6A7C96', fontSize: 20 }}
										/>
									}
									onChange={(e) => setSearch(e.target.value)}
								/>
							</div>
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Filtro</span>
							<br />
							<Select
								defaultValue="aluno"
								style={filtroStyle}
								onChange={(e: string) => setFiltro(e)}
							>
								<Select.Option value="aluno">Aluno</Select.Option>
								<Select.Option value="responsavel">Responsável</Select.Option>
							</Select>
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Matrícula</span>
							<br />
							<Select
								showSearch
								allowClear
								style={filtroStyle}
								defaultValue={matriculaId}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								onChange={(e) => setMatriculaId(e)}
							>
								{/* <Option value={0}>Todos</Option> */}
								{selectMatricula}
							</Select>
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Curso</span>
							<br />
							<Select
								showSearch
								allowClear
								style={filtroStyle}
								defaultValue={cursoId}
								optionFilterProp="children"
								disabled={matriculaId ? true : false}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								onChange={(e) => setCursoId(e)}
							>
								{/* <Option value={0}>Todos</Option> */}
								{selectCursos}
							</Select>
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Turma</span>
							<br />
							<Select
								showSearch
								allowClear
								style={filtroStyle}
								defaultValue={turmaId}
								optionFilterProp="children"
								disabled={matriculaId ? true : false}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								onChange={(e) => setTurmaId(e)}
							>
								{/* <Option value={0}>Todos</Option> */}
								{selectTurmas}
							</Select>
						</Col>
						<Col flex={2} style={{ paddingRight: '1em' }}>
							<span style={{ fontSize: '0.8em' }}>Período da aula</span>
							<br />
							<RangePicker
								value={getPeriodo()}
								onChange={(values) => {
									setDataAulaIni(values && values[0]);
									setDataAulaFim(values && values[1]);
								}}
								format={'DD-MM-YYYY'}
							/>
							{/* <Input value={competencia} onChange={(e) => setCompetencia(e.target.value)} placeholder="Buscar" /> */}
						</Col>
						<Col flex={2}>
							<span style={{ fontSize: '0.8em' }}>Excel</span>
							<br />
							<ExportToExcel
								apiData={dados}
								fileName={`Frequência de alunos ` + hoje().toISOString()}
							/>
						</Col>
					</Row>
					<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />

					{matriculaId ? (
						<>
							<h1>Skill do aluno {dados?.[0]?.C02_ALUNO}</h1>
							<hr
								style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }}
							/>
							<Table
								dataSource={dadosSkill}
								columns={columns2}
								scroll={{ x: '100%' }}
							/>
						</>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default RelRegistroFrequenciaAlunoAcademy;
