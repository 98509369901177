import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useEscolaContext } from '../../../context/EscolaContext';
import { useEscolaService } from '../../../services/escola.service';
import { Predicate } from '../../../models/predicate.model';
import useProfileIsFranquia from '../../../globalHooks/useProfileIsFranquia';
import { PageableResponse } from '../../../models/response.model';
import { IEscolaFullDTO } from '../../../models/happy-code-api.model';
import { Operators } from '../../../enum/operators.enum';
import { FilterState } from '../../../types/filter-state.type';
import { FilterDefaultValue } from '../../../enum/filter-default-value.enum';
import { SortingEnum } from '../../../enum/sorting.enum';

export default function useFindSchools() {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const { setFlRevisao } = useEscolaContext();
	const { find } = useEscolaService();
	const predicate = useMemo(() => new Predicate(), []);

	const profileIsFranquia = useProfileIsFranquia();
	const addProfileParams = useCallback(() => {
		profileIsFranquia &&
			unidade.id &&
			predicate.addOption('unidade.id', unidade.id);
	}, [profileIsFranquia, unidade.id]);

	const [stateId, setStateId] = useState<FilterState['id'] | string>();
	const onFilterStateChange = (stateValue: FilterState['id'] | string) =>
		setStateId(stateValue);
	const addStateParams = useCallback(() => {
		if (!stateId) return;

		if (stateId !== FilterDefaultValue.DEFAULT)
			return predicate.addOption('enderecoUf', stateId);

		return predicate.removeOption('enderecoUf');
	}, [stateId]);

	const [schoolStatusId, setSchoolStatusId] = useState<number | string>();
	const onFilterSchoolStatusChange = (schoolStatusValue: number | string) =>
		setSchoolStatusId(schoolStatusValue);
	const addSchoolStatusParams = useCallback(() => {
		if (!schoolStatusId) return;

		if (schoolStatusId !== FilterDefaultValue.DEFAULT)
			return predicate.addOption('statusId', schoolStatusId);

		return predicate.removeOption('statusId');
	}, [schoolStatusId]);

	const [sortSchoolName, setSortSchoolName] = useState<string>(SortingEnum.ASC);
	const onSortSchoolNameChange = (sortValue: string) =>
		setSortSchoolName(sortValue);
	const addSortSchoolNameParams = useCallback((): void => {
		const sort =
			sortSchoolName === SortingEnum.ASC ? SortingEnum.DESC : SortingEnum.ASC;

		predicate.sort = sort;
		return predicate.addSort('nomeFantasia');
	}, [sortSchoolName]);

	const addParamsToPredicate = useCallback(() => {
		addProfileParams();
		addStateParams();
		addSchoolStatusParams();
		addSortSchoolNameParams();
	}, [
		addProfileParams,
		addStateParams,
		addSchoolStatusParams,
		addSortSchoolNameParams,
	]);

	const [schoolData, setSchoolData] =
		useState<PageableResponse<IEscolaFullDTO>>();

	const findSchoolData = useCallback(async () => {
		setIsGlobalLoading(true);
		setFlRevisao(false);

		addParamsToPredicate();

		return find(predicate)
			.then(({ data }) =>
				setSchoolData(data as PageableResponse<IEscolaFullDTO>)
			)
			.catch((error) => console.error(error))
			.finally(() => setIsGlobalLoading(false));
	}, [setIsGlobalLoading, addParamsToPredicate]);

	useEffect(() => {
		const controller = new AbortController();
		findSchoolData();
		return () => controller.abort();
	}, [findSchoolData]);

	const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findSchoolData();
	};

	const [searchbarValue, setSearchbarValue] = useState('');

	const FUNCTION_EXECUTION_TIME_IN_MILLISECONDS = 800;

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			predicate.addOption(
				'nomeFantasia',
				searchbarValue || '',
				Operators.CONTAINS
			);
			findSchoolData();
		}, FUNCTION_EXECUTION_TIME_IN_MILLISECONDS);

		return () => clearTimeout(typingTimeout);
	}, [findSchoolData, searchbarValue]);

	return {
		findSchoolData,
		schoolData,
		onPageChange,
		setSearchbarValue,
		onFilterStateChange,
		onFilterSchoolStatusChange,
		onSortSchoolNameChange,
	};
}
