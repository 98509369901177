import { useCallback } from 'react';

export const useSortedResponsaveis = () => {

    const orderListResponsavel = useCallback((aluno, setAluno) => {
        const sorted = [...aluno?.pessoa?.pessoaResponsaveis].sort((a, b) => {
            if (a.principal && !b.principal) return -1;
            if (!a.principal && b.principal) return 1;

            if (a.ativo && !b.ativo) return -1;
            if (!a.ativo && b.ativo) return 1;

            return 0;
        });
        setAluno({ ...aluno, pessoa: { ...aluno?.pessoa, pessoaResponsaveis: sorted } });
    }, []);

    return { orderListResponsavel };
};
