import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useTaxaFixaContext } from '../../context/TaxaContext';

import StepsCreate from './steps-create';
import './style.css';

const TaxasCreateStep1: React.FunctionComponent = () => {

  const { taxaFixa, setTaxaFixa, revisao } = useTaxaFixaContext();

  const { TextArea } = Input;
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const onFinish = () => {
    history.push("/taxas/create/step-2");
  }
  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={20} strokeLinecap="square" showInfo={false} />
      </div>
      <StepsCreate current={0} />
      <div className="franquia">
        <div className="user-row">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h3 id="taxas-h3">Insira o nome e descrição da taxa</h3>
            {/* <h6 id="taxas-h6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
            <div className="input-box">
              <p>Nome da Taxa</p>
              <Form.Item
                rules={[{ required: true, message: 'Insira o valor!' }]}
              >
                <Input value={taxaFixa.codigo} placeholder="Nome teste de taxa" id="nome" onChange={e => setTaxaFixa({ ...taxaFixa, codigo: e.target.value })} />
              </Form.Item>
            </div>
            <div className="input-box">
              <p>Descreva o funcionamento da taxa</p>
              <TextArea id="descricao" value={taxaFixa.descricao} rows={4} onChange={e => setTaxaFixa({ ...taxaFixa, descricao: e.target.value })} />
            </div>
            <div className="botoes">
              {revisao ?
                <Link to="/taxas/create/step-6">
                  <Button type="primary" id="revisao" className="button-primary" style={{ width: 700 }}>Voltar para Revisão</Button>
                </Link>
                : <>
                  <Link to="/taxas">
                    <Button id="voltar" className="button-second" style={{ width: 340 }}>Voltar</Button>
                  </Link>
                  <Form.Item>
                    <Button id="proximo" type="primary" htmlType="submit" className="button-primary" style={{ width: 340 }}>Próximo</Button>
                  </Form.Item>
                </>
              }
            </div>
          </Form>
        </div>
      </div>
      <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title='Você deseja cancelar a criação dessa taxa?'
        subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
        textButton1='Não'
        textButton2='Sim, Cancelar'
        styleButton1='button-line'
        styleButton2='button-danger'
        link2='/taxas'
      />
    </div>
  )

}

export default TaxasCreateStep1;