import { Steps } from 'antd';
import { ReactElement } from 'react';
import { useCursoContext } from '../../context/CursoContext';

interface Props {
    current: number;
}

function StepsCreate({ current }: Props): ReactElement {

    const { revisao } = useCursoContext();

    return (
        <div className="menu-user">
            <h3>Criação de Curso</h3>
            <Steps direction="vertical" current={revisao ? 6 : current}>
                <Steps.Step title="Dados Básicos" />
                <Steps.Step title="Modo de Aplicação" />
                <Steps.Step title="Local de Oferta" />
                <Steps.Step title="Matrícula" />
                <Steps.Step title="Rematrícula" />
                <Steps.Step title="Revisão" />
            </Steps>
        </div>
    )
}

export default StepsCreate
