import { Redirect, Route } from 'react-router-dom';
import usePrivateRoute from './use-private-route.hook';

interface PrivateRoutesProps {
	path: string;
	exact: boolean;
	component: any;
}

interface User {
	active: boolean;
}

interface Token {
	access_token: string;
}

const PrivateRoute = ({
	path,
	exact,
	component: Component,
}: PrivateRoutesProps) => {
	const { checkIfUserIsLogged } = usePrivateRoute();

	return (
		<Route
			path={path}
			exact={exact}
			render={(props) =>
				checkIfUserIsLogged() ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
