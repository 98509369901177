/* eslint-disable react-hooks/rules-of-hooks */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CloseOutlined } from "@mui/icons-material";
import { Button, Checkbox, Input, InputNumber, Radio, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { PresentImage } from "../../../../components/ApresentarImagem";
import Header from "../../../../components/Header";
import Modal from '../../../../components/Modal';
import { useGlobalContext } from "../../../../context/GlobalContext";
import "../../../../global.css";
import { INegocioCleanDTO, IWebinarDTO } from "../../../../models/happy-code-api.model";
import { useAcademyArquivoService } from "../../../../services/academy-arquivo";
import { useAcademyWebinarService } from "../../../../services/academy-webinar";
import { useNegocioService } from "../../../../services/negocio.service";
import { useNotificationService } from "../../../../services/notification.service";
import { validateFileExtension } from "../../../../util/util";
import { Predicate } from "../../../../models/predicate.model";

const webinarCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const history = useHistory();
    const negocioService = useNegocioService();
    const arquivoService = useAcademyArquivoService();
    const webinarService = useAcademyWebinarService();
    const [predicate] = useState<Predicate>(new Predicate());
    const [isOpen, setIsOpen] = useState(false);
    const [webinar, setWebinar] = useState<IWebinarDTO>();
    const { idWebinar } = useParams<any>()
    const [sendFileCapa, setSendFileCapa] = useState<File | null>(null);
    const [validaCapa, setValidaCapa] = useState<boolean>(false);
    const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);

    const findWebinars = () => {
        setIsGlobalLoading(true);
        webinarService.findById({ id: idWebinar })
            .then((response) => {
                setWebinar(response.data);
                setValidaCapa(response.data.capa ? true : false);
            })
            .finally(() => setIsGlobalLoading(false));
    };


    const findNegocio = () => {
        setIsGlobalLoading(true);
        negocioService.findList(predicate)
            .then((response) => {
                setNegocios(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };


    const enviarArquivo = async () => {
        setIsGlobalLoading(true);
        const fetchTrilha = async () => {
            try {
                let updatedUploadWebinar = { ...webinar };
                if (sendFileCapa) {
                    const { data } = await arquivoService.upload(sendFileCapa);
                    updatedUploadWebinar = {
                        ...updatedUploadWebinar,
                        arquivo: {
                            id: data.id,
                            codigo: data.codigo,
                            url: data.url,
                            ativo: false
                        }
                    };
                } else {
                    updatedUploadWebinar = {
                        ...updatedUploadWebinar,
                        arquivo: null,
                    }
                }

                if (!idWebinar) {
                    await webinarService.create(updatedUploadWebinar)
                    history.push(`/academy/gestao-webinar/webinar`);
                    notification({
                        description: `Webinar cadastrado com sucesso, por gentileza atualizar a página.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                } else {
                    await webinarService.editarArquivoCustom(webinar.id, updatedUploadWebinar);
                    history.push(`/academy/gestao-webinar/webinar`);
                    notification({
                        description: `Webinar alterado com sucesso, por gentileza atualizar a página.`,
                        type: 'success',
                        message: 'Sucesso!',
                    });
                }
            } catch (error) {
                notification({
                    description: `Desculpe, não foi possível editar a Webinar.`,
                    type: 'error',
                    message: 'Erro!',
                });
            }
            setIsGlobalLoading(false);
        };
        fetchTrilha();
    };

    const submitValidationFields = () => {
        if (!webinar?.acessoB2c && !webinar?.acessoB2b && !webinar?.acessoComercial) {
            document.getElementById('checkTipoAcesso')?.focus();
            return notification({
                description: `Verifique o campo "Visualização Academy". É necessário selecionar um tipo de visualização para o Academy.`,
                type: 'warning',
                message: 'Atenção!',
            });
        }

        if (webinar?.id && sendFileCapa && !validaCapa && validateFileExtension(sendFileCapa?.name)) {
            document.getElementById('arquivoCapa')?.focus();
            notification({
                description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (!webinar?.id && !sendFileCapa && !validaCapa && validateFileExtension(sendFileCapa?.name)) {
            document.getElementById('arquivoCapa')?.focus();
            notification({
                description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (validaCapa && validateFileExtension(webinar?.capa)) {
            document.getElementById('linkCapa')?.focus();
            notification({
                description: `A extensão do link selecionado para a "capa" não é permitida. Por favor, selecione um link com extensão válida (.jpg, .jpeg, .png, .gif).`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }

        if (!webinar?.conteudo) {
            document.getElementById('conteudo')?.focus();
            notification({
                description: `O conteúdo não foi informado para o campo "Conteúdo do Webinar". Por favor, inserir o conteúdo no editor.`,
                type: 'warning',
                message: 'Atenção!',
            });
            return;
        }
        enviarArquivo();
    }


    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve) => {
                    loader.file.then((file) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64String = reader.result?.toString().split(',')[1] || '';
                            resolve({ default: `data:image/png;base64,${base64String}` });
                        };
                        reader.readAsDataURL(file);
                    });
                });
            },
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository")
            .createUploadAdapter = (loader) => {
                return uploadAdapter(loader);
            };
    }

    const limparArquivoCapa = () => {
        setSendFileCapa(null);
        setWebinar({ ...webinar, arquivo: null })
    }

    useEffect(() => {
        findNegocio();
        if (idWebinar) {
            findWebinars();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idWebinar])

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ justifyContent: "normal", width: "65%" }}>
                <div className="space1" />
                <div className="user-row">
                    <div className="card-form" style={{ width: "150%" }} >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 id="taxas-h3" style={{ marginBottom: 10 }}>Insira informações para cadastro do Webinar</h3>
                            <Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
                        </div>
                        <div className="input-box">
                            <label>Visualização Academy</label>
                            <div style={{ marginBottom: 5 }}>
                                <label style={{ marginRight: 10 }} htmlFor="checkTipoAcesso">B2C:</label>
                                <Checkbox
                                    id="checkTipoAcesso"
                                    checked={webinar?.acessoB2c}
                                    onChange={(e) => setWebinar({ ...webinar, acessoB2c: e.target.checked })}
                                />
                            </div>
                            <div style={{ marginBottom: 5 }}>
                                <label style={{ marginRight: 10 }}>B2B:</label>
                                <Checkbox
                                    checked={webinar?.acessoB2b}
                                    onChange={(e) => setWebinar({ ...webinar, acessoB2b: e.target.checked })}
                                />
                            </div>
                            <div style={{ marginBottom: 5 }}>
                                <label style={{ marginRight: 10 }}>Comercial:</label>
                                <Checkbox
                                    checked={webinar?.acessoComercial}
                                    onChange={(e) => setWebinar({ ...webinar, acessoComercial: e.target.checked })}
                                />
                            </div>
                        </div>

                        <div className="input-box">
                            <label htmlFor="negocio">Negócio</label>
                            <Select
                                style={{ width: "100%" }}
                                id="negocio"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                defaultValue={webinar?.negocio?.id}
                                value={webinar?.negocio?.id}
                                onChange={(e) => setWebinar({ ...webinar, negocio: { ...webinar.negocio, id: e } })}
                                filterOption={(input, option) =>
                                    option?.text?.toLowerCase().includes(input.toLowerCase())}
                            >
                                {negocios && negocios.map(negocio =>
                                    <Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>{negocio.descricao}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className="input-box">
                            <label htmlFor="nome">Título</label>
                            <Input
                                id="nome"
                                defaultValue={webinar?.nome}
                                value={webinar?.nome}
                                onChange={(e) => setWebinar({ ...webinar, nome: e.target.value })} />
                        </div>

                        <div className="input-box">
                            <label htmlFor="happyCoins">Happy Coins</label>
                            <InputNumber
                                style={{ width: "100%" }}
                                id="happyCoins"
                                type="number"
                                min={0}
                                defaultValue={webinar?.happyCoins}
                                value={webinar?.happyCoins}
                                onChange={(e) => setWebinar({ ...webinar, happyCoins: e })} />
                        </div>

                        <div className="input-box">
                            <label htmlFor="checkedCapa">Tipo do arquivo Capa</label>
                            <Radio.Group
                                value={validaCapa}
                                defaultValue={validaCapa}
                                onChange={(e) => setValidaCapa(e.target.value)}
                            >
                                <Space direction="vertical">
                                    <Radio value={false} id="checkedCapa">Arquivo</Radio>
                                    <Radio value={true} onClick={limparArquivoCapa}>Link</Radio>
                                </Space>
                            </Radio.Group>
                        </div>

                        {!validaCapa ?
                            <div className="input-box">
                                <label htmlFor="arquivoCapa">Upload arquivo capa</label>
                                <Input
                                    id="arquivoCapa"
                                    type="file"
                                    onChange={(e) => {
                                        setSendFileCapa(e.target.files?.[0] || null)
                                        setWebinar({ ...webinar, capa: null })
                                    }} />
                            </div>
                            :
                            <div className="input-box">
                                <label htmlFor="linkCapa">Informar link capa</label>
                                <Input
                                    id="linkCapa"
                                    defaultValue={webinar?.capa}
                                    value={webinar?.capa}
                                    onChange={(e) => setWebinar({ ...webinar, capa: e.target.value })} />
                            </div>
                        }

                        <PresentImage
                            file={sendFileCapa}
                            link={webinar?.capa ? webinar?.capa : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + webinar?.arquivo?.url}
                            size={"100%"}
                        />

                        <div className="input-box">
                            <label htmlFor="editor">Conteúdo do Webinar</label>
                            <CKEditor
                                id="conteudo"
                                config={{
                                    extraPlugins: [uploadPlugin],
                                    mediaEmbed: {
                                        previewsInData: true, // Ative a visualização de incorporação na visualização de dados
                                    },
                                }}
                                editor={ClassicEditor}
                                data={webinar?.conteudo}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setWebinar(prev => ({ ...prev, conteudo: data }));
                                }}
                            />
                        </div>

                        <div className="botoes" style={{ marginTop: 10 }}>
                            <Link to={`/academy/gestao-webinar/webinar`}>
                                <Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>Voltar</Button>
                            </Link>
                            <Button
                                style={{ width: 345 }}
                                type="primary"
                                id="proximo"
                                className="button-primary"
                                onClick={submitValidationFields}>Salvar</Button>
                        </div>
                    </div>
                </div>
                <Modal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    title='Você deseja cancelar a operação?'
                    subtitle='Todos os dados serão perdidos, e essa ação não pode ser desfeita.'
                    textButton1='Não'
                    textButton2='Sim, Cancelar'
                    styleButton1='button-line'
                    styleButton2='button-danger'
                    link2={`/academy/gestao-webinar/webinar`}
                />

            </div>
        </div >
    )
}

export default webinarCreate;
