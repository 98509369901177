import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import '../style.css';
import StepsCreate from './steps-create';

const FranquiaCreateStep6: React.FunctionComponent = () => {
  const { revisao } = useUnidadeContext();
  return (
    <div className="container">
      <Header />
      <Progress />
      <StepsCreate current={5} />
      <div className="franquia" style={{ top: 70 }}>
        <div className="franquia-row">
          <h5>Responsaveis</h5>
          <div className="box-cards">
            <div className="card">
              <div className="check">
                <Unicons.UilCheck size="20" color="#FFFFFF" />
              </div>
              <h4>Responsável legal</h4>
              <div className="dados-box">
                <div className="circle-avatar"></div>
                <div className="dados-responsavel">
                  <h4>Rodrigo Paiva Jesus</h4>
                  <span>Ver mais detalhes</span>
                </div>
              </div>
              <Link to="/franquias/create/responsavel">
                <button className="alterar">
                  Alterar
                </button>
              </Link>
            </div>
            <div className="card">
              <div className="check">
                <Unicons.UilCheck size="20" color="#FFFFFF" />
              </div>
              <h4>Gestor</h4>
              <div className="dados-box">
                <div className="circle-avatar"></div>
                <div className="dados-responsavel">
                  <h4>Mariana Paz Silva Maria</h4>
                  <span>Ver mais detalhes</span>
                </div>
              </div>
              <Link to="/franquias/create/responsavel">
                <button className="alterar">
                  Alterar
                </button>
              </Link>
            </div>
          </div>
          <div className="botoes">
            {revisao ?
              <Link to="/franquias/create/step-10">
                <Button type="primary" className="button-primary" id="revisao" style={{ width: 700 }}>Voltar para Revisão</Button>
              </Link>
              : <>
                <Link to="/franquias/create/step-5">
                  <div className="voltar"><span>Voltar</span></div>
                </Link>
                <Link to="/franquias/create/step-7">
                  <div className="proximo"><span>Próximo</span></div>
                </Link>
              </>
            }
          </div>
        </div>
      </div>
      <Link to="/franquias">
        <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
      </Link>
    </div>
  )
}

export default FranquiaCreateStep6;